import React, { Component } from "react";
import { Button, Header, Modal, Form } from "semantic-ui-react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as modalActions from "../../actions/modalActions";
import * as labActions from "../../actions/labActions";

import "../reusable/Modal.css";

export class LabOrderCancelationModal extends Component {
  state = {
    reason: "",
    valid: false
  };

  handleClose = (back = true) => {
    const {
      actions: { hideModal },
      multiple
    } = this.props;
    hideModal(multiple, !back);
  };

  handleSave = () => {
    const { aoe, patientId, orderId, labSource, labCode, isConfirmatory, isCancelOrder } = this.props;
    const promiseArray = [
      new Promise(resolve => {
        this.props.actions.cancelRequestedLab(
          patientId,
          orderId,
          labSource,
          labCode,
          this.state.reason,
          isConfirmatory,
          isCancelOrder,
          aoe
        );
        resolve();
      })
    ];
    Promise.all(promiseArray).then(() => this.handleClose(false));
  };

  handleReasonOnChange = (e, data) => {
    this.setState({ reason: data.value, valid: data.value.trim() !== "" });
  };

  render() {
    const { open, patientName, processing } = this.props;

    return (
      <Modal open={open} onClose={this.handleClose} closeIcon size="small" className="secondary-dem-modal">
        <Header content={`Reason for canceling test: ${patientName}`} />
        <Modal.Content>
          <Form>
            <Form.Group widths="equal">
              <Form.Field>
                <Form.TextArea
                  name="cancelationReason"
                  label="Reason"
                  placeholder="Please type the reason"
                  value={this.state.reason}
                  maxLength={140}
                  onChange={this.handleReasonOnChange}
                  id="input-cancelation-reason"
                  onFocus={this.handleFocus}
                />
              </Form.Field>
            </Form.Group>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.handleClose} id="btn-cancel">
            Cancel
          </Button>
          <Button
            color="blue"
            onClick={this.handleSave}
            id="btn-save"
            disabled={!this.state.valid}
            loading={processing}
          >
            Save
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    processing: state.ajaxCallsInProgress > 0
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...modalActions,
        ...labActions
      },
      dispatch
    )
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LabOrderCancelationModal);
