import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { isEmpty } from "lodash";
import { Accordion, Container, Row, Col, Button, Icon, Tabs, Tab } from "../../../ui";
import emrComponent from "../../common/emrComponent";
import * as modalActions from "../../../actions/modalActions";
import * as contextActions from "../../../actions/contextActions";
import * as templateActions from "../../../actions/templateActions";
import * as labActions from "../../../actions/labActions";
import { roleGroups } from "../../../constants/securityRoles";
import { checkRoles, showModal } from "../../../helpers";
import {
  SURVEY_ID_COWS_SURVEY_ID,
  SURVEY_ID_VITALS_SURVEY_ID,
  TEMPLATE_ID_MA_TEMPLAE_ID,
  VISIT_STATUS_VISIT_COMPLETE
} from "../../../constants/miscellaneous";
import LabResults from "./LabResults";
import LabResultsByDateOfService from "./LabResultsByDateOfService";
import PendingOrdersTile from "./PendingOrdersTile";
import StandingLabOrder from "./StandingLabOrder";
import { INTAKE_MODAL, LAB_ORDER_MODAL, SURVEY_MODAL, PRIMARY_LABEL_DATE_MODAL } from "../../../helpers/modals";
import Notes from "../../common/Notes";
import "./LabsPage.css";

export class LabsPage extends Component {
  state = {
    activeAccordionIndex: -1,
    enableAddOrder: true,
    enableAddVitals: true,
    enableAddCows: true,
    enableAddIntake: true
  };
  abortController = new AbortController();
  abortSignal = this.abortController.signal;

  componentDidMount() {
    this._isMounted = true;
    const { patientId, labsVisitId } = this.props;
    this.props.actions.setContext("template", { templateId: TEMPLATE_ID_MA_TEMPLAE_ID });
    if (patientId) {
      this.setState({ enableAddOrder: true });
    } else {
      this.setState({ enableAddOrder: false });
    }
    if (patientId && labsVisitId) {
      this.loadTemplates();
      this.setState({ enableAddVitals: true, enableAddCows: true });
    } else {
      this.setState({ enableAddVitals: false, enableAddCows: false });
    }
  }

  componentDidUpdate(prevProps) {
    const { patientId, labsVisitId, reloadTemplate } = this.props;
    let templateLoaded = false;
    if (this._isMounted && (prevProps.patientId !== patientId || prevProps.labsVisitId !== labsVisitId)) {
      if (patientId) {
        this.setState({ enableAddOrder: true });
      } else {
        this.setState({ enableAddOrder: false });
      }
      if (patientId && labsVisitId) {
        templateLoaded = true;
        this.loadTemplates();
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({ enableAddVitals: true, enableAddCows: true });
      } else {
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({ enableAddVitals: false, enableAddCows: false });
      }
    }
    if (!templateLoaded && reloadTemplate && !prevProps.reloadTemplate && patientId && labsVisitId) {
      this.props.actions.setReloadSelectedTemplate(false);
      this.loadTemplates();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.abortController.abort();
    this.props.actions.setContext("template", { templateId: null });
    this.props.actions.clearPatientLabs(this.props.patientId);
    this.props.actions.clearTemplates();
  }

  _isMounted = false;

  loadTemplates = () => {
    const { patientId, labsVisitId } = this.props;
    if (!patientId || !labsVisitId) return;
    this.setState({ enableAddVitals: false, enableAddCows: false });
    this.props.actions
      .loadSpecificTemplateAndItsSurveys(patientId, TEMPLATE_ID_MA_TEMPLAE_ID, labsVisitId, "", false, this.abortSignal)
      .then(() => {
        if (this._isMounted) this.setState({ enableAddVitals: true, enableAddCows: true });
      })
      .catch((e) => {
        if (e.message !== "USER_ABORTED") throw e;
      });
  };

  handleOrderModalOpen = () =>
    showModal(LAB_ORDER_MODAL, {
      open: true
    });

  handleVitalsModalOpen = () =>
    showModal(SURVEY_MODAL, {
      open: true,
      asModal: true,
      for: "templates",
      survey: this.props.vitalsSurvey
    });

  handleCowsModalOpen = () =>
    showModal(SURVEY_MODAL, {
      open: true,
      asModal: true,
      for: "templates",
      survey: this.props.cowsSurvey
    });

  handleIntakeModalOpen = () => {
    showModal(INTAKE_MODAL, {});
  };

  handlePrintPrimaryLabel = () => {
    showModal(PRIMARY_LABEL_DATE_MODAL, {
      open: true,
      patientId: this.props.patientId
    });
  };

  handleAccordionClick = (index) => {
    // const { index } = titleProps;
    const { activeAccordionIndex } = this.state;
    const newIndex = activeAccordionIndex === index ? -1 : index;
    this.setState({ activeAccordionIndex: newIndex });
  };

  render() {
    const {
      patientId,
      labsVisitId,
      visitTypes,
      labsVisitType,
      labsVisitStatus,
      authRoles,
      vitalsSurvey,
      cowsSurvey,
      hiddenSurveys
    } = this.props;

    const { activeAccordionIndex, enableAddOrder, enableAddVitals, enableAddCows, enableAddIntake } = this.state;
    const labsVisitTypeObject = visitTypes.find((v) => v.value === labsVisitType);

    const isStandingVisit = labsVisitTypeObject ? labsVisitTypeObject.standing : false;
    const canCreateStandingLabOrders = checkRoles(roleGroups.createStandingLabOrders, authRoles);
    const canCancelSubmittedLabOrders = checkRoles(roleGroups.cancelSubmittedLabOrders, authRoles);
    const canViewStandingOrderTile =
      isStandingVisit &&
      canCreateStandingLabOrders &&
      !isEmpty(labsVisitStatus) &&
      labsVisitStatus !== VISIT_STATUS_VISIT_COMPLETE;
    const canViewLabOrders = checkRoles(roleGroups.viewLabOrders, authRoles);
    const canAddLabOrders = checkRoles(roleGroups.addLabOrders, authRoles);
    const canPrintPrimaryLabel = checkRoles(roleGroups.printPrimaryLabel, authRoles);
    const showLabResults = checkRoles(roleGroups.viewLabResults, authRoles);

    let hiddenSurvey = vitalsSurvey
      ? hiddenSurveys[`${patientId}-${TEMPLATE_ID_MA_TEMPLAE_ID}-${vitalsSurvey.id}`]
      : null;
    const disabledVitalsSurvey = vitalsSurvey && !isEmpty(hiddenSurveys) && isEmpty(hiddenSurvey);
    const vitalSurveyColor = vitalsSurvey && !isEmpty(hiddenSurvey) ? "yellow" : null;

    hiddenSurvey = cowsSurvey ? hiddenSurveys[`${patientId}-${TEMPLATE_ID_MA_TEMPLAE_ID}-${cowsSurvey.id}`] : null;
    const disabledCowsSurvey = cowsSurvey && !isEmpty(hiddenSurveys) && isEmpty(hiddenSurvey);
    const cowsSurveyColor = cowsSurvey && !isEmpty(hiddenSurvey) ? "yellow" : null;

    return (
      <Container fluid className="labs-content py-2">
        <Row style={{ padding: "0" }}>
          <Col lg={9}>
            <Tabs defaultActiveKey="new" id="uncontrolled-tab-example" className="mb-3" mountOnEnter unmountOnExit>
              <Tab eventKey="new" title="Results by Date of Service">
                <LabResultsByDateOfService />
              </Tab>
              <Tab eventKey="old" title="By Lab Orders">
                {showLabResults && (
                  <React.Fragment>
                    <h2 style={{ padding: "10px 0" }}>Results</h2>
                    <LabResults patientId={patientId} labsVisitId={labsVisitId} />
                  </React.Fragment>
                )}
              </Tab>
            </Tabs>
          </Col>
          <Col lg={3} className="labs-right-panel">
            <div>
              {canAddLabOrders && (
                <Button
                  id="labs-plus-order"
                  variant="outline-primary"
                  size="sm"
                  className="me-2 mb-2"
                  onClick={this.handleOrderModalOpen}
                  disabled={!enableAddOrder}
                >
                  <Icon name="plus-lg" className="me-1" />
                  Order
                </Button>
              )}

              {(canAddLabOrders || canCreateStandingLabOrders) && (
                <Button
                  id="labs-plus-vitals"
                  variant="outline-primary"
                  size="sm"
                  className="me-2 mb-2"
                  onClick={this.handleVitalsModalOpen}
                  disabled={!enableAddVitals || disabledVitalsSurvey}
                  color={vitalSurveyColor}
                >
                  <Icon name="plus-lg" className="me-1" />
                  Vitals
                </Button>
              )}
              {(canAddLabOrders || canCreateStandingLabOrders) && (
                <Button
                  id="labs-plus-cows"
                  variant="outline-primary"
                  size="sm"
                  className="me-2 mb-2"
                  onClick={this.handleCowsModalOpen}
                  disabled={!enableAddCows || disabledCowsSurvey}
                  color={cowsSurveyColor}
                >
                  <Icon name="plus-lg" className="me-1" />
                  COWS
                </Button>
              )}
              <Button
                id="labs-plus-intake"
                variant="outline-primary"
                size="sm"
                className="me-2 mb-2"
                onClick={this.handleIntakeModalOpen}
                disabled={!enableAddIntake}
              >
                <Icon name="plus-lg" className="me-1" />
                Intake
              </Button>
              {canPrintPrimaryLabel && (
                <Button
                  id="labs-print-primary-label"
                  variant="outline-primary"
                  size="sm"
                  className="me-2 mb-2"
                  onClick={this.handlePrintPrimaryLabel}
                >
                  <Icon name="plus-lg" className="me-1" />
                  Primary Label
                </Button>
              )}
            </div>
            <Accordion activeKey={activeAccordionIndex} defaultActiveKey="0" className="pb-2">
              <Accordion.Item eventKey="0">
                <Accordion.Header
                  onClick={() => {
                    this.handleAccordionClick("0");
                  }}
                >
                  {/* <Accordion.Title active={activeAccordionIndex === 0} index={0} onClick={this.handleAccordionClick}>
                  <Icon name="dropdown" /> */}
                  Notes
                </Accordion.Header>
                <Accordion.Body>
                  <Notes noteType="Labs" />
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            {canViewStandingOrderTile && <StandingLabOrder />}
            {canViewLabOrders && (
              <PendingOrdersTile
                canAddLabOrders={canAddLabOrders}
                canCreateStandingLabOrders={canCreateStandingLabOrders}
                canCancelSubmittedLabOrders={canCancelSubmittedLabOrders}
              />
            )}
          </Col>
        </Row>
      </Container>
    );
  }
}

function mapStateToProps(state) {
  const labsVisit = state.visits.contextVisit;
  const labsVisitId = labsVisit ? labsVisit.visitId : null;
  let vitalsSurvey = null;
  let cowsSurvey = null;

  const maTemplate = state.templates.specificTemplates[`${labsVisitId || ""}_${TEMPLATE_ID_MA_TEMPLAE_ID}`];
  if (maTemplate) {
    vitalsSurvey = maTemplate.surveys.find((survey) => survey.id === SURVEY_ID_VITALS_SURVEY_ID);
    cowsSurvey = maTemplate.surveys.find((survey) => survey.id === SURVEY_ID_COWS_SURVEY_ID);
  }

  return {
    patientId: state.patient.currentPatient.patientId,
    labsVisitId,
    labsVisitType: labsVisit ? labsVisit.visitType : null,
    labsVisitStatus: labsVisit ? labsVisit.status : null,
    vitalsSurvey,
    cowsSurvey,
    authRoles: state.auth.user.profile.roles,
    protocolOrderSets: state.labs.protocolOrderSets,
    hiddenSurveys: state.templates.hiddenSurveys,
    reloadTemplate: state.templates.reloadTemplate,
    visitTypes: state.lookups.visitTypes
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...labActions, ...modalActions, ...contextActions, ...templateActions }, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(emrComponent(LabsPage));
