/* eslint-disable react/react-in-jsx-scope */
import React from "react";
import moment from "moment";
import { toast } from "react-toastify";
import { toastErrorOptions } from "../../constants/toastconfig";
import { dateWithFullTimeFormat } from "../../constants/miscellaneous";
import { pullOrchestraItem } from "../../helpers";

const handleErrors = message => {
  const orchestraItem = pullOrchestraItem(message.Content.orchestrationId);
  // for now we will only handle messages linked to orchestration Ids we add to the list window.orchestra
  if (orchestraItem) {
    if (orchestraItem.errorHandler && typeof orchestraItem.errorHandler === "function") {
      orchestraItem.errorHandler({ ...orchestraItem.payload, ...message.Content });
    }
    toast.error(
      <span>
        <div>
          {`${message.Content.errorType}`}
          <small style={{ float: "right", marginRight: "10px" }}>
            {moment()
              .utc()
              .format(dateWithFullTimeFormat)}
          </small>
        </div>
        {message.Content.errorUserMessage}
        <br />
      </span>,
      toastErrorOptions
    );
  }
};

const handleGeneralError = message => {
  handleErrors(message);
};

const handleBusinessViolationError = message => {
  handleErrors(message);
};

export default {
  handleGeneralError,
  handleBusinessViolationError
};
