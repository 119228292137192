import React, { Component } from "react";
import { Link } from "react-router-dom";
import ReactDOMServer from "react-dom/server";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { DateInput } from "semantic-ui-calendar-react";
import { Button, Checkbox, Grid, Icon, Message, Progress, Table } from "semantic-ui-react";
import moment from "moment";
import { cloneDeep, get, isEmpty, isEqual, set } from "lodash";
import * as visitActions from "../../../actions/visitActions";
import * as contextActions from "../../../actions/contextActions";
import * as lookupActions from "../../../actions/lookupActions";
import * as patientExportActions from "../../../actions/patientExportActions";
import { dateFormat, dateWithTimeFormat, isoFormat, visitSummarySections } from "../../../constants/miscellaneous";
import VisitSummaryPrint from "../visits/VisitSummaryPrint";
import VisitSummaryPrintLoading from "../visits/VisitSummaryPrintLoading";
import NoteSlashTaskCompactView from "../../common/tasks/NoteSlashTaskCompactView";
import { checkRoles, getLookupText } from "../../../helpers";
import { roleNames } from "../../../constants/securityRoles";
import { PrescriptionHeader, PrescriptionRow } from "../prescriptions/PrescriptionsSent";
import prescriptionStatus from "../../../constants/prescriptionStatus";

class IExportPatient extends Component {
  constructor(props) {
    super(props);
    const initInclude = [
      visitSummarySections.SURVEYS,
      visitSummarySections.TELEHEALTH,
      visitSummarySections.AMENDMENTS,
      visitSummarySections.PRESCRIPTIONS,
      visitSummarySections.LAB_ORDERS,
      visitSummarySections.LAB_RESULTS,
      visitSummarySections.LAB_PRIOR_RESULTS
    ];
    this.state = {
      visitTypeOptions: [],
      visitOptions: [],
      prescriptionStatusOptions: Object.keys(prescriptionStatus).map((key) => ({
        text: prescriptionStatus[key],
        value: prescriptionStatus[key] // this is what we save in the DB .. really !
      })),
      filter: {
        from: moment().subtract(3, "month").format(isoFormat),
        to: moment().format(isoFormat),
        selectedTypes: [],
        groupByType: true,
        visits: {
          onlySignedVisits: true,
          selectedVisitTypeGroups: [],
          selectedVisitTypes: [],
          selectedVisitStatuses: ["visitcomplete"],
          selectedVisitIds: [],
          include: initInclude,
          includeAfterFetch: initInclude
        },
        tasks: {
          nstSelectedTypes: [],
          nstSelectedStatuses: [],
          nstIncludeDeleted: false
        },
        prescriptions: {
          selectedPrescriptionStatuses: []
        }
      },
      print: []
    };
  }

  componentDidMount() {
    this._isMounted = true;
    const {
      match: {
        params: { patientId }
      }
    } = this.props;

    this.props.actions.setContext("patient", { patientId });

    this.props.actions.loadVisitTypes();
    this.props.actions.loadVisitTypeGroups();
    this.props.actions.loadReactions();
    this.props.actions.loadRelationships();
    this.props.actions.loadVisitTypes();
    this.props.actions.loadTaskRoles();
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      match: {
        params: { patientId }
      },
      patientExport
    } = this.props;

    const {
      filter: {
        selectedTypes,
        visits: { selectedVisitTypeGroups, selectedVisitTypes },
        from,
        to
      }
    } = this.state;

    if (!selectedTypes.includes("visits") && prevState.filter.selectedTypes.includes("visits")) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        visitOptions: [],
        filter: {
          ...this.state.filter,
          visits: {
            ...this.state.filter.visits,
            selectedVisitTypeGroups: [],
            selectedVisitTypes: [],
            selectedVisitIds: []
          }
        }
      });
    }

    if (!isEqual(selectedVisitTypeGroups, prevState.filter.visits.selectedVisitTypeGroups)) {
      const updatedVisitTypeOptions = [
        ...new Set(
          Array.prototype.concat.apply(
            [],
            this.props.visitTypeGroupOptions
              .filter((vtgo) => selectedVisitTypeGroups.includes(vtgo.groupName))
              .map((vtgo) => vtgo.visitTypes)
          )
        )
      ].sort();
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        visitTypeOptions: updatedVisitTypeOptions
      });
    }

    if (
      !isEqual(from, prevState.filter.from) ||
      !isEqual(to, prevState.filter.to) ||
      !isEqual(selectedVisitTypes, prevState.filter.visits.selectedVisitTypes)
    ) {
      if (selectedTypes.includes("visits") && !isEmpty(selectedVisitTypes))
        this.props.actions
          .fetchPatientVisits(patientId, {
            from,
            to,
            visitTypes: selectedVisitTypes,
            visitStatuses: ["visitcomplete"],
            onlySigned: true
          })
          .then((data) => {
            const updatedVisitOptions = data.visits?.items.sort((a, b) =>
              a.visitTime > b.visitTime ? 1 : a.visitTime < b.visitTime ? -1 : 0
            );
            this.setState({
              visitOptions: updatedVisitOptions,
              filter: {
                ...this.state.filter,
                visits: {
                  ...this.state.filter.visits,
                  selectedVisitIds: this.state.filter.visits.selectedVisitIds.filter((visitId) =>
                    updatedVisitOptions.map((_v) => _v.visitId).includes(visitId)
                  )
                }
              }
            });
          });
      else if (selectedTypes.includes("visits")) {
        this.setState({
          visitOptions: [],
          filter: {
            ...this.state.filter,
            visits: {
              ...this.state.filter.visits,
              selectedVisitIds: []
            }
          }
        });
      }
    }

    if (!selectedTypes.includes("prescriptions") && prevState.filter.selectedTypes.includes("prescriptions")) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        filter: {
          ...this.state.filter,
          prescriptions: { ...this.state.filter.prescriptions, selectedPrescriptionStatuses: [] }
        }
      });
    }

    // this is a hack to delay the removal of not included sections after user presses build .. to force backend log
    if (!isEqual(patientExport, prevProps.patientExport)) {
      this.setState({
        filter: {
          ...this.state.filter,
          visits: { ...this.state.filter.visits, includeAfterFetch: this.state.filter.visits.include }
        }
      });
      this.updatePrintList();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.props.actions.setContext("patient", { patientId: null });
  }

  _isMounted = false;

  updatePrintList = () => {
    const { patientExport } = this.props;
    const {
      filter: { selectedTypes }
    } = this.state;

    let print = [];

    // visits .. restructure data from graphql to match old structure used in current ui components
    let _visits = [];
    if (!selectedTypes.includes("visits")) _visits = [];
    else
      _visits =
        patientExport.visits?.items?.map((i) => {
          const allergyHistory = [];
          if (i.medicalAllergyHistory)
            i.medicalAllergyHistory.forEach((item) =>
              allergyHistory.push({ itemId: item.id, allergySource: item.allergySource })
            );
          if (i.nonMedicalAllergyHistory)
            i.nonMedicalAllergyHistory.forEach((item) =>
              allergyHistory.push({ itemId: item.id, allergySource: item.allergySource })
            );
          return {
            ...i,
            patient: { ...i.patient },
            type: "visit",
            date: moment(i.visitTime).format(isoFormat),
            data: {
              ctx: "SUMMARY",
              summary: {
                visitId: i.visitId,
                visitHeader: {
                  chiefComplaint: i.visitType,
                  visitDate: i.visitTime,
                  patient: { ...i.patient, dateOfBirth: moment.utc(i.patient.dateOfBirth).format(dateWithTimeFormat) },
                  center: { ...i.center },
                  provider: {
                    providerId: i.visitProviderId,
                    firstName: i.providerFirstName,
                    lastName: i.providerLastName
                  }
                },
                surveys: i.surveyResults ? [...i.surveyResults] : []
              },
              amendments: i.amendments
                ? i.amendments.map((a) => ({
                    ...a,
                    createdOn: a.createdDate,
                    createdBy: { firstName: a.createdByFirstName, lastName: a.createdByLastName }
                  }))
                : [],
              details: i.signedByProviderId
                ? {
                    dateSigned: i.signedOn,
                    signedBy: i.signedByProviderId,
                    providerFullName: `${i.signedByProviderFirstName} ${i.signedByProviderLastName}`
                  }
                : {},
              cosignDetail: i.coSignedByProviderId
                ? {
                    dateSigned: i.coSignedOn,
                    signedBy: i.coSignedByProviderId,
                    supervisingProviderFullName: `${i.coSignedByProviderFirstName} ${i.coSignedByProviderLastName}`
                  }
                : {},
              labOrdersAndResults: {
                labOrders: i.labOrders
                  ? i.labOrders.map((o) => ({ ...o, confirmatoryLabs: [...o.confirmatoryLabOrders] }))
                  : [],
                priorVisitLabResults: i.priorLabResults
                  ? i.priorLabResults.map((r) => ({
                      ...r,
                      sampleDate: r.sampleCollectionTime,
                      results: (r.childLabResults || []).map((cr) => ({ ...cr, test: cr.resultLabName }))
                    }))
                  : [],
                visitLabResults: i.labResults
                  ? i.labResults.map((r) => ({
                      ...r,
                      sampleDate: r.sampleCollectionTime,
                      results: (r.childLabResults || []).map((cr) => ({ ...cr, test: cr.resultLabName }))
                    }))
                  : []
              },
              patientHistory: {
                allergyHistory,
                medicationHistory: i.medicationHistory
                  ? i.medicationHistory.map((item) => ({ ...item, itemId: item.id }))
                  : [],
                medicalHistory: i.nonResolvedMedicalHistory
                  ? i.nonResolvedMedicalHistory.map((item) => ({ ...item, itemId: item.id }))
                  : [],
                interventionHistory: [], // based on #33039 we should not include this in print or export
                familyHistory: i.familyHistory ? i.familyHistory.map((item) => ({ ...item, itemId: item.id })) : []
              },
              prescriptionsSentByVisit: i.prescriptions
                ? i.prescriptions.map((p) => ({
                    ...p,
                    rxId: p.prescription.platformId,
                    rxSentDate: p.prescription.sentDate,
                    prescriberFirstName: p.prescriber.firstName,
                    prescriberLastName: p.prescriber.lastName,
                    prescriberTitle: p.prescriberDetails.title,
                    drugName: p.prescription.drugName,
                    rxStrength: p.prescription.strength,
                    rxDosage: p.prescription.dosage,
                    rxForm: p.prescription.form,
                    rxFrequency: p.prescription.frequency,
                    rxNoteToPharmacist: p.prescription.noteToPharmacist
                  }))
                : [],
              signHistory: i.signHistory
                ? i.signHistory.map((item) => ({
                    ...item,
                    createdDate: item.createdDate,
                    providerFullName: `${item.providerFirstName} ${item.providerLastName}`
                  }))
                : [],
              visitLocations: {
                isPatientHome: i.isPatientHome,
                patientCenterId: i.patientCenterId,
                isProviderHome: i.isProviderHome,
                providerCenterId: i.providerCenterId,
                visitSpecimenCenterId: i.visitSpecimenCenterId,
                sampleCollectionTime: i.labOrderStatus?.sampleCollectionTime,
                facilitatedBy: i.facilitatedBy
              },
              visitType: i.visitType
            }
          };
        }) || [];
    print = [...print, ..._visits];

    // tasks
    let _tasks = [];
    if (!selectedTypes.includes("tasks")) _tasks = [];
    else
      _tasks =
        patientExport.tasks?.items?.map((i) => ({
          type: "task",
          date: moment(i.task.dueDate).format(isoFormat),
          entityId: i.task.platformId,
          data: {
            ...i.task,
            assignee: {
              assignedCenter: i.task.assignedCenter,
              assignedUserId: i.task.assignedUserId,
              assignedUserFirstName: i.task.assignedUserFirstName,
              assignedUserLastName: i.task.assignedUserLastName,
              assignedRole: i.task.assignedRole
            },
            comments: (i.taskComments || []).map((c) => ({ ...c, platformId: c.id }))
          }
        })) || [];
    print = [...print, ..._tasks];

    // prescriptions
    let _prescriptions = [];
    if (!selectedTypes.includes("prescriptions")) _prescriptions = [];
    else
      _prescriptions =
        patientExport.prescriptions?.items?.map((i) => ({
          type: "prescription",
          date: moment(i.prescriptionTransaction.timeStamp).format(isoFormat),
          prescriptionId: i.prescription.platformId,
          data: {
            ...i.prescriptionTransaction,
            message: i.prescriptionTransaction.status,
            prescriptionId: i.prescription.id,
            transactionId: i.prescriptionTransaction.id,
            dateSent: i.prescription.sentDate,
            prescriberFirstName: i.prescriber.firstName,
            prescriberLastName: i.prescriber.lastName,
            quantity: i.prescription.quantity,
            drugDescription: i.prescription.drugDescription,
            specialInstructions: i.prescription.specialInstructions,
            refills: i.prescription.refills,
            status: i.prescription.status,
            sendingMethod: i.prescriptionTransaction.sendingMethod,
            isMigration: i.prescription.isMigration,
            timeStamp: i.prescriptionTransaction.timeStamp,
            pharmacy: i.pharmacy
              ? {
                  ...i.pharmacy,
                  specialties: i.pharmacy?.specialties ? i.pharmacy?.specialties.split(",") : [],
                  ePrescribeServices: i.pharmacy?.ePrescribeServices ? i.pharmacy?.ePrescribeServices.split(",") : []
                }
              : null
          }
        })) || [];
    print = [...print, ..._prescriptions];

    // same for labs

    // same for treatment plans

    print = print.sort((a, b) => (a.date > b.date ? 1 : a.date < b.date ? -1 : 0));

    this.setState({ print });
  };

  handleInput = (e, data) => {
    const updatedState = cloneDeep(this.state);
    let value = data.value;
    if (data.name === "filter.from") {
      value = isEmpty(value) ? `` : moment(value, dateFormat).startOf("day");
    }
    if (data.name === "filter.to") {
      value = isEmpty(value) ? `` : moment(value, dateFormat).endOf("day");
    }
    set(updatedState, data.name, value);
    if (this._isMounted) this.setState(updatedState);
  };

  handleCheckbox = (e, data) => {
    const updatedState = cloneDeep(this.state);
    const field = get(updatedState, data.name);
    if (Array.isArray(field)) {
      let updatedField = [...field];
      if (!field.includes(data.value) && data.checked) {
        updatedField = [...field, data.value];
      }
      if (field.includes(data.value) && !data.checked) {
        updatedField.splice(updatedField.indexOf(data.value), 1);
      }
      set(updatedState, data.name, updatedField);
    } else {
      set(updatedState, data.name, data.checked);
    }

    if (
      data.name === "filter.tasks.nstSelectedTypes" &&
      (updatedState.filter.tasks.nstSelectedTypes.length !== 1 ||
        updatedState.filter.tasks.nstSelectedTypes.includes("notes"))
    ) {
      updatedState.filter.tasks.nstSelectedStatuses = [];
    }

    this.setState(updatedState);
  };

  handleSelectAllVisitTypeGroups = (e, data) => {
    const { visitTypeGroupOptions } = this.props;
    this.setState({
      filter: {
        ...this.state.filter,
        visits: {
          ...this.state.filter.visits,
          selectedVisitTypeGroups: data.checked ? visitTypeGroupOptions.map((o) => o.groupName) : []
        }
      }
    });
  };

  handleSelectAllPrescriptionStatuses = (e, data) => {
    const { prescriptionStatusOptions } = this.state;
    this.setState({
      filter: {
        ...this.state.filter,
        prescriptions: {
          ...this.state.filter.visits,
          selectedPrescriptionStatuses: data.checked ? prescriptionStatusOptions.map((o) => o.value) : []
        }
      }
    });
  };

  handleSelectAllVisitTypes = (e, data) => {
    const { visitTypeOptions } = this.state;
    this.setState({
      filter: {
        ...this.state.filter,
        visits: {
          ...this.state.filter.visits,
          selectedVisitTypes: data.checked ? visitTypeOptions.map((o) => o) : []
        }
      }
    });
  };

  handleSelectAllVisits = (e, data) => {
    const { visitOptions } = this.state;
    this.setState({
      filter: {
        ...this.state.filter,
        visits: {
          ...this.state.filter.visits,
          selectedVisitIds: data.checked ? visitOptions.map((o) => o.visitId) : []
        }
      }
    });
  };

  handleVisitInclude = (checked, item) => {
    if (item === visitSummarySections.HIST_ALL)
      this.setState({
        filter: {
          ...this.state.filter,
          visits: {
            ...this.state.filter.visits,
            include: checked
              ? [
                  ...new Set([
                    ...this.state.filter.visits.include,
                    visitSummarySections.HIST_ALLERGIES,
                    visitSummarySections.HIST_FAMILY_HISTORY,
                    visitSummarySections.HIST_MEDICAL_HISTORY,
                    visitSummarySections.HIST_MEDICATIONS
                  ])
                ]
              : this.state.filter.visits.include.filter(
                  (i) =>
                    ![
                      visitSummarySections.HIST_ALLERGIES,
                      visitSummarySections.HIST_FAMILY_HISTORY,
                      visitSummarySections.HIST_MEDICAL_HISTORY,
                      visitSummarySections.HIST_MEDICATIONS
                    ].includes(i)
                )
          }
        }
      });
    else if (item === visitSummarySections.LAB_ALL)
      this.setState({
        filter: {
          ...this.state.filter,
          visits: {
            ...this.state.filter.visits,
            include: checked
              ? [
                  ...new Set([
                    ...this.state.filter.visits.include,
                    visitSummarySections.LAB_ORDERS,
                    visitSummarySections.LAB_RESULTS,
                    visitSummarySections.LAB_PRIOR_RESULTS
                  ])
                ]
              : this.state.filter.visits.include.filter(
                  (i) =>
                    ![
                      visitSummarySections.LAB_ORDERS,
                      visitSummarySections.LAB_RESULTS,
                      visitSummarySections.LAB_PRIOR_RESULTS
                    ].includes(i)
                )
          }
        }
      });
    else
      this.setState({
        filter: {
          ...this.state.filter,
          visits: {
            ...this.state.filter.visits,
            include: checked
              ? [...this.state.filter.visits.include, item]
              : this.state.filter.visits.include.filter((i) => i !== item)
          }
        }
      });
  };

  handleBuild = () => {
    const {
      match: {
        params: { patientId }
      },
      actions: { fetchPatientExportData }
    } = this.props;
    const { filter } = this.state;
    fetchPatientExportData(patientId, filter);
  };

  handlePrint = () => {
    const printWindow = window.open();
    const loadingBody = ReactDOMServer.renderToString(<VisitSummaryPrintLoading />);
    const head = document.head.innerHTML;
    printWindow.document.write(`${head}${loadingBody}`);
    printWindow.addEventListener("load", () => {
      printWindow.print();
    });
    const bodyPrint = ReactDOMServer.renderToString(this.renderPreview());
    printWindow.document.body.getElementsByClassName("zontainer")[0].remove();
    printWindow.document.write(bodyPrint);
    printWindow.document.close();
  };

  renderItem = (item) => {
    const {
      authRoles,
      match: {
        params: { patientId }
      },
      reactionTypes,
      relationships,
      visitTypes,
      centerOptions,
      roleOptions
    } = this.props;

    const {
      visitOptions,
      filter: {
        visits: { includeAfterFetch }
      }
    } = this.state;

    switch (item.type) {
      case "visit":
        return (
          <div
            key={item.visitId}
            className={`print visit-container full-padding`}
            style={{ borderBottom: "1px solid #ddd" }}
          >
            {item.loading ? (
              <span>Loading...</span>
            ) : !item.visitId === null ? (
              (() => {
                const visit = visitOptions.find((v) => v.visitId === item.visitId);
                return (
                  <React.Fragment>
                    <h3>
                      Visit
                      <div style={{ display: "inline-block", float: "right", fontWeight: "normal", fontSize: "12px" }}>
                        DOS: {moment(visit.visitTime).format(dateFormat)}
                      </div>
                    </h3>
                    <hr />
                    <Message color="red">
                      {`${moment(visit.visitTime).format(dateWithTimeFormat)} - ${getLookupText(
                        visit.visitType,
                        this.props.visitTypeOptions
                      )} - ${visit.providerFirstName} ${visit.providerLastName}`}

                      <br />
                      {`Null visit header!`}
                    </Message>
                  </React.Fragment>
                );
              })()
            ) : (
              <React.Fragment>
                <h3>
                  Visit
                  <div style={{ display: "inline-block", float: "right", fontWeight: "normal", fontSize: "12px" }}>
                    MRN: {item.patient.medicalRecordNumber}
                    {`, `}
                    DOS: {moment(item.visitTime).format(dateFormat)}
                  </div>
                </h3>
                <hr />
                <VisitSummaryPrint
                  dataVisitSummary={[item]}
                  authRoles={authRoles}
                  patientId={patientId}
                  reactionTypes={reactionTypes}
                  relationships={relationships}
                  visitTypes={visitTypes}
                  visitSummaryLocations={item.data.visitLocations}
                  centerOptions={centerOptions}
                  include={includeAfterFetch}
                />
              </React.Fragment>
            )}
          </div>
        );
      case "task":
        return (
          <div
            key={item.entityId}
            className={`print ${item.data.type.toLowerCase()}-container full-padding`}
            style={{ borderBottom: "1px solid #ddd" }}
          >
            <h3>{item.data.type}</h3>
            <hr />
            <NoteSlashTaskCompactView
              entity={item.data}
              roleUserOptions={{}}
              centerOptions={centerOptions}
              roleOptions={roleOptions}
            />
          </div>
        );
      case "prescription":
        return (
          <div
            key={item.prescriptionId}
            className={`print prescription-container full-padding`}
            style={{ borderBottom: "1px solid #ddd" }}
          >
            <h3>Prescription</h3>
            <hr />
            <Table padded>
              {<PrescriptionHeader />}
              <Table.Body>
                <PrescriptionRow key={item.data.prescriptionId} rx={item.data} />
              </Table.Body>
            </Table>
          </div>
        );

      default:
        return null;
    }
  };

  renderPreview = () => {
    const {
      print,
      filter: { selectedTypes, groupByType }
    } = this.state;
    if (groupByType) {
      const visits = print
        .filter((i) => i.type === "visit")
        .sort((a, b) => (a.date > b.date ? 1 : a.date < b.date ? -1 : 0));
      const tasks = print
        .filter((i) => i.type === "task")
        .sort((a, b) => (a.date > b.date ? 1 : a.date < b.date ? -1 : 0));
      const prescriptions = print
        .filter((i) => i.type === "prescription")
        .sort((a, b) => (a.date > b.date ? 1 : a.date < b.date ? -1 : 0));
      return (
        <div>
          {visits.map((item) => this.renderItem(item))}
          {tasks.map((item) => this.renderItem(item))}
          {selectedTypes.includes("prescriptions") && (
            <div className={`print prescription-container full-padding`} style={{ borderBottom: "1px solid #ddd" }}>
              <h3>Prescriptions</h3>
              <hr />
              <Table padded>
                {<PrescriptionHeader />}
                <Table.Body>
                  {prescriptions.map((item) => (
                    <PrescriptionRow key={item.data.prescriptionId} rx={item.data} />
                  ))}
                </Table.Body>
              </Table>
            </div>
          )}
        </div>
      );
    }
    return <div>{print.map((item) => this.renderItem(item))}</div>;
  };

  render() {
    const {
      processing,
      visitTypeGroupOptions,
      match: {
        params: { patientId }
      }
    } = this.props;
    const {
      visitTypeOptions,
      visitOptions,
      prescriptionStatusOptions,
      filter: {
        selectedTypes,
        groupByType,
        visits: { selectedVisitTypeGroups, selectedVisitTypes, selectedVisitIds, include },
        tasks: { nstSelectedTypes, nstSelectedStatuses, nstIncludeDeleted },
        prescriptions: { selectedPrescriptionStatuses },
        from,
        to
      }
    } = this.state;

    const canExportTasks = checkRoles([roleNames.systemAdmin, roleNames.centerManager], this.props.authRoles);
    const canExportPrescriptions = checkRoles([roleNames.systemAdmin, roleNames.centerManager], this.props.authRoles);

    const allVisitTypeGroupsSelected =
      visitTypeGroupOptions.length > 0 &&
      visitTypeGroupOptions.every((o) => selectedVisitTypeGroups.includes(o.groupName));
    const allVisitTypesSelected =
      visitTypeOptions.length > 0 && visitTypeOptions.every((o) => selectedVisitTypes.includes(o));
    const allVisitsSelected =
      visitOptions.length > 0 && visitOptions.every((o) => selectedVisitIds.includes(o.visitId));

    const includePatientHistory =
      include.includes(visitSummarySections.HIST_ALLERGIES) &&
      include.includes(visitSummarySections.HIST_FAMILY_HISTORY) &&
      include.includes(visitSummarySections.HIST_MEDICAL_HISTORY) &&
      include.includes(visitSummarySections.HIST_MEDICATIONS);

    const includeAllLab =
      include.includes(visitSummarySections.LAB_ORDERS) &&
      include.includes(visitSummarySections.LAB_RESULTS) &&
      include.includes(visitSummarySections.LAB_PRIOR_RESULTS);

    const allPrescriptionStatusesSelected =
      prescriptionStatusOptions.length > 0 &&
      prescriptionStatusOptions.every((o) => selectedPrescriptionStatuses.includes(o.value));

    return (
      <div className="content-wrapper labs-content">
        <Grid style={{ backgroundColor: "#fff" }}>
          <Grid.Row className="no-padding">
            <Grid.Column mobile={16} tablet={16} computer={8} style={{ borderRight: "1px solid #ddd" }}>
              <Grid className="full-v-padding">
                <Grid.Row>
                  <Grid.Column width={16}>
                    <Message color="olive">
                      <Link to={`/current-patients/${patientId}/demographics`}>
                        <Icon name="arrow alternate circle left" />
                        {`Back to patient's chart`}
                      </Link>
                    </Message>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={8}>
                    <DateInput
                      label="Date From"
                      name="filter.from"
                      placeholder="Date From"
                      value={isEmpty(from) ? "" : moment(from).format(dateFormat)}
                      dateFormat={dateFormat}
                      iconPosition="right"
                      onChange={this.handleInput}
                      hideMobileKeyboard
                      closable
                      clearable
                      className="cs-field"
                    />
                  </Grid.Column>
                  <Grid.Column width={8}>
                    <DateInput
                      label="Date To"
                      name="filter.to"
                      placeholder="Date To"
                      value={isEmpty(to) ? "" : moment(to).format(dateFormat)}
                      dateFormat={dateFormat}
                      iconPosition="right"
                      onChange={this.handleInput}
                      hideMobileKeyboard
                      closable
                      clearable
                      className="cs-field"
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={16} textAlign="right" className="field cs-field">
                    <label>Group by type</label>
                    <Checkbox
                      toggle
                      style={{ marginBottom: "-0.3rem" }}
                      name="filter.groupByType"
                      checked={groupByType}
                      onChange={this.handleCheckbox}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row className="half-v-padding">
                  <Grid.Column width={16}>
                    <Checkbox
                      key={"visits"}
                      name="filter.selectedTypes"
                      label={"Visits"}
                      value={"visits"}
                      checked={selectedTypes.includes("visits")}
                      onChange={this.handleCheckbox}
                      style={{ display: "block", marginTop: ".5em", marginRight: ".5em" }}
                    />
                  </Grid.Column>
                </Grid.Row>
                {selectedTypes.includes("visits") && (
                  <>
                    <Grid.Row className="half-v-padding">
                      <Grid.Column width={4} style={{ paddingLeft: "2em" }}>
                        <Checkbox
                          label={`Select All`}
                          checked={allVisitTypeGroupsSelected}
                          onChange={this.handleSelectAllVisitTypeGroups}
                          style={{ display: "block", marginTop: ".5em", marginRight: ".5em" }}
                        />
                        {visitTypeGroupOptions.map((option) => (
                          <Checkbox
                            key={option.id}
                            name="filter.visits.selectedVisitTypeGroups"
                            value={option.groupName}
                            label={option.groupName}
                            checked={selectedVisitTypeGroups.includes(option.groupName)}
                            onChange={this.handleCheckbox}
                            style={{ display: "block", marginTop: ".5em", marginRight: ".5em" }}
                          />
                        ))}
                      </Grid.Column>
                      <Grid.Column width={4}>
                        <Checkbox
                          label={`Select All`}
                          checked={allVisitTypesSelected}
                          onChange={this.handleSelectAllVisitTypes}
                          style={{ display: "block", marginTop: ".5em", marginRight: ".5em" }}
                        />
                        {visitTypeOptions.map((option) => (
                          <Checkbox
                            key={option}
                            name="filter.visits.selectedVisitTypes"
                            value={option}
                            label={getLookupText(option, this.props.visitTypeOptions)}
                            checked={selectedVisitTypes.includes(option)}
                            onChange={this.handleCheckbox}
                            style={{ display: "block", marginTop: ".5em", marginRight: ".5em" }}
                          />
                        ))}
                      </Grid.Column>
                      <Grid.Column width={8}>
                        <Checkbox
                          label={`Select All`}
                          checked={allVisitsSelected}
                          onChange={this.handleSelectAllVisits}
                          style={{ display: "block", marginTop: ".5em", marginRight: ".5em" }}
                        />
                        {visitOptions.map((visit) => (
                          <Checkbox
                            key={visit.visitId}
                            name="filter.visits.selectedVisitIds"
                            value={visit.visitId}
                            label={`${moment(visit.visitTime).format(dateWithTimeFormat)} - ${getLookupText(
                              visit.visitType,
                              this.props.visitTypeOptions
                            )} - ${visit.providerFirstName} ${visit.providerLastName}`}
                            checked={selectedVisitIds.includes(visit.visitId)}
                            onChange={this.handleCheckbox}
                            style={{ marginTop: ".5em", marginRight: ".5em" }}
                          />
                        ))}
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row className="no-padding" style={{ marginTop: ".5rem" }}>
                      <Grid.Column>
                        <h4>Include</h4>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row className="no-padding">
                      <Grid.Column width={5}>
                        <Checkbox
                          label={`History`}
                          checked={includePatientHistory}
                          onChange={(_, data) => this.handleVisitInclude(data.checked, visitSummarySections.HIST_ALL)}
                          style={{ display: "block", marginTop: ".5em", marginRight: ".5em" }}
                        />
                        <Checkbox
                          label={`Allergies`}
                          checked={include.includes(visitSummarySections.HIST_ALLERGIES)}
                          onChange={(_, data) => {
                            this.handleVisitInclude(data.checked, visitSummarySections.HIST_ALLERGIES);
                          }}
                          style={{ display: "block", paddingLeft: "1rem", marginTop: ".5em", marginRight: ".5em" }}
                        />
                        <Checkbox
                          label={`Medications`}
                          checked={include.includes(visitSummarySections.HIST_MEDICATIONS)}
                          onChange={(_, data) => {
                            this.handleVisitInclude(data.checked, visitSummarySections.HIST_MEDICATIONS);
                          }}
                          style={{ display: "block", paddingLeft: "1rem", marginTop: ".5em", marginRight: ".5em" }}
                        />
                        <Checkbox
                          label={`Medical History`}
                          checked={include.includes(visitSummarySections.HIST_MEDICAL_HISTORY)}
                          onChange={(_, data) => {
                            this.handleVisitInclude(data.checked, visitSummarySections.HIST_MEDICAL_HISTORY);
                          }}
                          style={{ display: "block", paddingLeft: "1rem", marginTop: ".5em", marginRight: ".5em" }}
                        />
                        <Checkbox
                          label={`Family History`}
                          checked={include.includes(visitSummarySections.HIST_FAMILY_HISTORY)}
                          onChange={(_, data) => {
                            this.handleVisitInclude(data.checked, visitSummarySections.HIST_FAMILY_HISTORY);
                          }}
                          style={{ display: "block", paddingLeft: "1rem", marginTop: ".5em", marginRight: ".5em" }}
                        />
                      </Grid.Column>
                      <Grid.Column width={5}>
                        <Checkbox
                          label={`Labs`}
                          checked={includeAllLab}
                          onChange={(_, data) => this.handleVisitInclude(data.checked, visitSummarySections.LAB_ALL)}
                          style={{ display: "block", marginTop: ".5em", marginRight: ".5em" }}
                        />
                        <Checkbox
                          label={`Orders`}
                          checked={include.includes(visitSummarySections.LAB_ORDERS)}
                          onChange={(_, data) => {
                            this.handleVisitInclude(data.checked, visitSummarySections.LAB_ORDERS);
                          }}
                          style={{ display: "block", paddingLeft: "1rem", marginTop: ".5em", marginRight: ".5em" }}
                        />
                        <Checkbox
                          label={`Results`}
                          checked={include.includes(visitSummarySections.LAB_RESULTS)}
                          onChange={(_, data) => {
                            this.handleVisitInclude(data.checked, visitSummarySections.LAB_RESULTS);
                          }}
                          style={{ display: "block", paddingLeft: "1rem", marginTop: ".5em", marginRight: ".5em" }}
                        />
                        <Checkbox
                          label={`Prior visit's results`}
                          checked={include.includes(visitSummarySections.LAB_PRIOR_RESULTS)}
                          onChange={(_, data) => {
                            this.handleVisitInclude(data.checked, visitSummarySections.LAB_PRIOR_RESULTS);
                          }}
                          style={{ display: "block", paddingLeft: "1rem", marginTop: ".5em", marginRight: ".5em" }}
                        />
                      </Grid.Column>
                      <Grid.Column width={5}>
                        <Checkbox
                          label={`Surveys`}
                          checked={include.includes(visitSummarySections.SURVEYS)}
                          onChange={(_, data) => {
                            this.handleVisitInclude(data.checked, visitSummarySections.SURVEYS);
                          }}
                          style={{ marginTop: ".5em", marginRight: ".5em" }}
                        />
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column width={5}>
                        <Checkbox
                          label={`Amendments`}
                          checked={include.includes(visitSummarySections.AMENDMENTS)}
                          onChange={(_, data) => {
                            this.handleVisitInclude(data.checked, visitSummarySections.AMENDMENTS);
                          }}
                          style={{ marginTop: ".5em", marginRight: ".5em" }}
                        />
                      </Grid.Column>
                      <Grid.Column width={5}>
                        <Checkbox
                          label={`Prescriptions`}
                          checked={include.includes(visitSummarySections.PRESCRIPTIONS)}
                          onChange={(_, data) => {
                            this.handleVisitInclude(data.checked, visitSummarySections.PRESCRIPTIONS);
                          }}
                          style={{ marginTop: ".5em", marginRight: ".5em" }}
                        />
                      </Grid.Column>
                      <Grid.Column width={5}>
                        {" "}
                        <Checkbox
                          label={`Telehealth`}
                          checked={include.includes(visitSummarySections.TELEHEALTH)}
                          onChange={(_, data) => {
                            this.handleVisitInclude(data.checked, visitSummarySections.TELEHEALTH);
                          }}
                          style={{ marginTop: ".5em", marginRight: ".5em" }}
                        />
                      </Grid.Column>
                    </Grid.Row>
                  </>
                )}
                {canExportTasks && (
                  <Grid.Row className="half-v-padding" style={{ borderTop: "1px solid rgba(34,36,38,.15)" }}>
                    <Grid.Column width={16}>
                      <Checkbox
                        key={"tasks"}
                        name="filter.selectedTypes"
                        label={"Tasks"}
                        value={"tasks"}
                        checked={selectedTypes.includes("tasks")}
                        onChange={this.handleCheckbox}
                        style={{ marginTop: ".5em", marginRight: ".5em" }}
                      />
                    </Grid.Column>
                  </Grid.Row>
                )}
                {selectedTypes.includes("tasks") && (
                  <Grid.Row className="half-v-padding">
                    <Grid.Column width={6} style={{ paddingLeft: "2em" }}>
                      <Checkbox
                        name="filter.tasks.nstSelectedTypes"
                        value={"tasks"}
                        label={"Tasks"}
                        checked={nstSelectedTypes.includes("tasks")}
                        onChange={this.handleCheckbox}
                        style={{ marginRight: ".5em" }}
                      />
                      <Checkbox
                        name="filter.tasks.nstSelectedTypes"
                        value={"notes"}
                        label={"Notes"}
                        checked={nstSelectedTypes.includes("notes")}
                        onChange={this.handleCheckbox}
                        style={{ marginRight: ".5em" }}
                      />
                    </Grid.Column>
                    <Grid.Column width={6}>
                      <Checkbox
                        name="filter.tasks.nstSelectedStatuses"
                        value="Opened"
                        checked={nstSelectedTypes.includes("tasks") && nstSelectedStatuses.includes("Opened")}
                        onChange={this.handleCheckbox}
                        label="Open"
                        style={{ marginRight: ".5em" }}
                        disabled={!(nstSelectedTypes.length === 1 && nstSelectedTypes.includes("tasks"))}
                      />
                      <Checkbox
                        name="filter.tasks.nstSelectedStatuses"
                        value="Completed"
                        checked={nstSelectedTypes.includes("tasks") && nstSelectedStatuses.includes("Completed")}
                        onChange={this.handleCheckbox}
                        label="Complete"
                        disabled={!(nstSelectedTypes.length === 1 && nstSelectedTypes.includes("tasks"))}
                      />
                    </Grid.Column>
                    <Grid.Column width={4}>
                      <Checkbox
                        name="filter.tasks.nstIncludeDeleted"
                        label="Include deleted"
                        checked={nstIncludeDeleted}
                        onChange={this.handleCheckbox}
                      />
                    </Grid.Column>
                  </Grid.Row>
                )}

                {canExportPrescriptions && (
                  <Grid.Row className="half-v-padding" style={{ borderTop: "1px solid rgba(34,36,38,.15)" }}>
                    <Grid.Column width={16}>
                      <Checkbox
                        key={"prescriptions"}
                        name="filter.selectedTypes"
                        label={"Prescriptions"}
                        value={"prescriptions"}
                        checked={selectedTypes.includes("prescriptions")}
                        onChange={this.handleCheckbox}
                        style={{ marginTop: ".5em", marginRight: ".5em" }}
                      />
                    </Grid.Column>
                  </Grid.Row>
                )}
                {selectedTypes.includes("prescriptions") && (
                  <Grid.Row className="half-v-padding">
                    <Grid.Column width={4} style={{ paddingLeft: "2em" }}>
                      <Checkbox
                        label={`Select All`}
                        checked={allPrescriptionStatusesSelected}
                        onChange={this.handleSelectAllPrescriptionStatuses}
                        style={{ display: "block", marginTop: ".5em", marginRight: ".5em" }}
                      />
                      {prescriptionStatusOptions.map((option) => (
                        <Checkbox
                          key={option.value}
                          name="filter.prescriptions.selectedPrescriptionStatuses"
                          value={option.value}
                          label={option.text}
                          checked={selectedPrescriptionStatuses.includes(option.value)}
                          onChange={this.handleCheckbox}
                          style={{ display: "block", marginTop: ".5em", marginRight: ".5em" }}
                        />
                      ))}
                    </Grid.Column>
                  </Grid.Row>
                )}

                {/* <Grid.Row className="half-v-padding">
                  <Grid.Column width={16}>
                    <Checkbox
                      key={"treatmentplans"}
                      name="filter.selectedTypes"
                      label={"Treatment Plans"}
                      value={"treatmentplans"}
                      checked={selectedTypes.includes("treatmentplans")}
                      onChange={this.handleCheckbox}
                      style={{ marginTop: ".5em", marginRight: ".5em" }}
                      disabled
                    />
                  </Grid.Column>
                </Grid.Row>

                <Grid.Row className="half-v-padding">
                  <Grid.Column width={16}>
                    <Checkbox
                      key={"labs"}
                      name="filter.selectedTypes"
                      label={"Lab Results"}
                      value={"labs"}
                      checked={selectedTypes.includes("labs")}
                      onChange={this.handleCheckbox}
                      style={{ marginTop: ".5em", marginRight: ".5em" }}
                      disabled
                    />
                  </Grid.Column>
                </Grid.Row> */}
                <Grid.Row className="no-padding full-bottom-padding">
                  <Grid.Column width={16}>
                    <Button
                      icon="cogs"
                      color="grey"
                      content="Build"
                      onClick={this.handleBuild}
                      fluid
                      disabled={processing}
                      style={{ marginBottom: ".5rem" }}
                    />
                    <Button
                      icon="print"
                      color="yellow"
                      content="Print"
                      onClick={this.handlePrint}
                      fluid
                      disabled={processing}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={16} computer={8} className="no-padding">
              {processing && <Progress percent={100} active color="yellow" size="small" />}
              {this.renderPreview()}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    processing: state.ajaxCallsInProgress > 0,
    authRoles: state.auth.user.profile.roles,
    patientExport: state.patientExport,
    visitTypes: state.lookups.visitTypes,
    visitTypeGroupOptions: state.lookups.visitTypeGroups,
    visitStatuses: state.lookups.visitStatuses,
    reactionTypes: state.lookups.reactionTypes,
    relationships: state.lookups.relationships,
    visitTypeOptions: state.lookups.visitTypes.map((i) => ({ text: i.text, value: i.value })),
    centerOptions: state.lookups.centers.map((i) => ({ text: i.text, value: i.value })),
    roleOptions: state.lookups.taskRoles.map((i) => ({ text: i.displayName, value: i.name }))
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      { ...visitActions, ...contextActions, ...lookupActions, ...patientExportActions },
      dispatch
    )
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(IExportPatient);
