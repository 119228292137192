import { setNarxScores } from "../../actions/miscActions";

const store = window.store;

const handlePatientScoresMessage = message => {
  /* {"Type":"@Notification.PatientScores","Title":"Scores","Content":{"scores":[{"key":"Narcotics","value":"000"},{"key":"Stimulants","value":"000"},{"key":"Sedatives","value":"000"},{"key":"Overdose","value":"000"}],"requestedOn":"2021-08-10T22:04:59.0284212+02:00"},"Options":null,"Time":"2021-08-10T22:04:59.3710476+02:00"} */
  store.dispatch(setNarxScores({ ...message.Content, error: false, errorMessage: "" }));
};

export default {
  handlePatientScoresMessage
};
