import React from "react";
import { Table } from "semantic-ui-react";

import "./InsuranceHistoryModal.css";

const InsuranceHistoryRow = ({
  insuranceType,
  insurer,
  policyNumber,
  policyGroup,
  startDate,
  endDate,
  insuredFirstName,
  insuredMiddleNameOrInitial,
  insuredLastName,
  address1,
  address2,
  city,
  state,
  zip,
  zip4,
  dateOfBirth,
  gender,
  relationship,
  mspOptions,
  msp,
  getLookupText,
  createdDate,
  insuranceProviders
}) => (
  <Table.Row>
    <Table.Cell>
      <h4>{`${insuranceType}:`}</h4>
      <h4>{getLookupText(insurer, insuranceProviders)}</h4>
      <Table>
        <Table.Body>
          <Table.Row>
            <Table.Cell className="table-label">Policy #:</Table.Cell>
            <Table.Cell id="lbl-policyNumber">{policyNumber}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell className="table-label">Group #:</Table.Cell>
            <Table.Cell>{policyGroup || "None"}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell className="table-label">Start Date:</Table.Cell>
            <Table.Cell>{startDate || "No start date"}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell className="table-label">End Date:</Table.Cell>
            <Table.Cell>{endDate || "No end date"}</Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </Table.Cell>
    <Table.Cell>
      <div>
        <p>{`${insuredFirstName} ${
          insuredMiddleNameOrInitial || ""
        } ${insuredLastName}`}</p>
        <p>{`${address1} ${address2}`}</p>
        <p>{`${city}, ${state} ${zip} ${zip4}`}</p>
        <p>{`dob: ${dateOfBirth}, gender: ${gender === "Male" ? "M" : "F"}`}</p>
        <p>{`relationship: ${relationship}`}</p>
      </div>
    </Table.Cell>
    <Table.Cell id="lbl-msp">
      {mspOptions && msp ? getLookupText(msp, mspOptions) : "-"}
    </Table.Cell>
    {/* <Table.Cell>{props.changedBy}</Table.Cell> */}
    <Table.Cell id="lbl-createdDate">{createdDate}</Table.Cell>
  </Table.Row>
);

export default InsuranceHistoryRow;
