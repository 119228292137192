import initialState from "./initialState";
import * as types from "../actions/actionTypes";
import { contextualizeSurveys } from "../components/patients/surveys/surveyHelper";

export default function templateReducer(state = initialState.templates, action) {
  switch (action.type) {
    case types.CLEAR_TEMPLATES:
      return {
        contextVisit: null,
        availableTemplates: [],
        selectedTemplate: {
          templateId: null,
          templateName: null,
          version: null
        },
        specificTemplates: {},
        hiddenSurveys: { ...state.hiddenSurveys },
        reloadTemplate: false
      };
    case types.SET_SELECTED_TEMPLATE_DONE:
      return { ...state, selectedTemplate: state.availableTemplates.find((t) => t.templateId === action.templateId) };
    case types.CLEAR_SELECTED_TEMPLATE:
      return {
        ...state,
        selectedTemplate: {
          templateId: null,
          templateName: null,
          version: null
        }
      };
    case types.LOAD_TEMPLATES_SUCCESS:
      return {
        ...state,
        contextVisit: { visitId: action.visitId },
        availableTemplates: action.templates.availableTemplates,
        selectedTemplate: {
          templateId: action.templates.selectedTemplate,
          templateName: null,
          version: null
        }
      };
    case types.LOAD_SPECIFIC_TEMPLATE_SUCCESS: {
      const key = `${action.visitId}_${action.template.templateId}`;
      const updatedSpecificTemplates = { ...state.specificTemplates };
      updatedSpecificTemplates[key] = {
        templateId: action.template.templateId,
        templateName: action.template.templateName,
        version: action.template.version,
        surveys: contextualizeSurveys(action.template.surveys, "templates", action.visitId)
      };
      return {
        ...state,
        contextVisit: { visitId: action.visitId },
        availableTemplates: [
          {
            templateDisplayName: updatedSpecificTemplates[key].templateName,
            templateId: updatedSpecificTemplates[key].templateId,
            isDefault: true,
            version: updatedSpecificTemplates[key].version
          }
        ],
        selectedTemplate: updatedSpecificTemplates[key],
        specificTemplates: updatedSpecificTemplates
      };
    }
    case types.LOAD_SURVEYS_SUCCESS:
      return {
        ...state,
        selectedTemplate: {
          ...state.selectedTemplate,
          templateId: action.template.templateId,
          templateName: action.template.templateName,
          version: action.template.version,
          surveys: contextualizeSurveys(action.template.surveys, "templates", action.visitId)
        }
      };
    case types.HIDE_HP_SURVEY: {
      const { templateId, surveyId, patientId, answers } = action;
      const hiddenSurveys = { ...state.hiddenSurveys };
      hiddenSurveys[`${patientId}-${templateId}-${surveyId}`] = { templateId, surveyId, patientId, answers };
      return { ...state, hiddenSurveys };
    }
    case types.CLEAR_HIDDEN_HP_SURVEYS:
      return { ...state, hiddenSurveys: {} };
    case types.CLEAR_HIDDEN_HP_SURVEY: {
      const { patientId, templateId, surveyId } = action;
      const hiddenSurveys = { ...state.hiddenSurveys };
      delete hiddenSurveys[`${patientId}-${templateId}-${surveyId}`];
      return { ...state, hiddenSurveys };
    }
    case types.SAVE_SURVEY_SUCCESS: {
      const surveyAnswers = action.surveyAnswers.answers;
      const { surveyId } = action;
      const surveyToUpdate = state.selectedTemplate.surveys.find((survey) => survey.id === surveyId);
      const updatedQuestions = surveyToUpdate.questions.map((question) => {
        const answersToPush = surveyAnswers.find((answer) => answer.questionKey === question.key);
        if (!answersToPush) return question;
        const _question = { ...question };
        _question.previousAnswers = answersToPush.answers;
        _question.elucidations = answersToPush.elucidations;
        _question.previousAnswerComment = answersToPush.comments;
        if (answersToPush.answers.length) _question.previousAnswersVisitId = action.surveyAnswers.visitId;
        return _question;
      });
      const updateSurveys = [...state.selectedTemplate.surveys];
      updateSurveys.splice(
        state.selectedTemplate.surveys.indexOf(state.selectedTemplate.surveys.find((survey) => survey.id === surveyId)),
        1,
        {
          ...surveyToUpdate,
          // completedThisVisit: true,
          hasAnswersFromContextContainer: true,
          questions: updatedQuestions
        }
      );

      const updatedSpecificTemplates = {};
      Object.keys(state.specificTemplates).forEach((key) => {
        updatedSpecificTemplates[key] = {
          ...state.specificTemplates[key],
          surveys: state.specificTemplates[key].surveys.map((survey) => {
            if (survey.id !== action.surveyId) return { ...survey };
            return {
              ...survey,
              questions: survey.questions.map((question) => {
                const answersToPush = surveyAnswers.find((answer) => answer.questionKey === question.key);
                if (!answersToPush) return question;
                const _question = { ...question };
                _question.previousAnswers = answersToPush.answers;
                _question.elucidations = answersToPush.elucidations;
                _question.previousAnswerComment = answersToPush.comments;
                if (answersToPush.answers.length) _question.previousAnswersVisitId = action.surveyAnswers.visitId;
                return _question;
              })
            };
          })
        };
      });
      return {
        ...state,
        // reInitSurvey: true, // set it when needed
        selectedTemplate: {
          ...state.selectedTemplate,
          surveys: updateSurveys
        },
        specificTemplates: updatedSpecificTemplates,
        hiddenSurveys: {}
      };
    }
    case types.COMPLETE_VISIT_CLEANUP:
      return {
        availableTemplates: [],
        selectedTemplate: {
          templateId: null,
          templateName: null,
          version: null,
          surveys: [],
          styles: []
        },
        specificTemplates: {},
        hiddenSurveys: {}
      };
    case types.SET_RELOAD_SELECTED_TEMPLATE: {
      const updatedSpecificTemplates = { ...state.specificTemplates };
      Object.keys(state.specificTemplates).forEach((key) => {
        if (updatedSpecificTemplates[key].templateId === state.selectedTemplate?.templateId) {
          delete updatedSpecificTemplates[key]; // Specific Templates are cached .. so remove it from cache to force reload it
        }
      });
      return action.value
        ? {
            ...state,
            selectedTemplate: {
              ...state.selectedTemplate,
              surveys: []
            },
            specificTemplates: updatedSpecificTemplates,
            reloadTemplate: action.value
          }
        : { ...state, reloadTemplate: action.value };
    }
    case types.SET_RE_INIT_SURVEY:
      return { ...state, reInitSurvey: action.value };
    default:
      return { ...state };
  }
}
