import React, { Component } from "react";
import { Table, Checkbox, Dropdown, Button } from "semantic-ui-react";
import { map, find, isEmpty } from "lodash";
import moment from "moment";
import matchesProperty from "lodash/matchesProperty";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import shortid from "shortid";
import { withRouter } from "react-router-dom";
import * as userActions from "../../actions/userActions";
import * as providerActions from "../../actions/providerActions";
import * as lookupActions from "../../actions/lookupActions";
import * as modalActions from "../../actions/modalActions";
import * as adminActions from "../../actions/adminActions";
import { roleNames } from "../../constants/securityRoles";
import { dateFormat } from "../../constants/miscellaneous";
import { showModal } from "../../helpers";
import { RENEW_LICENSE_MODAL } from "../../helpers/modals";

export class ManageUsersPage extends Component {
  state = {
    column: null,
    direction: null,
    filter: {
      show: "all",
      roles: [
        ...(this.props.authRoles.includes(roleNames.systemAdmin) && [
          roleNames.systemAdmin,
          roleNames.ldac2,
          roleNames.ldac1
        ]),
        roleNames.provider,
        roleNames.supProvider
      ]
    }
  };

  constructor(props) {
    super(props);
    this.props.actions.pushItemToBreadcrumb({
      key: "bc-manage-users",
      to: "/admin/manage-users",
      text: "Manage Users"
    });
  }

  componentDidMount() {
    const {
      actions: { loadStates }
    } = this.props;
    loadStates();
    this.loadProviders();
  }

  componentDidUpdate(_, prevState) {
    const {
      filter: { show, roles }
    } = this.state;
    const {
      actions: { filterUsers }
    } = this.props;
    const {
      filter: { show: prevShow, roles: prevRoles }
    } = prevState;
    const showAll = show === "all";
    const isActive = show === "active";

    if (show !== prevShow && roles !== prevRoles && !showAll) {
      filterUsers(isActive);
    }

    if (roles.length !== prevRoles.length) {
      this.loadProviders();
    }
  }

  getProvider = (id) => {
    const provider = find(this.props.providers, matchesProperty("userId", id));
    return provider;
  };

  loadProviders = () => {
    const {
      actions: { loadUsersWithRoles }
    } = this.props;
    const {
      filter: { show, roles }
    } = this.state;
    const activeOnly = show !== "all";

    loadUsersWithRoles(roles, activeOnly);
  };

  handleEditProvider = (e) => {
    const { history } = this.props;
    const userId = e.currentTarget.getAttribute("data-id");
    history.push(`/admin/manage-users/${userId}`);
  };

  handleEditProviderKeys(providerId, providerName) {
    this.props.actions.showModal({
      type: "PROVIDER_INTEGRATION",
      props: { open: true, providerId, providerName }
    });
  }

  handleToggleActivateProvider = (providerId, activate) => {
    const {
      actions: { toggleUserStatus }
    } = this.props;
    toggleUserStatus(providerId, activate);
  };

  confirmToggleActivateProvider(e, data, providerId, providerName) {
    const { checked } = data;
    this.props.actions.showModal({
      type: "CONFIRMATION",
      props: {
        open: true,
        icon: "exclamation-triangle",
        title: `${checked ? `Activate` : `Inactivate`} provider?`,
        description: `Are you sure you want to ${checked ? `activate` : `inactivate`} provider: ${providerName}?`,
        buttonMessage: checked ? `Activate` : `Inactivate`,
        buttonColor: "red",
        size: "tiny",
        onConfirm: () => this.handleToggleActivateProvider(providerId, checked)
      }
    });
  }

  handleFilterChange = (_, data) => {
    const { name, value } = data;
    this.setState({ filter: { ...this.state.filter, [name]: value } });
  };

  handleSort = (clickedColumn) => () => {
    const {
      actions: { sortUserByColumn }
    } = this.props;
    const { column, direction } = this.state;

    sortUserByColumn(column, clickedColumn);
    this.setState({
      column: clickedColumn,
      direction: direction === "ascending" && column === clickedColumn ? "descending" : "ascending"
    });
  };

  handleGrantEpcs = (providerId) => {
    this.props.actions.setExostarSigningPermission(providerId, true).then(() => {
      this.loadProviders();
    });
  };

  handleResetPin = (providerId, providerName) => {
    this.props.actions.showModal({
      type: "CONFIRMATION",
      props: {
        open: true,
        icon: "exclamation-triangle",
        title: `Reset PIN`,
        description: `Are you sure you want to reset PIN for provider: ${providerName}?`,
        buttonMessage: `Reset`,
        buttonColor: "orange",
        size: "tiny",
        onConfirm: () => {
          this.props.actions.resetRxPin(providerId).then(() => {
            this.loadProviders();
          });
        }
      }
    });
  };

  handleRxGatewayChange = (userId, gateway) => {
    this.props.actions.setProviderRxGateway(userId, gateway).then(() => {
      this.loadProviders();
    });
  };

  handleRenewLicense = (userId) => {
    showModal(RENEW_LICENSE_MODAL, { userId, stateOptions: this.props.states });
  };

  render() {
    const { column, direction, filter } = this.state;
    const { authRoles, users, processing } = this.props;
    const isEpcsAdmin = authRoles.includes(roleNames.systemAdmin);

    return (
      <React.Fragment>
        <div className="header-wrapper">
          <h2>Manage Users</h2>
        </div>
        <div className="content-wrapper">
          <div>
            {`Show `}
            <Dropdown
              name="show"
              selection
              placeholder="Select..."
              options={[
                { text: "All", value: "all" },
                { text: "Active", value: "active" },
                { text: "Inactive", value: "inactive" }
              ]}
              value={filter.show}
              onChange={this.handleFilterChange}
            />
            {` Users with the following roles: `}
            <Dropdown
              name="roles"
              options={Object.keys(roleNames)
                .filter(
                  (key) =>
                    authRoles.includes(roleNames.systemAdmin) ||
                    roleNames[key] === roleNames.provider ||
                    roleNames[key] === roleNames.supProvider
                )
                .map((key) => ({ text: roleNames[key], value: roleNames[key] }))}
              value={filter.roles}
              onChange={this.handleFilterChange}
              style={{ display: "inline-block" }}
              multiple
              selection
              search
            />
          </div>
          <Table selectable sortable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell
                  width={2}
                  sorted={column === "firstName" ? direction : null}
                  onClick={this.handleSort("firstName")}
                >
                  First Name
                </Table.HeaderCell>
                <Table.HeaderCell
                  width={2}
                  sorted={column === "lastName" ? direction : null}
                  onClick={this.handleSort("lastName")}
                >
                  Last Name
                </Table.HeaderCell>
                <Table.HeaderCell sorted={column === "title" ? direction : null} onClick={this.handleSort("title")}>
                  Title
                </Table.HeaderCell>
                <Table.HeaderCell>Role</Table.HeaderCell>
                {authRoles.includes(roleNames.systemAdmin) && <Table.HeaderCell>Integration</Table.HeaderCell>}
                <Table.HeaderCell>EPCS Status</Table.HeaderCell>
                {isEpcsAdmin && <Table.HeaderCell>EPCS Permission</Table.HeaderCell>}
                <Table.HeaderCell>License Expiry Date</Table.HeaderCell>
                <Table.HeaderCell />
                <Table.HeaderCell>Active</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {users &&
                users
                  .filter(
                    (provider) =>
                      filter.show === "all" ||
                      (filter.show === "active" && provider.isActive) ||
                      (filter.show === "inactive" && !provider.isActive)
                  )
                  .map(
                    ({
                      userId,
                      firstName,
                      lastName,
                      title,
                      roles,
                      isActive,
                      epcsCalculatedStatus,
                      exostarGranted,
                      licenseExpiryDate
                    }) => (
                      <Table.Row key={userId} data-id={userId} onClick={this.handleEditProvider} verticalAlign="top">
                        <Table.Cell style={{ maxWidth: "100px" }}>{firstName}</Table.Cell>
                        <Table.Cell style={{ maxWidth: "100px" }}>{lastName}</Table.Cell>
                        <Table.Cell>{title}</Table.Cell>
                        <Table.Cell>
                          {map(roles, (role) => (
                            <div key={shortid.generate()}>{role}</div>
                          ))}
                        </Table.Cell>
                        {authRoles.includes(roleNames.systemAdmin) && (
                          <Table.Cell
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                          >
                            <a
                              style={{ display: "inline", cursor: "pointer" }}
                              role="button"
                              tabIndex={0}
                              onClick={() => {
                                this.handleEditProviderKeys(
                                  userId,
                                  `${title || ""} ${firstName || ""} ${lastName || ""}`
                                );
                              }}
                            >
                              Edit keys
                            </a>
                          </Table.Cell>
                        )}
                        <Table.Cell>{epcsCalculatedStatus}</Table.Cell>
                        {isEpcsAdmin && (
                          <Table.Cell onClick={(e) => e.stopPropagation()}>
                            {epcsCalculatedStatus === "Needs Grant" && (
                              <Button
                                size="mini"
                                color="green"
                                content="Grant"
                                onClick={() => {
                                  this.handleGrantEpcs(userId);
                                }}
                                loading={processing}
                                disabled={processing}
                              />
                            )}
                            {(epcsCalculatedStatus === "Granted" || epcsCalculatedStatus === "Finalized") && (
                              <Button
                                size="mini"
                                color="orange"
                                content="Reset PIN"
                                onClick={() => {
                                  this.handleResetPin(userId, `${title || ""} ${firstName || ""} ${lastName || ""}`);
                                }}
                                loading={processing}
                                disabled={processing}
                              />
                            )}
                          </Table.Cell>
                        )}
                        <Table.Cell
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        >
                          {isEmpty(licenseExpiryDate) ? null : moment(licenseExpiryDate).format(dateFormat)}
                        </Table.Cell>
                        <Table.Cell
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        >
                          {exostarGranted && (
                            <Button
                              color="green"
                              size="mini"
                              content="Renew License"
                              onClick={() => {
                                this.handleRenewLicense(userId);
                              }}
                            />
                          )}
                        </Table.Cell>
                        <Table.Cell
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        >
                          {/* only these user roles can be deactivated */}
                          {(roles.includes(roleNames.provider) ||
                            roles.includes(roleNames.supProvider) ||
                            roles.includes(roleNames.ldac1) ||
                            roles.includes(roleNames.ldac2)) && (
                            <Checkbox
                              toggle
                              checked={isActive}
                              onChange={(e, data) => {
                                this.confirmToggleActivateProvider(
                                  e,
                                  data,
                                  userId,
                                  `${title || ""} ${firstName || ""} ${lastName || ""}`
                                );
                              }}
                              readOnly={!authRoles.includes(roleNames.systemAdmin)}
                            />
                          )}
                        </Table.Cell>
                      </Table.Row>
                    )
                  )}
            </Table.Body>
            <Table.Footer />
          </Table>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { auth: { user: { userName, profile: { roles = [] } = {} } = {} } = {} } = state;

  return {
    users: state.user.list,
    states: state.lookups.states,
    processing: state.ajaxCallsInProgress > 0,
    authRoles: roles,
    authUserName: userName
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      { ...lookupActions, ...modalActions, ...adminActions, ...userActions, ...providerActions },
      dispatch
    )
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ManageUsersPage));
