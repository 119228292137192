import { loadPrescriptionsSent } from "../../actions/prescriptionActions";
import { showPopupMessage } from "../../components/common/PopupMessage";

const store = window.store;

const handlePrescriptionStatusMessage = message => {
  /* {"Type":"@Notification.PrescriptionStatus","Title":null,"Content":{"reload":true,"patientId":"4925133c-c222-4312-9384-76b22e82138b"},"Options":null,"Time":"2021-07-13T23:55:00.6056099+02:00"} */
  if (message.Content.reload) store.dispatch(loadPrescriptionsSent(message.Content.patientId));
};

const handlePrescriptionErrorMessage = message => {
  /* {"Type":"@Notification.PrescriptionError","Title":null,"Content":{ ... } */
  showPopupMessage(message.Content.errorUserMessage);
};

export default {
  handlePrescriptionStatusMessage,
  handlePrescriptionErrorMessage
};
