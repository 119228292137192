import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Button, Checkbox, Divider, Dropdown, Grid, Icon, Pagination, Radio, Segment, Table } from "semantic-ui-react";
import { DateInput } from "semantic-ui-calendar-react";
import moment from "moment";
import { cloneDeep, isEmpty, isEqual, set } from "lodash";
import { roleGroups } from "../../../constants/securityRoles";
import { checkRoles, patientName } from "../../../helpers";
import * as badgeActions from "../../../actions/badgeActions";
import * as modalActions from "../../../actions/modalActions";
import * as lookupActions from "../../../actions/lookupActions";
import * as userActions from "../../../actions/userActions";
import * as noteSlashTasksActions from "../../../actions/noteSlashTasksActions";
import emrComponent from "../../common/emrComponent";
import NoteSlashTaskCompactView from "./NoteSlashTaskCompactView";
import { dateFormat, dateWithTimeFormat, isoDateFormat, isoFormat } from "../../../constants/miscellaneous";
import "./NoteSlashTaskList.css";

export class NoteSlashTaskList extends Component {
  state = this.initState();

  componentDidMount() {
    this._isMounted = true;
    this.props.actions.loadTaskRoles();
    this.props.actions.loadRoleGroups();
    this.props.actions.loadStates();
    this.fetch();
  }

  componentDidUpdate(prevProps, prevState) {
    if (!isEmpty(this.props.sessionFilter)) {
      const copy = { ...this.state };
      delete copy.selectedRoleGroups;
      window.cleanSlate.sessionStorage.setItem(this.props.sessionFilter, copy);
    }

    if (
      (this.props.noteSlashTasks.forceReload &&
        this.props.noteSlashTasks.forceReload !== prevProps.noteSlashTasks.forceReload) ||
      this.state.assignedRoles.length !== prevState.assignedRoles.length ||
      this.state.assignedCenterIds.length !== prevState.assignedCenterIds.length
    ) {
      this.fetch();
    }

    if (!isEqual(this.props.defaultFilter, prevProps.defaultFilter)) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ ...this.initState() }, () => {
        this.fetch();
      });
    }

    if (
      !isEmpty(this.props.roleGroupOptions) &&
      !isEmpty(this.state.assignedRoles) &&
      (isEmpty(prevProps.roleGroupOptions) || this.props.roleGroupOptions.length !== prevProps.roleGroupOptions.length)
    ) {
      const selectedRoleGroups = [];
      this.props.roleGroupOptions.forEach((roleGroup) => {
        if (roleGroup.roles.some((role) => this.state.assignedRoles.includes(role.name))) {
          selectedRoleGroups.push(roleGroup.groupName);
        }
      });
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ selectedRoleGroups });
    }

    if (
      this.state.assignedCenterIds.length !== prevState.assignedCenterIds.length ||
      this.state.assignedRoles.length !== prevState.assignedRoles.length ||
      this.state.dueDateFrom !== prevState.dueDateFrom ||
      this.state.dueDateTo !== prevState.dueDateTo
    ) {
      this.props.actions.loadTasksBadge();
    }

    if (this.state.expandDetailsForEntityId !== prevState.expandDetailsForEntityId) {
      let entity = null;
      const found = this.props.noteSlashTasks.data.find(
        (i) => i.entity.platformId === this.state.expandDetailsForEntityId
      );
      if (found) entity = found.entity;
      const roleUserOptions = { ...this.state.roleUserOptions };
      if (
        entity &&
        entity.assignee &&
        entity.assignee.assignedRole &&
        isEmpty(roleUserOptions[entity.assignee.assignedRole])
      ) {
        this.props.actions.loadUsersWithRoles([entity.assignee.assignedRole]).then(({ list }) => {
          roleUserOptions[entity.assignee.assignedRole] = list.map((u) => ({
            text: `${u.firstName} ${u.lastName}`,
            value: u.userId
          }));
          this.setState({ roleUserOptions });
        });
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  _isMounted = false;

  initState() {
    const state = {
      loading: false,
      patientId: "",
      status: "All",
      type: "All",
      dueDateFrom: "",
      dueDateTo: "",
      includeDeleted: false,
      includePatientInfo: false,
      assignedUserIds: [],
      assignedCenterIds: [],
      assignedRoles: [],
      selectedRoleGroups: [],
      sortBy: "lastModified",
      sortOrder: "descending",
      pageNumber: 1,
      itemsPerPage: 20,
      roleUserOptions: {},
      expandDetailsForEntityId: null
    };

    let sourceObject = null;
    const sessionObject = window.cleanSlate.sessionStorage.getItem(this.props.sessionFilter);
    if (!isEmpty(this.props.sessionFilter) && sessionObject) {
      sourceObject = sessionObject;
    } else if (Object.prototype.hasOwnProperty.call(this.props, "defaultFilter")) {
      sourceObject = this.props.defaultFilter;
    }

    if (sourceObject) {
      Object.keys(state).forEach((key) => {
        if (Object.prototype.hasOwnProperty.call(sourceObject, key)) {
          state[key] = sourceObject[key];
        }
      });
    }

    if (!isEmpty(this.props.roleGroupOptions) && !isEmpty(state.assignedRoles)) {
      this.props.roleGroupOptions.forEach((roleGroup) => {
        if (roleGroup.roles.some((role) => state.assignedRoles.includes(role.name))) {
          state.selectedRoleGroups.push(roleGroup.groupName);
        }
      });
    }

    return state;
  }

  fetch = (resetPageNumber = true) => {
    this.setState({ loading: true });
    this.props.actions
      .fetchNoteSlashTasks({
        ...this.state,
        dueDateFrom: isEmpty(this.state.dueDateFrom)
          ? ``
          : `${moment(this.state.dueDateFrom).startOf("day").format(isoFormat)}`,
        dueDateTo: isEmpty(this.state.dueDateTo)
          ? ``
          : `${moment(this.state.dueDateTo).endOf("day").format(isoFormat)}`,
        sortOrder: this.state.sortOrder === "ascending" ? "asc" : "desc"
      })
      .then(() => {
        this.setState({ loading: false });
        if (resetPageNumber) this.setState({ pageNumber: 1 });
      });
  };

  handleInput = (e, data) => {
    let updatedState = cloneDeep(this.state);
    let value = data.value;
    if (["dueDateFrom", "dueDateTo"].includes(data.name)) {
      value = isEmpty(value) ? `` : moment(value, dateFormat).format(isoDateFormat);
    }
    set(updatedState, data.name, value);
    if (data.name === "type") {
      if (value === "Task")
        updatedState = {
          ...updatedState,

          ...updatedState,
          status: "Opened",
          dueDateFrom: "",
          dueDateTo: moment().add(7, "days").format(isoDateFormat)
        };
      else
        updatedState = {
          ...updatedState,
          ...updatedState,
          status: "All",
          dueDateFrom: "",
          dueDateTo: ""
        };
    }
    if (this._isMounted)
      this.setState(updatedState, () => {
        this.fetch();
      });
  };

  handleSort = (clickedColumn) => () => {
    const { sortBy, sortOrder } = this.state;
    this.setState(
      {
        ...this.state,
        sortBy: clickedColumn,
        sortOrder: sortOrder === "ascending" && sortBy === clickedColumn ? "descending" : "ascending"
      },
      () => {
        this.fetch();
      }
    );
  };

  handleIncludeDeletedCheck = (e, data) => {
    this.setState({ ...this.state, includeDeleted: data.checked }, () => {
      this.fetch();
    });
  };

  handleNewEntity = (type) => {
    this.props.actions.showModal({
      type: "NOTE_SLASH_TASK_MODAL",
      props: {
        open: true,
        isNew: true,
        patient: this.props.patient,
        type,
        afterSave: () => {
          this.fetch();
        }
      }
    });
  };

  handleViewEntity = (id) => {
    const entity = this.props.noteSlashTasks.data.find((i) => i.entity.platformId === id).entity;
    this.props.actions.showModal({
      type: "NOTE_SLASH_TASK_MODAL",
      props: {
        open: true,
        entityId: id,
        type: entity.type.toLowerCase(),
        afterSave: () => {
          this.fetch();
        }
      }
    });
  };

  handleEditEntity = (id) => {
    const entity = this.props.noteSlashTasks.data.find((i) => i.entity.platformId === id).entity;
    this.props.actions.showModal({
      type: "NOTE_SLASH_TASK_MODAL",
      props: {
        open: true,
        entityId: id,
        type: entity.type.toLowerCase(),
        afterSave: () => {
          this.fetch();
        }
      }
    });
  };

  handleDeleteEntity = (id) => {
    const entity = this.props.noteSlashTasks.data.find((i) => i.entity.platformId === id).entity;
    this.props.actions.showModal({
      type: "NOTE_SLASH_TASK_DELETE_REASON_MODAL",
      props: {
        open: true,
        entity,
        afterDelete: () => this.fetch()
      }
    });
  };

  handleCompleteTask = (id) => {
    const entity = this.props.noteSlashTasks.data.find((i) => i.entity.platformId === id).entity;
    this.props.actions.showModal({
      type: "CONFIRMATION",
      props: {
        open: true,
        icon: "check",
        title: `Confirm Complete ${entity.type}`,
        description: `Are you sure you want to complete and close ${entity.type.toLowerCase()}?`,
        buttonMessage: "Complete",
        buttonColor: "green",
        size: "mini",
        onConfirm: () => this.props.actions.completeNoteSlashTask(entity.patientId, id).then(() => this.fetch())
      }
    });
  };

  handleRestoreEntity = (id) => {
    const entity = this.props.noteSlashTasks.data.find((i) => i.entity.platformId === id).entity;
    this.props.actions.showModal({
      type: "CONFIRMATION",
      props: {
        open: true,
        icon: "exclamation-triangle",
        title: `Confirm Restore ${entity.type}`,
        description: `Are you sure you want to restore this ${entity.type.toLowerCase()}?`,
        buttonMessage: "Restore",
        buttonColor: "green",
        size: "mini",
        onConfirm: () => this.props.actions.restoreNoteSlashTask(entity.patientId, id).then(() => this.fetch())
      }
    });
  };

  handleRoleGroupChange = (_, { value = [] } = {}) => {
    const { roleGroupOptions } = this.props;
    this.setState({
      assignedRoles: [
        ...new Set(
          roleGroupOptions
            .filter((i) => value.includes(i.groupName))
            .map((i) => i.roles)
            .flat()
        )
      ].map((i) => i.name),
      selectedRoleGroups: value
    });
  };

  handleCheckAllRoles = (e, data) => {
    const { roleGroupOptions } = this.props;
    const assignedRoles = data.checked
      ? [...new Set(roleGroupOptions.map((i) => i.roles).flat())].map((i) => i.name)
      : [...this.props.defaultFilter.assignedRoles];
    const selectedRoleGroups = [];
    this.props.roleGroupOptions.forEach((roleGroup) => {
      if (roleGroup.roles.some((role) => assignedRoles.includes(role.name))) {
        selectedRoleGroups.push(roleGroup.groupName);
      }
    });
    this.setState({
      ...this.state,
      assignedRoles,
      selectedRoleGroups
    });
  };

  handleCenterChange = (_, { value = [] } = {}) => {
    this.setState({ assignedCenterIds: value });
  };

  handleCheckAllStateCenters = (e, data) => {
    const state = this.props.centerOptions.find((c) => c.value === this.state.assignedCenterIds[0]).state;
    this.setState({
      assignedCenterIds: data.checked
        ? this.props.centerOptions.filter((c) => c.state === state).map((c) => c.value)
        : [...this.props.defaultFilter.assignedCenterIds]
    });
  };

  toggleDetailedView = (entityId) => {
    this.setState(
      { expandDetailsForEntityId: this.state.expandDetailsForEntityId === entityId ? null : entityId },
      () => {
        if (this.state.expandDetailsForEntityId)
          this.props.actions.fetchNoteSlashTaskDetails(this.state.expandDetailsForEntityId);
      }
    );
  };

  handlePaginationChange = (e, { activePage }) => {
    if (activePage >= 1) {
      this.setState({ pageNumber: activePage }, () => this.fetch(false));
    }
  };

  render() {
    const {
      loading,
      type,
      status,
      includeDeleted,
      includePatientInfo,
      dueDateFrom,
      dueDateTo,
      selectedRoleGroups,
      assignedCenterIds,
      sortBy: column,
      sortOrder: direction,
      pageNumber,
      itemsPerPage: pageSize,
      roleUserOptions,
      expandDetailsForEntityId
    } = this.state;
    const {
      typeOptions,
      roleGroupOptions,
      noteSlashTasks,
      currentUserId,
      currentUserRoles,
      fullCenterOptions,
      centerOptions,
      roleOptions,
      detailedData
    } = this.props;
    const { data, count } = noteSlashTasks;
    const canEdit = checkRoles(roleGroups.canEditNoteSlashTask, currentUserRoles);
    // const canComplete = ???;
    const canDelete = checkRoles(roleGroups.canDeleteNoteSlashTask, currentUserRoles);
    const canUndoDelete = checkRoles(roleGroups.canRestoreNoteSlashTask, currentUserRoles);
    const selectedCentersStates = centerOptions
      ? [...new Set(centerOptions.filter((c) => assignedCenterIds.includes(c.value)).map((c) => c.state))]
      : [];
    const allStateCentersAreSelected =
      selectedCentersStates.length === 1 &&
      centerOptions.filter((c) => c.state === selectedCentersStates[0]).length === assignedCenterIds.length;
    const disableCeclAllStateCenters = selectedCentersStates.length !== 1;

    return (
      <Grid className="no-margin">
        <Grid.Row style={{ paddingBottom: "0" }} className="no-padding">
          <Grid.Column width={3}>{this.props.header ? <h3>{this.props.header}</h3> : null}</Grid.Column>
          <Grid.Column width={4}>
            <Segment basic padded className="no-v-padding">
              {typeOptions.length > 0 &&
                typeOptions.map((typeOption) => (
                  <Radio
                    key={typeOption.value}
                    name="type"
                    value={typeOption.value}
                    checked={type === typeOption.value}
                    onChange={this.handleInput}
                    label={typeOption.text}
                    style={{ marginRight: ".5em" }}
                  />
                ))}
            </Segment>
          </Grid.Column>
          <Grid.Column width={4}>
            <Segment basic padded className="no-v-padding">
              <Radio
                name="status"
                value="All"
                checked={status === "All"}
                onChange={this.handleInput}
                label="All"
                style={{ marginRight: ".5em" }}
                disabled={type !== "Task"}
              />
              <Radio
                name="status"
                value="Opened"
                checked={status === "Opened"}
                onChange={this.handleInput}
                label="Open"
                style={{ marginRight: ".5em" }}
                disabled={type !== "Task"}
              />
              <Radio
                name="status"
                value="Completed"
                checked={status === "Completed"}
                onChange={this.handleInput}
                label="Complete"
                disabled={type !== "Task"}
              />
            </Segment>
          </Grid.Column>
          <Grid.Column width={2}>
            <Checkbox
              label="Include deleted"
              checked={includeDeleted}
              onChange={this.handleIncludeDeletedCheck}
              // style={{ paddingTop: "1em" }}
            />
          </Grid.Column>
          <Grid.Column width={3} textAlign="right">
            {this.props.showNewButtons && (
              <React.Fragment>
                <Button
                  className="note-button"
                  size="small"
                  content="New Note"
                  onClick={() => this.handleNewEntity("note")}
                />
                <Button
                  className="task-button"
                  size="small"
                  content="New Task"
                  onClick={() => this.handleNewEntity("task")}
                />
              </React.Fragment>
            )}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className="half-v-padding">
          <Grid.Column width={8} />
          <Grid.Column width={4}>
            <DateInput
              label="Due Date From"
              name="dueDateFrom"
              placeholder="Due Date From"
              value={isEmpty(dueDateFrom) ? "" : moment(dueDateFrom).format(dateFormat)}
              dateFormat={dateFormat}
              iconPosition="right"
              onChange={this.handleInput}
              hideMobileKeyboard
              closable
              clearable
              disabled={type !== "Task"}
              className="cs-field"
            />
          </Grid.Column>
          <Grid.Column width={4}>
            <DateInput
              label="Due Date To"
              name="dueDateTo"
              placeholder="Due Date To"
              value={isEmpty(dueDateTo) ? "" : moment(dueDateTo).format(dateFormat)}
              dateFormat={dateFormat}
              iconPosition="right"
              onChange={this.handleInput}
              hideMobileKeyboard
              closable
              clearable
              disabled={type !== "Task"}
              className="cs-field"
            />
          </Grid.Column>
        </Grid.Row>
        {!isEmpty(centerOptions) && (
          <Grid.Row className="half-v-padding">
            <Grid.Column width={2} className="field">
              {!isEmpty(centerOptions) && (
                <label style={{ display: "inline-block", marginTop: ".5em" }}>Tasks for Center(s)</label>
              )}
            </Grid.Column>
            <Grid.Column width={12}>
              {!isEmpty(centerOptions) && (
                <Dropdown
                  options={centerOptions}
                  value={assignedCenterIds}
                  onChange={this.handleCenterChange}
                  fluid
                  search
                  clearable
                  multiple
                  selection
                />
              )}
            </Grid.Column>
            <Grid.Column width={2}>
              <Checkbox
                label="All State Centers"
                style={{ marginTop: "1em" }}
                checked={allStateCentersAreSelected}
                disabled={disableCeclAllStateCenters}
                onChange={this.handleCheckAllStateCenters}
              />
            </Grid.Column>
          </Grid.Row>
        )}
        {!isEmpty(roleGroupOptions) && (
          <Grid.Row className="half-v-padding">
            <React.Fragment>
              <Grid.Column width={2} className="field">
                <label style={{ display: "inline-block", marginTop: ".5em" }}>Tasks for Role(s)</label>
              </Grid.Column>
              <Grid.Column width={13}>
                <Dropdown
                  options={roleGroupOptions.map((i) => ({ text: i.groupName, value: i.groupName }))}
                  value={selectedRoleGroups}
                  onChange={this.handleRoleGroupChange}
                  fluid
                  search
                  clearable
                  multiple
                  selection
                />
              </Grid.Column>
              <Grid.Column width={1} className="no-padding">
                <Checkbox
                  label="All Roles"
                  style={{ marginTop: "1em" }}
                  checked={roleGroupOptions.every(({ groupName }) => selectedRoleGroups.includes(groupName))}
                  onChange={this.handleCheckAllRoles}
                />
              </Grid.Column>
            </React.Fragment>
          </Grid.Row>
        )}
        <Grid.Row className="no-padding">
          {loading && (
            <Grid.Column width={16} className="no-padding">
              <div style={{ textAlign: "center", padding: "2rem" }}>Loading...</div>
            </Grid.Column>
          )}
          {!loading && count <= 0 && (
            <Grid.Column width={16} className="no-padding">
              <div style={{ textAlign: "center", padding: "2rem" }}>No notes / tasks to display</div>
            </Grid.Column>
          )}
          {!loading && count > 0 && (
            <Grid.Column width={16} className="no-padding">
              <Table
                id="note-slash-tasks-table"
                selectable
                sortable
                style={{ wordBreak: "break-all" }}
                compact
                className="compact-table"
              >
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell style={{ width: "20px" }} />
                    <Table.HeaderCell
                      sorted={column === "createdDate" ? direction : null}
                      onClick={this.handleSort("createdDate")}
                    >
                      Created Date/Time
                    </Table.HeaderCell>
                    {includePatientInfo && (
                      <Table.HeaderCell
                        sorted={column === "lastName" ? direction : null}
                        onClick={this.handleSort("lastName")}
                      >
                        Patient
                      </Table.HeaderCell>
                    )}
                    <Table.HeaderCell
                      sorted={column === "subject" ? direction : null}
                      onClick={this.handleSort("subject")}
                    >
                      Subject
                    </Table.HeaderCell>
                    <Table.HeaderCell
                      sorted={column === "centerName" ? direction : null}
                      onClick={this.handleSort("centerName")}
                    >
                      Center
                    </Table.HeaderCell>
                    <Table.HeaderCell
                      sorted={column === "assignedRole" ? direction : null}
                      onClick={this.handleSort("assignedRole")}
                    >
                      Role
                    </Table.HeaderCell>
                    <Table.HeaderCell
                      sorted={column === "assignedUserLastName" ? direction : null}
                      onClick={this.handleSort("assignedUserLastName")}
                    >
                      Assignee
                    </Table.HeaderCell>
                    <Table.HeaderCell
                      sorted={column === "dueDate" ? direction : null}
                      onClick={this.handleSort("dueDate")}
                    >
                      Due Date
                    </Table.HeaderCell>
                    <Table.HeaderCell
                      sorted={column === "completeAt" ? direction : null}
                      onClick={this.handleSort("completeAt")}
                    >
                      Completed Date
                    </Table.HeaderCell>
                    <Table.HeaderCell
                      sorted={column === "lastModified" ? direction : null}
                      onClick={this.handleSort("lastModified")}
                    >
                      Last updated date
                    </Table.HeaderCell>
                    <Table.HeaderCell style={{ width: "80px" }} />
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {data.map(
                    ({
                      entity: {
                        platformId,
                        patientId,
                        type: entityType,
                        createdDate,
                        subject,
                        assignee,
                        dueDate,
                        completeAt,
                        lastModified,
                        isDeleted,
                        isUrgent
                      },
                      patient
                    }) => (
                      <React.Fragment key={platformId}>
                        <Table.Row
                          key={platformId}
                          className={`clickable ${entityType.toLowerCase()} ${
                            isDeleted ? `deleted` : !isEmpty(completeAt) ? `completed` : isUrgent ? `urgent` : ``
                          }`.trim()}
                          onClick={() => this.toggleDetailedView(platformId)}
                        >
                          <Table.Cell style={{ width: "20px" }}>
                            <Icon name={entityType === "Note" ? "sticky note" : "tasks"} />
                          </Table.Cell>
                          <Table.Cell>{createdDate ? moment(createdDate).format(dateWithTimeFormat) : ""}</Table.Cell>
                          {includePatientInfo && patient && (
                            <Table.Cell>
                              <Link
                                to={`/current-patients/${patientId}/tasks`}
                                style={{ textDecoration: "none" }}
                                key={patientId}
                              >
                                {patientName(
                                  patient.firstName,
                                  patient.preferredName,
                                  patient.middleName,
                                  patient.lastName
                                )}
                              </Link>
                            </Table.Cell>
                          )}
                          <Table.Cell>{subject}</Table.Cell>
                          <Table.Cell>{assignee.centerName}</Table.Cell>
                          <Table.Cell>{assignee.assignedRole ? assignee.assignedRole.substring(4) : ``}</Table.Cell>
                          <Table.Cell>
                            {patientName(assignee.assignedUserFirstName, null, null, assignee.assignedUserLastName)}
                          </Table.Cell>
                          <Table.Cell>{dueDate ? moment(dueDate).format(dateFormat) : ""}</Table.Cell>
                          <Table.Cell>{completeAt ? moment(completeAt).format(dateFormat) : ""}</Table.Cell>
                          <Table.Cell>{lastModified ? moment(lastModified).format(dateWithTimeFormat) : ""}</Table.Cell>
                          <Table.Cell
                            className="actions-column"
                            style={{ width: "80px", padding: "0", textAlign: "right" }}
                          >
                            <React.Fragment>
                              {(isDeleted || !canEdit) && (
                                <Icon
                                  title="View"
                                  name="sticky note"
                                  color="grey"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    this.handleViewEntity(platformId);
                                  }}
                                  style={{ margin: "2px" }}
                                />
                              )}
                              {!isDeleted && canEdit && (
                                <Icon
                                  title="Edit"
                                  name="edit"
                                  color="blue"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    this.handleEditEntity(platformId);
                                  }}
                                  style={{ margin: "2px" }}
                                />
                              )}
                              {!isDeleted &&
                                entityType === "Task" &&
                                isEmpty(completeAt) &&
                                fullCenterOptions
                                  .filter((c) => c.active)
                                  .find((c) => c.value === assignee.assignedCenter) !== undefined &&
                                (assignee.assignedUser === currentUserId ||
                                  currentUserRoles.includes(assignee.assignedRole)) && (
                                  <Icon
                                    title="Complete"
                                    name="check"
                                    color="green"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      this.handleCompleteTask(platformId);
                                    }}
                                    style={{ margin: "2px" }}
                                  />
                                )}
                              {!isDeleted && canDelete && isEmpty(completeAt) && (
                                <Icon
                                  title="Delete"
                                  name="delete"
                                  color="red"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    this.handleDeleteEntity(platformId);
                                  }}
                                  style={{ margin: "2px" }}
                                />
                              )}
                              {isDeleted && canUndoDelete && (
                                <Icon
                                  title="Restore"
                                  name="undo"
                                  color="green"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    this.handleRestoreEntity(platformId);
                                  }}
                                  style={{ margin: "2px" }}
                                />
                              )}
                            </React.Fragment>
                          </Table.Cell>
                        </Table.Row>
                        {expandDetailsForEntityId === platformId && detailedData[platformId] && (
                          <Table.Row className="note-slash-task-details-row">
                            <Table.Cell colSpan={includePatientInfo ? 11 : 10} className="no-padding">
                              <Divider className="divider-notes" style={{ marginTop: "0" }} />
                              <div className="padding" style={{ padding: "1em" }}>
                                <NoteSlashTaskCompactView
                                  entity={detailedData[platformId]}
                                  roleUserOptions={roleUserOptions}
                                  centerOptions={centerOptions}
                                  roleOptions={roleOptions}
                                />
                              </div>
                            </Table.Cell>
                          </Table.Row>
                        )}
                      </React.Fragment>
                    )
                  )}
                </Table.Body>
                <Table.Footer />
              </Table>

              <div style={{ padding: "0 0 1em 0", textAlign: "center" }}>
                <Pagination
                  boundaryRange={2}
                  activePage={pageNumber}
                  ellipsisItem={`...`}
                  size="mini"
                  firstItem={null}
                  lastItem={null}
                  siblingRange={1}
                  totalPages={Math.ceil(count / pageSize)}
                  onPageChange={this.handlePaginationChange}
                />
              </div>
            </Grid.Column>
          )}
        </Grid.Row>
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  return {
    roleGroupOptions: state.lookups.roleGroups,
    patient: state.patient.currentPatient,
    noteSlashTasks: state.noteSlashTasks,
    detailedData: state.noteSlashTasks.detailedData,
    currentUserId: state.userPreference.currentUserId,
    currentUserRoles: state.auth.user.profile.roles,
    stateOptions: state.lookups.states,
    fullCenterOptions: state.lookups.centers,
    centerOptions: state.lookups.centers,
    roleOptions: state.lookups.taskRoles.map((i) => ({ text: i.displayName, value: i.name }))
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...badgeActions,
        ...modalActions,
        ...lookupActions,
        ...userActions,
        ...noteSlashTasksActions
      },
      dispatch
    )
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(emrComponent(NoteSlashTaskList));
