import React from "react";
import { Button } from "semantic-ui-react";

import "../SliderPanel.css";

const InterventionHistorySlider = ({ conditions, sliderVisible, sliderClose, handleQuickSearch }) => (
  <div className={`scroll-bar slider-panel ${sliderVisible ? "visible" : ""}`}>
    <Button icon="arrow right" onClick={sliderClose} className="slider-close transparent-button-icon" />

    <div className="search-results-container">
      <h3>common</h3>
      <div>
        {conditions.map(({ text, code }) => (
          <div key={code}>
            <Button
              icon="plus"
              size="small"
              className="transparent-button-icon"
              onClick={() => handleQuickSearch({ code, condition: text })}
            />
            <span>{text}</span>
          </div>
        ))}
      </div>
    </div>
  </div>
);

export default InterventionHistorySlider;
