import React from "react";
import { Table } from "semantic-ui-react";
import { connect } from "react-redux";
import { Button, Modal } from "../../../../ui";
import { hideModal } from "../../../../helpers";

const OutsideProviderHistoryView = (props) => {
  const { as = "fragment", header, lines, columns, keyColumn } = props;

  const content = (
    <React.Fragment>
      {as !== "modal" && <h4>{header}</h4>}
      <Table className="table table-striped">
        <Table.Header>
          <Table.Row>
            {columns
              .filter((col) => col.visible !== false)
              .map((col) => (
                <Table.HeaderCell key={`h-${col.name}`}>{col.header}</Table.HeaderCell>
              ))}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {lines.length
            ? lines.map((line) => (
                <Table.Row key={line[keyColumn]}>
                  {columns
                    .filter((col) => col.visible !== false)
                    .map((col) => (
                      <Table.Cell key={`${line[keyColumn]}-${col.name}`}>
                        <div className="cell-ob-provider">{line[col.name]}</div>
                      </Table.Cell>
                    ))}
                </Table.Row>
              ))
            : null}
        </Table.Body>
      </Table>
    </React.Fragment>
  );
  if (as === "modal")
    return (
      <Modal
        size="lg"
        backdrop="static"
        keyboard={false}
        onHide={() => {
          hideModal();
        }}
        show
      >
        <Modal.Header closeButton>
          <Modal.Title>{header}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{content}</Modal.Body>
        <Modal.Footer>
          <Button
            variant="danger"
            onClick={() => {
              hideModal();
            }}
            className="float-end"
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    );
  return content;
};

function mapStateToProps(state, props) {
  return {
    lines: state.patientHistory[props.stateItem]
  };
}

export default connect(mapStateToProps)(OutsideProviderHistoryView);
