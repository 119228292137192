import React, { Component } from "react";
import { Grid, Icon, Label, Table } from "semantic-ui-react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import moment from "moment";
import * as modalActions from "../../actions/modalActions";
import * as contextActions from "../../actions/contextActions";
import * as prescriptionActions from "../../actions/prescriptionActions";
import { fetchPrescriptionInteractions, fetchPrescriptionAllergies } from "../../api/screening";
import { dateWithTimeFormat } from "../../constants/miscellaneous";
import PrescriptionToSign from "./PrescriptionToSign";
import "./PrescriptionsToSign.css";
import { subscribe, unsubscribe } from "../../helpers";

export class PrescriptionsToSign extends Component {
  state = {
    loading: false,
    sortColumn: "prescriptionCreatedDate",
    sortDirection: "ascending",
    sortedPrescriptionsToSign: [],
    expandDetailsForPrescriptionId: null
  };

  componentDidMount() {
    const {
      actions: { loadPrescriptionsToSign }
    } = this.props;
    subscribe(`/PrescriptionError/${this.props.currentUserId}`);
    loadPrescriptionsToSign().then(() => {
      this.sort();
    });
  }

  componentDidUpdate() {
    if (this.props.forceReloadPrescriptionsToSign) {
      this.props.actions.setForceReloadPrescriptionsToSign(false);
      this.props.actions.loadPrescriptionsToSign().then(() => {
        this.props.actions.setContext("patient", { patientId: null });
        this.setState({ expandDetailsForPrescriptionId: null });
        this.sort();
      });
    }
  }

  componentWillUnmount() {
    unsubscribe(`/PrescriptionError/${this.props.currentUserId}`);
    this.props.actions.setContext("patient", { patientId: null });
    this.props.actions.clearPrescriptionsToSign();
  }

  handleInteractions = (e, prescriptionId) => {
    e.stopPropagation();
    const {
      prescriptionsToSign,
      actions: { showModal }
    } = this.props;
    const prescription = prescriptionsToSign.find((rx) => rx.prescriptionId === prescriptionId);
    const { patientId } = prescription;

    fetchPrescriptionInteractions(prescriptionId).then((interactionsResult) => {
      showModal({
        type: "PATIENT_INTERACTION_MATCH",
        props: {
          open: true,
          interactionsResult,
          prescription,
          patientId
        }
      });
    });
  };

  handleAllergies = (e, prescriptionId) => {
    e.stopPropagation();
    const {
      prescriptionsToSign,
      actions: { showModal }
    } = this.props;
    const prescription = prescriptionsToSign.find((rx) => rx.prescriptionId === prescriptionId);

    fetchPrescriptionAllergies(prescriptionId).then((allergiesResult) => {
      showModal({
        type: "PATIENT_ALLERGY_MATCH",
        props: {
          open: true,
          selectedMedication: {
            drugName: prescription.drugName
          },
          screeningResults: allergiesResult,
          isShowOnly: true
        }
      });
    });
  };

  sort = () => {
    const { sortColumn, sortDirection } = this.state;

    this.setState({
      sortedPrescriptionsToSign: this.props.prescriptionsToSign.sort((a, b) => {
        if (sortDirection === "ascending")
          return a[sortColumn] > b[sortColumn] ? 1 : b[sortColumn] > a[sortColumn] ? -1 : 0;
        return a[sortColumn] < b[sortColumn] ? 1 : b[sortColumn] < a[sortColumn] ? -1 : 0;
      })
    });
  };

  handleSort = (clickedColumn) => () => {
    const { sortColumn, sortDirection } = this.state;
    this.setState(
      {
        ...this.state,
        sortColumn: clickedColumn,
        sortDirection: sortDirection === "ascending" && sortColumn === clickedColumn ? "descending" : "ascending"
      },
      () => {
        this.sort();
      }
    );
  };

  toggleDetailedView = (prescriptionId) => {
    const expand = this.state.expandDetailsForPrescriptionId !== prescriptionId;
    const prescription = this.props.prescriptionsToSign.find((i) => i.prescriptionId === prescriptionId);

    this.props.actions.setContext("patient", { patientId: expand ? prescription.patientId : null });

    this.setState({ expandDetailsForPrescriptionId: expand ? prescriptionId : null });
  };

  render() {
    const {
      loading,
      sortColumn,
      sortDirection,
      sortedPrescriptionsToSign: prescriptionsToSign,
      expandDetailsForPrescriptionId
    } = this.state;

    return (
      <React.Fragment>
        <div className="header-wrapper">
          <h2>e-prescriptions to sign</h2>
        </div>
        <div className="content-wrapper half-h-padding">
          <Grid className="no-margin">
            <Grid.Row className="no-padding">
              {loading && (
                <Grid.Column width={16}>
                  <div style={{ textAlign: "center", padding: "2rem" }}>Loading...</div>
                </Grid.Column>
              )}
              {!loading && prescriptionsToSign.length <= 0 && (
                <Grid.Column width={16}>
                  <div style={{ textAlign: "center", padding: "2rem" }}>No notes / tasks to display</div>
                </Grid.Column>
              )}
              {!loading && prescriptionsToSign.length > 0 && (
                <Grid.Column width={16}>
                  <Table id="note-slash-tasks-table" selectable sortable style={{ wordBreak: "break-all" }}>
                    <Table.Header>
                      <Table.Row>
                        {/* <Table.HeaderCell style={{ width: "20px" }} /> */}
                        <Table.HeaderCell
                          sorted={sortColumn === "prescriptionCreatedDate" ? sortDirection : null}
                          onClick={this.handleSort("prescriptionCreatedDate")}
                        >
                          Queued Date/Time
                        </Table.HeaderCell>
                        <Table.HeaderCell
                          sorted={sortColumn === "patientMRN" ? sortDirection : null}
                          onClick={this.handleSort("patientMRN")}
                        >
                          MRN
                        </Table.HeaderCell>
                        <Table.HeaderCell
                          sorted={sortColumn === "patientLastName" ? sortDirection : null}
                          onClick={this.handleSort("patientLastName")}
                        >
                          Patient Name
                        </Table.HeaderCell>
                        <Table.HeaderCell>Drug</Table.HeaderCell>
                        <Table.HeaderCell>Level</Table.HeaderCell>
                        <Table.HeaderCell>Alert(s)</Table.HeaderCell>
                        <Table.HeaderCell />
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {prescriptionsToSign.map((rx) => (
                        <React.Fragment key={rx.prescriptionId}>
                          <Table.Row
                            className={`prescription-row clickable ${
                              rx.isCrossCoverage ? `cross-coverage-indicator` : ``
                            } ${expandDetailsForPrescriptionId === rx.prescriptionId ? `expanded` : ``}`}
                            onClick={() => this.toggleDetailedView(rx.prescriptionId)}
                          >
                            {/* <Table.Cell>
                              <Checkbox
                                onClick={e => {
                                  e.stopPropagation();
                                }}
                              />
                            </Table.Cell> */}
                            <Table.Cell>{moment(rx.prescriptionCreatedDate).format(dateWithTimeFormat)}</Table.Cell>
                            <Table.Cell>{rx.patientMRN}</Table.Cell>
                            <Table.Cell>{`${rx.patientFirstName} ${rx.patientLastName}`}</Table.Cell>
                            <Table.Cell>{rx.drugName}</Table.Cell>
                            <Table.Cell style={{ textAlign: "center" }}>
                              {rx.patientLevel && <Label color={rx.patientLevel} size="tiny" circular />}
                            </Table.Cell>
                            <Table.Cell>
                              {rx.hasInteractions && (
                                <Icon
                                  name="exclamation triangle"
                                  color="orange"
                                  onClick={(e) => this.handleInteractions(e, rx.prescriptionId)}
                                />
                              )}
                              {rx.hasAllergies && (
                                <Icon
                                  name="dont"
                                  color="red"
                                  className=""
                                  onClick={(e) => this.handleAllergies(e, rx.prescriptionId)}
                                />
                              )}
                            </Table.Cell>
                            <Table.Cell style={{ textAlign: "center" }}>
                              <Link
                                target="_blank"
                                onClick={(e) => {
                                  e.stopPropagation();
                                }}
                                to={`/current-patients/${rx.patientId}/visits`}
                              >
                                <Icon className="transparent-button-icon" name="file alternate outline" />
                              </Link>
                            </Table.Cell>
                          </Table.Row>
                          {expandDetailsForPrescriptionId === rx.prescriptionId && (
                            <Table.Row className="prescription-details-row expanded">
                              <Table.Cell colSpan={/* 8 */ 7} className="no-padding">
                                <div className="padding half-padding">
                                  <PrescriptionToSign prescriptionId={rx.prescriptionId} />
                                </div>
                              </Table.Cell>
                            </Table.Row>
                          )}
                        </React.Fragment>
                      ))}
                    </Table.Body>
                  </Table>
                </Grid.Column>
              )}
            </Grid.Row>
          </Grid>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  currentUserId: state.userPreference.currentUserId,
  prescriptionsToSign: state.prescriptions.prescriptionsToSign || [],
  forceReloadPrescriptionsToSign: state.prescriptions.forceReloadPrescriptionsToSign
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      ...modalActions,
      ...contextActions,
      ...prescriptionActions
    },
    dispatch
  )
});

export default connect(mapStateToProps, mapDispatchToProps)(PrescriptionsToSign);
