import React, { Component } from "react";
import { Table, Icon, Button, Popup } from "semantic-ui-react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import moment from "moment";
import shortid from "shortid";
import isEmpty from "lodash/isEmpty";

import { loadPrescriptionsSent } from "../../../actions/prescriptionActions";
import { dateWithTimeFormat } from "../../../constants/miscellaneous";
import prescriptionStatus from "../../../constants/prescriptionStatus";
import "./PrescriptionHistory.css";

export const PrescriptionHeader = ({ handleRefresh }) => (
  <Table.Header>
    {handleRefresh && (
      <Table.Row textAlign="center">
        <Table.HeaderCell colSpan={10} onClick={handleRefresh}>
          <Button className="no-border" basic>
            <Icon name="refresh" size="large" color="blue" />
          </Button>
        </Table.HeaderCell>
      </Table.Row>
    )}
    <Table.Row>
      <Table.HeaderCell width={2}>Date Sent</Table.HeaderCell>
      <Table.HeaderCell width={2}>Prescriber</Table.HeaderCell>
      <Table.HeaderCell width={1}>DAW</Table.HeaderCell>
      <Table.HeaderCell width={1}>Qty</Table.HeaderCell>
      <Table.HeaderCell width={3}>Drug and Instructions</Table.HeaderCell>
      <Table.HeaderCell width={1}>Refills</Table.HeaderCell>
      <Table.HeaderCell width={2}>Status</Table.HeaderCell>
      <Table.HeaderCell width={2}>Pharmacy</Table.HeaderCell>
      <Table.HeaderCell width={1}>Method</Table.HeaderCell>
      <Table.HeaderCell width={2}>Note to Pharmacist</Table.HeaderCell>
    </Table.Row>
  </Table.Header>
);

export const PrescriptionRow = ({ rx }) => {
  const {
    pharmacy,
    message,
    prescriptionId,
    dateSent,
    prescriberFirstName,
    prescriberLastName,
    daw,
    quantity,
    drugDescription,
    specialInstructions,
    refills,
    status,
    sendingMethod,
    isMigration,
    timeStamp,
    noteToPharmacist
  } = rx;
  const {
    pharmacyName,
    address1,
    address2,
    city,
    state,
    zip,
    zip4,
    fax,
    phone,
    specialties = [],
    ePrescribeServices = []
  } = pharmacy || {};
  const formattedMessage = (message || "N/A").split(",").map((st) => (
    <React.Fragment key={shortid.generate()}>
      {st}
      <br />
    </React.Fragment>
  ));

  const getRxStatusColor = () => {
    if (isMigration) {
      return "blue";
    }

    if (status === prescriptionStatus.failed) {
      return "red";
    }

    if (status === prescriptionStatus.success || status === prescriptionStatus.printed) {
      return "green";
    }

    return "orange";
  };

  return (
    <Table.Row key={prescriptionId}>
      <Table.Cell>
        {dateSent ? moment(dateSent).format(dateWithTimeFormat) : moment(timeStamp).format(dateWithTimeFormat)}
      </Table.Cell>
      <Table.Cell>
        {prescriberFirstName} {prescriberLastName}
      </Table.Cell>
      <Table.Cell>{daw ? `Y` : `N`}</Table.Cell>
      <Table.Cell>{quantity}</Table.Cell>
      <Table.Cell>
        {drugDescription}
        <br />
        {specialInstructions}
      </Table.Cell>
      <Table.Cell>{refills}</Table.Cell>
      <Table.Cell>
        <Popup
          content={
            <div>
              {isMigration && <h5>Migration Rx</h5>}
              {formattedMessage}
            </div>
          }
          trigger={<span className={`prescription-status-${getRxStatusColor()}`}>{status}</span>}
          wide
        />
      </Table.Cell>
      <Table.Cell>
        {isEmpty(pharmacy) ? (
          "N/A"
        ) : (
          <Popup
            content={
              <Table columns={2} basic fixed>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell width={7}>
                      <h5>Specialties</h5>
                    </Table.Cell>
                    <Table.Cell width={9}>
                      <h5>e-Prescribing Services</h5>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row verticalAlign="top">
                    <Table.Cell>
                      {specialties.map((specialty) => (
                        <div key={shortid.generate()}>{specialty}</div>
                      ))}
                    </Table.Cell>
                    <Table.Cell>
                      {ePrescribeServices.map((service) => (
                        <div key={shortid.generate()}>{service}</div>
                      ))}
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            }
            trigger={
              <div>
                <h5 style={{ display: "inline" }}>{pharmacyName}</h5>
                <br />
                {address1}
                <br />
                {address2 && address2}
                {address2 && <br />}
                {city}, {state}, {zip}
                {zip4 && `-${zip4}`}
                <br />
                Phone: {phone}
                <br />
                Fax: {fax}
              </div>
            }
            wide
          />
        )}
      </Table.Cell>
      <Table.Cell>{sendingMethod || "N/A"}</Table.Cell>
      <Table.Cell>{noteToPharmacist}</Table.Cell>
    </Table.Row>
  );
};

export class PrescriptionsSent extends Component {
  componentDidMount() {
    const {
      patientId,
      actions: { loadPrescriptionsSent: fetchPrescriptionsSent }
    } = this.props;

    if (patientId) {
      fetchPrescriptionsSent(patientId);
    }
  }

  handleRefresh = () => {
    const {
      patientId,
      actions: { loadPrescriptionsSent: fetchPrescriptionsSent }
    } = this.props;

    fetchPrescriptionsSent(patientId);
  };

  render() {
    const { prescriptionsSent } = this.props;

    return (
      <Table padded>
        {<PrescriptionHeader handleRefresh={this.handleRefresh} />}
        <Table.Body>
          {(prescriptionsSent || []).map((rx) => (
            <PrescriptionRow key={rx.prescriptionId} rx={rx} />
          ))}
        </Table.Body>
      </Table>
    );
  }
}

function mapStateToProps(state) {
  return {
    prescriptionsSent: state.prescriptions.prescriptionsSent || []
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ loadPrescriptionsSent }, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PrescriptionsSent);
