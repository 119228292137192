import React from "react";
import moment from "moment";
import { Grid } from "semantic-ui-react";
import { isoDateFormat } from "../../../constants/miscellaneous";
import NoteSlashTaskList from "../../common/tasks/NoteSlashTaskList";

export default function Tasks(props) {
  return (
    <Grid className="no-margin">
      <Grid.Row>
        <Grid.Column width={16}>
          <NoteSlashTaskList
            header={`Notes / Tasks`}
            typeOptions={[
              { text: "All", value: "All" },
              { text: "Tasks", value: "Task" },
              { text: "Notes", value: "Note" }
            ]}
            roleGroupOptions={[]}
            centerOptions={[]}
            showNewButtons
            defaultFilter={{
              patientId: props.patient.patientId,
              status: "Opened",
              type: "Task",
              dueDateFrom: "",
              dueDateTo: moment()
                .add(7, "days")
                .format(isoDateFormat),
              includeDeleted: false,
              includePatientInfo: false,
              assignedUserIds: [],
              assignedCenterIds: [],
              assignedRoles: [],
              sortBy: "lastModified",
              sortOrder: "descending",
              pageNumber: 1,
              itemsPerPage: 20
            }}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}
