import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as patientHistoryActions from "../../../../actions/patientHistoryActions";
import * as modalActions from "../../../../actions/modalActions";
import { roleGroups } from "../../../../constants/securityRoles";
import { checkRoles } from "../../../../helpers";
import MedicationTableRow from "./MedicationTableRow";
import "./MedicationTable.css";

export class MedicationTable extends Component {
  handleEditMedication = (e, data) => {
    const { medicationHistory } = this.props;
    switch (data.icon) {
      case "delete":
        this.props.actions.deleteMedication(this.props.patientId, medicationHistory[data.index].itemId);
        break;
      case "edit":
      case "plus":
        this.props.actions.showModal({
          type: "ADD_MEDICATION",
          props: {
            open: true,
            medication: medicationHistory[data.index],
            drugName: medicationHistory[data.index].drugName,
            drugKey: medicationHistory[data.index].drugKey,
            isEdit: true
          }
        });
        break;
      default:
        break;
    }
  };
  render() {
    const { medicationHistory, authRoles } = this.props;
    const showEditHistory = checkRoles(roleGroups.excludeHelpDesk, authRoles);
    const showDeleteHistory = checkRoles(roleGroups.providers, authRoles);
    return (
      <div className="medicationHistoryTable">
        <table>
          <thead>
            <tr>
              <th>Medications</th>
              <th width="20%">Start</th>
              <th width="20%">End</th>
              <th width="5%">Note</th>
              <th width="5%" />
              <th width="5%" />
              <th width="5%" />
            </tr>
          </thead>
          <tbody>
            {medicationHistory.length
              ? medicationHistory.map((medication, i) => (
                  <MedicationTableRow
                    key={medication.drugKey || i}
                    index={i}
                    medication={medication}
                    handleEditMedication={this.handleEditMedication}
                    showEditHistory={showEditHistory}
                    showDeleteHistory={showDeleteHistory}
                  />
                ))
              : null}
          </tbody>
        </table>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    patientId: state.patient.currentPatient.patientId,
    authRoles: state.auth.user.profile.roles
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...patientHistoryActions, ...modalActions }, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MedicationTable);
