import moment from "moment";
import React, { Component } from "react";
import { Table } from "semantic-ui-react";
import shortid from "shortid";
import { dateWithTimeFormat } from "../../../constants/miscellaneous";
import { patientName } from "../../../helpers";

function sortComments(comments, sortDirection = "descending") {
  return comments.sort((a, b) => {
    if (sortDirection === "ascending")
      return a.createdDate > b.createdDate ? 1 : a.createdDate < b.createdDate ? -1 : 0;
    return a.createdDate < b.createdDate ? 1 : a.createdDate > b.createdDate ? -1 : 0;
  });
}

export class NoteSlashTaskCommentsTable extends Component {
  state = {
    sortedComments: sortComments(this.props.comments, "descending"),
    sortColumn: "createdDate",
    sortDirection: "descending"
  };

  componentDidUpdate(prevProps) {
    if (this.props.comments.length !== prevProps.comments.length)
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ sortedComments: sortComments(this.props.comments) });
  }

  handleSort = clickedColumn => () => {
    const { sortColumn, sortDirection } = this.state;
    this.setState({
      sortedComments: sortComments(this.props.comments, sortDirection === "ascending" ? "descending" : "ascending"),
      sortColumn: clickedColumn,
      sortDirection: sortDirection === "ascending" && sortColumn === clickedColumn ? "descending" : "ascending"
    });
  };

  render() {
    const { sortedComments, sortColumn, sortDirection } = this.state;

    return sortedComments.length === 0 ? (
      <div>No comments to show</div>
    ) : (
      <Table selectable sortable style={{ wordBreak: "break-all" }} compact className="compact-table no-padding">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={10}>Comment</Table.HeaderCell>
            <Table.HeaderCell width={2}>Added By</Table.HeaderCell>
            <Table.HeaderCell
              width={4}
              sorted={sortColumn === "createdDate" ? sortDirection : null}
              onClick={this.handleSort("createdDate")}
            >
              Added at
            </Table.HeaderCell>
          </Table.Row>
          {sortedComments.map(_comment => (
            <Table.Row key={_comment.platformId} style={{ borderBottom: "1px solid rgba(34,36,38,.15)" }}>
              <Table.Cell width={10}>
                {_comment.note.split("\n").map(item => (
                  <span key={`rand-${shortid.generate()}`}>
                    {item}
                    <br />
                  </span>
                ))}
              </Table.Cell>
              <Table.Cell width={2}>
                {patientName(_comment.addedByFirstName, null, null, _comment.addedByLastName)}
              </Table.Cell>
              <Table.Cell width={4}>{moment(_comment.createdDate).format(dateWithTimeFormat)}</Table.Cell>
            </Table.Row>
          ))}
        </Table.Header>
      </Table>
    );
  }
}

export default NoteSlashTaskCommentsTable;
