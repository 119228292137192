import React, { Component } from "react";
import { Button } from "semantic-ui-react";
import { isEmpty, startCase, lowerCase } from "lodash";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as modalActions from "../../../../actions/modalActions";
import { checkRoles } from "../../../../helpers";
import { roleGroups } from "../../../../constants/securityRoles";

export class PCPDetails extends Component {
  state = {};

  handleModalOpen = () => {
    if (this.props.handleEdit) this.props.handleEdit();
    else
      this.props.actions.showModal({
        type: "DOCTOR_SEARCH",
        props: {
          open: true
        }
      });
  };

  render() {
    if (isEmpty(this.props.primaryCarePhysician))
      return (
        <div className="PCP-details">
          <div>PCP not set</div>
        </div>
      );
    const {
      primaryCarePhysician: { firstName, lastName, address1, address2, city, state, zip, zip4, phone },
      authRoles
    } = this.props;
    const showEditPcp = checkRoles(roleGroups.excludeHelpDesk, authRoles);
    return (
      <div className="PCP-details">
        <div>
          <strong>{`${startCase(lowerCase(firstName))} ${startCase(lowerCase(lastName))}`}</strong>
          <p>{`${startCase(lowerCase(address1))} ${startCase(lowerCase(address2))}`}</p>
          <p>{`${startCase(lowerCase(city))}, ${state} ${zip}-${zip4}`}</p>
          <p>{`Phone: ${phone}`}</p>
        </div>
        <div>
          {showEditPcp && (
            <Button
              id="button-editPCP"
              className="btn-edit transparent-button-icon"
              onClick={this.handleModalOpen}
              icon="edit"
              size="small"
            />
          )}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    patientId: state.patient.currentPatient.patientId,
    primaryCarePhysician: state.demographics.primaryCarePhysician,
    authRoles: state.auth.user.profile.roles
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...modalActions }, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PCPDetails);
