import { gql } from "graphql-request";
import { isEmpty } from "lodash";
import * as types from "./actionTypes";
import { beginAjaxCall, ajaxCallDone } from "./ajaxStatusActions";
import { processThenThrowApiError, graphQLUtils } from "../helpers";
import { graphQLQuery } from "../adalConfig";
import { visitSummarySections } from "../constants/miscellaneous";

export function fetchPatientExportDataDone(patientId, data) {
  return {
    type: types.FETCH_PATIENT_EXPORT_DATA_DONE,
    context: "patient",
    patientId,
    data
  };
}

function constructVisitsQuery(_filter) {
  const filter = { from: _filter.from, to: _filter.to, ..._filter.visits };
  if (filter.visitIds) {
    delete filter.from;
    delete filter.to;
    delete filter.visitTypes;
    delete filter.visitStatuses;
    delete filter.isVisitSigned;
  }
  const where = graphQLUtils.constructWhere([
    { field: "visitId", op: "in", variable: "$visitIds", parameter: filter.visitIds },
    { field: "visitType", op: "in", variable: "$visitTypes", parameter: filter.visitTypes },
    { field: "visitStatus", op: "in", variable: "$visitStatuses", parameter: filter.visitStatuses },
    { field: "isVisitSigned", op: "eq", variable: "$isVisitSigned", parameter: filter.isVisitSigned },
    {
      field: "visitTime",
      op: [
        { op: "gte", variable: "$from", parameter: filter.from },
        { op: "lte", variable: "$to", parameter: filter.to }
      ]
    }
  ]);

  const { include } = filter;

  return `
  visits(
    patientId: $patientId
    ${isEmpty(where) ? `` : `where: { ${where} }`}
    skip: 0
    take: 1000
  ) {
    items {
      visitId
      visitTime
      visitStatus
      visitType
      visitProviderId
      providerLastName
      providerFirstName
      centerId
      centerName
      centerState
      signedOn
      signedByProviderId
      signedByProviderFirstName
      signedByProviderLastName
      coSignedOn
      coSignedByProviderId
      coSignedByProviderFirstName
      coSignedByProviderLastName
      ${
        include.includes(visitSummarySections.TELEHEALTH)
          ? `
          isPatientHome
          patientCenterId
          isProviderHome
          providerCenterId
          visitSpecimenCenterId
          facilitatedBy
          `
          : ``
      }
      patient {
        firstName
        lastName
        dateOfBirth
        medicalRecordNumber
      }
      center {
        centerId
        address1
        address2
        centerName
        city
        nickName
        state
        zip
        zip4
      }
      ${
        include.includes(visitSummarySections.TELEHEALTH)
          ? `labOrderStatus {
            sampleCollectionTime
          }`
          : ``
      }
      signHistory {
        providerFirstName
        providerLastName
        providerTitle
        actionType
      }
      ${
        include.includes(visitSummarySections.HIST_ALLERGIES)
          ? `medicalAllergyHistory {
              id
              allergySource
            }`
          : ``
      }
      ${
        include.includes(visitSummarySections.HIST_ALLERGIES)
          ? `nonMedicalAllergyHistory {
              id
              allergySource
            }`
          : ``
      }
      ${
        include.includes(visitSummarySections.HIST_MEDICATIONS)
          ? `medicationHistory {
              id
              drugName
              strength
            }`
          : ``
      }
      ${
        include.includes(visitSummarySections.HIST_MEDICAL_HISTORY)
          ? `nonResolvedMedicalHistory {
              id
              condition
              isResolved
            }`
          : ``
      }
      ${
        include.includes(visitSummarySections.HIST_FAMILY_HISTORY)
          ? `familyHistory {
              id
              condition
            }`
          : ``
      }
      ${
        include.includes(visitSummarySections.AMENDMENTS)
          ? `amendments {
              content
              createdDate
              createdByFirstName
              createdByLastName
            }`
          : ``
      }
      ${
        include.includes(visitSummarySections.PRESCRIPTIONS)
          ? `prescriptions {
              prescription {
                platformId
                sentDate
                drugName
                strength
                dosage
                form
                frequency
                noteToPharmacist
              }
              prescriber {
                firstName
                lastName
              }
              prescriberDetails {
                title
              }
            }`
          : ``
      }
      ${
        include.includes(visitSummarySections.LAB_ORDERS)
          ? `labOrders {
              labName
              selectionReason
              confirmatoryLabOrders {
                displayText
                selectionReason
              }
            }`
          : ``
      }
      ${
        include.includes(visitSummarySections.LAB_PRIOR_RESULTS)
          ? `priorLabResults {
              parentLabCode
              resultLabName
              sampleCollectionTime
              childLabResults {
                parentLabCode
                resultLabCode
                resultLabName
                result
                units
                reference
                isResultNormal
                resultDate
              }
            }`
          : ``
      }
      ${
        include.includes(visitSummarySections.LAB_RESULTS)
          ? `labResults {
              parentLabCode
              resultLabName
              sampleCollectionTime
              childLabResults {
                result
                parentLabCode
                resultLabCode
                resultLabName
                isResultNormal
                units
                reference
                resultDate
              }
            }`
          : ``
      }
      ${
        include.includes(visitSummarySections.SURVEYS)
          ? `surveyResults {
              surveyTitle
              sections {
                header
                content
              }
            }`
          : ``
      }
    }
    pageInfo {
      hasNextPage
    }
  }
  `;
}

function constructTasksQuery(filter) {
  const {
    from,
    to,
    tasks: { selectedTaskTypes, selectedTaskStatuses, includeDeletedTasks }
  } = filter;

  const conditions = [];
  const type = selectedTaskTypes.length === 2 ? "" : selectedTaskTypes[0] === "tasks" ? "Task" : "Note";
  if (!isEmpty(type)) conditions.push({ field: "type", op: "eq", variable: "$taskType", parameter: type });
  if (type === "Task") {
    conditions.push({
      field: "dueDate",
      op: [
        { op: "gte", variable: "$from", parameter: from },
        { op: "lte", variable: "$to", parameter: to }
      ]
    });
  }
  if (selectedTaskStatuses.length === 1)
    conditions.push({
      field: "completeAt",
      op: selectedTaskStatuses[0] === "Opened" ? "eq" : "neq",
      variable: "null",
      parameter: true
    });
  if (!includeDeletedTasks) conditions.push({ field: "isDeleted", op: "eq", variable: "false", parameter: true });

  const where = graphQLUtils.constructWhere(conditions);

  return `
    tasks(
      patientId: $patientId
      ${isEmpty(where) ? `` : `where: { task: { ${where} } }`}
      skip: 0
      take: 1000
    ) {
      items {
        task{
          platformId
          createdDate
          creatorFirstName
          creatorLastName
          type
          subject
          body
          dueDate
          completeAt
          assignedCenter
          assignedUserId
          assignedUserFirstName
          assignedUserLastName
          assignedRole
          isUrgent
          isDeleted
        }
        taskComments {
          id
          createdDate
          addedByFirstName
          addedByLastName
          note
        }
      }
    }
  `;
}

function constructPrescriptionsQuery(filter) {
  const {
    from,
    to,
    prescriptions: { selectedPrescriptionStatuses }
  } = filter;

  const prescriptionWhere = graphQLUtils.constructWhere([
    {
      field: "status",
      op: "in",
      variable: "$selectedPrescriptionStatuses",
      parameter: selectedPrescriptionStatuses
    }
  ]);

  const transactionWhere = graphQLUtils.constructWhere([
    {
      field: "id",
      op: "gt",
      variable: "0",
      parameter: true
    },
    {
      field: "timeStamp",
      op: [
        { op: "gte", variable: "$from", parameter: from },
        { op: "lte", variable: "$to", parameter: to }
      ]
    }
  ]);

  return `
    prescriptions(
      patientId: $patientId
      ${
        isEmpty(prescriptionWhere) && isEmpty(transactionWhere)
          ? ``
          : `where: { ${isEmpty(prescriptionWhere) ? `` : `prescription: { ${prescriptionWhere} }`} ${
              isEmpty(transactionWhere) ? `` : `prescriptionTransaction: { ${transactionWhere} }`
            } }`
      }
      skip: 0
      take: 1000
    ) {
      items {
        prescription {
          id
          platformId
          quantity
          drugDescription
          specialInstructions
          refills
          status
          isMigration
        }
        prescriptionTransaction {
          id
          transactionId
          platformId
          timeStamp
          sendingMethod
        }
        pharmacy {
          id
          pharmacyName
          address1
          address2
          city
          state
          zip
          zip4
          fax
          phone
          specialties
          ePrescribeServices
        }
        prescriber {
          firstName
          lastName
        }
      }
    }
  `;
}

export function fetchPatientExportData(patientId, _filter) {
  const {
    from,
    to,
    selectedTypes,
    visits: {
      onlySignedVisits: isVisitSigned,
      selectedVisitTypes: visitTypes,
      selectedVisitStatuses: visitStatuses,
      selectedVisitIds: visitIds,
      include
    },
    tasks: { nstSelectedTypes, nstSelectedStatuses, nstIncludeDeleted },
    prescriptions: { selectedPrescriptionStatuses }
  } = _filter;
  const filter = {
    patientId,
    from,
    to,
    selectedTypes,
    visits: {
      visitTypes,
      visitStatuses,
      visitIds,
      isVisitSigned,
      include
    },
    tasks: {
      selectedTaskTypes: nstSelectedTypes,
      selectedTaskStatuses: nstSelectedStatuses,
      includeDeletedTasks: nstIncludeDeleted
    },
    prescriptions: {
      selectedPrescriptionStatuses
    }
  };

  const paramList = [];

  let visitsQuery = ``;
  if (selectedTypes.includes("visits") && !isEmpty(visitIds)) {
    visitsQuery = constructVisitsQuery(filter);
    paramList.push({ variable: "$visitIds", type: "[UUID!]", parameter: visitIds });
  }

  let tasksQuery = ``;
  if (
    selectedTypes.includes("tasks") &&
    (nstSelectedTypes.length === 2 ||
      (nstSelectedTypes.length === 1 && nstSelectedTypes[0] === "notes") ||
      (nstSelectedTypes.length === 1 && nstSelectedTypes[0] === "tasks" && nstSelectedStatuses.length > 0))
  ) {
    tasksQuery = constructTasksQuery(filter);
    if (nstSelectedTypes.length === 1) {
      paramList.push({ variable: "$taskType", type: "String", parameter: nstSelectedTypes });
    }
  }

  let prescriptionsQuery = ``;
  if (selectedTypes.includes("prescriptions") && selectedPrescriptionStatuses.length > 0) {
    prescriptionsQuery = constructPrescriptionsQuery(filter);
    paramList.push({
      variable: "$selectedPrescriptionStatuses",
      type: "[String]",
      parameter: selectedPrescriptionStatuses
    });
  }

  if (
    // (selectedTypes.includes("visits") && !isEmpty(visitTypes) && isEmpty(visitIds)) || // we only fetch visits by id
    (!isEmpty(tasksQuery) && nstSelectedTypes.length === 1 && nstSelectedTypes[0] === "tasks") ||
    (selectedTypes.includes("prescriptions") && !isEmpty(selectedPrescriptionStatuses))
  ) {
    paramList.push({ variable: "$from", type: "DateTime", parameter: from });
    paramList.push({ variable: "$to", type: "DateTime", parameter: to });
  }

  if (isEmpty(visitsQuery) && isEmpty(tasksQuery) && isEmpty(prescriptionsQuery)) {
    return fetchPatientExportDataDone(patientId, {});
  }

  const params = graphQLUtils.constructParams(paramList);
  const query = gql`
    query getExportData(
      $patientId: UUID!
      ${params}
    ) {
      ${visitsQuery}
      ${tasksQuery}
      ${prescriptionsQuery}
    }
  `;

  const variables = {
    patientId: filter.patientId,
    from: filter.from,
    to: filter.to,
    ...filter.visits,
    taskType: nstSelectedTypes.length === 1 ? (nstSelectedTypes[0] === "tasks" ? "Task" : "Note") : "",
    selectedPrescriptionStatuses
  };

  return (dispatch) => {
    dispatch(beginAjaxCall("fetchPatientExportData"));
    return graphQLQuery(query, variables)
      .then((data) => dispatch(fetchPatientExportDataDone(patientId, data)))
      .catch((error) => processThenThrowApiError(error, dispatch))
      .finally(() => dispatch(ajaxCallDone()));
  };
}
