import React from "react";
import { Grid, Form, Table, Pagination, Button, Icon, Dropdown } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { CSVLink } from "react-csv";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { DateInput } from "semantic-ui-calendar-react";
import moment from "moment";
import set from "lodash/set";
import * as modalActions from "../../actions/modalActions";
import * as reportActions from "../../actions/reportActions";
import * as lookupActions from "../../actions/lookupActions";
import * as providerActions from "../../actions/providerActions";
import { dateFormat, dateWithTimeFormat, isoDateFormat } from "../../constants/miscellaneous";

export class ReviewOfPatients extends React.Component {
  state = {
    filter: {
      visitTimeFrom: moment()
        .subtract(2, "days")
        .format(isoDateFormat),
      visitTimeTo: moment().format(isoDateFormat),
      centerIds: [],
      visitTypes: [],
      providerIds: [],
      patientLevels: []
    },
    activePage: 1,
    pageSize: 100,
    pageNumber: 1,
    sortColumn: null,
    sortDirection: null
  };

  componentDidMount() {
    this.fetch();
    this.props.actions.loadVisitTypes();
    this.props.actions.loadLevels();
    this.props.actions.loadProvidersByState();
  }

  setCSVLinkRef = element => {
    this.cSVLink = element;
  };

  cSVLink = null;

  handleExport = () => {
    const { pageSize, filter } = this.state;
    const {
      reviewOfPatients: { count },
      actions: { loadReviewOfPatients }
    } = this.props;
    loadReviewOfPatients(count > 0 ? count : pageSize, 1, filter, null, null, true).then(() => {
      this.cSVLink.link.click();
    });
  };

  handlePaginationChange = (e, { activePage }) => {
    if (activePage >= 1) {
      this.setState({ pageNumber: activePage }, () => this.fetch(true));
    }
  };

  handleFocus = e => {
    e.target.setAttribute("autocomplete", "nope");
  };

  handleInput = (e, data) => {
    const { name: field } = data;
    let { value } = data;
    const newState = { ...this.state };
    if (field === "filter.visitTimeFrom" || field === "filter.visitTimeTo") {
      value = moment(value, dateFormat).format(isoDateFormat);
    }
    set(newState, field, value);
    this.setState(newState);
  };

  fetch = (page = false) => {
    const { pageSize, pageNumber, filter, sortColumn, sortDirection } = this.state;
    if (!page) this.setState({ pageNumber: 1 });
    this.props.actions.loadReviewOfPatients(pageSize, page ? pageNumber : 1, filter, sortColumn, sortDirection);
  };

  handleSort = clickedColumn => () => {
    const { sortColumn, sortDirection } = this.state;
    this.setState(
      {
        sortColumn: clickedColumn,
        sortDirection: sortDirection === "ascending" && sortColumn === clickedColumn ? "descending" : "ascending"
      },
      () => {
        this.fetch();
      }
    );
  };

  renderTable() {
    const { sortColumn, sortDirection, pageSize, pageNumber } = this.state;
    const reviewOfPatients = this.props.reviewOfPatients;
    const { count, data } = reviewOfPatients;
    return (
      <div>
        {count <= 0 && <div style={{ textAlign: "center", padding: "2rem" }}>No items to display</div>}
        {count > 0 && (
          <React.Fragment>
            <Table selectable sortable style={{ wordBreak: "break-all" }}>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell
                    width={2}
                    sorted={sortColumn === "visitTime" ? sortDirection : null}
                    onClick={this.handleSort("visitTime")}
                  >
                    Visit Time
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    width={1}
                    sorted={sortColumn === "medicalRecordNumber" ? sortDirection : null}
                    onClick={this.handleSort("medicalRecordNumber")}
                  >
                    MRN
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    width={1}
                    sorted={sortColumn === "patientName" ? sortDirection : null}
                    onClick={this.handleSort("patientName")}
                  >
                    Patient Name
                  </Table.HeaderCell>

                  <Table.HeaderCell
                    width={1}
                    sorted={sortColumn === "visitTypeName" ? sortDirection : null}
                    onClick={this.handleSort("visitTypeName")}
                  >
                    Visit Type
                  </Table.HeaderCell>

                  <Table.HeaderCell
                    width={1}
                    sorted={sortColumn === "selectedTemplateName" ? sortDirection : null}
                    onClick={this.handleSort("selectedTemplateName")}
                  >
                    Visit Template
                  </Table.HeaderCell>

                  <Table.HeaderCell
                    width={1}
                    sorted={sortColumn === "provider" ? sortDirection : null}
                    onClick={this.handleSort("provider")}
                  >
                    Visit Provider
                  </Table.HeaderCell>

                  <Table.HeaderCell
                    width={2}
                    sorted={sortColumn === "visitStatusName" ? sortDirection : null}
                    onClick={this.handleSort("visitStatusName")}
                  >
                    Visit Status
                  </Table.HeaderCell>

                  <Table.HeaderCell
                    width={2}
                    sorted={sortColumn === "primaryInsurance" ? sortDirection : null}
                    onClick={this.handleSort("primaryInsurance")}
                  >
                    Primary Insurance
                  </Table.HeaderCell>

                  <Table.HeaderCell
                    width={1}
                    sorted={sortColumn === "mainPrescriber" ? sortDirection : null}
                    onClick={this.handleSort("mainPrescriber")}
                  >
                    Main Prescriber
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    width={2}
                    sorted={sortColumn === "patientLevel" ? sortDirection : null}
                    onClick={this.handleSort("patientLevel")}
                  >
                    Patient Level
                  </Table.HeaderCell>

                  <Table.HeaderCell width={3}>Visit ID</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {data.map(
                  ({
                    visitId,
                    patientId,
                    visitTime,
                    medicalRecordNumber,
                    patientName,
                    visitTypeName,
                    selectedTemplateName,
                    provider,
                    visitStatusName,
                    primaryInsurance,
                    mainPrescriber,
                    patientLevel
                  }) => (
                    <Table.Row key={visitId}>
                      <Table.Cell width={2}>{moment(visitTime).format(dateWithTimeFormat)}</Table.Cell>
                      <Table.Cell width={1}>{medicalRecordNumber}</Table.Cell>
                      <Table.Cell width={1}>
                        <Link
                          to={`/current-patients/${patientId}/demographics`}
                          style={{ textDecoration: "none" }}
                          key={patientId}
                        >
                          {patientName}
                        </Link>
                      </Table.Cell>
                      <Table.Cell width={1}>{visitTypeName}</Table.Cell>
                      <Table.Cell width={2}>{selectedTemplateName}</Table.Cell>
                      <Table.Cell width={1}>{provider}</Table.Cell>
                      <Table.Cell width={2}>{visitStatusName}</Table.Cell>
                      <Table.Cell width={2}>{primaryInsurance}</Table.Cell>
                      <Table.Cell width={2}>{mainPrescriber}</Table.Cell>
                      <Table.Cell width={1} style={{ color: patientLevel }}>
                        {patientLevel}
                      </Table.Cell>
                      <Table.Cell width={2}>{visitId}</Table.Cell>
                    </Table.Row>
                  )
                )}
              </Table.Body>
              <Table.Footer />
            </Table>

            <div style={{ padding: "0 0 1em 0", textAlign: "center" }}>
              <Pagination
                boundaryRange={2}
                activePage={pageNumber}
                ellipsisItem={`...`}
                size="mini"
                firstItem={null}
                lastItem={null}
                siblingRange={1}
                totalPages={Math.ceil(count / pageSize)}
                onPageChange={this.handlePaginationChange}
              />
            </div>
          </React.Fragment>
        )}
      </div>
    );
  }

  render() {
    const { filter } = this.state;
    const {
      centerOptions,
      visitTypeOptions,
      levelOptions,
      providerOptions,
      allReviewOfPatients: { data }
    } = this.props;

    return (
      <React.Fragment>
        <div className="header-wrapper">
          <h2>Review of Patients</h2>
        </div>
        <div className="content-wrapper visit-claims-list" style={{ padding: "1rem 3rem 1rem 1rem" }}>
          <Grid>
            <React.Fragment>
              <Grid.Row>
                <Grid.Column width={16}>
                  <Form>
                    <Grid>
                      <Grid.Row style={{ paddingBottom: "0" }}>
                        <Grid.Column width={1} />
                        <Grid.Column width={3}>
                          Visit Date From
                          <br />
                          <Form.Group widths="equal">
                            <DateInput
                              size="mini"
                              name="filter.visitTimeFrom"
                              placeholder="From"
                              value={moment(filter.visitTimeFrom).format(dateFormat)}
                              dateFormat={dateFormat}
                              iconPosition="right"
                              onChange={this.handleInput}
                              hideMobileKeyboard
                              closable
                            />
                          </Form.Group>
                        </Grid.Column>
                        <Grid.Column width={3}>
                          Visit Date To
                          <br />
                          <Form.Group widths="equal">
                            <DateInput
                              size="mini"
                              name="filter.visitTimeTo"
                              placeholder="To"
                              value={moment(filter.visitTimeTo).format(dateFormat)}
                              dateFormat={dateFormat}
                              iconPosition="right"
                              onChange={this.handleInput}
                              hideMobileKeyboard
                              closable
                            />
                          </Form.Group>
                        </Grid.Column>
                        <Grid.Column width={3}>
                          Clinic
                          <br />
                          <Form.Group widths="equal" style={{ margin: "0 0 1em" }}>
                            <Dropdown
                              name="filter.centerIds"
                              // label="Clinic:"
                              // placeholder="Select a Center..."
                              options={centerOptions}
                              onChange={this.handleInput}
                              value={filter.centerIds}
                              // style={{ display: "inline-block" }}
                              className="mini"
                              fluid
                              multiple
                              selection
                              search
                            />
                          </Form.Group>
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row style={{ padding: "0" }}>
                        <Grid.Column width={1} />
                        <Grid.Column width={3}>
                          Visit Type
                          <br />
                          <Form.Group widths="equal" style={{ margin: "0 0 1em" }}>
                            <Dropdown
                              name="filter.visitTypes"
                              options={visitTypeOptions}
                              onChange={this.handleInput}
                              value={filter.visitTypes}
                              className="mini"
                              fluid
                              multiple
                              selection
                              search
                            />
                          </Form.Group>
                        </Grid.Column>
                        <Grid.Column width={3}>
                          Provider
                          <br />
                          <Form.Group widths="equal" style={{ margin: "0 0 1em" }}>
                            <Dropdown
                              name="filter.providerIds"
                              options={providerOptions}
                              onChange={this.handleInput}
                              value={filter.providerIds}
                              className="mini"
                              fluid
                              multiple
                              selection
                              search
                            />
                          </Form.Group>
                        </Grid.Column>
                        <Grid.Column width={3}>
                          Patient Level
                          <br />
                          <Form.Group widths="equal" style={{ margin: "0 0 1em" }}>
                            <Dropdown
                              name="filter.patientLevels"
                              options={levelOptions}
                              onChange={this.handleInput}
                              value={filter.patientLevels}
                              className="mini"
                              fluid
                              multiple
                              selection
                              search
                            />
                          </Form.Group>
                        </Grid.Column>
                        <Grid.Column width={2}>
                          {` `}
                          <br />
                          <Form.Button
                            size="mini"
                            color="blue"
                            onClick={() => {
                              this.fetch();
                            }}
                          >
                            Filter
                            <Icon name="search" style={{ marginLeft: "5px" }} />
                          </Form.Button>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Form>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row style={{ paddingBottom: "0" }}>
                <Grid.Column width={5} />
                <Grid.Column width={11} floated="right" textAlign="right">
                  <CSVLink ref={this.setCSVLinkRef} filename="report.csv" data={data} style={{ display: "none" }} />
                  <Button
                    icon="file excel"
                    className="transparent-button-icon"
                    content="Export"
                    onClick={this.handleExport}
                  />
                </Grid.Column>
              </Grid.Row>
            </React.Fragment>

            <Grid.Row style={{ paddingTop: "0" }}>
              <Grid.Column style={{ overflowX: "auto", whitespace: "nowrap" }}>{this.renderTable()}</Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    centerOptions: state.lookups.centers.map(i => ({ text: i.text, value: i.value })),
    visitTypeOptions: state.lookups.visitTypes.map(i => ({ text: i.text, value: i.value })),
    levelOptions: state.lookups.levels.map(i => ({ text: i.text, value: i.text })),
    providerOptions: state.providers.allProviders.map(i => ({ text: `${i.firstName} ${i.lastName}`, value: i.userId })),
    reviewOfPatients: state.reports.reviewOfPatients,
    allReviewOfPatients: state.reports.allReviewOfPatients
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...modalActions, ...reportActions, ...lookupActions, ...providerActions }, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReviewOfPatients);
