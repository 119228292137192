import React from "react";
import { Grid, Form, Icon, Table, Pagination, Button } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { CSVLink } from "react-csv";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import set from "lodash/set";
import * as modalActions from "../../actions/modalActions";
import * as adminActions from "../../actions/adminActions";
import * as claimErrorsActions from "../../actions/claimErrorsActions";
import { dateWithFullTimeFormat, monthsOptions, daysOptions, getYearOptions } from "../../constants/miscellaneous";
import "./ManageClaimErrorsPage.css";

const yearsOptions = getYearOptions();

const searchByOptions = [
  { text: "MRN", value: "mrn" },
  { text: "ErrorID", value: "errorId" },
  { text: "Patient First Name", value: "firstName" },
  { text: "Patient Last Name", value: "lastName" },
  { text: "OrchestrationId", value: "orchestrationId" },
  { text: "PlatformEventId", value: "platformEventId" },
  { text: "Error Type", value: "errorType" },
  { text: "Center", value: "centerName" }
];

export class ManageClaimErrorsPage extends React.Component {
  static m = moment();
  state = {
    filters: {
      from: {
        day: ManageClaimErrorsPage.m.date(),
        month: ManageClaimErrorsPage.m.month(),
        year: ManageClaimErrorsPage.m.year()
      },
      to: {
        day: ManageClaimErrorsPage.m.date(),
        month: ManageClaimErrorsPage.m.month(),
        year: ManageClaimErrorsPage.m.year()
      },
      includeNonVisitClaims: false,
      keyword: "",
      searchBy: "mrn"
    },
    activePage: 1,
    orderBySourceHandler: false,
    pageSize: 10,
    pageNumber: 1,
    column: null,
    direction: null
  };

  componentDidMount() {
    this.loadErrors();
    this.interval = setInterval(() => {
      this.loadErrors();
    }, 5000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  setCSVLinkRef = (element) => {
    this.cSVLink = element;
  };

  cSVLink = null;

  handleExport = () => {
    const { filters, orderBySourceHandler } = this.state;
    const {
      athenaServiceErrorsOnly,
      claims: { totalCount },
      actions: { loadClaimErrors }
    } = this.props;
    loadClaimErrors(totalCount, 1, athenaServiceErrorsOnly, filters, orderBySourceHandler, true).then(() => {
      this.cSVLink.link.click();
    });
  };

  handlePaginationChange = (e, { activePage }) => {
    if (activePage >= 1) {
      this.setState({ pageNumber: activePage }, () => this.loadErrors());
    }
  };

  loadErrors = () => {
    this.handleUpddate();
  };

  handleFocus = (e) => {
    e.target.setAttribute("autocomplete", "nope");
  };

  handleInput = (e, data) => {
    const { name: field, type, value, checked } = data;
    const newState = { ...this.state };
    set(newState, field, type === "checkbox" ? checked : value);
    if (field === "filters.searchBy") {
      set(newState, "filters.keyword", "");
    }
    this.setState(newState);
  };

  handleCheckbox = (e, data) => {
    const { checked } = data;
    this.setState({ orderBySourceHandler: checked });
  };

  handleUpddate = () => {
    const { pageSize, pageNumber, filters, orderBySourceHandler } = this.state;
    const { athenaServiceErrorsOnly } = this.props;
    this.props.actions.loadClaimErrors(pageSize, pageNumber, athenaServiceErrorsOnly, filters, orderBySourceHandler);
  };

  handleRequeue = (id) => {
    const { pageSize, pageNumber, filters, orderBySourceHandler } = this.state;
    const {
      athenaServiceErrorsOnly,
      actions: { loadClaimErrors, requeueClaimError }
    } = this.props;
    requeueClaimError(id).then(() => {
      loadClaimErrors(pageSize, pageNumber, athenaServiceErrorsOnly, filters, orderBySourceHandler);
    });
  };

  openRequeueConfirmationModal = (id) => {
    this.props.actions.showModal({
      type: "CONFIRMATION",
      props: {
        open: true,
        icon: "exclamation-triangle",
        title: "Confirm Re-queue Claim",
        description: "Are you sure you want to re-queue this claim?",
        buttonMessage: "Re-queue",
        buttonColor: "red",
        size: "mini",
        onConfirm: () => this.handleRequeue(id)
      }
    });
  };

  handleDismiss = (id) => {
    this.props.actions.dismissClaimError(id).then(() => this.handleUpddate());
  };

  handleEdit = (id) => {
    this.showErrorDetailsModal(id, true);
  };

  showErrorDetailsModal = (id, editMode = false) => {
    this.props.actions.showModal({
      type: "CLAIM_ERROR_DETAILS",
      props: {
        open: true,
        errorId: id,
        editMode,
        athenaServiceErrorsOnly: this.props.athenaServiceErrorsOnly,
        adminPageState: this.state
      }
    });
  };

  openDismissConfirmationModal = (id) => {
    this.props.actions.showModal({
      type: "CONFIRMATION",
      props: {
        open: true,
        icon: "exclamation-triangle",
        title: "Confirm Dismiss Claim",
        description: "Are you sure you want to dismiss this claim?",
        buttonMessage: "Dismiss",
        buttonColor: "red",
        size: "mini",
        onConfirm: () => this.handleDismiss(id)
      }
    });
  };

  renderProceduresAndDiagnoses = (_proceduresAndDiagnoses) => {
    const proceduresAndDiagnoses = JSON.parse(_proceduresAndDiagnoses);
    return proceduresAndDiagnoses ? (
      <Table>
        <Table.Body>
          {Object.keys(proceduresAndDiagnoses).map((cptCode) =>
            cptCode ? (
              <Table.Row key={cptCode}>
                <Table.Cell>{cptCode}</Table.Cell>
                <Table.Cell>{proceduresAndDiagnoses[cptCode].join(", ")}</Table.Cell>
              </Table.Row>
            ) : null
          )}
        </Table.Body>
      </Table>
    ) : null;
  };

  renderErrors() {
    const { column, direction, pageSize, pageNumber } = this.state;
    let errorList = {};
    errorList = this.props.claims;
    const { errors, totalCount } = errorList;
    return (
      <div>
        {totalCount <= 0 && <div style={{ textAlign: "center", padding: "2rem" }}>No errors to display</div>}
        {totalCount > 0 && (
          <React.Fragment>
            <Table selectable sortable style={{ wordBreak: "break-all" }}>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell width={1}>Action</Table.HeaderCell>
                  <Table.HeaderCell width={1} sorted={column === "id" ? direction : null}>
                    ErrorID
                  </Table.HeaderCell>
                  <Table.HeaderCell width={3} sorted={column === "errorType" ? direction : null}>
                    ErrorType
                  </Table.HeaderCell>
                  <Table.HeaderCell width={2} sorted={column === "visitId" ? direction : null}>
                    Visit ID
                  </Table.HeaderCell>
                  <Table.HeaderCell width={2} sorted={column === "mrn" ? direction : null}>
                    MRN
                  </Table.HeaderCell>
                  <Table.HeaderCell width={3} sorted={column === "fullName" ? direction : null}>
                    Patient Name
                  </Table.HeaderCell>
                  <Table.HeaderCell width={2} sorted={column === "visitStatus" ? direction : null}>
                    Visit Status
                  </Table.HeaderCell>
                  <Table.HeaderCell width={2} sorted={column === "visitType" ? direction : null}>
                    Visit Type
                  </Table.HeaderCell>
                  <Table.HeaderCell width={2} sorted={column === "providerName" ? direction : null}>
                    Provider Name
                  </Table.HeaderCell>
                  <Table.HeaderCell width={2} sorted={column === "centerName" ? direction : null}>
                    Center Name
                  </Table.HeaderCell>
                  <Table.HeaderCell width={2} sorted={column === "patientLevel" ? direction : null}>
                    Patient Level
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    width={4}
                    sorted={column === "proceduresAndDiagnoses" ? direction : null}
                    style={{ minWidth: "300px" }}
                  >
                    Procedures And Diagnoses
                  </Table.HeaderCell>
                  <Table.HeaderCell width={2} sorted={column === "appointmentExternalId" ? direction : null}>
                    Appointment External Id
                  </Table.HeaderCell>
                  <Table.HeaderCell width={2} sorted={column === "signedOnDate" ? direction : null}>
                    Signed On Date
                  </Table.HeaderCell>
                  <Table.HeaderCell width={2} sorted={column === "labName" ? direction : null}>
                    Lab Name
                  </Table.HeaderCell>
                  <Table.HeaderCell width={2} sorted={column === "visitDate" ? direction : null}>
                    Visit Date
                  </Table.HeaderCell>
                  <Table.HeaderCell width={1} sorted={column === "username" ? direction : null}>
                    UserName
                  </Table.HeaderCell>
                  <Table.HeaderCell width={1} sorted={column === "payor" ? direction : null}>
                    Payor
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {errors.map(
                  ({
                    id,
                    errorType,
                    visitId,
                    mrn,
                    firstName,
                    lastName,
                    visitStatus,
                    visitType,
                    providerName,
                    centerName,
                    patientLevel,
                    proceduresAndDiagnoses,
                    appointmentExternalId,
                    signedOnDate,
                    labName,
                    visitDate,
                    username,
                    payor,
                    patientId
                  }) => (
                    <Table.Row key={id}>
                      <Table.Cell width={1} className="actions-column" style={{ padding: "0", textAlign: "center" }}>
                        <Icon
                          name="undo"
                          color="blue"
                          onClick={() => this.openRequeueConfirmationModal(id)}
                          style={{ margin: "2px" }}
                        />
                        <Icon name="edit" color="blue" onClick={() => this.handleEdit(id)} style={{ margin: "2px" }} />
                        <Icon
                          name="close"
                          color="blue"
                          onClick={() => this.openDismissConfirmationModal(id)}
                          style={{ margin: "2px" }}
                        />
                      </Table.Cell>
                      <Table.Cell width={1} className="id-column">
                        <a role="button" tabIndex={0} onClick={() => this.showErrorDetailsModal(id)}>
                          {id}
                        </a>
                      </Table.Cell>
                      <Table.Cell width={3}>{errorType}</Table.Cell>
                      <Table.Cell width={3}>
                        <Link to={`/current-patients/${patientId}/visits/vid/${visitId}`}>{visitId}</Link>
                      </Table.Cell>
                      <Table.Cell width={3}>{mrn}</Table.Cell>
                      <Table.Cell width={4}>
                        {firstName} {lastName}
                      </Table.Cell>
                      <Table.Cell width={3}>{visitStatus}</Table.Cell>
                      <Table.Cell width={3}>{visitType}</Table.Cell>
                      <Table.Cell width={3}>{providerName}</Table.Cell>
                      <Table.Cell width={3}>{centerName}</Table.Cell>
                      <Table.Cell width={3}>{patientLevel}</Table.Cell>
                      <Table.Cell width={4}>{this.renderProceduresAndDiagnoses(proceduresAndDiagnoses)}</Table.Cell>
                      <Table.Cell width={3}>{appointmentExternalId}</Table.Cell>
                      <Table.Cell width={3}>{signedOnDate}</Table.Cell>
                      <Table.Cell width={3}>{labName}</Table.Cell>
                      <Table.Cell width={2}>{moment(visitDate).format(dateWithFullTimeFormat)}</Table.Cell>
                      <Table.Cell width={1}>{username}</Table.Cell>
                      <Table.Cell width={1}>{payor}</Table.Cell>
                    </Table.Row>
                  )
                )}
              </Table.Body>
              <Table.Footer />
            </Table>

            <div style={{ padding: "0 0 1em 0", textAlign: "center" }}>
              <Pagination
                boundaryRange={2}
                activePage={pageNumber}
                ellipsisItem={`...`}
                size="mini"
                firstItem={null}
                lastItem={null}
                siblingRange={1}
                totalPages={Math.ceil(totalCount / pageSize)}
                onPageChange={this.handlePaginationChange}
              />
            </div>
          </React.Fragment>
        )}
      </div>
    );
  }

  render() {
    const { filters, orderBySourceHandler } = this.state;
    const {
      athenaServiceErrorsOnly,
      allClaims: { errors }
    } = this.props;
    const formatedErrors = errors.map((e) => {
      const processedError = {};
      Object.keys(e).forEach((key) => {
        if (e[key] === null || e[key] === undefined) processedError[key] = `null`;
        else {
          let str = ``;
          try {
            JSON.parse(e[key]);
            str = JSON.stringify(e[key]);
          } catch (_) {
            str = e[key].toString();
          }
          processedError[key] = str
            .replace(/,/g, ";")
            .replace(/\\r\\n/g, "")
            .replace(/\\"/g, "")
            .replace(/"/g, "");
        }
      });
      return processedError;
    });

    return (
      <React.Fragment>
        <div className="header-wrapper">
          <h2>{`${athenaServiceErrorsOnly ? `Athena` : `Center`} Claim Errors`}</h2>
        </div>
        <div className="content-wrapper errors">
          <Grid>
            {searchByOptions.length > 0 && (
              <React.Fragment>
                <Grid.Row>
                  <Grid.Column width={11}>
                    <Form>
                      <Grid>
                        <Grid.Row style={{ paddingBottom: "0" }}>
                          <Grid.Column width={6}>
                            Date From
                            <br />
                            <Form.Group widths="equal">
                              <Form.Dropdown
                                fluid
                                selection
                                options={monthsOptions}
                                name="filters.from.month"
                                value={filters.from.month}
                                onChange={this.handleInput}
                                onFocus={this.handleFocus}
                              />
                              <Form.Dropdown
                                fluid
                                selection
                                options={daysOptions}
                                name="filters.from.day"
                                value={filters.from.day}
                                onChange={this.handleInput}
                                onFocus={this.handleFocus}
                              />
                              <Form.Dropdown
                                fluid
                                selection
                                options={yearsOptions}
                                name="filters.from.year"
                                value={filters.from.year}
                                onChange={this.handleInput}
                                onFocus={this.handleFocus}
                              />
                            </Form.Group>
                          </Grid.Column>
                          <Grid.Column width={6}>
                            To
                            <br />
                            <Form.Group widths="equal">
                              <Form.Dropdown
                                fluid
                                selection
                                options={monthsOptions}
                                name="filters.to.month"
                                value={filters.to.month}
                                onChange={this.handleInput}
                                onFocus={this.handleFocus}
                              />
                              <Form.Dropdown
                                fluid
                                selection
                                options={daysOptions}
                                name="filters.to.day"
                                value={filters.to.day}
                                onChange={this.handleInput}
                                onFocus={this.handleFocus}
                              />
                              <Form.Dropdown
                                fluid
                                selection
                                options={yearsOptions}
                                name="filters.to.year"
                                value={filters.to.year}
                                onChange={this.handleInput}
                                onFocus={this.handleFocus}
                              />
                            </Form.Group>
                          </Grid.Column>
                          <Grid.Column width={4}>
                            <label>&nbsp;</label>
                            <Form.Checkbox
                              label="Include Non Visit Claims"
                              name="filters.includeNonVisitClaims"
                              checked={filters.includeNonVisitClaims}
                              onChange={this.handleInput}
                              onFocus={this.handleFocus}
                            />
                          </Grid.Column>
                        </Grid.Row>
                        <Grid.Row style={{ paddingTop: "0", paddingBottom: "0" }}>
                          <Grid.Column width={4}>
                            Search by:
                            <br />
                            <Form.Field>
                              <Form.Dropdown
                                fluid
                                selection
                                options={searchByOptions}
                                name="filters.searchBy"
                                value={filters.searchBy}
                                onChange={this.handleInput}
                                onFocus={this.handleFocus}
                              />
                            </Form.Field>
                          </Grid.Column>
                          <Grid.Column width={8}>
                            {` `}
                            <br />
                            <Form.Input
                              name="filters.keyword"
                              placeholder="Search..."
                              value={filters.keyword}
                              onChange={this.handleInput}
                              onFocus={this.handleFocus}
                            />
                          </Grid.Column>
                          <Grid.Column width={4}>
                            {` `}
                            <br />
                            <Form.Button color="blue" onClick={this.handleUpddate}>
                              Update
                              <Icon name="search" style={{ marginLeft: "5px" }} />
                            </Form.Button>
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    </Form>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row style={{ paddingBottom: "0" }}>
                  <Grid.Column width={5}>
                    <Form.Checkbox
                      label="Sort by source handler"
                      name="orderBySourceHandler"
                      checked={orderBySourceHandler}
                      onChange={this.handleCheckbox}
                    />
                  </Grid.Column>
                  <Grid.Column width={11} floated="right" textAlign="right">
                    <CSVLink
                      ref={this.setCSVLinkRef}
                      filename="claims.csv"
                      data={formatedErrors}
                      // separator={";"}
                      style={{ display: "none" }}
                    />
                    <Button
                      icon="file excel"
                      className="transparent-button-icon"
                      content="Export"
                      onClick={this.handleExport}
                    />
                  </Grid.Column>
                </Grid.Row>
              </React.Fragment>
            )}
            <Grid.Row style={{ paddingTop: "0" }}>
              <Grid.Column style={{ overflowX: "auto", whitespace: "nowrap" }}>{this.renderErrors()}</Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    claims: state.claimErrors.claims,
    allClaims: state.claimErrors.allClaims
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...modalActions, ...adminActions, ...claimErrorsActions }, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageClaimErrorsPage);
