import React, { Component } from "react";
import { Table, Dropdown } from "semantic-ui-react";
import { Link } from "react-router-dom";
import _, { isEqual } from "lodash";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";

import * as visitActions from "../../../actions/visitActions";
import * as lookupActions from "../../../actions/lookupActions";
import * as modalActions from "../../../actions/modalActions";
import * as badgeActions from "../../../actions/badgeActions";
import { calculateTimeFromNow, isVisitTypeMedical } from "../../../helpers/index";
import { dateWithTimeFormat } from "../../../constants/miscellaneous";
import { PatientLevel } from "../../../ui";

export class StatusWatchVisitingTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visits: this.populateVisitsList(),
      column: null,
      direction: null,
      selectedRow: ""
    };
  }

  componentDidMount() {
    this.props.actions.loadVisitStatuses();
  }

  componentDidUpdate(prevProps) {
    const { refetch, resetRefetch, centerContext, allCenterVisits, visitingStatusSelected } = this.props;

    if (!isEqual(allCenterVisits.dataVisits, prevProps.allCenterVisits.dataVisits)) {
      this.populateVisitsList();
    }

    if (centerContext !== prevProps.centerContext || visitingStatusSelected !== prevProps.visitingStatusSelected) {
      this.populateVisitsList();
    }

    if (refetch && !prevProps.refetch) {
      resetRefetch();
      this.populateVisitsList();
    }
  }

  populateVisitsList = (resetSort = false) => {
    const {
      allCenterVisits: { dataVisits },
      visitingStatusSelected
    } = this.props;
    const visits = dataVisits.filter((v) => visitingStatusSelected.includes(v.visitStatusCode));
    if (this.state) {
      const column = this.state.column;
      if (resetSort) this.setState({ visits, selectedRow: "", direction: null, column: null });
      else if (column) {
        this.setState({ visits }, () => {
          this.handleSort(column, true);
        });
      } else {
        this.setState({ visits });
      }
    }
    return visits;
  };

  handleRowSelect = (e) => {
    const currentRow = e.currentTarget.getAttribute("data-index");
    const visitId = e.currentTarget.getAttribute("data-visit");
    this.setState({
      selectedRow: currentRow,
      selectedVisitId: visitId
    });
  };

  handleStatusChange = (e, data) => {
    const { value } = data;
    const visitIdSelect = this.state.selectedVisitId;

    if (value === "visitcomplete") {
      this.props.actions.showModal({
        type: "CONFIRMATION",
        props: {
          open: true,
          icon: "exclamation-triangle",
          iconColor: "warning",
          title: "Confirm Complete",
          buttonColor: "red",
          description: "Are you sure you want to end the visit?",
          onConfirm: () => {
            this.props.actions.updateVisitStatus(this.state.selectedVisitId, value);
          }
        }
      });
    }

    const currentItem = this.state.visits.find((i) => i.visitId === visitIdSelect);

    // check change visit status -> call API
    if (currentItem.visitStatusCode !== value) {
      this.props.actions.updateVisitStatus(visitIdSelect, value);
    }
  };

  handleSort = (clickedColumn, justSort = false) => {
    const { visits, column, direction } = this.state;
    const sorted = _.sortBy(visits, [clickedColumn]);

    if (justSort) {
      this.setState({ visits: direction === "ascending" ? sorted : sorted.reverse() });
      return;
    }

    if (column !== clickedColumn) {
      this.setState({
        visits: sorted,
        column: clickedColumn,
        direction: "ascending"
      });

      return;
    }
    const newDirection = direction === "ascending" ? "descending" : "ascending";
    this.setState({
      visits: newDirection === "ascending" ? sorted : sorted.reverse(),
      column: clickedColumn,
      direction: newDirection
    });
  };

  render() {
    const { visits, column, direction, selectedRow } = this.state;
    const { statuses } = this.props;
    return (
      <React.Fragment>
        {visits && visits.length > 0 ? (
          <div>
            <h2>Status Watch</h2>
            <div className="divStatusWatch">
              <Table selectable sortable className="visits-table">
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell
                      sorted={column === "visitTime" ? direction : null}
                      onClick={() => this.handleSort("visitTime")}
                    >
                      Visit Start/Status Time
                    </Table.HeaderCell>
                    <Table.HeaderCell
                      sorted={column === "patientFirstName" ? direction : null}
                      onClick={() => this.handleSort("patientFirstName")}
                    >
                      Patient Name
                    </Table.HeaderCell>
                    <Table.HeaderCell
                      sorted={column === "provider" ? direction : null}
                      onClick={() => this.handleSort("provider")}
                    >
                      Provider
                    </Table.HeaderCell>
                    <Table.HeaderCell
                      sorted={column === "insurance" ? direction : null}
                      onClick={() => this.handleSort("insurance")}
                    >
                      Insurance
                    </Table.HeaderCell>
                    <Table.HeaderCell
                      sorted={column === "mainPrescriber" ? direction : null}
                      onClick={() => this.handleSort("mainPrescriber")}
                    >
                      Main Prescriber
                    </Table.HeaderCell>
                    <Table.HeaderCell
                      sorted={column === "visitStatus" ? direction : null}
                      onClick={() => this.handleSort("visitStatus")}
                    >
                      Status
                    </Table.HeaderCell>
                    <Table.HeaderCell
                      sorted={column === "visitTypeName" ? direction : null}
                      onClick={() => this.handleSort("visitTypeName")}
                    >
                      Visit Type
                    </Table.HeaderCell>
                    <Table.HeaderCell className="text-center">Level</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {visits.map(
                    (
                      {
                        visitTime,
                        patientFirstName,
                        patientLastName,
                        mainPrescriber,
                        visitStatus,
                        visitStatusCode,
                        visitType,
                        visitTypeName,
                        level,
                        bhLevel,
                        patientId,
                        visitId,
                        provider,
                        insurance
                      },
                      i
                    ) => (
                      <Table.Row
                        key={visitId}
                        data-index={i}
                        data-visit={visitId}
                        onClick={this.handleRowSelect}
                        className={String(i) === selectedRow ? "selected " : ""}
                      >
                        <Table.Cell data-label="Visit Start/Status Time">
                          <span>
                            {moment(visitTime).format(dateWithTimeFormat)}
                            <span> | </span>
                            {calculateTimeFromNow(visitTime)}
                          </span>
                        </Table.Cell>
                        <Table.Cell data-label="Patient Name">
                          <span>
                            <Link
                              to={`/current-patients/${patientId}/demographics`}
                              style={{ textDecoration: "none" }}
                              key={patientId}
                            >
                              {`${patientFirstName} ${patientLastName}`}
                            </Link>
                          </span>
                        </Table.Cell>
                        <Table.Cell data-label="Provider">
                          <span>{provider}</span>
                        </Table.Cell>
                        <Table.Cell data-label="Insurance">
                          <span>{insurance}</span>
                        </Table.Cell>
                        <Table.Cell data-label="Main Prescriber">
                          <span>{mainPrescriber}</span>
                        </Table.Cell>
                        <Table.Cell data-label="Status">
                          <span>
                            {String(i) === selectedRow ? (
                              <Dropdown
                                name="visitStatus"
                                placeholder=""
                                fluid
                                selection
                                options={statuses}
                                width={12}
                                onChange={this.handleStatusChange}
                                value={visitStatusCode}
                              />
                            ) : (
                              <span>{visitStatus}</span>
                            )}
                          </span>
                        </Table.Cell>
                        <Table.Cell data-label="Visit Type">
                          <span>{visitTypeName}</span>
                        </Table.Cell>
                        <Table.Cell data-label="Level" className="text-center">
                          {isVisitTypeMedical(visitType) ? (
                            <PatientLevel level={level || "orange"} size="sm" />
                          ) : (
                            bhLevel && <PatientLevel level={bhLevel} size="sm" bh />
                          )}
                        </Table.Cell>
                      </Table.Row>
                    )
                  )}
                </Table.Body>
                <Table.Footer />
              </Table>
            </div>
          </div>
        ) : null}
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    statuses: state.lookups.visitStatuses,
    centerContext: state.userPreference.centerContext,
    visitingStatusSelected: state.visits.visitingStatusSelected,
    allCenterVisits: state.visits.allCenterVisits
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...visitActions,
        ...lookupActions,
        ...modalActions,
        ...badgeActions
      },
      dispatch
    )
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(StatusWatchVisitingTable);
