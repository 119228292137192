import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Button, Grid } from "semantic-ui-react";
import * as miscActions from "../../../actions/miscActions";
import { isEmpty } from "../../../helpers";

class StatePdmp extends Component {
  state = {
    disableShowReportButton: false
  };

  componentDidUpdate(prevProps) {
    const {
      narxScores: { reportUrl }
    } = this.props;
    if (this.state.disableShowReportButton && reportUrl && reportUrl !== prevProps.narxScores.reportUrl) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ disableShowReportButton: false });
      window.open(reportUrl, "_blank");
    }
  }

  componentWillUnmount() {
    this.props.actions.clearNarxScores();
  }

  handleShowReport = () => {
    this.setState({ disableShowReportButton: true });
    this.props.actions.clearNarxScores();
    this.props.actions.queryNarxScores(this.props.patientId);
  };

  render() {
    const {
      compact,
      narxScores: { scores, error, errorMessage }
    } = this.props;
    const { disableShowReportButton } = this.state;

    return (
      <Grid style={{ backgroundColor: "#f9fafb", marginBottom: "1em" }}>
        {!compact && error === true && (
          <Grid.Row>
            <Grid.Column width={16} textAlign="center">
              {errorMessage}
            </Grid.Column>
          </Grid.Row>
        )}
        {!compact && error !== true && isEmpty(scores) && (
          <Grid.Row>
            <Grid.Column width={16} textAlign="center">
              Loading...
            </Grid.Column>
          </Grid.Row>
        )}
        {error !== true && !isEmpty(scores) && (
          <React.Fragment>
            {!compact && (
              <Grid.Row className="no-padding">
                <Grid.Column width={16}>
                  <label>NarxCare:</label>
                </Grid.Column>
              </Grid.Row>
            )}
            <Grid.Row style={{ paddingTop: "0", paddingBottom: ".5em" }}>
              <Grid.Column width={compact ? 10 : 3} />
              {!compact && (
                <Grid.Column width={7}>
                  <table>
                    <tbody>
                      {scores.map(score => (
                        <tr key={score.key}>
                          <td>{score.key}</td>
                          <td>{score.value}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </Grid.Column>
              )}
              <Grid.Column width={6} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                <Button
                  color="grey"
                  content={compact ? "PDMP: Show Report" : "Show Report"}
                  onClick={this.handleShowReport}
                  loading={error !== true && disableShowReportButton}
                  disabled={error !== true && disableShowReportButton}
                  style={{ padding: ".75em .5em", marginTop: ".5em", wordBreak: "break-word" }}
                />
              </Grid.Column>
            </Grid.Row>
          </React.Fragment>
        )}
      </Grid>
    );
  }
}

function mapStateToProps(state, props) {
  return {
    patientId: props.patientId ? props.patientId : (state.patient.currentPatient || {}).patientId || null,
    recurringRx: state.prescriptions.recurringPrescriptions,
    currentRx: state.prescriptions.currentPrescriptions,
    centerId: state.demographics.homeCenterId,
    mainPrescriberId: state.demographics.prescribingProvider,
    narxScores: state.misc.narxScores,
    processing: state.ajaxCallsInProgress > 0
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...miscActions }, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StatePdmp);
