import { bindActionCreators } from "@reduxjs/toolkit";
import { isEmpty, set } from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import MaskedInput from "react-text-mask";
import * as providerActions from "../../actions/providerActions";
import { phoneMask, zip4Mask, zipMask } from "../../constants/validation";
import { hideModal } from "../../helpers";
import { Button, Modal, Form, Row, Col } from "../../ui";

export class RenewLicenseModal extends Component {
  state = {
    model: {
      firstName: "",
      middleName: "",
      lastName: "",
      street1: "",
      street2: "",
      city: "",
      state: "",
      country: "",
      zip: "",
      zip4: "",
      phoneNumber: ""
    },
    renewPressed: false
  };

  handleClose = () => {
    hideModal();
  };

  handleInput = (e) => {
    const updatedState = { ...this.state };
    set(updatedState, e.target.name, e.target.value);
    this.setState(updatedState);
  };

  handleMaskedInput = (e) => {
    const {
      target: { name, value }
    } = e;
    const updatedState = { ...this.state };
    set(updatedState, name, value);
    this.setState(updatedState);
  };

  handleRenew = () => {
    this.setState({ renewPressed: true });
    const { zip, zip4, phoneNumber } = this.state.model;
    this.props.actions
      .renewIdentityProofing(this.props.userId, {
        ...this.state.model,
        zip: `${zip}-${zip4}`,
        phoneNumber: phoneNumber.replace(/\D+/g, "") // unmask
      })
      .then(() => {
        this.handleClose();
      })
      .finally(() => {
        this.setState({ renewPressed: false });
      });
  };

  render() {
    const { stateOptions } = this.props;
    const { model, renewPressed } = this.state;
    const { firstName, lastName, street1, city, state, country, zip, zip4, phoneNumber } = model;
    const disableRenewButton =
      isEmpty(firstName) ||
      isEmpty(lastName) ||
      isEmpty(street1) ||
      isEmpty(city) ||
      isEmpty(state) ||
      isEmpty(country) ||
      isEmpty(zip) ||
      isEmpty(zip4) ||
      isEmpty(phoneNumber) ||
      zip.replace(/_/g, "").length !== 5 ||
      zip4.replace(/_/g, "").length !== 4 ||
      phoneNumber.replace(/_/g, "").length !== 14;

    return (
      <Modal className="intake-modal" size="lg" backdrop="static" keyboard={false} onHide={this.handleClose} show>
        <Modal.Header closeButton>
          <Modal.Title>{`Renew Exostar License`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row>
              <Form.Group as={Col}>
                <Form.Label>First name</Form.Label>
                <Form.Control
                  type="text"
                  name="model.firstName"
                  value={model.firstName}
                  onChange={this.handleInput}
                  isInvalid={isEmpty(model.firstName)}
                />
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label>Middle name</Form.Label>
                <Form.Control
                  type="text"
                  name="model.middleName"
                  value={model.middleName}
                  onChange={this.handleInput}
                />
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label sm="2">Last name</Form.Label>
                <Form.Control
                  type="text"
                  name="model.lastName"
                  value={model.lastName}
                  onChange={this.handleInput}
                  isInvalid={isEmpty(model.lastName)}
                />
              </Form.Group>
            </Row>

            <Row>
              <Form.Group as={Col}>
                <Form.Label>Address 1</Form.Label>
                <Form.Control
                  type="text"
                  name="model.street1"
                  value={model.street1}
                  onChange={this.handleInput}
                  isInvalid={isEmpty(model.street1)}
                />
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label sm="2">Address 2</Form.Label>
                <Form.Control type="text" name="model.street2" value={model.street2} onChange={this.handleInput} />
              </Form.Group>
            </Row>

            <Row>
              <Form.Group as={Col}>
                <Form.Label sm="2">State</Form.Label>
                <Form.Select
                  name="model.state"
                  placeholder="Select state"
                  value={model.state}
                  options={stateOptions}
                  onChange={this.handleInput}
                  isInvalid={isEmpty(model.state)}
                />
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label>City</Form.Label>
                <Form.Control
                  type="text"
                  name="model.city"
                  value={model.city}
                  onChange={this.handleInput}
                  isInvalid={isEmpty(model.city)}
                />
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label sm="2">Country</Form.Label>
                <Form.Control
                  type="text"
                  name="model.country"
                  value={model.country}
                  onChange={this.handleInput}
                  isInvalid={isEmpty(model.country)}
                />
              </Form.Group>
            </Row>

            <Row>
              <Form.Group as={Col}>
                <Form.Label>Zip</Form.Label>
                <MaskedInput
                  name="model.zip"
                  mask={zipMask}
                  value={model.zip}
                  className={`form-control ${model.zip.replace(/_/g, "").length !== 5 ? `is-invalid` : ``}`}
                  onChange={this.handleMaskedInput}
                  required
                />
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label>Zip4</Form.Label>
                <MaskedInput
                  name="model.zip4"
                  mask={zip4Mask}
                  value={model.zip4}
                  className={`form-control ${model.zip4.replace(/_/g, "").length !== 4 ? `is-invalid` : ``}`}
                  onChange={this.handleMaskedInput}
                  required
                />
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label sm="2">Phone Number</Form.Label>
                <MaskedInput
                  name="model.phoneNumber"
                  mask={phoneMask}
                  value={model.phoneNumber}
                  className={`form-control ${model.phoneNumber.replace(/_/g, "").length !== 14 ? `is-invalid` : ``}`}
                  onChange={this.handleMaskedInput}
                  required
                />
              </Form.Group>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={this.handleClose}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={this.handleRenew}
            className="ms-auto"
            loading={renewPressed}
            disabled={disableRenewButton || renewPressed}
          >
            Renew
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...providerActions
      },
      dispatch
    )
  };
}

export default connect(null, mapDispatchToProps)(RenewLicenseModal);
