import React, { Component } from "react";
import { Button, Header, Modal, Form, Grid, Item, Table } from "semantic-ui-react";
import MaskedInput from "react-text-mask";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import shortid from "shortid";
import { head, isEmpty } from "lodash";

import * as modalActions from "../../../actions/modalActions";
import referenceValues from "../../../api/mockReferenceValues";
import { zip, zipMask } from "../../../constants/validation";
import { removeSpecialCharacters, updateErrors } from "../../../helpers";
import api from "../../../api";
import { addPatientDefaultPharmacy } from "../../../actions/prescriptionActions";
import PaginationRow from "../../reusable/PaginationRow";

import "./PatientPharmacyModal.css";
import "../../reusable/Modal.css";

const inputValidation = {
  zip
};

const getProximityZip = (proximityOption, searchZip, patientZip, clinicZip) => {
  if (proximityOption === "Patient") {
    return patientZip;
  }

  if (proximityOption === "Clinic") {
    return clinicZip;
  }

  return searchZip;
};

export class PatientPharmacyModal extends Component {
  state = {
    searchCriteria: {
      pharmacyType: "Retail",
      zip: "",
      pharmacyName: "",
      proximityMiles: -1,
      controlledSubstanceOnly: true,
      proximityOption: "None",
      requestedPageSize: 5,
      requestedPage: 1
    },
    selectedPharmacy: {},
    errors: {},
    currentPatient: {},
    centerDetails: {},
    patientDefaultPharmacy: {},
    isLoading: false,
    pharmacies: {
      results: [],
      currentPage: 0,
      actualPageSize: 0,
      totalRecords: 0
    }
  };

  componentDidMount() {
    const { patientId, centerId } = this.props;
    this.setState(prevState => ({ isLoading: !prevState.isLoading }));

    if (!isEmpty(patientId) && !isEmpty(centerId)) {
      Promise.all([
        api.fetchPatientDemographics(patientId),
        api.fetchCenter(centerId),
        api.fetchPatientDefaultPharmacy(patientId)
      ])
        .then(([currentPatient, centerDetails, patientDefaultPharmacy]) =>
          this.setState(prevState => ({
            currentPatient,
            centerDetails,
            patientDefaultPharmacy,
            isLoading: !prevState.isLoading
          }))
        )
        .catch(() => {});
    }
  }

  onPageChange = (_, { activePage }) => {
    this.handleSearch(null, null, activePage);
  };

  handleSearch = (_, __, activePage = 1) => {
    this.setState(prevState => ({ isLoading: !prevState.isLoading }));
    api
      .fetchPharmacies(this.buildAndLaunchPharmaciesSearch(activePage))
      .then(pharmacies => this.setState(prevState => ({ isLoading: !prevState.isLoading, pharmacies })));
  };

  buildAndLaunchPharmaciesSearch = activePage => {
    const {
      searchCriteria,
      searchCriteria: { proximityMiles },
      currentPatient: { associatedAddresses = [] },
      centerDetails
    } = this.state;
    const { proximityOption, ...rest } = searchCriteria;
    const { zip: patientZip } = associatedAddresses.find(a => a.isBilling) || head(associatedAddresses) || {};

    return {
      ...rest,
      proximityMiles: proximityMiles === -1 ? null : proximityMiles,
      requestedPage: activePage,
      zip: getProximityZip(proximityOption, rest.zip, patientZip, centerDetails.zip)
    };
  };

  handleUpdate = () => {
    let { selectedPharmacy } = this.state;
    if (selectedPharmacy.zip.length > 5) {
      selectedPharmacy = {
        ...selectedPharmacy,
        zip: selectedPharmacy.zip.substring(0, 5),
        zip4: selectedPharmacy.zip.substring(selectedPharmacy.zip.length - 4)
      };
    }
    const {
      patientId,
      actions: { addPatientDefaultPharmacy: insertPatientDefaultPharmacy }
    } = this.props;
    const { services, ...rest } = selectedPharmacy;
    this.setState({ isLoading: true });

    insertPatientDefaultPharmacy(patientId, {
      ...rest,
      ePrescribeServices: services
    })
      .then(() => this.setState({ selectedPharmacy, isLoading: false }))
      .then(this.handleClose);
  };

  handlePharmacySelect = e => {
    const { pharmacies: { results = [] } = {} } = this.state;
    const pharmacykey = e.currentTarget.getAttribute("pharmacykey");
    const selectedPharmacy = results.find(pharmacy => pharmacy.pharmacyKey === pharmacykey);
    this.setState({ selectedPharmacy });
  };

  handleInput = (e, data) => {
    const { name: field, value, required, checked, type } = data;
    const {
      searchCriteria,
      searchCriteria: { proximityOption }
    } = this.state;
    const updatedSearchCriteria = { ...searchCriteria, [field]: type === "checkbox" ? checked : value };

    return this.setState({
      searchCriteria: {
        ...updatedSearchCriteria,
        zip: proximityOption !== updatedSearchCriteria.proximityOption ? "" : updatedSearchCriteria.zip
      },
      error: updateErrors(
        field,
        type === "checkbox" ? checked : value,
        required,
        this.state.errors,
        inputValidation[field]
      )
    });
  };

  handleMaskedInput = e => {
    const {
      type,
      target: { name: field, required, value }
    } = e;
    let errors = Object.assign({}, this.state.errors);
    const searchCriteria = Object.assign({}, this.state.searchCriteria, {
      [field]: value
    });

    if (type !== "focus" && type !== "blur") {
      errors = updateErrors(
        field,
        required ? removeSpecialCharacters(value) : value,
        required,
        errors,
        inputValidation[field]
      );
    }

    this.setState({ searchCriteria, errors });
  };

  handleClose = () => {
    const {
      actions: { hideModal },
      multiple
    } = this.props;
    hideModal(multiple);
  };

  render() {
    const { open } = this.props;
    const {
      selectedPharmacy,
      searchCriteria: {
        pharmacyName,
        controlledSubstanceOnly,
        pharmacyType,
        proximityMiles,
        proximityOption,
        zip: zipCode,
        requestedPageSize
      },
      errors,
      pharmacies: { results: pharmacyData = [], totalRecords, currentPage },
      isLoading
    } = this.state;

    return (
      <Modal className="patient-pharmacy-modal" open={open} onClose={this.handleClose} closeIcon size="large">
        <Header content="Patient Pharmacy" />
        <Modal.Content>
          <Grid stackable>
            <Grid.Row>
              {selectedPharmacy.pharmacyKey ? (
                <Grid.Column width={4} className="smallerFont">
                  <h3 className="currentSelectedHeader">Current Selected Pharmacy:</h3>
                  <h3>{selectedPharmacy.pharmacyName || "Selected Pharmacy"}</h3>
                  <h4>{selectedPharmacy.address1}</h4>
                  <h4>{selectedPharmacy.address2}</h4>
                  <h4>
                    {selectedPharmacy.city}, {selectedPharmacy.state} {selectedPharmacy.zip} {selectedPharmacy.zip4}
                  </h4>
                  <h4>{selectedPharmacy.phone}</h4>
                  <Item.Group>
                    {selectedPharmacy.services ? (
                      <Item>
                        <Item.Content>
                          <Item.Header>Services</Item.Header>
                          <Item.Description>
                            {selectedPharmacy.services.map(v => (
                              <div key={`rand-${shortid.generate()}`}>{v}</div>
                            ))}
                          </Item.Description>
                        </Item.Content>
                      </Item>
                    ) : (
                      ""
                    )}
                    {selectedPharmacy.specialties ? (
                      <Item>
                        <Item.Content>
                          <Item.Header>Specialties</Item.Header>
                          <Item.Description>
                            {selectedPharmacy.specialties.map(s => (
                              <div key={`rand-${shortid.generate()}`}>{s}</div>
                            ))}
                          </Item.Description>
                        </Item.Content>
                      </Item>
                    ) : (
                      ""
                    )}
                  </Item.Group>
                </Grid.Column>
              ) : (
                <Grid.Column width={4} className="smallerFont">
                  <h3 className="currentSelectedHeader">Current Selected Pharmacy</h3>
                  <h4>No Pharmacy Selected</h4>
                </Grid.Column>
              )}
              <Grid.Column width={12}>
                <Form className="lightGreyBackground">
                  <h3>Pharmacy Search</h3>
                  <Form.Group>
                    <Form.Field width={4}>
                      <Form.Dropdown
                        fluid
                        selection
                        label="Pharmacy Type"
                        id="input-pharmacyType"
                        placeholder="Select..."
                        name="pharmacyType"
                        options={referenceValues.pharmacyTypes}
                        value={pharmacyType}
                        onChange={this.handleInput}
                      />
                    </Form.Field>
                    <Form.Field width={6}>
                      <Form.Input
                        label="Search Pharmacy Name or Phone Number"
                        name="pharmacyName"
                        id="input-pharmacyName"
                        placeholder=""
                        value={pharmacyName}
                        onChange={this.handleInput}
                      />
                    </Form.Field>
                    <Form.Field width={4}>
                      <Form.Checkbox
                        className="cbxLongLabel"
                        id="input-controlledSubstanceOnly"
                        label="Accepts e-Prescriptions for Controlled Substances"
                        name="controlledSubstanceOnly"
                        checked={controlledSubstanceOnly}
                        onChange={this.handleInput}
                      />
                    </Form.Field>
                  </Form.Group>
                  <Form.Group>
                    <Form.Field width={4}>
                      <Form.Dropdown
                        fluid
                        selection
                        label="Proximity"
                        placeholder="Select..."
                        name="proximityOption"
                        value={proximityOption}
                        options={referenceValues.proximities}
                        onChange={this.handleInput}
                      />
                    </Form.Field>
                    <Form.Field width={4}>
                      <Form.Field className={Object.prototype.hasOwnProperty.call(errors, "zip") ? "error field" : ""}>
                        <label
                          className={proximityOption === "Zipcode" ? undefined : "disabled-label"}
                          htmlFor="input-zip"
                        >
                          Zip Code
                        </label>
                        <MaskedInput
                          name="zip"
                          id="input-zip"
                          mask={zipMask}
                          guide={false}
                          value={zipCode}
                          onChange={this.handleMaskedInput}
                          disabled={proximityOption !== "Zipcode"}
                        />
                      </Form.Field>
                    </Form.Field>
                    <Form.Field width={4}>
                      <Form.Dropdown
                        fluid
                        selection
                        label="Max distance"
                        placeholder="Select..."
                        name="proximityMiles"
                        value={proximityMiles}
                        options={referenceValues.distances}
                        onChange={this.handleInput}
                      />
                    </Form.Field>
                    <Form.Field>
                      <Button className="searchButton" onClick={this.handleSearch} color="blue" loading={isLoading}>
                        Search
                      </Button>
                    </Form.Field>
                  </Form.Group>
                </Form>
                <Header as="h5">
                  {totalRecords >= 0
                    ? `There are ${pharmacyData.length} pharmacies in this search range out of ${totalRecords} possibilities.`
                    : "Enter search criteria to view pharmacies."}
                </Header>
                <div className="pharmacy-table-container">
                  <Table className="pharmacy-search-results" selectable>
                    <Table.Header>
                      <Table.Row columns={3}>
                        <Table.Cell width={6} className="reducedHeader">
                          <h5>Name, Address, Phone</h5>
                        </Table.Cell>
                        <Table.Cell width={5} className="reducedHeader">
                          <h5>e-Prescribing Services</h5>
                        </Table.Cell>
                        <Table.Cell width={3} className="reducedHeader">
                          <h5>Specialties</h5>
                        </Table.Cell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {pharmacyData.map(p => (
                        <Table.Row
                          columns={3}
                          className={selectedPharmacy.pharmacyKey === p.pharmacyKey ? "lightGreyBackground" : ""}
                          key={`rand-${shortid.generate()}`}
                          pharmacykey={p.pharmacyKey}
                          onClick={this.handlePharmacySelect}
                        >
                          <Table.Cell key={p.pharmacyKey} width={6}>
                            <h3>{p.pharmacyName}</h3>
                            <h4>{p.name}</h4>
                            <h5>{p.address1}</h5>
                            <h5>{p.address2}</h5>
                            <h5>
                              {p.city}, {p.state} {p.zip}
                            </h5>
                            <h5>{p.phone}</h5>
                          </Table.Cell>
                          <Table.Cell width={5}>
                            <ul>
                              {p.services.map(v => (
                                <li key={`rand-${shortid.generate()}`}>{v}</li>
                              ))}
                            </ul>
                          </Table.Cell>
                          <Table.Cell width={3}>
                            <ul>
                              {p.specialties.map(s => (
                                <li key={`rand-${shortid.generate()}`}>{s}</li>
                              ))}
                            </ul>
                          </Table.Cell>
                        </Table.Row>
                      ))}
                    </Table.Body>
                    <Table.Footer>
                      <Table.Row>
                        <Table.Cell textAlign="center" colSpan={3}>
                          <PaginationRow
                            requestedPage={currentPage}
                            totalRecords={totalRecords}
                            resultsPerPage={requestedPageSize}
                            onPageChange={this.onPageChange}
                          />
                        </Table.Cell>
                      </Table.Row>
                    </Table.Footer>
                  </Table>
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.handleClose}>Cancel</Button>
          <Button color="blue" disabled={!selectedPharmacy.pharmacyKey} onClick={this.handleUpdate} loading={isLoading}>
            Update
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...modalActions, addPatientDefaultPharmacy }, dispatch)
  };
}

export default connect(null, mapDispatchToProps)(PatientPharmacyModal);
