import React from "react";
import { Icon, Header } from "semantic-ui-react";
import { toast } from "react-toastify";

import { toastMessageOptions } from "../../constants/toastconfig";

import "./popUpMessage.css";

const Message = ({ message }) => (
  <Header as="h4" color="orange">
    <Icon name="warning sign" color="orange" />
    <Header.Content>{message}</Header.Content>
  </Header>
);

export const showPopupMessage = message =>
  toast(<Message message={message} />, {
    ...toastMessageOptions,
    className: "notification-background",
    progressClassName: "progress-bar-color"
  });

export default {
  showPopupMessage
};
