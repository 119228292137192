import moment from "moment";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "@reduxjs/toolkit";
import { dateFormat, datetimeFormatRemoveZeroInHour } from "../../constants/miscellaneous";
import * as patientHistoryActions from "../../actions/patientHistoryActions";
import * as lookupActions from "../../actions/lookupActions";
import { getLookupText, isVisitTypeMedical, patientName } from "../../helpers";
import { Card, Col, Container, Icon, OverlayTrigger, Row, Tooltip } from "../../ui";
import "./PatientSummary.css";
// import Notes from "../common/Notes";

export class PatientInfo extends Component {
  componentDidMount() {
    const { patientId } = this.props;
    this.props.actions.loadReactions();
    this.props.actions.loadAllergyHist(patientId);
    this.props.actions.loadMedication(patientId);
  }

  sortVisits = (visits) =>
    visits.sort((a, b) => (a.visitDateTime < b.visitDateTime ? 1 : a.visitDateTime > b.visitDateTime ? -1 : 0));

  renderVisitSummary = (_visit) => (
    <>
      <div className="visit-item-container">
        <p className="m-0 p-0">{moment(_visit.visitDateTime).format(datetimeFormatRemoveZeroInHour)}</p>
        <p className="m-0 p-0">
          {getLookupText(_visit.visitType, this.props.visitTypes)}{" "}
          {_visit.visitStatus && _visit.visitStatus.visitStatus === "visitcanceled" && (
            <span className="spn-visit-cancelled">
              {getLookupText(_visit.visitStatus.visitStatus, this.props.visitStatuses)}
            </span>
          )}
          {_visit.selectedTemplateName && (
            <span>
              <br />
              {`(${_visit.selectedTemplateName})`}
            </span>
          )}
        </p>
        <p className="provider-name m-0 p-0">{`${_visit.prescribingProvider.firstName} ${_visit.prescribingProvider.lastName}`}</p>
      </div>
    </>
  );

  renderAllergies = () => (
    <table>
      <tbody>
        {this.props.allergyHistory.map(({ itemId, allergySource, reactionType }) => (
          <tr key={itemId}>
            <td data-label="Medication/allergen" className="allergy-row-item" colSpan={2}>
              <strong>{allergySource}</strong>
            </td>
            <td data-label="Reaction" colSpan={3}>
              {getLookupText(reactionType, this.props.reactionTypes)}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );

  renderMedications = () => (
    <table>
      <tbody>
        {this.props.medicationHistory.map(({ itemId, drugName, strength, startDate, endDate }) => (
          <tr key={itemId} className="medication-row-item">
            <td className="drug-name" data-label="Medications">
              <strong>{drugName} </strong>
              {strength}
            </td>
            <td className="medication-date" data-label="Start">
              <strong>{startDate && moment(startDate).format(dateFormat)}</strong>
            </td>
            <td className="medication-date" data-label="End">
              <strong>{endDate && moment(endDate).format(dateFormat)}</strong>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );

  render() {
    const {
      patient: { patientFirstName, patientPreferredName, patientMiddleName, patientLastName, suffix },
      patientVisits
    } = this.props;
    const { totalNumberOfVisits, totalNumberOfEpsiodesOfCare, visits } = patientVisits;
    const medicalVisits = visits.filter(
      (v) => isVisitTypeMedical(v.visitType) && v.visitStatus.visitStatus === "visitcomplete"
    );
    const nonMedicalVisits = visits.filter(
      (v) =>
        !isVisitTypeMedical(v.visitType) &&
        v.visitType !== "progressnote" &&
        v.visitStatus.visitStatus !== "visitcanceled"
    );
    const randomVisits = visits.filter((v) => v.visitType === "random");
    const sortedMedicalVisits = this.sortVisits(medicalVisits);
    const sortedNonMedicalVisits = this.sortVisits(nonMedicalVisits);
    const sortedRandomVisits = this.sortVisits(randomVisits);
    return (
      <Container className="m-0 p-0">
        <Row className="m-0 p-0">
          <Col width={12}>
            <Row>
              <Col width={12}>
                <h4>
                  {patientName(patientFirstName, patientPreferredName, patientMiddleName, patientLastName, suffix)}
                </h4>
              </Col>
            </Row>
            <Row>
              <Col width={12}>
                <h5>Total # of visits: {totalNumberOfVisits}</h5>
                <h5>Total # of epsiodes of care: {totalNumberOfEpsiodesOfCare}</h5>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col width={6}>
                <Card border="primary">
                  <Card.Header>
                    Last Medical Visit
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id="tooltip-1">
                          Only <strong>complete</strong> visits are considered.
                        </Tooltip>
                      }
                    >
                      <Icon name="info-lg" className="float-end" />
                    </OverlayTrigger>
                  </Card.Header>
                  <Card.Body>
                    {sortedMedicalVisits.length ? this.renderVisitSummary(sortedMedicalVisits[0]) : `No medical visits`}
                  </Card.Body>
                </Card>
              </Col>
              <Col width={6}>
                <Card border="primary">
                  <Card.Header>
                    Last BH visit
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id="tooltip-2">
                          <strong>General notes</strong> are ignored.
                        </Tooltip>
                      }
                    >
                      <Icon name="info-lg" className="float-end" />
                    </OverlayTrigger>
                  </Card.Header>
                  <Card.Body>
                    {sortedNonMedicalVisits.length
                      ? this.renderVisitSummary(sortedNonMedicalVisits[0])
                      : `No BH visits`}
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col width={6}>
                <Card border="primary">
                  <Card.Header>Latest random visit</Card.Header>
                  <Card.Body>
                    {sortedRandomVisits.length ? this.renderVisitSummary(sortedRandomVisits[0]) : `No random visits`}
                  </Card.Body>
                </Card>
              </Col>
              <Col width={6}>
                <Card border="warning">
                  <Card.Header>Latest no show</Card.Header>
                  <Card.Body>{`Can't load info!`}</Card.Body>
                </Card>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col width={12}>
                <Card border="danger">
                  <Card.Header>Allergies</Card.Header>
                  <Card.Body>{this.props.allergyHistory.length ? this.renderAllergies() : `No allergies`}</Card.Body>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col width={12}>
                <Card border="info">
                  <Card.Header>Medications</Card.Header>
                  <Card.Body>
                    {this.props.medicationHistory.length ? this.renderMedications() : `No medications`}
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
          {/* <Col width={6} style={{ maxHeight: "550px", overflow: "auto" }}>
            <div>
              <h5>Notes</h5>
              <Notes noteType="Patient" />
            </div>
          </Col> */}
        </Row>
      </Container>
    );
  }
}

function mapStateToProps(state) {
  return {
    patient: state.patient.currentPatient,
    patientVisits: state.visits.patientVisits,
    visitTypes: state.lookups.visitTypes,
    visitStatuses: state.lookups.visitStatuses,
    medicationHistory: state.patientHistory.medicationHistory || [],
    allergyHistory: state.patientHistory.allergyHistory || [],
    reactionTypes: state.lookups.reactionTypes
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...patientHistoryActions, ...lookupActions }, dispatch)
  };
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PatientInfo));
