import React from "react";
import { Switch, Route, Link } from "react-router-dom";
import { Breadcrumb } from "semantic-ui-react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import AdminOptionsPage from "./AdminOptionsPage";
import ManageCentersPage from "../centers/ManageCentersPage";
import ManageUsersPage from "./ManageUsersPage";
import * as adminActions from "../../actions/adminActions";
import ReportPermission from "../report/ReportPermission";
import ManageSurveysPage from "./surveys/ManageSurveysPage";
import SurveyBuilderPage from "./surveys/SurveyBuilderPage";
import ManageErrorsPage from "../error/ManageErrorsPage";
import ManageShadowQueuePage from "../error/ManageShadowQueuePage";
import ManageProxyRequestsPage from "../error/ManageProxyRequestsPage";
import ManageUser from "./ManageUser";
import ProviderCenters from "./ProviderCenters";
import { roleGroups } from "../../constants/securityRoles";
import { checkRoles } from "../../helpers";

class Admin extends React.Component {
  constructor(props) {
    super(props);
    this.props.actions.pushItemToBreadcrumb({ key: "bc-admin", to: "/admin", text: "Admin" });
  }

  render() {
    const { match, breadcrumb, authRoles } = this.props;
    const showUserManagement = checkRoles(roleGroups.viewAdminPage, authRoles);
    const showAdminOptions = checkRoles(roleGroups.systemAdmin, authRoles);

    return (
      <div>
        {breadcrumb.length > 0 && (
          <Breadcrumb>
            {breadcrumb.map(item => (
              <React.Fragment key={item.key}>
                <Breadcrumb.Section active={item.active}>
                  {item.active ? item.text : <Link to={item.to}>{item.text}</Link>}
                </Breadcrumb.Section>
                {!item.active && <Breadcrumb.Divider />}
              </React.Fragment>
            ))}
          </Breadcrumb>
        )}
        <Switch>
          <Route
            path={match.path}
            render={() => (
              <AdminOptionsPage
                match={match}
                showUserManagement={showUserManagement}
                showAdminOptions={showAdminOptions}
              />
            )}
            exact
          />
          <Route path={`${match.path}/manage-centers`} component={ManageCentersPage} />
          <Route path={`${match.path}/manage-users/:userId/provider-centers`} component={ProviderCenters} exact />
          <Route path={`${match.path}/manage-users/:userId`} component={ManageUser} exact />
          <Route path={`${match.path}/manage-users`} component={ManageUsersPage} />
          <Route path={`${match.path}/report-permission`} component={ReportPermission} />
          <Route path={`${match.path}/error-admin`} component={ManageErrorsPage} />
          <Route path={`${match.path}/shadow-queue`} component={ManageShadowQueuePage} />
          <Route path={`${match.path}/proxy-requests`} component={ManageProxyRequestsPage} />
          <Route path={`${match.path}/survey-admin`} component={ManageSurveysPage} />
          <Route path={`${match.path}/survey-builder`} component={SurveyBuilderPage} />
        </Switch>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    breadcrumb: state.admin.breadcrumb,
    authRoles: state.auth.user.profile.roles
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...adminActions }, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Admin);
