import * as types from "./actionTypes";

// eslint-disable-next-line import/prefer-default-export
export function setContext(key, value) {
  return {
    type: types.SET_CONTEXT,
    key,
    value
  };
}
