import React, { Component } from "react";
import shortid from "shortid";
import { Button, Input, Message } from "semantic-ui-react";

class EventTriggersEditor extends Component {
  state = {
    triggerName: "",
    metadata: "{}",
    invalidJson: false
  };

  onChange = (e, data) => {
    if (data.name === "metadata") {
      try {
        JSON.parse(data.value);
        this.setState({ invalidJson: false });
      } catch (exp) {
        this.setState({ invalidJson: true });
      }
    }
    this.setState({ [data.name]: data.value });
  };

  add = () => {
    if (this.state.invalidJson) return;

    const { question, update } = this.props;
    const { triggerName, metadata } = this.state;
    const eventTriggers = [...question.eventTriggers];

    if (eventTriggers.find(e => e.triggerName === triggerName)) return;

    eventTriggers.push({ triggerName, metadata: JSON.parse(metadata) });
    update("eventTriggers", eventTriggers);
  };

  remove = triggerName => {
    const { question, update } = this.props;
    const eventTriggers = [...question.eventTriggers];
    const index = eventTriggers.findIndex(e => e.triggerName === triggerName);
    eventTriggers.splice(index, 1);
    update("eventTriggers", eventTriggers);
  };

  render() {
    const { invalidJson } = this.state;
    const { question } = this.props;
    const eventTriggers = question.eventTriggers || [];

    return (
      <div className="ui grid">
        <div className="row">
          <div className="sixteen wide column">
            <ul>
              {eventTriggers.map(e => (
                <React.Fragment key={shortid.generate()}>
                  <li key={shortid.generate()}>
                    <span style={{ display: "inline-block", width: "90%" }}>{e.triggerName}</span>
                    <i
                      className="minus link icon"
                      style={{ display: "inline-block", width: "10%", margin: "0", color: "red" }}
                      role="button"
                      tabIndex={0}
                      onClick={() => this.remove(e.triggerName)}
                    />
                  </li>
                </React.Fragment>
              ))}
            </ul>
          </div>
          <div className="sixteen wide column">
            <div className="ui grid">
              <div className="sixteen wide column " style={{ padding: "10px 30px" }}>
                <label>Trigger Name</label>
                <Input type="text" fluid name="triggerName" value={this.state.triggerName} onChange={this.onChange} />
                <label>Metadata</label>
                {invalidJson && <Message color="red">Invalid JSON</Message>}
                <textarea
                  name="metadata"
                  value={this.state.metadata}
                  // value={invalidJson ? this.state.metadata : JSON.stringify(this.state.metadata, null, 2)}
                  onChange={e => this.onChange(e, { name: "metadata", value: e.target.value })}
                />
                <div className="sixteen wide column">
                  <Button type="button" color="blue" size="mini" floated="right" onClick={this.add}>
                    Add
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default EventTriggersEditor;
