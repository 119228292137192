import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Menu, Button, Grid } from "semantic-ui-react";
import { Link } from "react-router-dom";
import PatientSearch from "../patients/PatientSearch";
import { fetchReportByName } from "../../api/report";
import * as userPreferenceActions from "../../actions/userPreferenceActions";
import "./SearchNav.css";

const adminReportName = "Review of Patients Admin";

export class SearchNav extends Component {
  state = {
    isAdvanced: true,
    reportId: null
  };

  componentDidMount() {
    fetchReportByName(adminReportName).then(({ reportId } = {}) => this.setState({ reportId }));
  }

  toggleAdvanced = () => {
    if (this.state.isAdvanced) {
      this.setState({
        isAdvanced: false
      });
    } else {
      this.setState({
        isAdvanced: true
      });
    }
  };

  handleToggleDarkMode = (e, data) => {
    this.props.actions.setTheme(data.checked ? "/themes/dark.css" : "/themes/default.css");
  };

  render() {
    // const { theme } = this.props;
    const { isAdvanced, reportId } = this.state;
    return (
      <Grid className="no-margin">
        <Grid.Row className="search-nav-container no-padding">
          <Grid.Column width={13} className="no-padding">
            <Menu className="search-nav">
              <Menu.Item>
                <PatientSearch />
              </Menu.Item>
              <Menu.Item>
                <Button
                  icon="options"
                  content={isAdvanced ? "Advanced" : "Simple"}
                  as={Link}
                  to={isAdvanced ? "/search" : ""}
                  name="search"
                  onClick={this.toggleAdvanced}
                />
              </Menu.Item>
              <Menu.Item>
                {reportId && (
                  <Button icon="step backward" content="Review" as={Link} to={`/report-details/${reportId}`} />
                )}
              </Menu.Item>
            </Menu>
          </Grid.Column>
          <Grid.Column
            className="no-padding"
            width={3}
            textAlign="right"
            // style={{ borderBottom: "1px solid rgba(34, 36, 38, 0.15)" }}
          >
            <div style={{ height: "100%", padding: ".92857143em 0" }}>
              {/* <Checkbox
                toggle
                label="Dark Mode"
                checked={theme === "/themes/dark.css"}
                style={{ padding: ".78571429em" }}
                onChange={this.handleToggleDarkMode}
              /> */}
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  return {
    theme: state.userPreference.theme
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...userPreferenceActions
      },
      dispatch
    )
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchNav);
