import React, { Component } from "react";
import shortid from "shortid";
import { Dropdown, Button, Input } from "semantic-ui-react";

class TriggersEditor extends Component {
  state = {
    questionKey: "",
    matchOperator: "",
    matchValue: ""
  };

  onChange = (e, data) => {
    this.setState({ [data.name]: data.value });
  };

  add = () => {
    const { question, update } = this.props;
    const { questionKey, matchOperator, matchValue } = this.state;
    const triggers = [...question.triggers];
    triggers.push({ questionKey, matchOperator, matchValue });
    update("triggers", triggers);
  };

  remove = trigger => {
    const { question, update } = this.props;
    const triggers = [...question.triggers];
    const index = triggers.findIndex(
      t =>
        t.questionKey === trigger.questionKey &&
        t.matchOperator === trigger.matchOperator &&
        t.matchValue === trigger.matchValue
    );
    triggers.splice(index, 1);
    update("triggers", triggers);
  };

  render() {
    const { questionKey, matchOperator, matchValue } = this.state;
    const { question, questions } = this.props;
    const triggers = question.triggers || [];
    let questionKeyOptions = questions
      ? questions.filter(q => q.key !== question.key).map(q => ({ text: q.text, value: q.key }))
      : [];
    questionKeyOptions = questionKeyOptions.filter(
      trigger =>
        triggers.findIndex(
          t =>
            t.questionKey === trigger.questionKey &&
            t.matchOperator === trigger.matchOperator &&
            t.matchValue === trigger.matchValue
        ) < 0
    );
    const matchQuestion = questions.find(q => q.key === questionKey);
    const matchValueOptions =
      matchQuestion && ["SingleSelect", "MultiSelect"].includes(matchQuestion.type)
        ? matchQuestion.options.map(o => ({ text: o, value: o }))
        : [];
    return (
      <div className="ui grid" style={{ marginTop: "0" }}>
        <label>Only show this question if:</label>
        <div className="row">
          <div className="sixteen wide column">
            <ul>
              {triggers.map(t => {
                const triggerQuestion = questions.find(q => q.key === t.questionKey);
                return (
                  <React.Fragment key={shortid.generate()}>
                    <li key={shortid.generate()}>
                      <span style={{ display: "inline-block", width: "90%" }}>{triggerQuestion.text}</span>
                      <i
                        className="minus link icon"
                        style={{ display: "inline-block", width: "10%", margin: "0", color: "red" }}
                        role="button"
                        tabIndex={0}
                        onClick={() => this.remove(t)}
                      />
                    </li>
                    <li key={shortid.generate()}>
                      <span style={{ display: "inline-block", width: "100%" }}>
                        {t.matchOperator === "exists" ? `Answered` : `Answered with`}
                      </span>
                    </li>
                    <li key={shortid.generate()} style={{ borderBottom: "1px solid rgba(34,36,38,.15)" }}>
                      <span style={{ display: "inline-block", width: "100%" }}>{t.matchValue}</span>
                    </li>
                  </React.Fragment>
                );
              })}
            </ul>
          </div>
          <div className="sixteen wide column">
            <div className="ui grid">
              <div className="sixteen wide column " style={{ padding: "30px" }}>
                <label>Question</label>
                <Dropdown
                  name="questionKey"
                  options={questionKeyOptions}
                  fluid
                  selection
                  value={questionKey}
                  onChange={this.onChange}
                />

                <div className="form-group row inline fields">
                  <div className="field">
                    <div className="ui radio checkbox">
                      <input
                        type="radio"
                        name="matchOperator"
                        value="exists"
                        checked={matchOperator === "exists"}
                        onChange={e => this.onChange(e, { name: "matchOperator", value: e.target.value })}
                      />
                      <label>Answered</label>
                    </div>
                  </div>
                  <div className="field">
                    <div className="ui radio checkbox">
                      <input
                        type="radio"
                        name="matchOperator"
                        value="eq"
                        checked={matchOperator === "eq"}
                        onChange={e => this.onChange(e, { name: "matchOperator", value: e.target.value })}
                      />
                      <label>Answered with</label>
                    </div>
                  </div>
                </div>

                {matchQuestion && matchOperator === "eq" && (
                  <React.Fragment>
                    <label>Answer</label>
                    {["SingleSelect", "MultiSelect"].includes(matchQuestion.type) && (
                      <Dropdown
                        name="matchValue"
                        options={matchValueOptions}
                        fluid
                        selection
                        value={matchValue}
                        onChange={this.onChange}
                      />
                    )}
                    {!["SingleSelect", "MultiSelect"].includes(matchQuestion.type) && (
                      <Input fluid name="matchValue" type="text" value={matchValue} onChange={this.onChange} />
                    )}
                  </React.Fragment>
                )}

                <div className="sixteen wide column">
                  <Button type="button" color="blue" size="mini" floated="right" onClick={this.add}>
                    Add
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TriggersEditor;
