import React, { Component } from "react";
import { Modal, Button, Form, Header, Dropdown, Grid, Icon, Message } from "semantic-ui-react";
import { DateInput } from "semantic-ui-calendar-react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { cloneDeep, isEmpty, isEqual, set } from "lodash";
import moment from "moment";
import shortid from "shortid";
import * as modalActions from "../../../actions/modalActions";
import * as userActions from "../../../actions/userActions";
import * as lookupActions from "../../../actions/lookupActions";
import * as noteSlashTasksActions from "../../../actions/noteSlashTasksActions";
import NoteSlashTaskCommentsTable from "./NoteSlashTaskCommentsTable";
import { dateFormat, dateWithTimeFormat, isoDateFormat, isoFormat } from "../../../constants/miscellaneous";
import "./NoteSlashTaskFormModal.css";
import { getLookupText, patientName } from "../../../helpers";
import ActivityLog from "../ActivityLog";

export class NoteSlashTaskFormModal extends Component {
  state = {
    view: "DETAILS",
    selectedTemplate: "",
    initType: this.props.type,
    type: this.props.type,
    typeChanged: false,
    initEntity: {
      subject: "",
      body: "",
      isUrgent: false,
      dueDate: moment(),
      comments: [],
      assignee: { assignedCenter: this.props.defaultAssigneeCenterId, assignedRole: null, assignedUserId: null },
      taskCC: []
    },
    entity: {
      subject: "",
      body: "",
      isUrgent: false,
      dueDate: moment(),
      comments: [],
      assignee: { assignedCenter: this.props.defaultAssigneeCenterId, assignedRole: null, assignedUserId: null },
      taskCC: []
    },
    entityChanged: isEmpty(this.props.entityId),
    comment: "",
    addCommentPressed: false,
    reassignPressed: false,
    reccPressed: false,
    completePressed: false,
    reopenPressed: false,
    roleUserOptions: {},
    selectedRoleGroups: []
  };

  componentDidMount() {
    this._isMounted = true;
    const {
      entityId,
      centerOptions,
      currentUserRoles,
      roleGroupOptions,
      actions: { loadTaskRoles, loadRoleGroups, loadTaskTemplates, loadNoteTemplates }
    } = this.props;
    const { entity } = this.state;

    loadRoleGroups();
    loadTaskRoles();

    if (entityId) {
      this.fetch();
    }

    if (
      entity.assignee.assignedCenter &&
      !isEmpty(centerOptions) &&
      !centerOptions.map((o) => o.value).includes(entity.assignee.assignedCenter)
    ) {
      this.setState({ entity: { ...entity, assignee: { ...entity.assignee, assignedCenter: null } } });
    }

    const _selectedRoleGroups = [];
    if (!isEmpty(roleGroupOptions) && !isEmpty(currentUserRoles)) {
      roleGroupOptions.forEach((rg) => {
        if (rg.roles.some((role) => currentUserRoles.includes(role.name))) _selectedRoleGroups.push(rg.groupName);
      });
      this.setState({ selectedRoleGroups: _selectedRoleGroups });
    }
    if (this.props.type === "note") loadNoteTemplates(this.distinctRoles(_selectedRoleGroups));
    else loadTaskTemplates(this.distinctRoles(_selectedRoleGroups));
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      taskRoles,
      centerOptions,
      taskTemplates,
      noteTemplates,
      currentUserRoles,
      roleGroupOptions,
      actions: { loadNoteTemplates, loadTaskTemplates }
    } = this.props;
    const { type, entity, roleUserOptions, selectedRoleGroups } = this.state;
    const promises = [];
    const taskRole = taskRoles.find((r) => r.name === entity.assignee.assignedRole);

    if (type !== prevState.type) {
      if (type === "note") loadNoteTemplates(this.distinctRoles(selectedRoleGroups));
      else loadTaskTemplates(this.distinctRoles(selectedRoleGroups));
    }

    if (
      entity.assignee.assignedCenter &&
      !isEmpty(centerOptions) &&
      !centerOptions.map((o) => o.value).includes(entity.assignee.assignedCenter)
    ) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ entity: { ...entity, assignee: { ...entity.assignee, assignedCenter: null } } });
    }

    if (
      entity.assignee &&
      entity.assignee.assignedRole &&
      taskRole &&
      taskRole.showUsersListInTaskAssignee &&
      entity.assignee.assignedRole !== prevState.entity.assignee.assignedRole &&
      isEmpty(roleUserOptions[entity.assignee.assignedRole])
    ) {
      promises.push(
        this.props.actions
          .loadUsersWithRoles([entity.assignee.assignedRole])
          .then(({ list }) => ({ role: entity.assignee.assignedRole, list }))
      );
    }

    if (type === "task" && !isEmpty(entity.taskCC)) {
      entity.taskCC.forEach((cc, index) => {
        const _taskRole = taskRoles.find((r) => r.name === cc.assignedRole);
        if (
          cc.assignedRole &&
          _taskRole &&
          _taskRole.showUsersListInTaskAssignee &&
          (isEmpty(prevState.entity.taskCC) ||
            isEmpty(prevState.entity.taskCC[index]) ||
            cc.assignedRole !== prevState.entity.taskCC[index].assignedRole) &&
          isEmpty(roleUserOptions[cc.assignedRole])
        ) {
          promises.push(
            this.props.actions
              .loadUsersWithRoles([cc.assignedRole])
              .then(({ list }) => ({ role: cc.assignedRole, list }))
          );
        }
      });
    }

    if (promises.length > 0) {
      Promise.all(promises).then((responses) => {
        this.setState({
          roleUserOptions: {
            ...roleUserOptions,
            ...responses.reduce((acc, curr) => {
              acc[curr.role] = curr.list.map((user) => ({
                value: user.userId,
                text: `${user.firstName} ${user.lastName}`
              }));
              return acc;
            }, {})
          }
        });
      });
    }

    if (
      entity.subject !== prevState.entity.subject &&
      ((this.state.type === "note" && noteTemplates.length > 0) ||
        (this.state.type !== "note" && taskTemplates.length > 0))
    ) {
      this.setState({
        selectedTemplate:
          (this.state.type === "note" ? noteTemplates : taskTemplates).find((t) => t.subject === entity.subject)?.id ||
          ""
      });
    }

    if (
      !isEqual(roleGroupOptions, prevProps.roleGroupOptions) ||
      !isEqual(currentUserRoles, prevProps.currentUserRoles)
    ) {
      if (!isEmpty(roleGroupOptions) && !isEmpty(currentUserRoles)) {
        const _selectedRoleGroups = [];
        roleGroupOptions.forEach((rg) => {
          if (rg.roles.some((role) => currentUserRoles.includes(role.name))) _selectedRoleGroups.push(rg.groupName);
        });
        this.setState({ selectedRoleGroups: _selectedRoleGroups });
      }
    }

    if (!isEqual(selectedRoleGroups, prevState.selectedRoleGroups)) {
      if (this.props.type === "note") loadNoteTemplates(this.distinctRoles(selectedRoleGroups));
      else loadTaskTemplates(this.distinctRoles(selectedRoleGroups));
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  _isMounted = false;

  distinctRoles = (groups) =>
    [
      ...new Set(
        this.props.roleGroupOptions
          .filter((g) => groups.includes(g.groupName))
          .map((i) => i.roles)
          .flat()
      )
    ].map((r) => r.name);

  fetch = (init = true, updateCommentsOnly = false) =>
    this.props.actions.fetchNoteSlashTaskDetails(this.props.entityId).then(({ entity }) => {
      if (this._isMounted)
        this.setState({
          entity: updateCommentsOnly
            ? { ...this.state.entity, comments: [...entity.comments] }
            : { ...entity, dueDate: entity.dueDate || moment() },
          initEntity: init
            ? updateCommentsOnly
              ? { ...cloneDeep(this.state.entity), comments: [...entity.comments] }
              : { ...cloneDeep(entity), dueDate: entity.dueDate || moment() }
            : { ...this.state.initEntity },
          selectedTemplate:
            (this.state.type === "note" ? this.props.noteTemplates : this.props.taskTemplates).find(
              (t) => t.subject === entity.subject
            )?.id || ""
        });
    });

  handleSave = () => {
    const entity = { ...this.state.entity };
    entity.subject = this.state.entity.subject.trim();
    entity.body = this.state.entity.body ? this.state.entity.body.trim() : "";

    if (this.state.type === "note") {
      delete entity.isUrgent;
      delete entity.dueDate;
      delete entity.taskCC;
      entity.assignee = { assignedCenter: null, assignedRole: null, assignedUserId: null };
    } else {
      entity.taskCC = [...this.state.entity.taskCC].filter(
        (cc) => !isEmpty(cc.assignedCenter) && !isEmpty(cc.assignedRole)
      );
      entity.dueDate = `${moment(entity.dueDate)
        .set({ hour: 18, minute: 0, second: 0, millisecond: 0 })
        .format(isoFormat)}`;
    }

    this.props.actions
      .saveNoteSlashTask(entity.patientId || this.props.patientId, entity, this.props.entityId)
      .then(() => {
        if (this.props.afterSave) this.props.afterSave();
        this.handleCancel();
      });
  };

  // saveTemplate = () => {
  //   const { entity } = this.state;
  //   localStorage.setItem(
  //     "NOTE_SLASH_TASK_OPTIONS",
  //     localStorage.getItem("NOTE_SLASH_TASK_OPTIONS")
  //       ? JSON.stringify({
  //           ...JSON.parse(localStorage.getItem("NOTE_SLASH_TASK_OPTIONS")),
  //           [entity.subject]: { ...this.state }
  //         })
  //       : JSON.stringify({ [entity.subject]: { ...this.state } })
  //   );
  // };

  handleAddComment = () => {
    const { patientId, entityId } = this.props;
    const { entity } = this.state;
    this.setState({ addCommentPressed: true });
    this.props.actions
      .addNoteSlashTaskComment(entity.patientId || patientId, entityId, this.state.comment.trim())
      .then(() => {
        this.fetch(false, true).then(() => {
          this.setState({ addCommentPressed: false });
        });
      });
  };

  handleCancel = () => {
    const { entity, initEntity } = this.state;
    if (entity.comments.length !== initEntity.comments.length && this.props.afterSave) {
      this.props.afterSave();
    }
    this.props.actions.hideModal();
  };

  // handleSubjectSelectSelect = (_, data) => {
  //   const state = { ...this.state };
  //   const template = JSON.parse(localStorage.getItem("NOTE_SLASH_TASK_OPTIONS"))[data.result.title];
  //   const updatedState = (function clone(a, b) {
  //     const ua = {};
  //     Object.keys(a).forEach(key => {
  //       if (Object.prototype.hasOwnProperty.call(b, key)) {
  //         if (b[key] !== Object(b[key]) && !isEmpty(b[key])) ua[key] = b[key];
  //         if (typeof b[key] === "object" && !isEmpty(b[key])) ua[key] = clone(a[key], b[key]);
  //       }
  //     });
  //     return ua;
  //   })(state, template);
  //   this.setState(
  //     updatedState.type === "note" ? updatedState : { ...updatedState, entity: { ...updatedState.entity, dueDate: "" } }
  //   );
  // };

  checkEntityChanged = (entity, initEntity) =>
    entity.subject !== initEntity.subject ||
    entity.body !== initEntity.body ||
    entity.isUrgent !== initEntity.isUrgent ||
    moment(entity.dueDate).endOf("day").format(isoFormat) !==
      moment(initEntity.dueDate).endOf("day").format(isoFormat) ||
    entity.assignee.assignedCenter !== initEntity.assignee.assignedCenter ||
    entity.assignee.assignedRole !== initEntity.assignee.assignedRole ||
    entity.assignee.assignedUserId !== initEntity.assignee.assignedUserId;
  // entity.taskCC.some(cc=>) // when adding CC feature back

  handleInput = (e, data) => {
    const updatedState = cloneDeep(this.state);
    set(updatedState, data.name, data.value);

    if (data.name === "type") {
      if (updatedState.type === "note") {
        delete updatedState.entity.isUrgent;
        delete updatedState.entity.dueDate;
        delete updatedState.entity.taskCC;
        updatedState.entity.assignee = {
          assignedCenter: null,
          assignedRole: null,
          assignedUserId: null
        };
      } else {
        updatedState.entity.isUrgent = false;
        updatedState.entity.dueDate = moment();
        updatedState.entity.taskCC = [];
        updatedState.entity.assignee = {
          assignedCenter: this.props.defaultAssigneeCenterId,
          assignedRole: null,
          assignedUserId: null
        };
        updatedState.reassignPressed = true;
        updatedState.reccPressed = true;
      }
    }
    if (data.name === "entity.dueDate") {
      updatedState.entity.dueDate = isEmpty(data.value) ? `` : moment(data.value, dateFormat).format(isoDateFormat);
    }
    if (data.name === "entity.assignee.assignedRole") {
      updatedState.entity.assignee.assignedUserId = null;
      updatedState.entity.assignee.assignedUserFirstName = null;
      updatedState.entity.assignee.assignedUserLastName = null;
    }
    updatedState.typeChanged = updatedState.type !== updatedState.initType;
    updatedState.entityChanged = this.checkEntityChanged(updatedState.entity, updatedState.initEntity);

    if (data.name === "selectedTemplate") {
      const { type } = this.state;
      const { noteTemplates, taskTemplates } = this.props;
      const template = (type === "note" ? noteTemplates : taskTemplates).find((i) => i.id === data.value);
      updatedState.entity.subject = template.subject;
      updatedState.entity.body = template.details;
      updatedState.entity.tags = isEmpty(template.tags) ? [] : [...template.tags];
      if (type === "task") {
        updatedState.entity.assignee.assignedRole = template.role;
        updatedState.entity.isUrgent = template.urgent;
        if (template.hasDueDate) updatedState.entity.dueDate = moment().add(template.dueDateInDays, "days");
        else updatedState.entity.dueDate = moment();
      }
    }
    if (this._isMounted) this.setState(updatedState);
  };

  handleCheckbox = (e, data) => {
    const updatedState = { ...this.state };
    set(updatedState, data.name, data.checked);
    updatedState.entityChanged = this.checkEntityChanged(updatedState.entity, updatedState.initEntity);
    if (this._isMounted) this.setState(updatedState);
  };

  handleCompleteClick = () => {
    if (this.state.completePressed) {
      const {
        patientId,
        entityId,
        actions: { completeNoteSlashTask }
      } = this.props;
      const { entity } = this.state;
      completeNoteSlashTask(entity.patientId || patientId, entityId).then(() => {
        if (this.props.afterSave) this.props.afterSave();
        this.handleCancel();
      });
    } else {
      this.setState({ completePressed: true });
    }
  };

  handleCompleteMouseLeave = () => {
    this.setState({ completePressed: false });
  };

  handleReopenClick = () => {
    if (this.state.reopenPressed) {
      const {
        patientId,
        entityId,
        actions: { openNoteSlashTask }
      } = this.props;
      const { entity } = this.state;
      openNoteSlashTask(entity.patientId || patientId, entityId).then(() => {
        if (this.props.afterSave) this.props.afterSave();
        this.handleCancel();
      });
    } else {
      this.setState({ reopenPressed: true });
    }
  };

  handleReopenMouseLeave = () => {
    this.setState({ reopenPressed: false });
  };

  render() {
    const {
      view,
      selectedTemplate,
      entity,
      type,
      typeChanged,
      entityChanged,
      roleUserOptions,
      addCommentPressed,
      reassignPressed,
      // reccPressed,
      comment,
      completePressed,
      reopenPressed,
      selectedRoleGroups
    } = this.state;
    const {
      entityId,
      patient,
      open,
      saving,
      centerOptions,
      taskRoles,
      currentUserId,
      currentUserRoles,
      taskTemplates,
      noteTemplates,
      roleGroupOptions
    } = this.props;
    const roleOptions = taskRoles.map((i) => ({
      text: i.displayName,
      value: i.name
    }));
    const readOnly = entity ? entity.isDeleted : false;
    const editMode = !!entityId;
    const missingRequiredFields =
      type === "note"
        ? !typeChanged && (editMode || isEmpty(entity.subject) || isEmpty(entity.body))
        : editMode
        ? isEmpty(entity.dueDate) ||
          isEmpty(entity.assignee.assignedCenter) ||
          isEmpty(entity.assignee.assignedRole) ||
          (!isEmpty(entity.taskCC) &&
            entity.taskCC.every(
              (cc) =>
                (isEmpty(cc.assignedCenter) && !isEmpty(cc.assignedRole)) ||
                (!isEmpty(cc.assignedCenter) && isEmpty(cc.assignedRole))
            ))
        : isEmpty(entity.subject) ||
          isEmpty(entity.body) ||
          isEmpty(entity.dueDate) ||
          isEmpty(entity.assignee.assignedCenter) ||
          isEmpty(entity.assignee.assignedRole) ||
          (!isEmpty(entity.taskCC) &&
            entity.taskCC.every(
              (cc) =>
                (isEmpty(cc.assignedCenter) && !isEmpty(cc.assignedRole)) ||
                (!isEmpty(cc.assignedCenter) && isEmpty(cc.assignedRole))
            ));

    const canCompleteTask =
      type === "task" &&
      (entity.assignee.assignedUserId === currentUserId || currentUserRoles.includes(entity.assignee.assignedRole));

    const unassignedTask = type === "task" && !entity.assignee.assignedCenter;

    const taskRole = taskRoles.find((r) => r.name === entity.assignee.assignedRole);
    // const noteSlashTaskOptions = JSON.parse(localStorage.getItem("NOTE_SLASH_TASK_OPTIONS") || "{}");
    // const subjectOptions = Object.keys(noteSlashTaskOptions)
    //   .filter(k => k.includes(entity.subject))
    //   .map(k => ({ title: k }));

    const patientFirstName = entity.patientFirstName
      ? entity.patientFirstName
      : patient
      ? patient.patientFirstName
      : ``;
    const patientPreferredName = entity.patientPreferredName
      ? entity.patientPreferredName
      : patient
      ? patient.patientPreferredName
      : ``;
    const patientLastName = entity.patientLastName ? entity.patientLastName : patient ? patient.patientLastName : ``;
    const medicalRecordNumber = entity.medicalRecordNumber
      ? entity.medicalRecordNumber
      : patient
      ? patient.medicalRecordNumber
      : ``;

    return (
      <Modal
        open={open}
        size="large"
        className={`note-task-modal ${type}`}
        closeIcon
        closeOnDimmerClick={false}
        onClose={this.handleCancel}
      >
        <Modal.Header className="actions-column" style={{ paddingRight: "4rem" }}>
          {view === "ACTIVITY_LOG"
            ? `Change log`
            : editMode
            ? isEmpty(entity.completeAt)
              ? `Edit `
              : `${entity.type} details`
            : `Create a `}
          {view === "DETAILS" && isEmpty(entity.completeAt) && (
            <Dropdown
              name="type"
              size="small"
              value={type}
              options={[
                { text: "task", value: "task" },
                { text: "note", value: "note" }
              ]}
              onChange={this.handleInput}
              className="ui selection dropdown modal-header-dropdown"
              disabled={readOnly || saving}
            />
          )}
          <span style={{ marginLeft: ".5em" }}>
            {`Patient Name: `}
            <span style={{ color: "#2185d0" }}>
              {patientName(patientFirstName, patientPreferredName, "", patientLastName)}
            </span>
          </span>
          <span style={{ marginLeft: ".5em" }}>{`MRN: ${medicalRecordNumber}`}</span>
          {editMode && view === "ACTIVITY_LOG" && (
            <Icon
              title="Details"
              name="arrow left"
              color="grey"
              style={{ float: "right" }}
              onClick={() => this.setState({ view: "DETAILS" })}
            />
          )}
          {editMode && view === "DETAILS" && (
            <Icon
              title="Activity log"
              name="history"
              color="grey"
              style={{ float: "right" }}
              onClick={() => this.setState({ view: "ACTIVITY_LOG" })}
            />
          )}
        </Modal.Header>
        <Modal.Content>
          {entityId && (
            <Message color="yellow">
              Created by: {`${entity.creatorFirstName} ${entity.creatorLastName}, at: `}
              {moment(entity.createdDate).format(dateWithTimeFormat)}
            </Message>
          )}
          {view === "ACTIVITY_LOG" && <ActivityLog entityId={entityId} />}
          {view === "DETAILS" && (
            <Form>
              <Form.Group widths="equal">
                <Form.Field>
                  <label>Templates for Role(s)</label>
                  <Dropdown
                    name="selectedRoleGroups"
                    options={roleGroupOptions.map((i) => ({ text: i.groupName, value: i.groupName }))}
                    value={selectedRoleGroups}
                    onChange={this.handleInput}
                    fluid
                    search
                    clearable
                    multiple
                    selection
                  />
                </Form.Field>
              </Form.Group>
              {!readOnly && (type === "note" ? noteTemplates : taskTemplates).length > 0 && (
                <>
                  <Form.Group widths="equal">
                    <Form.Field>
                      <label>Template</label>
                      <Dropdown
                        name="selectedTemplate"
                        value={selectedTemplate}
                        options={(type === "note" ? noteTemplates : taskTemplates).map((i) => ({
                          text: i.subject,
                          value: i.id
                        }))}
                        onChange={this.handleInput}
                        disabled={saving || readOnly || editMode}
                        fluid
                        search
                        selection
                      />
                    </Form.Field>
                  </Form.Group>
                </>
              )}
              <Form.Group widths="equal">
                <Form.Field>
                  {readOnly || editMode ? (
                    <React.Fragment>
                      <label>Subject</label>
                      <p>{entity.subject}</p>
                    </React.Fragment>
                  ) : (
                    <Form.Input
                      type="text"
                      name="entity.subject"
                      label="Subject"
                      value={entity.subject}
                      onChange={this.handleInput}
                      maxLength={250}
                      required
                    />
                    // <React.Fragment>
                    //   <label>Subject</label>
                    //   <Search
                    //     name="entity.subject"
                    //     minCharacters={2}
                    //     // loading={processing}
                    //     // className={className || ""}
                    //     onResultSelect={this.handleSubjectSelectSelect}
                    //     onSearchChange={this.handleInput}
                    //     results={subjectOptions}
                    //     // results={result.patients.map(p => ({
                    //     //   childKey: `rand-${shortid.generate()}` /* using rand key here as it really happened on prod .. we have duplicate patient id */,
                    //     //   as: PatientSearchResultWrapper,
                    //     //   id: p.platformId,
                    //     //   title: `${p.firstName} ${p.lastName}`,
                    //     //   patientId: p.platformId,
                    //     //   firstName: p.firstName,
                    //     //   lastName: p.lastName,
                    //     //   city: p.city,
                    //     //   state: p.state
                    //     // }))}
                    //     value={entity.subject}
                    //     // resultRenderer={PatientSearchResult}
                    //     // noResultsMessage="No results found."
                    //     placeholder="Subject"
                    //     fluid
                    //     required
                    //     // id={`input-patient-search-rand-${shortid.generate()}`}
                    //   />
                    // </React.Fragment>
                  )}
                </Form.Field>
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Field>
                  {readOnly || editMode ? (
                    <React.Fragment>
                      <label>Details</label>
                      <p>
                        {isEmpty(entity.body)
                          ? `No details`
                          : entity.body.split("\n").map((item) => (
                              <span key={`rand-${shortid.generate()}`}>
                                {item}
                                <br />
                              </span>
                            ))}
                      </p>
                    </React.Fragment>
                  ) : (
                    <Form.TextArea
                      maxLength="1500"
                      name="entity.body"
                      label="Details"
                      value={entity.body}
                      onChange={this.handleInput}
                      rows="6"
                      required
                    />
                  )}
                </Form.Field>
              </Form.Group>
              {type === "task" && (
                <React.Fragment>
                  <Form.Group widths="equal">
                    <Form.Field>
                      <Form.Checkbox
                        name="entity.isUrgent"
                        label="Urgent"
                        onChange={this.handleCheckbox}
                        checked={!!entity.isUrgent}
                        disabled={readOnly || saving || !isEmpty(entity.completeAt)}
                      />
                    </Form.Field>
                    <Form.Field required>
                      <label style={{ display: "inline-block", width: "20%" }}>Due Date</label>
                      <div style={{ display: "inline-block", width: "80%" }}>
                        <DateInput
                          name="entity.dueDate"
                          onChange={this.handleInput}
                          value={isEmpty(entity.dueDate) ? "" : moment(entity.dueDate).format(dateFormat)}
                          placeholder="Select Date"
                          dateFormat={dateFormat}
                          iconPosition="right"
                          hideMobileKeyboard
                          closable
                          disabled={readOnly || saving || !isEmpty(entity.completeAt)}
                        />
                      </div>
                    </Form.Field>
                  </Form.Group>

                  {readOnly || (editMode && !reassignPressed) ? (
                    <Grid className="no-margin">
                      <Grid.Row className="half-v-padding">
                        <Grid.Column width={15} className="inline field no-padding">
                          <strong>Assigned to: </strong>
                          {!isEmpty(entity.assignee.assignedCenter) && !isEmpty(centerOptions) && (
                            <React.Fragment>
                              <label style={{ marginRight: "1em" }}>Center:</label>
                              <span style={{ marginRight: "1em" }}>
                                {getLookupText(entity.assignee.assignedCenter, centerOptions)}
                              </span>
                            </React.Fragment>
                          )}
                          {!isEmpty(entity.assignee.assignedRole) && !isEmpty(roleOptions) && (
                            <React.Fragment>
                              <label style={{ marginRight: "1em" }}>Role:</label>
                              <span style={{ marginRight: "1em" }}>
                                {getLookupText(entity.assignee.assignedRole, roleOptions, true)}
                              </span>
                            </React.Fragment>
                          )}
                          {!isEmpty(entity.assignee.assignedUserId) &&
                            !isEmpty(roleUserOptions[entity.assignee.assignedRole]) && (
                              <React.Fragment>
                                <label style={{ marginRight: "1em" }}>User:</label>
                                <span style={{ marginRight: "1em" }}>
                                  {getLookupText(
                                    entity.assignee.assignedUserId,
                                    roleUserOptions[entity.assignee.assignedRole]
                                  )}
                                </span>
                              </React.Fragment>
                            )}
                        </Grid.Column>
                        <Grid.Column width={1} className="no-padding">
                          <Form.Field>
                            <Button
                              fluid
                              size="small"
                              color="olive"
                              content="Reassign"
                              className="half-padding"
                              onClick={() => {
                                this.setState({ reassignPressed: true });
                              }}
                              disabled={readOnly || saving || !isEmpty(entity.completeAt)}
                            />
                          </Form.Field>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  ) : (
                    <React.Fragment>
                      <div className="required field no-margin">
                        <Header
                          as="label"
                          className="no-v-margin no-padding"
                          content={`Assign to`}
                          style={{ fontSize: "1em" }}
                        />
                      </div>
                      <Grid className="no-margin">
                        <Grid.Row className="no-padding">
                          <Grid.Column width={editMode && !reassignPressed ? 14 : 16}>
                            <Form.Group widths="equal" required>
                              <Form.Field>
                                <Form.Dropdown
                                  fluid
                                  search
                                  selection
                                  clearable
                                  required
                                  label="Center"
                                  name="entity.assignee.assignedCenter"
                                  placeholder="Select..."
                                  options={centerOptions || []}
                                  value={entity.assignee.assignedCenter}
                                  onChange={this.handleInput}
                                  disabled={editMode && !reassignPressed}
                                />
                              </Form.Field>
                              <Form.Field>
                                <Form.Dropdown
                                  fluid
                                  search
                                  selection
                                  clearable
                                  required
                                  label="Role"
                                  name="entity.assignee.assignedRole"
                                  placeholder="Select..."
                                  options={roleOptions || []}
                                  value={entity.assignee.assignedRole}
                                  onChange={this.handleInput}
                                  disabled={editMode && !reassignPressed}
                                />
                              </Form.Field>
                              <Form.Field>
                                {!isEmpty(taskRoles) &&
                                  !isEmpty(entity.assignee.assignedRole) &&
                                  taskRole &&
                                  taskRole.showUsersListInTaskAssignee && (
                                    <Form.Dropdown
                                      fluid
                                      search
                                      selection
                                      clearable
                                      label="Name"
                                      name="entity.assignee.assignedUserId"
                                      placeholder="Select..."
                                      options={roleUserOptions[entity.assignee.assignedRole] || []}
                                      value={entity.assignee.assignedUserId}
                                      onChange={this.handleInput}
                                      disabled={editMode && !reassignPressed}
                                    />
                                  )}
                              </Form.Field>
                            </Form.Group>
                          </Grid.Column>
                          {editMode && !reassignPressed && (
                            <Grid.Column width={2}>
                              <Form.Field>
                                <label>&nbsp;</label>
                                <Button
                                  fluid
                                  color="olive"
                                  content="Reassign"
                                  onClick={() => {
                                    this.setState({ reassignPressed: true });
                                  }}
                                  disabled={saving || !isEmpty(entity.completeAt)}
                                />
                              </Form.Field>
                            </Grid.Column>
                          )}
                        </Grid.Row>
                      </Grid>
                    </React.Fragment>
                  )}

                  {/* {isEmpty(entity.taskCC) || !reccPressed ? (
                  <Grid className="no-margin">
                    <Grid.Row className="no-padding">
                      <Grid.Column width={15} className="inline field no-padding">
                        <strong>CCed to: </strong>{" "}
                        {!isEmpty(entity.taskCC) && !isEmpty(entity.taskCC[0].assignedCenter) ? (
                          <React.Fragment>
                            {!isEmpty(entity.taskCC[0].assignedCenter) && !isEmpty(centerOptions) && (
                              <React.Fragment>
                                <label style={{ marginRight: "1em" }}>Center:</label>
                                <span style={{ marginRight: "1em" }}>
                                  {getLookupText(entity.taskCC[0].assignedCenter, centerOptions)}
                                </span>
                              </React.Fragment>
                            )}
                            {!isEmpty(entity.taskCC[0].assignedRole) && !isEmpty(roleOptions) && (
                              <React.Fragment>
                                <label style={{ marginRight: "1em" }}>Role:</label>
                                <span style={{ marginRight: "1em" }}>
                                  {getLookupText(entity.taskCC[0].assignedRole, roleOptions)}
                                </span>
                              </React.Fragment>
                            )}
                            {!isEmpty(entity.taskCC[0].assignedUserId) &&
                              !isEmpty(roleUserOptions[entity.taskCC[0].assignedRole]) && (
                                <React.Fragment>
                                  <label style={{ marginRight: "1em" }}>User:</label>
                                  <span style={{ marginRight: "1em" }}>
                                    {getLookupText(
                                      entity.taskCC[0].assignedUserId,
                                      roleUserOptions[entity.taskCC[0].assignedRole]
                                    )}
                                  </span>
                                </React.Fragment>
                              )}
                          </React.Fragment>
                        ) : (
                          <span>No cc added</span>
                        )}
                      </Grid.Column>
                      <Grid.Column width={1} className="no-padding">
                        <Form.Field>
                          <Button
                            fluid
                            size="small"
                            color="olive"
                            content={isEmpty(entity.taskCC) ? `Add CC` : `Re-CC`}
                            className="half-padding"
                            onClick={() => {
                              this.setState({
                                reccPressed: true,
                                entity: {
                                  ...entity,
                                  taskCC: !isEmpty(entity.taskCC)
                                    ? [...entity.taskCC]
                                    : [
                                        {
                                          assignedCenter: null,
                                          assignedRole: null,
                                          assignedUserId: null
                                        }
                                      ]
                                }
                              });
                            }}
                            disabled={saving || !isEmpty(entity.completeAt)}
                          />
                        </Form.Field>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                ) : (
                  <React.Fragment>
                    <div className="field no-margin">
                      <Header
                        as="label"
                        className="no-v-margin no-padding"
                        content={`CC`}
                        style={{ fontSize: "1em" }}
                      />
                    </div>
                    <Grid className="no-margin">
                      <Grid.Row className="no-padding">
                        <Grid.Column width={editMode && !reccPressed ? 14 : 16}>
                          <Form.Group widths="equal" required>
                            <Form.Field>
                              <Form.Dropdown
                                fluid
                                search
                                selection
                                clearable
                                label="Center *"
                                name="entity.taskCC[0].assignedCenter"
                                placeholder="Select..."
                                options={centerOptions || []}
                                value={entity.taskCC[0].assignedCenter}
                                onChange={this.handleInput}
                                disabled={editMode && !reccPressed}
                              />
                            </Form.Field>
                            <Form.Field>
                              <Form.Dropdown
                                fluid
                                search
                                selection
                                clearable
                                label="Role *"
                                name="entity.taskCC[0].assignedRole"
                                placeholder="Select..."
                                options={roleOptions || []}
                                value={entity.taskCC[0].assignedRole}
                                onChange={this.handleInput}
                                disabled={editMode && !reccPressed}
                              />
                            </Form.Field>
                            <Form.Field>
                              <Form.Dropdown
                                fluid
                                search
                                selection
                                clearable
                                label="Name"
                                name="entity.taskCC[0].assignedUserId"
                                placeholder="Select..."
                                options={roleUserOptions[entity.taskCC[0].assignedRole] || []}
                                value={entity.taskCC[0].assignedUserId}
                                onChange={this.handleInput}
                                disabled={editMode && !reccPressed}
                              />
                            </Form.Field>
                          </Form.Group>
                        </Grid.Column>
                        {editMode && !reccPressed && (
                          <Grid.Column width={2}>
                            <Form.Field>
                              <label>&nbsp;</label>
                              <Button
                                fluid
                                color="olive"
                                content="Re-CC"
                                onClick={() => {
                                  this.setState({ reccPressed: true });
                                }}
                                disabled={saving || !isEmpty(entity.completeAt)}
                              />
                            </Form.Field>
                          </Grid.Column>
                        )}
                      </Grid.Row>
                    </Grid>
                  </React.Fragment>
                ) */}
                </React.Fragment>
              )}
              {editMode && (
                <React.Fragment>
                  <div className="field no-margin">
                    <Header
                      as="label"
                      className="no-v-margin no-padding"
                      content={`Comments`}
                      style={{ fontSize: "1em" }}
                    />
                  </div>
                  <Grid className="no-margin">
                    <Grid.Row className="no-padding">
                      <Grid.Column width={14}>
                        <Form.TextArea rows={2} name="comment" value={comment} onChange={this.handleInput} />
                      </Grid.Column>
                      <Grid.Column width={2}>
                        <label>&nbsp;</label>
                        <Button
                          fluid
                          color="linkedin"
                          icon="plus"
                          content="comment"
                          style={{ padding: ".75em" }}
                          disabled={isEmpty(comment.trim()) || addCommentPressed}
                          onClick={this.handleAddComment}
                        />
                      </Grid.Column>
                    </Grid.Row>
                    <NoteSlashTaskCommentsTable comments={entity.comments} />
                  </Grid>
                </React.Fragment>
              )}
            </Form>
          )}
        </Modal.Content>
        <Modal.Actions className="actions-column">
          {/* {false && (
            <Icon
              name="clone"
              color="teal"
              title="Save as template"
              style={{ marginTop: "1em", float: "left" }}
              onClick={this.saveTemplate}
            />
          )} */}
          <Button
            onClick={this.handleCancel}
            content={view === "ACTIVITY_LOG" || (editMode && type === "note") ? "Close" : "Cancel"}
            id="button-cancel"
          />
          {"type" in entity && entity.type === "Task" && editMode && canCompleteTask && isEmpty(entity.completeAt) && (
            <Button
              color={completePressed ? `yellow` : `green`}
              onClick={this.handleCompleteClick}
              onMouseLeave={this.handleCompleteMouseLeave}
              content={completePressed ? `Are you sure?` : `Complete and Close`}
              disabled={readOnly || saving || entityChanged || unassignedTask}
              loading={saving}
              style={{ minWidth: "170px" }}
            />
          )}
          {"type" in entity && entity.type === "Task" && editMode && !isEmpty(entity.completeAt) && (
            <Button
              color={reopenPressed ? `yellow` : `green`}
              onClick={this.handleReopenClick}
              onMouseLeave={this.handleReopenMouseLeave}
              content={reopenPressed ? `Are you sure?` : `Reopen`}
              disabled={readOnly || saving}
              loading={saving}
              style={{ minWidth: "170px" }}
            />
          )}
          {view === "DETAILS" && (typeChanged || !(editMode && type === "note")) && (
            <Button
              color="blue"
              onClick={this.handleSave}
              content="Save"
              disabled={readOnly || saving || missingRequiredFields || (editMode && !entityChanged)}
              loading={saving}
            />
          )}
        </Modal.Actions>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    patientId: state.patient.currentPatient.patientId,
    saving: state.ajaxCallsInProgress > 0,
    centerOptions: state.lookups.centers.filter((c) => c.active).map((i) => ({ text: i.text, value: i.value })),
    taskTemplates: state.lookups.taskTemplates.sort((a, b) =>
      a.subject > b.subject ? 1 : a.subject < b.subject ? -1 : 0
    ),
    noteTemplates: state.lookups.noteTemplates.sort((a, b) =>
      a.subject > b.subject ? 1 : a.subject < b.subject ? -1 : 0
    ),
    currentUserId: state.userPreference.currentUserId,
    currentUserRoles: state.auth.user.profile.roles,
    defaultAssigneeCenterId: state.demographics.homeCenterId,
    roleGroupOptions: state.lookups.roleGroups,
    taskRoles: state.lookups.taskRoles
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...modalActions,
        ...lookupActions,
        ...userActions,
        ...noteSlashTasksActions
      },
      dispatch
    )
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(NoteSlashTaskFormModal);
