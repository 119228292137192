import React, { Component } from "react";
import { Button, Header, Modal, Grid } from "semantic-ui-react";
import Webcam from "react-webcam";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as modalActions from "../../../../actions/modalActions";
import * as patientActions from "../../../../actions/patientActions";

import greyMan from "../../../../assets/patients/current/grey-man.jpg";

import "./PatientPhotoModal.css";

export class PatientPhotoModal extends Component {
  state = {
    imgPost: {
      image64: greyMan
    }
  };

  setRef = webcam => {
    this.webcam = webcam;
  };

  handleClose = () => this.props.actions.hideModal();

  capture = () => {
    const patientPic = this.webcam.getScreenshot();
    const imgPost = Object.assign({}, this.state.imgPost, {
      image64: patientPic
    });
    this.setState({ imgPost });
  };

  handleSave = () => {
    const { patientId } = this.props;
    const { imgPost } = this.state;
    this.props.actions.createPhoto(patientId, imgPost).then(this.handleClose);
  };

  render() {
    const { open } = this.props;
    const { imgPost } = this.state;
    return (
      <Modal className="patient-photo-modal" open={open} onClose={this.handleClose} closeIcon size="small">
        <Header content="Take Patient Photo" />
        <Modal.Content />
        <Grid doubling stackable>
          <Grid.Row>
            <Grid.Column mobile={16} tablet={16} computer={8}>
              <div className="video-container">
                <Webcam audio={false} ref={this.setRef} screenshotFormat="image/jpeg" width="auto" height="auto" />
              </div>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={16} computer={8}>
              <div className="patient-photo" style={{ backgroundImage: `url(${imgPost.image64})` }} />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Button className="take-photo" onClick={this.capture} color="blue" id="btn-takePhoto">
          Take Photo
        </Button>
        <Modal.Actions>
          <Button onClick={this.handleClose} id="btn-cancel">
            Cancel
          </Button>
          <Button onClick={this.handleSave} color="blue" id="btn-save">
            Save
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    patientId: state.patient.currentPatient.patientId
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...modalActions, ...patientActions }, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PatientPhotoModal);
