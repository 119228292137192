import React, { Component } from "react";
import { Grid, Segment, Header, Label } from "semantic-ui-react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import moment from "moment";
import isEmpty from "lodash/isEmpty";
import { loadLookup } from "../../actions/lookupActions";
import { loadMainPrescribers } from "../../actions/userActions";
import * as prescriptionActions from "../../actions/prescriptionActions";
import * as visitActions from "../../actions/visitActions";
import * as labActions from "../../actions/labActions";
import * as chartsActions from "../../actions/chartsActions";
import PrescriptionToSignForm from "./PrescriptionToSignForm";
import { isoFormat, dateFormat } from "../../constants/miscellaneous";
import AttestationForm from "./AttestationForm";
import VisitSummary from "../patients/visits/VisitSummary";
import StatePdmp from "../patients/prescriptions/StatePdmp";

export class PrescriptionToSign extends Component {
  componentDidMount() {
    const {
      actions: {
        loadLookup: _loadLookup,
        loadMainPrescribers: _loadMainPrescribers,
        loadPrescriptionToSign,
        loadPrescriptionsSentByVisit,
        loadVisitSummary,
        loadVisitAmendment,
        getVisitLocations,
        loadSignedChartDetails,
        loadCoSignedChartDetails,
        loadLabOrdersAndResultsPerVisit,
        loadSignHistory
      }
    } = this.props;
    loadPrescriptionToSign(this.props.prescriptionId).then(({ prescription: { visitId } }) => {
      if (visitId) {
        loadVisitSummary(visitId);
        loadVisitAmendment(visitId);
        getVisitLocations(visitId, "SUMMARY");
        loadSignedChartDetails(visitId);
        loadCoSignedChartDetails(visitId);
        loadPrescriptionsSentByVisit(visitId);
        loadLabOrdersAndResultsPerVisit(visitId);
        loadSignHistory(visitId);
      }
    });

    _loadMainPrescribers(["EMR-Provider", "EMR-SupervisingProvider"]);

    _loadLookup("RefillOptions");
    _loadLookup("NumberPerDoseOptions");
    _loadLookup("FrequencyOptions");
    _loadLookup("AttestationOptions");
  }

  componentWillUnmount() {
    const {
      actions: { clearVisitLocations, clearPrescriptionToSign }
    } = this.props;
    clearVisitLocations("SUMMARY");
    clearPrescriptionToSign();
  }

  handleFieldChange = (_, data) => {
    const { name: field, value, type, checked } = data;

    if (type === "checkbox") {
      return this.setState({ [field]: checked });
    }
    return this.setState({ [field]: value });
  };

  render() {
    if (
      isEmpty(this.props.prescriptionToSign) ||
      isEmpty(this.props.currentPrescriptionToSign) ||
      isEmpty(this.props.currentPrescriptionToSign.prescriptionId)
    )
      return null;
    const {
      prescriptionToSign: {
        patientFirstName,
        patientLastName,
        patientMRN,
        patientBirthDate,
        isCrossCoverage,
        deaClass,
        patientGender,
        patientLevel,
        patientId
      },
      currentPrescriptionToSign,
      currentPrescriptionToSign: { visitProviderId, prescriberId, visitId },
      currentPrescriber,
      visitSummary,
      visitAmendment,
      latestVisitSignedChart,
      cosignDetail,
      prescriptionsSent,
      signHistory,
      labOrdersAndResultsPerVisit,
      processing,
      currentUserRoles,
      visitProvider,
      visitTypes,
      patientVisits,
      visitSummaryLocations,
      attestationOptions,
      centerOptions
    } = this.props;
    const isSigned = Object.keys(latestVisitSignedChart).length > 0;
    const isCoSigned = Object.keys(cosignDetail).length > 0;
    const showAttestation = deaClass > 0 && !isEmpty(visitProviderId) && visitProviderId !== prescriberId;
    const visit = patientVisits.visits.find(i => i.visitId === visitId);
    const visitTypeObject = visit ? visitTypes.find(v => v.value === visit.visitType) : null;
    return (
      <Segment>
        <div className="header-wrapper">
          <h2>Prescription details</h2>
        </div>

        <Grid centered divided stackable style={{ display: "block" }}>
          <Grid.Column width={6} style={{ position: "sticky", top: "0" }}>
            <div>
              <h3 style={{ display: "inline-block" }}>
                {patientLevel && <Label color={patientLevel} size="mini" circular />} {patientFirstName}{" "}
                {patientLastName}
              </h3>
              {isCrossCoverage && (
                <Label className="cross-coverage-indicator" horizontal style={{ float: "right" }}>
                  Cross coverage
                </Label>
              )}
            </div>
            <Header as="h4" style={{ margin: "1em 0" }}>
              (MRN: {patientMRN}, DOB: {moment(patientBirthDate, isoFormat).format(dateFormat)}, Gender: {patientGender}
              )
            </Header>
            {deaClass > 0 && <StatePdmp patientId={patientId} compact />}
            <PrescriptionToSignForm
              prescriptionId={this.props.prescriptionId}
              rxInfo={{ prescriptionToSign: this.props.prescriptionToSign }}
              prescriptionToSign={currentPrescriptionToSign}
            />
          </Grid.Column>
          <Grid.Column width={10}>
            {visitId && Object.keys(visitSummary).length !== 0 && showAttestation && (
              <div>
                <Header as="h2">Before signing or sending the rx, the following items must be reviewed</Header>
                &nbsp;
                <Header as="h3">
                  Review the patient&#39;s last medical visit{" "}
                  {showAttestation && <span>(scroll down for attestations/signatures)</span>}
                </Header>
                <VisitSummary
                  visitSummary={visitSummary}
                  visitAmendment={visitAmendment}
                  authRoles={currentUserRoles}
                  isLoading={processing}
                  isSigned={isSigned}
                  isCoSigned={isCoSigned}
                  signDetail={latestVisitSignedChart}
                  cosignDetail={cosignDetail}
                  prescriptionsSent={prescriptionsSent}
                  visitTypeObject={visitTypeObject}
                  visitSummaryLocations={visitSummaryLocations}
                  centerOptions={centerOptions}
                  signHistory={signHistory}
                  labOrdersAndResultsPerVisit={labOrdersAndResultsPerVisit}
                  isReadOnly
                />
              </div>
            )}
            {showAttestation && (
              <AttestationForm
                {...this.props}
                prescriptionToSign={this.props.prescriptionToSign}
                attestationOptions={attestationOptions}
                currentPrescriptionToSign={currentPrescriptionToSign}
                currentPrescriber={currentPrescriber}
                patientName={`${patientFirstName} ${patientLastName}`}
                patientId={patientId}
                visitProvider={visitProvider}
              />
            )}
          </Grid.Column>
        </Grid>
      </Segment>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const prescribers = state.user.mainPrescribers.map(prescriber => {
    const availablePrescriptionSlots = prescriber.availablePrescriptionSlots || 0;

    return {
      value: prescriber.userId,
      text: `${prescriber.firstName} ${prescriber.lastName} (${availablePrescriptionSlots})`,
      availableprescriptionslots: availablePrescriptionSlots,
      fullname: `${prescriber.firstName} ${prescriber.lastName}`
    };
  });
  const currentPrescriber =
    prescribers.find(mp => mp.value === state.prescriptions.currentPrescriptionToSign.prescriberId) || {};
  const visitProvider =
    prescribers.find(mp => mp.value === state.prescriptions.currentPrescriptionToSign.visitProviderId) || {};

  return {
    prescriptionToSign: (state.prescriptions.prescriptionsToSign || []).find(
      i => i.prescriptionId === ownProps.prescriptionId
    ),
    currentPrescriptionToSign: state.prescriptions.currentPrescriptionToSign,
    currentPrescriber,
    visitProvider,
    processing: state.ajaxCallsInProgress > 0,
    patientVisits: state.visits.patientVisits,
    visitSummary: state.visits.visitSummary,
    visitAmendment: state.visits.visitAmendment,
    latestVisitSignedChart: state.charts.latestVisitSignedChart,
    cosignDetail: state.charts.cosignDetail,
    prescriptionsSent: state.prescriptions.prescriptionsSentByVisit,
    currentUserRoles: state.auth.user.profile.roles,
    visitTypes: state.lookups.visitTypes,
    attestationOptions: state.lookups.attestationOptions,
    visitSummaryLocations: state.visits.visitSummaryLocations,
    labOrdersAndResultsPerVisit: state.labs.labOrdersAndResultsPerVisit || {},
    signHistory: state.visits.visitSummary.signHistory || [],
    centerOptions: state.lookups.centers.map(i => ({ text: i.text, value: i.value }))
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        loadLookup,
        loadMainPrescribers,
        ...prescriptionActions,
        ...visitActions,
        ...labActions,
        ...chartsActions
      },
      dispatch
    )
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PrescriptionToSign);
