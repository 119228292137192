import React from "react";
import { Header, Grid, Icon, Message } from "semantic-ui-react";
import { isEmpty } from "lodash";
import moment from "moment";
import shortid from "shortid";
import NoteSlashTaskCommentsTable from "./NoteSlashTaskCommentsTable";
import { dateFormat, dateWithTimeFormat } from "../../../constants/miscellaneous";
import "./NoteSlashTaskFormModal.css";
import { getLookupText } from "../../../helpers";

const NoteSlashTaskCompactView = ({ entity, roleUserOptions, centerOptions, roleOptions }) => (
  <Grid>
    <Grid.Row>
      <Grid.Column width={16}>
        <Message color="yellow">
          Created by: {`${entity.creatorFirstName} ${entity.creatorLastName}, at: `}
          {moment(entity.createdDate).format(dateWithTimeFormat)}
        </Message>
      </Grid.Column>
    </Grid.Row>
    <Grid.Row>
      <Grid.Column width={16}>
        <React.Fragment>
          <label>Details</label>
          <p>
            {isEmpty(entity.body)
              ? `No details`
              : entity.body.split("\n").map((item) => (
                  <span key={`rand-${shortid.generate()}`}>
                    {item}
                    <br />
                  </span>
                ))}
          </p>
        </React.Fragment>
      </Grid.Column>
    </Grid.Row>
    {entity.type === "Task" && (
      <React.Fragment>
        <Grid.Row>
          <Grid.Column width={6}>
            <label style={{ display: "inline-block", width: "30%" }}>Due Date</label>
            <div style={{ display: "inline-block", width: "70%" }}>{moment(entity.dueDate).format(dateFormat)}</div>
          </Grid.Column>
          <Grid.Column width={6}>
            {entity.isUrgent && (
              <React.Fragment>
                <Icon size="large" name="exclamation circle" color="red" />
                Urgent
              </React.Fragment>
            )}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row width={16}>
          <Grid.Column>
            <strong>Assigned to: </strong>
            {!isEmpty(entity.assignee.assignedCenter) && !isEmpty(centerOptions) && (
              <React.Fragment>
                <label style={{ marginRight: "1em" }}>Center:</label>
                <span style={{ marginRight: "1em" }}>
                  {getLookupText(entity.assignee.assignedCenter, centerOptions)}
                </span>
              </React.Fragment>
            )}
            {!isEmpty(entity.assignee.assignedRole) && !isEmpty(roleOptions) && (
              <React.Fragment>
                <label style={{ marginRight: "1em" }}>Role:</label>
                <span style={{ marginRight: "1em" }}>{getLookupText(entity.assignee.assignedRole, roleOptions)}</span>
              </React.Fragment>
            )}
            {!isEmpty(entity.assignee.assignedUserId) && !isEmpty(roleUserOptions[entity.assignee.assignedRole]) && (
              <React.Fragment>
                <label style={{ marginRight: "1em" }}>User:</label>
                <span style={{ marginRight: "1em" }}>
                  {getLookupText(entity.assignee.assignedUserId, roleUserOptions[entity.assignee.assignedRole])}
                </span>
              </React.Fragment>
            )}
            {isEmpty(roleUserOptions[entity.assignee.assignedRole]) && !isEmpty(entity.assignee.assignedUserFirstName) && (
              <React.Fragment>
                <label style={{ marginRight: "1em" }}>User:</label>
                <span style={{ marginRight: "1em" }}>
                  {`${entity.assignee.assignedUserFirstName} ${entity.assignee.assignedUserLastName}`}
                </span>
              </React.Fragment>
            )}
          </Grid.Column>
        </Grid.Row>
      </React.Fragment>
    )}
    <Grid.Row>
      <Grid.Column width={16}>
        <React.Fragment>
          <div className="field no-margin">
            <Header as="label" className="no-v-margin no-padding" content={`Comments`} style={{ fontSize: "1em" }} />
          </div>
          {entity.comments && (
            <Grid className="no-margin">
              <NoteSlashTaskCommentsTable comments={entity.comments} />
            </Grid>
          )}
        </React.Fragment>
      </Grid.Column>
    </Grid.Row>
  </Grid>
);

export default NoteSlashTaskCompactView;
