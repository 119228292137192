import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function errorLogsReducer(state = initialState.errorLogs, action) {
  switch (action.type) {
    case types.SET_RECOVERABLE_ERRORS:
      return { ...state, recoverable: action.data };
    case types.SET_NON_RECOVERABLE_ERRORS:
      return { ...state, nonRecoverable: action.data };
    case types.SET_SELECTED_ERROR_DETAILSS:
      return { ...state, selectedErrorDetails: action.data };
    case types.SET_ERROR_KEYWORDS:
      return { ...state, errorKeywords: action.data };
    default:
      return state;
  }
}
