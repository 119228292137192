import React, { Component } from "react";

class SectionEditor extends Component {
  handleFieldChange = (key, value) => {
    const { section, update } = this.props;
    const uSection = { ...section };
    uSection[key] = value;
    update(uSection);
  };
  render() {
    const { section } = this.props;
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <form className="ui form" style={{ padding: "15px" }}>
              <div className="form-group row">
                <label className="col-sm-3 col-form-label">key</label>
                <div className="col-sm-9">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="key"
                    value={section.key}
                    onChange={e => this.handleFieldChange("key", e.target.value)}
                  />
                </div>
              </div>
              <div className="form-group row">
                <label className="col-sm-3 col-form-label">header</label>
                <div className="col-sm-9">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="header"
                    value={section.header}
                    onChange={e => this.handleFieldChange("header", e.target.value)}
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default SectionEditor;
