import React from "react";
import shortid from "shortid";
import { Input, Button } from "semantic-ui-react";
import AllergySliderRow from "./AllergySliderRow";

import "../SliderPanel.css";

const AllergySlider = ({
  allergies,
  sliderVisible,
  sliderClose,
  handleQuickSearch,
  handleSearchEnter,
  handleSearchInput,
  handleSearchClick
}) => (
  <div className={`slider-panel ${sliderVisible ? "visible" : ""}`}>
    <Button icon="arrow right" onClick={sliderClose} className="slider-close transparent-button-icon" />
    <div className="search-container">
      <Input
        placeholder="Allergies Search"
        onKeyPress={handleSearchEnter}
        onChange={handleSearchInput}
        icon={{
          name: "search",
          link: true,
          onClick: handleSearchClick
        }}
        id="input-search-allergy"
      />
    </div>
    <div className="search-results-container">
      <h3>common</h3>
      <div>
        {allergies.map(allergyHistoryItem => (
          <AllergySliderRow
            key={shortid.generate()}
            handleQuickSearch={handleQuickSearch}
            allergy={allergyHistoryItem}
          />
        ))}
      </div>
    </div>
  </div>
);

export default AllergySlider;
