import React, { Component } from "react";
import { Table } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { isEmpty, sortBy } from "lodash";
import moment from "moment";
import { connect } from "react-redux";
import { dateWithTimeFormat } from "../../../constants/miscellaneous";

export class ScheduledAppointmentsTable extends Component {
  state = {
    appointments: this.props.myCalendar.appointments,
    column: null,
    direction: null,
    data: [],
    selectedRow: "",
    selectedMyPatientsId: ""
  };

  componentDidMount() {
    this._isMounted = true;
  }

  componentDidUpdate(prevProps) {
    const {
      myCalendar: { appointments }
    } = this.props;
    if (
      appointments.length !== prevProps.myCalendar.appointments.length ||
      !appointments.every((a) =>
        prevProps.myCalendar.appointments.some(
          (aa) => aa.appointmentId === a.appointmentId && aa.appointmentStatus === a.appointmentStatus
        )
      )
    ) {
      this.setState({ appointments: this.props.myCalendar.appointments }, () => {
        if (!isEmpty(this.state.column)) this.handleSort(this.state.column, true);
      });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  _isMounted = false;

  handleRowSelect = (e) => {
    const currentRow = e.currentTarget.getAttribute("data-index");
    const appointmentId = e.currentTarget.getAttribute("data-visit");
    this.setState({
      selectedRow: currentRow,
      selectedVisitId: appointmentId
    });
  };

  handleSort = (clickedColumn, justSort = false) => {
    const { appointments, column, direction } = this.state;
    const sorted = sortBy(appointments, [clickedColumn]);
    if (justSort || column !== clickedColumn) {
      this.setState({
        appointments: sorted,
        selectedRow: "",
        column: clickedColumn,
        direction: "ascending"
      });
      return;
    }
    const newDirection = direction === "ascending" ? "descending" : "ascending";
    this.setState({
      appointments: newDirection === "ascending" ? sorted : sorted.reverse(),
      selectedRow: "",
      column: clickedColumn,
      direction: newDirection
    });
  };

  render() {
    const { appointments, column, direction, selectedRow } = this.state;
    return (
      <React.Fragment>
        {appointments.length > 0 ? (
          <div>
            <h2>Scheduled Appointments</h2>
            <div className="divMyPatients">
              <Table selectable sortable className="appointments-table">
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell
                      sorted={column === "appointmentStartTime" ? direction : null}
                      onClick={() => {
                        this.handleSort("appointmentStartTime");
                      }}
                    >
                      Visit Start
                    </Table.HeaderCell>
                    <Table.HeaderCell
                      sorted={column === "patientFullName" ? direction : null}
                      onClick={() => {
                        this.handleSort("patientFullName");
                      }}
                    >
                      Patient Name
                    </Table.HeaderCell>
                    <Table.HeaderCell
                      sorted={column === "provider" ? direction : null}
                      onClick={() => {
                        this.handleSort("provider");
                      }}
                    >
                      Provider
                    </Table.HeaderCell>
                    <Table.HeaderCell
                      sorted={column === "insurance" ? direction : null}
                      onClick={() => {
                        this.handleSort("insurance");
                      }}
                    >
                      Insurance
                    </Table.HeaderCell>
                    <Table.HeaderCell
                      sorted={column === "mainPrescriber" ? direction : null}
                      onClick={() => {
                        this.handleSort("mainPrescriber");
                      }}
                    >
                      Main Prescriber
                    </Table.HeaderCell>
                    <Table.HeaderCell
                      sorted={column === "appointmentStatus" ? direction : null}
                      onClick={() => {
                        this.handleSort("appointmentStatus");
                      }}
                    >
                      Status
                    </Table.HeaderCell>
                    <Table.HeaderCell
                      sorted={column === "appointmentVisitType" ? direction : null}
                      onClick={() => {
                        this.handleSort("appointmentVisitType");
                      }}
                    >
                      Visit Type
                    </Table.HeaderCell>
                    <Table.HeaderCell
                      sorted={column === "centerName" ? direction : null}
                      onClick={() => {
                        this.handleSort("centerName");
                      }}
                    >
                      Visit Center
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {appointments.map(
                    (
                      {
                        appointmentStartTime,
                        centerName,
                        patientFullName,
                        mainPrescriber,
                        appointmentStatus,
                        appointmentVisitType,
                        patientId,
                        appointmentId,
                        provider,
                        insurance
                      },
                      i
                    ) => (
                      <Table.Row
                        key={appointmentId}
                        data-index={i}
                        data-visit={appointmentId}
                        onClick={this.handleRowSelect}
                        className={String(i) === selectedRow ? "selected" : ""}
                      >
                        <Table.Cell data-label="Visit Start">
                          <span>{moment(appointmentStartTime).format(dateWithTimeFormat)}</span>
                        </Table.Cell>
                        <Table.Cell data-label="Patient Name">
                          <span>
                            <Link
                              to={`/current-patients/${patientId}/demographics`}
                              style={{ textDecoration: "none" }}
                              key={patientId}
                            >
                              {patientFullName}
                            </Link>
                          </span>
                        </Table.Cell>
                        <Table.Cell data-label="Provider">
                          <span>{provider}</span>
                        </Table.Cell>
                        <Table.Cell data-label="Insurance">
                          <span>{insurance}</span>
                        </Table.Cell>
                        <Table.Cell data-label="Main Prescriber">
                          <span>{mainPrescriber}</span>
                        </Table.Cell>
                        <Table.Cell data-label="Status">
                          <span>{appointmentStatus}</span>
                        </Table.Cell>
                        <Table.Cell data-label="Visit Type">
                          <span>{appointmentVisitType}</span>
                        </Table.Cell>
                        <Table.Cell data-label="Visit Type">
                          <span>{centerName}</span>
                        </Table.Cell>
                      </Table.Row>
                    )
                  )}
                </Table.Body>
                <Table.Footer />
              </Table>
            </div>
          </div>
        ) : (
          <React.Fragment>
            <h2>Scheduled Appointments</h2>
            <div className="no-my-patient-placeholder">You currently have no scheduled appointments</div>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}
function mapStateToProps(state) {
  return {
    myCalendar: state.visits.myCalendar
  };
}

export default connect(mapStateToProps, null)(ScheduledAppointmentsTable);
