/* eslint-disable react/no-array-index-key */
import React, { Component } from "react";
import { Image, Grid, TextArea, Form, Button, Table, List, Header, Accordion, Icon } from "semantic-ui-react";
import shortid from "shortid";
import moment from "moment";
import { isEmpty } from "lodash";
import logo from "../../../assets/cleanslate-logo-color.svg";
import { checkRoles, getLookupText } from "../../../helpers";
import { roleGroups } from "../../../constants/securityRoles";
import {
  datetimeFormatRemoveZeroInHour,
  dateFormat,
  dateWithTimeFormat,
  isoFormat,
  visitSummarySections
} from "../../../constants/miscellaneous";
import "./VisitSummary.css";
import VisitResultsReview from "../labs/VisitResultsReview";
import { Container, Row, Col } from "../../../ui";

export class VisitSummary extends Component {
  state = {
    activeIndex: -1
  };

  checkHasReviewedResults = (visitId) => this.props.reviewedLabResultsVisits.map((v) => v.visitId).includes(visitId);

  render() {
    const {
      visitSummary: { visitHeader, surveys },
      visitAmendment: { amendments },
      handleAddAmendment,
      handleInput,
      authRoles,
      isLoading,
      amendmentContent,
      isSigned,
      isCoSigned,
      cosignDetail,
      signDetail,
      prescriptionsSent = [],
      isReadOnly,
      handleCoSign,
      visitId: summaryVisitId,
      visitTypeObject,
      patientId,
      showCoSign,
      handlerPrintSummary,
      listVisitSelected,
      isPrint,
      patientHistory: {
        medicalHistory = [],
        interventionHistory = [],
        medicationHistory = [],
        allergyHistory = [],
        familyHistory = []
      } = {},
      showSign,
      handleSign,
      currentUserId: loggedInUserId,
      labOrdersAndResultsPerVisit: { labOrders = [], visitLabResults = [], priorVisitLabResults = [] } = {},
      signHistory,
      visitSummaryLocations: visitLocations,
      centerOptions,
      signCosignBarPosition,
      interventionConditions,
      include,
      reviewedLabResultsVisits,
      reviewedLabResultsHistory
    } = this.props;

    const vitals = surveys ? surveys.find((survey) => survey.surveyTitle === "Vitals") : null;
    const notVitals = surveys ? surveys.filter((survey) => survey.surveyTitle !== "Vitals") : null;
    const showAddAmendment = checkRoles(roleGroups.addAmendment, authRoles) && isSigned;

    if (!visitHeader) return null;

    const signInfo = {
      visitId: summaryVisitId,
      stateCode: visitHeader.center.state,
      currentUserId: loggedInUserId
    };

    const { activeIndex } = this.state;

    const handleSignHistoryClick = (e, titleProps) => {
      const { index } = titleProps;
      const newIndex = activeIndex === index ? -1 : index;
      this.setState({ activeIndex: newIndex });
    };

    const getSignHistoryText = (signHistoryItem) => {
      let historyText = moment(signHistoryItem.createdDate).format(dateWithTimeFormat);
      if (signHistoryItem.actionType === "sign") {
        historyText = `- Signed by ${signHistoryItem.providerFullName}, ${signHistoryItem.providerTitle} ${historyText}`;
      } else {
        historyText = `- Unsigned by ${signHistoryItem.providerFullName} ${historyText}`;
      }
      return historyText;
    };

    const isTelemedVisit = visitTypeObject ? visitTypeObject.virtual : false;

    const labHistoryReviewed = reviewedLabResultsHistory && reviewedLabResultsHistory.length > 0;

    const signCosignBar = (
      <React.Fragment>
        {!isReadOnly && !isCoSigned && showCoSign && (
          <Grid.Row>
            <Grid.Column width={16}>
              <Button
                className="co-sign-button"
                color="blue"
                content="Co-sign"
                onClick={handleCoSign(
                  summaryVisitId,
                  patientId,
                  visitHeader.provider.providerId,
                  visitHeader.center.state
                )}
                disabled={isLoading}
              />
            </Grid.Column>
          </Grid.Row>
        )}
        {!isReadOnly && !isSigned && showSign && (
          <Grid.Row>
            <Grid.Column width={16}>
              <Button className="sign-button" color="blue" content="Sign" onClick={handleSign(patientId, signInfo)} />
            </Grid.Column>
          </Grid.Row>
        )}
        {isCoSigned && (
          <Grid.Row>
            <Grid.Column width={16}>
              <div className="sign-chart-detail">
                {isCoSigned && (
                  <div className="cosign-detail">
                    <h3>Co-signed by {cosignDetail.supervisingProviderFullName}</h3>
                    <p>{moment(cosignDetail.dateSigned, isoFormat).format(dateWithTimeFormat)}</p>
                  </div>
                )}
              </div>
            </Grid.Column>
          </Grid.Row>
        )}
      </React.Fragment>
    );

    return (
      <div className={!isPrint ? "visit-summary" : "visit-summary-print"}>
        <div className="summary-header">
          <div />
          <Grid columns={4}>
            {signCosignBarPosition !== "bottom" && signCosignBar}
            <Grid.Row>
              <Grid.Column width={16}>
                <Accordion className="sign-history">
                  <Accordion.Title active={activeIndex === 0} index={0} onClick={handleSignHistoryClick}>
                    <Icon name="dropdown" />
                    Signing History
                    {isSigned && (
                      <label className="sign-history-label">
                        {" "}
                        - Signed by {signDetail.providerFullName} on{" "}
                        {moment(signDetail.dateSigned, isoFormat).format(dateWithTimeFormat)}
                      </label>
                    )}
                  </Accordion.Title>
                  {activeIndex > -1 && (
                    <Accordion.Content active={activeIndex === 0}>
                      {!isEmpty(signHistory) ? (
                        signHistory.map((item) => (
                          <label className="label-new-line" key={item.id}>
                            {getSignHistoryText(item)}
                          </label>
                        ))
                      ) : (
                        <label>No signing history found!</label>
                      )}
                    </Accordion.Content>
                  )}
                </Accordion>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <Image src={logo} alt="logo" size="small" />
              </Grid.Column>
              <Grid.Column>
                {visitHeader ? (
                  <div className="header-center-address">
                    <p>{visitHeader.center.address1}</p>
                    <p>{visitHeader.center.address2}</p>
                    <p>{`${visitHeader.center.city}, ${visitHeader.center.state} ${visitHeader.center.zip}-${visitHeader.center.zip4}`}</p>
                  </div>
                ) : (
                  <div>{`Bad visit data [missing visit header]`}</div>
                )}
              </Grid.Column>
              <Grid.Column>
                <h5>Provider</h5>
                {visitHeader ? (
                  <strong>{`${visitHeader.provider.firstName} ${visitHeader.provider.lastName}`}</strong>
                ) : (
                  <strong>{`Bad visit data [missing visit header]`}</strong>
                )}
              </Grid.Column>
              {!isReadOnly && listVisitSelected.length > 0 && (
                <Grid.Column>
                  <Button
                    basic
                    color="blue"
                    content={listVisitSelected.length === 1 ? "Print" : "Print multiple"}
                    onClick={handlerPrintSummary}
                  />
                </Grid.Column>
              )}
            </Grid.Row>
          </Grid>
          {visitHeader ? (
            <div className="visit-information-container">
              <h5>Visit Information for</h5>
              <strong>{`${visitHeader.patient.firstName} ${visitHeader.patient.lastName} ${
                visitHeader.patient.dateOfBirth
                  ? `(DOB ${moment(visitHeader.patient.dateOfBirth, dateWithTimeFormat).format(dateFormat)})`
                  : ""
              } on ${moment(visitHeader.visitDate).format(dateWithTimeFormat)}`}</strong>
            </div>
          ) : (
            <div>{`Bad visit data [missing visit header]`}</div>
          )}
        </div>
        <hr />
        <div className="visit-patient-hist">
          {(include === undefined || include.includes(visitSummarySections.HIST_ALLERGIES)) && (
            <div className="visit-allergy-history">
              <h4>Allergies</h4>
              <table className="tbl-patient-history">
                <tbody className="item-table-body">
                  {allergyHistory && allergyHistory.length
                    ? allergyHistory.map((item) => (
                        <tr key={item.itemId}>
                          <td>{item.allergySource}</td>
                        </tr>
                      ))
                    : null}
                </tbody>
              </table>
            </div>
          )}
          {(include === undefined || include.includes(visitSummarySections.HIST_MEDICATIONS)) && (
            <div className="visit-medication-history">
              <h4>Medications</h4>
              <table className="tbl-patient-history">
                <tbody className="item-table-body">
                  {medicationHistory && medicationHistory.length
                    ? medicationHistory.map((item) => (
                        <tr key={item.itemId}>
                          <td>
                            <b>{item.drugName}</b> {item.strength}
                          </td>
                        </tr>
                      ))
                    : null}
                </tbody>
              </table>
            </div>
          )}
          {(include === undefined || include.includes(visitSummarySections.HIST_MEDICAL_HISTORY)) && (
            <div className="visit-medical-history">
              <h4>Medical History</h4>
              <table className="tbl-patient-history">
                <tbody className="item-table-body">
                  {medicalHistory && medicalHistory.length
                    ? medicalHistory.map(
                        (item) =>
                          !item.isResolved && (
                            <tr key={item.itemId}>
                              <td>{item.condition}</td>
                            </tr>
                          )
                      )
                    : null}
                </tbody>
              </table>
            </div>
          )}
          {!isPrint && (
            <div className="visit-intervention-history">
              <h4>Interventions</h4>
              <table className="tbl-patient-history">
                <tbody className="item-table-body">
                  {interventionHistory && interventionHistory.length
                    ? interventionHistory.map(
                        (item) =>
                          !item.isComplete && (
                            <tr key={item.platformId}>
                              <td>
                                {isEmpty(interventionConditions)
                                  ? item.condition
                                  : interventionConditions.find((i) => i.code === item.condition).text}
                              </td>
                            </tr>
                          )
                      )
                    : null}
                </tbody>
              </table>
            </div>
          )}
          {(include === undefined || include.includes(visitSummarySections.HIST_FAMILY_HISTORY)) && (
            <div className="visit-family-history">
              <h4>Family History</h4>
              <table className="tbl-patient-history">
                <tbody className="item-table-body">
                  {familyHistory && familyHistory.length
                    ? familyHistory.map((item) => (
                        <tr key={item.itemId}>
                          <td>{item.condition}</td>
                        </tr>
                      ))
                    : null}
                </tbody>
              </table>
            </div>
          )}
          {isTelemedVisit && (include === undefined || include.includes(visitSummarySections.TELEHEALTH)) && (
            <div className="visit-family-history">
              <h4>Telehealth Visit Details</h4>
              <div style={{ marginLeft: "1%", width: "50%", borderBottom: "1pt solid gray" }}>
                <p>{`Patient was located at ${
                  visitLocations.isPatientHome ? `Home` : getLookupText(visitLocations.patientCenterId, centerOptions)
                } during this visit`}</p>
                <p>{`Provider was located at ${
                  visitLocations.isProviderHome ? `Home` : getLookupText(visitLocations.providerCenterId, centerOptions)
                } during this visit`}</p>
                {visitLocations.visitSpecimenCenterId && (
                  <p>{`Patient's Lab Specimen was collected by the ${getLookupText(
                    visitLocations.visitSpecimenCenterId,
                    centerOptions
                  )} center`}</p>
                )}
                <p>
                  {visitTypeObject.medical && visitLocations.sampleCollectionTime
                    ? `Specimen collection Date/Time: ${moment(visitLocations.sampleCollectionTime).format(
                        dateWithTimeFormat
                      )}`
                    : null}
                </p>
                <p>{!isEmpty(visitLocations.facilitatedBy) && `Facilitated by ${visitLocations.facilitatedBy}`}</p>
              </div>
            </div>
          )}
        </div>
        <Grid columns={2} className="summary-survey-results">
          <Grid.Row>
            <Grid.Column width={4}>
              <h4>Chief Complaint</h4>
              {visitHeader ? (
                <div>{visitHeader.chiefComplaint}</div>
              ) : (
                <div>{`Bad visit data [missing visit header]`}</div>
              )}
              {vitals && (include === undefined || include.includes(visitSummarySections.SURVEYS)) && (
                <div className="vitals-results">
                  <h4>{vitals.surveyTitle}</h4>
                  <div className="results-container">
                    {vitals.sections.map((section) => (
                      <React.Fragment key={`section-${section.header}`}>
                        {!isEmpty(section.header) && <strong>{section.header}</strong>}
                        {section.content.map((text) => (
                          <div key={`content-${text}`}>{text}</div>
                        ))}
                      </React.Fragment>
                    ))}
                  </div>
                </div>
              )}
            </Grid.Column>
            <Grid.Column width={12}>
              <div>
                {notVitals &&
                  (include === undefined || include.includes(visitSummarySections.SURVEYS)) &&
                  notVitals.map((survey) => (
                    <div key={`survey-${survey.surveyTitle}`} className="survey-container">
                      <h4>{survey.surveyTitle}</h4>
                      <div className="results-container">
                        {survey.sections.map((section) => (
                          <React.Fragment key={`section-${section.header}`}>
                            {!isEmpty(section.header) && <strong>{section.header}</strong>}
                            {section.content.map((text, i) => (
                              <div key={`content-${text}-${i}`}>{text}</div>
                            ))}
                          </React.Fragment>
                        ))}
                      </div>
                    </div>
                  ))}
                {(include === undefined || include.includes(visitSummarySections.AMENDMENTS)) && (
                  <div className="survey-container">
                    <h4
                      className={
                        amendments && amendments.length === 0
                          ? isPrint
                            ? "no-print"
                            : "disabled-title-amendment"
                          : null
                      }
                    >
                      Amendment
                    </h4>
                    <div className="results-container amendment-container">
                      {amendments &&
                        amendments.map((item) => (
                          <div className="amendment-view" key={shortid.generate()}>
                            <p>{item.content}</p>
                            <span>{`- ${item.createdBy.firstName} ${item.createdBy.lastName}, ${moment
                              .utc(item.createdOn)
                              .local()
                              .format(datetimeFormatRemoveZeroInHour)}`}</span>
                          </div>
                        ))}
                      {!isReadOnly && handleAddAmendment && (
                        <Form onSubmit={handleAddAmendment}>
                          <TextArea
                            id={`txt-amendment-for-visit-id-${summaryVisitId}`}
                            onChange={handleInput}
                            rows="4"
                            value={amendmentContent}
                            disabled={!showAddAmendment}
                          />
                          <Button
                            loading={isLoading}
                            disabled={!showAddAmendment}
                            className="btn-add-amendment"
                            primary
                          >
                            + Amendment
                          </Button>
                        </Form>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        {(include === undefined || include.includes(visitSummarySections.PRESCRIPTIONS)) &&
          prescriptionsSent.length > 0 && (
            <div style={{ paddingLeft: "10px", marginBottom: "10px" }}>
              <h4 style={{ color: "green" }}>Prescriptions this visit</h4>
              <Table className="no-border" padded>
                <Table.Header className="table-header-visit">
                  <Table.Row>
                    <Table.HeaderCell>Date</Table.HeaderCell>
                    <Table.HeaderCell>Prescriber</Table.HeaderCell>
                    <Table.HeaderCell>Prescription</Table.HeaderCell>
                    <Table.HeaderCell>Pharm Note</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {prescriptionsSent.map((rxSent) => (
                    <Table.Row key={rxSent.rxId}>
                      <Table.Cell>{moment(rxSent.rxSentDate).format(dateWithTimeFormat)}</Table.Cell>
                      <Table.Cell>
                        {rxSent.prescriberFirstName} {rxSent.prescriberLastName} {rxSent.prescriberTitle}
                      </Table.Cell>
                      <Table.Cell>
                        {rxSent.drugName} {rxSent.rxStrength} <br />
                        {rxSent.rxDosage} {rxSent.rxForm} {rxSent.rxFrequency}
                      </Table.Cell>
                      <Table.Cell>{rxSent.rxNoteToPharmacist}</Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            </div>
          )}

        {/** LABS */}
        <div style={{ paddingLeft: "10px", marginBottom: "10px" }}>
          <h4 style={{ color: "green" }}>Labs</h4>
          <Grid>
            <Grid.Row>
              <Grid.Column width={16}>
                {labHistoryReviewed && (
                  <Container className="mt-3 mb-2 text-muted">
                    <Row>
                      <Col className="p-0">
                        {reviewedLabResultsHistory.map((reviewDetails) => (
                          <p key={reviewDetails.reviewDate}>
                            <b>{`${reviewDetails.firstName} ${reviewDetails.lastName}`}</b>
                            {` has reviewed the analyzer results history - ${moment(reviewDetails.reviewDate).format(
                              dateWithTimeFormat
                            )}`}
                            <br />
                            <i>Lab History Comment:</i>
                            {(JSON.parse(reviewDetails.reviewContent)?.comments || []).map((_comment) => (
                              <p key={_comment}>
                                {_comment.split("\n").map((l) => (
                                  <span key={l}>
                                    {l}
                                    <br />
                                  </span>
                                ))}
                              </p>
                            ))}
                          </p>
                        ))}
                      </Col>
                    </Row>
                  </Container>
                )}
              </Grid.Column>
              <Grid.Column width={16}>
                {reviewedLabResultsVisits && (
                  <VisitResultsReview view="summary" visitId={summaryVisitId} includeNotReviewedResults={false} />
                )}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>

        {/** LABS for export view ONLY */}
        {include && // we only send `include` from iexport
          (include.includes(visitSummarySections.LAB_ORDERS) ||
            include.includes(visitSummarySections.LAB_RESULTS) ||
            include.includes(visitSummarySections.LAB_PRIOR_RESULTS)) && (
            <div style={{ paddingLeft: "10px", marginBottom: "10px" }}>
              <Header as="h4" style={{ color: "green" }}>
                Labs
              </Header>
              <Grid>
                {(include === undefined || include.includes(visitSummarySections.LAB_ORDERS)) && labOrders.length > 0 && (
                  <Grid.Row>
                    <Grid.Column width={2}>Orders</Grid.Column>
                    {labOrders.map((labOrder) => (
                      <React.Fragment key={shortid.generate()}>
                        <Grid.Column width={14} floated="right">
                          <Header as="h5">
                            {labOrder.labName}&emsp;
                            {!isEmpty(labOrder.selectionReason) ? (
                              <i>{`Rationale for ordering: (${labOrder.selectionReason})`}</i>
                            ) : (
                              ``
                            )}
                          </Header>
                          <br />
                        </Grid.Column>
                        {labOrder.confirmatoryLabs.length > 0 && (
                          <Grid.Column width={14} floated="right">
                            Please automatically definitively test for the indicated substance if the ImmunoAssay test
                            on the analyzer returns:
                            <br />
                            <List bulleted>
                              {labOrder.confirmatoryLabs.map((confirmatoryItem) => (
                                <List.Item key={shortid.generate()}>
                                  {confirmatoryItem.displayText}&emsp;
                                  {!isEmpty(confirmatoryItem.selectionReason) ? (
                                    <i>{`Rationale for ordering: (${confirmatoryItem.selectionReason})`}</i>
                                  ) : (
                                    ``
                                  )}{" "}
                                </List.Item>
                              ))}
                            </List>
                            <br />
                          </Grid.Column>
                        )}
                      </React.Fragment>
                    ))}
                  </Grid.Row>
                )}
                {(include === undefined || include.includes(visitSummarySections.LAB_PRIOR_RESULTS)) &&
                  priorVisitLabResults.length > 0 && (
                    <Grid.Row>
                      <Grid.Column width={2}>Prior visit&#39;s results</Grid.Column>
                      {priorVisitLabResults.map((priorVisitLabResult, index) => (
                        <React.Fragment key={shortid.generate()}>
                          {index > 0 && (
                            <React.Fragment>
                              <Grid.Column width={16}>
                                <br />
                              </Grid.Column>
                              <Grid.Column width={2} />
                            </React.Fragment>
                          )}
                          <Grid.Column width={3}>
                            <Header as="h5">{priorVisitLabResult.resultLabName}</Header>
                          </Grid.Column>
                          <Grid.Column width={11}>
                            {`sample date: ${moment(priorVisitLabResult.sampleDate).format(dateFormat)}`}
                          </Grid.Column>
                          <Grid.Column width={2} />
                          <Grid.Column width={3} className="bold-header">
                            Test
                          </Grid.Column>
                          <Grid.Column width={4} className="bold-header">
                            Result
                          </Grid.Column>
                          <Grid.Column width={4} className="bold-header">
                            Ref. Range
                          </Grid.Column>
                          <Grid.Column width={3} className="bold-header">
                            Result Date
                          </Grid.Column>
                          {priorVisitLabResult.results.map((result) => (
                            <React.Fragment key={shortid.generate()}>
                              <Grid.Column width={2} />
                              <Grid.Column width={3}>{result.test}</Grid.Column>
                              <Grid.Column
                                style={result.isResultNormal ? {} : { color: "red" }}
                                width={4}
                              >{`${result.result} ${result.units}`}</Grid.Column>
                              <Grid.Column width={4}>{`${result.reference} ${result.units}`}</Grid.Column>
                              <Grid.Column width={3}>{moment(result.resultDate).format(dateFormat)}</Grid.Column>
                            </React.Fragment>
                          ))}
                        </React.Fragment>
                      ))}
                    </Grid.Row>
                  )}
                {(include === undefined || include.includes(visitSummarySections.LAB_RESULTS)) &&
                  visitLabResults.length > 0 && (
                    <Grid.Row>
                      <Grid.Column width={2}>This visit&#39;s results</Grid.Column>
                      {visitLabResults.map((visitLabResult, index) => (
                        <React.Fragment key={shortid.generate()}>
                          {index > 0 && (
                            <React.Fragment>
                              <Grid.Column width={16}>
                                <br />
                              </Grid.Column>
                              <Grid.Column width={2} />
                            </React.Fragment>
                          )}
                          <Grid.Column width={3}>
                            <Header as="h5">{visitLabResult.resultLabName}</Header>
                          </Grid.Column>
                          <Grid.Column width={11}>
                            {`sample date: ${moment(visitLabResult.sampleDate).format(dateFormat)}`}
                          </Grid.Column>
                          <Grid.Column width={2} />
                          <Grid.Column width={3} className="bold-header">
                            Test
                          </Grid.Column>
                          <Grid.Column width={4} className="bold-header">
                            Result
                          </Grid.Column>
                          <Grid.Column width={4} className="bold-header">
                            Ref. Range
                          </Grid.Column>
                          <Grid.Column width={3} className="bold-header">
                            Result Date
                          </Grid.Column>
                          {visitLabResult.results.map((result) => (
                            <React.Fragment key={shortid.generate()}>
                              <Grid.Column width={2} />
                              <Grid.Column width={3}>{result.test}</Grid.Column>
                              <Grid.Column
                                style={result.isResultNormal ? {} : { color: "red" }}
                                width={4}
                              >{`${result.result} ${result.units}`}</Grid.Column>
                              <Grid.Column width={4}>{`${result.reference} ${result.units}`}</Grid.Column>
                              <Grid.Column width={3}>{moment(result.resultDate).format(dateFormat)}</Grid.Column>
                            </React.Fragment>
                          ))}
                        </React.Fragment>
                      ))}
                    </Grid.Row>
                  )}
                {signCosignBarPosition === "bottom" && signCosignBar}
              </Grid>
            </div>
          )}
      </div>
    );
  }
}

export default VisitSummary;
