import React from "react";
import { Popover as BsPopover } from "react-bootstrap";

const Popover = React.forwardRef((props, ref) => <BsPopover {...props} ref={ref} />);

Popover.Header = BsPopover.Header;
Popover.Body = BsPopover.Body;
Popover.POPPER_OFFSET = BsPopover.POPPER_OFFSET;

export default Popover;
