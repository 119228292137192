import upperFirst from "lodash/upperFirst";
import "react-toastify/dist/ReactToastify.css";
import * as types from "./actionTypes";
import { beginAjaxCall, ajaxCallDone } from "./ajaxStatusActions";
import { pollApiEvents, processApiResponse, processApiError } from "../helpers";
import { adalApiFetch } from "../adalConfig";
import { API_URL } from "../constants/environment";

function createInsuranceSuccess(patientId, insurance, insuranceType) {
  return {
    type: types.CREATE_INSURANCE_SUCCESS,
    context: "patient",
    patientId,
    insurance,
    insuranceType
  };
}

function createReferralSuccess(patientId, referral) {
  return {
    type: types.CREATE_REFERRAL_SUCCESS,
    context: "patient",
    patientId,
    referral
  };
}

function loadPatientInsuranceSuccess(patientId, insurance) {
  return {
    type: types.LOAD_PATIENT_INSURANCE_SUCCESS,
    context: "patient",
    patientId,
    insurance
  };
}

function createUDSPriorAuthSuccess(patientId, priorAuth) {
  return {
    type: types.CREATE_UDS_PRIOR_AUTH_SUCCESS,
    context: "patient",
    patientId,
    priorAuth
  };
}

function createMedicationPriorAuthSuccess(patientId, response, medicationPriorAuth) {
  return {
    type: types.CREATE_MED_PRIOR_AUTH_SUCCESS,
    context: "patient",
    patientId,
    response,
    medicationPriorAuth
  };
}

function loadInsuranceHistorySuccess(patientId, insuranceHistory) {
  return {
    type: types.LOAD_INSURANCE_HISTORY_SUCCESS,
    context: "patient",
    patientId,
    insuranceHistory
  };
}

function loadBenefitInformationSuccess(patientId, benefitsInformation) {
  return {
    type: types.LOAD_BENEFIT_INFORMATION_SUCCESS,
    context: "patient",
    patientId,
    benefitsInformation
  };
}

function loadDrugHistorySuccess(patientId, drugHistory) {
  return {
    type: types.LOAD_DRUG_HISTORY_SUCCESS,
    context: "patient",
    patientId,
    drugHistory
  };
}

export function loadPatientInsurance(patientId) {
  return dispatch => {
    dispatch(beginAjaxCall("loadPatientInsurance"));
    return adalApiFetch(`${API_URL}/Patients/${patientId}/InsuranceInformation`)
      .then(insurance => dispatch(loadPatientInsuranceSuccess(patientId, insurance)))
      .catch(error => {
        processApiError(error, dispatch);
      })
      .finally(() => {
        dispatch(ajaxCallDone());
      });
  };
}

export function clearPatientInsurance() {
  return {
    type: types.CLEAR_PATIENT_INSURANCE_SUCCESS
  };
}

export function loadInsuranceHistory(patientId) {
  return dispatch => {
    dispatch(beginAjaxCall("loadInsuranceHistory"));
    return adalApiFetch(`${API_URL}/Patients/${patientId}/InsuranceHistory`)
      .then(insuranceHistory => dispatch(loadInsuranceHistorySuccess(patientId, insuranceHistory)))
      .catch(error => {
        processApiError(error, dispatch);
      })
      .finally(() => {
        dispatch(ajaxCallDone());
      });
  };
}

export function createInsurance(patientId, insurance, insuranceType) {
  return dispatch => {
    dispatch(beginAjaxCall("createInsurance"));
    return adalApiFetch(
      // using lodash upperFirst to make the object and API call the same
      `${API_URL}/Patients/${patientId}/${upperFirst(insuranceType)}`,
      {
        method: "POST",
        body: JSON.stringify(insurance)
      }
    )
      .then(response => processApiResponse(response.orchestrationId))
      .then(orchestrationId => pollApiEvents(orchestrationId, "IPatientInsuranceAddedEvent"))
      .then(() => dispatch(createInsuranceSuccess(patientId, insurance, insuranceType)))
      .catch(error => {
        processApiError(error, dispatch);
      })
      .finally(() => {
        dispatch(ajaxCallDone());
      });
  };
}

export function createMedicationPriorAuth(patientId, medicationPriorAuth) {
  return dispatch => {
    dispatch(beginAjaxCall("createMedicationPriorAuth"));
    return adalApiFetch(`${API_URL}/Patients/${patientId}/MedicationPriorAuthorization`, {
      method: "POST",
      body: JSON.stringify(medicationPriorAuth)
    })
      .then(response => processApiResponse(response.orchestrationId))
      .then(orchestrationId => pollApiEvents(orchestrationId, "IMedicationPriorAuthorizationAddedEvent"))
      .then(pollResponse =>
        dispatch(createMedicationPriorAuthSuccess(patientId, pollResponse.orchestrationId, medicationPriorAuth))
      )
      .catch(error => {
        processApiError(error, dispatch);
      })
      .finally(() => {
        dispatch(ajaxCallDone());
      });
  };
}

export function createUDSPriorAuth(patientId, priorAuth) {
  return dispatch => {
    dispatch(beginAjaxCall("createUDSPriorAuth"));
    return adalApiFetch(`${API_URL}/Patients/${patientId}/UDSPriorAuthorization`, {
      method: "POST",
      body: JSON.stringify(priorAuth)
    })
      .then(response => processApiResponse(response.orchestrationId))
      .then(orchestrationId => pollApiEvents(orchestrationId, "IUDSPriorAuthorizationAddedEvent"))
      .then(() => dispatch(createUDSPriorAuthSuccess(patientId, priorAuth)))
      .then(() => dispatch(createUDSPriorAuthSuccess(patientId, priorAuth)))
      .catch(error => {
        processApiError(error, dispatch);
      })
      .finally(() => {
        dispatch(ajaxCallDone());
      });
  };
}

export function createReferral(patientId, referral) {
  return dispatch => {
    dispatch(beginAjaxCall("createReferral"));
    return adalApiFetch(`${API_URL}/Patients/${patientId}/Referral`, {
      method: "POST",
      body: JSON.stringify(referral)
    })
      .then(response => processApiResponse(response.orchestrationId))
      .then(orchestrationId => pollApiEvents(orchestrationId, "IPatientReferralAddedEvent"))
      .then(() => dispatch(createReferralSuccess(patientId, referral)))
      .catch(error => {
        processApiError(error, dispatch);
      })
      .finally(() => {
        dispatch(ajaxCallDone());
      });
  };
}

export function loadBenefitInformation(patientId) {
  return dispatch => {
    dispatch(beginAjaxCall("loadBenefitInformation"));
    return adalApiFetch(`${API_URL}/Patients/${patientId}/BenefitInformation`)
      .then(benefitsInformation => dispatch(loadBenefitInformationSuccess(patientId, benefitsInformation)))
      .catch(error => {
        processApiError(error, dispatch);
      })
      .finally(() => {
        dispatch(ajaxCallDone());
      });
  };
}

export function loadDrugHistory(patientId) {
  return dispatch => {
    dispatch(beginAjaxCall("loadDrugHistory"));
    return adalApiFetch(`${API_URL}/Prescriptions/Patients/${patientId}/History`)
      .then(result => dispatch(loadDrugHistorySuccess(patientId, result)))
      .catch(error => {
        processApiError(error, dispatch);
      })
      .finally(() => {
        dispatch(ajaxCallDone());
      });
  };
}
