import React, { Component } from "react";
import { Modal, Button, Icon } from "semantic-ui-react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as modalActions from "../../../../actions/modalActions";
import * as demographicActions from "../../../../actions/demographicActions";

export class ConfirmReverseDischargeModal extends Component {
  state = {
    saving: false
  };

  handleCancel = () => {
    this.props.actions.hideModal();
  };

  handleReverse = () => {
    const {
      patient: { patientId }
    } = this.props;
    this.setState({ saving: true }, () => {
      this.props.actions.reverseDischargePatient(patientId).then(() => {
        this.setState({ saving: false }, () => {
          this.props.actions.loadPatientDemo(patientId);
          this.props.actions.loadPatientDischargeReason(patientId);
          this.handleCancel();
        });
      });
    });
  };

  render() {
    const { patient, mainPrescriber } = this.props;
    const { saving } = this.state;
    return (
      <Modal open onClose={this.handleCancel} closeIcon size="small">
        <Modal.Header id="lbl-modalTitle">
          <Icon name="warning sign" color="orange" />
          Are you sure that you want to reverse the discharge of {patient.patientFirstName} {patient.patientLastName}?
        </Modal.Header>
        <Modal.Content id="lbl-modalDesc">
          {mainPrescriber && `${mainPrescriber.firstName} ${mainPrescriber.lastName}`}
        </Modal.Content>
        <Modal.Actions>
          <Button id="btn-cancel" onClick={this.handleCancel} content="Don't Reverse Discharge" />
          <Button color="red" id="btn-reverse" loading={saving} onClick={this.handleReverse} content="Reverse" />
        </Modal.Actions>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  const {
    demographics: { prescribingProvider: mainPrescriber }
  } = state;
  return {
    patient: state.patient.currentPatient,
    mainPrescriber
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...modalActions,
        ...demographicActions
      },
      dispatch
    )
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfirmReverseDischargeModal);
