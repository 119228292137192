import React, { Component } from "react";
import { Form, Button } from "semantic-ui-react";
import { DateInput } from "semantic-ui-calendar-react";
import { dateFormat } from "../../../../constants/miscellaneous";
import { dateRegEx } from "../../../../constants/validation";
import { removeSpecialCharacters, requiredFieldsFilled, updateErrors, hasProperty } from "../../../../helpers";

const inputValidation = {
  required: ["endDate", "priorAuthorizationNumber"],
  endDate: dateRegEx
};

const clearState = {
  insuranceProviderKey: "",
  pharmacyCoverageProvider: "",
  endDate: "",
  priorAuthorizationNumber: "",
  rxBinNumber: ""
};

export class MedicationPriorAuthForm extends Component {
  state = {
    medicationPriorAuth: {
      insuranceProviderKey: "",
      pharmacyCoverageProvider: "",
      endDate: "",
      priorAuthorizationNumber: "",
      rxBinNumber: ""
    },
    errors: {}
  };

  handleSave = () => {
    this.props.handleMedicationSave(this.state.medicationPriorAuth);
    this.setState({ medicationPriorAuth: clearState });
  };

  handleFocus = e => {
    e.target.setAttribute("autocomplete", "nope");
  };

  handleInput = (e, data) => {
    const { name: field, value, required } = data;
    const errors = updateErrors(field, value, required, this.state.errors, inputValidation[field]);
    const medicationPriorAuth = Object.assign({}, this.state.medicationPriorAuth, {
      [field]: value
    });
    this.setState({ medicationPriorAuth, errors });
  };

  handleMaskedInput = e => {
    const {
      type,
      target: { name: field, required, value }
    } = e;
    let errors = Object.assign({}, this.state.errors);
    const medicationPriorAuth = Object.assign({}, this.state.medicationPriorAuth, {
      [field]: value
    });

    if (type !== "focus" && type !== "blur") {
      errors = updateErrors(
        field,
        required ? removeSpecialCharacters(value) : value,
        required,
        errors,
        inputValidation[field]
      );
    }

    this.setState({ medicationPriorAuth, errors });
  };

  render() {
    const { medicationPriorAuth, errors } = this.state;
    const { insuranceProviders, pcpOptions, enableAddAuth } = this.props;
    const requiredFieldsCheck = requiredFieldsFilled(inputValidation.required, medicationPriorAuth);
    const checkOptionalRequired =
      medicationPriorAuth.pharmacyCoverageProvider.length === 0 &&
      medicationPriorAuth.insuranceProviderKey.length === 0;
    const disableAdd = requiredFieldsCheck || Object.keys(errors).length !== 0 || checkOptionalRequired;
    return (
      <Form>
        <Form.Group>
          <Form.Field width={7}>
            <Form.Dropdown
              required={!medicationPriorAuth.pharmacyCoverageProvider}
              fluid
              selection
              search
              label="Insurance"
              name="insuranceProviderKey"
              id="input-insuranceProviderKey"
              placeholder="Select..."
              onChange={this.handleInput}
              options={insuranceProviders}
              value={medicationPriorAuth.insuranceProviderKey}
              error={hasProperty(errors, "insuranceProviderKey")}
              onFocus={this.handleFocus}
              selectOnBlur={false}
              disabled={!enableAddAuth}
            />
          </Form.Field>
          <Form.Field width={5}>
            <Form.Dropdown
              required={!medicationPriorAuth.insuranceProviderKey}
              fluid
              selection
              search
              label="Pharmacy Coverage"
              name="pharmacyCoverageProvider"
              onChange={this.handleInput}
              id="input-pharmacyCoverageProvider"
              placeholder="Select..."
              options={pcpOptions}
              value={medicationPriorAuth.pharmacyCoverageProvider}
              onFocus={this.handleFocus}
              selectOnBlur={false}
              disabled={!enableAddAuth}
            />
          </Form.Field>
          <Form.Field width={4} className={hasProperty(errors, "endDate") ? "error required field" : "required field"}>
            <label>End Date</label>
            <DateInput
              id="input-endDate"
              name="endDate"
              placeholder="End Date"
              value={medicationPriorAuth.endDate}
              dateFormat={dateFormat}
              iconPosition="right"
              onChange={this.handleInput}
              disabled={!enableAddAuth}
              hideMobileKeyboard
              closable
            />
          </Form.Field>
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Field>
            <Form.Input
              required
              label="PA #"
              name="priorAuthorizationNumber"
              value={medicationPriorAuth.priorAuthorizationNumber}
              onChange={this.handleInput}
              id="input-priorAuthorizationNumber"
              error={hasProperty(errors, "priorAuthorizationNumber")}
              disabled={!enableAddAuth}
            />
          </Form.Field>
          <Form.Field>
            <Form.Input
              label="RX Bin"
              name="rxBinNumber"
              placeholder="Optional"
              value={medicationPriorAuth.rxBinNumber}
              onChange={this.handleInput}
              id="input-rxBinNumber"
              disabled={!enableAddAuth}
            />
          </Form.Field>
          <Form.Field>
            {enableAddAuth && (
              <Button color="blue" disabled={disableAdd} onClick={this.handleSave} className="prior-auth-button">
                Add New PA
              </Button>
            )}
          </Form.Field>
        </Form.Group>
      </Form>
    );
  }
}

export default MedicationPriorAuthForm;
