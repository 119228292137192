import React from "react";
import { Icon, Table } from "semantic-ui-react";
import moment from "moment";
import momenttz from "moment-timezone";
import { getLookupText } from "../../../helpers";
import "./VisitsPage.css";
import { dateWithTimeFormat } from "../../../constants/miscellaneous";

const AppointmentRow = ({
  visitDateTime,
  episodeOfCareNumber,
  visitType,
  visitTypes,
  visitStatus,
  prescribingProvider
}) => (
  <Table.Row className="timeline-row" style={{ color: "gray" }}>
    <Table.Cell style={{ width: "30px" }}>
      <Icon name="calendar alternate" />
    </Table.Cell>
    <Table.Cell style={{ width: "200px" }}>
      {moment(visitDateTime).format(dateWithTimeFormat)} <small>[{momenttz.tz(momenttz.tz.guess()).zoneAbbr()}]</small>
    </Table.Cell>
    <Table.Cell>{episodeOfCareNumber}</Table.Cell>
    <Table.Cell>{getLookupText(visitType, visitTypes)}</Table.Cell>
    <Table.Cell />
    <Table.Cell>{visitStatus.visitStatus}</Table.Cell>
    <Table.Cell />
    <Table.Cell>{prescribingProvider && `${prescribingProvider.firstName} ${prescribingProvider.lastName}`}</Table.Cell>
    <Table.Cell />
  </Table.Row>
);

export default AppointmentRow;
