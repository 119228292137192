import React, { Component } from "react";
import { Button, Header, Modal, Form, Table, Grid } from "semantic-ui-react";
import moment from "moment";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import shortid from "shortid";

import * as modalActions from "../../../../actions/modalActions";
import * as patientHistoryActions from "../../../../actions/patientHistoryActions";
import * as lookupActions from "../../../../actions/lookupActions";
import { longDateFormat } from "../../../../constants/miscellaneous";

import "../../../reusable/Modal.css";
import "./AddFamilyHistoryModal.css";

const getFamilyHist = props =>
  props.familyHistory || {
    relation: "",
    notes: []
  };

export class AddFamilyHistoryModal extends Component {
  state = {
    familyHistory: getFamilyHist(this.props),
    isNoteInputAvailiable: false,
    isNoteInputDisable: false,
    isRelationValid: false,
    familyNotesUpdate: [],
    isSaveDisable: false,
    note: ""
  };

  componentDidMount() {
    this.props.actions.loadRelationships();
    const { patientId } = this.props;
    this.props.actions.loadFamilyHist(patientId);
    const familyHistory = this.state.familyHistory;
    const currentState = this.state;
    if (familyHistory.relation) {
      currentState.isRelationValid = true;
    }
    this.setState(currentState);
  }

  componentWillUnmount() {
    const { patientId } = this.props;
    this.props.actions.loadFamilyHist(patientId);
  }

  handleInput = (e, data) => {
    const { name: field, value } = data;
    const { familyHistory } = this.state;
    let flag = this.state.isRelationValid;

    if (value.length > 0) {
      flag = true;
    } else {
      flag = false;
    }

    familyHistory[field] = value;
    this.setState({ [field]: value, familyHistory, isRelationValid: flag });
  };

  handleMaskedInput = e => {
    const { name: field, value } = e.target;
    const { familyHistory } = this.state;
    familyHistory[field] = value;
    this.setState({ [field]: value, familyHistory });
  };

  handleSave = () => {
    this.setState({ isSaveDisable: true });
    const { patientId, condition, familyHistory } = this.props;

    const newFamilyHistory = Object.assign({}, this.state.familyHistory, {
      condition,
      conditionKey: condition.key || "93"
    });

    if (this.state.familyHistory.itemId) {
      const familyUpdateHistory = Object.assign({}, this.state.familyHistory, {
        notes: this.state.familyNotesUpdate,
        condition,
        conditionKey: familyHistory.conditionKey || "93"
      });

      this.props.actions.updateFamilyHist(patientId, familyUpdateHistory).then(this.handleClose);
    } else {
      this.props.actions.createFamilyHist(patientId, newFamilyHistory).then(this.handleClose);
    }
  };

  handleClose = () => this.props.actions.hideModal();

  handleChangeNote = (e, data) => {
    const { value } = data;
    if (value.trim() !== "") {
      this.setState({ isNoteInputAvailiable: true });
    } else {
      this.setState({ isNoteInputAvailiable: false });
    }
    this.setState({ note: value });
  };

  handleAddNote = notes => () => {
    const noteInput = this.state.note;
    let updateNotes = [];
    // Current list note != null -> restore old note
    if (notes && notes.length > 0) {
      updateNotes = notes;
    }
    const noteAdd = {
      text: noteInput,
      noteAddedOn: new Date(),
      creatorFullName: this.props.userName
    };

    // Is update family => save new note to list separate old note list
    if (this.state.familyHistory.itemId) {
      const { familyNotesUpdate } = this.state;
      familyNotesUpdate.unshift(noteAdd);
    } else {
      // Add new medication -> only add one note
      this.setState({ isNoteInputDisable: true });
    }

    // Update to current notes
    updateNotes.unshift(noteAdd);
    const currentFamilyHistory = this.state.familyHistory;

    currentFamilyHistory.notes = updateNotes;
    const updateFamilyHistory = Object.assign({}, currentFamilyHistory, { note: noteInput });

    this.setState({
      familyHistory: updateFamilyHistory,
      note: "",
      isNoteInputAvailiable: false
    });
  };

  render() {
    const { open, relationships, processing } = this.props;
    const {
      familyHistory,
      isNoteInputAvailiable,
      isNoteInputDisable,
      isRelationValid,
      isSaveDisable,
      note
    } = this.state;
    const { notes } = familyHistory;

    return (
      <Modal className="add-family-history-modal" open={open} onClose={this.handleClose} closeIcon size="small">
        <Header content="Add Family History" />
        <h2>
          Condition: <span className="condition-header">{this.props.condition || familyHistory.condition}</span>
        </h2>
        <Modal.Content>
          <Form>
            <Form.Group>
              <Form.Field width={13}>
                <Form.Dropdown
                  fluid
                  selection
                  name="relation"
                  placeholder="Select..."
                  label="Relation"
                  value={familyHistory.relation}
                  required
                  options={relationships}
                  onChange={this.handleInput}
                  id="input-relation"
                />
              </Form.Field>
            </Form.Group>
            <Grid>
              <Grid.Column computer={13} tablet={13} moblie={13}>
                <Form.Input
                  className="family-history-note-input"
                  onChange={this.handleChangeNote}
                  id="input-note"
                  name="note"
                  disabled={isNoteInputDisable}
                  value={note}
                  label="Notes"
                />
              </Grid.Column>
              <Grid.Column className="btn-add" computer={3} tablet={3} mobile={3}>
                <Button
                  content="Add Note"
                  loading={processing}
                  color="blue"
                  id="btn-add-note"
                  onClick={this.handleAddNote(notes)}
                  disabled={!isNoteInputAvailiable}
                />
              </Grid.Column>
            </Grid>
            <div className="note-section">
              {notes
                ? notes.map(mNote => (
                    <React.Fragment key={mNote.noteId || shortid.generate()}>
                      <Table unstackable>
                        <Table.Body>
                          <Table.Row>
                            <Table.Cell colSpan="2">
                              <p>
                                <strong>{mNote.text}</strong>
                              </p>
                            </Table.Cell>
                          </Table.Row>
                          <Table.Row className="grey">
                            {mNote.noteAddedOn && (
                              <Table.Cell>{moment(mNote.noteAddedOn).format(longDateFormat)}</Table.Cell>
                            )}
                            <Table.Cell>{mNote.creatorFullName}</Table.Cell>
                          </Table.Row>
                          <Table.Row />
                        </Table.Body>
                      </Table>
                    </React.Fragment>
                  ))
                : null}
            </div>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.handleClose} id="btn-cancel">
            Cancel
          </Button>
          <Button
            content="Save"
            onClick={this.handleSave}
            loading={processing}
            color="blue"
            id="btn-save"
            disabled={!isRelationValid || isSaveDisable}
          />
        </Modal.Actions>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    patientId: state.patient.currentPatient.patientId,
    relationships: state.lookups.relationships,
    processing: state.ajaxCallsInProgress > 0,
    userName: state.auth.user.profile.fullName
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...modalActions, ...patientHistoryActions, ...lookupActions }, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddFamilyHistoryModal);
