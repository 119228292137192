import React, { Component } from "react";
import { Button, Header, Modal, Form } from "semantic-ui-react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as modalActions from "../../../actions/modalActions";
import * as lookupActions from "../../../actions/lookupActions";
import * as demographicActions from "../../../actions/demographicActions";
import "../../reusable/Modal.css";

export class EditPatientMainCenterModal extends Component {
  state = { mainClinic: this.props.demographics.homeCenterId || "" };

  componentDidMount() {
    this.props.actions.loadCentersLookup();
  }

  handleFocus = e => {
    e.target.setAttribute("autocomplete", "nope");
  };

  handleClose = () => this.props.actions.hideModal();

  handleInput = (e, data) => {
    const { name: field, value } = data || e.target;
    this.setState({ [field]: value });
  };

  handleSave = () => {
    const {
      actions: { updateCoreDemographics },
      patientId,
      demographics
    } = this.props;
    const { mainClinic } = this.state;
    const payload = { ...demographics, homeCenterId: mainClinic };
    updateCoreDemographics(patientId, payload).then(this.handleClose);
  };

  render() {
    const { open, mainClinics, processing } = this.props;
    const { mainClinic } = this.state;

    return (
      <Modal open={open} onClose={this.handleClose} closeIcon size="small">
        <Header content="Patient Support" />
        <Modal.Content>
          <Form>
            <Form.Field>
              <Form.Dropdown
                name="mainClinic"
                fluid
                selection
                label="Main Center"
                placeholder="Select..."
                options={mainClinics}
                value={mainClinic}
                search
                onChange={this.handleInput}
                id="input-mainClinic"
                onFocus={this.handleFocus}
              />
            </Form.Field>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.handleClose} id="btn-cancel">
            Cancel
          </Button>
          <Button color="blue" onClick={this.handleSave} id="btn-save" loading={processing} disabled={processing}>
            Save
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    mainClinics: state.lookups.centers || {},
    patientId: state.patient.currentPatient.patientId,
    demographics: state.demographics,
    processing: state.ajaxCallsInProgress > 0
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...modalActions,
        ...lookupActions,
        ...demographicActions
      },
      dispatch
    )
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditPatientMainCenterModal);
