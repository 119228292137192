import React from "react";
import { Grid, Form, Table, Button, Message } from "semantic-ui-react";
import { hasProperty } from "../../helpers";

const NewCenterFormIntegrationTab = ({
  center,
  integrationKey,
  handleIkInput,
  handleFocus,
  errors,
  integrationKeyOptions,
  enableEditDetails,
  handleAddIntegrationKey,
  handleDeleteIntegrationKey,
  integrationKeyErrorMessage
}) => (
  <React.Fragment>
    <Grid>
      <Grid.Column width={14}>
        <Form.Group widths="equal">
          <Form.Field>
            <Form.Dropdown
              required
              fluid
              selection
              search
              options={integrationKeyOptions}
              placeholder="Select..."
              label="Key"
              name="name"
              value={integrationKey.name}
              onChange={handleIkInput}
              error={hasProperty(errors, "integrationKeyName")}
              onFocus={handleFocus}
              disabled={!enableEditDetails}
            />
          </Form.Field>
          <Form.Field>
            <Form.Input
              required
              label="Value"
              name="value"
              value={integrationKey.value}
              onChange={handleIkInput}
              error={hasProperty(errors, "integrationKeyValue")}
              disabled={!enableEditDetails}
            />
          </Form.Field>
        </Form.Group>
      </Grid.Column>
      <Grid.Column width={2} className="field">
        <label>&nbsp;</label>
        <Button
          color="blue"
          onClick={handleAddIntegrationKey}
          disabled={!integrationKey.name || !integrationKey.value.trim()}
          style={{ width: "100%" }}
        >
          Add
        </Button>
      </Grid.Column>
    </Grid>
    {integrationKeyErrorMessage !== "" && <Message color="red">{integrationKeyErrorMessage}</Message>}
    <Table>
      <Table.Body>
        {center.integrationKeys.map(ik => (
          <Table.Row key={ik.integrationKeyName}>
            <Table.Cell>{ik.integrationKeyName}</Table.Cell>
            <Table.Cell>{ik.integrationKeyValue}</Table.Cell>
            <Table.Cell width={1}>
              <Button
                icon="delete"
                className="transparent-button-icon delete"
                onClick={() => handleDeleteIntegrationKey(ik.integrationKeyName)}
              />
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  </React.Fragment>
);

export default NewCenterFormIntegrationTab;
