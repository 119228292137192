/* eslint-disable max-len */
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import { Button, Checkbox, Table, Dimmer, Grid, Dropdown } from "semantic-ui-react";
import moment from "moment";
import { isEqual, isEmpty } from "lodash";
import { DateTimeInput } from "semantic-ui-calendar-react";
import { isoFormat, dateFormat, dateWithTimeFormat } from "../../constants/miscellaneous";
import { processApiError, unsubscribe } from "../../helpers";
import * as labActions from "../../actions/labActions";
import * as documentActions from "../../actions/documentActions";
import * as modalActions from "../../actions/modalActions";
import PatientSearch from "../patients/PatientSearch";
import "./RequestedLabs.css";

export class RequestedLabs extends Component {
  componentDidMount() {
    this.props.actions.fetchRequestedLabs();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.centerId !== this.props.centerId || !isEqual(prevProps.contextPatient, this.props.contextPatient)) {
      if (this.props.streamId) {
        if (prevProps.centerId !== this.props.centerId && prevProps.centerId) {
          unsubscribe(`/RequestedLabs/center/${prevProps.centerId}`);
        }
        if (!isEqual(prevProps.contextPatient, this.props.contextPatient) && !isEmpty(prevProps.contextPatient)) {
          unsubscribe(`/PatientRequestedLabs/patient/${prevProps.contextPatient.id}`);
        }
      }
      this.props.actions.fetchRequestedLabs(true);
    }
  }

  componentWillUnmount() {
    // we do not unsubscribe center because it is used to update the badge
    if (this.props.streamId && !isEmpty(this.props.contextPatient)) {
      unsubscribe(`/PatientRequestedLabs/patient/${this.props.contextPatient.id}`);
    }
    this.props.actions.clearLabSubmissionQueue();
  }

  handleCancelOrder = (
    patientId,
    orderId,
    labSource,
    labCode,
    patientName,
    isConfirmatory = false,
    isCancelOrder = false
  ) =>
    this.props.actions.showModal({
      type: "CANCEL_LAB_ORDER",
      props: {
        open: true,
        patientId,
        orderId,
        labSource,
        labCode,
        patientName,
        isConfirmatory,
        isCancelOrder
      }
    });

  handleLabChecked = (data, patientId, orderId, labCode, labName, labSource) => {
    this.props.actions.setRequestedLabCheckedValue(data.checked, patientId, orderId, labCode, labName, labSource);
  };

  handleLabAllChecked = (data, patientId, orderId, labSource) => {
    this.props.actions.setAllLabSourceCheckedValue(data.checked, patientId, orderId, labSource);
  };

  toggleIncludeOrderedToday = () => {
    this.props.actions.toggleIncludeOrderedToday();
  };

  openDocument = (documentId, documentType, container) => {
    this.props.actions.getDocument(documentId, documentType, container).then((response) => {
      const blob = new Blob([response.document], {
        type: "application/pdf"
      });
      const fileURL = URL.createObjectURL(blob);
      window.open(fileURL);
    });
  };

  handleSpecimenCollectionCenterChange = (orderId, centerId) => {
    this.props.actions.setRequestedLabSpecimenCollectionCenter(orderId, centerId);
  };

  handleSpecimenCollectionDateChange = (orderId, date) => {
    this.props.actions.setRequestedLabSpecimenCollectionDate(orderId, date);
  };

  handleConfirmSpecimenDateAndCenterClicked = (patientId, orderId, specimenCenterId, specimenCollectionDate) => {
    this.props.actions.confirmSpecimenDateAndCenter(patientId, orderId, specimenCenterId, specimenCollectionDate);
  };

  handlePatientSelected = (patientSearch, patient) => {
    this.props.actions.setLabSubmissionQueuePatientContext(patient);
    patientSearch.resetComponent();
  };

  handleResetPatientContextClick = () => {
    this.props.actions.setLabSubmissionQueuePatientContext({});
  };

  handleOrderLabs = (patientId, orderId) => {
    this.props.actions.submitLabOrder(patientId, orderId).then((response) => {
      if (response && Array.isArray(response)) {
        response.forEach((event) => {
          if (event.eventTypes === "ILabOrderSubmittedEvent") {
            const message = event.message;
            if (message.Status !== "Submitted") {
              processApiError(message.ErrorMessage);
            } else if (message.Container) {
              ["LabLabelDocumentKey", "RequisitionDocumentKey"].forEach((attribute) => {
                if (message[attribute])
                  this.openDocument(message[attribute], message.LabProvider + message[attribute], message.Container);
              });
            }
            if (message.AoeUrl) {
              this.props.actions.showModal({
                type: "AOE",
                props: {
                  open: true,
                  multiple: true,
                  patientId,
                  orderId,
                  url: message.AoeUrl
                }
              });
            }
          }
        });
      }
    });
  };

  render() {
    const { centers, contextPatient, fetchingRequestedLabsFlag, requestedLabs } = this.props;
    return (
      <div className="lab-submission-queue">
        <div className="header-wrapper">
          <h2>Lab Submission Queue</h2>
        </div>
        <div className="content-wrapper">
          <Grid className="no-padding">
            <Grid.Row className="no-padding">
              <Grid.Column width={4} className="half-v-padding">
                <Checkbox
                  label="Include Today's Lab Orders"
                  onChange={this.toggleIncludeOrderedToday}
                  checked={this.props.includeOrderedToday}
                />
              </Grid.Column>
              <Grid.Column width={4}>
                <PatientSearch handlePatientSelected={this.handlePatientSelected} />
              </Grid.Column>
              <Grid.Column width={8} className="half-v-padding">
                {!isEmpty(contextPatient) && (
                  <React.Fragment>
                    {`Displaying orders for `}
                    <strong>{`${contextPatient.firstName} ${contextPatient.lastName}`}</strong>
                    &emsp;
                    <Button
                      basic
                      size="mini"
                      // color="green"
                      content="Reset"
                      onClick={this.handleResetPatientContextClick}
                    />
                  </React.Fragment>
                )}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className="half-v-padding">
              <Grid.Column width={16} className="no-padding">
                <Table id="lab-submission-queue-table" className="lab-orders-table" compact columns={16}>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>Patient</Table.HeaderCell>
                      <Table.HeaderCell>Visit Date</Table.HeaderCell>
                      <Table.HeaderCell>Test</Table.HeaderCell>
                      <Table.HeaderCell>Lab</Table.HeaderCell>
                      <Table.HeaderCell>Required By</Table.HeaderCell>
                      <Table.HeaderCell>Test Type</Table.HeaderCell>
                      <Table.HeaderCell width={1}>Sample Type</Table.HeaderCell>
                      <Table.HeaderCell width={3} />
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {fetchingRequestedLabsFlag && (
                      <Table.Row id="lab-submission-queue-loading-row">
                        <Table.Cell className="text-center" colSpan={8}>{`Loading...`}</Table.Cell>
                      </Table.Row>
                    )}
                    {requestedLabs.length === 0 && !fetchingRequestedLabsFlag && (
                      <Table.Row id="lab-submission-queue-no-orders-row">
                        <Table.Cell className="text-center" colSpan={8}>{`No orders found`}</Table.Cell>
                      </Table.Row>
                    )}
                    {!fetchingRequestedLabsFlag &&
                      requestedLabs.map(
                        ({
                          patientId,
                          orderId,
                          labSources,
                          specimenCollectionDate,
                          specimenCenterId,
                          isFirstSubmission,
                          specimenCollectionDateAndCenterIdConfirmed,
                          specimenCenterIdChanged,
                          specimenCollectionDateChanged,
                          confirmingSpecimenDateAndCenter,
                          tests
                        }) => {
                          const showCancelButton = tests.some(
                            (lab) =>
                              lab.actionable ||
                              (lab.isPSCHold && !lab.canceled) ||
                              (lab.labProvider === "Clean Slate Rapid" && !lab.canceled)
                          );
                          const showSubmitButton = tests.some(
                            (lab) => lab.actionable || (lab.isPSCHold && !lab.canceled)
                          );
                          let newPatient = true;
                          const ret = [];
                          ret.push(
                            tests.map(
                              ({
                                patientFirstName,
                                patientLastName,
                                insurance,
                                pendingOrderDateTime,
                                labCode,
                                labName,
                                isConfirmatory,
                                labProvider,
                                labSource,
                                providerFirstName,
                                providerLastName,
                                actionable,
                                checked,
                                canceled,
                                submitted,
                                loading,
                                specimenCollectedByFirstName,
                                specimenCollectedByLastName,
                                // specimenCollectionDate,
                                canceledByFirstName,
                                canceledByLastName,
                                canceledDate,
                                cancellationReason,
                                // orderSubmittedById,
                                orderSubmittedByFirstName,
                                orderSubmittedByLastName,
                                orderSubmittedDate,
                                isPSCHold,
                                testType
                              }) => {
                                const old = moment(pendingOrderDateTime, isoFormat) < moment().startOf("day");
                                const _ret = (
                                  <Table.Row
                                    id={`lab-submission-queue-test-${patientId}-${orderId}-${labCode}`}
                                    key={`${patientId}-${orderId}-${labCode}}`}
                                    negative={old}
                                    className={newPatient ? "new-patient-row" : ""}
                                  >
                                    {newPatient && (
                                      <React.Fragment>
                                        <Table.Cell rowSpan={tests.length + 2}>
                                          <Link
                                            to={`/current-patients/${patientId}/labs`}
                                            style={{ textDecoration: "none" }}
                                            key={patientId}
                                          >
                                            {`${patientFirstName} ${patientLastName}`}
                                            <br />
                                            {insurance ? <small>{` (${insurance})`}</small> : ``}
                                          </Link>
                                        </Table.Cell>

                                        <Table.Cell rowSpan={tests.length + 2}>
                                          {moment.utc(pendingOrderDateTime, isoFormat).local().format(dateFormat)}
                                        </Table.Cell>
                                      </React.Fragment>
                                    )}
                                    <Table.Cell
                                      id={`lab-submission-queue-test-${patientId}-${orderId}-${labCode}-lab-name`}
                                    >
                                      {labName}
                                    </Table.Cell>
                                    <Table.Cell>{labProvider}</Table.Cell>
                                    <Table.Cell>{`${providerFirstName} ${providerLastName}`}</Table.Cell>
                                    <Table.Cell
                                      id={`lab-submission-queue-test-${patientId}-${orderId}-${labCode}-test-type`}
                                    >
                                      {testType}
                                    </Table.Cell>
                                    <Table.Cell width={1} style={{ minWidth: "100px" }}>
                                      {false && testType === "Confirmatory" && ` Auto Ordered `}
                                      {labProvider !== "Clean Slate Rapid" &&
                                        /* testType !== "Confirmatory" && */
                                        !canceled &&
                                        !isPSCHold && (
                                          <Dimmer.Dimmable dimmed={loading}>
                                            {labSource}
                                            <Dimmer active={loading} onClickOutside={this.handleHide} />
                                            {checked &&
                                              ` ${specimenCollectedByFirstName.charAt(
                                                0
                                              )}${specimenCollectedByLastName.charAt(0)}`}
                                          </Dimmer.Dimmable>
                                        )}
                                      {isPSCHold && `${labSource} (PSC hold)`}
                                    </Table.Cell>
                                    <Table.Cell width={3} style={{ textAlign: "right" }}>
                                      {submitted ? (
                                        <span>
                                          <span className={"success"}>
                                            {orderSubmittedByFirstName.charAt(0) + orderSubmittedByLastName.charAt(0)}{" "}
                                            Sent:{" "}
                                            {moment
                                              .utc(orderSubmittedDate, isoFormat)
                                              .local()
                                              .format(dateWithTimeFormat)}
                                            <br />
                                          </span>
                                          {cancellationReason}
                                        </span>
                                      ) : canceled ? (
                                        <span>
                                          <span className={"danger"}>
                                            {canceledByFirstName.charAt(0) + canceledByLastName.charAt(0)} Canceled:{" "}
                                            {moment.utc(canceledDate, isoFormat).local().format(dateWithTimeFormat)}
                                            <br />
                                          </span>
                                          {cancellationReason}
                                        </span>
                                      ) : actionable || isPSCHold || labProvider === "Clean Slate Rapid" ? (
                                        <Button
                                          id={`lab-submission-queue-test-${patientId}-${orderId}-${labCode}-cancel-button`}
                                          basic
                                          size="tiny"
                                          color="blue"
                                          content="Cancel"
                                          onClick={() =>
                                            this.handleCancelOrder(
                                              patientId,
                                              orderId,
                                              labSource,
                                              labCode,
                                              `${patientFirstName} ${patientLastName}`,
                                              isConfirmatory
                                            )
                                          }
                                        />
                                      ) : null}
                                    </Table.Cell>
                                  </Table.Row>
                                );
                                newPatient = false;
                                return _ret;
                              }
                            )
                          );

                          ret.push(
                            <Table.Row key={`${orderId}-check-row`} className={"action-row"}>
                              <Table.Cell colSpan={5} style={{ textAlign: "right" }}>
                                {labSources.map(
                                  (labSource) =>
                                    !labSource.canceled &&
                                    (labSource.checkedCount > 0 || labSource.uncheckedCount > 0) && (
                                      <Dimmer.Dimmable
                                        dimmed={labSource.loading}
                                        key={labSource.source}
                                        style={{ display: "inline-block" }}
                                      >
                                        <Checkbox
                                          id={`lab-submission-queue-order-${patientId}-${orderId}-check-all-${labSource.source}`}
                                          key={labSource.source}
                                          label={`All ${labSource.source}`}
                                          className={
                                            labSource.source.toLowerCase().indexOf("blood") > -1
                                              ? "danger"
                                              : labSource.source.toLowerCase().indexOf("urine") > -1
                                              ? "warning"
                                              : ""
                                          }
                                          onChange={(e, data) =>
                                            this.handleLabAllChecked(data, patientId, orderId, labSource)
                                          }
                                          checked={labSource.checked}
                                          disabled={labSource.canceled || labSource.submitted}
                                        />
                                        <Dimmer active={labSource.loading} onClickOutside={this.handleHide} />
                                      </Dimmer.Dimmable>
                                    )
                                )}
                              </Table.Cell>
                            </Table.Row>
                          );
                          ret.push(
                            <Table.Row key={`${orderId}-action-row`} className={"action-row"}>
                              <Table.Cell colSpan={5} style={{ textAlign: "right" }}>
                                {`Specimen Collected at Center `}
                                <Dropdown
                                  id={`lab-submission-queue-order-${patientId}-${orderId}-specimen-center-ddl`}
                                  name="type"
                                  className="mini"
                                  placeholder="Select a Center..."
                                  options={centers}
                                  onChange={(_, data) => {
                                    this.handleSpecimenCollectionCenterChange(orderId, data.value);
                                  }}
                                  value={specimenCenterId}
                                  disabled={!isFirstSubmission || confirmingSpecimenDateAndCenter}
                                  search
                                  selection
                                />
                                {` Date `}
                                <DateTimeInput
                                  id={`lab-submission-queue-order-${patientId}-${orderId}-specimen-date-time`}
                                  size="mini"
                                  className="inline"
                                  placeholder="Specimen Collection Date"
                                  value={moment(specimenCollectionDate).format(dateWithTimeFormat)}
                                  dateFormat={dateWithTimeFormat}
                                  iconPosition="right"
                                  maxDate={moment()}
                                  onChange={(_, data) => {
                                    this.handleSpecimenCollectionDateChange(orderId, data.value);
                                  }}
                                  disabled={!isFirstSubmission || confirmingSpecimenDateAndCenter}
                                  hideMobileKeyboard
                                  closable
                                />{" "}
                                <Button
                                  id={`lab-submission-queue-order-${patientId}-${orderId}-confirm-center-date-time-button`}
                                  color="orange"
                                  size="mini"
                                  content="Confirm"
                                  loading={confirmingSpecimenDateAndCenter}
                                  disabled={
                                    !isFirstSubmission ||
                                    confirmingSpecimenDateAndCenter ||
                                    labSources.some((labSource) => labSource.loading)
                                  }
                                  onClick={() =>
                                    this.handleConfirmSpecimenDateAndCenterClicked(
                                      patientId,
                                      orderId,
                                      specimenCenterId,
                                      specimenCollectionDate
                                    )
                                  }
                                />
                              </Table.Cell>
                              <Table.Cell style={{ textAlign: "right" }}>
                                {showCancelButton && (
                                  <Button
                                    id={`lab-submission-queue-order-${patientId}-${orderId}-cencel-button`}
                                    size="tiny"
                                    color="red"
                                    content="Cancel"
                                    loading={labSources.some((labSource) => labSource.loading)}
                                    disabled={
                                      labSources.some((labSource) => labSource.loading) ||
                                      labSources.every((labSource) => labSource.canceled)
                                    }
                                    onClick={() =>
                                      this.handleCancelOrder(
                                        patientId,
                                        orderId,
                                        null,
                                        null,
                                        `${tests[0].patientFirstName} ${tests[0].patientLastName}`,
                                        false,
                                        true
                                      )
                                    }
                                  />
                                )}
                                {showSubmitButton && (
                                  <Button
                                    id={`lab-submission-queue-order-${patientId}-${orderId}-submit-button`}
                                    size="tiny"
                                    primary
                                    onClick={() => this.handleOrderLabs(patientId, orderId)}
                                    loading={labSources.some((labSource) => labSource.loading)}
                                    disabled={
                                      labSources.some((labSource) => labSource.loading) ||
                                      !labSources.every((labSource) => labSource.checked) ||
                                      confirmingSpecimenDateAndCenter ||
                                      specimenCenterIdChanged ||
                                      specimenCollectionDateChanged ||
                                      !specimenCollectionDateAndCenterIdConfirmed
                                    }
                                  >
                                    Submit
                                  </Button>
                                )}
                              </Table.Cell>
                            </Table.Row>
                          );
                          return (
                            <Table.Row id={`lab-submission-queue-order-${orderId}`} key={orderId}>
                              <Table.Cell colSpan={8} className="no-padding">
                                <Table className="fluid" style={{ width: "100%" }}>
                                  <Table.Body>{ret}</Table.Body>
                                </Table>
                              </Table.Cell>
                            </Table.Row>
                          );
                        }
                      )}
                  </Table.Body>
                  <Table.Footer />
                </Table>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    streamId: state.streaming.streamId,
    centerId: state.userPreference.centerContext,
    contextPatient: state.labs.labSubmissionQueue.contextPatient,
    includeOrderedToday: state.labs.labSubmissionQueue.includeOrderedToday,
    requestedLabs: state.labs.labSubmissionQueue.requestedLabs,
    fetchingRequestedLabsFlag: state.labs.labSubmissionQueue.fetchingRequestedLabsFlag,
    centers: state.lookups.centers || []
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...modalActions, ...documentActions, ...labActions }, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RequestedLabs);
