import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { isEmpty } from "lodash";
import { Container, Row, Col, Form, Icon, Button } from "../../../../ui";
import * as modalActions from "../../../../actions/modalActions";
import * as demographicActions from "../../../../actions/demographicActions";
import * as patientHistoryActions from "../../../../actions/patientHistoryActions";
import { checkLengthError, removeExtraSpace } from "../../../../helpers";

class PatientOutsideCounselor extends Component {
  state = {
    model: { outsideCounselor: this.props.outsideCounselor || "" },
    originalModal: { outsideCounselor: this.props.outsideCounselor || "" },
    error: false,
    errorMessage: ""
  };

  componentDidMount() {
    const { patientId } = this.props;

    if (patientId) {
      this.props.actions.loadOutsideCounselorHistory(this.props.patientId);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.outsideCounselor !== prevProps.outsideCounselor) {
      const outsideCounselor = this.props.outsideCounselor;
      this.setState({ model: { outsideCounselor }, originalModal: { outsideCounselor } });
    }
  }

  handleInput = (e) => {
    const { name: field, value } = e.target;
    const model = Object.assign({}, this.state.model, {
      [field]: value
    });

    this.setState({
      model,
      error: checkLengthError(model.outsideCounselor, 100)
    });
  };

  handleBlur = () => {
    const { outsideCounselor } = this.state.model;
    if (checkLengthError(outsideCounselor, 100)) {
      return this.setState({
        errorMessage: "Outside Counselor's name should not exceed 100 text characters."
      });
    }
    return this.setState({ errorMessage: "" });
  };

  handleSave = () => {
    const { patientId } = this.props;
    const {
      model: { outsideCounselor },
      error
    } = this.state;
    const model = { outsideCounselor: removeExtraSpace(outsideCounselor.trim()) };
    if (!error) {
      this.props.actions.updateOutsideCounselorSuccess(patientId, model.outsideCounselor);
      this.props.actions.createOutsideCounselor(patientId, model).then(() => {
        this.setState({ originalModal: { outsideCounselor } });
        this.props.actions.loadOutsideCounselorHistory(this.props.patientId);
      });
    }
  };

  render() {
    const { model, originalModal, error, errorMessage } = this.state;
    const { handleOutsideCounselorHistory, outsideCounselors, saving } = this.props;
    const noChange = isEmpty(model.outsideCounselor) || model.outsideCounselor === originalModal.outsideCounselor;
    return (
      <div className="patient-outside-counselor-container">
        <Container>
          <Row>
            <Col md={8} className="p-0">
              <Form.Control
                type="text"
                id="input-outside-counselor"
                name="outsideCounselor"
                className="m-0"
                value={model.outsideCounselor || ""}
                onChange={this.handleInput}
                onBlur={this.handleBlur}
                // error={error}
              />
            </Col>
            <Col md={2}>
              <Button
                color="blue"
                onClick={this.handleSave}
                content=""
                loading={saving}
                disabled={saving || error || noChange}
              >
                Save
              </Button>
            </Col>
            <Col md={2}>
              {outsideCounselors.length > 0 && (
                <Button
                  variant="outline-primary"
                  id="btn-outside-counselor-history"
                  className="btn-history transparent-button-icon"
                  title="Outside Counselor History"
                  onClick={handleOutsideCounselorHistory}
                >
                  <Icon name="clock-history" />
                </Button>
              )}
            </Col>
          </Row>
          {errorMessage.length > 0 && (
            <Row>
              <div className="search-error">{errorMessage}</div>
            </Row>
          )}
        </Container>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    patientId: state.patient.currentPatient.patientId,
    outsideCounselors: state.patientHistory.outsideCounselorHistory,
    saving: state.ajaxCallsInProgress > 0
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...modalActions, ...demographicActions, ...patientHistoryActions }, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PatientOutsideCounselor);
