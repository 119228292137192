import React from "react";
import { Switch, Route } from "react-router-dom";
import PrescriptionsToSign from "./PrescriptionsToSign";

const Prescriptions = props => {
  const { isMobile } = props;

  return (
    <Switch>
      <Route path="/" render={() => <PrescriptionsToSign isMobile={isMobile} />} />
    </Switch>
  );
};

export default Prescriptions;
