import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function noteReducer(state = initialState.notes, action) {
  switch (action.type) {
    case types.LOAD_NOTES_SUCCESS:
      return Object.assign({}, state, {
        notes: action.notes
      });
    case types.SIGN_CHART_SUCCESS:
    default:
      return state;
  }
}