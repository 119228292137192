import React, { Component } from "react";
import { Grid, Table } from "semantic-ui-react";
import InfiniteScroll from "react-infinite-scroller";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import { debounce } from "lodash";
import moment from "moment";
import * as searchActions from "../../actions/searchActions";
import * as lookupActions from "../../actions/lookupActions";
import "./SearchResultsPage.css";
import LoadComponent from "../reusable/LoadComponent";
import AdvancedSearch from "./AdvancedSearch";
import { dateFormat } from "../../constants/miscellaneous";

export class SearchResultsPage extends Component {
  state = {
    activePage: 1,
    pageSize: 20,
    pageNumber: 1,
    sortColumn: null,
    sortDirection: null,
    filter: {
      center: true,
      state: "",
      socialSecurityNumber: "",
      insurancePolicyNumber: ""
    },
    result: { patients: [], totalPatientsCount: 0 },
    hasMoreItems: true,
    keyword: ""
  };

  componentDidMount() {
    this.resetComponent();
    this.props.actions.loadStates();
  }

  resetComponent = () => this.setState({ result: { patients: [], totalPatientsCount: 0 }, keyword: "" });

  handleSearchInput = (e, data) => {
    const { value } = data;
    if (value.length < 1) {
      this.resetComponent();
    } else {
      this.setState({ keyword: value, pageNumber: 1, hasMoreItems: true }, () => {
        this.handlePatientSearch();
      });
    }
  };

  handleAdvancedSearchInput = () => {
    this.setState({ pageNumber: 1, hasMoreItems: true }, () => {
      this.handlePatientSearch();
    });
  };

  handleCheckbox = (e, data) => {
    const { name: field, checked } = data;
    const filter = Object.assign({}, this.state.filter, {
      [field]: checked
    });
    this.setState({ filter });
  };

  handleInput = (e, data) => {
    const { name: field, value } = data || e.target;
    const filter = Object.assign({}, this.state.filter, { [field]: value });
    this.setState({
      filter,
      pageSize: this.state.pageSize,
      pageNumber: this.state.pageNumber
    });
  };

  handlePatientSearch = debounce(() => this.doHandlePatientSearch(), 500);

  doHandlePatientSearch = () => {
    const { filter, keyword, sortColumn, sortDirection } = this.state;
    const { centerContext } = this.props;
    if (!this.state.hasMoreItems || !keyword) return;
    this.props.actions
      .searchPatientAdvanced({
        value: keyword,
        centerContext,
        isAllCenter: filter.center,
        states: filter.state,
        socialSecurityNumber: filter.socialSecurityNumber,
        insurancePolicyNumber: filter.insurancePolicyNumber,
        pageSize: this.state.pageSize,
        pageNumber: this.state.pageNumber,
        sortBy: sortColumn,
        sortDirection: sortDirection === "ascending" ? "asc" : "desc"
      })
      .then(({ patients: result }) => {
        const updatedResult = { ...this.state.result };
        if (this.state.pageNumber === 1) {
          updatedResult.patients = [...result.patients];
        } else {
          updatedResult.patients = [...updatedResult.patients, ...result.patients];
        }
        updatedResult.totalPatientsCount = result.totalPatientsCount;
        this.setState({
          result: updatedResult,
          value: this.state.keyword,
          pageNumber:
            updatedResult.patients.length < result.totalPatientsCount
              ? this.state.pageNumber + 1
              : this.state.pageNumber,
          hasMoreItems: updatedResult.patients.length > 0 && updatedResult.patients.length < result.totalPatientsCount
        });
      });
  };

  fetch = () => {
    const { keyword, pageNumber } = this.state;
    const { processing } = this.props;
    if (keyword && pageNumber && !processing) {
      this.handlePatientSearch();
    }
  };

  handleSort = clickedColumn => () => {
    const { sortColumn, sortDirection } = this.state;
    this.setState(
      {
        hasMoreItems: true,
        pageNumber: 1,
        sortColumn: clickedColumn,
        sortDirection: sortDirection === "ascending" && sortColumn === clickedColumn ? "descending" : "ascending"
      },
      () => {
        this.fetch();
      }
    );
  };

  render() {
    const {
      result: { patients },
      filter,
      sortColumn,
      sortDirection
    } = this.state;
    const { processing, states } = this.props;

    return (
      <div>
        <AdvancedSearch
          filter={filter}
          handleSearchInput={this.handleSearchInput}
          handleInput={this.handleInput}
          handleCheckbox={this.handleCheckbox}
          handleAdvancedSearchInput={this.handleAdvancedSearchInput}
          states={states}
        />
        <InfiniteScroll
          pageStart={1}
          initialLoad={false}
          loadMore={this.fetch}
          hasMore={this.state.hasMoreItems}
          loader={processing ? <LoadComponent key="div-loader" /> : null}
        >
          <Grid className="no-margin">
            <Grid.Row>
              <Grid.Column width={16}>
                <Table selectable sortable style={{ wordBreak: "break-all" }} color="purple">
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell
                        sorted={sortColumn === "firstName" ? sortDirection : null}
                        onClick={this.handleSort("firstName")}
                      >
                        First Name
                      </Table.HeaderCell>
                      <Table.HeaderCell
                        sorted={sortColumn === "middleName" ? sortDirection : null}
                        onClick={this.handleSort("middleName")}
                      >
                        Middle Name
                      </Table.HeaderCell>
                      <Table.HeaderCell
                        sorted={sortColumn === "preferredName" ? sortDirection : null}
                        onClick={this.handleSort("preferredName")}
                      >
                        Preferred Name
                      </Table.HeaderCell>
                      <Table.HeaderCell
                        sorted={sortColumn === "lastName" ? sortDirection : null}
                        onClick={this.handleSort("lastName")}
                      >
                        Last Name
                      </Table.HeaderCell>
                      <Table.HeaderCell
                        sorted={sortColumn === "dateOfBirth" ? sortDirection : null}
                        onClick={this.handleSort("dateOfBirth")}
                      >
                        Date of Birth
                      </Table.HeaderCell>
                      <Table.HeaderCell
                        sorted={sortColumn === "city" ? sortDirection : null}
                        onClick={this.handleSort("city")}
                      >
                        City
                      </Table.HeaderCell>
                      <Table.HeaderCell
                        sorted={sortColumn === "state" ? sortDirection : null}
                        onClick={this.handleSort("state")}
                      >
                        State
                      </Table.HeaderCell>
                      <Table.HeaderCell
                        sorted={sortColumn === "medicalRecordNumber" ? sortDirection : null}
                        onClick={this.handleSort("medicalRecordNumber")}
                      >
                        MRN
                      </Table.HeaderCell>
                      <Table.HeaderCell
                        sorted={sortColumn === "centerName" ? sortDirection : null}
                        onClick={this.handleSort("centerName")}
                      >
                        Center
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {patients.map(patient => (
                      <Table.Row key={patient.platformId}>
                        <Table.Cell>
                          <Link
                            to={`/current-patients/${patient.platformId}/demographics`}
                            style={{ textDecoration: "none" }}
                            key={patient.platformId}
                          >
                            {`${patient.firstName}`}
                          </Link>
                        </Table.Cell>
                        <Table.Cell>{patient.middleName}</Table.Cell>
                        <Table.Cell>{patient.preferredName}</Table.Cell>
                        <Table.Cell>{patient.lastName}</Table.Cell>
                        <Table.Cell>{moment(patient.dateOfBirth).format(dateFormat)}</Table.Cell>
                        <Table.Cell>{patient.city}</Table.Cell>
                        <Table.Cell>{patient.state}</Table.Cell>
                        <Table.Cell>
                          <Link
                            to={`/current-patients/${patient.platformId}/demographics`}
                            style={{ textDecoration: "none" }}
                            key={patient.platformId}
                          >
                            {`${patient.medicalRecordNumber}`}
                          </Link>
                        </Table.Cell>
                        <Table.Cell>{patient.centerName}</Table.Cell>
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </InfiniteScroll>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    result: state.search.patients,
    processing: state.ajaxCallsInProgress > 0,
    states: state.lookups.states,
    centerContext: state.userPreference.centerContext
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...searchActions, ...lookupActions }, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchResultsPage);
