import React, { Component } from "react";
import set from "lodash/set";
import { Checkbox, Radio } from "semantic-ui-react";
import OptionsEditor from "./OptionsEditor";
import ElucidationsEditor from "./ElucidationsEditor";
import TriggersEditor from "./TriggersEditor";
import DefaultAnswersEditor from "./DefaultAnswersEditor";
import EventTriggersEditor from "./EventTriggersEditor";
import { isEmpty } from "../../../helpers";

class QuestionEditor extends Component {
  handleQuestionFieldChange = (key, value) => {
    const { question, update } = this.props;
    const uQuestion = { ...question };

    switch (key) {
      case "addQuestionElucidation": {
        const elucidations = [...uQuestion.elucidations];
        const questionElucidation = elucidations.find(e => e.key === "");
        if (value) {
          if (isEmpty(questionElucidation)) elucidations.push({ key: "", caption: "" });
        } else if (!isEmpty(questionElucidation)) {
          const index = elucidations.findIndex(e => e.key === "");
          elucidations.splice(index, 1);
        }
        uQuestion.elucidations = elucidations;
        break;
      }
      case "questionElucidationCaption": {
        const elucidations = uQuestion.elucidations.map(e => {
          if (e.key === "") return { ...e, caption: value };
          return e;
        });
        uQuestion.elucidations = elucidations;
        break;
      }
      default:
        set(uQuestion, key, value); // allow nested set
    }

    if (
      uQuestion.metadata &&
      uQuestion.metadata.service &&
      !isEmpty(uQuestion.metadata.service.target) &&
      isEmpty(uQuestion.metadata.service.order)
    )
      uQuestion.metadata = { ...uQuestion.metadata, service: { ...uQuestion.metadata.service, order: 1 } };

    if (uQuestion.metadata && uQuestion.metadata.service && isEmpty(uQuestion.metadata.service.target))
      delete uQuestion.metadata.service;

    if (
      uQuestion.type !== "SingleSelect" &&
      uQuestion.metadata &&
      uQuestion.metadata.ui &&
      uQuestion.metadata.ui.component
    )
      delete uQuestion.metadata.ui.component;
    if (!["SingleSelect", "MultiSelect"].includes(uQuestion.type)) delete uQuestion.options;
    if (["SingleSelect", "MultiSelect"].includes(uQuestion.type) && isEmpty(uQuestion.options)) uQuestion.options = [];

    update(uQuestion);
  };

  render() {
    const {
      question,
      survey: { questions }
    } = this.props;
    const singleSelectAs =
      question.type === "SingleSelect" && question.metadata && question.metadata.ui && question.metadata.ui.component
        ? question.metadata.ui.component
        : null;

    const isDiagnosisOrCptCode = !!(
      question.metadata &&
      question.metadata.service &&
      !isEmpty(question.metadata.service.target)
    );

    const isCptCode = !!(
      question.metadata &&
      question.metadata.service &&
      question.metadata.service.target &&
      question.metadata.service.target === "CPT"
    );

    const isDiagnosisCode = !!(
      question.metadata &&
      question.metadata.service &&
      question.metadata.service.target &&
      question.metadata.service.target === "ICD10"
    );

    const diagnosisCodeOrder =
      question.metadata && question.metadata.service && question.metadata.service.order
        ? question.metadata.service.order
        : 1;

    const isFreeDiagnosisCode = !!(
      question.metadata &&
      question.metadata.service &&
      question.metadata.service.target &&
      question.metadata.service.target === "ICD10FREE"
    );

    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <form className="ui form" style={{ padding: "15px" }}>
              <div className="form-group row">
                <label className="col-sm-3 col-form-label">key</label>
                <div className="col-sm-9">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="key"
                    value={question.key}
                    onChange={e => this.handleQuestionFieldChange("key", e.target.value)}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label className="col-sm-3 col-form-label">description</label>
                <div className="col-sm-9">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="description"
                    value={question.description}
                    onChange={e => this.handleQuestionFieldChange("description", e.target.value)}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label className="col-sm-3 col-form-label">Text</label>
                <div className="col-sm-9">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="text"
                    value={question.text}
                    onChange={e => this.handleQuestionFieldChange("text", e.target.value)}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label className="col-sm-3 col-form-label">Green box text</label>
                <div className="col-sm-9">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="shortDisplayText"
                    value={question.shortDisplayText}
                    onChange={e => this.handleQuestionFieldChange("shortDisplayText", e.target.value)}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label className="col-sm-3 col-form-label">Summary text</label>
                <div className="col-sm-9">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="summaryText"
                    value={question.summaryText}
                    onChange={e => this.handleQuestionFieldChange("summaryText", e.target.value)}
                  />
                </div>
              </div>
              <hr />
              <div className="form-group row">
                <label className="col-sm-3 col-form-label">Type</label>
                <div className="col-sm-9">
                  <select
                    className="form-control"
                    value={question.type}
                    onChange={e => this.handleQuestionFieldChange("type", e.target.value)}
                  >
                    <option value="ShortText">Short Text</option>
                    <option value="LongText">Long Text</option>
                    <option value="SingleSelect">Single Select</option>
                    <option value="MultiSelect">Multi Select</option>
                  </select>
                </div>
              </div>

              <div
                className="form-group row inline fields"
                style={{
                  display: question.type !== "SingleSelect" ? "none" : "flex"
                }}
              >
                <div className="field">
                  <div className="ui radio checkbox">
                    <input
                      type="radio"
                      name="single-select-as"
                      value="RadioGroup"
                      checked={singleSelectAs === "RadioGroup"}
                      onChange={e => this.handleQuestionFieldChange("metadata.ui.component", e.target.value)}
                    />
                    <label>As radios</label>
                  </div>
                </div>
                <div className="field">
                  <div className="ui radio checkbox">
                    <input
                      type="radio"
                      name="single-select-as"
                      value="Dropdown"
                      checked={singleSelectAs === "Dropdown"}
                      onChange={e => this.handleQuestionFieldChange("metadata.ui.component", e.target.value)}
                    />
                    <label>As a dropdown</label>
                  </div>
                </div>
              </div>
              {["SingleSelect", "MultiSelect"].includes(question.type) && (
                <React.Fragment>
                  <hr />
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">Options</label>
                    <OptionsEditor options={question.options} update={this.handleQuestionFieldChange} />
                  </div>
                </React.Fragment>
              )}
              <hr />
              <Checkbox
                label="Is Required?"
                name="isRequired"
                onChange={(_, data) => this.handleQuestionFieldChange("isRequired", data.checked)}
                checked={question.isRequired}
              />
              <hr />
              <Checkbox
                label="Is Diagnosis / CPT Code?"
                name="isCptCode"
                onChange={(_, data) =>
                  this.handleQuestionFieldChange("metadata.service.target", data.checked ? "CPT" : "")
                }
                checked={isDiagnosisOrCptCode}
              />
              {isDiagnosisOrCptCode && (
                <React.Fragment>
                  <br />
                  <Radio
                    label="Is CPT Code?"
                    name="isCptCode"
                    onChange={(_, data) =>
                      this.handleQuestionFieldChange("metadata.service.target", data.checked ? "CPT" : "")
                    }
                    checked={isCptCode}
                  />
                  <br />
                  <Radio
                    label="Is Diagnosis Code?"
                    name="isDiagnosisCode"
                    onChange={(_, data) =>
                      this.handleQuestionFieldChange("metadata.service.target", data.checked ? "ICD10" : "")
                    }
                    checked={isDiagnosisCode}
                  />

                  {isDiagnosisCode && (
                    <React.Fragment>
                      <br />
                      <input
                        type="number"
                        className="form-control"
                        placeholder="order"
                        value={diagnosisCodeOrder}
                        onChange={e => this.handleQuestionFieldChange("metadata.service.order", e.target.value)}
                      />
                    </React.Fragment>
                  )}
                  <br />
                  <Radio
                    label="Is Free Diagnosis Code?"
                    name="isFreeDiagnosisCode"
                    onChange={(_, data) =>
                      this.handleQuestionFieldChange("metadata.service.target", data.checked ? "ICD10FREE" : "")
                    }
                    checked={isFreeDiagnosisCode}
                  />

                  {isFreeDiagnosisCode && (
                    <React.Fragment>
                      <br />
                      <input
                        type="number"
                        className="form-control"
                        placeholder="order"
                        value={diagnosisCodeOrder}
                        onChange={e => this.handleQuestionFieldChange("metadata.service.order", e.target.value)}
                      />
                    </React.Fragment>
                  )}
                </React.Fragment>
              )}
              <hr />
              <div className="form-group row">
                <label className="col-sm-3 col-form-label">Elucidations</label>
                <ElucidationsEditor question={question} update={this.handleQuestionFieldChange} />
              </div>
              <hr />
              <div className="form-group row">
                <label className="col-sm-3 col-form-label">Triggers</label>
                <TriggersEditor question={question} questions={questions} update={this.handleQuestionFieldChange} />
              </div>
              <hr />
              <div className="form-group row">
                <label className="col-sm-3 col-form-label">Default Answer(s)</label>
                <DefaultAnswersEditor question={question} update={this.handleQuestionFieldChange} />
              </div>
              <hr />
              <div className="form-group row">
                <label className="col-sm-3 col-form-label">EventTriggers</label>
                <EventTriggersEditor question={question} update={this.handleQuestionFieldChange} />
              </div>
              <hr />
              <div className="form-group row">
                <label className="col-sm-3 col-form-label">version</label>
                <div className="col-sm-9">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="version"
                    value={question.version}
                    onChange={e => this.handleQuestionFieldChange("version", e.target.value)}
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default QuestionEditor;
