import initialState from "./initialState";

import * as types from "../actions/actionTypes";

export default function modalReducer(state = initialState.search, action) {
  switch (action.type) {
    case types.SEARCH_PATIENT_SUCCESS:
      return Object.assign({}, state, {
        patients: action.patients
      });
    case types.SEARCH_MEDS_SUCCESS:
      return Object.assign({}, state, {
        medications: action.medications
      });
    case types.SEARCH_ALLERGY_SUCCESS:
      return Object.assign({}, state, {
        allergies: action.allergies
      });
    case types.CLEAR_SEARCH:
      return Object.assign({}, state, {
        [action.searchType]: []
      });
    case types.SEARCH_PHYSICIAN_SUCCESS:
      return Object.assign({}, state, {
        physicians: action.physicians
      });
    case types.SEARCH_CONDITION_SUCCESS:
      return Object.assign({}, state, {
        conditions: action.conditions
      });
    default:
      return state;
  }
}
