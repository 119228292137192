import React, { Component } from "react";
import { Header, Table, Grid } from "semantic-ui-react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import moment from "moment";
import shortid from "shortid";
import * as insuranceActions from "../../../actions/insuranceActions";

import "./PrescriptionHistory.css";

export class PrescriptionHistory extends Component {
  componentDidMount() {
    const {
      patientId,
      actions: { loadDrugHistory }
    } = this.props;
    if (patientId) {
      loadDrugHistory(patientId);
    }
  }

  render() {
    const { drugHistory } = this.props;

    const renderPrescriptionHistory = (drugHistory || []).map(rx => (
      <Table.Row key={shortid.generate()}>
        <Table.Cell>{rx.drugInfo}</Table.Cell>
        <Table.Cell>{rx.quantity}</Table.Cell>
        <Table.Cell>{moment(rx.rxDate).format("L")}</Table.Cell>
        <Table.Cell>{rx.prescriber}</Table.Cell>
      </Table.Row>
    ));
    const remainingDosages = (drugHistory || []).filter(rx => rx.dosage && rx.dosage.length === 0).length;

    return (
      <Grid className="barH_" columns={1} doubling>
        <Grid.Row>
          <Grid.Column>
            <div className="prescriptions-history-content">
              <Header as="h4" className="prescription-history-header">
                {`${
                  renderPrescriptionHistory.length
                } prescriptions reported (${remainingDosages} appear to have doses remaining)`}
              </Header>

              <div className="prescription-history-disclaimer">
                Certain information may not be available or accurate in this including items that the patient asked not
                to be disclosed due to patient privacy concerns, over-the-counter medications, low cost prescriptions,
                prescriptions paid for by the patient or nonparticipating sources, or errors in insurance claims
                information. The provider should indenpendently verify medication history with the patient.
              </div>

              <Table>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Drug/Strength</Table.HeaderCell>
                    <Table.HeaderCell>Qty</Table.HeaderCell>
                    <Table.HeaderCell>Rx Date</Table.HeaderCell>
                    <Table.HeaderCell>Prescriber</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>{renderPrescriptionHistory}</Table.Body>
              </Table>
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  return {
    drugHistory: state.insurance.drugHistory || []
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...insuranceActions }, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PrescriptionHistory);
