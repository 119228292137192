import React from "react";
import { Segment } from "semantic-ui-react";
import { Button, Icon } from "../../../ui";
import { getLookupText, isEmpty } from "../../../helpers";

import "./ViewPatientDemographics.css";

export default function SecondaryDemographicsCard(props) {
  const {
    handleEditMainCenter,
    handleEditMainPrescriber,
    mainPrescriber,
    handlePrescriberHistory,
    homeCenterId,
    centers,
    showDemoEdit,
    contextVisit,
    status
  } = props;
  return (
    <Segment.Group className="secondary-dem-card" piled>
      <Segment textAlign="right">
        {showDemoEdit && (isEmpty(contextVisit) || contextVisit.status === "visitcomplete") && (
          <Button variant="outline-primary" size="sm" onClick={handleEditMainCenter} title="Update Main Center">
            <Icon name="pencil-square" />
          </Button>
        )}
      </Segment>
      <Segment>
        <h6>Main Center</h6>
        {getLookupText(homeCenterId, centers)}
      </Segment>
      <Segment textAlign="right">
        {showDemoEdit && status !== "Discharged" && status !== "Inactive" && (
          <Button
            variant="outline-primary"
            size="sm"
            onClick={handleEditMainPrescriber}
            title="Update Main Prescriber"
            className="me-3"
          >
            <Icon name="pencil-square" />
          </Button>
        )}
        {mainPrescriber && (
          <Button variant="outline-primary" size="sm" onClick={handlePrescriberHistory} title="Prescriber History">
            <Icon name="clock-history" />
          </Button>
        )}
      </Segment>
      <Segment>
        <h6>Main Prescriber</h6>
        {status !== "Discharged" &&
          status !== "Inactive" &&
          mainPrescriber &&
          !mainPrescriber.isDelete &&
          `${mainPrescriber.firstName} ${mainPrescriber.lastName}`}
      </Segment>
    </Segment.Group>
  );
}
