import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Grid, Dropdown, Message, Table, Icon, Loader, Breadcrumb } from "semantic-ui-react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import set from "lodash/set";
import isEmpty from "lodash/isEmpty";
import moment from "moment";
import { CSVLink } from "react-csv";
import * as modalActions from "../../actions/modalActions";
import { loadLookup } from "../../actions/lookupActions";
import { loadMainPrescribers } from "../../actions/userActions";
import {
  fetchProviderSlotsData,
  fetchCustomReportById,
  fetchProviderPatientsWithActiveRx,
  fetchTotalPatientsInPanel
} from "../../api/report";
import ControlledSubstancesStatsRow from "./ControlledSubstancesStatsRow";
import { dateWithTimeFormat } from "../../constants/miscellaneous";
import ButtonLink from "../../components/reusable/ButtonLink";

export class ActiveControlledSubstancePatients extends Component {
  state = {
    query: {
      prescriberId: null
    },
    isLoading: false,
    result: {
      slotsHeader: {},
      patientRxData: [],
      currentView: ""
    },
    reportName: null,
    validationMessage: null
  };

  componentDidMount() {
    const {
      actions: { loadMainPrescribers: fetchProviders },
      match: {
        params: { reportId }
      }
    } = this.props;

    fetchProviders(["EMR-Provider", "EMR-SupervisingProvider"]);
    fetchCustomReportById(reportId).then(response => {
      this.setState({ reportName: response.name });
    });
  }

  fetchData = providerId => {
    fetchProviderSlotsData(providerId).then(slotsHeader => {
      const newState = { ...this.state };
      set(newState, "result.slotsHeader", slotsHeader);
      set(newState, "result.patientRxData", []);
      set(newState, "result.currentView", "");
      this.setState(prevState => ({
        ...newState,
        validationMessage: slotsHeader ? null : "No data found for provider selected.",
        isLoading: !prevState.isLoading
      }));
    });
  };

  handleFieldChange = (_, data) => {
    const { name: field, value } = data;
    const newState = { ...this.state };
    set(newState, field, value);
    this.setState(prevState => ({ ...newState, isLoading: !prevState.isLoading }));
    this.fetchData(value);
  };

  handlePatientsWithActiveRxClick = () => {
    const {
      query: { prescriberId }
    } = this.state;
    fetchProviderPatientsWithActiveRx(prescriberId).then(result =>
      this.setState({
        ...this.state,
        result: { ...this.state.result, patientRxData: result, currentView: "Total Patients with Active Rx" }
      })
    );
  };

  handleTotalPatientsInPanelClick = () => {
    const {
      query: { prescriberId }
    } = this.state;
    fetchTotalPatientsInPanel(prescriberId).then(result =>
      this.setState({
        ...this.state,
        result: { ...this.state.result, patientRxData: result, currentView: "Total Patients in Panel" }
      })
    );
  };

  render() {
    const {
      query: { prescriberId },
      result: { slotsHeader, patientRxData, currentView },
      reportName,
      isLoading,
      validationMessage
    } = this.state;
    const { prescribers } = this.props;

    return (
      <div className="report-container">
        <Breadcrumb>
          <Breadcrumb.Section active={false}>
            <Link to={"/"}>
              <Icon name="home" />
            </Link>
          </Breadcrumb.Section>
          <Breadcrumb.Divider />
          <Breadcrumb.Section active={false}>
            <Link to={"/report"}>{`Reports`}</Link>
          </Breadcrumb.Section>
        </Breadcrumb>
        <div className="header-wrapper">
          <h2>{reportName}</h2>
        </div>
        <Grid columns={1} centered padded stackable>
          <Grid.Row>
            <Grid.Column width={15}>
              <Dropdown
                options={prescribers}
                name="query.prescriberId"
                value={prescriberId}
                onChange={this.handleFieldChange}
                placeholder="Select..."
                label="Prescriber"
                loading={isLoading}
                selection
                required
                search
              />
            </Grid.Column>
          </Grid.Row>
          {validationMessage && (
            <Grid.Row>
              <Grid.Column width={15}>
                <Message negative>{validationMessage}</Message>
              </Grid.Column>
            </Grid.Row>
          )}
          {!isEmpty(slotsHeader) && (
            <Grid.Row>
              <Grid.Column width={15}>
                <ControlledSubstancesStatsRow
                  {...slotsHeader}
                  handlePatientsWithActiveRxClick={this.handlePatientsWithActiveRxClick}
                  handleTotalPatientsInPanelClick={this.handleTotalPatientsInPanelClick}
                />
              </Grid.Column>
            </Grid.Row>
          )}
          {isLoading && (
            <Grid.Row>
              <Grid.Column width={15}>
                <Loader>Loading</Loader>
              </Grid.Column>
            </Grid.Row>
          )}
          {patientRxData.length > 0 && (
            <Grid.Row>
              <Grid.Column width={15}>
                <Table striped>
                  <Table.Header>
                    <Table.Row textAlign="center">
                      <Table.HeaderCell colSpan={7}>
                        <CSVLink filename={`${currentView}.csv`} data={patientRxData} className="ui blue button">
                          Export
                        </CSVLink>
                        <br />
                        <br />
                        {currentView}
                      </Table.HeaderCell>
                    </Table.Row>
                    <Table.Row>
                      <Table.HeaderCell>MRN</Table.HeaderCell>
                      <Table.HeaderCell>Patient Name</Table.HeaderCell>
                      <Table.HeaderCell>DOB</Table.HeaderCell>
                      <Table.HeaderCell>Main Center</Table.HeaderCell>
                      <Table.HeaderCell>Insurance</Table.HeaderCell>
                      <Table.HeaderCell>Rx Exp Date</Table.HeaderCell>
                      <Table.HeaderCell>Cross coverage</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {patientRxData.map(
                      ({
                        mrn,
                        patientFirstName,
                        patientLastName,
                        patientDateOfBirth,
                        mainClinic,
                        insurance,
                        rxExpirationDate,
                        patientId,
                        isCrossCoverage
                      }) => (
                        <Table.Row key={patientId}>
                          <Table.Cell>{mrn}</Table.Cell>
                          <Table.Cell>
                            <ButtonLink to={`/current-patients/${patientId}/demographics`} target="_blank">
                              {patientFirstName} {patientLastName}
                            </ButtonLink>
                          </Table.Cell>
                          <Table.Cell>{patientDateOfBirth}</Table.Cell>
                          <Table.Cell>{mainClinic}</Table.Cell>
                          <Table.Cell>{insurance || "N/A"}</Table.Cell>
                          <Table.Cell>
                            {rxExpirationDate ? moment(rxExpirationDate).format(dateWithTimeFormat) : "N/A"}
                          </Table.Cell>
                          <Table.Cell>
                            {isCrossCoverage && <Icon color="purple" name="checkmark" size="large" />}
                          </Table.Cell>
                        </Table.Row>
                      )
                    )}
                  </Table.Body>
                </Table>
              </Grid.Column>
            </Grid.Row>
          )}
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  prescribers: state.user.mainPrescribers.map(prescriber => ({
    value: prescriber.userId,
    text: `${prescriber.firstName} ${prescriber.lastName}`
  }))
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      ...modalActions,
      loadMainPrescribers,
      loadLookup
    },
    dispatch
  )
});

export default connect(mapStateToProps, mapDispatchToProps)(ActiveControlledSubstancePatients);
