import { isEmpty, isObject } from "lodash";
import { WEB_SOCKET } from "../constants/environment";
import { getToken } from "../adalConfig";
import { processApiError, logError, resubscribeAll } from "../helpers";
import { showPopupMessage } from "../components/common/PopupMessage";
import { getNotifications, dismissNotificationDone, dismissAllNotificationsDone } from "../actions/notificationActions";
import { loadNotificationsBadgeDone } from "../actions/badgeActions";
import { setStreamId } from "../actions/streamingActions";
import miscMessagesHandler from "./handlers/miscMessagesHandler";
import labMessagesHandler from "./handlers/labMessagesHandler";
import visitsMessagesHandler from "./handlers/visitsMessagesHandler";
import userMessagesHandler from "./handlers/demographicsMessagesHandler";
import errorMessagesHandler from "./handlers/errorMessagesHandler";
import badgeMessagesHandler from "./handlers/badgeMessagesHandler";
import centerMessagesHandler from "./handlers/centerMessagesHandler";
import identityProofingHandler from "./handlers/identityProofingHandler";
import prescriptionMessagesHandler from "./handlers/prescriptionMessagesHandler";

let source;
let timeout = 250;
let connectInterval;

const handleMessage = (event) => {
  try {
    const message = JSON.parse(event.data);
    if (message && message.StreamId) {
      const oldStreamId = window.store.getState().streaming.streamId;
      window.store.dispatch(setStreamId(message.StreamId));
      if (oldStreamId && oldStreamId !== message.StreamId) resubscribeAll();
    }
    const { Content: payload, Type } = message;
    switch (Type) {
      case "@Notification.Summary":
        /*
        {"Type":"@Notification.Summary","Title":null,"Content":{"notificationType":"inbox", "unreadedCount":"0"},"Options":null,"Time":"2020-05-19T10:57:38.6398638-04:00"}
        */
        window.store.dispatch(loadNotificationsBadgeDone(payload.unreadedCount ? payload.unreadedCount : 0));
        if (!payload.unreadedCount || payload.unreadedCount === 0) window.store.dispatch(dismissAllNotificationsDone());
        if (payload.unreadedCount && payload.unreadedCount > 0) window.store.dispatch(getNotifications());
        return null;
      case "@Notification.NotificationDismissed":
        /*
        {"Type":"@Notification.NotificationDismissed","Title":null,"Content":{"notificationId":19},"Options":null,"Time":"2020-05-19T17:15:20.4920021+02:00"}
        */
        window.store.dispatch(dismissNotificationDone(payload.notificationId));
        return null;
      case "@Notification.Lab.OrderCreated":
        /*
        {"Type":"@Notification.Lab.OrderCreated","Title":"Pending Lab Order Updated","Content":"Pending lab Order has been created for patient Ooo Ppp.","Options":{"isUrgent":true,"patientId":"be0b33d7-3095-42c1-88d7-3e628c974f8b","labOrderId":"2b12bbf9-be8f-4cab-a9e5-f0f47b39e6a4"},"Time":"2020-05-27T17:53:12.0690758+02:00"}
        */
        return labMessagesHandler.handleOrderCreated(message);
      case "@Notification.Lab.OrderUpdated":
        /*
        {"Type":"@Notification.Lab.OrderUpdated","Title":"Pending Lab Order Updated","Content":"Pending lab Order has been updated for patient Aaa Zzz.","Options":{"isUrgent":true,"patientId":"4925133c-c222-4312-9384-76b22e82138b","labOrderId":"9c8a3e6b-47da-4e19-b989-d8b27c0f4d47"},"Time":"2020-05-26T23:41:59.9281511+02:00"}
        */
        return labMessagesHandler.handleOrderUpdated(message);

      case "@Notification.User.PatientPrescriberUnassigned":
        /*
        {"Type":"@Notification.User.PatientPrescriberUnassigned","Title":"Patient 41b8d85d-3482-485c-8b25-87a37adba259 assigned to prescriber e373e2e6-b38a-4500-9345-ad7411d2418c","Content":{"providerId":"e373e2e6-b38a-4500-9345-ad7411d2418c","patientId":"41b8d85d-3482-485c-8b25-87a37adba259","orchestrationId":"f76c15d2-a1b5-4d4a-b313-c3a7274ce644","platformEventId":"a7299d00-d6ff-4007-ae06-3f82c901ed73","revision":730},"Options":null,"Time":"2020-06-05T18:48:19.6629635+02:00"}
        */
        return userMessagesHandler.handlePatientPrescriberUnassigned(message);
      case "@Notification.User.PatientPrescriberAssigned":
        /*
        {"Type":"@Notification.User.PatientPrescriberAssigned","Title":"Patient 41b8d85d-3482-485c-8b25-87a37adba259 assigned to prescriber 177624d5-8cab-e911-bcd0-0003ffca95c7","Content":{"providerId":"177624d5-8cab-e911-bcd0-0003ffca95c7","patientId":"41b8d85d-3482-485c-8b25-87a37adba259","orchestrationId":"f76c15d2-a1b5-4d4a-b313-c3a7274ce644","platformEventId":"9c24e302-ff42-4297-8882-29ffd36a080f","revision":7109},"Options":null,"Time":"2020-06-05T18:48:19.8939945+02:00"}
        */
        return userMessagesHandler.handlePatientPrescriberAssigned(message);
      case "@Notification.User.PatientInterventionFlagsUpdated":
        /*
        {"Type":"@Notification.User.PatientInterventionFlagsUpdated","Title":null,"Content":{"patientId":"4925133c-c222-4312-9384-76b22e82138b","reload":true},"Options":null,"Time":"2021-10-19T21:21:58.9635627+02:00"}
        */
        return userMessagesHandler.handlePatientInterventionFlagsUpdated(message);
      case "@Notification.Lab.SpecimenDateCenterSaved":
        /*  
        {"Type":"@Notification.Lab.SpecimenDateCenterSaved","Title":"Specimen Collection Date and Center saved","Content":{"labOrderId":"8fe939bb-a826-4c16-8987-9b3d7d6776b6","centerId":"8127c40c-06e1-48a9-a55e-ec4051863f6d","specimenCollectionDate":"2020-06-11T16:49:17.389+02:00"},"Options":null,"Time":"2020-06-11T16:51:38.8569738+02:00"}
        */
        return labMessagesHandler.handleSpecimenDateCenterSaved(message);
      case "@Notification.Labs":
        /*
        {"Type":"@Notification.Labs","Title":null,"Content":{"reload":true,"centerId":"8127c40c-06e1-48a9-a55e-ec4051863f6d","patientCount":5,"subType":"RequestedLabs"},"Options":null,"Time":"2020-10-26T18:29:25.5143304+02:00"}
        {"Type":"@Notification.Labs","Title":null,"Content":{"reload":true,"patientId":"059c4f22-adb3-42c6-9cbb-e7c01fe83aee","subType":"PatientRequestedLabs"},"Options":null,"Time":"2020-10-26T18:31:00.1157677+02:00"}
        */
        return labMessagesHandler.handleLabNotifications(message);
      case "@Notification.Error.General":
        /*
        {"Type":"@Notification.Error.General","Title":null,"Content":{"serviceName":"Labs","sourceHandler":"UpdatePendingOrderCommandHandler","errorType":"CommandValidationException","errorSource":"CleanSlate.EMR.Services.Labs","errorMessage":"The system was unable to process an message of IUpdatePendingOrderCommand using the handler CleanSlate.EMR.Services.Labs.Impl.Messaging.CommandHandlers.UpdatePendingOrderCommandHandler, CleanSlate.EMR.Services.Labs, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null.The error message returned was: Validation errors were encountered while trying to process a platform command.  The errors returned were Duplication while ordering confirmatory labs..  No additional events were published as a result.","errorUserMessage":"Validation errors were encountered while trying to process a platform command.  The errors returned were Duplication while ordering confirmatory labs.","orchestrationId":"1ea420d2-572e-457c-927f-14d2b2e271c8","identityContext":"177624d5-8cab-e911-bcd0-0003ffca95c7","platformEventId":"4bff4aa3-3dec-4d42-8600-819fbcc79023","timeStamp":"2020-05-29T21:56:56.3792236+02:00"},"Options":null,"Time":"2020-05-29T21:56:56.4494544+02:00"}        
        */
        return errorMessagesHandler.handleGeneralError(message);
      case "@Notification.Error.BusinessViolation":
        /*
        {"Type":"@Notification.Error.BusinessViolation","Title":null,"Content":{"serviceName":"Labs","sourceHandler":"UpdatePendingOrderCommandHandler","errorType":"BusinessRulesViolationException","errorSource":"CleanSlate.EMR.Services.Labs","errorMessage":"The system was unable to process an message of IUpdatePendingOrderCommand using the handler CleanSlate.EMR.Services.Labs.Impl.Messaging.CommandHandlers.UpdatePendingOrderCommandHandler, CleanSlate.EMR.Services.Labs, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null.The error message returned was: The requested operation  would have resulted in a violation of known business rules.  The violation reported was: Duplication while ordering confirmatory labs.  No additional events were published as a result.","errorUserMessage":"The requested operation  would have resulted in a violation of known business rules.  The violation reported was: Duplication while ordering confirmatory labs","orchestrationId":"355fc269-1e93-4758-bd8b-07d60badec74","identityContext":"177624d5-8cab-e911-bcd0-0003ffca95c7","platformEventId":"7ed33975-759d-4fcb-bea8-fa3a1295679f","timeStamp":"2020-05-29T22:35:04.3111449+02:00"},"Options":null,"Time":"2020-05-29T22:35:04.5496829+02:00"}
        */
        return errorMessagesHandler.handleBusinessViolationError(message);
      case "inbox":
        if (isEmpty(message.Options) || (!isEmpty(message.Options) && message.Options.isUrgent)) {
          showPopupMessage(payload);
        }
        return null;
      case "@Notification.User.ChartsToSignCountChanged":
        return badgeMessagesHandler.handleChartsToSignCountChanged(message);
      case "@Notification.ActiveVisits":
        /* {"Type":"@Notification.ActiveVisits","Title":"Visit Created","Content":{"patientId": "41b8d85d-3482-485c-8b25-87a37adba259" "reload":true},"Options":null,"Time":"2020-09-11T19:52:28.4699384+02:00"} */
        return visitsMessagesHandler.handleActiveVisits(message);
      case "@Notification.CenterVisits":
        /* {"Type":"@Notification.CenterVisits","Title":null,"Content":{"centerId":"8127c40c-06e1-48a9-a55e-ec4051863f6d","patientId":"4925133c-c222-4312-9384-76b22e82138b","visitId":"2e78a1db-e303-407a-8ac0-37990158b52c","visitStatus":"newvisit"},"Options":null,"Time":"2022-07-14T23:10:05.1423372+02:00"} */
        return visitsMessagesHandler.handleCenterVisits(message);
      case "@Notification.ProviderVisits":
        return visitsMessagesHandler.handleProviderVisits(message);
      case "@Notification.ProviderAppointments":
        return visitsMessagesHandler.handleProviderAppointments(message);
      case "@Notification.Center.NumberOfPendingTasks":
        /* {"Type":"@Notification.Center.NumberOfPendingTasks","Title":"Number of pending tasks is updated","Content":{"reload":true},"Options":null,"Time":"2020-10-28T19:54:35.9093169+02:00"} */
        return centerMessagesHandler.handleNumberOfPendingTasks(message);
      case "@Notification.IdentityProofingStatusUpdated":
        /* {"Type":"@Notification.IdentityProofingStatusUpdated","Title":"wtg_redirect","Content":{"proofType":"l3xo_pb","status":"wtg_redirect","uiLink":"https://sotp.exostartest.com/xosotp/pages/proofing/proofing.jsf?ankh=1RIbeQcfHCq28Ti0dp6ZFkVrlMEBqhT6tQfloEFyPsFFr8qJvTAbm1KViW6QOxOtOmqKktWrpnyb90o2DaiShw","start":"2021-06-16T23:38:26.622+00:00"},"Options":null,"Time":"2021-06-16T23:38:26.2214297+02:00"} */
        return identityProofingHandler.handleStatusUpdated(message);
      case "@Notification.LaunchSelfAdminSession":
        return identityProofingHandler.handleLaunchSelfAdminSession(message);
      case "@Notification.PrescriptionStatus":
        /* {"Type":"@Notification.PrescriptionStatus","Title":null,"Content":{"reload":true,"patientId":"4925133c-c222-4312-9384-76b22e82138b"},"Options":null,"Time":"2021-07-13T23:55:00.6056099+02:00"} */
        return prescriptionMessagesHandler.handlePrescriptionStatusMessage(message);
      case "@Notification.PrescriptionError":
        /* {"Type":"@Notification.PrescriptionError","Title":null,"Content":{ ... } */
        return prescriptionMessagesHandler.handlePrescriptionErrorMessage(message);
      case "@Notification.PatientScores":
        /* {"Type":"@Notification.PatientScores","Title":"Scores","Content":{"scores":[{"key":"Narcotics","value":"000"},{"key":"Stimulants","value":"000"},{"key":"Sedatives","value":"000"},{"key":"Overdose","value":"000"}],"requestedOn":"2021-08-10T22:04:59.0284212+02:00"},"Options":null,"Time":"2021-08-10T22:04:59.3710476+02:00"} */
        return miscMessagesHandler.handlePatientScoresMessage(message);
      default:
        return null;
    }
  } catch (error) {
    processApiError(error.toString(), window.store.dispatch);
  }
  return null;
};

const handleError = (error) => {
  /* eslint-disable no-console */
  console.log("Opps! We have a problem", error);
  /* eslint-enable no-console */
  const formattedError = isObject(error) ? JSON.stringify(error) : error.toString();
  processApiError(formattedError, window.store.dispatch);
  logError(formattedError).catch((e) => processApiError(e, window.store.dispatch));
  // source.close();
};

const handleOpen = () => {
  /* eslint-disable no-console */
  console.log("Web Socket is opened!");
  /* eslint-enable no-console */
  timeout = 250;
  clearTimeout(connectInterval);
};

const connectSocket = () => {
  source = new WebSocket(`${WEB_SOCKET}/Streaming/OpenEventStream`, [getToken()]);
  source.onmessage = handleMessage;
  source.onerror = handleError;
  source.onopen = handleOpen;
  source.onclose = () => {
    timeout += timeout;
    timeout = Math.min(10000, timeout);
    const retryReachingServerIn = window.store.getState().userPreference.retryReachingServerIn;
    if (retryReachingServerIn !== -1) timeout = retryReachingServerIn * 1000;
    /* eslint-disable no-console */
    console.log("Web socket is closing! I will try to reconnect in: ", timeout);
    /* eslint-enable no-console */
    connectInterval = setTimeout(() => {
      if (!source || source.readyState === WebSocket.CLOSED) {
        connectSocket();
      }
    }, timeout);
  };
};

const closeSocket = () => {
  if (source) {
    source.close();
  }
};

export default {
  connectSocket,
  closeSocket
};
