import React from "react";
import { Pagination } from "semantic-ui-react";

const PaginationRow = ({ requestedPage, totalRecords, resultsPerPage, onPageChange }) => (
  <Pagination
    activePage={requestedPage}
    totalPages={totalRecords ? Math.ceil(totalRecords / resultsPerPage) : 0}
    onPageChange={onPageChange}
  />
);

export default PaginationRow;
