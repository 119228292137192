/* eslint-disable camelcase */
import React, { Component } from "react";
import { Menu, Image, Button, Dropdown, Transition, Icon } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Button as BsButton, Icon as BsIcon } from "../../ui";
import * as modalActions from "../../actions/modalActions";
import * as userPreferenceActions from "../../actions/userPreferenceActions";
import * as sidebarErrorLogActions from "../../actions/sidebarErrorLogActions";
import { roleGroups } from "../../constants/securityRoles";
import { checkRoles } from "../../helpers";
import logo from "../../assets/cleanslate-logo-color.svg";
import error_icon from "../../assets/common/error_icon.svg";
import { logout } from "../../adalConfig";
import EpcsSetup from "../admin/EpcsSetup";
import "./TopNav.css";

export class TopNav extends Component {
  state = {
    showEpcs: false,
    fontSize: "15px" // this should be a step forward in the migration from semantic to bootstrap; semantic's default is 15px; bootstrap's default is "unset"
  };

  handleDropdown = (e, data) => {
    const { value } = data;
    this.props.actions.updateCenterContext(value);
  };

  handleClick = () => {
    this.props.actions.showModal({
      type: "NEW_PATIENT",
      props: { open: true }
    });
  };

  openSlider = () => {
    this.props.actions.openSliderPanel();
  };

  render() {
    const { loggedInUser, authRoles, isMobile, handleNavToggle, centers, centerContext, errors, loggedInUserId } =
      this.props;
    const { showEpcs } = this.state;
    const canCreateNewPatient = checkRoles(roleGroups.createNewPatient, authRoles);
    const canSeeEpcs = checkRoles(roleGroups.prescriberEpcsSetup, authRoles);

    return (
      <React.Fragment>
        <Menu attached="top" className="top-nav" borderless>
          {isMobile ? (
            <Menu.Item className="top-nav-item top-nav-button">
              <Button icon="bars" onClick={handleNavToggle} />
            </Menu.Item>
          ) : null}
          <Menu.Item className="logo">
            <Image as={Link} to="/" name="home" src={logo} alt="logo" size="tiny" className="clean-slate-logo" />
          </Menu.Item>
          <Menu.Item name="patient-center" className="top-nav-item clinic-dropdown-width">
            <Dropdown
              id="topbar-centers-ddl"
              label="Clinic:"
              name="type"
              placeholder="Select a Center..."
              options={centers.map((center) => ({ id: `topbar-centers-ddl-item-${center.value}`, ...center }))}
              onChange={this.handleDropdown}
              value={centerContext}
              fluid
              search
              selection
            />
          </Menu.Item>

          <Menu.Item
            className="top-nav-item top-nav-button"
            style={{ minWidth: "180px", textAlign: "center", backgroundColor: showEpcs ? "rgba(0,0,0,.1) " : "white" }}
          >
            <Button
              style={{ margin: "auto" }}
              onClick={() => {
                this.setState({ showEpcs: !this.state.showEpcs });
              }}
            >
              <span style={{ display: "inline-block", marginRight: ".5em" }}>EPCS</span>
              <Icon name={`caret ${showEpcs ? `up` : `down`}`} />
            </Button>
          </Menu.Item>

          <Menu.Item name="new-patient" className="top-nav-item top-nav-button">
            {canCreateNewPatient && (
              <Button onClick={this.handleClick} icon="add user" content={!isMobile && "New Patient"} />
            )}
          </Menu.Item>
          {!isMobile && (
            <Menu.Item name="logout" className="top-nav-item">
              <label className="lbl-logged-in-user">
                Logged in as: <strong>{loggedInUser.userName}</strong>
              </label>
            </Menu.Item>
          )}
          <Menu.Item name="logout" className="top-nav-item top-nav-button px-0" style={{ display: "block" }}>
            <Button id="topbar-logout-button" onClick={() => logout()} icon="log out" content={!isMobile && "Logout"} />

            <br />
            <br />
            <a
              target="_blank"
              href="https://cleanslate.myportallogin.com/"
              style={{ color: "green", fontWeight: "bold" }}
            >
              <Icon name="question circle outline" style={{ color: "green", fontWeight: "bold" }} /> Support
            </a>
          </Menu.Item>
          <Menu.Item name="logout" className="top-nav-item top-nav-button" style={{ display: "block" }}>
            <BsButton
              variant="outline-dark"
              size="sm"
              onClick={() => {
                this.setState(
                  (prevState) => ({ fontSize: prevState.fontSize === "15px" ? "unset" : "15px" }),
                  () => {
                    document.querySelector("html").style.fontSize = this.state.fontSize;
                  }
                );
              }}
              title={`Zoom ${this.state.fontSize === "15px" ? `in` : `out`}`}
              className="rounded-circle"
            >
              <BsIcon name={`zoom-${this.state.fontSize === "15px" ? `in` : `out`}`} />
            </BsButton>
          </Menu.Item>
          {errors.length !== 0 && (
            <Menu.Item name="error_log" className="top-nav-item top-nav-button">
              <div
                role="button"
                tabIndex={0}
                className="error-log-top-nav"
                onClick={this.openSlider}
                onKeyDown={() => {}}
              >
                <label className="error-log-top-nav-total">{errors.length}</label>
                <Image className="error-log-top-nav-button" src={error_icon} alt="Error icon" size="mini" />
              </div>
            </Menu.Item>
          )}
        </Menu>
        <Transition.Group animation="slide down" duration={100}>
          {showEpcs && loggedInUserId && (
            <div style={{ padding: ".5em 1em .5em 5em", backgroundColor: "rgba(0,0,0,.1)" }}>
              <EpcsSetup
                centerId={centerContext}
                userId={loggedInUserId}
                canSeeEpcs={canSeeEpcs}
                collapse={() => {
                  this.setState({ showEpcs: false });
                }}
              />
            </div>
          )}
        </Transition.Group>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    loggedInUser: state.auth.user || {},
    loggedInUserId: (state.userPreference || {}).currentUserId,
    authRoles: state.auth.user.profile.roles,
    centers: state.lookups.centers || [],
    centerContext: state.userPreference.centerContext,
    errors: state.sidebarErrorLog.errors
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...modalActions,
        ...userPreferenceActions,
        ...sidebarErrorLogActions
      },
      dispatch
    )
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TopNav);
