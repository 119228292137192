import React, { Component } from "react";
import { Modal, Button, Grid, List } from "semantic-ui-react";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as patientActions from "../../../actions/patientActions";
import * as modalActions from "../../../actions/modalActions";
import * as searchActions from "../../../actions/searchActions";

import NewPatientForm from "../../patients/new/NewPatientForm";
import NewPatientSearchResults from "../../patients/new/NewPatientSearchResults";
import { updateErrors, requiredFieldsFilled } from "../../../helpers";

import * as validation from "../../../constants/validation";

import providers from "../../../api/mockProviderApi";

const inputValidation = {
  required: ["patientLastName", "patientFirstName", "patientDateOfBirth", "homeCenterId"],
  patientDateOfBirth: validation.dateRegEx
};

export class NewPatientModal extends Component {
  state = {
    patient: {
      patientFirstName: "",
      patientLastName: "",
      visit_radio: "with",
      patientDateOfBirth: "",
      homeCenterId: this.props.homeCenterId || ""
    },
    searchResults: [],
    errors: {}
  };

  handleFocus = (e) => {
    e.target.setAttribute("autocomplete", "nope");
  };

  handleSave = () => {
    this.props.actions.createPatient(this.state.patient).then(() =>
      this.props.actions.showModal({
        type: "CORE_DEMOGRAPHICS",
        props: {
          open: true,
          newPatient: true
        }
      })
    );
  };

  handleCancel = () => this.props.actions.hideModal();

  handleModalNav = () => {
    this.props.actions.createPatient(this.state.patient).then((response) => {
      const { centerContext } = this.props;
      const patientCenter = response.patient.homeCenterId;
      if (centerContext && centerContext !== patientCenter) {
        return this.props.actions.showModal({
          type: "VISIT_CENTER_CONFLICT",
          props: {
            open: true,
            centerContext,
            patientCenter,
            newPatient: true
          }
        });
      }
      return this.props.actions.showModal({
        type: "NEW_VISIT",
        props: {
          open: true,
          newPatient: true,
          centerId: patientCenter
        }
      });
    });
  };

  handleInput = (e, data) => {
    const { name: field, value, required } = data;
    const errors = updateErrors(field, value, required, this.state.errors, inputValidation[field]);
    const patient = Object.assign({}, this.state.patient, { [field]: value });
    this.setState({ patient, errors });
  };

  handleInputWithSearch = (e, data) => {
    const { name: field, value, required } = data;
    const errors = updateErrors(field, value, required, this.state.errors, inputValidation[field]);
    const patient = Object.assign({}, this.state.patient, { [field]: value });
    this.setState({ patient, errors }, () => {
      // gives state time to update before send off search
      setTimeout(() => {
        this.handleSearch();
      }, 500);
    });
  };

  handleMaskedInputWithSearch = (e, data) => {
    const { name: field, value, required } = data;
    let errors = Object.assign({}, this.state.errors);
    const patient = Object.assign({}, this.state.patient, {
      [field]: value
    });

    errors = updateErrors(field, value, required, errors, inputValidation[field]);

    this.setState({ patient, errors }, () => {
      // gives state time to update before send off search
      setTimeout(() => {
        // don't search unless dob is passing validation
        if (!Object.prototype.hasOwnProperty.call(errors, "patientDateOfBirth")) {
          this.handleSearch();
        }
      }, 500);
    });
  };

  handleSearch = () => {
    const { patient, errors } = this.state;
    const formInvalid = requiredFieldsFilled(inputValidation.required, patient);

    if (!formInvalid && Object.keys(errors).length === 0) {
      this.props.actions.searchPatientBasic({ patient, pageSize: 5, pageNumber: 1 }).then((results) => {
        this.setState({
          searchResults: results.patients
        });
      });
    } else {
      this.setState({ searchResults: [] });
    }
  };

  render() {
    const { patient, searchResults, errors } = this.state;
    const { open, saving, centers, homeCenterId } = this.props;
    const formInvalid = requiredFieldsFilled(inputValidation.required, patient);
    const disableSave = formInvalid || Object.keys(errors).length !== 0 || searchResults.length > 0;

    return (
      <Modal open={open}>
        <Modal.Header>New Patient</Modal.Header>
        <Modal.Content>
          <Grid divided="vertically">
            <Grid.Row columns={2}>
              <Grid.Column>
                <NewPatientForm
                  patient={patient}
                  handleInput={this.handleInput}
                  handleInputWithSearch={this.handleInputWithSearch}
                  handleMaskedInputWithSearch={this.handleMaskedInputWithSearch}
                  centers={centers}
                  providers={providers}
                  homeCenterId={homeCenterId}
                  handleFocus={this.handleFocus}
                  errors={errors}
                />
              </Grid.Column>
              <Grid.Column>
                {searchResults.length > 0 ? (
                  <List divided selection verticalAlign="middle">
                    {searchResults.map((result) => (
                      <NewPatientSearchResults
                        key={result.patientId}
                        patientId={result.patientId}
                        headerTextLeft={result.patientFirstName}
                        headerTextRight={result.patientLastName}
                        descripTextLeft={result.city}
                        descripTextRight={result.state}
                        noDescripText="No address information."
                        separator=", "
                        onSearchResultClick={this.handleCancel}
                      />
                    ))}
                  </List>
                ) : !formInvalid && searchResults.length === 0 && Object.keys(errors).length === 0 ? (
                  `No Existing Record`
                ) : null}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.handleCancel} content="Cancel" />
          {patient.visit_radio === "with" ? (
            <Button
              color="blue"
              onClick={this.handleModalNav}
              content="Next"
              id="btn-next"
              disabled={saving || disableSave}
              loading={saving}
            />
          ) : (
            <Button
              color="blue"
              id="btn-save"
              onClick={this.handleSave}
              content="Save"
              disabled={disableSave}
              loading={saving}
            />
          )}
        </Modal.Actions>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    homeCenterId: state.userPreference.centerContext,
    patients: state.search.patients,
    centers: state.lookups.centers,
    saving: state.ajaxCallsInProgress > 0,
    centerContext: state.userPreference.centerContext
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...patientActions, ...modalActions, ...searchActions }, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(NewPatientModal);
