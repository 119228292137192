import React from "react";
import { Grid, List } from "semantic-ui-react";
import { Link } from "react-router-dom";

const ReportDashboardRow = ({ reportId, name, description, path }) => (
  <Grid.Column>
    <List>
      <List.Item as={Link} to={path ? `${path}/${reportId}` : `/report-details/${reportId}`}>
        <List.Content>
          <List.Header as="span" className="list-link-header">
            {name}
          </List.Header>
          <List.Description>{description}</List.Description>
        </List.Content>
      </List.Item>
    </List>
  </Grid.Column>
);

export default ReportDashboardRow;
