import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import emrComponent from "../../common/emrComponent";
import { extendedApiSlice, labResultsVisitsSelectors } from "./labsSlice";
import { Accordion, Message } from "../../../ui";
import { dateWithTimeFormat } from "../../../constants/miscellaneous";
import VisitResultsReview from "./VisitResultsReview";
import VisitResultHistoryReview from "./VisitResultHistoryReview";
import { isVisitTypeMedical } from "../../../helpers";

export class LabResultsByDateOfService extends Component {
  state = { activeAccordionItem: null };
  unsubscribes = [];

  componentDidMount() {
    const { dispatch, patientId, labResultsVisits } = this.props;
    const { unsubscribe } = dispatch(extendedApiSlice.endpoints.getLabResultsVisits.initiate({ patientId }));
    this.unsubscribes = [unsubscribe];
    if (
      this.props.activeVisits &&
      this.props.activeVisits.length > 0 &&
      labResultsVisits &&
      labResultsVisits.length > 0
    ) {
      const activeVisits = labResultsVisits
        .filter((v) => this.isVisitActive(v.visitId))
        .sort((a, b) => moment(b.visitTime) - moment(a.visitTime));
      if (activeVisits && activeVisits.length > 0) {
        this.setState({ activeAccordionItem: activeVisits[0].visitId });
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.activeVisits &&
      this.props.activeVisits.length > 0 &&
      this.props.labResultsVisits &&
      this.props.labResultsVisits.length > 0 &&
      (this.props.labResultsVisits.length !== prevProps.labResultsVisits.length ||
        this.props.activeVisits.length !== prevProps.activeVisits.length)
    ) {
      const activeVisits = this.props.labResultsVisits
        .filter((v) => this.isVisitActive(v.visitId))
        .sort((a, b) => moment(b.visitTime) - moment(a.visitTime));
      if (activeVisits && activeVisits.length > 0) {
        this.setState({ activeAccordionItem: activeVisits[0].visitId });
      }
    }
  }

  componentWillUnmount() {
    this.unsubscribes.forEach((unsubscribe) => unsubscribe());
  }

  isVisitActive = (visitId) => this.props.activeVisits.map((v) => v.visitId).includes(visitId);

  render() {
    const { activeVisits, contextVisit, currentUserId, labResultsVisits } = this.props;

    const { activeAccordionItem } = this.state;

    const orders = labResultsVisits.sort((a, b) => moment(b.visitTime) - moment(a.visitTime));

    const activeMedicalVisits = activeVisits.filter((v) => isVisitTypeMedical(v.visitType));
    const activeVisitsHasNoResultsYet = activeMedicalVisits.filter(
      (v) => !orders.map((o) => o.visitId).includes(v.visitId)
    );

    const items = [
      ...activeVisitsHasNoResultsYet.map((i) => ({ ...i, type: "visit" })),
      ...orders.map((i) => ({ ...i, type: "order" }))
    ].sort((a, b) => moment(b.visitTime) - moment(a.visitTime));

    return (
      <div>
        {orders.length === 0 && <Message variant="info">No results to display</Message>}
        <Accordion activeKey={activeAccordionItem} defaultActiveKey={0} flush>
          {items.map((item) => (
            <Accordion.Item key={item.visitId} eventKey={item.visitId}>
              <Accordion.Header
                onClick={() => {
                  this.setState({ activeAccordionItem: activeAccordionItem === item.visitId ? null : item.visitId });
                }}
                className={`inactive-accordion-header ${this.isVisitActive(item.visitId) ? "bg-primary" : ""} ${
                  item.type === "order" && item.hasNotReviewedResults ? "bg-warning" : ""
                }`}
              >
                <strong>
                  DOS:&nbsp;
                  {moment.utc(item.visitTime).startOf("day").diff(moment.utc().startOf("day"), "days") === 0
                    ? "Today"
                    : moment(item.visitTime).format(dateWithTimeFormat)}
                </strong>
              </Accordion.Header>
              <Accordion.Body className="no-padding">
                {this.isVisitActive(item.visitId) && (
                  <VisitResultHistoryReview
                    visitId={item.visitId}
                    visitTime={item.visitTime}
                    userCanReview={contextVisit?.prescribingProvider.providerId === currentUserId}
                  />
                )}
                {item.type === "order" && (
                  <VisitResultsReview
                    view="labs"
                    visitId={item.visitId}
                    orderId={item.orderId}
                    userCanReview={contextVisit?.prescribingProvider.providerId === currentUserId}
                    includeNotReviewedResults
                  />
                )}
              </Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const patientId = state.patient.currentPatient.patientId;
  return {
    patientId,
    currentUserId: state.userPreference.currentUserId,
    contextVisit: state.visits.contextVisit,
    activeVisits: state.visits.activeVisits,
    labResultsVisits: labResultsVisitsSelectors(state, { patientId }).selectAll() || []
  };
}

export default connect(mapStateToProps, null)(emrComponent(LabResultsByDateOfService));
