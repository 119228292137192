import "react-toastify/dist/ReactToastify.css";
import * as types from "./actionTypes";
import { processApiError, processThenThrowApiError } from "../helpers";
import { beginAjaxCall, ajaxCallDone } from "./ajaxStatusActions";
import { adalApiFetch } from "../adalConfig";
import { API_URL } from "../constants/environment";

function loadReactionsSuccess(reactionTypes) {
  return {
    type: types.LOAD_REACTIONS_SUCCESS,
    reactionTypes
  };
}

function loadStatesSuccess(states) {
  return {
    type: types.LOAD_STATES_SUCCESS,
    states
  };
}

function loadLabsUniqueSuccess(data) {
  return {
    type: types.LOAD_LABS_UNIQUE_SUCCESS,
    data
  };
}

function loadLabSpecimenTypesSuccess(data) {
  return {
    type: types.LOAD_LAB_SPECIMEN_TYPES_SUCCESS,
    data
  };
}

function loadLabTestTypesSuccess(data) {
  return {
    type: types.LOAD_LAB_TEST_TYPES_SUCCESS,
    data
  };
}

function loadLabTestStatusesSuccess(data) {
  return {
    type: types.LOAD_LAB_TEST_STATUSES_SUCCESS,
    data
  };
}

function loadLabProvidersSuccess(data) {
  return {
    type: types.LOAD_LAB_PROVIDERS_SUCCESS,
    data
  };
}

function loadLabOrderStatusesSuccess(data) {
  return {
    type: types.LOAD_LAB_ORDER_STATUSES_SUCCESS,
    data
  };
}

function loadIntegrationKeysSuccess(integrationKeys) {
  return {
    type: types.LOAD_INTEGRATION_KEYS_SUCCESS,
    integrationKeys
  };
}

function loadVisitStatusesSuccess(visitStatuses) {
  return {
    type: types.LOAD_VISIT_STATUSES_SUCCESS,
    visitStatuses
  };
}

function loadPrescriberSlotsSuccess(response) {
  return {
    type: types.LOAD_PRESCRIBER_SLOTS_SUCCESS,
    prescribersWithSlots: response.providers
  };
}

function clearPrescriberSlots() {
  return {
    type: types.CLEAR_PRESCRIBER_SLOTS
  };
}

function loadLivingSituationsSuccess(livingSituations) {
  return {
    type: types.LOAD_LIVING_SITUATIONS_SUCCESS,
    livingSituations
  };
}

function loadBillingCodesSuccess(billingCodes) {
  return {
    type: types.LOAD_BILLING_CODES_SUCCESS,
    billingCodes
  };
}

function loadVisitTypesSuccess(visitTypes) {
  return {
    type: types.LOAD_VISIT_TYPES_SUCCESS,
    visitTypes
  };
}

function loadDischargeReasonsSuccess(dischargeReasons) {
  return {
    type: types.LOAD_DISCHARGE_REASONS_SUCCESS,
    dischargeReasons
  };
}

function loadLanguagesSuccess(languages) {
  return {
    type: types.LOAD_LANGUAGES_SUCCESS,
    languages
  };
}

function loadTreatmentsSuccess(treatments) {
  return {
    type: types.LOAD_TREATMENTS_SUCCESS,
    treatments
  };
}

function loadAppointmentCancelReasonSuccess(appointmentCancelReason) {
  return {
    type: types.LOAD_APPOINTMENT_CANCEL_REASON_SUCCESS,
    appointmentCancelReason
  };
}

function loadRacesSuccess(races) {
  return {
    type: types.LOAD_RACES_SUCCESS,
    races
  };
}

function loadEthnicitiesSuccess(ethnicities) {
  return {
    type: types.LOAD_ETHNICITIES_SUCCESS,
    ethnicities
  };
}

function loadMaritalStatusesSuccess(maritalStatuses) {
  return {
    type: types.LOAD_MARITAL_STATUSES_SUCCESS,
    maritalStatuses
  };
}

function loadRelationshipsSuccess(relationships) {
  return {
    type: types.LOAD_RELATIONSHIPS_SUCCESS,
    relationships
  };
}

function loadInsuranceRelationshipsSuccess(insuranceRelationships) {
  return {
    type: types.LOAD_INS_RELATIONSHIPS_SUCCESS,
    insuranceRelationships
  };
}

function loadCommunicationPreferencesSuccess(communicationPreferences) {
  return {
    type: types.LOAD_COMM_PREF_SUCCESS,
    communicationPreferences
  };
}

function loadExostarActionTypesSuccess(exostarActionTypes) {
  return {
    type: types.LOAD_EXOSTAR_ACTION_TYPES_SUCCESS,
    data: exostarActionTypes
  };
}

function loadCentersLookupSuccess(centers) {
  return {
    type: types.LOAD_CENTERS_LOOKUP_SUCCESS,
    centers
  };
}

export function loadTaskRolesDone(data) {
  return { type: types.FETCH_TASK_ROLES_DONE, data };
}

export function loadRoleGroupsDone(data) {
  return {
    type: types.FETCH_ROLE_GROUPS_DONE,
    data
  };
}

function loadLevelsSuccess(levels) {
  return {
    type: types.LOAD_LEVELS_SUCCESS,
    levels
  };
}

function loadMspOptionsSuccess(mspOptions) {
  return {
    type: types.LOAD_MSP_OPTIONS_SUCCESS,
    mspOptions
  };
}

function loadPcpOptionsSuccess(pcpOptions) {
  return {
    type: types.LOAD_PCP_OPTIONS_SUCCESS,
    pcpOptions
  };
}

function loadInsuranceProvidersSuccess(insuranceProviders) {
  return {
    type: types.LOAD_INSURANCE_PROV_SUCCESS,
    insuranceProviders
  };
}

function loadAuxFlagsSuccess(auxFlags) {
  return {
    type: types.LOAD_AUX_FLAGS_SUCCESS,
    auxFlags
  };
}

function loadGroupTypesSuccess(groupTypes) {
  return {
    type: types.LOAD_GROUP_TYPES_SUCCESS,
    groupTypes
  };
}

function loadVisitTypeGroupsSuccess(visitTypeGroups) {
  return {
    type: types.LOAD_VISIT_TYPE_GROUPS_SUCCESS,
    visitTypeGroups
  };
}

function loadDrugFormsSuccess(drugForms) {
  return {
    type: types.LOAD_DRUG_FORMS_SUCCESS,
    drugForms
  };
}

function loadDrugRoutesSuccess(drugRoutes) {
  return {
    type: types.LOAD_DRUG_ROUTES_SUCCESS,
    drugRoutes
  };
}

function loadDrugStrengthsSuccess(drugStrengths) {
  return {
    type: types.LOAD_DRUG_STRENGTHS_SUCCESS,
    drugStrengths
  };
}

function loadDocumentTagsSuccess(documentTags) {
  return {
    type: types.LOAD_DOCUMENT_TAGS_SUCCESS,
    documentTags
  };
}

function loadRxAuthenticationTypesSuccess(rxAuthenticationTypes) {
  return {
    type: types.LOAD_RX_AUTHENTICATION_TYPES_SUCCESS,
    rxAuthenticationTypes
  };
}

function loadNumberPerDoseOptionsSuccess(numberPerDoseOptions) {
  return {
    type: types.LOAD_NUMBER_PER_DOSE_OPTIONS_SUCCESS,
    numberPerDoseOptions
  };
}

function loadRefillOptionsSuccess(refillOptions) {
  return {
    type: types.LOAD_REFILL_OPTIONS_SUCCESS,
    refillOptions
  };
}

function loadFrequencyOptionsSuccess(frequencyOptions) {
  return {
    type: types.LOAD_PRESCRIPTION_FREQUENCY_OPTIONS_SUCCESS,
    frequencyOptions
  };
}

function loadAttestationOptionsSuccess(attestationOptions) {
  return {
    type: types.LOAD_ATTESTATION_OPTIONS_SUCCESS,
    attestationOptions
  };
}

function loadCenterSuccess(centers) {
  return {
    type: types.LOAD_CENTERS_LOOKUP_SUCCESS,
    centers
  };
}

function loadConditionsToSuccess(conditionsResult) {
  return {
    type: types.LOAD_ALL_CONDITION_TO_SUCCESS,
    conditionsResult
  };
}

function loadInterventionConditionsSuccess(interventionConditions) {
  return {
    type: types.LOAD_INTERVENTION_CONDITIONS_SUCCESS,
    interventionConditions
  };
}

function addToCached(path) {
  return {
    type: types.ADD_TO_CACHED,
    path
  };
}

export function clearLookupCache(path) {
  return {
    type: types.CLEAR_LOOKUP_CACHE,
    path
  };
}

export function loadAndCache(path, url, successCallback) {
  return (dispatch, getState) => {
    const value = path.split(".").reduce((o, i) => o[i], getState());
    if (getState().lookups.cached.includes(path)) {
      if (
        (typeof value === "object" && Object.keys(value).length) ||
        (Array.isArray(value) && value.length) ||
        (value !== Object(value) && value)
      )
        return Promise.resolve(value);
      return Promise.resolve([]);
    }
    dispatch(addToCached(path));
    dispatch(beginAjaxCall(url));
    return adalApiFetch(`${API_URL}/${url}`)
      .then((response) => dispatch(successCallback(response)))
      .catch((error) => processThenThrowApiError(error, dispatch))
      .finally(() => dispatch(ajaxCallDone()));
  };
}

export function loadConditions() {
  return loadAndCache("lookups.conditions", "LookupData/MedicalCondition", loadConditionsToSuccess);
}

export function loadInterventionConditions() {
  return loadAndCache(
    "lookups.interventionConditions",
    "LookupData/InterventionConditions",
    loadInterventionConditionsSuccess
  );
}

function loadGenderPronounsSuccess(genderPronouns) {
  return {
    type: types.LOAD_GENDERPRONOUNS_SUCCESS,
    genderPronouns
  };
}

function loadGenderIdentitiesSuccess(genderIdentities) {
  return {
    type: types.LOAD_GENDER_IDENTITIES_SUCCESS,
    genderIdentities
  };
}

function loadSexAssignedAtBirthSuccess(sexAssignedAtBirths) {
  return {
    type: types.LOAD_SEXASSIGNEDATBIRTHS_SUCCESS,
    sexAssignedAtBirths
  };
}

function loadSexualOrientationsSuccess(sexualOrientations) {
  return {
    type: types.LOAD_SEXUALORIENTATIONS_SUCCESS,
    sexualOrientations
  };
}

function loadDrugsToSuccess(drugsResult) {
  return {
    type: types.LOAD_ALL_DRUG_TO_SUCCESS,
    drugsResult
  };
}

function loadAllergiesToSuccess(allergiesResult) {
  return {
    type: types.LOAD_ALL_ALLERGY_TO_SUCCESS,
    allergiesResult
  };
}

function loadTaskTemplatesSuccess(data) {
  return {
    type: types.LOAD_TASK_TEMPLATES_SUCCESS,
    data
  };
}

function loadNoteTemplatesSuccess(data) {
  return {
    type: types.LOAD_NOTE_TEMPLATES_SUCCESS,
    data
  };
}

function loadPatientCustomFieldsSuccess(data) {
  return {
    type: types.LOAD_PATIENT_CUSTOM_FIELDS_SUCCESS,
    data
  };
}

export function loadInsuranceProviders() {
  return loadAndCache("lookups.insuranceProviders", "LookupData/InsuranceProvider", loadInsuranceProvidersSuccess);
}

export function loadPcpOptions() {
  return loadAndCache("lookups.pcpOptions", "LookupData/PharmacyCoverageProvider", loadPcpOptionsSuccess);
}

export function loadMspOptions() {
  return loadAndCache("lookups.mspOptions", "LookupData/MedicareSecondaryProvider", loadMspOptionsSuccess);
}

export function loadCommunicationPreferences() {
  return loadAndCache(
    "lookups.communicationPreferences",
    "LookupData/CommunicationPreference",
    loadCommunicationPreferencesSuccess
  );
}

export function loadExostarActionTypes() {
  return loadAndCache("lookups.exostarActionTypes", "LookupData/ExostarActionTypes", loadExostarActionTypesSuccess);
}

export function loadRelationships() {
  return loadAndCache("lookups.relationships", "LookupData/Relationship", loadRelationshipsSuccess);
}

export function loadInsuranceRelationships() {
  return loadAndCache(
    "lookups.insuranceRelationships",
    "LookupData/InsuranceRelationship",
    loadInsuranceRelationshipsSuccess
  );
}

export function loadEthnicities() {
  return loadAndCache("lookups.ethnicities", "LookupData/Ethnicity", loadEthnicitiesSuccess);
}

export function loadMaritalStatuses() {
  return loadAndCache("lookups.maritalStatuses", "LookupData/MaritalStatuses", loadMaritalStatusesSuccess);
}

export function loadRaces() {
  return loadAndCache("lookups.races", "LookupData/Race", loadRacesSuccess);
}

export function loadTreatments() {
  return loadAndCache("lookups.treatments", "LookupData/TreatmentType", loadTreatmentsSuccess);
}

export function loadAppointmentCancelReason() {
  return loadAndCache(
    "lookups.appointmentCancelReason",
    "LookupData/AppointmentCancelReason",
    loadAppointmentCancelReasonSuccess
  );
}

export function loadLanguages() {
  return loadAndCache("lookups.languages", "LookupData/Language", loadLanguagesSuccess);
}

export function loadReactions() {
  return loadAndCache("lookups.reactionTypes", "LookupData/ReactionType", loadReactionsSuccess);
}

export function loadStates() {
  return loadAndCache("lookups.states", "LookupData/State", loadStatesSuccess);
}

export function loadLabsUnique() {
  return loadAndCache("lookups.labsUnique", "LookupData/LabsUnique", loadLabsUniqueSuccess);
}

export function loadLabSpecimenTypes() {
  return loadAndCache("lookups.labSpecimenTypes", "LookupData/LabSpecimenTypes", loadLabSpecimenTypesSuccess);
}

export function loadLabTestTypes() {
  return loadAndCache("lookups.labTestTypes", "LookupData/LabTestTypes", loadLabTestTypesSuccess);
}

export function loadLabTestStatuses() {
  return loadAndCache("lookups.labTestStatuses", "LookupData/LabTestStatuses", loadLabTestStatusesSuccess);
}

export function loadLabProviders() {
  return loadAndCache("lookups.labProviders", "LookupData/LabProviders", loadLabProvidersSuccess);
}

export function loadLabOrderStatuses() {
  return loadAndCache("lookups.labOrderStatuses", "LookupData/LabOrderStatuses", loadLabOrderStatusesSuccess);
}

export function loadIntegrationKeys(modelName) {
  // eslint-disable-next-line consistent-return
  return (dispatch, getState) => {
    if (!getState().lookups.integrationKeys.find((ik) => ik.modelName === modelName)) {
      dispatch(beginAjaxCall("loadIntegrationKeys"));
      return adalApiFetch(`${API_URL}/LookupData/IntegrationKeys?modelName=${modelName}`)
        .then((integrationKeys) => dispatch(loadIntegrationKeysSuccess(integrationKeys)))
        .catch((error) => {
          processApiError(error, dispatch);
        })
        .finally(() => {
          dispatch(ajaxCallDone());
        });
    }
  };
}

export function loadPrescriberSlots() {
  return (dispatch) => {
    dispatch(clearPrescriberSlots());
    dispatch(beginAjaxCall("loadPrescriberSlots"));
    return adalApiFetch(`${API_URL}/Users/Providers/AvailablePatientSlots`)
      .then((data) => dispatch(loadPrescriberSlotsSuccess(data)))
      .catch((error) => processThenThrowApiError(error, dispatch))
      .finally(() => dispatch(ajaxCallDone()));
  };
}

export function loadLivingSituations() {
  return loadAndCache("lookups.livingSituations", "LookupData/LivingSituation", loadLivingSituationsSuccess);
}

export function loadBillingCodes() {
  return loadAndCache("lookups.billingCodes", "LookupData/BillingCode", loadBillingCodesSuccess);
}

export function loadVisitTypes() {
  return loadAndCache("lookups.visitTypes", "LookupData/VisitType", loadVisitTypesSuccess);
}

export function loadLevels() {
  return loadAndCache("lookups.levels", "LookupData/Level", loadLevelsSuccess);
}

export function loadDischargeReasons() {
  return loadAndCache("lookups.dischargeReasons", "LookupData/DischargeReason", loadDischargeReasonsSuccess);
}

export function loadVisitStatuses() {
  return loadAndCache("lookups.visitStatuses", "LookupData/VisitStatus", loadVisitStatusesSuccess);
}

export function loadCentersLookup() {
  return loadAndCache("lookups.centers", "LookupData/Center", loadCentersLookupSuccess);
}

export function loadTaskRoles() {
  return loadAndCache("lookups.taskRoles", "LookupData/Tasks/Roles", loadTaskRolesDone);
}

export function loadRoleGroups() {
  return loadAndCache("lookups.roleGroups", "LookupData/TasksBoard/Roles", loadRoleGroupsDone);
}

export function loadAuxFlags() {
  return loadAndCache("lookups.auxFlags", "LookupData/AuxiliaryFlag", loadAuxFlagsSuccess);
}

export function loadGroupTypes() {
  return loadAndCache("lookups.groupTypes", "LookupData/VisitGroupType", loadGroupTypesSuccess);
}

export function loadVisitTypeGroups() {
  return loadAndCache("lookups.visitTypeGroups", "LookupData/VisitTypeGroups", loadVisitTypeGroupsSuccess);
}

export function loadDrugForms(name) {
  return (dispatch) => {
    dispatch(beginAjaxCall("loadDrugForms"));
    return adalApiFetch(`${API_URL}/Reference/Drugs/Forms?drug=${name}`)
      .then((drugForms) => dispatch(loadDrugFormsSuccess(drugForms)))
      .catch((error) => {
        processApiError(error, dispatch);
      })
      .finally(() => {
        dispatch(ajaxCallDone());
      });
  };
}

export function loadDrugRoutes(name, form) {
  return (dispatch) => {
    dispatch(beginAjaxCall("loadDrugRoutes"));
    return adalApiFetch(`${API_URL}/Reference/Drugs/Forms/Routes?drug=${name}&form=${form}`)
      .then((drugRoutes) => dispatch(loadDrugRoutesSuccess(drugRoutes)))
      .catch((error) => {
        processApiError(error, dispatch);
      })
      .finally(() => {
        dispatch(ajaxCallDone());
      });
  };
}

export function loadDrugStrengths(name, form, route) {
  return (dispatch) => {
    dispatch(beginAjaxCall("loadDrugStrenghts"));
    return adalApiFetch(`${API_URL}/Reference/Drugs/Forms/Routes/Strengths?drug=${name}&form=${form}&route=${route}`)
      .then((drugStrengths) => dispatch(loadDrugStrengthsSuccess(drugStrengths)))
      .catch((error) => {
        processApiError(error, dispatch);
      })
      .finally(() => {
        dispatch(ajaxCallDone());
      });
  };
}

export function clearDrugRoutes() {
  return (dispatch) =>
    dispatch({
      type: types.CLEAR_DRUG_ROUTES_SUCCESS,
      drugRoutes: []
    });
}

export function clearDrugStrengths() {
  return (dispatch) =>
    dispatch({
      type: types.CLEAR_DRUG_STRENGTHS_SUCCESS,
      drugStrengths: []
    });
}

const _LOOKUPS_ = {
  loadDocumentTagsSuccess,
  loadRxAuthenticationTypesSuccess,
  loadNumberPerDoseOptionsSuccess,
  loadRefillOptionsSuccess,
  loadFrequencyOptionsSuccess,
  loadAttestationOptionsSuccess,
  loadCenterSuccess
};

export function loadLookup(lookup) {
  return loadAndCache(
    `lookups.${lookup[0].toLowerCase()}${lookup.slice(1)}`,
    `LookupData/${lookup}`,
    _LOOKUPS_[`load${lookup}Success`]
  );
}

export function loadGenderPronouns() {
  return loadAndCache("lookups.genderPronouns", "LookupData/GenderPronoun", loadGenderPronounsSuccess);
}

export function loadGenderIdentities() {
  return loadAndCache("lookups.genderIdentities", "LookupData/GenderIdentity", loadGenderIdentitiesSuccess);
}

export function loadSexAssignedAtBirths() {
  return loadAndCache("lookups.sexAssignedAtBirths", "LookupData/SexAssignedAtBirth", loadSexAssignedAtBirthSuccess);
}

export function loadSexualOrientations() {
  return loadAndCache("lookups.sexualOrientations", "LookupData/SexualOrientation", loadSexualOrientationsSuccess);
}

export function loadDrugs() {
  return loadAndCache("lookups.drugs", "LookupData/Drug", loadDrugsToSuccess);
}

export function loadAllergies() {
  return loadAndCache("lookups.allergies", "LookupData/Allergy", loadAllergiesToSuccess);
}

export function loadFamilyMedicalConditions() {
  return loadAndCache("lookups.familyMedicalConditions", "LookupData/FamilyMedicalCondition", (result) => ({
    type: types.LOAD_ALL_FAMILY_MEDICAL_CONDITIONS_SUCCESS,
    result
  }));
}

export function loadTaskTemplates(roles) {
  return (dispatch) => {
    dispatch(beginAjaxCall("loadTaskTemplates"));
    return adalApiFetch(`${API_URL}/LookupData/TaskTemplates?roles=${roles.join(`,`)}`)
      .then((data) => dispatch(loadTaskTemplatesSuccess(data)))
      .catch((error) => processThenThrowApiError(error, dispatch))
      .finally(() => dispatch(ajaxCallDone()));
  };
}

export function loadNoteTemplates(roles) {
  return (dispatch) => {
    dispatch(beginAjaxCall("loadNoteTemplates"));
    return adalApiFetch(`${API_URL}/LookupData/NoteTemplates?roles=${roles.join(`,`)}`)
      .then((data) => dispatch(loadNoteTemplatesSuccess(data)))
      .catch((error) => processThenThrowApiError(error, dispatch))
      .finally(() => dispatch(ajaxCallDone()));
  };
}

export function loadPatientCustomFields() {
  return loadAndCache("lookups.patientCustomFields", "LookupData/PatientCustomFields", loadPatientCustomFieldsSuccess);
}
