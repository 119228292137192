import React from "react";
import * as _ from "lodash";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import { toastErrorOptions } from "../constants/toastconfig";
import { API_URL } from "../constants/environment";
import { adalApiFetch, logout } from "../adalConfig";
import { isoFormat, dateWithTimeFormat, dateWithFullTimeFormat } from "../constants/miscellaneous";
import { ajaxCallError } from "../actions/ajaxStatusActions";
import { showModal as _showModal, hideModal as _hideModal } from "../actions/modalActions";
import { pushUiErrorLogToSlider } from "../actions/sidebarErrorLogActions";
import { addItemToSubscriptionList, removeItemFromSubscriptionList } from "../actions/streamingActions";
import prescriptionStatus from "../constants/prescriptionStatus";
import * as serviceWorkerRegistration from "../serviceWorkerRegistration";
import * as graphQLUtils from "./graphql";

export { graphQLUtils };
export * from "./graphql";

export function removeSpecialCharacters(value) {
  return value === undefined || value === null || Number.isNaN(value)
    ? ""
    : value.toString().replace(/[^A-Za-z0-9]/g, "");
}

export function maskedInputRemoveUnderscore(value) {
  return value === undefined || value === null || Number.isNaN(value) ? "" : value.replace(/[_]/g, "").trim();
}

export function updateErrors(field, value, required, errors, inputValidation) {
  let isValid = true;

  if (removeSpecialCharacters(value).length && inputValidation) {
    const re = new RegExp(inputValidation, "i");
    isValid = re.test(value);
  }

  const _error = { ...errors };
  if ((required && value.toString().trim().length < 1) || !isValid) {
    _error[field] = "";
  } else {
    delete _error[field];
  }

  return _error;
}

function validateStartEndDates(field, value, startDateFromState, endDateFromState) {
  const _value = moment(value, "MM/DD/YYYY");
  const dateToCompare =
    field === "startDate" ? moment(endDateFromState, "MM/DD/YYYY") : moment(startDateFromState, "MM/DD/YYYY");
  return field === "startDate"
    ? _value.diff(moment(dateToCompare), "days") <= 1
    : _value.diff(moment(dateToCompare), "days") >= 1;
}

export function updateErrorsWithStartEndDates(
  field,
  value,
  required,
  errors,
  inputValidation,
  startDateFromState,
  endDateFromState
) {
  let isValid = true;

  const _value = required ? removeSpecialCharacters(value) : value;

  if (removeSpecialCharacters(_value).length && Object.prototype.hasOwnProperty.call(inputValidation, field)) {
    const re = new RegExp(inputValidation[field], "i");
    isValid = re.test(_value);

    if (startDateFromState && endDateFromState && isValid) {
      if (field === "startDate" || field === "endDate") {
        if (field === "startDate" ? endDateFromState.length === 10 : startDateFromState.length === 10) {
          isValid = validateStartEndDates(field, value, startDateFromState, endDateFromState);
        }
      }
    }
  }

  const _errors = { ...errors };
  if ((required && value.toString().trim().length < 1) || !isValid) {
    if (field === "startDate" || field === "endDate") {
      _errors.startDate = "";
      _errors.endDate = "";
    } else {
      _errors[field] = "";
    }
  } else if (field === "startDate" || field === "endDate") {
    delete _errors.startDate;
    delete _errors.endDate;
  } else {
    delete _errors[field];
  }
  return _errors;
}

export function requiredFieldsFilled(fields, input) {
  function checkLength(i, _input) {
    return removeSpecialCharacters(_input[fields[i]]);
  }
  for (let i = 0; i < fields.length; i++) {
    if (checkLength(i, input).length === 0) {
      return true;
    }
  }
  return false;
}

export function getGender(gender) {
  if (gender === "female") {
    return 1;
  }
  if (gender === "male") {
    return 0;
  }

  return 2;
}

export function getLookupText(value, lookup, isValueLookup = false) {
  if (value && lookup.length) {
    const lookupItem = lookup.find((item) => `${item.value}` === `${value}`);
    if (isValueLookup) {
      return lookupItem ? lookupItem.text : value;
    }
    return lookupItem ? lookupItem.text : "NO TEXT VALUE FOUND";
  }
  return "";
}

export function isDateInRangeWithCurrentDate(startDate, endDate) {
  const parsedStartDate = Date.parse(startDate);
  const parsedEndDate = Date.parse(endDate);
  const currentDate = Date.now();

  return currentDate >= parsedStartDate && currentDate <= parsedEndDate;
}

export function formatDateToLocalTime(date, format = dateWithTimeFormat) {
  if (date && typeof date === "string" && date.endsWith("+00:00")) {
    return moment(date, isoFormat).format(format);
  }
  // suppose it is in UTC timezone!
  return moment.utc(date, dateWithTimeFormat).local().format(format);
}

function _pushUiErrorLogToSlider(error, orchestrationId = "N/A") {
  if (window.store)
    window.store.dispatch(
      pushUiErrorLogToSlider({
        detail: error,
        orchestrationId
      })
    );
  // else // failed to login // do nothing just die on login... page // login happens before creating the store
}

export function processApiError(error, dispatch = false, orchestrationId = "N/A") {
  const orchestrationIdInError = error && error.orchestrationId;
  const promise = new Promise((resolve, reject) => {
    if (error instanceof DOMException && error.name === "AbortError") {
      reject({ message: "USER_ABORTED" });
    } else if (error && typeof error !== "string") {
      try {
        const errorMessage = JSON.parse(error.message);
        _pushUiErrorLogToSlider(errorMessage.ErrorUserMessage, orchestrationIdInError);
        toast.error(errorMessage.ErrorUserMessage, toastErrorOptions);
      } catch (e) {
        if (error.message === "USER_ABORTED") {
          reject(error);
        } else if (error.message !== "IGNORE_THIS_ERROR") {
          /* not json response like 404 */
          _pushUiErrorLogToSlider(error.message, orchestrationIdInError);
          toast.error(error.message, toastErrorOptions); // THIS ONE
        }
      }
    } else if (typeof error === "string") {
      _pushUiErrorLogToSlider(error, orchestrationId);
      toast.error(error, toastErrorOptions);
    }
    resolve(error);
  });
  return dispatch ? promise.then((_error) => dispatch(ajaxCallError(_error))) : promise;
}

export function processThenThrowApiError(error, dispatch = false, orchestrationId = "N/A") {
  processApiError(error, dispatch, orchestrationId);
  throw error;
}

export function logError(errorMessage, url = "", responseStatusCode = "", stackTrace = "") {
  return adalApiFetch(
    `${API_URL}/Admin/NonRecoverableError`,
    {
      method: "POST",
      body: JSON.stringify({
        errorMessage,
        timeStamp: moment().toISOString(),
        responseStatusCode,
        url,
        stackTrace
      })
    },
    false
  ).catch((error) => {
    processApiError(error);
  });
}

export function pollApiEvents(orchestrationId, successEvents, resolveEvents = false, count = 0) {
  // eslint-disable-next-line no-param-reassign
  if (!Array.isArray(successEvents)) successEvents = [successEvents];
  return new Promise((resolve, reject) => {
    if (count >= 4 * 60 * 2) {
      /* keep trying for two mins */
      const message = `Too many trials fetching event(s) [${successEvents.join(", ")}]`;
      logError(message);
      reject({ message, orchestrationId });
      return;
    }
    setTimeout(() => {
      if (orchestrationId) {
        adalApiFetch(`${API_URL}/Events/${orchestrationId}`).then((json) => {
          if (json.error) {
            reject(json.error);
          } else {
            const { events } = json;
            if (events.length) {
              // const event = events.find(e => successEvents.find(se => se === e.eventTypes));
              const filteredEvents = events.filter((e) => successEvents.includes(e.eventTypes));
              const error = _.find(events, {
                eventTypes: "IPlatformErrorEvent"
              });
              if (filteredEvents && filteredEvents.length) {
                if (resolveEvents) {
                  resolve(filteredEvents.map((fe) => ({ ...fe, message: JSON.parse(fe.message) })));
                } else {
                  resolve({ ...JSON.parse(filteredEvents[0].message), eventTypes: filteredEvents[0].eventTypes });
                }
              } else if (error) {
                reject(error);
              } else {
                // eslint-disable-next-line no-param-reassign
                resolve(pollApiEvents(orchestrationId, successEvents, resolveEvents, ++count));
              }
            } else {
              // eslint-disable-next-line no-param-reassign
              resolve(pollApiEvents(orchestrationId, successEvents, resolveEvents, ++count));
            }
          }
        });
      } else {
        reject(new Error("No orchestration ID returned"));
      }
    }, 250);
  });
}

export function processApiResponse(response, events = []) {
  const promise = new Promise((resolve, reject) => {
    try {
      const checkForErrors = JSON.parse(response);
      if (Object.prototype.hasOwnProperty.call(checkForErrors, "errors")) {
        reject(checkForErrors.errors);
      } else {
        resolve(response);
      }
    } catch (error) {
      resolve(response);
    }
  });
  return events.length ? promise.then((orchestrationId) => pollApiEvents(orchestrationId, events)) : promise;
}

export function getProviderName(value, lookup) {
  if (value && lookup.length) {
    const provider = lookup.find((item) => item.userId === value);
    return provider ? `${provider.firstName} ${provider.lastName}` : "";
  }
  return false;
}

export function handleErrors(response, logoutIfForbidden = false) {
  if (logoutIfForbidden && response.status === 403) {
    /* maybe we need to show some message before logging out */
    logout();
  }
  response.text().then((text) => {
    try {
      /* json body */
      const data = JSON.parse(text);
      let message = ``;
      if (data.message) message += `${data.message}: `;
      if (data.errors && data.errors.length)
        message += `${data.errors
          .map((e) => `${e.field ? `${e.field}: ` : ``}${e.message ? e.message : ``}`)
          .join(", ")} `;
      if (message === ``) throw Error("_");
      toast.error(message, toastErrorOptions);
    } catch (e) {
      /* not json body */
      if ([response.status, response.statusText].includes("User login is required")) {
        logout();
      } else if ([response.status, response.statusText].includes("Token renewal operation failed due to timeout")) {
        logError(response.status, response.url, response.statusText); // just log to let us know
      } else {
        const message = `Error [${response.status}] while trying to reach endpoint ${response.url}`;
        logError(message, response.url, response.statusText);
        toast.error(
          <span>
            <div>
              {`${response.statusText}`}
              <small style={{ float: "right", marginRight: "10px" }}>
                {moment().utc().format(dateWithFullTimeFormat)}
              </small>
            </div>
            {text} {text && <br />}
            {message}
            <br />
            <strong>{`Error reported to IT department`}</strong>
          </span>,
          toastErrorOptions
        );
      }
    }
  });
  throw Error("IGNORE_THIS_ERROR");
}

export function checkRoles(group, userRoles = []) {
  return group.filter((role) => userRoles.includes(role)).length > 0;
}

export function alphaOnlyKeyPress(event) {
  const regex = new RegExp("^[a-zA-Z ]+$");
  const key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
  if (!regex.test(key)) {
    event.preventDefault();
    return true;
  }
  return false;
}

export function alphaOnlyInputPaste(event) {
  const regex = new RegExp("^[a-zA-Z ]+$");
  if (!regex.test(event.clipboardData.getData("Text"))) {
    event.preventDefault();
    return true;
  }
  return false;
}

export function checkLengthError(value, length) {
  // eslint-disable-next-line no-param-reassign
  value = value || "";
  if (value.length > length) {
    return true;
  }
  return false;
}

export function removeExtraSpace(value) {
  return value.replace(/\s+/g, " ");
}

export function formatInputSpace(obj) {
  for (const name in obj) {
    if (typeof obj[name] === "string" || obj[name] instanceof String) {
      // eslint-disable-next-line no-param-reassign
      obj[name] = !!obj[name] && !!obj[name].trim() ? removeExtraSpace(obj[name].trim()) : null;
    }
    // continue; // Amr: what is this?
  }
  return obj;
}

/** I don't know why removing this function fails the lint on build server */
export const isEmpty = (value) => _.isEmpty(value);

export const isEmptyOrWhiteSpace = (value) => _.isEmpty(_.trim(value));

export function paddingNumber(number) {
  return (number < 10 && number >= 0 ? "0" : "") + number;
}

export function calculateTimeFromNow(startTime) {
  const now = moment();
  const then = moment(startTime).local();
  const milliseconds = moment(now).diff(then);
  if (!milliseconds) return "";
  const dur = moment.duration(milliseconds);
  const hours = Math.floor(dur.hours());
  const minutes = Math.floor(dur.minutes());
  const seconds = Math.floor(dur.seconds());
  return `
    ${paddingNumber(hours)}: 
    ${paddingNumber(minutes)}: 
    ${paddingNumber(seconds)}`;
}

export function hasProperty(obj, prop) {
  return Object.prototype.hasOwnProperty.call(obj, prop);
}
export const pendingPrescriptionsUrlQuery = `ofStatus=${[
  prescriptionStatus.new,
  prescriptionStatus.savedForSignature,
  prescriptionStatus.signed
].join("&ofStatus=")}`;

export function hasWhiteSpace(value) {
  return ((value || "").toString().match(/^\s+$/g) || "").length > 0;
}

export function specialInstrunctionsInvalid(value) {
  return (
    hasWhiteSpace(value) ||
    (!isEmpty(value) &&
      ((value || "").toString().match(/^[a-zA-Z0-9#(][a-zA-Z0-9 '().,#:/\-@_%&=]*$/g) || "").length === 0)
  );
}

export function noteToPharmacistInvalid(value) {
  return (
    hasWhiteSpace(value) ||
    (!isEmpty(value) &&
      ((value || "").toString().match(/^[a-zA-Z0-9#(]*[a-zA-Z0-9 '.,()#:/\-@_%\r\n$;]*$/g) || "").length === 0)
  );
}

export function b64toBlob(b64Data, contentType = "", sliceSize = 512) {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);
    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
}

export function pushOrchestraItem(orchestrationId, timestamp, errorHandler = null, payload = {}) {
  window.cleanSlate.orchestra.push({ orchestrationId, timestamp, errorHandler, payload });
}

export function pullOrchestraItem(orchestrationId, del = true) {
  const o = window.cleanSlate.orchestra.find((_o) => _o.orchestrationId === orchestrationId);
  if (del)
    window.cleanSlate.orchestra = window.cleanSlate.orchestra.filter((_o) => _o.orchestrationId !== orchestrationId);
  return o;
}

export function deleteOrchestraItem(orchestrationId) {
  window.cleanSlate.orchestra = window.cleanSlate.orchestra.filter((o) => o.orchestrationId !== orchestrationId);
}

export function formatPhoneNumber(phoneNumberString) {
  const cleaned = `${phoneNumberString}`.replace(/\D/g, "");
  const match = cleaned.match(/^(\d{3})(\d{3})(\d*)$/);
  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }
  return phoneNumberString;
}

export function subscribe(groupName, trial = 1) {
  const store = window.store;
  if (!store) {
    // eslint-disable-next-line no-console
    console.log("You cannot use subscribe function outside redux context");
    return;
  }
  const streamId = store.getState().streaming.streamId;
  if (streamId) {
    const subscriptionList = store.getState().streaming.subscriptionList;
    if (subscriptionList.includes(groupName)) return; // already added
    adalApiFetch(
      `${API_URL}/Streaming/Subscribe`,
      {
        method: "POST",
        body: JSON.stringify({
          streamId,
          groupName
        })
      },
      false
    ).then(() => {
      store.dispatch(addItemToSubscriptionList(groupName));
    });
  } else if (trial < 4) {
    setTimeout(() => {
      subscribe(groupName, trial + 1);
    }, 3000);
  } else {
    // stream id is not set for some reason
    toast.error("Stream Id is not set!", toastErrorOptions);
  }
}

export function unsubscribe(groupName) {
  const store = window.store;
  if (!store) {
    // eslint-disable-next-line no-console
    console.log("You cannot use unsubscribe function outside redux context");
    return;
  }
  const streamId = store.getState().streaming.streamId;
  if (streamId) {
    adalApiFetch(
      `${API_URL}/Streaming/UnSubscribe`,
      {
        method: "POST",
        body: JSON.stringify({
          streamId,
          groupName
        })
      },
      false
    ).then(() => {
      store.dispatch(removeItemFromSubscriptionList(groupName));
    });
  } else {
    // stream id is not set for some reason
    toast.error("Stream Id is not set!", toastErrorOptions);
  }
}

export function resubscribeAll() {
  const store = window.store;
  if (!store) {
    // eslint-disable-next-line no-console
    console.log("You cannot use resubscribeAll function outside redux context");
    return;
  }
  const streamId = store.getState().streaming.streamId;
  if (streamId) {
    const subscriptionList = store.getState().streaming.subscriptionList;
    subscriptionList.forEach((groupName) => {
      adalApiFetch(
        `${API_URL}/Streaming/Subscribe`,
        {
          method: "POST",
          body: JSON.stringify({
            streamId,
            groupName
          })
        },
        false
      );
    });
  } else {
    // stream id is not set for some reason
    toast.error("Stream Id is not set!", toastErrorOptions);
  }
}

export function patientName(first, pref, middle, last, suff) {
  return `${first ? `${first} ` : ``}${pref ? `"${pref}" ` : ``}${middle ? `${middle} ` : ``}${last ? `${last}` : ``}${
    suff ? `, ${suff}` : ``
  }`;
}

export function handleAppLink(e) {
  e.preventDefault();
  /* const newWindow = */ window.open(e.currentTarget.getAttribute("href"));
  // setTimeout(() => newWindow.close(), 10);
  return false;
}

export function detectDocumentType(document, callback) {
  const fileReader = new FileReader();
  fileReader.onloadend = (e) => {
    const arr = new Uint8Array(e.target.result).subarray(0, 4);
    let header = "";
    for (let i = 0; i < arr.length; i++) {
      header += arr[i].toString(16);
    }
    let type = "application/octet-stream";
    switch (header) {
      case "89504e47":
        type = "image/png";
        break;
      case "47494638":
        type = "image/gif";
        break;
      case "ffd8ffe0":
      case "ffd8ffe1":
      case "ffd8ffe2":
      case "ffd8ffe3":
      case "ffd8ffe8":
        type = "image/jpeg";
        break;
      case "25504446":
        type = "application/pdf";
        break;
      case "24726162":
        type = "text/plain";
        break;
      default:
        type = document.type;
        break;
    }
    if (callback) callback(type);
  };
  fileReader.readAsArrayBuffer(document);
}
export function clearCachesAndReload() {
  if ("serviceWorker" in navigator) {
    serviceWorkerRegistration.unregister();
    caches
      .keys()
      .then((cacheNames) => {
        cacheNames.forEach((cacheName) => {
          caches.delete(cacheName);
        });
      })
      .then(() => {
        localStorage.setItem("ARES_LAST_CLEAN_LOAD", +new Date());
      })
      .finally(() => {
        serviceWorkerRegistration.register();
      });
  }
  setTimeout(() => {
    window.location.reload();
  }, 300);
}
export function logClientEvent(event, lastNMinutes = 2) {
  const date = new Date();
  const _eventLog = localStorage.getItem("ARES_CLIENT_EVENT_LOG") || JSON.stringify([]);
  const eventLog = JSON.parse(_eventLog);
  const tabId = sessionStorage.getItem("ARES_TAB_ID") || "NA";
  eventLog.push({ tabId, ts: date.getTime(), event });
  const nMinAgo = new Date();
  nMinAgo.setMinutes(nMinAgo.getMinutes() - lastNMinutes);
  const updatedEventLog = eventLog.filter((e) => +e.ts > nMinAgo.getTime());
  localStorage.setItem("ARES_CLIENT_EVENT_LOG", JSON.stringify(updatedEventLog));
}
export function exportTextFile(filename, text) {
  const element = document.createElement("a");
  element.setAttribute("href", `data:text/plain;charset=utf-8,${encodeURIComponent(text)}`);
  element.setAttribute("download", filename);

  element.style.display = "none";
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}
export function isVisitTypeMedical(visitType) {
  return window.store.getState().lookups.visitTypes.find((vt) => vt.value === visitType).medical;
}
export function showModal(modal, props) {
  window.store.dispatch(_showModal({ type: modal, props }));
}
export function hideModal() {
  window.store.dispatch(_hideModal());
}
export function arrayHasSomeUndefined(arr) {
  return arr.some((i) => i === undefined);
}
