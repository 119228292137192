import React, { Component } from "react";
import { Button } from "semantic-ui-react";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as modalActions from "../../../../actions/modalActions";
import * as lookupActions from "../../../../actions/lookupActions";
import { roleGroups } from "../../../../constants/securityRoles";
import { checkRoles } from "../../../../helpers";
import { NKDA_ALLERGY_KEY, NKDA_REACTION_TYPE } from "../../../../constants/miscellaneous";

import AllergyTable from "./AllergyTable";
import AllergySlider from "./AllergySlider";

import "../TableSliderComponent.css";

export class ManageAllergies extends Component {
  state = {
    sliderVisible: null,
    searchValue: "",
    allergies: []
  };

  componentDidMount() {
    this.props.actions.loadAllergies();
  }

  handleModalOpen = () => {
    this.setState(
      {
        sliderVisible: false
      },
      () =>
        this.props.actions.showModal({
          type: "SEARCH_ALLERGY",
          props: { open: true, searchValue: this.state.searchValue }
        })
    );
  };

  handleQuickSearch = (value) => {
    const {
      actions: { showModal }
    } = this.props;

    showModal({
      type: "ADD_ALLERGY",
      props: {
        open: true,
        allergyHistory: {
          allergySource: value.description,
          allergyKey: value.allergyKey,
          ...(value.allergyKey === NKDA_ALLERGY_KEY && { reactionType: NKDA_REACTION_TYPE }) // N/A if NKDA
        }
      }
    });
  };

  sliderOpen = () => {
    this.setState({
      sliderVisible: true
    });
  };

  sliderClose = () => {
    this.setState({
      sliderVisible: false
    });
  };

  handleSearchInput = (e, data) => {
    this.setState({ searchValue: data.value });
  };

  handleSearchEnter = (e) => {
    if (e.key === "Enter") {
      this.handleModalOpen();
    }
  };

  handleSearchClick = () => {
    this.handleModalOpen();
  };

  render() {
    const { authRoles, allergies } = this.props;
    const { sliderVisible } = this.state;
    const showAddHistory = checkRoles(roleGroups.excludeHelpDesk, authRoles);
    return (
      <div className="table-slider-container">
        <h2>Allergies</h2>
        {showAddHistory && (
          <Button
            icon="plus"
            className="slider-open transparent-button-icon"
            onClick={this.sliderOpen}
            id="btn-openAllergy"
          />
        )}
        <AllergyTable allergyHistory={this.props.allergyHistory} />
        <AllergySlider
          allergies={allergies}
          sliderVisible={sliderVisible}
          sliderClose={this.sliderClose}
          handleQuickSearch={this.handleQuickSearch}
          handleModalOpen={this.handleModalOpen}
          handleSearchEnter={this.handleSearchEnter}
          handleSearchInput={this.handleSearchInput}
          handleSearchClick={this.handleSearchClick}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    authRoles: state.auth.user.profile.roles,
    allergies: state.lookups.allergies,
    patientId: state.patient.currentPatient.patientId
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...modalActions, ...lookupActions }, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageAllergies);
