import React, { Component } from "react";
import { Grid, Segment, Header, Divider, Table } from "semantic-ui-react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import shortid from "shortid";
import { Button, Icon } from "../../../ui";
import * as modalActions from "../../../actions/modalActions";
import * as demographicActions from "../../../actions/demographicActions";
import * as patientHistoryActions from "../../../actions/patientHistoryActions";
import * as insuranceActions from "../../../actions/insuranceActions";
import * as lookupActions from "../../../actions/lookupActions";
import PatientAddressRow from "./addresses/PatientAddressRow";
import PatientPhoneNumberRow from "./PatientPhoneNumberRow";
import PatientEmailRow from "./PatientEmailRow";
import PatientEmContactRow from "./PatientEmContactRow";
import DemographicsAccordion from "./accordion/DemographicsAccordion";
import SecondaryDemographicsCard from "./SecondaryDemographicsCard";
import InsuranceAccordion from "./accordion/InsuranceAccordion";
import { roleGroups } from "../../../constants/securityRoles";
import { getLookupText, checkRoles, patientName } from "../../../helpers";
import "./ViewPatientDemographics.css";

export class ViewPatientDemographics extends Component {
  state = {
    viewSsnIsClicked: false,
    coreDemographics: {
      patientFirstName: "",
      patientLastName: "",
      patientDateOfBirth: "",
      patientGender: "",
      genderIdentity: "",
      race: "",
      ethnicity: "",
      language: "",
      socialSecurityNumber: "",
      driversLicenseNumber: "",
      patientPreferredName: "",
      auxiliaryFlags: [],
      ptType: "",
      homeCenterId: "",
      communicationPreference: null,
      genderPronoun: "",
      sexAssignedAtBirth: "",
      sexualOrientation: ""
    }
  };

  componentDidMount() {
    this._isMounted = true;
    const {
      patientId,
      actions: {
        loadCommunicationPreferences,
        loadLanguages,
        loadTreatments,
        loadRaces,
        loadEthnicities,
        loadAuxFlags,
        loadRelationships,
        loadGenderPronouns,
        loadGenderIdentities,
        loadSexAssignedAtBirths,
        loadSexualOrientations,
        loadPatientDemo,
        loadPatientInsurance,
        loadPatientHist,
        loadHistoricalAddress,
        clearUnmaskedSocialSecurityNumber
      }
    } = this.props;
    if (this._isMounted) {
      loadCommunicationPreferences();
      loadLanguages();
      loadTreatments();
      loadRaces();
      loadEthnicities();
      loadAuxFlags();
      loadRelationships();
      loadGenderPronouns();
      loadGenderIdentities();
      loadSexAssignedAtBirths();
      loadSexualOrientations();
    }
    if (patientId) {
      clearUnmaskedSocialSecurityNumber(patientId);
      loadPatientDemo(patientId)
        .then(() => {
          if (this._isMounted) return loadPatientInsurance(patientId);
          return Promise.resolve(this._isMounted);
        })
        .then(() => {
          if (this._isMounted) return loadPatientHist(patientId);
          return Promise.resolve(this._isMounted);
        })
        .then(() => {
          if (this._isMounted) return loadHistoricalAddress(patientId);
          return Promise.resolve(this._isMounted);
        });
    }
  }

  // Update patient's main clinic in secondary demo card after creating new visit
  // while choosing different center
  componentDidUpdate(prevProps) {
    const { patientId, demographics } = this.props;
    if (this.props.contextVisit && prevProps.contextVisit) {
      if (this.props.contextVisit.visitId !== prevProps.contextVisit.visitId) {
        this.props.actions.loadPatientHomeCenter(patientId);
      }
    } else if (this.props.contextVisit && !prevProps.contextVisit) {
      if (this.props.contextVisit.visitId) {
        this.props.actions.loadPatientHomeCenter(patientId);
      }
    }
    if (patientId && patientId !== prevProps.patientId) {
      this.props.actions.clearUnmaskedSocialSecurityNumber(patientId);
    }
    if (patientId && demographics.status !== prevProps.demographics.status) {
      this.props.actions.loadPatientDemo(patientId);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  _isMounted = false;

  handleAddAddress = () =>
    this.props.actions.showModal({
      type: "EDIT_ADDRESS_MODAL",
      props: { open: true, patientId: this.props.patientId }
    });

  handleAddEmail = () =>
    this.props.actions.showModal({
      type: "EDIT_EMAIL_ADDRESS",
      props: { open: true, isEdit: false }
    });

  handleAddEmContact = () =>
    this.props.actions.showModal({
      type: "EDIT_ASSOCIATED_CONTACT",
      props: { open: true, isEdit: false }
    });

  handleAddPhoneNumber = () =>
    this.props.actions.showModal({
      type: "EDIT_PHONE_NUMBER",
      props: { open: true, isEdit: false }
    });

  handleEditAddress = (address) => {
    this.props.actions.showModal({
      type: "EDIT_ADDRESS_MODAL",
      props: {
        open: true,
        patientId: this.props.patientId,
        address,
        isEdit: true
      }
    });
  };

  handleDeleteAddress = (address) => {
    this.props.actions.deleteAddress(this.props.patientId, address).then(() => {
      this.props.actions.loadPatientSummary(this.props.patientId);
    });
  };

  handleHistoricalAddressModalOpen = () => {
    this.props.actions.showModal({
      type: "HISTORY_ADDRESS_MODAL",
      props: {
        open: true
      }
    });
  };

  handleViewSsnMouseDown = () => {
    this.setState({ viewSsnIsClicked: true });
    if (this.props.demographics.unmaskedSocialSecurityNumber) {
      this.props.actions.unmaskSsn(this.props.patientId);
    } else {
      this.props.actions.fetchUnmaskedSsn(this.props.patientId).then(() => {
        if (this.state.viewSsnIsClicked) this.props.actions.unmaskSsn(this.props.patientId);
      });
    }
  };

  handleViewSsnMouseUporOut = () => {
    this.setState({ viewSsnIsClicked: false });
    this.props.actions.maskSsn(this.props.patientId);
  };

  handleEditCore = () =>
    this.props.actions.showModal({
      type: "CORE_DEMOGRAPHICS",
      props: { open: true }
    });

  handleEditMainPrescriber = () =>
    this.props.actions.showModal({
      type: "EDIT_MAIN_PRESCRIBER",
      props: { open: true }
    });

  handleEditMainCenter = () =>
    this.props.actions.showModal({
      type: "EDIT_MAIN_CENTER",
      props: { open: true }
    });

  handlePrescriberHistory = () =>
    this.props.actions.showModal({
      type: "MAIN_PRESCRIBER_HISTORY",
      props: { open: true }
    });

  handleEmailEditClick = (emailIndex) => {
    const {
      demographics: { associatedEmailAddresses: addresses }
    } = this.props;
    const email = addresses[emailIndex];
    return this.props.actions.showModal({
      type: "EDIT_EMAIL_ADDRESS",
      props: { open: true, isEdit: true, email }
    });
  };

  handleEmContactEditClick = (e, data) => {
    const {
      demographics: { associatedContacts: contacts }
    } = this.props;
    const currentContact = contacts[data.contactindex];
    return this.props.actions.showModal({
      type: "EDIT_ASSOCIATED_CONTACT",
      props: { open: true, isEdit: true, currentContact }
    });
  };

  handlePhoneEditClick = (phoneNumberId) => {
    const {
      demographics: { associatedPhoneNumbers: phoneNumbers }
    } = this.props;
    const currentPhone = phoneNumbers.find((p) => p.phoneNumberId === phoneNumberId);
    return this.props.actions.showModal({
      type: "EDIT_PHONE_NUMBER",
      props: { open: true, isEdit: true, currentPhone }
    });
  };

  render() {
    const {
      demographics: {
        patientFirstName,
        patientLastName,
        suffix,
        gender,
        genderIdentity,
        genderIdentityOther,
        patientPreferredName,
        patientMiddleName,
        race,
        ethnicity,
        patientDateOfBirth,
        driversLicenseNumber,
        socialSecurityNumber,
        language,
        patientTreatmentType,
        associatedAddresses: addresses,
        associatedEmailAddresses: emails,
        associatedPhoneNumbers: phoneNumbers,
        associatedContacts: emContacts,
        auxiliaryFlags,
        communicationPreference,
        prescribingProvider: mainPrescriber,
        homeCenterId,
        primaryCarePhysician,
        genderPronoun,
        sexAssignedAtBirth,
        sexualOrientation,
        sexualOrientationOther,
        status
      },
      races,
      languages,
      treatments,
      auxFlags,
      ethnicities,
      communicationPreferences,
      relationships,
      centers,
      authRoles,
      genderPronouns,
      sexAssignedAtBirths,
      sexualOrientations,
      genderIdentities,
      historicalAddresses,
      contextVisit,
      processing
    } = this.props;
    const showViewSsn = true;
    const showDemoEdit = checkRoles(roleGroups.excludeHelpDesk, authRoles);
    const canAddDemographicsAitem = checkRoles(roleGroups.systemAdmin, authRoles);
    const showDischarge = checkRoles(roleGroups.providers, authRoles);
    return (
      <div className="content-wrapper">
        <Grid doubling stackable>
          <Grid.Row columns={3}>
            <Grid.Column mobile={16} tablet={16} computer={6}>
              <Segment className="demographic">
                <Header as="h3" id="lbl-patientName">
                  {patientName(patientFirstName, patientPreferredName, patientMiddleName, patientLastName, suffix)}
                </Header>
                <Divider clearing horizontal>
                  Identification
                </Divider>
                <Table basic="very">
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell>
                        <Icon name="file-person" className="display-1 text-secondary" />
                      </Table.Cell>
                      <Table.Cell>
                        <div id="lbl-sex">
                          <span className="grey">Sex: </span>
                          <span className="sp-view-demo">{gender}</span>
                        </div>
                        <div id="lbl-identity">
                          <span className="grey">Identity: </span>
                          <span className="sp-view-demo">{getLookupText(genderIdentity, genderIdentities)}</span>
                          <div>{genderIdentityOther}</div>
                        </div>
                        <div id="lbl-pronoun">
                          <span className="grey">Pronoun: </span>
                          <span className="sp-view-demo">{getLookupText(genderPronoun, genderPronouns)}</span>
                        </div>
                        <div id="lbl-birth">
                          <span className="grey">Birth: </span>
                          <span className="sp-view-demo">{getLookupText(sexAssignedAtBirth, sexAssignedAtBirths)}</span>
                        </div>
                        <div id="lbl-sexorientation">
                          <span className="grey">SO: </span>
                          <span className="sp-view-demo">{getLookupText(sexualOrientation, sexualOrientations)}</span>
                          <div>{sexualOrientationOther}</div>
                        </div>
                        <div id="lbl-race">
                          <span className="grey">Race: </span>
                          <span className="sp-view-demo">{getLookupText(race, races)}</span>
                        </div>
                        <div id="lbl-ethnicity">
                          <span className="grey">Ethnicity: </span>
                          <span className="sp-view-demo">{getLookupText(ethnicity, ethnicities)}</span>
                        </div>
                      </Table.Cell>
                      <Table.Cell align="right" colSpan="2">
                        <div id="lbl-dateOfBirth">
                          <span className="grey">DOB: </span>
                          {patientDateOfBirth}
                        </div>
                        <div id="lbl-socialSecurityNumber">
                          <span className="grey">SS#: </span>
                          <span style={{ whiteSpace: "nowrap" }}>
                            {socialSecurityNumber ? socialSecurityNumber.replace(/X/g, "#") : null}
                            {` `}
                            {showViewSsn && (
                              <Button
                                variant="outline-primary"
                                size="sm"
                                onMouseDown={this.handleViewSsnMouseDown}
                                onMouseUp={this.handleViewSsnMouseUporOut}
                                onMouseOut={this.handleViewSsnMouseUporOut}
                                title="Show"
                                className="rounded-circle"
                                style={{ marginTop: "-.5rem" }}
                              >
                                <Icon name="eye" />
                              </Button>
                            )}
                          </span>
                        </div>
                        {driversLicenseNumber && (
                          <div id="lbl-driversLicenseNumber">
                            <span className="grey">DL#: </span>
                            {driversLicenseNumber
                              ? `#####${driversLicenseNumber.substr(driversLicenseNumber.length - 4)}`
                              : null}
                          </div>
                        )}
                      </Table.Cell>
                      <Table.Cell collapsing className="no-padding">
                        {showDemoEdit && (
                          <Button variant="outline-primary" size="sm" onClick={this.handleEditCore} title="Edit">
                            <Icon name="pencil-square" />
                          </Button>
                        )}
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell className="noborder_" colSpan="2">
                        <Header as="h4">
                          <div id="lbl-communicationPreference">
                            {/* eslint-disable-next-line jsx-a11y/label-has-for */}
                            <label>Preferred Communication</label>
                            {getLookupText(communicationPreference, communicationPreferences) || "None"}
                          </div>
                        </Header>
                      </Table.Cell>
                      <Table.Cell className="noborder_">
                        <Header as="h4">
                          <div id="lbl-language">
                            {/* eslint-disable-next-line jsx-a11y/label-has-for */}
                            <label>Language</label>
                            {getLookupText(language, languages)}
                          </div>
                        </Header>
                      </Table.Cell>
                      <Table.Cell className="noborder_" />
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell className="noborder_" colSpan="2">
                        <Header as="h4">
                          <div id="lbl-patientTreatmentType">
                            {/* eslint-disable-next-line jsx-a11y/label-has-for */}
                            <label>PT Type</label>
                            {getLookupText(patientTreatmentType, treatments)}
                          </div>
                        </Header>
                      </Table.Cell>
                      <Table.Cell className="noborder_">
                        <Header as="h4" width={2}>
                          <div id="lbl-auxiliaryFlags">
                            <label>Auxiliary Flags</label>
                            {(auxiliaryFlags || []).length
                              ? auxiliaryFlags.map((auxiliaryFlag) => (
                                  <div key={`rand-${shortid.generate()}`}>{getLookupText(auxiliaryFlag, auxFlags)}</div>
                                ))
                              : "None"}
                          </div>
                        </Header>
                      </Table.Cell>
                      <Table.Cell className="noborder_" />
                    </Table.Row>
                  </Table.Body>
                </Table>
                <Divider horizontal>{(addresses || []).length <= 1 ? "Current Address" : "Current Addresses"}</Divider>

                <div className="demographics-button-container" style={{ borderBottom: "1px solid rgba(34,36,38,.15)" }}>
                  {(historicalAddresses || []).length ? (
                    <Button
                      variant="outline-primary"
                      size="sm"
                      onClick={this.handleHistoricalAddressModalOpen}
                      title="Address History"
                      className="rounded-circle"
                    >
                      <Icon name="clock-history" />
                    </Button>
                  ) : null}
                  {showDemoEdit && canAddDemographicsAitem && (
                    <Button
                      variant="outline-primary"
                      size="sm"
                      onClick={this.handleAddAddress}
                      title="Add Address"
                      className="rounded-circle float-end"
                    >
                      <Icon name="plus-lg" />
                    </Button>
                  )}
                </div>

                <Table basic="very" className="align-middle table table-sm">
                  <Table.Body className="patient-address-row">
                    {(addresses || []).length
                      ? addresses.map((address, i) => (
                          <PatientAddressRow
                            key={address.addressId}
                            index={i}
                            address={address}
                            handleEditAddress={this.handleEditAddress}
                            handleDeleteAddress={this.handleDeleteAddress}
                            processing={processing}
                          />
                        ))
                      : null}
                  </Table.Body>
                </Table>

                <Divider horizontal>Email</Divider>
                <Table basic="very">
                  <Table.Body>
                    {(emails || []).length
                      ? emails.map((email, i) => (
                          <PatientEmailRow
                            key={email.emailAddressId}
                            email={email}
                            emailIndex={i}
                            handleEmailEditClick={this.handleEmailEditClick}
                            showDemoEdit={showDemoEdit}
                          />
                        ))
                      : null}
                  </Table.Body>
                </Table>
                <div className="demographics-button-container">
                  {showDemoEdit && canAddDemographicsAitem && (
                    <Button
                      variant="outline-primary"
                      size="sm"
                      onClick={this.handleAddEmail}
                      title="Add Email"
                      className="rounded-circle"
                    >
                      <Icon name="plus-lg" />
                    </Button>
                  )}
                </div>

                <Divider horizontal>{(phoneNumbers || []).length <= 1 ? "Phone Number" : "Phone Numbers"}</Divider>
                <Table basic="very">
                  <Table.Body>
                    {(phoneNumbers || []).length
                      ? phoneNumbers.map((phone) => (
                          <PatientPhoneNumberRow
                            key={phone.phoneNumberId}
                            phone={phone}
                            handlePhoneEditClick={this.handlePhoneEditClick}
                            showDemoEdit={showDemoEdit}
                            authRoles={authRoles}
                          />
                        ))
                      : null}
                  </Table.Body>
                </Table>
                <div className="demographics-button-container">
                  {false && showDemoEdit && canAddDemographicsAitem && (
                    <Button
                      variant="outline-primary"
                      size="sm"
                      onClick={this.handleAddPhoneNumber}
                      title="Add Phone Number"
                      className="rounded-circle"
                    >
                      <Icon name="plus-lg" />
                    </Button>
                  )}
                </div>

                <Divider horizontal>
                  {(emContacts || []).length <= 1 ? "Emergency Contact" : "Emergency Contacts"}
                </Divider>
                <Table basic="very" className="emContacts">
                  <Table.Body>
                    {(emContacts || []).length
                      ? emContacts.map((emContact, i) => (
                          <PatientEmContactRow
                            key={emContact.personId}
                            emContact={emContact}
                            relationText={getLookupText(emContact.relationship, relationships)}
                            contactindex={i}
                            handleEmContactEditClick={this.handleEmContactEditClick}
                            showDemoEdit={showDemoEdit}
                          />
                        ))
                      : null}
                  </Table.Body>
                </Table>
                <div className="demographics-button-container">
                  {showDemoEdit && canAddDemographicsAitem && (
                    <Button
                      variant="outline-primary"
                      size="sm"
                      onClick={this.handleAddEmContact}
                      title="Add Emergency Contacts"
                      className="rounded-circle"
                    >
                      <Icon name="plus-lg" />
                    </Button>
                  )}
                </div>
              </Segment>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={16} computer={3}>
              <SecondaryDemographicsCard
                handleEditMainPrescriber={this.handleEditMainPrescriber}
                handleEditMainCenter={this.handleEditMainCenter}
                mainPrescriber={mainPrescriber}
                handlePrescriberHistory={this.handlePrescriberHistory}
                homeCenterId={homeCenterId}
                centers={centers}
                showDemoEdit={showDemoEdit}
                contextVisit={contextVisit}
                status={status}
              />
            </Grid.Column>
            <Grid.Column mobile={16} tablet={16} computer={7}>
              <DemographicsAccordion
                primaryCarePhysician={primaryCarePhysician}
                showDischarge={showDischarge}
                status={status}
              />
              <InsuranceAccordion />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}

function mapStateToProps(state) {
  let demographics = {
    patientId: "",
    patientFirstName: "",
    patientLastName: "",
    patientDateOfBirth: "",
    associatedAddresses: [],
    associatedPhoneNumbers: [],
    associatedEmailAddresses: [],
    associatedContacts: [],
    primaryCarePhysician: ""
  };

  if (state.demographics) {
    demographics = state.demographics;
  }

  return {
    patientId: state.patient.currentPatient.patientId,
    demographics,
    races: state.lookups.races,
    languages: state.lookups.languages,
    treatments: state.lookups.treatments,
    auxFlags: state.lookups.auxFlags,
    ethnicities: state.lookups.ethnicities,
    communicationPreferences: state.lookups.communicationPreferences,
    relationships: state.lookups.relationships,
    centers: state.lookups.centers,
    authRoles: state.auth.user.profile.roles,
    genderPronouns: state.lookups.genderPronouns,
    genderIdentities: state.lookups.genderIdentities,
    sexAssignedAtBirths: state.lookups.sexAssignedAtBirths,
    sexualOrientations: state.lookups.sexualOrientations,
    historicalAddresses: state.patientHistory.historicalAddresses,
    contextVisit: state.visits.contextVisit,
    processing: state.ajaxCallsInProgress > 0
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...modalActions,
        ...demographicActions,
        ...patientHistoryActions,
        ...insuranceActions,
        ...lookupActions
      },
      dispatch
    )
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewPatientDemographics);
