import { API_URL } from "../constants/environment";
import { adalApiFetch } from "../adalConfig";

export const saveAttestation = (patientId, prescriptionId, payload) =>
  adalApiFetch(`${API_URL}/Prescriptions/${prescriptionId}/Patients/${patientId}/Attestations`, {
    method: "POST",
    body: JSON.stringify(payload)
  });

export const savePrescriptionPrescriber = (prescriptionId, payload) =>
  adalApiFetch(`${API_URL}/Prescriptions/${prescriptionId}/Medication`, {
    method: "PATCH",
    body: JSON.stringify(payload)
  });

export const generatePrescriptionDocuments = (patientId, prescriptionIds, payload) =>
  adalApiFetch(`${API_URL}/Prescriptions/Patients/${patientId}/DocumentToPrint?${prescriptionIds}`, {
    method: "POST",
    body: JSON.stringify(payload)
  });

export const getPrescriptionDocuments = prescriptionIds =>
  adalApiFetch(`${API_URL}/Prescriptions/ToPrint/DocumentMetadata?prescriptionIds=${prescriptionIds}`);

export const updatePrescription = prescription =>
  adalApiFetch(`${API_URL}/Prescriptions/${prescription.prescriptionId}/Medication`, {
    method: "PUT",
    body: JSON.stringify(prescription)
  });

export const getPrescriptionsSentByVisit = visitId => adalApiFetch(`${API_URL}/Prescriptions/Visits/${visitId}`);

export const signPrescription = (patientId, prescriptionId, payload) =>
  adalApiFetch(`${API_URL}/Prescriptions/${prescriptionId}/Patients/${patientId}/Sign`, {
    method: "PUT",
    body: JSON.stringify(payload)
  });

export const getPrescriptions = prescriptionIds => {
  const queryParams = prescriptionIds.map(prescriptionId => `prescriptionIds=${prescriptionId}`).join("&");
  return adalApiFetch(`${API_URL}/Prescriptions?${queryParams}`);
};

export const checkBuprenorphinePrescriptionQueued = (patientId, visitId) =>
  adalApiFetch(`${API_URL}/Prescriptions/Patients/${patientId}/HasQueuedPrescription/Visits/${visitId}`);

export default {
  saveAttestation,
  savePrescriptionPrescriber,
  generatePrescriptionDocuments,
  getPrescriptionDocuments,
  updatePrescription,
  getPrescriptionsSentByVisit,
  signPrescription,
  getPrescriptions,
  checkBuprenorphinePrescriptionQueued
};
