import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import { isEmpty, set } from "lodash";
import { Dropdown, Form, Grid, Icon } from "semantic-ui-react";
import { DateInput } from "semantic-ui-calendar-react";
import Report from "./Report";
import * as lookupActions from "../../actions/lookupActions";
import * as providerActions from "../../actions/providerActions";
import { dateFormat, dateWithTimeFormat, isoDateFormat, isoFormat } from "../../constants/miscellaneous";
import { roleGroups } from "../../constants/securityRoles";
import { checkRoles } from "../../helpers";

export class ExostarAuditLog extends React.Component {
  state = {
    fetchCounter: 0,
    filter: {
      createdFrom: moment()
        .startOf("day")
        .format(isoDateFormat),
      createdTo: moment()
        .endOf("day")
        .format(isoDateFormat),
      providerIds: [],
      actionTypes: [],
      medicalRecordNumber: ""
    }
  };

  componentDidMount() {
    this.props.actions.loadProvidersByState();
    this.props.actions.loadExostarActionTypes();
  }

  handleInput = (e, data) => {
    const { name: field } = data;
    let { value } = data;
    const newState = { ...this.state };
    if (field === "filter.createdFrom") {
      value = isEmpty(value)
        ? ``
        : `${moment(value, dateFormat)
            .startOf("day")
            .format(isoFormat)}`;
    }
    if (field === "filter.createdTo") {
      value = isEmpty(value)
        ? ``
        : `${moment(value, dateFormat)
            .endOf("day")
            .format(isoFormat)}`;
    }
    set(newState, field, value);
    this.setState(newState);
  };

  beforeFetch = filter => ({
    ...filter,
    createdFrom: isEmpty(this.state.filter.createdFrom)
      ? ``
      : moment(this.state.filter.createdFrom)
          .startOf("day")
          .format(isoFormat),
    createdTo: isEmpty(this.state.filter.createdTo)
      ? ``
      : moment(this.state.filter.createdTo)
          .endOf("day")
          .format(isoFormat)
  });

  refetch = () => {
    this.setState({ fetchCounter: this.state.fetchCounter + 1 });
  };

  renderFilterForm = () => {
    const { authRoles, actionTypeOptions, providerOptions } = this.props;
    const { filter } = this.state;
    const fullView = checkRoles(roleGroups.providerAuditReportFullView, authRoles);
    const disableFilterButton = isEmpty(filter.createdFrom) || isEmpty(filter.createdTo);

    return (
      <Grid.Column width={16}>
        <Form>
          <Grid>
            <Grid.Row style={{ paddingBottom: "0" }}>
              <Grid.Column width={1} />
              <Grid.Column width={2}>
                Date From <strong style={{ color: "#e74c3c" }}>*</strong>
                <br />
                <Form.Group widths="equal">
                  <DateInput
                    size="mini"
                    name="filter.createdFrom"
                    placeholder="From"
                    value={isEmpty(filter.createdFrom) ? `` : moment(filter.createdFrom).format(dateFormat)}
                    dateFormat={dateFormat}
                    iconPosition="right"
                    onChange={this.handleInput}
                    hideMobileKeyboard
                    closable
                  />
                </Form.Group>
              </Grid.Column>
              <Grid.Column width={2}>
                Date To <strong style={{ color: "#e74c3c" }}>*</strong>
                <br />
                <Form.Group widths="equal">
                  <DateInput
                    size="mini"
                    name="filter.createdTo"
                    placeholder="To"
                    value={isEmpty(filter.createdTo) ? `` : moment(filter.createdTo).format(dateFormat)}
                    dateFormat={dateFormat}
                    iconPosition="right"
                    onChange={this.handleInput}
                    hideMobileKeyboard
                    closable
                  />
                </Form.Group>
              </Grid.Column>
              <Grid.Column width={3}>
                Action Type
                <br />
                <Form.Group widths="equal" style={{ margin: "0 0 1em" }}>
                  <Dropdown
                    name="filter.actionTypes"
                    options={actionTypeOptions}
                    onChange={this.handleInput}
                    value={filter.actionTypes}
                    className="mini"
                    fluid
                    multiple
                    selection
                    search
                  />
                </Form.Group>
              </Grid.Column>
              {fullView && (
                <Grid.Column width={3}>
                  Provider
                  <br />
                  <Form.Group widths="equal" style={{ margin: "0 0 1em" }}>
                    <Dropdown
                      name="filter.providerIds"
                      options={providerOptions}
                      onChange={this.handleInput}
                      value={filter.providerIds}
                      className="mini"
                      fluid
                      multiple
                      selection
                      search
                    />
                  </Form.Group>
                </Grid.Column>
              )}
              <Grid.Column width={3}>
                MRN
                <br />
                <Form.Group widths="equal" style={{ margin: "0 0 1em" }}>
                  <Form.Input
                    type="text"
                    name="filter.medicalRecordNumber"
                    onChange={this.handleInput}
                    value={filter.medicalRecordNumber}
                    size="mini"
                  />
                </Form.Group>
              </Grid.Column>
              <Grid.Column width={2}>
                <br />
                <Form.Button size="mini" color="blue" onClick={this.refetch} disabled={disableFilterButton}>
                  Filter
                  <Icon name="search" style={{ marginLeft: "5px" }} />
                </Form.Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
      </Grid.Column>
    );
  };

  render() {
    const { authRoles } = this.props;
    const providerView = !checkRoles(roleGroups.providerAuditReportFullView, authRoles);
    return (
      <Report
        endPoint="ExostarAuditLog"
        fetchCounter={this.state.fetchCounter}
        header="Provider Audit Report"
        rowKeyField="id"
        filterObject={this.state.filter}
        sortColumn="createdDate"
        sortDirection="descending"
        renderFilterForm={this.renderFilterForm}
        pagination={false}
        beforeFetch={this.beforeFetch}
        fields={[
          { name: "id", visible: false },
          { name: "userId", visible: false },
          { name: "providerName", label: "Provider", sortable: false, visible: !providerView },
          { name: "actionType", label: "Action Type" },
          { name: "actionDescription", label: "Action Description", sortable: false },
          { name: "doneBy", label: "Action By", sortable: false },
          { name: "medicalRecordNumber", label: "MRN", sortable: true },
          {
            name: "createdDate",
            label: "Create Date",
            render: row => moment(row.createdDate).format(dateWithTimeFormat)
          }
        ]}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    authRoles: state.auth.user.profile.roles,
    providerOptions: state.providers.allProviders.map(i => ({ text: `${i.firstName} ${i.lastName}`, value: i.userId })),
    actionTypeOptions: state.lookups.exostarActionTypes.map(i => ({ text: i, value: i }))
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...lookupActions, ...providerActions }, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ExostarAuditLog);
