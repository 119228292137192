import React, { Component } from "react";
import { Table, Dropdown, Button } from "semantic-ui-react";
import { Link } from "react-router-dom";
import moment from "moment";
import _ from "lodash";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as visitActions from "../../../actions/visitActions";
import * as lookupActions from "../../../actions/lookupActions";
import * as modalActions from "../../../actions/modalActions";
import * as providerActions from "../../../actions/providerActions";
import { getLookupText, isVisitTypeMedical } from "../../../helpers";
import { dateWithTimeFormat } from "../../../constants/miscellaneous";
import "./StatusWatchPage.css";
import { PatientLevel } from "../../../ui";

export class AllStatusTable extends Component {
  state = {
    fetching: false,
    column: null,
    direction: null,
    selectedRow: "",
    selectedVisitId: "",
    visitsToLoad: 100,
    currentPage: 1,
    showLoadButton: false
  };

  componentDidMount() {
    this.props.actions.loadVisitStatuses();
    this.props.actions.loadProviders();
    this.fetch();
  }

  componentDidUpdate(prevProps) {
    const {
      refetch,
      resetRefetch,
      centerContext,
      allCenterVisits: { dataVisits, totalVisits }
    } = this.props;

    if (prevProps.centerContext !== centerContext) {
      this.setState({ visitsToLoad: 100 }, () => {
        this.fetch();
      });
    }

    if (refetch && !prevProps.refetch) {
      resetRefetch();
      this.fetch();
    }

    if (
      dataVisits.length !== prevProps.allCenterVisits.dataVisits.length ||
      totalVisits !== prevProps.allCenterVisits.totalVisits
    ) {
      if (dataVisits.length < totalVisits) {
        this.setState({ showLoadButton: true });
      } else {
        this.setState({ showLoadButton: false });
      }
    }
  }

  getProvider = (id) => {
    const provider = _.find(this.props.providers, _.matchesProperty("userId", id));
    return provider;
  };

  loadMoreVisits = () => {
    let { visitsToLoad } = this.state;
    visitsToLoad += 50;
    this.setState({ visitsToLoad }, () => {
      this.fetch();
    });
  };

  fetch() {
    const { centerContext } = this.props;

    const { fetching, currentPage, visitsToLoad } = this.state;

    if (fetching) return;

    this.setState({ fetching: true });
    this.props.actions
      .loadAllCenterVisits(centerContext, currentPage, visitsToLoad)
      .then(() => {
        this.setState({ visitsToLoad: this.props.allCenterVisits.totalVisits });
        if (this.state.column) this.handleSort(this.state.column, true);
      })
      .finally(() => {
        this.setState({ fetching: false });
      });
  }

  handleStatusChange = (e, data) => {
    const { value } = data;
    const listPatient = this.props.allCenterVisits.dataVisits;
    const visitIdSelect = this.state.selectedVisitId;

    if (value === "visitcomplete") {
      this.props.actions.showModal({
        type: "CONFIRMATION",
        props: {
          open: true,
          icon: "exclamation-triangle",
          iconColor: "warning",
          title: "Confirm Complete",
          buttonColor: "red",
          description: "Are you sure you want to end the visit?",
          onConfirm: () => {
            this.props.actions.updateVisitStatus(this.state.selectedVisitId, value).then(() => {
              this.fetch();
            });
          }
        }
      });
    }
    const currentItem = listPatient.find((i) => i.visitId === visitIdSelect);
    // check change visit status -> call API
    if (currentItem.visitStatusCode !== value) {
      this.props.actions.updateVisitStatus(visitIdSelect, value).then(() => {
        this.fetch();
      });
    }
  };

  handleRowSelect = (e) => {
    const currentRow = e.currentTarget.getAttribute("data-index");
    const visitId = e.currentTarget.getAttribute("data-visit");
    this.setState({
      selectedRow: currentRow,
      selectedVisitId: visitId
    });
  };

  updateLoadMore = (visits) => {
    const { dataVisits, totalVisits } = visits.allVisitingStatus;
    if (dataVisits.length < totalVisits) {
      this.setState({ showLoadButton: true });
    } else {
      this.setState({ showLoadButton: false });
    }
  };

  handleSort = (clickedColumn, justSort = false) => {
    const { column, direction } = this.state;
    const {
      allCenterVisits: { dataVisits: visits, totalVisits }
    } = this.props;
    let sorted;

    if (clickedColumn === "level") {
      sorted = _.orderBy(visits, (visit) => visit.level, ["asc"]);
    } else {
      sorted = _.orderBy(visits, [clickedColumn], ["asc"]);
    }

    if (justSort) {
      this.props.actions.setAllCenterVisits({
        totalVisits,
        dataVisits: direction === "ascending" ? sorted : sorted.reverse()
      });
      return;
    }

    if (column !== clickedColumn) {
      this.setState({
        column: clickedColumn,
        direction: "ascending"
      });
      this.props.actions.setAllCenterVisits({ totalVisits, dataVisits: sorted });
      return;
    }

    const newDirection = direction === "ascending" ? "descending" : "ascending";
    this.setState({
      direction: newDirection
    });
    this.props.actions.setAllCenterVisits({
      totalVisits,
      dataVisits: newDirection === "ascending" ? sorted : sorted.reverse()
    });
  };

  render() {
    const { column, direction, selectedRow } = this.state;
    const {
      statuses,
      allCenterVisits: { dataVisits: visits }
    } = this.props;
    const { showLoadButton } = this.state;
    return (
      <React.Fragment>
        {visits && visits.length > 0 ? (
          <div>
            <h2>All Visit Status</h2>
            <div className="divAllStatus">
              <Table selectable sortable className="visits-table">
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell
                      sorted={column === "visitTime" ? direction : null}
                      onClick={() => this.handleSort("visitTime")}
                    >
                      Visit Start
                    </Table.HeaderCell>
                    {/* <Table.HeaderCell
                  sorted={column === "statusTime" ? direction : null}
                  onClick={this.handleSort("statusTime")}
                >
                  Status Time
                </Table.HeaderCell> */}
                    <Table.HeaderCell
                      sorted={column === "patientFirstName" ? direction : null}
                      onClick={() => this.handleSort("patientFirstName")}
                    >
                      Patient Name
                    </Table.HeaderCell>
                    <Table.HeaderCell
                      sorted={column === "provider" ? direction : null}
                      onClick={() => this.handleSort("provider")}
                    >
                      Provider
                    </Table.HeaderCell>
                    <Table.HeaderCell
                      sorted={column === "insurance" ? direction : null}
                      onClick={() => this.handleSort("insurance")}
                    >
                      Insurance
                    </Table.HeaderCell>
                    <Table.HeaderCell
                      sorted={column === "mainPrescriber" ? direction : null}
                      onClick={() => this.handleSort("mainPrescriber")}
                    >
                      Main Prescriber
                    </Table.HeaderCell>
                    {/* <Table.HeaderCell
                  sorted={column === "task" ? direction : null}
                  onClick={this.handleSort("task")}
                >
                  Task
                </Table.HeaderCell> */}
                    <Table.HeaderCell
                      sorted={column === "visitStatusCode" ? direction : null}
                      onClick={() => this.handleSort("visitStatusCode")}
                    >
                      Status
                    </Table.HeaderCell>
                    <Table.HeaderCell
                      sorted={column === "visitTypeName" ? direction : null}
                      onClick={() => this.handleSort("visitTypeName")}
                    >
                      Visit Type
                    </Table.HeaderCell>
                    <Table.HeaderCell className="text-center">Level</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {visits.map(
                    (
                      {
                        visitTime,
                        patientFirstName,
                        patientLastName,
                        provider,
                        insurance,
                        mainPrescriber,
                        visitStatusCode,
                        visitType,
                        visitTypeName,
                        level,
                        bhLevel,
                        patientId,
                        visitId
                      },
                      i
                    ) => (
                      <Table.Row
                        key={visitId}
                        data-index={i}
                        data-visit={visitId}
                        onClick={this.handleRowSelect}
                        className={String(i) === selectedRow ? "selected" : ""}
                      >
                        <Table.Cell data-label="Visit Start">
                          <span>{moment(visitTime).format(dateWithTimeFormat)}</span>
                        </Table.Cell>
                        {/* <Table.Cell /> */}
                        <Table.Cell data-label="Patient">
                          <span>
                            <Link
                              to={`/current-patients/${patientId}/demographics`}
                              style={{ textDecoration: "none" }}
                              key={patientId}
                            >
                              {`${patientFirstName} ${patientLastName}`}
                            </Link>
                          </span>
                        </Table.Cell>
                        <Table.Cell data-label="Provider">
                          <span>{provider}</span>
                        </Table.Cell>
                        <Table.Cell data-label="Insurance">
                          <span>{insurance}</span>
                        </Table.Cell>
                        <Table.Cell data-label="Main Prescriber">
                          <span>{mainPrescriber}</span>
                        </Table.Cell>
                        {/* <Table.Cell></Table.Cell> */}
                        <Table.Cell data-label="Status">
                          <span>
                            {String(i) === selectedRow ? (
                              <Dropdown
                                name="visitStatus"
                                placeholder=""
                                fluid
                                selection
                                options={statuses}
                                width={12}
                                onChange={this.handleStatusChange}
                                value={visitStatusCode}
                              />
                            ) : (
                              <span>{getLookupText(visitStatusCode, statuses)}</span>
                            )}
                          </span>
                        </Table.Cell>
                        <Table.Cell data-label="Visit Type">
                          <span>{visitTypeName}</span>
                        </Table.Cell>
                        <Table.Cell data-label="Level" className="text-center">
                          {isVisitTypeMedical(visitType) ? (
                            <PatientLevel level={level || "orange"} size="sm" />
                          ) : (
                            bhLevel && <PatientLevel level={bhLevel} size="sm" bh />
                          )}
                        </Table.Cell>
                      </Table.Row>
                    )
                  )}
                </Table.Body>
                <Table.Footer />
              </Table>
            </div>
            {showLoadButton && (
              <div className="centered">
                <Button color="blue" onClick={this.loadMoreVisits}>
                  Load More
                </Button>
              </div>
            )}
          </div>
        ) : (
          <div className="no-visiting-placeholder">
            There are currently no visiting patients checked in at this time
          </div>
        )}
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const provider = state.providers.allProviders.length ? state.providers.allProviders[0].userId : "";
  return {
    statuses: state.lookups.visitStatuses,
    provider,
    centerContext: state.userPreference.centerContext,
    authRoles: state.auth.user.profile.roles,
    allCenterVisits: state.visits.allCenterVisits
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...visitActions,
        ...lookupActions,
        ...modalActions,
        ...providerActions
      },
      dispatch
    )
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AllStatusTable);
