/* eslint-disable react/no-array-index-key */
import React, { Component } from "react";
import ReactDOM from "react-dom";
import { toast } from "react-toastify";
import { Prompt } from "react-router";
import {
  Button,
  Header,
  Modal,
  Form,
  Grid,
  Message,
  Menu,
  Tab,
  Confirm,
  Icon,
  Segment,
  Label
} from "semantic-ui-react";
import { DateInput } from "semantic-ui-calendar-react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { isEqual, isEmpty, debounce, groupBy } from "lodash";
import emrComponent from "../../common/emrComponent";
import * as modalActions from "../../../actions/modalActions";
import * as templateActions from "../../../actions/templateActions";
import * as treatmentPlanActions from "../../../actions/treatmentPlanActions";
import * as patientActions from "../../../actions/patientActions";
import MultiToggle from "./MultiToggle";
import { toastErrorOptions } from "../../../constants/toastconfig";
import {
  parseLabel,
  isQuestionTriggered,
  getQuestionPaddingLevel,
  initSurvey,
  applyAction,
  findItem,
  getQuestionStringId,
  getQuestionId,
  refreshQuestionOptions,
  refreshRequiredState,
  validateSurveyAnswersDataIntegrity
} from "./surveyHelper";
import "./Survey.css";
import { dateFormat, numbersToText } from "../../../constants/miscellaneous";
import AdvancedSearch from "./AdvancedSearch";

export class Survey extends Component {
  constructor(props) {
    super(props);
    const { id: surveyId, sections } = props.survey;
    this.state = {
      confirm: false,
      surveysVisitId: this.props.surveysVisitId,
      selectedTemplate: { ...this.props.selectedTemplate },
      surveyId,
      shouldBlockNavigation: false,
      enableSaveButton: true,
      activeSectionKey: `${sections[0].key}-1`,
      surveyLayout: { sections: [] },
      surveyData: {},
      initSurveyData: {},
      actions: this.props.survey.templateActions.templateActions.map((a) => ({
        ...a,
        targetKey: JSON.parse(a.targetKey)
      }))
    };
  }

  _closing = false;

  componentDidMount() {
    const { patientId, hiddenSurveys } = this.props;
    const {
      selectedTemplate: { templateId },
      surveyId
    } = this.state;
    this.props.actions.setReInitSurvey(false);
    if (hiddenSurveys[`${patientId}-${templateId}-${surveyId}`]) {
      this.preloadHiddenSurvey();
    } else {
      let surveyLayout = {};
      let surveyData = {};
      [surveyLayout, surveyData] = initSurvey(
        this.props.survey,
        this.props.survey.templateActions.templateActions.map((a) => ({ ...a, targetKey: JSON.parse(a.targetKey) }))
      );
      this.setState({ surveyLayout, surveyData, initSurveyData: { ...surveyData } });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { patientId, hiddenSurveys } = this.props;
    const {
      selectedTemplate: { templateId },
      surveyId,
      shouldBlockNavigation,
      surveyLayout,
      surveyData
    } = this.state;

    if (shouldBlockNavigation) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = undefined;
    }

    // here I am relying on the fact that I am NOT clearing out hiddenSurveys[`${patientId}-${templateId}-${surveyId}`] immediately after preloading the survey
    if (this.props.reInitSurvey && isEmpty(hiddenSurveys[`${patientId}-${templateId}-${surveyId}`])) {
      this.props.actions.setReInitSurvey(false);
      const [_surveyLayout, _surveyData] = initSurvey(
        this.props.survey,
        this.props.survey.templateActions.templateActions.map((a) => ({ ...a, targetKey: JSON.parse(a.targetKey) }))
      );
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ surveyLayout: _surveyLayout, surveyData: _surveyData, initSurveyData: { ..._surveyData } });
    } else if (!isEqual(surveyData, prevState.surveyData)) {
      const _surveyLayout = refreshRequiredState(surveyLayout, surveyData);
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ surveyLayout: _surveyLayout });
    }
    if (this.props.surveysVisitId !== prevProps.surveysVisitId) {
      toast.error(
        <div>
          Error !
          <br />
          Context visit got changed in the middle of filling a survey, Please use the red `Report a problem` button at
          the bottom right corner immediately;
          <br />
          Share the result with support team
        </div>,
        toastErrorOptions
      );
    }
  }

  componentWillUnmount() {
    window.onbeforeunload = undefined;
  }

  preloadHiddenSurvey = () => {
    const {
      patientId,
      hiddenSurveys,
      actions: { hideModal }
    } = this.props;
    const {
      selectedTemplate: { templateId },
      surveyId
    } = this.state;
    const hiddenSurvey = hiddenSurveys[`${patientId}-${templateId}-${surveyId}`];
    if (isEmpty(hiddenSurvey)) return;
    if (
      hiddenSurvey.patientId !== patientId ||
      hiddenSurvey.templateId !== templateId ||
      hiddenSurvey.surveyId !== surveyId
    ) {
      toast.error(`User can't edit this survey while having other unsaved survey`, toastErrorOptions);
      hideModal();
      return;
    }
    this.setState({ surveyLayout: hiddenSurvey.answers.surveyLayout, surveyData: hiddenSurvey.answers.surveyData });
  };

  getElucidationValue = (_question, elucidationKey, triggerCheck) => {
    const elucidation = _question.elucidations.find((e) => e.key === elucidationKey);
    return triggerCheck && elucidation ? elucidation.value : "";
  };

  getQuestionValue = (type, question, option) => {
    const _question = this.state.surveyData[getQuestionStringId(question)];
    switch (type) {
      case "MultiSelect":
        return _question.answers.includes(option);
      case "Radio":
        return option ? _question.answers[0] === option : !!_question.answers.pop();
      case "Dropdown":
      case "Text":
        return _question.answers[0] || "";
      case "Keyword":
        return _question.keyword || "";
      default:
        return "";
    }
  };

  handleClear = () => {
    const {
      patientId,
      actions: { clearHiddenHpSurvey, clearHiddenTreatmentPlanSurvey },
      for: _for
    } = this.props;
    const {
      selectedTemplate: { templateId },
      surveyId
    } = this.state;
    let func = null;
    if (_for === "treatmentPlan") func = clearHiddenTreatmentPlanSurvey;
    if (_for === "templates") func = clearHiddenHpSurvey;
    if (func) func(patientId, templateId, surveyId);
  };

  handleHide = () => {
    const {
      patientId,
      actions: { hideTemplatesSurvey, hideTreatmentPlanSurvey, hideModal },
      for: _for,
      asModal
    } = this.props;
    const {
      selectedTemplate: { templateId },
      surveyId,
      surveyLayout,
      surveyData
    } = this.state;
    let func = null;
    if (_for === "treatmentPlan") func = hideTreatmentPlanSurvey;
    if (_for === "templates") func = hideTemplatesSurvey;
    if (func) func(templateId, surveyId, patientId, { surveyLayout, surveyData });
    if (asModal) hideModal();
  };

  handleClose = (confirm = true, reload = false) => {
    if (confirm) {
      this.setState({ confirm: true });
    } else {
      this._closing = true;
      if (!reload) this.props.actions.setReInitSurvey(true);
      if (reload) this.props.actions.setReloadSelectedTemplate(true);
      if (this.props.hideModal !== false) this.props.actions.hideModal();
      if (this.props.hideModal === false) this.setState({ enableSaveButton: true });
    }
  };

  handleCloseConfirmed = () => {
    const {
      for: _for,
      patientId,
      actions: { clearHiddenHpSurvey, clearHiddenTreatmentPlanSurvey, hideModal }
    } = this.props;
    const {
      selectedTemplate: { templateId },
      surveyId
    } = this.state;
    let func = null;
    if (_for === "treatmentPlan") func = clearHiddenTreatmentPlanSurvey;
    if (_for === "templates") func = clearHiddenHpSurvey;
    if (func) func(patientId, templateId, surveyId);
    this._closing = true;
    this.props.actions.setReInitSurvey(true);
    hideModal();
  };

  createAnswersObject() {
    const {
      surveysVisitId,
      surveyLayout,
      surveyData,
      actions,
      selectedTemplate: { version: templateVersion }
    } = this.state;
    const flatLayout = [].concat(
      ...surveyLayout.sections.map((_s) => [].concat(..._s.groups.map((_g) => _g.questions)))
    );
    const answersToPush = flatLayout
      .filter((_q) => isQuestionTriggered(_q, flatLayout, surveyData))
      .map((question) => {
        const _question = surveyData[getQuestionStringId(question)];
        return {
          visitId: surveysVisitId,
          questionKey: question.key,
          questionCount: question.questionCount,
          sectionKey: question.sectionKey,
          sectionCount: question.sectionCount,
          groupKey: question.groupKey,
          groupCount: question.groupCount,
          questionVersion: question.version,
          answers: _question.answers,
          elucidations: _question.elucidations.filter(
            (e) => !isEmpty(e.value) && (isEmpty(e.key) || _question.answers.includes(e.key))
          )
        };
      });
    return { templateVersion, surveyVersion: surveyLayout.version, answers: answersToPush, templateActions: actions };
  }

  handleSave = async () => {
    const {
      tab,
      patientId,
      handleSave,
      manipulateAnswers,
      contextVisitId,
      contextVisit,
      availableTemplates,
      selectedTemplate,
      actions: { saveSurvey, loadPatientSummary }
    } = this.props;
    const {
      selectedTemplate: { templateId },
      surveyId
    } = this.state;
    let surveyAnswers = this.createAnswersObject();
    this.setState({ enableSaveButton: false });
    if (manipulateAnswers) {
      surveyAnswers = manipulateAnswers(surveyAnswers);
    }
    if (handleSave) {
      handleSave(templateId, surveyId, surveyAnswers)
        .then(() => {
          if (this.props.afterSave) this.props.afterSave();
        })
        .finally(() => {
          this.setState({ enableSaveButton: true });
        });
    } else {
      try {
        validateSurveyAnswersDataIntegrity(
          tab === "discharge" ? null : contextVisitId || contextVisit.visitId,
          availableTemplates,
          selectedTemplate,
          templateId,
          surveyId,
          surveyAnswers
        );
        await saveSurvey(templateId, surveyId, patientId, surveyAnswers).then(() => {
          if (this.props.afterSave) this.props.afterSave();
        });
      } catch (error) {
        toast.error(error.message, toastErrorOptions);
      } finally {
        this.setState({ enableSaveButton: true }, () => {
          loadPatientSummary(this.props.patientId);
          this.handleClose(false, true);
        });
      }
    }
  };

  createComponent = (question, triggered, questionPaddingLevel, uiMetadata) => {
    switch (uiMetadata.component) {
      case "Dropdown":
        return this.createDropdown(question, triggered, questionPaddingLevel);
      case "RadioGroup":
        return this.createSingleSelect(question, triggered, questionPaddingLevel);
      case "DateInput":
        return this.createDateInput(question, triggered, questionPaddingLevel);
      default:
        return "";
    }
  };

  renderQuestion = (question) => {
    const { surveyLayout } = this.state;
    const flatLayout = [].concat(
      ...surveyLayout.sections.map((_s) => [].concat(..._s.groups.map((_g) => _g.questions)))
    );
    const triggered = isQuestionTriggered(question, flatLayout, this.state.surveyData);

    if (!triggered) return <Grid.Column key={question.key} className="hide" />;

    const questionPaddingLevel = getQuestionPaddingLevel(question, flatLayout);

    const uiMetadata =
      question.metadata &&
      typeof question.metadata === "object" &&
      question.metadata.ui &&
      typeof question.metadata.ui === "object"
        ? question.metadata.ui
        : {};
    if (Object.prototype.hasOwnProperty.call(uiMetadata, "component")) {
      return this.createComponent(question, triggered, questionPaddingLevel, uiMetadata);
    }

    switch (question.type) {
      case "MultiSelect":
        return this.createMultiSelect(question, triggered, questionPaddingLevel);
      case "SingleSelect":
        // if 7 or more options, create a dropdown instead
        if (question.options.length >= 7) return this.createDropdown(question, triggered, questionPaddingLevel);
        return this.createSingleSelect(question, triggered, questionPaddingLevel);
      case "Dropdown":
        return this.createDropdown(question, triggered, questionPaddingLevel);
      case "ShortText":
        return this.createShortText(question, triggered, questionPaddingLevel);
      case "LongText":
        return this.createLongText(question, triggered, questionPaddingLevel);
      case "MultiToggle":
        return this.createMultiToggle(question, triggered, questionPaddingLevel);
      case "AdvancedSearch":
        return this.createAdvancedSearch(question, triggered, questionPaddingLevel);
      default:
        return "";
    }
  };

  initiateMultiToggleAnswers = (selection, question) => {
    const stringId = getQuestionStringId(question);
    const questionOptions = question.options;
    const _question = { ...this.state.surveyData[stringId] };
    if (selection) {
      // add defaults
      const answersToPush = questionOptions.map((optionsString) => {
        const options = optionsString.split("|").flat();
        for (let i = 0; i < options.length; i++) {
          if (parseLabel(options[i], "style").includes("option-default")) return parseLabel(options[i], "label");
        }
        return null;
      });
      _question.answers = ["Yes", ...answersToPush];
    } else {
      // remove defaults
      _question.answers = ["No"];
    }
    this.setState({ surveyData: { ...this.state.surveyData, [stringId]: _question } });
  };

  restoreMultiToggleState = (question, answers) => {
    const stringId = getQuestionStringId(question);
    const _question = this.state.surveyData[stringId];
    _question.answers = answers.length > 0 ? ["Yes", ...answers] : ["No"];
    this.setState({ surveyData: { ...this.state.surveyData, [stringId]: _question } });
  };

  clearQuestionAnswer = (question) => {
    const { surveyLayout, surveyData } = this.state;
    const stringId = getQuestionStringId(question);
    const _surveyData = { ...surveyData, [stringId]: { ...surveyData[stringId], keyword: "", answers: [] } };
    this.setState({
      surveyLayout: !isEmpty(question.originalOptions)
        ? refreshQuestionOptions(surveyLayout, _surveyData)
        : surveyLayout,
      surveyData: _surveyData
    });
  };

  updateAnswerObjectAnswers = (question, answers) => {
    const stringId = getQuestionStringId(question);
    const _question = this.state.surveyData[stringId];
    this.setState({ surveyData: { ...this.state.surveyData, [stringId]: { ..._question, answers } } });
  };

  handleDuplicateSection = (section) => {
    const { surveyLayout, surveyData, actions } = this.state;
    const max = Math.max(...actions.map((action) => action.order), 0);
    const action = {
      type: "DUPLICATE",
      targetType: "SECTION",
      targetKey: { sectionKey: section.key },
      order: max + 1
    };
    const [_surveyLayout, _surveyData, _newSection] = applyAction(surveyLayout, surveyData, action);
    this.setState({
      surveyLayout: _surveyLayout,
      surveyData: _surveyData,
      actions: [...actions, action],
      activeSectionKey: `${_newSection.key}-${_newSection.sortOrder}`
    });
  };

  handleDuplicateGroup = (group) => {
    const { surveyLayout, surveyData, actions } = this.state;
    const max = Math.max(...actions.map((action) => action.order), 0);
    const action = {
      type: "DUPLICATE",
      targetType: "GROUP",
      targetKey: { sectionKey: group.sectionKey, sectionCount: group.sectionCount, groupKey: group.key },
      order: max + 1
    };
    const [_surveyLayout, _surveyData] = applyAction(surveyLayout, surveyData, action);
    this.setState({ surveyLayout: _surveyLayout, surveyData: _surveyData, actions: [...actions, action] });
  };

  handleDuplicateQuestion = (question) => {
    const { surveyLayout, surveyData, actions } = this.state;
    const max = Math.max(...actions.map((action) => action.order), 0);
    const action = {
      type: "DUPLICATE",
      targetType: "QUESTION",
      targetKey: {
        sectionKey: question.sectionKey,
        sectionCount: question.sectionCount,
        groupKey: question.groupKey,
        groupCount: question.groupCount,
        questionKey: question.key
      },
      order: max + 1
    };
    const [_surveyLayout, _surveyData] = applyAction(surveyLayout, surveyData, action);
    this.setState({ surveyLayout: _surveyLayout, surveyData: _surveyData, actions: [...actions, action] });
  };

  handleDeleteSection = (section) => {
    const { activeSectionKey, surveyLayout, surveyData, actions } = this.state;
    const max = Math.max(...actions.map((action) => action.order), 0);
    const action = {
      type: "DELETE",
      targetType: "SECTION",
      targetKey: {
        sectionKey: section.key,
        sectionCount: section.sectionCount
      },
      order: max + 1
    };
    const [_surveyLayout, _surveyData] = applyAction(surveyLayout, surveyData, action);

    const order = +activeSectionKey.split("-")[1];
    const _activeSectionKey = section.sortOrder <= order ? `${section.key}-${+order - 1}` : `${section.key}-${order}`;

    this.setState({
      surveyLayout: _surveyLayout,
      surveyData: _surveyData,
      actions: [...actions, action],
      activeSectionKey: _activeSectionKey
    });
  };

  handleDeleteGroup = (group) => {
    const { surveyLayout, surveyData, actions } = this.state;
    const max = Math.max(...actions.map((action) => action.order), 0);
    const action = {
      type: "DELETE",
      targetType: "GROUP",
      targetKey: {
        sectionKey: group.sectionKey,
        sectionCount: group.sectionCount,
        groupKey: group.key,
        groupCount: group.groupCount
      },
      order: max + 1
    };
    const [_surveyLayout, _surveyData] = applyAction(surveyLayout, surveyData, action);
    this.setState({ surveyLayout: _surveyLayout, surveyData: _surveyData, actions: [...actions, action] });
  };

  handleDeleteQuestion = (question) => {
    const { surveyLayout, surveyData, actions } = this.state;
    const max = Math.max(...actions.map((action) => action.order), 0);
    const action = {
      type: "DELETE",
      targetType: "QUESTION",
      targetKey: {
        sectionKey: question.sectionKey,
        sectionCount: question.sectionCount,
        groupKey: question.groupKey,
        groupCount: question.groupCount,
        questionKey: question.key,
        questionCount: question.questionCount
      },
      order: max + 1
    };
    const [_surveyLayout, _surveyData] = applyAction(surveyLayout, surveyData, action);
    this.setState({ surveyLayout: _surveyLayout, surveyData: _surveyData, actions: [...actions, action] });
  };

  questionWrapper = (question, component, triggered, questionPaddingLevel) => {
    let className = triggered ? "show" : "hide";
    className += ` ${numbersToText[questionPaddingLevel]}-rem-padded-question no-top-padding `;
    return (
      <Grid.Column key={question.key} className={className}>
        <div className="survey-question-container" key={question.key}>
          {question.isDynamic && question.questionCount > 1 && (
            <Button
              size="mini"
              color="red"
              basic
              icon="delete"
              floated="right"
              title="Delete group"
              onClick={() => this.handleDeleteQuestion(question)}
            />
          )}
          <label className={question.isRequired ? `mock-semantic-required` : ""}>{question.text}</label>
          <Button
            color="red"
            size="mini"
            floated="right"
            content="Clear"
            disabled={!this.questionAnswered(this.state.surveyData[getQuestionStringId(question)])}
            onClick={() => this.clearQuestionAnswer(question)}
          />
          {question.isDynamic && question.isLastDynamicQuestion && (
            <Button
              color="yellow"
              size="mini"
              icon="plus"
              floated="right"
              onClick={() => this.handleDuplicateQuestion(question)}
            />
          )}
          {!isEmpty(question.suggestedAnswers) && (
            <div>
              <h5>Previous visit answer(s)</h5>
              <div style={{ paddingLeft: "1em" }}>
                {question.suggestedAnswers.map((answer) => (
                  <Label key={answer} style={{ marginBottom: ".5em" }}>
                    {answer}
                  </Label>
                ))}
              </div>
              <div style={{ paddingLeft: "1em", textAlign: "right", marginBottom: "1em" }}>
                <Button
                  color="green"
                  size="mini"
                  content="Apply"
                  onClick={() => {
                    this.updateAnswerObjectAnswers(question, question.suggestedAnswers);
                  }}
                />
              </div>
            </div>
          )}
          {component}
        </div>
      </Grid.Column>
    );
  };

  // Question rendering functions
  createMultiSelect = (question, triggered, questionPaddingLevel) =>
    this.questionWrapper(
      question,
      <React.Fragment>
        {question.options.map((option, index) => (
          <React.Fragment key={index}>
            <Form.Checkbox
              className={parseLabel(option, "style").join(" ")}
              key={index}
              label={parseLabel(option, "label")}
              checked={this.getQuestionValue("MultiSelect", question, parseLabel(option, "label"))}
              onChange={this.handleMultiSelect}
              name={question.key}
              skey={question.sectionKey}
              scount={question.sectionCount}
              gkey={question.groupKey}
              gcount={question.groupCount}
              qcount={question.questionCount}
            />
            {this.createElucidation(question, triggered, option)}
          </React.Fragment>
        ))}
        {this.createElucidation(question, triggered, "")}
      </React.Fragment>,
      triggered,
      questionPaddingLevel
    );

  createDateInput = (question, triggered, questionPaddingLevel) =>
    this.questionWrapper(
      question,
      <React.Fragment>
        <DateInput
          name={question.key}
          skey={question.sectionKey}
          scount={question.sectionCount}
          gkey={question.groupKey}
          gcount={question.groupCount}
          qcount={question.questionCount}
          onChange={this.handleInputChange}
          value={this.getQuestionValue("Text", question)}
          placeholder="Select Date"
          dateFormat={dateFormat}
          iconPosition="right"
          hideMobileKeyboard
          closable
        />
        {this.createElucidation(question, triggered, "")}
      </React.Fragment>,
      triggered,
      questionPaddingLevel
    );

  createSingleSelect = (question, triggered, questionPaddingLevel) =>
    this.questionWrapper(
      question,
      <React.Fragment>
        {question.options.map((option, index) => (
          <React.Fragment key={index}>
            <Form.Field key={index}>
              <Form.Radio
                className={parseLabel(option, "style").join(" ")}
                label={parseLabel(option, "label")}
                name={question.key}
                skey={question.sectionKey}
                scount={question.sectionCount}
                gkey={question.groupKey}
                gcount={question.groupCount}
                qcount={question.questionCount}
                value={option}
                checked={
                  triggered
                    ? this.getQuestionValue("Radio", question, parseLabel(option, "label"))
                    : this.getQuestionValue("Radio", question, "")
                }
                onChange={this.handleInputChange}
              />
            </Form.Field>
            {this.createElucidation(question, triggered, option)}
          </React.Fragment>
        ))}
        {this.createElucidation(question, triggered, "")}
      </React.Fragment>,
      triggered,
      questionPaddingLevel
    );

  createDropdown = (question, triggered, questionPaddingLevel) =>
    this.questionWrapper(
      question,
      <React.Fragment>
        <Form.Field>
          <Form.Dropdown
            fluid
            selection
            search
            options={question.options.map((option) => ({
              text: option,
              value: option
            }))}
            // label={question.text}
            required={question.isRequired}
            name={question.key}
            skey={question.sectionKey}
            scount={question.sectionCount}
            gkey={question.groupKey}
            gcount={question.groupCount}
            qcount={question.questionCount}
            onChange={this.handleInputChange}
            placeholder="Select..."
            value={this.getQuestionValue("Dropdown", question)}
          />
        </Form.Field>
        {question.options.map((option, index) => (
          <React.Fragment key={index}>{this.createElucidation(question, triggered, option)}</React.Fragment>
        ))}
        {this.createElucidation(question, triggered, "")}
      </React.Fragment>,
      triggered,
      questionPaddingLevel
    );

  createShortText = (question, triggered, questionPaddingLevel) =>
    this.questionWrapper(
      question,
      <React.Fragment>
        <Form.Field>
          <Form.Input
            name={question.key}
            skey={question.sectionKey}
            scount={question.sectionCount}
            gkey={question.groupKey}
            gcount={question.groupCount}
            qcount={question.questionCount}
            onChange={this.handleInputChange}
            value={this.getQuestionValue("Text", question)}
          />
        </Form.Field>
        {this.createElucidation(question, triggered, "")}
      </React.Fragment>,
      triggered,
      questionPaddingLevel
    );

  createLongText = (question, triggered, questionPaddingLevel) =>
    this.questionWrapper(
      question,
      <React.Fragment>
        <Form.Field>
          <Form.TextArea
            name={question.key}
            skey={question.sectionKey}
            scount={question.sectionCount}
            gkey={question.groupKey}
            gcount={question.groupCount}
            qcount={question.questionCount}
            onChange={this.handleInputChange}
            value={this.getQuestionValue("Text", question)}
          />
        </Form.Field>
        {this.createElucidation(question, triggered, "")}
      </React.Fragment>,
      triggered,
      questionPaddingLevel
    );

  createMultiToggle = (question, triggered, questionPaddingLevel) => (
    <MultiToggle
      key={question.key}
      question={question}
      triggered={triggered}
      questionPaddingLevel={questionPaddingLevel}
      questionWrapper={this.questionWrapper}
      getQuestionValue={this.getQuestionValue}
      createElucidation={this.createElucidation}
      surveyData={this.state.surveyData}
      handleMultiToggle={this.handleMultiToggle}
      initiateMultiToggleAnswers={this.initiateMultiToggleAnswers}
      restoreMultiToggleState={this.restoreMultiToggleState}
      parseLabel={parseLabel}
    />
  );

  createAdvancedSearch = (question, triggered, questionPaddingLevel) => {
    const stringId = getQuestionStringId(question);
    const _question = this.state.surveyData[stringId];
    return (
      <AdvancedSearch
        key={question.key}
        question={question}
        triggered={triggered}
        questionPaddingLevel={questionPaddingLevel}
        answerObject={_question}
        questionWrapper={this.questionWrapper}
        handleSearchChange={this.handleSearchChange}
        handleResultSelect={this.handleResultSelect}
        updateAnswerObjectAnswers={this.updateAnswerObjectAnswers}
      />
    );
  };

  // Handles the creation of elucidations, can be at question level or option level
  createElucidation = (question, triggered, option) => {
    const _question = this.state.surveyData[getQuestionStringId(question)];
    const _elucidation = _question.elucidations.find((_e) => _e.key === option);
    if (_elucidation) {
      const hide = _elucidation.key ? !_question.answers.includes(_elucidation.key) : false;
      return (
        <Form.Field className={_elucidation.key ? "elucidation-input" : ""} style={hide ? { display: "none" } : {}}>
          <Form.Input
            onChange={this.handleElucidation}
            name={`${_question.questionKey}~${_elucidation.key}`}
            skey={_question.sectionKey}
            scount={_question.sectionCount}
            gkey={_question.groupKey}
            gcount={_question.groupCount}
            qcount={_question.questionCount}
            value={this.getElucidationValue(_question, _elucidation.key, triggered) || ""}
            label={_elucidation.caption}
            disabled={hide}
          />
        </Form.Field>
      );
    }
    return "";
  };

  autoSave = () => {
    if (!this.props.autoSave) return;
    const { initSurveyData, surveyData } = this.state;
    if (isEqual(initSurveyData, surveyData)) this.handleClear();
    else this.handleHide();
  };

  handleMultiToggle = (index, value, question) => {
    const stringId = getQuestionStringId(question);
    const _question = { ...this.state.surveyData[stringId] };
    _question.answers[index + 1] = value;
    this.setState(
      { surveyData: { ...this.state.surveyData, [stringId]: _question }, shouldBlockNavigation: true },
      this.autoSave
    );
  };

  resetSearch = (id) => {
    const { surveyData } = this.state;
    const stringId = getQuestionStringId(id);
    const _question = this.state.surveyData[stringId];
    this.setState({
      surveyData: {
        ...surveyData,
        [stringId]: { ..._question, searchResult: [] }
      }
    });
  };

  debouncedSearch = debounce((id) => this.doSearch(id), 500);

  doSearch = (id) => {
    const { surveyLayout, surveyData } = this.state;
    const flatLayout = [].concat(
      ...surveyLayout.sections.map((_s) => [].concat(..._s.groups.map((_g) => _g.questions)))
    );
    const question = findItem(flatLayout, id);

    const stringId = getQuestionStringId(id);
    const _question = this.state.surveyData[stringId];

    const value = this.getQuestionValue("Keyword", _question);
    if (isEmpty(value.trim())) {
      this.resetSearch(id);
    } else {
      const config = question.config && typeof question.config === "object" ? question.config : {};

      if (isEmpty(config.searchUrl)) throw new Error("Search URL is missing");

      this.props.actions.search(config.searchUrl, value.trim()).then((result) => {
        this.setState({
          surveyData: {
            ...surveyData,
            [stringId]: { ..._question, searchResult: result.map((i) => ({ ...i, key: i.id, title: i.text })) }
          }
        });
      });
    }
  };

  handleSearchChange = (e, data) => {
    const {
      name: key,
      qcount: questionCount,
      skey: sectionKey,
      scount: sectionCount,
      gkey: groupKey,
      gcount: groupCount,
      value,
      mcs
    } = data;
    const id = getQuestionId({ sectionKey, sectionCount, groupKey, groupCount, key, questionCount });
    const stringId = getQuestionStringId({ sectionKey, sectionCount, groupKey, groupCount, key, questionCount });
    const _question = { ...this.state.surveyData[stringId] };
    _question.keyword = value;
    this.setState(
      {
        surveyData: { ...this.state.surveyData, [stringId]: _question }
      },
      () => {
        if (value.trim().length >= mcs) this.debouncedSearch(id);
        else this.resetSearch(id);
      }
    );
  };

  handleResultSelect = (e, data) => {
    const {
      name: key,
      qcount: questionCount,
      skey: sectionKey,
      scount: sectionCount,
      gkey: groupKey,
      gcount: groupCount,
      result
    } = data;
    const stringId = getQuestionStringId({ sectionKey, sectionCount, groupKey, groupCount, key, questionCount });
    const _question = { ...this.state.surveyData[stringId] };
    _question.keyword = "";
    _question.answers = [...new Set([..._question.answers, result.text])];
    this.setState(
      {
        surveyData: { ...this.state.surveyData, [stringId]: _question },
        shouldBlockNavigation: true
      },
      this.autoSave
    );
  };

  handleInputChange = (e, data) => {
    const {
      name: questionKey,
      qcount: questionCount,
      skey: sectionKey,
      scount: sectionCount,
      gkey: groupKey,
      gcount: groupCount,
      label,
      type,
      value
    } = data;
    const { surveyLayout, surveyData } = this.state;
    const stringId = getQuestionStringId({
      key: questionKey,
      questionCount,
      sectionKey,
      sectionCount,
      groupKey,
      groupCount
    });
    const _surveyData = {
      ...surveyData,
      [stringId]: { ...surveyData[stringId], answers: type === "radio" ? [label] : !isEmpty(value) ? [value] : [] }
    };
    this.setState(
      {
        surveyLayout: data.options ? refreshQuestionOptions(surveyLayout, _surveyData) : surveyLayout,
        surveyData: _surveyData,
        shouldBlockNavigation: true
      },
      this.autoSave
    );
  };

  handleMultiSelect = (e, data) => {
    const {
      name: questionKey,
      qcount: questionCount,
      skey: sectionKey,
      scount: sectionCount,
      gkey: groupKey,
      gcount: groupCount,
      checked,
      label
    } = data;
    const stringId = getQuestionStringId({
      questionKey,
      questionCount,
      sectionKey,
      sectionCount,
      groupKey,
      groupCount
    });
    const { surveyLayout, surveyData } = this.state;
    const _question = { ...surveyData[stringId] };
    if (checked) _question.answers.push(label);
    else _question.answers.splice(_question.answers.indexOf(label), 1);
    const _surveyData = { ...surveyData, [stringId]: _question };
    this.setState(
      {
        surveyLayout: refreshQuestionOptions(surveyLayout, _surveyData),
        surveyData: _surveyData,
        shouldBlockNavigation: true
      },
      this.autoSave
    );
  };

  handleElucidation = (e, data) => {
    const {
      name,
      qcount: questionCount,
      skey: sectionKey,
      scount: sectionCount,
      gkey: groupKey,
      gcount: groupCount,
      value
    } = data;
    const splitName = name.split("~");
    const questionKey = splitName[0];
    const elucidationKey = splitName[1];
    const stringId = getQuestionStringId({
      key: questionKey,
      questionCount,
      sectionKey,
      sectionCount,
      groupKey,
      groupCount
    });
    const _question = { ...this.state.surveyData[stringId] };
    const elucidations = [..._question.elucidations];
    const elucidation = elucidations.find((_elu) => _elu.key === elucidationKey);
    const elucidationIndex = elucidations.findIndex((_elu) => _elu.key === elucidationKey);
    elucidation.value = value;
    elucidations.splice(elucidationIndex, 1, elucidation);
    _question.elucidations = elucidations;
    this.setState(
      {
        surveyData: { ...this.state.surveyData, [stringId]: _question },
        shouldBlockNavigation: true
      },
      this.autoSave
    );
  };

  questionAnswered = (question) => !isEmpty(question.answers);

  // Checks to make sure the required questions are filled out
  requiredQuestionsFilled = () => {
    const { surveyLayout, surveyData } = this.state;
    const flatLayout = [].concat(
      ...surveyLayout.sections.map((_s) => [].concat(..._s.groups.map((_g) => _g.questions)))
    );
    const requiredQuestions = flatLayout.filter(
      (_q) => _q.isRequired === true && isQuestionTriggered(_q, flatLayout, surveyData)
    );
    return requiredQuestions.every((_q) => this.questionAnswered(surveyData[getQuestionStringId(_q)]));
  };

  renderGroup = (group) => {
    const { surveyLayout, surveyData } = this.state;
    const flatLayout = [].concat(
      ...surveyLayout.sections.map((_s) => [].concat(..._s.groups.map((_g) => _g.questions)))
    );
    const show = surveyData ? group.questions.some((_q) => isQuestionTriggered(_q, flatLayout, surveyData)) : false;

    if (!show) return null;

    const handleRows = (rows) =>
      Object.keys(rows).map((key) => (
        <Grid.Row columns={rows[key].length} key={key} className="no-padding survey-row">
          {rows[key].map((question) => this.renderQuestion(question))}
        </Grid.Row>
      ));

    const plusButton = () => (
      <Button
        size="mini"
        color="yellow"
        icon="plus"
        fluid
        onClick={(e) => {
          e.stopPropagation();
          this.handleDuplicateGroup(group);
        }}
      />
    );

    const deleteButton = () => (
      <Button
        size="mini"
        color="red"
        basic
        icon="delete"
        floated="right"
        title="Delete group"
        // className="transparent-button-icon delete"
        onClick={(e) => {
          e.stopPropagation();
          this.handleDeleteGroup(group);
        }}
      />
    );

    const rows = groupBy(group.questions, "sortOrder");

    switch (group.style) {
      case "none":
        return (
          <React.Fragment key={`${group.key}-${group.sortOrder}`}>
            {group.isDynamic === true && group.groupCount > 1 && deleteButton()}
            {handleRows(rows)}
            {group.isDynamic === true && group.isLastDynamicGroup && plusButton()}
          </React.Fragment>
        );
      // case "solid-border": return null; // DIL Do It Later
      case "dashed-border":
        return (
          <Grid
            key={`${group.key}-${group.sortOrder}`}
            className="no-padding"
            style={{ width: "100%", border: "dashed 1px grey", margin: "1em 0" }}
          >
            {group.isDynamic === true && group.groupCount > 1 && (
              <Grid.Row className="no-bottom-padding">
                <Grid.Column width={16}>{deleteButton()}</Grid.Column>
              </Grid.Row>
            )}
            {handleRows(rows)}
            {group.isDynamic === true && group.isLastDynamicGroup && (
              <Grid.Row>
                <Grid.Column width={16}>{plusButton()}</Grid.Column>
              </Grid.Row>
            )}
          </Grid>
        );
      // empty string, null and undefined are treated the same as fieldset to maintain legacy data
      case "":
      case null:
      case undefined:
      case "fieldset":
      default:
        return (
          <fieldset
            key={`${group.key}-${group.sortOrder}`}
            className="survey-question-group"
            style={{
              display: "block",
              width: "100%",
              padding: group && group.header ? `1em` : `0`,
              borderColor: "#d0d0d0"
            }}
          >
            {group && group.header && <legend style={{ fontWeight: "600" }}>{group.header}</legend>}
            {group.isDynamic === true && group.groupCount > 1 && deleteButton()}
            {handleRows(rows)}
            {group.isDynamic === true && group.isLastDynamicGroup && plusButton()}
          </fieldset>
        );
    }
  };

  renderSection = (section, showTitle = true) => {
    if (section.groups.length < 1) return null;

    const sectionTitle = section.header;

    return (
      <React.Fragment>
        <Grid padded stackable>
          {showTitle && sectionTitle && (
            <Grid.Row key={`${section.key}-${section.sortOrder}`} className="no-padding">
              <h2>{sectionTitle}</h2>
            </Grid.Row>
          )}
          {section.groups
            .sort((a, b) => (+a.sortOrder > +b.sortOrder ? 1 : +a.sortOrder < +b.sortOrder ? -1 : 0))
            .map((group) => this.renderGroup(group))}
        </Grid>
      </React.Fragment>
    );
  };

  renderForm = () => {
    const {
      surveyLayout: { sections },
      activeSectionKey
    } = this.state;
    if (sections.length === 1 && !sections[0].isDynamic) {
      return this.renderSection(sections[0]);
    }
    const panes = [];
    sections
      .sort((a, b) => (+a.sortOrder > +b.sortOrder ? 1 : +a.sortOrder < +b.sortOrder ? -1 : 0))
      .forEach((section) => {
        panes.push({
          menuItem: (
            <Menu.Item
              key={`${section.key}-${section.sortOrder}`}
              className={`no-padding ${section.hasMissingRequiredFields ? "danger" : "success"} ${
                section.isDynamic && section.isLastDynamicSection ? "last-dynamic" : ""
              }`}
            >
              <div className="full-padding">
                {section.isDynamic && section.sectionCount > 1 && (
                  <Button
                    size="mini"
                    icon="delete"
                    title="Delete section"
                    className="no-padding transparent-button-icon delete"
                    onClick={(e) => {
                      e.stopPropagation();
                      this.handleDeleteSection(section);
                    }}
                  />
                )}
                {section.header + (section.hasRequiredFields ? " *" : "")}
                <br />
                {section.groups.map((group) =>
                  group.questions.map((question) =>
                    question.showAnswerInSectionHeader
                      ? (() => {
                          const answer = this.getQuestionValue("Text", question);
                          return !isEmpty(answer) ? (
                            <span className="section-extra" key={answer}>
                              {answer}
                              <br />
                            </span>
                          ) : (
                            ``
                          );
                        })()
                      : ``
                  )
                )}
              </div>
              {section.isDynamic && section.isLastDynamicSection && (
                <Button
                  size="mini"
                  color="yellow"
                  icon="plus"
                  fluid
                  onClick={(e) => {
                    e.stopPropagation();
                    this.handleDuplicateSection(section);
                  }}
                />
              )}
            </Menu.Item>
          ),
          render: () => (
            <Tab.Pane key={`${section.key}-${section.sortOrder}`}>{this.renderSection(section, false)}</Tab.Pane>
          )
        });
      });
    const twoTabs = sections.length > 14;
    return (
      <Tab
        grid={{ paneWidth: twoTabs ? 8 : 12, tabWidth: twoTabs ? 8 : 4 }}
        menu={{
          fluid: true,
          pointing: true,
          vertical: true,
          tabular: true,
          className: twoTabs ? "columns_" : ""
        }}
        panes={panes}
        activeIndex={sections.findIndex((s) => `${s.key}-${s.sortOrder}` === activeSectionKey)}
        onTabChange={(_, data) => {
          this.setState({
            activeSectionKey: `${sections[data.activeIndex].key}-${sections[data.activeIndex].sortOrder}`
          });
          // eslint-disable-next-line react/no-find-dom-node
          ReactDOM.findDOMNode(this).getElementsByClassName("scrolling")[0].scrollTop = 0;
        }}
      />
    );
  };

  renderAsModal(requiredQuestionsFilled, someQuestionsFilled) {
    const { survey, disableSave } = this.props;
    const { enableSaveButton } = this.state;
    return (
      <Modal
        className="survey-modal"
        open={this.props.open}
        closeOnDimmerClick={false}
        onClose={this.handleClose}
        closeIcon
        size="large"
      >
        {!isEmpty(survey.title) && <Header content={survey.title} />}
        <Modal.Content scrolling>
          <Form>{this.renderForm()}</Form>
        </Modal.Content>
        <Modal.Actions className="bottom-bar">
          {!requiredQuestionsFilled && (
            <Message error content="Please fill in all required fields marked with a red asterisk (*)" />
          )}
          <Button onClick={this.handleHide} id="btn-hide" color="orange" disabled={!someQuestionsFilled}>
            Hide
          </Button>
          <Button onClick={this.handleClose} id="btn-cancel">
            Cancel
          </Button>
          <Button
            onClick={this.handleSave}
            color="blue"
            id="btn-save"
            loading={!enableSaveButton}
            disabled={!enableSaveButton || disableSave || !requiredQuestionsFilled}
          >
            Save
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }

  renderAsSegment(requiredQuestionsFilled) {
    const { survey, disableSave } = this.props;
    const { enableSaveButton } = this.state;
    return (
      <Segment className="survey-modal">
        {!isEmpty(survey.title) && <Header content={survey.title} />}
        <Segment basic className="scrolling">
          <Form>{this.renderForm()}</Form>
        </Segment>
        <Segment basic textAlign="right" className="bottom-bar">
          {!requiredQuestionsFilled && (
            <Message error content="Please fill in all required fields marked with a red asterisk (*)" />
          )}
          <Button
            onClick={this.handleSave}
            color="blue"
            id="btn-save"
            loading={!enableSaveButton}
            disabled={!enableSaveButton || disableSave || !requiredQuestionsFilled}
          >
            Save
          </Button>
        </Segment>
      </Segment>
    );
  }

  render() {
    const { surveyData, shouldBlockNavigation } = this.state;
    const { test, asModal, promptOnLeaving, hiddenSurveys } = this.props;
    const questions = Object.values(surveyData);
    const requiredQuestionsFilled = this.requiredQuestionsFilled();
    const someQuestionsFilled = questions.some((_q) => this.questionAnswered(_q));
    return (
      <React.Fragment>
        {!test && promptOnLeaving !== false && isEmpty(hiddenSurveys) && (
          <Prompt when={shouldBlockNavigation} message="You have unsaved changes, are you sure you want to leave?" />
        )}
        <Confirm
          open={this.state.confirm}
          header={
            <Modal.Header>
              <Icon name="exclamation triangle" />
              <span>Confirm closing survey</span>
            </Modal.Header>
          }
          content="Are you sure you want to close this survey - all entered answers will be lost?"
          confirmButton="Close"
          onCancel={() => this.setState({ confirm: false })}
          onConfirm={this.handleCloseConfirmed}
        />
        {asModal === false
          ? this.renderAsSegment(requiredQuestionsFilled)
          : this.renderAsModal(requiredQuestionsFilled, someQuestionsFilled)}
      </React.Fragment>
    );
  }
}

function mapStateToProps(state, props) {
  const contextVisit = state.visits.contextVisit;
  const latestVisit = state.visits.latestVisit;
  const surveysVisit = props.tab === "discharge" ? null : contextVisit || latestVisit;
  const subState = state[props.for];

  return {
    patientId: state.patient.currentPatient.patientId,
    contextVisit: state.templates.contextVisit,
    availableTemplates: state.templates.availableTemplates,
    selectedTemplate: subState.selectedTemplate,
    reInitSurvey: subState.reInitSurvey,
    surveysVisitId: surveysVisit ? surveysVisit.visitId : null,
    hiddenSurveys: subState.hiddenSurveys
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      { ...modalActions, ...templateActions, ...treatmentPlanActions, ...patientActions },
      dispatch
    )
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(emrComponent(Survey));
