import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function patientHistoryReducer(state = initialState.patientHistory, action) {
  switch (action.type) {
    case types.LOAD_HISTORICAL_ADDRESS_SUCCESS:
      return Object.assign({}, state, {
        historicalAddresses: action.historicalAddresses
      });
    case types.LOAD_MEDICATION_SUCCESS:
      return Object.assign({}, state, {
        medicationHistory: action.medicationHistory
      });
    case types.LOAD_FAMILY_HIST_SUCCESS:
      return Object.assign({}, state, {
        familyHistory: action.familyHistory
      });
    case types.LOAD_ALLERGY_HIST_SUCCESS:
      return Object.assign({}, state, {
        allergyHistory: action.allergyHistory
      });
    case types.LOAD_MEDICAL_HIST_SUCCESS:
      return Object.assign({}, state, {
        medicalHistory: action.medicalHistory
      });
    case types.LOAD_INTERVENTION_HISTORY_SUCCESS:
      return { ...state, interventionHistory: action.interventionHistory };
    case types.LOAD_PATIENT_HIST_SUCCESS:
      return state;
    case types.CLEAR_PATIENT_HISTORY:
      return {
        medicationHistory: {},
        allergyHistory: {},
        familyHistory: {},
        medicalHistory: [],
        outsideCounselorHistory: [],
        obProviderHistory: []
      };
    case types.CREATE_MEDICATION_SUCCESS:
      return Object.assign({}, state, {
        medicationHistory: [
          ...state.medicationHistory,
          Object.assign(action.medication, {
            startDate: action.response.StartDate, // prevents moment ISO warning from displaying
            itemId: action.response.ItemId,
            endDate: action.response.EndDate // prevents moment ISO warning from displaying
          })
        ]
      });
    case types.LOAD_OB_PROVIDER_SUCCESS:
      return Object.assign({}, state, {
        obProviderHistory: action.obProviders
      });
    case types.UPDATE_MEDICATION_SUCCESS:
      return Object.assign({}, state, {
        medicationHistory: [
          ...state.medicationHistory.filter((medication) => medication.itemId !== action.medication.itemId),
          action.medication
        ]
      });

    case types.DELETE_MEDICATION_SUCCESS:
      return Object.assign({}, state, {
        medicationHistory: [...state.medicationHistory.filter((medication) => medication.itemId !== action.itemId)]
      });
    case types.CREATE_FAMILY_HIST_SUCCESS:
      return Object.assign({}, state, {
        familyHistory: [
          ...state.familyHistory,
          Object.assign(action.familyHistory, {
            itemId: action.response.ItemId
          })
        ]
      });
    case types.UPDATE_FAMILY_HIST_SUCCESS:
      return Object.assign({}, state, {
        familyHistory: [
          ...state.familyHistory.map((familyHistoryItem) => {
            if (familyHistoryItem.itemId === action.familyHistory.itemId) {
              return action.familyHistory;
            }
            return familyHistoryItem;
          })
        ]
      });
    case types.DELETE_FAMILY_HIST_SUCCESS:
      return Object.assign({}, state, {
        familyHistory: [
          ...state.familyHistory.filter((familyHistoryItem) => familyHistoryItem.itemId !== action.itemId)
        ]
      });
    case types.CREATE_ALLERGY_HIST_SUCCESS: {
      const allergy = Object.assign({}, Object.assign(action.allergy, { itemId: action.response.ItemId }), {
        isMedicationAllergy: action.allergyType === "MedicationAllergyHistory"
      });
      return Object.assign({}, state, {
        allergyHistory: [allergy, ...state.allergyHistory]
      });
    }
    case types.UPDATE_ALLERGY_HIST_SUCCESS:
      return Object.assign({}, state, {
        allergyHistory: [...state.allergyHistory.filter((allergy) => allergy.itemId !== action.itemId), action.allergy]
      });
    case types.DELETE_ALLERGY_HIST_SUCCESS:
      return Object.assign({}, state, {
        allergyHistory: [...state.allergyHistory.filter((allergy) => allergy.itemId !== action.itemId)]
      });
    case types.CREATE_MEDICAL_HIST_SUCCESS:
      return Object.assign({}, state, {
        medicalHistory: [
          ...state.medicalHistory,
          Object.assign(action.medicalHistory, {
            itemId: action.response.ItemId,
            startDate: action.response.StartDate, // prevents moment ISO warning from displaying
            endDate: action.response.EndDate // prevents moment ISO warning from displaying
          })
        ]
      });
    case types.UPDATE_MEDICAL_HIST_SUCCESS: {
      const foundIndex = state.medicalHistory.findIndex((x) => x.itemId === action.medicalHistory.itemId);

      // Update medicnpm run lintal history
      const newMedicalHistory = Object.assign([], state.medicalHistory);
      if (foundIndex !== undefined) {
        newMedicalHistory[foundIndex] = action.medicalHistory;
      }

      return Object.assign({}, state, {
        medicalHistory: newMedicalHistory
      });
    }
    case types.DELETE_MEDICAL_HIST_SUCCESS:
      return Object.assign({}, state, {
        medicalHistory: [
          ...state.medicalHistory.filter((medicalHistoryItem) => medicalHistoryItem.itemId !== action.itemId)
        ]
      });

    case types.CREATE_INTERVENTION_HISTORY_SUCCESS:
      return {
        ...state,
        interventionHistory: [
          ...state.interventionHistory,
          { ...action.interventionHistory, itemId: action.response.ItemId }
        ]
      };
    case types.UPDATE_INTERVENTION_HISTORY_SUCCESS: {
      const index = state.interventionHistory.findIndex((x) => x.itemId === action.interventionHistory.itemId);
      const updatedInterventionHistory = [...state.interventionHistory];
      if (index > -1) {
        updatedInterventionHistory[index] = action.interventionHistory;
      }
      return { ...state, interventionHistory: updatedInterventionHistory };
    }
    case types.DELETE_INTERVENTION_HISTORY_SUCCESS:
      return {
        ...state,
        interventionHistory: state.interventionHistory.filter((item) => item.itemId !== action.itemId)
      };

    case types.LOAD_OUTSIDE_COUNSELOR_SUCCESS:
      return Object.assign({}, state, {
        outsideCounselorHistory: action.outsideCounselors
      });

    default:
      return state;
  }
}
