import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Accordion, Button, Icon } from "semantic-ui-react";
import startCase from "lodash/startCase";
import lowerCase from "lodash/lowerCase";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import PatientPhoto from "./PatientPhoto";
import PatientDischarge from "./PatientDischarge";
import PatientPsychiatrist from "./PatientPsychiatrist";
import PatientOBProvider from "./PatientOBProvider";
import PatientOutsideCounselor from "./PatientOutsideCounselor";
import PatientOtherProviders from "./PatientOtherProviders";
import * as demographicActions from "../../../../actions/demographicActions";
import * as modalActions from "../../../../actions/modalActions";
import AdvancedDirectives from "./AdvancedDirectives";
import Notes from "../../../common/Notes";
import PCP from "../pcp";
import { OUTSIDE_PROVIDER_HISTORY_MODAL } from "../../../../helpers/modals";
import {
  CUSTOM_FIELD_ID_PSYCHIATRIST,
  CUSTOM_FIELD_ID_OTHER_PROVIDERS,
  isoFormat,
  mediumDateFormat
} from "../../../../constants/miscellaneous";
import { showModal } from "../../../../helpers";
import "./DemographicsAccordion.css";

export class DemographicsAccordion extends Component {
  state = {
    activeIndex: 0
  };

  componentDidMount() {
    this._isMounted = true;
    this.props.actions.loadPatientDischargeReason(this.props.patientId);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  _isMounted = false;

  handleOBProviderHistory = () => {
    showModal(OUTSIDE_PROVIDER_HISTORY_MODAL, {
      header: "OB Provider History",
      stateItem: "obProviderHistory",
      columns: [
        { name: "obProviderId", visible: false },
        { name: "obProvider", header: "OB Provider" },
        { name: "dateAssigned", header: "Date Added" },
        { name: "dateRemoved", header: "Date Removed" }
      ],
      keyColumn: "obProviderId"
    });
  };

  handleOutsideCounselorHistory = () => {
    showModal(OUTSIDE_PROVIDER_HISTORY_MODAL, {
      header: "Outside Counselor History",
      stateItem: "outsideCounselorHistory",
      columns: [
        { name: "outsideCounselorId", visible: false },
        { name: "outsideCounselor", header: "Outside Counselor" },
        { name: "dateAssigned", header: "Date Added" },
        { name: "dateRemoved", header: "Date Removed" }
      ],
      keyColumn: "outsideCounselorId"
    });
  };

  handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;
    this.setState({ activeIndex: newIndex });
  };

  render() {
    const { activeIndex } = this.state;
    const {
      history,
      patientId,
      primaryCarePhysician,
      psychiatrist,
      outsideCounselor,
      obProvider,
      otherProviders,
      status,
      patientDischargeReason
    } = this.props;

    return (
      <div className="demographics-accordion-container">
        <Accordion styled>
          {/* <Accordion.Title
            active={activeIndex === 0}
            index={0}
            onClick={this.handleClick}
          >
            <Icon name="dropdown" />
            Patient Notes
          </Accordion.Title>
          <Accordion.Content active={activeIndex === 0}>
            <p>
              A dog is a type of domesticated animal. Known for its loyalty and
              faithfulness, it can be found as a welcome guest in many
              households across the world.
            </p>
          </Accordion.Content> */}
          <Accordion.Title active={activeIndex === 9} index={9} onClick={this.handleClick}>
            <Icon name="dropdown" />
            Patient Notes
          </Accordion.Title>
          <Accordion.Content active={activeIndex === 9}>
            <Notes noteType="Patient" />
          </Accordion.Content>

          <Accordion.Title active={activeIndex === 1} index={1} onClick={this.handleClick}>
            <Icon name="dropdown" />
            Advanced Directives
          </Accordion.Title>
          <Accordion.Content active={activeIndex === 1}>
            <AdvancedDirectives hideContinueButton />
          </Accordion.Content>

          <Accordion.Title active={activeIndex === 2} index={2} onClick={this.handleClick}>
            <Icon name="dropdown" />
            PCP
            <span className="right">
              {primaryCarePhysician
                ? `${startCase(lowerCase(primaryCarePhysician.firstName))} ${startCase(
                    lowerCase(primaryCarePhysician.lastName)
                  )}`
                : "none"}
            </span>
          </Accordion.Title>
          <Accordion.Content active={activeIndex === 2} className="px-0">
            <PCP hideContinue />
          </Accordion.Content>

          <Accordion.Title active={activeIndex === 3} index={3} onClick={this.handleClick}>
            <Icon name="dropdown" />
            Psychiatrist
            <span className="title-psychiatrist right">{psychiatrist || "none"}</span>
          </Accordion.Title>
          <Accordion.Content active={activeIndex === 3}>
            <PatientPsychiatrist />
          </Accordion.Content>

          <Accordion.Title active={activeIndex === 4} index={4} onClick={this.handleClick}>
            <Icon name="dropdown" />
            Outside Counselor
            <span className="title-outside-counselor right">{outsideCounselor || "none"}</span>
          </Accordion.Title>

          <Accordion.Content active={activeIndex === 4}>
            <PatientOutsideCounselor
              outsideCounselor={outsideCounselor}
              handleOutsideCounselorHistory={this.handleOutsideCounselorHistory}
            />
          </Accordion.Content>

          <Accordion.Title active={activeIndex === 5} index={5} onClick={this.handleClick}>
            <Icon name="dropdown" />
            OB Provider
            <span className="title-patient-ob right">{obProvider || "none"}</span>
          </Accordion.Title>
          <Accordion.Content active={activeIndex === 5}>
            <PatientOBProvider obProvider={obProvider} handleOBProviderHistory={this.handleOBProviderHistory} />
          </Accordion.Content>

          <Accordion.Title active={activeIndex === 6} index={6} onClick={this.handleClick}>
            <Icon name="dropdown" />
            Other Providers
            <span className="title-outside-counselor right">{otherProviders || "none"}</span>
          </Accordion.Title>
          <Accordion.Content active={activeIndex === 6}>
            <PatientOtherProviders />
          </Accordion.Content>

          <Accordion.Title active={activeIndex === 7} index={7} onClick={this.handleClick}>
            <Icon name="dropdown" />
            Picture
          </Accordion.Title>
          <Accordion.Content active={activeIndex === 7}>
            <PatientPhoto />
          </Accordion.Content>

          <Accordion.Title active={activeIndex === 8} index={8} onClick={this.handleClick}>
            <Icon name="dropdown" />
            Chart Export
          </Accordion.Title>
          <Accordion.Content active={activeIndex === 8}>
            <div style={{ textAlign: "center" }}>
              <Button
                color="olive"
                size="mini"
                icon="print"
                content="Export"
                onClick={() => {
                  history.push(`/s/patient/${patientId}/iexport`);
                }}
              />
            </div>
          </Accordion.Content>

          <React.Fragment>
            <Accordion.Title active={activeIndex === 9} index={9} onClick={this.handleClick}>
              <Icon name="dropdown" />
              Discharge
              {status === "Discharged" && patientDischargeReason && !Array.isArray(patientDischargeReason) ? (
                <div className={patientDischargeReason.isReversed ? "reversed-discharge-date" : "discharge-date"}>
                  Discharge Date:{" "}
                  {moment(patientDischargeReason.dischargedDate, isoFormat).local().format(mediumDateFormat)}
                </div>
              ) : null}
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 9}>
              <PatientDischarge status={status} />
            </Accordion.Content>
          </React.Fragment>
        </Accordion>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    patientId: state.patient.currentPatient.patientId,
    psychiatrist: state.patient.customFields[CUSTOM_FIELD_ID_PSYCHIATRIST]?.customFieldAnswer || "none",
    otherProviders: state.patient.customFields[CUSTOM_FIELD_ID_OTHER_PROVIDERS]?.customFieldAnswer || "none",
    outsideCounselor: state.demographics.outsideCounselor,
    obProvider: state.demographics.obProvider,
    patientDischargeReason: state.demographics.patientDischargeReason
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...demographicActions,
        ...modalActions
      },
      dispatch
    )
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DemographicsAccordion));
