import React, { Component } from "react";
import map from "lodash/map";
import { Table, Icon, Button } from "semantic-ui-react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as adminActions from "../../actions/adminActions";
import * as centerActions from "../../actions/centerActions";
import * as modalActions from "../../actions/modalActions";
import { roleGroups } from "../../constants/securityRoles";
import { checkRoles } from "../../helpers";
import "./ManageCentersPage.css";
import CenterSearch from "./CenterSearch";

export class ManageCentersPage extends Component {
  state = {
    column: null,
    direction: null,
    openModal: false
  };

  constructor(props) {
    super(props);
    this.props.actions.pushItemToBreadcrumb({
      key: "bc-manage-centers",
      to: "/manage-centers",
      text: "CleanSlate Centers"
    });
  }

  componentDidMount() {
    this.props.actions.loadCenters();
  }

  componentDidUpdate(prevProps) {
    const { modal } = this.props;
    if (modal !== prevProps.modal) {
      this.props.actions.loadCenters();
    }
  }

  handleSort = (clickedColumn) => () => {
    const { column, direction } = this.state;
    const { centers } = this.props;

    this.setState(
      {
        column: clickedColumn,
        direction: direction === "ascending" && column === clickedColumn ? "descending" : "ascending"
      },
      () => {
        const { column: _column, direction: _direction } = this.state;
        this.props.actions.updateCenterSort([
          ...centers.sort((a, b) => {
            const first = `${a[_column]}`.toLowerCase();
            const second = `${b[_column]}`.toLowerCase();
            if (_direction === "ascending") return first > second ? 1 : first < second ? -1 : 0;
            return first < second ? 1 : first > second ? -1 : 0;
          })
        ]);
      }
    );
  };

  handleNewModalOpen = () =>
    this.props.actions.showModal({
      type: "NEW_CENTER",
      props: { open: true, isNew: true }
    });

  handleEditModalOpen = (centerId) => () => {
    this.props.actions.showModal({
      type: "NEW_CENTER",
      props: { open: true, isNew: false, centerId }
    });
  };

  render() {
    const { column, direction } = this.state;
    const { centers, authRoles } = this.props;
    const showAddCenter = checkRoles(roleGroups.centersFull, authRoles);
    const showCenterDetails = checkRoles(roleGroups.centerDetailsView, authRoles);
    return (
      <React.Fragment>
        <div className="header-wrapper">
          <h2>CleanSlate Centers</h2>
        </div>
        <div className="content-wrapper centers-content">
          <CenterSearch />
          {showAddCenter && (
            <Button color="blue" onClick={this.handleNewModalOpen}>
              <Icon name={"plus"} />
              Add New Center
            </Button>
          )}
          <Table sortable celled fixed compact className="compact-table">
            <Table.Header>
              <Table.Row style={{ textAlign: "center" }}>
                <Table.HeaderCell
                  sorted={column === "centerName" ? direction : null}
                  onClick={this.handleSort("centerName")}
                >
                  Name
                </Table.HeaderCell>
                <Table.HeaderCell>Address</Table.HeaderCell>
                <Table.HeaderCell sorted={column === "city" ? direction : null} onClick={this.handleSort("city")}>
                  City
                </Table.HeaderCell>
                <Table.HeaderCell sorted={column === "state" ? direction : null} onClick={this.handleSort("state")}>
                  State
                </Table.HeaderCell>
                <Table.HeaderCell>Zip</Table.HeaderCell>
                <Table.HeaderCell
                  sorted={column === "nickName" ? direction : null}
                  onClick={this.handleSort("nickName")}
                >
                  Nickname
                </Table.HeaderCell>
                <Table.HeaderCell>Code</Table.HeaderCell>
                <Table.HeaderCell>Phone</Table.HeaderCell>
                <Table.HeaderCell>Fax</Table.HeaderCell>
                <Table.HeaderCell
                  sorted={column === "labDirector" ? direction : null}
                  onClick={this.handleSort("labDirector")}
                >
                  Lab
                  <br />
                  Director
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={column === "medicalDirector" ? direction : null}
                  onClick={this.handleSort("medicalDirector")}
                >
                  Medical
                  <br />
                  Director
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={column === "locationType" ? direction : null}
                  onClick={this.handleSort("locationType")}
                >
                  Location
                  <br />
                  Type
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={column === "isLocationOpen" ? direction : null}
                  onClick={this.handleSort("isLocationOpen")}
                >
                  Location
                  <br />
                  Status
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {map(
                centers,
                ({
                  centerName,
                  centerId,
                  address1,
                  city,
                  state,
                  zip,
                  nickName,
                  centerCode,
                  phone,
                  fax,
                  labDirector,
                  medicalDirector,
                  locationType,
                  isLocationOpen
                }) => (
                  <Table.Row
                    key={centerId}
                    className="selectable-row"
                    onClick={showCenterDetails && this.handleEditModalOpen(centerId)}
                  >
                    <Table.Cell>{centerName}</Table.Cell>
                    <Table.Cell>{address1}</Table.Cell>
                    <Table.Cell>{city}</Table.Cell>
                    <Table.Cell>{state}</Table.Cell>
                    <Table.Cell>{zip}</Table.Cell>
                    <Table.Cell>{nickName}</Table.Cell>
                    <Table.Cell>{centerCode}</Table.Cell>
                    <Table.Cell>{phone}</Table.Cell>
                    <Table.Cell>{fax}</Table.Cell>
                    <Table.Cell>{labDirector}</Table.Cell>
                    <Table.Cell>{medicalDirector}</Table.Cell>
                    <Table.Cell>{locationType}</Table.Cell>
                    <Table.Cell>
                      {isLocationOpen ? (
                        <strong style={{ padding: ".2em", fontSize: "14px", color: "#75ce0a" }}>Open</strong>
                      ) : (
                        <strong style={{ padding: ".2em", fontSize: "14px", color: "#db2828" }}>Close</strong>
                      )}
                    </Table.Cell>
                  </Table.Row>
                )
              )}
            </Table.Body>
          </Table>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    authRoles: state.auth.user.profile.roles,
    centers: state.centers.items,
    modal: state.modal
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...adminActions, ...centerActions, ...modalActions }, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageCentersPage);
