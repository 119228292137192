import React, { useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import {
  useGetLabResultsHistoryQuery,
  labResultsHistorySelectors,
  useGetReviewedLabResultsHistoryQuery,
  useReviewLabResultHistoryMutation
} from "./labsSlice";
import { Card, Container, Row, Col, Table, OverlayTrigger, Tooltip, Button, Form, Icon } from "../../../ui";
import LoadComponent from "../../reusable/LoadComponent";
import { dateFormat, dateWithTimeFormat } from "../../../constants/miscellaneous";
import LabResults from "./LabResults";

function ResultHistory({ patientId, visitTime, type, ordersCount }) {
  const { isLoading, data, isError, error } = useGetLabResultsHistoryQuery({ patientId, visitTime, type, ordersCount });

  const [showDetails, setShowDetails] = useState(false);
  const [detailsFor, setDetailsFor] = useState({ labCode: null, ordrId: null });

  // const data = useSelector(
  //   (state) => labResultsHistorySelectors(state, { patientId, visitTime, type }).selectAll() || []
  // );

  if (isLoading) return <LoadComponent />;
  if (isError) return <div className="text-center text-danger">{error.message.toString()}</div>;

  const { entities } = data;

  const columns = Object.values(entities)
    .map((entity) => ({
      orderId: entity.orderId,
      visitDate: entity.visitTime.substring(0, 10),
      visitTime: entity.visitTime
    }))
    .sort((a, b) => b.visitTime.localeCompare(a.visitTime));

  const distinctLabs = {};
  Object.values(entities).forEach((entity) => {
    entity.labs.forEach((lab) => {
      if (!distinctLabs[lab.abbreviation])
        distinctLabs[lab.abbreviation] = {
          abbreviation: lab.abbreviation,
          description: lab.description,
          sortOrder: lab.sortOrder,
          children: []
        };
      lab.children.forEach((child) => {
        if (!distinctLabs[lab.abbreviation].children.find((_child) => _child.abbreviation === child.abbreviation))
          distinctLabs[lab.abbreviation].children.push({
            abbreviation: child.abbreviation,
            description: child.description,
            sortOrder: child.sortOrder
          });
      });
    });
  });

  const rows = [];
  Object.values(distinctLabs)
    .sort((a, b) => a.sortOrder - b.sortOrder)
    .forEach((lab) => {
      let newParent = true;
      lab.children
        .sort((a, b) => a.sortOrder - b.sortOrder)
        .forEach((child) => {
          rows.push({
            key: `${lab.abbreviation}-${child.abbreviation}`,
            newParent,
            rowSpan: lab.children.length,
            parentAbbreviation: lab.abbreviation,
            parentDescription: lab.description,
            childAbbreviation: child.abbreviation,
            childDescription: child.description
          });
          newParent = false;
        });
    });

  const _data = [];
  rows.forEach((row, i) => {
    const rowData = [];
    columns.forEach((column, j) => {
      const _lab = entities[column.orderId].labs.find((lab) => lab.abbreviation === row.parentAbbreviation);
      if (_lab) {
        const _child = _lab.children.find((child) => child.abbreviation === row.childAbbreviation);
        if (_child) {
          rowData.push({ key: `${i}-${j}`, ..._child, emrOrderId: column.orderId, parentLabCode: _lab.labCode });
        } else rowData.push({ key: `${i}-${j}` });
      } else rowData.push({ key: `${i}-${j}` });
    });
    _data.push(rowData);
  });

  return (
    <>
      {showDetails && (
        <LabResults
          patientId={patientId}
          labCode={detailsFor.labCode}
          orderId={detailsFor.orderId}
          backHandler={() => {
            setShowDetails(false);
          }}
        />
      )}
      {!showDetails && (rows.length === 0 || columns.length === 0) && (
        <div className={`text-${type === "Urine" ? "warning" : "danger"}`}>{`No ${type} History`}</div>
      )}
      {!showDetails && rows.length > 0 && columns.length > 0 && (
        <Table size="sm" className="fit" striped bordered hover responsive>
          <thead>
            <tr className="no-border-top">
              <th className="rotated-th _text-vertical" colSpan={2}>
                <div>
                  <span>{`${type} Tests`}</span>
                </div>
              </th>
              {columns.map((column) => (
                <th key={column.orderId} className="rotated-th _text-vertical">
                  <div>
                    <span>{moment(column.visitDate).format(dateFormat)}</span>
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {rows.map((row, i) => (
              <tr key={row.key}>
                {row.newParent && (
                  <td
                    className={`fw-bold text-end ${row.newParent ? `no-border-right` : ``}`}
                    rowSpan={row.newParent ? row.rowSpan : 1}
                  >
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip id="tooltip-11">{row.parentDescription}</Tooltip>}
                    >
                      <span>{row.parentAbbreviation}</span>
                    </OverlayTrigger>
                  </td>
                )}
                <td className="fw-bold text-end no-border-left">
                  <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-11">{row.childDescription}</Tooltip>}>
                    <span>{row.childAbbreviation}</span>
                  </OverlayTrigger>
                </td>
                {_data[i].map((child) => (
                  <td key={child.key} className={child.isResultNormal === false ? `table-danger` : ``}>
                    {child.isResultNormal ? (
                      child.result
                    ) : (
                      <a
                        href=""
                        style={{ textDecoration: "none" }}
                        onClick={(e) => {
                          e.preventDefault();
                          setDetailsFor({ orderId: child.emrOrderId, labCode: child.parentLabCode });
                          setShowDetails(true);
                          return false;
                        }}
                      >
                        {child.result}
                      </a>
                    )}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </>
  );
}

function VisitResultHistoryReview({ visitId, visitTime, userCanReview }) {
  const [urineOrdersCount, setUrineOrdersCount] = useState(8);
  const [bloodOrdersCount, setBloodOrdersCount] = useState(8);
  const [comments, setComments] = useState([]);
  const [comment, setComment] = useState("");
  const patientId = useSelector((state) => state.patient.currentPatient.patientId);
  const { data: reviewedLabResultsHistory } = useGetReviewedLabResultsHistoryQuery({
    patientId,
    visitId
  });
  const [reviewLabResultHistory, { isLoading }] = useReviewLabResultHistoryMutation();

  const handleReviewedClick = async (body) => {
    if (!isLoading) {
      try {
        reviewLabResultHistory({ patientId, body });
      } catch (err) {
        // console.error("Failed to reviewed: ", err);
      }
    }
  };

  const urineCount = useSelector(
    (state) =>
      labResultsHistorySelectors(state, {
        patientId,
        visitTime,
        type: "Urine",
        ordersCount: urineOrdersCount
      }).selectTotal() || 0
  );

  const bloodCount = useSelector(
    (state) =>
      labResultsHistorySelectors(state, {
        patientId,
        visitTime,
        type: "Blood",
        ordersCount: bloodOrdersCount
      }).selectTotal() || 0
  );

  function loadMore(type) {
    if (type === "Urine") setUrineOrdersCount((prevCount) => prevCount + 8);
    else setBloodOrdersCount((prevCount) => prevCount + 8);
  }

  const reviewed =
    reviewedLabResultsHistory && reviewedLabResultsHistory.ids && reviewedLabResultsHistory.ids.length > 0;

  return (
    <Container fluid>
      <Row>
        <Col>
          <Card className="my-2">
            <Card.Header>History</Card.Header>
            <Card.Body>
              <Container>
                <Row>
                  <Col md="2" />
                  <Col className="p-0">
                    <Card.Title>
                      <span className="text-warning">{`Urine`}</span>
                      <Button
                        variant="outline-warning"
                        size="sm"
                        className="float-end"
                        onClick={() => {
                          loadMore("Urine");
                        }}
                        disabled={urineCount < urineOrdersCount}
                      >
                        Load more
                      </Button>
                    </Card.Title>
                    <ResultHistory
                      patientId={patientId}
                      visitId={visitId}
                      visitTime={visitTime}
                      type="Urine"
                      ordersCount={urineOrdersCount}
                    />
                    <Card.Title>
                      <span className="text-danger">{`Blood`}</span>
                      <Button
                        variant="outline-danger"
                        size="sm"
                        className="float-end"
                        onClick={() => {
                          loadMore("Blood");
                        }}
                        disabled={bloodCount < bloodOrdersCount}
                      >
                        Load more
                      </Button>
                    </Card.Title>
                    <ResultHistory
                      patientId={patientId}
                      visitId={visitId}
                      visitTime={visitTime}
                      type="Blood"
                      ordersCount={bloodOrdersCount}
                    />
                  </Col>
                </Row>
                <Row className="p-0">
                  <Col md="2" className="d-flex align-items-center">
                    {reviewed && (
                      <Container className="mt-3 mb-2 text-muted">
                        <Row>
                          <Col className="p-0 text-center">
                            <Icon name="eye" className="me-2" />
                            Reviewed
                          </Col>
                        </Row>
                        <Row>
                          <Col className="p-0 text-center">
                            {Object.values(reviewedLabResultsHistory.entities).map((reviewDetails) => (
                              <React.Fragment key={reviewDetails.reviewDate}>
                                {moment(reviewDetails.reviewDate).format(dateWithTimeFormat)}
                                {` - `}
                                {`${reviewDetails.firstName} ${reviewDetails.lastName}`}
                              </React.Fragment>
                            ))}
                          </Col>
                        </Row>
                      </Container>
                    )}

                    {!reviewed && userCanReview && (
                      <Button
                        variant="primary"
                        className="mt-3 mb-2"
                        loading={isLoading}
                        disabled={isLoading}
                        onClick={() => {
                          handleReviewedClick({
                            visitId,
                            reviewContent: JSON.stringify({
                              comments
                            })
                          });
                        }}
                      >
                        <Icon name="eye" className="me-2" />
                        Reviewed
                      </Button>
                    )}
                  </Col>
                  <Col className="p-0">
                    {reviewed && (
                      <Row>
                        <Col sm="10" className="pe-0">
                          <h5>Comments</h5>
                          {Object.values(reviewedLabResultsHistory.entities).map((reviewDetails) => (
                            <React.Fragment key={reviewDetails.documentName}>
                              {(JSON.parse(reviewDetails.reviewContent)?.comments || []).map((_comment) => (
                                <p key={_comment} className="my-3">
                                  {_comment.split("\n").map((l) => (
                                    <span key={l}>
                                      {l}
                                      <br />
                                    </span>
                                  ))}
                                </p>
                              ))}
                            </React.Fragment>
                          ))}
                        </Col>
                      </Row>
                    )}
                    {!reviewed && userCanReview && (
                      <Form>
                        <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">
                          <Col sm="10" className="pe-0">
                            <Form.Control
                              as="textarea"
                              rows={3}
                              value={comment}
                              onChange={(e) => {
                                setComment(e.target.value);
                              }}
                            />
                          </Col>
                          <Col
                            sm="2"
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "end",
                              justifyContent: "end"
                            }}
                          >
                            <Button
                              variant="outline-primary"
                              size="md"
                              style={{ width: "100%" }}
                              onClick={() => {
                                if (!comments.includes(comment)) setComments([...comments, comment]);
                              }}
                            >
                              <Icon name="plus" />
                              Comment
                            </Button>
                          </Col>

                          <Col sm="10" className="pe-0">
                            {comments.map((_comment) => (
                              <p key={_comment} className="my-3">
                                {_comment.split("\n").map((l) => (
                                  <span key={l}>
                                    {l}
                                    <br />
                                  </span>
                                ))}
                              </p>
                            ))}
                          </Col>
                        </Form.Group>
                      </Form>
                    )}
                  </Col>
                </Row>
              </Container>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default VisitResultHistoryReview;
