import { API_URL } from "../constants/environment";
import { adalApiFetch, defaultOptions } from "../adalConfig";

const parsedJson = true;
const logoutIfForbidden = false;
const ignoreClientErrors = true;

export const fetchCustomReportById = reportId => adalApiFetch(`${API_URL}/Reports/${reportId}/Custom`);

export const fetchProviderSlotsData = providerId =>
  adalApiFetch(
    `${API_URL}/Reports/Providers/${providerId}/SlotsData`,
    defaultOptions,
    parsedJson,
    logoutIfForbidden,
    ignoreClientErrors
  );

export const fetchProviderPatientsWithActiveRx = providerId =>
  adalApiFetch(
    `${API_URL}/Reports/Providers/${providerId}/PatientsWithActiveRx`,
    defaultOptions,
    parsedJson,
    logoutIfForbidden,
    ignoreClientErrors
  );

export const fetchTotalPatientsInPanel = providerId =>
  adalApiFetch(
    `${API_URL}/Reports/Providers/${providerId}/TotalPatientsInPanel`,
    defaultOptions,
    parsedJson,
    logoutIfForbidden,
    ignoreClientErrors
  );

export const fetchReportByName = reportName =>
  adalApiFetch(
    `${API_URL}/Reports/Name/${reportName}`,
    defaultOptions,
    parsedJson,
    logoutIfForbidden,
    ignoreClientErrors
  );

export default {
  fetchProviderSlotsData,
  fetchProviderPatientsWithActiveRx,
  fetchCustomReportById,
  fetchReportByName,
  fetchTotalPatientsInPanel
};
