import React from "react";
import { Accordion as BsAccordion } from "react-bootstrap";

const Accordion = (props) => <BsAccordion {...props} />;

Accordion.Button = BsAccordion.Button;
Accordion.Collapse = BsAccordion.Collapse;
Accordion.Item = BsAccordion.Item;
Accordion.Header = BsAccordion.Header;
Accordion.Body = BsAccordion.Body;

export default Accordion;
