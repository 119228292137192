import React from "react";

import { Table, Icon, Header, Button, Image } from "semantic-ui-react";
// eslint-disable-next-line camelcase
import messages_ok from "../../../assets/patients/current/messages_ok.svg";
// eslint-disable-next-line camelcase
import no_messages from "../../../assets/patients/current/no_messages.svg";

import "./ViewPatientDemographics.css";
import { formatPhoneNumber, handleAppLink } from "../../../helpers";

const PatientEmContactRow = ({
  emContact: {
    associatedContactType,
    // type,
    // name,
    firstName,
    lastName,
    // relationship,
    phoneNumber,
    isPrimary,
    consentGivenForMessages
  },
  handleEmContactEditClick,
  contactindex,
  relationText,
  showDemoEdit
}) => {
  // eslint-disable-next-line camelcase
  const messageIconColor = consentGivenForMessages ? messages_ok : no_messages;

  return (
    <Table.Row>
      <Table.Cell className="phone_" width="6">
        <Header as="h4">
          <Icon name="user circle outline" size="big" />
          <div id="lbl-name" className="resizable-content">
            <label>{relationText}</label>
            {firstName} {lastName}
          </div>
        </Header>
      </Table.Cell>
      <Table.Cell className="phone_" width="6">
        <Header as="h4">
          <Icon name="mobile" size="big" />
          <div id="lbl-phoneNumber" className="resizable-content">
            <label>{associatedContactType}</label>
            {formatPhoneNumber(phoneNumber)}
          </div>
        </Header>
      </Table.Cell>
      <Table.Cell className="icon-container" width="2">
        <a href={`tel:${phoneNumber}`} style={{ display: "inline" }} onClick={handleAppLink}>
          <Image src={messageIconColor} size="mini" title="Ok to leave messages" inline />
        </a>
        {isPrimary ? <Icon name="star" size="large" color="yellow" title="Primary contact" /> : null}
      </Table.Cell>
      <Table.Cell collapsing className="no-padding" width="1">
        {showDemoEdit && (
          <Button
            className="transparent-button-icon"
            contactindex={contactindex}
            onClick={handleEmContactEditClick}
            icon="edit"
            size="big"
            id="btn-editEmContact"
          />
        )}
      </Table.Cell>
    </Table.Row>
  );
};

export default PatientEmContactRow;
