import React, { Component } from "react";
import { Button, Header, Modal, Form, Checkbox } from "semantic-ui-react";
import MaskedInput from "react-text-mask";
import moment from "moment";
import { connect } from "react-redux";
import groupBy from "lodash/groupBy";
import { bindActionCreators } from "redux";
import { DateInput } from "semantic-ui-calendar-react";
import { dateFormat } from "../../../../constants/miscellaneous";
import * as modalActions from "../../../../actions/modalActions";
import * as providerActions from "../../../../actions/providerActions";
import * as centerActions from "../../../../actions/centerActions";
import * as insuranceActions from "../../../../actions/insuranceActions";
import { dateRegEx, npi, npiMask, referralNumber, referralNumberMask } from "../../../../constants/validation";
import {
  getLookupText,
  requiredFieldsFilled,
  updateErrorsWithStartEndDates,
  checkRoles,
  hasProperty
} from "../../../../helpers";

import { roleGroups } from "../../../../constants/securityRoles";

import ReferralsTable from "./ReferralsTable";

import "./AddReferralsModal.css";

const inputValidation = {
  required: ["providerNPINumber", "referralNumber", "startDate", "endDate", "numberOfReferralsAvailable"],
  startDate: dateRegEx,
  endDate: dateRegEx,
  referralNumber,
  numberOfReferralsAvailable: referralNumber,
  providerNPINumber: npi
};

export class AddReferralsModal extends Component {
  state = {
    referral: this.props.referral,
    statusVisible: "Active",
    errors: {}
  };

  componentDidMount() {
    this.props.actions.loadProviders();
    this.props.actions.loadCenters();
  }

  handleClose = () => this.props.actions.hideModal();

  handleFocus = (e) => {
    e.target.setAttribute("autocomplete", "nope");
  };

  handleOpenLookup = () =>
    this.props.actions.showModal({
      type: "DOCTOR_SEARCH",
      props: {
        open: true,
        fromReferrals: true,
        referral: this.state.referral
      }
    });

  handleToggle = (e, data) => {
    const { checked } = data;
    if (checked) {
      this.setState({ statusVisible: "Inactive" });
    } else {
      this.setState({ statusVisible: "Active" });
    }
  };

  handleAdd = () => {
    this.props.actions.createReferral(this.props.patientId, this.state.referral).then(this.clearReferrals);
  };

  clearReferrals = () => {
    const referral = {
      providerNPINumber: "",
      referralNumber: "",
      startDate: "",
      endDate: "",
      numberOfReferralsAvailable: "",
      provider: "",
      centerId: ""
    };
    this.setState({ referral });
  };

  handleInput = (e, data) => {
    const { name: field, value, required } = data;
    const { startDate, endDate } = this.state.referral;
    const errors = updateErrorsWithStartEndDates(
      field,
      value,
      required,
      this.state.errors,
      inputValidation,
      startDate,
      endDate
    );
    const referral = Object.assign({}, this.state.referral, {
      [field]: value
    });
    this.setState({ referral, errors });
  };

  handleMaskedInput = (e) => {
    const {
      type,
      target: { name: field, required, value }
    } = e;
    const { startDate, endDate } = this.state.referral;
    let errors = Object.assign({}, this.state.errors);
    const referral = Object.assign({}, this.state.referral, {
      [field]: value
    });

    if (type !== "focus" && type !== "blur") {
      errors = updateErrorsWithStartEndDates(
        field,
        value,
        required,
        this.state.errors,
        inputValidation,
        startDate,
        endDate
      );
    }

    this.setState({ referral, errors });
  };

  render() {
    const { open, centers, providers, separatedReferrals, authRoles } = this.props;
    const { errors, referral, statusVisible } = this.state;

    const requiredFieldsCheck = requiredFieldsFilled(inputValidation.required, referral);
    const disableAdd = requiredFieldsCheck || Object.keys(errors).length !== 0;
    const enableAddReferral = checkRoles(roleGroups.providers, authRoles);

    return (
      <Modal className="add-referrals-modal" open={open} onClose={this.handleClose} closeIcon size="large">
        <Header content="Additional Referrals - Add Referral" />
        <Modal.Content>
          <Form>
            <Form.Group widths="equal">
              <Form.Field
                className={hasProperty(errors, "providerNPINumber") ? "error required field" : "required field"}
              >
                <label htmlFor="input-providerNPINumber">NPI</label>
                <MaskedInput
                  required
                  name="providerNPINumber"
                  mask={npiMask}
                  guide={false}
                  value={referral.providerNPINumber}
                  onChange={this.handleMaskedInput}
                  id="input-providerNPINumber"
                  disabled={!enableAddReferral}
                />
              </Form.Field>
              <Form.Field className={hasProperty(errors, "referralNumber") ? "error required field" : "required field"}>
                <label htmlFor="input-referralNumber">Referral #</label>
                <MaskedInput
                  required
                  name="referralNumber"
                  mask={referralNumberMask}
                  guide={false}
                  value={referral.referralNumber}
                  onChange={this.handleMaskedInput}
                  id="input-referralNumber"
                  disabled={!enableAddReferral}
                />
              </Form.Field>
              <Form.Field className={hasProperty(errors, "startDate") ? "error required field" : "required field"}>
                <label>Start Date</label>
                <DateInput
                  name="startDate"
                  placeholder="Start Date"
                  value={referral.startDate}
                  dateFormat={dateFormat}
                  iconPosition="right"
                  onChange={this.handleInput}
                  disabled={!enableAddReferral}
                  error={hasProperty(errors, "startDate")}
                  hideMobileKeyboard
                  closable
                />
              </Form.Field>
              <Form.Field className={hasProperty(errors, "endDate") ? "error required field" : "required field"}>
                <label>End Date</label>
                <DateInput
                  name="endDate"
                  placeholder="End Date"
                  value={referral.endDate}
                  dateFormat={dateFormat}
                  iconPosition="right"
                  onChange={this.handleInput}
                  disabled={!enableAddReferral}
                  error={hasProperty(errors, "endDate")}
                  hideMobileKeyboard
                  closable
                />
              </Form.Field>
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Field
                className={
                  hasProperty(errors, "numberOfReferralsAvailable") ? "error required field" : "required field"
                }
              >
                <label htmlFor="input-paNumber"># Ref</label>
                <MaskedInput
                  required
                  name="numberOfReferralsAvailable"
                  mask={referralNumberMask}
                  guide={false}
                  value={referral.numberOfReferralsAvailable}
                  onChange={this.handleMaskedInput}
                  id="input-paNumber"
                  error={hasProperty(errors, "numberOfReferralsAvailable")}
                  disabled={!enableAddReferral}
                />
              </Form.Field>
              <Form.Field>
                <Form.Dropdown
                  fluid
                  selection
                  search
                  label="Provider (opt.)"
                  name="cleanSlateProviderId"
                  placeholder="Optional"
                  onChange={this.handleInput}
                  id="input-cleanSlateProviderId"
                  options={providers}
                  onFocus={this.handleFocus}
                  value={referral.cleanSlateProviderId}
                  disabled={!enableAddReferral}
                />
              </Form.Field>
              <Form.Field>
                <Form.Dropdown
                  fluid
                  selection
                  search
                  label="Location (opt.)"
                  placeholder="Optional"
                  name="centerId"
                  onChange={this.handleInput}
                  id="input-location"
                  options={centers}
                  onFocus={this.handleFocus}
                  value={referral.centerId}
                  disabled={!enableAddReferral}
                />
              </Form.Field>
            </Form.Group>
          </Form>
          <div className="referral-button-container">
            <Button color="blue" onClick={this.handleOpenLookup}>
              Doctor Lookup
            </Button>
            {enableAddReferral && (
              <Button color="blue" onClick={this.handleAdd} disabled={disableAdd}>
                Add Referral
              </Button>
            )}
          </div>
          <div className="view-referrals-container">
            <Checkbox toggle label="View Inactive" onClick={this.handleToggle} />
            <ReferralsTable
              tableType={statusVisible}
              referrals={statusVisible === "Active" ? separatedReferrals.active : separatedReferrals.inactive}
              getLookupText={getLookupText}
              centers={centers}
              providers={providers}
            />
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.handleClose} id="btn-cancel">
            Cancel
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

function providersFormattedDropdown(providers) {
  return providers.map((provider) => ({
    value: provider.userId.toString(),
    text: `${provider.firstName} ${provider.lastName}`
  }));
}

function mapStateToProps(state, ownProps) {
  let referral = {
    providerNPINumber: "",
    referralNumber: "",
    startDate: "",
    endDate: "",
    numberOfReferralsAvailable: "",
    provider: "",
    centerId: ""
  };

  if (ownProps.npi) {
    referral = Object.assign({}, ownProps.referral, {
      providerNPINumber: ownProps.npi
    });
  }

  const referrals = state.insurance.referrals.map((_referral) => {
    if (
      _referral.numberOfReferralsAvailable > 0 &&
      (moment().toDate() < moment(_referral.endDate).toDate() || !_referral.endDate)
    ) {
      // eslint-disable-next-line no-param-reassign
      _referral.status = "active";
    } else {
      // eslint-disable-next-line no-param-reassign
      _referral.status = "inactive";
    }
    return _referral;
  });

  const separatedReferrals = groupBy(referrals, (_referral) => _referral.status);

  return {
    referral,
    patientId: state.patient.currentPatient.patientId,
    centers: state.lookups.centers,
    providers: providersFormattedDropdown(state.providers.allProviders),
    separatedReferrals,
    authRoles: state.auth.user.profile.roles
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...modalActions,
        ...providerActions,
        ...centerActions,
        ...insuranceActions
      },
      dispatch
    )
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddReferralsModal);
