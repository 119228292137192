import React from "react";
import { Modal } from "../../../../ui";
import { hideModal } from "../../../../helpers";
import EditAddress from "./EditAddress";

export const EditAddressModal = (props) => (
  <Modal
    size="xl"
    backdrop="static"
    keyboard={false}
    onHide={() => {
      hideModal();
    }}
    show={props.open}
  >
    <Modal.Header closeButton>
      <Modal.Title>{props.isEdit ? `Edit Address` : `Add Address`}</Modal.Title>
    </Modal.Header>
    <Modal.Body className="no-padding">
      <EditAddress
        isEdit={props.isEdit}
        patientId={props.patientId}
        address={props.address}
        afterSave={() => {
          hideModal();
        }}
        handleCancel={() => {
          hideModal();
        }}
      />
    </Modal.Body>
  </Modal>
);

export default EditAddressModal;
