import React, { Component } from "react";
import { Button, Icon } from "semantic-ui-react";
import moment from "moment";
import shortid from "shortid";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as lookupActions from "../../actions/lookupActions";
import * as modalActions from "../../actions/modalActions";
import * as groupActions from "../../actions/groupActions";
import * as providerActions from "../../actions/providerActions";

import { roleGroups } from "../../constants/securityRoles";
import { checkRoles } from "../../helpers";
import GroupTable from "./GroupTable";
import "./GroupVisitsPage.css";

export class GroupVisitsPage extends Component {
  state = {
    params: {
      centerId: "",
      dateTime: moment().format("MM/DD/YYYY")
    },
    groups: []
  };

  componentDidMount() {
    this.props.actions.loadGroups(this.state.params);
    this.props.actions.loadVisitStatuses();
    this.props.actions.loadGroupTypes();
    this.props.actions.loadProviders();
    this.props.actions.loadInsuranceProviders();
  }

  handleGroupModalOpen = () =>
    this.props.actions.showModal({
      type: "NEW_GROUP",
      props: { open: true }
    });

  render() {
    const { groups, authRoles } = this.props;
    const showCreateGroup = checkRoles(roleGroups.excludeHelpDesk, authRoles);
    return (
      <React.Fragment>
        <div className="header-wrapper">
          <h2>
            Visiting Groups
            {showCreateGroup && (
              <Button
                className="transparent-button add-group"
                id="btn-addGroup"
                size="small"
                onClick={this.handleGroupModalOpen}
              >
                <Icon name={"plus"} />
                Group
              </Button>
            )}
          </h2>
        </div>

        <div className="content-wrapper">
          <div className="group-visits-table">
            {groups.length > 0 ? (
              groups.map((group) => (
                <GroupTable group={group} key={group.groupId ? group.groupId : `rand-${shortid.generate()}`} />
              ))
            ) : (
              <div className="no-groups-placeholder">No groups have been added for today</div>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    groups: state.groups,
    authRoles: state.auth.user.profile.roles
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...lookupActions, ...modalActions, ...groupActions, ...providerActions }, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(GroupVisitsPage);
