import React, { Component } from "react";
import { Table, Dropdown } from "semantic-ui-react";
import { Link } from "react-router-dom";
import moment from "moment";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { isEqual, sortBy } from "lodash";
import isEmpty from "lodash/isEmpty";
import * as visitActions from "../../../actions/visitActions";
import * as modalActions from "../../../actions/modalActions";
import * as badgeActions from "../../../actions/badgeActions";
import { dateWithTimeFormat } from "../../../constants/miscellaneous";
import { calculateTimeFromNow, isVisitTypeMedical } from "../../../helpers/index";
import { PatientLevel } from "../../../ui";

export class StatusWatchMyPatientTable extends Component {
  static filterVisits(visits, visitStatusFilter) {
    return isEmpty(visitStatusFilter)
      ? visits.filter((v) => v.visitStatus !== "visitcomplete")
      : visits.filter((v) => v.visitStatus !== "visitcomplete" && visitStatusFilter.includes(v.visitStatus));
  }

  state = {
    visits: StatusWatchMyPatientTable.filterVisits(this.props.myCalendar.visits, this.props.visitStatusFilter),
    visitStatusFilter: this.props.visitStatusFilter,
    column: null,
    direction: null,
    data: [],
    selectedRow: ""
  };

  componentDidMount() {
    this._isMounted = true;
  }

  componentDidUpdate(prevProps) {
    const {
      visitStatusFilter,
      myCalendar: { visits }
    } = this.props;
    if (
      !isEqual(visitStatusFilter, prevProps.visitStatusFilter) ||
      visits.length !== prevProps.myCalendar.visits.length ||
      !visits.every((v) =>
        prevProps.myCalendar.visits.some((vv) => vv.visitId === v.visitId && vv.visitStatus === v.visitStatus)
      )
    ) {
      this.setState(
        { visits: StatusWatchMyPatientTable.filterVisits(visits, visitStatusFilter), visitStatusFilter },
        () => {
          if (!isEmpty(this.state.column)) this.handleSort(this.state.column, true);
        }
      );
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  _isMounted = false;

  handleRowSelect = (e) => {
    const currentRow = e.currentTarget.getAttribute("data-index");
    const visitId = e.currentTarget.getAttribute("data-visit");
    this.setState({
      selectedRow: currentRow,
      selectedVisitId: visitId
    });
  };

  handleStatusChange = (e, data) => {
    const { value } = data;
    if (value === "visitcomplete") {
      this.props.actions.showModal({
        type: "CONFIRMATION",
        props: {
          open: true,
          icon: "exclamation-triangle",
          iconColor: "warning",
          title: "Confirm Complete",
          buttonColor: "red",
          description: "Are you sure you want to end the visit?",
          onConfirm: () => {
            this.updateVisitStatus(value);
          }
        }
      });
    } else {
      this.updateVisitStatus(value);
    }
  };

  updateVisitStatus = (newStatus) => {
    const { visits } = this.state;
    const selectedVisitId = this.state.selectedVisitId;
    const currentItem = visits.find((i) => i.visitId === selectedVisitId);
    if (currentItem.visitStatus !== newStatus) {
      this.props.actions.updateVisitStatus(this.state.selectedVisitId, newStatus);
    }
  };

  handleSort = (clickedColumn, justSort = false) => {
    const { visits, column, direction } = this.state;
    const sorted = sortBy(visits, [clickedColumn]);
    if (justSort || column !== clickedColumn) {
      this.setState({
        visits: sorted,
        selectedRow: "",
        column: clickedColumn,
        direction: "ascending"
      });
      return;
    }
    const newDirection = direction === "ascending" ? "descending" : "ascending";
    this.setState({
      visits: newDirection === "ascending" ? sorted : sorted.reverse(),
      selectedRow: "",
      column: clickedColumn,
      direction: newDirection
    });
  };

  render() {
    const { visits, column, direction, selectedRow } = this.state;
    const { statuses, visitTypes } = this.props;

    if (isEmpty(statuses) || isEmpty(visitTypes)) return null;

    return (
      <React.Fragment>
        {visits && visits.length > 0 ? (
          <div>
            <h2>Status Watch</h2>
            <div className="divMyPatients">
              <Table selectable sortable className="visits-table">
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell
                      sorted={column === "visitTime" ? direction : null}
                      onClick={() => {
                        this.handleSort("visitTime");
                      }}
                    >
                      Visit Start/Status Time
                    </Table.HeaderCell>
                    <Table.HeaderCell
                      sorted={column === "patientFullName" ? direction : null}
                      onClick={() => {
                        this.handleSort("patientFullName");
                      }}
                    >
                      Patient Name
                    </Table.HeaderCell>
                    <Table.HeaderCell
                      sorted={column === "provider" ? direction : null}
                      onClick={() => {
                        this.handleSort("provider");
                      }}
                    >
                      Provider
                    </Table.HeaderCell>
                    <Table.HeaderCell
                      sorted={column === "insurance" ? direction : null}
                      onClick={() => {
                        this.handleSort("insurance");
                      }}
                    >
                      Insurance
                    </Table.HeaderCell>
                    <Table.HeaderCell
                      sorted={column === "mainPrescriber" ? direction : null}
                      onClick={() => {
                        this.handleSort("mainPrescriber");
                      }}
                    >
                      Main Prescriber
                    </Table.HeaderCell>
                    <Table.HeaderCell
                      sorted={column === "visitStatus" ? direction : null}
                      onClick={() => {
                        this.handleSort("visitStatus");
                      }}
                    >
                      Status
                    </Table.HeaderCell>
                    <Table.HeaderCell
                      sorted={column === "visitTypeName" ? direction : null}
                      onClick={() => {
                        this.handleSort("visitTypeName");
                      }}
                    >
                      Visit Type
                    </Table.HeaderCell>
                    <Table.HeaderCell
                      sorted={column === "homeCenterName" ? direction : null}
                      onClick={() => {
                        this.handleSort("homeCenterName");
                      }}
                    >
                      Visit Center
                    </Table.HeaderCell>
                    <Table.HeaderCell className="text-center">Level</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {visits.map(
                    (
                      {
                        visitTime,
                        patientFullName,
                        homeCenterName,
                        mainPrescriber,
                        visitStatusName,
                        visitStatus,
                        visitType,
                        visitTypeName,
                        patientLevel,
                        bhLevel,
                        patientId,
                        visitId,
                        provider,
                        insurance
                      },
                      i
                    ) => (
                      <Table.Row
                        key={visitId}
                        data-index={i}
                        data-visit={visitId}
                        onClick={this.handleRowSelect}
                        className={String(i) === selectedRow ? "selected " : ""}
                      >
                        <Table.Cell data-label="Visit Start">
                          <span>
                            {moment(visitTime).format(dateWithTimeFormat)}
                            <span> | </span>
                            {calculateTimeFromNow(visitTime)}
                          </span>
                        </Table.Cell>
                        <Table.Cell data-label="Patient Name">
                          <span>
                            <Link
                              to={`/current-patients/${patientId}/demographics`}
                              style={{ textDecoration: "none" }}
                              key={patientId}
                            >
                              {patientFullName}
                            </Link>
                          </span>
                        </Table.Cell>
                        <Table.Cell data-label="Provider">
                          <span>{provider}</span>
                        </Table.Cell>
                        <Table.Cell data-label="Insurance">
                          <span>{insurance}</span>
                        </Table.Cell>
                        <Table.Cell data-label="Main Prescriber">
                          <span>{mainPrescriber}</span>
                        </Table.Cell>
                        <Table.Cell data-label="Status">
                          <span>
                            {String(i) === selectedRow ? (
                              <Dropdown
                                name="visitStatus"
                                placeholder=""
                                fluid
                                selection
                                options={statuses}
                                width={12}
                                onChange={this.handleStatusChange}
                                value={visitStatus}
                              />
                            ) : (
                              <span>{visitStatusName}</span>
                            )}
                          </span>
                        </Table.Cell>
                        <Table.Cell data-label="Visit Type">
                          <span>{visitTypeName}</span>
                        </Table.Cell>
                        <Table.Cell data-label="Visit Type">
                          <span>{homeCenterName}</span>
                        </Table.Cell>
                        <Table.Cell data-label="Level" className="text-center">
                          {isVisitTypeMedical(visitType) ? (
                            <PatientLevel level={patientLevel || "orange"} size="sm" />
                          ) : (
                            bhLevel && <PatientLevel level={bhLevel} size="sm" bh />
                          )}
                        </Table.Cell>
                      </Table.Row>
                    )
                  )}
                </Table.Body>
                <Table.Footer />
              </Table>
            </div>
          </div>
        ) : null}
      </React.Fragment>
    );
  }
}
function mapStateToProps(state) {
  return {
    myCalendar: state.visits.myCalendar,
    visitTypes: state.lookups.visitTypes,
    statuses: state.lookups.visitStatuses,
    visitStatusFilter: state.visits.visitStatusFilter
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...visitActions,
        ...modalActions,
        ...badgeActions
      },
      dispatch
    )
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(StatusWatchMyPatientTable);
