import React from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import { dateFormat, dateWithTimeFormat } from "../../../constants/miscellaneous";
import { Alert, Button, Card, Container, Row, Col, Icon, Table, Tooltip, OverlayTrigger } from "../../../ui";
import {
  reviewedLabResultsSelectors,
  labResultsReviewDetailsSelectors,
  useGetLabResultsReviewDetailsQuery,
  useGetReviewedLabResultsQuery,
  useReviewLabResultMutation
} from "./labsSlice";

// TODO export

function LabResult({ patientId, reviewVisitId, result, reviewed, userCanReview, view }) {
  const [reviewLabResult, { isLoading }] = useReviewLabResultMutation();
  const handleReviewedClick = async (body) => {
    if (!isLoading) {
      try {
        reviewLabResult({ patientId, body });
      } catch (err) {
        // console.error("Failed to reviewed: ", err);
      }
    }
  };
  /**
   * reviewDetails is an array because business said we will allow multi review by multi doctors in the future
   */
  return (
    <>
      {false &&
        view === "summary" &&
        result.reviewDetails.map((reviewDetails) => (
          <tr key={reviewDetails.reviewDate}>
            <td colSpan={8} className="text-center text-secondary">
              {result.labProvider === "Clean Slate Rapid" && (
                <strong>{`${reviewDetails.providerFirstName} ${reviewDetails.providerLastName} reviewed rapid test results taken during this visit`}</strong>
              )}
              {result.labProvider !== "Clean Slate Rapid" && (
                <strong>{`${reviewDetails.providerFirstName} ${
                  reviewDetails.providerLastName
                } reviewed results from ${moment(reviewDetails.reviewDate).format(dateWithTimeFormat)}`}</strong>
              )}
            </td>
          </tr>
        ))}
      <tr>
        <td rowSpan={result.childLabs.length + 1} className="text-center">
          <strong>{result.parentLabName}</strong>
          {result.submissionComment && (
            <OverlayTrigger
              placement="right"
              overlay={
                <Tooltip id="tooltip-11">
                  <span>
                    Note sent with order:&nbsp;
                    {result.submissionComment.split(/(?:\r\n|\r|\n)/g).map((i) => (
                      <React.Fragment key={`submission-comment-${result.emrOrderId}-${result.parentLabCode}`}>
                        {i === null ? "" : i.replace(/ /g, "\u00a0")}
                        <br />
                      </React.Fragment>
                    ))}
                  </span>
                </Tooltip>
              }
            >
              <Icon name="chat-fill" className="ms-2 text-warning" />
            </OverlayTrigger>
          )}
          <br />
          {result.labProvider}
          <br />
          {reviewed && view === "labs" && (
            <Container className="mt-3 mb-2 text-muted">
              <Row>
                <Col className="p-0 text-center">
                  <Icon name="eye" className="me-2" />
                  Reviewed
                </Col>
              </Row>
              <Row>
                <Col className="p-0 text-center">
                  {result.reviewDetails.map((reviewDetails) => (
                    <React.Fragment key={reviewDetails.reviewDate}>
                      {moment(reviewDetails.reviewDate).format(dateWithTimeFormat)}
                      {` - `}
                      {`${reviewDetails.providerFirstName} ${reviewDetails.providerLastName}`}
                    </React.Fragment>
                  ))}
                </Col>
              </Row>
            </Container>
          )}
          {!reviewed && userCanReview && (
            <Button
              variant="primary"
              className="mt-3 mb-2"
              loading={isLoading}
              disabled={isLoading}
              onClick={() => {
                handleReviewedClick({
                  orderId: result.emrOrderId,
                  visitId: reviewVisitId,
                  labCodes: [result.parentLabCode]
                });
              }}
            >
              <Icon name="eye" className="me-2" />
              Reviewed
            </Button>
          )}
        </td>
        <td />
        <td />
        <td />
        <td />
        <td>
          <strong>{moment(result.sampleDate).format(dateFormat)}</strong>
        </td>
        <td>
          <strong>{moment(result.resultDate).format(dateFormat)}</strong>
        </td>
        <td>
          <strong>{result.isConfirmatory ? `Confirmatory` : `Primary`}</strong>
        </td>
      </tr>
      {result.childLabs.map((child) => (
        <tr key={child.labParameter} className={child.isResultNormal ? `` : `table-danger`}>
          <td>{child.labName}</td>
          <td>{child.result}</td>
          <td>{child.reference}</td>
          <td>{child.status}</td>
          <td colSpan={3}>
            {child.noteFromLab ? (
              <React.Fragment>
                Note from Lab:
                <Alert variant="warning">
                  {JSON.parse(child.noteFromLab).map((note, i) => (
                    <React.Fragment
                      // eslint-disable-next-line react/no-array-index-key
                      key={`note-from-lab-${child.emrOrderId}-${child.parentLabCode}-${child.labParameter}-${i}`}
                    >
                      {note === null ? "" : note.replace(/ /g, "\u00a0")}
                      <br />
                    </React.Fragment>
                  ))}
                </Alert>
              </React.Fragment>
            ) : null}
          </td>
        </tr>
      ))}
    </>
  );
}

function LabResultsReviewDetails({
  patientId,
  visitId,
  orderId,
  includeNotReviewedResults,
  reviewVisitId,
  userCanReview,
  view
}) {
  const { isLoading, isSuccess, error } =
    view === "labs"
      ? useGetLabResultsReviewDetailsQuery({ patientId, orderId })
      : useGetReviewedLabResultsQuery({ patientId, visitId });

  const data =
    view === "labs"
      ? (
          useSelector((state) => labResultsReviewDetailsSelectors(state, { patientId, orderId })).selectAll() || []
        ).filter((item) => item.visitId === visitId)
      : useSelector((state) => reviewedLabResultsSelectors(state, { patientId, visitId })).selectAll() || [];
  let tbody = null;
  if (isLoading)
    tbody = (
      <tr>
        <td colSpan={8}>Loading...</td>
      </tr>
    );

  if (!isLoading && isSuccess)
    tbody =
      data.length > 0 ? (
        data
          .filter((result) => (includeNotReviewedResults ? !!result.visitId : result.reviewDetails !== []))
          .sort((a, b) => b.resultDate - a.resultDate)
          .map((result) => (
            <LabResult
              key={`${result.visitId}-${result.parentLabCode}`}
              view={view}
              patientId={patientId}
              reviewVisitId={reviewVisitId}
              result={result}
              userCanReview={userCanReview}
              reviewed={result.reviewDetails.length > 0}
            />
          ))
      ) : (
        <tr>
          <td colSpan={8} className="text-center text-info">
            No results to display
          </td>
        </tr>
      );

  if (error)
    tbody = (
      <tr className="table-danger">
        <td colSpan={8}>
          [{error.status}] {error.data.message}
        </td>
      </tr>
    );

  return tbody;
}

function VisitResultsReview({ view = "labs", visitId, orderId, includeNotReviewedResults, userCanReview }) {
  const patientId = useSelector((state) => state.patient.currentPatient.patientId);
  const provider = useSelector((state) => state.visits.patientVisits.visits).find(
    (v) => v.visitId === visitId
  )?.prescribingProvider;
  const reviewVisitId = useSelector((state) => state.visits.contextVisit)?.visitId;

  return (
    <Container>
      <Row>
        <Col>
          <Card className="my-2">
            <Card.Header>
              {view === "summary"
                ? `${provider.firstName} ${provider.lastName} reviewed following results during this visit`
                : `Lab Results`}
            </Card.Header>
            <Card.Body>
              <Table size="sm" responsive hover className="mb-0">
                <thead className="table-secondary">
                  <tr>
                    <td>TEST/LAB</td>
                    <td>PANEL</td>
                    <td>RESULT</td>
                    <td>REFERENCE</td>
                    <td>STATUS</td>
                    <td>SAMPLE DATE</td>
                    <td>RESULT DATE</td>
                    <td>TYPE</td>
                  </tr>
                </thead>

                <tbody>
                  <LabResultsReviewDetails
                    patientId={patientId}
                    visitId={visitId}
                    orderId={orderId}
                    includeNotReviewedResults={includeNotReviewedResults}
                    reviewVisitId={reviewVisitId}
                    userCanReview={userCanReview}
                    view={view}
                  />
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default VisitResultsReview;
