import React, { Component } from "react";
import { Button, Header, Modal, Input, List } from "semantic-ui-react";
import { isEmpty } from "lodash";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as modalActions from "../../../../actions/modalActions";
import * as searchActions from "../../../../actions/searchActions";
import { largeResultMessage } from "../../../../constants/validation";

import "../../../reusable/Modal.css";
import "../PatientHistorySearchModal.css";

const getInitialSearch = (props) => props.searchValue || "";

const getSearchError = (props) =>
  (props.searchValue || "").length >= 3 ? "" : "Please type more than 3 characters to search.";

export class SearchAllergyModal extends Component {
  state = {
    searchAllergy: getInitialSearch(this.props),
    searchedAllergy: getInitialSearch(this.props),
    selectedAllergy: {},
    searchError: getSearchError(this.props)
  };

  componentDidMount() {
    const { searchValue } = this.props;
    if ((searchValue || []).length >= 3) {
      this.props.actions.searchAllergy({ allergyName: searchValue });
    }
  }

  componentWillUnmount() {
    this.props.actions.clearSearch("allergies");
  }

  handleSearchInput = (e, data) => {
    this.setState({ searchAllergy: data.value });
  };

  handleSearchEnter = (e) => {
    if (e.key === "Enter") {
      const { searchAllergy } = this.state;
      this.setState({ searchedAllergy: searchAllergy, selectedAllergy: {} }, () => this.handleSearch());
    }
  };

  handleResultClick = (e, data) => {
    const { index } = data;
    const { allergies } = this.props;
    const selectedAllergy = allergies[index];
    this.setState({ selectedAllergy });
  };

  handleSearch = () => {
    const { searchAllergy } = this.state;
    this.props.actions.clearSearch("allergies");
    if (searchAllergy.length >= 3) {
      this.props.actions.searchAllergy({ allergyName: searchAllergy });
      this.setState({
        searchError: ""
      });
    } else {
      this.setState({
        searchError: "Please type more than 3 characters to search."
      });
    }
  };

  handleClose = () => {
    this.props.actions.hideModal();
    this.props.actions.clearSearch("allergies");
  };

  handleModalNav = () => {
    const { selectedAllergy, searchedAllergy } = this.state;
    return this.props.actions.showModal({
      type: "ADD_ALLERGY",
      props: {
        open: true,
        allergyHistory: {
          allergySource: selectedAllergy.description,
          allergyKey: selectedAllergy.allergyKey,
          searchedAllergy
        }
      }
    });
  };

  render() {
    const { open, allergies, processing } = this.props;
    const { searchAllergy, searchedAllergy, selectedAllergy, searchError } = this.state;
    const items = allergies.slice(0, 50).map((allergy, i) => (
      <List.Item
        key={allergy.allergyKey}
        index={i}
        value={allergy.allergyKey.toString()}
        active={
          selectedAllergy.allergyKey === allergy.allergyKey && selectedAllergy.conceptType === allergy.conceptType
        }
        onClick={this.handleResultClick}
      >
        {`${allergy.description}${isEmpty(allergy.conceptType) ? `` : ` (Concept Type: ${allergy.conceptType})`}`}
      </List.Item>
    ));
    const disableButton = !searchAllergy || !selectedAllergy;
    return (
      <Modal className="search-patient-history-modal" open={open} onClose={this.handleClose} closeIcon size="small">
        <Header content="Search Allergies" />
        <Modal.Content>
          <div className="search-container-modal">
            <Input
              icon="search"
              placeholder="Search..."
              onKeyPress={this.handleSearchEnter}
              onChange={this.handleSearchInput}
              value={searchAllergy}
              id="input-search-search-allergy"
            />
          </div>
          <div className="medication-search-results">
            {selectedAllergy.allergyKey ? (
              <h3 id="lbl-selected">
                You selected:{" "}
                {`${selectedAllergy.description}${
                  isEmpty(selectedAllergy.conceptType) ? `` : ` (Concept Type: ${selectedAllergy.conceptType})`
                }`}
              </h3>
            ) : (
              searchedAllergy && <h3>Search results for: {searchedAllergy}</h3>
            )}
            {allergies.length > 50 ? <div className="large-result-message">{largeResultMessage}</div> : null}
            {searchedAllergy ? (
              <List className="search-results-list" selection>
                {searchError.length ? <div id="searchError">{searchError}</div> : items}
              </List>
            ) : null}
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.handleClose} id="btn-cancel">
            Cancel
          </Button>
          <Button
            disabled={disableButton}
            onClick={this.handleModalNav}
            loading={processing}
            color="blue"
            id="btn-next"
          >
            {selectedAllergy.allergyKey ? "Next" : "Free Text Next"}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    allergies: state.search.allergies || [],
    processing: state.ajaxCallsInProgress > 0
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...modalActions, ...searchActions }, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchAllergyModal);
