import React from "react";
import { Grid, Form, Icon, Tab, Table, Pagination } from "semantic-ui-react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import { set, isEmpty } from "lodash";
import * as modalActions from "../../actions/modalActions";
import * as adminActions from "../../actions/adminActions";
import * as errorLogsActions from "../../actions/errorLogsActions";
import { dateWithFullTimeFormat, monthsOptions, daysOptions, getYearOptions } from "../../constants/miscellaneous";
import QueryBuilder from "../common/QueryBuilder";
import "./ManageErrorsPage.css";

const yearsOptions = getYearOptions();

// set inital value to ErrorId keyword
const initKeywordValues = {
  type: "group",
  id: "9a99988a-0123-4456-b89a-b1607f326fd8",
  children1: {
    1: {
      type: "rule",
      properties: {
        field: "0", // for errorId
        operator: "equal",
        value: [],
        valueSrc: ["value"],
        valueType: ["int64"]
      }
    }
  },
  properties: {
    conjunction: "OR",
    not: false
  }
};

const ErrorKeywordValueErrorLogId = "ErrorKeywordValue.ErrorLogId";

export class ManageErrorsPage extends React.Component {
  static m = moment();
  state = {
    filters: {
      from: {
        day: "",
        month: "",
        year: ""
      },
      to: {
        day: "",
        month: "",
        year: ""
      },
      filterQuery: {}
    },
    activeTabIndex: 0,
    activePage: 1,
    orderBySourceHandler: false,
    pageSize: 10,
    pageNumber: 1,
    column: null,
    direction: null,
    queryFields: null
  };

  constructor(props) {
    super(props);
    this.props.actions.pushItemToBreadcrumb({ key: "bc-error-admin", to: "/error-admin", text: "Error Admin" });
  }

  componentDidMount() {
    this.props.actions.loadErrorKeywords().then(() => {
      const errorKeywords = this.props.errorKeywords;
      // append errorId field to the filter list
      const fields = {
        errorId: {
          // for errorId
          label: "ErrorId",
          type: "int64",
          valueSources: ["value"],
          // confusing: this is the tableName.fieldName
          tableName: ErrorKeywordValueErrorLogId,
          fieldSettings: {
            min: 1
          }
        }
      };
      if (!isEmpty(errorKeywords)) {
        errorKeywords.forEach((errorKeyword) => {
          fields[errorKeyword.id] = {
            type: errorKeyword.dataType,
            label: errorKeyword.keyword,
            valueSources: ["value"]
          };
        });
        this.setState({ queryFields: fields });
      }
    });
    this.loadErrors();
  }

  handleTabChange = (e, { activeIndex }) => {
    this.setState({ activeTabIndex: activeIndex, pageNumber: 1 }, () => this.loadErrors());
  };

  handlePaginationChange = (e, { activePage }) => {
    if (activePage >= 1) {
      this.setState({ pageNumber: activePage }, () => this.loadErrors());
    }
  };

  loadErrors = () => {
    this.handleUpddate();
  };

  handleFocus = (e) => {
    e.target.setAttribute("autocomplete", "nope");
  };

  handleInput = (e, data) => {
    const { name: field, value } = data;
    const newState = { ...this.state };
    set(newState, field, value);
    this.setState(newState);
  };

  mapErrorKeywordsInQuery = (query) => {
    // add extra rule for error keyword fields to include the KeywordId in the query
    if (query && !isEmpty(query.rules)) {
      const filterQuery = { ...query, rules: [] };
      query.rules.forEach((rule, index) => {
        if (rule.fieldName !== ErrorKeywordValueErrorLogId) {
          // exclude errorId field, not required to add KeywordId condition
          const ruleGroup = {};
          ruleGroup.condition = "AND";
          ruleGroup.rules = [
            {
              id: index.toString(),
              fieldName: "ErrorKeywordValue.KeywordId",
              type: "number",
              input: "number",
              operator: "equal",
              values: [{ type: "number", value: +rule.fieldName }]
            },
            { ...rule, fieldName: "ErrorKeywordValue.KeywordValue" }
          ];
          filterQuery.rules.push(ruleGroup);
        } else {
          filterQuery.rules.push({ ...rule });
        }
      });
      return filterQuery;
    }
    return {};
  };

  handleFilterQuery = (query) => {
    this.setState({
      ...this.state,
      filters: { ...this.state.filters, filterQuery: query }
    });
  };

  handleCheckbox = (e, data) => {
    const { checked } = data;
    this.setState({ orderBySourceHandler: checked });
  };

  handleSort = () => {};

  handleUpddate = () => {
    const { activeTabIndex, pageSize, pageNumber, filters, orderBySourceHandler } = this.state;
    const modifiedFilters = { ...filters, filterQuery: this.mapErrorKeywordsInQuery(filters.filterQuery) };
    this.props.actions.loadErrorLogs(pageSize, pageNumber, modifiedFilters, orderBySourceHandler, activeTabIndex === 0);
  };

  handleRequeue = (id) => {
    const { activeTabIndex, pageSize, pageNumber, filters, orderBySourceHandler } = this.state;
    this.props.actions.requeueError(id).then(() => {
      this.props.actions.loadErrorLogs(pageSize, pageNumber, filters, orderBySourceHandler, activeTabIndex === 0);
    });
  };

  openRequeueConfirmationModal = (id) => {
    this.props.actions.showModal({
      type: "CONFIRMATION",
      props: {
        open: true,
        icon: "exclamation-triangle",
        title: "Confirm Re-queue Error",
        description: "Are you sure you want to re-queue this error?",
        buttonMessage: "Re-queue",
        buttonColor: "red",
        size: "mini",
        onConfirm: () => this.handleRequeue(id)
      }
    });
  };

  handleDismiss = (id) => {
    const { activeTabIndex } = this.state;
    this.props.actions.dismissError(id, activeTabIndex === 0).then(() => this.handleUpddate());
  };

  handleEdit = (id) => {
    this.showErrorDetailsModal(id, true);
  };

  showErrorDetailsModal = (id, editMode = false) => {
    this.props.actions.showModal({
      type: "ERROR_DETAILS",
      props: {
        open: true,
        errorId: id,
        editMode,
        recoverable: this.state.activeTabIndex === 0,
        adminPageState: this.state
      }
    });
  };

  openDismissConfirmationModal = (id) => {
    this.props.actions.showModal({
      type: "CONFIRMATION",
      props: {
        open: true,
        icon: "exclamation-triangle",
        title: "Confirm Dismiss Error",
        description: "Are you sure you want to dismiss this error?",
        buttonMessage: "Dismiss",
        buttonColor: "red",
        size: "mini",
        onConfirm: () => this.handleDismiss(id)
      }
    });
  };

  renderErrors() {
    const { column, direction, pageSize, pageNumber } = this.state;
    let errorList = {};
    if (this.state.activeTabIndex === 0) {
      errorList = this.props.recoverable;
    } else if (this.state.activeTabIndex === 1) {
      errorList = this.props.nonRecoverable;
    }
    const { errors, totalCount } = errorList;
    return (
      <div>
        {totalCount <= 0 && <div style={{ textAlign: "center", padding: "2rem" }}>No errors to display</div>}
        {totalCount > 0 && (
          <React.Fragment>
            <Table selectable sortable style={{ wordBreak: "break-all" }}>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell
                    width={2}
                    sorted={column === "timeStamp" ? direction : null}
                    onClick={this.handleSort("timeStamp")}
                  >
                    TimeStamp
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    width={1}
                    sorted={column === "id" ? direction : null}
                    onClick={this.handleSort("id")}
                  >
                    ErrorID
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    width={2}
                    sorted={column === "sourceHandler" ? direction : null}
                    onClick={this.handleSort("sourceHandler")}
                  >
                    SourceHandler
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    width={3}
                    sorted={column === "errorType" ? direction : null}
                    onClick={this.handleSort("errorType")}
                  >
                    ErrorType
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    width={3}
                    sorted={column === "errorSource" ? direction : null}
                    onClick={this.handleSort("errorSource")}
                  >
                    ErrorSource
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    width={3}
                    sorted={column === "errorMessage" ? direction : null}
                    onClick={this.handleSort("errorMessage")}
                  >
                    ErrorMessage
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    width={2}
                    sorted={column === "sourceMessageType" ? direction : null}
                    onClick={this.handleSort("sourceMessageType")}
                  >
                    SourceMessageType
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    width={2}
                    sorted={column === "orchestrationId" ? direction : null}
                    onClick={this.handleSort("orchestrationId")}
                  >
                    OrchestrationId
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    width={1}
                    sorted={column === "identityContext" ? direction : null}
                    onClick={this.handleSort("identityContext")}
                  >
                    IdentityContext
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    width={1}
                    sorted={column === "username" ? direction : null}
                    onClick={this.handleSort("username")}
                  >
                    UserName
                  </Table.HeaderCell>
                  <Table.HeaderCell width={1}>Action</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {errors.map(
                  ({
                    timeStamp,
                    id,
                    sourceHandler,
                    errorType,
                    errorSource,
                    errorMessage,
                    sourceMessageType,
                    orchestrationId,
                    identityContext,
                    username
                  }) => (
                    <Table.Row key={id}>
                      <Table.Cell width={2}>{moment(timeStamp).format(dateWithFullTimeFormat)}</Table.Cell>
                      <Table.Cell width={1} className="id-column">
                        <a role="button" tabIndex={0} onClick={() => this.showErrorDetailsModal(id)}>
                          {id}
                        </a>
                      </Table.Cell>
                      <Table.Cell width={2}>{sourceHandler}</Table.Cell>
                      <Table.Cell width={3}>{errorType}</Table.Cell>
                      <Table.Cell width={3}>{errorSource}</Table.Cell>
                      <Table.Cell width={3}>{errorMessage}</Table.Cell>
                      <Table.Cell width={2}>{sourceMessageType}</Table.Cell>
                      <Table.Cell width={2}>{orchestrationId}</Table.Cell>
                      <Table.Cell width={1}>{identityContext}</Table.Cell>
                      <Table.Cell width={1}>{username}</Table.Cell>
                      <Table.Cell width={1} className="actions-column" style={{ padding: "0", textAlign: "center" }}>
                        {this.state.activeTabIndex === 0 && (
                          <React.Fragment>
                            <Icon
                              name="undo"
                              color="blue"
                              onClick={() => this.openRequeueConfirmationModal(id)}
                              style={{ margin: "2px" }}
                            />
                            <Icon
                              name="edit"
                              color="blue"
                              onClick={() => this.handleEdit(id)}
                              style={{ margin: "2px" }}
                            />
                          </React.Fragment>
                        )}
                        <Icon
                          name="close"
                          color="blue"
                          onClick={() => this.openDismissConfirmationModal(id)}
                          style={{ margin: "2px" }}
                        />
                      </Table.Cell>
                    </Table.Row>
                  )
                )}
              </Table.Body>
              <Table.Footer />
            </Table>

            <div style={{ padding: "0 0 1em 0", textAlign: "center" }}>
              <Pagination
                boundaryRange={2}
                // defaultActivePage={1}
                activePage={pageNumber}
                ellipsisItem={`...`}
                size="mini"
                firstItem={null}
                lastItem={null}
                siblingRange={1}
                totalPages={Math.ceil(totalCount / pageSize)}
                onPageChange={this.handlePaginationChange}
              />
            </div>
          </React.Fragment>
        )}
      </div>
    );
  }

  render() {
    const { filters, orderBySourceHandler, activeTabIndex, queryFields } = this.state;
    const panes = [
      { menuItem: "Recoverable", render: () => <Tab.Pane attached={false}>{this.renderErrors()}</Tab.Pane> },
      { menuItem: "Non-recoverable", render: () => <Tab.Pane attached={false}>{this.renderErrors()}</Tab.Pane> }
    ];
    return (
      <React.Fragment>
        <div className="header-wrapper">
          <h2>Error Admin</h2>
        </div>
        <div className="content-wrapper errors">
          <Grid>
            <React.Fragment>
              <Grid.Row>
                <Grid.Column width={11}>
                  <Form>
                    <Grid>
                      <Grid.Row style={{ paddingBottom: "0" }}>
                        <Grid.Column width={6}>
                          From
                          <br />
                          <Form.Group widths="equal">
                            <Form.Dropdown
                              fluid
                              selection
                              options={monthsOptions}
                              name="filters.from.month"
                              value={filters.from.month}
                              onChange={this.handleInput}
                              onFocus={this.handleFocus}
                            />
                            <Form.Dropdown
                              fluid
                              selection
                              options={daysOptions}
                              name="filters.from.day"
                              value={filters.from.day}
                              onChange={this.handleInput}
                              onFocus={this.handleFocus}
                            />
                            <Form.Dropdown
                              fluid
                              selection
                              options={yearsOptions}
                              name="filters.from.year"
                              value={filters.from.year}
                              onChange={this.handleInput}
                              onFocus={this.handleFocus}
                            />
                          </Form.Group>
                        </Grid.Column>
                        <Grid.Column width={6}>
                          To
                          <br />
                          <Form.Group widths="equal">
                            <Form.Dropdown
                              fluid
                              selection
                              options={monthsOptions}
                              name="filters.to.month"
                              value={filters.to.month}
                              onChange={this.handleInput}
                              onFocus={this.handleFocus}
                            />
                            <Form.Dropdown
                              fluid
                              selection
                              options={daysOptions}
                              name="filters.to.day"
                              value={filters.to.day}
                              onChange={this.handleInput}
                              onFocus={this.handleFocus}
                            />
                            <Form.Dropdown
                              fluid
                              selection
                              options={yearsOptions}
                              name="filters.to.year"
                              value={filters.to.year}
                              onChange={this.handleInput}
                              onFocus={this.handleFocus}
                            />
                          </Form.Group>
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row style={{ paddingTop: "0", paddingBottom: "0" }}>
                        <Grid.Column width={12}>
                          Filter keywords:{" "}
                          {queryFields && (
                            <QueryBuilder
                              conjunctions="or"
                              operators="all"
                              fields={queryFields}
                              queryChanged={this.handleFilterQuery}
                              initialValue={initKeywordValues}
                            />
                          )}
                        </Grid.Column>
                        <Grid.Column width={4}>
                          <Form.Button color="blue" onClick={this.handleUpddate}>
                            Update
                            <Icon name="search" style={{ marginLeft: "5px" }} />
                          </Form.Button>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Form>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row style={{ paddingBottom: "0" }}>
                <Grid.Column width={5}>
                  <Form.Checkbox
                    label="Sort by source handler"
                    name="orderBySourceHandler"
                    checked={orderBySourceHandler}
                    onChange={this.handleCheckbox}
                  />
                </Grid.Column>
              </Grid.Row>
            </React.Fragment>
            <Grid.Row style={{ paddingTop: "0" }}>
              <Grid.Column>
                <Tab
                  menu={{ secondary: true, pointing: true }}
                  panes={panes}
                  className="error-tab"
                  activeIndex={activeTabIndex}
                  onTabChange={this.handleTabChange}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    recoverable: state.errorLogs.recoverable,
    nonRecoverable: state.errorLogs.nonRecoverable,
    errorKeywords: state.errorLogs.errorKeywords
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...modalActions, ...adminActions, ...errorLogsActions }, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageErrorsPage);
