import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function insuranceReducer(state = initialState.insurance, action) {
  switch (action.type) {
    case types.LOAD_PATIENT_INSURANCE_SUCCESS:
      return Object.assign({}, state, action.insurance);
    case types.CLEAR_PATIENT_INSURANCE_SUCCESS:
      return Object.assign({}, state, []);
    case types.CREATE_INSURANCE_SUCCESS:
      return Object.assign({}, state, {
        [action.insuranceType]: action.insurance
      });
    case types.CREATE_UDS_PRIOR_AUTH_SUCCESS:
      return Object.assign({}, state, {
        udsPriorAuthorizations: [action.priorAuth, ...state.udsPriorAuthorizations]
      });
    case types.CREATE_MED_PRIOR_AUTH_SUCCESS:
      return Object.assign({}, state, {
        medicationPriorAuthorizations: [action.medicationPriorAuth, ...state.medicationPriorAuthorizations]
      });
    case types.CREATE_REFERRAL_SUCCESS:
      return Object.assign({}, state, {
        referrals: [action.referral, ...state.referrals]
      });
    case types.LOAD_INSURANCE_HISTORY_SUCCESS:
      return Object.assign({}, state, {
        insuranceHistory: action.insuranceHistory
      });
    case types.LOAD_BENEFIT_INFORMATION_SUCCESS:
      return { ...state, benefitsInformation: action.benefitsInformation };
    case types.LOAD_DRUG_HISTORY_SUCCESS:
      return Object.assign({}, state, { drugHistory: action.drugHistory });
    default:
      return state;
  }
}
