import React, { Component } from "react";
import isEmpty from "lodash/isEmpty";
import { Button, Dropdown } from "semantic-ui-react";

class DefaultAnswersEditor extends Component {
  state = {
    defaultAnswers: this.props.question.defaultAnswers
  };

  onChange = (e, data) => {
    this.setState({ [data.name]: data.value });
  };

  reset = () => {
    this.props.update("defaultAnswers", []);
  };

  set = () => {
    this.props.update("defaultAnswers", this.state.defaultAnswers);
  };

  remove = trigger => {
    const { question, update } = this.props;
    const triggers = [...question.triggers];
    const index = triggers.findIndex(
      t =>
        t.questionKey === trigger.questionKey &&
        t.matchOperator === trigger.matchOperator &&
        t.matchValue === trigger.matchValue
    );
    triggers.splice(index, 1);
    update("triggers", triggers);
  };

  render() {
    const { question } = this.props;
    const { defaultAnswers } = this.state;
    return (
      <div className="ui grid" style={{ marginTop: "0" }}>
        {question.type === "ShortText" && (
          <input
            name="defaultAnswers"
            type="text"
            value={isEmpty(defaultAnswers) ? "" : defaultAnswers[0]}
            onChange={e => this.onChange(e, { name: "defaultAnswers", value: [e.target.value] })}
          />
        )}
        {question.type === "LongText" && (
          <textarea
            name="defaultAnswers"
            type="text"
            value={isEmpty(defaultAnswers) ? "" : defaultAnswers[0]}
            onChange={e => this.onChange(e, { name: "defaultAnswers", value: [e.target.value] })}
          />
        )}
        {["SingleSelect", "MultiSelect"].includes(question.type) && (
          <Dropdown
            name="defaultAnswers"
            fluid
            selection
            multiple={question.type === "MultiSelect"}
            value={question.type === "MultiSelect" ? defaultAnswers : isEmpty(defaultAnswers) ? "" : defaultAnswers[0]}
            options={question.options.map(o => ({ text: o, value: o }))}
            onChange={(e, data) =>
              this.onChange(e, {
                name: "defaultAnswers",
                value: question.type === "MultiSelect" ? data.value : [data.value]
              })
            }
          />
        )}
        <div className="sixteen wide column">
          <Button type="button" color="blue" size="mini" floated="right" onClick={this.set}>
            Set
          </Button>
          <Button type="button" color="red" size="mini" floated="right" onClick={this.reset}>
            Reset
          </Button>
        </div>
      </div>
    );
  }
}

export default DefaultAnswersEditor;
