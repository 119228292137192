import React, { Component } from "react";
import { Form } from "semantic-ui-react";
import MaskedInput from "react-text-mask";
import { map, isEqual, isEmpty, lowerCase, startCase, toLower } from "lodash";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Container, Row, Col, Button } from "../../../../ui";
import * as lookupActions from "../../../../actions/lookupActions";
import * as searchActions from "../../../../actions/searchActions";
import * as demographicActions from "../../../../actions/demographicActions";
import { updateErrors, hasProperty } from "../../../../helpers";
import { npi, npiMask } from "../../../../constants/validation";
import "./DoctorSearchForm.css";

const inputValidation = {
  required: [],
  npi
};

export class DoctorSearchForm extends Component {
  state = {
    physicianSearch: {
      firstName: "",
      lastName: "",
      npi: "",
      state: ""
    },
    physicianSelected: {},
    errors: {},
    errorMessage: "",
    disableSearch: true
  };

  componentDidMount() {
    this.props.actions.loadStates();
  }

  handleFocus = (e) => {
    e.target.setAttribute("autocomplete", "nope");
  };

  handleInput = (e, data) => {
    const { name: field, value } = data;
    const physicianSearch = Object.assign({}, this.state.physicianSearch, {
      [field]: value
    });
    this.setState({ physicianSearch });
  };

  handleMaskedInput = (e) => {
    const {
      type,
      target: { name: field, required, value }
    } = e;
    let errors = Object.assign({}, this.state.errors);
    const physicianSearch = Object.assign({}, this.state.physicianSearch, {
      [field]: value
    });

    if (type !== "focus" && type !== "blur") {
      errors = updateErrors(field, value, required, errors, inputValidation[field]);
    }

    this.setState({ physicianSearch, errors });
  };

  handleSelect = (pcp) => () => {
    this.setState({ physicianSelected: pcp });
  };

  handleSearch = () => {
    // eslint-disable-next-line no-shadow
    const { firstName, lastName, npi, state } = this.state.physicianSearch;
    // build the query string
    const queryString = {
      firstName: firstName || "",
      lastName: lastName || "",
      npi: npi || "",
      state: state || "",
      pageSize: 100,
      pageNumber: 1
    };

    this.props.actions.searchPhysicians(queryString);
    this.setState({ errorMessage: "" });
  };

  handleSave = () => {
    const { fromReferrals, referral } = this.props;
    if (fromReferrals) {
      this.props.actions.showModal({
        type: "ADD_REFERRALS_MODAL",
        props: {
          open: true,
          npi: this.state.physicianSelected.npi,
          referral
        }
      });
      this.props.actions.clearSearch("physicians");
    } else {
      const physicianSelected = Object.assign({}, this.state.physicianSelected, {
        telephoneNumber: this.state.physicianSelected.phone
      });
      this.props.actions.createPrimaryCarePhysician(this.props.patientId, physicianSelected).then(() => {
        this.props.actions.clearSearch("physicians");
        if (this.props.afterSave) this.props.afterSave();
      });
    }
  };

  handleBlur = () => {
    // eslint-disable-next-line no-shadow
    const { firstName, lastName, npi } = this.state.physicianSearch;
    if (npi && npi.length !== 10) {
      const message =
        "Please enter a valid 10 digit NPI number or the first two letters of first and last name to search";
      this.setState({ errorMessage: message, disableSearch: true });
    } else if (
      npi.length === 0 &&
      ((firstName.length < 2 && lastName.length < 2) ||
        (firstName.length === 2 && lastName.length < 2) ||
        (lastName.length === 2 && firstName.length < 2))
    ) {
      const message =
        "Please enter a valid 10 digit NPI number or the first two letters of first and last name to search";
      this.setState({ errorMessage: message, disableSearch: true });
    } else {
      this.setState({ errorMessage: "", disableSearch: false });
    }
  };

  clearState = () => {
    const physicianSearch = Object.assign({}, this.state.physicianSearch, {
      state: ""
    });
    this.setState({ physicianSearch });
  };

  render() {
    const { physicianSelected, physicianSearch, errorMessage, disableSearch, errors } = this.state;
    const { states, physicians, processing, saveButtonText, saveButtonIcon, hideCancelButton } = this.props;

    return (
      <Container className="p-0">
        <Row className="ps-2">
          <Col width={6}>
            <Form>
              <Form.Group>
                <Form.Field width={14}>
                  <Form.Input
                    label="First Name"
                    name="firstName"
                    value={physicianSearch.firstName}
                    maxLength={50}
                    onChange={this.handleInput}
                    id="input-firstName"
                    onBlur={this.handleBlur}
                  />
                </Form.Field>
              </Form.Group>
              <Form.Group>
                <Form.Field width={14}>
                  <Form.Input
                    label="Last Name"
                    name="lastName"
                    value={physicianSearch.lastName}
                    maxLength={150}
                    onChange={this.handleInput}
                    id="input-lastName"
                    onBlur={this.handleBlur}
                  />
                </Form.Field>
              </Form.Group>
              <Form.Group>
                <Form.Field width={14} className={hasProperty(errors, "npi") ? "error field" : "field"}>
                  <label htmlFor="input-npi">NPI</label>
                  <MaskedInput
                    name="npi"
                    mask={npiMask}
                    guide={false}
                    value={physicianSearch.npi}
                    maxLength={50}
                    onChange={this.handleMaskedInput}
                    id="input-npi"
                    onBlur={this.handleBlur}
                    // error={hasProperty(errors, "npi")} no such property!
                  />
                </Form.Field>
              </Form.Group>
              <Form.Group>
                <Form.Field width={14}>
                  <Form.Dropdown
                    fluid
                    selection
                    search
                    label="State"
                    placeholder="Select..."
                    options={states}
                    name="state"
                    value={physicianSearch.state}
                    onChange={this.handleInput}
                    id="input-state"
                    onFocus={this.handleFocus}
                  />
                </Form.Field>
              </Form.Group>
              <div className="search-error">{errorMessage}</div>
              <Button disabled={disableSearch} onClick={this.handleSearch} variant="primary" loading={processing}>
                Search
              </Button>
            </Form>
          </Col>
          <Col width={10}>
            <div className="pcp-search-results">
              {physicians.length === 0 || physicians.result_count === 0 ? (
                <ul className="no-results">
                  <li>currently no results</li>
                </ul>
              ) : (
                <ul>
                  {map(physicians, (physician) => (
                    <li key={physician.npi} className={isEqual(physician, physicianSelected) ? "selected" : ""}>
                      <div
                        role="button"
                        tabIndex={0}
                        key={physician.npi}
                        onClick={this.handleSelect(physician)}
                        onKeyDown={() => {}}
                      >
                        {`${startCase(toLower(physician.firstName))} ${startCase(
                          toLower(physician.lastName)
                        )} (${startCase(toLower(physician.city))}, ${physician.state})`}
                        {isEqual(physician, physicianSelected) && (
                          <>
                            <p className="mb-0">{`${startCase(lowerCase(physician.address1))} ${startCase(
                              lowerCase(physician.address2)
                            )}`}</p>
                            <p className="mb-0">{`${startCase(lowerCase(physician.city))}, ${physician.state} ${
                              physician.zip
                            }-${physician.zip4}`}</p>
                            <p className="mb-0">{`Phone: ${physician.phone}`}</p>
                          </>
                        )}
                      </div>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </Col>
        </Row>
        <Row className="mt-2 p-2" style={{ borderTop: "1px solid rgba(34,36,38,.15)" }}>
          <Col className="">
            {hideCancelButton !== true && (
              <Button variant="danger" onClick={this.props.handleCancel} className="float-start">
                Cancel
              </Button>
            )}
            <Button
              variant="primary"
              onClick={this.handleSave}
              disabled={processing || isEmpty(physicianSelected)}
              loading={processing}
              className="float-end"
              style={{ minWidth: "100px" }}
            >
              {saveButtonText || `Save`}
              {saveButtonIcon && <i className={saveButtonIcon} />}
            </Button>
          </Col>
        </Row>
      </Container>
    );
  }
}

function mapStateToProps(state) {
  return {
    patientId: state.patient.currentPatient.patientId,
    states: state.lookups.states,
    physicians: state.search.physicians,
    processing: state.ajaxCallsInProgress > 0
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...lookupActions,
        ...searchActions,
        ...demographicActions
      },
      dispatch
    )
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DoctorSearchForm);
