import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function demographicsReducer(state = initialState.demographics, action) {
  switch (action.type) {
    case types.LOAD_DEMO_SUCCESS:
      return Object.assign({}, state, action.demographics);
    case types.SET_SSN:
      return { ...state, socialSecurityNumber: action.ssn };
    case types.SET_UNMASKED_SSN:
      return { ...state, unmaskedSocialSecurityNumber: action.ssn };
    case types.LOAD_DEF_PRESC_PROV_SUCCESS:
      return Object.assign({}, state, {
        prescribingProvider: action.prescribingProvider
      });
    case types.LOAD_PERSON_ADDRESS_SUCCESS:
      return Object.assign({}, state, {
        associatedAddresses: action.personAddress
      });
    case types.DELETE_BILLING_FLAG: {
      const currentBillingIndex = state.associatedAddresses.findIndex((address) => address.isBilling === true);
      if (
        currentBillingIndex !== -1 &&
        action.address.addressId !== state.associatedAddresses[currentBillingIndex].addressId
      ) {
        return Object.assign({}, state, {
          associatedAddresses: [
            ...state.associatedAddresses.slice(0, currentBillingIndex),
            Object.assign({}, state.associatedAddresses[currentBillingIndex], {
              isBilling: false
            }),
            ...state.associatedAddresses.slice(currentBillingIndex + 1)
          ]
        });
      }
      return state;
    }
    case types.DELETE_PRIMARY_FLAG: {
      const currentPrimaryIndex = state.associatedContacts.findIndex((contact) => contact.isPrimary === true);
      if (
        currentPrimaryIndex !== -1 &&
        action.contact.personId !== state.associatedContacts[currentPrimaryIndex].personId
      ) {
        return Object.assign({}, state, {
          associatedContacts: [
            ...state.associatedContacts.slice(0, currentPrimaryIndex),
            Object.assign({}, state.associatedContacts[currentPrimaryIndex], {
              isPrimary: false
            }),
            ...state.associatedContacts.slice(currentPrimaryIndex + 1)
          ]
        });
      }
      return state;
    }
    case types.CREATE_ADDRESS_SUCCESS:
      return Object.assign({}, state, {
        associatedAddresses: [
          ...state.associatedAddresses,
          Object.assign(action.address, {
            addressId: action.response.AddressId
          })
        ]
      });
    case types.UPDATE_ADDRESS_SUCCESS:
      return Object.assign({}, state, {
        associatedAddresses: [
          ...state.associatedAddresses.filter((address) => address.addressId !== action.address.addressId),
          action.address
        ]
      });
    case types.DELETE_ADDRESS_SUCCESS:
      return Object.assign({}, state, {
        associatedAddresses: [
          ...state.associatedAddresses.filter((address) => address.addressId !== action.address.addressId)
        ]
      });
    case types.DELETE_PHONE_SUCCESS:
      return Object.assign({}, state, {
        associatedPhoneNumbers: [
          ...state.associatedPhoneNumbers.filter((phone) => phone.phoneNumberId !== action.patientPhone.phoneNumberId)
        ]
      });
    case types.CREATE_PHONE_SUCCESS:
      return Object.assign({}, state, {
        associatedPhoneNumbers: [
          ...state.associatedPhoneNumbers.map((phone) => {
            if (action.patientPhone.isPreferred === true && phone.phoneNumberId !== action.patientPhone.phoneNumberId) {
              return Object.assign({}, phone, { isPreferred: false });
            }
            return phone;
          }),
          Object.assign(action.patientPhone, {
            phoneNumberId: action.response.PhoneNumberId
          })
        ]
      });
    case types.UPDATE_PHONE_SUCCESS:
      return Object.assign({}, state, {
        associatedPhoneNumbers: [
          ...state.associatedPhoneNumbers
            .map((phone) => {
              if (action.patientPhone.isPreferred && phone.phoneNumberId !== action.patientPhone.phoneNumberId) {
                return Object.assign({}, phone, { isPreferred: false });
              }
              return phone;
            })
            .filter((patientPhone) => patientPhone.phoneNumberId !== action.patientPhone.phoneNumberId),
          action.patientPhone
        ]
      });
    case types.UPDATE_CORE_DEMO_SUCCESS:
      return Object.assign({}, state, action.coreDemographics);
    case types.CREATE_EMAIL_SUCCESS:
      return Object.assign({}, state, {
        associatedEmailAddresses: [
          ...state.associatedEmailAddresses.map((email) => {
            if (action.emailAddress.isPreferred && email.emailAddressId !== action.emailAddress.emailAddressId) {
              return Object.assign({}, email, { isPreferred: false });
            }
            return email;
          }),
          Object.assign(action.emailAddress, {
            emailAddressId: action.response.EmailAddressId
          })
        ]
      });
    case types.UPDATE_EMAIL_SUCCESS:
      return Object.assign({}, state, {
        associatedEmailAddresses: [
          ...state.associatedEmailAddresses
            .map((p) => {
              if (action.emailAddress.isPreferred === true && p.emailAddressId !== action.emailAddress.emailAddressId) {
                return Object.assign({}, p, { isPreferred: false });
              }
              return p;
            })
            .filter((temailAddress) => temailAddress.emailAddressId !== action.emailAddress.emailAddressId),
          action.emailAddress
        ]
      });
    case types.DELETE_EMAIL_SUCCESS:
      return Object.assign({}, state, {
        associatedEmailAddresses: [
          ...state.associatedEmailAddresses.filter(
            (email) => email.emailAddressId !== action.emailAddress.emailAddressId
          )
        ]
      });
    case types.CREATE_CONTACT_SUCCESS:
      return Object.assign({}, state, {
        associatedContacts: [
          ...state.associatedContacts,
          Object.assign(action.currentContact, {
            personId: action.response.AssociatedContactId
          })
        ]
      });
    case types.DELETE_CONTACT_SUCCESS:
      return Object.assign({}, state, {
        associatedContacts: [
          ...state.associatedContacts.filter((tcontact) => tcontact.personId !== action.currentContact.personId)
        ]
      });
    case types.UPDATE_CONTACT_SUCCESS:
      return Object.assign({}, state, {
        associatedContacts: [
          ...state.associatedContacts.filter((tcontact) => tcontact.personId !== action.currentContact.personId),
          action.currentContact
        ]
      });
    case types.SET_DEFAULT_PRESCRIBING_PROVIDER_DONE:
      return Object.assign({}, state, {
        prescribingProvider: action.mainPrescriber
      });
    case types.DELETE_PRESC_PROVIDER:
      return Object.assign({}, state, {
        prescribingProvider: null
      });
    case types.CREATE_PCP_SUCCESS:
      return Object.assign({}, state, {
        primaryCarePhysician: action.physician
      });
    case types.CREATE_REFERRAL_SUCCESS:
      return Object.assign({}, state, {
        referrals: action.referral
      });
    case types.CREATE_OB_PROVIDER_SUCCESS:
      return Object.assign({}, state, {
        obProvider: action.obProvider
      });
    case types.UPDATE_OB_PROVIDER_SUCCESS:
      return Object.assign({}, state, {
        obProvider: action.obProvider
      });
    case types.UPDATE_OTHER_PROVIDERS_SUCCESS:
      return { ...state, otherProviders: action.otherProviders };
    case types.CREATE_OUTSIDE_COUNSELOR_SUCCESS:
      return Object.assign({}, state, {
        outsideCounselor: action.outsideCounselor
      });
    case types.UPDATE_PSYCHIATRIST_SUCCESS:
      return { ...state, psychiatrist: action.psychiatrist };
    case types.UPDATE_OUTSIDE_COUNSELOR_SUCCESS:
      return Object.assign({}, state, {
        outsideCounselor: action.outsideCounselor
      });
    case types.UPDATE_DISCHARGE_SUCCESS:
      return { ...state, status: action.discharged ? "Discharged" : "Active" };
    case types.LOAD_PATIENT_HOMECENTER_SUCCESS:
      return Object.assign({}, state, {
        homeCenterId: action.patientHomeCenter.homeCenter
      });
    case types.LOAD_PATIENT_DISCHARGE_REASON_SUCCESS:
      return Object.assign({}, state, {
        patientDischargeReason: action.patientDischargeReason
      });
    case types.LOAD_PATIENT_DISCHARGE_SUMMARY_SUCCESS:
      return { ...state, patientDischargeSummary: action.patientDischargeSummary };
    case types.DISCHARGE_PATIENT_SUCCESS:
    case types.REVERSE_DISCHARGE_PATIENT_SUCCESS:
    default:
      return state;
  }
}
