import "react-toastify/dist/ReactToastify.css";
import * as types from "./actionTypes";
import { API_URL } from "../constants/environment";
import { beginAjaxCall, ajaxCallDone } from "./ajaxStatusActions";
import { pollApiEvents, processApiResponse, processThenThrowApiError } from "../helpers";
import { adalApiFetch } from "../adalConfig";

export function setForceReload(value) {
  return { type: types.SET_DOCUMENTS_FORCE_RELOAD, value };
}

function getDocumentSuccess(document, documentType) {
  return { type: types.GET_DOCUMENT_SUCCESS, document, documentType };
}

function uploadDocumentSuccess(response, document, documentId) {
  return {
    type: types.UPLOAD_DOCUMENT_SUCCESS,
    response,
    document,
    documentId
  };
}

function loadDocumentsSuccess(availableDocuments, numberofPages) {
  return {
    type: types.LOAD_AVAILABLE_DOCS_SUCCESS,
    availableDocuments,
    numberofPages
  };
}

function downloadDocumentsSuccess(document) {
  return {
    type: types.DOWNLOAD_DOCS_SUCCESS,
    document
  };
}

function editDocumentSuccess(response, document, documentId) {
  return {
    type: types.EDIT_DOCUMENT_SUCCESS,
    response,
    document,
    documentId
  };
}

export function getDocument(documentId, documentType, container = null) {
  const url = container
    ? `${API_URL}/Documents/DownloadFromContainer?documentKey=${documentId}&container=${container}`
    : `${API_URL}/Documents/${documentId}`;
  return dispatch => {
    dispatch(beginAjaxCall("getDocument"));
    return adalApiFetch(
      url,
      {
        headers: {
          "Content-Type": "application/json"
        }
      },
      false
    )
      .then(response => response.blob())
      .then(document => dispatch(getDocumentSuccess(document, documentType)))
      .catch(error => {
        processThenThrowApiError(error, dispatch);
      })
      .finally(() => {
        dispatch(ajaxCallDone());
      });
  };
}

export function uploadDocument(document, patientId) {
  return dispatch => {
    dispatch(beginAjaxCall("uploadDocument"));
    return adalApiFetch(`${API_URL}/Documents/${patientId}/Upload`, {
      method: "POST",
      body: document,
      headers: {}
    })
      .then(response => processApiResponse(response.orchestrationId))
      .then(orchestrationId => pollApiEvents(orchestrationId, "IDocumentUploadedEvent"))
      .then(pollResponse => {
        dispatch(uploadDocumentSuccess(pollResponse, document, pollResponse.DocumentId));
      })
      .catch(error => processThenThrowApiError(error, dispatch))
      .finally(() => dispatch(ajaxCallDone()));
  };
}

export function loadDocuments(patientId, filterTags, pageSize, pageNumber, sortColumn, sortDirection) {
  let tagParam = "";
  if (filterTags) {
    filterTags.forEach(i => {
      tagParam += `filterTags=${i}&`;
    });
  }
  return dispatch => {
    dispatch(beginAjaxCall("loadDocuments"));
    return adalApiFetch(
      `${API_URL}/Documents/${patientId}/Available?${
        filterTags ? tagParam : "filterTags="
      }&pageSize=${pageSize}&pageNumber=${pageNumber}&sortBy=${sortColumn}&sortDirection=${
        sortDirection === "ascending" ? "asc" : "desc"
      }`,
      {
        method: "GET"
      }
    )
      .then(({ availableDocuments, numberofPages }) => {
        dispatch(loadDocumentsSuccess(availableDocuments, numberofPages));
      })
      .catch(error => processThenThrowApiError(error, dispatch))
      .finally(() => dispatch(ajaxCallDone()));
  };
}

export function downloadDocument(documentId) {
  return dispatch => {
    dispatch(beginAjaxCall("downloadDocument"));
    return adalApiFetch(
      `${API_URL}/Documents/${documentId}/Download`,
      {
        headers: {
          "Content-Type": "application/json"
        }
      },
      false
    )
      .then(response => response.blob())
      .then(document => dispatch(downloadDocumentsSuccess(document)))
      .catch(error => processThenThrowApiError(error, dispatch))
      .finally(() => dispatch(ajaxCallDone()));
  };
}

export function editDocument(patientId, documentId, document) {
  return dispatch => {
    dispatch(beginAjaxCall("editDocument"));
    return adalApiFetch(`${API_URL}/Documents/${patientId}/Edit?documentId=${documentId}`, {
      method: "PUT",
      body: JSON.stringify(document)
    })
      .then(response => processApiResponse(response.orchestrationId))
      .then(orchestrationId => pollApiEvents(orchestrationId, "IDocumentDataUpdatedEvent"))
      .then(pollResponse => {
        dispatch(editDocumentSuccess(pollResponse, document, documentId));
      })
      .catch(error => processThenThrowApiError(error, dispatch))
      .finally(() => dispatch(ajaxCallDone()));
  };
}

export function deleteDocument(patientId, documentId, reason) {
  return dispatch => {
    dispatch(beginAjaxCall("saveNoteSlashTask"));
    return adalApiFetch(`${API_URL}/Documents/${patientId}`, {
      method: "DELETE",
      body: JSON.stringify({ documentIds: [documentId], reason })
    })
      .then(response => processApiResponse(response.orchestrationId))
      .then(orchestrationId => pollApiEvents(orchestrationId, "IDocumentDeletedEvent"))
      .then(() => {
        dispatch(setForceReload(true));
      })
      .catch(error => processThenThrowApiError(error, dispatch))
      .finally(() => dispatch(ajaxCallDone()));
  };
}
