import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Checkbox, Grid, Table } from "semantic-ui-react";
import { DateInput } from "semantic-ui-calendar-react";
import moment from "moment";
import { cloneDeep, isEmpty, set } from "lodash";
import * as visitActions from "../../../actions/visitActions";
import * as lookupActions from "../../../actions/lookupActions";
import * as modalActions from "../../../actions/modalActions";
import { dateFormat, isoDateFormat, isoFormat } from "../../../constants/miscellaneous";
import VisitsRow from "./VisitsRow";
import AppointmentRow from "./AppointmentRow";
import { checkRoles } from "../../../helpers";
import { roleGroups } from "../../../constants/securityRoles";
import NoteSlashTaskRow from "./NoteSlashTaskRow";
import NoteSlashTaskList from "../../common/tasks/NoteSlashTaskList";

export class Timeline extends Component {
  static typeOptions = [
    { text: "Visits", value: "visit" },
    { text: "Tasks", value: "task" },
    { text: "Appointments", value: "appointment" },
    { text: "Future Tasks", value: "ftask" },
    { text: "Deleted Notes/Tasks", value: "dtask" }
  ];

  state = {
    timeline: {
      totalNumberOfEpsiodesOfCare: 0,
      totalNumberOfVisits: 0,
      data: []
    },
    selectedTypes: ["visit", "task", "appointment"],
    selectedVisitTypeGroups: ["Behavioral Health", "Medical", "Maintenance", "Initial/Rejoin", "Random", "Group"],
    filter: {
      patientId: this.props.patientId,
      from: moment().subtract(3, "month").format(isoDateFormat),
      to: moment().format(isoDateFormat),

      includeFutureTasks: false,
      includeDeletedTasks: false
    },
    sortColumn: "date",
    sortDirection: "descending",
    selectedRow: null,
    updatingVisit: null,
    noteSlashTasksOnlyView: false
  };

  componentDidMount() {
    this._isMounted = true;
    this.props.actions.loadTaskRoles();
    this.props.actions.loadVisitTypeGroups();
    this.fetch();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.modal !== this.props.modal || // Update when close modal add new task or visit
      this.props.forceReloadPatientTimelineHistory !== prevProps.forceReloadPatientTimelineHistory
    ) {
      this.fetch();
    }

    // future tasks
    if (this.state.selectedTypes.includes("ftask") && !prevState.selectedTypes.includes("ftask")) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ filter: { ...this.state.filter, includeFutureTasks: true } }, () => {
        this.fetch();
      });
    }
    if (!this.state.selectedTypes.includes("ftask") && prevState.selectedTypes.includes("ftask")) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ filter: { ...this.state.filter, includeFutureTasks: false } }, () => {
        this.fetch();
      });
    }

    // deleted tasks
    if (this.state.selectedTypes.includes("dtask") && !prevState.selectedTypes.includes("dtask")) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ filter: { ...this.state.filter, includeDeletedTasks: true } }, () => {
        this.fetch();
      });
    }
    if (!this.state.selectedTypes.includes("dtask") && prevState.selectedTypes.includes("dtask")) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ filter: { ...this.state.filter, includeDeletedTasks: false } }, () => {
        this.fetch();
      });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  _isMounted = false;

  handleRowSelect = (e) => {
    const currentRow = e.currentTarget.getAttribute("data-index");
    const visitId = e.currentTarget.getAttribute("data-visit");
    if (this._isMounted)
      this.setState({
        selectedRow: currentRow,
        selectedVisitId: visitId
      });
  };

  handleStatusChange = (e, data) => {
    const { value } = data;
    const { timeline } = this.props;
    const { selectedVisitId } = this.state;
    const currentItem = timeline.data.find(
      (timelineItem) => timelineItem.type === "visit" && timelineItem.platformId === selectedVisitId
    ).entity;
    if (currentItem.visitStatus.visitStatus !== value) {
      if (this._isMounted)
        this.setState({
          updatingVisit: { ...currentItem, visitStatus: { ...currentItem.visitStatus, visitStatus: value } }
        });
      if (value === "visitcomplete") {
        this.props.actions.showModal({
          type: "CONFIRMATION",
          props: {
            open: true,
            icon: "exclamation-triangle",
            iconColor: "warning",
            title: "Confirm Complete",
            buttonColor: "red",
            description: "Are you sure you want to end the visit?",
            onConfirm: () => {
              this.updateVisitStatus(value);
            }
          }
        });
      } else if (value === "visitcanceled") {
        this.props.actions.showModal({
          type: "CANCEL_VISIT_MODAL",
          props: {
            open: true,
            visitId: this.state.selectedVisitId,
            preCancel: () => {},
            postCancel: () => {
              this.reset();
              this.reload();
            }
          }
        });
      } else {
        this.updateVisitStatus(value);
      }
    }
  };

  updateVisitStatus = (newStatus) => {
    this.props.actions.updateVisitStatus(this.state.selectedVisitId, newStatus).then(() => {
      this.reset();
      this.reload();
    });
  };

  reset() {
    if (this._isMounted) this.setState({ selectedRow: "", updatingVisit: null });
  }

  reload() {
    this.props.actions.loadPatientActiveVisits(this.props.patientId, false, false, true);
    this.props.actions.setForceReloadPatientTimelineHistory(this.props.patientId, true);
  }

  fetch() {
    const { filter } = this.state;
    this.props.actions
      .loadPatientTimeline({
        ...filter,
        from: isEmpty(filter.from) ? `` : `${moment(filter.from).startOf("day").format(isoFormat)}`,
        to: isEmpty(filter.to) ? `` : `${moment(filter.to).endOf("day").format(isoFormat)}`
      })
      .then(() => {
        if (this._isMounted)
          this.setState({ timeline: this.props.timeline }, () => {
            this.applyFilterAndSort();
          });
        if (this.props.forceReloadPatientTimelineHistory) {
          this.props.actions.setForceReloadPatientTimelineHistory(this.props.patientId, false);
        }
      });
  }

  applyFilterAndSort() {
    const {
      timeline: { data },
      visitTypeGroupOptions
    } = this.props;
    const { selectedTypes, selectedVisitTypeGroups, sortColumn, sortDirection } = this.state;
    const updatedData = data
      .filter(
        (i) =>
          selectedTypes.includes(i.type) &&
          (i.type !== "visit" ||
            visitTypeGroupOptions
              .filter((vtg) => selectedVisitTypeGroups.includes(vtg.groupName))
              .some((vtg) => vtg.visitTypes.includes(i.entity.visitType)))
      )
      .sort((a, b) => {
        switch (sortColumn) {
          case "date": {
            if (sortDirection === "ascending") return a.date > b.date ? 1 : a.date < b.date ? -1 : 0;
            return a.date < b.date ? 1 : a.date > b.date ? -1 : 0;
          }
          default:
            return 0;
        }
      });
    if (this._isMounted) this.setState({ timeline: { ...this.state.timeline, data: updatedData } });
  }

  handleInput = (e, data) => {
    const updatedState = cloneDeep(this.state);
    let value = data.value;
    if (["filter.from", "filter.to"].includes(data.name)) {
      value = isEmpty(value) ? `` : moment(value, dateFormat).format(isoDateFormat);
    }
    set(updatedState, data.name, value);
    if (this._isMounted)
      this.setState(updatedState, () => {
        this.fetch();
      });
  };

  handleTypeChange = (type, checked) => {
    let selectedTypes = [...this.state.selectedTypes];
    if (selectedTypes.includes(type) && !checked) selectedTypes = selectedTypes.filter((t) => t !== type);
    if (!selectedTypes.includes(type) && checked) selectedTypes.push(type);
    this.setState(
      {
        selectedTypes,
        noteSlashTasksOnlyView: !selectedTypes.includes("visit") && !selectedTypes.includes("appointment")
      },
      () => {
        this.applyFilterAndSort();
      }
    );
  };

  handleVisitTypeGroupChange = (visitTypeGroup, checked) => {
    let selectedVisitTypeGroups = [...this.state.selectedVisitTypeGroups];
    if (selectedVisitTypeGroups.includes(visitTypeGroup) && !checked)
      selectedVisitTypeGroups = selectedVisitTypeGroups.filter((t) => t !== visitTypeGroup);
    if (!selectedVisitTypeGroups.includes(visitTypeGroup) && checked) selectedVisitTypeGroups.push(visitTypeGroup);
    this.setState({ selectedVisitTypeGroups }, () => {
      this.applyFilterAndSort();
    });
  };

  handleSort = (clickedColumn) => () => {
    const { sortColumn, sortDirection } = this.state;
    this.setState(
      {
        ...this.state,
        sortColumn: clickedColumn,
        sortDirection: sortDirection === "ascending" && sortColumn === clickedColumn ? "descending" : "ascending"
      },
      () => {
        this.applyFilterAndSort();
      }
    );
  };

  render() {
    const { authRoles, patientId, visitTypes, goToVisit, visitStatuses, visitTypeGroupOptions } = this.props;
    const {
      timeline,
      selectedRow,
      updatingVisit,
      selectedTypes,
      selectedVisitTypeGroups,
      filter: { from, to },
      sortColumn,
      sortDirection,
      noteSlashTasksOnlyView
    } = this.state;
    const canUndoCancelAndCompleteVisit = checkRoles(roleGroups.undoCancelAndCompleteVisit, authRoles);
    return (
      <Grid>
        <Grid.Row className="half-v-padding">
          <Grid.Column width={8}>
            {Timeline.typeOptions.map((option) => (
              <Checkbox
                key={option.value}
                label={option.text}
                checked={selectedTypes.includes(option.value)}
                onChange={(_, data) => {
                  this.handleTypeChange(option.value, data.checked);
                }}
                style={{ marginTop: ".5em", marginRight: ".5em" }}
                disabled={noteSlashTasksOnlyView && !["visit", "appointment"].includes(option.value)}
              />
            ))}
          </Grid.Column>
          <Grid.Column width={4}>
            {!noteSlashTasksOnlyView && (
              <DateInput
                label="Date From"
                name="filter.from"
                placeholder="Date From"
                value={isEmpty(from) ? "" : moment(from).format(dateFormat)}
                dateFormat={dateFormat}
                iconPosition="right"
                onChange={this.handleInput}
                hideMobileKeyboard
                closable
                clearable
                className="cs-field"
              />
            )}
          </Grid.Column>
          <Grid.Column width={4}>
            {!noteSlashTasksOnlyView && (
              <DateInput
                label="Date To"
                name="filter.to"
                placeholder="Date To"
                value={isEmpty(to) ? "" : moment(to).format(dateFormat)}
                dateFormat={dateFormat}
                iconPosition="right"
                onChange={this.handleInput}
                hideMobileKeyboard
                closable
                clearable
                className="cs-field"
              />
            )}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className="no-padding full-bottom-padding">
          <Grid.Column width={8}>
            {selectedTypes.includes("visit") &&
              visitTypeGroupOptions.map((option) => (
                <Checkbox
                  key={option.id}
                  value={option.groupName}
                  label={option.groupName}
                  checked={selectedVisitTypeGroups.includes(option.groupName)}
                  onChange={(_, data) => {
                    this.handleVisitTypeGroupChange(option.groupName, data.checked);
                  }}
                  style={{ marginTop: ".5em", marginRight: ".5em" }}
                />
              ))}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className="no-padding">
          <Grid.Column width={16}>
            {noteSlashTasksOnlyView && (
              <NoteSlashTaskList
                typeOptions={[
                  { text: "All", value: "All" },
                  { text: "Tasks", value: "Task" },
                  { text: "Notes", value: "Note" }
                ]}
                roleGroupOptions={[]}
                centerOptions={[]}
                defaultFilter={{
                  patientId,
                  status: "Opened",
                  type: "All",
                  dueDateFrom: "",
                  dueDateTo: "",
                  includeDeleted: false,
                  includePatientInfo: false,
                  assignedUserIds: [],
                  assignedCenterIds: [],
                  assignedRoles: [],
                  sortBy: "lastModified",
                  sortOrder: "descending",
                  pageNumber: 1,
                  itemsPerPage: 20
                }}
              />
            )}
            {!noteSlashTasksOnlyView && (
              <Table fixed selectable sortable className="no-h-padding">
                <Table.Header className="tableHeadRow">
                  <Table.Row>
                    <Table.HeaderCell style={{ width: "30px" }} />
                    <Table.HeaderCell
                      sorted={sortColumn === "date" ? sortDirection : null}
                      onClick={this.handleSort("date")}
                      style={{ width: "200px" }}
                    >
                      Date
                    </Table.HeaderCell>
                    <Table.HeaderCell>Episode</Table.HeaderCell>
                    <Table.HeaderCell>Appt/Visit Type</Table.HeaderCell>
                    <Table.HeaderCell>Visit Template</Table.HeaderCell>
                    <Table.HeaderCell>Appt/Visit Status</Table.HeaderCell>
                    <Table.HeaderCell>Level</Table.HeaderCell>
                    <Table.HeaderCell>Provider</Table.HeaderCell>
                    <Table.HeaderCell>Summary</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                {patientId && (
                  <Table.Body>
                    {timeline.data.length
                      ? timeline.data.map((item, index) => (
                          <React.Fragment key={item.platformId}>
                            {item.type === "visit" ? (
                              <VisitsRow
                                {...item.entity}
                                visitTypes={visitTypes}
                                goToVisit={goToVisit}
                                visitStatuses={visitStatuses}
                                index={index}
                                selectedRow={selectedRow}
                                updatingVisit={updatingVisit}
                                handleRowSelect={this.handleRowSelect}
                                handleStatusChange={this.handleStatusChange}
                                canUndoCancelAndCompleteVisit={canUndoCancelAndCompleteVisit}
                              />
                            ) : null}
                            {item.type === "appointment" ? (
                              <AppointmentRow {...item.entity} visitTypes={visitTypes} />
                            ) : null}
                            {item.type === "task" ? <NoteSlashTaskRow entity={item.entity} /> : null}
                          </React.Fragment>
                        ))
                      : null}
                  </Table.Body>
                )}
              </Table>
            )}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className="no-padding">
          <Grid.Column width={16} textAlign="right">
            {!noteSlashTasksOnlyView && (
              <span>
                Displaying <b>{timeline.data.filter((i) => i.type === "visit").length}</b> of{" "}
                <b>{timeline.totalNumberOfVisits}</b> visits
              </span>
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  return {
    authRoles: state.auth.user.profile.roles,
    patientId: state.patient.currentPatient.patientId,
    visitTypes: state.lookups.visitTypes,
    visitTypeGroupOptions: state.lookups.visitTypeGroups,
    visitStatuses: state.lookups.visitStatuses,
    timeline: state.visits.timeline,
    forceReloadPatientTimelineHistory: state.visits.forceReloadPatientTimelineHistory,
    currentUserId: state.userPreference.currentUserId,
    currentUserRoles: state.auth.user.profile.roles,
    modal: state.modal
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...visitActions,
        ...lookupActions,
        ...modalActions
      },
      dispatch
    )
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Timeline);
