import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { isEmpty, isEqual } from "lodash";
import { Container, Row, Col, Form, Button } from "../../../../ui";
import * as patientActions from "../../../../actions/patientActions";
import * as demographicActions from "../../../../actions/demographicActions";
import * as patientHistoryActions from "../../../../actions/patientHistoryActions";
import { CUSTOM_FIELD_ID_PSYCHIATRIST } from "../../../../constants/miscellaneous";
import { checkLengthError, removeExtraSpace } from "../../../../helpers";

class PatientPsychiatrist extends Component {
  state = {
    model: { psychiatrist: "" },
    originalModal: { psychiatrist: "" },
    error: false,
    errorMessage: ""
  };

  componentDidMount() {
    const { patientId, psychiatristField } = this.props;
    if (!patientId || !psychiatristField) return;
    // fetch psychiatrist
    this.fetchPsychiatrist();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.patientId &&
      this.props.psychiatristField &&
      (this.props.patientId !== prevProps.patientId ||
        !isEqual(this.props.psychiatristField, prevProps.psychiatristField))
    ) {
      this.fetchPsychiatrist();
    }
    if (this.props.psychiatrist !== prevProps.psychiatrist) {
      const psychiatrist = this.props.psychiatrist;
      this.setState({ model: { psychiatrist }, originalModal: { psychiatrist } });
    }
  }

  fetchPsychiatrist = () => {
    const { patientId, psychiatristField } = this.props;
    this.props.actions.fetchCustomFields(patientId, [psychiatristField.customeFieldAthenaId]).then(() => {
      const model = {
        psychiatrist: this.props.customFields[psychiatristField.id]
          ? this.props.customFields[psychiatristField.id].customFieldAnswer
          : ""
      };
      this.setState({
        model,
        originalModal: { ...model }
      });
    });
  };

  handleInput = (e) => {
    const { name: field, value } = e.target;
    const model = Object.assign({}, this.state.model, {
      [field]: value
    });

    this.setState({
      model,
      error: checkLengthError(model.psychiatrist, 100)
    });
  };

  handleBlur = () => {
    const { psychiatrist } = this.state.model;
    if (checkLengthError(psychiatrist, 100)) {
      return this.setState({
        errorMessage: "Psychiatrist's name should not exceed 100 text characters."
      });
    }
    return this.setState({ errorMessage: "" });
  };

  handleSave = () => {
    const { patientId, psychiatristField } = this.props;
    const {
      model: { psychiatrist },
      error
    } = this.state;

    if (!error) {
      this.props.actions
        .saveCustomFields(patientId, [
          {
            customFieldId: psychiatristField.customeFieldAthenaId,
            customFieldValue: removeExtraSpace(psychiatrist.trim())
          }
        ])
        .then(() => {
          this.setState({ originalModal: { psychiatrist } });
          this.props.actions.updatePsychiatristSuccess(patientId, psychiatrist);
          // this.props.actions.loadPsychiatristHistory(this.props.patientId);
        });
    }
  };

  render() {
    const { model, originalModal, error, errorMessage } = this.state;
    const { saving } = this.props;
    const noChange = isEmpty(model.psychiatrist) || model.psychiatrist === originalModal.psychiatrist;
    return (
      <div className="patient-psychiatrist-container">
        <Container>
          <Row>
            <Col md={8} className="p-0">
              <Form.Control
                type="text"
                id="input-psychiatrist"
                name="psychiatrist"
                className="m-0"
                value={model.psychiatrist || ""}
                onChange={this.handleInput}
                onBlur={this.handleBlur}
                // error={error}
              />
            </Col>
            <Col md={2}>
              <Button
                color="blue"
                onClick={this.handleSave}
                content="Save"
                loading={saving}
                disabled={saving || error || noChange}
              >
                Save
              </Button>
            </Col>
            <Col md={2} />
          </Row>
          {errorMessage.length > 0 && (
            <Row>
              <div className="search-error">{errorMessage}</div>
            </Row>
          )}
        </Container>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const psychiatristField = state.lookups.patientCustomFields.find((cf) => cf.id === CUSTOM_FIELD_ID_PSYCHIATRIST);

  return {
    psychiatristField,
    patientId: state.patient.currentPatient.patientId,
    customFields: state.patient.customFields,
    // psychiatrist: state.demographics.psychiatrist,
    // psychiatrists: state.patientHistory.psychiatristHistory || [],
    saving: state.ajaxCallsInProgress > 0
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...patientActions, ...demographicActions, ...patientHistoryActions }, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PatientPsychiatrist);
