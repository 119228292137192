import "react-toastify/dist/ReactToastify.css";
import * as types from "./actionTypes";
import api from "../api";
import { beginAjaxCall, ajaxCallDone } from "./ajaxStatusActions";
import { processApiError } from "../helpers";

export function getNotificationsDone(response) {
  return {
    type: types.GET_NOTIFICATIONS_DONE,
    response
  };
}

export function dismissAllNotificationsDone() {
  return {
    type: types.DISMISS_ALL_NOTIFICATIONS_DONE
  };
}

export function dismissNotificationDone(notificationId) {
  return {
    type: types.DISMISS_NOTIFICATION_DONE,
    notificationId
  };
}

export function getNotifications() {
  return dispatch => {
    dispatch(beginAjaxCall("getNotifications"));
    return api
      .getNotifications()
      .then(response => dispatch(getNotificationsDone(response)))
      .catch(error => processApiError(error, dispatch))
      .finally(() => dispatch(ajaxCallDone()));
  };
}
