import React, { Component } from "react";
import { Button, Header, Modal, Input, List } from "semantic-ui-react";
import shortid from "shortid";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as modalActions from "../../../../actions/modalActions";
import * as searchActions from "../../../../actions/searchActions";

import "../../../reusable/Modal.css";
import "../PatientHistorySearchModal.css";

const getInitialSearch = (props) => props.searchValue || "";

const getSearchError = (props) =>
  (props.searchValue || []).length >= 3 ? "" : "Please type more than 3 characters to search.";

export class SearchConditionModal extends Component {
  state = {
    searchCondition: getInitialSearch(this.props),
    searchedCondition: getInitialSearch(this.props),
    selectedCondition: "",
    selectedIcd10Code: "",
    searchError: getSearchError(this.props)
  };

  componentDidMount() {
    const { searchValue } = this.props;
    if ((searchValue || []).length >= 3) {
      this.props.actions.searchCondition({
        conditionSearchString: searchValue
      });
    }
  }

  componentWillUnmount() {
    this.props.actions.clearSearch("conditions");
  }

  handleSearch = () => {
    const { searchCondition } = this.state;
    this.props.actions.clearSearch("conditions");
    if (searchCondition.length >= 3) {
      this.props.actions.searchCondition({
        conditionSearchString: searchCondition
      });
      this.setState({
        searchError: ""
      });
    } else {
      this.setState({
        searchError: "Please type more than 3 characters to search."
      });
    }
  };

  handleSearchInput = (e, data) => {
    this.setState({ searchCondition: data.value, selectedCondition: "" });
  };

  handleSearchEnter = (e) => {
    if (e.key === "Enter") {
      const { searchCondition } = this.state;

      this.setState({ searchedCondition: searchCondition, selectedCondition: "" }, () => this.handleSearch());
    }
  };

  handleSearchClick = () => {
    const { searchCondition } = this.state;
    this.setState({ searchedCondition: searchCondition, selectedCondition: "" }, () => this.handleSearch());
  };

  handleClose = () => {
    this.props.actions.hideModal();
    this.props.actions.clearSearch("conditions");
  };

  handleResultClick = (icd10Code, condition) => {
    this.setState({ selectedIcd10Code: icd10Code, selectedCondition: condition });
  };

  handleModalNav = () => {
    const { searchCondition, selectedCondition, selectedIcd10Code } = this.state;
    const { isFamilyHistory } = this.props;
    if (isFamilyHistory) {
      this.props.actions.showModal({
        type: "ADD_FAMILY_HISTORY",
        props: {
          open: true,
          condition: selectedCondition || searchCondition
        }
      });
    } else {
      this.props.actions.showModal({
        type: "ADD_MEDICAL_HISTORY",
        props: {
          open: true,
          conditionAdd: selectedCondition || searchCondition,
          icd10CodeAdd: selectedIcd10Code
        }
      });
    }
  };

  render() {
    const { open, processing, conditions } = this.props;
    const { selectedCondition, searchCondition, searchedCondition, searchError } = this.state;
    const disableButton = !searchCondition && !selectedCondition;
    return (
      <Modal className="search-condition-modal" open={open} onClose={this.handleClose} closeIcon size="small">
        <Header content="Search Conditions" />
        <Modal.Content>
          <div className="search-container-modal">
            <Input
              icon={{
                name: "search",
                link: true,
                onClick: this.handleSearchClick
              }}
              placeholder="Search..."
              loading={processing}
              onChange={this.handleSearchInput}
              onKeyPress={this.handleSearchEnter}
              value={searchCondition}
              id="input-search-conditional"
            />
          </div>
          <div className="condition-search-results">
            {selectedCondition ? (
              <h3 id="lbl-selected">You selected: {selectedCondition}</h3>
            ) : (
              searchedCondition && <h3 id="lbl-searched">Search results for: {searchedCondition}</h3>
            )}
            <List selection>
              {searchError.length ? (
                <div id="searchError">{searchError}</div>
              ) : conditions ? (
                conditions.map((condition) => (
                  <List.Item
                    key={shortid.generate()}
                    active={selectedCondition === condition.value}
                    onClick={() => this.handleResultClick(condition.key, condition.value)}
                  >
                    {condition.value}
                  </List.Item>
                ))
              ) : null}
            </List>
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.handleClose} id="btn-cancel">
            Cancel
          </Button>
          <Button
            disabled={disableButton}
            loading={processing}
            onClick={this.handleModalNav}
            color="blue"
            id="btn-next"
          >
            {selectedCondition ? "Next" : "Free Text Next"}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    processing: state.ajaxCallsInProgress > 0,
    conditions: state.search.conditions.results
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...modalActions, ...searchActions }, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchConditionModal);
