import { adalApiFetch } from "../adalConfig";
import { API_URL } from "../constants/environment";
import { processApiError } from "../helpers";
import { beginAjaxCall, ajaxCallDone } from "./ajaxStatusActions";

// eslint-disable-next-line import/prefer-default-export
export function fetchActivityLog(entityId) {
  return dispatch => {
    dispatch(beginAjaxCall("fetchNoteSlashTasks"));
    return adalApiFetch(`${API_URL}/ActivityLog/${entityId}`)
      .catch(error => processApiError(error, dispatch))
      .finally(() => dispatch(ajaxCallDone()));
  };
}
