import { loadChartBadgeSuccess } from "../../actions/badgeActions";

const store = window.store;

const handleChartsToSignCountChanged = message =>
  store.dispatch(loadChartBadgeSuccess(message.Content.chartsToSign, message.Content.isChartsToSignUrgent));

export default {
  handleChartsToSignCountChanged
};
