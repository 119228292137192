import React from "react";
import { Table, Dropdown, Icon } from "semantic-ui-react";
import moment from "moment";
import momenttz from "moment-timezone";
import { getLookupText } from "../../../helpers";
import "./VisitsPage.css";
import {
  dateWithTimeFormat,
  VISIT_STATUS_VISIT_CANCELED,
  VISIT_STATUS_VISIT_COMPLETE
} from "../../../constants/miscellaneous";
import { PatientLevel } from "../../../ui";

const VisitsRow = ({
  visitId,
  visitDateTime,
  episodeOfCareNumber,
  visitType,
  visitTypes,
  visitStatus,
  visitStatuses,
  level,
  bhLevel,
  prescribingProvider,
  goToVisit,
  selectedTemplateName,
  index,
  selectedRow,
  updatingVisit,
  handleRowSelect,
  handleStatusChange,
  canUndoCancelAndCompleteVisit
}) => (
  <Table.Row
    data-index={index}
    data-visit={visitId}
    onClick={handleRowSelect}
    className={`${String(index) === selectedRow ? "timeline-row selected " : "timeline-row"} ${
      visitStatus && visitStatus.visitStatus && visitStatus.visitStatus === VISIT_STATUS_VISIT_CANCELED
        ? `deleted timeline-canceled-visit-row`
        : ``
    }`}
  >
    <Table.Cell style={{ width: "30px" }}>
      <Icon name="user md" />
    </Table.Cell>
    <Table.Cell style={{ width: "200px" }}>
      {moment(visitDateTime).format(dateWithTimeFormat)} <small>[{momenttz.tz(momenttz.tz.guess()).zoneAbbr()}]</small>
    </Table.Cell>
    <Table.Cell>{episodeOfCareNumber}</Table.Cell>
    <Table.Cell>{getLookupText(visitType, visitTypes)}</Table.Cell>
    <Table.Cell>{selectedTemplateName}</Table.Cell>
    <Table.Cell style={{ overflow: "visible" }}>
      {visitStatus ? (
        <span>
          {canUndoCancelAndCompleteVisit &&
          [VISIT_STATUS_VISIT_CANCELED, VISIT_STATUS_VISIT_COMPLETE].includes(visitStatus.visitStatus) &&
          String(index) === selectedRow ? (
            <Dropdown
              name="visitStatus"
              placeholder=""
              fluid
              selection
              options={visitStatuses}
              width={12}
              onChange={handleStatusChange}
              value={updatingVisit ? updatingVisit.visitStatus.visitStatus : visitStatus.visitStatus}
              loading={updatingVisit && updatingVisit.visitId === visitId}
              disabled={updatingVisit && updatingVisit.visitId === visitId}
            />
          ) : visitStatus.visitStatus === "visitcanceled" ? (
            visitStatus.cancelReason
          ) : (
            getLookupText(visitStatus.visitStatus, visitStatuses)
          )}
        </span>
      ) : (
        `Corrupted`
      )}
    </Table.Cell>
    <Table.Cell>
      <PatientLevel level={level ? level.levelName : "orange"} size="sm" />
      {` `}
      {bhLevel && <PatientLevel level={bhLevel.levelName} size="sm" bh />}
    </Table.Cell>
    <Table.Cell>{prescribingProvider && `${prescribingProvider.firstName} ${prescribingProvider.lastName}`}</Table.Cell>
    <Table.Cell className="actions-column" style={{ textAlign: "right" }}>
      <Icon
        title="Summary"
        name="file alternate outline"
        color="blue"
        style={{ margin: "2px", fontSize: "1.1em" }}
        onClick={() => goToVisit(visitId)}
      />
    </Table.Cell>
  </Table.Row>
);

export default VisitsRow;
