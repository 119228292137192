import React from "react";
import { Table } from "semantic-ui-react";
import map from "lodash/map";

const ReferralsTable = ({
  tableType,
  referrals,
  getLookupText,
  centers,
  /* getProviderName, */
  providers
}) => (
  <div className="referrals-table-container">
    <h4>{`${tableType} Referrals`}</h4>
    <Table>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Referring NPI</Table.HeaderCell>
          <Table.HeaderCell>Referral #</Table.HeaderCell>
          <Table.HeaderCell>Start Date</Table.HeaderCell>
          <Table.HeaderCell>End Date</Table.HeaderCell>
          <Table.HeaderCell>Referrals Left</Table.HeaderCell>
          <Table.HeaderCell>Provider</Table.HeaderCell>
          <Table.HeaderCell>Location</Table.HeaderCell>
          <Table.HeaderCell />
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {map(
          referrals,
          (
            {
              referralNumber,
              providerNPINumber,
              startDate,
              endDate,
              numberOfReferralsAvailable,
              cleanSlateProviderId,
              centerId
            },
            i
          ) => (
            <Table.Row key={`${referralNumber}_${i}`}>
              <Table.Cell>{providerNPINumber}</Table.Cell>
              <Table.Cell>{referralNumber}</Table.Cell>
              <Table.Cell>{startDate}</Table.Cell>
              <Table.Cell>{endDate}</Table.Cell>
              <Table.Cell>{numberOfReferralsAvailable}</Table.Cell>
              <Table.Cell>{getLookupText(cleanSlateProviderId, providers)}</Table.Cell>
              <Table.Cell>{getLookupText(centerId, centers)}</Table.Cell>
            </Table.Row>
          )
        )}
      </Table.Body>
    </Table>
  </div>
);

export default ReferralsTable;
