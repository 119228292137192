import React, { Component } from "react";
import { Button, Form, Grid } from "semantic-ui-react";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import prescriptionStatus from "../../../constants/prescriptionStatus";

export class RxItemForm extends Component {
  componentDidMount() {
    const {
      rxItemForm: { prescription },
      loadDrugForms,
      loadDrugRoutes,
      loadDrugStrengths
    } = this.props;
    if (prescription) {
      loadDrugForms(prescription.drugName);
      loadDrugRoutes(prescription.drugName, prescription.form);
      if (prescription.administerRoute) {
        loadDrugStrengths(prescription.drugName, prescription.form, prescription.administerRoute);
      }
    }
  }

  componentDidUpdate(prevProps) {
    const {
      rxItemForm: { prescription },
      loadDrugForms,
      loadDrugRoutes,
      clearDrugRoutes,
      loadDrugStrengths,
      clearDrugStrengths
    } = this.props;
    if (prescription) {
      const { drugName, form, administerRoute } = prescription;
      const { rxItemForm: prevRxItemForm } = prevProps;

      if (isEmpty(prevRxItemForm) || (drugName && drugName !== prevRxItemForm.prescription.drugName)) {
        loadDrugForms(prescription.drugName);
      }

      if (
        isEmpty(prevRxItemForm) ||
        (form && form !== prevRxItemForm.prescription.form) ||
        (drugName && drugName !== prevRxItemForm.prescription.drugName)
      ) {
        loadDrugRoutes(drugName, form);
      } else if (form !== prevRxItemForm.prescription.form) {
        clearDrugRoutes();
      }

      if (
        drugName &&
        form &&
        administerRoute &&
        (isEmpty(prevRxItemForm) ||
          administerRoute !== prevRxItemForm.prescription.administerRoute ||
          drugName !== prevRxItemForm.prescription.drugName ||
          form !== prevRxItemForm.prescription.form)
      ) {
        loadDrugStrengths(drugName, form, administerRoute);
      } else if (administerRoute !== prevRxItemForm.prescription.administerRoute) {
        clearDrugStrengths();
      }
    }
  }

  render() {
    const {
      rxItemForm: { prescription },
      mainPrescribers,
      disableSave,
      disableRxButton,
      handleInput,
      handleKeyDown,
      handleProviderInput,
      handleCheckbox,
      handleCancel,
      handleSavedForSig,
      handleSend,
      handleSign,
      handleFocus,
      handleQuantity,
      handleQuantityBlur,
      handleAddRecurring,
      handleAddCurrent,
      handleSave,
      drugForms,
      drugRoutes,
      drugStrengths,
      numberPerDoseOptions,
      refillOptions,
      frequencyOptions,
      rxType,
      processing,
      enableEditCurrent,
      isSignedButtonEnabled,
      enableSendingRx,
      canSaveFavoritePrescription,
      handleAddToFavorites,
      drugFavorites
    } = this.props;

    if (isEmpty(prescription)) return null;

    const disableEdit =
      (!enableEditCurrent && (rxType === "currentRx" || rxType === "recurringRx")) ||
      prescription.prescriptionStatus === prescriptionStatus.signed;

    const editFavorites = !isEmpty(drugFavorites.find(i => i.drugKey === prescription.drugKey));

    return (
      <Form>
        <Grid doubling stackable>
          <Grid.Row>
            <Grid.Column mobile={16} tablet={16} computer={5}>
              <Form.Group widths="equal">
                <Form.Dropdown
                  name="form"
                  selection
                  required
                  value={prescription.form}
                  onChange={handleInput}
                  placeholder="Select..."
                  options={drugForms}
                  label="Drug Form"
                  search
                  id="input-form"
                  onFocus={handleFocus}
                  disabled={disableEdit}
                  fluid
                />
                <Form.Dropdown
                  name="administerRoute"
                  selection
                  required
                  value={prescription.administerRoute}
                  placeholder="Select..."
                  onChange={handleInput}
                  options={drugRoutes}
                  label="Route"
                  search
                  id="input-administerRoute"
                  onFocus={handleFocus}
                  disabled={drugRoutes.length === 0 || disableEdit}
                  fluid
                />
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Dropdown
                  name="dosage"
                  selection
                  required
                  value={prescription.dosage}
                  placeholder="Select..."
                  onChange={handleInput}
                  options={numberPerDoseOptions}
                  label="Dose"
                  search
                  id="input-dosage"
                  onFocus={handleFocus}
                  disabled={disableEdit}
                  fluid
                />
                <Form.Dropdown
                  name="frequency"
                  selection
                  required
                  value={prescription.frequency}
                  onChange={handleInput}
                  options={frequencyOptions}
                  label="Frequency"
                  search
                  id="input-frequency"
                  onFocus={handleFocus}
                  disabled={disableEdit}
                  fluid
                />
                <Form.Input
                  required
                  label="Quantity"
                  name="quantity"
                  value={prescription.quantity}
                  onChange={handleQuantity}
                  onBlur={handleQuantityBlur}
                  id="input-quantity"
                  disabled={disableEdit}
                  fluid
                />
              </Form.Group>
              <Form.Dropdown
                name="prescriberId"
                selection
                value={prescription.prescriberId}
                placeholder="Select..."
                onChange={handleProviderInput}
                options={mainPrescribers}
                label="Prescriber"
                search
                id="input-prescriberId"
                onFocus={handleFocus}
                direction="left"
                disabled={disableEdit}
                upward
                fluid
              />
            </Grid.Column>
            <Grid.Column mobile={16} tablet={16} computer={6}>
              <Form.Dropdown
                name="drugKey"
                fluid
                selection
                required
                placeholder="Select..."
                value={prescription.drugKey}
                onChange={handleInput}
                options={(drugStrengths || []).filter(x => x.text)}
                label="Strength"
                search
                id="input-strength"
                onFocus={handleFocus}
                disabled={drugStrengths.length === 0 || disableEdit}
              />

              <Form.TextArea
                label={`Special Instructions (${(prescription.specialInstructions || "").length} of 140 characters)`}
                maxLength={140}
                name="specialInstructions"
                value={prescription.specialInstructions || ""}
                onKeyDown={handleKeyDown}
                onChange={handleInput}
                id="input-specialInstructions"
                disabled={disableEdit}
                className={
                  (prescription.specialInstructions || "").length > 134
                    ? "danger"
                    : (prescription.specialInstructions || "").length > 129
                    ? "warning"
                    : ""
                }
                rows={3}
              />

              <Form.Checkbox
                label="DAW"
                name="daw"
                checked={prescription.daw}
                onChange={handleCheckbox}
                id="input-daw"
                disabled={disableEdit}
              />
            </Grid.Column>
            <Grid.Column mobile={16} tablet={16} computer={5}>
              <Form.TextArea
                label={`Note to Pharmacist (${(prescription.noteToPharmacist || "").length} of 169 characters)`}
                maxLength={169}
                name="noteToPharmacist"
                value={prescription.noteToPharmacist || ""}
                onKeyDown={handleKeyDown}
                onChange={handleInput}
                id="input-noteToPharmacist"
                disabled={disableEdit}
                className={
                  (prescription.noteToPharmacist || "").length > 164
                    ? "danger"
                    : (prescription.noteToPharmacist || "").length > 159
                    ? "warning"
                    : ""
                }
                rows={5}
              />
              <Form.Group>
                <Form.Dropdown
                  name="refills"
                  selection
                  placeholder="Select..."
                  value={prescription.refills || 0}
                  onChange={handleInput}
                  options={refillOptions}
                  label="Refills"
                  search
                  id="input-refills"
                  onFocus={handleFocus}
                  direction="left"
                  disabled={disableEdit || prescription.deaClass === 2}
                  width={6}
                  upward
                />
              </Form.Group>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={4} />
            <Grid.Column width={4}>
              {rxType === "currentRx" && enableEditCurrent ? (
                <Button
                  type="button"
                  icon="plus"
                  content="Recurring"
                  className="transparent-button"
                  id="btn-recurring"
                  onClick={handleAddRecurring(prescription)}
                  disabled={disableSave || processing}
                />
              ) : null}
            </Grid.Column>
            <Grid.Column width={8} textAlign="right">
              <Button
                type="button"
                content="Cancel"
                className="transparent-button"
                onClick={handleCancel}
                id="btn-cancel"
              />
              {canSaveFavoritePrescription && (
                <Button
                  type="button"
                  content={editFavorites ? `Update Favorite` : `Add to Favorites`}
                  onClick={handleAddToFavorites}
                  id="btn-fav"
                  disabled={processing}
                  color="olive"
                  loading={processing}
                />
              )}
              {prescription.prescriptionStatus !== prescriptionStatus.signed && rxType === "currentRx" && (
                <React.Fragment>
                  {enableEditCurrent && (
                    <Button
                      type="button"
                      content="Save for Signature"
                      onClick={handleSavedForSig}
                      id="btn-save"
                      disabled={disableSave || processing}
                      color="blue"
                      loading={processing}
                    />
                  )}
                  {isSignedButtonEnabled && (
                    <Button
                      type="button"
                      content="Sign"
                      onClick={handleSign}
                      disabled={disableSave || processing}
                      id="btn-sign"
                      color="blue"
                      loading={processing}
                    />
                  )}
                </React.Fragment>
              )}
              {prescription.prescriptionStatus === prescriptionStatus.signed && enableSendingRx && (
                <Button
                  type="button"
                  content="Send"
                  onClick={handleSend}
                  id="btn-send"
                  disabled={disableSave || processing}
                  color="blue"
                  loading={processing}
                />
              )}
              {rxType === "recurringRx" && enableEditCurrent && (
                <React.Fragment>
                  {!disableRxButton && (
                    <Button
                      type="button"
                      content="Add to Current Rx"
                      onClick={handleAddCurrent(prescription)}
                      id="btn-addToCurrentRx"
                      disabled={disableSave || processing}
                      color="blue"
                      loading={processing}
                    />
                  )}
                  <Button
                    type="button"
                    content="Save"
                    onClick={handleSave}
                    disabled={disableSave || processing}
                    id="btn-save"
                    color="blue"
                    loading={processing}
                  />
                </React.Fragment>
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
    );
  }
}

function mapStateToProps(state) {
  return {
    rxItemForm: state.prescriptions.rxItemForm,
    drugForms: state.lookups.drugForms,
    drugRoutes: state.lookups.drugRoutes,
    drugStrengths: state.lookups.drugStrengths.map(ds => ({
      text: ds.text,
      value: ds.value,
      deaclass: ds.deaClass,
      drugdescription: ds.drugDescription,
      formdescription: ds.formDescription
    })),
    drugFavorites: state.user.drugFavorites,
    processing: state.ajaxCallsInProgress > 0
  };
}

export default connect(mapStateToProps)(RxItemForm);
