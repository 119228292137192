import React, { Component, useState, useCallback, useRef, useEffect } from "react";
import { Form, Grid, Icon, Button, Search, Segment } from "semantic-ui-react";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { isEqual } from "lodash";
import shortid from "shortid";

function SelectedItem({ index, answer, prefix, moveItem, deleteItem }) {
  const ref = useRef(null);

  const [{ isDragging }, drag] = useDrag({
    type: "ADVANCED_SEARCH_SELECTED_ITEM",
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  });

  const [{ handlerId }, drop] = useDrop({
    accept: "ADVANCED_SEARCH_SELECTED_ITEM",
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId()
      };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      moveItem(dragIndex, hoverIndex);
      // eslint-disable-next-line no-param-reassign
      item.index = hoverIndex;
    }
  });

  const opacity = isDragging ? 0 : 1;

  drag(drop(ref));

  return (
    <div ref={ref} data-handler-id={handlerId} className={isDragging ? "dragging" : ""}>
      <Grid
        style={{
          border: "1px dashed grey",
          padding: "0.5em",
          margin: "0 0 .5em 0",
          backgroundColor: "white",
          cursor: "move",
          opacity
        }}
      >
        <Grid.Row className="no-margin no-padding">
          <Grid.Column className="no-padding" width={14}>
            <strong>{prefix}</strong>
            {` `}
            <span>
              {answer.split(/(?:\r\n|\r|\n)/g).map((l, i) => (
                <React.Fragment key={`rand-${shortid.generate()}`}>
                  {i === 0 ? <span>{l}</span> : <i>{l}</i>}
                  <br />
                </React.Fragment>
              ))}
            </span>
          </Grid.Column>
          <Grid.Column
            className="no-padding"
            width={2}
            style={{
              textAlign: "right"
            }}
          >
            <Icon
              title="Delete"
              name="delete"
              color="red"
              onClick={(e) => {
                e.stopPropagation();
                deleteItem(index);
              }}
              style={{ margin: "2px", fontSize: "1.1em", cursor: "pointer" }}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
}

function SelectedItemsContainer(props) {
  const { question, answers: _answers, prefixes, updateAnswerObjectAnswers } = props;

  const [answers, setAnswers] = useState(_answers);

  useEffect(() => {
    if (!isEqual(answers, _answers)) setAnswers(_answers);
  }, [_answers]);

  useEffect(() => {
    updateAnswerObjectAnswers(question, answers);
  }, [answers]);

  const moveItem = useCallback(
    (dragIndex, hoverIndex) => {
      const dragItem = answers[dragIndex];
      const updatedItems = [...answers];
      updatedItems[dragIndex] = updatedItems[hoverIndex];
      updatedItems[hoverIndex] = dragItem;
      setAnswers(updatedItems);
    },
    [answers]
  );

  const deleteItem = (index) => {
    const updatedAnswers = [...answers];
    updatedAnswers.splice(index, 1);
    setAnswers(updatedAnswers);
  };

  return (
    <div>
      {answers.map((answer, index) => (
        <SelectedItem
          key={answer}
          index={index}
          answer={answer}
          prefix={prefixes[index] || ``}
          moveItem={moveItem}
          deleteItem={deleteItem}
        />
      ))}
    </div>
  );
}

export default class AdvancedSearch extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
  }

  render() {
    const {
      question,
      triggered,
      questionPaddingLevel,
      questionWrapper,
      answerObject,
      handleSearchChange,
      handleResultSelect,
      updateAnswerObjectAnswers
    } = this.props;

    const config = question.config && typeof question.config === "object" ? question.config : {};

    const maxAllowedAnswers = config.maxAllowedAnswers || 0;
    const minCharsToSearch = config.minCharsToSearch || 0;

    return (
      <div ref={this.myRef}>
        {questionWrapper(
          question,
          <Grid className="no-padding no-margin">
            <Grid.Row className="no-padding">
              <Grid.Column width={10} className="no-padding">
                <Grid className="no-margin" style={{ padding: "0 1em 0 0" }}>
                  <Grid.Row className="no-padding">
                    <Grid.Column width={16} className="no-padding">
                      <Form.Field>
                        <Search
                          name={question.key}
                          mcs={minCharsToSearch}
                          skey={question.sectionKey}
                          scount={question.sectionCount}
                          gkey={question.groupKey}
                          gcount={question.groupCount}
                          qcount={question.questionCount}
                          onResultSelect={(e, d) => {
                            this.myRef.current.scrollIntoView(true);
                            handleResultSelect(e, d);
                          }}
                          onSearchChange={handleSearchChange}
                          results={answerObject.searchResult || []}
                          value={answerObject.keyword || ``}
                          // eslint-disable-next-line react/no-unstable-nested-components
                          resultRenderer={({ title }) => (
                            <div>
                              {title.split(/(?:\r\n|\r|\n)/g).map((l) => (
                                <React.Fragment key={`rand-${shortid.generate()}`}>
                                  {l}
                                  <br />
                                </React.Fragment>
                              ))}
                            </div>
                          )}
                          disabled={maxAllowedAnswers > 0 && answerObject.answers.length >= maxAllowedAnswers}
                          fluid
                        />
                      </Form.Field>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row className="no-padding">
                    <h5 style={{ display: "block", width: "100%", margin: "1em 0" }}>Frequently Used</h5>
                    {question.commonAnswers
                      .filter((ca) => ca.items.filter((i) => !answerObject.answers.includes(i.text)).length > 0)
                      .map((ca) => (
                        <div key={ca.header} style={{ display: "block", width: "100%", paddingLeft: "1em" }}>
                          <h5 style={{ display: "block", width: "100%" }}>{ca.header}</h5>
                          <div style={{ paddingLeft: "1em" }}>
                            {ca.items
                              .filter((i) => !answerObject.answers.includes(i.text))
                              .map((i) => (
                                <Button
                                  key={i.value}
                                  color="vk"
                                  onClick={() => {
                                    updateAnswerObjectAnswers(question, [
                                      ...new Set([...answerObject.answers, i.text])
                                    ]);
                                  }}
                                  disabled={maxAllowedAnswers > 0 && answerObject.answers.length >= maxAllowedAnswers}
                                  style={{
                                    textAlign: "left",
                                    padding: ".5em",
                                    marginBottom: ".5em",
                                    fontWeight: "500",
                                    width: "100%",
                                    cursor:
                                      maxAllowedAnswers > 0 && answerObject.answers.length >= maxAllowedAnswers
                                        ? "default"
                                        : "pointer"
                                  }}
                                  fluid
                                >
                                  {i.text.split(/(?:\r\n|\r|\n)/g).map((l, index) => (
                                    <React.Fragment key={`rand-${shortid.generate()}`}>
                                      {index === 0 ? <span>{l}</span> : <i>{l}</i>}
                                      <br />
                                    </React.Fragment>
                                  ))}
                                </Button>
                              ))}
                          </div>
                        </div>
                      ))}
                  </Grid.Row>
                </Grid>
              </Grid.Column>
              <Grid.Column width={6} className="no-padding">
                <Segment className="half-padding">
                  <h4>Selected items</h4>
                  <DndProvider backend={HTML5Backend}>
                    <SelectedItemsContainer
                      question={question}
                      answers={answerObject.answers}
                      prefixes={config.selectedAnswerPrefixes || []}
                      updateAnswerObjectAnswers={updateAnswerObjectAnswers}
                    />
                  </DndProvider>
                </Segment>
              </Grid.Column>
            </Grid.Row>
          </Grid>,
          triggered,
          questionPaddingLevel
        )}
      </div>
    );
  }
}
