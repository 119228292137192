const providers = [
  {
    key: "provider-one",
    text: "Provider One",
    value: "Provider One",
    city: "Atlanta",
    state: "GA"
  },
  {
    key: "provider-two",
    text: "Provider Two",
    value: "Provider Two",
    city: "Chicago",
    state: "IL"
  },
  {
    key: "provider-three",
    text: "Provider Three",
    value: "Provider Three",
    city: "Seattle",
    state: "WA"
  },
  {
    key: "provider-four",
    text: "Provider Four",
    value: "Provider Four",
    city: "Albany",
    state: "NY"
  },
  {
    key: "provider-five",
    text: "Provider Five",
    value: "Provider Five",
    city: "NYC",
    state: "NY"
  },
  {
    key: "provider-six",
    text: "Provider Six",
    value: "Provider Six",
    city: "Buffalo",
    state: "NY"
  },
  {
    key: "provider-seven",
    text: "Provider Seven",
    value: "Provider Seven",
    city: "Yonkers",
    state: "NY"
  }
];

export default providers;
