import React from "react";
import { Table, Icon, Button } from "semantic-ui-react";
import moment from "moment";
import map from "lodash/map";
import { dateFormat, longDateFormatWithTime } from "../../../../constants/miscellaneous";

const MedicationTableRow = ({
  medication: { drugName, strength, startDate, endDate, notes },
  handleEditMedication,
  index,
  showEditHistory,
  showDeleteHistory
}) => (
  <React.Fragment>
    <tr className="medication-row-item">
      <td className="drug-name" data-label="Medications">
        <strong>{drugName} </strong>
        {""}
        {strength}
      </td>
      <td className="medication-date" data-label="Start">
        <strong>{startDate && moment(startDate).format(dateFormat)}</strong>
      </td>
      <td className="medication-date" data-label="End">
        <strong>{endDate && moment(endDate).format(dateFormat)}</strong>
      </td>
      <td data-label="Note">
        {showEditHistory ? (
          <Button
            icon="plus"
            color="blue"
            index={index}
            className="transparent-button-icon"
            onClick={handleEditMedication}
          />
        ) : (
          <span>&nbsp;</span>
        )}
      </td>
      <td data-label="&nbsp;">
        {notes && notes.length > 0 ? (
          <Icon name="sticky note outline" />
        ) : (
          <Icon className="opacity" name="sticky note outline" />
        )}
      </td>
      <td data-label="&nbsp;">
        {showEditHistory ? (
          <Button icon="edit" index={index} className="transparent-button-icon" onClick={handleEditMedication} />
        ) : (
          <span>&nbsp;</span>
        )}
      </td>
      <td data-label="&nbsp;">
        {showDeleteHistory ? (
          <Button
            icon="delete"
            index={index}
            className="transparent-button-icon delete"
            onClick={handleEditMedication}
          />
        ) : (
          <span>&nbsp;</span>
        )}
      </td>
    </tr>
    {notes
      ? map(notes, (note, i) => (
          <React.Fragment key={note.noteId || i}>
            <Table.Row className="tritangleHistory">
              {i === 0 && (
                <React.Fragment>
                  <Table.Cell className="first-tritangle" colSpan={4} />
                  <Table.Cell className="first-tritangle triangle-row-item">
                    <div className="note-background-first" />
                  </Table.Cell>
                  <Table.Cell className="first-tritangle" colSpan={2} />
                </React.Fragment>
              )}
            </Table.Row>
            <Table.Row className="note-background" key={i}>
              {note.noteAddedOn && (
                <Table.Cell colSpan="3" className="general-info1">
                  <strong>{moment(note.noteAddedOn).format(longDateFormatWithTime)}</strong>
                </Table.Cell>
              )}
              <Table.Cell colSpan="4" className="general-info2">
                {note.creatorFullName}
              </Table.Cell>
            </Table.Row>
            <Table.Row className="note-background">
              <Table.Cell colSpan="7">
                <p>{note.text}</p>
              </Table.Cell>
            </Table.Row>
          </React.Fragment>
        ))
      : null}
  </React.Fragment>
);

export default MedicationTableRow;
