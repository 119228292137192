import React from "react";
import { Table, Icon, Button } from "semantic-ui-react";
import moment from "moment";
import { isEmpty } from "lodash";
import shortid from "shortid";
import { dateFormat, timeFormat, longDateFormat } from "../../../../constants/miscellaneous";

const InterventionHistoryTableRow = ({
  interventionHistory: { condition, startDate, endDate, isComplete, notes },
  handleEditInterventionHistory,
  index,
  showEditHistory,
  showDeleteHistory
}) => (
  <React.Fragment>
    <tr>
      <td data-label="Condition" className="intervention-row-item-condition">
        <strong>{condition}</strong>
      </td>
      <td data-label="Start">
        <strong>{startDate && moment(startDate).format(dateFormat)}</strong>
      </td>
      <td data-label="End">
        <strong>{endDate && moment(endDate).format(dateFormat)}</strong>
      </td>
      <td data-label="Is Complete">
        {isComplete ? <strong className="is-complete">Yes</strong> : <strong className="not-complete">No</strong>}
      </td>
      <td data-label="Note">
        {showEditHistory ? (
          <Button
            icon="plus"
            index={index}
            className="transparent-button-icon"
            onClick={handleEditInterventionHistory}
          />
        ) : (
          <span>&nbsp;</span>
        )}
      </td>
      <td data-label="&nbsp;">
        {notes && notes.length > 0 ? (
          <Icon name="sticky note outline" />
        ) : (
          <Icon className="opacity" name="sticky note outline" />
        )}
      </td>
      <td data-label="&nbsp;">
        {showEditHistory ? (
          <Button
            icon="edit"
            index={index}
            className="transparent-button-icon"
            onClick={handleEditInterventionHistory}
          />
        ) : (
          <span>&nbsp;</span>
        )}
      </td>
      <td data-label="&nbsp;">
        {showDeleteHistory ? (
          <Button
            icon="delete"
            index={index}
            className="transparent-button-icon delete"
            onClick={handleEditInterventionHistory}
          />
        ) : (
          <span>&nbsp;</span>
        )}
      </td>
    </tr>
    {isEmpty(notes)
      ? null
      : notes.map((note, i) => (
          <React.Fragment key={note.noteId || shortid.generate()}>
            <Table.Row className="tr-tritangle">
              {i === 0 && (
                <React.Fragment>
                  <Table.Cell className="first-tritangle" colSpan={5} />
                  <Table.Cell className="first-tritangle triangle-row-item">
                    <div className="note-background-first" />
                  </Table.Cell>
                  <Table.Cell className="first-tritangle" colSpan={2} />
                </React.Fragment>
              )}
            </Table.Row>
            <Table.Row className="note-background" key={note.noteId || shortid.generate()}>
              {note.noteAddedOn && (
                <Table.Cell colSpan="5" className="general-info1">
                  <strong>{moment(note.noteAddedOn).format(longDateFormat)} </strong>
                  <span>{moment(note.noteAddedOn).format(timeFormat)}</span>
                </Table.Cell>
              )}
              <Table.Cell colSpan="4" className="general-info2">
                {note.creatorFullName}
              </Table.Cell>
            </Table.Row>
            <Table.Row className="note-background">
              <Table.Cell colSpan="9">
                <p>{note.text}</p>
              </Table.Cell>
            </Table.Row>
          </React.Fragment>
        ))}
  </React.Fragment>
);

export default InterventionHistoryTableRow;
