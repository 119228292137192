import React from "react";
import "../../LoggingIn.css";

export default function VisitSummaryPrintLoading() {
  return (
    <div className="zontainer">
      <span className="zhild ui zimmer">Preparing to print . .</span>
    </div>
  );
}
