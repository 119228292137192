import React from "react";
import { Switch, Route } from "react-router-dom";
import ReportPageBI from "./ReportPage";

const ReportSubLayout = ({ match }) => (
  <Switch>
    <Route path={`${match.path}/:reportId`} component={ReportPageBI} />
  </Switch>
);

export default ReportSubLayout;
