import React from "react";
import SurveysDashboard from "../surveys/SurveysDashboard";
import "./DischargePage.css";

export function DischargePage() {
  return (
    <React.Fragment>
      <SurveysDashboard tab="discharge" />
    </React.Fragment>
  );
}

export default DischargePage;
