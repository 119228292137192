import React from "react";
import { Label, Icon, Table } from "semantic-ui-react";

const ControlledSubstancesStatsRow = ({
  slotCapacity,
  patientsInPanel,
  crossCoveragePatients,
  availableSlots,
  totalPatientsActiveRx,
  handlePatientsWithActiveRxClick,
  bonusCoverageSlots,
  handleTotalPatientsInPanelClick
}) => (
  <Table color="blue" textAlign="center" inverted>
    <Table.Body>
      <Table.Row>
        <Table.Cell>Total Slot Capacity</Table.Cell>
        <Table.Cell />
        <React.Fragment>
          <Table.Cell>Bonus Slots for Coverage</Table.Cell>
          <Table.Cell />
        </React.Fragment>
        <Table.Cell size="massive">Total Patients in Panel</Table.Cell>
        <Table.Cell />
        <Table.Cell size="massive">Cross-Cover Patients</Table.Cell>
        <Table.Cell />
        <Table.Cell size="massive">Total Remaining Available Slots</Table.Cell>
        <Table.Cell />
        <Table.Cell>Total Patients with Active Rx</Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>
          <Label color="grey" size="big">
            {slotCapacity}
          </Label>
        </Table.Cell>
        <React.Fragment>
          <Table.Cell>
            <Icon name="plus" size="big" />
          </Table.Cell>
          <Table.Cell>
            <Label color="grey" size="big">
              {bonusCoverageSlots}
            </Label>
          </Table.Cell>
        </React.Fragment>
        <Table.Cell>
          <Icon name="minus" size="big" />
        </Table.Cell>
        <Table.Cell>
          <Label color="grey" size="big" as={patientsInPanel ? "a" : ""} onClick={handleTotalPatientsInPanelClick}>
            {patientsInPanel}
          </Label>
        </Table.Cell>
        <Table.Cell>
          <Icon name="minus" size="big" />
        </Table.Cell>
        <Table.Cell>
          <Label color="grey" size="big">
            {crossCoveragePatients}
          </Label>
        </Table.Cell>
        <Table.Cell>
          <Icon name="pause" size="big" rotated="clockwise" />
        </Table.Cell>
        <Table.Cell>
          <Label color="grey" size="big">
            {availableSlots}
          </Label>
        </Table.Cell>
        <Table.Cell>
          <Icon name="ellipsis vertical" size="big" />
        </Table.Cell>
        <Table.Cell>
          <Label
            color="grey"
            size="big"
            as={totalPatientsActiveRx ? "a" : ""}
            onClick={handlePatientsWithActiveRxClick}
          >
            {totalPatientsActiveRx}
          </Label>
        </Table.Cell>
      </Table.Row>
    </Table.Body>
  </Table>
);

export default ControlledSubstancesStatsRow;
