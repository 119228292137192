import React from "react";
import { Form, Icon, Segment } from "semantic-ui-react";
import MaskedInput from "react-text-mask";
import { isEmpty } from "lodash";
import moment from "moment";
import { phoneMask, taxIdMask, npiMask } from "../../constants/validation";
import { hasProperty } from "../../helpers";
import NewCenterModal from "./NewCenterModal";
import { dateWithTimeFormat } from "../../constants/miscellaneous";

const NewCenterModalMainView = ({
  center,
  billingCodeOptions,
  locationTypeOptions,
  handleInput,
  handleMaskedInput,
  handleCheckbox,
  handleFocus,
  handleUpdateDetailsTabView: handleUpdateView,
  errors,
  enableEditDetails
}) => (
  <div style={{ paddingBottom: "1em" }}>
    <Form.Group widths="equal">
      <Form.Field>
        <Form.Input
          label="Center Name"
          name="centerName"
          value={center.centerName}
          onChange={handleInput}
          error={hasProperty(errors, "centerName")}
          disabled={!enableEditDetails}
        />
      </Form.Field>
      <Form.Field>
        <Form.Input
          label="Claim Name"
          name="claimName"
          placeholder="Optional"
          value={center.claimName}
          onChange={handleInput}
          disabled={!enableEditDetails}
        />
      </Form.Field>
    </Form.Group>
    <Segment clearing>
      <b style={{ display: "inline-block", marginBottom: ".5em" }}>Current Address</b>
      <Icon
        name="history"
        color="blue"
        title="Address History"
        className="clickable"
        style={{ float: "right" }}
        onClick={() => {
          handleUpdateView(NewCenterModal.DETAILS_TAB_VIEWS.ADDRESS_HISTORY);
        }}
      />
      <p style={{ marginBottom: ".5em" }}>
        <b>Effective from: </b>
        {isEmpty(center.addressStartDate) ? (
          <i>not set</i>
        ) : (
          <i>{moment(center.addressStartDate).format(dateWithTimeFormat)}</i>
        )}
      </p>
      {!isEmpty(center.address1) && <p style={{ marginBottom: ".5em" }}>{center.address1}</p>}
      {!isEmpty(center.address2) && <p style={{ marginBottom: ".5em" }}>{center.address2}</p>}
      <p style={{ marginBottom: ".5em" }}>{`${!isEmpty(center.city) ? center.city : ``}${
        !isEmpty(center.state) ? `, ${center.state}` : ``
      }${!isEmpty(center.zim) ? `, ${center.zip}` : ``}${!isEmpty(center.zip4) ? `, ${center.zip4}` : ``}`}</p>
    </Segment>
    <Form.Group widths="equal">
      <Form.Field className={hasProperty(errors, "phone") ? "error  field" : " field"}>
        <label>Phone</label>
        <MaskedInput
          name="phone"
          mask={phoneMask}
          guide={false}
          value={center.phone}
          onChange={handleMaskedInput}
          disabled={!enableEditDetails}
        />
      </Form.Field>
      <Form.Field className={hasProperty(errors, "fax") ? "error  field" : " field"}>
        <label>Fax</label>
        <MaskedInput
          name="fax"
          mask={phoneMask}
          guide={false}
          value={center.fax}
          onChange={handleMaskedInput}
          disabled={!enableEditDetails}
        />
      </Form.Field>
      <Form.Field className={hasProperty(errors, "npi") ? "error  field" : " field"}>
        <label>NPI</label>
        <MaskedInput
          name="npi"
          mask={npiMask}
          guide={false}
          value={center.npi}
          onChange={handleMaskedInput}
          disabled={!enableEditDetails}
        />
      </Form.Field>
      <Form.Field>
        <Form.Input
          label="DEA Number"
          name="dea"
          value={center.dea || ""}
          onChange={handleInput}
          error={hasProperty(errors, "dea")}
          disabled={!enableEditDetails}
        />
      </Form.Field>
    </Form.Group>
    <Form.Group widths="equal">
      <Form.Field>
        <Form.Dropdown
          fluid
          search
          selection
          placeholder="Select..."
          label="Billing Location Code"
          name="billingCode"
          options={billingCodeOptions}
          value={center.billingCode}
          onChange={handleInput}
          error={hasProperty(errors, "billingCode")}
          onFocus={handleFocus}
          disabled={!enableEditDetails}
        />
      </Form.Field>
      <Form.Field>
        <Form.Input
          label="Clinic Group"
          name="centerGroup"
          value={center.centerGroup}
          onChange={handleInput}
          error={hasProperty(errors, "centerGroup")}
          disabled={!enableEditDetails}
        />
      </Form.Field>
      <Form.Field>
        <Form.Input
          label="CLIA Number"
          name="cliaNumber"
          value={center.cliaNumber}
          onChange={handleInput}
          error={hasProperty(errors, "cliaNumber")}
          disabled={!enableEditDetails}
        />
      </Form.Field>
      <Form.Field>
        <Form.Input
          label="Taxonomy Code"
          name="taxonomyCode"
          value={center.taxonomyCode}
          onChange={handleInput}
          error={hasProperty(errors, "taxonomyCode")}
          disabled={!enableEditDetails}
        />
      </Form.Field>
    </Form.Group>
    <Form.Group widths="equal">
      <Form.Field>
        <Form.Dropdown
          fluid
          search
          selection
          placeholder="Select..."
          label="Location Type"
          name="locationType"
          options={locationTypeOptions}
          value={center.locationType}
          onChange={handleInput}
          error={hasProperty(errors, "locationType")}
          onFocus={handleFocus}
          disabled={!enableEditDetails}
        />
      </Form.Field>
      <Form.Field className={hasProperty(errors, "taxId") ? "error  field" : " field"}>
        <label htmlFor="input-taxId">Tax ID</label>
        <MaskedInput
          name="taxId"
          id="input-taxId"
          mask={taxIdMask}
          guide={false}
          value={center.taxId}
          onChange={handleMaskedInput}
          disabled={!enableEditDetails}
        />
      </Form.Field>
      <Form.Field />
      <Form.Field />
    </Form.Group>
    <Form.Group widths="equal">
      <Form.Field>
        <Segment clearing>
          <b style={{ display: "inline-block", marginBottom: ".5em" }}>Location History</b>
          <Icon
            name="history"
            color="blue"
            title={`${NewCenterModal.DETAILS_TAB_ENTITIES.locationStatus.title} History`}
            className="clickable"
            style={{ float: "right" }}
            onClick={() => {
              handleUpdateView(NewCenterModal.DETAILS_TAB_VIEWS.ENTITY_HISTORY, "locationStatus");
            }}
          />
          <div style={{ marginBottom: ".5em", textAlign: "center" }}>
            {center.isLocationOpen ? (
              <strong style={{ padding: ".2em", fontSize: "14px", color: "#75ce0a" }}>Open</strong>
            ) : (
              <strong style={{ padding: ".2em", fontSize: "14px", color: "#db2828" }}>Closed</strong>
            )}
          </div>
        </Segment>
      </Form.Field>
      <Form.Field>
        <Segment clearing>
          <b style={{ display: "inline-block", marginBottom: ".5em" }}>Current Lab Director</b>
          <Icon
            name="history"
            color="blue"
            title={`${NewCenterModal.DETAILS_TAB_ENTITIES.labDirector.title} History`}
            className="clickable"
            style={{ float: "right" }}
            onClick={() => {
              handleUpdateView(NewCenterModal.DETAILS_TAB_VIEWS.ENTITY_HISTORY, "labDirector");
            }}
          />
          <p style={{ marginBottom: ".5em" }}>{isEmpty(center.labDirector) ? <i>Not set</i> : center.labDirector}</p>
        </Segment>
      </Form.Field>
      <Form.Field>
        <Segment clearing>
          <b style={{ display: "inline-block", marginBottom: ".5em" }}>Current Medical Director</b>
          <Icon
            name="history"
            color="blue"
            title={`${NewCenterModal.DETAILS_TAB_ENTITIES.medicalDirector.title} History`}
            className="clickable"
            style={{ float: "right" }}
            onClick={() => {
              handleUpdateView(NewCenterModal.DETAILS_TAB_VIEWS.ENTITY_HISTORY, "medicalDirector");
            }}
          />
          <p style={{ marginBottom: ".5em" }}>
            {isEmpty(center.medicalDirector) ? <i>Not set</i> : center.medicalDirector}
          </p>
        </Segment>
      </Form.Field>
    </Form.Group>
    <Form.Field>
      <Form.Checkbox
        label="Active"
        name="active"
        checked={center.active}
        onChange={handleCheckbox}
        disabled={!enableEditDetails}
      />
    </Form.Field>
  </div>
);

export default NewCenterModalMainView;
