import React, { Component } from "react";
import { Button, Header, Modal, Grid, Icon, Form, Message, Checkbox } from "semantic-ui-react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import shortid from "shortid";
import { withRouter } from "react-router-dom";
import { head, isEmpty } from "lodash";
import moment from "moment";

import * as modalActions from "../../../actions/modalActions";
import * as centerActions from "../../../actions/centerActions";
import * as providerActions from "../../../actions/providerActions";
import * as prescriptionActions from "../../../actions/prescriptionActions";
import { loadLookup } from "../../../actions/lookupActions";
import api from "../../../api";
import { dateFormat, isoFormat } from "../../../constants/miscellaneous";

import "../../reusable/Modal.css";

export class SendPrescriptionModal extends Component {
  state = {
    rxAuthenticationType: "SoftToken",
    password: "",
    rxPin: "",
    isLoading: false,
    patientDemographics: {},
    patientDefaultCenter: {},
    prescriber: {},
    selectedPharmacy: {},
    supervisingProvider: {},
    prescriptions: [],
    dateWritten: moment(),
    hasControlledSubstance: false
  };

  componentDidMount() {
    const {
      prescriptionIds = [],
      actions: { loadLookup: fetchLookup },
      centerId,
      patientId
    } = this.props;

    this.setState({ isLoading: true });
    fetchLookup("RxAuthenticationTypes");

    Promise.all([
      api.getPrescriptions(prescriptionIds),
      api.fetchPatientDemographics(patientId),
      api.fetchCenter(centerId),
      api.fetchPatientDefaultPharmacy(patientId)
    ])
      .then(([prescriptions, patientDemographics, patientDefaultCenter, selectedPharmacy]) => {
        const [{ prescriberId } = {}] = prescriptions;

        return Promise.all([
          Promise.resolve(prescriptions),
          Promise.resolve(patientDemographics),
          Promise.resolve(patientDefaultCenter),
          Promise.resolve(selectedPharmacy),
          api.fetchProvider(prescriberId)
        ]);
      })
      .then(([prescriptions, patientDemographics, patientDefaultCenter, selectedPharmacy, prescriber]) => {
        const hasControlledSubstance = prescriptions.find((rx) => rx.deaClass > 0) !== undefined;

        return this.setState(
          {
            prescriptions,
            patientDemographics,
            patientDefaultCenter,
            prescriber,
            selectedPharmacy,
            isLoading: false,
            ...(!hasControlledSubstance && { rxAuthenticationType: "None" }),
            hasControlledSubstance
          },
          () => {
            const {
              prescriber: { title, providerStateSettings },
              patientDefaultCenter: { state }
            } = this.state;

            if (title === "NP" || title === "PAC") {
              const { supervisorId } = providerStateSettings.find((pss) => pss.stateCode === state) || {};

              if (supervisorId) {
                api.fetchProvider(supervisorId).then((supervisingProvider) => this.setState({ supervisingProvider }));
              }
            }
          }
        );
      });
  }

  handleClose = () => {
    const {
      actions: { hideModal },
      multiple
    } = this.props;
    if (this.props.onClose) this.props.onClose();
    hideModal(multiple);
  };

  handleDefaultPharmacyModalOpen = () => {
    const {
      actions: { showModal },
      searchedMedication,
      centerId,
      patientId,
      multiple
    } = this.props;
    showModal({
      type: "PATIENT_PHARMACY_MODAL",
      props: {
        open: true,
        searchValue: searchedMedication,
        centerId,
        patientId,
        multiple
      }
    });
  };

  handleSend = () => {
    const {
      patientId,
      prescriptionIds,
      actions: { sendPrescription },
      fromReport
    } = this.props;
    const { rxAuthenticationType, password, rxPin, dateWritten, prescriptions } = this.state;
    const { prescriberId } = head(prescriptions) || {};

    this.setState({ isLoading: true });

    sendPrescription(
      patientId,
      prescriptionIds.map((rxId) => rxId),
      {
        authenticationType: rxAuthenticationType,
        ...(rxAuthenticationType !== "None" && { password }),
        dateWritten: dateWritten.format(isoFormat),
        prescriberId,
        rxPin
      },
      fromReport
    ).then(() => {
      this.setState({ isLoading: false });
      this.handleClose();
    });
  };

  handleInput = (_, data) => {
    const { name: field, value } = data;
    this.setState({ [field]: value });
  };

  render() {
    const {
      rxAuthenticationType,
      password,
      rxPin,
      isLoading,
      selectedPharmacy,
      patientDemographics,
      patientDemographics: { associatedAddresses = [], associatedPhoneNumbers = [], associatedEmailAddresses = [] },
      patientDefaultCenter,
      prescriber,
      supervisingProvider,
      selectedPharmacy: { pharmacyKey },
      dateWritten,
      prescriptions,
      hasControlledSubstance
    } = this.state;
    const { open, rxAuthenticationTypes, mainPrescriberId } = this.props;

    const patientBillingAddress = associatedAddresses.find((a) => a.isBilling) || {};
    const cellPhoneNumber = associatedPhoneNumbers.find((a) => a.type === "Cell") || {};
    const workPhoneNumber = associatedPhoneNumbers.find((a) => a.type === "Work") || {};
    const homePhoneNumber = associatedPhoneNumbers.find((a) => a.type !== "Cell" && a.type !== "Work") || {};
    const emailAddress = associatedEmailAddresses.find((e) => e.isPreferred) || {};
    const mainPrescriberRequired = hasControlledSubstance && isEmpty(mainPrescriberId);
    const patientAddressRequired =
      isEmpty(patientBillingAddress.address1) ||
      isEmpty(patientBillingAddress.city) ||
      isEmpty(patientBillingAddress.state) ||
      isEmpty(patientBillingAddress.zip);
    const hasErrors = mainPrescriberRequired || isEmpty(pharmacyKey) || patientAddressRequired;
    const { deaNumber: prescriberDeaNumber, stateMedicalLicenseNumber: prescriberStateLicense } =
      (prescriber.providerStateSettings || []).find((pss) => pss.stateCode === patientDefaultCenter.state) || {};
    const { deaNumber: supervisingProviderDeaNumber, stateMedicalLicenseNumber: supervisingProviderStateLicense } =
      (supervisingProvider.providerStateSettings || []).find((pss) => pss.stateCode === patientDefaultCenter.state) ||
      {};

    if (isEmpty(patientDemographics)) {
      return null;
    }

    return (
      <Modal open={open} onClose={this.handleClose} closeIcon size="large">
        <Header>Review Prescriptions</Header>
        <Modal.Content scrolling>
          <Message error hidden={!hasErrors}>
            <Message.List>
              <Message.Item hidden={!mainPrescriberRequired}>
                <b>You cannot send controlled substances without a main prescriber</b>
              </Message.Item>
              <Message.Item hidden={!isEmpty(pharmacyKey)}>
                <b>You cannot send prescriptions without selecting a pharmacy</b>
              </Message.Item>
              <Message.Item hidden={!patientAddressRequired}>
                <b>You cannot send prescriptions without the patient&#39;s billing address</b>
              </Message.Item>
            </Message.List>
          </Message>
          <Grid relaxed stackable>
            <Grid.Row>
              <Grid.Column width={12}>
                <Header size="small">Patient:</Header>
              </Grid.Column>
              <Grid.Column width={12}>
                <div>
                  {patientDemographics.patientFirstName.substring(0, 35)}{" "}
                  {patientDemographics.patientLastName.substring(0, 35)}
                </div>
                <div>{patientBillingAddress.address1}</div>
                {patientBillingAddress.address2 && <div>{patientBillingAddress.address2}</div>}
                <div>
                  {patientBillingAddress.city}, {patientBillingAddress.state}, {patientBillingAddress.zip}
                  {patientBillingAddress.zip4 && `-${patientBillingAddress.zip4}`}
                </div>
                <div>MRN: {patientDemographics.medicalRecordNumber}</div>
                {cellPhoneNumber.phoneNumber && <div>Cell: {cellPhoneNumber.phoneNumber}</div>}
                {homePhoneNumber.phoneNumber && <div>Home: {homePhoneNumber.phoneNumber}</div>}
                {workPhoneNumber.phoneNumber && <div>Work: {workPhoneNumber.phoneNumber}</div>}
                {emailAddress.address && <div>Email: {emailAddress.address}</div>}
                <div>
                  DOB: {patientDemographics.patientDateOfBirth} Gender: {patientDemographics.gender}
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={12}>
                <Header size="small">Prescription</Header>
              </Grid.Column>
            </Grid.Row>
            {prescriptions.map((prescription) => (
              <Grid.Row key={shortid.generate()}>
                <Grid.Column width={5}>
                  {prescription.drugDescription}
                  <br />
                  <b>QTY: </b>
                  {prescription.quantity} {prescription.formDescription || prescription.form}
                  <br />
                  {prescription.specialInstructions}
                  <br />
                  <b>Note to pharmacist: </b>
                  {prescription.noteToPharmacist}
                  <br />
                  {prescription.refills || 0} refills
                  <br />
                  <Checkbox label="DAW" checked={prescription.daw} readOnly />
                  <br />
                  {dateWritten.format(dateFormat)}
                </Grid.Column>
                <Grid.Column width={5}>
                  {selectedPharmacy.pharmacyName}
                  <br />
                  {selectedPharmacy.address1}
                  <br />
                  {selectedPharmacy.address2 && selectedPharmacy.address2}
                  {selectedPharmacy.address2 && <br />}
                  {selectedPharmacy.city}, {selectedPharmacy.state}, {selectedPharmacy.zip}
                  {selectedPharmacy.zip4 && `-${selectedPharmacy.zip4}`}
                  <br />
                  Phone: {selectedPharmacy.phone}
                  <br />
                  Fax: {selectedPharmacy.fax}
                  <br />
                  e-prescribing service: {(selectedPharmacy.services || []).join(",")}
                  <br />
                  specialty: {(selectedPharmacy.specialties || []).join(",")}
                </Grid.Column>
                <Grid.Column width={5}>
                  {prescriber.firstName} {prescriber.lastName}, {prescriber.title}
                  <br />
                  DEA #{prescriberDeaNumber}
                  <br />
                  NPI #{prescriber.npi}
                  <br />
                  {prescriberStateLicense && <div>State License #/CTP #: {prescriberStateLicense}</div>}
                  {patientDefaultCenter.centerName}
                  <br />
                  {patientDefaultCenter.address1}
                  <br />
                  {patientDefaultCenter.address2 && patientDefaultCenter.address2}
                  {patientDefaultCenter.address2 && <br />}
                  {patientDefaultCenter.city}, {patientDefaultCenter.state}, {patientDefaultCenter.zip}
                  {patientDefaultCenter.zip4 && `-${patientDefaultCenter.zip4}`}
                  <br />
                  {patientDefaultCenter.phone}
                </Grid.Column>
              </Grid.Row>
            ))}
            <Grid.Row>
              <Grid.Column width={5} />
              <Grid.Column width={5} textAlign="left">
                <Button className="transparent-button-icon delete" onClick={this.handleDefaultPharmacyModalOpen}>
                  <Icon name="edit" size="large" />
                </Button>
              </Grid.Column>
              {!isEmpty(supervisingProvider) && (
                <Grid.Column width={5}>
                  <Header size="small">Supervising Physician</Header>
                  {supervisingProvider.firstName} {supervisingProvider.lastName}, {supervisingProvider.title}
                  <br />
                  DEA #{supervisingProviderDeaNumber}
                  <br />
                  NPI #{supervisingProvider.npi}
                  {supervisingProviderStateLicense && (
                    <div>State License #/CTP #: {supervisingProviderStateLicense}</div>
                  )}
                </Grid.Column>
              )}
            </Grid.Row>
          </Grid>
        </Modal.Content>
        <Modal.Actions>
          <Grid padded stackable>
            {hasControlledSubstance && (
              <Grid.Column textAlign="left" width={16}>
                By Completing the two-factor authentication protocol at this time you are legally signing the
                prescription(s) and authorizing the transmission of the above information to the pharmacy for
                dispensing. The two-factor authentication protocol may only be completed by the practitioner whose name
                and DEA registration number appear above.
              </Grid.Column>
            )}
            <Grid.Column width={10} textAlign="left">
              <Form>
                <Form.Group>
                  <Form.Dropdown
                    name="rxAuthenticationType"
                    selection
                    placeholder="Select..."
                    options={rxAuthenticationTypes}
                    label="Device"
                    search
                    onChange={this.handleInput}
                    value={rxAuthenticationType}
                    width={6}
                    disabled={!hasControlledSubstance}
                    compact
                  />
                  <Form.Input
                    label="6 digits passcode"
                    name="password"
                    onChange={this.handleInput}
                    value={password}
                    width={5}
                    maxLength={6}
                    disabled={!hasControlledSubstance}
                  />
                  <Form.Input
                    label="PIN"
                    name="rxPin"
                    onChange={this.handleInput}
                    value={rxPin}
                    width={5}
                    maxLength={4}
                    disabled={!hasControlledSubstance}
                  />
                </Form.Group>
              </Form>
            </Grid.Column>
            <Grid.Column width={6} verticalAlign="middle" textAlign="center">
              <Button onClick={this.handleClose}>Cancel</Button>&nbsp;&nbsp;
              <Button
                onClick={this.handleSend}
                color="blue"
                content="Send"
                disabled={
                  prescriptions.length === 0 ||
                  hasErrors ||
                  isLoading ||
                  (hasControlledSubstance &&
                    (rxAuthenticationType === "None" ||
                      isEmpty(password) ||
                      password.length < 6 ||
                      isEmpty(rxPin) ||
                      rxPin.length < 4))
                }
                loading={isLoading}
              />
            </Grid.Column>
          </Grid>
        </Modal.Actions>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  rxAuthenticationTypes: state.lookups.rxAuthenticationTypes
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      ...modalActions,
      ...centerActions,
      ...providerActions,
      ...prescriptionActions,
      loadLookup
    },
    dispatch
  )
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SendPrescriptionModal));
