export const emptyGuid = "00000000-0000-0000-0000-000000000000";
export const isoDateFormat = "YYYY-MM-DD";
export const isoFormat = "YYYY-MM-DDTHH:mm:ss.SSSZ";
export const shortIsoFormat = "YYYY-MM-DDTHH:mm:ssZ";
export const dateWithTimeFormat = "MM/DD/YYYY hh:mm a";
export const dateWithFullTimeFormat = "MM/DD/YYYY HH:mm:ss";
export const dateFormat = "MM/DD/YYYY";
export const timeFormat = "h:mm a";
export const dateFormatShortTime = "LL";
export const shortDateFormat = "MMM DD, YYYY"; // Apr 18, 2019
export const mediumDateFormat = "MMMM DD, YYYY"; // April 18, 2019
export const longDateFormat = "MMMM Do, YYYY"; // April 18th, 2019
export const longDateFormatWithTime = `MMMM Do, YYYY ${timeFormat}`; // April 18th, 2019
export const datetimeFormatRemoveZeroInHour = "MM/DD/YYYY h:mm a";
export const chartOptions = [
  { text: "Line Chart", value: "LineChart" },
  { text: "Table", value: "Table" }
];
export const numbersToText = {
  0: "zero",
  1: "one",
  2: "two",
  3: "three",
  4: "four",
  5: "five",
  6: "six",
  7: "seven",
  8: "eight",
  9: "nine",
  10: "ten"
};
export const monthsOptions = [
  { text: "Jan", value: 0 },
  { text: "Feb", value: 1 },
  { text: "Mar", value: 2 },
  { text: "Apr", value: 3 },
  { text: "May", value: 4 },
  { text: "Jun", value: 5 },
  { text: "Jul", value: 6 },
  { text: "Aug", value: 7 },
  { text: "Sep", value: 8 },
  { text: "Oct", value: 9 },
  { text: "Nov", value: 10 },
  { text: "Dec", value: 11 }
];
export const daysOptions = [
  { text: "1", value: 1 },
  { text: "2", value: 2 },
  { text: "3", value: 3 },
  { text: "4", value: 4 },
  { text: "5", value: 5 },
  { text: "6", value: 6 },
  { text: "7", value: 7 },
  { text: "8", value: 8 },
  { text: "9", value: 9 },
  { text: "10", value: 10 },
  { text: "11", value: 11 },
  { text: "12", value: 12 },
  { text: "13", value: 13 },
  { text: "14", value: 14 },
  { text: "15", value: 15 },
  { text: "16", value: 16 },
  { text: "17", value: 17 },
  { text: "18", value: 18 },
  { text: "19", value: 19 },
  { text: "20", value: 20 },
  { text: "21", value: 21 },
  { text: "22", value: 22 },
  { text: "23", value: 23 },
  { text: "24", value: 24 },
  { text: "25", value: 25 },
  { text: "26", value: 26 },
  { text: "27", value: 27 },
  { text: "28", value: 28 },
  { text: "29", value: 29 },
  { text: "30", value: 30 },
  { text: "31", value: 31 }
];
export const getYearOptions = () => {
  const currentYear = new Date().getFullYear();
  return [{}, {}, {}, {}, {}].map((_, index) => {
    const year = currentYear - index;
    return { value: year, text: year };
  });
};
export const visitSummarySections = {
  HIST_ALL: "HIST_ALL",
  HIST_ALLERGIES: "HIST_ALLERGIES",
  HIST_MEDICATIONS: "HIST_MEDICATIONS",
  HIST_MEDICAL_HISTORY: "HIST_MEDICAL_HISTORY",
  HIST_FAMILY_HISTORY: "HIST_FAMILY_HISTORY",
  SURVEYS: "SURVEYS",
  TELEHEALTH: "TELEHEALTH",
  AMENDMENTS: "AMENDMENTS",
  PRESCRIPTIONS: "PRESCRIPTIONS",
  LAB_ALL: "LAB_ALL",
  LAB_ORDERS: "LAB_ORDERS",
  LAB_RESULTS: "LAB_RESULTS",
  LAB_PRIOR_RESULTS: "LAB_PRIOR_RESULTS"
};
export const VISIT_STATUS_VISIT_COMPLETE = "visitcomplete";
export const VISIT_STATUS_VISIT_CANCELED = "visitcanceled";
export const DISCHARGE_REASON_MANUAL_DISCHARGE = "manual";

export const TEMPLATE_ID_MA_TEMPLAE_ID = "eafcf39f-dc14-40e0-961f-205c3d661099";
export const TEMPLATE_ID_TREATMENT_PLAN_TEMPLAE_ID = "eafcf39f-dc14-40e0-961f-205c3d661023";
export const TEMPLATE_ID_DISCHARGE_TEMPLATE_ID = "eafcf39f-dc14-40e0-961f-205c3d661003";

export const SURVEY_ID_VITALS_SURVEY_ID = "00000000-0000-0000-0000-000000000007";
export const SURVEY_ID_COWS_SURVEY_ID = "00000000-0000-0000-0000-000000000039";
export const SURVEY_ID_DIAGNOSIS_CODES_SURVEY_ID = "00000000-0000-0000-0000-000000000121";

export const NKDA_REACTION_TYPE = 75064007;
export const NKDA_ALLERGY_KEY = "none";

export const URINE_DRUG_SCREEN_LAB_CODE = "601";

export const yesNoDeclinedOptions = [
  { text: "Yes", value: "Yes" },
  { text: "No", value: "No" },
  { text: "Declined to answer", value: "Declined" }
];

export const yesNoUnknownDeclinedOptions = [
  { text: "Yes", value: "Yes" },
  { text: "No", value: "No" },
  { text: "Unknown", value: "Unknown" },
  { text: "Declined to answer", value: "Declined" }
];

/* Below IDs must be match ondes from the file PatientCustomFields.json */
export const CUSTOM_FIELD_ID_IDENTIFY_AS_LGBTQI = 6;
export const CUSTOM_FIELD_ID_ABILITY_READ_WRITE_PREFERRED_LANGUAGE = 7;
export const CUSTOM_FIELD_ID_NEED_ASSISTED_TECHNOLOGY = 8;
export const CUSTOM_FIELD_ID_PSYCHIATRIST = 11;
export const CUSTOM_FIELD_ID_OTHER_PROVIDERS = 12;
export const CUSTOM_FIELD_ID_EVER_BEEN_IN_MILITARY_SERVICE = 13;
export const CUSTOM_FIELD_ID_CURRENT_MILITARY_INVOLVEMENT_DESCRIPTION = 14;
export const CUSTOM_FIELD_ID_INTERESTED_IN_REFER_MILITARY_INVOLVEMENT = 15;
export const CUSTOM_FIELD_ID_ADDITIONAL_COMMENTS = 16;
