import { API_URL } from "../constants/environment";
import { adalApiFetch } from "../adalConfig";

export const fetchCurrentUser = () => adalApiFetch(`${API_URL}/Users`);

export const fetchUsersByRoles = (roles = [], activeOnly = true) =>
  adalApiFetch(`${API_URL}/Users/Roles?activeOnly=${activeOnly}`, {
    method: "POST",
    body: JSON.stringify(roles)
  });

export const toggleUserStatus = (userId, activate) =>
  adalApiFetch(`${API_URL}/Users/Providers/${userId}/${activate ? "Activate" : "Inactivate"}`, {
    method: "PUT"
  });

export const userHasEpcsAdminAccess = userId => adalApiFetch(`${API_URL}/Users/${userId}/EpcsAdminAccess`);

export default {
  fetchCurrentUser,
  fetchUsersByRoles,
  toggleUserStatus,
  userHasEpcsAdminAccess
};
