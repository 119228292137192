import React, { Component } from "react";
import { Button, Grid, TextArea, Message, Form } from "semantic-ui-react";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as noteActions from "../../actions/noteActions";

import { checkLengthError, formatDateToLocalTime, isEmptyOrWhiteSpace } from "../../helpers";

import "./Notes.css";

export class Notes extends Component {
  state = {
    patientNote: {
      noteText: ""
    },
    error: true,
    errorMessage: ""
  };

  componentDidMount() {
    const {
      noteType,
      patientId,
      noteText,
      actions: { loadNotes }
    } = this.props;

    if (patientId) {
      loadNotes(patientId, noteType);
    }

    this.setState({
      patientNote: Object.assign({}, this.state.patientNote, {
        noteText
      })
    });
  }

  handleInput = (e, data) => {
    const { name: field, value } = data;

    const patientNote = Object.assign({}, this.state.patientNote, {
      [field]: value
    });

    this.setState({
      patientNote,
      error: checkLengthError(patientNote.noteText, 500) || isEmptyOrWhiteSpace(patientNote.noteText)
    });
  };

  handleBlur = () => {
    const { noteText } = this.state.patientNote;
    if (checkLengthError(noteText, 500)) {
      return this.setState({
        errorMessage: "Note should not exceed 500 text characters."
      });
    }
    return this.setState({ errorMessage: "" });
  };

  handleSaveNote = () => {
    const {
      patientId,
      noteType,
      actions: { loadNotes, createNote }
    } = this.props;
    const { patientNote, error } = this.state;

    if (!error) {
      createNote(patientId, patientNote.noteText, noteType).then(() => {
        loadNotes(patientId, noteType);
        this.setState({
          patientNote: Object.assign({}, this.state.patientNote, {
            noteText: ""
          }),
          error: true
        });
      });
    }
  };

  render() {
    const { patientNote, error, errorMessage } = this.state;
    const { notes, saving } = this.props;

    return (
      <div className="patient-note-container">
        <Grid className="no-margin">
          <Grid.Row className="no-padding">
            <Grid.Column mobile={16} tablet={16} computer={11} className="no-padding">
              <Form>
                <TextArea
                  placeholder="Add note here"
                  rows="3"
                  id="text-area-note"
                  name="noteText"
                  value={patientNote.noteText || ""}
                  onChange={this.handleInput}
                  onBlur={this.handleBlur}
                  error={error.toString()}
                />
              </Form>
            </Grid.Column>
            <Grid.Column mobile={14} tablet={14} computer={5}>
              <Button
                color="blue"
                onClick={this.handleSaveNote}
                content="Save"
                loading={saving}
                disabled={error || saving}
              />
            </Grid.Column>
          </Grid.Row>
          {errorMessage.length > 0 && (
            <Grid.Row>
              <div className="search-error">{errorMessage}</div>
            </Grid.Row>
          )}
        </Grid>

        {notes != null &&
          notes.map(note => (
            <Message key={note.noteTime}>
              <Message.Header>
                {formatDateToLocalTime(note.noteTime)} by {note.firstName} {note.lastName}:
              </Message.Header>
              <p className="note-text-paragraph">{note.noteText}</p>
            </Message>
          ))}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    patientId: state.patient.currentPatient.patientId,
    notes: state.notes.notes,
    saving: state.ajaxCallsInProgress > 0
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...noteActions }, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Notes);
