import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as modalActions from "../../../../actions/modalActions";

export class AddPatientInsurance extends React.Component {
  state = {};

  handleModalOpen = () =>
    this.props.actions.showModal({
      type: "PATIENT_INSURANCE",
      props: {
        open: true,
        isNew: true,
        insuranceType: this.props.insuranceType
      }
    });

  handleHistoryModalOpen = () =>
    this.props.actions.showModal({
      type: "INSURANCE_HISTORY_MODAL",
      props: {
        open: true,
        insuranceType: this.props.insuranceType
      }
    });

  render() {
    /* we are commenting this code as we may allow this functionality later */
    return null;
    // const { authRoles } = this.props;
    // const showAddInsurance = checkRoles(roleGroups.excludeHelpDesk, authRoles);
    // return (
    //   <div className="add-insurance">
    //     {showAddInsurance && (
    //       <Button id="btn-add-insurance" className="border-button" onClick={this.handleModalOpen}>
    //         <Icon name={"plus"} />
    //         Add Insurance
    //       </Button>
    //     )}

    //     {/* <Button
    //       id="btn-insurance-history"
    //       className="btn-history"
    //       onClick={this.handleHistoryModalOpen}
    //     >
    //       <Icon name={"undo"} />
    //     </Button> */}
    //   </div>
    // );
  }
}

function mapStateToProps(state) {
  return {
    patientId: state.patient.currentPatient.patientId,
    authRoles: state.auth.user.profile.roles
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...modalActions }, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddPatientInsurance);
