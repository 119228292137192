import React, { Component } from "react";
import { Query, Builder, Utils, BasicConfig } from "react-awesome-query-builder";
import { throttle } from "lodash";
// extracting all required styles into a custom file to not conflict with semantic ui styles
// import "antd/dist/antd.css";
import "./antdCustom.css";
import "./QueryBuilder.css";

const { queryBuilderFormat, checkTree, loadTree, uuid } = Utils;
const allOperators = {
  equal: {
    ...BasicConfig.operators.equal,
    label: "Equals"
  },
  not_equal: {
    ...BasicConfig.operators.not_equal,
    label: "Not equals"
  },
  like: {
    ...BasicConfig.operators.like
  },
  not_like: {
    ...BasicConfig.operators.not_like
  },
  between: {
    ...BasicConfig.operators.between
  },
  not_between: {
    ...BasicConfig.operators.not_between
  }
};
const settings = {
  ...BasicConfig.settings,
  showNot: false,
  maxNesting: 1
};

const types = {
  ...BasicConfig.types,
  int64: {
    defaultOperator: "equal",
    mainWidget: "number",
    widgets: {
      number: {
        operators: [
          "equal",
          "not_equal",
          "less",
          "less_or_equal",
          "greater",
          "greater_or_equal",
          "between",
          "not_between",
          "is_empty",
          "is_not_empty"
        ]
      },
      slider: {
        operators: [
          "equal",
          "not_equal",
          "less",
          "less_or_equal",
          "greater",
          "greater_or_equal",
          "is_empty",
          "is_not_empty"
        ]
      },
      rangeslider: {
        operators: ["range_between", "range_not_between", "is_empty", "is_not_empty"]
      }
    }
  }
};

export default class QueryBuilder extends Component {
  state = {
    tree: null,
    config: null
  };

  componentDidMount() {
    const { fields, initialValue, showNot, maxNesting, operators, conjunctions } = this.props;
    const emptyInitValue = { id: uuid(), type: "group" };
    const initValue = initialValue && Object.keys(initialValue).length > 0 ? initialValue : emptyInitValue;
    settings.showNot = showNot || false;
    // 1 to disable grouping
    settings.maxNesting = maxNesting || 1;

    let selectedOperators = {};
    if (!operators || operators === "all") {
      selectedOperators = { ...allOperators };
    } else {
      operators.split("|").forEach((op) => {
        if (allOperators[op]) {
          selectedOperators[op] = { ...allOperators[op] };
        }
      });
    }

    let selectedConjunctions = {};
    if (!conjunctions || conjunctions === "all") {
      selectedConjunctions = { ...BasicConfig.conjunctions };
    } else {
      conjunctions.split("|").forEach((conj) => {
        const conjUpperCase = conj.toUpperCase();
        if (BasicConfig.conjunctions[conjUpperCase]) {
          selectedConjunctions[conjUpperCase] = { ...BasicConfig.conjunctions[conjUpperCase] };
        }
      });
    }

    const loadedConfig = {
      ...BasicConfig,
      types,
      conjunctions: selectedConjunctions,
      operators: selectedOperators,
      settings,
      fields
    };
    this.setState({
      tree: checkTree(loadTree(initValue), loadedConfig),
      config: loadedConfig
    });
  }

  onChange = (immutableTree, config) => {
    this.immutableTree = immutableTree;
    this.config = config;
    this.updateResult();
  };

  getQueryResult = () => queryBuilderFormat(this.immutableTree, this.config);

  updateResult = throttle(() => {
    this.setState({ tree: this.immutableTree, config: this.config });
    this.props.queryChanged(this.getQueryResult());
  }, 1000);

  renderBuilder = (props) => (
    <div className="query-builder-container">
      <div className="query-builder qb-lite">
        <Builder {...props} />
      </div>
    </div>
  );

  render() {
    const { config } = this.state;
    return (
      config && (
        <Query {...config} value={this.state.tree} onChange={this.onChange} renderBuilder={this.renderBuilder} />
      )
    );
  }
}
