import React from "react";
import "./LoggingIn.css";

export default function LoggingOut() {
  return (
    <div className="zontainer">
      <span className="zhild ui zimmer">Logging out . .</span>
    </div>
  );
}
