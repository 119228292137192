import React, { Component } from "react";
import { Button, Form, Label, Icon, Header } from "semantic-ui-react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { isEmpty } from "lodash";
import * as modalActions from "../../actions/modalActions";
import {
  loadDrugForms,
  loadDrugRoutes,
  loadDrugStrengths,
  clearDrugRoutes,
  clearDrugStrengths
} from "../../actions/lookupActions";
import * as prescriptionActions from "../../actions/prescriptionActions";
import { specialInstrunctionsInvalid, noteToPharmacistInvalid } from "../../helpers";
import { fetchPrescriptionInteractions, fetchPrescriptionAllergies } from "../../api/screening";
import prescriptionStatus from "../../constants/prescriptionStatus";

const getPrescription = (props) => {
  const {
    prescriptionToSign: {
      drugKey,
      drugName,
      form,
      strength,
      dosage,
      administerRoute,
      frequency,
      refills,
      noteToPharmacist,
      specialInstructions,
      prescriberId,
      daw,
      quantity,
      prescriptionStatus: rxStatus,
      prescriptionId,
      deaClass,
      drugDescription,
      formDescription
    }
  } = props;
  return {
    form: {
      form,
      strength,
      dosage,
      administerRoute,
      frequency,
      refills,
      noteToPharmacist,
      specialInstructions,
      prescriberId,
      daw,
      quantity,
      drugKey,
      drugName,
      prescriptionId,
      deaClass,
      drugDescription,
      formDescription
    },
    prescriptionStatus: rxStatus,
    isLoading: false
  };
};

const anyRequiredFieldHasNoValue = (form) =>
  isEmpty(form.form) ||
  isEmpty(form.strength) ||
  form.dosage <= 0 ||
  isEmpty(form.administerRoute) ||
  isEmpty(form.frequency) ||
  form.quantity <= 0 ||
  form.quantity.toString().split(".").join("").length > 10 ||
  specialInstrunctionsInvalid(form.specialInstructions) ||
  noteToPharmacistInvalid(form.noteToPharmacist);

const isRedux = false;

export class PrescriptionToSignForm extends Component {
  state = getPrescription(this.props);

  componentDidMount() {
    const {
      actions: { loadDrugForms: fetchDrugForms, loadDrugRoutes: fetchDrugRoutes, loadDrugStrengths: fetchDrugStrengths }
    } = this.props;
    const {
      form: { drugName, form, administerRoute }
    } = this.state;

    fetchDrugForms(drugName);
    fetchDrugRoutes(drugName, form);
    if (administerRoute) {
      fetchDrugStrengths(drugName, form, administerRoute);
    }
  }

  componentDidUpdate(_, prevState) {
    const {
      actions: {
        loadDrugRoutes: fetchDrugRoutes,
        clearDrugRoutes: removeDrugRoutes,
        loadDrugStrengths: fetchDrugStrengths,
        clearDrugStrengths: removeDrugStrengths
      }
    } = this.props;
    const {
      form: { drugName, form, administerRoute }
    } = this.state;
    const {
      form: { form: prevForm, administerRoute: prevAdministerRoute }
    } = prevState;

    if (form && form !== prevForm) {
      fetchDrugRoutes(drugName, form);
    } else if (form !== prevForm) {
      removeDrugRoutes();
    }

    if (administerRoute && administerRoute !== prevAdministerRoute) {
      fetchDrugStrengths(drugName, form, administerRoute);
    } else if (administerRoute !== prevAdministerRoute) {
      removeDrugStrengths();
    }
  }

  getFormValue = (field, type, value, checked) => {
    const re = /^\d*\.?\d*$/;
    const { form } = this.state;

    if (type === "checkbox") {
      return checked;
    }

    if (field !== "quantity") {
      return value;
    }

    if (field === "quantity" && (value === "" || re.test(value))) {
      return value;
    }

    return form[field];
  };

  handleKeyDown = (e) => {
    if (e.key === "Enter") e.preventDefault();
  };

  handleFieldChange = (e, data) => {
    const {
      currentTarget,
      currentTarget: { innerText }
    } = e;
    const { name: field, type, checked } = data;
    let { value } = data;

    const match = /\r|\n/g.exec(value);
    if (match) {
      value = value.replace(/\r|\n/g, " ");
    }

    let prescription = {
      ...this.state,
      form: {
        ...this.state.form,
        [field]: this.getFormValue(field, type, value, checked),
        ...(field === "form" && { administerRoute: "", strength: "", drugKey: "" }),
        ...(field === "administerRoute" && { strength: "", drugKey: "" }),
        ...(field === "drugKey" && {
          strength: innerText,
          deaClass: parseInt(currentTarget.getAttribute("deaclass"), 10),
          drugDescription: currentTarget.getAttribute("drugdescription"),
          formDescription: currentTarget.getAttribute("formdescription")
        })
      }
    };
    const {
      form: { dosage, form, administerRoute, frequency }
    } = prescription;
    const specialInstructions = `${dosage} ${form} ${administerRoute} ${frequency}`;

    if (
      (field === "drugKey" || field === "frequency" || field === "dosage") &&
      !isEmpty(form) &&
      !isEmpty(administerRoute) &&
      !isEmpty(frequency) &&
      dosage > 0 &&
      prescription.specialInstructions !== specialInstructions
    ) {
      prescription = { ...prescription, form: { ...prescription.form, specialInstructions } };
    }

    return this.setState(prescription);
  };

  handleQuantityOnBlur = () => {
    this.setState({
      form: {
        ...this.state.form,
        quantity: isEmpty(this.state.form.quantity) ? "" : parseFloat(this.state.form.quantity).toString()
      }
    });
  };

  handleSubmit = () => {
    const {
      actions: { updatePrescription },
      prescriptionToSign: { patientId, drugKey: prevDrugKey }
    } = this.props;
    const { form } = this.state;
    this.setState({ isLoading: true });
    return updatePrescription(patientId, form, prevDrugKey, isRedux).then((response) => {
      this.setState({ isLoading: false });
      return response;
    });
  };

  handleInteractions = (e) => {
    e.stopPropagation();
    const {
      actions: { showModal },
      rxInfo: {
        prescriptionToSign: { patientId }
      }
    } = this.props;
    const {
      form: { prescriptionId }
    } = this.state;
    const prescription = this.state;

    fetchPrescriptionInteractions(prescriptionId).then((interactionsResult) => {
      showModal({
        type: "PATIENT_INTERACTION_MATCH",
        props: {
          open: true,
          interactionsResult,
          prescription,
          patientId,
          isQueuedRxForm: true
        }
      });
    });
  };

  handleAllergies = (e) => {
    e.stopPropagation();
    const {
      actions: { showModal },
      rxInfo: {
        prescriptionToSign: { patientId }
      }
    } = this.props;
    const {
      form: { drugName, prescriptionId }
    } = this.state;

    fetchPrescriptionAllergies(prescriptionId).then((allergiesResult) => {
      showModal({
        type: "PATIENT_ALLERGY_MATCH",
        props: {
          open: true,
          selectedMedication: { drugName },
          screeningResults: allergiesResult,
          isShowOnly: true,
          patientId
        }
      });
    });
  };

  handleSend = () => {
    const {
      actions: { signPrescription },
      rxInfo: {
        prescriptionToSign: { patientId, prescriptionId }
      }
    } = this.props;
    const {
      form: { prescriberId },
      prescriptionStatus: rxStatus
    } = this.state;

    if (rxStatus === prescriptionStatus.signed) {
      this.showModal();
    } else {
      this.handleSubmit()
        .then(({ type } = {}) => {
          if (type !== "AJAX_CALL_ERROR") {
            this.setState({ isLoading: true });
            return signPrescription(
              patientId,
              prescriptionId,
              {
                prescriberId
              },
              isRedux
            ).then(() => {
              this.setState({ isLoading: false });
            });
          }

          return Promise.reject();
        })
        .then((response) => {
          const { type } = response || {};

          if (type !== "AJAX_CALL_ERROR") {
            this.setState({ prescriptionStatus: prescriptionStatus.signed });
            this.showModal();
          }
        })
        .catch();
    }
  };

  showModal = () => {
    const {
      actions: { showModal },
      rxInfo: {
        prescriptionToSign: { patientId, centerId, mainPrescriberId }
      }
    } = this.props;
    const {
      form: { prescriptionId }
    } = this.state;

    showModal({
      type: "SEND_PRESCRIPTION_MODAL",
      props: {
        open: true,
        prescriptionIds: [prescriptionId],
        patientId,
        centerId,
        mainPrescriberId,
        multiple: true,
        onClose: () => {
          this.props.actions.setForceReloadPrescriptionsToSign(true);
        }
      }
    });
  };

  render() {
    const {
      drugForms,
      drugRoutes,
      drugStrengths,
      processing,
      rxInfo: {
        prescriptionToSign: { isCrossCoverage }
      },
      prescriptionToSign: {
        attestationSaved,
        attestionCustomText,
        attestationPredefined,
        visitProviderId,
        hasAllergies,
        hasInteractions
      },
      numberPerDoseOptions,
      frequencyOptions,
      refillOptions,
      prescribers
    } = this.props;
    const {
      form: {
        form,
        dosage,
        administerRoute,
        frequency,
        refills,
        noteToPharmacist,
        specialInstructions,
        prescriberId,
        daw,
        quantity,
        drugDescription,
        drugKey,
        deaClass
      },
      form: payload,
      prescriptionStatus: rxStatus,
      isLoading
    } = this.state;
    const canSendPrescription =
      deaClass === 0 ||
      isEmpty(visitProviderId) ||
      visitProviderId === prescriberId ||
      attestationSaved ||
      !isEmpty(attestationPredefined) ||
      !isEmpty(attestionCustomText);

    return (
      <Form>
        {isCrossCoverage && (
          <Label className="cross-coverage-indicator" horizontal>
            Cross coverage
          </Label>
        )}
        <Header as="h3">{drugDescription}</Header>
        <Form.Group widths="equal">
          <Form.Dropdown
            name="form"
            selection
            required
            value={form}
            onChange={this.handleFieldChange}
            placeholder="Select..."
            options={drugForms}
            label="Drug Form"
            disabled={rxStatus === prescriptionStatus.signed}
            search
            fluid
          />
          <Form.Dropdown
            name="administerRoute"
            selection
            required
            value={administerRoute}
            placeholder="Select..."
            onChange={this.handleFieldChange}
            options={drugRoutes}
            label="Route"
            search
            disabled={drugRoutes.length === 0 || rxStatus === prescriptionStatus.signed}
            fluid
          />
          <Form.Dropdown
            name="drugKey"
            selection
            required
            placeholder="Select..."
            value={drugKey}
            onChange={this.handleFieldChange}
            options={(drugStrengths || []).filter((_strength) => _strength.text)}
            label="Strength"
            search
            disabled={drugStrengths.length === 0 || rxStatus === prescriptionStatus.signed}
            fluid
          />
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Dropdown
            name="dosage"
            selection
            required
            value={dosage}
            placeholder="Select..."
            options={numberPerDoseOptions}
            label="Dose"
            search
            onChange={this.handleFieldChange}
            disabled={rxStatus === prescriptionStatus.signed}
            fluid
          />
          <Form.Dropdown
            name="frequency"
            selection
            required
            value={frequency}
            placeholder="Select..."
            options={frequencyOptions}
            label="Frequency"
            search
            onChange={this.handleFieldChange}
            disabled={rxStatus === prescriptionStatus.signed}
            fluid
          />
          <Form.Input
            required
            label="Quantity"
            name="quantity"
            value={quantity}
            onChange={this.handleFieldChange}
            onBlur={this.handleQuantityOnBlur}
            disabled={rxStatus === prescriptionStatus.signed}
            fluid
          />
          <Form.Dropdown
            name="refills"
            selection
            placeholder="Select..."
            value={refills || 0}
            onChange={this.handleFieldChange}
            options={refillOptions}
            label="Refills"
            disabled={rxStatus === prescriptionStatus.signed || deaClass === 2}
            search
            fluid
          />
        </Form.Group>
        <Form.Checkbox
          label="DAW"
          name="daw"
          width={4}
          checked={daw}
          onChange={this.handleFieldChange}
          disabled={rxStatus === prescriptionStatus.signed}
        />
        <Form.Group>
          <Form.Dropdown
            name="prescriberId"
            selection
            value={prescriberId}
            placeholder="Select..."
            onChange={this.handleFieldChange}
            options={prescribers}
            label="Prescriber"
            width={12}
            disabled={rxStatus === prescriptionStatus.signed}
            search
            compact
          />
        </Form.Group>
        <Form.TextArea
          label="Special Instructions (140 characters)"
          maxLength="140"
          name="specialInstructions"
          value={specialInstructions || ""}
          onChange={this.handleFieldChange}
          onKeyDown={this.handleKeyDown}
          disabled={rxStatus === prescriptionStatus.signed}
          rows={2}
        />
        <Form.TextArea
          label="Note to Pharmacist (169 characters)"
          maxLength="169"
          name="noteToPharmacist"
          value={noteToPharmacist || ""}
          onChange={this.handleFieldChange}
          onKeyDown={this.handleKeyDown}
          rows={5}
          disabled={rxStatus === prescriptionStatus.signed}
        />
        <Header as="h4">{rxStatus}</Header>
        <span>
          {hasInteractions && (
            <Button onClick={this.handleInteractions} className="no-border" basic>
              <Icon name="exclamation triangle" color="orange" />
            </Button>
          )}
          {hasAllergies && (
            <Button onClick={this.handleAllergies} className="no-border" basic>
              <Icon name="dont" color="red" />
            </Button>
          )}
          <Button
            content="Save"
            disabled={
              anyRequiredFieldHasNoValue(payload) || rxStatus === prescriptionStatus.signed || processing || isLoading
            }
            color="blue"
            loading={processing || isLoading}
            onClick={this.handleSubmit}
          />
          <Button
            content={rxStatus === prescriptionStatus.signed ? "Send" : "Sign & Send"}
            disabled={anyRequiredFieldHasNoValue(payload) || !canSendPrescription || processing || isLoading}
            color="blue"
            loading={processing || isLoading}
            type="button"
            onClick={this.handleSend}
          />
        </span>
      </Form>
    );
  }
}

function mapStateToProps(state) {
  return {
    processing: state.ajaxCallsInProgress > 0,
    drugForms: state.lookups.drugForms,
    drugRoutes: state.lookups.drugRoutes,
    numberPerDoseOptions: state.lookups.numberPerDoseOptions,
    frequencyOptions: state.lookups.frequencyOptions,
    refillOptions: state.lookups.refillOptions,
    prescribers: state.user.mainPrescribers.map((prescriber) => {
      const availablePrescriptionSlots = prescriber.availablePrescriptionSlots || 0;
      return {
        value: prescriber.userId,
        text: `${prescriber.firstName} ${prescriber.lastName} (${availablePrescriptionSlots})`,
        availableprescriptionslots: availablePrescriptionSlots,
        fullname: `${prescriber.firstName} ${prescriber.lastName}`
      };
    }),
    drugStrengths: state.lookups.drugStrengths.map((ds) => ({
      text: ds.text,
      value: ds.value,
      deaclass: ds.deaClass,
      drugdescription: ds.drugDescription,
      formdescription: ds.formDescription
    }))
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...modalActions,
        loadDrugForms,
        loadDrugRoutes,
        loadDrugStrengths,
        clearDrugRoutes,
        clearDrugStrengths,
        ...prescriptionActions
      },
      dispatch
    )
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PrescriptionToSignForm));
