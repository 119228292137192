import React, { Component } from "react";
import { Table, Icon, Grid } from "semantic-ui-react";
import { Link } from "react-router-dom";
import moment from "moment";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { shortIsoFormat, timeFormat } from "../../constants/miscellaneous";
import { getLookupText, getProviderName, calculateTimeFromNow } from "../../helpers";
import { UrgentTask } from "../../assets/common/AdditionalIcons";
import "./GroupTable.css";

export class GroupTable extends Component {
  state = {};

  isHasUrgentTask = (tasks) => {
    if(tasks && tasks.length > 0){
      if(tasks.some(t => t.isUrgent)) return true;
    }
    return false;
  }

  render() {
    const {
      group: { visitGroupTime, providerId, groupTypeCode, patients },
      visitStatuses,
      providers,
      groupTypes,
      insuranceProviders
    } = this.props;
    return (
      <React.Fragment>
        <Grid columns={2}>
          <Grid.Row>
            <Grid.Column width={8}>
              <h2 className="providerName-visitGroupTime">
                {providers.length > 0 &&
                  `${getProviderName(providerId, providers)} ${moment(visitGroupTime, shortIsoFormat).format(timeFormat)}`}
              </h2>
            </Grid.Column>
            <Grid.Column width={8}>
              <h2 className="groupTypeName">{`${getLookupText(groupTypeCode, groupTypes)}`}</h2>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Table fixed>
          <Table.Header>
            <Table.Row className="tr-visiting-group">
              <Table.HeaderCell>Visit Start</Table.HeaderCell>
              <Table.HeaderCell>Status Time</Table.HeaderCell>
              <Table.HeaderCell>Patient</Table.HeaderCell>
              <Table.HeaderCell>Chief Complaint</Table.HeaderCell>
              <Table.HeaderCell width={3}>Insurance</Table.HeaderCell>
              <Table.HeaderCell>Main Prescriber</Table.HeaderCell>
              <Table.HeaderCell>Signed</Table.HeaderCell>
              <Table.HeaderCell>Task</Table.HeaderCell>
              <Table.HeaderCell>Status</Table.HeaderCell>
              <Table.HeaderCell>Level</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {patients.map(patient => (
              <Table.Row key={patient.patientId}>
                <Table.Cell data-label="Visit Start">
                  <strong>{moment(patient.visitTime).format(timeFormat)}</strong>
                </Table.Cell>
                <Table.Cell data-label="Status Time">
                  <strong>
                    {calculateTimeFromNow(patient.visitTime)}
                  </strong>
                </Table.Cell>
                <Table.Cell data-label="Patient">
                  <strong>
                    <Link
                      to={`/current-patients/${patient.patientId}/demographics`}
                      style={{ textDecoration: "none" }}
                      key={patient.patientId}
                    >
                      {patient.patientName}
                    </Link>
                  </strong>
                </Table.Cell>
                <Table.Cell data-label="Chief Complaint">
                  <strong>{getLookupText(patient.chiefComplaint, groupTypes)}</strong>
                </Table.Cell>
                <Table.Cell data-label="Insurance">
                  <strong>{getLookupText(patient.insurance, insuranceProviders)}</strong>
                </Table.Cell>
                <Table.Cell data-label="Main Prescriber">
                  <strong>{patient.mainPrescriber}</strong>
                </Table.Cell>
                <Table.Cell data-label="Signed">
                  <strong>{patient.signed && <Icon name="check circle" color="green" />}</strong>
                </Table.Cell>
                <Table.Cell data-label="Task">
                  <strong>{this.isHasUrgentTask(patient.tasks) && <UrgentTask />}</strong>
                </Table.Cell>
                <Table.Cell data-label="Status">
                  <strong>
                    {visitStatuses.length > 0 && getLookupText(patient.visitStatus, visitStatuses)}
                  </strong>
                </Table.Cell>
                <Table.Cell data-label="Level">
                  <div className={`${patient.patientLevel} patient-level`} />
                  <Icon name="circle" color={patient.patientLevel} size="large" />
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    visitStatuses: state.lookups.visitStatuses,
    groupTypes: state.lookups.groupTypes,
    providers: state.providers.allProviders,
    insuranceProviders: state.lookups.insuranceProviders
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({}, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GroupTable);
