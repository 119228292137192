import React from "react";
import shortid from "shortid";
import { Input, Button } from "semantic-ui-react";
import MedicalHistorySliderRow from "./MedicalHistorySliderRow";

import "../SliderPanel.css";

const MedicalHistorySlider = ({
  conditions,
  sliderVisible,
  sliderClose,
  handleQuickSearch,
  handleSearchClick,
  handleSearchEnter,
  handleSearchInput
}) => (
  <div className={`scroll-bar slider-panel ${sliderVisible ? "visible" : ""}`}>
    <Button icon="arrow right" onClick={sliderClose} className="slider-close transparent-button-icon" />
    <div className="search-container">
      <Input
        placeholder="Conditions Search"
        onKeyPress={handleSearchEnter}
        onChange={handleSearchInput}
        icon={{
          name: "search",
          link: true,
          onClick: handleSearchClick
        }}
        id="input-search-med-history"
      />
    </div>
    <div className="search-results-container">
      <h3>common</h3>
      <div>
        {conditions.map(historyMedicalItem => (
          <MedicalHistorySliderRow
            key={shortid.generate()}
            handleQuickSearch={handleQuickSearch}
            {...historyMedicalItem}
          />
        ))}
      </div>
    </div>
  </div>
);

export default MedicalHistorySlider;
