import LZString from "lz-string";

export default function getCsSessionStorage() {
  return class {
    static getItem(key) {
      const item = sessionStorage.getItem(key);
      return item ? JSON.parse(LZString.decompress(item)).value : null;
    }

    static setItem(key, value) {
      sessionStorage.setItem(key, LZString.compress(JSON.stringify({ value })));
    }
  };
}
