import React from "react";
import { Icon, Table } from "semantic-ui-react";

import moment from "moment";

import { dateFormatShortTime, dateWithTimeFormat } from "../../../../constants/miscellaneous";

import "./HistoricalAddressModal.css";

const HistoricalAddressRow = ({
  action = "",
  address1 = "",
  address2 = null,
  city = "",
  stateName = "",
  zip = "",
  dateAdded = "",
  isBilling = false,
  isResidence = false,
  addressType
}) => (
  <Table.Row className="no-bottom-border">
    <Table.Cell className="lbl-action">{action}</Table.Cell>
    <Table.Cell className="lbl-dateCreate">
      {moment
        .utc(dateAdded, dateWithTimeFormat)
        .local()
        .format(dateFormatShortTime)}
    </Table.Cell>
    <Table.Cell className="lbl-msp">
      {isBilling ? <Icon name="credit card" size="large" color="grey" /> : ""}
      {isResidence ? <Icon name="bed" size="large" color="grey" /> : ""}
    </Table.Cell>
    {addressType === "Home" ? (
      <Table.Cell className="lbl-address">
        <p>{address1}</p>
        <p>{address2}</p>
        <p>{`${city}, ${stateName} ${zip}`}</p>
      </Table.Cell>
    ) : (
      <Table.Cell className="lbl-address">{addressType}</Table.Cell>
    )}
  </Table.Row>
);

export default HistoricalAddressRow;
