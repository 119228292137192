import React from "react";
import ReactDOM from "react-dom";

// react-router
import { Router, Route } from "react-router-dom";
// import createBrowserHistory from "history/createBrowserHistory";
import { createBrowserHistory as CreateHistory } from "history";

// semantic
import "semantic-ui-css/semantic.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";

// redux
import { Provider } from "react-redux";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

import { loadCentersLookup } from "./actions/lookupActions";
import { storeUser } from "./actions/authActions";

// auth
import { getUser } from "./adalConfig";

import App from "./components/App";

const history = new CreateHistory();
const store = window.store;

// const authToken = getToken();
const authUser = getUser();

// store.dispatch(storeToken(authToken));
store.dispatch(storeUser(authUser));
store.dispatch(loadCentersLookup());

ReactDOM.render(
  <Provider store={store}>
    <Router history={history}>
      <Route to="/" component={App} />
    </Router>
  </Provider>,
  document.getElementById("root")
);
serviceWorkerRegistration.register();
