import React, { Component } from "react";
import { Button, Header, Modal, Form, Image, Popup } from "semantic-ui-react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as modalActions from "../../../../actions/modalActions";
import * as demographicActions from "../../../../actions/demographicActions";

// eslint-disable-next-line camelcase
import email_ok from "../../../../assets/patients/current/mail_ok.svg";
// eslint-disable-next-line camelcase
import no_email from "../../../../assets/patients/current/no_mail.svg";

import "../../../reusable/Modal.css";
import "./EditEmailAddressModal.css";

const getEmail = props =>
  props.email || {
    address: "",
    name: "",
    description: "",
    isPreferred: false,
    consentGivenForMessages: false
  };

export class EditEmailAddressModal extends Component {
  state = {
    email: getEmail(this.props),
    errors: {},
    confirmationOpen: false
  };

  handleClose = () => this.props.actions.hideModal();

  handleConfirmationOpen = () => {
    const { confirmationOpen } = this.state;
    this.setState({ confirmationOpen: !confirmationOpen });
  };

  handleDelete = () => {
    const { patientId } = this.props;
    const email = Object.assign({}, this.state.email);
    this.props.actions.deleteEmail(patientId, email).then(this.handleClose);
  };

  handleInput = (e, data) => {
    const { name: field, value } = data;
    const email = Object.assign({}, this.state.email, { [field]: value });
    this.setState({ email });
  };

  handleCheckbox = (e, data) => {
    const { name: field, checked } = data;
    const email = Object.assign({}, this.state.email, { [field]: checked });
    this.setState({ email });
  };

  handleSave = () => {
    const { patientId } = this.props;
    const { email } = this.state;
    if (email.emailAddressId) {
      this.props.actions.updateEmail(patientId, email).then(this.handleClose);
    } else {
      this.props.actions.createEmail(patientId, email).then(this.handleClose);
    }
  };

  render() {
    const { open, isEdit, processing } = this.props;
    const {
      email: { address, consentGivenForMessages, confirmationOpen, isPreferred }
    } = this.state;
    // let starColor = isPreferred ? "yellow" : "grey";
    // eslint-disable-next-line camelcase
    const mailIconColor = consentGivenForMessages ? email_ok : no_email;

    return (
      <Modal className="edit-emailaddress-modal" open={open} onClose={this.handleClose} closeIcon size="small">
        <Header content="Manage Email Address" />
        <Modal.Content>
          <Form>
            <Form.Group widths="equal">
              <Form.Field className="emailField">
                <Form.Input
                  required
                  label="Email Address"
                  name="address"
                  type="email"
                  maxLength={255}
                  defaultValue={address}
                  onChange={this.handleInput}
                  id="input-address"
                />
              </Form.Field>
            </Form.Group>
            <Form.Group>
              <Form.Field width={5}>
                <Form.Checkbox
                  label="Primary Contact"
                  name="isPreferred"
                  checked={isPreferred}
                  id="input-isPreferred"
                  onChange={this.handleCheckbox}
                />
              </Form.Field>
              <Form.Field width={7}>
                <Form.Checkbox
                  className="sendMessagesCheckbox"
                  label="Ok to Send Messages"
                  name="consentGivenForMessages"
                  checked={consentGivenForMessages}
                  id="input-consentGivenForMessages"
                  onChange={this.handleCheckbox}
                />
                <Image src={mailIconColor} size="mini" inline />
              </Form.Field>
            </Form.Group>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          {isEdit ? (
            <Popup
              trigger={
                <Button color="red" floated="left" id="btn-delete">
                  Delete
                </Button>
              }
              content={
                <React.Fragment>
                  <Button color="grey" content="Cancel" onClick={this.handleConfirmationOpen} id="btn-confirmCancel" />
                  <Button
                    color="red"
                    content="Delete email address"
                    onClick={this.handleDelete}
                    id="btn-confirmDelete"
                  />
                </React.Fragment>
              }
              on="click"
              position="top left"
              wide
              open={confirmationOpen}
              onOpen={this.handleConfirmationOpen}
            />
          ) : null}
          <Button onClick={this.handleClose} id="btn-cancel">
            Cancel
          </Button>
          <Button onClick={this.handleSave} loading={processing} color="blue" id="btn-save">
            Save
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    patientId: state.patient.currentPatient.patientId,
    processing: state.ajaxCallsInProgress > 0
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...modalActions, ...demographicActions }, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditEmailAddressModal);
