import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Label, Button, Grid, Header } from "semantic-ui-react";
import moment from "moment";
import shortid from "shortid";
import * as modalActions from "../../../../actions/modalActions";
import * as demographicActions from "../../../../actions/demographicActions";
import * as lookupActions from "../../../../actions/lookupActions";
import { roleGroups } from "../../../../constants/securityRoles";
import { checkRoles } from "../../../../helpers";
import "./PatientDischarge.css";
import { dateFormat } from "../../../../constants/miscellaneous";

export class PatientDischarge extends Component {
  componentDidMount() {
    this._isMounted = true;
    const { patientId } = this.props;
    if (patientId) {
      this.props.actions.loadPatientDischargeSummary(patientId);
    }
  }

  componentDidUpdate(prevProps) {
    const { patientId, status } = this.props;
    if (prevProps.status !== status) {
      this.props.actions.loadPatientDischargeSummary(patientId);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  _isMounted = false;

  handleConfirmReverseDischargeModalOpen = () =>
    this.props.actions.showModal({
      type: "CONFIRM_REVERSE_DISCHARGE",
      props: {
        open: true
      }
    });

  render() {
    const { patientDischargeReason, patientDischargeSummary, authRoles } = this.props;
    const showReverse = checkRoles(roleGroups.centerManager, authRoles); // TODO add providers and system admins

    return patientDischargeSummary.map((discharge, index) => (
      <React.Fragment key={discharge.dischargeId}>
        <Label className={discharge.isReversed ? "reversed-discharge-reason-label" : "discharge-reason-label"}>
          {!discharge.isAutoDischarged && (
            <div className={discharge.isReversed ? "reversed-discharge-reason" : "discharge-reason"}>
              <p>
                Discharge Date:{" "}
                {moment(moment(discharge.dischargedOn).startOf("day").format("YYYY-MM-DD")).format(dateFormat)}
              </p>
              <p>Primary Reason: {discharge.primaryReason}</p>
              <p />
              <p>Secondary Reason: {discharge.secondaryReason}</p>
            </div>
          )}
          {discharge.isAutoDischarged && (
            <div className={discharge.isReversed ? "reversed-discharge-reason" : "discharge-reason"}>
              <p>Reason: Auto</p>
            </div>
          )}

          <hr />

          {discharge.surveyResults.map((survey) => (
            <React.Fragment key={`${survey.surveyTitle}-${survey.surveyDate}`}>
              <Grid padded>
                <Grid.Row>
                  <Header>{survey.surveyTitle}</Header>
                </Grid.Row>
                <Grid.Row>
                  {survey.endUserLanguageCollection.map((text) => (
                    <p key={`rand-${shortid.generate()}`}>{text}</p>
                  ))}
                </Grid.Row>
              </Grid>
            </React.Fragment>
          ))}

          {index === 0 && showReverse && patientDischargeReason.canReverse && (
            <div className="divReverse">
              <Button color="red" onClick={this.handleConfirmReverseDischargeModalOpen}>
                Reverse
              </Button>
            </div>
          )}
        </Label>

        <hr />
      </React.Fragment>
    ));
  }
}

function mapStateToProps(state) {
  return {
    patientId: state.patient.currentPatient.patientId,
    authRoles: state.auth.user.profile.roles,
    patientDischargeReason: state.demographics.patientDischargeReason,
    patientDischargeSummary: state.demographics.patientDischargeSummary
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...modalActions, ...demographicActions, ...lookupActions }, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PatientDischarge);
