import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Form, Menu } from "semantic-ui-react";
import { isEmpty, isEqual } from "lodash";
import * as visitActions from "../../../actions/visitActions";
import * as badgeActions from "../../../actions/badgeActions";
import StatusWatchMyPatientTable from "./StatusWatchMyPatientTable";
import AllStatusMyPatientsTable from "./AllStatusMyPatientsTable";
import ScheduledAppointmentsTable from "./ScheduledAppointmentsTable";
import "./MyPatientsPage.css";

export class MyPatientsPage extends Component {
  state = {
    fetching: false
  };
  componentDidMount() {
    this.fetch();
  }

  componentDidUpdate(prevProps) {
    const { centerContext, providerVisitsMessage, providerAppointmentsMessage } = this.props;
    if (centerContext !== prevProps.centerContext) {
      this.fetch();
    }
    if (!isEmpty(providerVisitsMessage) && !isEqual(providerVisitsMessage, prevProps.providerVisitsMessage)) {
      this.fetch();
    }
    if (
      !isEmpty(providerAppointmentsMessage) &&
      !isEqual(providerAppointmentsMessage, prevProps.providerAppointmentsMessage)
    ) {
      this.fetch();
    }
  }

  fetch = () => {
    const { fetching } = this.state;
    if (fetching) return;
    this.setState({ fetching: true });
    this.props.actions.loadMyCalendar().finally(() => {
      this.setState({ fetching: false });
    });
  };

  handlerStatusChange = (e, data) => {
    this.props.actions.updateMyPatientStatusSelectSuccess(data.value);
    this.props.actions.loadMyPatientStatusBadge();
    this.updateStatusToServer(data.value);
  };

  updateStatusToServer(value) {
    this.props.actions.updateStatusWatchMyPatient(value);
  }

  render() {
    const { statuses, visitStatusFilter, centerContext } = this.props;
    return (
      <React.Fragment>
        <div className="header-wrapper">
          <Menu borderless stackable>
            <Menu.Item>
              <h2>My Patients</h2>
            </Menu.Item>
            <Menu.Menu position="right">
              <Menu.Item>
                <h4>Status Watch:</h4>
              </Menu.Item>
              <Menu.Item name="status-watch" className="status-watch-dropdown-width">
                <Form.Dropdown
                  fluid
                  className="drop-status"
                  placeholder="Select a Status..."
                  multiple
                  selection
                  options={statuses}
                  onChange={this.handlerStatusChange}
                  value={visitStatusFilter || []}
                />
              </Menu.Item>
            </Menu.Menu>
          </Menu>
        </div>

        <div className="content-wrapper">
          {!isEmpty(centerContext) && <StatusWatchMyPatientTable />}
          {!isEmpty(centerContext) && <AllStatusMyPatientsTable />}
          {!isEmpty(centerContext) && <ScheduledAppointmentsTable />}
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    myCalendar: state.visits.myCalendar,
    statuses: state.lookups.visitStatuses.filter(
      (item) => item.value !== "visitcomplete" && item.value !== "visitcanceled"
    ),
    visitStatusFilter: state.visits.visitStatusFilter,
    centerContext: state.userPreference.centerContext,
    providerVisitsMessage: state.visits.providerVisitsMessage,
    providerAppointmentsMessage: state.visits.providerAppointmentsMessage
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...visitActions, ...badgeActions }, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MyPatientsPage);
