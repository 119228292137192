import React from "react";
import { Switch, Route } from "react-router-dom";

import VisitingPatientsPage from "./visitingPatients/VisitingPatientsPage";
import ManagePatientSubLayout from "./ManagePatientSubLayout";
import emrComponent from "../common/emrComponent";
import IExportPatient from "./iexport/index";

const PatientSubLayout = ({ match }) => (
  <Switch>
    <Route exact path={match.path} component={VisitingPatientsPage} />
    <Route exact path={`${match.url}/:patientId/iexport`} component={IExportPatient} />
    <Route path={`${match.path}/:patient`} component={ManagePatientSubLayout} />
  </Switch>
);

export default emrComponent(PatientSubLayout);
