import React, { Component } from "react";
import { Input, Grid, Segment, Menu, Icon, Button, Checkbox } from "semantic-ui-react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import head from "lodash/head";

import * as modalActions from "../../../actions/modalActions";
import * as prescriptionActions from "../../../actions/prescriptionActions";

import "./PrescriptionsPage.css";

import RxList from "./RxList";
import PrescriptionHistory from "./PrescriptionHistory";
import PrescriptionsSent from "./PrescriptionsSent";
import StatePdmp from "./StatePdmp";
import prescriptionStatus from "../../../constants/prescriptionStatus";
import { checkRoles } from "../../../helpers";
import { roleGroups } from "../../../constants/securityRoles";

export class ManagePrescriptions extends Component {
  state = {
    activeTap: "Prescriptions Sent",
    prescriptionsToPrint: []
  };

  handleTabClick = (_, name, tab) => {
    if (this.state[tab] !== name) {
      this.setState({ [tab]: name });
    }
  };

  handlePrintPrescriptions = (e, data, prescription) => {
    e.stopPropagation();
    const { checked } = data;

    if (checked !== undefined) {
      const { prescriptionId } = prescription;
      const { prescriptionsToPrint } = this.state;

      if (checked) {
        const exists = prescriptionsToPrint.findIndex((id) => id === prescriptionId) !== -1;

        if (!exists) {
          this.setState({
            prescriptionsToPrint: [...prescriptionsToPrint, prescriptionId]
          });
        }
      } else {
        this.setState({
          prescriptionsToPrint: prescriptionsToPrint.filter((id) => id !== prescriptionId)
        });
      }
    }
  };

  handlePrintAll = (_, data) => {
    const { checked } = data;

    if (checked !== undefined) {
      const { currentRx } = this.props;
      this.setState({
        prescriptionsToPrint: checked ? currentRx.map((rx) => rx.prescriptionId) : []
      });
    }
  };

  handlePrintPrescriptionModalOpen = () => {
    const {
      actions: { showModal },
      patientId,
      mainPrescriberId,
      currentRx
    } = this.props;
    const { prescriptionsToPrint } = this.state;
    const { prescriberId } = head(currentRx) || {};

    showModal({
      type: "PRINT_PRESCRIPTION_MODAL",
      props: {
        open: true,
        prescriptionIds: prescriptionsToPrint,
        patientId,
        mainPrescriberId,
        prescriberId
      }
    });

    this.setState({ prescriptionsToPrint: [] });
  };

  handleSendPrescriptionModalOpen = () => {
    const {
      currentRx,
      actions: { showModal },
      centerId,
      patientId,
      mainPrescriberId
    } = this.props;
    const prescriptionIds = currentRx
      .filter((rx) => rx.prescriptionStatus === prescriptionStatus.signed)
      .map((rx) => rx.prescriptionId);

    showModal({
      type: "SEND_PRESCRIPTION_MODAL",
      props: {
        open: true,
        prescriptionIds,
        patientId,
        centerId,
        mainPrescriberId,
        multiple: true
      }
    });
  };

  handleMedSearchModalOpen = () => {
    const {
      patientId,
      activeRxTab,
      actions: { showModal }
    } = this.props;
    const { searchValue } = this.state;

    showModal({
      type: "SEARCH_MED_PHARM",
      props: {
        open: true,
        activeRxTab,
        searchValue,
        patientId,
        multiple: true
      }
    });
  };

  handleSearchInput = (e, data) => {
    this.setState({ searchValue: data.value });
  };

  handleSearchEnter = (e) => {
    if (e.key === "Enter") {
      this.handleMedSearchModalOpen();
    }
  };

  handleSearchClick = () => {
    this.handleMedSearchModalOpen();
  };

  addAllToCurrent = () => {
    const { recurringRx, patientId } = this.props;
    for (let i = 0; i < recurringRx.length; i++) {
      const prescriptionObj = {
        patientId,
        recurringPrescriptionKey: recurringRx[i].prescriptionId
      };
      this.props.actions.addRecurringToCurrent(patientId, prescriptionObj, recurringRx[i]);
    }
  };

  allSignedRxDoNotHaveSamePrescriber = () => {
    const { currentRx } = this.props;
    const filteredRx = currentRx.filter((rx) => rx.prescriptionStatus === prescriptionStatus.signed && rx.prescriberId);
    const prescriberTotal =
      filteredRx.length > 1
        ? Object.keys(
            filteredRx
              .map((rx) => rx.prescriberId)
              .reduce((acc, curr, i) => {
                if (i === 1) return { [acc]: 1, [curr]: acc === curr ? 2 : 1 };
                return { ...acc, [curr]: (acc[curr] || 0) + 1 };
              })
          ).length
        : filteredRx.length;

    return filteredRx.length === 0 || prescriberTotal !== 1;
  };

  renderPrescriptionTab = (activeTab) => {
    const { patientId } = this.props;
    switch (activeTab) {
      case "Prescription History":
        return <PrescriptionHistory patientId={patientId} />;
      case "Prescriptions Sent":
        return <PrescriptionsSent patientId={patientId} />;
      case "State PDMP":
        return <StatePdmp patientId={patientId} />;
      default:
        return null;
    }
  };

  renderActiveRxTab = (activeTab) => {
    const { patientId, mainPrescriberId } = this.props;
    const { prescriptionsToPrint } = this.state;

    if (activeTab === "Current Rx") {
      return (
        <RxList
          patientId={patientId}
          key={patientId}
          handlePrintPrescriptions={this.handlePrintPrescriptions}
          rxType="currentRx"
          prescriptionsToPrint={prescriptionsToPrint}
          mainPrescriberId={mainPrescriberId}
        />
      );
    }
    return <RxList patientId={patientId} key={patientId} rxType="recurringRx" />;
  };

  render() {
    const { activeTap, prescriptionsToPrint } = this.state;
    const { activeRxTab, recurringRx, currentRx, showEditCurrent, processing, handleRxTabClick, currentUserRoles } =
      this.props;
    const disableAddAll = recurringRx.length === 0;

    const disabledRxButtons = currentRx
      .filter((currentPrescription) => currentPrescription.recurringPrescriptionKey)
      .map((currentPrescription) => currentPrescription.recurringPrescriptionKey).length;

    const canSeeStatePdmp = checkRoles(roleGroups.providers, currentUserRoles);

    return (
      <Grid doubling stackable>
        <Grid.Row>
          <Grid.Column>
            <Menu attached="top" tabular className="prescription-menu">
              <Menu.Item
                name="Current Rx"
                active={activeRxTab === "Current Rx"}
                onClick={() => handleRxTabClick("Current Rx")}
                id="btn-currentRx"
              >
                Current Rx<div className="recurring-rx-badge">{currentRx.length}</div>
              </Menu.Item>
              <Menu.Item
                name="Recurring Rx"
                active={activeRxTab === "Recurring Rx"}
                onClick={() => handleRxTabClick("Recurring Rx")}
                id="btn-recurringRx"
              >
                Recurring Rx<div className="recurring-rx-badge">{recurringRx.length}</div>
              </Menu.Item>
              {showEditCurrent && (activeRxTab === "Current Rx" || activeRxTab === "Recurring Rx") && (
                <Menu.Menu position="right" className="prescriptions-search-add">
                  <Menu.Item>
                    <Input
                      placeholder="Meds Search"
                      onKeyPress={this.handleSearchEnter}
                      onChange={this.handleSearchInput}
                      icon={{
                        name: "search",
                        link: true,
                        onClick: this.handleSearchClick
                      }}
                      id="input-search-prescriptions"
                    />
                  </Menu.Item>
                  <Button className="transparent-button" onClick={this.handleSearchClick} id="btn-add" size="small">
                    <Icon name="plus" />
                    Meds
                  </Button>
                </Menu.Menu>
              )}
            </Menu>
            <Segment attached="bottom">{this.renderActiveRxTab(activeRxTab)}</Segment>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={8}>
            {activeRxTab === "Current Rx" && (
              <div>
                <Checkbox
                  label="All"
                  onClick={this.handlePrintAll}
                  checked={currentRx.length !== 0 && currentRx.length === prescriptionsToPrint.length}
                />
                &nbsp;&nbsp;&nbsp;
                <Button
                  color={prescriptionsToPrint.length === 0 ? "grey" : "blue"}
                  content="Print selected Prescriptions"
                  disabled={prescriptionsToPrint.length === 0}
                  onClick={this.handlePrintPrescriptionModalOpen}
                />
              </div>
            )}
            {activeRxTab === "Recurring Rx" && !disabledRxButtons && showEditCurrent ? (
              <Button
                content="Add all to Current Rx"
                color="blue"
                onClick={this.addAllToCurrent}
                disabled={disableAddAll}
                id="btn-addAllCurrent"
              />
            ) : null}
          </Grid.Column>
          <Grid.Column textAlign="right" width={8}>
            {activeRxTab === "Current Rx" && (
              <Button
                color="blue"
                content="Send all signed prescriptions"
                onClick={this.handleSendPrescriptionModalOpen}
                disabled={this.allSignedRxDoNotHaveSamePrescriber()}
                loading={processing}
              />
            )}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Menu attached="top" tabular>
              {/* <Menu.Item
                name="Prescription History"
                active={activeTap === "Prescription History"}
                onClick={(_, { name }) => this.handleTabClick(_, name, "activeTap")}
                id="prescription-history"
              /> */}

              {canSeeStatePdmp && (
                <Menu.Item
                  name="State PDMP"
                  active={activeTap === "State PDMP"}
                  onClick={(_, { name }) => this.handleTabClick(_, name, "activeTap")}
                  id="state-pdmp"
                />
              )}
              <Menu.Item
                name="Prescriptions Sent"
                active={activeTap === "Prescriptions Sent"}
                onClick={(_, { name }) => this.handleTabClick(_, name, "activeTap")}
                id="prescriptions-sent"
              />
            </Menu>
            <Segment attached="bottom">{this.renderPrescriptionTab(activeTap)}</Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  return {
    patientId: (state.patient.currentPatient || {}).patientId || null,
    recurringRx: state.prescriptions.recurringPrescriptions,
    currentRx: state.prescriptions.currentPrescriptions,
    centerId: state.demographics.homeCenterId,
    mainPrescriberId: state.demographics.prescribingProvider,
    currentUserRoles: state.auth.user.profile.roles,
    processing: state.ajaxCallsInProgress > 0
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...modalActions, ...prescriptionActions }, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ManagePrescriptions);
