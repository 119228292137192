import React, { Component } from "react";
import escapeRegExp from "lodash/escapeRegExp";
import take from "lodash/take";
import filter from "lodash/filter";
import { Search, Header } from "semantic-ui-react";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as centerActions from "../../actions/centerActions";
import * as modalActions from "../../actions/modalActions";

import { removeSpecialCharacters } from "../../helpers";

const resultRenderer = ({ centerName, nickName, centerId, address1, address2, city, state, zip }) => (
  <div>
    <Header as="h4" key={centerId}>
      {centerName}
    </Header>
    <strong>
      {address1} {address2}
      <br />
      {city}, {state} {zip}
    </strong>{" "}
    {nickName ? <div>{nickName}</div> : null}
  </div>
);

export class CenterSearch extends Component {
  state = {
    value: "",
    results: [],
    isLoading: false
  };

  componentDidMount() {
    this.resetComponent();
  }

  handleEditModalOpen = (centerId) => {
    this.props.actions.showModal({
      type: "NEW_CENTER",
      props: { open: true, isNew: false, centerId }
    });
  };

  resetComponent = () => this.setState({ isLoading: false, results: [], value: "" });

  handleResultSelect = (e, { result }) => {
    this.handleEditModalOpen(result.centerId);
    this.setState({ value: result.name });
  };

  handleSearchChange = (e, { value }) => {
    this.setState({ isLoading: true, value });

    setTimeout(() => {
      if (this.state.value.length < 1) this.resetComponent();

      const noSpecialCharacters = removeSpecialCharacters(this.state.value);

      const re = new RegExp(escapeRegExp(noSpecialCharacters), "i");
      const isMatch = (result) => re.test(`${result.city}${result.state}`);

      this.setState({
        isLoading: false,
        results: take(filter(this.props.centers, isMatch), 5)
      });
    }, 500);
  };

  render() {
    const { isLoading, value, results } = this.state;

    return (
      <Search
        loading={isLoading}
        onResultSelect={this.handleResultSelect}
        onSearchChange={this.handleSearchChange}
        results={results}
        value={value}
        resultRenderer={resultRenderer}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    centers: state.centers.items
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...centerActions, ...modalActions }, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CenterSearch);
