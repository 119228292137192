import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Icon } from "../../../../ui";

import "../ViewPatientDemographics.css";

const PatientAddressRow = ({
  address,
  address: { address1, address2, city, state, zip, isBilling, isResidence, addressType },
  handleEditAddress,
  handleDeleteAddress,
  processing
}) => {
  const [deletePressed, setDeletePressed] = useState(false);
  const icons = [...(isBilling ? ["credit-card"] : []), ...(isResidence ? ["house-fill"] : [])];
  return (
    <tr>
      <td className="col-md-1">
        {icons.map((icon) => (
          <Icon key={icon} name={icon} className="h3" />
        ))}
      </td>
      <td className="col-md-7">
        {addressType === "Home" ? (
          <h4 as="h4" className="lbl-address">
            {address1}
            <br />
            {address2 || null}
            {address2 && <br />}
            {city}, {state} {zip}
          </h4>
        ) : (
          <h4 as="h4" className="lbl-address">
            {addressType}
          </h4>
        )}
      </td>
      <td className="col-md-4 text-end">
        <Button
          variant="outline-primary"
          size="sm"
          onClick={() => {
            handleEditAddress(address);
          }}
          title="Edit"
        >
          <Icon name="pencil-square" />
        </Button>
        {` `}
        <Button
          variant="outline-danger"
          size="sm"
          onClick={() => {
            if (!deletePressed) setDeletePressed(true);
            else handleDeleteAddress(address);
          }}
          onMouseLeave={() => {
            setDeletePressed(false);
          }}
          title={deletePressed ? "Confirm Delete" : "Delete"}
          disabled={processing}
          loading={processing}
        >
          {deletePressed ? `Are you sure?` : ``}
          {!deletePressed && <Icon name="trash-fill" />}
        </Button>
      </td>
    </tr>
  );
};
PatientAddressRow.propTypes = {
  address: PropTypes.shape({
    address1: PropTypes.string,
    address2: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zip: PropTypes.string,
    isBilcling: PropTypes.bool,
    isResidence: PropTypes.bool
  }).isRequired,
  // index: PropTypes.number.isRequired,
  handleEditAddress: PropTypes.func.isRequired
};
export default PatientAddressRow;
