import React, { Component } from "react";

class AddSurveyQuestion extends Component {
  state = {
    index: 0,
    afterKey: "",
    question: {
      key: "***-100-Q###",
      sortOrder: 1,
      description: "",
      text: "",
      shortDisplayText: "",
      summaryText: "",
      type: "",
      options: [],
      triggers: [],
      elucidations: [],
      sectionKey: "",
      version: "1.0.0"
    }
  };
  componentDidMount() {
    this.setState({
      index: this.props.questions.length,
      question: {
        ...this.state.question,
        key: this.state.question.key.replace("***", this.props.prefix).replace("###", this.props.questions.length + 1),
        sortOrder: this.props.questions.length + 1,
        type: "ShortText",
        sectionKey: this.props.sections.length > 0 ? this.props.sections[this.props.sections.length - 1].key : null
      }
    });
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.questions && nextProps.questions.length > 0)
      this.setState({
        index: nextProps.questions.length,
        afterKey: nextProps.questions[nextProps.questions.length - 1].key
      });
  }

  onLocationChange = location => {
    switch (location) {
      case "top":
        this.setState({ index: 0 });
        break;
      case "bottom":
        this.setState({ index: this.props.questions.length });
        break;
      case "after":
        this.setState({
          index: this.props.questions.findIndex(question => question.key === this.state.afterKey)
        });
        break;
      default:
        break;
    }
  };
  handleAfterChange = e => {
    this.setState({ afterKey: e.target.value });
  };
  handleQuestionFieldChange = (key, value) => {
    const question = Object.assign({}, this.state.question);
    question[key] = value;
    if (key === "sectionKey") {
      question.sortOrder = this.props.questions.length + 1;
    }
    this.setState({ question });
  };
  render() {
    const { question } = this.state;
    const { sections } = this.props;
    return sections.length < 1 ? (
      <div style={{ padding: "15px" }}>You have to add one section at lease before being able to add questions</div>
    ) : (
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            {this.props.questions.length > 0 && (
              <span>
                <span style={{ marginRight: "10px" }}>
                  <input
                    type="radio"
                    name="location"
                    style={{ margin: "10px" }}
                    onChange={() => this.onLocationChange("top")}
                    checked={this.state.index === 0}
                  />
                  At the top
                </span>
                <br />
                <span style={{ marginRight: "10px" }}>
                  <input
                    type="radio"
                    name="location"
                    style={{ margin: "10px" }}
                    onChange={() => this.onLocationChange("after")}
                    checked={this.state.index !== 0 && this.state.index !== this.props.questions.length}
                  />
                  After
                  <select
                    disabled={this.state.index === 0 || this.state.index === this.props.questions.length}
                    className="ui dropdown"
                    style={{ marginLeft: "10px" }}
                    value={this.state.afterKey}
                    onChange={this.handleAfterChange}
                  >
                    {this.props.questions.map(_question => (
                      <option key={_question.key} value={_question.key}>
                        {_question.key}
                      </option>
                    ))}
                  </select>
                </span>
                <br />
              </span>
            )}
            <span style={{ marginRight: "10px" }}>
              <input
                type="radio"
                name="location"
                style={{ margin: "10px" }}
                onChange={() => this.onLocationChange("bottom")}
                checked={this.state.index === this.props.questions.length}
              />
              At the bottom
            </span>
          </div>
          <div className="col-sm-12">
            <form className="ui form" style={{ padding: "15px" }}>
              <div className="form-group row">
                <label className="col-sm-3 col-form-label">Section</label>
                <div className="col-sm-9">
                  <select
                    className="form-control"
                    value={question.sectionKey}
                    onChange={e => this.handleQuestionFieldChange("sectionKey", e.target.value)}
                  >
                    {sections.map(section => (
                      <option key={section.key} value={section.key}>
                        {section.header} / {section.key}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="form-group row">
                <label className="col-sm-3 col-form-label">key</label>
                <div className="col-sm-9">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="key"
                    value={question.key}
                    onChange={e => this.handleQuestionFieldChange("key", e.target.value)}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label className="col-sm-3 col-form-label">sortOrder</label>
                <div className="col-sm-9">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="sortOrder"
                    value={question.sortOrder}
                    onChange={e => this.handleQuestionFieldChange("sortOrder", e.target.value)}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label className="col-sm-3 col-form-label">description</label>
                <div className="col-sm-9">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="description"
                    value={question.description}
                    onChange={e => this.handleQuestionFieldChange("description", e.target.value)}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label className="col-sm-3 col-form-label">Text</label>
                <div className="col-sm-9">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="text"
                    value={question.text}
                    onChange={e => this.handleQuestionFieldChange("text", e.target.value)}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label className="col-sm-3 col-form-label">EUL</label>
                <div className="col-sm-9">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="shortDisplayText"
                    value={question.shortDisplayText}
                    onChange={e => this.handleQuestionFieldChange("shortDisplayText", e.target.value)}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label className="col-sm-3 col-form-label">HPT</label>
                <div className="col-sm-9">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="summaryText"
                    value={question.summaryText}
                    onChange={e => this.handleQuestionFieldChange("summaryText", e.target.value)}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label className="col-sm-3 col-form-label">Type</label>
                <div className="col-sm-9">
                  <select
                    className="form-control"
                    value={question.type}
                    onChange={e => this.handleQuestionFieldChange("type", e.target.value)}
                  >
                    <option value="ShortText">Short Text</option>
                    <option value="LongText">Long Text</option>
                    <option value="SingleSelect">Single Select</option>
                    <option value="MultiSelect">Multi Select</option>
                  </select>
                </div>
              </div>

              <div
                className="form-group row"
                style={{
                  display: question.type !== "SingleSelect" && question.type !== "MultiSelect" ? "none" : "block"
                }}
              >
                <label className="col-sm-3 col-form-label">options</label>
                <div className="col-sm-9">
                  <textarea
                    type="text"
                    className="form-control"
                    placeholder="options"
                    value={question.options}
                    onChange={e => this.handleQuestionFieldChange("options", e.target.value)}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label className="col-sm-3 col-form-label">triggers</label>
                <div className="col-sm-9">
                  <textarea
                    type="text"
                    className="form-control"
                    placeholder="triggers"
                    value={question.triggers}
                    onChange={e => this.handleQuestionFieldChange("triggers", e.target.value)}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label className="col-sm-3 col-form-label">elucidations</label>
                <div className="col-sm-9">
                  <textarea
                    type="text"
                    className="form-control"
                    placeholder="elucidations"
                    value={question.elucidations}
                    onChange={e => this.handleQuestionFieldChange("elucidations", e.target.value)}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label className="col-sm-3 col-form-label">version</label>
                <div className="col-sm-9">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="version"
                    value={question.version}
                    onChange={e => this.handleQuestionFieldChange("version", e.target.value)}
                  />
                </div>
              </div>
            </form>
          </div>
          <div className="col-sm-12" style={{ padding: "15px" }}>
            <button className="mini ui green button" onClick={() => this.props.onAddQuestion(this.state)}>
              Add
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default AddSurveyQuestion;
