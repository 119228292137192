import React from "react";
import { Grid, Icon, Button, Popup, Header } from "semantic-ui-react";
import { isEmpty } from "lodash";

const GroupedPendingOrders = ({
  orderId,
  grouping,
  handlePrintLabel,
  handleResultEntryClick,
  handleCancelSubmittedClick,
  isAllPSCHold,
  canAddLabOrders,
  canCreateStandingLabOrders,
  canCancelSubmittedLabOrders
}) => {
  const rows = grouping.testTypeGroup.map(({ testType, submissions }) =>
    submissions.map(({ submissionId, requisitionKey, labelDocumentKey, container, isSubmitted, labSourceGroup }) => {
      const ret = [];
      const hasLabs = labSourceGroup.every(({ labs }) => labs && labs.length > 0);
      if (hasLabs) {
        ret.push(
          <Grid.Row key={`hr-${testType}`} style={{ padding: "5px 0 0 0" }}>
            <Grid.Column width={10}>
              <Header className={isSubmitted ? "success" : ""}>{testType}</Header>
            </Grid.Column>
            <Grid.Column width={3} style={{ textAlign: "right" }}>
              {!isEmpty(labelDocumentKey) && !isEmpty(container) && (
                <a
                  role="button"
                  tabIndex={0}
                  onClick={() => handlePrintLabel(labelDocumentKey, container)}
                  className="lab-order-links"
                  onKeyDown={() => {}}
                >
                  <Popup content="Label" trigger={<Icon name="print" color="black" />} />
                </a>
              )}
            </Grid.Column>
            <Grid.Column width={3} style={{ textAlign: "right" }}>
              {!isEmpty(requisitionKey) && !isEmpty(container) && (
                <a
                  role="button"
                  tabIndex={0}
                  onClick={() => handlePrintLabel(requisitionKey, container)}
                  className="lab-order-links"
                  onKeyDown={() => {}}
                >
                  <Popup content="Print Requisition" trigger={<Icon name="print" color="blue" />} />
                </a>
              )}
            </Grid.Column>
          </Grid.Row>
        );
      }

      labSourceGroup.forEach(({ labSource, labs }) => {
        ret.push(
          labs.map(({ labName, labCode, isPSCHold, isProtocolOrderSet, cancelButtonPressed }) => (
            <Grid.Row key={`${labCode}-${testType}`} style={{ padding: "5px 0" }}>
              <Grid.Column width={7}>
                <strong>
                  {labName}
                  {isPSCHold && !isAllPSCHold && " (PSC hold)"}
                  {false && isProtocolOrderSet && (
                    <Popup
                      content="Test added via protocol order set"
                      trigger={
                        <span>
                          &nbsp;
                          <Icon name="info circle" />
                        </span>
                      }
                    />
                  )}
                </strong>
              </Grid.Column>

              <Grid.Column width={5} style={{ direction: "rtl", whiteSpace: "nowrap" }}>
                {grouping && grouping.labProvider === "Clean Slate Rapid" && (
                  <div style={{ textAlign: "right" }}>
                    {(canAddLabOrders || canCreateStandingLabOrders) && (
                      <Button
                        className="transparent-button"
                        style={{ padding: "5px" }}
                        onClick={() => handleResultEntryClick(orderId, labCode)}
                      >
                        Add Result
                      </Button>
                    )}
                  </div>
                )}

                {grouping && isSubmitted && grouping.labProvider !== "Clean Slate Rapid" && (
                  <div style={{ textAlign: "right" }}>
                    {canCancelSubmittedLabOrders && (
                      <Button
                        size="small"
                        color="red"
                        style={{ padding: "5px" }}
                        onClick={() =>
                          handleCancelSubmittedClick(
                            orderId,
                            grouping.labProvider,
                            testType,
                            submissionId,
                            labSource,
                            labCode,
                            labName
                          )
                        }
                        loading={cancelButtonPressed}
                        disabled={cancelButtonPressed}
                      >
                        Cancel
                      </Button>
                    )}
                  </div>
                )}
              </Grid.Column>

              <Grid.Column width={4} style={{ textAlign: "right" }}>
                {labSource}
              </Grid.Column>
            </Grid.Row>
          ))
        );
      });
      return (
        <Grid.Row key={submissionId} style={{ padding: "0" }}>
          <Grid padded className={isSubmitted ? "success" : ""}>
            {ret}
          </Grid>
        </Grid.Row>
      );
    })
  );

  return <Grid padded>{rows}</Grid>;
};

export default GroupedPendingOrders;
