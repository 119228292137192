import { toast } from "react-toastify";
import * as types from "../actions/actionTypes";
import initialState from "./initialState";
import { toastErrorOptions } from "../constants/toastconfig";

// function actionTypeEndsInSuccess(type) {
//   return type.substring(type.length - 8) === "_SUCCESS";
// }

// function commonErrorUserText(errorCode) {
//   switch (errorCode) {
//     case 403:
//       return `You do not have access to perform this action.`;
//     default:
//       break;
//   }
// }

export default function ajaxStatusReducer(state = initialState.ajaxCallsInProgress, action) {
  if (action.type === types.BEGIN_AJAX_CALL) {
    return state + 1;
  }
  if (action.type === types.AJAX_CALL_ERROR) {
    if (!action.origin) {
      if (action.error !== undefined) toast.error(action.error, toastErrorOptions);
    }

    return state && state - 1;
  }
  if (
    action.type === types.AJAX_CALL_DONE
    // || actionTypeEndsInSuccess(action.type) // really!
  ) {
    // decrement ajaxCallsInProgress unless state is 0
    return state && state - 1;
  }

  return state;
}
