import React, { Component } from "react";
import { Button } from "semantic-ui-react";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as modalActions from "../../../../actions/modalActions";
import * as lookupActions from "../../../../actions/lookupActions";

import InterventionHistoryTable from "./InterventionHistoryTable";
import InterventionHistorySlider from "./InterventionHistorySlider";

import { roleGroups } from "../../../../constants/securityRoles";
import { checkRoles } from "../../../../helpers";

import "../TableSliderComponent.css";

export class ManageInterventionHistory extends Component {
  state = {
    sliderVisible: null,
    searchValue: "",
    conditions: []
  };

  componentDidMount() {
    this.props.actions.loadInterventionConditions();
  }

  sliderOpen = () => {
    this.setState({
      sliderVisible: true
    });
  };

  sliderClose = () => {
    this.setState({
      sliderVisible: false
    });
  };

  handleSearchInput = (e, data) => {
    this.setState({ searchValue: data.value });
  };

  handleQuickSearch = (value) => {
    this.props.actions.showModal({
      type: "ADD_INTERVENTION_HISTORY",
      props: {
        open: true,
        conditionToAdd: value.code
      }
    });
  };

  render() {
    const { authRoles } = this.props;
    const { sliderVisible } = this.state;
    const { conditions } = this.props;
    const showAddHistory = checkRoles(roleGroups.excludeHelpDesk, authRoles);

    return (
      <div className="table-slider-container">
        <h2>Interventions</h2>
        {showAddHistory && (
          <Button
            icon="plus"
            className="slider-open transparent-button-icon"
            onClick={this.sliderOpen}
            id="btn-open-intervention-history"
          />
        )}
        <InterventionHistoryTable />
        <InterventionHistorySlider
          conditions={conditions}
          sliderVisible={sliderVisible}
          sliderClose={this.sliderClose}
          handleQuickSearch={this.handleQuickSearch}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    authRoles: state.auth.user.profile.roles,
    conditions: state.lookups.interventionConditions
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...modalActions, ...lookupActions }, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageInterventionHistory);
