import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";
import { isEmpty, set } from "lodash";
import { Dropdown, Form, Grid, Icon } from "semantic-ui-react";
import { DateInput } from "semantic-ui-calendar-react";
import Report from "./Report";
import { dateFormat, dateWithTimeFormat, isoDateFormat } from "../../constants/miscellaneous";

export class AppointmentReminderConfirmationReport extends React.Component {
  state = {
    fetchCounter: 0,
    filter: {
      appointmentFrom: moment().format(isoDateFormat),
      appointmentTo: moment().add(2, "days").format(isoDateFormat),
      centerIds: []
    }
  };

  handleInput = (e, data) => {
    const { name: field } = data;
    let { value } = data;
    const newState = { ...this.state };
    if (field === "filter.appointmentFrom" || field === "filter.appointmentTo") {
      value = isEmpty(value) ? "" : moment(value, dateFormat).format(isoDateFormat);
    }
    set(newState, field, value);
    this.setState(newState);
  };

  refetch = () => {
    this.setState({ fetchCounter: this.state.fetchCounter + 1 });
  };

  renderFilterForm = () => {
    const { centerOptions } = this.props;
    const { filter } = this.state;
    return (
      <Grid.Column width={16}>
        <Form>
          <Grid>
            <Grid.Row style={{ paddingBottom: "0" }}>
              <Grid.Column width={1} />
              <Grid.Column width={2}>
                Appointment date from
                <br />
                <Form.Group widths="equal">
                  <DateInput
                    size="mini"
                    name="filter.appointmentFrom"
                    placeholder="From"
                    value={isEmpty(filter.appointmentFrom) ? "" : moment(filter.appointmentFrom).format(dateFormat)}
                    dateFormat={dateFormat}
                    iconPosition="right"
                    onChange={this.handleInput}
                    hideMobileKeyboard
                    clearable
                    closable
                  />
                </Form.Group>
              </Grid.Column>
              <Grid.Column width={2}>
                Appointment date to
                <br />
                <Form.Group widths="equal">
                  <DateInput
                    size="mini"
                    name="filter.appointmentTo"
                    placeholder="To"
                    value={isEmpty(filter.appointmentTo) ? "" : moment(filter.appointmentTo).format(dateFormat)}
                    dateFormat={dateFormat}
                    iconPosition="right"
                    onChange={this.handleInput}
                    hideMobileKeyboard
                    clearable
                    closable
                  />
                </Form.Group>
              </Grid.Column>
              <Grid.Column width={6}>
                Center(s)
                <br />
                <Form.Group widths="equal" style={{ margin: "0 0 1em" }}>
                  <Dropdown
                    name="filter.centerIds"
                    // label="Clinic:"
                    // placeholder="Select a Center..."
                    options={centerOptions}
                    onChange={this.handleInput}
                    value={filter.centerIds}
                    // style={{ display: "inline-block" }}
                    className="mini"
                    clearable
                    fluid
                    multiple
                    selection
                    search
                  />
                </Form.Group>
              </Grid.Column>
              <Grid.Column width={2}>
                {` `}
                <br />
                <Form.Button size="mini" color="blue" onClick={this.refetch}>
                  Filter
                  <Icon name="search" style={{ marginLeft: "5px" }} />
                </Form.Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
      </Grid.Column>
    );
  };

  render() {
    return (
      <Report
        endPoint="AppointmentReminderConfirmation"
        fetchCounter={this.state.fetchCounter}
        header="Appointment Reminder Confirmation"
        rowKeyField="id"
        filterObject={this.state.filter}
        beforeFetch={(filterObject) =>
          Object.keys(filterObject).reduce((acc, key) => {
            if (!isEmpty(filterObject[key])) {
              acc[key] = filterObject[key];
            }
            return acc;
          }, {})
        }
        renderFilterForm={this.renderFilterForm}
        fields={[
          { name: "patientId", visible: false },
          {
            name: "medicalRecordNumber",
            label: "MRN",

            render: (row) => (
              <Link
                to={`/current-patients/${row.patientId}/demographics`}
                target="_blank"
                style={{ textDecoration: "none" }}
                key={row.patientId}
              >
                {row.medicalRecordNumber}
              </Link>
            )
          },
          { name: "firstName", label: "First Name" },
          { name: "lastName", label: "Last Name" },
          {
            name: "startTime",
            label: "Appointment Date & Time",
            render: (row) => moment(row.startTime).format(dateWithTimeFormat)
          },
          { name: "sentAt", label: "Date Text Sent", render: (row) => moment(row.sentAt).format(dateWithTimeFormat) },
          {
            name: "confirmed",
            label: "Did patient confirm?",
            sortable: false,
            render: (row) => (row.visitTypeCode === "random" ? "N/A" : row.reply?.toLowerCase() === `c` ? "Yes" : "No")
          },
          { name: "reply", label: "Reply" },
          { name: "centerName", label: "Appointment Center" },
          { name: "appointmentStatus", label: "Status" }
        ]}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    centerOptions: state.lookups.centers.map((i) => ({ text: i.text, value: i.value }))
  };
}

export default connect(mapStateToProps)(AppointmentReminderConfirmationReport);
