import * as types from "./actionTypes";

export function beginAjaxCall(origin) {
  return { type: types.BEGIN_AJAX_CALL, origin };
}
export function ajaxCallDone() {
  return { type: types.AJAX_CALL_DONE };
}
export function ajaxCallError(origin, error) {
  return { type: types.AJAX_CALL_ERROR, origin, error };
}
