import React, { Component } from "react";
import { Search, Header, Segment } from "semantic-ui-react";
import { withRouter } from "react-router-dom";
import shortid from "shortid";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { debounce } from "lodash";
import * as searchActions from "../../actions/searchActions";

const PatientSearchResultWrapper = params => (
  <div className={params.className} role="button" tabIndex={0} onClick={e => params.onClick(e)}>
    {params.children}
  </div>
);

const PatientSearchResult = ({ patientId, firstName, lastName, city, state }) => (
  // causing a key prop warning in console.
  // https://github.com/Semantic-Org/Semantic-UI-React/issues/1141
  <Segment basic key={patientId}>
    <Header as="h4" content={`${firstName} ${lastName}`} />
    {city && state ? (
      <div>
        {city}, {state}
      </div>
    ) : (
      "No address information."
    )}
  </Segment>
);

class PatientSearch extends Component {
  state = {
    value: "",
    result: { patients: [], totalPatientsCount: 0 }
  };

  componentDidMount() {
    this.resetComponent();
  }

  resetComponent = () => this.setState({ result: { patients: [], totalPatientsCount: 0 }, value: "" });

  handleResultSelect = (e, data) => {
    const { patientId } = data.result;

    if (this.props.handlePatientSelected) {
      this.props.handlePatientSelected(this, data.result);
      return;
    }

    this.props.history.push(`/current-patients/${patientId}/demographics`);
    this.resetComponent();
  };

  debouncedSearch = debounce(() => this.handleSearchChange(), 500);
  handleInput = (e, { value }) => {
    if (value.length < 3) this.setState({ result: { patients: [], totalPatientsCount: 0 }, value });
    else this.setState({ value }, this.debouncedSearch);
  };

  handleSearchChange = () => {
    const { value } = this.state;
    if (value.length < 1) {
      this.resetComponent();
    } else {
      const {
        actions: { searchPatientAdvanced }
      } = this.props;
      searchPatientAdvanced({ value, pageSize: 10, page: 1 }).then(result => {
        this.setState({
          result: result.patients
        });
      });
    }
  };

  render() {
    const { value, result } = this.state;
    const { processing, className } = this.props;
    return (
      <Search
        loading={processing}
        className={className || ""}
        onResultSelect={this.handleResultSelect}
        onSearchChange={this.handleInput}
        results={result.patients.map(p => ({
          childKey: `rand-${shortid.generate()}` /* using rand key here as it really happened on prod .. we have duplicate patient id */,
          as: PatientSearchResultWrapper,
          id: p.platformId,
          title: `${p.firstName} ${p.lastName}`,
          patientId: p.platformId,
          firstName: p.firstName,
          lastName: p.lastName,
          city: p.city,
          state: p.state
        }))}
        value={value}
        resultRenderer={PatientSearchResult}
        placeholder="Patient Search..."
        fluid
        id={`input-patient-search-rand-${shortid.generate()}`}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    patients: state.search.patients,
    processing: state.ajaxCallsInProgress > 0
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(searchActions, dispatch)
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(PatientSearch)
);
