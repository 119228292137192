import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function centersReducer(state = initialState.auth, action) {
  switch (action.type) {
    case types.STORE_USER:
      return Object.assign({}, state, {
        user: action.user
      });
    default:
      return state;
  }
}
