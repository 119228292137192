import React from "react";
import find from "lodash/find";
import { Form, Input } from "semantic-ui-react";
import MaskedInput from "react-text-mask";
import { ssnMask } from "../../constants/validation";

const AdvancedSearch = ({
  filter,
  handleSearchInput,
  handleInput,
  handleCheckbox,
  handleAdvancedSearchInput,
  states
}) => {
  // Add default state option
  const defaultState = {
    value: "",
    text: "All States"
  };

  const stateOptions = [...states];
  if (!find(stateOptions, defaultState)) {
    stateOptions.unshift(defaultState);
  }

  return (
    <div className="advanced-search-container">
      <Input
        icon="search"
        placeholder="Patient Search..."
        onChange={handleSearchInput}
        className="advanced-search-input"
      />
      <Form>
        <Form.Group>
          <Form.Checkbox
            label="All Centers"
            name="center"
            checked={filter.center}
            id="input-isBilling"
            className="advanced-search-center"
            onChange={handleCheckbox}
          />
          <Form.Field width={3}>
            <Form.Dropdown
              fluid
              selection
              search
              placeholder="All States"
              options={stateOptions}
              name="state"
              value={filter.state}
              onChange={handleInput}
              id="input-state"
              className="advanced-search-states"
            />
          </Form.Field>
          <Form.Field>
            <MaskedInput
              mask={ssnMask}
              guide={false}
              name="socialSecurityNumber"
              value={filter.socialSecurityNumber}
              onChange={handleInput}
              placeholder="Social Security Number"
            />
          </Form.Field>
          <Form.Field>
            <Form.Input
              placeholder="Policy Number"
              name="insurancePolicyNumber"
              value={filter.insurancePolicyNumber}
              onChange={handleInput}
            />
          </Form.Field>
          <Form.Button
            icon="search"
            content="Search"
            color="blue"
            value=""
            className="advanced-search-button"
            onClick={handleAdvancedSearchInput}
          />
        </Form.Group>
      </Form>
    </div>
  );
};

export default AdvancedSearch;
