import React from "react";
import { Form } from "semantic-ui-react";
import { hasProperty } from "../../../../helpers";

const AdditionalInformationForm = ({
  model,
  errors,
  disableEdit,
  handleInput,
  handleFocus,
  abilityReadWritePreferredLanguageOptions,
  needAssistedTechnologyOptions
}) => (
  <Form>
    <Form.Group widths="equal">
      <Form.Field disabled={disableEdit}>
        <label className="mock-semantic">Ability to read/write in preferred language</label>
        <Form.Dropdown
          fluid
          selection
          search
          name="model.abilityReadWritePreferredLanguage"
          placeholder="Select..."
          options={abilityReadWritePreferredLanguageOptions}
          value={model.abilityReadWritePreferredLanguage}
          onChange={handleInput}
          error={hasProperty(errors, "abilityReadWritePreferredLanguage")}
          onFocus={handleFocus}
          disabled={disableEdit}
        />
      </Form.Field>
      <Form.Field disabled={disableEdit}>
        <label className="mock-semantic">Need for assisted technology</label>
        <Form.Dropdown
          fluid
          selection
          search
          name="model.needAssistedTechnology"
          placeholder="Select..."
          options={needAssistedTechnologyOptions}
          value={model.needAssistedTechnology}
          onChange={handleInput}
          error={hasProperty(errors, "needAssistedTechnology")}
          onFocus={handleFocus}
          disabled={disableEdit}
        />
      </Form.Field>
    </Form.Group>
    <Form.Group widths="equal">
      <Form.Field disabled={disableEdit} style={{ height: "100px" }} />
    </Form.Group>
  </Form>
);

export default AdditionalInformationForm;
