import React, { Component } from "react";
import { Table } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import moment from "moment";
import { isEqual } from "lodash";
import * as chartsActions from "../../actions/chartsActions";
import * as visitActions from "../../actions/visitActions";
import * as prescriptionActions from "../../actions/prescriptionActions";
import * as labActions from "../../actions/labActions";
import * as modalActions from "../../actions/modalActions";
import * as badgeActions from "../../actions/badgeActions";
import { timeFormat, dateFormat } from "../../constants/miscellaneous";
import VisitSummary from "../patients/visits/VisitSummary";
import { PatientLevel } from "../../ui";
import { isVisitTypeMedical } from "../../helpers";

class ChartsToCoSignTable extends Component {
  state = {
    data: [],
    sortColumn: "visitDateTime",
    sortDirection: "descending",
    amendmentContents: {},
    expandedVisitId: null,
    isLoading: false
  };

  componentDidMount() {
    document.body.style.backgroundColor = "#fff";
    // this.props.actions.loadCoSignBadge();
  }

  componentDidUpdate(prevProps) {
    // Change provider
    const { selectedProvider, coSignBadge } = this.props;
    if (!isEqual(selectedProvider, prevProps.selectedProvider) || coSignBadge !== prevProps.coSignBadge) {
      this.loadData();
      // this.props.actions.loadCoSignBadge();
    }
  }

  componentWillUnmount() {
    document.body.style.backgroundColor = "#f3f3f3";
  }

  // Amr this is not used!
  // isNonMedicalVisit = visitType =>
  //   ["carecoordinationvisit", "certrecoveryspecialistvisit", "group", "sudcounselingvisit"].includes(visitType);

  handleSort = (clickedColumn) => () => {
    const { sortColumn, sortDirection } = this.state;
    this.setState(
      {
        sortColumn: clickedColumn,
        sortDirection: sortDirection === "ascending" && sortColumn === clickedColumn ? "descending" : "ascending"
      },
      () => {
        this.setState({
          data: this.prepareData(this.state.data)
        });
      }
    );
  };

  prepareData = (data) =>
    data.sort((a, b) => {
      const { sortColumn, sortDirection } = this.state;
      if (sortDirection === "ascending")
        return a[sortColumn] > b[sortColumn] ? 1 : a[sortColumn] < b[sortColumn] ? -1 : 0;
      return a[sortColumn] < b[sortColumn] ? 1 : a[sortColumn] > b[sortColumn] ? -1 : 0;
    });

  loadData() {
    const {
      selectedProvider: { providerId, stateCode, selectedReport }
    } = this.props;
    if (providerId && stateCode && selectedReport) {
      this.props.actions
        .loadChartsToCoSignReport(providerId, stateCode, selectedReport.year, selectedReport.month)
        .then((resp) => {
          this.setState({
            data: this.prepareData(resp ? resp.listVisit.chartsToCoSign : [])
          });
        });
    } else {
      this.setState({ data: [] });
    }
  }

  handleCoSign = (visitId, patientId, providerId, stateCode) => () => {
    this.props.actions.coSignVisit(patientId, visitId, providerId, stateCode, true).then(() => {
      this.loadData();
      if (this.props.afterCoSign) this.props.afterCoSign();
    });
  };

  toggleVisitSummaryView = (visitId) => {
    const {
      actions: {
        loadVisitSummary,
        loadVisitAmendment,
        loadSignedChartDetails,
        loadCoSignedChartDetails,
        loadPrescriptionsSentByVisit,
        loadLabOrdersAndResultsPerVisit,
        getVisitLocations
      }
    } = this.props;
    this.setState({ expandedVisitId: this.state.expandedVisitId === visitId ? null : visitId }, () => {
      if (visitId) {
        loadVisitSummary(visitId);
        loadVisitAmendment(visitId);
        loadSignedChartDetails(visitId);
        loadCoSignedChartDetails(visitId);
        loadPrescriptionsSentByVisit(visitId);
        getVisitLocations(visitId, "SUMMARY");
        loadLabOrdersAndResultsPerVisit(visitId);
      }
    });
  };

  handleInput = (visitId, value) => {
    this.setState({ amendmentContents: { ...this.state.amendmentContents, [visitId]: value } });
  };

  handleAddAmendment = (patientId, visitId) => {
    const { amendmentContents } = this.state;
    // check null or empty, when button is loading then return.
    if (amendmentContents[visitId].trim().length === 0 || this.state.isLoading) return;
    const body = {
      patientId,
      visitId,
      content: amendmentContents[visitId]
    };
    // start loading
    this.setState({ isLoading: true });
    this.props.actions
      .createVisitAmendment(body)
      .then(() => {
        this.props.actions.loadVisitAmendment(visitId);
      })
      .finally(() => {
        // end loading and set value is empty
        this.setState({
          isLoading: false,
          amendmentContents: { ...this.state.amendmentContents, [visitId]: "" }
        });
      });
  };

  render() {
    const {
      visitSummary,
      visitAmendment,
      currentUserRoles,
      latestVisitSignedChart,
      cosignDetail,
      prescriptionsSent,
      visitSummaryLocations,
      labOrdersAndResultsPerVisit,
      centerOptions,
      visitTypes,
      processing
    } = this.props;
    const { data, expandedVisitId, sortColumn, sortDirection, amendmentContents } = this.state;
    const isSigned = Object.keys(latestVisitSignedChart).length > 0;
    const isCoSigned = Object.keys(cosignDetail).length > 0;
    const expandedVisit = data.find((i) => i.visitId === expandedVisitId);
    const visitTypeObject = expandedVisit ? visitTypes.find((v) => v.value === expandedVisit.visitTypeCode) : null;

    return (
      <React.Fragment>
        <Table sortable className="no-margin cosign-table">
          <Table.Header>
            <Table.Row className="non-sticky">
              <Table.HeaderCell
                sorted={sortColumn === "visitDateTime" ? sortDirection : null}
                onClick={this.handleSort("visitDateTime")}
              >
                Visit Date/Time
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={sortColumn === "medicalRecordNumber" ? sortDirection : null}
                onClick={this.handleSort("medicalRecordNumber")}
              >
                MRN
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={sortColumn === "lastName" ? sortDirection : null}
                onClick={this.handleSort("lastName")}
              >
                Patient
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={sortColumn === "visitTypeName" ? sortDirection : null}
                onClick={this.handleSort("visitTypeName")}
              >
                Visit type
              </Table.HeaderCell>
              <Table.HeaderCell className="text-center">Level</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          {data && data.length > 0 && (
            <Table.Body>
              {data.map((item) => (
                <React.Fragment key={`fragment-${item.visitId}`}>
                  <Table.Row key={`row-${item.visitId}`} onClick={() => this.toggleVisitSummaryView(item.visitId)}>
                    <Table.Cell>
                      {moment(item.visitDateTime).format(dateFormat)}
                      <span className="sp-visit-time">{moment(item.visitDateTime).format(timeFormat)}</span>
                    </Table.Cell>
                    <Table.Cell>{item.medicalRecordNumber}</Table.Cell>
                    <Table.Cell
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <Link
                        to={`/current-patients/${item.patientId}/visits/vid/${item.visitId}`}
                        target="_blank"
                        style={{ textDecoration: "none" }}
                        key={item.patientId}
                      >
                        {item.patientName}
                      </Link>
                    </Table.Cell>
                    <Table.Cell>{item.visitTypeName}</Table.Cell>
                    <Table.Cell data-label="Level" className="text-center">
                      {isVisitTypeMedical(item.visitTypeCode) ? (
                        <PatientLevel level={item.patientLevel ? item.patientLevel : "orange"} size="sm" />
                      ) : (
                        item.bhLevel && <PatientLevel level={item.bhLevel} size="sm" bh />
                      )}
                    </Table.Cell>
                  </Table.Row>
                  {expandedVisitId === item.visitId && visitSummary && (
                    <Table.Row key={`row-expanded-${item.visitId}`} className="visit-summary-row">
                      <Table.Cell colSpan={5} style={{ padding: "1em" }}>
                        <VisitSummary
                          visitId={item.visitId}
                          patientId={item.patientId}
                          visitSummary={visitSummary}
                          visitAmendment={visitAmendment}
                          authRoles={currentUserRoles}
                          isLoading={processing}
                          isSigned={isSigned}
                          isCoSigned={isCoSigned}
                          signDetail={latestVisitSignedChart}
                          cosignDetail={cosignDetail}
                          prescriptionsSent={prescriptionsSent}
                          visitTypeObject={visitTypeObject}
                          visitSummaryLocations={visitSummaryLocations}
                          amendmentContent={amendmentContents[item.visitId] || ``}
                          labOrdersAndResultsPerVisit={labOrdersAndResultsPerVisit}
                          handleInput={(_, _data) => {
                            this.handleInput(item.visitId, _data.value);
                          }}
                          handleAddAmendment={(e) => {
                            e.preventDefault();
                            this.handleAddAmendment(item.patientId, item.visitId);
                          }}
                          centerOptions={centerOptions}
                          listVisitSelected={[]}
                          handleCoSign={this.handleCoSign}
                          signCosignBarPosition="bottom"
                          showCoSign
                        />
                      </Table.Cell>
                    </Table.Row>
                  )}
                </React.Fragment>
              ))}
            </Table.Body>
          )}
        </Table>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentUserRoles: state.auth.user.profile.roles,
    // listVisitCoSign: state.charts.listVisitCoSign,
    coSignBadge: state.badges.chartsToCoSign,
    visitSummary: state.visits.visitSummary,
    visitAmendment: state.visits.visitAmendment,
    labOrdersAndResultsPerVisit: state.labs.labOrdersAndResultsPerVisit || {},
    latestVisitSignedChart: state.charts.latestVisitSignedChart,
    cosignDetail: state.charts.cosignDetail,
    prescriptionsSent: state.prescriptions.prescriptionsSentByVisit,
    visitTypes: state.lookups.visitTypes,
    visitSummaryLocations: state.visits.visitSummaryLocations,
    centerOptions: state.lookups.centers.map((i) => ({ text: i.text, value: i.value })),
    processing: state.ajaxCallsInProgress > 0
  };
}
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...chartsActions,
        ...modalActions,
        ...visitActions,
        ...labActions,
        ...prescriptionActions,
        ...badgeActions
      },
      dispatch
    )
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ChartsToCoSignTable);
