import { API_URL } from "../constants/environment";
import { adalApiFetch } from "../adalConfig";

export const fetchDrugAllergies = (patientId, drugKey) =>
  adalApiFetch(`${API_URL}/Prescriptions/${patientId}/Screening/${drugKey}/Allergies`);

export const fetchPrescriptionInteractions = prescriptionId =>
  adalApiFetch(`${API_URL}/Prescriptions/${prescriptionId}/Interactions`);

export const fetchPrescriptionAllergies = prescriptionId =>
  adalApiFetch(`${API_URL}/Prescriptions/${prescriptionId}/Allergies`);

export default {
  fetchDrugAllergies,
  fetchPrescriptionInteractions,
  fetchPrescriptionAllergies
};
