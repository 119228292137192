import React from "react";
import { Alert as BsAlert } from "react-bootstrap";

const Alert = React.forwardRef(({ children, ...rest }, ref) => (
  <BsAlert {...rest} ref={ref}>
    {children}
  </BsAlert>
));

export default Alert;
