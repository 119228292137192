import React from "react";
import { Form } from "semantic-ui-react";

const MilitaryServiceForm = ({
  model,
  disableEdit,
  handleInput,
  handleFocus,
  everBeenInMilitaryServiceOptions,
  currentMilitaryInvolvementDescriptionOptions,
  interestedInReferMilitaryInvolvementOptions
}) => (
  <Form>
    <Form.Group widths="equal">
      <Form.Field>
        <label>Have you ever been in military service?</label>
        {everBeenInMilitaryServiceOptions.map((option) => (
          <Form.Radio
            key={option.value}
            name="model.everBeenInMilitaryService"
            label={option.text}
            value={option.value}
            checked={model.everBeenInMilitaryService === option.value}
            onChange={handleInput}
            onFocus={handleFocus}
            disabled={disableEdit}
            inline
          />
        ))}
      </Form.Field>
    </Form.Group>
    <Form.Group widths="equal">
      <Form.Field>
        <label className="mock-semantic" style={{ display: "block" }}>
          Please describe your current involvement with the military
        </label>
        {currentMilitaryInvolvementDescriptionOptions.map((option) => (
          <Form.Checkbox
            key={option.value}
            name="model.currentMilitaryInvolvementDescription"
            label={option.text}
            value={option.value}
            checked={model.currentMilitaryInvolvementDescription.includes(option.value)}
            onChange={handleInput}
            onFocus={handleFocus}
            disabled={disableEdit}
            inline
          />
        ))}
      </Form.Field>
    </Form.Group>
    <Form.Group widths="equal">
      <Form.Field>
        <label className="mock-semantic">
          Are you interested in counseling or referral related to your military involvement?
        </label>
        {interestedInReferMilitaryInvolvementOptions.map((option) => (
          <Form.Radio
            key={option.value}
            name="model.interestedInReferMilitaryInvolvement"
            label={option.text}
            value={option.value}
            checked={model.interestedInReferMilitaryInvolvement === option.value}
            onChange={handleInput}
            // error={hasProperty(errors, "interestedInReferMilitaryInvolvement")}
            onFocus={handleFocus}
            disabled={disableEdit}
            inline
          />
        ))}
      </Form.Field>
    </Form.Group>
    <Form.Group widths="equal">
      <Form.Field>
        <label className="mock-semantic" style={{ display: "block" }}>
          Additional comments
        </label>
        <Form.TextArea
          name="model.additionalComments"
          placeholder="Descripe"
          defaultValue={model.additionalComments}
          onChange={handleInput}
          // error={hasProperty(errors, "additionalComments")}
          onFocus={handleFocus}
          disabled={disableEdit}
        />
      </Form.Field>
    </Form.Group>
  </Form>
);

export default MilitaryServiceForm;
