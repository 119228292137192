import React from "react";
import { Input, Button } from "semantic-ui-react";

import "../SliderPanel.css";

const MedicationSlider = ({ sliderVisible, sliderClose, handleSearchClick, handleSearchEnter, handleSearchInput }) => (
  <div className={`slider-panel ${sliderVisible ? "visible" : ""}`}>
    <Button icon="arrow right" onClick={sliderClose} className="slider-close transparent-button-icon" />
    <div className="search-container">
      <Input
        placeholder="Meds Search"
        onKeyPress={handleSearchEnter}
        onChange={handleSearchInput}
        icon={{
          name: "search",
          link: true,
          onClick: handleSearchClick
        }}
        id="input-search-med-slider"
      />
    </div>
    <div className="search-results-container">
      <h3>common</h3>
      <div style={{ textAlign: "center" }}>No active medications</div>
    </div>
  </div>
);

export default MedicationSlider;
