import {
  loadPatientActiveVisits,
  loadPatientVisits,
  setCenterVisitsMessage,
  setProviderVisitsMessage,
  setProviderAppointmentsMessage,
  setForceReloadPatientTimelineHistory
} from "../../actions/visitActions";
import { loadPatientDemo } from "../../actions/demographicActions";
import { loadPatientInsurance } from "../../actions/insuranceActions";
import { loadMyPatientStatusBadge, loadVisitingStatusBadge } from "../../actions/badgeActions";
import { roleGroups } from "../../constants/securityRoles";
import { checkRoles } from "../../helpers";

const store = window.store;

const handleActiveVisits = (message) => {
  /* {"Type":"@Notification.ActiveVisits","Title":"Visit Created","Content":{"patientId": "41b8d85d-3482-485c-8b25-87a37adba259" "reload":true},"Options":null,"Time":"2020-09-11T19:52:28.4699384+02:00"} */
  const authRoles = store.getState().auth.user.profile.roles;
  const selectFirstMedicalVisitIfNoVisitSelected = checkRoles(
    roleGroups.selectFirstMedicalVisitIfNoVisitSelected,
    authRoles
  );
  if (message.Content.reload) {
    store.dispatch(setForceReloadPatientTimelineHistory(message.Content.patientId, true));
    store.dispatch(loadPatientVisits(message.Content.patientId)).then(() => {
      store.dispatch(
        loadPatientActiveVisits(message.Content.patientId, selectFirstMedicalVisitIfNoVisitSelected, false, true)
      );
      store.dispatch(loadPatientDemo(message.Content.patientId));
      store.dispatch(loadPatientInsurance(message.Content.patientId));
    });
  }
};

const handleCenterVisits = (message) => {
  /* {"Type":"@Notification.Visits","Title":null,"Content":{"centerId":"8127c40c-06e1-48a9-a55e-ec4051863f6d","patientId":"4925133c-c222-4312-9384-76b22e82138b","visitId":"2e78a1db-e303-407a-8ac0-37990158b52c","visitStatus":"newvisit"},"Options":null,"Time":"2022-07-14T23:10:05.1423372+02:00"} */
  store.dispatch(setCenterVisitsMessage(message.Content));
  store.dispatch(loadVisitingStatusBadge());
};

const handleProviderVisits = (message) => {
  store.dispatch(setProviderVisitsMessage(message.Content));
  store.dispatch(loadMyPatientStatusBadge());
};

const handleProviderAppointments = (message) => {
  store.dispatch(setProviderAppointmentsMessage(message.Content));
  store.dispatch(loadMyPatientStatusBadge());
};

export default {
  handleActiveVisits,
  handleCenterVisits,
  handleProviderVisits,
  handleProviderAppointments
};
