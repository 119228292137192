import { isEmpty } from "lodash";
import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function noteSlashTasksReducer(state = initialState.noteSlashTasks, action) {
  switch (action.type) {
    case types.LOAD_NOTE_SLASH_TASKS_DONE: {
      const payload = {
        ...action.payload,
        data: action.payload.data.map((i) => ({
          ...i,
          entity: { ...i.entity, tags: isEmpty(i.entity.tags) ? [] : i.entity.tags.split(";") }
        }))
      };
      return { ...state, ...payload, forceReload: false };
    }
    case types.SET_NOTE_SLASH_TASKS_FORCE_RELOAD:
      return { ...state, forceReload: action.value };
    case types.FETCH_NOTE_SLASH_TASK_DETAILS_DONE:
      return { ...state, detailedData: { ...state.detailedData, [action.entityId]: action.entity } };
    default:
      return state;
  }
}
