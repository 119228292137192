import React, { Component } from "react";
import { Modal, Button, TextArea, Grid, Icon, Message } from "semantic-ui-react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "./ErrorDetailsModal.css";
import * as modalActions from "../../actions/modalActions";
import * as errorLogsActions from "../../actions/errorLogsActions";

export class ErrorDetailsModal extends Component {
  state = {
    editMode: this.props.editMode || false,
    updatedSourceMessageContent: "",
    confirmDismiss: false,
    confirmRequeue: false
  };

  componentDidMount() {
    const { errorId, recoverable } = this.props;
    this.props.actions.loadSelectedErrorDetails(errorId, recoverable).then(() => {
      if (this.state.editMode) {
        this.setState({
          updatedSourceMessageContent: this.props.error.sourceMessageContent
        });
      }
    });
  }

  componentWillUnmount() {
    this.props.actions.clearSelectedError();
  }

  handleCancel = () => this.props.actions.hideModal();

  handleEdit = () => {
    const { editMode } = this.state;
    const {
      error: { sourceMessageContent }
    } = this.props;
    this.setState({
      editMode: !editMode,
      updatedSourceMessageContent: !editMode ? sourceMessageContent : ""
    });
  };

  handleupdateSourceMessageContent = (e, data) => {
    this.setState({ updatedSourceMessageContent: data.value });
  };

  handleDismiss = () => {
    const { confirmDismiss } = this.state;
    const { errorId, recoverable } = this.props;
    if (confirmDismiss) {
      this.props.actions.dismissError(errorId, recoverable).then(() => {
        const { activeTabIndex, pageSize, pageNumber, filters, orderBySourceHandler } = this.props.adminPageState;
        this.props.actions.loadErrorLogs(pageSize, pageNumber, filters, orderBySourceHandler, activeTabIndex === 0);
        this.handleCancel();
      });
    } else {
      this.setState({ confirmDismiss: !confirmDismiss });
    }
  };

  handleDismissMouseOut = () => {
    this.setState({ confirmDismiss: false });
  };

  handleRequeue = () => {
    const { updatedSourceMessageContent, confirmRequeue } = this.state;
    const { errorId } = this.props;
    if (confirmRequeue) {
      const { pageSize, pageNumber, filters, orderBySourceHandler } = this.props.adminPageState;
      this.props.actions.requeueError(errorId, updatedSourceMessageContent).then(() => {
        this.props.actions.loadErrorLogs(pageSize, pageNumber, filters, orderBySourceHandler, true);
        this.handleCancel();
      });
    } else {
      this.setState({ confirmRequeue: !confirmRequeue });
    }
  };

  handleRequeueMouseOut = () => {
    this.setState({ confirmRequeue: false });
  };

  render() {
    const { editMode, updatedSourceMessageContent, confirmDismiss, confirmRequeue } = this.state;
    const {
      open,
      recoverable,
      processing,
      error: {
        id,
        sourceHandler,
        errorType,
        errorSource,
        errorMessage,
        errorUserMessage,
        stackTrace,
        sourceMessageType,
        sourceMessageContent,
        orchestrationId,
        identityContext,
        platformEventId,
        timeStamp,
        serviceName,
        username
      }
    } = this.props;

    return (
      <Modal open={open} size="large" className="error-details-modal" closeIcon onClose={this.handleCancel}>
        <Modal.Header>{`${recoverable ? `Recoverable ` : ``} Error Details`}</Modal.Header>
        <Modal.Content className="error-details-modal-content">
          <Grid>
            <Grid.Row>
              <Grid.Column width={4}>
                <strong>ErrorId</strong>
                <span>{id}</span>
              </Grid.Column>

              <Grid.Column width={4}>
                <strong>UserName</strong>
                <span>{username}</span>
              </Grid.Column>

              <Grid.Column width={4}>
                <strong>ServiceName</strong>
                <span>{serviceName}</span>
              </Grid.Column>

              <Grid.Column width={4}>
                <strong>SourceHandler</strong>
                <span>{sourceHandler}</span>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column width={4}>
                <strong>ErrorType</strong>
                <span>{errorType}</span>
              </Grid.Column>

              <Grid.Column width={4}>
                <strong>ErrorSource</strong>
                <span>{errorSource}</span>
              </Grid.Column>
              <Grid.Column width={8}>
                <strong>OrchestrationId</strong>
                <span>{orchestrationId}</span>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column width={16}>
                <strong>ErrorMessage</strong>
                <span>{errorMessage}</span>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column width={16}>
                <strong>ErrorUserMessage</strong>
                <span>{errorUserMessage}</span>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column width={16}>
                <strong>StackTrace</strong>
                <span>{stackTrace}</span>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column width={16}>
                <strong>SourceMessageType</strong>
                <span>{sourceMessageType}</span>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={16}>
                <div style={{ minHeight: "30px" }}>
                  <strong style={{ display: "inline" }}>SourceMessageContent</strong>
                  {recoverable && (
                    <React.Fragment>
                      <Icon name="edit" color="blue" onClick={this.handleEdit} style={{ margin: "2px 10px" }} />
                      {editMode && (
                        <Message warning style={{ display: "inline", padding: "5px 10px" }}>
                          <Icon name="warning" />
                          You will need to get approval before saving edits
                        </Message>
                      )}
                    </React.Fragment>
                  )}
                </div>
                <span>
                  {editMode && (
                    <TextArea
                      name="sourceMessageContent"
                      style={{ width: "100%" }}
                      rows={8}
                      value={updatedSourceMessageContent}
                      onChange={this.handleupdateSourceMessageContent}
                    />
                  )}
                  {!editMode && sourceMessageContent}
                </span>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column width={5}>
                <strong>IdentityContext</strong>
                <span>{identityContext}</span>
              </Grid.Column>
              <Grid.Column width={5}>
                <strong>PlatformEventId</strong>
                <span>{platformEventId}</span>
              </Grid.Column>
              <Grid.Column width={6}>
                <strong>TimeStamp</strong>
                <span>{timeStamp}</span>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Modal.Content>
        <Modal.Actions>
          <Button
            onClick={this.handleCancel}
            content="Close"
            id="btn-close"
            loading={processing}
            disabled={processing}
          />
          <Button
            color={confirmDismiss ? `red` : `blue`}
            onClick={this.handleDismiss}
            content={`${confirmDismiss ? `Confirm ` : ``}Dismiss`}
            onMouseLeave={this.handleDismissMouseOut}
            icon="close"
            loading={processing}
            disabled={processing}
          />
          {recoverable && (
            <React.Fragment>
              <Button
                color={confirmRequeue ? `red` : `blue`}
                onClick={this.handleRequeue}
                content={`${confirmRequeue ? `Confirm` : ``}Re-queue`}
                onMouseLeave={this.handleRequeueMouseOut}
                icon="undo"
                loading={processing}
                disabled={processing}
              />
            </React.Fragment>
          )}
        </Modal.Actions>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    error: state.errorLogs.selectedErrorDetails,
    processing: state.ajaxCallsInProgress > 0
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...modalActions,
        ...errorLogsActions
      },
      dispatch
    )
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ErrorDetailsModal);
