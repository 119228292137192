import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import * as visitActions from "../../actions/visitActions";
import emrComponent from "../common/emrComponent";
import { setContext } from "../../actions/contextActions";

class PatientVisitContextedRouteComponent extends Component {
  componentDidMount() {
    this.reset();
  }

  componentDidUpdate(prevProps) {
    this.reset(prevProps);
  }

  reset = (prevProps = { patientId: null, visits: [], match: { params: { visitId: null } } }) => {
    const {
      patientId,
      match: {
        params: { visitId: contextVisitId }
      },
      actions: { setContextVisit },
      visits,
      contextVisit
    } = this.props;
    if (
      patientId &&
      (contextVisitId !== prevProps.match.params.visitId ||
        (contextVisitId && !contextVisit) ||
        visits.length !== prevProps.visits.length)
    ) {
      setContext("visit", { visitId: contextVisitId });
      setContextVisit(patientId, contextVisitId);
    }
  };

  render() {
    return <React.Fragment>{this.props.children}</React.Fragment>;
  }
}

function mapStateToProps(state) {
  return {
    patientId: state.patient.currentPatient.patientId,
    visits: state.visits.patientVisits.visits || [],
    contextVisit: state.visits.contextVisit
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...visitActions }, dispatch)
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(emrComponent(PatientVisitContextedRouteComponent))
);
