import "react-toastify/dist/ReactToastify.css";
import * as types from "./actionTypes";
import { beginAjaxCall, ajaxCallDone } from "./ajaxStatusActions";
import { updateCurrentPatient } from "./patientActions";
import {
  pollApiEvents,
  processApiResponse,
  processApiError,
  pushOrchestraItem,
  processThenThrowApiError
} from "../helpers";
import { API_URL } from "../constants/environment";
import { adalApiFetch } from "../adalConfig";
import { fetchPatientDemographics } from "../api/patientDemographics";

function setSsn(patientId, ssn) {
  return {
    type: types.SET_SSN,
    context: "patient",
    patientId,
    ssn
  };
}

function setUnmaskedSsn(patientId, ssn) {
  return {
    type: types.SET_UNMASKED_SSN,
    context: "patient",
    patientId,
    ssn
  };
}

export function clearUnmaskedSocialSecurityNumber(patientId) {
  return setUnmaskedSsn(patientId, "");
}

function loadPatientDemoSuccess(patientId, demographics) {
  return {
    type: types.LOAD_DEMO_SUCCESS,
    context: "patient",
    patientId,
    demographics
  };
}

function updateCoreDemographicsSuccess(patientId, response, coreDemographics) {
  return {
    type: types.UPDATE_CORE_DEMO_SUCCESS,
    context: "patient",
    patientId,
    response,
    coreDemographics
  };
}

function createAddressSuccess(patientId, response, address) {
  return { type: types.CREATE_ADDRESS_SUCCESS, context: "patient", patientId, response, address };
}

function updateAddressSuccess(patientId, response, address) {
  return { type: types.UPDATE_ADDRESS_SUCCESS, context: "patient", patientId, response, address };
}

function deleteAddressSuccess(patientId, response, address) {
  return { type: types.DELETE_ADDRESS_SUCCESS, context: "patient", patientId, response, address };
}

export function deleteBillingFlag(patientId, address) {
  return { type: types.DELETE_BILLING_FLAG, context: "patient", patientId, address };
}

export function deletePrimaryFlag(patientId, contact) {
  return { type: types.DELETE_PRIMARY_FLAG, context: "patient", patientId, contact };
}

function createPhoneSuccess(patientId, response, patientPhone) {
  return { type: types.CREATE_PHONE_SUCCESS, context: "patient", patientId, response, patientPhone };
}

function deletePhoneSuccess(patientId, response, patientPhone) {
  return { type: types.DELETE_PHONE_SUCCESS, context: "patient", patientId, response, patientPhone };
}

function updatePhoneSuccess(patientId, response, patientPhone) {
  return {
    type: types.UPDATE_PHONE_SUCCESS,
    context: "patient",
    patientId,
    response,
    patientPhone
  };
}

function createEmailSuccess(patientId, response, emailAddress) {
  return { type: types.CREATE_EMAIL_SUCCESS, context: "patient", patientId, response, emailAddress };
}

function updateEmailSuccess(patientId, response, emailAddress) {
  return {
    type: types.UPDATE_EMAIL_SUCCESS,
    context: "patient",
    patientId,
    response,
    emailAddress
  };
}

function createContactSuccess(patientId, response, currentContact) {
  return { type: types.CREATE_CONTACT_SUCCESS, context: "patient", patientId, response, currentContact };
}

function deleteContactSuccess(patientId, response, currentContact) {
  return { type: types.DELETE_CONTACT_SUCCESS, context: "patient", patientId, response, currentContact };
}

function deleteEmailSuccess(patientId, response, emailAddress) {
  return { type: types.DELETE_EMAIL_SUCCESS, context: "patient", patientId, response, emailAddress };
}

function updateContactSuccess(patientId, response, currentContact) {
  return {
    type: types.UPDATE_CONTACT_SUCCESS,
    context: "patient",
    patientId,
    response,
    currentContact
  };
}

export function setDefaultPrescribingProviderDone(patientId, response) {
  return {
    type: types.SET_DEFAULT_PRESCRIBING_PROVIDER_DONE,
    context: "patient",
    patientId,
    mainPrescriber: response
  };
}

function loadDefaultPrescribingProviderSuccess(patientId, prescribingProvider) {
  return {
    type: types.LOAD_DEF_PRESC_PROV_SUCCESS,
    context: "patient",
    patientId,
    prescribingProvider
  };
}

function dischargePatientSuccess(patientId, response) {
  return {
    type: types.DISCHARGE_PATIENT_SUCCESS,
    context: "patient",
    patientId,
    response
  };
}

function reverseDischargePatientSuccess(patientId, response) {
  return {
    type: types.REVERSE_DISCHARGE_PATIENT_SUCCESS,
    context: "patient",
    patientId,
    response
  };
}

function deletePrescribingProvider(patientId, response) {
  return {
    type: types.DELETE_PRESC_PROVIDER,
    context: "patient",
    patientId,
    response
  };
}

function createPrimaryCarePhysicianSuccess(patientId, response, physician) {
  return {
    type: types.CREATE_PCP_SUCCESS,
    context: "patient",
    patientId,
    response,
    physician
  };
}

function createOBProviderSuccess(patientId, response, obProvider) {
  return {
    type: types.CREATE_OB_PROVIDER_SUCCESS,
    context: "patient",
    patientId,
    response,
    obProvider
  };
}

export function updateOBProviderSuccess(patientId, obProvider) {
  return {
    type: types.UPDATE_OB_PROVIDER_SUCCESS,
    context: "patient",
    patientId,
    obProvider
  };
}

export function updateOtherProviderSuccess(patientId, otherProviders) {
  return {
    type: types.UPDATE_OTHER_PROVIDERS_SUCCESS,
    context: "patient",
    patientId,
    otherProviders
  };
}

function createPatientOutsideCounselorSuccess(patientId, response, outsideCounselor) {
  return {
    type: types.CREATE_OUTSIDE_COUNSELOR_SUCCESS,
    context: "patient",
    patientId,
    response,
    outsideCounselor
  };
}

export function updatePsychiatristSuccess(patientId, psychiatrist) {
  return {
    type: types.UPDATE_PSYCHIATRIST_SUCCESS,
    context: "patient",
    patientId,
    psychiatrist
  };
}

export function updateOutsideCounselorSuccess(patientId, outsideCounselor) {
  return {
    type: types.UPDATE_OUTSIDE_COUNSELOR_SUCCESS,
    context: "patient",
    patientId,
    outsideCounselor
  };
}

function loadPersonAddressSuccess(patientId, personAddress) {
  return {
    type: types.LOAD_PERSON_ADDRESS_SUCCESS,
    context: "patient",
    patientId,
    personAddress
  };
}

function loadPatientHomeCenterSuccess(patientId, patientHomeCenter) {
  return {
    type: types.LOAD_PATIENT_HOMECENTER_SUCCESS,
    context: "patient",
    patientId,
    patientHomeCenter
  };
}

function loadPatientDischargeReasonSuccess(patientId, patientDischargeReason) {
  return {
    type: types.LOAD_PATIENT_DISCHARGE_REASON_SUCCESS,
    context: "patient",
    patientId,
    patientDischargeReason
  };
}

function loadPatientDischargeSummarySuccess(patientId, patientDischargeSummary) {
  return {
    type: types.LOAD_PATIENT_DISCHARGE_SUMMARY_SUCCESS,
    context: "patient",
    patientId,
    patientDischargeSummary
  };
}

export function updateDischargeSuccess(patientId, discharged) {
  return {
    type: types.UPDATE_DISCHARGE_SUCCESS,
    context: "patient",
    patientId,
    discharged
  };
}

export function fetchUnmaskedSsn(patientId) {
  return (dispatch) => {
    dispatch(beginAjaxCall("loadSsn"));
    return adalApiFetch(`${API_URL}/Patients/${patientId}/SSN`, {
      method: "GET"
    })
      .then(({ ssn }) => dispatch(setUnmaskedSsn(patientId, ssn)))

      .catch((error) => {
        processApiError(error, dispatch);
      })
      .finally(() => {
        dispatch(ajaxCallDone());
      });
  };
}

export function maskSsn(patientId) {
  return (dispatch, getState) => {
    const ssn = getState().demographics.unmaskedSocialSecurityNumber
      ? getState().demographics.unmaskedSocialSecurityNumber || ""
      : getState().demographics.socialSecurityNumber || "";
    dispatch(setSsn(patientId, ssn.replace(/^.{6}/g, "XXX-XX")));
  };
}

export function unmaskSsn(patientId) {
  return (dispatch, getState) => {
    if (getState().demographics.unmaskedSocialSecurityNumber) {
      dispatch(setSsn(patientId, getState().demographics.unmaskedSocialSecurityNumber));
    }
  };
}

export function loadPatientDemo(patientId) {
  return (dispatch) => {
    dispatch(beginAjaxCall("loadPatientDemo"));
    return fetchPatientDemographics(patientId)
      .then((demographics) => dispatch(loadPatientDemoSuccess(patientId, demographics)))
      .catch((error) => {
        processApiError(error, dispatch);
      })
      .finally(() => {
        dispatch(ajaxCallDone());
      });
  };
}

export function updateCoreDemographics(patientId, coreDemographics) {
  return (dispatch) => {
    dispatch(beginAjaxCall("updateCoreDemographics"));
    return adalApiFetch(`${API_URL}/Patients/${patientId}`, {
      method: "PUT",
      body: JSON.stringify(coreDemographics)
    })
      .then((response) => processApiResponse(response.orchestrationId))
      .then((orchestrationId) => pollApiEvents(orchestrationId, "IPatientDemographicsUpdatedEvent"))
      .then((pollResponse) => {
        dispatch(updateCoreDemographicsSuccess(patientId, pollResponse, coreDemographics));
        dispatch(updateCurrentPatient(patientId, coreDemographics));
      })
      .catch((error) => {
        processApiError(error, dispatch);
      })
      .finally(() => {
        dispatch(ajaxCallDone());
      });
  };
}

export function createAddress(patientId, address) {
  return (dispatch) => {
    dispatch(beginAjaxCall("createAddress"));
    return adalApiFetch(`${API_URL}/Patients/${patientId}/Addresses`, {
      method: "POST",
      body: JSON.stringify(address)
    })
      .then((response) => processApiResponse(response.orchestrationId))
      .then((orchestrationId) => pollApiEvents(orchestrationId, "IPatientAddressAddedEvent"))
      .then((response) => dispatch(createAddressSuccess(patientId, response, address)))
      .catch((error) => {
        processApiError(error, dispatch);
      })
      .finally(() => {
        dispatch(ajaxCallDone());
      });
  };
}

export function updateAddress(patientId, address) {
  return (dispatch) => {
    dispatch(beginAjaxCall("updateAddress"));
    return adalApiFetch(`${API_URL}/Patients/${patientId}/Addresses/${address.addressId}`, {
      method: "PUT",
      body: JSON.stringify(address)
    })
      .then((response) => processApiResponse(response.orchestrationId))
      .then((orchestrationId) => pollApiEvents(orchestrationId, "IPatientAddressUpdatedEvent"))
      .then((response) => dispatch(updateAddressSuccess(patientId, response, address)))
      .catch((error) => {
        processApiError(error, dispatch);
      })
      .finally(() => {
        dispatch(ajaxCallDone());
      });
  };
}

export function deleteAddress(patientId, address) {
  return (dispatch) => {
    dispatch(beginAjaxCall("deleteAddress"));
    return adalApiFetch(`${API_URL}/Patients/${patientId}/Addresses/${address.addressId}`, {
      method: "DELETE"
    })
      .then((response) => processApiResponse(response.orchestrationId))
      .then((orchestrationId) => pollApiEvents(orchestrationId, "IPatientAddressDeletedEvent"))
      .then((response) => dispatch(deleteAddressSuccess(patientId, response, address)))
      .catch((error) => {
        processApiError(error, dispatch);
      })
      .finally(() => {
        dispatch(ajaxCallDone());
      });
  };
}

export function deletePhone(patientId, patientPhone) {
  return (dispatch) => {
    dispatch(beginAjaxCall("deletePhone"));
    return adalApiFetch(`${API_URL}/Patients/${patientId}/PhoneNumbers/${patientPhone.phoneNumberId}`, {
      method: "DELETE"
    })
      .then((response) => processApiResponse(response.orchestrationId))
      .then((orchestrationId) => pollApiEvents(orchestrationId, "IPatientPhoneNumberDeletedEvent"))
      .then((response) => dispatch(deletePhoneSuccess(patientId, response, patientPhone)))
      .catch((error) => {
        processApiError(error, dispatch);
      })
      .finally(() => {
        dispatch(ajaxCallDone());
      });
  };
}

export function deleteEmail(patientId, emailAddress) {
  return (dispatch) => {
    dispatch(beginAjaxCall("deleteEmail"));
    return adalApiFetch(`${API_URL}/Patients/${patientId}/EmailAddresses/${emailAddress.emailAddressId}`, {
      method: "DELETE"
    })
      .then((response) => processApiResponse(response.orchestrationId))
      .then((orchestrationId) => pollApiEvents(orchestrationId, "IPatientEmailAddressDeletedEvent"))
      .then((response) => dispatch(deleteEmailSuccess(patientId, response, emailAddress)))
      .catch((error) => {
        processApiError(error, dispatch);
      })
      .finally(() => {
        dispatch(ajaxCallDone());
      });
  };
}

export function createPhone(patientId, patientPhone) {
  return (dispatch) => {
    dispatch(beginAjaxCall("createPhone"));
    return adalApiFetch(`${API_URL}/Patients/${patientId}/PhoneNumbers`, {
      method: "POST",
      body: JSON.stringify(patientPhone)
    })
      .then((response) => processApiResponse(response.orchestrationId))
      .then((orchestrationId) => pollApiEvents(orchestrationId, "IPatientPhoneNumberAddedEvent"))
      .then((response) => dispatch(createPhoneSuccess(patientId, response, patientPhone)))
      .catch((error) => {
        processApiError(error, dispatch);
      })
      .finally(() => {
        dispatch(ajaxCallDone());
      });
  };
}

export function updatePhone(patientId, patientPhone) {
  return (dispatch) => {
    dispatch(beginAjaxCall("updatePhone"));
    return adalApiFetch(`${API_URL}/Patients/${patientId}/PhoneNumbers/${patientPhone.phoneNumberId}`, {
      method: "PUT",
      body: JSON.stringify(patientPhone)
    })
      .then((response) => processApiResponse(response.orchestrationId))
      .then((orchestrationId) => pollApiEvents(orchestrationId, "IPatientPhoneNumberUpdatedEvent"))
      .then((response) => dispatch(updatePhoneSuccess(patientId, response, patientPhone)))
      .catch((error) => {
        processApiError(error, dispatch);
      })
      .finally(() => {
        dispatch(ajaxCallDone());
      });
  };
}

export function createEmail(patientId, emailAddress) {
  return (dispatch) => {
    dispatch(beginAjaxCall("createEmail"));
    return adalApiFetch(`${API_URL}/Patients/${patientId}/EmailAddress`, {
      method: "POST",
      body: JSON.stringify(emailAddress)
    })
      .then((response) => processApiResponse(response.orchestrationId))
      .then((orchestrationId) => pollApiEvents(orchestrationId, "IPatientEmailAddressAddedEvent"))
      .then((response) => dispatch(createEmailSuccess(patientId, response, emailAddress)))
      .catch((error) => {
        processApiError(error, dispatch);
      })
      .finally(() => {
        dispatch(ajaxCallDone());
      });
  };
}

export function updateEmail(patientId, emailAddress) {
  return (dispatch) => {
    dispatch(beginAjaxCall("updatePatientEmailAddress"));
    return adalApiFetch(`${API_URL}/Patients/${patientId}/EmailAddresses/${emailAddress.emailAddressId}`, {
      method: "PUT",
      body: JSON.stringify(emailAddress)
    })
      .then((response) => processApiResponse(response.orchestrationId))
      .then((orchestrationId) => pollApiEvents(orchestrationId, "IPatientEmailAddressUpdatedEvent"))
      .then((response) => dispatch(updateEmailSuccess(patientId, response, emailAddress)))
      .catch((error) => {
        processApiError(error, dispatch);
      })
      .finally(() => {
        dispatch(ajaxCallDone());
      });
  };
}

export function createContact(patientId, currentContact) {
  return (dispatch) => {
    dispatch(beginAjaxCall("createContact"));
    return adalApiFetch(`${API_URL}/Patients/${patientId}/AssociatedContacts`, {
      method: "POST",
      body: JSON.stringify(currentContact)
    })
      .then((response) => processApiResponse(response.orchestrationId))
      .then((orchestrationId) => pollApiEvents(orchestrationId, "IPatientAssociatedContactAddedEvent"))
      .then((response) => dispatch(createContactSuccess(patientId, response, currentContact)))
      .catch((error) => {
        processApiError(error, dispatch);
      })
      .finally(() => {
        dispatch(ajaxCallDone());
      });
  };
}

export function updateContact(patientId, currentContact) {
  return (dispatch) => {
    dispatch(beginAjaxCall("updatePatientAssociatedContact"));
    return adalApiFetch(`${API_URL}/Patients/${patientId}/AssociatedContacts/${currentContact.personId}`, {
      method: "PUT",
      body: JSON.stringify(currentContact)
    })
      .then((response) => processApiResponse(response.orchestrationId))
      .then((orchestrationId) => pollApiEvents(orchestrationId, "IPatientAssociatedContactUpdatedEvent"))
      .then((response) => dispatch(updateContactSuccess(patientId, response, currentContact)))
      .catch((error) => {
        processApiError(error, dispatch);
      })
      .finally(() => {
        dispatch(ajaxCallDone());
      });
  };
}

export function deleteContact(patientId, currentContact) {
  return (dispatch) => {
    dispatch(beginAjaxCall("deleteContact"));
    return adalApiFetch(`${API_URL}/Patients/${patientId}/AssociatedContacts/${currentContact.personId}`, {
      method: "DELETE"
    })
      .then((response) => processApiResponse(response.orchestrationId))
      .then((orchestrationId) => pollApiEvents(orchestrationId, "IPatientAssociatedContactDeletedEvent"))
      .then((response) => dispatch(deleteContactSuccess(patientId, response, currentContact)))
      .catch((error) => {
        processApiError(error, dispatch);
      })
      .finally(() => {
        dispatch(ajaxCallDone());
      });
  };
}

export function setDefaultPrescribingProvider(patientId, providerId) {
  return (dispatch) => {
    dispatch(beginAjaxCall("setDefaultPrescribingProvider"));
    const url = `${API_URL}/Patients/${patientId}/DefaultPrescribingProvider/${providerId}?providerId=${providerId}`;
    return adalApiFetch(url, {
      method: "POST"
    })
      .then((response) => processApiResponse(response.orchestrationId))
      .then((orchestrationId) => {
        dispatch(setDefaultPrescribingProviderDone(patientId, null));
        pushOrchestraItem(orchestrationId, +new Date(), null, { url });
      })
      .catch((error) => processThenThrowApiError(error, dispatch))
      .finally(() => dispatch(ajaxCallDone()));
  };
}

export function loadDefaultPrescribingProvider(patientId) {
  return (dispatch) => {
    dispatch(beginAjaxCall("loadDefaultPrescribingProvider"));
    return adalApiFetch(`${API_URL}/Patients/${patientId}/DefaultPrescribingProvider`)
      .then((prescribingProvider) => dispatch(loadDefaultPrescribingProviderSuccess(patientId, prescribingProvider)))
      .catch((error) => {
        processApiError(error, dispatch);
      })
      .finally(() => {
        dispatch(ajaxCallDone());
      });
  };
}

export function dischargePatient(patientId, dischargeReason) {
  return (dispatch) => {
    dispatch(beginAjaxCall("dischargePatient"));
    return adalApiFetch(`${API_URL}/Patients/${patientId}/DischargePatient`, {
      method: "POST",
      body: JSON.stringify(dischargeReason)
    })
      .then((response) => processApiResponse(response.orchestrationId))
      .then((orchestrationId) => pollApiEvents(orchestrationId, ["IPatientDischargedEvent"]))
      .then((pollResponse) => {
        dispatch(dischargePatientSuccess(patientId, pollResponse.orchestrationId));
        dispatch(deletePrescribingProvider(patientId, pollResponse.orchestrationId));
      })
      .catch((error) => {
        processApiError(error, dispatch);
      })
      .finally(() => {
        dispatch(ajaxCallDone());
      });
  };
}

export function reverseDischargePatient(patientId) {
  return (dispatch) => {
    dispatch(beginAjaxCall("reverseDischargePatient"));
    return adalApiFetch(`${API_URL}/Patients/${patientId}/ReverseDischargePatient`, {
      method: "POST"
    })
      .then((response) => processApiResponse(response.orchestrationId))
      .then((orchestrationId) => pollApiEvents(orchestrationId, ["IUndoneDischargeProviderRestoredEvent"]))
      .then((pollResponse) => dispatch(reverseDischargePatientSuccess(patientId, pollResponse.orchestrationId)))
      .catch((error) => {
        processApiError(error, dispatch);
      })
      .finally(() => {
        dispatch(ajaxCallDone());
      });
  };
}

export function createPrimaryCarePhysician(patientId, physician) {
  return (dispatch) => {
    dispatch(beginAjaxCall("createPrimaryCarePhysician"));
    return adalApiFetch(`${API_URL}/Patients/${patientId}/PrimaryCarePhysician`, {
      method: "POST",
      body: JSON.stringify(physician)
    })
      .then((response) => processApiResponse(response.orchestrationId))
      .then((orchestrationId) => pollApiEvents(orchestrationId, "IPatientPrimaryCarePhysicianAssignedEvent"))
      .then((response) => dispatch(createPrimaryCarePhysicianSuccess(patientId, response, physician)))
      .catch((error) => {
        processApiError(error, dispatch);
      })
      .finally(() => {
        dispatch(ajaxCallDone());
      });
  };
}

export function createOBProvider(patientId, obProviderForm) {
  return (dispatch) => {
    dispatch(beginAjaxCall("createOBProvider"));
    return adalApiFetch(`${API_URL}/Patients/${patientId}/OBProvider`, {
      method: "POST",
      body: JSON.stringify(obProviderForm)
    })
      .then((response) => processApiResponse(response.orchestrationId))
      .then((orchestrationId) => pollApiEvents(orchestrationId, "IPatientObProviderAddedEvent"))
      .then((response) => dispatch(createOBProviderSuccess(patientId, response, obProviderForm.obProvider)))
      .catch((error) => {
        processApiError(error, dispatch);
      })
      .finally(() => {
        dispatch(ajaxCallDone());
      });
  };
}

export function createOutsideCounselor(patientId, outsideCounselorForm) {
  return (dispatch) => {
    dispatch(beginAjaxCall("createOutsideCounselor"));
    return adalApiFetch(`${API_URL}/Patients/${patientId}/OutsideCounselor`, {
      method: "POST",
      body: JSON.stringify(outsideCounselorForm)
    })
      .then((response) => processApiResponse(response.orchestrationId))
      .then((orchestrationId) => pollApiEvents(orchestrationId, "IPatientOutsideCounselorAddedEvent"))
      .then((response) =>
        dispatch(createPatientOutsideCounselorSuccess(patientId, response, outsideCounselorForm.outsideCounselor))
      )
      .catch((error) => {
        processApiError(error, dispatch);
      })
      .finally(() => {
        dispatch(ajaxCallDone());
      });
  };
}

export function loadPersonAddress(patientId) {
  return (dispatch) => {
    dispatch(beginAjaxCall("loadPersonAddress"));
    return adalApiFetch(`${API_URL}/Patients/${patientId}/PersonAddress`)
      .then((personAddress) => dispatch(loadPersonAddressSuccess(patientId, personAddress)))
      .catch((error) => {
        processApiError(error, dispatch);
      })
      .finally(() => {
        dispatch(ajaxCallDone());
      });
  };
}

export function loadPatientHomeCenter(patientId) {
  return (dispatch) => {
    dispatch(beginAjaxCall("loadPatientHomeCenter"));
    return adalApiFetch(`${API_URL}/Patients/${patientId}/HomeCenter`)
      .then((homeCenter) => dispatch(loadPatientHomeCenterSuccess(patientId, homeCenter)))
      .catch((error) => {
        processApiError(error, dispatch);
      })
      .finally(() => {
        dispatch(ajaxCallDone());
      });
  };
}

export function loadPatientDischargeReason(patientId) {
  return (dispatch) => {
    dispatch(beginAjaxCall("loadPatientDischargeReason"));
    return adalApiFetch(`${API_URL}/Patients/${patientId}/DischargeReasons`)
      .then((patientDischargeReason) => dispatch(loadPatientDischargeReasonSuccess(patientId, patientDischargeReason)))
      .catch((error) => {
        processApiError(error, dispatch);
      })
      .finally(() => {
        dispatch(ajaxCallDone());
      });
  };
}

export function loadPatientDischargeSummary(patientId) {
  return (dispatch) => {
    dispatch(beginAjaxCall("loadPatientDischargeSummary"));
    return adalApiFetch(`${API_URL}/Patients/${patientId}/DischargeSummary`)
      .then((summary) => dispatch(loadPatientDischargeSummarySuccess(patientId, summary)))
      .catch((error) => {
        processApiError(error, dispatch);
      })
      .finally(() => {
        dispatch(ajaxCallDone());
      });
  };
}
