import React, { Component } from "react";
import { Button, Header, Modal, Form, Table, Grid } from "semantic-ui-react";
import moment from "moment";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as modalActions from "../../../../actions/modalActions";
import * as lookupActions from "../../../../actions/lookupActions";
import * as patientHistoryActions from "../../../../actions/patientHistoryActions";
import { updateErrors, requiredFieldsFilled } from "../../../../helpers";
import { longDateFormat } from "../../../../constants/miscellaneous";

import "../../../reusable/Modal.css";
import "./AddAllergyModal.css";

const getAllergy = props =>
  props.isEdit
    ? props.allergyHistory
    : Object.assign({}, props.allergyHistory, {
        notes: [],
        reactionType: props.allergyHistory.reactionType || ""
      });

const getAllergyType = props => {
  if (props.isEdit) {
    return props.allergyHistory.isMedicationAllergy ? "MedicationAllergyHistory" : "NonMedicationAllergyHistory";
  }
  return "MedicationAllergyHistory";
};

const inputValidation = {
  required: []
};

export class AddAllergyModal extends Component {
  state = {
    allergy: getAllergy(this.props),
    allergyType: getAllergyType(this.props),
    isNoteInputAvailiable: false,
    isNoteInputDisable: false,
    allergyNotesUpdate: [],
    note: "",
    errors: {},
    isSaveDisable: false
  };

  componentDidMount() {
    this.props.actions.loadReactions();
    const { patientId } = this.props;
    this.props.actions.loadAllergyHist(patientId);
  }

  componentWillUnmount() {
    const { patientId } = this.props;
    this.props.actions.loadAllergyHist(patientId);
  }

  handleSave = () => {
    this.setState({ isSaveDisable: true });
    const { patientId, allergyHistory } = this.props;
    const { allergyType, allergy } = this.state;
    const newAllergy = Object.assign({}, this.state.allergy, {
      allergySource: allergy.allergySource || allergyHistory.searchedAllergy,
      allergyType,
      allergyKey: allergyHistory.allergyKey || "93"
    });

    if (this.state.allergy.itemId) {
      newAllergy.notes = this.state.allergyNotesUpdate;
      this.props.actions
        .updateAllergyHist(patientId, newAllergy, newAllergy.allergyType, newAllergy.itemId)
        .then(this.handleClose);
    } else {
      this.props.actions.createAllergyHist(patientId, newAllergy, newAllergy.allergyType).then(this.handleClose);
    }
  };

  handleClose = () => this.props.actions.hideModal();

  handleChangeNote = (e, data) => {
    const { value } = data;
    if (value.trim() !== "") {
      this.setState({ isNoteInputAvailiable: true });
    } else {
      this.setState({ isNoteInputAvailiable: false });
    }
    this.setState({ note: value });
  };

  handleChange = (e, data) => {
    const { name: field, value, required } = data;
    const errors = updateErrors(field, value, required, this.state.errors, inputValidation[field]);
    const allergy = Object.assign({}, this.state.allergy, { [field]: value });
    this.setState({ allergy, errors });
  };

  handleAllergyTypeChange = (e, data) => {
    const { value } = data;
    this.setState({ allergyType: value });
  };

  handleAddNote = notes => () => {
    const noteInput = this.state.note;
    let updateNotes = [];
    // Current list note != null -> restore old note
    if (notes && notes.length > 0) {
      updateNotes = notes;
    }
    const noteAdd = {
      text: noteInput,
      noteAddedOn: new Date(),
      creatorFullName: this.props.userName
    };

    // Is update allergy => save new note to list separate old note list
    if (this.state.allergy.itemId) {
      const { allergyNotesUpdate } = this.state;
      allergyNotesUpdate.unshift(noteAdd);
    } else {
      // Add new medication -> only add one note
      this.setState({ isNoteInputDisable: true });
    }
    // Update to current notes
    updateNotes.unshift(noteAdd);
    const currentAllergy = this.state.allergy;

    currentAllergy.notes = updateNotes;
    const updateAllergy = Object.assign({}, currentAllergy, { note: noteInput });

    this.setState({
      allergy: updateAllergy,
      note: "",
      isNoteInputAvailiable: false
    });
  };

  render() {
    const { open, reactionTypes, isEdit, processing } = this.props;
    const { allergyType, allergy, errors, note, isNoteInputAvailiable, isNoteInputDisable, isSaveDisable } = this.state;
    const formInvalid = requiredFieldsFilled(inputValidation.required, allergy);
    const disableSave = formInvalid || Object.keys(errors).length !== 0;
    const { notes } = allergy;
    return (
      <Modal className="add-allergy-modal" open={open} onClose={this.handleClose} closeIcon size="small">
        <Header content="Add Allergies" />
        <h2>
          Allergen:{" "}
          <span data-testid="drug-header">
            {this.props.allergy || allergy.allergySource || this.props.allergyHistory.searchedAllergy}
          </span>
        </h2>
        <Modal.Content>
          <Form>
            <Form.Group grouped>
              <label>Allergy Type</label>
              <Form.Radio
                label="Medication"
                name="allergyType"
                disabled={isEdit}
                value="MedicationAllergyHistory"
                checked={allergyType === "MedicationAllergyHistory"}
                onChange={this.handleAllergyTypeChange}
                id="input-allergyTypeMedication"
              />
              <Form.Radio
                label="Non-Medication"
                name="allergyType"
                disabled={isEdit}
                value="NonMedicationAllergyHistory"
                checked={allergyType === "NonMedicationAllergyHistory"}
                onChange={this.handleAllergyTypeChange}
                id="input-allergyTypeNonMedication"
              />
            </Form.Group>
            <Form.Group>
              <Form.Dropdown
                width={10}
                fluid
                selection
                name="reactionType"
                label="Reaction"
                placeholder="Select..."
                value={parseInt(allergy.reactionType, 10)}
                options={reactionTypes}
                onChange={this.handleChange}
                id="input-reactionType"
              />
            </Form.Group>
            <Grid>
              <Grid.Column computer={13} tablet={13} moblie={13}>
                <Form.Input
                  className="medical-history-note-input"
                  onChange={this.handleChangeNote}
                  id="input-note"
                  name="note"
                  disabled={isNoteInputDisable}
                  value={note}
                  label="Notes"
                />
              </Grid.Column>
              <Grid.Column className="btn-add" computer={3} tablet={3} mobile={3}>
                <Button
                  content="Add Note"
                  loading={processing}
                  color="blue"
                  id="btn-add-note"
                  onClick={this.handleAddNote(notes)}
                  disabled={!isNoteInputAvailiable}
                />
              </Grid.Column>
            </Grid>
            <div className="note-section">
              {notes
                ? notes.map(mNote => (
                    <React.Fragment key={`${mNote.noteId}-${mNote.noteAddedOn}`}>
                      <Table unstackable>
                        <Table.Body>
                          <Table.Row>
                            <Table.Cell colSpan="2">
                              <p>
                                <strong>{mNote.text}</strong>
                              </p>
                            </Table.Cell>
                          </Table.Row>
                          <Table.Row className="grey">
                            {mNote.noteAddedOn && (
                              <Table.Cell>{moment(mNote.noteAddedOn).format(longDateFormat)}</Table.Cell>
                            )}
                            <Table.Cell>{mNote.creatorFullName}</Table.Cell>
                          </Table.Row>
                          <Table.Row />
                        </Table.Body>
                      </Table>
                    </React.Fragment>
                  ))
                : null}
            </div>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.handleClose} id="btn-cancel">
            Cancel
          </Button>
          <Button
            onClick={this.handleSave}
            loading={processing}
            disabled={disableSave || isSaveDisable}
            color="blue"
            id="btn-save"
          >
            Save
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  const tempNoteList = [];
  return {
    reactionTypes: state.lookups.reactionTypes,
    patientId: state.patient.currentPatient.patientId,
    processing: state.ajaxCallsInProgress > 0,
    userName: state.auth.user.profile.fullName,
    tempNoteList
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...modalActions, ...lookupActions, ...patientHistoryActions }, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddAllergyModal);
