import { isEmpty } from "lodash";
import moment from "moment";
import * as types from "./actionTypes";
import { processApiError, processThenThrowApiError, subscribe, unsubscribe } from "../helpers";
import { API_URL } from "../constants/environment";
import { adalApiFetch } from "../adalConfig";
import { isoFormat } from "../constants/miscellaneous";

export function updateBadge(badge, amount) {
  return { type: types.UPDATE_BADGE_AMOUNT, badge, amount };
}

export function loadNotificationsBadgeDone(newCount) {
  return { type: types.LOAD_NOTIFICATIONS_BADGE_SUCCESS, value: newCount };
}

export function loadChartBadgeSuccess(chartsToSign, isChartsToSignUrgent) {
  return {
    type: types.LOAD_CHART_BADGE_SUCCESS,
    chartsToSign,
    isChartsToSignUrgent
  };
}

function loadPrescriptionsToSignBadgeSuccess(prescriptionsToSignTotal) {
  return {
    type: types.LOAD_PRESCRIPTIONS_TO_SIGN_BADGE_SUCCESS,
    prescriptionsToSignTotal
  };
}

export function setRequestedLabsCount(pendingOrderPatientsTotal) {
  return {
    type: types.SET_LAB_SUBMISSION_QUEUE_COUNT,
    pendingOrderPatientsTotal
  };
}

function setMyPatientStatusBadge(myPatients) {
  return {
    type: types.SET_MY_PATIENT_STATUS_BADGE,
    myPatients
  };
}

function setVisitingStatusBadge(visiting) {
  return {
    type: types.SET_VISITING_STATUS_BADGE,
    visiting
  };
}

function loadTasksBadgeSuccess(tasksTotal) {
  return {
    type: types.LOAD_TASKS_BADGE_SUCCESS,
    tasksTotal
  };
}

function loadCoSignBadgeSuccess(value) {
  return {
    type: types.LOAD_COSIGN_BADGE_SUCCESS,
    value
  };
}

function loadVisitsGroupBadgeSuccess(value) {
  return {
    type: types.LOAD_VISITS_GROUP_BADGE_SUCCESS,
    value
  };
}

export function loadChartBadge() {
  return (dispatch) =>
    adalApiFetch(`${API_URL}/Visits/NumberOfChartsToSign`)
      .then(({ chartsToSign, isChartsToSignUrgent }) =>
        dispatch(loadChartBadgeSuccess(chartsToSign, isChartsToSignUrgent))
      )
      .catch((error) => {
        processApiError(error, dispatch);
      });
}

export function loadPrescriptionsToSignBadge() {
  return (dispatch) =>
    adalApiFetch(`${API_URL}/Prescriptions/Statuses/ToSign/Count`)
      .then(({ prescriptionsToSignTotal }) => dispatch(loadPrescriptionsToSignBadgeSuccess(prescriptionsToSignTotal)))
      .catch((error) => {
        processApiError(error, dispatch);
      });
}

export function loadRequestedLabsBadge(oldCenterId = null) {
  if (oldCenterId) {
    unsubscribe(`/RequestedLabs/center/${oldCenterId}`);
  }
  return (dispatch, getState) => {
    const currentCenterId = getState().userPreference.centerContext;
    if (!currentCenterId) {
      return Promise.resolve({
        pendingOrderPatientsTotal: 0
      });
    }
    return adalApiFetch(`${API_URL}/PatientLabs/PatientCountWithPendingOrders?centerId=${currentCenterId}`)
      .then((response) => {
        if (response.groupName) {
          subscribe(response.groupName);
        }
        dispatch(setRequestedLabsCount(response.pendingOrderPatientsTotal));
      })
      .catch((error) => {
        processApiError(error, dispatch);
      });
  };
}

export function loadVisitingStatusBadge() {
  return (dispatch, getState) => {
    if (!getState().visits.isVisitStatusWatchLoaded) {
      return Promise.resolve({
        visiting: 0
      });
    }
    const model = {
      clinicId: getState().userPreference.centerContext || "",
      listStatus: getState().visits.visitingStatusSelected
    };
    return adalApiFetch(`${API_URL}/Visits/VisitingStatusBadge`, {
      method: "POST",
      body: JSON.stringify(model)
    })
      .then(({ value }) => dispatch(setVisitingStatusBadge(value)))
      .catch((error) => processThenThrowApiError(error, dispatch));
  };
}

export function loadTasksBadge() {
  return (dispatch, getState) => {
    const filter = {
      assignedCenterIds: [getState().userPreference.centerContext],
      assignedRoles: [...getState().auth.user.profile.roles],
      dueDateFrom: "",
      dueDateTo: moment().endOf("day").format(isoFormat)
    };
    const sessionObject = window.cleanSlate.sessionStorage.getItem("TASKS_BOARD_FILTER");
    if (sessionObject) {
      sessionObject.dueDateFrom = sessionObject.dueDateFrom
        ? moment(sessionObject.dueDateFrom).startOf("day").format(isoFormat)
        : ``;
      sessionObject.dueDateTo = sessionObject.dueDateTo
        ? moment(sessionObject.dueDateTo).endOf("day").format(isoFormat)
        : ``;
      Object.keys(filter).forEach((key) => {
        if (Object.prototype.hasOwnProperty.call(sessionObject, key)) {
          filter[key] = sessionObject[key];
        }
      });
    }

    if (isEmpty(filter.assignedCenterIds)) {
      return Promise.resolve({});
    }
    const groupName = "/Tasks/Open/Count?centerId=";
    const subscriptionList = getState().streaming.subscriptionList;
    const tasksCountSubscriptionList = subscriptionList.filter((i) => i.startsWith(groupName));
    const subscribedCenterIds = tasksCountSubscriptionList.map((i) => i.substring(groupName.length));
    subscribedCenterIds.forEach((centerId) => {
      if (!filter.assignedCenterIds.includes(centerId)) {
        unsubscribe(`/Tasks/Open/Count?centerId=${centerId}`);
      }
    });

    return adalApiFetch(`${API_URL}/Tasks/Open/Count`, {
      method: "POST",
      body: JSON.stringify(filter)
    })
      .then((response) => {
        if (response.groupName) {
          filter.assignedCenterIds.forEach((centerId) => {
            subscribe(`${response.groupName}${centerId}`);
          });
        }
        dispatch(loadTasksBadgeSuccess(response));
      })
      .catch((error) => {
        processApiError(error, dispatch);
      });
  };
}

export function loadMyPatientStatusBadge() {
  return (dispatch, getState) => {
    if (!getState().visits.isMyPatientStatusWatchLoaded) {
      return Promise.resolve({
        myPatients: 0
      });
    }
    const model = {
      // clinicId: getState().userPreference.centerContext || "",
      listStatus: getState().visits.visitStatusFilter
    };
    return adalApiFetch(`${API_URL}/Visits/MyPatientStatusBadge`, {
      method: "POST",
      body: JSON.stringify(model)
    })
      .then(({ value }) => dispatch(setMyPatientStatusBadge(value)))
      .catch((error) => processThenThrowApiError(error, dispatch));
  };
}

export function loadCoSignBadge() {
  return (dispatch) =>
    adalApiFetch(`${API_URL}/Visits/NumberOfChartsToCoSign`)
      .then(({ value }) => dispatch(loadCoSignBadgeSuccess(value)))
      .catch((error) => {
        processApiError(error, dispatch);
      });
}

export function loadVisitsGroupBadge() {
  return (dispatch) =>
    adalApiFetch(`${API_URL}/Visits/NumberVisitingGroups`)
      .then(({ value }) => dispatch(loadVisitsGroupBadgeSuccess(value)))
      .catch((error) => {
        processApiError(error, dispatch);
      });
}

export function loadNotificationsBadge() {
  return (dispatch) =>
    adalApiFetch(`${API_URL}/Notifications/Summary`)
      .then((response) => {
        const { newCount } = response.find((item) => item.type === "inbox") || {};
        return dispatch(loadNotificationsBadgeDone(newCount));
      })
      .catch((error) => processApiError(error, dispatch));
}

export function resetNotifications() {
  return (dispatch) => dispatch({ type: types.RESET_NOTICATIONS_BADGE, value: [] });
}
