import React, { Component } from "react";

class SurveyHeaderEditor extends Component {
  state = {};
  render() {
    return (
      <div>
        <form className="ui form" style={{ padding: "15px" }}>
          <div className="form-group row">
            <label htmlFor="exampleInputEmail1" className="col-sm-3 col-form-label">
              Questions Prefix
            </label>
            <div className="col-sm-9">
              <input
                type="text"
                className="form-control"
                placeholder="Questions Prefix"
                value={this.props.prefix}
                onChange={e => this.props.handlePrefixChnage(e.target.value)}
              />
            </div>
          </div>
          {Object.keys(this.props.survey).map(
            key =>
              key !== "questions" &&
              key !== "sections" && (
                <div className="form-group row" key={key}>
                  <label htmlFor="exampleInputEmail1" className="col-sm-3 col-form-label">
                    {key}
                  </label>
                  <div className="col-sm-9">
                    <input
                      type="text"
                      className="form-control"
                      placeholder={key}
                      value={this.props.survey[key]}
                      onChange={e => this.props.handleHeaderKeyChnage(key, e.target.value)}
                    />
                  </div>
                </div>
              )
          )}
        </form>
      </div>
    );
  }
}

export default SurveyHeaderEditor;
