import { Segment, Form, Button, Message } from "semantic-ui-react";
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import shortid from "shortid";
import { isEmpty, isEqual } from "lodash";
import emrComponent from "../../common/emrComponent";
import * as labActions from "../../../actions/labActions";
import * as documentActions from "../../../actions/documentActions";
import { checkRoles } from "../../../helpers";
import { roleGroups } from "../../../constants/securityRoles";

export class StandingLabOrder extends Component {
  state = {
    isAllCheckedAndDisabled: false,
    labOrder: {
      originalOrder: {},
      submittedTests: [],
      unsubmittedTests: []
    }
  };

  componentDidMount() {
    this._isMounted = true;
    this.load();
  }

  componentDidUpdate(prevProps) {
    const { patientId, centerId, recentOrders } = this.props;
    if (centerId !== prevProps.centerId || patientId !== prevProps.patientId)
      this.load(centerId !== prevProps.centerId, patientId !== prevProps.patientId);

    if (!isEqual(recentOrders, prevProps.recentOrders)) this.reset();
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.props.actions.setStandingLabOrderRendered(false);
  }

  _isMounted = false;

  load(centerChanged = true, patientChanged = true) {
    const {
      patientId,
      centerId,
      actions: { loadAvailableLabs, loadPendingOrders, loadRecentOrders }
    } = this.props;
    if (patientId && patientChanged) loadPendingOrders(patientId);
    if (patientId && centerId && centerChanged)
      loadAvailableLabs(centerId)
        .then(() => loadRecentOrders(patientId))
        .then(() => this.reset());
  }

  reset = () => {
    const { availableLabs, recentOrders } = this.props;
    const order = recentOrders.find(o => o.visitId === this.props.labsVisitId);
    if (this.props.centerId && availableLabs && order) {
      const updatedSubmittedTests = [];
      const updatedUnsubmittedTests = [];
      order.tests.forEach(test => {
        if (isEmpty(test.labCode)) return;
        const _lab = availableLabs.find(l => l.labCode === test.labCode);
        const lab = {
          ..._lab,
          reason: test.selectionReason,
          submittedConfirmatoryLabs: test.confirmatoryLabs
            .filter(ctest => ctest.isSubmitted)
            .map(ctest => ({
              ..._lab.confirmatoryLabs.find(clab => clab.labCode === ctest.labCode),
              reason: ctest.selectionReason
            })),
          unsubmittedConfirmatoryLabs: test.confirmatoryLabs
            .filter(ctest => !ctest.isSubmitted)
            .map(ctest => ({
              ..._lab.confirmatoryLabs.find(clab => clab.labCode === ctest.labCode),
              reason: ctest.selectionReason
            }))
        };
        if (test.isSubmitted) {
          updatedSubmittedTests.push(lab);
        } else {
          updatedUnsubmittedTests.push(lab);
        }
      });
      this.setState(
        {
          labOrder: {
            originalOrder: order,
            submittedTests: updatedSubmittedTests,
            unsubmittedTests: updatedUnsubmittedTests
          }
        },
        () => {
          this.setState({
            isAllCheckedAndDisabled: this.props.availableRapidTests.every(
              test => this.isLabChecked(test.labCode) && this.isLabDisabled(test.labCode)
            )
          });
          this.props.actions.setStandingLabOrderRendered(!this.state.isAllCheckedAndDisabled);
        }
      );
    }
  };

  handleSave = () => {
    const labs = this.state.labOrder.unsubmittedTests.map(l => ({
      labCode: l.labCode,
      reason: l.reason,
      confirmatoryLabs: l.unsubmittedConfirmatoryLabs.map(c => ({ labCode: c.labCode, reason: c.reason }))
    }));

    this.props.actions.setStandingLabOrderSaving(true);
    return this.props.actions.addTestsToPendingOrder(
      this.state.labOrder.originalOrder.orderId,
      this.props.patientId,
      this.state.labOrder.originalOrder.visitId,
      labs
    );
  };

  handleCheckbox = (e, data) => {
    const { checked } = data;
    const { availableLabs } = this.props;
    const {
      labOrder: { unsubmittedTests }
    } = this.state;
    const selectedLab = availableLabs.find(l => l.labCode === data.labcode);
    let updatedUnsubmittedTests = [...unsubmittedTests];
    if (checked) {
      updatedUnsubmittedTests.push({
        ...selectedLab,
        reason: "",
        submittedConfirmatoryLabs: [],
        unsubmittedConfirmatoryLabs: []
      });
    } else {
      updatedUnsubmittedTests = updatedUnsubmittedTests.filter(l => l.labCode !== selectedLab.labCode);
    }
    this.setState({ labOrder: { ...this.state.labOrder, unsubmittedTests: updatedUnsubmittedTests } });
  };

  disableSave = () => isEmpty(this.state.labOrder.unsubmittedTests);

  isLabChecked(labCode) {
    return (
      this.state.labOrder.unsubmittedTests.find(t => t.labCode === labCode) !== undefined ||
      this.state.labOrder.submittedTests.find(t => t.labCode === labCode) !== undefined
    );
  }

  isLabDisabled(labCode) {
    const { authRoles, labsConfirmatoryMap } = this.props;
    const showCreateLabOrder = checkRoles(roleGroups.labsCreate, authRoles);

    // here it is primary .. we need to check if its equivalent confirmatory is added ONLY if it has one
    let alreadyAdded = false;
    if (labsConfirmatoryMap[labCode]) {
      const allConfirmatoryCodesAdded = [];
      this.state.labOrder.submittedTests.forEach(t => {
        allConfirmatoryCodesAdded.push(...t.submittedConfirmatoryLabs.map(sct => sct.labCode));
        allConfirmatoryCodesAdded.push(...t.unsubmittedConfirmatoryLabs.map(sct => sct.labCode));
      });
      this.state.labOrder.unsubmittedTests.forEach(t => {
        allConfirmatoryCodesAdded.push(...t.submittedConfirmatoryLabs.map(sct => sct.labCode));
        allConfirmatoryCodesAdded.push(...t.unsubmittedConfirmatoryLabs.map(sct => sct.labCode));
      });

      alreadyAdded = allConfirmatoryCodesAdded.includes(labsConfirmatoryMap[labCode]);
    }

    return (
      !showCreateLabOrder ||
      isEmpty(this.state.labOrder.originalOrder) ||
      isEmpty(this.state.labOrder.originalOrder.visitId) ||
      this.state.labOrder.submittedTests.find(t => t.labCode === labCode) !== undefined ||
      alreadyAdded
    );
  }

  render() {
    const disableSend = this.disableSave();
    const { centerId, patientId, availableRapidTests, saving } = this.props;
    const { isAllCheckedAndDisabled, labOrder } = this.state;
    return isEmpty(labOrder.originalOrder) || isAllCheckedAndDisabled ? null : (
      <Segment id="div-standing-order" className="right-rail-container protocol-order-tile">
        <h2>Clean Slate - Rapid Tests</h2>
        {isEmpty(centerId) ? (
          <Message error>Patient home center must be set to be able to order rapid tests</Message>
        ) : (
          <Form>
            {availableRapidTests.length === 0 && <div>Loading...</div>}
            {(isEmpty(centerId) || isEmpty(patientId) || availableRapidTests.length > 0) && (
              <React.Fragment>
                {availableRapidTests.map(test => (
                  <Form.Checkbox
                    id={`input-${test.labCode}`}
                    key={`${test.labCode}-rand-${shortid.generate()}`}
                    label={test.labName}
                    labcode={test.labCode}
                    name={test.labCode}
                    checked={this.isLabChecked(test.labCode)}
                    disabled={this.isLabDisabled(test.labCode)}
                    onChange={this.handleCheckbox}
                  />
                ))}
              </React.Fragment>
            )}

            <div className="button-container">
              <Button
                onClick={this.handleSave}
                color="blue"
                id="btn-sendToLab"
                disabled={disableSend || saving}
                loading={saving}
              >
                Submit Order
              </Button>
            </div>
          </Form>
        )}
      </Segment>
    );
  }
}

function mapStateToProps(state) {
  const demographics = state.demographics;
  const centerId = demographics.homeCenterId;

  const availableLabs = state.labs.availableLabs[centerId] ? state.labs.availableLabs[centerId] : [];

  const contextVisit = state.visits.contextVisit;
  const latestVisit = state.visits.latestVisit;
  const labsVisit = contextVisit || latestVisit;

  const labsVisitPendingOrder = state.labs.pendingOrders.find(o => o.visitId === labsVisit.visitId);
  const labsVisitHasPendingOrder = !isEmpty(labsVisitPendingOrder);

  const labsConfirmatoryMap = state.labs.labsConfirmatoryMap[centerId] ? state.labs.labsConfirmatoryMap[centerId] : [];

  return {
    centerId,
    patientId: state.patient.currentPatient.patientId,
    availableLabs,
    availableRapidTests: availableLabs
      .filter(t => t.canBeAddedToStandingOrder)
      .sort((a, b) => (a.labName > b.labName ? 1 : a.labName < b.labName ? -1 : 0)),
    labsVisitHasPendingOrder,
    labsVisitId: labsVisit ? labsVisit.visitId : null,
    labsConfirmatoryMap,
    recentOrders: state.labs.recentOrders,
    authRoles: state.auth.user.profile.roles,
    saving: state.labs.standingLabOrder.saving
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...labActions, ...documentActions }, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(emrComponent(StandingLabOrder));
