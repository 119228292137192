import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Switch, Route, Redirect } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { isEmpty } from "lodash";
import "react-toastify/dist/ReactToastify.css";
import * as modalActions from "../actions/modalActions";
import * as lookupActions from "../actions/lookupActions";
import * as visitActions from "../actions/visitActions";
import * as userPreferenceActions from "../actions/userPreferenceActions";
import SideNav from "./navigation/SideNav";
import Toolbar from "./common/toolbar";
import LoginPage from "./Login";
import NewVersionAlert from "./NewVersionAlert";
import ConnectionLostAlert from "./ConnectionLostAlert";
import TopNav from "./navigation/TopNav";
import MyPatientsPage from "./patients/myPatients/MyPatientsPage";
import VisitingPatientsPage from "./patients/visitingPatients/VisitingPatientsPage";
import Admin from "./admin";
import SearchNav from "./navigation/SearchNav";
import PatientSubLayout from "./patients/PatientSubLayout";
import SearchResultsPage from "./search/SearchResultsPage";
import TasksDashboard from "./tasks";
import RequestedLabs from "./requested-labs/RequestedLabs";
import GroupVisitsPage from "./groups/GroupVisitsPage";
import ChartsPage from "./charts/ChartsPage";
import ReportDashboard from "./report/ReportDashboard";
import ReportSubLayout from "./report/ReportSubLayout";
import Prescriptions from "./prescriptions";
import ChartsToCoSignPage from "./cosign/ChartsToCoSignPage";
import EpcsCancel from "./admin/EpcsCancel";
import ActiveControlledSubstancePatients from "./report/ActiveControlledSubstancePatients";
import SidebarErrorLog from "./sidebarErrorLog/SidebarErrorLog";
import ModalRoot from "./reusable/ModalRoot";
import { subscribe, unsubscribe, checkRoles } from "../helpers";
import { roleGroups } from "../constants/securityRoles";

export class DefaultLayout extends Component {
  state = {
    isMobile: false,
    isOpen: false
  };

  componentDidMount() {
    const {
      centerContext: centerId,
      currentUserId,
      actions: { loadTheme, loadVisitTypes, loadVisitStatuses, loadCentersLookup, loadCenterContext, loadCurrentUserId }
    } = this.props;

    loadTheme();
    loadCentersLookup();
    loadCenterContext();
    loadVisitTypes();
    loadVisitStatuses();
    loadCurrentUserId();
    this.loadUserStatusWatch();

    if (!isEmpty(centerId)) subscribe(`/visits/center/${centerId}`);
    if (!isEmpty(currentUserId)) {
      subscribe(`/visits/provider/${currentUserId}`);
      subscribe(`/appointments/provider/${currentUserId}`);
    }

    this.updateWindowWidth();
    window.addEventListener("resize", this.updateWindowWidth);
  }

  loadUserStatusWatch() {
    this.props.actions.loadStatusWatchMyPatient();
    this.props.actions.loadStatusWatchVisiting();
  }

  componentDidUpdate(prevProps) {
    const { centerContext: centerId, currentUserId } = this.props;
    if (centerId !== prevProps.centerContext) {
      if (!isEmpty(prevProps.centerContext)) unsubscribe(`/visits/center/${prevProps.centerContext}`);
      if (!isEmpty(centerId)) subscribe(`/visits/center/${centerId}`);
    }
    if (currentUserId !== prevProps.currentUserId) {
      if (!isEmpty(prevProps.currentUserId)) {
        unsubscribe(`/visits/provider/${prevProps.currentUserId}`);
        unsubscribe(`/appointments/provider/${prevProps.currentUserId}`);
      }
      if (!isEmpty(currentUserId)) {
        subscribe(`/visits/provider/${currentUserId}`);
        subscribe(`/appointments/provider/${currentUserId}`);
      }
    }
  }

  componentWillUnmount() {
    const { centerContext: centerId, currentUserId } = this.props;
    unsubscribe(`/visits/center/${centerId}`);
    if (!isEmpty(currentUserId)) {
      unsubscribe(`/visits/provider/${currentUserId}`);
      unsubscribe(`/appointments/provider/${currentUserId}`);
    }
  }

  updateWindowWidth = () => {
    if (window.innerWidth < 767) {
      this.setState({ isMobile: true });
    } else {
      this.setState({ isMobile: false });
    }
  };

  handleNavToggle = () => {
    const { isOpen } = this.state;
    if (isOpen) this.setState({ isOpen: false });
    else this.setState({ isOpen: true });
  };

  handleReportProblem = () => {
    this.props.actions.showModal({
      type: "EXPORT_CLIENT_EVENTS_MODAL",
      props: { open: true, isNew: false }
    });
  };

  render() {
    const {
      theme,
      isProvider,
      showSearchBar,
      match,
      location: { pathname }
    } = this.props;
    const { isMobile, isOpen } = this.state;
    const matchPath = match.path === "/" ? "" : match.path;
    return (
      <div>
        <ConnectionLostAlert />
        <NewVersionAlert />
        <div style={{ backgroundColor: "white", borderBottom: "1px solid rgba(34, 36, 38, 0.15)" }}>
          <TopNav location={this.props.location} isMobile={isMobile} handleNavToggle={this.handleNavToggle} />
          {pathname !== "/search" && showSearchBar !== false ? <SearchNav /> : null}
        </div>
        <SideNav
          authRoles={this.props.authRoles}
          match={this.props.match}
          isMobile={isMobile}
          isOpen={isOpen}
          handleNavToggle={this.handleNavToggle}
          isProvider={isProvider}
        />
        {isOpen && (
          <div
            role="button"
            tabIndex={0}
            className="side-nav-overlay"
            onClick={this.handleNavToggle}
            onKeyDown={() => {}}
          />
        )}
        <div className="page-wrapper">
          <Switch>
            <Route
              exact
              path={`${matchPath}/`}
              render={() =>
                isProvider ? (
                  <Redirect to={`${matchPath}/my-patients`} />
                ) : (
                  <Redirect to={`${matchPath}/current-patients`} />
                )
              }
            />
            <Route path={`${matchPath}/login`} component={LoginPage} />
            <Route path={`${matchPath}/visiting-patients`} component={VisitingPatientsPage} />
            <Route path={`${matchPath}/my-patients`} component={MyPatientsPage} />
            <Route path={`${matchPath}/patient`} component={PatientSubLayout} />
            <Route path={`${matchPath}/current-patients`} component={PatientSubLayout} />
            <Route path={`${matchPath}/group-patients`} component={GroupVisitsPage} />
            <Route path={`${matchPath}/admin`} component={Admin} />
            <Route path={`${matchPath}/search`} component={SearchResultsPage} />
            <Route path={`${matchPath}/tasks`} component={TasksDashboard} />
            <Route path={`${matchPath}/requested-labs`} component={RequestedLabs} />
            <Route path={`${matchPath}/charts`} component={ChartsPage} />
            <Route path={`${matchPath}/charts-cosign`} render={() => <ChartsToCoSignPage isMobile={isMobile} />} />
            <Route path={`${matchPath}/prescriptions-to-sign`} render={() => <Prescriptions isMobile={isMobile} />} />
            <Route path={`${matchPath}/report`} component={ReportDashboard} />
            <Route path={`${matchPath}/report-details`} component={ReportSubLayout} />
            <Route path={`${matchPath}/epcs/cancel`} component={EpcsCancel} />
            <Route
              path={`${matchPath}/active-controlled-substance-patients-report/:reportId`}
              component={ActiveControlledSubstancePatients}
            />
          </Switch>
        </div>
        <SidebarErrorLog />
        <ModalRoot />
        <ToastContainer />
        {false && <Toolbar placement="right" autohide={false} />}
        <div id="div-float-log-error">
          <a href="#" className="hbutton" onClick={this.handleReportProblem}>
            <span className="hicon">
              <i aria-hidden="true" className="telegram plane icon" />
            </span>
            <span className="htext">Report a problem</span>
          </a>
        </div>
        <link rel="stylesheet" type="text/css" href={theme} />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { auth: { user: { profile: { roles = [] } = {} } = {} } = {} } = state;
  const isProvider = checkRoles(roleGroups.medicalProvider, roles);
  return {
    isProvider,
    authRoles: roles,
    centerContext: state.userPreference.centerContext,
    currentUserId: state.userPreference.currentUserId,
    theme: state.userPreference.theme
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      { ...modalActions, ...lookupActions, ...visitActions, ...userPreferenceActions },
      dispatch
    )
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DefaultLayout);
