import React from "react";
import { Grid, Form, Table, Pagination, Button, Icon, Dropdown, Input, Checkbox } from "semantic-ui-react";
import { CSVLink } from "react-csv";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { DateInput } from "semantic-ui-calendar-react";
import moment from "moment";
import { set, isEmpty } from "lodash";
import * as modalActions from "../../actions/modalActions";
import * as reportActions from "../../actions/reportActions";
import * as lookupActions from "../../actions/lookupActions";
import * as visitActions from "../../actions/visitActions";
import * as providerActions from "../../actions/providerActions";
import { dateFormat, dateWithTimeFormat, isoDateFormat } from "../../constants/miscellaneous";

export class ClaimReconciliationsReport extends React.Component {
  state = {
    selectedVisitIds: [],
    filter: {
      visitTimeFrom: moment()
        .subtract(2, "days")
        .format(isoDateFormat),
      visitTimeTo: moment().format(isoDateFormat),
      centerIds: [],
      providerIds: [],
      payorIds: [],
      procedureCode: "",
      hasAthenaClaim: true
    },
    activePage: 1,
    pageSize: 100,
    pageNumber: 1,
    sortColumn: null,
    sortDirection: null,
    loading: false
  };

  componentDidMount() {
    this.props.actions.loadProvidersByState();
    this.props.actions.loadInsuranceProviders();
    this.fetch();
  }

  setCSVLinkRef = element => {
    this.cSVLink = element;
  };

  cSVLink = null;

  handleExport = () => {
    const { filter } = this.state;
    const {
      claimReconciliations: { totalVisitClaimsCount },
      actions: { loadClaimReconciliations }
    } = this.props;
    loadClaimReconciliations(totalVisitClaimsCount, 1, filter, null, null, true).then(() => {
      this.cSVLink.link.click();
    });
  };

  handlePaginationChange = (e, { activePage }) => {
    if (activePage >= 1) {
      this.setState({ pageNumber: activePage, selectedVisitIds: [] }, () => this.fetch(true));
    }
  };

  handleFocus = e => {
    e.target.setAttribute("autocomplete", "nope");
  };

  handleInput = (e, data) => {
    const { name: field } = data;
    let { value } = data;
    const newState = { ...this.state };
    if (field === "filter.visitTimeFrom" || field === "filter.visitTimeTo") {
      value = moment(value, dateWithTimeFormat).format(isoDateFormat);
    }
    set(newState, field, value);
    this.setState(newState);
  };

  handleCheckbox = (e, data) => {
    const { checked } = data;
    this.setState({ ...this.state, filter: { ...this.state.filter, hasAthenaClaim: !checked } });
  };

  handleVisitCheckChange = (checked, visitId) => {
    const { selectedVisitIds } = this.state;
    if (selectedVisitIds.includes(visitId) && !checked) {
      this.setState({ selectedVisitIds: selectedVisitIds.filter(i => i !== visitId) });
    }
    if (!selectedVisitIds.includes(visitId) && checked) {
      this.setState({ selectedVisitIds: [...selectedVisitIds, visitId] });
    }
  };

  handleCheckAllVisitsChange = checked => {
    if (checked) {
      const { visitClaimsList } = this.props.claimReconciliations;
      this.setState({
        selectedVisitIds: visitClaimsList.filter(v => v.claimStatus !== "InProgress").map(v => v.visitId)
      });
    } else {
      this.setState({ selectedVisitIds: [] });
    }
  };

  fetch = (page = false) => {
    const { pageSize, pageNumber, filter, sortColumn, sortDirection } = this.state;
    if (!page) this.setState({ selectedVisitIds: [], pageNumber: 1 });
    this.props.actions.loadClaimReconciliations(pageSize, page ? pageNumber : 1, filter, sortColumn, sortDirection);
  };

  requeue = () => {
    this.setState({ loading: true });
    this.props.actions
      .submitAthenaClaim(
        this.state.selectedVisitIds.map(visitId => ({
          visitId,
          patientId: this.props.claimReconciliations.visitClaimsList.find(vc => vc.visitId === visitId).patientId
        }))
      )
      .then(() => {
        this.setState({ selectedVisitIds: [] });
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  handleSort = clickedColumn => () => {
    const { sortColumn, sortDirection } = this.state;
    this.setState(
      {
        sortColumn: clickedColumn,
        sortDirection: sortDirection === "ascending" && sortColumn === clickedColumn ? "descending" : "ascending"
      },
      () => {
        this.fetch();
      }
    );
  };

  renderTable() {
    const { loading, sortColumn, sortDirection, pageSize, pageNumber, selectedVisitIds } = this.state;
    const claimReconciliations = this.props.claimReconciliations;
    const { totalVisitClaimsCount, visitClaimsList } = claimReconciliations;
    return (
      <div>
        {totalVisitClaimsCount <= 0 && <div style={{ textAlign: "center", padding: "2rem" }}>No items to display</div>}
        {totalVisitClaimsCount > 0 && (
          <React.Fragment>
            <Table selectable sortable style={{ wordBreak: "break-all" }}>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell width={1}>
                    <Checkbox
                      onChange={(e, data) => {
                        this.handleCheckAllVisitsChange(data.checked);
                      }}
                      checked={
                        visitClaimsList.filter(v => v.claimStatus !== "InProgress").length &&
                        visitClaimsList
                          .filter(v => v.claimStatus !== "InProgress")
                          .every(v => selectedVisitIds.includes(v.visitId))
                      }
                      disabled={
                        loading ||
                        visitClaimsList.every(v => !isEmpty(v.claimExternalId) || v.claimStatus === "InProgress")
                      }
                    />
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    width={1}
                    sorted={sortColumn === "mrn" ? sortDirection : null}
                    onClick={this.handleSort("mrn")}
                  >
                    MRN
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    width={1}
                    sorted={sortColumn === "patientName" ? sortDirection : null}
                    onClick={this.handleSort("patientName")}
                  >
                    Patient Name
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    width={1}
                    sorted={sortColumn === "visitTime" ? sortDirection : null}
                    onClick={this.handleSort("visitTime")}
                  >
                    Service Date
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    width={1}
                    sorted={sortColumn === "centerName" ? sortDirection : null}
                    onClick={this.handleSort("centerName")}
                  >
                    Center Name
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    width={2}
                    sorted={sortColumn === "provider" ? sortDirection : null}
                    onClick={this.handleSort("provider")}
                  >
                    Provider
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    width={2}
                    sorted={sortColumn === "visitType" ? sortDirection : null}
                    onClick={this.handleSort("visitType")}
                  >
                    Visit Type
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    width={2}
                    sorted={sortColumn === "templateDisplayName" ? sortDirection : null}
                    onClick={this.handleSort("templateDisplayName")}
                  >
                    Template Display Name
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    width={2}
                    sorted={sortColumn === "payor" ? sortDirection : null}
                    onClick={this.handleSort("payor")}
                  >
                    Payor
                  </Table.HeaderCell>
                  <Table.HeaderCell width={3}>Procedure Codes</Table.HeaderCell>
                  <Table.HeaderCell width={3}>Diagnoses</Table.HeaderCell>
                  <Table.HeaderCell
                    width={3}
                    sorted={sortColumn === "claimExternalId" ? sortDirection : null}
                    onClick={this.handleSort("claimExternalId")}
                  >
                    Athena Claim ID
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {visitClaimsList.map(
                  ({
                    visitId,
                    mrn,
                    patientName,
                    visitTime,
                    centerName,
                    provider,
                    visitType,
                    templateDisplayName,
                    payor,
                    procedureCodes,
                    diagnoses,
                    claimExternalId,
                    claimStatus
                  }) => (
                    <Table.Row key={visitId}>
                      <Table.Cell width={1}>
                        {isEmpty(claimExternalId) && (
                          <Checkbox
                            onChange={(e, data) => {
                              this.handleVisitCheckChange(data.checked, visitId);
                            }}
                            checked={selectedVisitIds.includes(visitId)}
                            disabled={loading || claimStatus === "InProgress"}
                          />
                        )}
                      </Table.Cell>
                      <Table.Cell width={1}>{mrn}</Table.Cell>
                      <Table.Cell width={1}>{patientName}</Table.Cell>
                      <Table.Cell width={1}>{moment(visitTime).format(dateWithTimeFormat)}</Table.Cell>
                      <Table.Cell width={2}>{centerName}</Table.Cell>
                      <Table.Cell width={1}>{provider}</Table.Cell>
                      <Table.Cell width={2}>{visitType}</Table.Cell>
                      <Table.Cell width={2}>{templateDisplayName}</Table.Cell>
                      <Table.Cell width={2}>{payor}</Table.Cell>
                      <Table.Cell width={3}>{procedureCodes}</Table.Cell>
                      <Table.Cell width={3}>{diagnoses}</Table.Cell>
                      <Table.Cell width={3}>{claimExternalId}</Table.Cell>
                    </Table.Row>
                  )
                )}
              </Table.Body>
              <Table.Footer />
            </Table>

            <div style={{ padding: "0 0 1em 0", textAlign: "center" }}>
              <Pagination
                boundaryRange={2}
                activePage={pageNumber}
                ellipsisItem={`...`}
                size="mini"
                firstItem={null}
                lastItem={null}
                siblingRange={1}
                totalPages={Math.ceil(totalVisitClaimsCount / pageSize)}
                onPageChange={this.handlePaginationChange}
              />
            </div>

            <div>
              <Button
                content="Queue selected claims"
                color="blue"
                onClick={this.requeue}
                disabled={loading || selectedVisitIds.length < 1}
              />
            </div>
          </React.Fragment>
        )}
      </div>
    );
  }

  render() {
    const { filter } = this.state;
    const {
      allClaimReconciliations: { visitClaimsList },
      centerOptions,
      providerOptions,
      payorOptions
    } = this.props;

    return (
      <React.Fragment>
        <div className="header-wrapper">
          <h2>Claim Reconciliations Report</h2>
        </div>
        <div className="content-wrapper visit-claims-list" style={{ padding: "1rem 3rem 1rem 1rem" }}>
          <Grid>
            <React.Fragment>
              <Grid.Row>
                <Grid.Column width={11}>
                  <Form>
                    <Grid>
                      <Grid.Row style={{ paddingBottom: "0" }}>
                        <Grid.Column width={1} />
                        <Grid.Column width={4}>
                          Service Date From
                          <br />
                          <Form.Group widths="equal">
                            <DateInput
                              size="mini"
                              name="filter.visitTimeFrom"
                              placeholder="From"
                              value={moment(filter.visitTimeFrom).format(dateFormat)}
                              dateFormat={dateFormat}
                              iconPosition="right"
                              onChange={this.handleInput}
                              hideMobileKeyboard
                              closable
                            />
                          </Form.Group>
                        </Grid.Column>
                        <Grid.Column width={4}>
                          Service Date To
                          <br />
                          <Form.Group widths="equal">
                            <DateInput
                              size="mini"
                              name="filter.visitTimeTo"
                              placeholder="To"
                              value={moment(filter.visitTimeTo).format(dateFormat)}
                              dateFormat={dateFormat}
                              iconPosition="right"
                              onChange={this.handleInput}
                              hideMobileKeyboard
                              closable
                            />
                          </Form.Group>
                        </Grid.Column>
                        <Grid.Column width={4}>
                          Center
                          <br />
                          <Form.Group widths="equal" style={{ margin: "0 0 1em" }}>
                            <Dropdown
                              name="filter.centerIds"
                              // label="Clinic:"
                              // placeholder="Select a Center..."
                              options={centerOptions}
                              onChange={this.handleInput}
                              value={filter.centerIds}
                              // style={{ display: "inline-block" }}
                              className="mini"
                              fluid
                              multiple
                              selection
                              search
                            />
                          </Form.Group>
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row style={{ padding: "0" }}>
                        <Grid.Column width={1} />
                        <Grid.Column width={4}>
                          Provider
                          <br />
                          <Form.Group widths="equal" style={{ margin: "0 0 1em" }}>
                            <Dropdown
                              name="filter.providerIds"
                              options={providerOptions}
                              onChange={this.handleInput}
                              value={filter.providerIds}
                              className="mini"
                              fluid
                              multiple
                              selection
                              search
                            />
                          </Form.Group>
                        </Grid.Column>
                        <Grid.Column width={4}>
                          Payor
                          <br />
                          <Form.Group widths="equal" style={{ margin: "0 0 1em" }}>
                            <Dropdown
                              name="filter.payorIds"
                              options={payorOptions}
                              onChange={this.handleInput}
                              value={filter.payorIds}
                              className="mini"
                              fluid
                              multiple
                              selection
                              search
                            />
                          </Form.Group>
                        </Grid.Column>
                        <Grid.Column width={4}>
                          Procedure Code
                          <br />
                          <Form.Group widths="equal" style={{ margin: "0 0 1em" }}>
                            <Input
                              type="text"
                              name="filter.procedureCode"
                              onChange={this.handleInput}
                              value={filter.procedureCode}
                              className="mini"
                              style={{ width: "100%" }}
                              fluid
                            />
                          </Form.Group>
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row className="no-padding">
                        <Grid.Column width={1} />
                        <Grid.Column width={12}>
                          <Form.Group widths="equal" style={{ margin: "0 0 1em" }}>
                            <Form.Checkbox
                              label="Visits without Claim ID only"
                              name="filter.hasAthenaClaim"
                              onChange={this.handleCheckbox}
                              checked={!filter.hasAthenaClaim}
                              className="mini"
                            />
                          </Form.Group>
                        </Grid.Column>
                        <Grid.Column width={3}>
                          <Form.Button
                            size="mini"
                            color="blue"
                            onClick={() => {
                              this.fetch();
                            }}
                          >
                            Filter
                            <Icon name="search" style={{ marginLeft: "5px" }} />
                          </Form.Button>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Form>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row style={{ paddingBottom: "0" }}>
                <Grid.Column width={5} />
                <Grid.Column width={11} floated="right" textAlign="right">
                  <CSVLink
                    ref={this.setCSVLinkRef}
                    filename="report.csv"
                    data={visitClaimsList.map(c => ({
                      ...c,
                      proceduresAndDiagnoses: c.proceduresAndDiagnoses
                        ? Object.keys(JSON.parse(c.proceduresAndDiagnoses)).map(
                            key => ` [${key}: ${JSON.parse(c.proceduresAndDiagnoses)[key].join(", ")}] `
                          )
                        : ""
                    }))}
                    // separator={";"}
                    style={{ display: "none" }}
                  />
                  <Button
                    icon="file excel"
                    className="transparent-button-icon"
                    content="Export"
                    onClick={this.handleExport}
                  />
                </Grid.Column>
              </Grid.Row>
            </React.Fragment>

            <Grid.Row style={{ paddingTop: "0" }}>
              <Grid.Column style={{ overflowX: "auto", whitespace: "nowrap" }}>{this.renderTable()}</Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    claimReconciliations: state.reports.claimReconciliations,
    allClaimReconciliations: state.reports.allClaimReconciliations,
    centerOptions: state.lookups.centers.map(i => ({ text: i.text, value: i.value })),
    providerOptions: state.providers.allProviders.map(i => ({ text: `${i.firstName} ${i.lastName}`, value: i.userId })),
    payorOptions: state.lookups.insuranceProviders
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      { ...modalActions, ...reportActions, ...lookupActions, ...visitActions, ...providerActions },
      dispatch
    )
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClaimReconciliationsReport);
