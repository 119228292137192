/* eslint-disable react/prefer-stateless-function */
import React, { Component } from "react";
import ErrorBoundary from "./ErrorBoundary";

const emrComponent = WrappedComponent =>
  class EmrComponent extends Component {
    render() {
      return (
        <ErrorBoundary>
          <WrappedComponent {...this.props} />
        </ErrorBoundary>
      );
    }
  };

export default emrComponent;
