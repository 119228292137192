import React, { Component } from "react";
import { Button } from "semantic-ui-react";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as modalActions from "../../../../actions/modalActions";
import * as lookupActions from "../../../../actions/lookupActions";
import { roleGroups } from "../../../../constants/securityRoles";
import { checkRoles } from "../../../../helpers";

import MedicationTable from "./MedicationTable";
import MedicationSlider from "./MedicationSlider";

import "../TableSliderComponent.css";

export class ManageMedications extends Component {
  state = {
    sliderVisible: null,
    searchValue: "",
    drugs: []
  };

  componentDidMount() {
    this.props.actions.loadDrugs();
  }

  handleMedSearchModalOpen = () => {
    this.setState(
      {
        sliderVisible: false
      },
      () =>
        this.props.actions.showModal({
          type: "SEARCH_MEDICATION",
          props: { open: true, searchValue: this.state.searchValue }
        })
    );
  };

  sliderOpen = () => {
    this.setState({
      sliderVisible: true
    });
  };

  sliderClose = () => {
    this.setState({
      sliderVisible: false
    });
  };

  handleSearchInput = (e, data) => {
    this.setState({ searchValue: data.value });
  };

  handleSearchEnter = (e) => {
    if (e.key === "Enter") {
      this.handleMedSearchModalOpen();
    }
  };

  handleSearchClick = () => {
    this.handleMedSearchModalOpen();
  };

  handleQuickSearch = (value) => {
    this.props.actions.showModal({
      type: "ADD_MEDICATION",
      props: {
        open: true,
        drugName: value.drugName,
        drugKey: value.drugKey,
        strength: value.strength
      }
    });
  };

  render() {
    const { authRoles, drugs } = this.props;
    const { sliderVisible } = this.state;
    const showAddHistory = checkRoles(roleGroups.excludeHelpDesk, authRoles);
    return (
      <div className="table-slider-container">
        <h2>Medications</h2>
        {showAddHistory && (
          <Button
            icon="plus"
            className="slider-open transparent-button-icon"
            onClick={this.sliderOpen}
            id="btn-openMedications"
          />
        )}
        <MedicationTable medicationHistory={this.props.medicationHistory} />
        <MedicationSlider
          drugs={drugs}
          sliderVisible={sliderVisible}
          sliderClose={this.sliderClose}
          handleQuickSearch={this.handleQuickSearch}
          handleModalOpen={this.handleModalOpen}
          handleSearchEnter={this.handleSearchEnter}
          handleSearchInput={this.handleSearchInput}
          handleSearchClick={this.handleSearchClick}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    authRoles: state.auth.user.profile.roles,
    drugs: state.lookups.drugs
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...modalActions, ...lookupActions }, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageMedications);
