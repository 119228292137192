import React from "react";
import { connect } from "react-redux";
import * as modals from "../../helpers/modals";
import NewPatientModal from "../patients/new/NewPatientModal";
import CoreDemographicsModal from "../patients/demographics/core/CoreDemographicsModal";
import NewVisitModal from "../patients/visits/NewVisitModal";
import NewCenterModal from "../centers/NewCenterModal";
import SearchConditionModal from "../patients/history/family/SearchConditionModal";
import SearchMedicationModal from "../patients/history/medications/SearchMedicationModal";
import AddFamilyHistoryModal from "../patients/history/family/AddFamilyHistoryModal";
import SearchAllergyModal from "../patients/history/allergies/SearchAllergyModal";
import AddMedicationModal from "../patients/history/medications/AddMedicationModal";
import AddAllergyModal from "../patients/history/allergies/AddAllergyModal";
import AddMedicalHistoryModal from "../patients/history/medicalHistory/AddMedicalHistoryModal";
import EditEmailAddressModal from "../patients/demographics/emails/EditEmailAddressModal";
import EditPhoneNumberModal from "../patients/demographics/phones/EditPhoneNumberModal";
import PatientPhotoModal from "../patients/demographics/accordion/PatientPhotoModal";
import EditAddressModal from "../patients/demographics/addresses/EditAddressModal";
import ProviderIntegrationModal from "../admin/ProviderIntegrationModal";
import EditAssociatedContactModal from "../patients/demographics/contacts/EditAssociatedContactModal";
import PatientPharmacyModal from "../patients/prescriptions/PatientPharmacyModal";
import EditPatientMainCenterModal from "../patients/demographics/EditPatientMainCenterModal";
import EditPatientMainPrescriberModal from "../patients/demographics/EditPatientMainPrescriberModal";
import SearchMedPharmacyModal from "../patients/prescriptions/SearchMedPharmacyModal";
import AllergyMatchModal from "../patients/prescriptions/AllergyMatchModal";
import NoteSlashTaskFormModal from "../common/tasks/NoteSlashTaskFormModal";
import ExportClientEventsModal from "../common/ExportClientEventsModal";
import InteractionMatchModal from "../patients/prescriptions/InteractionMatchModal";
import PatientInsuranceModal from "../patients/demographics/accordion/PatientInsuranceModal";
import PatientInsuranceAddressModal from "../patients/demographics/accordion/PatientInsuranceAddressModal";
import InsuranceHistoryModal from "../patients/demographics/accordion/InsuranceHistoryModal";
import PriorAuthsModal from "../patients/demographics/accordion/PriorAuthsModal";
import ConfirmationModal from "./ConfirmationModal";
import MessageModal from "./MessageModal";
import Survey from "../patients/surveys/Survey";
import AddReferralsModal from "../patients/demographics/accordion/AddReferralsModal";
import DoctorSearchModal from "../patients/demographics/accordion/DoctorSearchModal";
import NewGroupModal from "../groups/NewGroupModal";
import PrimaryLabelDateModal from "../patients/labs/PrimaryLabelDateModal";
import ExtendedLabOrderSpecificationsModal from "../patients/labs/ExtendedLabOrderSpecificationsModal";
import AddDocumentModal from "../patients/scans/AddDocumentModal";
import MainPrescriberHistoryModal from "../patients/demographics/MainPrescriberHistoryModal";
import SendPrescriptionModal from "../patients/prescriptions/SendPrescriptionModal";
import VisitCenterConflictModal from "../patients/visits/VisitCenterConflictModal";
import LabOrderCancelationModal from "../requested-labs/LabOrderCancelationModal";
import CancelSubmittedLabModal from "../requested-labs/CancelSubmittedLabModal";
import RapidResultsEntryModal from "../patients/labs/RapidResultsEntryModal";
import PrintPrescriptionModal from "../patients/prescriptions/PrintPrescriptionModal";
import HistoryAddressModal from "../patients/history/address/HistoricalAddressModal";
import NoteSlashTaskDeleteReasonModal from "../common/tasks/NoteSlashTaskDeleteReasonModal";
import DocumentDeleteReasonModal from "../patients/scans/DocumentDeleteReasonModal";
import ErrorDetailsModal from "../error/ErrorDetailsModal";
import ClaimErrorDetailsModal from "../error/ClaimErrorDetailsModal";
import ConfirmReverseDischargeModal from "../patients/demographics/accordion/ConfirmReverseDischargeModal";
import ChangeVisitTypeModal from "../patients/visits/ChangeVisitTypeModal";
import TelemedDataModal from "../patients/visits/TelemedDataModal";
import CancelVisitModal from "../patients/visits/CancelVisitModal";
import AoeModal from "../patients/labs/AoeModal";
import AddInterventionHistoryModal from "../patients/history/interventions/AddInterventionHistoryModal";
import IntakeModal from "../patients/intake/IntakeModal";
import OutsideProviderHistoryModal from "../patients/demographics/accordion/OutsideProviderHistoryModal";
import RenewLicenseModal from "../admin/RenewLicenseModal";
import EditPatientLevelsModal from "../patients/demographics/EditPatientLevelsModal";

const MODALS = {
  [modals.NEW_PATIENT]: NewPatientModal,
  [modals.CORE_DEMOGRAPHICS]: CoreDemographicsModal,
  [modals.NEW_VISIT]: NewVisitModal,
  [modals.NEW_CENTER]: NewCenterModal,
  [modals.SEARCH_CONDITION]: SearchConditionModal,
  [modals.SEARCH_MEDICATION]: SearchMedicationModal,
  [modals.ADD_FAMILY_HISTORY]: AddFamilyHistoryModal,
  [modals.ADD_MEDICATION]: AddMedicationModal,
  [modals.SEARCH_ALLERGY]: SearchAllergyModal,
  [modals.ADD_ALLERGY]: AddAllergyModal,
  [modals.ADD_MEDICAL_HISTORY]: AddMedicalHistoryModal,
  [modals.ADD_INTERVENTION_HISTORY]: AddInterventionHistoryModal,
  [modals.EDIT_EMAIL_ADDRESS]: EditEmailAddressModal,
  [modals.EDIT_PHONE_NUMBER]: EditPhoneNumberModal,
  [modals.EDIT_ASSOCIATED_CONTACT]: EditAssociatedContactModal,
  [modals.PATIENT_PHOTO]: PatientPhotoModal,
  [modals.EDIT_ADDRESS_MODAL]: EditAddressModal,
  [modals.PATIENT_PHARMACY_MODAL]: PatientPharmacyModal,
  [modals.PROVIDER_INTEGRATION]: ProviderIntegrationModal,
  [modals.EDIT_MAIN_CENTER]: EditPatientMainCenterModal,
  [modals.EDIT_MAIN_PRESCRIBER]: EditPatientMainPrescriberModal,
  [modals.SEARCH_MED_PHARM]: SearchMedPharmacyModal,
  [modals.PATIENT_ALLERGY_MATCH]: AllergyMatchModal,
  [modals.NOTE_SLASH_TASK_MODAL]: NoteSlashTaskFormModal,
  [modals.PATIENT_INTERACTION_MATCH]: InteractionMatchModal,
  [modals.PATIENT_INSURANCE]: PatientInsuranceModal,
  [modals.PATIENT_INSURANCE_ADDRESS]: PatientInsuranceAddressModal,
  [modals.INSURANCE_HISTORY_MODAL]: InsuranceHistoryModal,
  [modals.PRIOR_AUTHS_MODAL]: PriorAuthsModal,
  [modals.CONFIRMATION]: ConfirmationModal,
  [modals.MESSAGE]: MessageModal,
  [modals.SURVEY_MODAL]: Survey,
  [modals.ADD_REFERRALS_MODAL]: AddReferralsModal,
  [modals.DOCTOR_SEARCH]: DoctorSearchModal,
  [modals.NEW_GROUP]: NewGroupModal,
  [modals.PRIMARY_LABEL_DATE_MODAL]: PrimaryLabelDateModal,
  [modals.LAB_ORDER_MODAL]: ExtendedLabOrderSpecificationsModal,
  [modals.ADD_DOCUMENT_MODAL]: AddDocumentModal,
  [modals.MAIN_PRESCRIBER_HISTORY]: MainPrescriberHistoryModal,
  [modals.SEND_PRESCRIPTION_MODAL]: SendPrescriptionModal,
  [modals.VISIT_CENTER_CONFLICT]: VisitCenterConflictModal,
  [modals.CANCEL_LAB_ORDER]: LabOrderCancelationModal,
  [modals.CANCEL_SUBMITTED_LAB_MODAL]: CancelSubmittedLabModal,
  [modals.RAPID_RESULTS_ENTRY_MODAL]: RapidResultsEntryModal,
  [modals.PRINT_PRESCRIPTION_MODAL]: PrintPrescriptionModal,
  [modals.HISTORY_ADDRESS_MODAL]: HistoryAddressModal,
  [modals.NOTE_SLASH_TASK_DELETE_REASON_MODAL]: NoteSlashTaskDeleteReasonModal,
  [modals.DOCUMENT_DELETE_REASON_MODAL]: DocumentDeleteReasonModal,
  [modals.ERROR_DETAILS]: ErrorDetailsModal,
  [modals.CLAIM_ERROR_DETAILS]: ClaimErrorDetailsModal,
  [modals.CONFIRM_REVERSE_DISCHARGE]: ConfirmReverseDischargeModal,
  [modals.CHANGE_VISIT_TYPE_MODAL]: ChangeVisitTypeModal,
  [modals.TELEMED_DATA_MODAL]: TelemedDataModal,
  [modals.CANCEL_VISIT_MODAL]: CancelVisitModal,
  [modals.EXPORT_CLIENT_EVENTS_MODAL]: ExportClientEventsModal,
  [modals.AOE]: AoeModal,
  [modals.INTAKE_MODAL]: IntakeModal,
  [modals.OUTSIDE_PROVIDER_HISTORY_MODAL]: OutsideProviderHistoryModal,
  [modals.RENEW_LICENSE_MODAL]: RenewLicenseModal,
  [modals.EDIT_PATIENT_LEVELS_MODAL]: EditPatientLevelsModal
};

const ModalRoot = (_props) => {
  const {
    multipleModals: { current: { type: typeMultiple, props: propsMultiple } = {} } = {},
    modal: { type, props } = {}
  } = _props;

  if (!type && !typeMultiple) {
    return null;
  }

  const ModalComponent = MODALS[type || typeMultiple];

  return type ? <ModalComponent {...props} /> : <ModalComponent {...propsMultiple} />;
};

function mapStateToProps(state) {
  return {
    modal: state.modal,
    multipleModals: state.multipleModals
  };
}

export default connect(mapStateToProps)(ModalRoot);
