import React from "react";
import { Form } from "semantic-ui-react";
import MaskedInput from "react-text-mask";
import { DateInput } from "semantic-ui-calendar-react";
import { CUSTOM_FIELD_ID_IDENTIFY_AS_LGBTQI, dateFormat } from "../../../../constants/miscellaneous";
import { hasProperty, arrayHasSomeUndefined } from "../../../../helpers";
import { ssnMask } from "../../../../constants/validation";
import "./CoreDemographicsForm.css";

export const CoreDemographicsForm = ({
  _for = "demographics",
  demographics,
  ethnicities,
  maritalStatuses,
  genderIdentities,
  genderPronouns,
  sexAssignedAtBirths,
  sexualOrientations,
  genders,
  handleInput,
  handleMaskedInput,
  handleSsnOnFocus,
  handleSsnOnBlur,
  handleGender,
  languages,
  races,
  treatments,
  errors,
  auxFlagOptions,
  handleFocus,
  // newPatient,
  disableEdit,
  identifiesAsLgbtqiOptions
}) => {
  const identifiesAsLgbtqi =
    demographics.patientCustomFieldAnswers.find((cfa) => +cfa.id === CUSTOM_FIELD_ID_IDENTIFY_AS_LGBTQI)
      ?.customFieldValue || "";

  const someUndefinedFields = arrayHasSomeUndefined([
    demographics.patientFirstName,
    demographics.patientMiddleName,
    demographics.patientLastName,
    demographics.suffix,
    demographics.patientPreferredName,
    demographics.patientPreviousLastName,
    demographics.patientDateOfBirth,
    demographics.socialSecurityNumber,
    demographics.language,
    demographics.race,
    demographics.ethnicity,
    demographics.maritalStatus,
    demographics.sexualOrientation,
    demographics.genderIdentity,
    demographics.sexAssignedAtBirth,
    demographics.genderPronoun,
    demographics.gender,
    demographics.driversLicenseNumber,
    demographics.auxiliaryFlags,
    demographics.patientTreatmentType,
    identifiesAsLgbtqi
  ]);

  return !demographics || someUndefinedFields ? (
    <div>Loading...</div>
  ) : (
    <Form>
      <Form.Group widths="equal">
        <Form.Field>
          <Form.Input
            fluid
            required
            label="First Name"
            name="patientFirstName"
            maxLength={50}
            value={demographics.patientFirstName}
            onChange={handleInput}
            error={hasProperty(errors, "patientFirstName")}
            id="input-firstName"
            disabled
          />
        </Form.Field>
        <Form.Field>
          <Form.Input
            fluid
            label="Middle Name"
            name="patientMiddleName"
            maxLength={50}
            value={demographics.patientMiddleName}
            onChange={handleInput}
            error={hasProperty(errors, "patientMiddleName")}
            id="input-middleName"
            disabled
          />
        </Form.Field>
        <Form.Field>
          <Form.Input
            fluid
            required
            label="Last Name"
            name="patientLastName"
            maxLength={100}
            value={demographics.patientLastName}
            onChange={handleInput}
            error={hasProperty(errors, "patientLastName")}
            id="input-lastName"
            disabled
          />
        </Form.Field>
        <Form.Field>
          <Form.Input
            fluid
            label="Suffix"
            name="suffix"
            maxLength={50}
            value={demographics.suffix}
            onChange={handleInput}
            error={hasProperty(errors, "suffix")}
            id="input-suffix"
            disabled
          />
        </Form.Field>
        <Form.Field>
          <Form.Input
            fluid
            label="Preferred Name"
            name="patientPreferredName"
            maxLength={50}
            value={demographics.patientPreferredName}
            onChange={handleInput}
            disabled={disableEdit}
          />
        </Form.Field>
      </Form.Group>

      <Form.Group widths="equal">
        <Form.Field>
          <Form.Input
            fluid
            label="Previous Name (last)"
            name="patientPreviousLastName"
            maxLength={50}
            value={demographics.patientPreviousLastName || ""}
            onChange={handleInput}
            error={hasProperty(errors, "patientPreviousLastName")}
            id="input-previousLastName"
            disabled
          />
        </Form.Field>
        <Form.Field
          className={hasProperty(errors, "patientDateOfBirth") ? "error required field" : "required field"}
          disabled
        >
          <label htmlFor="input-patientDateOfBirth" className="mock-semantic-required">
            Date of Birth
          </label>
          <DateInput
            id="input-patientDateOfBirth"
            name="patientDateOfBirth"
            placeholder="Date of Birth"
            value={demographics.patientDateOfBirth === undefined ? "" : demographics.patientDateOfBirth}
            dateFormat={dateFormat}
            iconPosition="right"
            onChange={handleInput}
            hideMobileKeyboard
            closable
            disabled
          />
        </Form.Field>
        {_for !== "intake" && (
          <Form.Field disabled={disableEdit}>
            <label className="mock-semantic">SSN</label>
            <div className={disableEdit ? "ui disabled fluid input" : ""}>
              <MaskedInput
                name="socialSecurityNumber"
                mask={ssnMask}
                guide={false}
                value={(demographics.socialSecurityNumber === undefined
                  ? ""
                  : demographics.socialSecurityNumber
                ).replace(/X/g, "#")}
                onChange={handleMaskedInput}
                onFocus={handleSsnOnFocus}
                onBlur={handleSsnOnBlur}
                id="input-socialSecurityNumber"
                disabled={disableEdit}
              />
            </div>
          </Form.Field>
        )}
        {_for === "intake" && (
          <Form.Field disabled={disableEdit}>
            <Form.Dropdown
              selection
              clearable
              label="Legal Sex"
              placeholder="Select..."
              name="gender"
              options={genders}
              value={demographics.gender}
              onChange={handleGender}
              id="input-patientGender"
              disabled={disableEdit}
            />
          </Form.Field>
        )}
        <Form.Field
          // className={hasProperty(errors, "language") ? "error required field" : "required field"}
          disabled={disableEdit}
        >
          <label className="_mock-semantic-required">Language</label>
          <Form.Dropdown
            fluid
            selection
            clearable
            search
            // required
            name="language"
            options={languages}
            placeholder="Select..."
            value={demographics.language}
            onChange={handleInput}
            error={hasProperty(errors, "language")}
            id="input-patientPreferredName"
            onFocus={handleFocus}
            disabled={disableEdit}
          />
        </Form.Field>
      </Form.Group>
      <Form.Group widths="equal">
        <Form.Field
          // className={hasProperty(errors, "race") ? "error required field" : "required field"}
          disabled={disableEdit}
        >
          <label className="mock-semantic">Race</label>
          <Form.Dropdown
            selection
            clearable
            // required={newPatient}
            name="race"
            options={races}
            placeholder="Select..."
            value={demographics.race}
            onChange={handleInput}
            error={hasProperty(errors, "race")}
            id="input-race"
            onFocus={handleFocus}
            disabled={disableEdit}
          />
        </Form.Field>
        <Form.Field
          // className={hasProperty(errors, "ethnicity") ? "error required field" : "required field"}
          disabled={disableEdit}
        >
          <label className="mock-semantic">Ethnicity</label>
          <Form.Dropdown
            selection
            clearable
            search
            // required={newPatient}
            name="ethnicity"
            options={ethnicities}
            placeholder="Select..."
            value={demographics.ethnicity}
            onChange={handleInput}
            error={hasProperty(errors, "ethnicity")}
            id="input-ethnicity"
            onFocus={handleFocus}
            disabled={disableEdit}
          />
        </Form.Field>
        <Form.Field
          // className={hasProperty(errors, "maritalStatus") ? "error required field" : "required field"}
          disabled={disableEdit}
        >
          <label className="mock-semantic">Marital Status</label>
          <Form.Dropdown
            selection
            clearable
            search
            // required={newPatient}
            name="maritalStatus"
            options={maritalStatuses} // .map((s) => ({ text: s.fullText, value: s.fullText }))}
            placeholder="Select..."
            value={demographics.maritalStatus}
            onChange={handleInput}
            error={hasProperty(errors, "maritalStatus")}
            id="input-maritalStatus"
            onFocus={handleFocus}
            disabled={disableEdit}
          />
        </Form.Field>
      </Form.Group>

      <Form.Group widths="equal">
        <Form.Field
          // className={hasProperty(errors, "sexualOrientation") ? "error required field" : "required field"}
          disabled={disableEdit}
        >
          <label className="mock-semantic">Sexual Orientation</label>
          <Form.Dropdown
            selection
            clearable
            name="sexualOrientation"
            options={sexualOrientations}
            placeholder="Select..."
            value={demographics.sexualOrientation}
            onChange={handleInput}
            error={hasProperty(errors, "sexualOrientation")}
            id="input-sexualOrientation"
            onFocus={handleFocus}
            disabled={disableEdit}
          />
        </Form.Field>
        <Form.Field
          // className={hasProperty(errors, "identifiesAsLgbtqi") ? "error required field" : "required field"}
          disabled={disableEdit}
        >
          <label className="mock-semantic">Identifies as LGBTQI</label>
          <Form.Dropdown
            selection
            clearable
            name="identifiesAsLgbtqi"
            options={identifiesAsLgbtqiOptions}
            placeholder="Select..."
            value={identifiesAsLgbtqi}
            onChange={handleInput}
            error={hasProperty(errors, "identifiesAsLgbtqi")}
            id="input-identifiesAsLgbtqi"
            onFocus={handleFocus}
            disabled={disableEdit}
            custome_field_id={CUSTOM_FIELD_ID_IDENTIFY_AS_LGBTQI}
          />
        </Form.Field>
        <Form.Field
          // className={hasProperty(errors, "genderIdentity") ? "error required field" : "required field"}
          disabled={disableEdit}
        >
          <label className="mock-semantic">Gender Identity</label>
          <Form.Dropdown
            selection
            clearable
            name="genderIdentity"
            options={genderIdentities}
            placeholder="Select..."
            value={demographics.genderIdentity}
            onChange={handleInput}
            error={hasProperty(errors, "genderIdentity")}
            id="input-genderIdentity"
            onFocus={handleFocus}
            disabled={disableEdit}
          />
        </Form.Field>
      </Form.Group>

      <Form.Group widths="equal">
        <Form.Field
          // className={hasProperty(errors, "sexAssignedAtBirth") ? "error required field" : "required field"}
          disabled={disableEdit}
        >
          <label className="mock-semantic">Assigned sex at birth</label>
          <Form.Dropdown
            selection
            clearable
            name="sexAssignedAtBirth"
            options={sexAssignedAtBirths}
            placeholder="Select..."
            value={demographics.sexAssignedAtBirth}
            onChange={handleInput}
            error={hasProperty(errors, "sexAssignedAtBirth")}
            id="input-sexAssignedAtBirth"
            onFocus={handleFocus}
            disabled={disableEdit}
          />
        </Form.Field>
        <Form.Field
          // className={hasProperty(errors, "genderPronoun") ? "error required field" : "required field"}
          disabled={disableEdit}
        >
          <label className="_mock-semantic-required" htmlFor="input-genderPronoun">
            Gender Pronoun
          </label>
          <Form.Dropdown
            fluid
            selection
            clearable
            // required
            name="genderPronoun"
            options={genderPronouns}
            placeholder="Select..."
            value={demographics.genderPronoun}
            onChange={handleInput}
            error={hasProperty(errors, "genderPronoun")}
            id="input-genderPronoun"
            onFocus={handleFocus}
            disabled={disableEdit}
          />
        </Form.Field>
        {_for !== "intake" && (
          <Form.Field disabled={disableEdit}>
            <Form.Dropdown
              selection
              clearable
              label="Legal Sex"
              placeholder="Select..."
              name="gender"
              options={genders}
              value={demographics.gender}
              onChange={handleGender}
              id="input-patientGender"
              disabled={disableEdit}
            />
          </Form.Field>
        )}
        {_for === "intake" && <Form.Field />}
      </Form.Group>

      {_for !== "intake" && (
        <Form.Group widths="equal">
          <Form.Field disabled={disableEdit}>
            <Form.Input
              fluid
              label="Driver's License"
              name="driversLicenseNumber"
              maxLength={50}
              value={demographics.driversLicenseNumber}
              onChange={handleInput}
              disabled={disableEdit}
            />
          </Form.Field>
          {/*
          <Form.Field disabled={disableEdit}>
          <label className="mock-semantic">Communication Preference</label>
          <Form.Dropdown
            fluid
            selection clearable
            search
            name="communicationPreference"
            placeholder="Select..."
            options={communicationPreferences}
            value={demographics.communicationPreference}
            onChange={handleInput}
            error={hasProperty(errors, "communicationPreference")}
            onFocus={handleFocus}
            disabled={disableEdit}
          />
          </Form.Field>
          */}

          <Form.Field>
            <Form.Dropdown
              name="auxiliaryFlags"
              fluid
              multiple
              selection
              clearable
              label="Auxiliary Flags"
              placeholder="Select..."
              options={auxFlagOptions ? auxFlagOptions.map((i) => ({ text: i.text, value: i.value })) : []}
              search
              onChange={handleInput}
              value={demographics.auxiliaryFlags}
              onFocus={handleFocus}
              id="input-states"
            />
          </Form.Field>
          <Form.Field>
            <label>Treatment Type</label>
            <Form.Dropdown
              selection
              clearable
              // required
              name="patientTreatmentType"
              placeholder="Select..."
              options={treatments}
              value={demographics.patientTreatmentType}
              onChange={handleInput}
              id="input-patientTreatmentType"
            />
          </Form.Field>
        </Form.Group>
      )}
    </Form>
  );
};
export default CoreDemographicsForm;
