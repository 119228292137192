import React from "react";
import { Segment, Button, Grid } from "semantic-ui-react";
import { isEmpty } from "../../../helpers";

const SurveyList = ({ patientId, surveys, handleModalOpen, hiddenSurveys, templateId }) => (
  <Grid columns={1}>
    <Grid.Column>
      {surveys.map(survey => {
        const hiddenSurvey = hiddenSurveys[`${patientId}-${templateId}-${survey.id}`];
        const disabled = !isEmpty(hiddenSurveys) && isEmpty(hiddenSurvey);
        const color = !isEmpty(hiddenSurvey) ? "yellow" : null;
        return (
          <Grid.Row key={survey.id} className="survey-list-row">
            <Segment
              className={`survey-list-item ${survey.hasAnswersFromContextContainer && "survey-completed"}`}
              as={Button}
              disabled={disabled}
              color={color}
              onClick={handleModalOpen(survey)}
            >
              {survey.title}
            </Segment>
          </Grid.Row>
        );
      })}
    </Grid.Column>
  </Grid>
);

export default SurveyList;
