import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import classNames from "classnames/bind";
import shortid from "shortid";
import isEmpty from "lodash/isEmpty";
import { Message, Button } from "semantic-ui-react";
import SurveyHeaderEditor from "./SurveyHeaderEditor";
import QuestionEditor from "./QuestionEditor";
import SectionEditor from "./SectionEditor";
import ResizePanel from "../../common/ResizePanel";
import * as modalActions from "../../../actions/modalActions";
import Survey from "../../patients/surveys/Survey";
import SurveyFormBuilder from "./SurveyFormBuilder";
import style from "./AdvancedSurveyBuilder.css";

const cx = classNames.bind(style);
const newEmptySurvey = {
  id: "00000000-0000-0000-0000-000000000000",
  name: "",
  isCumulative: true,
  requireReview: false,
  isReadOnly: false,
  sections: [],
  title: "",
  version: "1.0.0",
  questions: []
};

class AdvancedSurveyBuilder extends Component {
  state = {
    mode: "edit",
    autosave: true,
    saving: false,
    prefix: sessionStorage.getItem("prefix") ? sessionStorage.getItem("prefix") : "***",
    colOneWidth: 2,
    colTwoWidth: 10,
    colThreeWidth: 4,
    invalidJson: true,
    surveyJsonString: sessionStorage.getItem("surveyJsonString") ? sessionStorage.getItem("surveyJsonString") : "",
    editor: {
      target: null,
      targetObject: null
    },
    survey: null
  };

  componentDidMount() {
    /* localStorage is behaving crazy on chrome !!! */
    if (sessionStorage.getItem("surveyJsonString")) {
      try {
        this.setState({
          surveyJsonString: sessionStorage.getItem("surveyJsonString"),
          survey: JSON.parse(sessionStorage.getItem("surveyJsonString")),
          invalidJson: false
        });
      } catch (exp) {
        this.setState({
          surveyJsonString: sessionStorage.getItem("surveyJsonString"),
          invalidJson: true
        });
      }
    }

    this.interval = setInterval(() => {
      if (this.state.autosave) {
        this.setState({ saving: true }, () =>
          /* fake delay to allow saving message to be shown */
          setTimeout(() => {
            this.setState({ saving: false });
          }, 1000)
        );
        sessionStorage.setItem("prefix", this.state.prefix);
        sessionStorage.setItem("surveyJsonString", this.state.surveyJsonString);
      }
    }, 10000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  onJsonChange = (e) => {
    try {
      const survey = JSON.parse(e.target.value);
      const uSurvey = {
        ...survey,
        sections: survey.sections.map((s) => {
          if (isEmpty(s.sbId)) return { ...s, sbId: shortid.generate() };
          return s;
        }),
        questions: survey.questions.map((q) => {
          if (isEmpty(q.sbId)) return { ...q, sbId: shortid.generate() };
          return q;
        })
      };
      this.setState({ surveyJsonString: e.target.value, survey: uSurvey, invalidJson: false });
    } catch (exp) {
      this.setState({
        surveyJsonString: e.target.value,
        // survey: ... // do not update saved survey .. so it has latest valid json value
        invalidJson: true,
        editor: { target: null, targetObject: null }
      });
    }
  };

  handlePrefixChnage = (value) => {
    this.setState({ prefix: value });
  };

  handleHeaderKeyChnage = (key, value) => {
    const updatedSurvey = { ...this.state.survey };
    updatedSurvey[key] = value;
    this.setState({
      survey: updatedSurvey,
      surveyJsonString: JSON.stringify(updatedSurvey)
      // editor: { target: "header", targetObject: null } // should be already "header"
    });
  };

  updateSection = (section) => {
    const updatedSurvey = {
      ...this.state.survey,
      sections: this.state.survey.sections.map((s) => (s.sbId === section.sbId ? section : s))
    };
    this.setState({
      survey: updatedSurvey,
      surveyJsonString: JSON.stringify(updatedSurvey),
      editor: { ...this.state.editor, targetObject: section }
    });
  };

  updateQuestion = (question) => {
    const updatedSurvey = {
      ...this.state.survey,
      questions: this.state.survey.questions.map((q) => (q.sbId === question.sbId ? question : q))
    };
    this.setState({
      survey: updatedSurvey,
      surveyJsonString: JSON.stringify(updatedSurvey),
      editor: { ...this.state.editor, targetObject: question }
    });
  };

  handleDeleteSectionPress = (sbId) => {
    this.props.actions.showModal({
      type: "CONFIRMATION",
      props: {
        open: true,
        icon: "exclamation-triangle",
        iconColor: "warning",
        title: "Confirm Complete",
        buttonColor: "red",
        description: "Deleting section will delete it with all child question; are you sure?",
        onConfirm: () => {
          const { survey } = this.state;
          let sections = [...survey.sections];
          const section = sections.find((s) => s.sbId === sbId);
          let questions = [...survey.questions];
          questions = questions.filter((q) => q.sectionKey !== section.key);
          sections = sections.filter((s) => s.sbId !== sbId);
          const updatedSurvey = { ...survey, sections, questions };
          this.setState({
            survey: updatedSurvey,
            surveyJsonString: JSON.stringify(updatedSurvey),
            editor: { target: null, targetObject: null }
          });
        }
      }
    });
  };

  handleDeleteQuestionPress = (sbId) => {
    const { survey } = this.state;
    const questions = [...survey.questions];
    const index = questions.findIndex((q) => q.sbId === sbId);
    questions.splice(index, 1);
    const updatedSurvey = { ...survey, questions };
    this.setState({
      survey: updatedSurvey,
      surveyJsonString: JSON.stringify(updatedSurvey),
      editor: { target: null, targetObject: null }
    });
  };

  handleMoveSectionPress = (sbId, direction) => {
    const { survey } = this.state;
    const sections = [...survey.sections];
    const section = sections.find((s) => s.sbId === sbId);

    const index = sections.findIndex((s) => s.sbId === section.sbId);

    if (index > 0 && direction === "up") {
      const first = { ...sections[index] };
      const second = { ...sections[index - 1] };
      sections.splice(index - 1, 2, first, second);
    }

    if (index < sections.length - 1 && direction === "down") {
      const first = { ...sections[index] };
      const second = { ...sections[index + 1] };
      sections.splice(index, 2, second, first);
    }

    const updatedSurvey = { ...survey, sections };
    this.setState({
      survey: updatedSurvey,
      surveyJsonString: JSON.stringify(updatedSurvey)
      // editor: { target: "section", targetObject: section }
    });
  };

  handleMoveQuestionPress = (sbId, direction) => {
    const { survey } = this.state;
    let questions = [...survey.questions];
    const question = questions.find((q) => q.sbId === sbId);
    let sortOrder = question.sortOrder;

    if (direction === "up") {
      const questionsAbove = questions.filter(
        (q) => q.sectionKey === question.sectionKey && q.sbId !== question.sbId && q.sortOrder <= question.sortOrder
      );
      if (questionsAbove.length > 0) sortOrder--;
    }
    if (direction === "down") {
      const questionsBelow = questions.filter(
        (q) => q.sectionKey === question.sectionKey && q.sbId !== question.sbId && q.sortOrder >= question.sortOrder
      );
      if (questionsBelow.length > 0) sortOrder++;
    }

    if (direction === "up" || direction === "down") {
      questions = questions.map((q) => {
        if (q.sbId !== sbId) return q;
        return {
          ...q,
          sortOrder
        };
      });
    }

    const index = questions.findIndex((q) => q.sbId === question.sbId);

    if (
      direction === "right" &&
      questions.length > index + 1 &&
      questions[index + 1].sortOrder === question.sortOrder
    ) {
      const first = { ...questions[index] };
      const second = { ...questions[index + 1] };
      questions.splice(index, 2, second, first);
    }

    if (direction === "left" && index > 0 && questions[index - 1].sortOrder === question.sortOrder) {
      const first = { ...questions[index] };
      const second = { ...questions[index - 1] };
      questions.splice(index - 1, 2, first, second);
    }

    // shift sort order so it always positive
    let min = 999999;
    questions.forEach((q) => {
      if (q.sortOrder < min) min = q.sortOrder;
    });
    if (min < 1) {
      questions = questions.map((q) => ({ ...q, sortOrder: q.sortOrder + (-min + 1) }));
    }

    const updatedSurvey = { ...survey, questions };
    this.setState({
      survey: updatedSurvey,
      surveyJsonString: JSON.stringify(updatedSurvey),
      editor: { target: "question", targetObject: question }
    });
  };

  handleAddQuestionPress = (sectionKey, sbId = null, direction = "down") => {
    let key = null;
    let count = this.state.survey.questions.length;
    while (isEmpty(key)) {
      key = `${this.state.prefix}-100-Q${++count}`;
      // eslint-disable-next-line no-loop-func
      if (this.state.survey.questions.find((q) => q.key === key)) key = null;
    }

    let _sortOrder = sbId ? this.state.survey.questions.find((q) => q.sbId === sbId).sortOrder : 0;
    if (direction === "down") _sortOrder++;
    if (direction === "up") _sortOrder--;

    const question = {
      sbId: shortid.generate(),
      key,
      sortOrder: _sortOrder,
      description: "",
      text: "",
      shortDisplayText: "",
      summaryText:
        "{Answers:list:{Value}{Elucidation:; {Elucidation.Caption}: {Elucidation.Value}|}|, |, and }{Elucidation:, {Elucidation.Caption}: {Elucidation.Value}|}",
      type: "ShortText",
      options: [],
      triggers: [],
      elucidations: [],
      defaultAnswers: [],
      eventTriggers: [],
      sectionKey,
      version: "1.0.0"
    };

    let questions = [...this.state.survey.questions, question].sort((a, b) =>
      a.sortOrder > b.sortOrder ? 1 : b.sortOrder > a.sortOrder ? -1 : 0
    );

    // if down shift all following down
    questions = questions.map((q) => {
      if (direction === "down") {
        if (q.sbId === question.sbId || q.sortOrder < _sortOrder) return q;
        return { ...q, sortOrder: q.sortOrder + 1 };
      }
      if (direction === "up") {
        if (q.sbId === question.sbId || q.sortOrder > _sortOrder) return q;
        return { ...q, sortOrder: q.sortOrder - 1 };
      }
      return q;
    });

    if (direction === "right" || direction === "left") {
      const originalQuestionIndex = questions.findIndex((q) => q.sbId === sbId);
      const newQuestionIndex = questions.findIndex((q) => q.sbId === question.sbId);
      if (direction === "right" && newQuestionIndex !== originalQuestionIndex + 1) {
        // swap newQuestionIndex with originalQuestionIndex + 1
        questions.splice(originalQuestionIndex + 1, 0, question);
        questions.splice(newQuestionIndex + 1, 1);
      }
      if (direction === "left" && newQuestionIndex !== originalQuestionIndex - 1) {
        // swap newQuestionIndex with originalQuestionIndex - 1
        questions.splice(originalQuestionIndex, 0, question);
        questions.splice(newQuestionIndex + 1, 1);
      }
    }

    // shift sort order so it always positive
    let min = 999999;
    questions.forEach((q) => {
      if (q.sortOrder < min) min = q.sortOrder;
    });
    if (min < 1) {
      questions = questions.map((q) => ({ ...q, sortOrder: q.sortOrder + (-min + 1) }));
    }

    const updatedSurvey = { ...this.state.survey, questions };
    this.setState({
      survey: updatedSurvey,
      surveyJsonString: JSON.stringify(updatedSurvey),
      editor: { target: "question", targetObject: question }
    });
  };

  handleNewSurveyPress = () => {
    this.props.actions.showModal({
      type: "CONFIRMATION",
      props: {
        open: true,
        icon: "exclamation-triangle",
        iconColor: "warning",
        title: "Confirm Complete",
        buttonColor: "red",
        description: "Are you sure?",
        onConfirm: () => {
          this.setState({
            surveyJsonString: JSON.stringify(newEmptySurvey),
            survey: { ...newEmptySurvey },
            invalidJson: false,
            editor: { target: "header", targetObject: null }
          });
        }
      }
    });
  };

  handleClearEditorPress = (e) => {
    e.stopPropagation();
    this.setState({ editor: { target: "", targetObject: null } });
  };

  handleAddSectionPress = (e) => {
    e.stopPropagation();
    const { survey } = this.state;
    const section = { sbId: shortid.generate(), key: "", header: "" };
    const updatedSurvey = { ...survey, sections: [...survey.sections, section] };
    this.setState({
      survey: updatedSurvey,
      surveyJsonString: JSON.stringify(updatedSurvey),
      editor: { target: "section", targetObject: section }
    });
  };

  handleEditHeaderPress = (e) => {
    e.stopPropagation();
    this.setState({ editor: { target: "header", targetObject: null } });
  };

  handleEditSectionPress = (e, sbId) => {
    e.stopPropagation();
    this.setState({
      editor: { target: "section", targetObject: { ...this.state.survey.sections.find((s) => s.sbId === sbId) } }
    });
  };

  handleEditQuestionPress = (e, sbId) => {
    e.stopPropagation();
    this.setState({
      editor: { target: "question", targetObject: { ...this.state.survey.questions.find((q) => q.sbId === sbId) } }
    });
  };

  prepareJson = () => {
    const json = {
      ...this.state.survey,
      sections: this.state.survey.sections.map((s) => {
        const us = { ...s };
        delete us.sbId;
        return us;
      }),
      questions: this.state.survey.questions.map((q) => {
        const uq = { ...q };
        delete uq.sbId;
        return uq;
      })
    };

    return JSON.stringify(json, null, 2);
  };

  render() {
    const { mode, invalidJson, surveyJsonString, survey } = this.state;
    const _survey = invalidJson
      ? null
      : {
          ...survey,
          groups: [],
          templateActions: { templateActions: [] },
          questions: survey.questions.map((q) => ({
            ...q,
            options: q.options ? q.options : [],
            triggers: q.triggers ? q.triggers : [],
            elucidations: q.elucidations ? q.elucidations : [],
            previousAnswers: [],
            previousAnswersVisitId: null,
            staticValues: [],
            sectionCount: 1,
            groupKey: q.groupKey || null,
            groupCount: 1,
            questionCount: 1,
            eventTriggers: q.eventTriggers ? q.eventTriggers : [],
            defaultAnswers: q.defaultAnswers ? q.defaultAnswers : []
          }))
        };

    return (
      <div className="sb">
        <div className={cx("container")}>
          <div className={cx("body")}>
            <ResizePanel direction="e" style={{ flexGrow: "1" }}>
              <div
                className={cx("sidebar", "withMargin", "left-panel")}
                style={{ overflow: "hidden", paddingRight: "0" }}
              >
                <div className="toolbar">
                  <Button size="mini" color="blue" onClick={this.handleNewSurveyPress}>
                    New Survey
                  </Button>
                </div>
                <textarea
                  style={{ width: "100%", minHeight: "600px", height: "100%" }}
                  value={invalidJson ? surveyJsonString : this.prepareJson(surveyJsonString)}
                  onChange={this.onJsonChange}
                />
              </div>
            </ResizePanel>
            <div
              className={cx("content", "withMargin", "center-panel")}
              style={{ overflow: "hidden", paddingRight: "0" }}
            >
              <div className="toolbar">
                <div
                  style={{
                    display: this.state.saving ? "inline-block" : "none",
                    minWidth: "200px",
                    textAlign: "center"
                  }}
                >
                  Saving...
                </div>
                {mode === "edit" && (
                  <Button
                    className="transparent-button-icon"
                    onClick={() => this.setState({ mode: "preview" })}
                    title="Preview"
                    icon="eye"
                    size="small"
                  />
                )}
                {mode === "preview" && (
                  <Button
                    className="transparent-button-icon"
                    onClick={() => this.setState({ mode: "edit" })}
                    title="Edit"
                    icon="edit"
                    size="small"
                  />
                )}
              </div>
              <div style={{ overflow: "auto", marginBottom: "20px" }}>
                {invalidJson && <Message color="red">Invalid Survey JSON</Message>}
                {!invalidJson && mode === "edit" && (
                  <SurveyFormBuilder
                    survey={_survey}
                    handleClearEditorPress={this.handleClearEditorPress}
                    handleAddSectionPress={this.handleAddSectionPress}
                    handleMoveSectionPress={this.handleMoveSectionPress}
                    handleDeleteSectionPress={this.handleDeleteSectionPress}
                    handleEditSectionPress={this.handleEditSectionPress}
                    handleEditHeaderPress={this.handleEditHeaderPress}
                    handleEditQuestionPress={this.handleEditQuestionPress}
                    handleAddQuestionPress={this.handleAddQuestionPress}
                    handleMoveQuestionPress={this.handleMoveQuestionPress}
                    handleDeleteQuestionPress={this.handleDeleteQuestionPress}
                    editor={this.state.editor}
                  />
                )}
                {!invalidJson && mode === "preview" && (
                  <Survey for="templates" survey={_survey} asModal={false} autoSave disableSave />
                )}
              </div>
            </div>
            <ResizePanel direction="w" style={{ width: "360px" }}>
              <div
                className={cx("sidebar", "withMargin", "right-panel")}
                style={{ overflow: "hidden", paddingRight: "0" }}
              >
                <div className="toolbar">&nbsp;</div>
                {this.state.editor.target === "header" && (
                  <SurveyHeaderEditor
                    prefix={this.state.prefix}
                    survey={this.state.survey}
                    handleHeaderKeyChnage={this.handleHeaderKeyChnage}
                    handlePrefixChnage={this.handlePrefixChnage}
                  />
                )}
                {this.state.editor.target === "section" && (
                  <SectionEditor section={this.state.editor.targetObject} update={this.updateSection} />
                )}
                {this.state.editor.target === "question" && (
                  <QuestionEditor
                    prefix={this.state.prefix}
                    survey={this.state.survey}
                    question={this.state.editor.targetObject}
                    update={this.updateQuestion}
                  />
                )}
              </div>
            </ResizePanel>
          </div>
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...modalActions
      },
      dispatch
    )
  };
}

export default connect(null, mapDispatchToProps)(AdvancedSurveyBuilder);
