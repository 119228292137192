import React, { Component } from "react";
import { Form, Menu } from "semantic-ui-react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { isEmpty, isEqual } from "lodash";
import AllStatusTable from "../visitingPatients/AllStatusTable";
import * as visitActions from "../../../actions/visitActions";
import * as badgeActions from "../../../actions/badgeActions";
import StatusWatchVisitingTable from "./StatusWatchVisitingTable";
import "./VisitingPatientsPage.css";

export class VisitingPatientsPage extends Component {
  state = {
    refetchCenterVisits: false,
    refetchStatusWatchCenterVisits: false
  };

  componentDidUpdate(prevProps) {
    const {
      allCenterVisits: { dataVisits: visits },
      visitingStatusSelected,
      centerVisitsMessage
    } = this.props;

    if (!isEmpty(centerVisitsMessage) && !isEqual(centerVisitsMessage, prevProps.centerVisitsMessage)) {
      const message = { ...centerVisitsMessage };
      const visit = { ...visits.find((v) => v.visitId === message.visitId) }; // visit before message
      this.setRefetchCenterVisits(true);
      if (
        !isEmpty(visitingStatusSelected) &&
        (visitingStatusSelected.includes(message.visitStatus) || visitingStatusSelected.includes(visit.visitStatusCode))
      ) {
        this.setRefetchStatusWatchCenterVisits(true);
      }
    }
  }

  handlerStatusChange = (e, data) => {
    this.props.actions.updateVisittingStatusSelectSuccess(data.value);
    this.props.actions.loadVisitingStatusBadge();
    this.updateStatusToServer(data.value);
  };

  updateStatusToServer(value) {
    this.props.actions.updateStatusWatchVisiting(value);
  }

  setRefetchCenterVisits = (value) => {
    this.setState({ refetchCenterVisits: value });
  };
  setRefetchStatusWatchCenterVisits = (value) => {
    this.setState({ refetchStatusWatchCenterVisits: value });
  };

  render() {
    const { statuses, visitingStatusSelected, centerContext } = this.props;
    const { refetchCenterVisits, refetchStatusWatchCenterVisits } = this.state;
    return (
      <React.Fragment>
        <div className="header-wrapper">
          <Menu borderless stackable>
            <Menu.Item>
              <h2>Visiting Patients</h2>
            </Menu.Item>
            {!isEmpty(centerContext) && (
              <Menu.Menu position="right">
                <Menu.Item>
                  <h4>Status Watch:</h4>
                </Menu.Item>
                <Menu.Item name="status-watch" className="status-watch-dropdown-width">
                  <Form.Dropdown
                    fluid
                    className="drop-status"
                    placeholder="Select a Status..."
                    multiple
                    selection
                    options={statuses}
                    onChange={this.handlerStatusChange}
                    value={visitingStatusSelected || []}
                    onClose={this.handlerCloseDropdown}
                    onOpen={this.handlerOpenDropdown}
                  />
                </Menu.Item>
              </Menu.Menu>
            )}
          </Menu>
        </div>

        {isEmpty(centerContext) ? (
          <div className="content-wrapper">
            <div className="no-visiting-placeholder">Please select a center to view visiting patients.</div>
          </div>
        ) : (
          <div className="content-wrapper">
            <StatusWatchVisitingTable
              refetch={refetchStatusWatchCenterVisits}
              resetRefetch={() => {
                this.setRefetchStatusWatchCenterVisits(false);
              }}
            />
            <AllStatusTable
              refetch={refetchCenterVisits}
              resetRefetch={() => {
                this.setRefetchCenterVisits(false);
              }}
            />
          </div>
        )}
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    statuses: state.lookups.visitStatuses.filter((item) => item.value !== "visitcomplete"),
    visitingStatusSelected: state.visits.visitingStatusSelected,
    centerContext: state.userPreference.centerContext,
    centerVisitsMessage: state.visits.centerVisitsMessage,
    allCenterVisits: state.visits.allCenterVisits
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...visitActions, ...badgeActions }, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(VisitingPatientsPage);
