import React, { Component } from "react";
import { Button, Form, Grid, Accordion, Table, Input, Card, Message, Label, Icon } from "semantic-ui-react";
import { DateInput } from "semantic-ui-calendar-react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { set, isEmpty } from "lodash";
import moment from "moment";
import { withRouter } from "react-router-dom";
import ProviderCenters from "./ProviderCenters";
import * as providerActions from "../../actions/providerActions";
import * as lookupActions from "../../actions/lookupActions";
import * as adminActions from "../../actions/adminActions";
import * as modalActions from "../../actions/modalActions";
import { roleNames } from "../../constants/securityRoles";
import { fetchProvider } from "../../api/provider";
import { fetchUsersByRoles } from "../../api/user";
import { checkRoles, getLookupText } from "../../helpers";
import { dateFormat, isoDateFormat, isoFormat } from "../../constants/miscellaneous";
import "./ManageUser.css";

const titleOptions = [
  {
    key: "to1",
    text: "MD",
    value: "MD"
  },
  {
    key: "to2",
    text: "NP",
    value: "NP"
  },
  {
    key: "LDAC 1",
    text: "LDAC 1",
    value: "LDAC 1"
  },
  {
    key: "LDAC 2",
    text: "LDAC 2",
    value: "LDAC 2"
  },
  {
    key: "to3",
    text: "PAC",
    value: "PAC"
  },
  {
    key: "CNS",
    text: "CNS",
    value: "CNS"
  },
  {
    key: "DO",
    text: "DO",
    value: "DO"
  },
  {
    key: "CC",
    text: "Care Coordinator",
    value: "Care Coordinator"
  },
  {
    key: "CRS",
    text: "Certified Recovery Specialist",
    value: "CRS"
  },
  {
    key: "LICSW",
    text: "Licensed Independent Clinical Social Worker",
    value: "LICSW"
  },
  {
    key: "LISAC",
    text: "Licensed Independent Substance Abuse Counselor",
    value: "LISAC"
  },
  {
    key: "LPC",
    text: "Licensed Professional Counselor",
    value: "LPC"
  },
  {
    key: "LCSW",
    text: "Licensed Clinical Social Worker",
    value: "LCSW"
  },
  {
    key: "LICDC",
    text: "Licensed Independent Chemical Dependency Counselor",
    value: "LICDC"
  },
  {
    key: "LMHC",
    text: "Licensed Mental Health Counselor",
    value: "LMHC"
  },
  {
    key: "LMFT",
    text: "Licensed Marriage and Family Therapist",
    value: "LMFT"
  },
  {
    key: "CAADC",
    text: "Certified Advanced Alcohol and Drug Counselor",
    value: "CAADC"
  }
];

export class ManageUser extends Component {
  state = {
    activeStateCode: "",
    isMedicalProvider: false,
    providerDetailsView: "view", // view | edit
    providerDetails: {
      homeCenterId: "",
      npi: "",
      xdeaNumber: "",
      title: "",
      maxSuboxonePt: 0,
      providerStateSettings: [],
      bonusCoverageSlots: 0,
      firstName: "",
      lastName: ""
    },
    errors: {},
    providerStateInEditMode: {},
    showNewProviderStateForm: false,
    newProviderState: {
      stateCode: "",
      stateMedicalLicenseNumber: "",
      deaNumber: "",
      isDefaultDeaNumber: false
    },
    supervisionCyclesInEditMode: {},
    showNewSupervisionCycleForm: false,
    newSupervisionCycle: {
      providerId: this.props.match.params.userId,
      stateCode: "",
      supervisorId: "",
      startDate: moment().format(isoDateFormat),
      endDate: "",
      mustSignInitAndRejoin: false,
      isPercentage: false,
      percentageOfRequiredCosignCharts: 0,
      numberOfRequiredCosignCharts: 0
    },
    providerStateView: "view",
    showProviderStateForm: false,
    providerStateForm: {},
    providerStateFormErrors: [],
    providerOptions: [],
    loading: false
  };

  constructor(props) {
    super(props);
    const {
      breadcrumb,
      actions: { pushItemToBreadcrumb },
      match: {
        params: { userId }
      }
    } = this.props;
    if (!breadcrumb.find((i) => i.key === "bc-manage-users")) {
      this.props.actions.pushItemToBreadcrumb({
        key: "bc-manage-users",
        to: "/admin/manage-users",
        text: "Manage Users"
      });
    }
    pushItemToBreadcrumb({
      key: "bc-manage-user",
      to: `/admin/manage-users/${userId}`,
      text: "Manage User"
    });
  }

  // setStateAsync = async updater => new Promise(resolve => this.setState(updater, resolve));

  componentDidMount() {
    const {
      actions: { loadStates, loadCentersLookup }
    } = this.props;

    loadStates();
    loadCentersLookup();
    this.load();
  }

  load = () => {
    const {
      match: {
        params: { userId }
      }
    } = this.props;
    this.setState({ loading: true });
    fetchProvider(userId).then((providerDetails) => {
      const { roles: userRoles } = providerDetails;
      const { ldac2, ldac1, provider, supProvider, careCoordinator } = roleNames;
      const isLdac2User = checkRoles([ldac2], userRoles);

      if (isLdac2User) {
        fetchUsersByRoles([ldac1]).then(({ users = [] } = {}) =>
          this.setState({
            providerOptions: this.providersFormattedDropdown(users)
          })
        );
      } else if (checkRoles([provider], userRoles)) {
        fetchUsersByRoles([supProvider]).then(({ users = [] } = {}) =>
          this.setState({
            providerOptions: this.providersFormattedDropdown(users)
          })
        );
      } else if (checkRoles([careCoordinator], userRoles)) {
        fetchUsersByRoles([ldac1]).then(({ users = [] } = {}) =>
          this.setState({
            providerOptions: this.providersFormattedDropdown(users)
          })
        );
      }

      this.setState({
        providerDetails: {
          ...providerDetails,
          providerStateSettings: providerDetails.providerStateSettings.map((pss) => ({
            ...pss,
            cyclesLoaded: false,
            supervisionCycles: []
          }))
        },
        isMedicalProvider: checkRoles([provider, supProvider], userRoles),
        loading: false,
        ...(isLdac2User && {
          isPercentage: true,
          providerStateSetting: { ...this.state.providerStateSetting, percentageOfRequiredCosignCharts: 100 }
        })
      });
    });
  };

  providersFormattedDropdown = (providers) => {
    const {
      match: {
        params: { userId }
      }
    } = this.props;
    return providers
      .filter((sp) => sp.userId !== userId)
      .map((provider) => ({
        value: provider.userId,
        text: `${provider.firstName} ${provider.lastName}`,
        firstname: provider.firstName,
        lastname: provider.lastName
      }));
  };

  handleFocus = (e) => {
    e.target.setAttribute("autocomplete", "nope");
  };

  handleInput = (e, data) => {
    const { name: field, value, checked, type } = data;
    const updatedState = { ...this.state };
    if (type === "checkbox") {
      set(updatedState, field, checked);
    } else {
      set(updatedState, field, type === "number" && value ? parseInt(value, 10) : value);
    }
    this.setState(updatedState);
  };

  resync = () => {
    const {
      match: {
        params: { userId: providerId }
      }
    } = this.props;
    this.setState({ loading: true });
    this.props.actions
      .syncUserRoles(providerId)
      .then(() => {
        this.load();
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  handleCycleInput = (cycleId, field, data) => {
    const { value, checked, type } = data;
    let updated = null;
    if (cycleId) {
      updated = {
        ...this.state.supervisionCyclesInEditMode[cycleId],
        [field]: type === "checkbox" ? checked : type === "number" && value ? parseInt(value, 10) : value
      };
    } else {
      updated = {
        ...this.state.newSupervisionCycle,
        [field]: type === "checkbox" ? checked : type === "number" && value ? parseInt(value, 10) : value
      };
    }

    if (field === "startDate" && !isEmpty(updated.startDate)) {
      updated.startDate = moment(updated.startDate, dateFormat).startOf("day").format(isoDateFormat);
    }
    if (field === "endDate" && !isEmpty(updated.endDate)) {
      updated.endDate = moment(updated.endDate, dateFormat).endOf("day").format(isoDateFormat);
    }

    if (cycleId) {
      this.setState({
        supervisionCyclesInEditMode: {
          ...this.state.supervisionCyclesInEditMode,
          [cycleId]: { ...updated, errors: this.validateSupervisionCycle(updated) }
        }
      });
    } else {
      this.setState({
        newSupervisionCycle: { ...updated, errors: this.validateSupervisionCycle(updated) }
      });
    }
  };

  handleProviderStateInput = (e, data, isNew) => {
    const { name: field, value, type, checked } = data;
    if (isNew) {
      const updated = { ...this.state.newProviderState, [field]: type === "checkbox" ? checked : value };
      this.setState({
        newProviderState: { ...updated, errors: this.validateProviderState(updated) }
      });
    } else {
      const updated = {
        ...this.state.providerStateInEditMode,
        [field]: type === "checkbox" ? checked : value
      };
      this.setState({
        providerStateInEditMode: { ...updated, errors: this.validateProviderState(updated) }
      });
    }
  };

  handleChartDropdown = (e, data, cycleId) => {
    const isPercentage = data.value === "Percent";
    if (cycleId)
      this.setState({
        supervisionCyclesInEditMode: {
          ...this.state.supervisionCyclesInEditMode,
          [cycleId]: {
            ...this.state.supervisionCyclesInEditMode[cycleId],
            isPercentage,
            numberOfRequiredCosignCharts: isPercentage
              ? 0
              : this.state.newSupervisionCycle.numberOfRequiredCosignCharts,
            percentageOfRequiredCosignCharts: isPercentage
              ? this.state.newSupervisionCycle.percentageOfRequiredCosignCharts
              : 0
          }
        }
      });
    else
      this.setState({
        newSupervisionCycle: {
          ...this.state.newSupervisionCycle,
          isPercentage,
          numberOfRequiredCosignCharts: isPercentage ? 0 : this.state.newSupervisionCycle.numberOfRequiredCosignCharts,
          percentageOfRequiredCosignCharts: isPercentage
            ? this.state.newSupervisionCycle.percentageOfRequiredCosignCharts
            : 0
        }
      });
  };

  handleSaveProviderDetails = () => {
    const {
      authRoles,
      actions: { updateProvider, updateProviderBonusSlots },
      match: {
        params: { userId: providerId }
      }
    } = this.props;

    const {
      providerDetails,
      providerDetails: { bonusCoverageSlots }
    } = this.state;

    const errors = this.validateProviderDetails();
    if (!isEmpty(errors)) {
      this.setState({ providerDetails: { ...this.state.providerDetails, errors } });
      return;
    }

    const isCenterManager = !authRoles.includes(roleNames.systemAdmin) && authRoles.includes(roleNames.centerManager);

    this.setState({ loading: true });

    (isCenterManager
      ? updateProviderBonusSlots(providerId, bonusCoverageSlots)
      : updateProvider(providerId, providerDetails)
    )
      .then(() => {
        this.setState({ providerDetailsView: "view" });
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  setDefaultDea = (stateCode) => {
    const {
      actions: { updateProvider },
      match: {
        params: { userId: providerId }
      }
    } = this.props;
    const {
      providerDetails,
      providerDetails: { providerStateSettings }
    } = this.state;
    const updatedProviderStateSettings = providerStateSettings.map((s) => {
      if (s.stateCode !== stateCode) return { ...s, isDefaultDeaNumber: false };
      return { ...s, isDefaultDeaNumber: true };
    });
    const updatedProviderDetails = { ...providerDetails, providerStateSettings: updatedProviderStateSettings };
    this.setState({ loading: true });
    updateProvider(providerId, updatedProviderDetails)
      .then(() => {
        this.setState({ providerDetails: updatedProviderDetails, providerDetailsView: "view" });
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  handleDeleteState = (stateCode) => {
    const { stateOptions } = this.props;
    this.props.actions.showModal({
      type: "CONFIRMATION",
      props: {
        open: true,
        icon: "check",
        title: `Confirm deleting ${getLookupText(stateCode, stateOptions)}`,
        description: `Are you sure you want to delete ${getLookupText(stateCode, stateOptions)}?`,
        buttonMessage: "Delete",
        buttonColor: "red",
        size: "mini",
        onConfirm: () => {
          const {
            actions: { updateProvider },
            match: {
              params: { userId: providerId }
            }
          } = this.props;
          const {
            providerDetails,
            providerDetails: { providerStateSettings }
          } = this.state;
          const index = providerStateSettings.findIndex((s) => s.stateCode === stateCode);
          const updatedProviderStateSettings = [...providerStateSettings];
          updatedProviderStateSettings.splice(index, 1);
          const updatedProviderDetails = { ...providerDetails, providerStateSettings: updatedProviderStateSettings };
          this.setState({ loading: true });
          updateProvider(providerId, updatedProviderDetails)
            .then(() => {
              this.setState({ providerDetails: updatedProviderDetails, providerDetailsView: "view" });
            })
            .finally(() => {
              this.setState({ loading: false });
            });
        }
      }
    });
  };

  validateProviderDetails = () => {
    const { isMedicalProvider, providerDetails } = this.state;
    const errors = [];

    if (isMedicalProvider && isEmpty(providerDetails.npi)) {
      errors.push("Please fill in NPI");
    }

    if (isEmpty(providerDetails.title)) {
      errors.push("Please fill in title");
    }

    return errors;
  };

  loadStateCycles = (providerId, stateCode) => {
    this.props.actions.searchSupervisionCycle({ providerId, stateCode }).then((response) => {
      const selectedState = {
        ...this.state.providerDetails.providerStateSettings.find((s) => s.stateCode === stateCode),
        supervisionCycles: response.cycles.map((cycle) => ({
          ...cycle,
          isPercentage: cycle.percentageOfRequiredCosignCharts > 0,
          percentageOfRequiredCosignCharts: cycle.percentageOfRequiredCosignCharts * 100
        })),
        cyclesLoaded: true
      };
      this.setState({
        providerDetails: {
          ...this.state.providerDetails,
          providerStateSettings: this.state.providerDetails.providerStateSettings.map((_state) => {
            if (_state.stateCode !== stateCode) return { ..._state };
            return selectedState;
          })
        }
      });
    });
  };

  calculateChartsToSign = (percentageOfRequiredCosignCharts, numberOfRequiredCosignCharts) => {
    if (percentageOfRequiredCosignCharts) {
      return `${parseFloat(percentageOfRequiredCosignCharts).toFixed(2)} %`;
    }
    return numberOfRequiredCosignCharts;
  };

  handleExpandState = (titleProps, stateCode) => {
    const {
      match: {
        params: { userId: providerId }
      }
    } = this.props;

    const { providerDetails } = this.state;
    const state = providerDetails.providerStateSettings.find((s) => s.stateCode === stateCode);
    this.setState({ activeStateCode: titleProps.active ? null : stateCode, providerStateInEditMode: {} }, () => {
      if (!state.cyclesLoaded) {
        this.loadStateCycles(providerId, stateCode);
      }
    });
  };

  validateProviderState = (state) => {
    const { isMedicalProvider, stateCode, deaNumber } = state;
    const errors = [];
    if (isEmpty(stateCode)) {
      errors.push("Please pick a state");
    }
    // it is not required .. as some states does not have it
    // if (isEmpty(stateMedicalLicenseNumber)) {
    //   errors.push("Please fill in your state medical license number");
    // }
    if (isMedicalProvider && isEmpty(deaNumber)) {
      errors.push("Please fill in your DEA number");
    }
    return errors;
  };

  validateSupervisionCycle = (cycle) => {
    const { startDate, supervisorId, numberOfRequiredCosignCharts, percentageOfRequiredCosignCharts } = cycle;
    const errors = [];
    if (isEmpty(startDate)) {
      errors.push("Please pick start date");
    }
    if (isEmpty(supervisorId)) {
      errors.push("Please pick a supervisor");
    }
    if (
      isNaN(numberOfRequiredCosignCharts) ||
      !Number.isInteger(+numberOfRequiredCosignCharts) ||
      +numberOfRequiredCosignCharts < 0
    ) {
      errors.push("Number must be positive integer");
    }

    if (
      isNaN(percentageOfRequiredCosignCharts) ||
      +percentageOfRequiredCosignCharts < 0 ||
      percentageOfRequiredCosignCharts > 100
    ) {
      errors.push("Percentage must be a positive number between 0 and 100");
    }
    return errors;
  };

  handleSaveProviderState = (isNew) => {
    const {
      authRoles,
      actions: { updateProvider, updateProviderBonusSlots },
      match: {
        params: { userId: providerId }
      }
    } = this.props;

    const isCenterManager = !authRoles.includes(roleNames.systemAdmin) && authRoles.includes(roleNames.centerManager);

    const {
      providerDetails,
      providerDetails: { providerStateSettings },
      newProviderState,
      providerStateInEditMode
    } = this.state;

    const errors = this.validateProviderState(isNew ? newProviderState : providerStateInEditMode);

    if (!isEmpty(errors)) {
      if (isNew) this.setState({ newProviderState: { ...newProviderState, errors } });
      else this.setState({ providerStateInEditMode: { ...providerStateInEditMode, errors } });
    } else {
      const updatedProviderStateSettings = isNew
        ? [...providerStateSettings, { ...newProviderState, supervisionCycles: [], cyclesLoaded: true }]
        : providerStateSettings.map((s) => {
            if (s.stateCode !== providerStateInEditMode.stateCode) return { ...s };
            return { ...providerStateInEditMode };
          });

      const updatedProviderDetails = { ...providerDetails, providerStateSettings: updatedProviderStateSettings };

      this.setState({ loading: true });

      (isCenterManager
        ? updateProviderBonusSlots(providerId, updatedProviderDetails.bonusCoverageSlots)
        : updateProvider(providerId, updatedProviderDetails)
      )
        .then(() => {
          this.setState({
            providerDetails: updatedProviderDetails,
            providerStateInEditMode: {},
            showNewProviderStateForm: false,
            newProviderState: { stateCode: "", stateMedicalLicenseNumber: "", deaNumber: "", isDefaultDeaNumber: false }
          });
        })
        .finally(() => {
          this.setState({ loading: false });
        });
    }
  };

  handleSaveSupervisionCycle = (cycleId) => {
    const { supervisionCyclesInEditMode, newSupervisionCycle } = this.state;
    const cycle = cycleId ? supervisionCyclesInEditMode[cycleId] : newSupervisionCycle;
    const errors = this.validateSupervisionCycle(cycle);
    if (!isEmpty(errors)) {
      if (cycleId)
        this.setState({
          supervisionCyclesInEditMode: {
            ...this.state.supervisionCyclesInEditMode,
            [cycleId]: {
              ...this.state.supervisionCyclesInEditMode[cycleId],
              errors
            }
          }
        });
      else
        this.setState({
          newSupervisionCycle: {
            ...this.state.newSupervisionCycle,
            errors
          }
        });
    } else {
      const {
        match: {
          params: { userId }
        },
        actions: { updateSupervisionCycle, saveNewSupervisionCycle }
      } = this.props;

      const model = {
        ...cycle,
        startDate: isEmpty(cycle.startDate) ? `` : moment(cycle.startDate).startOf("day").format(isoFormat),
        endDate: isEmpty(cycle.endDate) ? `` : moment(cycle.endDate).endOf("day").format(isoFormat),
        percentageOfRequiredCosignCharts:
          cycle.percentageOfRequiredCosignCharts > 1
            ? cycle.percentageOfRequiredCosignCharts / 100
            : cycle.percentageOfRequiredCosignCharts
      };

      this.setState({ loading: true });
      (cycleId ? updateSupervisionCycle(cycleId, model) : saveNewSupervisionCycle(userId, model))
        .then(() => {
          const updatedSupervisionCyclesInEditMode = { ...this.state.supervisionCyclesInEditMode };
          delete updatedSupervisionCyclesInEditMode[cycleId];
          this.setState({
            showNewSupervisionCycleForm: false,
            supervisionCyclesInEditMode: {},
            newSupervisionCycle: {
              providerId: this.props.match.params.userId,
              stateCode: "",
              supervisorId: "",
              startDate: moment().format(isoDateFormat),
              endDate: "",
              mustSignInitAndRejoin: false,
              isPercentage: false,
              percentageOfRequiredCosignCharts: 0,
              numberOfRequiredCosignCharts: 0
            },
            providerDetails: {
              ...this.state.providerDetails,
              providerStateSettings: this.state.providerDetails.providerStateSettings.map((state) => {
                if (state.stateCode !== cycle.stateCode) return { ...state };
                return { ...state, cyclesLoaded: false };
              })
            }
          });
          this.loadStateCycles(cycle.providerId, cycle.stateCode);
        })
        .catch(() => {})
        .finally(() => {
          this.setState({ loading: false });
        });
    }
  };

  renderProviderDetails = () => {
    const { authRoles, centerOptions } = this.props;
    const {
      providerDetailsView,
      providerDetails,
      providerDetails: { title, npi, maxSuboxonePt, bonusCoverageSlots, xdeaNumber, homeCenterId },
      isMedicalProvider,
      errors,
      loading
    } = this.state;

    const isCenterManager = !authRoles.includes(roleNames.systemAdmin) && authRoles.includes(roleNames.centerManager);

    return (
      <Grid>
        <Grid.Row>
          <Grid.Column>
            {!isEmpty(providerDetails.errors) && (
              <Message color="red" style={{ backgroundColor: "#ffe8e6" }}>
                {providerDetails.errors.map((e) => (
                  <React.Fragment key={e}>
                    {e}
                    <br />
                  </React.Fragment>
                ))}
              </Message>
            )}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={4}>
            <h4>Credentials</h4> {providerDetailsView === "view" && title}
            {providerDetailsView === "edit" && (
              <Form.Dropdown
                name="providerDetails.title"
                value={providerDetails.title}
                selection
                placeholder="Select..."
                options={titleOptions}
                search
                required
                onChange={this.handleInput}
                onFocus={this.handleFocus}
                id="input-title"
                disabled={isCenterManager}
              />
            )}
          </Grid.Column>
          {providerDetailsView === "view" && (
            <Grid.Column width={4}>
              <h4>Home Center</h4> {providerDetailsView === "view" && getLookupText(homeCenterId, centerOptions)}
            </Grid.Column>
          )}
          {providerDetailsView === "edit" && <Grid.Column width={4} />}
          <Grid.Column width={4} />
          <Grid.Column width={4}>
            {providerDetailsView === "edit" && (
              <React.Fragment>
                <Button
                  color="blue"
                  size="mini"
                  floated="right"
                  content="Save"
                  loading={loading}
                  disabled={loading || !isEmpty(errors)}
                  onClick={this.handleSaveProviderDetails}
                />
                <Button
                  color="red"
                  size="mini"
                  floated="right"
                  content="Cancel"
                  onClick={() => {
                    // do we need to clear out the form model? maybe no
                    this.setState({ providerDetailsView: "view" });
                  }}
                />
              </React.Fragment>
            )}
          </Grid.Column>
        </Grid.Row>
        {isMedicalProvider && (
          <Grid.Row>
            <Grid.Column width={4}>
              <h4>NPI</h4>
              {providerDetailsView === "view" && npi}
              {providerDetailsView === "edit" && (
                <Form.Input
                  name="providerDetails.npi"
                  required
                  value={providerDetails.npi || ""}
                  onChange={this.handleInput}
                  id="input-npi"
                  readOnly={isCenterManager}
                />
              )}
            </Grid.Column>
            <Grid.Column width={4}>
              <h4>Max buprenorphine pt.</h4> {providerDetailsView === "view" && maxSuboxonePt}
              {providerDetailsView === "edit" && (
                <Form.Input
                  name="providerDetails.maxSuboxonePt"
                  onChange={this.handleInput}
                  value={providerDetails.maxSuboxonePt || ""}
                  id="input-maxSuboxonePt"
                  type="number"
                  readOnly={isCenterManager}
                />
              )}
            </Grid.Column>
            <Grid.Column width={4}>
              <h4>Bonus Coverage Slots</h4> {providerDetailsView === "view" && bonusCoverageSlots}
              {providerDetailsView === "edit" && (
                <Form.Input
                  name="providerDetails.bonusCoverageSlots"
                  onChange={this.handleInput}
                  value={providerDetails.bonusCoverageSlots || ""}
                  id="input-bonusCoverageSlots"
                  type="number"
                />
              )}
            </Grid.Column>
            <Grid.Column width={4}>
              <h4>XDEA#</h4> {providerDetailsView === "view" && xdeaNumber}
              {providerDetailsView === "edit" && (
                <Form.Input
                  name="providerDetails.xdeaNumber"
                  onChange={this.handleInput}
                  value={providerDetails.xdeaNumber || ""}
                  id="input-xDeaNumber"
                  readOnly={isCenterManager}
                />
              )}
            </Grid.Column>
          </Grid.Row>
        )}
      </Grid>
    );
  };

  renderProviderStateView = (model) => {
    const { stateOptions } = this.props;
    const { isMedicalProvider } = this.state;

    return !model ? null : (
      <Grid>
        <Grid.Row>
          <Grid.Column width={3}>
            <h4>State</h4> {getLookupText(model.stateCode, stateOptions)}
          </Grid.Column>
          <Grid.Column width={3}>
            {isMedicalProvider && (
              <React.Fragment>
                <h4>State License #</h4> {model.stateMedicalLicenseNumber}
              </React.Fragment>
            )}
          </Grid.Column>
          <Grid.Column width={3}>
            {isMedicalProvider && (
              <React.Fragment>
                <h4>DEA#</h4> {model.deaNumber}
              </React.Fragment>
            )}
          </Grid.Column>
          <Grid.Column width={3}>
            {isMedicalProvider && (
              <React.Fragment>
                {model.isDefaultDeaNumber ? (
                  <Label color="green" circular size="mini">
                    Default DEA#
                  </Label>
                ) : null}
              </React.Fragment>
            )}
          </Grid.Column>
          <Grid.Column width={4} />
        </Grid.Row>
      </Grid>
    );
  };

  renderProviderStateForm = (model, isNew = false) => {
    const { stateOptions } = this.props;
    const {
      providerDetails: { providerStateSettings },
      isMedicalProvider,
      loading
    } = this.state;
    return (
      <Grid>
        <Grid.Row>
          <Grid.Column>
            {!isEmpty(model.errors) && (
              <Message color="red" style={{ backgroundColor: "#ffe8e6" }}>
                {model.errors.map((e) => (
                  <React.Fragment key={e}>
                    {e}
                    <br />
                  </React.Fragment>
                ))}
              </Message>
            )}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={3}>
            <h4>State</h4>
            <Form.Dropdown
              name="stateCode"
              placeholder="Select..."
              selection
              options={
                isNew
                  ? stateOptions.filter((s) => !providerStateSettings.map((p) => p.stateCode).includes(s.value))
                  : stateOptions
              }
              search
              required
              disabled={!isNew}
              onChange={(e, data) => {
                this.handleProviderStateInput(e, data, isNew);
              }}
              value={model.stateCode}
              onFocus={this.handleFocus}
              fluid
            />
          </Grid.Column>
          <Grid.Column width={3}>
            {isMedicalProvider && (
              <React.Fragment>
                <h4>State License #</h4>
                <Form.Input
                  name="stateMedicalLicenseNumber"
                  onChange={(e, data) => {
                    this.handleProviderStateInput(e, data, isNew);
                  }}
                  value={model.stateMedicalLicenseNumber}
                  maxLength={50}
                  fluid
                />
              </React.Fragment>
            )}
          </Grid.Column>
          <Grid.Column width={3}>
            {isMedicalProvider && (
              <React.Fragment>
                <h4>DEA#</h4>
                <Form.Input
                  name="deaNumber"
                  onChange={(e, data) => {
                    this.handleProviderStateInput(e, data, isNew);
                  }}
                  value={model.deaNumber}
                  maxLength={50}
                  fluid
                />
              </React.Fragment>
            )}
          </Grid.Column>
          <Grid.Column width={3} />
          <Grid.Column width={4}>
            <Button
              color="blue"
              size="mini"
              floated="right"
              content="Save"
              loading={loading}
              disabled={loading || !isEmpty(model.errors)}
              onClick={() => {
                this.handleSaveProviderState(isNew);
              }}
            />
            <Button
              color="red"
              size="mini"
              floated="right"
              content="Cancel"
              onClick={() => {
                // do we need to clear out the form model? maybe no
                if (isNew) this.setState({ showNewProviderStateForm: false });
                else this.setState({ providerStateInEditMode: {} });
              }}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  };

  renderProviderState = (stateCode) => {
    const {
      providerDetails: { providerStateSettings },
      providerStateInEditMode
    } = this.state;
    const viewMode = isEmpty(providerStateInEditMode) || providerStateInEditMode.stateCode !== stateCode;
    return viewMode
      ? this.renderProviderStateView(providerStateSettings.find((s) => s.stateCode === stateCode))
      : this.renderProviderStateForm(providerStateInEditMode);
  };

  renderSupervisingProvidersTable = ({ supervisionCycles }) => {
    const { processing } = this.props;
    const { supervisionCyclesInEditMode, loading } = this.state;
    return (
      <Table color="green">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={3}>Supervising Provider</Table.HeaderCell>
            <Table.HeaderCell width={3}>Must Sign Initial And Rejoin</Table.HeaderCell>
            <Table.HeaderCell width={2}>Charts to Sign (Amount)</Table.HeaderCell>
            <Table.HeaderCell width={4}>Start Date</Table.HeaderCell>
            <Table.HeaderCell width={4}>End Date</Table.HeaderCell>
            <Table.HeaderCell />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {supervisionCycles.map((cycle) => {
            const {
              cycleId,
              supervisorFirstName,
              supervisorLastName,
              mustSignInitAndRejoin,
              percentageOfRequiredCosignCharts,
              numberOfRequiredCosignCharts,
              startDate,
              endDate
            } = cycle;
            return (
              <Table.Row key={cycleId}>
                {!Object.keys(supervisionCyclesInEditMode).includes(cycleId) && (
                  <React.Fragment>
                    <Table.Cell>{`${supervisorFirstName} ${supervisorLastName}`}</Table.Cell>

                    <Table.Cell>{mustSignInitAndRejoin ? "Yes" : "No"}</Table.Cell>
                    <Table.Cell>
                      {this.calculateChartsToSign(percentageOfRequiredCosignCharts, numberOfRequiredCosignCharts)}
                    </Table.Cell>
                    <Table.Cell>{moment(startDate).format(dateFormat)}</Table.Cell>
                    <Table.Cell>{endDate ? moment(endDate).format(dateFormat) : null}</Table.Cell>
                    <Table.Cell textAlign="center">
                      <Button
                        icon="edit"
                        size="mini"
                        color="orange"
                        disabled={loading} // || !isEmpty(endDate)}
                        loading={loading || processing}
                        onClick={() => {
                          if (!Object.keys(supervisionCyclesInEditMode).includes(cycleId))
                            this.setState({
                              supervisionCyclesInEditMode: {
                                ...this.state.supervisionCyclesInEditMode,
                                [cycleId]: { ...cycle }
                              }
                            });
                        }}
                      />
                      {/* <Button
                    icon="trash alternate outline"
                    size="mini"
                    // disabled={disableSave || loading || processing}
                    loading={loading || processing}
                    onClick={() => {
                      this.handleDelete(cycleId);
                    }}
                    negative
                  /> */}
                    </Table.Cell>
                  </React.Fragment>
                )}
                {Object.keys(supervisionCyclesInEditMode).includes(cycleId) && (
                  <React.Fragment>
                    <Table.Cell colSpan={5}>{this.renderSupervisionCycleForm(cycleId)}</Table.Cell>
                  </React.Fragment>
                )}
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    );
  };

  renderSupervisionCycleForm = (cycleId) => {
    const { loading, providerOptions, supervisionCyclesInEditMode, newSupervisionCycle } = this.state;
    const cycle = cycleId ? supervisionCyclesInEditMode[cycleId] : newSupervisionCycle;
    const disableMustSign = cycle.supervisorId === null;

    return (
      <Form>
        <Grid className="no-padding">
          <Grid.Row>
            <Grid.Column>
              {!isEmpty(cycle.errors) && (
                <Message color="red" style={{ backgroundColor: "#ffe8e6" }}>
                  {cycle.errors.map((e) => (
                    <React.Fragment key={e}>
                      {e}
                      <br />
                    </React.Fragment>
                  ))}
                </Message>
              )}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row className="no-padding">
            <Grid.Column>
              <Form.Group>
                <Form.Field width={4}>
                  <label>Start Date</label>
                  <DateInput
                    id="input-cycleStartDate"
                    name="cycle.startDate"
                    placeholder="Start Date"
                    value={isEmpty(cycle.startDate) ? `` : moment(cycle.startDate).format(dateFormat)}
                    dateFormat={dateFormat}
                    iconPosition="right"
                    onChange={(e, data) => {
                      this.handleCycleInput(cycleId, "startDate", data);
                    }}
                    hideMobileKeyboard
                    closable
                  />
                </Form.Field>
                <Form.Field width={4}>
                  <label>End Date</label>
                  <DateInput
                    id="input-cycleEndtDate"
                    name="cycle.endDate"
                    placeholder="End Date"
                    value={isEmpty(cycle.endDate) ? `` : moment(cycle.endDate).format(dateFormat)}
                    dateFormat={dateFormat}
                    iconPosition="right"
                    onChange={(e, data) => {
                      this.handleCycleInput(cycleId, "endDate", data);
                    }}
                    hideMobileKeyboard
                    clearable
                    closable
                  />
                </Form.Field>
              </Form.Group>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row className="no-padding">
            <Grid.Column>
              <Form.Group>
                <Form.Field width={6}>
                  <label>Supervising provider***</label>
                  <Form.Dropdown
                    name="cycle.supervisorId"
                    fluid
                    selection
                    required
                    placeholder="Select..."
                    options={providerOptions}
                    search
                    onChange={(e, data) => {
                      this.handleCycleInput(cycleId, "supervisorId", data);
                    }}
                    disabled={!isEmpty(cycleId)}
                    value={cycle.supervisorId}
                    onFocus={this.handleFocus}
                    id="input-supervisingProvider"
                  />
                </Form.Field>
                <React.Fragment>
                  <Form.Field width={4} style={{ paddingTop: ".75em" }}>
                    <label>&nbsp;</label>
                    <Form.Checkbox
                      label="Must Sign Initial/Rejoin"
                      name="cycle.mustSignInitAndRejoin"
                      onChange={(e, data) => {
                        this.handleCycleInput(cycleId, "mustSignInitAndRejoin", data);
                      }}
                      checked={cycle.mustSignInitAndRejoin}
                      id="input-mustSignInitAndRejoin"
                      disabled={disableMustSign}
                    />
                  </Form.Field>
                  <Form.Field width={3}>
                    <label>Amount of charts to sign</label>
                    <Form.Dropdown
                      placeholder="Select..."
                      fluid
                      selection
                      options={[
                        {
                          text: "Percent",
                          value: "Percent"
                        },
                        {
                          text: "Number",
                          value: "Number"
                        }
                      ]}
                      search
                      onChange={(e, data) => {
                        this.handleChartDropdown(e, data, cycleId);
                      }}
                      value={cycle.isPercentage ? "Percent" : "Number"}
                      onFocus={this.handleFocus}
                      id="input-amtOfCharts"
                      disabled={disableMustSign}
                    />
                  </Form.Field>
                  <Form.Field width={3}>
                    <label>&nbsp;</label>
                    <Input
                      type="number"
                      min="0"
                      onChange={(e, data) => {
                        this.handleCycleInput(
                          cycleId,
                          cycle.isPercentage ? "percentageOfRequiredCosignCharts" : "numberOfRequiredCosignCharts",
                          data
                        );
                      }}
                      value={
                        cycle.isPercentage ? cycle.percentageOfRequiredCosignCharts : cycle.numberOfRequiredCosignCharts
                      }
                      id="input-numberCharts"
                      labelPosition={cycle.isPercentage ? "right" : null}
                      label={cycle.isPercentage ? { basic: true, content: "%" } : null}
                      disabled={disableMustSign}
                    />
                  </Form.Field>
                </React.Fragment>
              </Form.Group>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row className="no-padding">
            <Grid.Column>
              <Button
                color="green"
                content="Save"
                floated="right"
                size="mini"
                loading={loading}
                disabled={loading || !isEmpty(cycle.errors)}
                onClick={() => {
                  this.handleSaveSupervisionCycle(cycleId);
                }}
              />
              <Button
                color="red"
                size="mini"
                floated="right"
                content="Cancel"
                onClick={() => {
                  if (cycleId) {
                    const updatedSupervisionCyclesInEditMode = { ...supervisionCyclesInEditMode };
                    delete updatedSupervisionCyclesInEditMode[cycleId];
                    this.setState({ supervisionCyclesInEditMode: updatedSupervisionCyclesInEditMode });
                  } else {
                    // do we need to clear out the form model? maybe no
                    this.setState({
                      showNewSupervisionCycleForm: false,
                      supervisionCyclesInEditMode: {},
                      newSupervisionCycle: {
                        providerId: this.props.match.params.userId,
                        stateCode: "",
                        supervisorId: "",
                        startDate: moment().format(isoDateFormat),
                        endDate: "",
                        mustSignInitAndRejoin: false,
                        isPercentage: false,
                        percentageOfRequiredCosignCharts: 0,
                        numberOfRequiredCosignCharts: 0
                      }
                    });
                  }
                }}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <div className="footnote">
          <p>
            *** Required for Physician Assistants and Nurse Practitioners, LDAC2, & any user where co-signing is
            required
          </p>
        </div>
      </Form>
    );
  };

  renderProviderStates = () => {
    const { authRoles, stateOptions } = this.props;
    const {
      isMedicalProvider,
      activeStateCode,
      providerDetails: { providerStateSettings },
      showNewSupervisionCycleForm,
      newSupervisionCycle,
      providerStateInEditMode,
      showNewProviderStateForm,
      newProviderState,
      loading
    } = this.state;

    const isCenterManager = !authRoles.includes(roleNames.systemAdmin) && authRoles.includes(roleNames.centerManager);

    return (
      <Grid>
        <Grid.Row>
          <Grid.Column width={16}>
            <Accordion styled fluid style={{ marginBottom: ".5em" }}>
              {providerStateSettings.map((state, index) => (
                <React.Fragment key={state.stateCode}>
                  <Accordion.Title
                    active={activeStateCode === state.stateCode}
                    index={index}
                    onClick={(e, titleProps) => this.handleExpandState(titleProps, state.stateCode)}
                    style={{ color: "#2185d0" }}
                  >
                    <span style={{ display: "inline-block", width: "300px" }}>
                      {isEmpty(stateOptions) ? `` : stateOptions.find((s) => s.value === state.stateCode).text}, DEA#:{" "}
                      {state.deaNumber}
                      {state.isDefaultDeaNumber ? (
                        <Label color="green" circular size="mini" style={{ margin: "0 2em" }}>
                          Default DEA#
                        </Label>
                      ) : null}
                    </span>
                    <Icon
                      name={`angle ${activeStateCode === state.stateCode ? `up` : `down`}`}
                      style={{ float: "right", marginLeft: "1em" }}
                    />
                    {isMedicalProvider && (
                      <Form.Radio
                        inline
                        name="radio-default-dea-number"
                        label="Default DEA"
                        style={{ marginLeft: "1em" }}
                        disabled={loading}
                        onChange={(e) => {
                          e.stopPropagation();
                          this.setDefaultDea(state.stateCode);
                        }}
                        checked={state.isDefaultDeaNumber}
                      />
                    )}
                    <Button
                      color="red"
                      size="mini"
                      content="Delete"
                      floated="right"
                      className="half-padding"
                      disabled={loading || isCenterManager}
                      onClick={(e) => {
                        e.stopPropagation();
                        this.handleDeleteState(state.stateCode);
                      }}
                    />
                  </Accordion.Title>
                  <Accordion.Content active={activeStateCode === state.stateCode}>
                    <Card fluid color="blue">
                      <Card.Content>
                        <Card.Header>
                          State Details
                          {isEmpty(providerStateInEditMode) && (
                            <Button
                              color="orange"
                              size="mini"
                              content="Edit"
                              floated="right"
                              disabled={loading}
                              onClick={() => {
                                this.setState({ providerStateInEditMode: { ...state } });
                              }}
                            />
                          )}
                        </Card.Header>
                      </Card.Content>
                      <Card.Content>{this.renderProviderState(state.stateCode)}</Card.Content>
                    </Card>
                    <Grid>
                      <Grid.Row>
                        <Grid.Column computer={12} tablet={16} mobile={16}>
                          <h4 style={{ color: "#21ba45" }}>Supervising Providers</h4>
                          {this.renderSupervisingProvidersTable(state)}
                          {!showNewSupervisionCycleForm && (
                            <Button
                              color="green"
                              icon="plus"
                              size="mini"
                              content="Add Supervising Provider"
                              fluid
                              disabled={state.supervisionCycles.some((i) => i.endDate === null)} // unifi names
                              onClick={() => {
                                this.setState({
                                  showNewSupervisionCycleForm: true,
                                  newSupervisionCycle: { ...newSupervisionCycle, stateCode: activeStateCode }
                                });
                              }}
                            />
                          )}
                          {showNewSupervisionCycleForm && (
                            <Card fluid>
                              <Card.Content>
                                <Card.Header>New Supervising Provider</Card.Header>
                                <Card.Description>{this.renderSupervisionCycleForm(null)}</Card.Description>
                              </Card.Content>
                            </Card>
                          )}
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Accordion.Content>
                </React.Fragment>
              ))}
            </Accordion>
            {!showNewProviderStateForm && (
              <Button
                color="blue"
                icon="plus"
                size="mini"
                content="Add State"
                fluid
                onClick={() => {
                  this.setState({ showNewProviderStateForm: true, activeStateCode: "", providerStateInEditMode: {} });
                }}
              />
            )}
            {showNewProviderStateForm && (
              <Card fluid>
                <Card.Content>
                  <Card.Header>New Active State</Card.Header>
                  <Card.Description>{this.renderProviderStateForm(newProviderState, true)}</Card.Description>
                </Card.Content>
              </Card>
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  };

  renderProviderCenters = () => {
    const { providerDetails } = this.state;
    return isEmpty(providerDetails) ? null : null;
  };

  render() {
    const {
      match: {
        params: { userId }
      }
    } = this.props;
    const {
      providerDetailsView,
      providerDetails: { firstName, lastName, providerStateSettings },
      loading
    } = this.state;

    return (
      <div className="manage-user-container">
        <div className="header-wrapper">
          <h2
            style={{ display: "inline-block", backgroundColor: "transparent" }}
          >{`User Details: ${firstName} ${lastName}`}</h2>
          <Button
            size="mini"
            color="teal"
            floated="right"
            icon="sync"
            content="Re-sync"
            className="full-margin"
            loading={loading}
            disabled={loading}
            onClick={this.resync}
          />
        </div>
        <div className="content-wrapper">
          <Card fluid>
            <Card.Content>
              <Card.Header>
                Provider Details
                {providerDetailsView === "view" && (
                  <Button
                    color="orange"
                    size="mini"
                    content="Edit"
                    floated="right"
                    disabled={loading}
                    onClick={() => {
                      this.setState({ providerDetailsView: "edit" });
                    }}
                  />
                )}
              </Card.Header>
            </Card.Content>
            <Card.Content>{this.renderProviderDetails()}</Card.Content>
          </Card>
          <h4 style={{ color: "#2185d0" }}>Provider Active States</h4>
          {this.renderProviderStates()}
          <br />
          <h4 style={{ color: "#21ba45" }}>Provider Active Centers</h4>
          <ProviderCenters userId={userId} states={providerStateSettings} />
          <br />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    processing: state.ajaxCallsInProgress > 0,
    breadcrumb: state.admin.breadcrumb,
    authRoles: state.auth.user.profile.roles,
    centerOptions: state.lookups.centers.map((i) => ({ text: i.text, value: i.value })),
    stateOptions: state.lookups.states
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...providerActions,
        ...lookupActions,
        ...adminActions,
        ...modalActions
      },
      dispatch
    )
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ManageUser));
