import React, { Component } from "react";
import moment from "moment";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { isoDateFormat } from "../../constants/miscellaneous";
import NoteSlashTaskList from "../common/tasks/NoteSlashTaskList";
import * as lookupActions from "../../actions/lookupActions";
import * as noteSlashTasksActions from "../../actions/noteSlashTasksActions";

export class TasksDashboard extends Component {
  componentDidMount() {
    this.props.actions.loadRoleGroups();
  }
  render() {
    const { profile: { roles = [] } = {} } = this.props.user;
    return (
      <React.Fragment>
        <div className="header-wrapper">
          <h2>Tasks</h2>
        </div>
        <div className="content-wrapper half-h-padding">
          {this.props.centerContext ? (
            <NoteSlashTaskList
              typeOptions={[]}
              roleGroupOptions={this.props.roleGroups}
              centerOptions={this.props.centerOptions}
              sessionFilter="TASKS_BOARD_FILTER"
              defaultFilter={{
                patientId: "",
                status: "Opened",
                type: "Task",
                dueDateFrom: "",
                dueDateTo: moment().format(isoDateFormat),
                includeDeleted: false,
                includePatientInfo: true,
                assignedUserIds: [],
                assignedCenterIds: [this.props.centerContext],
                assignedRoles: roles,
                sortBy: "lastModified",
                sortOrder: "descending",
                pageNumber: 1,
                itemsPerPage: 20
              }}
            />
          ) : (
            <div>Please select a context center</div>
          )}
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.auth.user,
    currentUserRoles: state.auth.user.profile.roles,
    centerOptions: state.lookups.centers,
    roleGroups: state.lookups.roleGroups,
    centerContext: state.userPreference.centerContext
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...lookupActions, ...noteSlashTasksActions }, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TasksDashboard);
