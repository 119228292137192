import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import { set, debounce } from "lodash";
import { Dropdown, Form, Grid, Icon, Input } from "semantic-ui-react";
import { DateInput } from "semantic-ui-calendar-react";
import Report from "./Report";
import * as modalActions from "../../actions/modalActions";
import * as lookupActions from "../../actions/lookupActions";
import * as providerActions from "../../actions/providerActions";
import * as searchActions from "../../actions/searchActions";
import { dateFormat, dateWithTimeFormat, isoDateFormat } from "../../constants/miscellaneous";

export class LabOrderStatusReport extends React.Component {
  state = {
    fetchCounter: 0,
    filter: {
      orderDateFrom: moment()
        .subtract(90, "days")
        .format(isoDateFormat),
      orderDateTo: moment().format(isoDateFormat),
      sampleDateFrom: moment()
        .subtract(90, "days")
        .format(isoDateFormat),
      sampleDateTo: moment().format(isoDateFormat),
      centerIds: [],
      providerIds: [],
      patientIds: [],
      labCodes: [],
      submissionNumber: "",
      sampleTypes: [],
      testTypes: [],
      labProviders: [],
      statuses: [],
      orderStatuses: []
    },
    searchQuery: null,
    patientOptions: [],
    isFetchingPatients: false
  };

  componentDidMount() {
    this.props.actions.loadProvidersByState();
    this.props.actions.loadLabsUnique();
    this.props.actions.loadLabSpecimenTypes();
    this.props.actions.loadLabTestTypes();
    this.props.actions.loadLabTestStatuses();
    this.props.actions.loadLabProviders();
    this.props.actions.loadLabOrderStatuses();
  }

  handleInput = (e, data) => {
    const { name: field } = data;
    let { value } = data;
    const newState = { ...this.state };
    if (field === "filter.orderDateFrom" || field === "filter.orderDateTo") {
      value = moment(value, dateFormat).format(isoDateFormat);
    }
    set(newState, field, value);
    this.setState(newState);
  };

  handlePatientSelected = (patientSearch, patient) => {
    this.setState({ filter: { ...this.state.filter, patientIds: patient.patientId } });
    patientSearch.resetComponent();
  };

  refetch = () => {
    this.setState({ fetchCounter: this.state.fetchCounter + 1 });
  };

  handlePatientChange = (e, { value }) => this.setState({ filter: { ...this.state.filter, patientIds: value } });

  debouncedSearch = debounce(() => this.handleSearchChange(), 500);

  handlePatientSearchChange = (e, { searchQuery }) => {
    const { patientIds } = this.state.filter;
    const patientOptions = this.state.patientOptions.filter(option => patientIds.includes(option.value));
    if (searchQuery.length < 3) this.setState({ patientOptions, searchQuery });
    else this.setState({ searchQuery }, this.debouncedSearch);
  };

  handleSearchChange = () => {
    const { searchQuery } = this.state;
    if (searchQuery.length < 1) {
      // this.resetComponent();
    } else {
      const {
        actions: { searchPatientAdvanced }
      } = this.props;

      searchPatientAdvanced({ value: searchQuery, pageSize: 10, page: 1 }).then(results => {
        this.setState({
          patientOptions: [
            ...this.state.patientOptions,
            ...results.patients.map(patient => ({
              text: `${patient.patientFirstName} ${patient.patientLastName}`,
              value: patient.patientId
            }))
          ]
        });
      });
    }
  };

  renderFilterForm = () => {
    const {
      centerOptions,
      providerOptions,
      labCodeOptions,
      labProviderOptions,
      statusOptions,
      testTypeOptions,
      orderStatusOptions,
      sampleTypeOptions
    } = this.props;
    const { filter, patientOptions, isFetchingPatients } = this.state;
    return (
      <Grid.Column width={16}>
        <Form>
          <Grid>
            <Grid.Row style={{ paddingBottom: "0" }}>
              <Grid.Column width={1} />
              <Grid.Column width={3}>
                Center
                <br />
                <Form.Group widths="equal" style={{ margin: "0 0 1em" }}>
                  <Dropdown
                    name="filter.centerIds"
                    // label="Clinic:"
                    // placeholder="Select a Center..."
                    options={centerOptions}
                    onChange={this.handleInput}
                    value={filter.centerIds}
                    // style={{ display: "inline-block" }}
                    className="mini"
                    fluid
                    multiple
                    selection
                    search
                  />
                </Form.Group>
              </Grid.Column>
              <Grid.Column width={2}>
                Order date from
                <br />
                <Form.Group widths="equal">
                  <DateInput
                    size="mini"
                    name="filter.orderDateFrom"
                    placeholder="From"
                    value={moment(filter.orderDateFrom).format(dateFormat)}
                    dateFormat={dateFormat}
                    iconPosition="right"
                    onChange={this.handleInput}
                    hideMobileKeyboard
                    closable
                  />
                </Form.Group>
              </Grid.Column>
              <Grid.Column width={2}>
                Order date to
                <br />
                <Form.Group widths="equal">
                  <DateInput
                    size="mini"
                    name="filter.orderDateTo"
                    placeholder="To"
                    value={moment(filter.orderDateTo).format(dateFormat)}
                    dateFormat={dateFormat}
                    iconPosition="right"
                    onChange={this.handleInput}
                    hideMobileKeyboard
                    closable
                  />
                </Form.Group>
              </Grid.Column>
              <Grid.Column width={2}>
                Sample date from
                <br />
                <Form.Group widths="equal">
                  <DateInput
                    size="mini"
                    name="filter.sampleDateFrom"
                    placeholder="From"
                    value={moment(filter.sampleDateFrom).format(dateFormat)}
                    dateFormat={dateFormat}
                    iconPosition="right"
                    onChange={this.handleInput}
                    hideMobileKeyboard
                    closable
                  />
                </Form.Group>
              </Grid.Column>
              <Grid.Column width={2}>
                Sample date to
                <br />
                <Form.Group widths="equal">
                  <DateInput
                    size="mini"
                    name="filter.sampleDateTo"
                    placeholder="To"
                    value={moment(filter.sampleDateTo).format(dateFormat)}
                    dateFormat={dateFormat}
                    iconPosition="right"
                    onChange={this.handleInput}
                    hideMobileKeyboard
                    closable
                  />
                </Form.Group>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row style={{ padding: "0" }}>
              <Grid.Column width={1} />
              <Grid.Column width={3}>
                Required by
                <br />
                <Form.Group widths="equal" style={{ margin: "0 0 1em" }}>
                  <Dropdown
                    name="filter.providerIds"
                    placeholder="Select Provider(s)"
                    options={providerOptions}
                    onChange={this.handleInput}
                    value={filter.providerIds}
                    className="mini"
                    fluid
                    multiple
                    selection
                    search
                  />
                </Form.Group>
              </Grid.Column>
              <Grid.Column width={3}>
                Patient
                <br />
                <Form.Group widths="equal" className="" style={{ margin: "0 0 1em" }}>
                  <Dropdown
                    fluid
                    selection
                    multiple
                    search
                    className="mini"
                    options={patientOptions}
                    value={filter.patientIds}
                    placeholder="Select Patient(s)"
                    onChange={this.handlePatientChange}
                    onSearchChange={this.handlePatientSearchChange}
                    disabled={isFetchingPatients}
                    loading={isFetchingPatients}
                  />
                </Form.Group>
              </Grid.Column>
              <Grid.Column width={3}>
                Test
                <br />
                <Form.Group widths="equal" className="" style={{ margin: "0 0 1em" }}>
                  <Dropdown
                    name="filter.labCodes"
                    placeholder="Select Test(s)"
                    options={labCodeOptions}
                    onChange={this.handleInput}
                    value={filter.labCodes}
                    className="mini"
                    fluid
                    multiple
                    selection
                    search
                  />
                </Form.Group>
              </Grid.Column>
              <Grid.Column width={2}>
                Submission #
                <br />
                <Form.Group widths="equal" className="" style={{ margin: "0 0 1em" }}>
                  <Input
                    name="filter.submissionNumber"
                    placeholder="Submission #"
                    onChange={this.handleInput}
                    value={filter.submissionNumber}
                    size="mini"
                    style={{ width: "100%" }}
                  />
                </Form.Group>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row style={{ padding: "0" }}>
              <Grid.Column width={1} />
              <Grid.Column width={2}>
                Sample type
                <br />
                <Form.Group widths="equal" style={{ margin: "0 0 1em" }}>
                  <Dropdown
                    name="filter.sampleTypes"
                    options={sampleTypeOptions}
                    onChange={this.handleInput}
                    value={filter.sampleTypes}
                    className="mini"
                    fluid
                    multiple
                    selection
                    search
                  />
                </Form.Group>
              </Grid.Column>
              <Grid.Column width={2}>
                Test type
                <br />
                <Form.Group widths="equal" style={{ margin: "0 0 1em" }}>
                  <Dropdown
                    name="filter.testTypes"
                    options={testTypeOptions}
                    onChange={this.handleInput}
                    value={filter.testTypes}
                    className="mini"
                    fluid
                    multiple
                    selection
                    search
                  />
                </Form.Group>
              </Grid.Column>
              <Grid.Column width={2}>
                Order status
                <br />
                <Form.Group widths="equal" style={{ margin: "0 0 1em" }}>
                  <Dropdown
                    name="filter.statuses"
                    options={statusOptions}
                    onChange={this.handleInput}
                    value={filter.statuses}
                    className="mini"
                    fluid
                    multiple
                    selection
                    search
                  />
                </Form.Group>
              </Grid.Column>
              <Grid.Column width={3}>
                Lab
                <br />
                <Form.Group widths="equal" style={{ margin: "0 0 1em" }}>
                  <Dropdown
                    name="filter.labProviders"
                    options={labProviderOptions}
                    onChange={this.handleInput}
                    value={filter.labProviders}
                    className="mini"
                    fluid
                    multiple
                    selection
                    search
                  />
                </Form.Group>
              </Grid.Column>
              <Grid.Column width={2}>
                Result status
                <br />
                <Form.Group widths="equal" style={{ margin: "0 0 1em" }}>
                  <Dropdown
                    name="filter.orderStatuses"
                    options={orderStatusOptions}
                    onChange={this.handleInput}
                    value={filter.orderStatuses}
                    className="mini"
                    fluid
                    multiple
                    selection
                    search
                  />
                </Form.Group>
              </Grid.Column>
              <Grid.Column width={2}>
                {` `}
                <br />
                <Form.Button size="mini" color="blue" onClick={this.refetch}>
                  Filter
                  <Icon name="search" style={{ marginLeft: "5px" }} />
                </Form.Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
      </Grid.Column>
    );
  };

  render() {
    return (
      <Report
        endPoint="LabOrderStatus"
        fetchCounter={this.state.fetchCounter}
        header="Lab Order Status"
        rowKeyField="id"
        filterObject={this.state.filter}
        renderFilterForm={this.renderFilterForm}
        fields={[
          { name: "id", visible: false },
          {
            name: "patientName",
            label: "Patient Name",
            width: 2,
            render: row => (
              <Link
                to={`/current-patients/${row.patientId}/labs`}
                style={{ textDecoration: "none" }}
                key={row.patientId}
              >
                {row.patientName}
              </Link>
            )
          },
          {
            name: "sampleCollectionDate",
            label: "Sample Date/Time",
            width: 2,
            render: row => moment(row.sampleCollectionDate).format(dateWithTimeFormat)
          },
          { name: "labSource", label: "Sample Type" },
          { name: "test", label: "Test" },
          { name: "testType", label: "Test Type" },
          { name: "requiredBy", label: "Required By" },
          {
            name: "orderDateTime",
            label: "Order Date/Time",
            width: 2,
            render: row => moment(row.orderDateTime).format(dateWithTimeFormat)
          },
          { name: "labProvider", label: "Lab" },
          { name: "orderSubmissionId", label: "Submission #" },
          { name: "status", label: "Order Status" },
          { name: "orderStatus", label: "Result Status" }
        ]}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    centerOptions: state.lookups.centers.map(i => ({ text: i.text, value: i.value })),
    providerOptions: state.providers.allProviders.map(i => ({ text: `${i.firstName} ${i.lastName}`, value: i.userId })),
    labCodeOptions: state.lookups.labsUnique.map(i => ({ text: i.labName, value: i.labCode })),
    sampleTypeOptions: state.lookups.labSpecimenTypes.map(i => ({ text: i, value: i })),
    testTypeOptions: state.lookups.labTestTypes.map(i => ({ text: i, value: i })),
    labProviderOptions: state.lookups.labProviders.map(i => ({ text: i, value: i })),
    orderStatusOptions: state.lookups.labOrderStatuses.map(i => ({ text: i, value: i })),
    statusOptions: state.lookups.labTestStatuses.map(i => ({ text: i, value: i }))
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...modalActions, ...lookupActions, ...providerActions, ...searchActions }, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LabOrderStatusReport);
