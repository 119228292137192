import React from "react";
import { Table } from "semantic-ui-react";

import map from "lodash/map";

const PriorAuthsTable = ({
  statusVisible,
  tableType,
  insuranceInformation,
  getLookupText,
  insuranceProviders
}) => (
  <div className="auths-table-container">
    <h4>{` ${statusVisible} ${tableType} Prior Auths`}</h4>
    <Table fixed>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>PA #</Table.HeaderCell>
          <Table.HeaderCell>Insurance</Table.HeaderCell>
          {tableType === "UDS" ? (
            <Table.HeaderCell>Start Date</Table.HeaderCell>
          ) : (
            <Table.HeaderCell>RX Bin</Table.HeaderCell>
          )}
          <Table.HeaderCell>End Date</Table.HeaderCell>
          {tableType === "UDS" ? (
            <Table.HeaderCell>Number of Auths</Table.HeaderCell>
          ) : (
            <Table.HeaderCell>Pharm Coverage</Table.HeaderCell>
          )}
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {map(insuranceInformation, (priorAuth, i) => (
          <Table.Row key={`${priorAuth.priorAuthorizationNumber}-${i}`}>
            <Table.Cell>{priorAuth.priorAuthorizationNumber}</Table.Cell>
            <Table.Cell>
              {insuranceProviders
                ? getLookupText(
                    priorAuth.insuranceProviderKey,
                    insuranceProviders
                  )
                : null}
            </Table.Cell>
            {tableType === "UDS" ? (
              <Table.Cell>{priorAuth.startDate}</Table.Cell>
            ) : (
              <Table.Cell>{priorAuth.rxBinNumber}</Table.Cell>
            )}
            <Table.Cell>{priorAuth.endDate}</Table.Cell>
            {tableType === "UDS" ? (
              <Table.Cell>{priorAuth.numberOfAuthorizations}</Table.Cell>
            ) : (
              <Table.Cell>{priorAuth.pharmacyCoverageProvider}</Table.Cell>
            )}
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  </div>
);

export default PriorAuthsTable;
