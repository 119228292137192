import React from "react";
import { Card as BsCard } from "react-bootstrap";

const Card = (props) => <BsCard {...props} />;

Card.Img = BsCard.Img;
Card.Title = BsCard.Title;
Card.Subtitle = BsCard.Subtitle;
Card.Body = BsCard.Body;
Card.Link = BsCard.Link;
Card.Text = BsCard.Text;
Card.Header = BsCard.Header;
Card.Footer = BsCard.Footer;
Card.ImgOverlay = BsCard.ImgOverlay;

export default Card;
