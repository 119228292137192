import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Accordion } from "../../../../ui";
import emrComponent from "../../../common/emrComponent";
import * as lookupActions from "../../../../actions/lookupActions";
import * as labActions from "../../../../actions/labActions";
import * as modalActions from "../../../../actions/modalActions";
import "./LabHistory.css";
import VisitResultHistoryReview from "../../labs/VisitResultHistoryReview";

export class LabHistory extends Component {
  state = { activeAccordionItem: 0 };

  render() {
    const { latestVisit } = this.props;
    const { activeAccordionItem } = this.state;

    return latestVisit ? (
      <Accordion activeKey={activeAccordionItem} defaultActiveKey={0} flush>
        <Accordion.Item eventKey={1}>
          <Accordion.Header
            onClick={() => {
              this.setState({ activeAccordionItem: activeAccordionItem === 1 ? null : 1 });
            }}
          >
            Lab Result History
          </Accordion.Header>
          <Accordion.Body className="no-padding">
            <VisitResultHistoryReview
              visitId={latestVisit.visitId}
              visitTime={latestVisit.visitDateTime}
              userCanReview={false}
            />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    ) : null;
  }
}

function mapStateToProps(state) {
  const contextVisit = state.visits.contextVisit;
  const demographics = state.demographics;

  const labsCenterId = contextVisit ? contextVisit.centerId : demographics.homeCenterId;

  return {
    labsCenterId,
    latestVisit: state.visits.latestVisit
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...lookupActions, ...labActions, ...modalActions }, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(emrComponent(LabHistory));
