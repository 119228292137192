import React, { Component } from "react";
import { Modal, Button, Icon } from "semantic-ui-react";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as modalActions from "../../actions/modalActions";

export class MessageModal extends Component {
  handleCancel = async () => {
    if (Object.prototype.hasOwnProperty.call(this.props, "onCancel")) {
      await this.props.onCancel();
    }

    this.hideModal();
  };

  hideModal = () => this.props.actions.hideModal();

  render() {
    const { open, icon, iconColor, title, message, size } = this.props;

    return (
      <Modal open={open} size={size || "small"}>
        <Modal.Header id="lbl-modalTitle">
          <Icon name={icon || ""} color={iconColor || null} />
          {title}
        </Modal.Header>
        <Modal.Content id="lbl-modalDesc">{message}</Modal.Content>
        <Modal.Actions>
          <Button onClick={this.handleCancel} content="OK" />
        </Modal.Actions>
      </Modal>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(modalActions, dispatch)
  };
}

export default connect(
  null,
  mapDispatchToProps
)(MessageModal);
