import "react-toastify/dist/ReactToastify.css";
import * as types from "./actionTypes";
import { beginAjaxCall, ajaxCallDone } from "./ajaxStatusActions";
import { adalApiFetch } from "../adalConfig";
import { API_URL } from "../constants/environment";
import { processApiError, processThenThrowApiError } from "../helpers";

function searchPatientSuccess(requestText, json) {
  return {
    type: types.SEARCH_PATIENT_SUCCESS,
    requestText,
    patients: json
  };
}

function searchMedicationSuccess(requestText, json) {
  return {
    type: types.SEARCH_MEDS_SUCCESS,
    requestText,
    medications: json
  };
}

function searchAllergySuccess(requestText, json) {
  return {
    type: types.SEARCH_ALLERGY_SUCCESS,
    requestText,
    allergies: json
  };
}

export function clearSearch(searchType) {
  return {
    type: types.CLEAR_SEARCH,
    searchType
  };
}

function searchPhysiciansSuccess(physicians) {
  return {
    type: types.SEARCH_PHYSICIAN_SUCCESS,
    physicians
  };
}

function searchConditionSuccess(conditions) {
  return {
    type: types.SEARCH_CONDITION_SUCCESS,
    conditions
  };
}

export function searchMedication(requestText) {
  return dispatch => {
    dispatch(beginAjaxCall("searchMedication"));
    return adalApiFetch(`${API_URL}/Reference/Drug`, {
      method: "POST",
      body: JSON.stringify(requestText)
    })
      .then(json => dispatch(searchMedicationSuccess(requestText, json)))
      .catch(error => processThenThrowApiError(error, dispatch))
      .finally(() => dispatch(ajaxCallDone()));
  };
}

export function searchPatientBasic(filterParams) {
  return dispatch => {
    dispatch(beginAjaxCall("searchPatientBasic"));
    return adalApiFetch(`${API_URL}/Patients/BasicSearch`, {
      method: "POST",
      body: JSON.stringify(filterParams)
    })
      .then(json => dispatch(searchPatientSuccess(filterParams.patient, json)))
      .catch(error => {
        processApiError(error, dispatch);
      })
      .finally(() => {
        dispatch(ajaxCallDone());
      });
  };
}

export function searchPatientAdvanced(filterParams) {
  return dispatch => {
    dispatch(beginAjaxCall("searchPatientAdvanced"));
    return adalApiFetch(`${API_URL}/Patients/AdvancedSearch`, {
      method: "POST",
      body: JSON.stringify(filterParams)
    })
      .then(json => dispatch(searchPatientSuccess(filterParams.value, json)))
      .catch(error => {
        processApiError(error, dispatch);
      })
      .finally(() => {
        dispatch(ajaxCallDone());
      });
  };
}

export function searchAllergy(requestText) {
  return dispatch => {
    dispatch(beginAjaxCall("searchAllergy"));
    return adalApiFetch(`${API_URL}/Reference/Allergy`, {
      method: "POST",
      body: JSON.stringify(requestText)
    })
      .then(json => {
        dispatch(searchAllergySuccess(requestText, json));
      })
      .catch(error => {
        processApiError(error, dispatch);
      })
      .finally(() => {
        dispatch(ajaxCallDone());
      });
  };
}

export function searchPhysicians(queryString) {
  return dispatch => {
    dispatch(beginAjaxCall("searchPhysicians"));
    return adalApiFetch(`${API_URL}/Reference/Physician`, {
      method: "POST",
      body: JSON.stringify(queryString)
    })
      .then(response => {
        dispatch(searchPhysiciansSuccess(response));
      })
      .catch(error => {
        processApiError(error, dispatch);
      })
      .finally(() => {
        dispatch(ajaxCallDone());
      });
  };
}

export function searchCondition(searchString) {
  return dispatch => {
    dispatch(beginAjaxCall("searchCondition"));
    return adalApiFetch(`${API_URL}/Reference/Condition`, {
      method: "POST",
      body: JSON.stringify(searchString)
    })
      .then(json => dispatch(searchConditionSuccess(json)))
      .catch(error => {
        processApiError(error, dispatch);
      })
      .finally(() => {
        dispatch(ajaxCallDone());
      });
  };
}
