import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import shortid from "shortid";
import { isEmpty, isEqual } from "lodash";
import { Segment, Grid, Button } from "semantic-ui-react";
import { parseLabel, isQuestionTriggered, initSurvey, getQuestionStringId } from "./surveyHelper";
import * as modalActions from "../../../actions/modalActions";
import * as visitActions from "../../../actions/visitActions";
import * as templateActions from "../../../actions/templateActions";

export class SurveyTile extends Component {
  static previousSection = "";

  constructor(props) {
    super(props);
    this.state = { surveyLayout: { sections: [] }, surveyData: {}, doRender: this.doRender() };
  }

  componentDidMount() {
    if (!this.state.doRender) return;
    let surveyLayout = {};
    let surveyData = {};
    [surveyLayout, surveyData] = initSurvey(
      this.props.survey,
      this.props.survey.templateActions.templateActions.map((a) => ({ ...a, targetKey: JSON.parse(a.targetKey) }))
    );
    this.setState({ surveyLayout, surveyData });
  }

  componentDidUpdate(prevProps) {
    const { survey, showSurveyEdit, surveysVisitStatus } = this.props;
    if (
      isEqual(survey, prevProps.survey) &&
      isEqual(showSurveyEdit, prevProps.showSurveyEdit) &&
      isEqual(surveysVisitStatus, prevProps.surveysVisitStatus)
    )
      return;
    const doRender = this.doRender();
    if (doRender && this.props.reInitSurvey) {
      this.props.actions.setReInitSurvey(false);
      const [surveyLayout, surveyData] = initSurvey(
        this.props.survey,
        this.props.survey.templateActions.templateActions.map((a) => ({ ...a, targetKey: JSON.parse(a.targetKey) }))
      );
      this.setState({ doRender, surveyLayout, surveyData });
    }
  }

  getHeaderText = (sectionKey) => {
    const { sections } = this.props.survey;
    const headerToDisplay = sections.find((section) => section.key === sectionKey);
    return headerToDisplay.header;
  };

  checkForStyles = (key, previousAnswer) => {
    const question = this.props.survey.questions.find((_question) => _question.key === key);
    const { options } = question;
    if (options.length) {
      const optionToReturn = options.find((option) => option.includes(previousAnswer));
      return optionToReturn && optionToReturn !== previousAnswer ? parseLabel(optionToReturn, "style").join(" ") : "";
    }
    return "";
  };

  processQuestion = (question) => {
    switch (question.type) {
      case "MultiSelect":
      case "MultiSelectWithText":
      case "SingleSelect":
      case "ShortText":
      case "SingleSelectWithText":
      case "LongText":
      case "MultiToggle":
      case "AdvancedSearch":
        return this.handleStrings(question);
      default:
        return null;
    }
  };

  updatePreviousSection = (getHeaderText) => {
    this.previousSection = getHeaderText;
    return getHeaderText;
  };

  handleStrings = (question) => {
    const { surveyData } = this.state;
    const {
      type,
      key: questionKey,
      questionCount,
      sectionKey,
      sectionCount,
      groupKey,
      groupCount,
      shortDisplayText
    } = question;
    const stringId = getQuestionStringId(question);
    const _question = surveyData[stringId];
    const { answers, elucidations } = _question;
    const nullElucidation = this.renderAnswerElucidation(elucidations, null);
    const getHeaderText = this.getHeaderText(sectionKey);
    let first = null;
    const noNoElucidation = elucidations.filter((e) => isEmpty(e.key) || e.key === "No").every((e) => isEmpty(e.value));

    if (type === "MultiToggle" && answers.length === 1 && answers[0] === "No" && noNoElucidation) return "";
    const stringKey = getQuestionStringId({
      sectionKey,
      sectionCount,
      groupKey,
      groupCount,
      questionKey,
      questionCount
    });

    return answers.length ? (
      <React.Fragment key={stringKey}>
        {this.previousSection !== getHeaderText && <h4>{this.updatePreviousSection(getHeaderText)}</h4>}
        <div key={stringKey} className={getHeaderText && `tile-section-question`}>
          <label>{shortDisplayText || "No Short Display Text Available:"} </label>
          {answers.length
            ? answers.map((_previousAnswer) => {
                first = first === null && _previousAnswer;
                const elucidation = this.renderAnswerElucidation(elucidations, _previousAnswer);
                let previousAnswer = _previousAnswer;
                if (type === "MultiToggle") {
                  if (_previousAnswer === "Yes") previousAnswer = "Reviewed";
                  if (_previousAnswer === "No") previousAnswer = "was not reviewed";
                }
                return (
                  <strong
                    className={this.checkForStyles(questionKey, previousAnswer)}
                    key={`${previousAnswer}-rand-${shortid.generate()}`}
                  >
                    {previousAnswer && `${first ? "" : ", "}${previousAnswer}${elucidation}`}
                  </strong>
                );
              })
            : ""}
          {!isEmpty(nullElucidation) ? ` - ${nullElucidation}` : ""}
        </div>
      </React.Fragment>
    ) : null;
  };

  checkForPreviousAnswers = () => this.props.survey.questions.some((question) => question.previousAnswers.length !== 0);

  handleClearSurveyClick = () => {
    const {
      patientId,
      surveysVisitId,
      survey,
      actions: { clearVisitSurveys, setReloadSelectedTemplate }
    } = this.props;
    this.props.actions.showModal({
      type: "CONFIRMATION",
      props: {
        open: true,
        icon: "exclamation-triangle",
        title: "Confirm clear survey",
        description:
          "Are you sure you want to clear this survey; If you proceed, the cancelled survey data cannot be restored?",
        buttonMessage: "Clear",
        buttonColor: "red",
        size: "mini",
        onConfirm: () =>
          clearVisitSurveys(patientId, surveysVisitId, false, [survey.id]).then(() => setReloadSelectedTemplate(true))
      }
    });
  };

  renderAnswerElucidation = (_elucidations, _previousAnswer = null) => {
    const elucidations = _elucidations.filter(
      (e) => !isEmpty(e.value) && ((_previousAnswer === null && isEmpty(e.key)) || e.key === _previousAnswer)
    );
    return elucidations.length ? `(${elucidations.map((e) => e.value).join(", ")})` : "";
  };

  doRender = () => {
    const { survey, showSurveyEdit, surveysVisitStatus } = this.props;
    const surveyHasAnswers = this.checkForPreviousAnswers();

    const renderTile =
      (surveysVisitStatus === "visitcomplete" && survey.hasAnswersFromContextContainer) ||
      (surveysVisitStatus !== "visitcomplete" &&
        ((surveyHasAnswers && survey.isCumulative) || survey.hasAnswersFromContextContainer));

    const canShowSurveyCompleted = survey.hasAnswersFromContextContainer || showSurveyEdit;

    return renderTile && canShowSurveyCompleted;
  };

  render() {
    const {
      showClearSurveysButton,
      patientId,
      templateId,
      survey,
      handleModalOpen,
      handleQuickReview,
      showSurveyEdit,
      surveysVisitStatus,
      hiddenSurveys
    } = this.props;
    const surveyHasAnswers = this.checkForPreviousAnswers();

    const renderTile =
      (surveysVisitStatus === "visitcomplete" && survey.hasAnswersFromContextContainer) ||
      (surveysVisitStatus !== "visitcomplete" &&
        ((surveyHasAnswers && survey.isCumulative) || survey.hasAnswersFromContextContainer));

    // Provider and supProvider -> show all survey
    // Other role only show survey completed
    const canShowSurveyCompleted = survey.hasAnswersFromContextContainer || showSurveyEdit;
    const { surveyLayout, surveyData } = this.state;
    const flatLayout = [].concat(
      ...surveyLayout.sections.map((_s) => [].concat(..._s.groups.map((_g) => _g.questions)))
    );
    const hiddenSurvey = hiddenSurveys[`${patientId}-${templateId}-${survey.id}`];
    const disabled = !isEmpty(hiddenSurveys) && isEmpty(hiddenSurvey);

    return (
      <Grid columns={2}>
        {renderTile && canShowSurveyCompleted ? (
          <Grid.Column key={survey.id} className="survey-tile">
            <Segment className={`${survey.hasAnswersFromContextContainer && "survey-completed"}`}>
              {showSurveyEdit && (
                <Button
                  className="transparent-button-icon float-right"
                  onClick={handleModalOpen(survey)}
                  icon={`${survey.hasAnswersFromContextContainer ? "edit" : "add"}`}
                  size="big"
                  disabled={disabled}
                  id="btn-coreEdit"
                />
              )}
              {!survey.requireReview && !survey.hasAnswersFromContextContainer ? (
                <Button
                  className="transparent-button-icon float-right"
                  onClick={() => handleQuickReview(survey.id, survey.version)}
                  icon="check"
                  size="big"
                  disabled={disabled}
                  id="btn-noChange"
                />
              ) : (
                ""
              )}
              {showSurveyEdit && survey.hasAnswersFromContextContainer && showClearSurveysButton && (
                <Button
                  title="Clear survey"
                  className="transparent-button-icon float-right"
                  onClick={this.handleClearSurveyClick}
                  color="red"
                  icon="eraser"
                  size="big"
                  disabled={disabled}
                  id="btn-clear-survey"
                />
              )}
              <h3 className="tile-header">{survey.title}</h3>
              {surveyLayout.sections.map((section) => (
                <div key={`${section.key}-${section.sortOrder}`}>
                  {section.groups.map((group) => (
                    <div key={`${group.key}-${group.sortOrder}`}>
                      {group.questions
                        .filter((question) => isQuestionTriggered(question, flatLayout, surveyData))
                        .map((question) => this.processQuestion(question))}
                    </div>
                  ))}
                </div>
              ))}
            </Segment>
          </Grid.Column>
        ) : null}
      </Grid>
    );
  }
}

function mapStateToProps(state, props) {
  const contextVisit = state.visits.contextVisit;
  const latestVisit = state.visits.latestVisit;
  const surveysVisit = props.tab === "discharge" ? null : contextVisit || latestVisit;

  const surveysVisitStatus = contextVisit
    ? contextVisit.status
    : surveysVisit && surveysVisit.visitStatus
    ? surveysVisit.visitStatus.visitStatus
    : null;

  return {
    patientId: state.patient.currentPatient.patientId,
    templateId: state.templates.selectedTemplate ? state.templates.selectedTemplate.templateId : null,
    surveys: state.templates.selectedTemplate.surveys || [],
    surveysVisitId: surveysVisit ? surveysVisit.visitId : null,
    reInitSurvey: state.templates.reInitSurvey,
    surveysVisitStatus,
    hiddenSurveys: state.templates.hiddenSurveys
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...modalActions, ...visitActions, ...templateActions }, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SurveyTile);
