import "react-toastify/dist/ReactToastify.css";
import * as types from "./actionTypes";
import { beginAjaxCall, ajaxCallDone } from "./ajaxStatusActions";
import { API_URL } from "../constants/environment";
import { pollApiEvents, processApiResponse, processApiError, processThenThrowApiError } from "../helpers";
import { adalApiFetch } from "../adalConfig";

function loadPatientHistSuccess(patientId) {
  return {
    type: types.LOAD_PATIENT_HIST_SUCCESS,
    context: "patient",
    patientId
  };
}

export function clearPatientHistory(patientId) {
  return {
    type: types.CLEAR_PATIENT_HISTORY,
    context: "patient",
    patientId
  };
}

function loadAllergyHistSuccess(patientId, allergyHistory) {
  return {
    type: types.LOAD_ALLERGY_HIST_SUCCESS,
    context: "patient",
    patientId,
    allergyHistory
  };
}

function loadFamilyHistSuccess(patientId, familyHistory) {
  return {
    type: types.LOAD_FAMILY_HIST_SUCCESS,
    context: "patient",
    patientId,
    familyHistory
  };
}

function loadMedicalHistSuccess(patientId, medicalHistory) {
  return {
    type: types.LOAD_MEDICAL_HIST_SUCCESS,
    context: "patient",
    patientId,
    medicalHistory
  };
}

function loadInterventionHistorySuccess(patientId, interventionHistory) {
  return {
    type: types.LOAD_INTERVENTION_HISTORY_SUCCESS,
    context: "patient",
    patientId,
    interventionHistory
  };
}

function loadMedicationSuccess(patientId, medicationHistory) {
  return {
    type: types.LOAD_MEDICATION_SUCCESS,
    context: "patient",
    patientId,
    medicationHistory
  };
}

function loadHistoricalAddressSuccess(patientId, historicalAddresses) {
  return {
    type: types.LOAD_HISTORICAL_ADDRESS_SUCCESS,
    context: "patient",
    patientId,
    historicalAddresses
  };
}

function loadPatientOBProviderSuccess(patientId, obProviders) {
  return {
    type: types.LOAD_OB_PROVIDER_SUCCESS,
    context: "patient",
    patientId,
    obProviders
  };
}

function loadPatientOutsideCounselorSuccess(patientId, outsideCounselors) {
  return {
    type: types.LOAD_OUTSIDE_COUNSELOR_SUCCESS,
    context: "patient",
    patientId,
    outsideCounselors
  };
}

function createMedicationSuccess(patientId, response, medication) {
  return {
    type: types.CREATE_MEDICATION_SUCCESS,
    context: "patient",
    patientId,
    response,
    medication
  };
}

function updateMedicationSuccess(patientId, response, medication) {
  return {
    type: types.UPDATE_MEDICATION_SUCCESS,
    context: "patient",
    patientId,
    response,
    medication
  };
}

function deleteMedicationSuccess(patientId, itemId) {
  return {
    type: types.DELETE_MEDICATION_SUCCESS,
    context: "patient",
    patientId,
    itemId
  };
}

function createAllergyHistSuccess(patientId, response, allergy, allergyType) {
  return {
    type: types.CREATE_ALLERGY_HIST_SUCCESS,
    context: "patient",
    patientId,
    response,
    allergy,
    allergyType
  };
}

function updateAllergyHistSuccess(patientId, response, allergy, itemId) {
  return {
    type: types.UPDATE_ALLERGY_HIST_SUCCESS,
    context: "patient",
    patientId,
    response,
    allergy,
    itemId
  };
}

function deleteAllergyHistSuccess(patientId, itemId) {
  return { type: types.DELETE_ALLERGY_HIST_SUCCESS, context: "patient", patientId, itemId };
}

function createFamilyHistSuccess(patientId, response, familyHistory) {
  return {
    type: types.CREATE_FAMILY_HIST_SUCCESS,
    context: "patient",
    patientId,
    response,
    familyHistory
  };
}

function updateFamilyHistSuccess(patientId, response, familyHistory) {
  return {
    type: types.UPDATE_FAMILY_HIST_SUCCESS,
    context: "patient",
    patientId,
    response,
    familyHistory
  };
}

function deleteFamilyHistSuccess(patientId, itemId) {
  return { type: types.DELETE_FAMILY_HIST_SUCCESS, context: "patient", patientId, itemId };
}

function createMedicalHistSuccess(patientId, response, medicalHistory) {
  return {
    type: types.CREATE_MEDICAL_HIST_SUCCESS,
    context: "patient",
    patientId,
    response,
    medicalHistory
  };
}

function updateMedicalHistSuccess(patientId, response, medicalHistory) {
  return {
    type: types.UPDATE_MEDICAL_HIST_SUCCESS,
    context: "patient",
    patientId,
    response,
    medicalHistory
  };
}

function deleteMedicalHistSuccess(patientId, itemId) {
  return { type: types.DELETE_MEDICAL_HIST_SUCCESS, context: "patient", patientId, itemId };
}

function createInterventionHistorySuccess(patientId, response, interventionHistory) {
  return {
    type: types.CREATE_INTERVENTION_HISTORY_SUCCESS,
    context: "patient",
    patientId,
    response,
    interventionHistory
  };
}

function updateInterventionHistorySuccess(patientId, response, interventionHistory) {
  return {
    type: types.UPDATE_INTERVENTION_HISTORY_SUCCESS,
    context: "patient",
    patientId,
    response,
    interventionHistory
  };
}

function deleteInterventionHistorySuccess(patientId, itemId) {
  return { type: types.DELETE_INTERVENTION_HISTORY_SUCCESS, context: "patient", patientId, itemId };
}

function createPatientHistoryItemByVisitSuccess(response) {
  return { type: types.CREATE_PATIENT_HISTORY_ITEM_BY_VISIT_SUCCESS, response };
}

export function loadHistoricalAddress(patientId) {
  return (dispatch) => {
    dispatch(beginAjaxCall("loadHistoricalAddress"));
    return adalApiFetch(`${API_URL}/Patients/${patientId}/AddressHistory`)
      .then((historicalAddresses) => dispatch(loadHistoricalAddressSuccess(patientId, historicalAddresses)))
      .catch((error) => {
        processApiError(error, dispatch);
      })
      .finally(() => {
        dispatch(ajaxCallDone());
      });
  };
}

export function loadMedication(patientId) {
  return (dispatch) => {
    dispatch(beginAjaxCall("loadMedication"));
    return adalApiFetch(`${API_URL}/Patients/${patientId}/MedicationHistory`)
      .then((medicationHistory) => dispatch(loadMedicationSuccess(patientId, medicationHistory)))
      .catch((error) => {
        processApiError(error, dispatch);
      })
      .finally(() => {
        dispatch(ajaxCallDone());
      });
  };
}

export function loadMedicalHist(patientId) {
  return (dispatch) => {
    dispatch(beginAjaxCall("loadMedicalHist"));
    return adalApiFetch(`${API_URL}/Patients/${patientId}/MedicalHistory`)
      .then((medicalHistory) => dispatch(loadMedicalHistSuccess(patientId, medicalHistory)))
      .catch((error) => {
        processApiError(error, dispatch);
      })
      .finally(() => {
        dispatch(ajaxCallDone());
      });
  };
}

export function loadInterventionHistory(patientId) {
  return (dispatch) => {
    dispatch(beginAjaxCall("loadInterventionHistory"));
    return adalApiFetch(`${API_URL}/Patients/${patientId}/InterventionHistory`)
      .then((interventionHistory) => dispatch(loadInterventionHistorySuccess(patientId, interventionHistory)))
      .catch((error) => processThenThrowApiError(error, dispatch))
      .finally(() => dispatch(ajaxCallDone()));
  };
}

export function loadFamilyHist(patientId) {
  return (dispatch) => {
    dispatch(beginAjaxCall("loadFamilyHist"));
    return adalApiFetch(`${API_URL}/Patients/${patientId}/FamilyHistory`)
      .then((familyHistory) => dispatch(loadFamilyHistSuccess(patientId, familyHistory)))
      .catch((error) => {
        processApiError(error, dispatch);
      })
      .finally(() => {
        dispatch(ajaxCallDone());
      });
  };
}

export function loadAllergyHist(patientId) {
  return (dispatch) => {
    dispatch(beginAjaxCall("loadAllergyHist"));
    return adalApiFetch(`${API_URL}/Patients/${patientId}/AllergyHistory`)
      .then((allergyHistory) => dispatch(loadAllergyHistSuccess(patientId, allergyHistory)))
      .catch((error) => {
        processApiError(error, dispatch);
      })
      .finally(() => {
        dispatch(ajaxCallDone());
      });
  };
}

export function loadOBProviderHistory(patientId) {
  return (dispatch) => {
    dispatch(beginAjaxCall("loadOBProviderHistory"));
    return adalApiFetch(`${API_URL}/Patients/${patientId}/OBProviderHistory`)
      .then((obProviders) => dispatch(loadPatientOBProviderSuccess(patientId, obProviders)))
      .catch((error) => {
        processApiError(error, dispatch);
      })
      .finally(() => {
        dispatch(ajaxCallDone());
      });
  };
}

export function loadPatientHist(patientId) {
  return (dispatch) => {
    dispatch(beginAjaxCall("loadPatientHist"));
    return dispatch(() =>
      Promise.all([
        dispatch(loadFamilyHist(patientId)),
        dispatch(loadMedication(patientId)),
        dispatch(loadAllergyHist(patientId)),
        dispatch(loadMedicalHist(patientId)),
        dispatch(loadInterventionHistory(patientId))
      ])
    )
      .then(() => dispatch(loadPatientHistSuccess(patientId, patientId)))
      .finally(() => {
        dispatch(ajaxCallDone());
      });
  };
}

export function loadOutsideCounselorHistory(patientId) {
  return (dispatch) => {
    dispatch(beginAjaxCall("loadOutsideCounselorHistory"));
    return adalApiFetch(`${API_URL}/Patients/${patientId}/OutsideCounselorHistory`)
      .then((outsideCounselor) => dispatch(loadPatientOutsideCounselorSuccess(patientId, outsideCounselor)))
      .catch((error) => {
        processApiError(error, dispatch);
      })
      .finally(() => {
        dispatch(ajaxCallDone());
      });
  };
}

export function createMedication(patientId, medication) {
  return (dispatch) => {
    dispatch(beginAjaxCall("createMedication"));
    return adalApiFetch(`${API_URL}/Patients/${patientId}/MedicationHistory`, {
      method: "POST",
      body: JSON.stringify(medication)
    })
      .then((response) => processApiResponse(response.orchestrationId))
      .then((orchestrationId) => pollApiEvents(orchestrationId, "IPatientMedicationHistoryItemAddedEvent"))
      .then((response) => dispatch(createMedicationSuccess(patientId, response, medication)))
      .catch((error) => {
        processApiError(error, dispatch);
      })
      .finally(() => {
        dispatch(ajaxCallDone());
      });
  };
}

export function updateMedication(patientId, medication) {
  return (dispatch) => {
    dispatch(beginAjaxCall("editMedication"));
    return adalApiFetch(`${API_URL}/Patients/${patientId}/MedicationHistory/${medication.itemId}`, {
      method: "PUT",
      body: JSON.stringify(medication)
    })
      .then((response) => processApiResponse(response.orchestrationId))
      .then((orchestrationId) => pollApiEvents(orchestrationId, "IPatientMedicationHistoryItemUpdatedEvent"))
      .then((response) => dispatch(updateMedicationSuccess(patientId, response, medication)))
      .catch((error) => {
        processApiError(error, dispatch);
      })
      .finally(() => {
        dispatch(ajaxCallDone());
      });
  };
}

export function deleteMedication(patientId, itemId) {
  return (dispatch) => {
    dispatch(beginAjaxCall("deleteMedication"));
    return adalApiFetch(`${API_URL}/Patients/${patientId}/MedicationHistory/${itemId}`, {
      method: "DELETE"
    })
      .then((response) => processApiResponse(response.orchestrationId))
      .then((orchestrationId) => pollApiEvents(orchestrationId, "IPatientMedicationHistoryItemDeletedEvent"))
      .then(() => dispatch(deleteMedicationSuccess(patientId, itemId)))
      .catch((error) => {
        processApiError(error, dispatch);
      })
      .finally(() => {
        dispatch(ajaxCallDone());
      });
  };
}

export function createFamilyHist(patientId, familyHistory) {
  return (dispatch) => {
    dispatch(beginAjaxCall("createFamilyHist"));
    return adalApiFetch(`${API_URL}/Patients/${patientId}/FamilyHistory`, {
      method: "POST",
      body: JSON.stringify(familyHistory)
    })
      .then((response) => processApiResponse(response.orchestrationId))
      .then((orchestrationId) => pollApiEvents(orchestrationId, "IPatientFamilyHistoryItemAddedEvent"))
      .then((response) => dispatch(createFamilyHistSuccess(patientId, response, familyHistory)))
      .catch((error) => {
        processApiError(error, dispatch);
      })
      .finally(() => {
        dispatch(ajaxCallDone());
      });
  };
}

export function updateFamilyHist(patientId, familyHistory) {
  return (dispatch) => {
    dispatch(beginAjaxCall("updateFamilyHist"));
    return adalApiFetch(`${API_URL}/Patients/${patientId}/FamilyHistory/${familyHistory.itemId}`, {
      method: "PUT",
      body: JSON.stringify(familyHistory)
    })
      .then((response) => processApiResponse(response.orchestrationId))
      .then((orchestrationId) => pollApiEvents(orchestrationId, "IPatientFamilyHistoryItemUpdatedEvent"))
      .then((response) => dispatch(updateFamilyHistSuccess(patientId, response, familyHistory)))
      .catch((error) => {
        processApiError(error, dispatch);
      })
      .finally(() => {
        dispatch(ajaxCallDone());
      });
  };
}

export function deleteFamilyHist(patientId, itemId) {
  return (dispatch) => {
    dispatch(beginAjaxCall("deleteFamilyHist"));
    return adalApiFetch(`${API_URL}/Patients/${patientId}/FamilyHistory/${itemId}`, {
      method: "DELETE"
    })
      .then((response) => processApiResponse(response.orchestrationId))
      .then((orchestrationId) => pollApiEvents(orchestrationId, "IPatientFamilyHistoryItemDeletedEvent"))
      .then(() => dispatch(dispatch(deleteFamilyHistSuccess(patientId, itemId))))
      .catch((error) => {
        processApiError(error, dispatch);
      })
      .finally(() => {
        dispatch(ajaxCallDone());
      });
  };
}

export function createAllergyHist(patientId, allergy, allergyType) {
  return (dispatch) => {
    dispatch(beginAjaxCall("createAllergyHist"));
    return adalApiFetch(`${API_URL}/Patients/${patientId}/${allergyType}`, {
      method: "POST",
      body: JSON.stringify(allergy)
    })
      .then((response) => processApiResponse(response.orchestrationId))
      .then((orchestrationId) =>
        allergyType === "MedicationAllergyHistory"
          ? pollApiEvents(orchestrationId, "IPatientMedicationAllergyHistoryItemAddedEvent")
          : pollApiEvents(orchestrationId, "IPatientNonMedAllergyHistoryItemAddedEvent")
      )
      .then((response) => dispatch(createAllergyHistSuccess(patientId, response, allergy, allergyType)))
      .catch((error) => {
        processApiError(error, dispatch);
      })
      .finally(() => {
        dispatch(ajaxCallDone());
      });
  };
}

export function updateAllergyHist(patientId, allergy, allergyType, itemId) {
  return (dispatch) => {
    dispatch(beginAjaxCall("updateAllergyHist"));

    return adalApiFetch(`${API_URL}/Patients/${patientId}/${allergyType}/${itemId}`, {
      method: "PUT",
      body: JSON.stringify(allergy)
    })
      .then((response) => processApiResponse(response.orchestrationId))
      .then((orchestrationId) =>
        allergyType === "MedicationAllergyHistory"
          ? pollApiEvents(orchestrationId, "IPatientMedicationAllergyHistoryItemUpdatedEvent")
          : pollApiEvents(orchestrationId, "IPatientNonMedAllergyHistoryItemUpdatedEvent")
      )
      .then((response) => dispatch(updateAllergyHistSuccess(patientId, response, allergy, itemId)))
      .catch((error) => {
        processApiError(error, dispatch);
      })
      .finally(() => {
        dispatch(ajaxCallDone());
      });
  };
}

export function deleteAllergyHist(patientId, itemId, allergyType) {
  return (dispatch) => {
    dispatch(beginAjaxCall("deleteAllergyHist"));
    return adalApiFetch(`${API_URL}/Patients/${patientId}/${allergyType}/${itemId}`, {
      method: "DELETE"
    })
      .then((response) => processApiResponse(response.orchestrationId))
      .then((orchestrationId) =>
        allergyType === "MedicationAllergyHistory"
          ? pollApiEvents(orchestrationId, "IPatientMedicationAllergyHistoryItemDeletedEvent")
          : pollApiEvents(orchestrationId, "IPatientNonMedAllergyHistoryItemDeletedEvent")
      )
      .then(() => dispatch(deleteAllergyHistSuccess(patientId, itemId)))
      .catch((error) => {
        processApiError(error, dispatch);
      })
      .finally(() => {
        dispatch(ajaxCallDone());
      });
  };
}

export function createMedicalHist(patientId, medicalHistory) {
  return (dispatch) => {
    dispatch(beginAjaxCall("createMedicalHist"));
    return adalApiFetch(`${API_URL}/Patients/${patientId}/MedicalHistory`, {
      method: "POST",
      body: JSON.stringify(medicalHistory)
    })
      .then((response) => processApiResponse(response.orchestrationId))
      .then((orchestrationId) =>
        pollApiEvents(orchestrationId, [
          "IPatientMedicalHistoryItemAddedEvent",
          "IPatientMedicalHistoryItemUpdatedEvent"
        ])
      )
      .then((response) => dispatch(createMedicalHistSuccess(patientId, response, medicalHistory)))
      .catch((error) => {
        processApiError(error, dispatch);
      })
      .finally(() => {
        dispatch(ajaxCallDone());
      });
  };
}

export function updateMedicalHist(patientId, medicalHistory) {
  return (dispatch) => {
    dispatch(beginAjaxCall("updateMedicalHist"));
    return adalApiFetch(`${API_URL}/Patients/${patientId}/MedicalHistory/${medicalHistory.itemId}`, {
      method: "PUT",
      body: JSON.stringify(medicalHistory)
    })
      .then((response) => processApiResponse(response.orchestrationId))
      .then((orchestrationId) => pollApiEvents(orchestrationId, "IPatientMedicalHistoryItemUpdatedEvent"))
      .then((response) => dispatch(updateMedicalHistSuccess(patientId, response, medicalHistory)))
      .catch((error) => {
        processApiError(error, dispatch);
      })
      .finally(() => {
        dispatch(ajaxCallDone());
      });
  };
}

export function deleteMedicalHist(patientId, itemId) {
  return (dispatch) => {
    dispatch(beginAjaxCall("deleteMedicalHist"));

    return adalApiFetch(`${API_URL}/Patients/${patientId}/MedicalHistory/${itemId}`, {
      method: "DELETE"
    })
      .then((response) => processApiResponse(response.orchestrationId))
      .then((orchestrationId) => pollApiEvents(orchestrationId, "IPatientMedicalHistoryItemDeletedEvent"))
      .then(() => dispatch(deleteMedicalHistSuccess(patientId, itemId)))
      .catch((error) => {
        processApiError(error, dispatch);
      })
      .finally(() => {
        dispatch(ajaxCallDone());
      });
  };
}

export function createInterventionHistory(patientId, interventionHistory) {
  return (dispatch) => {
    dispatch(beginAjaxCall("createInterventionHistory"));
    return adalApiFetch(`${API_URL}/Patients/${patientId}/InterventionHistory`, {
      method: "POST",
      body: JSON.stringify(interventionHistory)
    })
      .then((response) => processApiResponse(response.orchestrationId))
      .then((orchestrationId) =>
        pollApiEvents(
          orchestrationId,
          interventionHistory.notes.length > 0
            ? "IPatientInterventionHistoryItemNoteAddedEvent"
            : [
                "IPatientInterventionHistoryItemAddedEvent",
                "IPatientInterventionHistoryItemUpdatedEvent",
                "IPatientInterventionHistoryItemUpdatedEvent"
              ]
        )
      )
      .then((response) => dispatch(createInterventionHistorySuccess(patientId, response, interventionHistory)))
      .catch((error) => processApiError(error, dispatch))
      .finally(() => dispatch(ajaxCallDone()));
  };
}

export function updateInterventionHistory(patientId, interventionHistory) {
  return (dispatch) => {
    dispatch(beginAjaxCall("updateInterventionHistory"));
    return adalApiFetch(`${API_URL}/Patients/${patientId}/InterventionHistory/${interventionHistory.itemId}`, {
      method: "PUT",
      body: JSON.stringify(interventionHistory)
    })
      .then((response) => processApiResponse(response.orchestrationId))
      .then((orchestrationId) => pollApiEvents(orchestrationId, "IPatientInterventionHistoryItemUpdatedEvent"))
      .then((response) => dispatch(updateInterventionHistorySuccess(patientId, response, interventionHistory)))
      .catch((error) => processApiError(error, dispatch))
      .finally(() => dispatch(ajaxCallDone()));
  };
}

export function deleteInterventionHistory(patientId, itemId) {
  return (dispatch) => {
    dispatch(beginAjaxCall("deleteInterventionHistory"));

    return adalApiFetch(`${API_URL}/Patients/${patientId}/InterventionHistory/${itemId}`, {
      method: "DELETE"
    })
      .then((response) => processApiResponse(response.orchestrationId))
      .then((orchestrationId) => pollApiEvents(orchestrationId, "IPatientInterventionHistoryItemDeletedEvent"))
      .then(() => dispatch(deleteInterventionHistorySuccess(patientId, itemId)))
      .catch((error) => processApiError(error, dispatch))
      .finally(() => dispatch(ajaxCallDone()));
  };
}

export function createPatientHistoryItemByVisit(patientId, visitId) {
  return (dispatch) => {
    dispatch(beginAjaxCall("createPatientHistoryItemByVisit"));
    return adalApiFetch(`${API_URL}/Visits/${visitId}/PatientHistory/${patientId}`, {
      method: "POST"
    })
      .then((response) => processApiResponse(response.orchestrationId))
      .then((orchestrationId) => pollApiEvents(orchestrationId, "IVisitPatientConditionUpdatedEvent"))
      .then((response) => dispatch(createPatientHistoryItemByVisitSuccess(response)))
      .catch((error) => {
        processApiError(error, dispatch);
      })
      .finally(() => {
        dispatch(ajaxCallDone());
      });
  };
}
