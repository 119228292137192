import React, { Component } from "react";
import { Modal, Button, Icon, Form, TextArea, Label } from "semantic-ui-react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { isEmpty } from "lodash";
import * as modalActions from "../../../actions/modalActions";
import * as documentActions from "../../../actions/documentActions";

export class DocumentDeleteReasonModal extends Component {
  state = { reason: "", deleteButtonPressed: false };

  handleDelete = () => {
    const {
      document,
      actions: { deleteDocument }
    } = this.props;
    const { reason } = this.state;
    this.setState({ deleteButtonPressed: true });
    deleteDocument(document.patientId, document.documentId, reason).then(() => {
      if (this.props.afterDelete) this.props.afterDelete();
      this.hideModal();
    });
  };

  handleCancel = () => {
    this.hideModal();
  };

  hideModal = () => this.props.actions.hideModal();

  render() {
    const { reason, deleteButtonPressed } = this.state;
    const { open, document } = this.props;
    return (
      <Modal open={open} size="small">
        <Modal.Header id="lbl-modalTitle">
          <Icon name="exclamation triangle" color="red" />
          {`Delete ${document.displayName}`}
        </Modal.Header>
        <Modal.Content id="lbl-modalDesc">
          <Form>
            <Form.Group widths={16}>
              <Form.Field width={16}>
                <Label>Reason for deletion</Label>
                <TextArea
                  value={reason}
                  onChange={(e, d) => {
                    this.setState({ reason: d.value });
                  }}
                />
              </Form.Field>
            </Form.Group>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.handleCancel} content="Cancel" id="btn-cancel" />
          <Button
            color="red"
            onClick={this.handleDelete}
            content="Delete"
            loading={deleteButtonPressed}
            disabled={deleteButtonPressed || isEmpty(reason.trim())}
          />
        </Modal.Actions>
      </Modal>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...modalActions, ...documentActions }, dispatch)
  };
}

export default connect(
  null,
  mapDispatchToProps
)(DocumentDeleteReasonModal);
