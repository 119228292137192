/* eslint-disable camelcase */
import React from "react";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { Image } from "semantic-ui-react";
import "./ErrorLog.css";
import error_item_icon from "../../assets/common/error_item_icon.svg";

const ErrorLogSlider = ({ sliderVisible, sliderClose, errors, dismissAll, dismissItem }) => (
  <div className={`cd-panel cd-panel--from-right js-cd-panel-main ${sliderVisible && "cd-panel--is-visible"}`}>
    <div className="cd-panel__container">
      {/* Start Header */}
      <header className="cd-panel__header">
        <h4 className="cd-panel__header_title">ERROR LOG</h4>
        <a className="cd-panel__header_btn_close" href="#closeSlider" onClick={sliderClose}>
          X
        </a>
      </header>
      {errors.length !== 0 && (
        <div className="cd-panel__dismiss error-text-right cd-panel__content">
          {" "}
          <a href="#dismissAll" onClick={dismissAll}>
            Dismiss all
          </a>
        </div>
      )}
      {errors.length === 0 && (
        <div className="cd-panel__nocontent cd-panel__content">
          <span>No errors to display</span>{" "}
        </div>
      )}
      {/* End Header */}
      <TransitionGroup>
        {errors &&
          errors
            .sort((a, b) => (a.id < b.id ? 1 : -1))
            .map(
              item =>
                item && (
                  <CSSTransition key={item.id} timeout={{ enter: 300, exit: 300 }} classNames={"cd-panel__content"}>
                    <div className="cd-panel__content">
                      <div className="cd-panel__content_detail error-flex">
                        <Image src={error_item_icon} alt="Error icon" size="small" />
                        <div className="cd-panel__content_detail-middle">
                          <span className="content_detail">{item.detail}</span>
                          <div className="cd-panel__content_time">
                            <span className="cd-panel__content_time-left">
                              {item.orchestrationId ? item.orchestrationId : "N/A"}
                            </span>
                            <span className="cd-panel__content_time-right">{item.errorDate}</span>
                            <div style={{ clear: "both" }} />
                          </div>
                        </div>
                        <a href="#dismissItem" onClick={() => dismissItem(item)}>
                          X
                        </a>
                      </div>
                    </div>
                  </CSSTransition>
                )
              /* End content */
            )}
      </TransitionGroup>
    </div>
  </div>
);

export default ErrorLogSlider;
