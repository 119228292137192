import React, { Component } from "react";
import PropTypes from "prop-types";
import { Modal, Button } from "semantic-ui-react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { set } from "lodash";
import * as centerActions from "../../actions/centerActions";
import * as modalActions from "../../actions/modalActions";
import * as lookupActions from "../../actions/lookupActions";
import { roleGroups } from "../../constants/securityRoles";
import { updateErrors, requiredFieldsFilled, checkRoles } from "../../helpers";
import * as validation from "../../constants/validation";
import NewCenterForm from "./NewCenterForm";

const inputValidation = {
  required: [`locationType`],
  zip: validation.zip,
  zip4: validation.zip4,
  phone: validation.phone,
  fax: validation.phone,
  taxId: validation.taxId,
  npi: validation.npi
};

export class NewCenterModal extends Component {
  static DETAILS_TAB_VIEWS = {
    MAIN: 0,
    ADDRESS_HISTORY: 1,
    ENTITY_HISTORY: 2
  };

  static DETAILS_TAB_ENTITIES = {
    labDirector: {
      entityType: "LabDirector",
      title: "Lab Director",
      acceptsValue: true
    },
    medicalDirector: {
      entityType: "MedicalDirector",
      title: "Medical Director",
      acceptsValue: true
    },
    locationStatus: {
      entityType: "LocationStatus",
      title: "Location Status",
      acceptsValue: false
    }
  };

  state = {
    center: this.props.center,
    integrationKey: {
      name: "",
      value: ""
    },
    integrationKeyErrorMessage: "",
    errors: {},

    detailsTabView: NewCenterModal.DETAILS_TAB_VIEWS.MAIN,
    detailsTabEntityType: null
  };

  componentDidMount() {
    this.props.actions.loadStates();
    this.props.actions.loadBillingCodes();
    this.props.actions.loadIntegrationKeys("Centers");
    if (!this.props.isNew) {
      this.props.actions.loadCenterDetails(this.props.centerId).then(() => {
        const center = Object.assign({}, this.state.center, this.props.centerDetails);
        this.setState({ center });
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.forceUpdateCenterDetails &&
      this.props.forceUpdateCenterDetails !== prevProps.forceUpdateCenterDetails
    ) {
      this.props.actions.loadCenterDetails(this.props.centerId).then(() => {
        const center = Object.assign({}, this.state.center, this.props.centerDetails);
        this.setState({ center });
      });
    }
  }

  handleUpdateDetailsTabView = (detailsTabView, detailsTabEntityType = null) => {
    this.setState({ detailsTabView, detailsTabEntityType });
  };

  handleSave = () => {
    const center = {
      ...this.state.center,
      integrationKeys: this.state.center.integrationKeys.reduce((acc, obj) => {
        acc[obj.integrationKeyName] = obj.integrationKeyValue;
        return acc;
      }, {})
    };
    if (this.props.isNew) {
      return this.props.actions.createCenter(center).then(() => {
        this.handleCancel();
      });
    }
    return this.props.actions.updateCenterDetails(center, this.props.centerId).then(() => {
      this.handleCancel();
    });
  };

  handleCancel = () => this.props.actions.hideModal();

  handleFocus = (e) => {
    e.target.setAttribute("autocomplete", "nope");
  };

  handleInput = (e, data) => {
    const { name: field, value, required } = data;
    const errors = updateErrors(field, value, required, this.state.errors, inputValidation[field]);
    const center = { ...this.state.center }; // Object.assign({}, this.state.center, { [field]: value });
    set(center, field, value);
    this.setState({ center, errors });
  };

  handleIkInput = (e, data) => {
    const { name: field, value } = data;
    const integrationKey = Object.assign({}, this.state.integrationKey, {
      [field]: value
    });
    this.setState({ integrationKey, integrationKeyErrorMessage: "" });
  };

  handleMaskedInput = (e) => {
    const {
      type,
      target: { name: field, required, value }
    } = e;
    let errors = Object.assign({}, this.state.errors);
    const center = { ...this.state.center };
    set(center, field, value);
    if (type !== "focus" && type !== "blur") {
      errors = updateErrors(field, value, required, errors, inputValidation[field]);
    }
    this.setState({ center, errors });
  };

  handleAddIntegrationKey = () => {
    if (this.state.center.integrationKeys.find((ik) => ik.integrationKeyName === this.state.integrationKey.name)) {
      this.setState({
        integrationKeyErrorMessage: "This key is already mapped"
      });
    } else {
      this.setState({
        center: {
          ...this.state.center,
          integrationKeyErrorMessage: "",
          integrationKeys: [
            ...this.state.center.integrationKeys,
            {
              integrationKeyName: this.state.integrationKey.name,
              integrationKeyValue: this.state.integrationKey.value
            }
          ]
        }
      });
    }
  };

  handleDeleteIntegrationKey = (integrationKeyName) => {
    const index = this.state.center.integrationKeys.findIndex((ik) => ik.integrationKeyName === integrationKeyName);
    if (index !== -1) {
      const newIntegrationKeys = [...this.state.center.integrationKeys];
      newIntegrationKeys.splice(index, 1);
      this.setState({
        center: {
          ...this.state.center,
          integrationKeys: newIntegrationKeys
        }
      });
    }
  };

  handleCheckbox = (e, data) => {
    const { name: field, checked } = data;
    const center = Object.assign({}, this.state.center, { [field]: checked });
    this.setState({ center });
  };

  render() {
    const { center, integrationKey, detailsTabView, detailsTabEntityType, errors } = this.state;
    const { open, processing, states, integrationKeys, billingCodes, locationTypeOptions, isNew, authRoles } =
      this.props;
    const requiredFieldsCheck = requiredFieldsFilled(inputValidation.required, center);
    const disableSave = requiredFieldsCheck || Object.keys(errors).length !== 0 || processing;
    const showEditDetails = checkRoles(roleGroups.centersFull, authRoles);
    return (
      <Modal open={open}>
        <Modal.Header>{isNew ? "Add New Center" : "Edit Center Details"}</Modal.Header>
        <Modal.Content style={{ paddingTop: 0, paddingBottom: 0 }}>
          <NewCenterForm
            center={center}
            integrationKey={integrationKey}
            stateOptions={states}
            integrationKeyOptions={integrationKeys}
            billingCodeOptions={billingCodes}
            locationTypeOptions={locationTypeOptions}
            errors={errors}
            handleInput={this.handleInput}
            handleIkInput={this.handleIkInput}
            handleMaskedInput={this.handleMaskedInput}
            handleCheckbox={this.handleCheckbox}
            handleFocus={this.handleFocus}
            enableEditDetails={showEditDetails}
            handleAddIntegrationKey={this.handleAddIntegrationKey}
            handleDeleteIntegrationKey={this.handleDeleteIntegrationKey}
            integrationKeyErrorMessage={this.state.integrationKeyErrorMessage}
            detailsTabView={detailsTabView}
            detailsTabEntityType={detailsTabEntityType}
            handleUpdateDetailsTabView={this.handleUpdateDetailsTabView}
          />
        </Modal.Content>
        <Modal.Actions>
          {detailsTabView !== NewCenterModal.DETAILS_TAB_VIEWS.MAIN && (
            <Button
              text="Back"
              icon="arrow left"
              color="teal"
              content="Back"
              floated="left"
              onClick={() => this.setState({ detailsTabView: NewCenterModal.DETAILS_TAB_VIEWS.MAIN })}
            />
          )}
          <Button onClick={this.handleCancel} content="Cancel" />
          {showEditDetails && (
            <Button
              color="blue"
              onClick={this.handleSave}
              content={processing ? "Saving..." : "Save"}
              disabled={disableSave}
              loading={processing}
            />
          )}
        </Modal.Actions>
      </Modal>
    );
  }
}

NewCenterModal.propTypes = {
  open: PropTypes.bool.isRequired
};

function mapStateToProps(state) {
  const center = {
    centerName: "",
    centerCode: "",
    claimName: "",
    centerGroup: "",
    cliaNumber: "",
    email: "",
    address: {
      address1: "",
      address2: "",
      city: "",
      state: "",
      zip: "",
      zip4: ""
    },
    phone: "",
    fax: "",
    taxId: "",
    billingCode: "",
    clinicGroup: "",
    labDirector: "",
    active: true,
    npi: "",
    medicalDirector: "",
    taxonomyCode: "",
    integrationKeys: []
  };

  return {
    centerDetails: state.centers.centerDetails,
    forceUpdateCenterDetails: state.centers.forceUpdateCenterDetails,
    center,
    processing: state.ajaxCallsInProgress > 0,
    states: state.lookups.states,
    integrationKeys: state.lookups.integrationKeys.reduce((acc, obj) => {
      if (obj.modelName === "Centers") acc.push({ value: obj.keyName, text: obj.keyName });
      return acc;
    }, []),
    billingCodes: state.lookups.billingCodes,
    locationTypeOptions: [
      { text: "Center", value: "Center" },
      { text: "Lab", value: "Lab" },
      { text: "Billing", value: "Billing" }
    ],
    authRoles: state.auth.user.profile.roles
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...centerActions, ...modalActions, ...lookupActions }, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(NewCenterModal);
