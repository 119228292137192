import React, { Component } from "react";
import { Grid } from "semantic-ui-react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import isEqual from "lodash/isEqual";
import { Button } from "../../../../ui";
import * as modalActions from "../../../../actions/modalActions";
import * as demographicActions from "../../../../actions/demographicActions";
import * as patientHistoryActions from "../../../../actions/patientHistoryActions";
import * as lookupActions from "../../../../actions/lookupActions";
import * as patientActions from "../../../../actions/patientActions";
import { roleGroups } from "../../../../constants/securityRoles";
import EditAddressForm from "./EditAddressForm";
import * as validation from "../../../../constants/validation";

import {
  removeSpecialCharacters,
  updateErrors,
  requiredFieldsFilled,
  formatInputSpace,
  checkRoles
} from "../../../../helpers";
import "../../../reusable/Modal.css";

const inputValidation = {
  required: ["address1", "city", "state", "zip"],
  zip: validation.zip
};

const getAddress = (props) =>
  props.address || {
    addressType: "Home",
    address1: "",
    address2: "",
    city: "",
    state: "",
    zip: "",
    isBilling: false,
    isResidence: false
  };

export class EditAddress extends Component {
  state = {
    address: getAddress(this.props),
    errors: {}
    // confirmationOpen: false
  };

  componentDidMount() {
    this.props.actions.loadStates();
  }

  handleFocus = (e) => {
    e.target.setAttribute("autocomplete", "nope");
  };

  handleSave = () => {
    const { patientId, addresses, processing } = this.props;
    const { address } = this.state;
    if (processing) return;

    // Check address type
    if (address.addressType === "Home") {
      // Remove space from address
      formatInputSpace(address);
      // if an address currently has billing, remove it
      if (address.isBilling) {
        this.props.actions.deleteBillingFlag(address);
      }
    }

    if (address.addressId) {
      const addressFound = addresses.find((x) => x.addressId === address.addressId);
      if (isEqual(addressFound, address)) {
        this.afterSave();
        return;
      }
      this.props.actions.updateAddress(patientId, address).then(() => {
        this.props.actions.loadPatientSummary(patientId);
        this.props.actions.loadHistoricalAddress(patientId);
        this.props.actions.loadPersonAddress(patientId);
        this.afterSave();
      });
    } else {
      this.props.actions.createAddress(patientId, address).then(() => {
        this.props.actions.loadPatientSummary(patientId);
        this.props.actions.loadHistoricalAddress(patientId);
        this.props.actions.loadPersonAddress(patientId);
        this.afterSave();
      });
    }
  };

  afterSave = () => {
    if (this.props.afterSave) this.props.afterSave();
  };

  // handleDelete = () => {
  //   const { processing } = this.props;

  //   if (processing) return;

  //   this.props.actions.deleteAddress(this.props.patientId, this.state.address).then(() => {
  //     this.props.actions.loadPatientSummary(this.props.patientId);
  //     this.handleClose();
  //   });
  // };

  // handleClose = () => {
  //   this.props.actions.hideModal();
  // };

  handleInput = (e, data) => {
    const { name: field, value, required } = data;
    const errors = updateErrors(field, value, required, this.state.errors, inputValidation[field]);
    const address = Object.assign({}, this.state.address, { [field]: value });
    this.setState({ address, errors });
  };

  handleMaskedInput = (e) => {
    const {
      type,
      target: { name: field, required, value }
    } = e;
    let errors = Object.assign({}, this.state.errors);
    const address = Object.assign({}, this.state.address, {
      [field]: value
    });

    if (type !== "focus" && type !== "blur") {
      errors = updateErrors(
        field,
        required ? removeSpecialCharacters(value) : value,
        required,
        errors,
        inputValidation[field]
      );
    }

    this.setState({ address, errors });
  };

  handleCheckbox = (e, data) => {
    const { name: field, checked } = data;
    const address = Object.assign({}, this.state.address, { [field]: checked });
    if (address.isBilling === true || address.isResidence !== true) {
      address.addressType = "Home";
    }
    this.setState({ address });
  };

  handleRadioButton = (e, data) => {
    const address = Object.assign({}, this.state.address, { addressType: data.value });
    if (address.addressType !== "Home") {
      address.address1 = "";
      address.address2 = "";
      address.city = "";
      address.state = "";
      address.zip = "";
    }
    this.setState({ address });
  };

  // handleConfirmationOpen = () => {
  //   const { confirmationOpen } = this.state;
  //   this.setState({ confirmationOpen: !confirmationOpen });
  // };

  render() {
    const { states, isEdit, processing, authRoles, hideCancelButton, handleCancel } = this.props;
    const { address, errors } = this.state;
    let requiredFieldsCheck;
    let disableSave = false;
    if (address.addressType === "Home") {
      requiredFieldsCheck = requiredFieldsFilled(inputValidation.required, address);
      disableSave = requiredFieldsCheck || Object.keys(errors).length !== 0;
    }
    const showEditAddress = checkRoles(roleGroups.excludeHelpDesk, authRoles);
    return (
      <React.Fragment>
        <Grid className="no-margin">
          <Grid.Row>
            <Grid.Column>
              {showEditAddress && (
                <EditAddressForm
                  address={address}
                  isEdit={isEdit}
                  states={states}
                  handleInput={this.handleInput}
                  handleMaskedInput={this.handleMaskedInput}
                  handleCheckbox={this.handleCheckbox}
                  handleFocus={this.handleFocus}
                  handleRadioButton={this.handleRadioButton}
                  errors={errors}
                  isBilling={this.state.address.isBilling}
                  isResidence={this.state.address.isResidence}
                  addressType={address.addressType}
                />
              )}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row className="half-padding no-h-padding" style={{ borderTop: "1px solid rgba(34,36,38,.15)" }}>
            <Grid.Column className="" textAlign="right">
              {/* {isEdit ? (
                <Popup
                  trigger={
                    <Button color="red" floated="left" id="btn-delete">
                      Delete
                    </Button>
                  }
                  content={
                    <React.Fragment>
                      <Button
                        color="grey"
                        content="Cancel"
                        onClick={this.handleConfirmationOpen}
                        id="btn-confirmCancel"
                      />
                      <Button
                        id="btn-confirmDelete"
                        color="red"
                        content="Delete Address"
                        onClick={this.handleDelete}
                        loading={processing}
                        disabled={processing || disableSave}
                      />
                    </React.Fragment>
                  }
                  on="click"
                  position="top left"
                  wide
                  open={confirmationOpen}
                  onOpen={this.handleConfirmationOpen}
                />
                ) : null} 
                */}
              {!hideCancelButton && (
                <Button variant="danger" onClick={handleCancel} id="btn-cancel" className="float-start">
                  Cancel
                </Button>
              )}
              {showEditAddress && (
                <Button
                  id="btn-save"
                  onClick={this.handleSave}
                  variant="primary"
                  loading={processing}
                  disabled={processing || disableSave}
                >
                  Save
                </Button>
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    states: state.lookups.states,
    patientId: state.patient.currentPatient.patientId,
    processing: state.ajaxCallsInProgress > 0,
    authRoles: state.auth.user.profile.roles,
    addresses: state.demographics.associatedAddresses
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...modalActions,
        ...demographicActions,
        ...patientHistoryActions,
        ...lookupActions,
        ...patientActions
      },
      dispatch
    )
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EditAddress);
