import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function providersReducer(state = initialState.providers, action) {
  switch (action.type) {
    case types.LOAD_PROVIDERS_SUCCESS:
      return Object.assign({}, state, { allProviders: action.providers.users });
    case types.UPDATE_PROVIDER_SUCCESS:
      return state;
    case types.LOAD_PRESCRIBER_HISTORY_SUCCESS:
      return Object.assign({}, state, {
        prescriberHistory: action.prescribers
      });
    case types.LOAD_PROVIDER_CENTERS_SUCCESS:
    case types.UPDATE_PROVIDER_CENTERS_SUCCESS:
      return Object.assign({}, state, {
        providerCenters: action.centers
      });
    case types.SET_SELECTED_PROVIDER_INTEGRATION_KEYS:
      return {
        ...state,
        selectedProviderIntegrationValues: action.selectedProviderIntegrationValues
      };
    case types.ADD_SELECTED_PROVIDER_INTEGRATION_KEY_VALUE:
      return {
        ...state,
        selectedProviderIntegrationValues: [...state.selectedProviderIntegrationValues, action.integrationKey]
      };
    case types.DELETE_SELECTED_PROVIDER_INTEGRATION_KEY_VALUE:
      return {
        ...state,
        selectedProviderIntegrationValues: [
          ...state.selectedProviderIntegrationValues.slice(0, action.index),
          ...state.selectedProviderIntegrationValues.slice(action.index + 1)
        ]
      };
    case types.SET_SELECTED_PROVIDER_GATEWAYS:
      return { ...state, selectedProviderGateways: action.gateways };
    case types.SET_IDENTITY_PROOFING_STATUS: {
      return { ...state, identityProofingStatus: action.status };
    }
    case types.SET_LAUNCH_SELF_ADMIN_SESSION: {
      return { ...state, exostarSelfAdminSession: action.selfAdminSession };
    }
    case types.SET_IDENTITY_PROOFING_FLAGS:
      return { ...state, identityProofingFlags: action.flags };
    default:
      return state;
  }
}
