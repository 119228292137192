import React from "react";
import { Table, Button, Icon } from "semantic-ui-react";
import moment from "moment";
import map from "lodash/map";
import shortid from "shortid";

import { timeFormat, longDateFormat } from "../../../../constants/miscellaneous";
import "./FamilyHistoryRow.css";

const FamilyHistoryRow = ({
  index,
  condition,
  relation,
  familyHistoryNotes,
  handleEditFamilyHistory,
  showEditHistory,
  showDeleteHistory
}) => (
  <React.Fragment>
    <tr className="family-history-row-item">
      <td data-label="Condition" colSpan={2}>
        <strong>{condition}</strong>
      </td>
      <td data-label="Relation" className="td-relation">
        {relation}
      </td>
      <td data-label="Note">
        {showEditHistory ? (
          <Button
            icon="plus"
            color="blue"
            index={index}
            className="transparent-button-icon"
            onClick={handleEditFamilyHistory}
          />
        ) : (
          <span>&nbsp;</span>
        )}
      </td>
      <td data-label="&nbsp;">
        {familyHistoryNotes ? (
          <Icon name="sticky note outline" />
        ) : (
          <Icon className="opacity" name="sticky note outline" />
        )}
      </td>
      <td data-label="&nbsp;">
        {showEditHistory ? (
          <Button icon="edit" index={index} className="transparent-button-icon" onClick={handleEditFamilyHistory} />
        ) : (
          <span>&nbsp;</span>
        )}
      </td>
      <td data-label="&nbsp;">
        {showDeleteHistory ? (
          <Button
            icon="delete"
            index={index}
            className="transparent-button-icon delete"
            onClick={handleEditFamilyHistory}
          />
        ) : (
          <span>&nbsp;</span>
        )}
      </td>
    </tr>
    {familyHistoryNotes
      ? map(familyHistoryNotes, (note, i) => (
          <React.Fragment key={note.noteId || shortid.generate()}>
            <Table.Row className="tritangleHistory">
              {i === 0 && (
                <React.Fragment>
                  <Table.Cell className="first-tritangle" colSpan={4} />
                  <Table.Cell className="first-tritangle triangle-row-item">
                    <div className="note-background-first" />
                  </Table.Cell>
                  <Table.Cell className="first-tritangle" colSpan={2} />
                </React.Fragment>
              )}
            </Table.Row>
            <Table.Row className="note-background" key={note.noteId}>
              {note.noteAddedOn && (
                <Table.Cell colSpan={4} className="general-info1">
                  <strong>{moment(note.noteAddedOn).format(longDateFormat)} </strong>
                  <span>{moment(note.noteAddedOn).format(timeFormat)}</span>
                </Table.Cell>
              )}
              <Table.Cell colSpan={4} className="general-info2">
                <strong>{note.creatorFullName}</strong>
              </Table.Cell>
            </Table.Row>
            <Table.Row className="note-background">
              <Table.Cell colSpan={9}>
                <p>{note.text}</p>
              </Table.Cell>
            </Table.Row>
          </React.Fragment>
        ))
      : null}
  </React.Fragment>
);

export default FamilyHistoryRow;
