import * as pbi from "powerbi-client";
import $ from "jquery";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Breadcrumb, Icon } from "semantic-ui-react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as biReportActions from "../../actions/reportActions";
import "./ReportPage.css";

class ReportPageBI extends Component {
  constructor(props) {
    super(props);
    this.powerbi = new pbi.service.Service(
      pbi.factories.hpmFactory,
      pbi.factories.wpmpFactory,
      pbi.factories.routerFactory
    );
  }

  state = {
    isError: false
  };

  componentDidMount() {
    this.props.actions.loadReports().then(res => {
      if (this.validateReport(res.reports)) {
        this.loadPowerBI();
      } else {
        this.setState({ isError: true });
      }
    });
  }

  componentDidUpdate(prevState) {
    if (prevState.reportId !== this.props.reportId) {
      this.loadPowerBI();
    }
  }

  getConfiguration = () => {
    this.props.actions.loadReports();
  };

  getPowerBI = (id, embedUrl, accessToken) => {
    const embedConfiguration = {
      type: "report",
      id,
      embedUrl,
      accessToken,
      tokenType: 1,
      settings: {
        filterPaneEnabled: false,
        background: 1
      }
    };
    // Get a reference to the embedded report HTML element
    const embedContainer = $("#reportContainer")[0];

    // Embed the report and display it within the div container.
    return this.powerbi.embed(embedContainer, embedConfiguration);
  };

  powerbi;

  validateReport(reports) {
    const { reportId } = this.props;
    return reports.map(biReport => biReport.reportList.find(item => item.reportId === reportId));
  }

  loadPowerBI = () => {
    const reportId = this.props.match.params.reportId;
    this.props.actions.loadTokens(reportId).then(res => {
      this.getPowerBI(res.token.id, res.token.embedUrl, res.token.embedToken.token);
    });
  };

  render() {
    const { isError } = this.state;
    return (
      <div className="report-container">
        <Breadcrumb>
          <Breadcrumb.Section active={false}>
            <Link to={"/"}>
              <Icon name="home" />
            </Link>
          </Breadcrumb.Section>
          <Breadcrumb.Divider />
          <Breadcrumb.Section active={false}>
            <Link to={"/report"}>{`Reports`}</Link>
          </Breadcrumb.Section>
        </Breadcrumb>
        {isError && <div className="no-report-placeholder">Report is not found</div>}
        {!isError && <div className="reportContainer iframeContainer active" id="reportContainer" />}
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    authRoles: state.auth.user.profile.roles,
    reports: state.reports,
    reportId: ownProps.match.params.reportId
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...biReportActions
      },
      dispatch
    )
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportPageBI);
