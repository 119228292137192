import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import { set, debounce, isEmpty } from "lodash";
import { Dropdown, Form, Grid, Icon } from "semantic-ui-react";
import { DateInput } from "semantic-ui-calendar-react";
import Report from "./Report";
import * as searchActions from "../../actions/searchActions";
import * as modalActions from "../../actions/modalActions";
import * as lookupActions from "../../actions/lookupActions";
import * as providerActions from "../../actions/providerActions";
import { dateFormat, dateWithTimeFormat, isoFormat } from "../../constants/miscellaneous";
import prescriptionStatus from "../../constants/prescriptionStatus";

export class PrescriptionStatusReport extends React.Component {
  state = {
    fetchCounter: 0,
    filter: {
      createdFrom: moment()
        .subtract(2, "days")
        .startOf("day")
        .format(isoFormat),
      createdTo: moment()
        .endOf("day")
        .format(isoFormat),
      centerIds: [],
      providerIds: [],
      prescriptionStatus: [],
      drugKeys: []
    },
    drugKeyOptions: [],
    loadingDrugKeyOptions: false
  };

  componentDidMount() {
    this.props.actions.loadProvidersByState();
  }

  handleInput = (e, data) => {
    const { name: field } = data;
    let { value } = data;
    const newState = { ...this.state };
    if (field === "filter.createdFrom") {
      value = moment(value, dateFormat)
        .startOf("day")
        .format(isoFormat);
    }
    if (field === "filter.createdTo") {
      value = moment(value, dateFormat)
        .endOf("day")
        .format(isoFormat);
    }
    set(newState, field, value);
    this.setState(newState);
  };

  resetSearch = () => {
    this.setState({ loadingDrugKeyOptions: false });
  };

  debouncedSearch = debounce(searchQuery => this.doSearch(searchQuery), 500);

  doSearch = searchQuery => {
    if (isEmpty(searchQuery.trim())) {
      this.resetSearch();
    } else {
      const selectedOptions = this.state.drugKeyOptions.filter(o => this.state.filter.drugKeys.includes(o.value));
      this.props.actions
        .searchMedication({ drugName: searchQuery })
        .then(() => {
          this.setState({ drugKeyOptions: [...selectedOptions, ...this.props.medications] });
        })
        .finally(() => {
          this.setState({ loadingDrugKeyOptions: false });
        });
    }
  };

  handleSearchChange = (e, data) => {
    const { searchQuery } = data;
    this.setState(
      {
        loadingDrugKeyOptions: searchQuery.trim().length > 2
      },
      () => {
        if (searchQuery.trim().length > 2) this.debouncedSearch(searchQuery);
        else this.resetSearch();
      }
    );
  };

  refetch = () => {
    this.setState({ fetchCounter: this.state.fetchCounter + 1 });
  };

  renderFilterForm = () => {
    const { centerOptions, providerOptions, prescriptionStatusOptions } = this.props;
    const { filter, drugKeyOptions, loadingDrugKeyOptions } = this.state;
    return (
      <Grid.Column width={16}>
        <Form>
          <Grid>
            <Grid.Row style={{ padding: "0" }}>
              <Grid.Column width={1} />
              <Grid.Column width={3}>
                Date From
                <br />
                <Form.Group widths="equal">
                  <DateInput
                    size="mini"
                    name="filter.createdFrom"
                    placeholder="From"
                    value={moment(filter.createdFrom).format(dateFormat)}
                    dateFormat={dateFormat}
                    iconPosition="right"
                    onChange={this.handleInput}
                    hideMobileKeyboard
                    closable
                  />
                </Form.Group>
              </Grid.Column>
              <Grid.Column width={3}>
                Date To
                <br />
                <Form.Group widths="equal">
                  <DateInput
                    size="mini"
                    name="filter.createdTo"
                    placeholder="To"
                    value={moment(filter.createdTo).format(dateFormat)}
                    dateFormat={dateFormat}
                    iconPosition="right"
                    onChange={this.handleInput}
                    hideMobileKeyboard
                    closable
                  />
                </Form.Group>
              </Grid.Column>
              <Grid.Column width={3}>
                Main Clinic
                <br />
                <Form.Group widths="equal" style={{ margin: "0 0 1em" }}>
                  <Dropdown
                    name="filter.centerIds"
                    // label="Clinic:"
                    // placeholder="Select a Center..."
                    options={centerOptions}
                    onChange={this.handleInput}
                    value={filter.centerIds}
                    // style={{ display: "inline-block" }}
                    className="mini"
                    fluid
                    multiple
                    selection
                    search
                  />
                </Form.Group>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row style={{ padding: "0" }}>
              <Grid.Column width={1} />
              <Grid.Column width={3}>
                Provider
                <br />
                <Form.Group widths="equal" style={{ margin: "0 0 1em" }}>
                  <Dropdown
                    name="filter.providerIds"
                    options={providerOptions}
                    onChange={this.handleInput}
                    value={filter.providerIds}
                    className="mini"
                    fluid
                    multiple
                    selection
                    search
                  />
                </Form.Group>
              </Grid.Column>
              <Grid.Column width={6}>
                Status
                <br />
                <Form.Group widths="equal" style={{ margin: "0 0 1em" }}>
                  <Dropdown
                    name="filter.prescriptionStatus"
                    value={filter.prescriptionStatus}
                    options={prescriptionStatusOptions}
                    onChange={this.handleInput}
                    className="mini"
                    fluid
                    multiple
                    selection
                    search
                  />
                </Form.Group>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className="no-padding">
              <Grid.Column width={1} />
              <Grid.Column width={9}>
                Drug(s)
                <br />
                <Dropdown
                  name="filter.drugKeys"
                  value={filter.drugKeys}
                  options={drugKeyOptions}
                  loading={loadingDrugKeyOptions}
                  onChange={this.handleInput}
                  onSearchChange={this.handleSearchChange}
                  className="mini"
                  fluid
                  multiple
                  search
                  selection
                />
              </Grid.Column>
              <Grid.Column width={2}>
                {` `}
                <br />
                <Form.Button size="mini" color="blue" onClick={this.refetch}>
                  Filter
                  <Icon name="search" style={{ marginLeft: "5px" }} />
                </Form.Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
      </Grid.Column>
    );
  };

  render() {
    return (
      <Report
        endPoint="PrescriptionStatusReport"
        method="POST"
        fetchCounter={this.state.fetchCounter}
        header="Prescription Status"
        rowKeyField="platformId"
        filterObject={this.state.filter}
        renderFilterForm={this.renderFilterForm}
        fields={[
          { name: "platformId", visible: false },
          { name: "createdDate", label: "Create Date", render: row => moment(row.createdDate).format(dateFormat) },
          {
            name: "sentDate",
            label: "Sent Date",
            render: row => (row.sentDate ? moment(row.sentDate).format(dateWithTimeFormat) : ``)
          },
          { name: "medicalRecordNumber", label: "MRN" },
          {
            name: "patientName",
            label: "Patient Name",
            width: 2,
            render: row => (
              <Link
                to={`/current-patients/${row.patientId}/demographics`}
                style={{ textDecoration: "none" }}
                key={row.patientId}
              >
                {row.patientName}
              </Link>
            )
          },
          { name: "drugName", label: "Drug Name" },
          { name: "form", label: "Drug Form" },
          { name: "administerRoute", label: "Drug Route" },
          { name: "strength", label: "Drug Strength" },
          { name: "provider", label: "Provider" },
          { name: "status", label: "Status" },
          { name: "daysInQueue", label: "Days In Queue" },
          { name: "centerName", label: "Main Center" },
          { name: "visitCenter", label: "Visit Center" }
        ]}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    centerOptions: state.lookups.centers.map(i => ({ text: i.text, value: i.value })),
    providerOptions: state.providers.allProviders.map(i => ({ text: `${i.firstName} ${i.lastName}`, value: i.userId })),
    medications: state.search.medications.map(item => ({ text: item.drugDescription, value: item.drugKey })),
    prescriptionStatusOptions: Object.keys(prescriptionStatus).map(key => ({
      text: prescriptionStatus[key],
      value: prescriptionStatus[key]
    }))
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...searchActions, ...modalActions, ...lookupActions, ...providerActions }, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PrescriptionStatusReport);
