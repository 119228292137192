import React from "react";

const PatientLevel = React.forwardRef(({ bh = false, level, size = "md", className, style, ...rest }, ref) => {
  let _style = ``;
  if (bh) {
    _style =
      size === "xs"
        ? {
            display: "inline-block",
            width: "0",
            height: "0",
            borderLeft: ".6rem solid transparent",
            borderRight: ".6rem solid transparent",
            borderBottomWidth: ".75rem",
            borderBottomStyle: "solid"
          }
        : size === "sm"
        ? {
            display: "inline-block",
            width: "0",
            height: "0",
            borderLeft: "1rem solid transparent",
            borderRight: "1rem solid transparent",
            borderBottomWidth: "1.7rem",
            borderBottomStyle: "solid"
          }
        : size === "md"
        ? {
            display: "inline-block",
            width: "0",
            height: "0",
            borderLeft: "2rem solid transparent",
            borderRight: "2rem solid transparent",
            borderBottomWidth: "3rem",
            borderBottomStyle: "solid"
          }
        : {
            display: "inline-block",
            width: "0",
            height: "0",
            borderLeft: "3rem solid transparent",
            borderRight: "3rem solid transparent",
            borderBottomWidth: "4.5rem",
            borderBottomStyle: "solid"
          };
  } else {
    _style =
      size === "xs"
        ? { display: "inline-block", borderRadius: ".5rem", width: "1rem", height: "1rem" }
        : size === "sm"
        ? { display: "inline-block", borderRadius: "1rem", width: "2rem", height: "2rem" }
        : size === "md"
        ? { display: "inline-block", borderRadius: "1.5rem", width: "3rem", height: "3rem" }
        : { display: "inline-block", borderRadius: "2rem", width: "4rem", height: "4rem" };
  }

  return (
    <span
      className={`patient-level ${bh ? `bh` : ``} ${level} ${className}`}
      {...rest}
      ref={ref}
      style={{ ..._style, ...style }}
    />
  );
});

export default PatientLevel;
