import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function patientReducer(state = initialState.context, action) {
  switch (action.type) {
    case types.SET_CONTEXT:
      return { ...state, [action.key]: action.value };
    default:
      return state;
  }
}
