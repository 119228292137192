import React, { Component } from "react";
import ReactDOM from "react-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { find, isEmpty } from "lodash";
import groupBy from "lodash/groupBy";
import shortid from "shortid";
import { Message, Segment, Header, Form, Button, Menu, Tab, Grid } from "semantic-ui-react";
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";
import { parseLabel, initSurveyData, getQuestionPaddingLevel } from "../../patients/surveys/surveyHelper";
import MultiToggle from "../../patients/surveys/MultiToggle";
import AdvancedSearch from "../../patients/surveys/AdvancedSearch";
import * as modalActions from "../../../actions/modalActions";
import { numbersToText } from "../../../constants/miscellaneous";

const isTriggered = () => true;

class SurveyFormBuilder extends Component {
  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.surveyId !== nextProps.survey.id)
      return {
        surveyid: nextProps.survey.id,
        answers: Object.values(initSurveyData(nextProps.survey.questions))
      };
    return {};
  }
  state = {
    surveyId: null,
    answers: null
  };

  getRequiredQuestions = () => {
    const {
      survey: { questions }
    } = this.props;
    return questions.filter(
      question => question.isRequired === true && isTriggered(question, questions, Object.values(this.state.answers))
    );
  };

  getValue = (type, questionKey, option) => {
    const questionObj = find(Object.values(this.state.answers), ["questionKey", questionKey]);
    switch (type) {
      case "MultiSelect":
        return questionObj.answers.includes(option);
      case "Radio":
        return option ? questionObj.answers[0] === option : !!questionObj.answers.pop();
      case "Dropdown":
      case "Text":
        return questionObj.answers[0] || "";
      default:
        return "";
    }
  };

  questionAnswered = questionKey =>
    Object.values(this.state.answers).find(answer => answer.questionKey === questionKey).answers.length !== 0;

  requiredQuestionsFilled = () => {
    const requiredQuestions = this.getRequiredQuestions();
    return requiredQuestions.every(reqQuestion => this.questionAnswered(reqQuestion.key));
  };

  createFormContents = () => {
    const { survey, editor, handleEditSectionPress, handleMoveSectionPress, handleDeleteSectionPress } = this.props;
    const { answers } = this.state;
    if (survey.sections.length === 1) {
      return this.renderQuestions(survey.sections[0].key, survey.questions);
    }
    const sections = survey.sections.map(s => ({
      ...s,
      questions: survey.questions.filter(q => q.sectionKey === s.key),
      hasRequiredFields:
        survey.questions.filter(
          q => s.key === q.sectionKey && q.isRequired === true && isTriggered(q, survey.questions, answers)
        ).length > 0,
      hasMissingRequiredFields: !survey.questions
        .filter(q => s.key === q.sectionKey && q.isRequired === true && isTriggered(q, survey.questions, answers))
        .every(reqQuestion => answers.find(answer => answer.questionKey === reqQuestion.key).answers.length !== 0)
    }));
    const panes = [];
    sections.forEach(section => {
      const classes = [];
      classes.push(section.hasMissingRequiredFields ? "danger" : "success");
      classes.push("editable-component");
      classes.push("editable-section-component");
      classes.push(editor.target === `section` && editor.targetObject.sbId === section.sbId ? `eactive` : ``);
      panes.push({
        menuItem: (
          <Menu.Item
            key={section.key}
            className={classes.join(" ")}
            onClick={e => handleEditSectionPress(e, section.sbId)}
          >
            <ContextMenuTrigger id={section.key}>
              {section.header + (section.hasRequiredFields ? " *" : "")}
            </ContextMenuTrigger>
            <ContextMenu id={section.key}>
              <MenuItem data={{}} onClick={() => handleMoveSectionPress(section.sbId, "up")}>
                Move up
              </MenuItem>
              <MenuItem data={{}} onClick={() => handleMoveSectionPress(section.sbId, "down")}>
                Move down
              </MenuItem>
              <MenuItem divider />
              <MenuItem className="delete" data={{}} onClick={() => handleDeleteSectionPress(section.sbId)}>
                Delete
              </MenuItem>
            </ContextMenu>
          </Menu.Item>
        ),
        render: () => (
          <Tab.Pane key={section.key}>{this.renderQuestions(section.key, section.questions, false)}</Tab.Pane>
        )
      });
    });
    const twoTabs = sections.length > 14;
    return (
      <Tab
        grid={{ paneWidth: twoTabs ? 8 : 12, tabWidth: twoTabs ? 8 : 4 }}
        menu={{
          fluid: true,
          pointing: true,
          vertical: true,
          tabular: true,
          className: twoTabs ? "columns_" : ""
        }}
        panes={panes}
        onTabChange={() => {
          // eslint-disable-next-line react/no-find-dom-node
          ReactDOM.findDOMNode(this).getElementsByClassName("scrolling")[0].scrollTop = 0;
        }}
      />
    );
  };

  questionWrapper = (question, component) => {
    const { survey, editor, handleAddQuestionPress, handleMoveQuestionPress, handleDeleteQuestionPress } = this.props;
    let className = isTriggered(question, survey.questions, Object.values(this.state.answers)) ? "show" : "hide";
    className += ` editable-component editable-question-component ${
      editor.target === `question` && editor.targetObject.sbId === question.sbId ? ` eactive ` : ``
    }`;
    className += isEmpty(question.triggers) ? `` : ` editable-optional-component `;
    className += ` ${numbersToText[getQuestionPaddingLevel(question, survey.questions)]}-rem-padded-question `;
    return (
      <Grid.Column
        key={question.key}
        className={className}
        onClick={e => this.props.handleEditQuestionPress(e, question.sbId)}
      >
        <Grid>
          <Grid.Column>
            <Button
              size="mini"
              icon="arrow left"
              title="Move left"
              className="new-question"
              onClick={() => handleMoveQuestionPress(question.sbId, "left")}
            />
            <Button
              size="mini"
              color="blue"
              icon="plus"
              title="Add question to the left"
              className="v-button new-question"
              onClick={() => handleAddQuestionPress(question.sectionKey, question.sbId, "left")}
            />
          </Grid.Column>
          <Grid.Column>
            <div className="survey-question-container" key={question.key}>
              <Button
                size="mini"
                icon="arrow up"
                title="Move up"
                className="new-question"
                onClick={() => handleMoveQuestionPress(question.sbId, "up")}
              />
              <Button
                size="mini"
                color="blue"
                icon="plus"
                title="Add question before"
                className="new-question"
                onClick={() => handleAddQuestionPress(question.sectionKey, question.sbId, "up")}
              />

              <label className={question.isRequired ? `mock-semantic-required` : ""}>{question.text}</label>
              <Button
                color="red"
                size="mini"
                floated="right"
                content="Clear"
                disabled={!this.questionAnswered(question.key)}
                onClick={() => this.clearQuestionAnswer(question.key)}
              />
              <Button
                size="mini"
                icon="delete"
                title="Delete"
                className="new-question"
                floated="right"
                onClick={() => handleDeleteQuestionPress(question.sbId)}
              />
              {component}
              <Button
                size="mini"
                icon="arrow down"
                title="Move down"
                className="new-question"
                onClick={() => handleMoveQuestionPress(question.sbId, "down")}
              />
              <Button
                size="mini"
                color="blue"
                icon="plus"
                title="Add question after"
                className="new-question"
                onClick={() => handleAddQuestionPress(question.sectionKey, question.sbId, "down")}
              />
            </div>
          </Grid.Column>
          <Grid.Column>
            <Button
              size="mini"
              icon="arrow right"
              title="Move right"
              className="new-question"
              onClick={() => handleMoveQuestionPress(question.sbId, "right")}
            />
            <Button
              size="mini"
              color="blue"
              icon="plus"
              title="Add question to the right"
              className="v-button new-question"
              onClick={() => handleAddQuestionPress(question.sectionKey, question.sbId, "right")}
            />
          </Grid.Column>
        </Grid>
      </Grid.Column>
    );
  };

  // Question rendering functions
  createMultiSelect = question =>
    this.questionWrapper(
      question,
      <React.Fragment>
        {question.options.map(option => (
          <React.Fragment key={`rand-${shortid.generate()}`}>
            <Form.Checkbox
              className={parseLabel(option, "style").join(" ")}
              key={`rand-${shortid.generate()}`}
              label={parseLabel(option, "label")}
              checked={this.getValue("MultiSelect", question.key, parseLabel(option, "label"))}
              onChange={this.handleMultiSelect}
              name={question.key}
            />
            {this.createElucidation(question, option)}
          </React.Fragment>
        ))}
        {this.createElucidation(question, "")}
      </React.Fragment>
    );

  createSingleSelect = question =>
    this.questionWrapper(
      question,
      <React.Fragment>
        {question.options.map(option => (
          <React.Fragment key={`rand-${shortid.generate()}`}>
            <Form.Field key={`rand-${shortid.generate()}`}>
              <Form.Radio
                className={parseLabel(option, "style").join(" ")}
                label={parseLabel(option, "label")}
                name={question.key}
                value={option}
                checked={
                  isTriggered(question, this.props.survey.questions, Object.values(this.state.answers))
                    ? this.getValue("Radio", question.key, parseLabel(option, "label"))
                    : this.getValue("Radio", question.key, "")
                }
                onChange={this.handleInputChange}
              />
            </Form.Field>
            {this.createElucidation(question, option)}
          </React.Fragment>
        ))}
        {this.createElucidation(question, "")}
      </React.Fragment>
    );

  createDropdown = question =>
    this.questionWrapper(
      question,
      <React.Fragment>
        <Form.Field>
          <Form.Dropdown
            fluid
            selection
            search
            options={question.options.map(option => ({
              text: option,
              value: option
            }))}
            // label={question.text}
            required={question.isRequired}
            name={question.key}
            onChange={this.handleInputChange}
            placeholder="Select..."
            value={this.getValue("Dropdown", question.key)}
          />
        </Form.Field>
        {question.options.map(option => (
          <React.Fragment key={`rand-${shortid.generate()}`}>{this.createElucidation(question, option)}</React.Fragment>
        ))}
        {this.createElucidation(question, "")}
      </React.Fragment>
    );

  createShortText = question =>
    this.questionWrapper(
      question,
      <React.Fragment>
        <Form.Field>
          <Form.Input
            name={question.key}
            onChange={this.handleInputChange}
            value={this.getValue("Text", question.key)}
          />
        </Form.Field>
        {this.createElucidation(question, "")}
      </React.Fragment>
    );

  createLongText = question =>
    this.questionWrapper(
      question,
      <React.Fragment>
        <Form.Field>
          <Form.TextArea
            name={question.key}
            onChange={this.handleInputChange}
            value={this.getValue("Text", question.key)}
          />
        </Form.Field>
        {this.createElucidation(question, "")}
      </React.Fragment>
    );

  createAdvancedSearch = question => {
    const answerObject = Object.values(this.state.answers).find(a => a.questionKey === question.key);
    return (
      <AdvancedSearch
        key={question.key}
        question={question}
        answerObject={answerObject}
        questionWrapper={this.questionWrapper}
        handleSearchChange={() => {}}
        handleResultSelect={() => {}}
        updateAnswerObjectAnswers={() => {}}
      />
    );
  };

  // Handles the creation of elucidations, can be at question level or option level
  createElucidation = (question, option) => {
    if (find(question.elucidations, ["key", option])) {
      const elucidation = find(question.elucidations, ["key", option]);
      const isDisabled = elucidation.key
        ? !Object.values(this.state.answers).find(
            answer => answer.questionKey === question.key && answer.answers.includes(elucidation.key)
          )
        : false;
      return (
        <Form.Field
          className={elucidation.key ? "elucidation-input" : ""}
          style={isDisabled ? { display: "none" } : {}}
        >
          <Form.Input
            onChange={this.handleElucidation}
            name={`${question.key}~${elucidation.key}`}
            value=""
            label={elucidation.caption}
            disabled={isDisabled}
          />
        </Form.Field>
      );
    }
    return "";
  };

  createComponent = (question, uiMetadata) => {
    switch (uiMetadata.component) {
      case "Dropdown":
        return this.createDropdown(question);
      case "RadioGroup":
        return this.createSingleSelect(question);
      default:
        return "";
    }
  };

  // Decides what type of question will be rendered
  handleQuestionType = question => {
    const uiMetadata =
      question.metadata &&
      typeof question.metadata === "object" &&
      question.metadata.ui &&
      typeof question.metadata.ui === "object"
        ? question.metadata.ui
        : {};
    if (Object.prototype.hasOwnProperty.call(uiMetadata, "component")) {
      return this.createComponent(question, uiMetadata);
    }

    switch (question.type) {
      case "MultiSelect":
        return this.createMultiSelect(question);
      case "SingleSelect":
        // if 7 or more options, create a dropdown instead
        if (question.options.length >= 7) return this.createDropdown(question);
        return this.createSingleSelect(question);
      case "Dropdown":
        return this.createDropdown(question);
      case "ShortText":
        return this.createShortText(question);
      case "LongText":
        return this.createLongText(question);
      case "MultiToggle":
        return (
          <MultiToggle
            question={question}
            key={question.key}
            questionWrapper={this.questionWrapper}
            isTriggered={isTriggered}
            getValue={this.getValue}
            createElucidation={this.createElucidation}
            answers={Object.values(this.state.answers)}
            handleMultiToggle={this.handleMultiToggle}
            initiateMultiToggleAnswers={this.initiateMultiToggleAnswers}
            restoreMultiToggleState={this.restoreMultiToggleState}
            parseLabel={parseLabel}
          />
        );
      case "AdvancedSearch":
        return this.createAdvancedSearch(question);
      default:
        return "";
    }
  };

  renderQuestions = (sectionKey, questions, showTitle = true) => {
    const { survey, editor, handleAddQuestionPress } = this.props;
    const rows = groupBy(questions, "sortOrder");
    // const sectionKey =
    //   questions.length > 0 ? questions[0].sectionKey : survey.sections.length > 0 ? survey.sections[0].key : "";
    const sectionTitle = !isEmpty(sectionKey) ? survey.sections.find(section => section.key === sectionKey).header : "";
    return (
      <React.Fragment>
        <Grid padded stackable>
          {showTitle && sectionTitle && (
            <Grid.Row key={sectionKey} className="no-padding">
              <h2>{sectionTitle}</h2>
            </Grid.Row>
          )}
          {Object.keys(rows).map(key => (
            <Grid.Row columns={rows[key].length} key={key} className="no-padding survey-row">
              {rows[key].map(question => this.handleQuestionType(question))}
            </Grid.Row>
          ))}
          {!isEmpty(sectionKey) && isEmpty(questions) && editor.target !== "question" && (
            <Grid.Row className="no-padding survey-row">
              <Button color="blue" size="mini" fluid onClick={() => handleAddQuestionPress(sectionKey, null, "down")}>
                Add Question
              </Button>
            </Grid.Row>
          )}
        </Grid>
      </React.Fragment>
    );
  };

  render() {
    const { survey, handleAddSectionPress, handleClearEditorPress, handleEditHeaderPress, editor } = this.props;
    const requiredQuestionsFilled = this.requiredQuestionsFilled();
    return (
      <Segment className="survey-modal sfb" onClick={handleClearEditorPress}>
        <Header
          className={`editable-component ${editor.target === `header` ? `eactive` : ``}`}
          onClick={handleEditHeaderPress}
        >
          {survey.title}
        </Header>
        <Button size="mini" color="blue" onClick={handleAddSectionPress}>
          Add Section
        </Button>
        <Segment basic className="scrolling">
          <Form>{this.createFormContents()}</Form>
        </Segment>
        <Segment basic textAlign="right">
          {!requiredQuestionsFilled && (
            <Message error content="Please fill in all required fields marked with a red asterisk (*)" />
          )}
          <Button onClick={() => {}} color="blue" id="btn-save" loading={false} disabled={false}>
            Save
          </Button>
        </Segment>
      </Segment>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...modalActions
      },
      dispatch
    )
  };
}

export default connect(
  null,
  mapDispatchToProps
)(SurveyFormBuilder);
