import React, { Component } from "react";
import { Grid, Button, Dropdown, Popup, Icon } from "semantic-ui-react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import { isEmpty, isEqual } from "lodash";
import * as lookupActions from "../../../actions/lookupActions";
import * as modalActions from "../../../actions/modalActions";
import * as contextActions from "../../../actions/contextActions";
import * as templateActions from "../../../actions/templateActions";
import * as chartsActions from "../../../actions/chartsActions";
import * as demographicActions from "../../../actions/demographicActions";
import * as providerActions from "../../../actions/providerActions";
import * as badgeActions from "../../../actions/badgeActions";
import * as visitActions from "../../../actions/visitActions";
import * as userPreferenceActions from "../../../actions/userPreferenceActions";
import { roleGroups } from "../../../constants/securityRoles";
import { checkRoles } from "../../../helpers";
import SurveyList from "./SurveyList";
import SurveyTile from "./SurveyTile";
import {
  isoFormat,
  dateWithTimeFormat,
  TEMPLATE_ID_DISCHARGE_TEMPLATE_ID,
  VISIT_STATUS_VISIT_COMPLETE,
  VISIT_STATUS_VISIT_CANCELED
} from "../../../constants/miscellaneous";
import "./SurveysDashboard.css";
import emrComponent from "../../common/emrComponent";

export class SurveysDashboard extends Component {
  state = {
    loading: false,
    signButtonPressed: false,
    cosignButtonPressed: false,
    updatingSelectedTemplate: false,
    editSelectedTemplate: false
  };
  abortController = new AbortController();
  abortSignal = this.abortController.signal;

  componentDidMount() {
    this._isMounted = true;
    const { tab, patientId, surveysVisitId, signVisitId } = this.props;

    this.props.actions.loadCentersLookup();
    this.props.actions.loadCurrentUserId();
    this.props.actions.loadProvidersAndSuperProvider();

    if (patientId && (tab === "discharge" || surveysVisitId)) {
      /* survey visit id can be null */
      this.load(surveysVisitId, signVisitId, patientId);
    }
    if (patientId && tab === "discharge") {
      this.props.actions.loadPatientDischargeSummary(patientId);
    }
    if (signVisitId) {
      this.loadCoSignInfo(signVisitId);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      tab,
      surveysVisitId,
      signVisitId,
      patientId,
      selectedTemplate,
      selectedTemplateId,
      reloadTemplate,
      patientStatus,
      patientDischargeSummary
    } = this.props;
    if (
      (tab === "discharge" || surveysVisitId) &&
      ((prevProps.selectedTemplateId !== selectedTemplateId && selectedTemplate.templateName !== null) ||
        prevProps.surveysVisitId !== surveysVisitId ||
        prevProps.signVisitId !== signVisitId ||
        !isEqual(patientDischargeSummary, prevProps.patientDischargeSummary) ||
        reloadTemplate)
    ) {
      this.props.actions.setReloadSelectedTemplate(false);
      Promise.all([
        this.props.actions.clearSignedChartDetails(),
        this.props.actions.clearCosignedChartDetails()
        // this.props.actions.clearCounselingVisitDetail()
      ]).then(() => {
        this.load(surveysVisitId, signVisitId, patientId, prevProps.surveysVisitId !== surveysVisitId);
      });
    }
    if (patientId && tab === "discharge" && prevProps.patientStatus !== patientStatus) {
      this.props.actions.loadPatientDischargeSummary(patientId);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.abortController.abort();
    this.props.actions.clearSignedChartDetails();
    this.props.actions.clearCosignedChartDetails();
    // this.props.actions.clearCounselingVisitDetail();
    this.props.actions.setContext("template", { templateId: null });
    this.props.actions.clearTemplates();
  }

  _isMounted = false;

  loadCoSignInfo(visitId) {
    this.props.actions.loadCoSignedChartDetails(visitId);
  }

  load(surveysVisitId, signVisitId, patientId, loadTemplates = true) {
    const { loading, updatingSelectedTemplate } = this.state;
    const { tab, availableTemplates, templateId, patientDischargeSummary } = this.props;

    if (tab === "discharge" && patientDischargeSummary.length === 0) {
      return;
    }

    if (loading) {
      this.abortController.abort();
      this.abortController = new AbortController();
      this.abortSignal = this.abortController.signal;
    }

    this.setState({ loading: true });

    const showSurveyEdit = checkRoles(roleGroups.surveyEdit, this.props.authRoles);
    if (signVisitId) {
      this.props.actions.loadSignedChartDetails(signVisitId);
      this.props.actions.loadVisitSignPermissionDetails(signVisitId);
    }
    let dischargeId = ``;
    if (tab === "discharge") {
      dischargeId = patientDischargeSummary.sort((a, b) =>
        a.dischargedOn < b.dischargedOn ? 1 : a.dischargedOn > b.dischargedOn ? -1 : 0
      )[0].dischargeId;
    }
    const promise =
      tab === "discharge"
        ? this.props.actions.loadSpecificTemplateAndItsSurveys(
            patientId,
            TEMPLATE_ID_DISCHARGE_TEMPLATE_ID,
            "",
            dischargeId,
            true,
            this.abortSignal
          )
        : Promise.resolve(
            loadTemplates
              ? this.props.actions.loadTemplates(this.props.patientId, surveysVisitId, this.abortSignal)
              : Promise.resolve({ templates: { availableTemplates, selectedTemplate: templateId } })
          )
            .then(({ templates }) => {
              if (this._isMounted && templates.selectedTemplate) {
                this.setState({ updatingSelectedTemplate: false });
                this.props.actions.setContext("template", { templateId: templates.selectedTemplate });
                return this.props.actions.loadTemplateSurveys(
                  patientId,
                  surveysVisitId,
                  templates.selectedTemplate,
                  "",
                  this.abortSignal
                );
              }
              if (!updatingSelectedTemplate && showSurveyEdit && templates.availableTemplates.length === 1) {
                this.props.actions.setContext("template", { templateId: templates.availableTemplates[0].templateId });
                return templates.availableTemplates[0]
                  ? this.props.actions
                      .setSelectedTemplate(
                        templates.availableTemplates[0].templateId,
                        templates.availableTemplates[0].version,
                        this.props.patientId,
                        this.props.surveysVisitId,
                        this.abortSignal
                      )
                      .then(() =>
                        this.props.actions.loadTemplateSurveys(
                          this.props.patientId,
                          surveysVisitId,
                          templates.availableTemplates[0].templateId,
                          "",
                          this.abortSignal
                        )
                      )
                  : { template: { surveys: [] } };
              }
              return { template: { surveys: [] } };
            })
            .catch((e) => {
              if (e.message !== "USER_ABORTED") throw e;
            });
    promise
      .then(() => {
        this.props.actions.setReInitSurvey(true);
        if (this._isMounted) {
          this.setState({ loading: false, editSelectedTemplate: isEmpty(this.props.selectedTemplateId) });
        }
      })
      .catch((e) => {
        if (e.message !== "USER_ABORTED") throw e;
      });
  }

  handleModalOpen = (survey) => () => {
    this.props.actions.showModal({
      type: "SURVEY_MODAL",
      props: {
        open: true,
        asModal: true,
        for: "templates",
        tab: this.props.tab,
        survey
      }
    });
  };

  handleTemplateChange = (e, data) => {
    const { availableTemplates } = this.props;
    const { value } = data;
    if (value === this.props.selectedTemplateId) return;
    const template = availableTemplates.find((t) => t.templateId === value);
    this.props.actions.setContext("template", { templateId: template.templateId });
    this.setState({ updatingSelectedTemplate: true });
    this.props.actions
      .setSelectedTemplate(
        template.templateId,
        template.version,
        this.props.patientId,
        this.props.surveysVisitId,
        this.abortSignal
      )
      .then(() => {
        this.setState({ updatingSelectedTemplate: false, editSelectedTemplate: false });
      })
      .catch((_e) => {
        if (_e.message !== "USER_ABORTED") throw e;
      });
  };

  handleQuickReview = (surveyId, surveyVersion) => {
    const { templateId, templateVersion, patientId, surveysVisitId, surveys } = this.props;
    const surveyToUpdate = surveys.find((survey) => survey.id === surveyId);
    const quickReviewAnswers = {
      templateVersion,
      surveyVersion,
      answers: surveyToUpdate.questions.map((question) => ({
        visitId: surveysVisitId,
        questionKey: question.key,
        questionVersion: question.version,
        answers: question.previousAnswers,
        elucidations: question.elucidations
      })),
      templateActions: [...surveyToUpdate.templateActions.templateActions]
    };
    this.props.actions.saveSurvey(templateId, surveyId, patientId, quickReviewAnswers);
  };

  handleSign = () => {
    const signInfo = {
      visitId: this.props.surveysVisitId,
      stateCode: this.props.surveysVisitStateCode,
      providerId: this.props.surveysVisitProviderId
    };
    this.setState({ signButtonPressed: true });
    this.props.actions
      .signChart(this.props.patientId, signInfo)
      .then(() => {
        this.props.actions.loadSignedChartDetails(this.props.surveysVisitId);
        this.loadCoSignInfo(this.props.surveysVisitId);
        // this.loadBadge();
      })
      .catch(() => {
        this.setState({ signButtonPressed: false });
      });
  };

  handleCoSign = () => {
    const { patientId, surveysVisitId } = this.props;
    this.setState({ cosignButtonPressed: true });
    this.props.actions
      .coSignVisit(patientId, surveysVisitId, this.props.surveysVisitProviderId, this.props.surveysVisitStateCode)
      .then(() => {
        this.props.actions.loadCoSignedChartDetails(surveysVisitId);
        // this.props.actions.loadCoSignBadge();
      })
      .catch(() => {
        this.setState({ cosignButtonPressed: false });
      });
  };

  handleClearAllSurveysClick = () => {
    const {
      patientId,
      surveysVisitId,
      actions: { clearVisitSurveys, setReloadSelectedTemplate }
    } = this.props;
    this.props.actions.showModal({
      type: "CONFIRMATION",
      props: {
        open: true,
        icon: "exclamation-triangle",
        title: "Confirm clear all surveys",
        description:
          "Are you sure you want to clear all surveys; If you proceed, the cancelled survey data cannot be restored?",
        buttonMessage: "Clear",
        buttonColor: "red",
        size: "mini",
        onConfirm: () =>
          clearVisitSurveys(patientId, surveysVisitId, true, []).then(() => setReloadSelectedTemplate(true))
      }
    });
  };

  // loadBadge = () => {
  //   this.props.actions.loadVisitsGroupBadge();
  //   // this.props.actions.loadChartBadge();
  // };

  render() {
    const {
      tab,
      patientId,
      availableTemplatesOptions,
      selectedTemplate,
      selectedTemplateId,
      surveys,
      surveysVisitId,
      styles,
      authRoles,
      surveysVisitStatus,
      latestVisitSignedChart,
      cosignDetail,
      visitSignPermissionDetail,
      currentUserId,
      hiddenSurveys
    } = this.props;

    const { signButtonPressed, cosignButtonPressed, updatingSelectedTemplate, editSelectedTemplate } = this.state;

    if (surveys === []) return null;

    const showSign =
      Object.keys(latestVisitSignedChart).length === 0 && currentUserId === visitSignPermissionDetail.providerId;
    const showSurveyEdit = checkRoles(roleGroups.surveyEdit, authRoles);

    const _availableTemplatesOptions = updatingSelectedTemplate
      ? [{ text: "Loading...", value: "__LOADING__" }]
      : availableTemplatesOptions;

    const _selectedTemplateId = updatingSelectedTemplate ? "__LOADING__" : selectedTemplateId;
    const _selectedTemplateObject = _availableTemplatesOptions.find((t) => t.value === _selectedTemplateId);

    // is empty and is super provider
    const showCosign =
      Object.keys(cosignDetail).length === 0 &&
      checkRoles(roleGroups.supProvidersLdac1, authRoles) &&
      currentUserId === visitSignPermissionDetail.superProviderId;

    const showSignContainer =
      showSign ||
      showCosign ||
      Object.keys(latestVisitSignedChart).length !== 0 ||
      (cosignDetail && Object.keys(cosignDetail).length !== 0);

    const surveysToComplete = Array.isArray(surveys) ? surveys.filter((s) => s.mustComplete) : [];
    const surveysToUpdate = Array.isArray(surveys) ? surveys.filter((s) => !s.mustComplete) : [];

    const showClearSurveysButton =
      tab !== "discharge" && ![VISIT_STATUS_VISIT_COMPLETE, VISIT_STATUS_VISIT_CANCELED].includes(surveysVisitStatus);

    return (
      <div className={"content-wrapper surveys-content"}>
        {showSignContainer && surveysVisitStatus === "visitcomplete" && tab !== "discharge" && (
          <div className="sign-chart-container">
            {showCosign && (
              <Button
                color="blue"
                content="Co-Sign"
                onClick={this.handleCoSign}
                loading={cosignButtonPressed}
                disabled={cosignButtonPressed}
              />
            )}
            {showSign && (
              <Button
                color="blue"
                onClick={this.handleSign}
                id="btn-signChart"
                loading={signButtonPressed}
                disabled={signButtonPressed}
              >
                Sign
              </Button>
            )}
            {!latestVisitSignedChart.loading && Object.keys(latestVisitSignedChart).length !== 0 && (
              <React.Fragment>
                <h3>Signed by {latestVisitSignedChart.providerFullName}</h3>
                <p>{moment(latestVisitSignedChart.dateSigned).format("MM/DD/YYYY hh:mm a")}</p>
              </React.Fragment>
            )}
            {cosignDetail && !cosignDetail.loading && Object.keys(cosignDetail).length !== 0 && (
              <React.Fragment>
                <div className="cosign-info">
                  <h3>Co-signed by {cosignDetail.supervisingProviderFullName}</h3>
                  <p>{moment(cosignDetail.dateSigned, isoFormat).format(dateWithTimeFormat)}</p>
                </div>
              </React.Fragment>
            )}
          </div>
        )}
        {(tab === "discharge" || updatingSelectedTemplate || Object.keys(latestVisitSignedChart).length === 0) && (
          <Grid>
            <Grid.Row>
              {showSurveyEdit && (
                <React.Fragment>
                  <Grid.Column width={4}>
                    {_availableTemplatesOptions.length > 0 && (
                      <React.Fragment>
                        <strong>
                          <label style={{ display: "block", margin: "10px 0" }}>
                            {_availableTemplatesOptions.length > 2 || _selectedTemplateId === "__LOADING__"
                              ? `Templates`
                              : `Template`}
                          </label>
                        </strong>
                        {!editSelectedTemplate && _availableTemplatesOptions.length > 1 && (
                          <Popup
                            trigger={
                              <Button
                                className="transparent-button"
                                id="btn-visit-status"
                                size="small"
                                onClick={() => {
                                  this.setState({ editSelectedTemplate: true });
                                }}
                              >
                                <Icon name="edit" />
                                {selectedTemplate.templateName}
                              </Button>
                            }
                            content="Change Template"
                          />
                        )}
                        {editSelectedTemplate &&
                          (_availableTemplatesOptions.length > 1 || _selectedTemplateId === "__LOADING__") && (
                            <Dropdown
                              fluid
                              selection
                              options={_availableTemplatesOptions}
                              id="input-template"
                              placeholder="Select..."
                              name="template"
                              value={_selectedTemplateId}
                              onChange={this.handleTemplateChange}
                              disabled={!isEmpty(hiddenSurveys)}
                            />
                          )}
                        {_availableTemplatesOptions.length < 2 && _selectedTemplateId !== "__LOADING__" && (
                          <strong>
                            <label
                              style={{
                                display: "block",
                                margin: "10px 0",
                                padding: ".5rem",
                                borderRadius: "5px",
                                backgroundColor: "white"
                              }}
                            >
                              {_selectedTemplateObject ? _selectedTemplateObject.text : ``}
                            </label>
                          </strong>
                        )}
                      </React.Fragment>
                    )}

                    {surveysToComplete.length !== 0 && (
                      <React.Fragment>
                        <label style={{ display: "block", margin: "10px 0" }}>Surveys to complete:</label>
                        <SurveyList
                          patientId={patientId}
                          surveys={surveysToComplete}
                          handleModalOpen={this.handleModalOpen}
                          hiddenSurveys={hiddenSurveys}
                          templateId={selectedTemplateId}
                        />
                      </React.Fragment>
                    )}

                    {surveysToUpdate.length !== 0 && (
                      <React.Fragment>
                        <label style={{ display: "block", margin: "10px 0" }}>Surveys to update:</label>
                        <SurveyList
                          patientId={patientId}
                          surveys={surveysToUpdate}
                          handleModalOpen={this.handleModalOpen}
                          hiddenSurveys={hiddenSurveys}
                          templateId={selectedTemplateId}
                        />
                      </React.Fragment>
                    )}
                  </Grid.Column>
                  <Grid.Column width={12}>
                    {Array.isArray(surveys) &&
                      surveys.length > 0 &&
                      showSurveyEdit &&
                      surveys.some((survey) => survey.hasAnswersFromContextContainer) && (
                        <Grid columns={2} style={{ paddingTop: "1rem" }}>
                          <Grid.Column className="survey-tile no-v-padding">
                            {showClearSurveysButton && (
                              <Button
                                title="Clear all surveys"
                                content="Clear all surveys"
                                className="transparent-button-icon"
                                onClick={this.handleClearAllSurveysClick}
                                color="red"
                                icon="eraser"
                                id="btn-all-clear-survey"
                              />
                            )}
                          </Grid.Column>
                        </Grid>
                      )}
                    {Array.isArray(surveys) &&
                      surveys.map((survey) => (
                        <SurveyTile
                          key={survey.id}
                          tab={tab}
                          survey={survey}
                          styles={styles}
                          surveysVisitId={surveysVisitId}
                          handleQuickReview={this.handleQuickReview}
                          handleModalOpen={this.handleModalOpen}
                          showSurveyEdit={showSurveyEdit}
                          showClearSurveysButton={showClearSurveysButton}
                        />
                      ))}
                  </Grid.Column>
                </React.Fragment>
              )}
            </Grid.Row>
          </Grid>
        )}
      </div>
    );
  }
}

function mapStateToProps(state, props) {
  const { tab } = props;
  const contextVisit = state.visits.contextVisit;
  const latestVisit = state.visits.latestVisit;
  const surveysVisit = tab === "discharge" ? null : contextVisit || latestVisit;
  const signVisit = contextVisit || latestVisit;

  const surveysVisitStatus = surveysVisit && surveysVisit.visitStatus ? surveysVisit.visitStatus.visitStatus : null;
  const surveysVisitProviderId =
    surveysVisit && surveysVisit.prescribingProvider ? surveysVisit.prescribingProvider.providerId : null;

  const availableTemplatesOptions = state.templates.availableTemplates
    ? state.templates.availableTemplates.map((t) => ({ text: t.templateDisplayName, value: t.templateId }))
    : [];
  const selectedTemplateId = state.templates.selectedTemplate ? state.templates.selectedTemplate.templateId : null;
  const surveysVisitCenter = surveysVisit ? state.lookups.centers.find((c) => c.value === surveysVisit.centerId) : null;
  const surveysVisitStateCode = surveysVisit && surveysVisitCenter ? surveysVisitCenter.state : null;

  return {
    authRoles: state.auth.user.profile.roles,
    patientId: state.patient.currentPatient.patientId,
    surveys: state.templates.selectedTemplate ? state.templates.selectedTemplate.surveys : [],
    styles: state.templates.styles || [],
    templateId: selectedTemplateId,
    templateVersion: state.templates.selectedTemplate ? state.templates.selectedTemplate.version : null,
    availableTemplates: state.templates.availableTemplates,
    availableTemplatesOptions,
    selectedTemplate: state.templates.selectedTemplate,
    selectedTemplateId,
    surveysVisitId: surveysVisit ? surveysVisit.visitId : null,
    surveysVisitStateCode,
    surveysVisitType: surveysVisit ? surveysVisit.visitType : null,
    surveysVisitStatus,
    surveysVisitProviderId,
    signVisitId: signVisit ? signVisit.visitId : null,
    signVisitType: signVisit ? signVisit.visitType : null,
    latestVisitSignedChart: state.charts.latestVisitSignedChart,
    providers: state.providers.allProviders,
    cosignDetail: state.charts.cosignDetail,
    // listProviderCoSign: state.charts.listProviderCoSign,
    visitSignPermissionDetail: state.visits.visitSignPermissionDetail || {},
    currentUserId: state.userPreference.currentUserId,
    hiddenSurveys: state.templates.hiddenSurveys,
    reloadTemplate: state.templates.reloadTemplate,
    patientStatus: state.demographics.status,
    patientDischargeSummary: state.demographics.patientDischargeSummary
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...lookupActions,
        ...modalActions,
        ...contextActions,
        ...templateActions,
        ...chartsActions,
        ...demographicActions,
        ...providerActions,
        ...badgeActions,
        ...visitActions,
        ...userPreferenceActions
      },
      dispatch
    )
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(emrComponent(SurveysDashboard));
