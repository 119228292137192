import "react-toastify/dist/ReactToastify.css";
import * as types from "./actionTypes";
import { beginAjaxCall, ajaxCallDone } from "./ajaxStatusActions";
import { API_URL } from "../constants/environment";
import { pollApiEvents, processApiResponse, processApiError } from "../helpers";
import { adalApiFetch } from "../adalConfig";

function loadNotesSuccess(patientId, notes) {
  return {
    type: types.LOAD_NOTES_SUCCESS,
    patientId,
    notes
  };
}

function createNoteSuccess(notes) {
  return {
    type: types.CREATE_NOTE_SUCCESS,
    notes
  };
}

export function createNote(patientId, noteText, noteType) {
  return dispatch => {
    const note = {
      noteText,
      noteType
    };

    dispatch(beginAjaxCall("createNote"));
    return adalApiFetch(`${API_URL}/Notes/patients/${patientId}/`, {
      method: "POST",
      body: JSON.stringify(note)
    })
      .then(response => processApiResponse(response.orchestrationId))
      .then(orchestrationId => pollApiEvents(orchestrationId, "INoteCreatedEvent"))
      .then(response => dispatch(createNoteSuccess(response)))
      .catch(error => processApiError(error, dispatch))
      .finally(() => dispatch(ajaxCallDone()));
  };
}

export function loadNotes(patientId, noteType) {
  return dispatch => {
    dispatch(beginAjaxCall("loadNotes"));
    return adalApiFetch(`${API_URL}/Notes/patients/${patientId}/types/${noteType}`)
      .then(notes => dispatch(loadNotesSuccess(patientId, notes)))
      .catch(error => processApiError(error, dispatch))
      .finally(() => dispatch(ajaxCallDone()));
  };
}
