import { cloneDeep, isEmpty, set } from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import MaskedInput from "react-text-mask";
import { bindActionCreators } from "redux";
import { DateInput } from "semantic-ui-calendar-react";
import moment from "moment";
import { Button, Form, Grid, Icon, Segment } from "semantic-ui-react";
import * as centerActions from "../../actions/centerActions";
import { zip4Mask, zipMask } from "../../constants/validation";
import { dateFormat, dateWithTimeFormat, emptyGuid, isoDateFormat, isoFormat } from "../../constants/miscellaneous";

export class NewCenterFormDetailsTabAddressHistoryView extends Component {
  state = {
    address: {
      centerId: this.props.center.centerId,
      addressId: "",
      address1: "",
      address2: "",
      city: "",
      state: "",
      zip: "",
      zip4: "",
      startDate: "",
      endDate: ""
    },
    history: [],
    newAddressClicked: false
  };

  componentDidMount() {
    this._isMounted = true;
    this.props.actions.fetchCenterAddressHistory(this.props.center.centerId).then(() => {
      this.setState({ history: this.props.centerAddressHistory.map(i => ({ ...i, edit: false })) });
    });
  }

  componentDidUpdate() {}

  componentWillUnmount() {
    this._isMounted = false;
    this.props.actions.clearCenterAddressHistory();
  }

  _isMounted = false;

  handleFocus = e => {
    e.target.setAttribute("autocomplete", "nope");
  };

  handleInput = (e, data) => {
    const updatedState = cloneDeep(this.state);
    set(updatedState, data.name, data.value);
    if (data.name === "address.startDate") {
      updatedState.address.startDate = isEmpty(data.value) ? `` : moment(data.value, dateFormat).format(isoDateFormat);
    }
    if (data.name === "address.endDate") {
      updatedState.address.endDate = isEmpty(data.value) ? `` : moment(data.value, dateFormat).format(isoDateFormat);
    }
    if (this._isMounted) this.setState(updatedState);
  };

  handleMaskedInput = e => {
    const {
      target: { name: field, value }
    } = e;

    const state = { ...this.state };
    set(state, field, value);
    this.setState(state);
  };

  handleNewAddress = () => {
    const address = {
      addressId: emptyGuid,
      centerId: this.props.center.centerId,
      address1: "",
      address2: "",
      city: "",
      state: "",
      zip: "",
      zip4: "",
      startDate: "",
      endDate: "",
      edit: true
    };
    this.setState({
      address,
      history: [{ ...address }, ...this.state.history.map(i => ({ ...i, edit: false }))],
      newAddressClicked: true
    });
  };

  handleEditAddress = addressId => {
    this.setState({
      address: { ...this.state.history.find(i => i.addressId === addressId) },
      history: this.state.history.map(i => (i.addressId === addressId ? { ...i, edit: true } : { ...i, edit: false }))
    });
  };

  handleCancelEditAddress = () => {
    const history = this.state.history.map(i => ({ ...i, edit: false }));
    let newAddressClicked = this.state.newAddressClicked;
    if (history[0].addressId === emptyGuid) {
      history.shift();
      newAddressClicked = false;
    }
    this.setState({ history, newAddressClicked });
  };

  handleSaveAddress = () => {
    this.props.actions
      .saveCenterAddressHistory({
        ...this.state.address,
        addressId: this.state.address.addressId === emptyGuid ? null : this.state.address.addressId,
        startDate: isEmpty(this.state.address.startDate)
          ? ``
          : `${moment(this.state.address.startDate)
              .startOf("day")
              .format(isoFormat)}`,
        endDate: isEmpty(this.state.address.endDate)
          ? ``
          : `${moment(this.state.address.endDate)
              .endOf("day")
              .format(isoFormat)}`
      })
      .then(() => {
        this.setState({
          newAddressClicked: false,
          history: this.props.centerAddressHistory.map(i => ({ ...i, edit: false }))
        });
      });
  };

  renderAddressForm = () => {
    const { states: stateOptions, processing: loading } = this.props;
    const { address } = this.state;
    const disableSaveButton =
      isEmpty(address.startDate) || isEmpty(address.address1) || isEmpty(address.state) || isEmpty(address.zip);
    return (
      <React.Fragment>
        <Form.Group widths="equal">
          <Form.Field required>
            <label style={{ display: "inline-block", width: "40%" }}>Effective from</label>
            <div style={{ display: "inline-block", width: "60%" }}>
              <DateInput
                name="address.startDate"
                onChange={this.handleInput}
                value={isEmpty(address.startDate) ? "" : moment(address.startDate).format(dateFormat)}
                placeholder="Select Date"
                dateFormat={dateFormat}
                iconPosition="right"
                hideMobileKeyboard
                closable
              />
            </div>
          </Form.Field>
          <Form.Field>
            <label style={{ display: "inline-block", width: "40%" }}>To</label>
            <div style={{ display: "inline-block", width: "60%" }}>
              <DateInput
                name="address.endDate"
                onChange={this.handleInput}
                value={isEmpty(address.endDate) ? "" : moment(address.endDate).format(dateFormat)}
                placeholder="Select Date"
                dateFormat={dateFormat}
                iconPosition="right"
                hideMobileKeyboard
                closable
              />
            </div>
          </Form.Field>
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Field>
            <Form.Input
              label="Address 1"
              name="address.address1"
              value={address.address1}
              onChange={this.handleInput}
              required
            />
          </Form.Field>
          <Form.Field>
            <Form.Input
              label="Address 2"
              name="address.address2"
              placeholder="Optional"
              value={address.address2 || ""}
              onChange={this.handleInput}
            />
          </Form.Field>
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Field>
            <Form.Input
              label="City"
              id="input-city"
              name="address.city"
              value={address.city}
              onChange={this.handleInput}
            />
          </Form.Field>
          <Form.Field>
            <Form.Dropdown
              fluid
              selection
              search
              options={stateOptions}
              id="input-state"
              placeholder="Select..."
              label="State"
              name="address.state"
              value={address.state}
              onChange={this.handleInput}
              onFocus={this.handleFocus}
              required
            />
          </Form.Field>
          <Form.Field>
            <label>Zip</label>
            <MaskedInput
              name="address.zip"
              mask={zipMask}
              guide={false}
              value={address.zip}
              onChange={this.handleMaskedInput}
              required
            />
          </Form.Field>
          <Form.Field>
            <label>Zip4</label>
            <MaskedInput
              name="address.zip4"
              mask={zip4Mask}
              guide={false}
              value={address.zip4}
              onChange={this.handleMaskedInput}
            />
          </Form.Field>
        </Form.Group>
        <div style={{ textAlign: "right" }}>
          <Button size="mini" color="red" content="Cancel" onClick={this.handleCancelEditAddress} disabled={loading} />
          <Button
            size="mini"
            color="green"
            content="Save Address"
            onClick={this.handleSaveAddress}
            disabled={loading || disableSaveButton}
          />
        </div>
      </React.Fragment>
    );
  };

  render() {
    const { history, newAddressClicked } = this.state;
    return (
      <React.Fragment>
        <h3>Address History</h3>
        {!newAddressClicked && (
          <Segment vertical>
            <Button
              size="mini"
              color="blue"
              content="New Address"
              onClick={this.handleNewAddress}
              disabled={history.filter(i => isEmpty(i.endDate)).length > 0}
            />
          </Segment>
        )}
        {history.map(item => (
          <Segment key={item.addressId} vertical>
            {item.edit ? (
              this.renderAddressForm(item)
            ) : (
              <Grid>
                <Grid.Row>
                  <Grid.Column width={15}>
                    <p style={{ marginBottom: ".5em" }}>
                      <b>Effective from: </b>
                      {isEmpty(item.startDate) ? (
                        <i>not set</i>
                      ) : (
                        <i>{moment(item.startDate).format(dateWithTimeFormat)}</i>
                      )}
                      <b> to: </b>
                      {isEmpty(item.endDate) ? (
                        <i>still active</i>
                      ) : (
                        <i>{moment(item.endDate).format(dateWithTimeFormat)}</i>
                      )}
                    </p>
                    {!isEmpty(item.address1) && <p style={{ marginBottom: ".5em" }}>{item.address1}</p>}
                    {!isEmpty(item.address2) && <p style={{ marginBottom: ".5em" }}>{item.address2}</p>}
                    <p style={{ marginBottom: ".5em" }}>{`${!isEmpty(item.city) ? item.city : ``}${
                      !isEmpty(item.state) ? `, ${item.state}` : ``
                    }${!isEmpty(item.zim) ? `, ${item.zip}` : ``}${!isEmpty(item.zip4) ? `, ${item.zip4}` : ``}`}</p>
                  </Grid.Column>
                  <Grid.Column width={1} textAlign="right">
                    <Icon
                      title="Edit"
                      name="edit"
                      color="blue"
                      className="clickable"
                      onClick={() => {
                        this.handleEditAddress(item.addressId);
                      }}
                      style={{ margin: "2px" }}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            )}
          </Segment>
        ))}
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    centerAddressHistory: state.centers.centerAddressHistory,
    states: state.lookups.states,
    processing: state.ajaxCallsInProgress > 0
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...centerActions }, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewCenterFormDetailsTabAddressHistoryView);
