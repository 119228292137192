import { API_URL } from "../constants/environment";
import { adalApiFetch } from "../adalConfig";

export const getNotifications = () => adalApiFetch(`${API_URL}/Notifications?notificationType=inbox`);

export const dismissNotification = notificationId => adalApiFetch(`${API_URL}/Notifications/${notificationId}/Dismiss`);

export const resetNotifications = () =>
  adalApiFetch(`${API_URL}/Notifications/Reset?notificationType=inbox`, {
    method: "POST"
  });

export default { getNotifications, resetNotifications };
