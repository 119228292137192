import initialState from "./initialState";
import * as types from "../actions/actionTypes";

export default function modalReducer(state = initialState.reports, action) {
  switch (action.type) {
    case types.LOAD_REPORTS_SUCCESS:
      return Object.assign({}, state, { reportGroups: action.reports });
    case types.LOAD_REPORTROLES_SUCCESS:
      return Object.assign({}, state, { reportGroups: action.reportGroups });
    case types.LOAD_REPORTTOKENS_SUCCESS:
      return Object.assign({}, state, {
        token: action.token
      });
    case types.SET_CLAIM_RECONCILIATIONS:
      return { ...state, claimReconciliations: action.data };
    case types.SET_ALL_CLAIM_RECONCILIATIONS:
      return { ...state, allClaimReconciliations: action.data };
    case types.SUBMIT_ATHENA_CLAIM_SUCCESS:
      return {
        ...state,
        claimReconciliations: {
          ...state.claimReconciliations,
          visitClaimsList: state.claimReconciliations.visitClaimsList.map(vc => {
            const visitIds = action.athenaVisitClaimItems.map(avc => avc.visitId);
            if (!visitIds.includes(vc.visitId)) return { ...vc };
            return {
              ...vc,
              claimStatus: "InProgress"
            };
          })
        }
      };
    case types.SET_REVIEW_OF_PATIENTS:
      return { ...state, reviewOfPatients: action.data };
    case types.SET_ALL_REVIEW_OF_PATIENTS:
      return { ...state, allReviewOfPatients: action.data };
    case types.SET_REPORT_DATA:
      return {
        ...state,
        report: { ...state.report, count: action.response.count, currentPageRows: action.response.data }
      };
    case types.SET_REPORT_ALL_DATA:
      return { ...state, report: { ...state.report, count: action.response.count, allRows: action.response.data } };
    default:
      return state;
  }
}
