import React, { Component } from "react";
import { Grid, Button } from "semantic-ui-react";
// import loadImage from "blueimp-load-image";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as modalActions from "../../../../actions/modalActions";
import * as patientActions from "../../../../actions/patientActions";

import greyMan from "../../../../assets/patients/current/grey-man.jpg";

// Current accepted file types
const fileTypes = ["image/jpeg", "image/pjpeg", "image/png"];

export class PatientPhoto extends Component {
  state = {
    fileIsValid: true
  };

  handleModalOpen = () =>
    this.props.actions.showModal({
      type: "PATIENT_PHOTO",
      props: { open: true }
    });

  fileUploadClick = () => {
    document.getElementById("uploadImage").click();
  };

  updateImageDisplay = () => {
    const input = document.getElementById("uploadImage");
    const currentFile = input.files[0];
    // make sure currentFile isn't undefined
    if (typeof currentFile !== "undefined") {
      // check if is jpg or png
      if (this.validImageFile(currentFile)) {
        //  this.loadImageThumbnail(currentFile);
        this.convertToBase64(currentFile);

        this.setState({
          fileIsValid: true
        });
      } else {
        this.setState({
          fileIsValid: false
        });
      }
    }
  };

  // code to rotate files on upload - probably not necessary
  // loadImageThumbnail = currentFile => {
  //   loadImage(
  //     currentFile,
  //     img => {
  //       const patientPhoto = document.getElementById("patientPhoto");
  //       while (patientPhoto.firstChild) {
  //         patientPhoto.removeChild(patientPhoto.firstChild);
  //       }
  //       patientPhoto.appendChild(img);
  //     },
  //     {
  //       maxWidth: 120,
  //       orientation: true,
  //       crop: true,
  //       cover: true,
  //       maxHeight: 120
  //     }
  //   );
  // };

  validImageFile = (file) => {
    for (let i = 0; i < fileTypes.length; i++) {
      if (file.type === fileTypes[i]) {
        return true;
      }
    }
    return false;
  };

  convertToBase64 = (currentFile) => {
    const fileReader = new FileReader();
    if (fileReader && currentFile) {
      fileReader.readAsDataURL(currentFile);
      fileReader.onload = () => {
        this.updatePatientPhoto(fileReader.result);
      };
      fileReader.onerror = () => {};
    }
  };

  updatePatientPhoto = (base64) => {
    const { patientId } = this.props;
    const imgPost = { image64: base64 };
    this.props.actions.createPhoto(patientId, imgPost);
  };

  render() {
    const { fileIsValid } = this.state;
    const { image64 } = this.props.patient;

    return (
      <Grid doubling stackable className="picture-accordion-content">
        <Grid.Row columns={3}>
          <Grid.Column width={4}>
            <div
              id="patientPhoto"
              className="patient-photo"
              style={image64 === null ? { backgroundImage: `url(${greyMan})` } : { backgroundImage: `url(${image64})` }}
            />
            {fileIsValid ? null : <div>This is not a valid file type. Please update your selection.</div>}
          </Grid.Column>
          <Grid.Column width={6}>
            Take a photo
            <Button onClick={this.handleModalOpen} id="btn-open-webcam" color="blue">
              Open Webcam
            </Button>
          </Grid.Column>
          <Grid.Column width={6}>
            Upload Existing Photo
            <input
              id="uploadImage"
              onChange={this.updateImageDisplay}
              type="file"
              accept=".jpg, .jpeg, .png"
              style={{ display: "none" }}
            />
            <Button onClick={this.fileUploadClick} id="btn-open-upload" color="blue">
              Select and Upload
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  return {
    patientId: state.patient.currentPatient.patientId,
    patient: state.patient.currentPatient
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...modalActions, ...patientActions }, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PatientPhoto);
