import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function errorLogsReducer(state = initialState.admin, action) {
  switch (action.type) {
    case types.SET_SHADOW_QUEUE:
      return { ...state, shadowQueue: action.shadowQueue };
    case types.SET_PROXY_REQUESTS:
      return { ...state, proxyRequests: action.proxyRequests };
    case types.PUSH_ITEM_TO_BREADCRUMB: {
      const index = state.breadcrumb.findIndex(i => i.key === action.item.key);

      return {
        ...state,
        breadcrumb:
          index === -1
            ? [...state.breadcrumb.map(i => ({ ...i, active: false })), { ...action.item, active: true }]
            : [
                ...state.breadcrumb.slice(0, index).map(i => ({ ...i, active: false })),
                { ...action.item, active: true }
              ]
      };
    }
    default:
      return state;
  }
}
