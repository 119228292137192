import React, { Component } from "react";
import { Modal, Button, Form, Icon } from "semantic-ui-react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { updateErrors, requiredFieldsFilled, getLookupText } from "../../../helpers";
import * as modalActions from "../../../actions/modalActions";
import * as userPreferenceActions from "../../../actions/userPreferenceActions";
import "./VisitCenterConflictModal.css";

const inputValidation = {
  required: ["visitCenter"]
};

export class VisitCenterConflictModal extends Component {
  state = {
    center: {
      visitCenter: this.props.patientCenter
    },
    errors: {}
  };

  handleVisitModalOpen = () => {
    const { center } = this.state;
    const { patientCenter } = this.props;
    if (center.visitCenter === patientCenter) {
      this.props.actions.updateCenterContext(patientCenter);
    }
    return this.props.actions.showModal({
      type: "NEW_VISIT",
      props: {
        open: true,
        centerId: center.visitCenter,
        newPatient: this.props.newPatient
      }
    });
  };

  handleCancel = () => this.props.actions.hideModal();

  handleInput = (e, data) => {
    const { name: field, value, required } = data || e.target;
    const errors = updateErrors(field, value, required, this.state.errors, inputValidation[field]);
    const center = Object.assign({}, this.state.center, {
      visitCenter: value
    });
    this.setState({
      center,
      errors
    });
  };

  render() {
    const { center } = this.state;
    const {
      open,
      patient: { patientFirstName, patientLastName },
      centerContext,
      patientCenter,
      centers
    } = this.props;

    const requiredFieldsCheck = requiredFieldsFilled(inputValidation.required, center);

    return (
      <Modal open={open} size={"small"} className="center-conflict-modal" closeIcon onClose={this.handleCancel}>
        <Modal.Header>{`New Visit: ${patientFirstName} ${patientLastName}`}</Modal.Header>
        <Modal.Content>
          <div className="center-conflict-header">
            <Icon name="exclamation circle" />
            Your Selected Center and the Patient&#39;s Main Center are different
          </div>
          <Form>
            <h4>Choose the correct Center for this visit</h4>

            <Form.Group grouped>
              <label>Patient&#39;s Main Center*</label>
              <Form.Radio
                label={getLookupText(patientCenter, centers)}
                name="visitCenter"
                value={patientCenter}
                checked={center.visitCenter === patientCenter}
                id="input-patientCenter"
                onClick={this.handleInput}
              />
              <label htmlFor="input-centerContext">Your Selected Center</label>
              <Form.Radio
                label={getLookupText(centerContext, centers)}
                name="visitCenter"
                value={centerContext}
                checked={center.visitCenter === centerContext}
                id="input-centerContext"
                onClick={this.handleInput}
              />
            </Form.Group>
          </Form>
          <i>*If Patient&#39;s Main Center is selected, your current Selected Center will be updated</i>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.handleCancel} content="Cancel" id="button-cancel" />
          <Button
            color="blue"
            onClick={this.handleVisitModalOpen}
            content="Next"
            id="button-next"
            disabled={requiredFieldsCheck}
          />
        </Modal.Actions>
      </Modal>
    );
  }
}

function mapStateToProps(state, ownProps) {
  let patient = {
    patientFirstName: "",
    patientLastName: "",
    patientId: ""
  };

  patient = ownProps.newPatient ? state.patient.newPatient : state.patient.currentPatient;

  return {
    patient,
    centers: state.lookups.centers
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...modalActions,
        ...userPreferenceActions
      },
      dispatch
    )
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(VisitCenterConflictModal);
