import React, { Component } from "react";
import { List, Grid } from "semantic-ui-react";
import { NavLink } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import * as adminActions from "../../actions/adminActions";

import "./AdminOptionsPage.css";

const dummyDescription = `Leverage agile frameworks to provide a robust synopsis for high 
level overview.`;

export class AdminOptionsPage extends Component {
  constructor(props) {
    super(props);
    const {
      actions: { pushItemToBreadcrumb }
    } = this.props;
    pushItemToBreadcrumb({ key: "bc-admin", to: "/admin", text: "Admin" });
  }

  render() {
    const { match, showUserManagement, showAdminOptions } = this.props;
    return (
      <div className="admin-options">
        <div className="header-wrapper">
          <h2>Admin Options</h2>
        </div>
        <div className="content-wrapper" style={{ padding: "1.5rem 2rem" }}>
          <Grid centered columns={2}>
            <Grid.Column width={8}>
              <Grid.Row>
                <List selection>
                  {showAdminOptions && (
                    <List.Item as={NavLink} to={`${match.path}/manage-centers`}>
                      <List.Content>
                        <List.Header className="list-link-header">Manage Centers</List.Header>
                        <List.Description>{dummyDescription}</List.Description>
                      </List.Content>
                    </List.Item>
                  )}
                  {showUserManagement && (
                    <List.Item as={NavLink} to={`${match.path}/manage-users`}>
                      <List.Content>
                        <List.Header className="list-link-header">User Management</List.Header>
                        <List.Description>
                          Set NPI, DEA#, XDEA#, Title, # hours after login active cosign for, Max suboxone pt,
                          Signature, Supervising provider, Percent of charts to sign, and settings for other users.
                        </List.Description>
                      </List.Content>
                    </List.Item>
                  )}
                  {showAdminOptions && (
                    <List.Item as={NavLink} to={`${match.path}/report-permission`}>
                      <List.Content>
                        <List.Header className="list-link-header">Report Permissions by Role</List.Header>
                        <List.Description>Designate with user roles can access which reports</List.Description>
                      </List.Content>
                    </List.Item>
                  )}
                </List>
              </Grid.Row>
            </Grid.Column>
            <Grid.Column width={8}>
              {showAdminOptions && (
                <Grid.Row>
                  <List selection>
                    <List.Item as={NavLink} to={`${match.path}/error-admin`}>
                      <List.Content>
                        <List.Header className="list-link-header">Error Admin</List.Header>
                        <List.Description>Identify, search for, dismiss and remediate system errors</List.Description>
                      </List.Content>
                    </List.Item>
                    <List.Item as={NavLink} to={`${match.path}/shadow-queue`}>
                      <List.Content>
                        <List.Header className="list-link-header">Shadow Queue</List.Header>
                        <List.Description>Manage Shadow Queue</List.Description>
                      </List.Content>
                    </List.Item>
                    <List.Item as={NavLink} to={`${match.path}/proxy-requests`}>
                      <List.Content>
                        <List.Header className="list-link-header">Proxy Requests</List.Header>
                        <List.Description>Manage Proxy Requests</List.Description>
                      </List.Content>
                    </List.Item>
                  </List>
                </Grid.Row>
              )}
            </Grid.Column>
          </Grid>
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...adminActions }, dispatch)
  };
}

export default connect(
  null,
  mapDispatchToProps
)(AdminOptionsPage);
