import React, { Component } from "react";
import { Button, Header, Modal, Form } from "semantic-ui-react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { DateInput } from "semantic-ui-calendar-react";
import { dateFormat } from "../../../../constants/miscellaneous";
import * as modalActions from "../../../../actions/modalActions";
import * as lookupActions from "../../../../actions/lookupActions";
import { dateRegEx } from "../../../../constants/validation";
import { requiredFieldsFilled, updateErrorsWithStartEndDates, hasProperty } from "../../../../helpers";

const inputValidation = {
  required: ["insurer", "policyNumber"],
  startDate: dateRegEx,
  endDate: dateRegEx
};

export class PatientInsuranceModal extends Component {
  state = {
    insurance: this.props.insurance || {
      insurer: "",
      policyNumber: "",
      policyGroup: "",
      startDate: "",
      endDate: ""
    },
    errors: {}
  };

  componentDidMount() {
    this.props.actions.loadInsuranceProviders();
  }

  getInsuranceType = (insuranceType) => {
    switch (insuranceType) {
      case "primaryInsurance":
        return "Primary";
      case "secondaryInsurance":
        return "Secondary";
      case "tertiaryInsurance":
        return "Tertiary";
      case "nonBillableInsurance":
        return "Non-Billable";
      default:
        return "";
    }
  };

  handleClose = () => this.props.actions.hideModal();

  handleFocus = (e) => {
    e.target.setAttribute("autocomplete", "nope");
  };

  handleNav = () =>
    this.props.actions.showModal({
      type: "PATIENT_INSURANCE_ADDRESS",
      props: {
        open: true,
        isNew: true,
        insuranceType: this.props.insuranceType,
        insurance: this.state.insurance
      }
    });

  handleInput = (e, data) => {
    const { name: field, value, required } = data;
    const { startDate, endDate } = this.state.insurance;
    const errors = updateErrorsWithStartEndDates(
      field,
      value,
      required,
      this.state.errors,
      inputValidation,
      startDate,
      endDate
    );
    const insurance = Object.assign({}, this.state.insurance, {
      [field]: value
    });
    this.setState({ insurance, errors });
  };

  handleMaskedInput = (e) => {
    const {
      type,
      target: { name: field, required, value }
    } = e;
    const { startDate, endDate } = this.state.insurance;
    let errors = Object.assign({}, this.state.errors);
    const insurance = Object.assign({}, this.state.insurance, {
      [field]: value
    });

    if (type !== "focus" && type !== "blur") {
      errors = updateErrorsWithStartEndDates(
        field,
        value,
        required,
        this.state.errors,
        inputValidation,
        startDate,
        endDate
      );
    }

    this.setState({ insurance, errors });
  };

  render() {
    const { insurance, errors } = this.state;
    const { open, insuranceType, insuranceProviders } = this.props;
    const requiredFieldsCheck = requiredFieldsFilled(inputValidation.required, insurance);
    const disableNext = requiredFieldsCheck || Object.keys(errors).length !== 0;

    return (
      <Modal className="patient-insurance-modal" open={open} onClose={this.handleClose} closeIcon size="small">
        <Header content={`${this.getInsuranceType(insuranceType)} Insurance`} />
        <Modal.Content>
          <Form>
            <Form.Group widths="equal">
              <Form.Field>
                <Form.Dropdown
                  required
                  fluid
                  selection
                  search
                  options={insuranceProviders}
                  label="Insurer"
                  name="insurer"
                  placeholder="Select..."
                  value={insurance.insurer}
                  onChange={this.handleInput}
                  error={hasProperty(errors, "insurer")}
                  id="input-insurer"
                  onFocus={this.handleFocus}
                />
              </Form.Field>
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Field>
                <Form.Input
                  required
                  label="Policy #"
                  name="policyNumber"
                  placeholder={errors.policyNumber}
                  value={insurance.policyNumber}
                  maxLength={50}
                  onChange={this.handleInput}
                  error={hasProperty(errors, "policyNumber")}
                  id="input-policyNumber"
                />
              </Form.Field>
              <Form.Field>
                <Form.Input
                  label="Group #"
                  name="policyGroup"
                  value={insurance.policyGroup}
                  maxLength={50}
                  onChange={this.handleInput}
                  id="input-policyGroup"
                />
              </Form.Field>
            </Form.Group>
            <Form.Group>
              <Form.Field width={8} className={hasProperty(errors, "startDate") ? "error field" : "field"}>
                <label htmlFor="input-startDate">Start Date</label>
                <DateInput
                  id="input-startDate"
                  name="startDate"
                  placeholder="Start Date"
                  value={insurance.startDate}
                  dateFormat={dateFormat}
                  iconPosition="right"
                  onChange={this.handleInput}
                  hideMobileKeyboard
                  closable
                />
              </Form.Field>
              <Form.Field width={8} className={hasProperty(errors, "endDate") ? "error field" : "field"}>
                <label>End Date</label>
                <DateInput
                  id="input-endDate"
                  name="endDate"
                  placeholder="End Date"
                  value={insurance.endDate}
                  dateFormat={dateFormat}
                  iconPosition="right"
                  onChange={this.handleInput}
                  hideMobileKeyboard
                  closable
                />
              </Form.Field>
            </Form.Group>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.handleClose} id="btn-cancel">
            Cancel
          </Button>
          <Button onClick={this.handleNav} color="blue" id="btn-next" disabled={disableNext}>
            Next
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

// function formatInsuranceProvForDropdown(insuranceProviders) {
//   return insuranceProviders.map(insuranceProvider => {
//     return {
//       value: insuranceProvider.text,
//       text: insuranceProvider.text
//     };
//   });
// }

function formatInsuranceProvForDropdown(insuranceProviders) {
  return insuranceProviders.map((insuranceProvider) => ({
    value: insuranceProvider.value.toString(),
    text: insuranceProvider.text
  }));
}

function mapStateToProps(state, ownProps) {
  const insurance = state.insurance[ownProps.insuranceType].insurer || {
    insurer: "",
    policyNumber: "",
    policyGroup: "",
    startDate: "",
    endDate: ""
  };

  return {
    patientId: state.patient.currentPatient.patientId,
    insurance,
    insuranceProviders: formatInsuranceProvForDropdown(state.lookups.insuranceProviders)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...modalActions, ...lookupActions }, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PatientInsuranceModal);
