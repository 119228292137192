import React from "react";
import { Button, Icon } from "../../../ui";
import "./ViewPatientDemographics.css";

const PatientEmailRow = ({
  email: { emailAddress, consentGivenForMessages, address, isPreferred },
  handleEmailEditClick,
  emailIndex,
  showDemoEdit
}) => (
  <tr>
    <td className="col-md-1">
      <Icon name="envelope" className="h3" />
      <h4>
        <label>Email</label>
      </h4>
    </td>
    <td className="col-md-5">
      <div id="lbl-emailAddress" className="resizable-content">
        <div>{emailAddress || address}</div>
      </div>
    </td>
    <td className="col-md-4 text-end">
      {consentGivenForMessages ? (
        <Icon name="check-square" className="h3 me-3 text-success" title="Consent Given For Messages" />
      ) : null}
      {isPreferred ? <Icon name="star-fill" className="h4 text-warning" title="Primary email" /> : null}
    </td>

    <td className="col-md-2 text-end">
      {showDemoEdit && (
        <Button
          variant="outline-primary"
          size="sm"
          onClick={() => {
            handleEmailEditClick(emailIndex);
          }}
          title="Edit"
        >
          <Icon name="pencil-square" />
        </Button>
      )}
    </td>
  </tr>
);

export default PatientEmailRow;
