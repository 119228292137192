import React, { Component } from "react";

class AddSurveySection extends Component {
  state = {
    index: 0,
    afterKey: "",
    sectionKey: "",
    sectionHeader: ""
  };
  componentDidMount() {
    this.setState({
      index: this.props.survey.sections.length
    });
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.survey && nextProps.survey.sections && nextProps.survey.sections.length > 0)
      this.setState({
        index: nextProps.survey.sections.length,
        afterKey: nextProps.survey.sections[nextProps.survey.sections.length - 1].key
      });
  }
  onLocationChange = location => {
    switch (location) {
      case "top":
        this.setState({ index: 0 });
        break;
      case "bottom":
        this.setState({ index: this.props.survey.sections.length });
        break;
      case "after":
        this.setState({
          index: this.props.survey.sections.findIndex(section => section.key === this.state.afterKey)
        });
        break;
      default:
        break;
    }
  };
  handleAfterChange = e => {
    this.setState({ afterKey: e.target.value });
  };
  handleFieldChange = (key, value) => {
    const state = Object.assign({}, this.state);
    state[key] = value;
    this.setState(state);
  };
  render() {
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            {this.props.survey.sections.length > 0 && (
              <span>
                <span style={{ marginRight: "10px" }}>
                  <input
                    type="radio"
                    name="location"
                    style={{ margin: "10px" }}
                    onChange={() => this.onLocationChange("top")}
                    checked={this.state.index === 0}
                  />
                  At the top
                </span>
                <br />
                <span style={{ marginRight: "10px" }}>
                  <input
                    type="radio"
                    name="location"
                    style={{ margin: "10px" }}
                    onChange={() => this.onLocationChange("after")}
                    checked={this.state.index !== 0 && this.state.index !== this.props.survey.sections.length}
                  />
                  After
                  <select
                    disabled={this.state.index === 0 || this.state.index === this.props.survey.sections.length}
                    className="ui dropdown"
                    style={{ marginLeft: "10px" }}
                    value={this.state.afterKey}
                    onChange={this.handleAfterChange}
                  >
                    {this.props.survey.sections.map(section => (
                      <option key={section.key} value={section.key}>
                        {section.header} / {section.key}
                      </option>
                    ))}
                  </select>
                </span>
                <br />
              </span>
            )}
            <span style={{ marginRight: "10px" }}>
              <input
                type="radio"
                name="location"
                style={{ margin: "10px" }}
                onChange={() => this.onLocationChange("bottom")}
                checked={this.state.index === this.props.survey.sections.length}
              />
              At the bottom
            </span>
          </div>
          <div className="col-sm-12">
            <form className="ui form" style={{ padding: "15px" }}>
              <div className="form-group row">
                <label className="col-sm-3 col-form-label">key</label>
                <div className="col-sm-9">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="key"
                    value={this.state.sectionKey}
                    onChange={e => this.handleFieldChange("sectionKey", e.target.value)}
                  />
                </div>
              </div>
              <div className="form-group row">
                <label className="col-sm-3 col-form-label">header</label>
                <div className="col-sm-9">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="header"
                    value={this.state.sectionHeader}
                    onChange={e => this.handleFieldChange("sectionHeader", e.target.value)}
                  />
                </div>
              </div>
            </form>
          </div>
          <div className="col-sm-12" style={{ padding: "15px" }}>
            <button className="mini ui green button" onClick={() => this.props.onAddSection(this.state)}>
              Add
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default AddSurveySection;
