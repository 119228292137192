import React from "react";
import { Grid, Table, Pagination, Button } from "semantic-ui-react";
import { CSVLink } from "react-csv";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as reportActions from "../../actions/reportActions";

export class Report extends React.Component {
  state = {
    activePage: 1,
    pageSize: this.props.pageSize || 100,
    pageNumber: this.props.pageNumber || 1,
    sortColumn: this.props.sortColumn || null,
    sortDirection: this.props.sortDirection || null
  };

  componentDidMount() {
    this.fetch();
  }

  componentDidUpdate(prevProps) {
    if (this.props.fetchCounter !== prevProps.fetchCounter) this.fetch();
  }

  componentWillUnmount() {
    // clean report data
  }

  setCSVLinkRef = element => {
    this.cSVLink = element;
  };

  cSVLink = null;

  handleExport = () => {
    const { pageSize } = this.state;
    const {
      endPoint,
      method,
      count,
      filterObject: filter,
      actions: { loadReportData },
      pagination
    } = this.props;
    loadReportData(
      endPoint,
      method || "GET",
      pagination,
      count > 0 ? count : pageSize,
      1,
      filter,
      null,
      null,
      true
    ).then(() => {
      this.cSVLink.link.click();
    });
  };

  handlePaginationChange = (e, { activePage }) => {
    if (activePage >= 1) {
      this.setState({ pageNumber: activePage }, () => this.fetch(true));
    }
  };

  fetch = (page = false) => {
    const {
      endPoint,
      method,
      filterObject,
      beforeFetch,
      actions: { loadReportData },
      pagination
    } = this.props;
    const { pageSize, pageNumber, sortColumn, sortDirection } = this.state;
    let filter = { ...filterObject };
    if (beforeFetch) filter = beforeFetch(filterObject);
    loadReportData(
      endPoint,
      method || "GET",
      pagination,
      pageSize,
      page ? pageNumber : 1,
      filter,
      sortColumn,
      sortDirection
    );
  };

  handleSort = clickedColumn => () => {
    const { sortColumn, sortDirection } = this.state;
    this.setState(
      {
        sortColumn: clickedColumn,
        sortDirection: sortDirection === "ascending" && sortColumn === clickedColumn ? "descending" : "ascending"
      },
      () => {
        this.fetch(true);
      }
    );
  };

  renderTable() {
    const { sortColumn, sortDirection, pageSize, pageNumber } = this.state;
    const { rowKeyField, count, currentPageRows, fields, pagination } = this.props;
    return (
      <div>
        {count <= 0 && <div style={{ textAlign: "center", padding: "2rem" }}>No items to display</div>}
        {count > 0 && (
          <React.Fragment>
            <Table selectable sortable style={{ wordBreak: "break-all" }}>
              <Table.Header>
                <Table.Row>
                  {fields.map(field => {
                    if (field.visible === false) return null;
                    return (
                      <Table.HeaderCell
                        key={field.name}
                        width={field.width || 1}
                        sorted={field.sortable !== false && sortColumn === field.name ? sortDirection : null}
                        onClick={field.sortable !== false ? this.handleSort(field.name) : () => {}}
                      >
                        {field.label}
                      </Table.HeaderCell>
                    );
                  })}
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {currentPageRows.map(row => (
                  <Table.Row key={row[rowKeyField]}>
                    {fields.map(field => {
                      if (field.visible === false) return null;
                      return (
                        <Table.Cell key={field.name} width={field.width || 1}>
                          {field.render ? field.render(row) : row[field.name]}
                        </Table.Cell>
                      );
                    })}
                  </Table.Row>
                ))}
              </Table.Body>
              <Table.Footer />
            </Table>

            {pagination !== false && (
              <div style={{ padding: "0 0 1em 0", textAlign: "center" }}>
                <Pagination
                  boundaryRange={2}
                  activePage={pageNumber}
                  ellipsisItem={`...`}
                  size="mini"
                  firstItem={null}
                  lastItem={null}
                  siblingRange={1}
                  totalPages={Math.ceil(count / pageSize)}
                  onPageChange={this.handlePaginationChange}
                />
              </div>
            )}
          </React.Fragment>
        )}
      </div>
    );
  }

  render() {
    const { header, allRows, renderFilterForm } = this.props;

    return (
      <React.Fragment>
        <div className="header-wrapper">
          <h2>{header}</h2>
        </div>
        <div className="content-wrapper visit-claims-list" style={{ padding: "1rem 3rem 1rem 1rem" }}>
          <Grid>
            <Grid.Row>{renderFilterForm()}</Grid.Row>

            <Grid.Row style={{ paddingBottom: "0" }}>
              <Grid.Column width={5} />
              <Grid.Column width={11} floated="right" textAlign="right">
                <CSVLink ref={this.setCSVLinkRef} filename="report.csv" data={allRows} style={{ display: "none" }} />
                <Button
                  icon="file excel"
                  className="transparent-button-icon"
                  content="Export"
                  onClick={this.handleExport}
                />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row style={{ paddingTop: "0" }}>
              <Grid.Column style={{ overflowX: "auto", whitespace: "nowrap" }}>{this.renderTable()}</Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    count: state.reports.report.count,
    currentPageRows: state.reports.report.currentPageRows,
    allRows: state.reports.report.allRows
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...reportActions }, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Report);
