import React, { Component } from "react";
import ReactDOMServer from "react-dom/server";
import { Image, Accordion, Grid, Button, Tab, Menu, Icon, Segment, Confirm, Modal } from "semantic-ui-react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import shortid from "shortid";
import moment from "moment";
import isEmpty from "lodash/isEmpty";
import { toast } from "react-toastify";
import logo from "../../../assets/cleanslate-logo-color.svg";
import * as patientActions from "../../../actions/patientActions";
import * as treatmentPlanActions from "../../../actions/treatmentPlanActions";
import toastErrorOptions from "../../../constants/toastconfig";
import emrComponent from "../../common/emrComponent";
import {
  dateWithTimeFormat,
  TEMPLATE_ID_TREATMENT_PLAN_TEMPLAE_ID,
  dateFormat
} from "../../../constants/miscellaneous";
import { roleGroups } from "../../../constants/securityRoles";
import { checkRoles } from "../../../helpers";
import Survey from "../surveys/Survey";
import VisitSummaryPrintLoading from "../visits/VisitSummaryPrintLoading";

export class TreatmentPlan extends Component {
  state = {
    createTreatmentPlanPressed: false,
    signTreatmentPlanPressed: false,
    cancelTreatmentPlanPressed: false,
    confirmSign: false,
    confirmCancel: false,
    activeAccordionIndices: []
  };

  componentDidMount() {
    const {
      patient: { patientId },
      actions: { loadTreatmentPlanHistory }
    } = this.props;
    loadTreatmentPlanHistory(patientId).then(() => this.loadTreatmentPlan());
  }

  // componentDidUpdate(nextProps) {}

  componentWillUnmount() {}

  _isMounted = false;

  loadTreatmentPlan = () => {
    const {
      selectedTreatmentPlan: { header: treatmentPlan },
      actions: { loadTreatmentPlan, loadTreatmentPlanSummary }
    } = this.props;
    if (treatmentPlan && (treatmentPlan.isSigned || treatmentPlan.isDeleted)) {
      return loadTreatmentPlanSummary(treatmentPlan.treatmentPlanId);
    } else if (treatmentPlan) {
      return loadTreatmentPlan(treatmentPlan.treatmentPlanId, TEMPLATE_ID_TREATMENT_PLAN_TEMPLAE_ID);
    }
    return null;
  };

  handleTreatmentPlanChange = (treatmentPlanId) => {
    const {
      selectedTreatmentPlan: { header: treatmentPlan },
      actions: { setSelectedTreatmentPlan }
    } = this.props;
    if (treatmentPlan.treatmentPlanId === treatmentPlanId) return;
    setSelectedTreatmentPlan(treatmentPlanId).then(() => this.loadTreatmentPlan());
  };

  handleNewTreatmentPlan = () => {
    const {
      patient: { patientId },
      contextVisit,
      actions: { loadTreatmentPlanHistory, createTreatmentPlan }
    } = this.props;
    this.setState({ createTreatmentPlanPressed: true });
    createTreatmentPlan(patientId, contextVisit ? contextVisit.visitId : null).then(() => {
      loadTreatmentPlanHistory(patientId).then(() => this.loadTreatmentPlan());
    });
  };

  handlerPrint = () => {
    const printWindow = window.open();
    const loadingBody = ReactDOMServer.renderToString(<VisitSummaryPrintLoading />);
    const head = document.head.innerHTML;

    printWindow.document.write(`${head}${loadingBody}`);

    printWindow.addEventListener("load", () => {
      printWindow.print();
    });

    const bodyPrint = ReactDOMServer.renderToString(this.renderTreatmentPlan(true));
    printWindow.document.body.getElementsByClassName("zontainer")[0].remove();
    printWindow.document.write(bodyPrint);
    printWindow.document.close();
  };

  handleSign = () => {
    this.setState({ confirmSign: true });
  };

  handleSignConfirmed = () => {
    const {
      patient: { patientId },
      selectedTreatmentPlan: { header: treatmentPlan },
      actions: { signTreatmentPlan, loadTreatmentPlanHistory, updateCurrentPatientTreatmentPlanStatus }
    } = this.props;
    this.setState({ confirmSign: false, signTreatmentPlanPressed: true });
    signTreatmentPlan(patientId, treatmentPlan.treatmentPlanId)
      .then(() => {
        this.setState({ createTreatmentPlanPressed: false });
        updateCurrentPatientTreatmentPlanStatus(patientId, "Valid");
        loadTreatmentPlanHistory(patientId).then(() => {
          this.setState({ signTreatmentPlanPressed: false });
          this.loadTreatmentPlan();
        });
      })
      .catch(() => {
        this.setState({ signTreatmentPlanPressed: false });
      });
  };

  handleCancel = () => {
    this.setState({ confirmCancel: true });
  };

  handleCancelConfirmed = () => {
    const {
      patient: { patientId },
      selectedTreatmentPlan: { header: treatmentPlan },
      actions: { deleteTreatmentPlan, updateCurrentPatientTreatmentPlanStatus, loadTreatmentPlanHistory }
    } = this.props;
    this.setState({ confirmCancel: false, cancelTreatmentPlanPressed: true });
    deleteTreatmentPlan(patientId, treatmentPlan.treatmentPlanId)
      .then(() => {
        this.setState({ createTreatmentPlanPressed: false });
        updateCurrentPatientTreatmentPlanStatus(patientId, "Deleted");
        loadTreatmentPlanHistory(patientId).then(() => {
          this.setState({ cancelTreatmentPlanPressed: false });
          this.loadTreatmentPlan();
        });
      })
      .catch(() => {
        this.setState({ cancelTreatmentPlanPressed: false });
      });
  };

  handleSaveSurvey = (templateId, surveyId, answers) => {
    const {
      patient: { patientId },
      selectedTreatmentPlan: { header: treatmentPlanHeader, edit: treatmentPlan },
      actions: { clearHiddenTreatmentPlanSurvey }
    } = this.props;
    const { activeAccordionIndices } = this.state;
    const index = treatmentPlan.surveys.findIndex((survey) => survey.id === surveyId);
    const updatedActiveAccordionIndices = activeAccordionIndices.filter((i) => i !== index);
    if (!updatedActiveAccordionIndices.includes(index + 1)) updatedActiveAccordionIndices.push(index + 1);
    const _answers = { ...answers, templateId, surveyId };
    return this.props.actions.updateTreatmentPlan(patientId, treatmentPlanHeader.treatmentPlanId, _answers).then(() => {
      toast.success(`Survey saved successfully`, { ...toastErrorOptions, autoClose: 3000 });
      clearHiddenTreatmentPlanSurvey(patientId, templateId, surveyId);
      this.loadTreatmentPlan().then(() => {
        window.scrollTo({ top: 550, behavior: "smooth" });
        this.setState({ activeAccordionIndices: updatedActiveAccordionIndices });
      });
    });
  };

  handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeAccordionIndices } = this.state;
    const newIndex = activeAccordionIndices.includes(index)
      ? activeAccordionIndices.filter((i) => i !== index)
      : [...activeAccordionIndices, index];
    this.setState({ activeAccordionIndices: newIndex });
  };

  renderClosedTreatmentPlan = (isPrint = false) => {
    const {
      selectedTreatmentPlan: { view: treatmentPlan },
      patient
    } = this.props;
    if (!treatmentPlan) return null;
    const {
      header: { center, providerDetails }
    } = treatmentPlan;
    return (
      <span className={!isPrint ? "visit-summary" : "visit-summary-print"}>
        <div className="visit-summary-content">
          <div className="ui grid summary-survey-results summary-header">
            <Grid columns={4} style={{ width: "100%" }}>
              <Grid.Row>
                <Grid.Column>
                  <Image src={logo} alt="logo" size="small" />
                </Grid.Column>
                <Grid.Column>
                  <div className="header-center-address">
                    <p>{center.address1}</p>
                    <p>{center.address2}</p>
                    <p>{`${center.city}, ${center.state} ${center.zip}-${center.zip4}`}</p>
                  </div>
                </Grid.Column>
                <Grid.Column>
                  <h5>Provider</h5>
                  {!providerDetails && <strong style={{ color: "#db2828" }}>Not signed</strong>}
                  {providerDetails && (
                    <React.Fragment>
                      <strong>{`${providerDetails.firstName} ${providerDetails.lastName} (${
                        providerDetails.title || ``
                      })`}</strong>
                    </React.Fragment>
                  )}
                </Grid.Column>
                {!isPrint && (
                  <Grid.Column>
                    <Button basic color="blue" content="Print" onClick={this.handlerPrint} />
                  </Grid.Column>
                )}
              </Grid.Row>
            </Grid>
            <div className="visit-information-container">
              <h5>Treatment Plan for</h5>
              <strong>{`${patient.patientFirstName} ${patient.patientLastName} ${
                patient.patientDateOfBirth
                  ? `(DOB ${moment(patient.patientDateOfBirth, dateWithTimeFormat).format(dateFormat)})`
                  : ""
              } signed on ${moment(treatmentPlan.signedOn).format(dateWithTimeFormat)}`}</strong>
            </div>
            <hr />
            <div>
              {treatmentPlan.answers.map((survey) => (
                <div key={`rand-${shortid.generate()}`} className="survey-container">
                  <h4>{survey.surveyTitle}</h4>
                  <div className="results-container">
                    {survey.sections.map((section) => (
                      <React.Fragment key={`rand-${shortid.generate()}`}>
                        {!isEmpty(section.header) && <strong>{section.header}</strong>}
                        {section.content.map((text) => (
                          <div key={`rand-${shortid.generate()}`}>{text}</div>
                        ))}
                      </React.Fragment>
                    ))}
                  </div>
                </div>
              ))}
            </div>
            <div>
              <p>
                I attest that this Person-Centered Treatment Plan was developed with the active involvement of the
                person served. This Plan contains goals and objectives that incorporate the unique strengths, needs,
                abilities and preferences of the person served, identifying challenges and potential solutions in the
                Patient’s treatment and recovery.
              </p>
              {!providerDetails && <strong style={{ color: "#db2828" }}>Not signed</strong>}
              {providerDetails && (
                <p>
                  Electronically Signed By:{" "}
                  {`${providerDetails.firstName} ${providerDetails.lastName} (${providerDetails.title || ``})`}
                  <br />
                  {moment(treatmentPlan.signedOn).format(dateWithTimeFormat)}
                </p>
              )}
            </div>
          </div>
          {isPrint && (
            <Grid style={{ padding: "5em" }}>
              <Grid.Row>
                <Grid.Column width={8}>
                  <h4 style={{ paddingBottom: "2em" }}>Patient Signature</h4>
                  <span>______________________________</span>
                </Grid.Column>
                <Grid.Column width={8}>
                  <h4 style={{ paddingBottom: "2em" }}>Sign Date</h4>
                  <span>______________________________</span>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row style={{ paddingTop: "2em" }}>
                <Grid.Column width={8}>
                  <h4 style={{ paddingBottom: "2em" }}>Provider/Counselor Signature</h4>
                  <span>______________________________</span>
                </Grid.Column>
                <Grid.Column width={8}>
                  <h4 style={{ paddingBottom: "2em" }}>Sign Date</h4>
                  <span>______________________________</span>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          )}
        </div>
      </span>
    );
  };

  renderUnsignedTreatmentPlan = () => {
    const {
      patient: { patientId },
      selectedTreatmentPlan: { edit: treatmentPlan },
      hiddenTreatmentPlanSurveys,
      authRoles: roles
    } = this.props;
    const { signTreatmentPlanPressed, cancelTreatmentPlanPressed } = this.state;
    const { activeAccordionIndices } = this.state;
    if (!treatmentPlan) return null;
    const canSign = treatmentPlan.surveys.every(
      (survey) => !survey.mustComplete || survey.hasAnswersFromContextContainer
    );
    const canCancel = checkRoles(roleGroups.canCancelTreatmentPlan, roles);
    const canEditTreatmentPlan = checkRoles(roleGroups.canEditTreatmentPlan, roles);
    return (
      <div>
        <Accordion exclusive={false}>
          <Confirm
            open={this.state.confirmSign}
            header={
              <Modal.Header>
                <Icon name="exclamation triangle" />
                <span>Confirm signing plan</span>
              </Modal.Header>
            }
            content="Are you sure you want to sign this treatment plan - you can not edit values after signing the plan?"
            confirmButton="Sign"
            onCancel={() => this.setState({ confirmSign: false })}
            onConfirm={this.handleSignConfirmed}
          />
          <Confirm
            open={this.state.confirmCancel}
            size="mini"
            header={
              <Modal.Header>
                <Icon name="exclamation triangle" />
                <span>Confirm cancel plan</span>
              </Modal.Header>
            }
            content="Are you sure you want to cancel treatment plan?"
            confirmButton="Confirm"
            cancelButton="Close"
            onCancel={() => this.setState({ confirmCancel: false })}
            onConfirm={this.handleCancelConfirmed}
          />
          <Segment basic textAlign="left" className="no-v-padding">
            <Grid>
              <Grid.Row>
                <Grid.Column width={16}>
                  I attest that this Person-Centered Treatment Plan was developed with the active involvement of the
                  person served. This Plan contains goals and objectives that incorporate the unique strengths, needs,
                  abilities and preferences of the person served, identifying challenges and potential solutions in the
                  Patient’s treatment and recovery.
                </Grid.Column>
              </Grid.Row>
              <Grid.Row className="half-v-padding">
                <Grid.Column width={16}>
                  {canCancel && (
                    <Button
                      color="red"
                      content="Cancel"
                      disabled={cancelTreatmentPlanPressed}
                      onClick={this.handleCancel}
                    />
                  )}
                  <Button
                    floated="right"
                    color="green"
                    content="Sign"
                    disabled={signTreatmentPlanPressed || !canSign}
                    onClick={this.handleSign}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
          {treatmentPlan.surveys.map((survey, i) => {
            const updated = !!hiddenTreatmentPlanSurveys[`${patientId}-${treatmentPlan.templateId}-${survey.id}`];
            const color = updated ? "yellow" : survey.hasAnswersFromContextContainer ? "green" : "grey";
            return (
              <React.Fragment key={survey.id}>
                <Accordion.Title active={activeAccordionIndices.includes(i)} index={i} onClick={this.handleClick}>
                  <Segment basic color={color}>
                    <Icon name="dropdown" />
                    {survey.name}
                  </Segment>
                </Accordion.Title>
                {!isEmpty(activeAccordionIndices) && (
                  <Accordion.Content active={activeAccordionIndices.includes(i)}>
                    <Survey
                      key={survey.treatmentPlanId}
                      for="treatmentPlan"
                      survey={survey}
                      asModal={false}
                      autoSave
                      promptOnLeaving={false}
                      disableSave={!canEditTreatmentPlan}
                      handleSave={this.handleSaveSurvey}
                    />
                  </Accordion.Content>
                )}
              </React.Fragment>
            );
          })}
        </Accordion>
      </div>
    );
  };

  renderTreatmentPlan = (isPrint = false) => {
    const {
      selectedTreatmentPlan: { header: treatmentPlan }
    } = this.props;
    if (!treatmentPlan) return null;
    if (treatmentPlan.isSigned || treatmentPlan.isDeleted) return this.renderClosedTreatmentPlan(isPrint);
    return this.renderUnsignedTreatmentPlan();
  };

  render() {
    const {
      history,
      authRoles: roles,
      selectedTreatmentPlan: { header: treatmentPlan }
    } = this.props;
    const { createTreatmentPlanPressed } = this.state;
    const canEditTreatmentPlan = checkRoles(roleGroups.canEditTreatmentPlan, roles);
    const disableNewTreatmentPlan =
      !canEditTreatmentPlan || createTreatmentPlanPressed || !!history.find((i) => !i.isSigned && !i.isDeleted);

    const panes = [];

    history.forEach((item) => {
      panes.push({
        menuItem: (
          <Menu.Item
            color={item.isDeleted ? `grey` : `black`}
            key={item.treatmentPlanId}
            active={treatmentPlan && item.treatmentPlanId === treatmentPlan.treatmentPlanId}
            onClick={() => {
              this.handleTreatmentPlanChange(item.treatmentPlanId);
            }}
            style={{ textDecoration: item.isDeleted ? `line-through` : `` }}
          >
            <label>{moment(item.isSigned ? item.signedOn : item.createDate).format(dateWithTimeFormat)}</label>

            <Icon name={item.isSigned || item.isDeleted ? `lock` : `lock open`} />
            <br />
            <small>{`${item.isSigned ? item.signedByFirstName : item.providerFirstName} ${
              item.isSigned ? item.signedByLastName : item.providerLastName
            }`}</small>
            {<small>{`(${item.isSigned ? item.signedByTitle || `` : item.providerTitle || ``}) `}</small>}
          </Menu.Item>
        ),
        render: () => <Tab.Pane>{this.renderTreatmentPlan()}</Tab.Pane>
      });
    });

    return (
      <Grid className="tp" padded>
        <Grid.Row>
          <Grid.Column width={16}>
            <Grid>
              <Grid.Row>
                <Grid.Column width={3} className="center aligned">
                  <Button
                    content="New Treatment Plan"
                    className="fluid"
                    color="blue"
                    disabled={disableNewTreatmentPlan}
                    onClick={this.handleNewTreatmentPlan}
                  />
                </Grid.Column>
                <Grid.Column width={13} />
              </Grid.Row>
            </Grid>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={16} className="bg-white">
            <Tab
              menu={{ fluid: true, vertical: true, tabular: true }}
              grid={{ paneWidth: 13, tabWidth: 3 }}
              panes={panes}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  return {
    contextVisit: state.visits.contextVisit,
    authRoles: state.auth.user.profile.roles,
    patient: state.patient.currentPatient,
    history: state.treatmentPlan.history,
    selectedTreatmentPlan: state.treatmentPlan.selectedTreatmentPlan,
    hiddenTreatmentPlanSurveys: state.treatmentPlan.hiddenSurveys,
    processing: state.ajaxCallsInProgress > 0
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...patientActions,
        ...treatmentPlanActions
      },
      dispatch
    )
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(emrComponent(TreatmentPlan));
