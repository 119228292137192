import React, { Component } from "react";
import {
  Button,
  Header,
  Modal,
  List,
  Form,
  Grid,
  Accordion,
  Message,
  Icon,
  Dropdown,
  Input,
  Segment
} from "semantic-ui-react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import { isEmpty, groupBy, sortBy } from "lodash";
import {
  dateWithTimeFormat,
  SURVEY_ID_DIAGNOSIS_CODES_SURVEY_ID,
  TEMPLATE_ID_MA_TEMPLAE_ID,
  URINE_DRUG_SCREEN_LAB_CODE,
  VISIT_STATUS_VISIT_CANCELED
} from "../../../constants/miscellaneous";
import * as templateActions from "../../../actions/templateActions";
import * as modalActions from "../../../actions/modalActions";
import * as labActions from "../../../actions/labActions";
import * as lookupActions from "../../../actions/lookupActions";
import * as insuranceActions from "../../../actions/insuranceActions";
import { dateRegEx } from "../../../constants/validation";
import { updateErrors, checkRoles, getLookupText } from "../../../helpers";
import { roleGroups } from "../../../constants/securityRoles";
import ModalLoadingMessage from "../../common/ModalLoadingMessage";
import "./ExtendedLabOrderSpecificationsModal.css";
import Survey from "../surveys/Survey";

const inputValidation = {
  sampleCollectionDate: dateRegEx
};

const ExtendedLabGroup = ({
  submittedTests,
  unsubmittedTests,
  header,
  handleRemoveClicked,
  handleLabReasonDdlChange,
  handleLabReasonTextChange,
  primaryLabCode
}) => (
  <List.Item>
    <List.Content>
      <List.Header>{header}</List.Header>
      <List>
        {submittedTests &&
          submittedTests.length > 0 &&
          submittedTests.map((lab) => (
            <List.Item id={`order-specs-lab-item-${lab.labCode}`} key={lab.labCode} className={`lab-item`} disabled>
              <List.Content>
                {lab.labName}
                <br />
                {lab.reason}
              </List.Content>
            </List.Item>
          ))}
        {unsubmittedTests &&
          unsubmittedTests.length > 0 &&
          unsubmittedTests.map((test) => (
            <List.Item id={`order-specs-lab-item-${test.labCode}`} key={test.labCode} className={`lab-item`}>
              <List.Content>
                <div style={{ minHeight: "2em", backgroundColor: primaryLabCode ? "#fdf7f5" : "#fffaf3" }}>
                  <label style={{ display: "inline-block", paddingTop: ".5rem" }}>{test.labName}</label>
                  <Button
                    size="mini"
                    color="red"
                    content="x"
                    labcode={test.labCode}
                    onClick={(e, data) => handleRemoveClicked(e, data, primaryLabCode)}
                    floated="right"
                  />
                </div>
                {test.selectionReasons.length > 0 && (
                  <Dropdown
                    id={`order-specs-lab-reasons-ddl-${test.labCode}`}
                    value={test.reason}
                    className={isEmpty(test.reason) ? "required" : ""}
                    selection
                    fluid
                    options={test.selectionReasons.map((r) => ({ text: r, value: r }))}
                    onChange={(_, data) => handleLabReasonDdlChange(test.labCode, data.value, primaryLabCode)}
                  />
                )}
                <Input
                  id={`order-specs-lab-reasons-txt-${test.labCode}`}
                  value={test.reason === null || test.reason === undefined ? "" : test.reason}
                  className={isEmpty(test.reason) ? "required" : ""}
                  fluid
                  placeholder="Reason"
                  onChange={(_, data) => handleLabReasonTextChange(test.labCode, data.value, primaryLabCode)}
                />
                <Segment basic>
                  {test.confirmatoryLabs &&
                    (test.submittedConfirmatoryLabs.length > 0 || test.unsubmittedConfirmatoryLabs.length > 0) && (
                      <ExtendedLabGroup
                        header={`Confirmatory Tests`}
                        submittedTests={test.submittedConfirmatoryLabs}
                        unsubmittedTests={test.unsubmittedConfirmatoryLabs}
                        handleRemoveClicked={handleRemoveClicked}
                        handleLabReasonDdlChange={handleLabReasonDdlChange}
                        handleLabReasonTextChange={handleLabReasonTextChange}
                        primaryLabCode={test.labCode}
                      />
                    )}
                </Segment>
              </List.Content>
            </List.Item>
          ))}
        {!((submittedTests && submittedTests.length > 0) || (unsubmittedTests && unsubmittedTests.length > 0)) && (
          <List.Item>
            <span style={{ color: "gray" }}>No tests</span>
          </List.Item>
        )}
      </List>
    </List.Content>
  </List.Item>
);

export class ExtendedLabOrderSpecificationsModal extends Component {
  state = {
    labOrder: {
      submittedTests: [],
      unsubmittedTests: [],
      sampleWasRefrigerated: false,
      addConfirmatoryTest: false,
      submissionComment: "",
      sampleCollectionDateOption: "",
      sampleCollectionDate: "",
      questTestsAdded: false
    },
    loading: true,
    errors: {},
    saving: false,
    activeAccordionIndex: 0,
    activeIndex: 0,
    reloadingTemplate: false
  };
  abortController = new AbortController();
  abortSignal = this.abortController.signal;

  componentDidMount() {
    if (this.props.test) return;
    if (!this.props.centerId) return;
    this.props.actions.loadPatientInsurance(this.props.patientId);
    this.props.actions.loadInsuranceProviders();
    this.load();
  }

  componentDidUpdate(prevProps) {
    const { centerId, selectedLabOrder, reloadTemplate } = this.props;
    if (!this.state.saving && centerId && centerId !== prevProps.centerId) {
      this.load();
    } else if (
      !isEmpty(selectedLabOrder) &&
      (isEmpty(prevProps.selectedLabOrder) || selectedLabOrder.orderId !== prevProps.selectedLabOrder.orderId)
    ) {
      this.reset();
    }
    if (
      !isEmpty(selectedLabOrder) &&
      (reloadTemplate ||
        isEmpty(prevProps.selectedLabOrder) ||
        selectedLabOrder.visitId !== prevProps.selectedLabOrder.visitId)
    ) {
      if (reloadTemplate) {
        this.setState({ reloadingTemplate: true });
        this.props.actions.setReloadSelectedTemplate(false);
      }
      this.props.actions
        .loadSpecificTemplateAndItsSurveys(
          this.props.patientId,
          TEMPLATE_ID_MA_TEMPLAE_ID,
          selectedLabOrder.visitId,
          "",
          true,
          this.abortSignal
        )
        .then(() => {
          if (reloadTemplate) {
            this.setState({ reloadingTemplate: false });
          }
          this.props.actions.setReInitSurvey(true);
          const diagnosisCodesSurveyFilled = this.checkDiagnosisCodesSurveyFilled();
          this.setState({
            activeAccordionIndex: diagnosisCodesSurveyFilled ? 2 : 1
          });
        })
        .catch((e) => {
          if (e.message !== "USER_ABORTED") throw e;
        });
    }
  }

  componentWillUnmount() {
    this.abortController.abort();
    this.props.actions.clearPatientInsurance();
  }

  getDiagnosisCodesSurvey = () => {
    const { selectedLabOrder, specificTemplates } = this.props;
    let diagnosisCodesSurvey = null;
    const maTemplate = specificTemplates[`${selectedLabOrder.visitId}_${TEMPLATE_ID_MA_TEMPLAE_ID}`];
    if (maTemplate) {
      diagnosisCodesSurvey = maTemplate.surveys.find((survey) => survey.id === SURVEY_ID_DIAGNOSIS_CODES_SURVEY_ID);
    }
    return diagnosisCodesSurvey;
  };

  checkDiagnosisCodesSurveyFilled = () => {
    const { selectedLabOrder } = this.props;
    const diagnosisCodesSurvey = this.getDiagnosisCodesSurvey();
    let diagnosisCodesSurveyFilled = false;
    if (selectedLabOrder.visitId && selectedLabOrder.isVisitSigned) {
      diagnosisCodesSurveyFilled = true;
    } else if (diagnosisCodesSurvey) {
      diagnosisCodesSurveyFilled =
        diagnosisCodesSurvey.questions.find(
          (q) => q.metadata && q.metadata.service && q.metadata.service.target === "ICD10"
        ).previousAnswers.length > 0;
    }
    return diagnosisCodesSurveyFilled;
  };

  load = () => {
    this.props.actions
      .loadAvailableLabs(this.props.centerId)
      .then(() => this.props.actions.loadRecentOrders(this.props.patientId))
      .then(() => {
        const {
          patientId,
          recentOrders,
          selectedLabOrder,
          isSelectedLabOrderVisitMedical,
          actions: { setSelectedLabOrder }
        } = this.props;
        if (selectedLabOrder && isSelectedLabOrderVisitMedical) {
          const order = recentOrders.find((o) => o.visitId === selectedLabOrder.visitId);
          if (order) {
            setSelectedLabOrder(patientId, order);
          }
        }
      })
      .then(() => {
        this.reset();
      });
  };

  reset = () => {
    const { centerId, availableLabs, selectedLabOrder } = this.props;
    if (centerId && availableLabs && selectedLabOrder && selectedLabOrder.tests) {
      const updatedSubmittedTests = [];
      const updatedUnsubmittedTests = [];
      selectedLabOrder.tests.forEach((test) => {
        if (isEmpty(test.labCode)) return;
        const _lab = availableLabs.find((l) => l.labCode === test.labCode);
        const lab = {
          ..._lab,
          reason: test.selectionReason,
          submittedConfirmatoryLabs: test.confirmatoryLabs
            .filter((ctest) => ctest.isSubmitted)
            .map((ctest) => ({
              ..._lab.confirmatoryLabs.find((clab) => clab.labCode === ctest.labCode),
              reason: ctest.selectionReason
            })),
          unsubmittedConfirmatoryLabs: test.confirmatoryLabs
            .filter((ctest) => !ctest.isSubmitted)
            .map((ctest) => ({
              ..._lab.confirmatoryLabs.find((clab) => clab.labCode === ctest.labCode),
              reason: ctest.selectionReason
            }))
        };
        if (test.isSubmitted) {
          updatedSubmittedTests.push(lab);
        } else {
          updatedUnsubmittedTests.push(lab);
        }
      });
      this.setState({
        loading: false,
        labOrder: {
          ...this.state.labOrder,
          sampleCollectionDateOption: moment(selectedLabOrder.sampleDate).format("MM/DD/YYYY"),
          sampleCollectionDate: moment(selectedLabOrder.sampleDate).format("MM/DD/YYYY"),
          submittedTests: updatedSubmittedTests,
          unsubmittedTests: updatedUnsubmittedTests,
          questTestsAdded: updatedUnsubmittedTests.filter((test) => test.labProvider === "Quest").length > 0,
          sampleWasRefrigerated: !isEmpty(selectedLabOrder.submissionComment),
          submissionComment: selectedLabOrder.submissionComment || ""
        }
      });
    } else {
      this.setState({
        loading: false
      });
    }
  };

  handleSave = () => {
    const labs = this.state.labOrder.unsubmittedTests.map((l) => ({
      labCode: l.labCode,
      reason: l.reason,
      confirmatoryLabs: l.unsubmittedConfirmatoryLabs.map((c) => ({ labCode: c.labCode, reason: c.reason }))
    }));
    this.setState({ saving: true });
    return this.props.actions
      .addTestsToPendingOrder(
        this.props.selectedLabOrder.orderId,
        this.props.patientId,
        this.props.selectedLabOrder.visitId,
        labs,
        this.state.labOrder.submissionComment,
        this.state.labOrder.sampleCollectionDate,
        this.state.labOrder.sampleCollectionDateOption === "future"
      )
      .then(() => {
        this.setState({ saving: false });
        this.props.actions.clearRecentAndSelectedLabOrders();
        this.props.actions.hideModal();
      });
  };

  handleMainAccordionClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeAccordionIndex } = this.state;
    const newIndex = activeAccordionIndex === index ? -1 : index;
    this.setState({
      activeAccordionIndex: newIndex
    });
  };

  handleAccordionClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;
    this.setState({
      activeIndex: newIndex,
      labOrder: {
        ...this.state.labOrder
      }
    });
  };

  handleClose = () => {
    this.props.actions.clearRecentAndSelectedLabOrders();
    this.props.actions.hideModal();
  };

  handleFocus = (e) => {
    e.target.setAttribute("autocomplete", "nope");
  };

  handleInput = (e, data) => {
    const { name: field, value, required } = data;
    const errors = updateErrors(field, value, required, this.state.errors, inputValidation[field]);
    const labOrder = Object.assign({}, this.state.labOrder, {
      [field]: value
    });
    this.setState({ labOrder, errors });
  };

  handleSampleCollectionDateOptionChange = (e, data) => {
    const { name: field, value } = data;
    let sampleCollectionDate = "";
    const errors = updateErrors(
      "sampleCollectionDate",
      this.state.labOrder.sampleCollectionDate,
      true,
      this.state.errors,
      inputValidation.sampleCollectionDate
    );
    const {
      patientId,
      recentOrders,
      actions: { setSelectedLabOrder }
    } = this.props;

    const order = recentOrders.find((i) => i.visitId === value);
    setSelectedLabOrder(patientId, order);

    delete errors.sampleCollectionDate;
    sampleCollectionDate = moment(order.sampleDate).format("MM/DD/YYYY");

    this.setState({
      errors,
      labOrder: {
        ...this.state.labOrder,
        sampleCollectionDate,
        [field]: moment(order.sampleDate).format("MM/DD/YYYY")
      }
    });
  };

  handleCheckbox = (e, data) => {
    const { name: field, checked } = data;
    let newSubmissionComment = this.state.labOrder.submissionComment;
    if (field === "sampleWasRefrigerated") {
      newSubmissionComment = checked ? "This sample was refrigerated." : "";
    }
    this.setState({
      labOrder: {
        ...this.state.labOrder,
        [field]: checked,
        submissionComment: newSubmissionComment
      }
    });
  };

  handleLabChecked = (e, data) => {
    const { checked } = data;
    const { availableLabs } = this.props;
    const {
      labOrder: {
        unsubmittedTests,
        sampleWasRefrigerated,
        submissionComment,
        sampleCollectionDateOption,
        sampleCollectionDate,
        questTestsAdded
      }
    } = this.state;
    const selectedLab = availableLabs.find((l) => l.labCode === data.labcode);
    let updatedUnsubmittedTests = [...unsubmittedTests];
    if (checked) {
      updatedUnsubmittedTests.push({
        ...selectedLab,
        reason: "",
        submittedConfirmatoryLabs: [],
        unsubmittedConfirmatoryLabs: []
      });
    } else {
      updatedUnsubmittedTests = updatedUnsubmittedTests.filter((l) => l.labCode !== selectedLab.labCode);
    }
    const newQuestLabsAdded = updatedUnsubmittedTests.some((lab) => lab.labProvider === "Quest");

    let newSampleCollectionDateOption = sampleCollectionDateOption;
    let newSampleCollectionDate = sampleCollectionDate;

    let newSampleWasRefrigerated = sampleWasRefrigerated;
    let newSubmissionComment = submissionComment;
    if (questTestsAdded !== newQuestLabsAdded && questTestsAdded) {
      newSampleCollectionDateOption = moment().format("MM/DD/YYYY");
      newSampleCollectionDate = moment().format("MM/DD/YYYY");
      newSampleWasRefrigerated = false;
      newSubmissionComment = "";
    }
    const labOrder = Object.assign({}, this.state.labOrder, {
      sampleWasRefrigerated: newSampleWasRefrigerated,
      submissionComment: newSubmissionComment,
      unsubmittedTests: updatedUnsubmittedTests,
      sampleCollectionDateOption: newSampleCollectionDateOption,
      sampleCollectionDate: newSampleCollectionDate,
      questTestsAdded: newQuestLabsAdded
    });
    this.setState({ labOrder });
  };

  handleConfirmatoryChecked = (e, data) => {
    const { checked, pcode, ccode } = data;
    this.setState({
      labOrder: {
        ...this.state.labOrder,
        unsubmittedTests: this.state.labOrder.unsubmittedTests.map((test) => {
          if (test.labCode !== pcode) return test;
          const lab = test.confirmatoryLabs.find((clab) => clab.labCode === ccode);
          if (checked) return { ...test, unsubmittedConfirmatoryLabs: [...test.unsubmittedConfirmatoryLabs, lab] };
          return {
            ...test,
            unsubmittedConfirmatoryLabs: test.unsubmittedConfirmatoryLabs.filter((c) => c.labCode !== ccode)
          };
        })
      }
    });
  };

  handleRemoveClicked = (e, data, primaryLabCode = null) => {
    if (primaryLabCode)
      this.setState({
        labOrder: {
          ...this.state.labOrder,
          unsubmittedTests: this.state.labOrder.unsubmittedTests.map((test) => {
            if (test.labCode !== primaryLabCode) return test;
            return {
              ...test,
              unsubmittedConfirmatoryLabs: test.unsubmittedConfirmatoryLabs.filter((l) => l.labCode !== data.labcode)
            };
          })
        }
      });
    else
      this.setState({
        labOrder: {
          ...this.state.labOrder,
          unsubmittedTests: this.state.labOrder.unsubmittedTests.filter((l) => l.labCode !== data.labcode)
        }
      });
  };

  handleLabReasonDdlChange = (labCode, reason, primaryLabCode = null) => {
    if (primaryLabCode)
      this.setState({
        labOrder: {
          ...this.state.labOrder,
          unsubmittedTests: this.state.labOrder.unsubmittedTests.map((lab) => {
            if (lab.labCode !== primaryLabCode) return lab;
            return {
              ...lab,
              unsubmittedConfirmatoryLabs: lab.unsubmittedConfirmatoryLabs.map((clab) => {
                if (clab.labCode !== labCode) return clab;
                return { ...clab, reason };
              })
            };
          })
        }
      });
    else
      this.setState({
        labOrder: {
          ...this.state.labOrder,
          unsubmittedTests: this.state.labOrder.unsubmittedTests.map((lab) => {
            if (lab.labCode !== labCode) return lab;
            return { ...lab, reason };
          })
        }
      });
  };

  handleLabReasonTextChange = (labCode, reason, primaryLabCode = null) => {
    if (primaryLabCode)
      this.setState({
        labOrder: {
          ...this.state.labOrder,
          unsubmittedTests: this.state.labOrder.unsubmittedTests.map((lab) => {
            if (lab.labCode !== primaryLabCode) return lab;
            return {
              ...lab,
              unsubmittedConfirmatoryLabs: lab.unsubmittedConfirmatoryLabs.map((clab) => {
                if (clab.labCode !== labCode) return clab;
                return { ...clab, reason };
              })
            };
          })
        }
      });
    else
      this.setState({
        labOrder: {
          ...this.state.labOrder,
          unsubmittedTests: this.state.labOrder.unsubmittedTests.map((lab) => {
            if (lab.labCode !== labCode) return lab;
            return { ...lab, reason };
          })
        }
      });
  };

  isLabChecked(labCode) {
    return (
      this.state.labOrder.unsubmittedTests.find((t) => t.labCode === labCode) !== undefined ||
      this.state.labOrder.submittedTests.find((t) => t.labCode === labCode) !== undefined
    );
  }

  getLabDisableInfo(labCode) {
    const { availableLabs, authRoles, selectedLabOrder, labsConfirmatoryMap } = this.props;
    const {
      labOrder: { submittedTests, unsubmittedTests }
    } = this.state;
    const showCreateLabOrder = checkRoles(roleGroups.labsCreate, authRoles);

    // here it is primary .. we need to check if its equivalent confirmatory is added ONLY if it has one
    let alreadyAdded = false;
    if (labsConfirmatoryMap[labCode]) {
      const allConfirmatoryCodesAdded = [];
      [...submittedTests, ...unsubmittedTests].forEach((t) => {
        allConfirmatoryCodesAdded.push(...t.submittedConfirmatoryLabs.map((sct) => sct.labCode));
        allConfirmatoryCodesAdded.push(...t.unsubmittedConfirmatoryLabs.map((sct) => sct.labCode));
      });
      alreadyAdded = allConfirmatoryCodesAdded.includes(labsConfirmatoryMap[labCode]);
    }

    const willAutoOrderList = [];
    [...submittedTests, ...unsubmittedTests].forEach((t) => {
      if (t.autoOrderConfirmatory) {
        t.confirmatoryLabs.forEach((ct) => willAutoOrderList.push(ct.confirmatoryLabCode));
      }
    });

    let containsAutoOrderConfirmatoryAlreadyAdded = false;
    const lab = availableLabs.find((t) => t.labCode === labCode);
    if (lab.autoOrderConfirmatory) {
      lab.confirmatoryLabs.forEach((ct) => {
        if ([...submittedTests, ...unsubmittedTests].find((t) => t.labCode === ct.confirmatoryLabCode)) {
          containsAutoOrderConfirmatoryAlreadyAdded = true;
        }
      });
    }

    let message = ``;
    if (willAutoOrderList.includes(labCode))
      message = `Test auto-ordered as reflex confirmation for associated CleanSlate test`;
    if (containsAutoOrderConfirmatoryAlreadyAdded)
      message = `Ordering not allowed until associated Quest confirmation test is deselected`;

    return [
      !showCreateLabOrder ||
        isEmpty(selectedLabOrder) ||
        isEmpty(selectedLabOrder.visitId) ||
        submittedTests.find((t) => t.labCode === labCode) !== undefined ||
        willAutoOrderList.includes(labCode) ||
        containsAutoOrderConfirmatoryAlreadyAdded ||
        alreadyAdded,
      message
    ];
  }

  isConfirmatoryChecked(primaryLabCode, confirmatoryLabCode) {
    let ptest = this.state.labOrder.submittedTests.find((t) => t.labCode === primaryLabCode);
    if (!ptest) ptest = this.state.labOrder.unsubmittedTests.find((t) => t.labCode === primaryLabCode);
    if (!ptest) return false;
    let ctest = ptest.submittedConfirmatoryLabs.find((t) => t.labCode === confirmatoryLabCode);
    if (!ctest) ctest = ptest.unsubmittedConfirmatoryLabs.find((t) => t.labCode === confirmatoryLabCode);
    if (!ctest) return false;
    return true;
  }

  isConfirmatoryDisabled(primaryLabCode, confirmatoryLabCode) {
    const { authRoles, selectedLabOrder, labsConfirmatoryMap } = this.props;
    const showCreateLabOrder = checkRoles(roleGroups.labsCreate, authRoles);

    if (this.getLabDisableInfo(primaryLabCode)[0]) return true;

    const ptest = this.state.labOrder.unsubmittedTests.find((t) => t.labCode === primaryLabCode);
    if (!ptest) return true;

    const ctest = ptest.submittedConfirmatoryLabs.find((t) => t.labCode === confirmatoryLabCode);

    const allPrimaryCodesAdded = [
      ...this.state.labOrder.submittedTests.map((t) => t.labCode),
      ...this.state.labOrder.unsubmittedTests.map((t) => t.labCode)
    ];

    // here it is confirmatory .. we need to check if its equivalent primary is added
    const alreadyAdded = allPrimaryCodesAdded.includes(labsConfirmatoryMap[confirmatoryLabCode]);

    return (
      !showCreateLabOrder ||
      isEmpty(selectedLabOrder) ||
      isEmpty(selectedLabOrder.visitId) ||
      ctest !== undefined ||
      alreadyAdded
    );
  }

  render() {
    const { loading, labOrder, activeAccordionIndex, activeIndex, errors, saving, reloadingTemplate } = this.state;
    const {
      open,
      cleanSlateRapid,
      cleanSlateLabDaq,
      recentOrders,
      selectedLabOrder,
      quest,
      authRoles,
      visitStatuses,
      insurance: { primaryInsurance },
      insuranceProviders
    } = this.props;

    const diagnosisCodesSurvey = this.getDiagnosisCodesSurvey();
    const diagnosisCodesSurveyFilled = this.checkDiagnosisCodesSurveyFilled();

    const { submittedTests, unsubmittedTests } = labOrder;
    const questTestsAdded = labOrder.questTestsAdded;
    const testsThatHaveNonAutoConfirmatory = [...submittedTests, ...unsubmittedTests].filter(
      (t) => !isEmpty(t.confirmatoryLabs) && !t.autoOrderConfirmatory
    );
    const disableSend =
      isEmpty(selectedLabOrder) ||
      isEmpty(selectedLabOrder.visitId) ||
      !diagnosisCodesSurveyFilled ||
      isEmpty(unsubmittedTests) ||
      !isEmpty(errors) ||
      unsubmittedTests.find((t) => isEmpty(t.reason) || isEmpty(t.reason.trim())) !== undefined ||
      unsubmittedTests.find(
        (t) =>
          !isEmpty(t.unsubmittedConfirmatoryLabs) &&
          t.unsubmittedConfirmatoryLabs.find((ct) => isEmpty(ct.reason) || isEmpty(ct.reason.trim())) !== undefined
      ) !== undefined;
    const showCreateLabOrder = checkRoles(roleGroups.labsCreate, authRoles);

    const submittedRapid = submittedTests
      .filter((l) => l.labProvider === "Clean Slate Rapid")
      .sort((a, b) => (a.labName > b.labName ? 1 : a.labName < b.labName ? -1 : 0));
    const submittedLabDaq = submittedTests
      .filter((l) => l.labProvider === "Clean Slate LabDaq" && l.labCode !== URINE_DRUG_SCREEN_LAB_CODE)
      .sort((a, b) => (a.labName > b.labName ? 1 : a.labName < b.labName ? -1 : 0));
    const submittedUds = submittedTests.filter(
      (l) => l.labProvider === "Clean Slate LabDaq" && l.labCode === URINE_DRUG_SCREEN_LAB_CODE
    );
    if (submittedUds.length) submittedLabDaq.unshift(submittedUds[0]);
    const submittedQuest = submittedTests
      .filter((l) => l.labProvider === "Quest")
      .sort((a, b) => (a.labName > b.labName ? 1 : a.labName < b.labName ? -1 : 0));
    const unsubmittedRapid = unsubmittedTests
      .filter((l) => l.labProvider === "Clean Slate Rapid")
      .sort((a, b) => (a.labName > b.labName ? 1 : a.labName < b.labName ? -1 : 0));
    const unsubmittedLabDaq = unsubmittedTests
      .filter((l) => l.labProvider === "Clean Slate LabDaq" && l.labCode !== URINE_DRUG_SCREEN_LAB_CODE)
      .sort((a, b) => (a.labName > b.labName ? 1 : a.labName < b.labName ? -1 : 0));
    const unsubmittedUds = unsubmittedTests.filter(
      (l) => l.labProvider === "Clean Slate LabDaq" && l.labCode === URINE_DRUG_SCREEN_LAB_CODE
    );
    if (unsubmittedUds.length) unsubmittedLabDaq.unshift(unsubmittedUds[0]);
    const unsubmittedQuest = unsubmittedTests
      .filter((l) => l.labProvider === "Quest")
      .sort((a, b) => (a.labName > b.labName ? 1 : a.labName < b.labName ? -1 : 0));

    return (
      <Modal
        className="lab-order-modal"
        open={open}
        closeOnDimmerClick={false}
        onClose={this.handleClose}
        closeIcon
        size="large"
      >
        <Header>
          <span>{`Order Specifications`}</span>
          <span style={{ marginRight: "30px", float: "right", fontSize: "16px", color: "blue" }}>
            {primaryInsurance && getLookupText(primaryInsurance.insurer, insuranceProviders)}
            {primaryInsurance
              ? ` [Start Date: ${
                  primaryInsurance.startDate
                    ? moment(primaryInsurance.startDate, "M/D/YYYY").format("MM/DD/YYYY")
                    : `No start date`
                }]`
              : null}
          </span>
        </Header>
        <Modal.Content scrolling className="no-padding">
          {loading && <ModalLoadingMessage />}
          {!loading && isEmpty(recentOrders) && (
            <div>
              <Message warning style={{ padding: "15x", margin: "15px" }}>
                <Icon name="warning" />
                {`Patient doesn't have any saved specimens from any previous orders`}
              </Message>
            </div>
          )}
          {!loading && !isEmpty(recentOrders) && (
            <Accordion styled fluid style={{ marginBottom: ".5em" }}>
              <Accordion.Title active={activeAccordionIndex === 0} index={0} onClick={this.handleMainAccordionClick}>
                Please select the appropriate Visit/DOS
              </Accordion.Title>
              <Accordion.Content active={activeAccordionIndex === 0}>
                <Grid className="sample-date-container no-margin" style={{ overflow: "visible" }}>
                  <Grid.Row
                    className="half-v-padding"
                    style={{ position: "sticky", top: "0", zIndex: "1000", backgroundColor: "whitesmoke" }}
                  >
                    {recentOrders.filter(
                      (order) =>
                        moment.utc(order.sampleDate).startOf("day").diff(moment.utc().startOf("day"), "days") === 0
                    ).length < 1 && (
                      <Grid.Column width={16}>
                        <Message className="half-padding" warning>
                          There is no medical visit for today to add labs
                        </Message>
                      </Grid.Column>
                    )}
                    <Grid.Column width={16} className="full-padding">
                      <Dropdown
                        id="order-specs-visits-ddl"
                        fluid
                        selection
                        name="sampleCollectionDateOption"
                        value={selectedLabOrder.visitId}
                        options={recentOrders.map((order) => ({
                          id: `order-specs-visits-item-${order.visitId}`,
                          key: order.visitId,
                          value: order.visitId,
                          text: (
                            <Grid className="no-margin">
                              <Grid.Row className="no-padding">
                                <Grid.Column width={10}>{`${moment(order.sampleDate).format(dateWithTimeFormat)} (${
                                  order.visitType
                                })`}</Grid.Column>
                                <Grid.Column width={4} style={{ color: "red" }}>
                                  {order.visitStatus === VISIT_STATUS_VISIT_CANCELED
                                    ? `(${getLookupText(order.visitStatus, visitStatuses)})`
                                    : ``}
                                </Grid.Column>
                              </Grid.Row>
                            </Grid>
                          ),
                          description:
                            moment.utc(order.sampleDate).startOf("day").diff(moment.utc().startOf("day"), "days") === 0
                              ? "Today"
                              : `${-moment
                                  .utc(order.sampleDate)
                                  .startOf("day")
                                  .diff(moment.utc().startOf("day"), "days")} days`
                        }))}
                        onChange={this.handleSampleCollectionDateOptionChange}
                      />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Accordion.Content>

              <Accordion.Title
                active={activeAccordionIndex === 1}
                index={1}
                onClick={this.handleMainAccordionClick}
                className={`${
                  reloadingTemplate ||
                  selectedLabOrder.isVisitSigned ||
                  !selectedLabOrder.visitId ||
                  !diagnosisCodesSurvey
                    ? "disabled"
                    : ""
                } ${reloadingTemplate ? "" : diagnosisCodesSurveyFilled ? "success" : "danger"}`}
              >
                Please fill in Diagnoses Codes
              </Accordion.Title>
              <Accordion.Content active={activeAccordionIndex === 1}>
                {diagnosisCodesSurvey && (
                  <Survey
                    for="templates"
                    survey={diagnosisCodesSurvey}
                    asModal={false}
                    autoSave={false}
                    promptOnLeaving={false}
                    hideModal={false}
                    contextVisitId={selectedLabOrder.visitId}
                    manipulateAnswers={(answers) => ({
                      ...answers,
                      answers: answers.answers.map((answer) => ({ ...answer, visitId: selectedLabOrder.visitId }))
                    })}
                    afterSave={() => {
                      this.setState({ activeAccordionIndex: 2 });
                    }}
                  />
                )}
              </Accordion.Content>

              <Accordion.Title
                active={activeAccordionIndex === 2}
                index={2}
                onClick={this.handleMainAccordionClick}
                className={isEmpty(selectedLabOrder.visitId) || !diagnosisCodesSurveyFilled ? "disabled" : ""}
              >
                Please select tests
              </Accordion.Title>
              <Accordion.Content active={activeAccordionIndex === 2}>
                <Grid divided className="no-margin" style={{ maxHeight: "inherit" }}>
                  <Grid.Row className="no-padding" style={{ maxHeight: "inherit" }}>
                    <Grid.Column
                      width={8}
                      className="no-padding lab-order-form"
                      style={{ maxHeight: "inherit", overflow: "auto" }}
                    >
                      <Grid className="sample-date-container no-margin" style={{ overflow: "visible" }}>
                        <Grid.Row className="half-v-padding" style={{ zIndex: "500" }}>
                          <Grid.Column width={16}>
                            <Accordion fluid styled>
                              <Accordion.Title
                                id="order-specs-accordion-title-rapid"
                                active={activeIndex === 0}
                                index={0}
                                onClick={this.handleAccordionClick}
                                className={labOrder.sampleCollectionDateOption === "future" ? "disabled" : ""}
                              >
                                Clean Slate - Rapid Tests
                              </Accordion.Title>
                              <Accordion.Content
                                active={activeIndex === 0 && labOrder.sampleCollectionDateOption !== "future"}
                              >
                                {cleanSlateRapid && cleanSlateRapid.length > 0 ? (
                                  cleanSlateRapid.map((lab) => {
                                    const [disabled, message] = this.getLabDisableInfo(lab.labCode);
                                    return (
                                      <React.Fragment key={lab.labCode}>
                                        <Form.Checkbox
                                          id={`order-specs-lab-checkbox-${lab.labCode}`}
                                          label={lab.labName}
                                          labcode={lab.labCode}
                                          onChange={this.handleLabChecked}
                                          name="cleanSlateRapid"
                                          checked={this.isLabChecked(lab.labCode)}
                                          disabled={disabled}
                                        />
                                        {disabled && !isEmpty(message) && (
                                          <div className="disabled field">
                                            <div className="ui disabled warning" style={{ paddingLeft: "2rem" }}>
                                              <label>{message}</label>
                                            </div>
                                          </div>
                                        )}
                                      </React.Fragment>
                                    );
                                  })
                                ) : (
                                  <span style={{ color: "gray" }}>There are no tests available for this Center</span>
                                )}
                              </Accordion.Content>

                              <Accordion.Title
                                id="order-specs-accordion-title-labdaq"
                                active={activeIndex === 1}
                                index={1}
                                onClick={this.handleAccordionClick}
                                className={labOrder.sampleCollectionDateOption === "future" ? "disabled" : ""}
                              >
                                Clean Slate - LabDaq Tests
                              </Accordion.Title>
                              <Accordion.Content
                                active={activeIndex === 1 && labOrder.sampleCollectionDateOption !== "future"}
                              >
                                {cleanSlateLabDaq && cleanSlateLabDaq.length > 0 ? (
                                  cleanSlateLabDaq.map((lab) => {
                                    const [disabled, message] = this.getLabDisableInfo(lab.labCode);
                                    return (
                                      <React.Fragment key={lab.labCode}>
                                        <Form.Checkbox
                                          id={`order-specs-lab-checkbox-${lab.labCode}`}
                                          key={lab.labCode}
                                          label={lab.labName}
                                          labcode={lab.labCode}
                                          className={lab.labCode === URINE_DRUG_SCREEN_LAB_CODE ? "text-bold" : ""}
                                          onChange={this.handleLabChecked}
                                          name="cleanSlateLabDaq"
                                          checked={this.isLabChecked(lab.labCode)}
                                          disabled={disabled}
                                        />
                                        {disabled && !isEmpty(message) && (
                                          <div className="disabled field">
                                            <div className="ui disabled warning" style={{ paddingLeft: "2rem" }}>
                                              <label>{message}</label>
                                            </div>
                                          </div>
                                        )}
                                      </React.Fragment>
                                    );
                                  })
                                ) : (
                                  <span style={{ color: "gray" }}>There are no tests available for this Center</span>
                                )}
                              </Accordion.Content>

                              <Accordion.Title
                                id="order-specs-accordion-title-quest"
                                active={activeIndex === 2 || labOrder.sampleCollectionDateOption === "future"}
                                index={2}
                                onClick={this.handleAccordionClick}
                              >
                                Quest Diagnostics Tests
                              </Accordion.Title>
                              <Accordion.Content
                                active={activeIndex === 2 || labOrder.sampleCollectionDateOption === "future"}
                              >
                                {quest && quest.length > 0 ? (
                                  quest.map((lab) => {
                                    const [disabled, message] = this.getLabDisableInfo(lab.labCode);
                                    return (
                                      <React.Fragment key={lab.labCode}>
                                        <Form.Checkbox
                                          id={`order-specs-lab-checkbox-${lab.labCode}`}
                                          key={lab.labCode}
                                          label={lab.labName}
                                          labcode={lab.labCode}
                                          onChange={this.handleLabChecked}
                                          name="quest"
                                          checked={this.isLabChecked(lab.labCode)}
                                          disabled={disabled}
                                        />
                                        {disabled && !isEmpty(message) && (
                                          <div className="disabled field">
                                            <div className="ui disabled warning" style={{ paddingLeft: "2rem" }}>
                                              <label>{message}</label>
                                            </div>
                                          </div>
                                        )}
                                      </React.Fragment>
                                    );
                                  })
                                ) : (
                                  <span style={{ color: "gray" }}>There are no tests available for this Center</span>
                                )}
                              </Accordion.Content>

                              {testsThatHaveNonAutoConfirmatory.map((test, index) => (
                                <React.Fragment key={test.labCode}>
                                  <Accordion.Title
                                    id={`order-specs-accordion-title-conditional-${test.labCode}`}
                                    active={
                                      activeIndex === 3 + index || labOrder.sampleCollectionDateOption === "future"
                                    }
                                    index={3 + index}
                                    onClick={this.handleAccordionClick}
                                  >
                                    {`Conditional Tests`}
                                  </Accordion.Title>
                                  <Accordion.Content
                                    active={
                                      activeIndex === 3 + index || labOrder.sampleCollectionDateOption === "future"
                                    }
                                  >
                                    {test.confirmatoryLabs.map((confimatory) => (
                                      <Form.Checkbox
                                        id={`order-specs-lab-checkbox-${confimatory.labCode}`}
                                        key={confimatory.labCode}
                                        label={confimatory.displayText}
                                        pcode={test.labCode}
                                        ccode={confimatory.labCode}
                                        onChange={this.handleConfirmatoryChecked}
                                        name="quest"
                                        checked={this.isConfirmatoryChecked(test.labCode, confimatory.labCode)}
                                        disabled={this.isConfirmatoryDisabled(test.labCode, confimatory.labCode)}
                                      />
                                    ))}
                                  </Accordion.Content>
                                </React.Fragment>
                              ))}
                            </Accordion>
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    </Grid.Column>
                    <Grid.Column width={8} className="no-padding" style={{ maxHeight: "inherit", overflow: "auto" }}>
                      <Grid className="no-margin">
                        <Grid.Row>
                          <Grid.Column width={16}>
                            <h4>Added Tests</h4>
                            <List divided verticalAlign="middle">
                              {(submittedRapid.length > 0 || unsubmittedRapid.length > 0) && (
                                <ExtendedLabGroup
                                  header={`Clean Slate - Rapid Tests`}
                                  submittedTests={submittedRapid}
                                  unsubmittedTests={unsubmittedRapid}
                                  handleRemoveClicked={this.handleRemoveClicked}
                                  handleLabReasonDdlChange={this.handleLabReasonDdlChange}
                                  handleLabReasonTextChange={this.handleLabReasonTextChange}
                                />
                              )}
                              {(submittedLabDaq.length > 0 || unsubmittedLabDaq.length > 0) && (
                                <ExtendedLabGroup
                                  header={`Clean Slate - LabDaq Tests`}
                                  submittedTests={submittedLabDaq}
                                  unsubmittedTests={unsubmittedLabDaq}
                                  handleRemoveClicked={this.handleRemoveClicked}
                                  handleLabReasonDdlChange={this.handleLabReasonDdlChange}
                                  handleLabReasonTextChange={this.handleLabReasonTextChange}
                                />
                              )}
                              {(submittedQuest.length > 0 || unsubmittedQuest.length > 0) && (
                                <ExtendedLabGroup
                                  header={`Quest Diagnostics Tests`}
                                  submittedTests={submittedQuest}
                                  unsubmittedTests={unsubmittedQuest}
                                  handleRemoveClicked={this.handleRemoveClicked}
                                  handleLabReasonDdlChange={this.handleLabReasonDdlChange}
                                  handleLabReasonTextChange={this.handleLabReasonTextChange}
                                />
                              )}
                            </List>
                          </Grid.Column>
                        </Grid.Row>
                        {showCreateLabOrder && questTestsAdded && (
                          <Grid.Row>
                            <Grid.Column width={16} className="border-top">
                              <Form.Field className={!questTestsAdded || !showCreateLabOrder ? "disabled" : ""}>
                                <h4>Add a comment to be sent with the order</h4>
                                <Form.Checkbox
                                  id={`order-specs-sample-refrigerated-checkbox`}
                                  name="sampleWasRefrigerated"
                                  label="Sample was refrigerated"
                                  checked={labOrder.sampleWasRefrigerated}
                                  onChange={this.handleCheckbox}
                                  disabled={!questTestsAdded || !showCreateLabOrder}
                                />
                              </Form.Field>
                              <Form.Field>
                                <Form.TextArea
                                  id={`order-specs-submission-comment`}
                                  label={false}
                                  name="submissionComment"
                                  disabled={!labOrder.sampleWasRefrigerated || !questTestsAdded || !showCreateLabOrder}
                                  maxLength={255}
                                  value={labOrder.submissionComment}
                                  onChange={this.handleInput}
                                />
                              </Form.Field>
                            </Grid.Column>
                          </Grid.Row>
                        )}
                      </Grid>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Accordion.Content>
            </Accordion>
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.handleClose} id="btn-cancel" disabled={saving}>
            Cancel
          </Button>
          {showCreateLabOrder && (
            <Button
              onClick={/* labOrder.addConfirmatoryTest ? this.handleNext : */ this.handleSave}
              color="blue"
              id="btn-sendToLab"
              loading={saving}
              disabled={saving || disableSend}
            >
              {labOrder.addConfirmatoryTest ? `Next` : `Add to Order`}
            </Button>
          )}
        </Modal.Actions>
      </Modal>
    );
  }
}

function formatInsuranceProvForDropdown(insuranceProviders) {
  return insuranceProviders.map((insuranceProvider) => ({
    value: insuranceProvider.value.toString(),
    text: insuranceProvider.text
  }));
}

function mapStateToProps(state) {
  const selectedLabOrder = state.labs.selectedLabOrder;
  const demographics = state.demographics;

  let centerId = null;
  if (isEmpty(selectedLabOrder) || isEmpty(selectedLabOrder.sampleDate)) centerId = demographics.homeCenterId;
  else centerId = selectedLabOrder.centerId;
  const selectedLabOrderVisitType =
    selectedLabOrder && state.lookups.visitTypes
      ? state.lookups.visitTypes.find((vt) => vt.labCode === selectedLabOrder.visitType)
      : null;
  const isSelectedLabOrderVisitMedical = selectedLabOrderVisitType ? selectedLabOrderVisitType.medical : null;
  const availableLabs = state.labs.availableLabs[centerId] ? state.labs.availableLabs[centerId] : [];
  const labsConfirmatoryMap = state.labs.labsConfirmatoryMap[centerId] ? state.labs.labsConfirmatoryMap[centerId] : [];
  const groupedLabs = groupBy(availableLabs, "labProvider");
  const cleanSlateRapid = sortBy(groupedLabs["Clean Slate Rapid"], "labName");
  const cleanSlateLabDaq = sortBy(groupedLabs["Clean Slate LabDaq"], "labName").filter(
    (t) => t.labCode !== URINE_DRUG_SCREEN_LAB_CODE
  );
  const uds = groupedLabs["Clean Slate LabDaq"]
    ? groupedLabs["Clean Slate LabDaq"].filter((t) => t.labCode === URINE_DRUG_SCREEN_LAB_CODE)
    : [];
  if (uds.length) cleanSlateLabDaq.unshift(uds[0]);
  const quest = sortBy(groupedLabs.Quest, "labName");

  // let diagnosisCodesSurvey = null;
  // const maTemplate = state.templates.specificTemplates[TEMPLATE_ID_MA_TEMPLAE_ID];
  // if (maTemplate) {
  //   diagnosisCodesSurvey = maTemplate.surveys.find(survey => survey.id === SURVEY_ID_DIAGNOSIS_CODES_SURVEY_ID);
  // }

  return {
    centerId,
    isSelectedLabOrderVisitMedical,
    availableLabs,
    labsConfirmatoryMap,
    cleanSlateRapid,
    cleanSlateLabDaq,
    quest,
    recentOrders: state.labs.recentOrders.filter((o) => !o.hideFromOrderLabs),
    selectedLabOrder,
    patientId: state.patient.currentPatient.patientId,
    authRoles: state.auth.user.profile.roles,
    visitStatuses: state.lookups.visitStatuses,
    insurance: state.insurance || {},
    specificTemplates: state.templates.specificTemplates,
    reloadTemplate: state.templates.reloadTemplate,
    insuranceProviders: formatInsuranceProvForDropdown(state.lookups.insuranceProviders)
    // diagnosisCodesSurvey
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      { ...modalActions, ...templateActions, ...labActions, ...lookupActions, ...insuranceActions },
      dispatch
    )
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ExtendedLabOrderSpecificationsModal);
