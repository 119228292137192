import React, { Component } from "react";
import { Button, Form, Icon, Table, Segment, Dropdown } from "semantic-ui-react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { cloneDeep, isEqual, isEmpty, set } from "lodash";
import * as lookupActions from "../../actions/lookupActions";
import * as centerActions from "../../actions/centerActions";
import * as providerActions from "../../actions/providerActions";
import * as adminActions from "../../actions/adminActions";
import "./ManageUser.css";

export class ProviderCenters extends Component {
  state = {
    centerDetails: {
      homeCenter: {
        centerId: null,
        registeredGateways: null
      },
      otherCenters: []
    },
    column: "state",
    direction: "ascending",
    originalData: [],
    data: [],
    filter: {
      selectAll: false,
      state: null
    }
  };

  componentDidMount() {
    const {
      actions: { loadProviderCenters, loadStates, loadCenters },
      userId
    } = this.props;
    loadStates();
    loadCenters().then(() => {
      if (!isEmpty(this.props.states)) {
        const stateCodes = this.props.states.map(s => s.stateCode);
        const data = this.props.centers.filter(c => stateCodes.includes(c.state));
        this.setState({ data, originalData: data }, () => {
          this.doSort();
        });
      }
    });
    loadProviderCenters(userId).then(() => {
      this.setState({ centerDetails: this.props.providerCenters });
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (!isEqual(this.state.filter, prevState.filter)) {
      this.applyFilter();
    }
    if (!isEmpty(this.props.centers) && !isEqual(prevProps.states, this.props.states)) {
      const stateCodes = this.props.states.map(s => s.stateCode);
      const data = this.props.centers.filter(c => stateCodes.includes(c.state));
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ data, originalData: data }, () => {
        this.doSort();
      });
    }
  }

  getCenterInfo = value => {
    const { originalData, centerDetails } = this.state;
    if (value && originalData.length) {
      let center = originalData.find(item => item.centerId === value);
      if (isEmpty(center)) return ``;
      center = `${center.address1}, ${center.city}, ${center.state}`;
      let details = null;
      if (centerDetails.homeCenter && centerDetails.homeCenter.centerId === value) details = centerDetails.homeCenter;
      if (!details) details = centerDetails.otherCenters.find(c => c.centerId === value);
      return (
        <span>
          {center}&nbsp;
          <strong>
            {!isEmpty(details) && !isEmpty(details.registeredGateways) && `[`}
            <i>
              {!isEmpty(details) && !isEmpty(details.registeredGateways) ? details.registeredGateways.join(`, `) : ``}
            </i>
            {!isEmpty(details) && !isEmpty(details.registeredGateways) && `]`}
          </strong>
        </span>
      );
    }
    return "";
  };

  applyFilter = () => {
    this.setState({
      data: this.state.originalData.filter(i => isEmpty(this.state.filter.state) || i.state === this.state.filter.state)
    });
  };

  handleCheckbox = (e, data) => {
    const { checked, value } = data;
    let otherCenters = [...this.state.centerDetails.otherCenters];
    if (checked) otherCenters.push({ centerId: value, registeredGateways: [] });
    else otherCenters = otherCenters.filter(c => c.centerId !== value);
    this.setState({ centerDetails: { ...this.state.centerDetails, otherCenters } });
  };

  handleCheckAll = (e, data) => {
    let otherCenters = [...this.state.centerDetails.otherCenters];
    if (data.checked) {
      this.state.data.forEach(i => {
        if (!otherCenters.find(c => c.centerId === i.centerId))
          otherCenters.push({ centerId: i.centerId, registeredGateways: [] });
      });
    } else {
      this.state.data.forEach(i => {
        if (otherCenters.find(c => c.centerId === i.centerId))
          otherCenters = otherCenters.filter(c => c.centerId !== i.centerId);
      });
    }
    this.setState({
      filter: { ...this.state.filter, selectAll: !this.state.filter.selectAll },
      centerDetails: { ...this.state.centerDetails, otherCenters }
    });
  };

  handleSave = gateway => {
    const {
      actions: { updateProviderCenters },
      userId,
      history
    } = this.props;
    const { centerDetails } = this.state;

    updateProviderCenters(
      userId,
      {
        homeCenterId: centerDetails.homeCenter.centerId,
        otherCenterIds: centerDetails.otherCenters.map(c => c.centerId)
      },
      gateway
    ).then(() => {
      history.push(`/admin/manage-users`);
    });
  };

  handleHomeCenterRadio = (e, data) => {
    const { value } = data;
    this.setState({
      centerDetails: { ...this.state.centerDetails, homeCenter: { centerId: value, registeredGateways: [] } }
    });
  };

  doSort = () => {
    const { data, column, direction } = this.state;
    const sorted = data.sort((a, b) => {
      if (direction === "ascending") return a[column] > b[column] ? 1 : a[column] < b[column] ? -1 : 0;
      return a[column] < b[column] ? 1 : a[column] > b[column] ? -1 : 0;
    });
    this.setState({ data: sorted });
  };

  handleSort = clickedColumn => () => {
    const { column: stateColumn } = this.state;
    this.setState(
      {
        column: clickedColumn,
        direction:
          stateColumn === clickedColumn
            ? this.state.direction === "ascending"
              ? "descending"
              : "ascending"
            : "ascending"
      },
      () => {
        this.doSort();
      }
    );
  };

  handleFilterChange = (_, data) => {
    const { name, value } = data;
    const updatedState = cloneDeep(this.state);
    set(updatedState, name, value);
    this.setState(updatedState);
  };

  render() {
    const { stateOptions, processing } = this.props;
    const { centerDetails, column, direction, data, filter } = this.state;

    if (isEmpty(centerDetails)) return null;

    const disableSave = isEmpty(centerDetails.homeCenter) || isEmpty(centerDetails.homeCenter.centerId);

    return (
      <Segment color="green" className="no-margin no-padding">
        <Button
          color="green"
          size="mini"
          floated="right"
          disabled={disableSave || processing}
          onClick={() => {
            this.handleSave("surescripts");
          }}
          id="button-save-surescripts"
          loading={processing}
          style={{ margin: "1em" }}
        >
          Save
        </Button>
        <ul className="provider-centers-list">
          <li>
            <span>
              {centerDetails.homeCenter && centerDetails.homeCenter.centerId && <Icon color="orange" name="home" />}
              {centerDetails.homeCenter && <span>{this.getCenterInfo(centerDetails.homeCenter.centerId)}</span>}
            </span>
          </li>
          {centerDetails.otherCenters.map(({ centerId }) => (
            <li key={centerId}>{this.getCenterInfo(centerId)}</li>
          ))}
        </ul>
        <div style={{ height: "500px", overflow: "auto" }}>
          <Table sortable basic="very">
            <Table.Header>
              <Table.Row className="compact-header-row">
                <Table.HeaderCell>Home Clinic</Table.HeaderCell>
                <Table.HeaderCell>Select</Table.HeaderCell>
                <Table.HeaderCell
                  sorted={column === "centerName" ? direction : null}
                  onClick={this.handleSort("centerName")}
                >
                  Name
                </Table.HeaderCell>
                <Table.HeaderCell>Street</Table.HeaderCell>
                <Table.HeaderCell>City</Table.HeaderCell>
                <Table.HeaderCell sorted={column === "state" ? direction : null} onClick={this.handleSort("state")}>
                  State
                </Table.HeaderCell>
                <Table.HeaderCell>Zip</Table.HeaderCell>
              </Table.Row>
              <Table.Row className="filter-row">
                <Table.HeaderCell />
                <Table.HeaderCell textAlign="center">
                  <Form.Checkbox name="filter.selectAll" onChange={this.handleCheckAll} checked={filter.selectAll} />
                </Table.HeaderCell>
                <Table.HeaderCell />
                <Table.HeaderCell />
                <Table.HeaderCell />
                <Table.HeaderCell>
                  <Dropdown
                    name="filter.state"
                    options={stateOptions}
                    placeholder="Select..."
                    value={filter.state}
                    onChange={this.handleFilterChange}
                    // multiple
                    clearable
                    selection
                    search
                    fluid
                  />
                </Table.HeaderCell>
                <Table.HeaderCell />
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {data.map(center => (
                <Table.Row key={center.centerId}>
                  <Table.Cell>
                    <Form.Radio
                      name="homeCenterId"
                      value={center.centerId}
                      onClick={this.handleHomeCenterRadio}
                      checked={centerDetails.homeCenter.centerId === center.centerId}
                    />
                  </Table.Cell>
                  <Table.Cell textAlign="center">
                    <Form.Checkbox
                      name="select"
                      onChange={this.handleCheckbox}
                      checked={centerDetails.otherCenters.map(_center => _center.centerId).includes(center.centerId)}
                      value={center.centerId}
                    />
                  </Table.Cell>
                  <Table.Cell>{center.centerName}</Table.Cell>
                  <Table.Cell>{center.address1}</Table.Cell>
                  <Table.Cell>{center.city}</Table.Cell>
                  <Table.Cell>{center.state}</Table.Cell>
                  <Table.Cell>{center.zip}</Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </div>
      </Segment>
    );
  }
}

function mapStateToProps(state) {
  return {
    centers: state.centers.items.filter(i => i.active),
    stateOptions: state.lookups.states,
    currentProvider: state.providers.currentProvider,
    providerCenters: state.providers.providerCenters,
    processing: state.ajaxCallsInProgress > 0
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...lookupActions, ...centerActions, ...providerActions, ...adminActions }, dispatch)
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ProviderCenters)
);
