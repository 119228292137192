import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as patientHistoryActions from "../../../../actions/patientHistoryActions";
import * as modalActions from "../../../../actions/modalActions";
import * as lookupActions from "../../../../actions/lookupActions";
import { roleGroups } from "../../../../constants/securityRoles";
import AllergyTableRow from "./AllergyTableRow";
import { getLookupText, checkRoles } from "../../../../helpers";
import "./AllergyTable.css";

export class AllergyTable extends Component {
  componentDidMount() {
    this.props.actions.loadReactions();
  }

  handleEditAllergy = (e, data) => {
    const { allergyHistory } = this.props;
    const allergyType = allergyHistory[data.index].isMedicationAllergy
      ? "MedicationAllergyHistory"
      : "NonMedicationAllergyHistory";
    switch (data.icon) {
      case "delete":
        this.props.actions.deleteAllergyHist(this.props.patientId, allergyHistory[data.index].itemId, allergyType);
        break;
      case "plus":
      case "edit":
        this.props.actions.showModal({
          type: "ADD_ALLERGY",
          props: {
            open: true,
            allergyHistory: allergyHistory[data.index],
            isEdit: true
          }
        });
        break;
      default:
        break;
    }
  };

  render() {
    const { allergyHistory, reactionTypes, authRoles } = this.props;
    const showEditHistory = checkRoles(roleGroups.excludeHelpDesk, authRoles);
    const showDeleteHistory = checkRoles(roleGroups.providers, authRoles);
    return (
      <div className="allergyHistoryTable">
        <table>
          <thead>
            <tr>
              <th>Medication/allergen</th>
              <th width="5%" />
              <th width="50%">Reaction</th>
              <th width="5%" />
              <th width="5%" />
              <th width="5%">Note</th>
              <th width="5%" />
              <th width="5%" />
              <th width="5%" />
            </tr>
          </thead>
          <tbody>
            {allergyHistory.length
              ? allergyHistory.map((allergy, i) => (
                  <AllergyTableRow
                    key={allergy.itemId}
                    index={i}
                    allergy={allergy}
                    reactionText={getLookupText(allergy.reactionType, reactionTypes)}
                    handleEditAllergy={this.handleEditAllergy}
                    showEditHistory={showEditHistory}
                    showDeleteHistory={showDeleteHistory}
                  />
                ))
              : null}
          </tbody>
        </table>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    patientId: state.patient.currentPatient.patientId,
    reactionTypes: state.lookups.reactionTypes,
    authRoles: state.auth.user.profile.roles
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...patientHistoryActions, ...modalActions, ...lookupActions }, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AllergyTable);
