import React, { Component } from "react";
import { Accordion, Checkbox, Button, Grid, Header, Icon } from "semantic-ui-react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import isEmpty from "lodash/isEmpty";
import * as modalActions from "../../../actions/modalActions";
import {
  loadDrugForms,
  loadDrugRoutes,
  loadDrugStrengths,
  clearDrugRoutes,
  clearDrugStrengths
} from "../../../actions/lookupActions";
import * as userActions from "../../../actions/userActions";
import * as prescriptionActions from "../../../actions/prescriptionActions";
import { selectedPrescription as getSelectedPrescription } from "../../../reducers/rootReducer";
import { fetchPrescriptionInteractions, fetchPrescriptionAllergies } from "../../../api/screening";
import { roleGroups } from "../../../constants/securityRoles";
import {
  checkRoles,
  requiredFieldsFilled,
  noteToPharmacistInvalid,
  specialInstrunctionsInvalid
} from "../../../helpers";
import prescriptionStatus from "../../../constants/prescriptionStatus";
import RxItemForm from "./RxItemForm";

export class RxItem extends Component {
  setSelected = (e, d) => {
    if (d.active === true) {
      this.handleCancel();
    } else {
      const {
        rxType,
        prescription: { prescriptionId },
        actions: { setSelectedPrescription }
      } = this.props;
      this.props.actions.setRxItemForm(this.props.prescription, true);
      setSelectedPrescription(prescriptionId, rxType);
    }
  };

  handleFocus = (e) => {
    e.target.setAttribute("autocomplete", "nope");
  };

  handleKeyDown = (e) => {
    if (e.key === "Enter") e.preventDefault();
  };

  handleInput = (e, data) => {
    const {
      currentTarget,
      currentTarget: { innerText }
    } = e;
    const { name: field } = data;
    let { value } = data;

    const match = /\r|\n/g.exec(value);
    if (match) {
      value = value.replace(/\r|\n/g, " ");
    }

    const { prescription } = this.props.rxItemForm;
    const {
      actions: { setPrescriptionDrugChange }
    } = this.props;
    let newPrescription = Object.assign({}, prescription, {
      [field]: value,
      ...(field === "form" && { administerRoute: "", strength: "", drugKey: "" }),
      ...(field === "administerRoute" && { strength: "", drugKey: "" }),
      ...(field === "drugKey" && {
        strength: innerText,
        deaClass: parseInt(currentTarget.getAttribute("deaclass"), 10),
        drugDescription: currentTarget.getAttribute("drugdescription"),
        formDescription: currentTarget.getAttribute("formdescription")
      })
    });
    const { form, administerRoute, frequency, dosage } = newPrescription;
    const specialInstructions = `${dosage} ${form} ${administerRoute} ${frequency}`;

    if (
      (field === "drugKey" || field === "frequency" || field === "dosage") &&
      !isEmpty(form) &&
      !isEmpty(administerRoute) &&
      !isEmpty(frequency) &&
      dosage > 0 &&
      specialInstructions !== newPrescription.specialInstructions
    ) {
      newPrescription = { ...newPrescription, specialInstructions };
    }

    this.props.actions.setRxItemForm(newPrescription);

    if (field === "form" && value !== prescription.form) {
      setPrescriptionDrugChange();
    }

    if (field === "administerRoute" && value !== prescription.administerRoute) {
      setPrescriptionDrugChange();
    }

    if (field === "drugKey" && value !== prescription.drugKey) {
      setPrescriptionDrugChange();
    }
  };

  handleQuantity = (e, data) => {
    const { name: field, value } = data;
    const re = /^\d*\.?\d*$/;

    if (value === "" || re.test(value)) {
      const prescription = Object.assign({}, this.props.rxItemForm.prescription, {
        [field]: value
      });
      this.props.actions.setRxItemForm(prescription);
    }
  };

  handleQuantityBlur = () => {
    const prescription = { ...this.props.rxItemForm.prescription };
    prescription.quantity = isEmpty(prescription.quantity) ? "" : parseFloat(prescription.quantity).toString();
    this.props.actions.setRxItemForm(prescription);
  };

  handleProviderInput = (e, data) => {
    const { name: field, value } = data;

    const selection = data.options.find((option) => option.value === data.value);

    const prescription = Object.assign({}, this.props.rxItemForm.prescription, {
      prescriberFirstName: selection.firstname,
      prescriberLastName: selection.lastname,
      [field]: value
    });

    this.props.actions.setRxItemForm(prescription);
  };

  handleCheckbox = (e, data) => {
    const { name: field, checked } = data;
    const prescription = Object.assign({}, this.props.rxItemForm.prescription, {
      [field]: checked
    });
    this.props.actions.setRxItemForm(prescription);
  };

  handleSavedForSig = () => {
    const {
      prescription,
      originalPrescription: { drugKey: prevDrugKey }
    } = this.props.rxItemForm;
    const prescriptionToSave = Object.assign({}, prescription, {
      prescriptionStatus: prescriptionStatus.savedForSignature
    });

    this.props.actions.setRxItemForm(prescriptionToSave, true);
    this.props.handleUpdate(prescription, prevDrugKey);
  };

  handleSign = () => {
    const prescription = Object.assign({}, this.props.rxItemForm.prescription, {
      prescriptionStatus: prescriptionStatus.signed
    });
    const { prescriptionId, prescriberId } = prescription;
    const {
      patientId,
      actions: { updatePrescription, signPrescription }
    } = this.props;
    const {
      originalPrescription: { drugKey: prevDrugKey }
    } = this.props.rxItemForm;

    updatePrescription(patientId, prescription, prevDrugKey)
      .then((response) => {
        if (response) {
          return signPrescription(patientId, prescriptionId, { prescriberId });
        }

        return Promise.resolve();
      })
      .then((response) => {
        if (response) {
          this.props.actions.setRxItemForm({}, true);
          this.props.actions.setSelectedPrescription(null, null);
        }
      });
  };

  handleSend = () => {
    const {
      actions: { showModal },
      patientId,
      centerId,
      mainPrescriberId
    } = this.props;
    const {
      prescription: { prescriptionId }
    } = this.props.rxItemForm;

    showModal({
      type: "SEND_PRESCRIPTION_MODAL",
      props: {
        open: true,
        prescriptionIds: [prescriptionId],
        patientId,
        centerId,
        mainPrescriberId,
        multiple: true
      }
    });
  };

  handleCancel = () => {
    this.props.actions.setRxItemForm({}, true);
    this.props.actions.setSelectedPrescription(null, null);
  };

  handleInteractions = (e) => {
    e.stopPropagation();
    const {
      patientId,
      prescription,
      prescription: { prescriptionId },
      actions: { showModal }
    } = this.props;

    fetchPrescriptionInteractions(prescriptionId).then((interactionsResult) => {
      showModal({
        type: "PATIENT_INTERACTION_MATCH",
        props: {
          open: true,
          interactionsResult,
          prescription,
          patientId,
          isPatientContext: true
        }
      });
    });
  };

  handleAllergies = (e) => {
    e.stopPropagation();
    const {
      prescription,
      prescription: { prescriptionId },
      actions: { showModal }
    } = this.props;

    fetchPrescriptionAllergies(prescriptionId).then((allergiesResult) => {
      showModal({
        type: "PATIENT_ALLERGY_MATCH",
        props: {
          open: true,
          selectedMedication: {
            drugName: prescription.drugName
          },
          screeningResults: allergiesResult,
          isShowOnly: true
        }
      });
    });
  };

  handleAddRecurring = () => () => {
    const { patientId } = this.props;
    const prescriptionCopy = Object.assign({}, this.props.rxItemForm.prescription, {
      prescriptionStatus: ""
    });
    this.props.actions.createRecurringPrescription(patientId, prescriptionCopy);
  };

  handleSave = () => {
    const prescription = Object.assign({}, this.props.rxItemForm.prescription, {
      prescriptionStatus: prescriptionStatus.saved
    });
    // Update with method to edit recurring prescriptions
    this.props.actions.setRxItemForm(prescription, true);
    this.props.handleRecurringUpdate(prescription);
  };

  handleAddCurrent = (prescription) => () => {
    const { patientId } = this.props;
    const objToSend = {
      patientId,
      recurringPrescriptionKey: prescription.prescriptionId
    };
    this.props.actions.addRecurringToCurrent(patientId, objToSend, prescription);
  };

  handleAddToFavorites = () => {
    const {
      drugFavorites,
      rxItemForm: { prescription }
    } = this.props;
    const drugFavorite = drugFavorites.find((i) => i.drugKey === prescription.drugKey);
    const edit = !isEmpty(drugFavorite);
    if (edit) {
      this.props.actions.editDrugFavorites(drugFavorite.favoriteDrugId, this.props.rxItemForm.prescription);
    } else {
      this.props.actions.addDrugFavorites(this.props.rxItemForm.prescription);
    }
  };

  render() {
    const {
      rxItemForm,
      handleDelete,
      mainPrescribers,
      actions: {
        loadDrugForms: fetchDrugForms,
        loadDrugRoutes: fetchDrugRoutes,
        loadDrugStrengths: fetchDrugStrengths,
        clearDrugRoutes: removeDrugRoutes,
        clearDrugStrengths: removeDrugStrengths
      },
      numberPerDoseOptions,
      refillOptions,
      frequencyOptions,
      rxType,
      disabledRxButtons,
      authRoles,
      isReceptionist,
      handlePrintPrescriptions,
      prescriptionToPrintId,
      loggedInUsername,
      selectedPrescription,
      processing
    } = this.props;

    const prescription = this.props.prescription.isSelected ? rxItemForm.prescription : this.props.prescription;
    const {
      quantity,
      dosage,
      noteToPharmacist,
      specialInstructions,
      hasAllergies,
      hasInteractions,
      prescriptionStatus: rxStatus
    } = prescription;

    const active = selectedPrescription && selectedPrescription.prescriptionId === prescription.prescriptionId;

    const status =
      prescription.prescriptionStatus === prescriptionStatus.changesUnsaved && !active
        ? rxStatus
        : prescription.prescriptionStatus;

    const formInvalid =
      requiredFieldsFilled(["form", "strength", "dosage", "administerRoute", "frequency", "quantity"], prescription) ||
      noteToPharmacistInvalid(noteToPharmacist) ||
      specialInstrunctionsInvalid(specialInstructions) ||
      quantity <= 0 ||
      quantity.toString().split(".").join("").length > 10 ||
      dosage <= 0;

    const disableSave = formInvalid;

    const disableRxButton =
      rxType === "recurringRx"
        ? disabledRxButtons.some((disabledRxButton) => disabledRxButton === prescription.prescriptionId)
        : false;

    const enableEditCurrent = checkRoles(roleGroups.editPrescriptions, authRoles);
    const enableSendingRx = checkRoles(roleGroups.medicalProvider, authRoles);
    const canSaveFavoritePrescription = checkRoles(roleGroups.medicalProvider, authRoles);

    const isSignedButtonEnabled =
      enableSendingRx &&
      ((mainPrescribers.find((p) => p.value === prescription.prescriberId) || {}).username || "").toUpperCase() ===
        loggedInUsername.toUpperCase();

    let className = "";
    if (active && hasAllergies) {
      className = "pescription-has-allergy";
    } else if (active && prescription.prescriptionStatus === prescriptionStatus.signed) {
      className = "pescription-signed";
    } else if (active) {
      className = "pescription-no-allergy";
    }

    return (
      <React.Fragment>
        <Accordion.Title
          active={active}
          onClick={this.setSelected}
          pid={prescription.prescriptionId}
          className={className}
        >
          <Grid padded stackable centered>
            <Grid.Row>
              <Grid.Column width={1}>
                {rxType === "currentRx" && (
                  <Checkbox
                    onClick={(e, data) => handlePrintPrescriptions(e, data, prescription)}
                    checked={prescriptionToPrintId === prescription.prescriptionId}
                  />
                )}
              </Grid.Column>
              <Grid.Column width={4}>
                <Header as="h3">{prescription.drugName}</Header>
                {!active && (
                  <div className="secondary-prescription-info">
                    prescriber:
                    {prescription.prescriberId
                      ? ` ${prescription.prescriberFirstName} ${prescription.prescriberLastName}`
                      : "none selected"}
                  </div>
                )}
              </Grid.Column>
              <Grid.Column width={2} textAlign="left">
                {!active ? (
                  <div>
                    <div>{prescription.strength ? prescription.strength : null}</div>

                    <div className="secondary-prescription-info">
                      {prescription.dosage && prescription.frequency && specialInstructions}
                    </div>
                  </div>
                ) : null}
              </Grid.Column>
              <Grid.Column width={4}>
                <div
                  className={
                    prescription.prescriptionStatus === prescriptionStatus.signed
                      ? "signed-status-label"
                      : prescription.prescriptionStatus === prescriptionStatus.changesUnsaved
                      ? "unsaved-status-label"
                      : ""
                  }
                >
                  {prescription.prescriptionStatus !== prescriptionStatus.blank && status}
                </div>
              </Grid.Column>
              <Grid.Column width={1}>
                {hasInteractions && (
                  <Icon name="exclamation triangle" color="orange" onClick={this.handleInteractions} />
                )}
              </Grid.Column>
              <Grid.Column width={1}>
                {hasAllergies && <Icon name="dont" color="red" onClick={this.handleAllergies} />}
              </Grid.Column>
              <Grid.Column width={1}>
                {!active && (
                  <Button
                    className="transparent-button-icon prescriptions-edit"
                    icon="edit"
                    size="big"
                    onClick={this.setSelected}
                    id="btn-edit"
                  />
                )}
              </Grid.Column>
              <Grid.Column width={1}>
                <Button
                  className="transparent-button-icon delete"
                  icon="delete"
                  size="big"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDelete(prescription.prescriptionId);
                  }}
                  id="btn-delete"
                  disabled={processing}
                  loading={processing}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Accordion.Title>

        {active ? (
          <Accordion.Content active={active} className={className}>
            <RxItemForm
              mainPrescribers={mainPrescribers}
              disableSave={disableSave}
              handleInput={this.handleInput}
              handleKeyDown={this.handleKeyDown}
              handleProviderInput={this.handleProviderInput}
              handleCheckbox={this.handleCheckbox}
              handleCancel={this.handleCancel}
              handleSavedForSig={this.handleSavedForSig}
              handleSend={this.handleSend}
              handleSign={this.handleSign}
              handleFocus={this.handleFocus}
              handleSave={this.handleSave}
              handleQuantity={this.handleQuantity}
              handleQuantityBlur={this.handleQuantityBlur}
              handleAddRecurring={this.handleAddRecurring}
              handleAddCurrent={this.handleAddCurrent}
              loadDrugForms={fetchDrugForms}
              loadDrugRoutes={fetchDrugRoutes}
              numberPerDoseOptions={numberPerDoseOptions}
              refillOptions={refillOptions}
              frequencyOptions={frequencyOptions}
              loadDrugStrengths={fetchDrugStrengths}
              clearDrugRoutes={removeDrugRoutes}
              clearDrugStrengths={removeDrugStrengths}
              rxType={rxType}
              disableRxButton={disableRxButton}
              enableEditCurrent={enableEditCurrent}
              isReceptionist={isReceptionist}
              isSignedButtonEnabled={isSignedButtonEnabled}
              enableSendingRx={enableSendingRx}
              canSaveFavoritePrescription={canSaveFavoritePrescription}
              handleAddToFavorites={this.handleAddToFavorites}
            />
          </Accordion.Content>
        ) : null}
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    patientId: (state.patient.currentPatient || {}).patientId || null,
    centerId: state.demographics.homeCenterId,
    selectedPrescription: getSelectedPrescription(state),
    rxItemForm: state.prescriptions.rxItemForm,
    drugFavorites: state.user.drugFavorites,
    processing: state.ajaxCallsInProgress > 0
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...modalActions,
        loadDrugForms,
        loadDrugRoutes,
        loadDrugStrengths,
        clearDrugRoutes,
        clearDrugStrengths,
        ...userActions,
        ...prescriptionActions
      },
      dispatch
    )
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RxItem);
