import { isEmpty, cloneDeep } from "lodash";

const createStyleClass = (styleCode) => {
  switch (styleCode) {
    case "-$-":
      return "option-bold";
    case "-$$-":
      return "option-normal";
    case "-$$$-":
      return "option-warning";
    case "-$$$$-":
      return "option-danger";
    case "-*-":
      return "option-default";
    default:
      break;
  }
  return "";
};

// Checks to see if option has a default or a class applied to it
export const parseLabel = (option, returnedVariable) => {
  const splitOption = option.split("!").filter(String);
  const codeArray = splitOption.filter((item) => item[0].charAt(0) === "-").map((item) => createStyleClass(item));
  const labelText = splitOption.filter((item) => item[0].charAt(0) !== "-").join();
  return returnedVariable === "style" ? codeArray : labelText;
};

// Helper function to checking question triggers will add more match operators to make extensible
const handleMatchOperator = (operator, matchValue, currentValue) => {
  let _currentValue = null;
  if (Array.isArray(currentValue)) _currentValue = currentValue[0];
  else if (currentValue !== Object(currentValue)) _currentValue = currentValue;
  else throw new Error("Unsupported currentValue");

  switch (operator) {
    case "in":
      if (Array.isArray(matchValue)) return matchValue.indexOf(_currentValue) > -1;
      throw new Error("Match value must be an array for in operator");
    case "eq":
      return _currentValue === matchValue;
    case "exists":
      return !!_currentValue;
    case "includes":
      if (Array.isArray(currentValue)) return currentValue.includes(matchValue);
      throw new Error("Current value must be an array for includes operator");
    default:
      throw new Error("Unsupported operation");
  }
};

export const getQuestionId = (item) => ({
  questionKey: item.key || item.questionKey,
  questionCount: item.questionCount,
  sectionKey: item.sectionKey,
  sectionCount: item.sectionCount,
  groupKey: item.groupKey,
  groupCount: item.groupCount
});

export const getQuestionStringId = (item) =>
  `${item.sectionKey}-${item.sectionCount}-${item.groupKey}-${item.groupCount}-${item.key || item.questionKey}-${
    item.questionCount
  }`;

export const checkIsItem = (item, id) => {
  const _id = { ...id };
  if (item.questionKey !== undefined && _id.questionKey === undefined) {
    _id.questionKey = _id.key;
    delete _id.key;
  }
  if (item.key !== undefined && _id.key === undefined) {
    _id.key = _id.questionKey;
    delete _id.questionKey;
  }
  return Object.keys(_id).every((key) => _id[key] === item[key]);
};

export const findItem = (list, id) => list.find((item) => checkIsItem(item, id));

export const findItemIndex = (list, id) => list.findIndex((item) => checkIsItem(item, id));

export const getQuestionPaddingLevel = (question, flatLayout) => {
  const { triggers } = question;
  if (triggers.length > 0) {
    // for (let i = 0; i < triggers.length; i++) {
    /** theoretically question can be triggered by more than one question
     * so theoretically it can appear in different levels
     * but will will ignore this case ofr now, and will always suppose it is appearing on the same level
     * so we will just test the first trigger
     */
    const trigger = triggers[0];
    const _question = flatLayout.find((q) => q.key === trigger.questionKey);
    if (_question && _question.triggers && _question.triggers.length > 0) {
      return 1 + getQuestionPaddingLevel(_question, flatLayout);
    }
    return 1;
    // }
  }
  return 0;
};

export const isQuestionTriggered = (question, flatLayout, surveyData) => {
  const { triggers } = question;
  if (triggers.length === 0) {
    return true;
  }
  let result = false;
  const questionCount = question.questionCount;
  const sectionKey = question.sectionKey;
  const sectionCount = question.sectionCount;
  const groupKey = question.groupKey;
  const groupCount = question.groupCount;
  const paddingLevel = getQuestionPaddingLevel(question, flatLayout);
  triggers.forEach((trigger) => {
    let _questions = flatLayout.filter((q) => q.key === trigger.questionKey);
    if (_questions.length > 1) _questions = _questions.filter((q) => q.sectionKey === sectionKey);
    if (_questions.length > 1) _questions = _questions.filter((q) => q.sectionCount === sectionCount);
    if (_questions.length > 1) _questions = _questions.filter((q) => q.groupKey === groupKey);
    if (_questions.length > 1) _questions = _questions.filter((q) => q.groupCount === groupCount);
    if (paddingLevel > 1 || _questions.length > 1)
      _questions = _questions.filter((q) => q.questionCount === questionCount);
    if (_questions.length > 1) throw new Error("Duplicate question!");
    const _question = _questions.find((q) => q.key === trigger.questionKey);
    if (!_question) {
      // eslint-disable-next-line no-bitwise
      result |= false;
      return;
    }
    const currentValue = surveyData[getQuestionStringId(_question)].answers;
    let _result = handleMatchOperator(trigger.matchOperator, trigger.matchValue, currentValue);
    if (_question && _question.triggers && _question.triggers.length > 0) {
      // eslint-disable-next-line no-bitwise
      _result &= isQuestionTriggered(_question, flatLayout, surveyData);
    }
    // eslint-disable-next-line no-bitwise
    result |= _result;
  });
  return !!result;
};

// Checks default answers and sets any defaults or previous answers in state
const initQuestionAnswers = (previousAnswers, defaultAnswers, options, type) => {
  if (
    !previousAnswers.length && // no prev. answers
    defaultAnswers !== undefined && // default answers are sent present
    defaultAnswers.length // default answers is an array and not empty
  ) {
    return defaultAnswers;
  }
  if (previousAnswers.length === 0 && type !== "MultiToggle") {
    return options
      .filter((option) => parseLabel(option, "style").includes("option-default"))
      .map((option) => parseLabel(option, "label"));
  }
  if (previousAnswers.length === 0 && type === "MultiToggle") {
    return ["No"];
  }
  return previousAnswers;
};

export const getQuestionOptions = (q, surveyData) =>
  q.cumulativeOptions
    ? q.cumulativeOptions === "survey"
      ? q.originalOptions.filter((o) =>
          Object.values(surveyData)
            .filter(
              (a) =>
                a.questionKey === q.key &&
                (a.sectionKey !== q.sectionKey ||
                  a.sectionCount !== q.sectionCount ||
                  a.groupKey !== q.groupKey ||
                  a.groupCount !== q.groupCount ||
                  a.questionCount !== q.questionCount)
            )
            .map((_a) => _a.answers)
            .every((qa) => !qa.includes(o))
        )
      : q.cumulativeOptions === "section"
      ? q.originalOptions.filter((o) =>
          Object.values(surveyData)
            .filter(
              (a) =>
                a.questionKey === q.key &&
                a.sectionKey === q.sectionKey &&
                a.sectionCount === q.sectionCount &&
                (a.groupKey !== q.groupKey || a.groupCount !== q.groupCount || a.questionCount !== q.questionCount)
            )
            .map((_a) => _a.answers)
            .every((qa) => !qa.includes(o))
        )
      : //  "group"
        q.originalOptions.filter((o) =>
          Object.values(surveyData)
            .filter(
              (a) =>
                a.questionKey === q.key &&
                a.sectionKey === q.sectionKey &&
                a.sectionCount === q.sectionCount &&
                a.groupKey === q.groupKey &&
                a.groupCount === q.groupCount &&
                a.questionCount !== q.questionCount
            )
            .map((_a) => _a.answers)
            .every((qa) => !qa.includes(o))
        )
    : [...q.originalOptions];

export const initQuestionData = (question) => {
  const {
    key: _key,
    questionCount,
    sectionKey,
    sectionCount,
    groupKey,
    groupCount,
    version: questionVersion,
    previousAnswers,
    defaultAnswers,
    elucidations,
    options,
    type
    // isRequired
  } = question;
  return {
    questionKey: _key,
    questionCount,
    sectionKey,
    sectionCount,
    questionVersion,
    groupKey,
    groupCount,
    // isRequired, // maintained in the layout not the data
    // options, // maintained in the layout not the data
    answers: initQuestionAnswers(previousAnswers, defaultAnswers, options, type),
    elucidations: elucidations.map(({ key, caption, value }) => ({
      key,
      caption,
      value
    }))
  };
};

// Sets intial state to format required by post method
export const initSurveyData = (questions) => {
  const data = {};
  questions.forEach((question) => {
    data[getQuestionStringId(question)] = initQuestionData(question);
  });
  return data;
};

const duplicateSection = (surveyLayout, surveyData, { sectionKey }) => {
  const section = surveyLayout.sections
    .filter((_s) => _s.key === sectionKey)
    .sort((a, b) => (a.sortOrder > b.sortOrder ? -1 : a.sortOrder < b.sortOrder ? 1 : 0))[0];
  if (!section) return [surveyLayout, surveyData];
  const newSection = {
    ...section,
    sectionCount: +section.sectionCount + 1,
    sortOrder: +section.sortOrder + 1,
    groups: section.groups
      .filter((_g) => _g.groupCount === 1)
      .map((_g) => ({
        ..._g,
        sectionCount: +section.sectionCount + 1,
        questions: _g.questions
          .filter((_q) => _q.questionCount === 1)
          .map((_q) => ({
            ..._q,
            sectionCount: +section.sectionCount + 1,
            // groupCount: not changing .. it is a new section
            // questionCount: not changing .. it is a new section
            previousAnswers: [],
            answers: [],
            previousAnswersVisitId: null,
            previousAnswersTreatmentPlanId: null
          }))
      }))
  };

  const _surveyLayout = {
    ...surveyLayout,
    sections: [...surveyLayout.sections, newSection].map((_sec) => ({
      ..._sec,
      sortOrder:
        _sec.sortOrder >= newSection.sortOrder && _sec.key !== newSection.key ? _sec.sortOrder + 1 : _sec.sortOrder
    }))
  };

  const _surveyData = { ...surveyData };

  newSection.groups.forEach((group) =>
    group.questions.forEach((_q) => {
      if (!_surveyData[getQuestionStringId(_q)]) {
        _surveyData[getQuestionStringId(_q)] = initQuestionData(_q);
      }
    })
  );

  return [_surveyLayout, _surveyData, newSection];
};

const deleteSection = (surveyLayout, surveyData, { sectionKey, sectionCount }) => {
  if (sectionCount === 1) throw new Error("You can't delete first section");

  const section = surveyLayout.sections.find((_sec) => _sec.key === sectionKey && _sec.sectionCount === sectionCount);

  const _surveyLayout = {
    ...surveyLayout,
    sections: surveyLayout.sections
      .filter((_sec) => _sec.key !== sectionKey || _sec.sectionCount !== sectionCount)
      .map((_sec) => ({
        ..._sec,
        sortOrder: _sec.sortOrder >= section.sortOrder ? _sec.sortOrder - 1 : _sec.sortOrder
      }))
  };

  const _surveyData = { ...surveyData };

  section.groups.forEach((_grp) =>
    _grp.questions.forEach((_q) => {
      const stringId = getQuestionStringId(_q);
      if (_surveyData[stringId]) {
        delete _surveyData[stringId];
      }
    })
  );

  return [_surveyLayout, _surveyData];
};

const duplicateGroup = (surveyLayout, surveyData, { sectionKey, sectionCount, groupKey }) => {
  const section = surveyLayout.sections.find((_sec) => _sec.key === sectionKey && _sec.sectionCount === sectionCount);
  // if (!section) return [surveyLayout, surveyData];
  const group = section.groups
    .filter((_g) => _g.key === groupKey)
    .sort((a, b) => (a.sortOrder > b.sortOrder ? -1 : a.sortOrder < b.sortOrder ? 1 : 0))[0];
  // if (!group) return [surveyLayout, surveyData];
  const newGroup = {
    ...group,
    groupCount: +group.groupCount + 1,
    sortOrder: +group.sortOrder + 1,
    questions: group.questions
      .filter((_q) => _q.questionCount === 1)
      .map((_q) => ({
        ..._q,
        // sectionCount: not changing .. same section
        groupCount: +group.groupCount + 1,
        // questionCount: not changing .. same question count
        previousAnswers: [],
        answers: [],
        previousAnswersVisitId: null,
        previousAnswersTreatmentPlanId: null
      }))
  };

  const _surveyLayout = {
    ...surveyLayout,
    sections: surveyLayout.sections.map((_sec) => {
      if (_sec.key !== section.key || _sec.sectionCount !== sectionCount) return { ..._sec };
      return {
        ..._sec,
        groups: [..._sec.groups, newGroup].map((_grp) => ({
          ..._grp,
          sortOrder:
            _grp.sortOrder >= newGroup.sortOrder && _grp.key !== newGroup.key ? _grp.sortOrder + 1 : _grp.sortOrder
        }))
      };
    })
  };

  const _surveyData = { ...surveyData };

  newGroup.questions.forEach((_q) => {
    if (!_surveyData[getQuestionStringId(_q)]) {
      _surveyData[getQuestionStringId(_q)] = initQuestionData(_q);
    }
  });

  return [_surveyLayout, _surveyData, newGroup];
};

const deleteGroup = (surveyLayout, surveyData, { sectionKey, sectionCount, groupKey, groupCount }) => {
  if (groupCount === 1) throw new Error("You can't delete first group");

  const section = surveyLayout.sections.find((_sec) => _sec.key === sectionKey && _sec.sectionCount === sectionCount);
  const group = section.groups.find((_g) => _g.key === groupKey && _g.groupCount === groupCount);

  const _surveyLayout = {
    ...surveyLayout,
    sections: surveyLayout.sections.map((_sec) => {
      if (_sec.key !== sectionKey || _sec.sectionCount !== sectionCount) return { ..._sec };
      return {
        ..._sec,
        groups: _sec.groups
          .filter((_grp) => _grp.key !== groupKey || _grp.groupCount !== groupCount)
          .map((_grp) => ({
            ..._grp,
            sortOrder: _grp.sortOrder >= group.sortOrder ? _grp.sortOrder - 1 : _grp.sortOrder
          }))
      };
    })
  };

  const _surveyData = { ...surveyData };

  group.questions.forEach((_q) => {
    const stringId = getQuestionStringId(_q);
    if (_surveyData[stringId]) {
      delete _surveyData[stringId];
    }
  });

  return [_surveyLayout, _surveyData];
};

/**
 * we will keep this simple for now, we will ignore cases like
 * what if the question is part of a group of questions that each one
 * is conditionally triggered based on some question's answer, and
 * what if the question itself is triggering other chain of questions
 * I tried to write some smart code .. but after spending huge amount
 * of time .. it did not work .. so I decided to postpone it .. and
 * just do the straight forward case
 * */
const duplicateQuestion = (
  surveyLayout,
  surveyData,
  { sectionKey, sectionCount, groupKey, groupCount, questionKey }
) => {
  const section = surveyLayout.sections.find((_sec) => _sec.key === sectionKey && _sec.sectionCount === sectionCount);
  if (!section) return [surveyLayout, surveyData];
  const group = section.groups.find((_grp) => _grp.key === groupKey && _grp.groupCount === groupCount);
  if (!group) return [surveyLayout, surveyData];
  const question = group.questions
    .filter((_q) => _q.key === questionKey)
    .sort((a, b) => (a.sortOrder > b.sortOrder ? -1 : a.sortOrder < b.sortOrder ? 1 : 0))[0];
  if (!question) return [surveyLayout, surveyData];
  const newQuestion = {
    ...question,
    // sectionCount: not changing .. same section
    // groupCount: not changing .. same group
    questionCount: +question.questionCount + 1,
    sortOrder: +question.sortOrder + 1,
    previousAnswers: [],
    answers: [],
    previousAnswersVisitId: null,
    previousAnswersTreatmentPlanId: null
  };

  const _surveyLayout = {
    ...surveyLayout,
    sections: surveyLayout.sections.map((_sec) => {
      if (_sec.key !== section.key || _sec.sectionCount !== sectionCount) return { ..._sec };
      return {
        ..._sec,
        groups: _sec.groups.map((_grp) => {
          if (_grp.key !== group.key || _grp.groupCount !== groupCount) return { ..._grp };
          return {
            ..._grp,
            questions: [..._grp.questions, newQuestion].map((_q) => ({
              ..._q,
              sortOrder:
                _q.sortOrder >= newQuestion.sortOrder && _q.key !== newQuestion.key ? _q.sortOrder + 1 : _q.sortOrder
            }))
          };
        })
      };
    })
  };

  const _surveyData = { ...surveyData };

  if (!_surveyData[getQuestionStringId(newQuestion)]) {
    _surveyData[getQuestionStringId(newQuestion)] = initQuestionData(newQuestion);
  }

  return [_surveyLayout, _surveyData, newQuestion];
};

const deleteQuestion = (
  surveyLayout,
  surveyData,
  { sectionKey, sectionCount, groupKey, groupCount, questionKey, questionCount }
) => {
  if (questionCount === 1) throw new Error("You can't delete first question");

  const section = surveyLayout.sections.find((_sec) => _sec.key === sectionKey && _sec.sectionCount === sectionCount);
  const group = section.groups.find((_g) => _g.key === groupKey && _g.groupCount === groupCount);
  const question = group.questions.find((_q) => _q.key === questionKey && _q.questionCount === questionCount);

  const _surveyLayout = {
    ...surveyLayout,
    sections: surveyLayout.sections.map((_sec) => {
      if (_sec.key !== sectionKey || _sec.sectionCount !== sectionCount) return { ..._sec };
      return {
        ..._sec,
        groups: _sec.groups.map((_grp) => {
          if (_grp.key !== groupKey || _grp.groupCount !== groupCount) return { ..._grp };
          return {
            ..._grp,
            questions: _grp.questions
              .filter((_q) => _q.key !== questionKey || _q.questionCount !== questionCount)
              .map((_q) => ({
                ..._q,
                sortOrder: _q.sortOrder >= question.sortOrder ? _q.sortOrder - 1 : _q.sortOrder
              }))
          };
        })
      };
    })
  };

  const _surveyData = { ...surveyData };

  const stringId = getQuestionStringId(question);
  if (_surveyData[stringId]) {
    delete _surveyData[stringId];
  }

  return [_surveyLayout, _surveyData];
};

const refreshLastDynamicFlags = (survey) => {
  const _survey = { ...survey };

  const _sections = [];
  let key;
  _survey.sections
    .sort((a, b) => (a.sortOrder > b.sortOrder ? 1 : a.sortOrder < b.sortOrder ? -1 : 0))
    .forEach((_sec, i) => {
      const sectionKey = _sec.key;
      if (i > 0 && _sec.isDynamic && sectionKey === key) _sections[i - 1].isLastDynamicSection = false;
      _sections.push({ ..._sec, isLastDynamicSection: true });
      key = sectionKey;
    });

  const keyToMaxCountTable = {};

  return {
    ..._survey,
    sections: _sections.map((section) => {
      const _groups = [];
      let _key;
      section.groups
        .sort((a, b) => (a.sortOrder > b.sortOrder ? 1 : a.sortOrder < b.sortOrder ? -1 : 0))
        .forEach((_grp, i) => {
          const groupKey = _grp.key;
          if (i > 0 && _grp.isDynamic && groupKey === _key) _groups[i - 1].isLastDynamicGroup = false;
          _groups.push({ ..._grp, isLastDynamicGroup: true });
          _key = groupKey;
        });

      return {
        ...section,
        groups: _groups.map((grp) => ({
          ...grp,
          questions: grp.questions.map((question) => {
            let isLastDynamicQuestion = false;
            const stringId = getQuestionStringId(question);
            if (question.isDynamic && !keyToMaxCountTable[stringId]) {
              const questionWithSameKeyAndMaxCount = grp.questions
                .filter((_q) => _q.key === question.key)
                .reduce((prev, current) => (prev.questionCount > current.questionCount ? prev : current), 0);
              keyToMaxCountTable[stringId] = questionWithSameKeyAndMaxCount;
            }
            if (question.isDynamic && checkIsItem(keyToMaxCountTable[stringId], getQuestionId(question))) {
              isLastDynamicQuestion = true;
            }
            return { ...question, isLastDynamicQuestion };
          })
        }))
      };
    })
  };
};

export const refreshQuestionOptions = (surveyLayout, surveyData) => {
  const _surveyLayout = {
    ...surveyLayout,
    sections: surveyLayout.sections.map((_sec) => ({
      ..._sec,
      groups: _sec.groups.map((_grp) => ({
        ..._grp,
        questions: _grp.questions.map((_q) => ({ ..._q, options: getQuestionOptions(_q, surveyData) }))
      }))
    }))
  };
  return _surveyLayout;
};

export const refreshRequiredState = (surveyLayout, surveyData) => {
  const flatLayout = [].concat(...surveyLayout.sections.map((_s) => [].concat(..._s.groups.map((_g) => _g.questions))));
  let _surveyLayout = {
    ...surveyLayout,
    sections: surveyLayout.sections.map((_sec) => ({
      ..._sec,
      groups: _sec.groups.map((_grp) => ({
        ..._grp,
        questions: _grp.questions.map((_q) => ({
          ..._q,
          isRequired: _q.requiredGroup
            ? surveyData[getQuestionStringId(_q)].answers.length > 0 ||
              flatLayout
                .filter((__q) => __q.requiredGroup === _q.requiredGroup && __q.key !== _q.key)
                .every((___q) => surveyData[getQuestionStringId(___q)].answers.length === 0)
            : _q.isRequired === true
        }))
      }))
    }))
  };
  _surveyLayout = {
    ...surveyLayout,
    sections: _surveyLayout.sections.map((_sec) => ({
      ..._sec,
      hasRequiredFields: _sec.groups.some((_grp) =>
        _grp.questions.some((_q) => _q.isRequired && isQuestionTriggered(_q, flatLayout, surveyData))
      ),
      hasMissingRequiredFields:
        []
          .concat(..._sec.groups.map((_grp) => _grp.questions))
          .filter(
            (_q) =>
              _q.isRequired &&
              isQuestionTriggered(_q, flatLayout, surveyData) &&
              surveyData[getQuestionStringId(_q)].answers.length === 0
          ).length > 0
    }))
  };

  return _surveyLayout;
};

export const applyAction = (surveyLayout, surveyData, action, refresh = true) => {
  let _surveyLayout = { ...surveyLayout };
  let _surveyData = { ...surveyData };
  let _newItem = null;
  switch (action.type) {
    case "DUPLICATE":
      switch (action.targetType) {
        case "SECTION":
          [_surveyLayout, _surveyData, _newItem] = duplicateSection(_surveyLayout, _surveyData, action.targetKey);
          break;
        case "GROUP":
          [_surveyLayout, _surveyData, _newItem] = duplicateGroup(_surveyLayout, _surveyData, action.targetKey);
          break;
        case "QUESTION":
          [_surveyLayout, _surveyData, _newItem] = duplicateQuestion(_surveyLayout, _surveyData, action.targetKey);
          break;
        default:
          throw new Error("Unknown action target type");
      }
      break;
    case "DELETE":
      switch (action.targetType) {
        case "SECTION":
          [_surveyLayout, _surveyData] = deleteSection(_surveyLayout, _surveyData, action.targetKey);
          break;
        case "GROUP":
          [_surveyLayout, _surveyData] = deleteGroup(_surveyLayout, _surveyData, action.targetKey);
          break;
        case "QUESTION":
          [_surveyLayout, _surveyData] = deleteQuestion(_surveyLayout, _surveyData, action.targetKey);
          break;
        default:
          throw new Error("Unknown action target type");
      }
      break;
    default:
      throw new Error("Unknown action type");
  }
  if (refresh) {
    _surveyLayout = refreshLastDynamicFlags(_surveyLayout);
    _surveyLayout = refreshQuestionOptions(_surveyLayout, _surveyData);
  }
  return [_surveyLayout, _surveyData, _newItem];
};

export const contextualizeSurveys = (surveys, _for, containerId) => {
  // _for can be "templates" or "treatmentPlan"
  const idProperty = _for === "templates" ? "visitId" : "treatmentPlanId";
  const previousAnswersProperty = _for === "templates" ? "previousAnswersVisitId" : "previousAnswersTreatmentPlanId";
  return surveys.map((survey) => ({
    ...survey,
    hasAnswersFromContextContainer: survey.questions.some((q) => q[previousAnswersProperty] === containerId), // container can be a visit or a treatment plan
    templateActions: {
      ...survey.templateActions,
      templateActions:
        survey.isCumulative || survey.templateActions[idProperty] === containerId
          ? survey.templateActions.templateActions
          : []
    },
    questions: survey.questions.map((question) => {
      if (survey.isCumulative) return question;
      return {
        ...question,
        previousAnswers: question[previousAnswersProperty] === containerId ? question.previousAnswers : [],
        suggestedAnswers:
          question[previousAnswersProperty] === containerId
            ? []
            : question.suggestPreviousAnswers
            ? question.previousAnswers
            : [],
        elucidations:
          question[previousAnswersProperty] === containerId
            ? question.elucidations
            : question.elucidations.map((e) => ({ ...e, value: null }))
      };
    })
  }));
};

export const validateSurveyAnswersDataIntegrity = (
  contextVisitId,
  availableTemplates,
  selectedTemplate,
  templateId,
  surveyId,
  surveyAnswers
) => {
  if (!availableTemplates.map((t) => t.templateId).includes(selectedTemplate.templateId)) {
    throw Error("Selected template is not one of the available templates for this visit type");
  }
  if (selectedTemplate.templateId !== templateId) {
    throw Error("Template ");
  }
  if (selectedTemplate.version !== surveyAnswers.templateVersion) {
    throw Error("Template version is not matching the selected template version");
  }
  const survey = selectedTemplate.surveys.find((s) => s.id === surveyId);
  if (survey.version !== surveyAnswers.surveyVersion) {
    throw Error("Survey version is not correct");
  }
  if (surveyAnswers.answers.some((a) => a.visitId !== contextVisitId)) {
    throw Error("Some answers linked to a wrong visit");
  }
};

export const initSurvey = (_survey, _actions) => {
  let surveyLayout = { ...cloneDeep(_survey), sections: cloneDeep(_survey.sections) };

  const questions = {};
  _survey.questions.forEach((_q) => {
    if (questions[_q.key]) return;
    questions[_q.key] = cloneDeep(_q);
  });
  const _distinctQuestions = Object.values(questions);

  delete surveyLayout.questions;
  delete surveyLayout.groups;

  surveyLayout = {
    ...surveyLayout,
    sections: [
      ...surveyLayout.sections.map((section, i) => {
        const sectionQuestions = _distinctQuestions
          .filter((q) => q.sectionKey === section.key)
          .sort((a, b) => (a.sortOrder > b.sortOrder ? 1 : a.sortOrder < b.sortOrder ? -1 : 0))
          .map((_q) => ({ ..._q, originalOptions: [..._q.options] }));
        const groups = [];
        let key;
        let currentKey;
        let order = 1;
        let count = 1;
        sectionQuestions.forEach((question) => {
          let group = groups.find((g) => g.key === question.groupKey);
          if (group) {
            group.questions.push({
              ...question,
              sectionCount: 1,
              groupKey: currentKey,
              groupCount: 1,
              questionCount: 1
            });
          } else if (question.groupKey !== key) {
            key = question.groupKey;
            currentKey = question.groupKey;
            group = cloneDeep(_survey.groups).find((g) => g.key === question.groupKey);
            if (!group) {
              currentKey = `grp_${count++}`;
              group = {
                key: currentKey,
                header: "",
                isDynamic: false,
                style: "none"
              };
            }
            group.sectionKey = section.key;
            group.sectionCount = 1;
            group.groupCount = 1;
            group.isLastDynamicGroup = false;
            group.sortOrder = order++;
            group.questions = [{ ...question, sectionCount: 1, groupKey: currentKey, groupCount: 1, questionCount: 1 }];
            groups.push(group);
          } else {
            groups[groups.length - 1].questions.push({
              ...question,
              sectionCount: 1,
              groupKey: currentKey,
              groupCount: 1,
              questionCount: 1
            });
          }
        });
        return {
          ...section,
          sectionCount: 1,
          sortOrder: i + 1,
          isLastDynamicSection: section.isDynamic,
          groups
        };
      })
    ]
  };

  const flatLayout = [].concat(...surveyLayout.sections.map((_s) => [].concat(..._s.groups.map((_g) => _g.questions))));
  // fill missing group keys
  const _questions = _survey.questions.map((_q) => {
    if (!isEmpty(_q.groupKey)) return cloneDeep(_q);
    // search for equivalent question in same section but different group key
    let _qs = flatLayout.filter(
      (__q) =>
        __q.key === _q.key &&
        __q.sectionKey === _q.sectionKey &&
        __q.sectionCount === _q.sectionCount &&
        __q.groupCount === _q.groupCount
    );
    // if not found search in other sections
    if (isEmpty(_qs)) {
      _qs = flatLayout.filter(
        (__q) => __q.key === _q.key && __q.sectionKey === _q.sectionKey && __q.groupCount === _q.groupCount
      );
    }
    if (_qs.length === 1) return { ...cloneDeep(_q), groupKey: _qs[0].groupKey };
    throw new Error("Missing group key!");
  });

  // add questions in layout but don't have answers .. this happens when question is answered in section 2 and not answered in section 1
  surveyLayout.sections.forEach((_sec) =>
    _sec.groups.forEach((_grp) => {
      _grp.questions.forEach((_q) => {
        if (_questions.find((__q) => getQuestionStringId(__q) === getQuestionStringId(_q))) return;
        _questions.push({ ..._q });
      });
    })
  );

  let surveyData = initSurveyData(_questions);

  const orderedActions = _actions.sort((a, b) => (a.order > b.order ? 1 : a.order < b.order ? -1 : 0));
  orderedActions.forEach((action) => {
    [surveyLayout, surveyData] = applyAction(surveyLayout, surveyData, action, false);
  });

  // re-hydrate surveyData from original answers .. because applyAction generates empty answers for duplicate questions
  _questions.forEach((_q) => {
    const _stringId = getQuestionStringId(_q);
    if (surveyData[_stringId].answers.length !== _q.previousAnswers.length) {
      surveyData[_stringId] = initQuestionData(_q);
    }
  });

  surveyLayout = refreshLastDynamicFlags(surveyLayout);
  surveyLayout = refreshQuestionOptions(surveyLayout, surveyData);
  surveyLayout = refreshRequiredState(surveyLayout, surveyData);

  return [surveyLayout, surveyData];
};
