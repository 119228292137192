import React from "react";
import { Form as BsForm } from "react-bootstrap";
import Select from "./Select";

const Form = (props) => <BsForm {...props} />;

Form.Check = BsForm.Check;
Form.Group = BsForm.Group;
Form.Control = BsForm.Control;
Form.Floating = BsForm.Floating;
Form.Switch = BsForm.Switch;
Form.Label = BsForm.Label;
Form.Text = BsForm.Text;
Form.Range = BsForm.Range;
Form.Select = Select;
Form.FloatingLabel = BsForm.FloatingLabel;

export default Form;
