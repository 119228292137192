/* eslint-disable max-len */
import React from "react";

export const Example = () => <div />;

export const HorizontalBars = () => (
  <svg fill="#FFFFFF" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 0h24v24H0z" fill="none" />
    <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z" />
  </svg>
);

export const MyPatients = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <title>My Patients</title>
    <path
      d="M4.45,15.55c0,.22.08.42.12.62a1.72,1.72,0,1,0-1.16.09q-.07-.32-.13-.66a1.15,1.15,0,1,1,1.16-.06Z"
      style={{ fill: "#fff" }}
    />
    <path
      d="M23.61,1.74A1.93,1.93,0,0,0,22.11,1a.57.57,0,0,0-.28.08.74.74,0,0,0-.55-.24H19.92a.75.75,0,0,0,0,1.5h1.36a.74.74,0,0,0,.55-.24.57.57,0,0,0,.28.08.8.8,0,0,1,.57.28,1.89,1.89,0,0,1,0,1.53c-.75,2.53-2.94,8.58-3,8.65v0c-.16.5-.66,1.41-1.1,1.39s-.6,0-.92,0h-.33a.57.57,0,0,0-.3-.09H16.8c-.32,0-.62,0-.92,0s-.94-.89-1.1-1.39v0c0-.06-2.21-6.11-3-8.65a1.89,1.89,0,0,1,0-1.53.8.8,0,0,1,.57-.28A.57.57,0,0,0,12.67,2a.74.74,0,0,0,.55.24h1.36a.75.75,0,0,0,0-1.5H13.22a.74.74,0,0,0-.55.24.57.57,0,0,0-.28-.08,1.93,1.93,0,0,0-1.5.74,2.84,2.84,0,0,0-.19,2.57c.75,2.54,2.85,8.35,3,8.7s.77,2.19,2.18,2.19h.8c0,1.11-.16,3.9-1.66,5.51a3.89,3.89,0,0,1-3,1.22c-2.87,0-4.78-.12-6.07-2a9.1,9.1,0,0,1-1.49-.3C6,22.79,8.48,23,12,23a5,5,0,0,0,3.86-1.59c1.75-1.89,2-4.91,2-6.22h.82c1.4,0,2.09-1.92,2.18-2.19s2.23-6.16,3-8.7A2.84,2.84,0,0,0,23.61,1.74Z"
      style={{ fill: "#fff" }}
    />
    <path
      d="M9.47,10.09a4,4,0,0,1-2.95,1.32,4,4,0,0,1-2.7-1.07C1.56,11.16,0,12.84,0,14.78c0,2.09,1.82,3.88,4.39,4.63a13.73,13.73,0,0,1-.86-2.61,2.22,2.22,0,1,1,1.15-.13,9.84,9.84,0,0,0,1.17,3,9.93,9.93,0,0,0,1.2.07c3.89,0,7-2.24,7-5C14.09,12.62,12.17,10.79,9.47,10.09Z"
      style={{ fill: "#fff" }}
    />
    <ellipse cx="6.49" cy="6.35" rx="4.15" ry="4.32" style={{ fill: "#fff" }} />
  </svg>
);

export const VisitingPatients = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23.87 23.87">
    <title>Visiting Patients</title>
    <path
      d="M9.09,7.86A3.61,3.61,0,0,1,6.14,9.48,3.61,3.61,0,0,1,3.19,7.86C1.31,8.61,0,10.06,0,11.73c0,2.44,2.73,4.43,6.11,4.43s6.11-2,6.11-4.43C12.25,10.06,11,8.61,9.09,7.86Z"
      style={{ fill: "#fff" }}
    />
    <path
      d="M20.47,13.55a3.73,3.73,0,0,1-6.31,0c-2,.81-3.38,2.36-3.38,4.15,0,2.62,2.93,4.73,6.53,4.73s6.53-2.12,6.53-4.73C23.85,15.91,22.49,14.36,20.47,13.55Z"
      style={{ fill: "#fff" }}
    />
    <ellipse cx="6.14" cy="5.06" rx="3.6" ry="3.72" style={{ fill: "#fff" }} />
    <ellipse cx="17.17" cy="10.59" rx="3.85" ry="3.98" style={{ fill: "#fff" }} />
  </svg>
);

export const GroupPatients = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23.75 23.75">
    <title>Group Patients</title>
    <path
      d="M15,14.39A3.55,3.55,0,0,1,12.11,16a3.55,3.55,0,0,1-2.9-1.59c-1.85.74-3.1,2.17-3.1,3.81,0,2.4,2.69,4.35,6,4.35s6-1.95,6-4.35C18.11,16.56,16.86,15.13,15,14.39Z"
      style={{ fill: "#fff" }}
    />
    <ellipse cx="6.59" cy="4.33" rx="3.14" ry="3.25" style={{ fill: "#fff" }} />
    <ellipse cx="16.51" cy="4.33" rx="3.14" ry="3.25" style={{ fill: "#fff" }} />
    <path
      d="M8.21,11.58a4.1,4.1,0,0,1,2.54-3.82,5.26,5.26,0,0,0-1.62-1A3.15,3.15,0,0,1,6.55,8.17,3.15,3.15,0,0,1,4,6.75a3.89,3.89,0,0,0-2.76,3.38C1.22,12.27,3.61,14,6.55,14a7,7,0,0,0,2.21-.35A4.18,4.18,0,0,1,8.21,11.58Z"
      style={{ fill: "#fff" }}
    />
    <path
      d="M19,6.7a3,3,0,0,1-5.15,0,5.42,5.42,0,0,0-1.37.79,4,4,0,0,1,3.69,4.09,4.17,4.17,0,0,1-.69,2.31,7.33,7.33,0,0,0,.95.06c2.94,0,5.33-1.73,5.33-3.86A3.89,3.89,0,0,0,19,6.7Z"
      style={{ fill: "#fff" }}
    />
    <ellipse cx="12.19" cy="11.6" rx="3.54" ry="3.66" style={{ fill: "#fff" }} />
    <path
      d="M5.58,19.38C3.21,18,1.68,15.93,1.68,13.57c0-.14,0-.27,0-.41A4.27,4.27,0,0,1,.47,11.67,6.43,6.43,0,0,0,0,14.08c0,3.64,3.12,6.75,7.54,8A4.59,4.59,0,0,1,5.58,19.38Z"
      style={{ fill: "#fff" }}
    />
    <path
      d="M22.83,10.76A3.59,3.59,0,0,1,22,12.5a5.44,5.44,0,0,1,.11,1.06A6.66,6.66,0,0,1,18.79,19a4.26,4.26,0,0,1-1.6,2.77c3.89-1.41,6.56-4.33,6.56-7.7A6.6,6.6,0,0,0,22.83,10.76Z"
      style={{ fill: "#fff" }}
    />
    <path
      d="M13.13,5.52c-.41,0-.83,0-1.26,0a16.35,16.35,0,0,0-2,.13,3.87,3.87,0,0,1-.34.78,13.91,13.91,0,0,1,2.39-.21,14.06,14.06,0,0,1,1.62.09A4,4,0,0,1,13.13,5.52Z"
      style={{ fill: "#fff" }}
    />
  </svg>
);

export const Reports = () => (
  <svg fill="#FFFFFF" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
    <title>Reports</title>
    <path d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zM9 17H7v-7h2v7zm4 0h-2V7h2v10zm4 0h-2v-4h2v4z" />
    <path d="M0 0h24v24H0z" fill="none" />
  </svg>
);

export const Admin = () => (
  <svg fill="#FFFFFF" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
    <title>Admin</title>
    <path d="M0 0h24v24H0z" fill="none" />
    <path d="M19.43 12.98c.04-.32.07-.64.07-.98s-.03-.66-.07-.98l2.11-1.65c.19-.15.24-.42.12-.64l-2-3.46c-.12-.22-.39-.3-.61-.22l-2.49 1c-.52-.4-1.08-.73-1.69-.98l-.38-2.65C14.46 2.18 14.25 2 14 2h-4c-.25 0-.46.18-.49.42l-.38 2.65c-.61.25-1.17.59-1.69.98l-2.49-1c-.23-.09-.49 0-.61.22l-2 3.46c-.13.22-.07.49.12.64l2.11 1.65c-.04.32-.07.65-.07.98s.03.66.07.98l-2.11 1.65c-.19.15-.24.42-.12.64l2 3.46c.12.22.39.3.61.22l2.49-1c.52.4 1.08.73 1.69.98l.38 2.65c.03.24.24.42.49.42h4c.25 0 .46-.18.49-.42l.38-2.65c.61-.25 1.17-.59 1.69-.98l2.49 1c.23.09.49 0 .61-.22l2-3.46c.12-.22.07-.49-.12-.64l-2.11-1.65zM12 15.5c-1.93 0-3.5-1.57-3.5-3.5s1.57-3.5 3.5-3.5 3.5 1.57 3.5 3.5-1.57 3.5-3.5 3.5z" />
  </svg>
);

export const Billing = () => (
  <svg fill="#FFFFFF" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
    <title>Billing</title>
    <path d="M0 0h24v24H0z" fill="none" />
    <path d="M20 4H4c-1.11 0-1.99.89-1.99 2L2 18c0 1.11.89 2 2 2h16c1.11 0 2-.89 2-2V6c0-1.11-.89-2-2-2zm0 14H4v-6h16v6zm0-10H4V6h16v2z" />
  </svg>
);

export const Tasks = () => (
  <svg fill="#FFFFFF" xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
    <title>Tasks</title>
    <path
      className="st0"
      d="M 21.6 2.2 h -6 c 0 -0.1 0 -0.1 0 -0.2 c 0 -0.8 -0.7 -1.5 -1.5 -1.5 h -3.3 C 10 0.5 9.4 1.2 9.4 2 c 0 0.1 0 0.1 0 0.2 h -6 c -1.2 0 -2.2 1 -2.2 2.2 v 18.3 c 0 1.2 1 2.2 2.2 2.2 h 18.3 c 1.2 0 2.2 -1 2.2 -2.2 V 4.4 C 23.9 3.2 22.9 2.2 21.6 2.2 Z M 22.2 23.3 H 2.8 V 3.9 h 2.9 v 2.4 h 13.7 V 3.9 h 2.9 V 23.3 Z"
    />
    <path className="st0" d="M 9.9 14 H 4 V 8.1 h 5.9 V 14 Z M 4.7 13.3 h 4.4 V 8.8 H 4.7 V 13.3 Z" />
    <path className="st0" d="M 9.9 21.1 H 4 v -5.9 h 5.9 V 21.1 Z M 4.7 20.4 h 4.4 v -4.4 H 4.7 V 20.4 Z" />
    <rect className="st0" x="12.3" y="9.2" width="7.2" height="3" />
    <rect className="st0" x="12.3" y="16.7" width="7.2" height="3" />
    <polygon className="st0" points="5.8,10.7 6.9,12 9,9.2 9,10.5 6.9,13.3 4.7,10.7" />
  </svg>
);

export const RequestedLabs = () => (
  <svg fill="#FFFFFF" xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
    <title>Lab Submission Queue</title>
    <path
      d="M21.23,24.29a3,3,0,0,1-2.76-4.34,18.88,18.88,0,0,1,2.07-3.46.82.82,0,0,1,1.37,0,16.91,16.91,0,0,1,2.18,3.7A3,3,0,0,1,21.23,24.29Z"
      transform="translate(-0.19 -0.19)"
    />
    <path
      d="M23.93,8q-3.49-3.46-7-7A2.69,2.69,0,0,0,15.53.19h-.38A2.49,2.49,0,0,0,13.79,1c-.6.61-1.25,1.18-1.8,1.84a1.73,1.73,0,0,0,.06,2.21c.27.3.56.57.84.86s.32.32,0,.63l-10,10L1.41,18.08A4,4,0,0,0,.24,20.35a1.72,1.72,0,0,1,0,.18v.77c0,.17.07.34.12.51a3.83,3.83,0,0,0,3.19,2.95l.21.05h.77l.37-.08a4,4,0,0,0,2.08-1.14L18.38,12.13c.35-.35.35-.35.71,0l.66.66a1.74,1.74,0,0,0,2.55,0c.68-.64,1.33-1.32,2-2a2.19,2.19,0,0,0,.52-1V9.47A2.68,2.68,0,0,0,23.93,8ZM8.22,20.74a.77.77,0,0,1-1.09,0L5.59,19.2a.77.77,0,1,1,1.09-1.09l1.54,1.54A.77.77,0,0,1,8.22,20.74Zm2.39-2.39a.77.77,0,0,1-1.09,0L8,16.81a.77.77,0,1,1,1.09-1.09l1.54,1.54A.77.77,0,0,1,10.61,18.35ZM13,15.91a.77.77,0,0,1-1.09,0l-1.54-1.54a.77.77,0,1,1,1.09-1.09L13,14.83A.77.77,0,0,1,13,15.91Zm10-6.1q-.89.87-1.77,1.77c-.15.15-.27.16-.42,0q-.71-.72-1.43-1.43a.83.83,0,0,0-1.1-.21,1.47,1.47,0,0,0-.26.22c-.43.42-.86.84-1.27,1.28a.76.76,0,0,1-.62.25H10.48c-.08,0-.2,0-.24,0s.08-.15.13-.2l4.49-4.49a.84.84,0,0,0,.26-1,1.37,1.37,0,0,0-.33-.44L13.44,4.19c-.2-.2-.2-.25,0-.45L15.19,2c.15-.15.25-.14.4,0L23,9.41C23.15,9.56,23.17,9.66,23,9.81Z"
      transform="translate(-0.19 -0.19)"
    />
  </svg>
);

export const urgentTask = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23.21 24.91" fill="#ed1c24">
    <title>Urgent Task</title>
    <path
      transform="translate(-1 0.06)"
      d="M 22 1.64 H 15.8 a 1.5 1.5 0 0 0 0 -0.19 A 1.51 1.51 0 0 0 14.31 -0.06 h -3.4 A 1.51 1.51 0 0 0 9.4 1.45 a 1.5 1.5 0 0 0 0 0.19 H 3.27 A 2.27 2.27 0 0 0 1 3.91 V 22.59 a 2.27 2.27 0 0 0 2.26 2.26 H 22 a 2.27 2.27 0 0 0 2.26 -2.26 V 3.91 A 2.27 2.27 0 0 0 22 1.64 Z m 0.57 21.52 H 2.7 V 3.34 H 5.62 V 5.79 h 14 V 3.34 h 2.93 Z"
    />
    <path transform="translate(-1 0.06)" d="M 13.91 16.43 H 11.48 V 8.49 h 2.43 Z m 0 4.17 H 11.48 V 18.47 h 2.43 Z" />
  </svg>
);

export const CoSign = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24.67 23.27" fill="#FFFFFF">
    <title>Co-Sign</title>
    <path
      d="M1.92,8.05V7.52a.93.93,0,0,1,.26-.7L3.83,5.37,2.18,3.62a.93.93,0,0,1-.26-.7V2.35c0-.11,0-.17.14-.17h.59a.93.93,0,0,1,.7.26L4.41,3.53A.75.75,0,0,0,5,3.77a.78.78,0,0,0,.56-.25L6.56,2.45a.93.93,0,0,1,.7-.26h.56c.09,0,.14.06.14.17v.53a.93.93,0,0,1-.26.7L6.05,5.19l1.64,1.6a.93.93,0,0,1,.26.7v.57c0,.11,0,.17-.14.17H7.22A.93.93,0,0,1,6.52,8L5.47,6.88a.75.75,0,0,0-.54-.25.78.78,0,0,0-.56.25L3.32,8a.93.93,0,0,1-.7.26H2.06C2,8.22,1.92,8.16,1.92,8.05Z"
      transform="translate(-0.12 -0.53)"
    />
    <path
      d="M23.26,10.94H1a.92.92,0,0,0-.92.92v7.7a.92.92,0,0,0,.92.92H23.26a.92.92,0,0,0,.92-.92v-7.7A.92.92,0,0,0,23.26,10.94ZM8,13.39a.93.93,0,0,1-.26.7L6.05,15.7l1.64,1.6A.93.93,0,0,1,8,18v.57c0,.11,0,.17-.14.17H7.22a.93.93,0,0,1-.7-.26L5.47,17.39a.75.75,0,0,0-.54-.25.78.78,0,0,0-.56.25L3.32,18.47a.93.93,0,0,1-.7.26H2.06c-.09,0-.14-.06-.14-.17V18a.93.93,0,0,1,.26-.7l1.64-1.45L2.18,14.13a.93.93,0,0,1-.26-.7v-.57c0-.11,0-.17.14-.17h.59a.93.93,0,0,1,.7.26L4.41,14a.75.75,0,0,0,.54.25A.78.78,0,0,0,5.51,14L6.56,13a.93.93,0,0,1,.7-.26h.56c.09,0,.14.06.14.17Z"
      transform="translate(-0.12 -0.53)"
    />
    <path
      d="M24.58,5.87a.7.7,0,0,0-.39-.16V1.45a.92.92,0,0,0-.92-.92H1a.92.92,0,0,0-.92.92v7.7a.92.92,0,0,0,.92.92H23.26a.92.92,0,0,0,.92-.92V6.95a.63.63,0,0,0,.61-.59A.68.68,0,0,0,24.58,5.87ZM23,5.79a3.53,3.53,0,0,1-1.24,0,7.81,7.81,0,0,1-.87-.47c-.28-.17-.59-.35-1-.58a1.22,1.22,0,0,0-1.08-.09,1.21,1.21,0,0,0-.55,1,1.63,1.63,0,0,1-.1.43.37.37,0,0,1-.14.13,1.36,1.36,0,0,0-.16-.28,2.34,2.34,0,0,0-2-.76,5.24,5.24,0,0,0-2.65,1.11c-.78.49-1.38.84-1.84,1.1,2.33-2.72,3.54-4.62,3.67-5.7h8ZM1.27,1.68h10.4A20.86,20.86,0,0,0,8.3,5.43a.61.61,0,1,0,1,.7c1.08-1.53,3.15-3.93,4.48-4.3A25.89,25.89,0,0,1,10.09,7c-.86.9-1.11,1.5-.81,1.95h-8ZM11,8.91a27.4,27.4,0,0,0,2.86-1.63,4.25,4.25,0,0,1,1.94-.89c.79,0,1,.2,1,.24a.22.22,0,0,1,0,.15h0a.61.61,0,0,0,.09.56.58.58,0,0,0,.51.25,2.11,2.11,0,0,0,1.75-1,2.45,2.45,0,0,0,.21-.73c.36.18.61.34.85.48a9.08,9.08,0,0,0,1,.53A3.62,3.62,0,0,0,23,7v1.9Z"
      transform="translate(-0.12 -0.53)"
    />
    <polygon points="11.24 20.42 8.68 23.27 13.88 23.27 11.24 20.42" />
  </svg>
);

export const Labs = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24.63 24.63" fill="#FFFFFF">
    <title>Labs</title>
    <path
      d="M21.23,24.29a3,3,0,0,1-2.76-4.34,18.88,18.88,0,0,1,2.07-3.46.82.82,0,0,1,1.37,0,16.91,16.91,0,0,1,2.18,3.7A3,3,0,0,1,21.23,24.29Z"
      transform="translate(-0.19 -0.19)"
    />
    <path
      d="M23.93,8q-3.49-3.46-7-7A2.69,2.69,0,0,0,15.53.19h-.38A2.49,2.49,0,0,0,13.79,1c-.6.61-1.25,1.18-1.8,1.84a1.73,1.73,0,0,0,.06,2.21c.27.3.56.57.84.86s.32.32,0,.63l-10,10L1.41,18.08A4,4,0,0,0,.24,20.35a1.72,1.72,0,0,1,0,.18v.77c0,.17.07.34.12.51a3.83,3.83,0,0,0,3.19,2.95l.21.05h.77l.37-.08a4,4,0,0,0,2.08-1.14L18.38,12.13c.35-.35.35-.35.71,0l.66.66a1.74,1.74,0,0,0,2.55,0c.68-.64,1.33-1.32,2-2a2.19,2.19,0,0,0,.52-1V9.47A2.68,2.68,0,0,0,23.93,8ZM8.22,20.74a.77.77,0,0,1-1.09,0L5.59,19.2a.77.77,0,1,1,1.09-1.09l1.54,1.54A.77.77,0,0,1,8.22,20.74Zm2.39-2.39a.77.77,0,0,1-1.09,0L8,16.81a.77.77,0,1,1,1.09-1.09l1.54,1.54A.77.77,0,0,1,10.61,18.35ZM13,15.91a.77.77,0,0,1-1.09,0l-1.54-1.54a.77.77,0,1,1,1.09-1.09L13,14.83A.77.77,0,0,1,13,15.91Zm10-6.1q-.89.87-1.77,1.77c-.15.15-.27.16-.42,0q-.71-.72-1.43-1.43a.83.83,0,0,0-1.1-.21,1.47,1.47,0,0,0-.26.22c-.43.42-.86.84-1.27,1.28a.76.76,0,0,1-.62.25H10.48c-.08,0-.2,0-.24,0s.08-.15.13-.2l4.49-4.49a.84.84,0,0,0,.26-1,1.37,1.37,0,0,0-.33-.44L13.44,4.19c-.2-.2-.2-.25,0-.45L15.19,2c.15-.15.25-.14.4,0L23,9.41C23.15,9.56,23.17,9.66,23,9.81Z"
      transform="translate(-0.19 -0.19)"
    />
  </svg>
);

export const Prescriptions = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="-3 0 24 24.67" fill="#FFFFFF">
    <title>Prescriptions</title>
    <path
      d="M6.58,24.83a3,3,0,0,1-1.76-.75,3.12,3.12,0,0,1-1.06-2.15c-.12-1.38-.1-9.14-.14-11.64a5,5,0,0,1,.23-1.67,2.26,2.26,0,0,1,.57-.93c.39-.37.79-.74,1.21-1.09a1.06,1.06,0,0,0,.3-1.08c0-.13-.16-.1-.25-.11A1.16,1.16,0,0,1,4.54,4.23c0-.68,0-2.71,0-3a1,1,0,0,1,1.12-1C7,.26,19.27.16,19.52.18a.73.73,0,0,1,.61.38,1.56,1.56,0,0,1,.21.76c0,1,0,2,0,3a1.12,1.12,0,0,1-1.08,1c-.24,0-.28.09-.31.32a1.06,1.06,0,0,0,.51,1,7,7,0,0,1,1.12,1,3.47,3.47,0,0,1,.75,2.3c0,1.59.07,10.33,0,11.46A3.22,3.22,0,0,1,19,24.68a2.51,2.51,0,0,1-.62.09C17.46,24.78,7.32,24.89,6.58,24.83Zm-.95-3.2A1.26,1.26,0,0,0,7,22.95c1,0,8.43,0,11.21,0a1.11,1.11,0,0,0,.48-.12,1.4,1.4,0,0,0,.69-1.12c.09-.4,0-10.91,0-12a1.09,1.09,0,0,0-.45-.95c-.49-.38-.93-.81-1.38-1.23a1.62,1.62,0,0,1-.47-.81A4.76,4.76,0,0,1,17,5.53c0-.17-.05-.24-.23-.23L8,5.34c-.19,0-.27.06-.25.26s0,.48,0,.72a1.66,1.66,0,0,1-.53,1.3c-.47.44-.94.88-1.41,1.3a.89.89,0,0,0-.29.56,3.87,3.87,0,0,0,0,.6C5.53,12.81,5.58,20.57,5.64,21.63Zm2-18.06h.62c.15,0,.21-.06.2-.21q0-.49,0-1c0-.13,0-.2-.19-.2H7c-.14,0-.2.06-.19.2,0,.32,0,.65,0,1,0,.16.07.22.23.21Zm3.25-1.42h-.6c-.16,0-.23.06-.22.22,0,.32,0,.63,0,.95,0,.13,0,.2.19.2h1.25c.14,0,.19-.07.19-.2,0-.33,0-.66,0-1,0-.13-.06-.18-.18-.17Zm7.27.71h0c0-.17,0-.33,0-.5s0-.19-.17-.19l-1.27,0c-.14,0-.19.07-.18.2,0,.32,0,.63,0,.95s.07.23.24.23a9.17,9.17,0,0,1,1,0c.37,0,.37,0,.37-.36Q18.15,3,18.15,2.86Zm-4.84,0h0c0,.17,0,.33,0,.5s.05.2.19.2c.4,0,.8,0,1.2,0,.15,0,.21-.06.21-.21,0-.32,0-.63,0-.95,0-.16-.07-.22-.23-.22l-.95,0c-.42,0-.42,0-.42.43Z"
      transform="translate(-3.62 -0.18)"
    />
    <path
      d="M15.38,13.46l1.27,0c.38,0,.39,0,.38.37,0,.64,0,1.28,0,1.92,0,.25-.09.32-.32.31-.88-.05-1.77,0-2.65,0-.22,0-.28.07-.28.29,0,.91.06,1.82,0,2.72,0,.17-.05.25-.23.24-.67,0-1.33,0-2,0-.32,0-.35,0-.35-.33,0-.86,0-1.72,0-2.57,0-.23-.07-.3-.3-.3H8.27C8,16.1,8,16,8,15.78c0-.67,0-1.35,0-2,0-.17.07-.24.24-.24.9,0,1.8-.06,2.7,0,.22,0,.27-.06.27-.27,0-.89,0-1.78,0-2.67,0-.24.08-.32.31-.32.68,0,1.37,0,2,0,.21,0,.27.1.27.29,0,.87,0,1.75,0,2.62,0,.22.06.31.3.3.43,0,.87,0,1.3,0Z"
      transform="translate(-3.62 -0.18)"
    />
  </svg>
);

export const Charts = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24.23 23.98" fill="#FFFFFF">
    <title>Charts</title>
    <path
      d="M22.1,19.25l1.82,3.8-3.64-1.7a14.89,14.89,0,0,1-4.19-3.17L1.65,3.79a.81.81,0,0,1,0-1.14L3.41.88a.86.86,0,0,1,1.21,0L19.08,15.29A22.66,22.66,0,0,1,22.1,19.25Z"
      transform="translate(-0.33 -0.63)"
    />
    <path
      d="M4.42,24.61a14.7,14.7,0,0,1-1.92-.13C2,24.42.6,24.23.36,23.21c-.19-.8.44-1.71,1.87-2.69,2.62-1.8,7.6-3.58,9.76-2.1.87.6.94,1.18.85,1.56-.43,1.77-5.4,3.07-8,2L7,22.12c3.1-.39,4.69-1.64,4.87-2.38,0-.08-.11-.27-.44-.5-1.55-1.06-6,.28-8.63,2.1-1.26.87-1.51,1.45-1.47,1.64s.34.39,1.3.51a15.4,15.4,0,0,0,10.5-2.71l.93-.72-.24,1a8.87,8.87,0,0,0-.2,1.8c.08.08,0,1.08,1.44.36.6-.3,1.13-.12,1.58-.27a3.05,3.05,0,0,1,1.82-.19,2.33,2.33,0,0,1,.92.44.73.73,0,0,0,.47.2,1.53,1.53,0,0,0,.93-.31,6.16,6.16,0,0,1,1-.48c.83-.3,1.48.29,2.56.42l.21.32a5.4,5.4,0,0,0-2.43.21,5.26,5.26,0,0,0-.86.41,2.44,2.44,0,0,1-1.48.44,1.7,1.7,0,0,1-1-.38c-.16-.12-.47-.55-.74-.62-.46-.11-1.08.63-1.54.45-.75-.28-.65.43-1.51.37-.09,0-2.19-.14-2.37-1.19a13.05,13.05,0,0,1,.73-1.82C11.86,22.13,8.2,24.61,4.42,24.61Z"
      transform="translate(-0.33 -0.63)"
    />
  </svg>
);

export const QueuedPrescriptions = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="-3 0 24 24.67">
    <title>Queued Prescriptions</title>
    <path
      d="M6.58,24.83a3,3,0,0,1-1.76-.75,3.12,3.12,0,0,1-1.06-2.15c-.12-1.38-.1-9.14-.14-11.64a5,5,0,0,1,.23-1.67,2.26,2.26,0,0,1,.57-.93c.39-.37.79-.74,1.21-1.09a1.06,1.06,0,0,0,.3-1.08c0-.13-.16-.1-.25-.11A1.16,1.16,0,0,1,4.54,4.23c0-.68,0-2.71,0-3a1,1,0,0,1,1.12-1C7,.26,19.27.16,19.52.18a.73.73,0,0,1,.61.38,1.56,1.56,0,0,1,.21.76c0,1,0,2,0,3a1.12,1.12,0,0,1-1.08,1c-.24,0-.28.09-.31.32a1.06,1.06,0,0,0,.51,1,7,7,0,0,1,1.12,1,3.47,3.47,0,0,1,.75,2.3c0,1.59.07,10.33,0,11.46A3.22,3.22,0,0,1,19,24.68a2.51,2.51,0,0,1-.62.09C17.46,24.78,7.32,24.89,6.58,24.83Zm-.95-3.2A1.26,1.26,0,0,0,7,22.95c1,0,8.43,0,11.21,0a1.11,1.11,0,0,0,.48-.12,1.4,1.4,0,0,0,.69-1.12c.09-.4,0-10.91,0-12a1.09,1.09,0,0,0-.45-.95c-.49-.38-.93-.81-1.38-1.23a1.62,1.62,0,0,1-.47-.81A4.76,4.76,0,0,1,17,5.53c0-.17-.05-.24-.23-.23L8,5.34c-.19,0-.27.06-.25.26s0,.48,0,.72a1.66,1.66,0,0,1-.53,1.3c-.47.44-.94.88-1.41,1.3a.89.89,0,0,0-.29.56,3.87,3.87,0,0,0,0,.6C5.53,12.81,5.58,20.57,5.64,21.63Zm2-18.06h.62c.15,0,.21-.06.2-.21q0-.49,0-1c0-.13,0-.2-.19-.2H7c-.14,0-.2.06-.19.2,0,.32,0,.65,0,1,0,.16.07.22.23.21Zm3.25-1.42h-.6c-.16,0-.23.06-.22.22,0,.32,0,.63,0,.95,0,.13,0,.2.19.2h1.25c.14,0,.19-.07.19-.2,0-.33,0-.66,0-1,0-.13-.06-.18-.18-.17Zm7.27.71h0c0-.17,0-.33,0-.5s0-.19-.17-.19l-1.27,0c-.14,0-.19.07-.18.2,0,.32,0,.63,0,.95s.07.23.24.23a9.17,9.17,0,0,1,1,0c.37,0,.37,0,.37-.36Q18.15,3,18.15,2.86Zm-4.84,0h0c0,.17,0,.33,0,.5s.05.2.19.2c.4,0,.8,0,1.2,0,.15,0,.21-.06.21-.21,0-.32,0-.63,0-.95,0-.16-.07-.22-.23-.22l-.95,0c-.42,0-.42,0-.42.43Z"
      transform="translate(-3.62 -0.18)"
      style={{ fill: "#ffffff" }}
    />
    <path
      d="M15.38,13.46l1.27,0c.38,0,.39,0,.38.37,0,.64,0,1.28,0,1.92,0,.25-.09.32-.32.31-.88-.05-1.77,0-2.65,0-.22,0-.28.07-.28.29,0,.91.06,1.82,0,2.72,0,.17-.05.25-.23.24-.67,0-1.33,0-2,0-.32,0-.35,0-.35-.33,0-.86,0-1.72,0-2.57,0-.23-.07-.3-.3-.3H8.27C8,16.1,8,16,8,15.78c0-.67,0-1.35,0-2,0-.17.07-.24.24-.24.9,0,1.8-.06,2.7,0,.22,0,.27-.06.27-.27,0-.89,0-1.78,0-2.67,0-.24.08-.32.31-.32.68,0,1.37,0,2,0,.21,0,.27.1.27.29,0,.87,0,1.75,0,2.62,0,.22.06.31.3.3.43,0,.87,0,1.3,0Z"
      transform="translate(-3.62 -0.18)"
      style={{ fill: "#ffffff" }}
    />
  </svg>
);

export const MyNotifications = () => (
  <svg
    aria-hidden="true"
    focusable="false"
    data-prefix="far"
    data-icon="bell"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 448 512"
    fill="#FFFFFF"
  >
    <path d="M439.39 362.29c-19.32-20.76-55.47-51.99-55.47-154.29 0-77.7-54.48-139.9-127.94-155.16V32c0-17.67-14.32-32-31.98-32s-31.98 14.33-31.98 32v20.84C118.56 68.1 64.08 130.3 64.08 208c0 102.3-36.15 133.53-55.47 154.29-6 6.45-8.66 14.16-8.61 21.71.11 16.4 12.98 32 32.1 32h383.8c19.12 0 32-15.6 32.1-32 .05-7.55-2.61-15.27-8.61-21.71zM67.53 368c21.22-27.97 44.42-74.33 44.53-159.42 0-.2-.06-.38-.06-.58 0-61.86 50.14-112 112-112s112 50.14 112 112c0 .2-.06.38-.06.58.11 85.1 23.31 131.46 44.53 159.42H67.53zM224 512c35.32 0 63.97-28.65 63.97-64H160.03c0 35.35 28.65 64 63.97 64z" />
  </svg>
);
