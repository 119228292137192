import React, { Component } from "react";
import { Button, Icon, Form, Pagination } from "semantic-ui-react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import DocumentsTable from "./DocumentsTable";
import * as modalActions from "../../../actions/modalActions";
import * as documentActions from "../../../actions/documentActions";
import * as lookupActions from "../../../actions/lookupActions";
import { checkRoles, detectDocumentType } from "../../../helpers";
import { roleGroups } from "../../../constants/securityRoles";
import "./ScansPage.css";

export class ScansPage extends Component {
  state = {
    activePage: 1,
    pageSize: 100,
    pageNumber: 1,
    sortColumn: "DateCreated",
    sortDirection: "descending",
    loading: false,
    filterTagsSelected: []
  };

  componentDidMount() {
    this._isMounted = true;
    this.props.actions.loadLookup("DocumentTags");
    this.props.actions.loadVisitStatuses();
    this.fetch();
  }

  componentDidUpdate() {
    if (this.props.forceReload) {
      this.props.actions.setForceReload(false);
      this.fetch();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  _isMounted = false;

  fetch = () => {
    const { patientId } = this.props;
    const { pageSize, pageNumber, sortColumn, sortDirection, filterTagsSelected } = this.state;
    if (patientId) {
      this.props.actions.loadDocuments(patientId, filterTagsSelected, pageSize, pageNumber, sortColumn, sortDirection);
    }
  };

  handleDocumentModalOpen = () =>
    this.props.actions.showModal({
      type: "ADD_DOCUMENT_MODAL",
      props: {
        open: true,
        isEdit: false,
        readOnly: false,
        documentId: "",
        pageSize: this.state.pageSize,
        pageNumber: this.state.pageNumber,
        sortColumn: this.state.sortColumn,
        sortDirection: this.state.sortDirection,
        filterTagsSelected: this.state.filterTagsSelected
      }
    });

  handleEdit = (documentId, readOnly = false) =>
    this.props.actions.showModal({
      type: "ADD_DOCUMENT_MODAL",
      props: {
        open: true,
        isEdit: true,
        readOnly,
        documentId,
        pageSize: this.state.pageSize,
        pageNumber: this.state.pageNumber,
        sortColumn: this.state.sortColumn,
        sortDirection: this.state.sortDirection,
        filterTagsSelected: this.state.filterTagsSelected
      }
    });

  openDocument = (response, type) => {
    const blob = response ? new Blob([response.document], { type }) : null;
    if (blob) {
      const fileURL = URL.createObjectURL(blob);
      window.open(fileURL, "_blank").focus();
    }
  };

  handleDowload = (documentId) => {
    if (this.state.loading) return;
    this.setState({ loading: true });
    this.props.actions
      .downloadDocument(documentId)
      .then((response) => {
        this.setState({ loading: false });
        const type = response.document && response.document.type ? response.document.type : "application/octet-stream";
        if (type === "application/octet-stream")
          detectDocumentType(response.document, (_type) => {
            this.openDocument(response, _type);
          });
        else this.openDocument(response, type);
      })
      .catch(() => {
        this.setState({ loading: false });
      });
  };

  handleDelete = (documentId) => {
    const document = this.props.availableDocuments.find((i) => i.documentId === documentId);
    this.props.actions.showModal({
      type: "DOCUMENT_DELETE_REASON_MODAL",
      props: {
        open: true,
        document,
        afterDelete: () => {
          this.props.actions.setForceReload(true);
        }
      }
    });
  };

  handleFilter = (e, data) => {
    const { patientId } = this.props;
    const { value } = data;
    this.setState({ filterTagsSelected: value }, () => {
      const { pageSize, pageNumber, sortColumn, sortDirection, filterTagsSelected } = this.state;
      this.props.actions.loadDocuments(patientId, filterTagsSelected, pageSize, pageNumber, sortColumn, sortDirection);
    });
  };

  handleSort = (clickedColumn) => () => {
    const { sortColumn, sortDirection } = this.state;
    this.setState(
      {
        sortColumn: clickedColumn,
        sortDirection: sortDirection === "ascending" && sortColumn === clickedColumn ? "descending" : "ascending"
      },
      () => {
        this.fetch();
      }
    );
  };

  handlePaginationChange = (e, { activePage }) => {
    if (activePage >= 1) {
      this.setState({ pageNumber: activePage }, () => this.fetch());
    }
  };

  render() {
    const { currentUserRoles, availableDocuments, totalDocumentsCount, documentTags } = this.props;
    const { pageSize, pageNumber, sortColumn, sortDirection } = this.state;
    const canDelete = checkRoles(roleGroups.canDeleteDocument, currentUserRoles);
    return (
      <div className="scans-content-wrapper scans-content">
        {
          <Button
            className="patient-demo-white-button"
            id="btn-task"
            size="small"
            onClick={this.handleDocumentModalOpen}
          >
            <Icon name={"plus"} />
            New Document
          </Button>
        }
        <div className="tag-filter-container">
          <div className="tag-filter-label">
            <label>Tag Filter</label>
          </div>
          <div className="tag-filter-dropdown">
            <Form className="">
              <Form.Field>
                <Form.Dropdown
                  fluid
                  multiple
                  selection
                  name="status"
                  placeholder="Select..."
                  width={16}
                  options={documentTags.map((i) => {
                    const u = {
                      ...i,
                      is_require_expiration_date: i.isRequireExpirationDate ? "true" : "false",
                      expire_after_days: i.expireAfterDays
                    };
                    delete u.isRequireExpirationDate;
                    delete u.expireAfterDays;
                    return u;
                  })}
                  onChange={this.handleFilter}
                  search
                  clearable
                />
              </Form.Field>
            </Form>
          </div>
        </div>
        <DocumentsTable
          availableDocuments={availableDocuments}
          documentTags={documentTags}
          handleEdit={this.handleEdit}
          handleDowload={this.handleDowload}
          canDelete={canDelete}
          handleDelete={this.handleDelete}
          sortColumn={sortColumn}
          sortDirection={sortDirection}
          handleSort={this.handleSort}
        />
        <div style={{ padding: "0 0 1em 0", textAlign: "center" }}>
          <Pagination
            boundaryRange={2}
            activePage={pageNumber}
            ellipsisItem={`...`}
            size="mini"
            firstItem={null}
            lastItem={null}
            siblingRange={1}
            totalPages={Math.ceil(totalDocumentsCount / pageSize)}
            onPageChange={this.handlePaginationChange}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentUserRoles: state.auth.user.profile.roles,
    patientId: state.patient.currentPatient.patientId,
    availableDocuments: state.documents.availableDocuments,
    forceReload: state.documents.forceReload,
    totalDocumentsCount: state.documents.numberofPages || 20,
    documentTags: state.lookups.documentTags,
    authRoles: state.auth.user.profile.roles
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...modalActions,
        ...documentActions,
        ...lookupActions
      },
      dispatch
    )
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ScansPage);
