import React from "react";
import { Switch, Route } from "react-router-dom";
import IExportPatient from "./patients/iexport";

const BlankLayout = ({ match }) => (
  <Switch>
    <Route exact path={`${match.path}/patient/:patientId/iexport`} component={IExportPatient} />
  </Switch>
);

export default BlankLayout;
