import isEmpty from "lodash/isEmpty";

import initialState from "./initialState";
import * as types from "../actions/actionTypes";

export default function modalReducer(state = initialState.multipleModals, action) {
  const { current, previous } = state;

  switch (action.type) {
    case types.SHOW_MULTIPLE_MODALS: {
      const { modal } = action;
      const previousCopy = [...previous];

      if (!isEmpty(current)) {
        previousCopy.push(current);
      }

      return {
        current: modal,
        previous: previousCopy
      };
    }
    case types.HIDE_MULTIPLE_MODALS: {
      const previousCopy = [...previous];
      const newCurrent = previousCopy.pop() || {};
      const { closeAll, prevModalProps } = action;

      if (closeAll) {
        return initialState.multipleModals;
      }

      return {
        current: { ...newCurrent, props: { ...newCurrent.props, ...prevModalProps } },
        previous: previousCopy
      };
    }
    default:
      return state;
  }
}
