import React, { Component } from "react";
import { Modal, Button, Form } from "semantic-ui-react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as modalActions from "../../../actions/modalActions";
import * as templateActions from "../../../actions/templateActions";
import * as visitActions from "../../../actions/visitActions";
import * as contextActions from "../../../actions/contextActions";

export class ChangeVisitTypeModal extends Component {
  state = {
    selectedVisitType: this.props.visitType
  };

  reloadTemplates = () => {
    const {
      patientId,
      visitId,
      actions: { loadTemplates, setSelectedTemplate, loadTemplateSurveys }
    } = this.props;
    if (!patientId || !visitId) return;

    loadTemplates(patientId, visitId).then(({ templates }) => {
      if (templates.selectedTemplate) {
        this.props.actions.setContext("template", { templateId: templates.selectedTemplate });
        return loadTemplateSurveys(patientId, visitId, templates.selectedTemplate);
      }
      this.props.actions.setContext("template", { templateId: templates.availableTemplates[0].templateId });
      return templates.availableTemplates[0]
        ? setSelectedTemplate(
            templates.availableTemplates[0].templateId,
            templates.availableTemplates[0].version,
            patientId,
            visitId
          ).then(() => loadTemplateSurveys(patientId, visitId, templates.availableTemplates[0].templateId))
        : { surveys: [] };
    });
  };

  handleSave = () => {
    const { selectedVisitType } = this.state;
    const { visitId, reloadTemplates } = this.props;
    this.props.actions.updateVisitType(visitId, selectedVisitType).then(() => {
      // reload surveys
      if (reloadTemplates) {
        this.reloadTemplates();
      }
      // reload ...
      this.handleCancel();
    });
  };

  handleCancel = () => this.props.actions.hideModal();

  handleTypeChange = (e, data) => {
    const { value } = data;
    this.setState({ selectedVisitType: value });
  };

  render() {
    const { selectedVisitType } = this.state;
    const { open, visitTypeOptions } = this.props;

    return (
      <Modal open={open} size={"mini"} closeIcon closeOnDimmerClick={false} onClose={this.handleCancel}>
        <Modal.Header>{`Change Visit Type`}</Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Group widths="equal">
              <Form.Dropdown
                fluid
                label="Select Visit Type"
                selection
                options={visitTypeOptions}
                name="selectedVisitType"
                value={selectedVisitType}
                onChange={this.handleTypeChange}
                onFocus={this.handleFocus}
              />
            </Form.Group>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.handleCancel} content="Cancel" />
          <Button color="blue" onClick={this.handleSave} content="Change" />
        </Modal.Actions>
      </Modal>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const isMedicalVisit = state.lookups.visitTypes.find((vt) => vt.value === ownProps.visitType).medical;
  return {
    visitTypeOptions: state.lookups.visitTypes
      .filter((vt) => vt.medical === isMedicalVisit && vt.value !== "group" && vt.value !== "progressnote")
      .map((i) => ({ text: i.text, value: i.value }))
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...modalActions,
        ...templateActions,
        ...visitActions,
        ...contextActions
      },
      dispatch
    )
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangeVisitTypeModal);
