import React from "react";
import { Table, Header } from "semantic-ui-react";
import { Button, Icon } from "../../../ui";
import "./ViewPatientDemographics.css";

import { checkRoles, formatPhoneNumber, handleAppLink } from "../../../helpers";
import { roleNames } from "../../../constants/securityRoles";

function getIcon(value) {
  switch (value) {
    case "Home":
    case "Work":
    case "Other":
      return "telephone";
    case "Cell":
    case "SMS":
      return "phone";
    default:
      return "";
  }
}

const PatientPhoneNumberRow = ({
  phone: {
    type = "Home",
    phoneNumber,
    phoneNumberId,
    extension,
    isPreferred,
    // consentGiveForMessages,
    consentToCall, // this field will replace consentToCall in next release 2.21.0
    consentToLeaveMessages,
    textAppointmentReminder
  },
  handlePhoneEditClick,
  // showDemoEdit
  authRoles
}) => {
  let icon = "";
  if (type) {
    icon = getIcon(type);
  }

  const canEditPhoneNumber = checkRoles([roleNames.systemAdmin], authRoles);

  const _consentToCall =
    consentToCall === null
      ? null
      : typeof consentToCall === "string"
      ? consentToCall.trim().toLowerCase() === "true"
      : consentToCall;
  const _consentToLeaveMessages =
    consentToLeaveMessages === null
      ? null
      : typeof consentToLeaveMessages === "string"
      ? consentToLeaveMessages.trim().toLowerCase() === "true"
      : consentToLeaveMessages;
  const _textAppointmentReminder =
    textAppointmentReminder === null
      ? null
      : typeof textAppointmentReminder === "string"
      ? textAppointmentReminder.trim().toLowerCase() === "true"
      : textAppointmentReminder;

  return (
    <Table.Row>
      <Table.Cell className="phone_">
        <Header as="h4">
          <Icon name={icon} className="me-1" />
          <div id="lbl-phoneNumber" className="resizable-content">
            <label>{type}</label>
            {formatPhoneNumber(phoneNumber)}
          </div>
          {(type === "Work" || type === "Other") && extension ? (
            <div className="con_ resizable-content" id="lbl-extension">
              <label>Extension</label>
              {extension}
            </div>
          ) : null}
        </Header>
      </Table.Cell>
      <Table.Cell className="icon-container">
        {_consentToCall && (
          <a href={`tel:${phoneNumber}`} style={{ display: "inline" }} onClick={handleAppLink}>
            <Icon title="OK to call" name="telephone-fill" className={`h5 me-3 text-success`} />
          </a>
        )}
        {!_consentToCall && (
          <Icon
            title={_consentToCall === null ? `No answer provided` : `Don't call`}
            name="telephone-fill"
            className={_consentToCall === null ? `h5 me-3 text-black-50` : `h5 me-3 text-danger`}
          />
        )}

        {_consentToLeaveMessages && (
          <a href={`tel:${phoneNumber}`} style={{ display: "inline" }} onClick={handleAppLink}>
            <Icon title="OK to leave messages" name="voicemail" className={`h4 me-3 text-success`} />
          </a>
        )}
        {!_consentToLeaveMessages && (
          <Icon
            title={_consentToLeaveMessages === null ? `No answer provided` : `Don't to leave messages`}
            name="voicemail"
            className={_consentToLeaveMessages === null ? `h4 me-3 text-black-50` : `h4 me-3 text-danger`}
          />
        )}

        {icon === "phone" && _textAppointmentReminder && (
          <a href={`sms:${phoneNumber}`} style={{ display: "inline" }} onClick={handleAppLink}>
            <Icon title="OK to SMS" name="chat-left-text-fill" className={`mr-3 h5 me-3 text-success`} />
          </a>
        )}
        {icon === "phone" && !_textAppointmentReminder && (
          <Icon
            title={_textAppointmentReminder === null ? `No answer provided` : `Don't SMS`}
            name="chat-left-text-fill"
            className={_textAppointmentReminder === null ? `mr-3 h5 me-3 text-black-50` : `mr-3 h5 me-3 text-danger`}
          />
        )}
        {isPreferred ? <Icon name="star-fill" className="h4 text-warning" title="Primary contact" /> : null}
      </Table.Cell>
      <Table.Cell collapsing className="no-padding">
        {canEditPhoneNumber && (
          <Button
            variant="outline-primary"
            size="sm"
            onClick={() => {
              handlePhoneEditClick(phoneNumberId);
            }}
            title="Edit"
            className="mt-2"
          >
            <Icon name="pencil-square" />
          </Button>
        )}
      </Table.Cell>
    </Table.Row>
  );
};

export default PatientPhoneNumberRow;
