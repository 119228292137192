import moment from "moment";
import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function documentReducer(state = initialState.documents, action) {
  switch (action.type) {
    case types.SET_DOCUMENTS_FORCE_RELOAD:
      return {
        ...state,
        forceReload: action.value
      };

    case types.GET_DOCUMENT_SUCCESS: {
      return Object.assign({}, state, {
        [action.documentType]: action.document
      });
    }

    case types.UPLOAD_DOCUMENT_SUCCESS: {
      const uploadedDocument = {};
      const fileTags = [];
      for (const [key, value] of action.document.entries()) {
        if (key !== "File") {
          if (key === "fileTags[]") {
            fileTags.push(value);
          } else {
            uploadedDocument[key] = value;
          }
        }
      }
      uploadedDocument.fileTags = fileTags;
      uploadedDocument.dateCreated = moment(action.response.TimeStamp); // .format("MM/DD/YYYY");
      return Object.assign({}, state, {
        availableDocuments: [
          { ...uploadedDocument, documentId: action.response.DocumentId },
          ...state.availableDocuments
        ]
      });
    }

    case types.LOAD_AVAILABLE_DOCS_SUCCESS: {
      return Object.assign({}, state, {
        availableDocuments: action.availableDocuments,
        numberofPages: action.numberofPages
      });
    }

    case types.DOWNLOAD_DOCS_SUCCESS: {
      return Object.assign({}, state);
    }

    case types.EDIT_DOCUMENT_SUCCESS: {
      const updatedDocuments = state.availableDocuments.map(document => {
        if (action.documentId === document.documentId) {
          return Object.assign({}, document, action.document);
        }
        return document;
      });
      return Object.assign({}, state, {
        availableDocuments: updatedDocuments
      });
    }

    default:
      return state;
  }
}
