import { combineReducers } from "redux";
import ajaxCallsInProgress from "./ajaxStatusReducer";
import context from "./contextReducer";
import centers from "./centersReducer";
import modal from "./modalReducer";
import patient from "./patientReducer";
import patientHistory from "./patientHistoryReducer";
import patientExport from "./patientExportReducer";
import lookups from "./lookupReducer";
import search from "./searchReducer";
import demographics from "./demographicsReducer";
import prescriptions, * as fromPrescriptions from "./prescriptionReducer";
import providers from "./providersReducer";
import visits, * as fromVisits from "./visitsReducer";
import noteSlashTasks from "./noteSlashTasksReducer";
import auth from "./authReducer";
import insurance from "./insuranceReducer";
import treatmentPlan from "./treatmentPlanReducer";
import templates from "./templateReducer";
import groups from "./groupReducer";
import labs from "./labReducer";
import documents from "./documentReducer";
import userPreference from "./userPreferenceReducer";
import charts from "./chartsReducer";
import badges from "./badgeReducer";
import errorLogs from "./errorLogsReducer";
import claimErrors from "./claimErrorsReducer";
import sidebarErrorLog from "./sidebarErrorLogReducer";
import reports from "./reportReducer";
import multipleModals from "./multipleModalReducer";
import user from "./userReducer";
import admin from "./adminReducer";
import notifications from "./notificationReducer";
import notes from "./noteReducer";
import streaming from "./streamingReducer";
import misc from "./miscReducer";
import { apiSlice } from "../api/apiSlice";
// import labsSlice from "../components/patients/labs/labsSlice";

const rootReducer = combineReducers({
  [apiSlice.reducerPath]: apiSlice.reducer,
  ajaxCallsInProgress,
  context,
  centers,
  search,
  patient,
  patientHistory,
  patientExport,
  modal,
  lookups,
  demographics,
  prescriptions,
  providers,
  visits,
  noteSlashTasks,
  auth,
  insurance,
  treatmentPlan,
  templates,
  groups,
  labs,
  documents,
  userPreference,
  charts,
  badges,
  errorLogs,
  claimErrors,
  sidebarErrorLog,
  reports,
  multipleModals,
  user,
  admin,
  notifications,
  notes,
  streaming,
  misc
  // _labs: labsSlice
});

export const selectIsContextVisitInActiveVisits = (state) =>
  fromVisits.selectIsContextVisitInActiveVisits(state.visits);
export const selectedPrescription = (state) => fromPrescriptions.selectedPrescription(state.prescriptions);

export default rootReducer;
