import * as types from "./actionTypes";
import { API_URL } from "../constants/environment";
import { adalApiFetch } from "../adalConfig";
import { pollApiEvents, processApiResponse, processApiError, processThenThrowApiError } from "../helpers";
import { beginAjaxCall, ajaxCallDone } from "./ajaxStatusActions";

function loadChartsToSignSuccess(charts) {
  return {
    type: types.LOAD_CHARTS_TO_SIGN_SUCCESS,
    charts
  };
}

function signChartSuccess(charts) {
  return {
    type: types.SIGN_CHART_SUCCESS,
    charts
  };
}

export function clearSignedChartDetails() {
  return {
    type: types.CLEAR_CHART_DETAILS
  };
}

export function clearCosignedChartDetails() {
  return {
    type: types.CLEAR_COSIGNED_CHART_DETAIL
  };
}

function loadSignedChartDetailsSuccess(details) {
  return {
    type: types.LOAD_CHART_DETAILS_SUCCESS,
    details
  };
}

function loadCoSignedChartDetailsSuccess(cosignDetail) {
  return {
    type: types.LOAD_COSIGNED_CHART_DETAIL_SUCCESS,
    cosignDetail
  };
}

function coSignVisitSuccess(data) {
  return {
    type: types.COSIGN_VISIT_SUCCESS,
    data
  };
}

export function loadChartsToSign() {
  return (dispatch) => {
    dispatch(beginAjaxCall("loadChartsToSign"));
    return adalApiFetch(`${API_URL}/Visits/ChartsToSignReport`)
      .then((json) => dispatch(loadChartsToSignSuccess(json)))
      .catch((error) => {
        processApiError(error, dispatch);
      })
      .finally(() => {
        dispatch(ajaxCallDone());
      });
  };
}

export function signChart(patientId, signInfo) {
  return (dispatch) => {
    dispatch(beginAjaxCall("signChart"));
    return adalApiFetch(`${API_URL}/Visits/${patientId}/Sign`, {
      method: "POST",
      body: JSON.stringify(signInfo)
    })
      .then((response) => processApiResponse(response.orchestrationId))
      .then((orchestrationId) => pollApiEvents(orchestrationId, "IVisitSignedEvent"))
      .then((response) => dispatch(signChartSuccess(response)))
      .catch((error) => {
        processApiError(error, dispatch);
        throw error;
      })
      .finally(() => {
        dispatch(ajaxCallDone());
      });
  };
}

export function loadSignedChartDetails(visitId) {
  if (!visitId) throw new Error("loadSignedChartDetails(!visitId)");
  return (dispatch) => {
    dispatch(clearSignedChartDetails());
    dispatch(beginAjaxCall("loadSignedChartDetails"));
    return adalApiFetch(`${API_URL}/Visits/${visitId}/SignedChartDetails`)
      .then((json) => dispatch(loadSignedChartDetailsSuccess(json)))
      .catch((error) => {
        processApiError(error, dispatch);
      })
      .finally(() => {
        dispatch(ajaxCallDone());
      });
  };
}

export function loadProvidersBySupervisorId() {
  return (dispatch) => {
    dispatch(beginAjaxCall("loadProvidersBySupervisorId"));
    return adalApiFetch(`${API_URL}/Users/GetProvidersBySupervisorId`)
      .catch((error) => {
        processApiError(error, dispatch);
      })
      .finally(() => {
        dispatch(ajaxCallDone());
      });
  };
}

export function loadChartsToCoSignReport(providerId, state, year, month) {
  return (dispatch) => {
    dispatch(beginAjaxCall("loadChartsToCoSignReport"));
    return adalApiFetch(
      `${API_URL}/Visits/ChartsToCoSignReport?providerId=${providerId}&state=${state}&year=${year}&month=${month}`
    )
      .then((json) => dispatch({ type: "_", listVisit: json }))
      .catch((error) => processApiError(error, dispatch))
      .finally(() => dispatch(ajaxCallDone()));
  };
}

export function loadCoSignedChartDetails(visitId) {
  return (dispatch) => {
    dispatch(beginAjaxCall("loadCoSignedChartDetails"));
    return adalApiFetch(`${API_URL}/Visits/${visitId}/CoSignedChartDetails`)
      .then((json) => dispatch(loadCoSignedChartDetailsSuccess(json)))
      .catch((error) => processApiError(error, dispatch))
      .finally(() => dispatch(ajaxCallDone()));
  };
}

export function coSignVisit(
  patientId,
  visitId,
  providerId,
  stateCode,
  waitForSupervisionCycleStatisticsUpdated = false
) {
  return (dispatch) => {
    const coSignInfo = {
      visitId,
      providerId,
      stateCode
    };
    dispatch(beginAjaxCall("coSignVisit"));
    return adalApiFetch(`${API_URL}/Visits/${patientId}/CoSign`, {
      method: "POST",
      body: JSON.stringify(coSignInfo)
    })
      .then((response) => processApiResponse(response.orchestrationId))
      .then((orchestrationId) => {
        const promises = [pollApiEvents(orchestrationId, "IVisitCoSignedEvent")];
        if (waitForSupervisionCycleStatisticsUpdated) {
          promises.push(pollApiEvents(orchestrationId, "ISupervisionCycleStatisticsUpdatedEvent"));
        }
        return Promise.all(promises);
      })
      .then((response) => dispatch(coSignVisitSuccess(response)))
      .catch((error) => processThenThrowApiError(error, dispatch))
      .finally(() => dispatch(ajaxCallDone()));
  };
}
