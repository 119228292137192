import React, { Component } from "react";

import { Accordion, Icon, Grid } from "semantic-ui-react";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as lookupActions from "../../../../actions/lookupActions";
import * as insuranceActions from "../../../../actions/insuranceActions";

import PatientInsurance from "./PatientInsurance";
import AddPatientInsurance from "./AddPatientInsurance";

import { getLookupText } from "../../../../helpers";

export class InsuranceAccordion extends Component {
  state = {
    activeIndex: 0
  };

  componentDidMount() {
    this._isMounted = true;
    const {
      patientId,
      insurance: { casePolicy }
    } = this.props;

    if (patientId) {
      this.props.actions.loadPatientInsurance(patientId).then((result) => {
        if (this._isMounted) this.setState({ insurance: result });
      });
    }
    if (!casePolicy) this.setState({ activeIndex: 1 });
    this.props.actions.loadInsuranceProviders();
  }

  componentDidUpdate(prevProps) {
    const {
      patientId,
      insurance: { casePolicy }
    } = this.props;
    if (patientId !== prevProps.patientId) {
      this.props.actions.loadPatientInsurance(patientId).then((result) => {
        if (this._isMounted) this.setState({ insurance: result });
      });
      // eslint-disable-next-line react/no-did-update-set-state
      if (!casePolicy) this.setState({ activeIndex: 1 });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.props.actions.clearPatientInsurance();
  }

  _isMounted = false;

  handleDischargeReason = (e, data) => {
    if (data.value && this._isMounted) {
      this.setState({ dischargeReason: data.value });
    }
  };

  handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;
    if (this._isMounted) this.setState({ activeIndex: newIndex });
  };

  render() {
    const { activeIndex } = this.state;

    const {
      insurance: { casePolicy, primaryInsurance, secondaryInsurance, tertiaryInsurance, nonBillableInsurance },
      insuranceProviders
    } = this.props;
    return (
      <div className="insurance-accordion-container">
        <h3>Insurance</h3>
        <Accordion styled>
          {casePolicy && (
            <React.Fragment>
              <Accordion.Title active={activeIndex === 0} index={0} onClick={this.handleClick}>
                <Grid className="no-margin">
                  <Grid.Row className="no-padding">
                    <Grid.Column width={6} className="no-padding">
                      <Icon name="dropdown" />
                      {`Case Policy`}
                    </Grid.Column>
                    <Grid.Column width={10} textAlign="right">
                      <span id="lbl-primary">
                        {casePolicy && getLookupText(casePolicy.insurer, insuranceProviders)}
                      </span>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Accordion.Title>
              <Accordion.Content active={activeIndex === 0}>
                {casePolicy && Object.keys(casePolicy).length && <PatientInsurance insuranceType={"casePolicy"} />}
              </Accordion.Content>
            </React.Fragment>
          )}

          <Accordion.Title active={activeIndex === 1} index={1} onClick={this.handleClick}>
            <Grid className="no-margin">
              <Grid.Row className="no-padding">
                <Grid.Column width={6} className="no-padding">
                  <Icon name="dropdown" />
                  {`Primary Insurance`}
                </Grid.Column>
                <Grid.Column width={10} textAlign="right">
                  <span id="lbl-primary">
                    {primaryInsurance && getLookupText(primaryInsurance.insurer, insuranceProviders)}
                  </span>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Accordion.Title>
          <Accordion.Content active={activeIndex === 1}>
            {primaryInsurance && Object.keys(primaryInsurance).length ? (
              <PatientInsurance insuranceType={"primaryInsurance"} />
            ) : (
              <AddPatientInsurance insuranceType={"primaryInsurance"} />
            )}
          </Accordion.Content>

          <Accordion.Title active={activeIndex === 2} index={2} onClick={this.handleClick}>
            <Grid className="no-margin">
              <Grid.Row className="no-padding">
                <Grid.Column width={6} className="no-padding">
                  <Icon name="dropdown" />
                  {`Secondary Insurance`}
                </Grid.Column>
                <Grid.Column width={10} textAlign="right">
                  <span id="lbl-secondary">
                    {secondaryInsurance && getLookupText(secondaryInsurance.insurer, insuranceProviders)}
                  </span>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Accordion.Title>
          <Accordion.Content active={activeIndex === 2}>
            {secondaryInsurance ? (
              <PatientInsurance insuranceType={"secondaryInsurance"} />
            ) : (
              <AddPatientInsurance insuranceType={"secondaryInsurance"} />
            )}
          </Accordion.Content>

          <Accordion.Title active={activeIndex === 3} index={3} onClick={this.handleClick}>
            <Grid className="no-margin">
              <Grid.Row className="no-padding">
                <Grid.Column width={6} className="no-padding">
                  <Icon name="dropdown" />
                  {`Tertiary Insurance`}
                </Grid.Column>
                <Grid.Column width={10} textAlign="right">
                  <span id="lbl-tertiary">
                    {tertiaryInsurance && getLookupText(tertiaryInsurance.insurer, insuranceProviders)}
                  </span>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Accordion.Title>
          <Accordion.Content active={activeIndex === 3}>
            {tertiaryInsurance ? (
              <PatientInsurance insuranceType={"tertiaryInsurance"} />
            ) : (
              <AddPatientInsurance insuranceType={"tertiaryInsurance"} />
            )}
          </Accordion.Content>
          <Accordion.Title active={activeIndex === 4} index={4} onClick={this.handleClick}>
            <Grid className="no-margin">
              <Grid.Row className="no-padding">
                <Grid.Column width={6} className="no-padding">
                  <Icon name="dropdown" />
                  {`Non-Billable Insurance`}
                </Grid.Column>
                <Grid.Column width={10} textAlign="right">
                  <span id="lbl-non-billable">
                    {nonBillableInsurance && getLookupText(nonBillableInsurance.insurer, insuranceProviders)}
                  </span>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Accordion.Title>
          <Accordion.Content active={activeIndex === 4}>
            {nonBillableInsurance ? (
              <PatientInsurance insuranceType={"nonBillableInsurance"} />
            ) : (
              <AddPatientInsurance insuranceType={"nonBillableInsurance"} />
            )}
          </Accordion.Content>
        </Accordion>
      </div>
    );
  }
}

function formatInsuranceProvForDropdown(insuranceProviders) {
  return insuranceProviders.map((insuranceProvider) => ({
    value: insuranceProvider.value.toString(),
    text: insuranceProvider.text
  }));
}

function mapStateToProps(state) {
  return {
    patientId: state.patient.currentPatient.patientId,
    insurance: state.insurance || {},
    insuranceProviders: formatInsuranceProvForDropdown(state.lookups.insuranceProviders)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...lookupActions, ...insuranceActions }, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(InsuranceAccordion);
