/* eslint-disable no-param-reassign */
import { createSelector, createEntityAdapter } from "@reduxjs/toolkit";
import { ajaxCallDone, beginAjaxCall } from "../../../actions/ajaxStatusActions";
// import * as labsApi from "../../../api/labs";
import { apiSlice } from "../../../api/apiSlice";
import { pollApiEvents, processApiError } from "../../../helpers";

// const getNotReviewedLabResults = createAsyncThunk("_labs/getNotReviewedLabResults", async (patientId, { signal }) => {
//   const data = await labsApi.getNotReviewedLabResults(patientId, signal);
//   return data;
// });

const notReviewedLabResultsAdapter = createEntityAdapter({ selectId: (i) => `${i.emrOrderId}-${i.parentLabCode}` });
const reviewedLabResultsAdapter = createEntityAdapter({ selectId: (i) => `${i.emrOrderId}-${i.parentLabCode}` });
const labResultsVisitsAdapter = createEntityAdapter({ selectId: (v) => v.orderId });
const labResultsReviewDetailsAdapter = createEntityAdapter({ selectId: (i) => `${i.visitId}-${i.parentLabCode}` });
const reviewedLabResultsVisitsAdapter = createEntityAdapter({ selectId: (v) => v.visitId });
const labResultsHistoryAdapter = createEntityAdapter({ selectId: (i) => i.orderId });
const reviewedLabResultsHistoryAdapter = createEntityAdapter({ selectId: (i) => i.id });

const notReviewedLabResultsInitialState = notReviewedLabResultsAdapter.getInitialState();
const reviewedLabResultsInitialState = reviewedLabResultsAdapter.getInitialState();
const labResultsVisitsInitialState = labResultsVisitsAdapter.getInitialState();
const labResultsReviewDetailsInitialState = labResultsReviewDetailsAdapter.getInitialState();
const reviewedLabResultsVisitsInitialState = reviewedLabResultsVisitsAdapter.getInitialState();
const labResultsHistoryInitialState = labResultsHistoryAdapter.getInitialState();
const reviewedLabResultsHistoryInitialState = reviewedLabResultsHistoryAdapter.getInitialState();

export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getNotReviewedLabResults: builder.query({
      query: ({ patientId }) => `/PatientLabs/${patientId}/NotReviewedLabResults`,
      transformResponse: (responseData) =>
        notReviewedLabResultsAdapter.setAll(notReviewedLabResultsInitialState, responseData),
      providesTags: (result, error, { patientId }) => [`NotReviewedLabResults-${patientId}`]
    }),

    getLabResultsVisits: builder.query({
      query: ({ patientId }) => `/PatientLabs/${patientId}/LabResultsVisits`,
      transformResponse: (responseData) => labResultsVisitsAdapter.setAll(labResultsVisitsInitialState, responseData),
      providesTags: (result, error, { patientId }) => [`LabResultsVisits-${patientId}`]
    }),

    getReviewedLabResultsVisits: builder.query({
      query: ({ patientId }) => `/PatientLabs/${patientId}/ReviewedLabResultsVisits`,
      transformResponse: (responseData) =>
        reviewedLabResultsVisitsAdapter.setAll(reviewedLabResultsVisitsInitialState, responseData),
      providesTags: (result, error, { patientId }) => [`ReviewedLabResultsVisits-${patientId}`]
    }),

    getLabResultsReviewDetails: builder.query({
      query: ({ patientId, orderId }) => `/PatientLabs/${patientId}/LabResultsReviewDetails/Orders/${orderId}`,
      transformResponse: (responseData) =>
        labResultsReviewDetailsAdapter.setAll(labResultsReviewDetailsInitialState, responseData),
      providesTags: (result, error, { patientId, orderId }) => [`LabResultsReviewDetails-${patientId}-${orderId}`]
    }),

    getReviewedLabResults: builder.query({
      query: ({ patientId, visitId }) => `/PatientLabs/${patientId}/ReviewedLabResults/Visits/${visitId}`,
      transformResponse: (responseData) =>
        reviewedLabResultsAdapter.setAll(reviewedLabResultsInitialState, responseData),
      providesTags: (result, error, { patientId, visitId }) => [`ReviewedLabResults-${patientId}-${visitId}`]
    }),

    getLabResultsHistory: builder.query({
      query: ({ patientId, visitTime, type, ordersToSkip = 0, ordersCount = 8 }) => {
        if (!patientId || !visitTime || !type) {
          throw new Error("Missing required param [`patientId`, `visitTime`, and `type` are all required].");
        }
        return `/PatientLabs/${patientId}/LabResultsHistory/Visits/${visitTime}?type=${type}&ordersToSkip=${ordersToSkip}&ordersCount=${ordersCount}`;
      },
      transformResponse: (responseData) => labResultsHistoryAdapter.setAll(labResultsHistoryInitialState, responseData),
      providesTags: (result, error, { patientId, visitTime, type }) => [
        `LabResultsHistory-${patientId}`,
        `LabResultsHistory-${patientId}-${visitTime}-${type}`
      ]
    }),

    getReviewedLabResultsHistory: builder.query({
      query: ({ patientId, visitId }) => `/PatientLabs/${patientId}/ReviewedLabResultsHistory/Visits/${visitId}`,
      transformResponse: (responseData) =>
        reviewedLabResultsHistoryAdapter.setAll(reviewedLabResultsHistoryInitialState, responseData),
      providesTags: (result, error, { patientId, visitId }) => [`ReviewedLabResultsHistory-${patientId}-${visitId}`]
    }),

    reviewLabResult: builder.mutation({
      async queryFn({ patientId, body }, { dispatch }, extraOptions, baseQuery) {
        dispatch(beginAjaxCall("reviewLabResult"));
        const response = await baseQuery({ url: `/PatientLabs/${patientId}/ReviewLabResult`, method: "POST", body });
        if (response.error) {
          processApiError(response.error, dispatch);
          return { error: response.error };
        }
        try {
          const data = await pollApiEvents(response.data.orchestrationId, "ILabResultReviewedEvent");
          return { data };
        } catch (error) {
          processApiError(error, dispatch);
          return { error };
        } finally {
          dispatch(ajaxCallDone());
        }
      },
      invalidatesTags: (result, error, { patientId, body: { visitId, orderId } }) => [
        `LabResultsVisits-${patientId}`,
        `NotReviewedLabResults-${patientId}`,
        `LabResultsHistory-${patientId}`,
        `ReviewedLabResultsHistory-${patientId}-${visitId}`,
        `LabResultsReviewDetails-${patientId}-${orderId}`
      ]
    }),

    reviewLabResultHistory: builder.mutation({
      async queryFn({ patientId, body }, { dispatch }, extraOptions, baseQuery) {
        dispatch(beginAjaxCall("reviewLabResult"));
        const response = await baseQuery({
          url: `/PatientLabs/${patientId}/ReviewLabResultHistory`,
          method: "POST",
          body
        });
        if (response.error) {
          processApiError(response.error, dispatch);
          return { error: response.error };
        }
        try {
          const data = await pollApiEvents(response.data.orchestrationId, "ILabResultHistoryReviewedEvent");
          return { data };
        } catch (error) {
          processApiError(error, dispatch);
          return { error };
        } finally {
          dispatch(ajaxCallDone());
        }
      },
      invalidatesTags: (result, error, { patientId, body: { visitId } }) => [
        `ReviewedLabResultsHistory-${patientId}-${visitId}`
      ]
    })
  })
});

export const {
  useGetLabResultsReviewDetailsQuery,
  useGetNotReviewedLabResultsQuery,
  useGetLabResultsVisitsQuery,
  useGetReviewedLabResultsVisitsQuery,
  useGetReviewedLabResultsQuery,
  useGetLabResultsHistoryQuery,
  useReviewLabResultMutation,
  useGetReviewedLabResultsHistoryQuery,
  useReviewLabResultHistoryMutation
} = extendedApiSlice;

//---
export const selectLabResultsReviewDetails = (query) => apiSlice.endpoints.getLabResultsReviewDetails.select(query);

export const labResultsReviewDetailsSelectors = createSelector(
  (state, query) => selectLabResultsReviewDetails(query)(state),
  (result) => labResultsReviewDetailsAdapter.getSelectors(() => result?.data ?? labResultsReviewDetailsInitialState)
);

//---
export const selectNotReviewedLabResults = (query) => apiSlice.endpoints.getNotReviewedLabResults.select(query);

export const notReviewedLabResultsSelectors = createSelector(
  (state, query) => selectNotReviewedLabResults(query)(state),
  (result) => notReviewedLabResultsAdapter.getSelectors(() => result?.data ?? notReviewedLabResultsInitialState)
);

//---
export const selectReviewedLabResults = (query) => extendedApiSlice.endpoints.getReviewedLabResults.select(query);

export const reviewedLabResultsSelectors = createSelector(
  (state, query) => selectReviewedLabResults(query)(state),
  (result) => reviewedLabResultsAdapter.getSelectors(() => result?.data ?? reviewedLabResultsInitialState)
);

//---
export const selectLabResultsHistory = (query) => extendedApiSlice.endpoints.getLabResultsHistory.select(query);

export const labResultsHistorySelectors = createSelector(
  (state, query) => selectLabResultsHistory(query)(state),
  (result) => labResultsHistoryAdapter.getSelectors(() => result?.data ?? labResultsHistoryInitialState)
);

//---
export const selectLabResultsVisits = (query) => extendedApiSlice.endpoints.getLabResultsVisits.select(query);

export const labResultsVisitsSelectors = createSelector(
  (state, query) => selectLabResultsVisits(query)(state),
  (result) => labResultsVisitsAdapter.getSelectors(() => result?.data ?? labResultsVisitsInitialState)
);

//---
export const selectReviewedLabResultsVisits = (query) =>
  extendedApiSlice.endpoints.getReviewedLabResultsVisits.select(query);

export const reviewedLabResultsVisitsSelectors = createSelector(
  (state, query) => selectReviewedLabResultsVisits(query)(state),
  (result) => reviewedLabResultsVisitsAdapter.getSelectors(() => result?.data ?? reviewedLabResultsVisitsInitialState)
);

//---
export const selectReviewedLabResultsHistory = (query) =>
  extendedApiSlice.endpoints.getReviewedLabResultsHistory.select(query);

export const reviewedLabResultsHistorySelectors = createSelector(
  (state, query) => selectReviewedLabResultsHistory(query)(state),
  (result) => reviewedLabResultsHistoryAdapter.getSelectors(() => result?.data ?? reviewedLabResultsHistoryInitialState)
);

// advanced
export const getSelectors = (query) => {
  const selectResult = extendedApiSlice.endpoints.getReviewedLabResultsVisits.select(query);

  const adapterSelectors = createSelector(selectResult, (result) =>
    reviewedLabResultsVisitsAdapter.getSelectors(() => result?.data ?? reviewedLabResultsVisitsInitialState)
  );

  return {
    selectAll: createSelector(adapterSelectors, (s) => s.selectAll(undefined)),
    selectEntities: createSelector(adapterSelectors, (s) => s.selectEntities(undefined)),
    selectIds: createSelector(adapterSelectors, (s) => s.selectIds(undefined)),
    selectTotal: createSelector(adapterSelectors, (s) => s.selectTotal(undefined)),
    selectById: (id) => createSelector(adapterSelectors, (s) => s.selectById(s, id))
  };
};

export default null;
