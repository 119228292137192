import "react-toastify/dist/ReactToastify.css";
import * as types from "./actionTypes";
import { beginAjaxCall, ajaxCallDone } from "./ajaxStatusActions";
import { pollApiEvents, processApiResponse, processApiError } from "../helpers";
import { API_URL } from "../constants/environment";
import { adalApiFetch } from "../adalConfig";

function loadGroupsSuccess(groups) {
  return {
    type: types.LOAD_GROUPS_SUCCESS,
    groups
  };
}

function createGroupSuccess(response, group, groupId) {
  return {
    type: types.CREATE_GROUP_SUCCESS,
    response,
    group,
    groupId
  };
}

export function loadGroups(filterParams) {
  return dispatch => {
    dispatch(beginAjaxCall("loadGroups"));
    return adalApiFetch(`${API_URL}/Visits/VisitingGroups`, {
      method: "POST",
      body: JSON.stringify(filterParams)
    })
      .then(json => dispatch(loadGroupsSuccess(json)))
      .catch(error => {
        processApiError(error, dispatch);
      })
      .finally(() => {
        dispatch(ajaxCallDone());
      });
  };
}

export function createGroup(group) {
  return dispatch => {
    dispatch(beginAjaxCall("createGroup"));
    return adalApiFetch(`${API_URL}/Visits/VisitGroup`, {
      method: "POST",
      body: JSON.stringify(group)
    })
      .then(response => processApiResponse(response.orchestrationId))
      .then(orchestrationId => pollApiEvents(orchestrationId, "IVisitGroupCreatedEvent"))
      .then(pollResponse => dispatch(createGroupSuccess(pollResponse, group, pollResponse.VisitGroupPlatformId)))
      .catch(error => {
        processApiError(error, dispatch);
      })
      .finally(() => {
        dispatch(ajaxCallDone());
      });
  };
}
