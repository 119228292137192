import React, { Component } from "react";
import { Button, Header, Modal, Form } from "semantic-ui-react";
import MaskedInput from "react-text-mask";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { DateInput } from "semantic-ui-calendar-react";
import { dateFormat } from "../../../../constants/miscellaneous";
import * as modalActions from "../../../../actions/modalActions";
import * as lookupActions from "../../../../actions/lookupActions";
import * as insuranceActions from "../../../../actions/insuranceActions";
import * as patientActions from "../../../../actions/patientActions";
import { zip, zipMask, dateRegEx } from "../../../../constants/validation";
import { removeSpecialCharacters, updateErrors, requiredFieldsFilled, hasProperty } from "../../../../helpers";
import "./InsuranceModal.css";

const options = {
  gender: [{ text: "Male", value: "Male" }, { text: "Female", value: "Female" }]
};

const inputValidation = {
  required: ["address1", "city", "state", "zip"],
  zip,
  dateOfBirth: dateRegEx
};

export class PatientInsuranceAddressModal extends Component {
  state = {
    insurance: Object.assign(
      {
        address1: "",
        address2: "",
        city: "",
        state: "",
        zip: "",
        relationship: "SEL",
        msp: "",
        zip4: "1234",
        insuredFirstName: this.props.currentPatient.patientFirstName || "",
        insuredLastName: this.props.currentPatient.patientLastName || "",
        gender: this.props.currentPatient.gender || "",
        dateOfBirth: this.props.currentPatient.patientDateOfBirth || ""
      },
      this.props.insurance
    ),
    errors: {},
    copyAddress: {}
  };

  componentDidMount() {
    this.props.actions.loadStates();
    this.props.actions.loadInsuranceRelationships();
    this.props.actions.loadMspOptions();
  }

  getInsuranceType = insuranceType => {
    switch (insuranceType) {
      case "primaryInsurance":
        return "Primary";
      case "secondaryInsurance":
        return "Secondary";
      case "tertiaryInsurance":
        return "Tertiary";
      case "nonBillableInsurance":
        return "Non-Billable";
      default:
        return "";
    }
  };

  handleClose = () => this.props.actions.hideModal();

  handleSave = () => {
    const { insurance } = this.state;
    const { patientId, insuranceType } = this.props;

    if (insuranceType === "primaryInsurance") this.props.actions.updateCurrentPatientInsurance(patientId, insurance);

    this.props.actions.createInsurance(patientId, insurance, insuranceType).then(this.handleClose);
  };

  handleFocus = e => {
    e.target.setAttribute("autocomplete", "nope");
  };

  handleInput = (e, data) => {
    const { name: field, value, required } = data;
    const errors = updateErrors(field, value, required, this.state.errors, inputValidation[field]);
    const insurance = Object.assign({}, this.state.insurance, {
      [field]: value
    });
    this.setState({ insurance, errors });
  };

  handleRelationship = (e, data) => {
    const { value } = data;
    if (value === "SEL") this.resetFieldsToCurrentPatient();
    this.handleInput(null, data);
  };

  resetFieldsToCurrentPatient = () => {
    const { currentPatient } = this.props;
    if (currentPatient) {
      const insurance = Object.assign({}, this.state.insurance, {
        insuredFirstName: currentPatient.patientFirstName,
        insuredLastName: currentPatient.patientLastName,
        dateOfBirth: currentPatient.patientDateOfBirth,
        gender: currentPatient.gender,
        relationship: "SEL"
      });
      this.setState({ insurance });
    }
  };

  handleMaskedInput = e => {
    const {
      type,
      target: { name: field, required, value }
    } = e;
    let errors = Object.assign({}, this.state.errors);
    const insurance = Object.assign({}, this.state.insurance, {
      [field]: value
    });

    if (type !== "focus" && type !== "blur") {
      errors = updateErrors(
        field,
        required ? removeSpecialCharacters(value) : value,
        required,
        errors,
        inputValidation[field]
      );
    }

    this.setState({ insurance, errors });
  };

  handleAddressDropdown = (e, data) => {
    // eslint-disable-next-line no-shadow
    const { options, value } = data;
    const copyAddress = Object.assign({}, this.state.copyAddress, options[value].address);
    this.setState({ copyAddress });
  };

  handleCopyAddress = () => {
    const insurance = Object.assign({}, this.state.insurance, this.state.copyAddress);
    this.setState({ insurance });
  };

  render() {
    const { open, insuranceType, states, addresses, insuranceRelationships, mspOptions, processing } = this.props;
    const { insurance, errors } = this.state;

    const requiredFieldsCheck = requiredFieldsFilled(inputValidation.required, insurance);
    const disableSave = requiredFieldsCheck || Object.keys(errors).length !== 0;

    return (
      <Modal className="patient-insurance-modal" open={open} onClose={this.handleClose} closeIcon size="small">
        <Header content={`${this.getInsuranceType(insuranceType)} Insurance - Patient Address`} />
        <Modal.Content>
          <Form>
            <Form.Group widths="equal">
              <Form.Field>
                <Form.Input
                  label="First Name"
                  name="insuredFirstName"
                  disabled={insurance.relationship === "SEL"}
                  onChange={this.handleInput}
                  value={insurance.insuredFirstName}
                  id="input-insuredFirstName"
                />
              </Form.Field>
              <Form.Field>
                <Form.Input
                  label="Last Name"
                  name="insuredLastName"
                  disabled={insurance.relationship === "SEL"}
                  onChange={this.handleInput}
                  value={insurance.insuredLastName}
                  id="input-insuredLastName"
                />
              </Form.Field>
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Field
                className={
                  hasProperty(errors, "dateOfBirth")
                    ? "error field"
                    : insurance.relationship === "SEL"
                    ? "disabled field"
                    : "field"
                }
              >
                <label>Date of Birth</label>
                <DateInput
                  id="input-dateOfBirth"
                  name="dateOfBirth"
                  placeholder="Date of Birth"
                  value={insurance.dateOfBirth}
                  dateFormat={dateFormat}
                  iconPosition="right"
                  onChange={this.handleInput}
                  disabled={insurance.relationship === "SEL"}
                  hideMobileKeyboard
                  closable
                />
              </Form.Field>
              <Form.Field>
                <Form.Dropdown
                  fluid
                  selection
                  search
                  disabled={insurance.relationship === "SEL"}
                  placeholder="Select..."
                  options={options.gender}
                  label="Gender"
                  name="gender"
                  value={insurance.gender}
                  onChange={this.handleInput}
                  id="input-gender"
                />
              </Form.Field>
              <Form.Field>
                <Form.Dropdown
                  fluid
                  selection
                  search
                  options={insuranceRelationships}
                  placeholder="Select..."
                  label="Relationship"
                  name="relationship"
                  value={insurance.relationship}
                  onChange={this.handleRelationship}
                  id="input-relationship"
                />
              </Form.Field>
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Field>
                <Form.Dropdown
                  fluid
                  selection
                  search
                  options={mspOptions}
                  label="MSP"
                  name="msp"
                  value={insurance.msp}
                  placeholder="Optional"
                  onChange={this.handleInput}
                  id="input-msp"
                />
              </Form.Field>
            </Form.Group>
            <Form.Group>
              <Form.Field width={14}>
                <Form.Dropdown
                  fluid
                  selection
                  name="copyAddress"
                  id="input-copyAddress"
                  label="Copy from Current Address"
                  placeholder={addresses.length ? "Select..." : "No addresses entered"}
                  disabled={!addresses.length}
                  options={addresses}
                  onChange={this.handleAddressDropdown}
                />
              </Form.Field>
              <Button type="button" onClick={this.handleCopyAddress} disabled={!addresses.length}>
                Use
              </Button>
            </Form.Group>
            <Form.Group>
              <Form.Field width={16}>
                <Form.Input
                  required
                  label="Street Address"
                  name="address1"
                  placeholder={errors.address1}
                  value={insurance.address1}
                  onChange={this.handleInput}
                  error={hasProperty(errors, "address1")}
                  id="input-address1"
                />
              </Form.Field>
            </Form.Group>
            <Form.Group>
              <Form.Field width={16}>
                <Form.Input
                  label="Address Line 2"
                  name="address2"
                  placeholder="Optional"
                  value={insurance.address2}
                  onChange={this.handleInput}
                  id="input-address2"
                />
              </Form.Field>
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Field>
                <Form.Input
                  required
                  label="City"
                  name="city"
                  placeholder={errors.city}
                  value={insurance.city}
                  onChange={this.handleInput}
                  error={hasProperty(errors, "city")}
                  id="input-city"
                />
              </Form.Field>
              <Form.Field>
                <Form.Dropdown
                  required
                  fluid
                  selection
                  search
                  upward
                  options={states}
                  label="State"
                  name="state"
                  value={insurance.state}
                  onChange={this.handleInput}
                  placeholder="Select..."
                  error={hasProperty(errors, "state")}
                  id="input-state"
                  onFocus={this.handleFocus}
                />
              </Form.Field>
              <Form.Field className={hasProperty(errors, "zip") ? "error required field" : "required field"}>
                <label htmlFor="input-zip">Zip</label>
                <MaskedInput
                  name="zip"
                  required
                  mask={zipMask}
                  guide={false}
                  value={insurance.zip}
                  id="input-zip"
                  onChange={this.handleMaskedInput}
                />
              </Form.Field>
            </Form.Group>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.handleClose} id="btn-cancel">
            Cancel
          </Button>
          <Button onClick={this.handleSave} color="blue" id="btn-save" disabled={disableSave} loading={processing}>
            Save
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

function addressesFormattedForDropdown(addresses) {
  return addresses.map((address, i) => ({
    value: i,
    text: `${address.address1} ${address.address2} ${address.city}, ${address.state} ${address.zip} `,
    address: {
      address1: address.address1,
      address2: address.address2,
      city: address.city,
      state: address.state,
      zip: address.zip
    },
    key: address.addressId
  }));
}

function mapStateToProps(state, ownProps) {
  const insurance = ownProps.isNew ? ownProps.insurance : state.insurance[ownProps.insuranceType];
  return {
    states: state.lookups.states,
    patientId: state.patient.currentPatient.patientId,
    currentPatient: (({ patientFirstName, patientLastName, patientDateOfBirth, gender }) => ({
      patientFirstName,
      patientLastName,
      patientDateOfBirth,
      gender
    }))(state.demographics),
    addresses: addressesFormattedForDropdown(state.demographics.associatedAddresses),
    insurance,
    insuranceRelationships: state.lookups.insuranceRelationships,
    mspOptions: state.lookups.mspOptions,
    processing: state.ajaxCallsInProgress > 0
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...modalActions,
        ...lookupActions,
        ...insuranceActions,
        ...patientActions
      },
      dispatch
    )
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PatientInsuranceAddressModal);
