const prescriptionStatus = {
  signed: "Signed",
  savedForSignature: "Saved for Signature",
  saved: "Saved",
  blank: "Blank",
  changesUnsaved: "Changes Unsaved",
  new: "New",
  failed: "Failed",
  success: "Success",
  undetermined: "Undetermined",
  queued: "Queued",
  printed: "Printed"
};

export default prescriptionStatus;
