import React, { Component } from "react";
import { Table, Icon } from "semantic-ui-react";
import { connect } from "react-redux";
import shortid from "shortid";
import moment from "moment";
import { Link } from "react-router-dom";

import "./ChartsToSignTable.css";
import { dateWithTimeFormat } from "../../constants/miscellaneous";
import { PatientLevel } from "../../ui";
import { isVisitTypeMedical } from "../../helpers";

export class ChartsToSignTable extends Component {
  componentDidMount() {}
  render() {
    const { charts, visitTypes } = this.props;
    const isMedicalVisit = (visitType) =>
      visitTypes.length > 0 && visitTypes.find((vt) => vt.value === visitType).medical;

    return (
      <Table className="charts-table">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Visit Date/Time</Table.HeaderCell>
            <Table.HeaderCell>MRN</Table.HeaderCell>
            <Table.HeaderCell>Patient</Table.HeaderCell>
            <Table.HeaderCell>Visit Type</Table.HeaderCell>
            <Table.HeaderCell className="text-center">Level</Table.HeaderCell>
            <Table.HeaderCell className="text-center">Summary</Table.HeaderCell>
            <Table.HeaderCell className="text-center">Chart</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {charts.length
            ? charts.map((chart) => (
                <Table.Row key={`rand-${shortid.generate()}`}>
                  <Table.Cell>{moment(chart.visitDateTime).format(dateWithTimeFormat)}</Table.Cell>
                  <Table.Cell>{chart.medicalRecordNumber}</Table.Cell>
                  <Table.Cell>
                    <Link
                      to={`/current-patients/${chart.patientId}/demographics/vid/${chart.visitId}`}
                      style={{ textDecoration: "none" }}
                      key={chart.patientId}
                    >
                      {`${chart.firstName} ${chart.lastName}`}
                    </Link>
                  </Table.Cell>
                  <Table.Cell>{chart.visitTypeName}</Table.Cell>
                  <Table.Cell data-label="Level" className="text-center">
                    {isVisitTypeMedical(chart.visitType) ? (
                      <PatientLevel level={chart.patientLevel ? chart.patientLevel : "orange"} size="sm" />
                    ) : (
                      chart.bhLevel && <PatientLevel level={chart.bhLevel} size="sm" bh />
                    )}
                  </Table.Cell>
                  <Table.Cell className="text-center">
                    <Link to={`/current-patients/${chart.patientId}/visits/vid/${chart.visitId}`}>
                      <Icon className="transparent-button-icon" name="file alternate outline" />
                    </Link>
                  </Table.Cell>
                  <Table.Cell className="text-center">
                    <Link
                      to={`/current-patients/${chart.patientId}/${
                        isMedicalVisit(chart.visitType) ? "hp" : "counseling"
                      }/vid/${chart.visitId}`}
                    >
                      <Icon className="transparent-button-icon" name="clipboard list" color="black" />
                    </Link>
                  </Table.Cell>
                </Table.Row>
              ))
            : null}
        </Table.Body>
      </Table>
    );
  }
}

function mapStateToProps(state) {
  return {
    visitTypes: state.lookups.visitTypes
  };
}

export default connect(mapStateToProps, null)(ChartsToSignTable);
