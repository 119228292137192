import React from "react";
import { Button } from "semantic-ui-react";

const FamilyHistorySliderRow = ({ code, description, handleQuickSearch }) => (
  <div>
    <Button
      icon="plus"
      size="small"
      className="transparent-button-icon"
      onClick={() => handleQuickSearch({ code, condition: description })}
    />
    <span>{description}</span>
  </div>
);

export default FamilyHistorySliderRow;
