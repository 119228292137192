import React, { Component } from "react";
import { Grid, Form, Segment, Button, Message } from "semantic-ui-react";
import MaskedInput from "react-text-mask";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { set, isEmpty } from "lodash";
import * as lookupActions from "../../actions/lookupActions";
import * as providerActions from "../../actions/providerActions";
import * as prescriptionActions from "../../actions/prescriptionActions";
import * as modalActions from "../../actions/modalActions";
import { getLookupText, processApiError } from "../../helpers";
import api from "../../api";
import { phoneMask, zip4Mask, zipMask } from "../../constants/validation";

export class EpcsSetup extends Component {
  state = {
    payload: "",
    epcsEndpoint: "",
    loading: false,
    hasAdminEpcseAccess: false,
    form: {
      firstName: "",
      middleName: "",
      lastName: "",
      street1: "",
      street2: "",
      city: "",
      state: "",
      country: "US",
      zip: "",
      zip4: "",
      phoneNumber: ""
    },
    finalizeForm: {
      authenticationType: "SoftToken",
      otp: ""
    },
    rxPinForm: {
      rxPin: "",
      confirmRxPin: ""
    },
    startExostarPressed: false,
    resumeButtonPressed: false,
    showFinalizeForm: false,
    finalizePressed: false,
    showSetRxPinForm: false,
    setPinPressed: false
  };

  componentDidMount() {
    const { canSeeEpcs, centerId, userId } = this.props;
    const hasPayload = true;

    this.props.actions.loadStates();
    this.props.actions.loadLookup("RxAuthenticationTypes");

    this.setState({ loading: true });
    Promise.all([
      api.userHasEpcsAdminAccess(userId),
      api.fetchProviderEpcsSetup(userId, canSeeEpcs ? centerId : null, hasPayload)
    ])
      .then(([{ hasAdminAccess }, { payload, epcsEndpoint }]) =>
        this.setState({ payload, epcsEndpoint, hasAdminEpcseAccess: hasAdminAccess })
      )
      .catch((error) => processApiError(error))
      .finally(() => this.setState({ loading: false }));

    this.props.actions.queryIdentityProofingStatus();
  }

  componentDidUpdate(prevProps) {
    const { identityProofingStatus } = this.props;
    if (identityProofingStatus.status !== prevProps.identityProofingStatus.status) {
      if (identityProofingStatus.status === "complete" && identityProofingStatus.result === "success") {
        this.props.actions.queryLaunchSessionUrl();
        this.props.actions.fetchIdentityProofingFlags();
      }
    }

    const { isGranted } = this.props.identityProofingFlags;
    if (isGranted && isGranted !== prevProps.identityProofingFlags.isGranted) {
      this.props.actions.fetchRxPinInfo();
    }
  }

  componentWillUnmount() {
    this.props.actions.clearIdentityProofingStatus();
  }

  handleInput = (e, data) => {
    const { name, value } = data;
    const updatedState = { ...this.state };
    set(updatedState, name, value);
    this.setState(updatedState);
  };

  handleMaskedInput = (e) => {
    const {
      target: { name, value }
    } = e;
    const updatedState = { ...this.state };
    set(updatedState, name, value);
    this.setState(updatedState);
  };

  handleStartExostar = () => {
    this.setState({ startExostarPressed: true });
    const { zip, zip4, phoneNumber } = this.state.form;
    this.props.actions.orderLicenseAndStartProofing({
      ...this.state.form,
      zip: `${zip}-${zip4}`,
      phoneNumber: phoneNumber.replace(/\D+/g, "") // unmask
    });
  };

  handleResume = () => {
    this.setState({ resumeButtonPressed: true });
    this.props.actions.resumeIdentityProofing();
  };

  handleCancel = () => {
    this.props.actions.showModal({
      type: "CONFIRMATION",
      props: {
        open: true,
        icon: "exclamation-triangle",
        iconColor: "danger",
        title: "Confirm Cancelling",
        buttonColor: "red",
        description:
          "You may only begin the EPCS registration 3 times. After the 3rd attempt, your registration will be put on hold until you receive a letter from Experian with a code in the mail.\n\nBy clicking 'Proceed', this will cancel any registrations you currently have in progress and any information previously submitted will be lost. Or click 'exit' to keep any existing registrations active.",
        cancelButtonMessage: "Exit",
        buttonMessage: "Proceed",
        onConfirm: () => {
          this.setState({ loading: true });
          this.props.actions.cancelIdentityProofing().then(() => {
            this.setState({ loading: false });
          });
        }
      }
    });
  };

  handleSetRxPin = () => {
    this.setState({ setPinPressed: true });
    this.props.actions
      .setRxPin(this.state.rxPinForm)
      .then(() => {
        this.props.actions.fetchRxPinInfo();
      })
      .catch(() => {
        this.setState({ rxPinForm: { rxPin: "", confirmRxPin: "" }, setPinPressed: false });
      });
  };

  handleFinalizeIdentityProofing = () => {
    this.setState({ finalizePressed: true });
    this.props.actions
      .finalizeIdentityProofing(this.state.finalizeForm)
      .then(() => {
        this.props.actions.fetchIdentityProofingFlags();
      })
      .catch(() => {
        this.setState({ finalizeForm: { otp: "" }, finalizePressed: false });
      });
  };

  render() {
    const {
      stateOptions,
      identityProofingStatus,
      selfAdminSession,
      exostarIdentityProofingStatuses,
      exostarIdentityProofingFailReasons,
      identityProofingFlags,
      rxAuthenticationTypes,
      rxPinInfo
    } = this.props;
    const {
      loading,
      form,
      finalizeForm,
      rxPinForm,
      startExostarPressed,
      resumeButtonPressed,
      showFinalizeForm,
      showSetRxPinForm,
      finalizePressed,
      setPinPressed
    } = this.state;
    const { firstName, lastName, street1, city, state, country, zip, zip4, phoneNumber } = form;
    let identityProofingStatusStatus = getLookupText(
      identityProofingStatus.status,
      exostarIdentityProofingStatuses,
      true
    );
    identityProofingStatusStatus = identityProofingStatusStatus.replace("_", " ");
    identityProofingStatusStatus =
      identityProofingStatusStatus.length > 0
        ? identityProofingStatusStatus[0].toUpperCase() + identityProofingStatusStatus.slice(1)
        : "";
    const disableStartExostar =
      isEmpty(firstName) ||
      isEmpty(lastName) ||
      isEmpty(street1) ||
      isEmpty(city) ||
      isEmpty(state) ||
      isEmpty(country) ||
      isEmpty(zip) ||
      isEmpty(zip4) ||
      isEmpty(phoneNumber) ||
      zip.length !== 5 ||
      zip4.length !== 4 ||
      phoneNumber.length !== 14;

    const { isGranted, isFinalized } = identityProofingFlags;
    const { hasRxPin } = rxPinInfo;

    const disableFinalizeButton = finalizeForm.otp.length !== 6;
    const disableSetPinButton = rxPinForm.rxPin.length !== 4 || rxPinForm.rxPin !== rxPinForm.confirmRxPin;

    return (
      <Segment>
        <Grid>
          <Grid.Row className="half-padding">
            <Grid.Column width={4} />
            <Grid.Column width={8} textAlign="center">
              <h3>Exostar</h3>

              {isEmpty(identityProofingStatus) && <div style={{ textAlign: "center" }}>loading...</div>}
              {!isEmpty(identityProofingStatus) && identityProofingStatus.error !== true && (
                <Message
                  info={identityProofingStatus.result !== "fail"}
                  error={identityProofingStatus.result === "fail"}
                >
                  {identityProofingStatusStatus}
                  {!isEmpty(identityProofingStatus.result) ? (
                    <div>{`Result: ${identityProofingStatus.result}`}</div>
                  ) : (
                    ``
                  )}
                  {!isEmpty(identityProofingStatus.failReason) ? (
                    <div>{`Reason: ${getLookupText(
                      identityProofingStatus.failReason,
                      exostarIdentityProofingFailReasons,
                      true
                    )}`}</div>
                  ) : (
                    ``
                  )}
                  {identityProofingStatus.status === "wtg_proofer" && !isEmpty(identityProofingStatus.uiLink) && (
                    <React.Fragment>
                      <span>, </span>
                      <a
                        target="_blank"
                        href={identityProofingStatus.uiLink}
                        style={{ display: "inline-block" }}
                      >{`Schedule ID proofing meeting`}</a>
                    </React.Fragment>
                  )}

                  {identityProofingStatus.status === "wtg_resume" && (
                    <React.Fragment>
                      <br />
                      <Button
                        color="blue"
                        size="mini"
                        content="Resume"
                        onClick={this.handleResume}
                        disabled={resumeButtonPressed}
                        loading={resumeButtonPressed}
                      />
                    </React.Fragment>
                  )}
                  {identityProofingStatus.status === "complete" &&
                    identityProofingStatus.result === "success" &&
                    !isEmpty(selfAdminSession) &&
                    !isEmpty(selfAdminSession.selfAdminUrl) && (
                      <a target="_blank" href={selfAdminSession.selfAdminUrl}>
                        Manage your Exostar profile
                      </a>
                    )}
                  {["active", "wtg_proofer", "wtg_subscription"].includes(identityProofingStatus.status) && (
                    <React.Fragment>
                      <br />
                      <Button
                        content="Cancel"
                        color="red"
                        size="mini"
                        disabled={loading}
                        loading={loading}
                        onClick={this.handleCancel}
                      />
                    </React.Fragment>
                  )}
                  {!isEmpty(identityProofingStatus) && isGranted && (
                    <div className="half-padding" style={{ borderTop: "1px solid #a9d5de" }}>
                      {isFinalized && <span>Identity Proofing is Finalized</span>}
                      {!isFinalized && !showFinalizeForm && (
                        <Button
                          size="mini"
                          color="green"
                          content="Finalize EPCS Registration"
                          onClick={() => {
                            this.setState({ showFinalizeForm: true });
                          }}
                        />
                      )}
                      {!isFinalized && showFinalizeForm && (
                        <Form style={{ textAlign: "left" }} className="compact">
                          <Form.Group widths="equal">
                            <Form.Field className="required field" style={{ textAlign: "right", paddingTop: "0.25em" }}>
                              <label style={{ display: "inline" }}>Device</label>{" "}
                            </Form.Field>
                            <Form.Field className="required field">
                              <Form.Dropdown
                                name="finalizeForm.authenticationType"
                                selection
                                placeholder="Select..."
                                options={rxAuthenticationTypes}
                                onChange={this.handleInput}
                                value={finalizeForm.authenticationType}
                                disabled={finalizePressed}
                                compact
                              />
                            </Form.Field>
                            <Form.Field
                              className={`required field ${disableFinalizeButton ? `error` : ``}`}
                              style={{ textAlign: "right", paddingTop: "0.25em" }}
                            >
                              <label style={{ display: "inline" }}>6 digits passcode</label>{" "}
                            </Form.Field>
                            <Form.Field className={`required field ${disableFinalizeButton ? `error` : ``}`}>
                              <MaskedInput
                                name="finalizeForm.otp"
                                mask={[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
                                guide={false}
                                value={finalizeForm.otp}
                                onChange={this.handleMaskedInput}
                                disabled={finalizePressed}
                              />
                            </Form.Field>
                            <Form.Field>
                              <Button
                                size="mini"
                                color="green"
                                content="Finalize"
                                onClick={this.handleFinalizeIdentityProofing}
                                loading={finalizePressed}
                                disabled={disableFinalizeButton || finalizePressed}
                              />
                            </Form.Field>
                          </Form.Group>
                        </Form>
                      )}
                    </div>
                  )}
                  {!isEmpty(identityProofingStatus) && isGranted && (
                    <div className="half-padding" style={{ borderTop: "1px solid #a9d5de" }}>
                      {hasRxPin && <span>Your PIN has been set</span>}
                      {!hasRxPin && !showSetRxPinForm && (
                        <Button
                          size="mini"
                          color="green"
                          content="Set your PIN"
                          onClick={() => {
                            this.setState({ showSetRxPinForm: true });
                          }}
                        />
                      )}
                      {!hasRxPin && showSetRxPinForm && (
                        <Form style={{ textAlign: "left" }} className="compact">
                          <Form.Group widths="equal">
                            <Form.Field
                              className={`required field ${rxPinForm.rxPin.length !== 4 ? `error` : ``}`}
                              style={{ textAlign: "right", paddingTop: "0.25em" }}
                            >
                              <label style={{ display: "inline" }}>PIN</label>{" "}
                            </Form.Field>
                            <Form.Field className={`required field ${rxPinForm.rxPin.length !== 4 ? `error` : ``}`}>
                              <MaskedInput
                                name="rxPinForm.rxPin"
                                mask={[/\d/, /\d/, /\d/, /\d/]}
                                guide={false}
                                value={rxPinForm.rxPin}
                                onChange={this.handleMaskedInput}
                                disabled={setPinPressed}
                              />
                            </Form.Field>
                            <Form.Field
                              className={`required field ${disableSetPinButton ? `error` : ``}`}
                              style={{ textAlign: "right", paddingTop: "0.25em" }}
                            >
                              <label style={{ display: "inline" }}>Confirm PIN</label>{" "}
                            </Form.Field>
                            <Form.Field className={`required field ${disableSetPinButton ? `error` : ``}`}>
                              <MaskedInput
                                name="rxPinForm.confirmRxPin"
                                mask={[/\d/, /\d/, /\d/, /\d/]}
                                guide={false}
                                value={rxPinForm.confirmRxPin}
                                onChange={this.handleMaskedInput}
                                disabled={setPinPressed}
                              />
                            </Form.Field>
                            <Form.Field>
                              <Button
                                size="mini"
                                color="green"
                                content="Set PIN"
                                onClick={this.handleSetRxPin}
                                loading={setPinPressed}
                                disabled={disableSetPinButton || setPinPressed}
                              />
                            </Form.Field>
                          </Form.Group>
                        </Form>
                      )}
                    </div>
                  )}
                </Message>
              )}
              {!isEmpty(identityProofingStatus) &&
                (["nonexistent", "unknown_user", "DEAD"].includes(identityProofingStatus.status) ||
                  (identityProofingStatus.status === "complete" && identityProofingStatus.result === "fail") ||
                  identityProofingStatus.error === true) && (
                  <React.Fragment>
                    <Form style={{ textAlign: "left" }} className="compact">
                      <Form.Group widths="equal">
                        <Form.Field>
                          <Form.Input
                            label="First Name"
                            name="form.firstName"
                            value={form.firstName}
                            onChange={this.handleInput}
                            required
                          />
                        </Form.Field>
                        <Form.Field>
                          <Form.Input
                            label="Middle Name"
                            name="form.middleName"
                            value={form.middleName}
                            onChange={this.handleInput}
                          />
                        </Form.Field>
                        <Form.Field>
                          <Form.Input
                            label="Last Name"
                            name="form.lastName"
                            value={form.lastName}
                            onChange={this.handleInput}
                            required
                          />
                        </Form.Field>
                      </Form.Group>
                      <Form.Group widths="equal">
                        <Form.Field>
                          <Form.Input
                            label="Address 1"
                            name="form.street1"
                            value={form.street1}
                            onChange={this.handleInput}
                            required
                          />
                        </Form.Field>
                        <Form.Field>
                          <Form.Input
                            label="Address 2"
                            name="form.street2"
                            value={form.street2}
                            onChange={this.handleInput}
                          />
                        </Form.Field>
                      </Form.Group>
                      <Form.Group widths="equal">
                        <Form.Field>
                          <Form.Input
                            label="City"
                            name="form.city"
                            value={form.city}
                            onChange={this.handleInput}
                            required
                          />
                        </Form.Field>
                        <Form.Field>
                          <Form.Dropdown
                            fluid
                            selection
                            search
                            options={stateOptions}
                            id="input-state"
                            placeholder="Select..."
                            label="State"
                            name="form.state"
                            value={form.state}
                            onChange={this.handleInput}
                            required
                          />
                        </Form.Field>
                        <Form.Field>
                          <Form.Input
                            label="Country"
                            name="form.country"
                            value={form.country}
                            onChange={this.handleInput}
                            disabled
                            required
                          />
                        </Form.Field>
                      </Form.Group>
                      <Form.Group widths="equal">
                        <Form.Field className="required field">
                          <label>Zip</label>
                          <MaskedInput
                            name="form.zip"
                            mask={zipMask}
                            guide={false}
                            value={form.zip}
                            onChange={this.handleMaskedInput}
                          />
                        </Form.Field>
                        <Form.Field className="required field">
                          <label>Zip4</label>
                          <MaskedInput
                            name="form.zip4"
                            mask={zip4Mask}
                            guide={false}
                            value={form.zip4}
                            onChange={this.handleMaskedInput}
                          />
                        </Form.Field>
                        <Form.Field className="required field">
                          <label>Phone</label>
                          <MaskedInput
                            name="form.phoneNumber"
                            mask={phoneMask}
                            value={form.phoneNumber}
                            onChange={this.handleMaskedInput}
                          />
                        </Form.Field>
                      </Form.Group>
                    </Form>
                    <Grid>
                      <Grid.Row textAlign="right">
                        <Grid.Column>
                          <Button
                            color="blue"
                            size="mini"
                            onClick={this.handleStartExostar}
                            content="Start"
                            disabled={
                              identityProofingStatus.error !== true && (disableStartExostar || startExostarPressed)
                            }
                            loading={identityProofingStatus.error !== true && startExostarPressed}
                          />
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </React.Fragment>
                )}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    );
  }
}

function mapStateToProps(state) {
  return {
    identityProofingStatus: state.providers.identityProofingStatus,
    selfAdminSession: state.providers.exostarSelfAdminSession,
    stateOptions: state.lookups.states,
    exostarIdentityProofingStatuses: state.lookups.exostarIdentityProofingStatuses,
    exostarIdentityProofingFailReasons: state.lookups.exostarIdentityProofingFailReasons,
    identityProofingFlags: state.providers.identityProofingFlags,
    rxAuthenticationTypes: state.lookups.rxAuthenticationTypes.filter(
      (i) => ["SoftToken", "HardToken"].includes(i.value) // Sara wants only authy and token options
    ),
    rxPinInfo: state.prescriptions.rxPinInfo
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...modalActions,
        ...lookupActions,
        ...providerActions,
        ...prescriptionActions
      },
      dispatch
    )
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EpcsSetup);
