import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function noteReducer(state = initialState.notifications, action) {
  switch (action.type) {
    case types.GET_NOTIFICATIONS_DONE:
      return action.response;
    case types.DISMISS_ALL_NOTIFICATIONS_DONE: {
      return { totalCount: 0, notificationType: "inbox", notifications: [] };
    }
    case types.DISMISS_NOTIFICATION_DONE: {
      const { totalCount, notifications, ...rest } = state;
      const notification = notifications.find(n => n.id === action.notificationId);
      if (notification)
        return {
          totalCount: totalCount - 1,
          notifications: notifications.filter(n => n.id !== action.notificationId),
          ...rest
        };
      return state;
    }
    default:
      return state;
  }
}
