import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function miscReducer(state = initialState.misc, action) {
  switch (action.type) {
    case types.SET_NARX_SCORES: {
      return { ...state, narxScores: action.narxScores };
    }
    case types.CLEAR_NARX_SCORES:
      return {
        ...state,
        narxScores: {
          scores: [
            { key: "Narcotics", value: "000" },
            { key: "Stimulants", value: "000" },
            { key: "Sedatives", value: "000" },
            { key: "Overdose", value: "000" }
          ],
          reportUrl: ""
        }
      };
    default:
      return state;
  }
}
