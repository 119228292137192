import "react-toastify/dist/ReactToastify.css";
import * as types from "./actionTypes";
import { API_URL } from "../constants/environment";
import { pollApiEvents, processApiResponse, processApiError, processThenThrowApiError } from "../helpers";
import { beginAjaxCall, ajaxCallDone } from "./ajaxStatusActions";
import { adalApiFetch } from "../adalConfig";
import { fetchCenter } from "../api/center";
import { clearLookupCache, loadCentersLookup } from "./lookupActions";

function loadCentersSuccess(centers) {
  return { type: types.LOAD_CENTERS_SUCCESS, centers };
}

function createCenterSuccess(center, centerId) {
  return { type: types.CREATE_CENTER_SUCCESS, center, centerId };
}

function loadCenterDetailsSuccess(details) {
  return { type: types.LOAD_CENTER_DETAILS_SUCCESS, details };
}

function updateCenterDetailsSuccess(centerId, center) {
  return { type: types.UPDATE_CENTER_DETAILS_SUCCESS, centerId, center };
}

export function setForceUpdateCenterDetails(value) {
  return { type: types.SET_FORCE_UPDATE_CENTER_DETAILS, value };
}

export function loadCenters() {
  return dispatch => {
    dispatch(beginAjaxCall("loadCenters"));
    return adalApiFetch(`${API_URL}/Centers/Search`, {
      method: "POST",
      body: JSON.stringify({})
    })
      .then(json => dispatch(loadCentersSuccess(json)))
      .catch(error => {
        processApiError(error, dispatch);
      })
      .finally(() => {
        dispatch(ajaxCallDone());
      });
  };
}

export function updateCenterSort(sortedCenters) {
  return {
    type: types.UPDATE_CENTER_SORT,
    sortedCenters
  };
}

export function createCenter(center) {
  return dispatch => {
    dispatch(beginAjaxCall("createCenter"));
    return adalApiFetch(`${API_URL}/Centers`, {
      method: "POST",
      body: JSON.stringify(center)
    })
      .then(response => processApiResponse(response.orchestrationId))
      .then(orchestrationId => pollApiEvents(orchestrationId, "ICenterCreatedEvent"))
      .then(pollResponse => dispatch(createCenterSuccess(center, pollResponse.PlatformId)))
      .then(() => dispatch(clearLookupCache("lookups.centers")))
      .then(() => dispatch(loadCentersLookup()))
      .catch(error => processApiError(error, dispatch))
      .finally(() => dispatch(ajaxCallDone()));
  };
}

export function loadCenterDetails(centerId) {
  return dispatch => {
    dispatch(beginAjaxCall("loadCenterDetails"));
    dispatch(setForceUpdateCenterDetails(false));
    return fetchCenter(centerId)
      .then(details =>
        dispatch(
          loadCenterDetailsSuccess({
            ...details,
            address: {
              address1: details.address1,
              address2: details.address2,
              city: details.city,
              state: details.state,
              zip: details.zip,
              zip4: details.zip4
            }
          })
        )
      )
      .catch(error => {
        processApiError(error, dispatch);
      })
      .finally(() => {
        dispatch(ajaxCallDone());
      });
  };
}

export function updateCenterDetails(center, centerId) {
  return dispatch => {
    dispatch(beginAjaxCall("updateCenterDetails"));
    return adalApiFetch(`${API_URL}/Centers/${centerId}/Details`, {
      method: "POST",
      body: JSON.stringify(center)
    })
      .then(response => processApiResponse(response.orchestrationId))
      .then(orchestrationId => pollApiEvents(orchestrationId, "ICenterDetailsUpdatedEvent"))
      .then(() => dispatch(updateCenterDetailsSuccess(centerId, center)))
      .then(() => dispatch(clearLookupCache("lookups.centers")))
      .then(() => dispatch(loadCentersLookup()))
      .catch(error => processApiError(error, dispatch))
      .finally(() => dispatch(ajaxCallDone()));
  };
}

export function clearCenterAddressHistory() {
  return {
    type: types.CLEAR_CENTER_ADDRESS_HISTORY
  };
}

export function clearLocationAndDirectorHistory() {
  return {
    type: types.CLEAR_LOCATION_AND_DIRECTOR_HISTORY
  };
}

export function fetchCenterAddressHistoryDone(centerAddressHistory) {
  return {
    type: types.FETCH_CENTER_ADDRESS_HISTORY_DONE,
    centerAddressHistory
  };
}

export function fetchLocationAndDirectorHistoryDone(locationAndDirectorHistory) {
  return {
    type: types.FETCH_LOCATION_AND_DIRECTOR_HISTORY_DONE,
    locationAndDirectorHistory
  };
}

export function fetchCenterAddressHistory(centerId) {
  return dispatch => {
    dispatch(beginAjaxCall("fetchCenterAddressHistory"));
    return adalApiFetch(`${API_URL}/Centers/${centerId}/CenterAddressHistory`)
      .then(response => dispatch(fetchCenterAddressHistoryDone(response)))
      .catch(error => {
        processApiError(error, dispatch);
      })
      .finally(() => {
        dispatch(ajaxCallDone());
      });
  };
}

export function saveCenterAddressHistory(address) {
  return dispatch => {
    dispatch(beginAjaxCall("saveCenterAddressHistory"));
    return adalApiFetch(`${API_URL}/Centers/CenterAddress`, {
      method: "POST",
      body: JSON.stringify(address)
    })
      .then(response => processApiResponse(response.orchestrationId))
      .then(orchestrationId => pollApiEvents(orchestrationId, "ICenterAddressSetEvent"))
      .then(() => dispatch(fetchCenterAddressHistory(address.centerId)))
      .catch(error => processThenThrowApiError(error, dispatch))
      .finally(() => dispatch(ajaxCallDone()));
  };
}

export function fetchLocationAndDirectorHistory(centerId) {
  return dispatch => {
    dispatch(beginAjaxCall("fetchLocationAndDirectorHistory"));
    return adalApiFetch(`${API_URL}/Centers/${centerId}/LocationAndDirectorHistory`)
      .then(response => dispatch(fetchLocationAndDirectorHistoryDone(response)))
      .catch(error => {
        processApiError(error, dispatch);
      })
      .finally(() => {
        dispatch(ajaxCallDone());
      });
  };
}

export function saveLocationAndDirector(entity) {
  return dispatch => {
    dispatch(beginAjaxCall("saveLocationAndDirector"));
    return adalApiFetch(`${API_URL}/Centers/LocationAndDirector`, {
      method: "POST",
      body: JSON.stringify(entity)
    })
      .then(response => processApiResponse(response.orchestrationId))
      .then(orchestrationId => pollApiEvents(orchestrationId, "ICenterLocationDirectorSetEvent"))
      .then(() => dispatch(fetchLocationAndDirectorHistory(entity.centerId)))
      .catch(error => processThenThrowApiError(error, dispatch))
      .finally(() => dispatch(ajaxCallDone()));
  };
}
