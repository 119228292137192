import moment from "moment";
import React, { Component } from "react";
import { Table, Grid, Icon, Divider } from "semantic-ui-react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import momenttz from "moment-timezone";
import { isEmpty } from "lodash";
import NoteSlashTaskCompactView from "../../common/tasks/NoteSlashTaskCompactView";
import * as modalActions from "../../../actions/modalActions";
import * as lookupActions from "../../../actions/lookupActions";
import * as visitActions from "../../../actions/visitActions";
import * as userActions from "../../../actions/userActions";
import * as noteSlashTasksActions from "../../../actions/noteSlashTasksActions";
import { dateFormat, dateWithTimeFormat } from "../../../constants/miscellaneous";
import { checkRoles, getLookupText } from "../../../helpers/index";
import { roleGroups } from "../../../constants/securityRoles";
import emrComponent from "../../common/emrComponent";

export class NoteSlashTaskRow extends Component {
  state = {
    detailedView: false,
    roleUserOptions: {}
  };

  componentDidMount() {
    this.props.actions.loadTaskRoles();
  }

  componentDidUpdate(prevProps, prevState) {
    const entity = this.props.detailedEntity;
    const roleUserOptions = { ...this.state.roleUserOptions };
    if (
      this.state.detailedView &&
      !prevState.detailedView &&
      entity &&
      entity.assignee &&
      entity.assignee.assignedRole &&
      isEmpty(roleUserOptions[entity.assignee.assignedRole])
    ) {
      this.props.actions.loadUsersWithRoles([entity.assignee.assignedRole]).then(({ list }) => {
        roleUserOptions[entity.assignee.assignedRole] = list.map((u) => ({
          text: `${u.firstName} ${u.lastName}`,
          value: u.userId
        }));
        this.setState({ roleUserOptions });
      });
    }
  }

  toggleDetailedView = () => {
    this.setState({ detailedView: !this.state.detailedView }, () => {
      if (this.state.detailedView)
        this.props.actions.fetchTimelineItemDetails(this.props.entity.patientId, "TASK", this.props.entity.platformId);
    });
  };

  handleViewEntity = () => {
    const { entity } = this.props;
    this.props.actions.showModal({
      type: "NOTE_SLASH_TASK_MODAL",
      props: {
        open: true,
        entityId: entity.platformId,
        type: entity.type.toLowerCase(),
        afterSave: () => {
          this.props.actions.setForceReloadPatientTimelineHistory(entity.patientId, true);
        }
      }
    });
  };

  handleEditEntity = () => {
    const { entity } = this.props;
    this.props.actions.showModal({
      type: "NOTE_SLASH_TASK_MODAL",
      props: {
        open: true,
        entityId: entity.platformId,
        type: entity.type.toLowerCase(),
        afterSave: () => {
          this.props.actions.setForceReloadPatientTimelineHistory(entity.patientId, true);
        }
      }
    });
  };

  handleDeleteEntity = () => {
    const { entity } = this.props;
    this.props.actions.showModal({
      type: "NOTE_SLASH_TASK_DELETE_REASON_MODAL",
      props: {
        open: true,
        entity,
        afterDelete: () => this.props.actions.setForceReloadPatientTimelineHistory(entity.patientId, true)
      }
    });
  };

  handleCompleteTask = () => {
    const { entity } = this.props;
    this.props.actions.showModal({
      type: "CONFIRMATION",
      props: {
        open: true,
        icon: "check",
        title: `Confirm Complete ${entity.type}`,
        description: `Are you sure you want to complete and close ${entity.type.toLowerCase()}?`,
        buttonMessage: "Complete",
        buttonColor: "green",
        size: "mini",
        onConfirm: () =>
          this.props.actions
            .completeNoteSlashTask(entity.patientId, entity.platformId)
            .then(() => this.props.actions.setForceReloadPatientTimelineHistory(entity.patientId, true))
      }
    });
  };

  handleRestoreEntity = () => {
    const { entity } = this.props;
    this.props.actions.showModal({
      type: "CONFIRMATION",
      props: {
        open: true,
        icon: "exclamation-triangle",
        title: `Confirm Restore ${entity.type}`,
        description: `Are you sure you want to restore this ${entity.type.toLowerCase()}?`,
        buttonMessage: "Restore",
        buttonColor: "green",
        size: "mini",
        onConfirm: () =>
          this.props.actions
            .restoreNoteSlashTask(entity.patientId, entity.platformId)
            .then(() => this.props.actions.setForceReloadPatientTimelineHistory(entity.patientId, true))
      }
    });
  };

  render() {
    const { detailedView, roleUserOptions } = this.state;
    const { entity, detailedEntity, currentUserId, currentUserRoles, centerOptions, roleOptions } = this.props;
    const canEdit = checkRoles(roleGroups.canEditNoteSlashTask, currentUserRoles);
    // const canComplete = ???;
    const canDelete = checkRoles(roleGroups.canDeleteNoteSlashTask, currentUserRoles);
    const canUndoDelete = checkRoles(roleGroups.canRestoreNoteSlashTask, currentUserRoles);

    return (
      <React.Fragment>
        {entity && (
          <Table.Row
            key={entity.platformId}
            className={`clickable timeline-row ${
              entity.isDeleted ? `deleted` : ``
            } timeline-${entity.type.toLowerCase()}-row`}
            onClick={() => this.toggleDetailedView(entity.platformId)}
          >
            <Table.Cell colSpan={9} className="no-padding">
              <div
                style={{
                  padding: `1em`,
                  margin: `5px`
                  // border: `3px solid ${entity.type === "Task" ? `#537d85` : `#8d8d43`}`,
                  // borderRadius: "5px"
                }}
              >
                <Grid>
                  <Grid.Row className="half-v-padding">
                    <Grid.Column style={{ width: "30px" }}>
                      <Icon name={entity.type === "Note" ? "sticky note" : "tasks"} />
                    </Grid.Column>
                    <Grid.Column style={{ width: "200px" }}>
                      {moment(entity.createdDate).format(dateWithTimeFormat)}
                      {` `}
                      <small>[{momenttz.tz(momenttz.tz.guess()).zoneAbbr()}]</small>
                    </Grid.Column>
                    <Grid.Column width={5}>
                      <div style={{ display: "inline-block", width: "40px", textAlign: "center" }}>
                        {entity.type === "Task" && entity.isUrgent ? (
                          <Icon size="large" name="exclamation circle" color="red" />
                        ) : null}
                      </div>
                      <b>{entity.type}: </b>
                      {entity.subject}
                    </Grid.Column>
                    <Grid.Column width={3}>
                      {entity.type === "Task" && (
                        <React.Fragment>
                          <b>Assigned to: </b> {getLookupText(entity.assignee.assignedRole, roleOptions)}
                        </React.Fragment>
                      )}
                    </Grid.Column>
                    <Grid.Column width={3}>
                      {entity.type === "Task" && !isEmpty(entity.completeAt) && (
                        <React.Fragment>
                          <b>Completed at: </b> {moment(entity.completeAt).format(dateFormat)}
                        </React.Fragment>
                      )}
                      {entity.type === "Task" && isEmpty(entity.completeAt) && (
                        <p className={entity.isDeleted ? "" : "p-red"}>
                          <b>Not Completed</b>
                        </p>
                      )}
                    </Grid.Column>
                    <Grid.Column className="actions-column" style={{ flex: "1", textAlign: "right" }}>
                      {(entity.isDeleted || !canEdit) && (
                        <Icon
                          title="View"
                          name="sticky note"
                          color="grey"
                          onClick={(e) => {
                            e.stopPropagation();
                            this.handleViewEntity(entity.platformId);
                          }}
                          style={{ margin: "2px", fontSize: "1.1em" }}
                        />
                      )}
                      {!entity.isDeleted && canEdit && (
                        <Icon
                          title="Edit"
                          name="edit"
                          color="blue"
                          onClick={(e) => {
                            e.stopPropagation();
                            this.handleEditEntity(entity.platformId);
                          }}
                          style={{ margin: "2px", fontSize: "1.1em" }}
                        />
                      )}
                      {!entity.isDeleted &&
                        entity.type === "Task" &&
                        isEmpty(entity.completeAt) &&
                        (entity.assignee.assignedUser === currentUserId ||
                          currentUserRoles.includes(entity.assignee.assignedRole)) && (
                          <Icon
                            title="Complete"
                            name="check"
                            color="green"
                            onClick={(e) => {
                              e.stopPropagation();
                              this.handleCompleteTask(entity.platformId);
                            }}
                            style={{ margin: "2px", fontSize: "1.1em" }}
                          />
                        )}
                      {!entity.isDeleted && canDelete && isEmpty(entity.completeAt) && (
                        <Icon
                          title="Delete"
                          name="delete"
                          color="red"
                          onClick={(e) => {
                            e.stopPropagation();
                            this.handleDeleteEntity(entity.platformId);
                          }}
                          style={{ margin: "2px", fontSize: "1.1em" }}
                        />
                      )}
                      {entity.isDeleted && canUndoDelete && (
                        <Icon
                          title="Restore"
                          name="undo"
                          color="green"
                          onClick={(e) => {
                            e.stopPropagation();
                            this.handleRestoreEntity(entity.platformId);
                          }}
                          style={{ margin: "2px", fontSize: "1.1em" }}
                        />
                      )}
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
                {detailedView && detailedEntity && (
                  <React.Fragment>
                    <Divider className="divider-notes" />
                    <NoteSlashTaskCompactView
                      entity={detailedEntity}
                      roleUserOptions={roleUserOptions}
                      centerOptions={centerOptions}
                      roleOptions={roleOptions}
                    />
                  </React.Fragment>
                )}
              </div>
            </Table.Cell>
          </Table.Row>
        )}
      </React.Fragment>
    );
  }
}

function mapStateToProps(state, props) {
  return {
    currentUserId: state.userPreference.currentUserId,
    currentUserRoles: state.auth.user.profile.roles,
    detailedEntity: state.visits.detailedTimeline[`TASK_${props.entity.platformId}`],
    centerOptions: state.lookups.centers.map((i) => ({ text: i.text, value: i.value })),
    roleOptions: state.lookups.taskRoles.map((i) => ({ text: i.displayName, value: i.name }))
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...modalActions,
        ...lookupActions,
        ...visitActions,
        ...userActions,
        ...noteSlashTasksActions
      },
      dispatch
    )
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(emrComponent(NoteSlashTaskRow));
