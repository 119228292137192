import React, { Component } from "react";
import { Button, Header, Modal, Input, List } from "semantic-ui-react";
import map from "lodash/map";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as modalActions from "../../../../actions/modalActions";
import * as searchActions from "../../../../actions/searchActions";

import "../../../reusable/Modal.css";
import "../PatientHistorySearchModal.css";

const getInitialSearch = (props) => props.searchValue || "";

const getSearchError = (props) =>
  (props.searchValue || []).length >= 3 ? "" : "Please type more than 3 characters to search.";

export class SearchMedicationModal extends Component {
  state = {
    searchMedication: getInitialSearch(this.props),
    searchedMedication: getInitialSearch(this.props),
    selectedMedication: {},
    searchError: getSearchError(this.props)
  };

  componentDidMount() {
    const { searchValue } = this.props;
    if ((searchValue || []).length >= 3) {
      this.props.actions.searchMedication({ drugName: searchValue });
    }
  }

  componentWillUnmount() {
    this.props.actions.clearSearch("medications");
  }

  handleSearch = () => {
    const { searchMedication } = this.state;
    this.props.actions.clearSearch("medications");
    if (searchMedication.length >= 3) {
      this.props.actions.searchMedication({ drugName: searchMedication });
      this.setState({
        searchError: ""
      });
    } else {
      this.setState({
        searchError: "Please type more than 3 characters to search."
      });
    }
  };

  handleResultClick = (e, data) => {
    const { index } = data;
    const { medications } = this.props;
    const selectedMedication = medications[index];

    this.setState({ selectedMedication });
  };

  handleSearchInput = (e, data) => {
    this.setState({ searchMedication: data.value, selectedMedication: {} });
  };

  handleSearchEnter = (e) => {
    if (e.key === "Enter") {
      const { searchMedication } = this.state;

      this.setState({ searchedMedication: searchMedication, selectedMedication: "" }, () => this.handleSearch());
    }
  };

  handleSearchClick = () => {
    const { searchMedication } = this.state;
    this.setState({ searchedMedication: searchMedication, selectedMedication: "" }, () => this.handleSearch());
  };

  handleClose = () => {
    this.props.actions.hideModal();
    this.props.actions.clearSearch("medications");
  };

  handleModalNav = () => {
    const { searchMedication, selectedMedication } = this.state;
    return this.props.actions.showModal({
      type: "ADD_MEDICATION",
      props: {
        open: true,
        drugName: selectedMedication.drugName || searchMedication,
        drugKey: selectedMedication.drugKey || "",
        strength: selectedMedication.strength
      }
    });
  };

  render() {
    const { open, medications, processing } = this.props;
    const { searchMedication, selectedMedication, searchedMedication, searchError } = this.state;
    const disableButton = !searchMedication && !selectedMedication.drugKey;

    const items = [];

    map(medications, (medication, i) =>
      items.push(
        <List.Item
          key={medication.drugKey}
          index={i}
          active={selectedMedication.drugKey === medication.drugKey}
          onClick={this.handleResultClick}
        >
          {medication.drugDescription}
        </List.Item>
      )
    );

    return (
      <Modal className="search-medication-modal" open={open} onClose={this.handleClose} closeIcon size="small">
        <Header content="Search Medications" />
        <Modal.Content>
          <div className="search-container-modal">
            <Input
              icon={{
                name: "search",
                link: true,
                onClick: this.handleSearchClick
              }}
              placeholder="Meds Search"
              onChange={this.handleSearchInput}
              onKeyPress={this.handleSearchEnter}
              value={searchMedication}
              id="input-search-med-modal"
              loading={processing}
              autoFocus
            />
          </div>
          <div className="medication-search-results">
            {selectedMedication.drugKey ? (
              <h3 id="lbl-selected">You selected: {selectedMedication.drugName}</h3>
            ) : (
              searchedMedication && <h3 id="lbl-searched">Search results for: {searchedMedication}</h3>
            )}
            {searchedMedication ? (
              <List selection className="search-results-list">
                {searchError.length ? <div id="searchError">{searchError}</div> : items}
              </List>
            ) : null}
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.handleClose} id="btn-cancel">
            Cancel
          </Button>
          <Button
            disabled={disableButton}
            loading={processing}
            onClick={this.handleModalNav}
            color="blue"
            id="btn-next"
          >
            {selectedMedication.drugKey ? "Next" : "Free Text Next"}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    medications: state.search.medications,
    processing: state.ajaxCallsInProgress > 0
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...modalActions, ...searchActions }, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchMedicationModal);
