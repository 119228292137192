import { isEmpty } from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as providerActions from "../../actions/providerActions";

export class EpcsCancecl extends Component {
  componentDidMount() {
    this.props.actions.cancelIdentityProofing();
  }

  render() {
    const { identityProofingStatus } = this.props;
    return (
      <div>
        {!isEmpty(identityProofingStatus) &&
        identityProofingStatus.status === "complete" &&
        identityProofingStatus.result === "fail"
          ? `Status: ${identityProofingStatus.status}, Result: ${identityProofingStatus.result}, Reason: ${
              identityProofingStatus.failReason
            } `
          : `Cancelling...`}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    identityProofingStatus: state.providers.identityProofingStatus
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...providerActions
      },
      dispatch
    )
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EpcsCancecl);
