import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function centersReducer(state = initialState.badges, action) {
  switch (action.type) {
    case types.LOAD_CHART_BADGE_SUCCESS:
      return Object.assign({}, state, {
        chartsToSign: action.chartsToSign,
        isChartsToSignUrgent: action.isChartsToSignUrgent
      });
    case types.UPDATE_BADGE_AMOUNT:
      // alert(`UPDATE_BADGE_AMOUNT: ${action.badge}`);
      return Object.assign({}, state, {
        [action.badge]: state[action.badge] + action.amount
      });
    case types.LOAD_PRESCRIPTIONS_TO_SIGN_BADGE_SUCCESS:
      return Object.assign({}, state, {
        prescriptionsToSign: action.prescriptionsToSignTotal
      });
    case types.SET_LAB_SUBMISSION_QUEUE_COUNT:
      return Object.assign({}, state, {
        requestedLabs: action.pendingOrderPatientsTotal
      });
    case types.SET_VISITING_STATUS_BADGE:
      return Object.assign({}, state, {
        visiting: action.visiting
      });
    case types.LOAD_TASKS_BADGE_SUCCESS:
      return Object.assign({}, state, {
        tasks: action.tasksTotal.pendingTasksCount,
        isTaskToSignUrgent: action.tasksTotal.isUrgent
      });
    case types.SET_MY_PATIENT_STATUS_BADGE:
      return Object.assign({}, state, {
        myPatients: action.myPatients
      });
    case types.LOAD_COSIGN_BADGE_SUCCESS:
      return Object.assign({}, state, {
        chartsToCoSign: action.value
      });
    case types.LOAD_VISITS_GROUP_BADGE_SUCCESS:
      return Object.assign({}, state, {
        visitsGroup: action.value
      });
    case types.LOAD_NOTIFICATIONS_BADGE_SUCCESS:
    case types.RESET_NOTICATIONS_BADGE:
      return { ...state, notifications: action.value };
    default:
      return state;
  }
}
