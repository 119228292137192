import moment from "moment";
import { isEmpty } from "lodash";
import * as types from "../actions/actionTypes";
import initialState from "./initialState";
import { isoFormat, dateWithTimeFormat } from "../constants/miscellaneous";

export default function labReducer(state = initialState.labs, action) {
  switch (action.type) {
    case types.CLEAR_PATIENT_LABS: {
      return {
        ...state,
        results: [],
        pendingOrders: [],
        recentOrders: [],
        selectedLabOrder: {
          orderId: null,
          sampleDate: null,
          tests: []
        }
      };
    }
    case types.LOAD_AVAILABLE_LABS_SUCCESS: {
      const labsConfirmatoryMap = {};
      action.availableLabs.forEach((lab) => {
        lab.confirmatoryLabs.forEach((clab) => {
          labsConfirmatoryMap[clab.labCode] = clab.confirmatoryLabCode;
          labsConfirmatoryMap[clab.confirmatoryLabCode] = clab.labCode;
        });
      });
      return {
        ...state,
        availableLabs: { ...state.availableLabs, [action.centerId]: action.availableLabs },
        labsConfirmatoryMap: { ...state.labsConfirmatoryMap, [action.centerId]: labsConfirmatoryMap }
      };
    }
    case types.LOAD_RECENT_ORDERS_SUCCESS:
      return { ...state, recentOrders: action.recentOrders };
    case types.SET_SELECTED_LAB_ORDER:
      return {
        ...state,
        selectedLabOrder: action.selectedLabOrder || {
          orderId: null,
          sampleDate: null,
          tests: []
        }
      };
    case types.CLEAR_RECENT_AND_SELECTED_LAB_ORDERS:
      return {
        ...state,
        recentOrders: [],
        selectedLabOrder: {
          orderId: null,
          sampleDate: null,
          tests: []
        }
      };
    case types.SET_LAB_SUBMISSION_QUEUE_PATIENT_CONTEXT:
      return {
        ...state,
        labSubmissionQueue: {
          ...state.labSubmissionQueue,
          contextPatient: action.patient
        }
      };
    case types.CLEAR_LAB_SUBMISSION_QUEUE:
      return {
        ...state,
        labSubmissionQueue: {
          contextPatient: {},
          includeOrderedToday: false,
          requestedLabs: []
        }
      };
    case types.TOGGLE_INCLUDE_ORDERED_TODAY:
      return {
        ...state,
        labSubmissionQueue: {
          ...state.labSubmissionQueue,
          includeOrderedToday: !state.labSubmissionQueue.includeOrderedToday
        }
      };
    case types.SET_FETCHING_REQUESTED_LABS_FLAG:
      return {
        ...state,
        labSubmissionQueue: {
          ...state.labSubmissionQueue,
          fetchingRequestedLabsFlag: action.value
        }
      };
    case types.SET_REQUESTED_LABS:
      return {
        ...state,
        labSubmissionQueue: {
          ...state.labSubmissionQueue,
          requestedLabs: action.requestedLabs.map((order) => {
            const stateOrder = state.labSubmissionQueue.requestedLabs.find(
              (_order) => _order.orderId === order.orderId
            );
            if (!stateOrder)
              return {
                ...order,
                specimenCenterId: isEmpty(order.specimenCenterId) ? action.centerContext : order.specimenCenterId,
                specimenCenterIdChanged: false,
                specimenCollectionDate: !isEmpty(order.specimenCollectionDate)
                  ? order.specimenCollectionDate
                  : moment().format(isoFormat),
                specimenCollectionDateChanged: false,
                specimenCollectionDateAndCenterIdConfirmed:
                  !isEmpty(order.specimenCenterId) && !isEmpty(order.specimenCollectionDate),
                confirmingSpecimenDateAndCenter: false
              };
            return {
              ...order,
              specimenCenterIdChanged: stateOrder.specimenCenterIdChanged,
              specimenCenterId: stateOrder.specimenCenterIdChanged
                ? stateOrder.specimenCenterId
                : isEmpty(order.specimenCenterId)
                ? action.centerContext
                : order.specimenCenterId,
              specimenCollectionDateChanged: stateOrder.specimenCollectionDateChanged,
              specimenCollectionDate: stateOrder.specimenCollectionDateChanged
                ? stateOrder.specimenCollectionDate
                : isEmpty(order.specimenCollectionDate)
                ? moment().format(isoFormat)
                : order.specimenCollectionDate,
              specimenCollectionDateAndCenterIdConfirmed: stateOrder.specimenCollectionDateAndCenterIdConfirmed, // !isEmpty(order.specimenCenterId) && !isEmpty(order.specimenCollectionDate),
              confirmingSpecimenDateAndCenter: stateOrder.confirmingSpecimenDateAndCenter
            };
          })
        }
      };
    case types.SET_REQUESTED_LAB_SPECIMEN_COLLECTION_CENTER:
      return {
        ...state,
        labSubmissionQueue: {
          ...state.labSubmissionQueue,
          requestedLabs: state.labSubmissionQueue.requestedLabs.map((order) => {
            if (order.orderId !== action.orderId) return order;
            return { ...order, specimenCenterId: action.centerId, specimenCenterIdChanged: true };
          })
        }
      };
    case types.SET_REQUESTED_LAB_SPECIMEN_COLLECTION_DATE:
      return {
        ...state,
        labSubmissionQueue: {
          ...state.labSubmissionQueue,
          requestedLabs: state.labSubmissionQueue.requestedLabs.map((order) => {
            if (order.orderId !== action.orderId) return order;
            return {
              ...order,
              specimenCollectionDate: moment(action.date, dateWithTimeFormat).format(isoFormat),
              specimenCollectionDateChanged: true
            };
          })
        }
      };
    case types.CONFIRMING_SPECIMEN_DATE_AND_CENTER:
      return {
        ...state,
        labSubmissionQueue: {
          ...state.labSubmissionQueue,
          requestedLabs: state.labSubmissionQueue.requestedLabs.map((order) => {
            if (order.orderId !== action.orderId) return order;
            return {
              ...order,
              confirmingSpecimenDateAndCenter: true
            };
          })
        }
      };
    case types.CONFIRMING_SPECIMEN_DATE_AND_CENTER_DONE:
      return {
        ...state,
        labSubmissionQueue: {
          ...state.labSubmissionQueue,
          requestedLabs: state.labSubmissionQueue.requestedLabs.map((order) => {
            if (order.orderId !== action.orderId) return order;
            return {
              ...order,
              confirmingSpecimenDateAndCenter: false,
              specimenCenterIdChanged: !action.succeeded,
              specimenCollectionDateChanged: !action.succeeded,
              specimenCollectionDateAndCenterIdConfirmed: action.succeeded
            };
          })
        }
      };
    case types.LOAD_PROT_ORDER_SUCCESS:
      return {
        ...state,
        protocolOrderSets: action.protocolOrderSets
      };
    case types.LOAD_PEND_ORDERS_SUCCESS:
      return {
        ...state,
        pendingOrders: action.pendingOrders.map((pg) => ({
          ...pg,
          labProviderGroup: pg.labProviderGroup.map((lpg) => ({
            ...lpg,
            testTypeGroup: lpg.testTypeGroup.map((ttg) => ({
              ...ttg,
              submissions: ttg.submissions.map((s) => ({
                ...s,
                labSourceGroup: s.labSourceGroup.map((lsg) => ({
                  ...lsg,
                  labs: lsg.labs.map((lab) => ({ ...lab, cancelButtonPressed: false }))
                }))
              }))
            }))
          }))
        }))
      };
    case types.SET_TEST_CANCEL_BUTTON_PRESSED: {
      const { orderId, labProvider, testType, submissionId, labSource, labCode, value } = action;
      return {
        ...state,
        pendingOrders: state.pendingOrders.map((pg) => {
          if (pg.orderId !== orderId) return { ...pg };
          return {
            ...pg,
            labProviderGroup: pg.labProviderGroup.map((lpg) => {
              if (lpg.labProvider !== labProvider) return { ...lpg };
              return {
                ...lpg,
                testTypeGroup: lpg.testTypeGroup.map((ttg) => {
                  if (ttg.testType !== testType) return { ...ttg };
                  return {
                    ...ttg,
                    submissions: ttg.submissions.map((s) => {
                      if (s.submissionId !== submissionId) return { ...s };
                      return {
                        ...s,
                        labSourceGroup: s.labSourceGroup.map((lsg) => {
                          if (lsg.labSource !== labSource) return { ...lsg };
                          return {
                            ...lsg,
                            labs: lsg.labs.map((lab) => {
                              if (lab.labCode !== labCode) return { ...lab };
                              return { ...lab, cancelButtonPressed: value };
                            })
                          };
                        })
                      };
                    })
                  };
                })
              };
            })
          };
        })
      };
    }
    case types.LOAD_LAB_RESULT_HEADERS_SUCCESS: {
      const _results = [...state.results];
      const results = action.results.map((result) => {
        const _result = _results.find((r) => r.orderId === result.orderId && r.labCode === result.labCode);

        if (!_result || !_result.labResults) return result;

        return { ...result, labResults: _result.labResults };
      });
      return {
        ...state,
        results
      };
    }
    case types.LOAD_LAB_RESULTS_SUCCESS:
      return {
        ...state,
        results: state.results.map((result) => {
          if (result.orderId === action.orderId && result.labCode === action.labCode)
            return { ...result, labResults: action.results };
          return result;
        })
      };
    case types.SET_LABS_RAPID_TEST_REFERENCE:
      return {
        ...state,
        labsRapidTestReference: action.data
      };
    case types.INIT_LABS_RAPID_TEST_RESULTS:
      return {
        ...state,
        labsRapidTestResults: state.labsRapidTestReference.reduce((acc, f) => {
          if (f.labCode === action.labCode) {
            acc[f.parameterCode] = "";
          }
          return acc;
        }, {})
      };
    case types.PREFILL_LABS_RAPID_TEST_RESULTS: {
      const updatedLabsRapidTestResults = { ...state.labsRapidTestResults };
      Object.keys(updatedLabsRapidTestResults).forEach((labParameter) => {
        const result = state.results.find(
          (r) =>
            r.orderId === action.orderId &&
            r.submissionNumber === action.submissionNumber &&
            r.labCode === action.labCode
        );
        const labResult =
          result && result.labResults ? result.labResults.find((r) => r.labParameter === labParameter) : null;
        let value = labResult ? labResult.rawResult : "";
        const map = {
          Positive: "true",
          Yes: "true",
          Negative: "false",
          No: "false"
        };
        if (Object.keys(map).includes(value)) value = map[value];
        updatedLabsRapidTestResults[labParameter] = value;
      });
      return {
        ...state,
        labsRapidTestResults: updatedLabsRapidTestResults
      };
    }
    case types.UPDATE_LAB_RAPID_TEST_RESULTS: {
      const obj = {};
      obj[action.key] = action.value;
      return {
        ...state,
        labsRapidTestResults: Object.assign({}, state.labsRapidTestResults, obj)
      };
    }
    case types.LOAD_LAB_RESULT_GRAPHS_SUCCESS:
      return {
        ...state,
        labResultGraphs: action.labResultGraphs
      };
    case types.LOAD_LABS_ORDES_AND_RESULTS_PER_VISIT_SUCCESS:
      return {
        ...state,
        labOrdersAndResultsPerVisit: action.labOrdersAndResults
      };
    case types.SET_STANDING_LAB_ORDER_RENDERED:
      return {
        ...state,
        standingLabOrder: {
          ...state.standingLabOrder,
          rendered: action.value
        }
      };
    case types.SET_STANDING_LAB_ORDER_SAVING:
      return {
        ...state,
        standingLabOrder: {
          ...state.standingLabOrder,
          saving: action.value
        }
      };
    default:
      return state;
  }
}

export const selectGraphLabs = (state, labsCenterId) => {
  const availableLabs = state.availableLabs[labsCenterId];
  return availableLabs === undefined ? [] : availableLabs.filter((l) => l.showInGraphs);
};
