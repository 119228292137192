export default {
  ajaxCallsInProgress: 0,
  context: {
    patient: {
      patientId: null
    },
    visit: {
      visitId: null
    },
    template: {
      templateId: null
    }
  },
  badges: {
    chartsToSign: 0,
    prescriptionsToSign: 0,
    requestedLabs: 0,
    visiting: 0,
    myPatients: 0,
    tasks: 0,
    chartsToCoSign: 0,
    visitsGroup: 0,
    notifications: 0
  },
  centers: {
    items: [],
    centerDetails: {},
    centerAddressHistory: [],
    locationAndDirectorHistory: [],
    forceUpdateCenterDetails: false
  },
  patient: {
    currentPatient: {},
    newPatient: {},
    advanceDirectives: null,
    patientExternalRefs: [],
    numberOfOpenNoteSlashTasks: { openTasksCount: 0, urgentTasksCount: 0 },
    customFields: {}
  },
  patientHistory: {
    medicationHistory: {},
    allergyHistory: {},
    familyHistory: {},
    medicalHistory: [],
    interventionHistory: [],
    psychiatristHistory: [],
    outsideCounselorHistory: [],
    obProviderHistory: [],
    otherProvidersHistory: []
  },
  patientExport: {},
  modal: {
    type: null,
    props: null
  },
  multipleModals: {
    current: {},
    previous: []
  },
  lookups: {
    cached: [],
    reactionTypes: [],
    states: [],
    integrationKeys: [],
    livingSituations: [],
    billingCodes: [],
    visitTypes: [],
    languages: [],
    treatments: [],
    conditions: [],
    interventionConditions: [],
    drugs: [],
    allergies: [],
    races: [],
    ethnicities: [],
    maritalStatuses: [],
    relationships: [],
    insuranceRelationships: [],
    communicationPreferences: [],
    dischargeReasons: [],
    visitStatuses: [],
    taskAssignedTo: [],
    taskRoles: [],
    roleGroups: [],
    levels: [],
    centers: [],
    mspOptions: [],
    pcpOptions: [],
    auxFlags: [],
    groupTypes: [],
    insuranceProviders: [],
    drugForms: [],
    drugRoutes: [],
    drugStrengths: [],
    refillOptions: [],
    numberPerDoseOptions: [],
    frequencyOptions: [],
    prescribersWithSlots: [],
    documentTags: [],
    rxAuthenticationTypes: [],
    attestationOptions: [],
    genderPronouns: [],
    sexAssignedAtBirths: [],
    sexualOrientations: [],
    genderIdentities: [],
    familyMedicalConditions: [],
    appointmentCancelReason: [],
    labsUnique: [],
    labSpecimenTypes: [],
    labTestTypes: [],
    labTestStatuses: [],
    labProviders: [],
    labOrderStatuses: [],
    visitTypeGroups: [],
    exostarActionTypes: [],
    taskTemplates: [],
    noteTemplates: [],
    patientCustomFields: [],
    exostarIdentityProofingStatuses: [
      { value: "DEAD", text: "User account is inactive" },
      { value: "nonexistent", text: "New user" },
      { value: "unknown_user", text: "Unknown user" },
      { value: "wtg_redirect", text: "The service is waiting for the user’s browser to be redirected to the system" },
      {
        value: "complete",
        text: "The process is complete" // It is no longer in progress. The process may have succeeded or failed"
      },
      { value: "active", text: "Service is actively performing a step in the process" },
      { value: "wtg_resume", text: "Service is waiting for the client to request to resume the process" },
      { value: "wtg_proofer", text: "" },
      { value: "cancelling", text: "A cancel has been initiated on the Proofing process" },
      {
        value: "wtg_subscription",
        text: "Process is waiting for subscription to be activated (eg,  by the user entering a service license key)"
      }
    ],
    exostarIdentityProofingFailReasons: [
      {
        value: "reason_client_cancel",
        text: "The client cancelled the process from the Webservice"
      }
    ]
  },
  search: {
    medications: [],
    patients: [],
    physicians: [],
    allergies: [],
    conditions: []
  },
  coreDemographics: {},
  demographics: {
    patientDischargeReason: {},
    patientDischargeSummary: []
  },
  providers: {
    allProviders: [],
    prescriberHistory: [],
    providerCenters: { homeCenter: null, otherCenters: [] },
    selectedProviderIntegrationValues: [],
    selectedProviderGateways: {
      rxGateway: "",
      identityProofingGateway: ""
    },
    identityProofingStatus: {
      error: false
    },
    exostarSelfAdminSession: {},
    identityProofingFlags: { isGranted: false, isFinalized: false }
  },
  prescriptions: {
    selectedPharmacy: {},
    currentPrescriptions: [],
    currentDrugAlternatives: [],
    recurringPrescriptions: [],
    currentDrugFormulary: [],
    prescriptionsSent: [],
    prescriptionsToSign: [],
    forceReloadPrescriptionsToSign: false,
    currentPrescriptionToSign: {},
    prescriptionsSentByVisit: [],
    rxItemForm: {},
    rxPinInfo: { hasRxPin: false }
  },
  visits: {
    activeVisits: [],
    contextVisit: null,
    latestVisit: null,
    patientVisits: {
      visits: []
    },
    myCalendar: {
      visits: [],
      appointments: []
    },
    providerVisitsMessage: {},
    providerAppointmentsMessage: {},
    visitSummary: {},
    allCenterVisits: { dataVisits: [], totalVisits: 0 },
    centerVisitsMessage: {},
    visitingStatus: [],
    visitingStatusSelected: [],
    visitStatusFilter: [],
    visitAmendment: {
      amendments: []
    },
    timeline: {
      totalNumberOfEpsiodesOfCare: 0,
      totalNumberOfVisits: 0,
      data: []
    },
    detailedTimeline: {},
    // counselingVisitDetail: { loading: true },
    visitMedicalRecentDetail: {},
    medicationHistory: [],
    allergyHistory: [],
    familyHistory: [],
    medicalHistory: [],
    interventionHistory: [],
    listVisitSummaryToPrint: [],
    forceReloadPatientTimelineHistory: false,
    visitSummaryLocations: {
      visitSpecimenCenterId: null,
      sampleCollectionTime: null,
      isPatientHome: false,
      isProviderHome: false,
      patientCenterId: null,
      providerCenterId: null
    },
    contextVisitLocations: {
      visitSpecimenCenterId: null,
      sampleCollectionTime: null,
      isPatientHome: false,
      isProviderHome: false,
      patientCenterId: null,
      providerCenterId: null
    },
    oldVisitStatus: null
  },
  patientVisits: {},
  noteSlashTasks: { data: [], count: 0, detailedData: {}, forceReload: false },
  auth: {
    user: ""
  },
  insurance: {
    primaryInsurance: {},
    secondaryInsurance: {},
    tertiaryInsurance: {},
    nonBillableInsurance: {},
    udsPriorAuthorizations: [],
    medicationPriorAuthorizations: [],
    insuranceHistory: [],
    benefitsInformation: [],
    drugHistory: []
  },
  treatmentPlan: {
    history: [],
    selectedTreatmentPlan: { header: null, view: null, edit: null },
    selectedTemplate: {
      templateId: null,
      templateName: null,
      version: null,
      surveys: [],
      styles: []
    },
    hiddenSurveys: {}
  },
  templates: {
    contextVisit: null,
    availableTemplates: [],
    selectedTemplate: {
      templateId: null,
      templateName: null,
      version: null,
      surveys: [],
      styles: []
    },
    specificTemplates: {},
    hiddenSurveys: {},
    reInitSurvey: false,
    reloadTemplate: false
  },
  groups: [],
  labs: {
    results: [],
    pendingOrders: [],
    availableLabs: {},
    labsConfirmatoryMap: {},
    recentOrders: [],
    selectedLabOrder: {
      orderId: null,
      sampleDate: null,
      tests: []
    },
    protocolOrderSets: [],
    labSubmissionQueue: {
      contextPatient: {},
      includeOrderedToday: false,
      fetchingRequestedLabsFlag: false,
      requestedLabs: []
    },
    labResultGraphs: [],
    labsRapidTestResults: null,
    labOrdersAndResultsPerVisit: {},
    standingLabOrder: {
      saving: false,
      rendered: false
    }
  },
  userPreference: {
    centerContext: "",
    currentUserId: "",
    currentUserFullName: "",
    reloadPageIn: -1,
    forceUpdate: false,
    newVersion: {},
    retryConnectingIn: -1,
    retryReachingServerIn: -1,
    theme: "/themes/default.css"
  },
  documents: {
    forceReload: false,
    availableDocuments: []
  },
  charts: {
    chartsToSign: [],
    latestVisitSignedChart: { loading: true },
    listProviderCoSign: [],
    listVisitCoSign: [],
    cosignDetail: { loading: true },
    toggleVisitId: ""
  },
  sidebarErrorLog: {
    errors: [],
    sliderVisible: false,
    ss: []
  },
  errorLogs: {
    recoverable: { totalCount: 0, errors: [] },
    nonRecoverable: { totalCount: 0, errors: [] },
    selectedErrorDetails: {
      id: "",
      sourceHandler: "",
      errorType: "",
      errorSource: "",
      errorMessage: "",
      errorUserMessage: "",
      stackTrace: "",
      sourceMessageType: "",
      sourceMessageContent: "{}",
      orchestrationId: "",
      identityContext: "",
      platformEventId: "",
      timeStamp: "",
      username: ""
    }
  },
  claimErrors: {
    claims: { totalCount: 0, errors: [] },
    allClaims: { totalCount: 0, errors: [] },
    selectedClaimDetails: {}
  },
  reports: {
    reportList: [],
    report: { count: 0, currentPageRows: [], allRows: [] },
    claimReconciliations: { totalVisitClaimsCount: 0, visitClaimsList: [] },
    allClaimReconciliations: { totalVisitClaimsCount: 0, visitClaimsList: [] },
    reviewOfPatients: { count: 0, data: [] },
    allReviewOfPatients: { count: 0, data: [] }
  },
  user: {
    list: [],
    mainPrescribers: [],
    supervisingProviders: [],
    drugFavorites: []
  },
  admin: {
    breadcrumb: [
      {
        key: "bc-home",
        to: "/",
        text: "Home",
        active: true
      }
    ],
    shadowQueue: {
      totalCount: 0,
      items: []
    },
    proxyRequests: {
      totalCount: 0,
      requests: []
    }
  },
  notifications: {
    totalCount: 0,
    notificationType: "inbox",
    notifications: []
  },
  notes: {},
  misc: {
    narxScores: {
      scores: [
        { key: "Narcotics", value: "000" },
        { key: "Stimulants", value: "000" },
        { key: "Sedatives", value: "000" },
        { key: "Overdose", value: "000" }
      ],
      reportUrl: "",
      error: false,
      errorMessage: ""
    }
  },
  streaming: {
    streamId: null,
    subscriptionList: []
  }
};
