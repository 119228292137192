import React, { Component } from "react";
import { Button, Header, Modal, Table } from "semantic-ui-react";
import { connect } from "react-redux";
import shortid from "shortid";
import { bindActionCreators } from "redux";
import * as modalActions from "../../../../actions/modalActions";
import * as insuranceActions from "../../../../actions/insuranceActions";
import * as lookupActions from "../../../../actions/lookupActions";
import "./InsuranceHistoryModal.css";
import InsuranceHistoryRow from "./InsuranceHistoryRow";
import { getLookupText } from "../../../../helpers";

export class InsuranceHistoryModal extends Component {
  componentDidMount() {
    const { patientId } = this.props;
    if (patientId) this.props.actions.loadInsuranceHistory(patientId);
    this.props.actions.loadInsuranceProviders();
    this.props.actions.loadMspOptions();
  }

  handleClose = () => this.props.actions.hideModal();

  render() {
    const { open, insuranceHistory, mspOptions, insuranceProviders } = this.props;
    return (
      <Modal className="insurance-history-modal" open={open} onClose={this.handleClose} closeIcon>
        <Header content="Insurance History" />
        <Modal.Content>
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Insurance Information</Table.HeaderCell>
                <Table.HeaderCell>Insured</Table.HeaderCell>
                <Table.HeaderCell>MSP</Table.HeaderCell>
                {/* <Table.HeaderCell>Changed By</Table.HeaderCell> */}
                <Table.HeaderCell>Changed Date</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {insuranceHistory.map(historyItem => (
                <InsuranceHistoryRow
                  key={`rand-${shortid.generate()}`}
                  {...historyItem}
                  getLookupText={getLookupText}
                  mspOptions={mspOptions}
                  insuranceProviders={insuranceProviders}
                />
              ))}
            </Table.Body>
          </Table>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.handleClose} id="btn-cancel">
            Cancel
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  const insuranceHistory = state.insurance.insuranceHistory || [];
  return {
    patientId: state.patient.currentPatient.patientId,
    insuranceHistory,
    mspOptions: state.lookups.mspOptions,
    insuranceProviders: state.lookups.insuranceProviders
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...modalActions, ...insuranceActions, ...lookupActions }, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InsuranceHistoryModal);
