import { toast } from "react-toastify";
import * as types from "./actionTypes";
import { adalApiFetch } from "../adalConfig";
import { API_URL } from "../constants/environment";
import { beginAjaxCall, ajaxCallDone, ajaxCallError } from "./ajaxStatusActions";
import { processApiError } from "../helpers";
import { toastErrorOptions } from "../constants/toastconfig";

function setClaimErrors(data, all) {
  return {
    type: all ? types.SET_ALL_CLAIM_ERRORS_SUCCESS : types.SET_CLAIM_ERRORS_SUCCESS,
    data
  };
}

function setSelectedErrorDetails(data) {
  return {
    type: types.SET_SELECTED_CLAIM_ERROR_DETAILS_SUCCESS,
    data
  };
}

export function loadClaimErrors(
  pageSize = 15,
  pageNumber = 1,
  athenaServiceErrorsOnly,
  filters = {},
  orderBySourceHandler = false,
  all = false
) {
  const endPoint = "ErrorLogs/Claims";

  return (dispatch) => {
    dispatch(beginAjaxCall("loadClaimErrors"));
    const params = {
      pageSize,
      pageNumber,
      athenaServiceErrorsOnly,
      fromDay: filters.from.day,
      fromMonth: filters.from.month + 1,
      fromYear: filters.from.year,
      toDay: filters.to.day,
      toMonth: filters.to.month + 1,
      toYear: filters.to.year,
      includeNonVisitClaims: filters.includeNonVisitClaims,
      orderBySourceHandler
    };
    params[filters.searchBy] = filters.keyword;
    const urlParams = Object.keys(params)
      .map((k) => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
      .join("&");
    return adalApiFetch(`${API_URL}/Admin/${endPoint}?${urlParams}`)
      .then((data) => {
        if (data.message && data.message === "Validation Failed") {
          /* response status 422 */
          processApiError(data.errors[0].message).then(dispatch(ajaxCallError(data.errors[0].message)));
          return;
        }
        dispatch(setClaimErrors(data, all));
      })
      .catch((error) => {
        processApiError(error, dispatch);
      })
      .finally(() => {
        dispatch(ajaxCallDone());
      });
  };
}

export function loadSelectedErrorDetails(id) {
  const endPoint = "ErrorLogs/Claims";
  return (dispatch) => {
    dispatch(beginAjaxCall("loadClaimErrors"));
    return adalApiFetch(`${API_URL}/Admin/${endPoint}/${id}`)
      .then((data) => dispatch(setSelectedErrorDetails(data)))
      .catch((error) => {
        processApiError(error, dispatch);
      })
      .finally(() => {
        dispatch(ajaxCallDone());
      });
  };
}

export function clearClaimError() {
  return (dispatch) => {
    dispatch(
      setSelectedErrorDetails({
        firstName: "",
        lastName: "",
        visitStatus: "",
        visitType: "",
        visitId: "",
        providerId: "",
        appointmentExternalId: "",
        centerName: "",
        labName: "",
        visitDate: "",
        providerName: "",
        mrn: "",
        patientLevel: "",
        appointmentId: "",
        signedOnDate: "",
        id: "",
        sourceHandler: "",
        errorType: "",
        errorSource: "",
        errorMessage: "",
        errorUserMessage: "",
        stackTrace: "",
        sourceMessageType: "",
        sourceMessageContent: "{}",
        orchestrationId: "",
        identityContext: "",
        platformEventId: "",
        timeStamp: "",
        username: ""
      })
    );
  };
}

export function dismissClaimError(id) {
  const endPoint = "ErrorLogs/Claims";
  return (dispatch) => {
    dispatch(beginAjaxCall("dismissError"));
    return adalApiFetch(
      `${API_URL}/Admin/${endPoint}/${id}`,
      {
        method: "DELETE"
      },
      false
    )
      .catch((error) => {
        processApiError(error, dispatch);
      })
      .finally(() => {
        dispatch(ajaxCallDone());
      });
  };
}

export function requeueClaimError(id, messageContent = "") {
  return (dispatch) => {
    dispatch(beginAjaxCall("requeueError"));
    return adalApiFetch(
      `${API_URL}/Admin/ErrorLogs/Claims/Republish/${id}`,
      {
        method: "POST",
        body: JSON.stringify({ messageContent })
      },
      false
    )
      .then(() => toast.success(`claim error [id: ${id}] has successfully been re-queue'd`, toastErrorOptions))
      .catch((error) => {
        processApiError(error, dispatch);
      })
      .finally(() => {
        dispatch(ajaxCallDone());
      });
  };
}
