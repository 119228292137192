import moment from "moment";
import * as types from "../actions/actionTypes";
import initialState from "./initialState";
import { dateWithFullTimeFormat } from "../constants/miscellaneous";

export default function sidebarErrorLogReducer(state = initialState.sidebarErrorLog, action) {
  switch (action.type) {
    case types.OPEN_ERROR_LOG_SLIDER:
      return Object.assign({}, state, { sliderVisible: true });
    case types.CLOSE_ERROR_LOG_SLIDER:
      return Object.assign({}, state, { sliderVisible: false });
    case types.PUSH_ERROR_LOG_TO_SLIDER: {
      const prevErrors = state.errors;
      const newErr = action.error;

      let newErrorLog = {};

      if (prevErrors.length === 0) {
        newErrorLog = {
          id: 0,
          detail: newErr.detail,
          orchestrationId: newErr.orchestrationId,
          errorDate: moment().format(dateWithFullTimeFormat)
        };
      } else {
        const id = prevErrors.sort((a, b) => (a.id < b.id ? 1 : -1))[0].id;
        newErrorLog = {
          id: id + 1,
          detail: newErr.detail,
          orchestrationId: newErr.orchestrationId,
          errorDate: moment().format(dateWithFullTimeFormat)
        };
      }
      const newErrors = [...state.errors, newErrorLog];

      return Object.assign({}, state, { errors: newErrors });
    }
    case types.DISMISS_ITEM_ERROR_LOG_TO_SLIDER: {
      const newErrors1 = state.errors.filter(e => e !== action.error);
      return Object.assign({}, state, { errors: newErrors1 });
    }
    case types.DISMISS_ALL_ERROR_LOG_TO_SLIDER:
      return Object.assign({}, state, { errors: [] });
    default:
      return state;
  }
}
