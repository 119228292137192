import React from "react";
import { Button } from "semantic-ui-react";

const AllergySliderRow = ({
  handleQuickSearch,
  allergy
}) => (
    <div>
      <Button
        icon="plus"
        size="small"
        className="transparent-button-icon"
        onClick={() => handleQuickSearch(allergy)}
      />
      <span>{allergy && allergy.description}</span>
    </div>
  );

export default AllergySliderRow;
