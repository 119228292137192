import React from "react";
import { Button as BsButton } from "react-bootstrap";

const Button = React.forwardRef(({ loading, children, ...rest }, ref) => (
  <BsButton {...rest} ref={ref}>
    {loading && (
      <span
        className="spinner-border spinner-border-sm"
        role="status"
        aria-hidden="true"
        style={{ margin: "0 .5rem" }}
      />
    )}
    {!loading && children}
  </BsButton>
));

export default Button;
