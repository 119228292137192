import React, { Component } from "react";
import { Button, Header, Modal, Form, Grid } from "semantic-ui-react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { updateErrors, hasProperty } from "../../../helpers";
import * as modalActions from "../../../actions/modalActions";
import * as labActions from "../../../actions/labActions";

export class RapidResultsEntryModal extends Component {
  state = {
    errors: {}
  };
  componentDidMount() {
    const {
      edit,
      orderId,
      submissionNumber,
      labCode,
      actions: { loadLabsRapidTestReference, prefillLabsRapidTestResults }
    } = this.props;
    const promise = loadLabsRapidTestReference(labCode);
    if (edit) promise.then(() => prefillLabsRapidTestResults(orderId, submissionNumber, labCode));
  }

  handleInput = (e, data, validationRules = []) => {
    const { name: field, value } = data;
    this.props.actions.updateLabRapidTestResults(field, value);

    let valid = true;
    if (validationRules) {
      validationRules.forEach((rule) => {
        if (valid) {
          if (rule.rule === "regex") {
            const _errors = updateErrors(field, value, true, this.state.errors, rule.regex);
            if (hasProperty(_errors, field)) valid = false;
          }
          // else if (rule.rule === ...) in the future we can add more rules
        }
      });
      const errors = { ...this.state.errors };
      if (valid) {
        delete errors[field];
      } else {
        errors[field] = "";
      }
      this.setState({ errors });
    }
  };

  handleSave = () => {
    const { patientId, visitId, orderId, labCode } = this.props;
    this.props.actions.saveRapidResult(patientId, visitId, orderId, labCode).then(this.handleClose);
  };

  handleClose = () => this.props.actions.hideModal();

  validate = () => {
    const { results } = this.props;
    const { errors } = this.state;
    return results && Object.keys(results).every((key) => results[key] !== "") && Object.keys(errors).length === 0;
  };

  render() {
    const { open, fields, results, processing } = this.props;
    const { errors } = this.state;
    return !results ? (
      ""
    ) : (
      <Modal
        className="lab-order-modal"
        open={open}
        closeOnDimmerClick={false}
        onClose={this.handleClose}
        closeIcon
        size="small"
      >
        <Header content="Rapid Test Results" />
        <Modal.Content scrolling>
          <Form>
            <Grid className="mx-0">
              {fields.map((field) => (
                <Grid.Row key={field.parameterCode} style={{ borderBottom: "1px solid rgba(34, 36, 38, 0.15)" }}>
                  <Grid.Column width={4}>
                    <label>{field.parameterName}</label>
                  </Grid.Column>
                  <Grid.Column width={12}>
                    <Form.Field inline>
                      {field.parameterType === "boolean" && (
                        <React.Fragment>
                          <Form.Radio
                            label={
                              field.displayType === "YesNo"
                                ? "Yes"
                                : field.displayType === "PositiveNegative"
                                ? "Positive"
                                : "???"
                            }
                            name={field.parameterCode}
                            value="true"
                            checked={results[field.parameterCode] === "true"}
                            onChange={this.handleInput}
                          />
                          <Form.Radio
                            label={
                              field.displayType === "YesNo"
                                ? "No"
                                : field.displayType === "PositiveNegative"
                                ? "Negative"
                                : "???"
                            }
                            name={field.parameterCode}
                            value="false"
                            checked={results[field.parameterCode] === "false"}
                            onChange={this.handleInput}
                          />
                        </React.Fragment>
                      )}
                      {field.parameterType === "text" && (
                        <Form.Input
                          inline
                          name={field.parameterCode}
                          value={results[field.parameterCode]}
                          onChange={(e, data) => this.handleInput(e, data, field.validationRules)}
                          error={hasProperty(errors, field.parameterCode)}
                        />
                      )}
                      {field.displayType === "deg F" && <span>F</span>}
                    </Form.Field>
                  </Grid.Column>
                </Grid.Row>
              ))}
            </Grid>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.handleClose} id="btn-cancel">
            Cancel
          </Button>

          <Button
            onClick={this.handleSave}
            color="blue"
            id="btn-sendToLab"
            disabled={!this.validate() || processing}
            loading={processing}
          >
            Save Results
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const fields = state.labs.labsRapidTestReference
    ? state.labs.labsRapidTestReference.filter((f) => f.labCode === ownProps.labCode)
    : [];
  return {
    fields,
    results: state.labs.labsRapidTestResults,
    processing: state.ajaxCallsInProgress > 0
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...modalActions, ...labActions }, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RapidResultsEntryModal);
