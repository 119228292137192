import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { isEqual } from "lodash";
import { Button, Modal, Form, InputGroup } from "../../../ui";
import * as modalActions from "../../../actions/modalActions";
import * as lookupActions from "../../../actions/lookupActions";
import * as demographicActions from "../../../actions/demographicActions";
import "../../reusable/Modal.css";

export class EditPatientMainPrescriberModal extends Component {
  state = { mainPrescriber: this.props.currentProvider || "", errorMessage: `` };

  componentDidMount() {
    this.props.actions.loadPrescriberSlots();
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(this.props.currentProvider, prevProps.currentProvider)) {
      this.setState({ mainPrescriber: this.props.currentProvider });
    }
  }

  handleFocus = (e) => {
    e.target.setAttribute("autocomplete", "nope");
  };

  handleClose = () => this.props.actions.hideModal();

  handlePrescriber = ({ target: { value } }) => {
    if (value) {
      const prescriberId = value;
      const prescriber = this.props.prescriberSlots.find((p) => p.value === prescriberId);
      this.setState({
        mainPrescriber: {
          providerId: prescriberId,
          firstName: prescriber.fname,
          lastName: prescriber.lname
        },
        errorMessage: prescriber.slots > 0 ? `` : `Please select a prescriber with some available slots`
      });
    } else {
      this.setState({
        mainPrescriber: {
          providerId: ``,
          firstName: ``,
          lastName: ``
        },
        errorMessage: `Please select a provider`
      });
    }
  };

  handleInput = (e, data) => {
    const { name: field, value } = data || e.target;
    this.setState({ [field]: value });
  };

  handleSave = () => {
    const {
      actions: { setDefaultPrescribingProvider },
      patientId
    } = this.props;
    const { mainPrescriber } = this.state;

    setDefaultPrescribingProvider(patientId, mainPrescriber.providerId).then(this.handleClose);
  };

  render() {
    const { open, prescriberSlots, processing } = this.props;
    const { mainPrescriber, errorMessage } = this.state;

    return (
      <Modal show={open} size="md" backdrop="static" keyboard={false} onHide={this.handleClose}>
        <Modal.Header closeButton>Patient Support</Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Label>Main Prescriber</Form.Label>
            <InputGroup hasValidation>
              <Form.Select
                id="input-mainPrescriber"
                name="mainPrescriber"
                placeholder="Select a provider"
                value={mainPrescriber.providerId}
                options={prescriberSlots}
                onFocus={this.handleFocus}
                onChange={this.handlePrescriber}
                isInvalid={errorMessage !== ``}
                searchable
              />
              <Form.Control.Feedback type="invalid">{errorMessage}</Form.Control.Feedback>
            </InputGroup>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={this.handleClose} style={{ marginRight: "auto" }}>
            Cancel
          </Button>
          <Button
            color="blue"
            onClick={this.handleSave}
            loading={processing}
            disabled={processing || errorMessage !== ``}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

function providersFormattedDropdown(prescribersWithSlots) {
  return prescribersWithSlots.map(({ platformId, firstName, lastName, numberOfAvailableSlots }) => ({
    value: platformId,
    text: `${firstName} ${lastName} (${numberOfAvailableSlots})`,
    fname: firstName,
    lname: lastName,
    slots: numberOfAvailableSlots
  }));
}

function mapStateToProps(state) {
  return {
    prescriberSlots: providersFormattedDropdown(state.lookups.prescribersWithSlots),
    patientId: state.patient.currentPatient.patientId,
    currentProvider: state.demographics.prescribingProvider || {},
    demographics: state.demographics,
    processing: state.ajaxCallsInProgress > 0
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...modalActions,
        ...lookupActions,
        ...demographicActions
      },
      dispatch
    )
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EditPatientMainPrescriberModal);
