import { API_URL } from "../constants/environment";
import { adalApiFetch } from "../adalConfig";

export const fetchPharmacies = params =>
  adalApiFetch(`${API_URL}/Reference/Pharmacy`, {
    method: "POST",
    body: JSON.stringify(params)
  });

export default {
  fetchPharmacies
};
