import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function patientReducer(state = initialState.patientExport, action) {
  switch (action.type) {
    case types.FETCH_PATIENT_EXPORT_DATA_DONE:
      return { ...action.data };
    default:
      return state;
  }
}
