import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter, NavLink } from "react-router-dom";
import shortid from "shortid";
import moment from "moment";
import { isEqual, isEmpty } from "lodash";
import {
  Header,
  Button,
  Form,
  List,
  Label,
  Popup,
  Grid,
  HeaderContent,
  Image,
  Tab,
  Menu,
  Segment,
  Icon
} from "semantic-ui-react";
import * as patientActions from "../../actions/patientActions";
import * as lookupActions from "../../actions/lookupActions";
import * as visitActions from "../../actions/visitActions";
import * as templateActions from "../../actions/templateActions";
import * as patientHistoryActions from "../../actions/patientHistoryActions";
import * as demographicActions from "../../actions/demographicActions";
import * as prescriptionActions from "../../actions/prescriptionActions";
import { extendedApiSlice } from "./labs/labsSlice";
import { selectIsContextVisitInActiveVisits } from "../../reducers/rootReducer";
import { roleGroups } from "../../constants/securityRoles";
import {
  CONFIRMATION,
  NEW_VISIT,
  CANCEL_VISIT_MODAL,
  TELEMED_DATA_MODAL,
  CHANGE_VISIT_TYPE_MODAL,
  VISIT_CENTER_CONFLICT,
  NOTE_SLASH_TASK_MODAL
} from "../../helpers/modals";
import { checkRoles, getLookupText, patientName, showModal, unsubscribe } from "../../helpers";
import greyMan from "../../assets/patients/current/grey-man.jpg";
import "./PatientSummary.css";
import {
  DISCHARGE_REASON_MANUAL_DISCHARGE,
  dateFormat,
  VISIT_STATUS_VISIT_CANCELED,
  VISIT_STATUS_VISIT_COMPLETE,
  TEMPLATE_ID_MA_TEMPLAE_ID
} from "../../constants/miscellaneous";
import stratusIcon from "../../assets/patients/current/stratusemr_icon.ico";
import {
  Badge,
  Button as BsButton,
  // ButtonGroup,
  // Dropdown as BsDropdown,
  // DropdownButton,
  Icon as BsIcon,
  PatientLevel,
  OverlayTrigger,
  Popover
} from "../../ui";
import PatientInfo from "./PatientInfo";

export class PatientSummary extends Component {
  static formatDOB(patientDateOfBirth) {
    return moment(patientDateOfBirth, "MM/DD/YYYY").format("MM/DD/YYYY");
  }

  static formatAge(patientDateOfBirth) {
    return moment().diff(moment(patientDateOfBirth, "MM/DD/YYYY"), "years");
  }

  state = {
    selectedVisitId: null,
    selectedVisitStatus: null,
    dischargeButtonPressed: false
  };

  componentDidMount() {
    this._isMounted = true;
    const { patientId, contextVisit, visitTypes } = this.props;
    this.props.actions.loadLevels();
    this.props.actions.loadVisitTypes();
    this.props.actions.loadVisitStatuses();
    this.props.actions.loadInsuranceProviders();
    if (patientId) {
      this.load();
      this.props.actions.loadPatientExternalRefs(patientId);
    }
    if (contextVisit && !isEmpty(visitTypes)) {
      this.fetchVisitLocations();
    }
  }

  componentDidUpdate(prevProps) {
    const { patientId, activeVisits, contextVisit, contextVisitStatus, visitTypes } = this.props;

    if (patientId !== prevProps.patientId) {
      if (prevProps.patientId) {
        unsubscribe(`/Visits/${prevProps.patientId}/ActiveVisits`);
      }
      if (patientId) {
        this.load();
        this.props.actions.loadPatientExternalRefs(patientId);
      }
    }

    if (
      !isEqual(this.props.medicalHistory, prevProps.medicalHistory) ||
      !isEqual(this.props.interventionHistory, prevProps.interventionHistory) ||
      !isEqual(this.props.auxiliaryFlags, prevProps.auxiliaryFlags)
    ) {
      this.props.actions.loadPatientSummary(patientId);
    }
    if (
      contextVisit &&
      !isEmpty(visitTypes) &&
      (!prevProps.contextVisit ||
        !isEqual(contextVisit.visitId, prevProps.contextVisit.visitId) ||
        visitTypes.length !== prevProps.visitTypes.length)
    ) {
      this.fetchVisitLocations();
    }
    if (
      (contextVisit &&
        (!prevProps.contextVisit ||
          !isEqual(contextVisit.visitId, prevProps.contextVisit.visitId) ||
          contextVisitStatus !== prevProps.contextVisitStatus)) ||
      !isEqual(activeVisits, prevProps.activeVisits)
    ) {
      this.syncSelectedWithContextVisit();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    if (this.props.patientId) unsubscribe(`/Visits/${this.props.patientId}/ActiveVisits`);
    clearInterval(this.interval);
  }

  getStratusExternalRef = (patientExternalRefs) => {
    let sourceValue = "";
    if (Array.isArray(patientExternalRefs)) {
      const patientExternalRef = patientExternalRefs.find((item) => !isEmpty(item.stratusLink));
      if (patientExternalRef) {
        sourceValue = patientExternalRef.stratusLink;
      }
    }
    return sourceValue;
  };

  fetchVisitLocations = () => {
    const {
      contextVisit,
      contextVisitId,
      contextVisitType,
      actions: { getVisitLocations }
    } = this.props;
    getVisitLocations(contextVisitId).then(() => {
      const { visitLocations, visitTypes } = this.props;
      const visitTypeObject = contextVisit ? visitTypes.find((v) => v.value === contextVisitType) : {};
      const isMedicalVisit = visitTypeObject.medical;
      const telemedDateMissing =
        (isMedicalVisit && !visitLocations.visitSpecimenCenterId) ||
        (!visitLocations.isPatientHome && !visitLocations.patientCenterId) ||
        (!visitLocations.isProviderHome && !visitLocations.providerCenterId);
      const isTelemedVisit = visitTypeObject.virtual;
      if (isTelemedVisit && telemedDateMissing) this.handleTelemedDataButtonClick();
    });
  };

  load = (dontTouchContextVisit = false) => {
    const {
      patientId,
      authRoles,
      visitContexted,
      actions: { loadPatientActiveVisits }
    } = this.props;
    const selectFirstMedicalVisitIfNoVisitSelected =
      !visitContexted && checkRoles(roleGroups.selectFirstMedicalVisitIfNoVisitSelected, authRoles);

    return loadPatientActiveVisits(
      patientId,
      selectFirstMedicalVisitIfNoVisitSelected,
      false,
      dontTouchContextVisit
    ).then(() => {
      this.syncSelectedWithContextVisit();
    });
  };

  syncSelectedWithContextVisit = () => {
    const { contextVisitId, contextVisitStatus } = this.props;
    if (this._isMounted && contextVisitId) {
      this.setState({
        selectedVisitId: contextVisitId,
        selectedVisitStatus: contextVisitStatus
      });
    }
  };

  _isMounted = false;

  backToActiveVisits = () => {
    const { patientId, history } = this.props;
    this.props.actions.clearContextVisit(patientId);
    history.push(`/current-patients/${patientId}/visits`);
  };
  isRequireSurvey = (listQuestion) => {
    const listItemRequire = listQuestion.find((i) => i.isRequired);
    if (listItemRequire) {
      return true;
    }
    return false;
  };

  handleCompleteVisit = async () => {
    const {
      patientId,
      contextVisit: { visitId },

      actions: { getNotReviewedLabResults, getReviewedLabResultsHistory, checkBuprenorphinePrescriptionQueued }
    } = this.props;

    const { unwrap, unsubscribe: _unsubscribe } = getNotReviewedLabResults({ patientId });
    const data = await unwrap();
    _unsubscribe();

    const { unwrap: _unwrap, unsubscribe: __unsubscribe } = getReviewedLabResultsHistory({ patientId, visitId });
    const _data = await _unwrap();
    __unsubscribe();

    checkBuprenorphinePrescriptionQueued(patientId, visitId).then((response) => {
      const alert =
        response.isSuboxonePatient && response.isBuprenorphineVisit && !response.hasProperPrescriptionQueued;
      const description = () => (
        <>
          {`Are you sure you want to end the visit?`}
          {(alert || data.ids.length > 0) && (
            <>
              {data.ids.length > 0 && (
                <>
                  <hr className="my-2" />
                  <BsIcon name="exclamation-triangle" className="me-2 text-warning" />
                  <span className="text-warning">{`There are unreviewed labs for this patient, are you sure you want to complete this visit?`}</span>
                </>
              )}
              {_data.ids.length === 0 && (
                <>
                  <hr className="my-2" />
                  <BsIcon name="exclamation-triangle" className="me-2 text-warning" />
                  <span className="text-warning">{`Lab history not reviewed, are you sure you want to complete this visit?`}</span>
                </>
              )}

              {alert && (
                <>
                  <hr className="my-2" />
                  <BsIcon name="exclamation-triangle" className="me-2 text-warning" />
                  <span className="text-warning">{`The patient has no buprenorphine prescription queued`}</span>
                </>
              )}
            </>
          )}
        </>
      );
      return this.showModalCompleteVisit(description, alert || data.ids.length > 0);
    });
  };

  handleCancelVisit = () => this.showModalCancelVisit();

  reloadHistory = () => {
    const { contextVisitId } = this.props;
    this.updatePatientHistory(contextVisitId);
  };

  showModalCompleteVisit(description, warn) {
    showModal(CONFIRMATION, {
      open: true,
      icon: "exclamation-triangle",
      iconColor: "warning",
      title: "Confirm Complete",
      buttonColor: warn ? "orange" : "green",
      buttonMessage: warn ? "Complete visit anyway" : "Complete visit",
      description,
      onConfirm: () => {
        const {
          patientId,
          contextVisitType,
          contextVisitId,
          actions: { updatePatientVisitStatus, dischargePatient, updateDischargeSuccess }
        } = this.props;

        if (contextVisitType === "discharge") {
          updatePatientVisitStatus(contextVisitId, VISIT_STATUS_VISIT_COMPLETE).then(() => {
            dischargePatient(patientId, { dischargeReason: "manual" });
            updateDischargeSuccess(patientId, true);
            this.updatePatientHistory(contextVisitId);
          });
        } else {
          updatePatientVisitStatus(contextVisitId, VISIT_STATUS_VISIT_COMPLETE).then(() => {
            this.reloadHistory();
          });
        }
      },
      onCancel: () => {
        this.setState({
          selectedVisitId: this.props.contexVisitId,
          selectedVisitStatus: this.props.contextVisitStatus
        });
      }
    });
  }

  showModalCancelVisit() {
    showModal(CANCEL_VISIT_MODAL, {
      open: true,
      visitId: this.props.contextVisitId,
      preCancel: () => {},
      postCancel: this.reloadHistory
    });
  }

  updatePatientHistory = (visitId) => {
    this.props.actions.loadPatientHist(this.props.patientId).then(() => {
      const { patientId } = this.props;
      this.props.actions.createPatientHistoryItemByVisit(patientId, visitId);
    });
  };

  handleStatusChange = (e, data) => {
    const { value } = data;
    const {
      contextVisitId,
      contextVisitStatus,
      actions: { updatePatientVisitStatus }
    } = this.props;
    this.setState({ selectedVisitStatus: value });

    if (value === VISIT_STATUS_VISIT_COMPLETE) {
      this.handleCompleteVisit();
    } else if (value === VISIT_STATUS_VISIT_CANCELED) {
      this.handleCancelVisit();
    } else if (value !== contextVisitStatus) {
      updatePatientVisitStatus(contextVisitId, value);
    }
  };

  handleLevelUpdate = (e, data) => {
    const { value } = data;
    this.setPatientLevel(value, false);
  };

  handleBhLevelUpdate = (e, data) => {
    const { value } = data;
    this.setPatientLevel(value, true);
  };

  setPatientLevel = (value, bh) => {
    const { contextVisitId, currentLevel, currentBhLevel } = this.props;
    if ((!bh && value !== (currentLevel || {}).levelName) || (bh && value !== (currentBhLevel || {}).levelName)) {
      this.props.actions.setPatientLevel(this.props.patientId, contextVisitId, value, bh);
    }
  };

  handleTaskModalOpen = (e, d) => {
    showModal(NOTE_SLASH_TASK_MODAL, { open: true, isNew: true, type: d.value, patient: this.props.patient });
  };

  handleNewProgressNoteClick = () => {
    showModal(CONFIRMATION, {
      open: true,
      icon: "file text outline",
      title: "New General Note Visit",
      buttonColor: "red",
      description: "Create a new general note visit?",
      size: "tiny",
      onConfirm: () => {
        this.props.actions.createProgressNoteVisit(this.props.patientId, this.props.centerContext).then(() => {
          if (this.props.history.location !== `/current-patients/${this.props.patientId}/demographics`)
            this.props.history.push({
              pathname: `/current-patients/${this.props.patientId}/demographics`
            });
        });
      }
    });
  };

  handleVisitModalOpen = () => {
    const { centerContext, patientCenter } = this.props;
    if (centerContext && centerContext !== patientCenter) {
      return showModal(VISIT_CENTER_CONFLICT, {
        open: true,
        centerContext,
        patientCenter
      });
    }
    return showModal(NEW_VISIT, {
      open: true,
      isNewPatient: false,
      centerId: this.props.patientCenter
    });
  };

  handleChangeVisitTypeModalOpen = () => {
    const { patientId, contextVisitId, contextVisitType, availableTemplates, selectedTemplate } = this.props;
    return showModal(CHANGE_VISIT_TYPE_MODAL, {
      open: true,
      patientId,
      visitId: contextVisitId,
      visitType: contextVisitType,
      reloadTemplates: availableTemplates.length > 0 && selectedTemplate.templateId !== TEMPLATE_ID_MA_TEMPLAE_ID
    });
  };

  handleTelemedDataButtonClick = () => {
    const {
      patientId,
      contextVisitId,
      contextVisit,
      contextVisitType,
      availableTemplates,
      selectedTemplate,
      visitTypes
    } = this.props;
    const visitTypeObject = contextVisit ? visitTypes.find((v) => v.value === contextVisitType) : {};
    return showModal(TELEMED_DATA_MODAL, {
      open: true,
      patientId,
      visitId: contextVisitId,
      visitTypeObject,
      reloadTemplates: availableTemplates.length > 0 && selectedTemplate.templateId !== TEMPLATE_ID_MA_TEMPLAE_ID
    });
  };

  loadAuxiliaryFlags = (auxiliaryFlags) => {
    let auxFlagString = "";
    if (auxiliaryFlags && auxiliaryFlags.length !== 0) {
      auxFlagString = auxiliaryFlags.map((el, index) => {
        if (index === auxiliaryFlags.length - 1) {
          return `${el.auxiliaryFlagText}`;
        }
        return `${el.auxiliaryFlagText}, `;
      });
    }
    return auxFlagString;
  };

  handleDischarge = () => {
    const {
      patientId,
      history,
      patient: { patientFirstName, patientPreferredName, patientMiddleName, patientLastName, suffix }
    } = this.props;

    showModal(CONFIRMATION, {
      open: true,
      icon: "exclamation-triangle",
      title: "Confirm Discharge",
      description: `Are you sure that you want to discharge ${patientName(
        patientFirstName,
        patientPreferredName,
        patientMiddleName,
        patientLastName,
        suffix
      )}?`,
      buttonMessage: "Discharge",
      buttonColor: "red",
      size: "small",
      onConfirm: () => {
        this.setState({ dischargeButtonPressed: true });
        this.props.actions
          .dischargePatient(patientId, { dischargeReason: DISCHARGE_REASON_MANUAL_DISCHARGE })
          .then(() => {
            this.props.actions.updateDischargeSuccess(patientId, true);
            history.push(`/current-patients/${patientId}/discharge`);
          })
          .catch(() => this.setState({ dischargeButtonPressed: false }));
      }
    });
  };

  renderVisitDetails() {
    const {
      statuses,
      contextVisit,
      contextVisitType,
      contextVisitStatus,
      visitTypes,
      centers,
      authRoles,
      visitLocations
    } = this.props;
    const { selectedVisitStatus } = this.state;
    const statusOptions =
      selectedVisitStatus === "__LOADING__" ? [{ text: "Loading...", value: "__LOADING__" }] : statuses;
    const center = contextVisit ? getLookupText(contextVisit.centerId, centers) : "";
    const canEditVisitType = checkRoles(roleGroups.canEditVisitType, authRoles);
    const visitTypeObject = contextVisit ? visitTypes.find((v) => v.value === contextVisitType) : {};
    const isTelemedVisit = visitTypeObject.virtual;
    const isMedicalVisit = visitTypeObject.medical;
    const telemedDateMissing =
      (isMedicalVisit && !visitLocations.visitSpecimenCenterId) ||
      (!visitLocations.isPatientHome && !visitLocations.patientCenterId) ||
      (!visitLocations.isProviderHome && !visitLocations.providerCenterId);
    const visitType = contextVisit ? getLookupText(contextVisitType, visitTypes) : "";
    return (
      <React.Fragment>
        <div>
          {(!isEmpty(contextVisit) || contextVisitStatus !== VISIT_STATUS_VISIT_COMPLETE) && (
            <React.Fragment>
              <div className="current-visit">
                {(!canEditVisitType || !visitTypeObject.allowChangeVisitType) && (
                  <React.Fragment>
                    <Popup
                      trigger={
                        <div className="div-visit-type">
                          <i aria-hidden="true" className="icon terminal" />
                          {visitType}
                        </div>
                      }
                      content="Visit Type"
                    />
                  </React.Fragment>
                )}
                {canEditVisitType && visitTypeObject.allowChangeVisitType && (
                  <Popup
                    trigger={
                      <Button
                        className="transparent-button"
                        id="btn-visit-type"
                        size="small"
                        onClick={this.handleChangeVisitTypeModalOpen}
                      >
                        <BsIcon name="pencil-square" className="me-1" />
                        {visitType}
                      </Button>
                    }
                    content="Visit Type"
                  />
                )}
              </div>
              <div className="current-visit">
                {isTelemedVisit && (
                  <Popup
                    trigger={
                      <Button
                        color={telemedDateMissing ? "red" : "green"}
                        className="transparent-button"
                        id="btn-telemed-fields"
                        size="small"
                        onClick={this.handleTelemedDataButtonClick}
                      >
                        <BsIcon name="pencil-square" className="me-1" />
                        {`Telemed Data`}
                      </Button>
                    }
                    content="Telemed Data"
                  />
                )}
              </div>
              <div>
                <div className="current-visit">
                  <label className="visit-label">Visit Center:</label>
                  {center}
                </div>
              </div>
            </React.Fragment>
          )}
        </div>
        <Grid className="pb-3">
          {!isEmpty(contextVisit) && (
            <Grid.Column className="no-margin">
              <Form.Dropdown
                id="patient-summary-update-visit-status-ddl"
                fluid
                selection
                label="Status"
                name="status"
                placeholder="Select..."
                options={statusOptions}
                value={selectedVisitStatus}
                onChange={this.handleStatusChange}
                className="patientStatus"
                disabled={contextVisitStatus === VISIT_STATUS_VISIT_COMPLETE}
              />
            </Grid.Column>
          )}
        </Grid>
      </React.Fragment>
    );
  }

  render() {
    const {
      patient: {
        patientId,
        address,
        patientFirstName,
        patientPreferredName,
        patientMiddleName,
        patientLastName,
        suffix,
        patientDateOfBirth,
        primaryInsurance,
        medicalRecordNumber,
        level,
        bhLevel,
        image64,
        patientAuxiliaryFlagAlerts,
        patientMedicalAlert,
        patientInterventionAlert
      },
      levelOptions,
      bhLevelOptions,
      currentLevel,
      visitTypes,
      authRoles,
      patientExternalRefs,
      insuranceProviders,
      allergyHistory,
      status,
      contextVisit,
      contextVisitId,
      contextVisitType,
      activeVisits,
      isContextVisitInActiveVisits,
      deceasedDate
    } = this.props;
    const { selectedVisitId } = this.state;
    const stratusLink = this.getStratusExternalRef(patientExternalRefs);
    const firstAthenaExternalRef = Array.isArray(patientExternalRefs)
      ? patientExternalRefs.find((i) => i.sourceName === "athena")
      : null;
    const athenaPatientId =
      firstAthenaExternalRef && firstAthenaExternalRef.externalPrimaryPatientId
        ? firstAthenaExternalRef.externalPrimaryPatientId
        : "";
    const auxFlagString = this.loadAuxiliaryFlags(patientAuxiliaryFlagAlerts);
    const levelName = (currentLevel || {}).levelName || "";
    const showAddTask = checkRoles(roleGroups.canEditNoteSlashTask, authRoles);
    const showEditLevel = checkRoles(roleGroups.providers, authRoles);
    const showEditBhLevel = checkRoles(roleGroups.bhProviders, authRoles);
    const canCreateNewVisit = checkRoles(roleGroups.createNewVisit, authRoles);
    const canCreateProgressNoteVisit = checkRoles(roleGroups.createProgressNoteVisit, authRoles);
    const domain = new URL(window.location.href);
    const nonProd = domain.host.startsWith("localhost") || domain.host.startsWith("qa");
    const panes = [];
    if (contextVisit && !isContextVisitInActiveVisits) {
      panes.push({
        menuItem: (
          <Menu.Item id={`patient-summary-visit-tab-${contextVisitId}`} key={contextVisitId} className="green" active>
            <div>{getLookupText(contextVisitType, visitTypes)}</div>
            <div>({moment(contextVisit.visitDateTime).format(dateFormat)})</div>
          </Menu.Item>
        ),
        render: () => <Tab.Pane>{this.renderVisitDetails(true)}</Tab.Pane>
      });
    } else {
      activeVisits.forEach((v) => {
        panes.push({
          menuItem: (
            <Menu.Item
              id={`patient-summary-visit-tab-${v.visitId}`}
              key={v.visitId}
              as={NavLink}
              to={`/current-patients/${patientId}/demographics/vid/${v.visitId}`}
              className={v.visitId === selectedVisitId ? "blue" : ""}
              active={v.visitId === selectedVisitId}
            >
              <div>{getLookupText(v.visitType, visitTypes)}</div>
              <div>({moment(v.visitTime).format(dateFormat)})</div>
            </Menu.Item>
          ),
          render: () => <Tab.Pane>{this.renderVisitDetails()}</Tab.Pane>
        });
      });
    }

    const statusColorMap = {
      Prospective: "info",
      Active: "success",
      Inactive: "secondary",
      "Pending Discharge": "warning",
      Discharged: "danger"
    };

    return (
      <Grid padded>
        {contextVisit && !isContextVisitInActiveVisits && (
          <Grid.Row className="half-v-padding">
            <Grid.Column width={16}>
              <Segment size="small" textAlign="right" color="green" className="half-padding">
                {`You are viewing this patient in context of a completed visit, back to `}
                <Button color="blue" size="small" onClick={this.backToActiveVisits}>
                  Home
                </Button>
              </Segment>
            </Grid.Column>
          </Grid.Row>
        )}
        <Grid.Row style={{ paddingBottom: 0 }}>
          <Grid.Column width={9} className="right-border-condition">
            <div>
              <div className="patient-header">
                <div className="patient-info">
                  <div className="avatar">
                    <div
                      className="person"
                      style={
                        image64 === null
                          ? { backgroundImage: `url(${greyMan})` }
                          : { backgroundImage: `url(${image64})` }
                      }
                    >
                      {allergyHistory.length ? (
                        <Popup
                          className={`allergy-popup`}
                          trigger={
                            allergyHistory.length ? <Button className={`allergy_indicator`}>Allergy</Button> : ""
                          }
                          position="bottom left"
                          hideOnScroll
                        >
                          <Popup.Content className={`popup-content`}>
                            {allergyHistory.length
                              ? allergyHistory.map((allergy) => (
                                  <p key={allergy.itemId} className={`popup-item`}>
                                    {allergy.allergySource}
                                  </p>
                                ))
                              : null}
                          </Popup.Content>
                        </Popup>
                      ) : (
                        ""
                      )}
                    </div>
                    <OverlayTrigger
                      trigger="click"
                      placement="right"
                      overlay={
                        <Popover id="popover-basic" style={{ width: "40rem", backgroundColor: "#f0faff" }}>
                          <Popover.Body>
                            <PatientInfo patientId={patientId} />
                          </Popover.Body>
                        </Popover>
                      }
                      rootClose
                    >
                      <BsButton size="sm" variant="outline-dark" className="info">
                        <BsIcon name="info-circle" />
                      </BsButton>
                    </OverlayTrigger>
                    <div className="patient-level-container">
                      <PatientLevel
                        level={level ? level.levelName : "orange"}
                        className="patient-medical-level"
                        size="sm"
                      />
                      {bhLevel && <PatientLevel level={bhLevel.levelName} className="patient-bh-level" size="sm" bh />}
                    </div>
                  </div>
                  <div className="quickinfo">
                    <Header as="h2" id="lbl-patientName" className="no-margin">
                      <HeaderContent>
                        {patientName(
                          patientFirstName,
                          patientPreferredName,
                          patientMiddleName,
                          patientLastName,
                          suffix
                        )}
                        {!isEmpty(stratusLink) && (
                          <a
                            target="_blank"
                            href={stratusLink}
                            style={{ display: "inline-block", marginLeft: ".5rem" }}
                          >
                            <Image src={stratusIcon} className="stratus-icon" alt="Stratus Link" size="tiny" />
                          </a>
                        )}
                        {!isEmpty(deceasedDate) && (
                          <span
                            style={{ color: "blue", fontSize: "16px", marginLeft: ".5rem", display: "inline-block" }}
                          >
                            Patient Deceased: <span>{moment(deceasedDate).format(dateFormat)}</span>
                          </span>
                        )}
                        <Header.Subheader className="auxliary-flag">{auxFlagString}</Header.Subheader>
                      </HeaderContent>
                    </Header>
                    <div className="info1">
                      {address ? (
                        <React.Fragment>
                          {address.addressType === "Home" ? (
                            <div className="address" id="lbl-address">
                              <div>{address.address1}</div>
                              <div>{address.address2}</div>
                              <div>
                                {address.city}, {address.state} {address.zip}
                              </div>
                            </div>
                          ) : (
                            <div>{address.addressType}</div>
                          )}
                        </React.Fragment>
                      ) : (
                        "No address entered"
                      )}
                      <div className="insurance" id="lbl-insurance">
                        <span>Ins:</span>{" "}
                        {(primaryInsurance && getLookupText(primaryInsurance.insurer, insuranceProviders)) || "None"}
                      </div>
                    </div>
                    <div className="info2">
                      <div className="mrn" id="lbl-medicalRecordNumber">
                        {`MRN: ${medicalRecordNumber}`}
                      </div>
                      {athenaPatientId && <div>{`Athena Patient ID: ${athenaPatientId}`}</div>}
                      <div className="dob" id="lbl-patientDateOfBirth">
                        {`DOB: ${PatientSummary.formatDOB(patientDateOfBirth)}`}
                      </div>
                      <div className="age" id="lbl-age">
                        {`Age: ${PatientSummary.formatAge(patientDateOfBirth) || "Invalid date of birth was entered"}`}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="patient-flag text-center">
                  <h4 className="mb-2">
                    <Badge pill bg={statusColorMap[status]}>
                      {status}
                    </Badge>
                  </h4>
                  <BsButton
                    variant={status === "Pending Discharge" ? "warning" : "primary"}
                    onClick={this.handleDischarge}
                  >
                    {status === "Pending Discharge" ? `Pending Discharge` : `Discharge`}
                  </BsButton>
                  {/* <DropdownButton as={ButtonGroup} variant="danger" title="Discharge" align="end">
                    <BsDropdown.Item href="#/action-1">Action</BsDropdown.Item>
                    <BsDropdown.Item href="#/action-2">Another action</BsDropdown.Item>
                    <BsDropdown.Item href="#/action-3">Something else</BsDropdown.Item>
                  </DropdownButton> */}
                </div>
                <div>
                  <List>
                    {patientMedicalAlert &&
                      patientMedicalAlert.map((patientFlagItem) => (
                        <List.Item key={shortid.generate()}>
                          <Label
                            className="flag-label"
                            style={{
                              color: `${patientFlagItem ? patientFlagItem.color : ""}`,
                              backgroundColor: `${patientFlagItem ? patientFlagItem.backgroundColor : ""}`,
                              borderColor: `${patientFlagItem ? patientFlagItem.color : ""}`
                            }}
                          >
                            {patientFlagItem ? patientFlagItem.alertText : ""}
                          </Label>
                        </List.Item>
                      ))}
                  </List>
                </div>
              </div>
            </div>
            <div style={{ paddingTop: "0.5em" }}>
              {(patientInterventionAlert || []).map((flag) => (
                <Popup
                  key={flag.shortText}
                  content={flag.longText}
                  trigger={
                    <Label circular color={flag.color}>
                      <div style={{ padding: "0 .5em" }}>{flag ? flag.shortText : ""}</div>
                    </Label>
                  }
                />
              ))}
            </div>
          </Grid.Column>
          <Grid.Column width={7}>
            <div className="patient-tagging">
              <div>
                {canCreateProgressNoteVisit && (
                  <Button
                    id="patient-summary-plus-progress-note-button"
                    className="transparent-button"
                    size="small"
                    onClick={this.handleNewProgressNoteClick}
                  >
                    <BsIcon name="plus-lg" className="me-1" />
                    General Note
                  </Button>
                )}
                {nonProd && canCreateNewVisit && (
                  <Button
                    id="patient-summary-plus-visit-button"
                    className="transparent-button"
                    size="small"
                    onClick={this.handleVisitModalOpen}
                  >
                    <BsIcon name="plus-lg" className="me-1" />
                    Visit
                  </Button>
                )}
                {showAddTask && (
                  <>
                    <Button
                      id="patient-summary-plus-task-button"
                      className="transparent-button"
                      size="small"
                      onClick={() => {
                        this.handleTaskModalOpen(null, { value: "task" });
                      }}
                      style={{ backgroundColor: "#e3f5ff" }}
                    >
                      <Icon name={"plus"} />
                      Task
                    </Button>
                    <Button
                      id="patient-summary-plus-note-button"
                      className="transparent-button"
                      size="small"
                      onClick={() => {
                        this.handleTaskModalOpen(null, { value: "note" });
                      }}
                      style={{ backgroundColor: "#f8f8dc" }}
                    >
                      <Icon name={"plus"} />
                      Note
                    </Button>
                  </>
                )}
              </div>
              <Tab
                id="patient-summary-active-visits"
                menu={{ id: "patient-summary-active-visits-list", fluid: true, vertical: true, tabular: "right" }}
                grid={{ paneWidth: 9, tabWidth: 7 }}
                panes={panes}
              />
              <Grid className="summary-dropdown-container">
                {showEditLevel && (
                  <Grid.Column width={8} className="padding-bottom-5px">
                    <Form.Dropdown
                      id="patient-summary-update-patient-level-ddl"
                      fluid
                      selection
                      label="Level"
                      name="level"
                      placeholder="Select..."
                      options={levelOptions}
                      value={levelName}
                      onChange={this.handleLevelUpdate}
                      className="patient-level-ddl"
                    />
                  </Grid.Column>
                )}
                {showEditBhLevel && (
                  <Grid.Column width={8} className="padding-bottom-5px">
                    <Form.Dropdown
                      id="patient-summary-update-patient-bhLevel-ddl"
                      fluid
                      selection
                      label="BH Level"
                      name="bHevel"
                      placeholder="Select..."
                      options={bhLevelOptions}
                      value={bhLevel?.levelName}
                      onChange={this.handleBhLevelUpdate}
                      className="patient-level-ddl"
                    />
                  </Grid.Column>
                )}
              </Grid>
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  const patientId = state.patient.currentPatient.patientId;
  const patient = (state.patient.currentPatient || {}).patientId
    ? state.patient.currentPatient
    : {
        address: { address1: "", city: "", state: "", zip: "" },
        firstName: "",
        lastName: "",
        patientDateOfBirth: "",
        medicalRecordNumber: "",
        patientId: null,
        level: null
      };
  const contextVisit = state.visits.contextVisit;
  const contextVisitId = contextVisit ? contextVisit.visitId : null;
  const contextVisitType = contextVisit ? contextVisit.visitType : null;
  const contextVisitStatus = contextVisit && contextVisit.visitStatus ? contextVisit.visitStatus.visitStatus : null;

  const currentLevel = state.patient.currentPatient ? state.patient.currentPatient.level : {};
  const currentBhLevel = state.patient.currentPatient ? state.patient.currentPatient.bhLevel : {};

  return {
    patientId,
    currentLevel,
    currentBhLevel,
    bhLevel: state.patient.currentPatient?.bhLevel,
    patient,
    levelOptions: state.lookups.levels
      .filter((l) => l.isMedicalLevel)
      .map((level) => ({
        value: level.text,
        text: level.text
      })),
    bhLevelOptions: state.lookups.levels
      .filter((l) => l.isBHLevel)
      .map((level) => ({
        value: level.text,
        text: level.text
      })),
    processing: state.ajaxCallsInProgress > 0,
    statuses: state.lookups.visitStatuses,
    activeVisits: state.visits.activeVisits,
    contextVisit,
    contextVisitId,
    contextVisitType,
    contextVisitStatus,
    visitLocations: state.visits.contextVisitLocations,
    isContextVisitInActiveVisits: selectIsContextVisitInActiveVisits(state),
    visitTypes: state.lookups.visitTypes,
    centers: state.lookups.centers,
    insuranceProviders: state.lookups.insuranceProviders,
    centerContext: state.userPreference.centerContext,
    authRoles: state.auth.user.profile.roles,
    medicalHistory: state.patientHistory.medicalHistory || [],
    interventionHistory: state.patientHistory.interventionHistory || [],
    allergyHistory: state.patientHistory.allergyHistory || [],
    familyHistory: state.patientHistory.familyHistory || [],
    selectedTemplate: state.templates.selectedTemplate,
    availableTemplates: state.templates.availableTemplates,
    patientCenter: state.demographics.homeCenterId,
    auxiliaryFlags: state.demographics.auxiliaryFlags,
    status: state.demographics.status,
    deceasedDate: state.demographics.deceasedDate,
    patientExternalRefs: state.patient.patientExternalRefs
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      ...bindActionCreators(
        {
          ...patientActions,
          ...lookupActions,
          ...visitActions,
          ...templateActions,
          ...demographicActions,
          ...patientHistoryActions,
          ...prescriptionActions
        },
        dispatch
      ),
      getNotReviewedLabResults: ({ patientId }) =>
        dispatch(extendedApiSlice.endpoints.getNotReviewedLabResults.initiate({ patientId })),
      getReviewedLabResultsHistory: ({ patientId, visitId }) =>
        dispatch(extendedApiSlice.endpoints.getReviewedLabResultsHistory.initiate({ patientId, visitId }))
    }
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PatientSummary));
