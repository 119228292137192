import React, { Component } from "react";
import shortid from "shortid";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as patientHistoryActions from "../../../../actions/patientHistoryActions";
import * as modalActions from "../../../../actions/modalActions";

import { roleGroups } from "../../../../constants/securityRoles";
import { checkRoles } from "../../../../helpers";

import MedicalHistoryTableRow from "./MedicalHistoryTableRow";
import "./MedicalHistoryTable.css";

export class MedicalHistoryTable extends Component {
  handleEditMedicalHistory = (e, data) => {
    const { medicalHistory } = this.props;
    switch (data.icon) {
      case "delete":
        this.props.actions.deleteMedicalHist(this.props.patientId, medicalHistory[data.index].itemId);
        break;
      case "plus":
      case "edit":
        this.props.actions.showModal({
          type: "ADD_MEDICAL_HISTORY",
          props: {
            open: true,
            medicalHistory: medicalHistory[data.index],
            isEdit: true
          }
        });
        break;
      default:
        break;
    }
  };
  render() {
    const { medicalHistory, authRoles } = this.props;
    const showEditHistory = checkRoles(roleGroups.excludeHelpDesk, authRoles);
    const showDeleteHistory = checkRoles(roleGroups.providers, authRoles);
    return (
      <div className="medicalHistoryTable">
        <table>
          <thead>
            <tr>
              <th>Condition</th>
              <th width="20%">Start</th>
              <th width="20%">End</th>
              <th width="5%">Resolved</th>
              <th width="5%">Note</th>
              <th width="5%" />
              <th width="5%" />
              <th width="5%" />
            </tr>
          </thead>
          <tbody>
            {medicalHistory.length
              ? medicalHistory.map((historyItem, i) => (
                  <MedicalHistoryTableRow
                    key={shortid.generate()}
                    index={i}
                    medicalHistory={historyItem}
                    handleEditMedicalHistory={this.handleEditMedicalHistory}
                    showEditHistory={showEditHistory}
                    showDeleteHistory={showDeleteHistory}
                  />
                ))
              : null}
          </tbody>
        </table>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    patientId: state.patient.currentPatient.patientId,
    medicalHistory: state.patientHistory.medicalHistory,
    authRoles: state.auth.user.profile.roles
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...patientHistoryActions, ...modalActions }, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MedicalHistoryTable);
