import { API_URL } from "../constants/environment";
import { adalApiFetch } from "../adalConfig";

export const fetchPatientDefaultPharmacy = patientId =>
  adalApiFetch(`${API_URL}/Patients/${patientId}/DefaultPharmacy`, {
    method: "GET"
  });

export const addPatientDefaultPharmacy = (patientId, selectedPharmacy) =>
  adalApiFetch(`${API_URL}/Patients/${patientId}/DefaultPharmacy`, {
    method: "POST",
    body: JSON.stringify(selectedPharmacy)
  });

export default {
  fetchPatientDefaultPharmacy,
  addPatientDefaultPharmacy
};
