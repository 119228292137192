import initialState from "./initialState";

import * as types from "../actions/actionTypes";

export default function modalReducer(state = initialState.lookups, action) {
  switch (action.type) {
    case types.LOAD_REACTIONS_SUCCESS:
      return Object.assign({}, state, {
        reactionTypes: action.reactionTypes
      });
    case types.LOAD_STATES_SUCCESS:
      return Object.assign({}, state, {
        states: action.states
      });
    case types.LOAD_INTEGRATION_KEYS_SUCCESS:
      return Object.assign({}, state, {
        integrationKeys: action.integrationKeys
      });
    case types.LOAD_LIVING_SITUATIONS_SUCCESS:
      return Object.assign({}, state, {
        livingSituations: action.livingSituations
      });
    case types.LOAD_BILLING_CODES_SUCCESS:
      return Object.assign({}, state, {
        billingCodes: action.billingCodes
      });
    case types.LOAD_VISIT_TYPES_SUCCESS:
      return Object.assign({}, state, {
        visitTypes: action.visitTypes
      });
    case types.LOAD_MSP_OPTIONS_SUCCESS:
      return Object.assign({}, state, {
        mspOptions: action.mspOptions
      });
    case types.LOAD_LANGUAGES_SUCCESS:
      return Object.assign({}, state, { languages: action.languages });
    case types.LOAD_GENDERPRONOUNS_SUCCESS:
      return Object.assign({}, state, {
        genderPronouns: action.genderPronouns
      });
    case types.LOAD_GENDER_IDENTITIES_SUCCESS:
      return Object.assign({}, state, {
        genderIdentities: action.genderIdentities
      });
    case types.LOAD_SEXUALORIENTATIONS_SUCCESS:
      return Object.assign({}, state, {
        sexualOrientations: action.sexualOrientations
      });
    case types.LOAD_SEXASSIGNEDATBIRTHS_SUCCESS:
      return Object.assign({}, state, {
        sexAssignedAtBirths: action.sexAssignedAtBirths
      });
    case types.LOAD_TREATMENTS_SUCCESS:
      return Object.assign({}, state, { treatments: action.treatments });
    case types.LOAD_APPOINTMENT_CANCEL_REASON_SUCCESS:
      return Object.assign({}, state, { appointmentCancelReason: action.appointmentCancelReason });
    case types.LOAD_RACES_SUCCESS:
      return Object.assign({}, state, { races: action.races });
    case types.LOAD_ETHNICITIES_SUCCESS:
      return Object.assign({}, state, { ethnicities: action.ethnicities });
    case types.LOAD_MARITAL_STATUSES_SUCCESS:
      return Object.assign({}, state, { maritalStatuses: action.maritalStatuses });
    case types.LOAD_RELATIONSHIPS_SUCCESS:
      return Object.assign({}, state, { relationships: action.relationships });
    case types.LOAD_INS_RELATIONSHIPS_SUCCESS:
      return Object.assign({}, state, {
        insuranceRelationships: action.insuranceRelationships
      });
    case types.LOAD_COMM_PREF_SUCCESS:
      return Object.assign({}, state, {
        communicationPreferences: action.communicationPreferences
      });
    case types.LOAD_DISCHARGE_REASONS_SUCCESS:
      return Object.assign({}, state, {
        dischargeReasons: action.dischargeReasons
      });
    case types.LOAD_VISIT_STATUSES_SUCCESS:
      return Object.assign({}, state, {
        visitStatuses: action.visitStatuses
      });
    case types.LOAD_ASSIGNED_TO_SUCCESS:
      return Object.assign({}, state, {
        taskAssignedTo: action.taskAssignedTo
      });
    case types.FETCH_TASK_ROLES_DONE:
      return { ...state, taskRoles: action.data };
    case types.FETCH_ROLE_GROUPS_DONE:
      return { ...state, roleGroups: action.data };
    case types.LOAD_LEVELS_SUCCESS:
      return Object.assign({}, state, {
        levels: action.levels
      });
    case types.LOAD_CENTERS_LOOKUP_SUCCESS:
      return Object.assign({}, state, {
        centers: action.centers
      });
    case types.LOAD_INSURANCE_PROV_SUCCESS:
      return Object.assign({}, state, {
        insuranceProviders: action.insuranceProviders
      });
    case types.LOAD_PCP_OPTIONS_SUCCESS:
      return Object.assign({}, state, {
        pcpOptions: action.pcpOptions
      });
    case types.LOAD_AUX_FLAGS_SUCCESS:
      return Object.assign({}, state, {
        auxFlags: action.auxFlags
      });
    case types.LOAD_GROUP_TYPES_SUCCESS:
      return Object.assign({}, state, {
        groupTypes: action.groupTypes
      });
    case types.LOAD_VISIT_TYPE_GROUPS_SUCCESS:
      return Object.assign({}, state, {
        visitTypeGroups: action.visitTypeGroups
      });
    case types.LOAD_DRUG_FORMS_SUCCESS:
      return Object.assign({}, state, {
        drugForms: action.drugForms
      });
    case types.LOAD_DRUG_ROUTES_SUCCESS:
    case types.CLEAR_DRUG_ROUTES_SUCCESS:
      return Object.assign({}, state, {
        drugRoutes: action.drugRoutes
      });
    case types.LOAD_DRUG_STRENGTHS_SUCCESS:
    case types.CLEAR_DRUG_STRENGTHS_SUCCESS:
      return Object.assign({}, state, {
        drugStrengths: action.drugStrengths
      });
    case types.LOAD_NUMBER_PER_DOSE_OPTIONS_SUCCESS:
      return Object.assign({}, state, {
        numberPerDoseOptions: action.numberPerDoseOptions
      });
    case types.LOAD_REFILL_OPTIONS_SUCCESS:
      return Object.assign({}, state, {
        refillOptions: action.refillOptions
      });
    case types.LOAD_PRESCRIPTION_FREQUENCY_OPTIONS_SUCCESS:
      return Object.assign({}, state, {
        frequencyOptions: action.frequencyOptions
      });
    case types.LOAD_DOCUMENT_TAGS_SUCCESS:
      return Object.assign({}, state, {
        documentTags: action.documentTags
      });
    case types.LOAD_PRESCRIBER_SLOTS_SUCCESS:
      return Object.assign({}, state, {
        prescribersWithSlots: action.prescribersWithSlots
      });
    case types.CLEAR_PRESCRIBER_SLOTS:
      return { ...state, prescribersWithSlots: [] };
    case types.LOAD_RX_AUTHENTICATION_TYPES_SUCCESS:
      return Object.assign({}, state, {
        rxAuthenticationTypes: action.rxAuthenticationTypes
      });
    case types.LOAD_ATTESTATION_OPTIONS_SUCCESS:
      return Object.assign({}, state, {
        attestationOptions: action.attestationOptions
      });
    case types.LOAD_ALL_CONDITION_TO_SUCCESS:
      return Object.assign({}, state, {
        conditions: action.conditionsResult
      });
    case types.LOAD_INTERVENTION_CONDITIONS_SUCCESS:
      return { ...state, interventionConditions: action.interventionConditions };
    case types.LOAD_ALL_ALLERGY_TO_SUCCESS:
      return Object.assign({}, state, {
        allergies: action.allergiesResult
      });
    case types.LOAD_ALL_DRUG_TO_SUCCESS:
      return Object.assign({}, state, {
        drugs: action.drugsResult
      });
    case types.LOAD_ALL_FAMILY_MEDICAL_CONDITIONS_SUCCESS:
      return { ...state, familyMedicalConditions: action.result };
    case types.LOAD_LABS_UNIQUE_SUCCESS:
      return { ...state, labsUnique: action.data };
    case types.LOAD_LAB_SPECIMEN_TYPES_SUCCESS:
      return { ...state, labSpecimenTypes: action.data };
    case types.LOAD_LAB_TEST_TYPES_SUCCESS:
      return { ...state, labTestTypes: action.data };
    case types.LOAD_LAB_TEST_STATUSES_SUCCESS:
      return { ...state, labTestStatuses: action.data };
    case types.LOAD_LAB_PROVIDERS_SUCCESS:
      return { ...state, labProviders: action.data };
    case types.LOAD_LAB_ORDER_STATUSES_SUCCESS:
      return { ...state, labOrderStatuses: action.data };
    case types.LOAD_EXOSTAR_ACTION_TYPES_SUCCESS:
      return { ...state, exostarActionTypes: action.data };
    case types.LOAD_TASK_TEMPLATES_SUCCESS:
      return { ...state, taskTemplates: action.data };
    case types.LOAD_NOTE_TEMPLATES_SUCCESS:
      return { ...state, noteTemplates: action.data };
    case types.LOAD_PATIENT_CUSTOM_FIELDS_SUCCESS: {
      return { ...state, patientCustomFields: action.data };
    }
    case types.ADD_TO_CACHED: {
      return { ...state, cached: [...new Set([...state.cached, action.path])] };
    }
    case types.CLEAR_LOOKUP_CACHE: {
      const updatedStatus = { ...state };
      const key = action.path.split(".")[0];
      updatedStatus[key] = {};
      updatedStatus.cached = updatedStatus.cached.filter((i) => i !== action.path);
      return updatedStatus;
    }
    default:
      return state;
  }
}
