import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function providersReducer(state = initialState.charts, action) {
  switch (action.type) {
    case types.LOAD_CHARTS_TO_SIGN_SUCCESS:
      return Object.assign({}, state, {
        chartsToSign: action.charts.chartsToSign
      });
    case types.CLEAR_CHART_DETAILS:
      return { ...state, latestVisitSignedChart: { loading: true } };
    case types.LOAD_CHART_DETAILS_SUCCESS:
      return Object.assign({}, state, {
        latestVisitSignedChart: action.details
      });
    case types.CLEAR_COSIGNED_CHART_DETAIL:
      return { ...state, cosignDetail: { loading: true } };
    case types.LOAD_COSIGNED_CHART_DETAIL_SUCCESS:
      return Object.assign({}, state, { cosignDetail: action.cosignDetail });
    case types.COSIGN_VISIT_SUCCESS:
    case types.SIGN_CHART_SUCCESS:
    default:
      return state;
  }
}
