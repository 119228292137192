import React from "react";
import { Button } from "semantic-ui-react";
import { Link } from "react-router-dom";

import "./ButtonLink.css";

const ButtonLink = ({ className = "", ...props }) => (
  <Button color="blue" as={Link} className={["link", className].join(" ")} {...props} basic />
);

export default ButtonLink;
