import { toast } from "react-toastify";
import * as types from "./actionTypes";
import { adalApiFetch } from "../adalConfig";
import { API_URL } from "../constants/environment";
import { beginAjaxCall, ajaxCallDone, ajaxCallError } from "./ajaxStatusActions";
import { processApiError } from "../helpers";
import { toastErrorOptions } from "../constants/toastconfig";

function setErrorLogs(data, recoverable) {
  return {
    type: recoverable ? types.SET_RECOVERABLE_ERRORS : types.SET_NON_RECOVERABLE_ERRORS,
    data
  };
}

function setSelectedErrorDetails(data) {
  return {
    type: types.SET_SELECTED_ERROR_DETAILSS,
    data
  };
}

function setErrorKeywords(data) {
  return {
    type: types.SET_ERROR_KEYWORDS,
    data
  };
}

export function loadErrorLogs(
  pageSize = 15,
  pageNumber = 1,
  filters = {},
  orderBySourceHandler = false,
  recoverable = false
) {
  const endPoint = recoverable ? "RecoverableErrorLogs" : "ErrorLogs";
  return dispatch => {
    dispatch(beginAjaxCall("loadErrorLogs"));
    const params = {
      pageSize,
      pageNumber,
      fromDay: filters.from.day,
      fromMonth: filters.from.month + 1,
      fromYear: filters.from.year,
      toDay: filters.to.day,
      toMonth: filters.to.month + 1,
      toYear: filters.to.year,
      orderBySourceHandler
    };
    const urlParams = Object.keys(params)
      .map(k => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
      .join("&");
    return adalApiFetch(`${API_URL}/Admin/${endPoint}?${urlParams}`, {
      method: "POST",
      body: JSON.stringify(filters.filterQuery)
    })
      .then(data => {
        if (data.message && data.message === "Validation Failed") {
          /* response status 422 */
          processApiError(data.errors[0].message).then(dispatch(ajaxCallError(data.errors[0].message)));
          return;
        }
        dispatch(setErrorLogs(data, recoverable));
      })
      .catch(error => {
        processApiError(error, dispatch);
      })
      .finally(() => {
        dispatch(ajaxCallDone());
      });
  };
}

export function loadSelectedErrorDetails(id, recoverable = false) {
  const endPoint = recoverable ? "RecoverableErrorLogs" : "ErrorLogs";
  return dispatch => {
    dispatch(beginAjaxCall("loadErrorLogs"));
    return adalApiFetch(`${API_URL}/Admin/${endPoint}/${id}`)
      .then(data => dispatch(setSelectedErrorDetails(data)))

      .catch(error => {
        processApiError(error, dispatch);
      })
      .finally(() => {
        dispatch(ajaxCallDone());
      });
  };
}

export function clearSelectedError() {
  return dispatch => {
    dispatch(
      setSelectedErrorDetails({
        id: "",
        sourceHandler: "",
        errorType: "",
        errorSource: "",
        errorMessage: "",
        errorUserMessage: "",
        stackTrace: "",
        sourceMessageType: "",
        sourceMessageContent: "{}",
        orchestrationId: "",
        identityContext: "",
        platformEventId: "",
        timeStamp: "",
        username: ""
      })
    );
  };
}

export function dismissError(id, recoverable = false) {
  const endPoint = recoverable ? "RecoverableErrorLogs" : "ErrorLogs";
  return dispatch => {
    dispatch(beginAjaxCall("dismissError"));
    return adalApiFetch(
      `${API_URL}/Admin/${endPoint}/${id}`,
      {
        method: "DELETE"
      },
      false
    )
      .catch(error => {
        processApiError(error, dispatch);
      })
      .finally(() => {
        dispatch(ajaxCallDone());
      });
  };
}

export function requeueError(id, messageContent = "") {
  return dispatch => {
    dispatch(beginAjaxCall("requeueError"));
    return adalApiFetch(
      `${API_URL}/Admin/RecoverableErrorLogs/${id}`,
      {
        method: "POST",
        body: JSON.stringify({ messageContent })
      },
      false
    )
      .then(() => toast.success(`Recoverable error [id: ${id}] has successfully been re-queue'd`, toastErrorOptions))
      .catch(error => {
        processApiError(error, dispatch);
      })
      .finally(() => {
        dispatch(ajaxCallDone());
      });
  };
}

export function loadErrorKeywords() {
  return dispatch => {
    dispatch(beginAjaxCall("loadErrorKeywords"));
    return adalApiFetch(`${API_URL}/Admin/ErrorKeywords`)
      .then(data => dispatch(setErrorKeywords(data)))
      .catch(error => {
        processApiError(error, dispatch);
      })
      .finally(() => {
        dispatch(ajaxCallDone());
      });
  };
}
