import React from "react";
import { Table, Icon, Button } from "semantic-ui-react";
import moment from "moment";
import map from "lodash/map";
import { timeFormat, longDateFormat } from "../../../../constants/miscellaneous";

const AllergyTableRow = ({
  allergy: { allergySource, notes },
  handleEditAllergy,
  index,
  reactionText,
  showEditHistory,
  showDeleteHistory
}) => (
  <React.Fragment>
    <tr>
      <td data-label="Medication/allergen" className="allergy-row-item" colSpan={2}>
        <strong>{allergySource}</strong>
      </td>
      <td data-label="Reaction" colSpan={3}>
        {reactionText}
      </td>
      <td data-label="Note">
        {showEditHistory ? (
          <Button icon="plus" index={index} className="transparent-button-icon" onClick={handleEditAllergy} />
        ) : (
          <span>&nbsp;</span>
        )}
      </td>
      <td data-label="&nbsp;">
        {notes && notes.length > 0 ? (
          <Icon name="sticky note outline" />
        ) : (
          <Icon className="opacity" name="sticky note outline" />
        )}
      </td>
      <td data-label="&nbsp;">
        {showEditHistory ? (
          <Button icon="edit" index={index} className="transparent-button-icon" onClick={handleEditAllergy} />
        ) : (
          <span>&nbsp;</span>
        )}
      </td>
      <td data-label="&nbsp;">
        {showDeleteHistory ? (
          <Button icon="delete" index={index} className="transparent-button-icon delete" onClick={handleEditAllergy} />
        ) : (
          <span>&nbsp;</span>
        )}
      </td>
    </tr>
    {notes
      ? map(notes, (note, i) => (
          <React.Fragment key={`${note.noteId}-${note.noteAddedOn}`}>
            <Table.Row className="tr-tritangle">
              {i === 0 && (
                <React.Fragment>
                  <Table.Cell className="first-tritangle" colSpan={6} />
                  <Table.Cell className="first-tritangle triangle-row-item">
                    <div className="note-background-first" />
                  </Table.Cell>
                  <Table.Cell className="first-tritangle" colSpan={2} />
                </React.Fragment>
              )}
            </Table.Row>
            <Table.Row className="note-background" key={i}>
              {note.noteAddedOn && (
                <Table.Cell colSpan="5" className="general-info1">
                  <strong>{moment(note.noteAddedOn).format(longDateFormat)} </strong>
                  <span>{moment(note.noteAddedOn).format(timeFormat)}</span>
                </Table.Cell>
              )}
              <Table.Cell colSpan="5" className="general-info2">
                {note.creatorFullName}
              </Table.Cell>
            </Table.Row>
            <Table.Row className="note-background">
              <Table.Cell colSpan={9}>
                <p>{note.text}</p>
              </Table.Cell>
            </Table.Row>
          </React.Fragment>
        ))
      : null}
  </React.Fragment>
);

export default AllergyTableRow;
