/* eslint-disable max-len */
import React from "react";

export const UrgentTask = () => (
  <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24" fill="#ed1c24">
    <title>Urgent Task</title>
    <path
      transform="translate(-1 0.06)"
      d="M 22 1.64 H 15.8 a 1.5 1.5 0 0 0 0 -0.19 A 1.51 1.51 0 0 0 14.31 -0.06 h -3.4 A 1.51 1.51 0 0 0 9.4 1.45 a 1.5 1.5 0 0 0 0 0.19 H 3.27 A 2.27 2.27 0 0 0 1 3.91 V 22.59 a 2.27 2.27 0 0 0 2.26 2.26 H 22 a 2.27 2.27 0 0 0 2.26 -2.26 V 3.91 A 2.27 2.27 0 0 0 22 1.64 Z m 0.57 21.52 H 2.7 V 3.34 H 5.62 V 5.79 h 14 V 3.34 h 2.93 Z"
    />
    <path transform="translate(-1 0.06)" d="M 13.91 16.43 H 11.48 V 8.49 h 2.43 Z m 0 4.17 H 11.48 V 18.47 h 2.43 Z" />
  </svg>
);

export default UrgentTask;
