import React, { Component } from "react";
import { Button } from "semantic-ui-react";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as modalActions from "../../../../actions/modalActions";
import * as lookupActions from "../../../../actions/lookupActions";

import FamilyHistoryTable from "./FamilyHistoryTable";
import FamilyHistorySlider from "./FamilyHistorySlider";

import { roleGroups } from "../../../../constants/securityRoles";
import { checkRoles } from "../../../../helpers";

import "../TableSliderComponent.css";

export class ManageFamilyHistory extends Component {
  state = {
    sliderVisible: null,
    searchValue: ""
  };

  componentDidMount() {
    this.props.actions.loadFamilyMedicalConditions();
  }

  handleModalOpen = () => {
    this.setState(
      {
        sliderVisible: false
      },
      () =>
        this.props.actions.showModal({
          type: "SEARCH_CONDITION",
          props: {
            open: true,
            searchValue: this.state.searchValue,
            isFamilyHistory: true
          }
        })
    );
  };

  sliderOpen = () => {
    this.setState({
      sliderVisible: true
    });
  };

  sliderClose = () => {
    this.setState({
      sliderVisible: false
    });
  };

  handleSearchInput = (e, data) => {
    this.setState({ searchValue: data.value });
  };

  handleSearchEnter = (e) => {
    if (e.key === "Enter") {
      this.handleModalOpen();
    }
  };

  handleSearchClick = () => {
    this.handleModalOpen();
  };

  handleQuickSearch = (value) => {
    this.props.actions.showModal({
      type: "ADD_FAMILY_HISTORY",
      props: {
        open: true,
        condition: value.condition
      }
    });
  };

  render() {
    const { authRoles, conditions } = this.props;
    const { sliderVisible } = this.state;
    const showAddHistory = checkRoles(roleGroups.excludeHelpDesk, authRoles);
    return (
      <div className="table-slider-container">
        <h2>Family History</h2>
        {showAddHistory && (
          <Button
            icon="plus"
            className="slider-open transparent-button-icon"
            onClick={this.sliderOpen}
            id="btn-openFamHist"
          />
        )}
        <FamilyHistoryTable familyHistory={this.props.familyHistory} />
        <FamilyHistorySlider
          conditions={conditions}
          sliderVisible={sliderVisible}
          sliderClose={this.sliderClose}
          handleModalOpen={this.handleModalOpen}
          handleQuickSearch={this.handleQuickSearch}
          handleSearchEnter={this.handleSearchEnter}
          handleSearchInput={this.handleSearchInput}
          handleSearchClick={this.handleSearchClick}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    authRoles: state.auth.user.profile.roles,
    conditions: state.lookups.familyMedicalConditions
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...modalActions, ...lookupActions }, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageFamilyHistory);
