const roles = [
  { key: "allRoles", text: "All Roles", value: "EMR-AllRoles" },
  { key: "billingDirector", text: "Billing Director", value: "EMR-BillingDirector" },
  { key: "billingManager", text: "Billing Manager", value: "EMR-BillingManager" },
  { key: "billingSpecialist", text: "Billing Specialist", value: "EMR-BillingSpecialist" },
  { key: "callCenter", text: "Call Center", value: "EMR-CallCenter" },
  { key: "careCoordinator", text: "Care Coordinator", value: "EMR-CareCoordinator" },
  { key: "centerManager", text: "Center Manager", value: "EMR-CenterManager" },
  { key: "certRecovSpecialist", text: "Certified Recovery Specialist", value: "EMR-CertifiedRecoverySpecialist" },
  { key: "helpDesk", text: "Help Desk", value: "EMR-HelpDesk" },
  { key: "labTech", text: "Lab Technician", value: "EMR-LabTechnician" },
  { key: "ldac1", text: "LDAC1", value: "EMR-LDAC1" },
  { key: "ldac2", text: "LDAC2", value: "EMR-LDAC2" },
  { key: "medicalAssist", text: "Medical Assistant", value: "EMR-MedicalAssistant" },
  { key: "provider", text: "Provider", value: "EMR-Provider" },
  { key: "receptionist", text: "Receptionist", value: "EMR-Receptionist" },
  { key: "socialWorker", text: "Social Worker", value: "EMR-SocialWorker" },
  { key: "supProvider", text: "Supervising Provider", value: "EMR-SupervisingProvider" },
  { key: "systemAdmin", text: "System Administrator", value: "EMR-SystemAdministrator" }
];

export default roles;
