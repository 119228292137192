import React, { Component } from "react";
import { Button, Modal, Stepper } from "../../../ui";
import { hideModal } from "../../../helpers";
import CoreDemographics from "../demographics/core/CoreDemographics";
// import Addresses from "../demographics/addresses";
import AdditionalInformation from "../demographics/additional-information";
import MilitaryService from "../demographics/military-service";
import AdvancedDirectives from "../demographics/accordion/AdvancedDirectives";
import PCP from "../demographics/pcp";
import OutsideProviders from "../demographics/outside-providers";

export class IntakeModal extends Component {
  state = {
    activeStep: "1"
  };

  handleStepClick = (e, eventKey) => {
    this.setState({
      activeStep: eventKey
    });
  };

  goToNextStep = () => {
    this.setState({
      activeStep: `${+this.state.activeStep + 1}`
    });
  };

  handleClose = () => {
    hideModal();
  };

  handleFocus = (e) => {
    e.target.setAttribute("autocomplete", "nope");
  };

  render() {
    const { activeStep } = this.state;
    return (
      <Modal className="intake-modal" size="xl" backdrop="static" keyboard={false} onHide={this.handleClose} show>
        <Modal.Header closeButton>
          <Modal.Title>{`Intake`}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="no-padding" style={{ minHeight: "300px" }}>
          <Stepper activeKey={activeStep} defaultActiveKey="1" style={{ minHeight: "300px" }}>
            <Stepper.Item>
              <Stepper.Header onClick={this.handleStepClick}>Identification</Stepper.Header>
              <Stepper.Body className="no-padding">
                <CoreDemographics
                  _for="intake"
                  saveButtonText="Save and Continue"
                  saveButtonIcon="bi bi-chevron-right"
                  afterSave={() => {
                    this.goToNextStep();
                  }}
                  hideCancelButton
                />
              </Stepper.Body>
            </Stepper.Item>

            {/* <Stepper.Item>
              <Stepper.Header onClick={this.handleStepClick}>Current Address</Stepper.Header>
              <Stepper.Body className="half-padding">
                <Addresses
                  handleContinue={() => {
                    this.goToNextStep();
                  }}
                />
              </Stepper.Body>
            </Stepper.Item> */}

            <Stepper.Item>
              <Stepper.Header onClick={this.handleStepClick}>Additional Information</Stepper.Header>
              <Stepper.Body className="half-padding">
                <AdditionalInformation
                  saveButtonText="Save and Continue"
                  saveButtonIcon="bi bi-chevron-right"
                  afterSave={() => {
                    this.goToNextStep();
                  }}
                  hideCancelButton
                />
              </Stepper.Body>
            </Stepper.Item>

            <Stepper.Item>
              <Stepper.Header onClick={this.handleStepClick}>PCP</Stepper.Header>
              <Stepper.Body className="half-padding">
                <PCP
                  handleContinue={() => {
                    this.goToNextStep();
                  }}
                />
              </Stepper.Body>
            </Stepper.Item>

            <Stepper.Item>
              <Stepper.Header onClick={this.handleStepClick}>Outside Providers</Stepper.Header>
              <Stepper.Body className="half-padding">
                <OutsideProviders
                  handleContinue={() => {
                    this.goToNextStep();
                  }}
                />
              </Stepper.Body>
            </Stepper.Item>

            <Stepper.Item>
              <Stepper.Header onClick={this.handleStepClick}>Advance Directives</Stepper.Header>
              <Stepper.Body className="full-padding">
                <AdvancedDirectives
                  saveButtonText="Save and Continue"
                  saveButtonIcon="bi bi-chevron-right"
                  afterSave={() => {
                    this.goToNextStep();
                  }}
                  hideCancelButton
                />
              </Stepper.Body>
            </Stepper.Item>

            <Stepper.Item>
              <Stepper.Header onClick={this.handleStepClick}>Military Service</Stepper.Header>
              <Stepper.Body className="half-padding">
                <MilitaryService
                  saveButtonText="Save and Close"
                  saveButtonIcon="bi bi-chevron-right"
                  afterSave={() => {
                    hideModal();
                  }}
                  hideCancelButton
                />
              </Stepper.Body>
            </Stepper.Item>
          </Stepper>

          {/* <Accordion activeKey={activeStep} defaultActiveKey="0" flush>
            <Accordion.Item eventKey="0">
              <Accordion.Header
                onClick={() => {
                  this.handleStepClick("0");
                }}
              >
                Identification
              </Accordion.Header>
              <Accordion.Body className="no-padding">
                <CoreDemographics
                  _for="intake"
                  saveButtonText="Save and Continue"
                  saveButtonIcon="bi bi-chevron-right"
                  afterSave={() => {
                    this.handleStepClick("1");
                  }}
                  hideCancelButton
                />
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="1">
              <Accordion.Header
                onClick={() => {
                  this.handleStepClick("1");
                }}
              >
                Current Address
              </Accordion.Header>
              <Accordion.Body className="half-padding">
                <Addresses
                  handleContinue={() => {
                    this.handleStepClick("2");
                  }}
                />
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>} */}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={this.handleClose} style={{ marginRight: "auto" }}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default IntakeModal;
