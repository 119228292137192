import { toast } from "react-toastify";

export const toastErrorOptions = {
  position: toast.POSITION.BOTTOM_RIGHT,
  autoClose: false
};

export const toastMessageOptions = {
  position: toast.POSITION.BOTTOM_RIGHT,
  autoClose: 8000
};

export default { toastErrorOptions, toastMessageOptions };
