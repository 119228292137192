import isEmpty from "lodash/isEmpty";
import "react-toastify/dist/ReactToastify.css";
import * as types from "./actionTypes";
import { beginAjaxCall, ajaxCallDone } from "./ajaxStatusActions";
import { API_URL } from "../constants/environment";
import { pollApiEvents, processApiResponse, processApiError, processThenThrowApiError } from "../helpers";
import { adalApiFetch } from "../adalConfig";

function loadTemplatesSuccess(templates, visitId) {
  return {
    type: types.LOAD_TEMPLATES_SUCCESS,
    templates,
    visitId
  };
}

function loadSpecificTemplateAndItsSurveysSuccess(template, visitId) {
  return {
    type: types.LOAD_SPECIFIC_TEMPLATE_SUCCESS,
    template,
    visitId
  };
}

function loadSurveysSuccess(template, visitId) {
  return {
    type: types.LOAD_SURVEYS_SUCCESS,
    context: "template",
    templateId: template.templateId,
    template,
    visitId
  };
}

export function completeVisitCleanup() {
  return {
    type: types.COMPLETE_VISIT_CLEANUP
  };
}

function saveSurveySuccess(surveyId, surveyAnswers) {
  return {
    type: types.SAVE_SURVEY_SUCCESS,
    surveyId,
    surveyAnswers
  };
}

export function clearSelectedTemplate() {
  return {
    type: types.CLEAR_SELECTED_TEMPLATE
  };
}

function setSelectedTemplateDone(templateId) {
  return {
    type: types.SET_SELECTED_TEMPLATE_DONE,
    templateId
  };
}

export function setReloadSelectedTemplate(value) {
  return {
    type: types.SET_RELOAD_SELECTED_TEMPLATE,
    value
  };
}

export function setReInitSurvey(value) {
  return {
    type: types.SET_RE_INIT_SURVEY,
    value
  };
}

export function loadTemplates(patientId, visitId, signal = null) {
  return (dispatch) => {
    dispatch(beginAjaxCall("loadTemplates"));
    return adalApiFetch(`${API_URL}/Templates/Patients/${patientId}/Visits/${visitId}`, signal ? { signal } : {})
      .then((templates) => dispatch(loadTemplatesSuccess(templates, visitId)))
      .catch((error) => processApiError(error, dispatch))
      .finally(() => dispatch(ajaxCallDone()));
  };
}

export function loadSpecificTemplateAndItsSurveys(
  patientId,
  templateId,
  visitId = "",
  dischargeId = "",
  force = false,
  signal = null
) {
  return (dispatch, getState) => {
    if (!force) {
      const template = getState().templates.specificTemplates[`${visitId}_${templateId}`];
      if (!isEmpty(template)) return Promise.resolve({ template });
    }
    dispatch(beginAjaxCall("loadSpecificTemplateAndItsSurveys"));
    return adalApiFetch(
      `${API_URL}/Templates/${templateId}/Patients/${patientId}?visitId=${visitId}&dischargeId=${dischargeId}`,
      signal ? { signal } : {}
    )
      .then((_template) => dispatch(loadSpecificTemplateAndItsSurveysSuccess(_template, visitId)))
      .catch((error) => processApiError(error, dispatch))
      .finally(() => dispatch(ajaxCallDone()));
  };
}

export function loadTemplateSurveys(patientId, visitId = "", templateId, dischargeId = "", signal = null) {
  return (dispatch) => {
    // const selectedTemplate = getState().templates.selectedTemplate;
    if (!templateId) return { template: { surveys: [] } };
    // const templateId = getState().templates.selectedTemplate.templateId;
    if (!templateId) return { template: { surveys: [] } };
    dispatch(beginAjaxCall("loadTemplateSurveys"));
    return adalApiFetch(
      `${API_URL}/Templates/${templateId}/Patients/${patientId}?visitId=${visitId}&dischargeId=${dischargeId}`,
      signal ? { signal } : {}
    )
      .then((template) => dispatch(loadSurveysSuccess(template, visitId)))
      .catch((error) => processApiError(error, dispatch))
      .finally(() => dispatch(ajaxCallDone()));
  };
}

export function hideTemplatesSurvey(templateId, surveyId, patientId, answers) {
  return {
    type: types.HIDE_HP_SURVEY,
    templateId,
    surveyId,
    patientId,
    answers
  };
}

export function clearHiddenHpSurveys() {
  return {
    type: types.CLEAR_HIDDEN_HP_SURVEYS
  };
}

export function clearHiddenHpSurvey(patientId, templateId, surveyId) {
  return {
    type: types.CLEAR_HIDDEN_HP_SURVEY,
    patientId,
    templateId,
    surveyId
  };
}

export function saveSurvey(templateId, surveyId, patientId, surveyAnswers) {
  return (dispatch) => {
    dispatch(beginAjaxCall("createSurvey"));
    return adalApiFetch(`${API_URL}/Templates/${templateId}/Surveys/${surveyId}/patients/${patientId}`, {
      method: "POST",
      body: JSON.stringify(surveyAnswers)
    })
      .then((response) => processApiResponse(response.orchestrationId))
      .then((orchestrationId) => pollApiEvents(orchestrationId, "ISurveyResultAddedEvent"))
      .then(() => dispatch(saveSurveySuccess(surveyId, surveyAnswers)))
      .catch((error) => processApiError(error, dispatch))
      .finally(() => dispatch(ajaxCallDone()));
  };
}

export function setSelectedTemplate(templateId, templateVersion, patientId, visitId, signal = null) {
  return (dispatch) => {
    dispatch(clearSelectedTemplate());
    dispatch(beginAjaxCall("setSelectedTemplate"));
    return (
      adalApiFetch(
        `${API_URL}/Templates/${templateId}/version/${templateVersion}/Patients/${patientId}/Visits/${visitId}`,
        signal ? { signal, method: "POST" } : { method: "POST" }
      )
        .then((response) => processApiResponse(response.orchestrationId))
        .then((orchestrationId) => pollApiEvents(orchestrationId, "IPatientTemplateSelectedEvent"))
        .then((response) => dispatch(setSelectedTemplateDone(response.TemplateId)))
        // .then(() => dispatch(loadTemplates(patientId, visitId)))
        .catch((error) => processApiError(error, dispatch))
        .finally(() => dispatch(ajaxCallDone()))
    );
  };
}

export function clearTemplates() {
  return {
    type: types.CLEAR_TEMPLATES
  };
}

export function search(searchUrl, keyword) {
  return (dispatch) => {
    dispatch(beginAjaxCall("search"));
    return adalApiFetch(`${API_URL}${searchUrl.replace(`{keyword}`, keyword)}`)
      .catch((error) => processThenThrowApiError(error, dispatch))
      .finally(() => dispatch(ajaxCallDone()));
  };
}
