import React from "react";
import { Form, Button, Icon } from "semantic-ui-react";

const NewVisitForm = ({
  visit,
  onChange,
  onChangePrescriber,
  providers,
  groups,
  patientStatus,
  visitTypeOptions,
  prescriberSlots,
  mainPrescriber,
  groupByProvider,
  handleFocus,
  handleGroupModalOpen
}) => (
  <Form>
    <Form.Field>
      <Form.Dropdown
        fluid
        search
        selection
        required
        label="Provider"
        name="providerId"
        placeholder="Select..."
        options={providers}
        value={visit.provider}
        onChange={onChange}
        onFocus={handleFocus}
        id="input-provider"
      />
    </Form.Field>
    <Form.Field>
      <Form.Dropdown
        fluid
        selection
        search
        required
        label="Visit Type"
        name="visitType"
        placeholder="Select..."
        options={visitTypeOptions}
        value={visit.visitType}
        onChange={onChange}
        onFocus={handleFocus}
        id="input-visitType"
      />
    </Form.Field>
    {visit.visitType === "rejoin" || (visit.visitType === "initial" && patientStatus === "Discharged") ? (
      <Form.Field>
        <Form.Dropdown
          name="mainPrescriber"
          fluid
          selection
          label="Main Prescriber"
          placeholder="Select..."
          options={prescriberSlots}
          value={mainPrescriber.providerId}
          search
          onChange={onChangePrescriber}
          id="input-mainPrescriber"
          onFocus={handleFocus}
        />
      </Form.Field>
    ) : null}
    {visit.visitType === "group" ? (
      <Form.Group className="group-form-container">
        <Form.Field width={6}>
          <label className="mock-semantic-required">Time</label>
          <Form.Dropdown
            fluid
            selection
            name="visitGroupId"
            placeholder="Select..."
            options={groupByProvider(groups)}
            value={visit.visitGroupId}
            onChange={onChange}
            onFocus={handleFocus}
            id="input-visitGroupId"
          />
        </Form.Field>
        <Form.Field>
          <Button className="transparent-button" id="btn-group" size="small" onClick={handleGroupModalOpen}>
            <Icon name={"plus"} />
            Group
          </Button>
        </Form.Field>
      </Form.Group>
    ) : null}
  </Form>
);

export default NewVisitForm;
