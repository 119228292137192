import React, { Component } from "react";
import { Dropdown } from "semantic-ui-react";
import shortid from "shortid";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { isEqual } from "lodash";
import * as adminActions from "../../actions/adminActions";
import * as biReportActions from "../../actions/reportActions";
import optionRoles from "../../api/mockRolesApi";
import "./ReportPermission.css";

class ReportPermission extends Component {
  state = {
    reportGroups: [],
    isLoading: true
  };

  constructor(props) {
    super(props);
    this.props.actions.pushItemToBreadcrumb({
      key: "bc-report-permission",
      to: "/report-permission",
      text: "Report Permissions"
    });
  }

  componentDidMount() {
    document.body.style.backgroundColor = "#fff";
    this.props.actions.updateReportsFromServices().then(() =>
      this.props.actions.loadReportRoles().then(res => {
        this.setState({
          reportGroups: res.reportGroups.map(rg => ({
            ...rg,
            reportWithRoles: rg.reportWithRoles.map(rwr => ({
              ...rwr,
              roles: rwr.roles.filter((value, index, self) => self.indexOf(value) === index)
            }))
          })),
          isLoading: false
        });
      })
    );
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !isEqual(this.state, nextState);
  }

  componentWillUnmount() {
    document.body.style.backgroundColor = "#f3f3f3";
  }

  updateReportRoles = (groupCode, reportId, valueRoles) => {
    this.setState({
      reportGroups: this.state.reportGroups.map(rg => {
        if (rg.groupCode !== groupCode) return { ...rg };
        return {
          ...rg,
          reportWithRoles: rg.reportWithRoles.map(rwr => {
            if (rwr.reportId !== reportId) return { ...rwr };
            return { ...rwr, roles: valueRoles };
          })
        };
      })
    });
  };

  handleInputChange = (groupCode, reportId, _e, data) => {
    const rolesValue = optionRoles.map(role => role.value);
    const rolesUpdate = data.value;
    const foundAllRole = rolesUpdate.filter(role => role === "EMR-AllRoles");

    if (foundAllRole !== undefined && foundAllRole.length !== 0) {
      const allRoles = Object.assign([], rolesValue);
      allRoles.shift();
      this.updateReportRoles(groupCode, reportId, allRoles);
      this.props.actions.updateReportRoles(reportId, allRoles).then(() => this.props.actions.loadReports());
    } else {
      this.updateReportRoles(groupCode, reportId, rolesUpdate);
      this.props.actions.updateReportRoles(reportId, rolesUpdate).then(() => this.props.actions.loadReports());
    }
  };

  render() {
    const { reportGroups, isLoading } = this.state;
    return (
      <div className="report-permission-page">
        <div className="header">
          <h1>Report Permissions by role</h1>
        </div>
        <div className="report-group-content">
          {isLoading && (
            <div className="report-zontainer">
              <span className="zhild ui zimmer">Loading . .</span>
            </div>
          )}
          {reportGroups !== undefined
            ? reportGroups.map(({ groupCode, groupName, reportWithRoles }) => (
                <div className="report-group" key={`rand-${shortid.generate()}`}>
                  <div className="title">{groupName}</div>
                  <hr />
                  <div className="permission">
                    {reportWithRoles.map(({ reportId, name, description, roles }) => (
                      <div className="report-content" key={reportId}>
                        <h3>{name}</h3>
                        <p className="description">{description}</p>
                        <Dropdown
                          id={reportId}
                          placeholder="Choose here"
                          name={name}
                          fluid
                          multiple
                          search
                          selection
                          options={optionRoles}
                          onChange={(e, data) => this.handleInputChange(groupCode, reportId, e, data)}
                          value={roles.filter((value, index, self) => self.indexOf(value) === index)}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              ))
            : "There isn't any reports here!"}
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...adminActions,
        ...biReportActions
      },
      dispatch
    )
  };
}

export default connect(
  null,
  mapDispatchToProps
)(ReportPermission);
