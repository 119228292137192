import React, { Component } from "react";
import { Modal, Button, Form } from "semantic-ui-react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as lookupActions from "../../../actions/lookupActions";
import * as modalActions from "../../../actions/modalActions";
import * as templateActions from "../../../actions/templateActions";
import * as visitActions from "../../../actions/visitActions";
import * as patientHistoryActions from "../../../actions/patientHistoryActions";
import * as contextActions from "../../../actions/contextActions";

export class CancelVisitModal extends Component {
  state = {
    selectedCancelReason: null,
    explanation: "",
    loading: false
  };

  componentDidMount() {
    this.props.actions.loadAppointmentCancelReason();
  }

  updatePatientHistory = visitId => {
    this.props.actions.loadPatientHist(this.props.patientId).then(() => {
      const { patientId } = this.props;
      this.props.actions.createPatientHistoryItemByVisit(patientId, visitId);
    });
  };

  handleSave = () => {
    const { selectedCancelReason, explanation } = this.state;
    const {
      visitId,
      preCancel,
      postCancel,
      actions: { cancelVisit }
    } = this.props;
    this.setState({ loading: true });
    if (preCancel) preCancel();
    cancelVisit(visitId, selectedCancelReason, explanation).then(() => {
      this.handleCancel();
      if (postCancel) postCancel();
    });
  };

  handleCancel = () => {
    this.props.actions.hideModal();
  };

  handleOnChange = (e, data) => {
    const { name, value } = data;
    this.setState({ [name]: value });
  };

  render() {
    const { open, cancelReasons } = this.props;
    const { selectedCancelReason, explanation, loading } = this.state;

    return (
      <Modal open={open} size={"mini"} closeIcon closeOnDimmerClick={false} onClose={this.handleCancel}>
        <Modal.Header>{`Cancel Visit?`}</Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Group widths="equal">
              <Form.Dropdown
                fluid
                label="Select Cancel Reason:"
                selection
                options={cancelReasons}
                name="selectedCancelReason"
                value={selectedCancelReason}
                onChange={this.handleOnChange}
              />
            </Form.Group>
          </Form>
          <Form.Input
            fluid
            label="Cancel Explanation"
            name="explanation"
            maxLength={500}
            value={explanation}
            onChange={this.handleOnChange}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.handleCancel} content="Close" disabled={loading} />
          <Button
            loading={loading}
            color="red"
            onClick={this.handleSave}
            content="Confirm Cancel"
            disabled={loading || selectedCancelReason === null}
          />
        </Modal.Actions>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return { cancelReasons: state.lookups.appointmentCancelReason };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...modalActions,
        ...lookupActions,
        ...templateActions,
        ...visitActions,
        ...patientHistoryActions,
        ...contextActions
      },
      dispatch
    )
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CancelVisitModal);
