import React, { Component } from "react";
import PropTypes from "prop-types";
import { Modal, Button, Form, Grid, Table, Message } from "semantic-ui-react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as modalActions from "../../actions/modalActions";
import * as lookupActions from "../../actions/lookupActions";
import * as providerActions from "../../actions/providerActions";
import { hasProperty } from "../../helpers";

export class ProviderIntegrationModal extends Component {
  state = {
    integrationKey: {
      integrationKey: "",
      stateCode: "",
      integrationkeyValue: ""
    },
    integrationKeyErrorMessage: "",
    errors: {}
  };

  componentDidMount() {
    this.props.actions.loadStates();
    this.props.actions.loadIntegrationKeys("Users");
    this.props.actions.loadProviderIntegrationValues(this.props.providerId);
  }

  handleSave = () =>
    this.props.actions.saveSelectedProviderIntegrationKeyValues(this.props.providerId).then(() => {
      this.handleCancel();
    });

  handleCancel = () => {
    this.props.actions.hideModal();
    this.props.actions.clearSelectedProviderIntegrationKeyValues();
  };

  handleFocus = e => {
    e.target.setAttribute("autocomplete", "nope");
  };

  handleIkInput = (e, data) => {
    const { name: field, value } = data;
    const integrationKey = Object.assign({}, this.state.integrationKey, {
      [field]: value
    });
    this.setState({ integrationKey, integrationKeyErrorMessage: "" });
  };

  handleAddIntegrationKey = () => {
    if (
      this.props.selectedProviderIntegrationValues.find(
        ik =>
          ik.integrationKey === this.state.integrationKey.integrationKey &&
          ik.stateCode === this.state.integrationKey.stateCode
      )
    ) {
      this.setState({
        integrationKeyErrorMessage: "This key for this state is already mapped"
      });
    } else {
      this.props.actions.addSelectedProviderIntegrationKeyValue(this.state.integrationKey).then(() => {
        this.setState({
          integrationKey: {
            integrationKey: "",
            stateCode: "",
            integrationkeyValue: ""
          },
          integrationKeyErrorMessage: "",
          errors: {}
        });
      });
    }
  };

  handleDeleteIntegrationKey = (integrationKey, stateCode) => {
    const index = this.props.selectedProviderIntegrationValues.findIndex(
      ik => ik.integrationKey === integrationKey && ik.stateCode === stateCode
    );
    if (index !== -1) {
      this.props.actions.deleteSelectedProviderIntegrationKeyValues(index);
    }
  };

  render() {
    const { integrationKey, integrationKeyErrorMessage, errors } = this.state;
    const { states, open, processing, providerName, integrationKeys, selectedProviderIntegrationValues } = this.props;
    return (
      <Modal open={open}>
        <Modal.Header>{`Provider integration codes: ${providerName}`}</Modal.Header>
        <Modal.Content>
          <Form>
            <React.Fragment>
              <Grid>
                <Grid.Column width={14}>
                  <Form.Group widths="equal">
                    <Form.Field>
                      <Form.Dropdown
                        required
                        fluid
                        selection
                        search
                        options={integrationKeys}
                        placeholder="Select..."
                        label="Key"
                        name="integrationKey"
                        value={integrationKey.integrationKey}
                        onChange={this.handleIkInput}
                        error={hasProperty(errors, "integrationKey")}
                        onFocus={this.handleFocus}
                      />
                    </Form.Field>
                    <Form.Field>
                      <Form.Dropdown
                        required
                        fluid
                        selection
                        search
                        options={states}
                        id="input-state"
                        placeholder="Select..."
                        label="State"
                        name="stateCode"
                        value={integrationKey.stateCode}
                        onChange={this.handleIkInput}
                        error={hasProperty(errors, "state")}
                        onFocus={this.handleFocus}
                      />
                    </Form.Field>
                    <Form.Field>
                      <Form.Input
                        required
                        label="Value"
                        name="integrationkeyValue"
                        value={integrationKey.integrationkeyValue}
                        onChange={this.handleIkInput}
                        error={hasProperty(errors, "integrationKeyValue")}
                      />
                    </Form.Field>
                  </Form.Group>
                </Grid.Column>
                <Grid.Column width={2} className="field">
                  <label>&nbsp;</label>
                  <Button
                    color="blue"
                    onClick={this.handleAddIntegrationKey}
                    disabled={
                      !integrationKey.integrationKey ||
                      !integrationKey.stateCode ||
                      !integrationKey.integrationkeyValue.trim()
                    }
                    style={{ width: "100%" }}
                  >
                    Add
                  </Button>
                </Grid.Column>
              </Grid>
              {integrationKeyErrorMessage !== "" && <Message color="red">{integrationKeyErrorMessage}</Message>}
              <Table>
                <Table.Body>
                  {selectedProviderIntegrationValues.map(ik => (
                    <Table.Row key={ik.integrationKey + ik.stateCode}>
                      <Table.Cell>{ik.integrationKey}</Table.Cell>
                      <Table.Cell>{ik.stateCode}</Table.Cell>
                      <Table.Cell>{ik.integrationkeyValue}</Table.Cell>
                      <Table.Cell width={1} style={{ paddingTop: "5px" }}>
                        <Button
                          icon="delete"
                          className="transparent-button-icon delete"
                          onClick={() => this.handleDeleteIntegrationKey(ik.integrationKey, ik.stateCode)}
                        />
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            </React.Fragment>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.handleCancel} content="Cancel" />
          <Button
            color="blue"
            onClick={this.handleSave}
            content={processing ? "Saving..." : "Save"}
            loading={processing}
          />
        </Modal.Actions>
      </Modal>
    );
  }
}

ProviderIntegrationModal.propTypes = {
  open: PropTypes.bool.isRequired
};

function mapStateToProps(state) {
  return {
    processing: state.ajaxCallsInProgress > 0,
    states: state.lookups.states,
    selectedProviderIntegrationValues: state.providers.selectedProviderIntegrationValues,
    integrationKeys: state.lookups.integrationKeys.reduce((acc, obj) => {
      if (obj.modelName === "Users") acc.push({ value: obj.keyName, text: obj.keyName });
      return acc;
    }, [])
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...modalActions, ...lookupActions, ...providerActions }, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProviderIntegrationModal);
