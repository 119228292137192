import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import { Table, Button, Grid, Icon } from "semantic-ui-react";
import shortid from "shortid";
import { dateFormat, dateWithTimeFormat, VISIT_STATUS_VISIT_CANCELED } from "../../../constants/miscellaneous";
import { getLookupText } from "../../../helpers";
import "./DocumentsRow.css";

const DocumentsRow = ({
  document: {
    documentId,
    patientId,
    visitId,
    dateCreated,
    displayName,
    description,
    fileTags,
    originalFileName,
    expirationDate,
    lastUpdateDate,
    visitTime,
    visitType,
    visitStatus,
    providerFirstName,
    providerLastName,
    isDeleted
  },
  documentTags,
  handleEdit,
  handleDowload,
  canDelete,
  handleDelete
}) => (
  <Table.Row className={isDeleted ? `deleted` : ``}>
    <Table.Cell>{moment(dateCreated).format(dateFormat)}</Table.Cell>
    <Table.Cell>{fileTags.map(fileTag => getLookupText(fileTag, documentTags)).join(", ")}</Table.Cell>
    <Table.Cell>
      <a className="document-name" href={`#${documentId}`} onClick={() => handleDowload(documentId, originalFileName)}>
        {displayName}
      </a>
    </Table.Cell>
    <Table.Cell className={new Date(expirationDate) < new Date() ? "tb-exp-column" : ""}>
      {expirationDate ? moment(expirationDate).format(dateFormat) : ``}
    </Table.Cell>
    <Table.Cell>{moment(lastUpdateDate).format(dateFormat)}</Table.Cell>
    <Table.Cell>
      {description
        ? description.split(/(?:\r\n|\r|\n)/g).map(i => (
            <React.Fragment key={`rand-${shortid.generate()}`}>
              {i === null ? "" : i}
              <br />
            </React.Fragment>
          ))
        : null}
    </Table.Cell>
    <Table.Cell>
      {visitId && (
        <Grid className="no-margin" style={{ minWidth: "300px" }}>
          <Grid.Row className="no-padding">
            <Grid.Column width={9}>
              {`${moment(visitTime).format(dateWithTimeFormat)}`}
              <br /> {`(${visitType})`}
              <br />
              <strong>{`${providerFirstName} ${providerLastName}`}</strong>
            </Grid.Column>
            <Grid.Column width={5} className="no-padding" style={{ color: "red", wordBreak: "break-word" }}>
              {visitStatus === VISIT_STATUS_VISIT_CANCELED ? `(Cancelled)` : ``}
            </Grid.Column>
            <Grid.Column width={1}>
              <Link to={`/current-patients/${patientId}/visits/vid/${visitId}`}>
                <Icon
                  className="transparent-button-icon"
                  name="file alternate outline"
                  style={{ marginTop: ".75em" }}
                />
              </Link>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      )}
    </Table.Cell>
    <Table.Cell className="collapsed">
      <Button
        className="transparent-button-icon prescriptions-edit"
        icon="edit"
        size="big"
        onClick={() => handleEdit(documentId, isDeleted)}
        id="btn-edit"
      />
      {!isDeleted && canDelete && (
        <Button
          icon="delete"
          size="big"
          onClick={e => {
            e.stopPropagation();
            handleDelete(documentId);
          }}
          className="transparent-button-icon"
        />
      )}
    </Table.Cell>
  </Table.Row>
);

export default DocumentsRow;
