import React from "react";
import SurveysDashboard from "../surveys/SurveysDashboard";
import "./HistoryPhysicalPage.css";

export function HistoryPhysicalPage() {
  return (
    <React.Fragment>
      <SurveysDashboard tab="hp" />
    </React.Fragment>
  );
}

export default HistoryPhysicalPage;
