import React from "react";
import _ from "lodash";
import VisitSummary from "./VisitSummary";
import { checkRoles } from "../../../helpers";
import { roleGroups } from "../../../constants/securityRoles";
import "./VisitSummaryPrint.css";

const VisitSummaryPrint = ({
  dataVisitSummary,
  authRoles,
  patientId,
  reactionTypes,
  relationships,
  visitTypes,
  visitSummaryLocations,
  centerOptions,
  include
}) => (
  <React.Fragment>
    {dataVisitSummary.map((item, idx) => {
      const isSigned = !_.isEmpty(item.data.details);
      const isCoSigned = !_.isEmpty(item.data.cosignDetail);
      const showCoSign = !isCoSigned && checkRoles(roleGroups.supProvidersLdac1, authRoles);
      const visitAmendment = { amendments: item.data.amendments };
      const visitTypeObject = visitTypes.find((v) => v.value === item.visitType);
      return (
        item.data.summary && (
          <React.Fragment key={item.visitId}>
            <div className="visit-summary-content">
              <VisitSummary
                visitSummary={item.data.summary}
                visitAmendment={visitAmendment}
                authRoles={authRoles}
                isSigned={isSigned}
                isCoSigned={isCoSigned}
                signDetail={item.data.details}
                cosignDetail={item.data.cosignDetail}
                prescriptionsSent={item.data.prescriptionsSentByVisit}
                visitId={item.data.visitId}
                patientId={patientId}
                showCoSign={showCoSign}
                patientHistory={item.data.patientHistory}
                reactionTypes={reactionTypes}
                relationships={relationships}
                labOrdersAndResultsPerVisit={item.data.labOrdersAndResults}
                visitTypeObject={visitTypeObject}
                visitSummaryLocations={visitSummaryLocations}
                centerOptions={centerOptions}
                signHistory={item.data.signHistory}
                include={include}
                isReadOnly
                isPrint
              />
              <br />
              {idx < dataVisitSummary.length - 1 && <div className="page-break" />}
            </div>
          </React.Fragment>
        )
      );
    })}
  </React.Fragment>
);

export default VisitSummaryPrint;
