import React from "react";
import { Form, Tab, Menu } from "semantic-ui-react";
import NewCenterModal from "./NewCenterModal";
import NewCenterFormDetailsTab from "./NewCenterFormDetailsTab";
import NewCenterFormIntegrationTab from "./NewCenterFormIntegrationTab";

const NewCenterForm = ({
  center,
  integrationKey,
  stateOptions,
  integrationKeyOptions,
  billingCodeOptions,
  locationTypeOptions,
  handleInput,
  handleIkInput,
  handleMaskedInput,
  handleCheckbox,
  handleFocus,
  errors,
  enableEditDetails,
  handleAddIntegrationKey,
  handleDeleteIntegrationKey,
  integrationKeyErrorMessage,
  detailsTabView,
  detailsTabEntityType,
  handleUpdateDetailsTabView
}) => (
  <Form>
    <Tab
      menu={{
        fluid: true,
        tabular: true
      }}
      panes={[
        {
          menuItem: <Menu.Item key={1}>Details</Menu.Item>,
          render: () => (
            <Tab.Pane key={1} style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
              <NewCenterFormDetailsTab
                center={center}
                stateOptions={stateOptions}
                billingCodeOptions={billingCodeOptions}
                locationTypeOptions={locationTypeOptions}
                errors={errors}
                handleInput={handleInput}
                handleMaskedInput={handleMaskedInput}
                handleCheckbox={handleCheckbox}
                handleFocus={handleFocus}
                enableEditDetails={enableEditDetails}
                detailsTabView={detailsTabView}
                detailsTabEntityType={detailsTabEntityType}
                handleUpdateDetailsTabView={handleUpdateDetailsTabView}
              />
            </Tab.Pane>
          )
        },
        {
          menuItem: <Menu.Item key={2}>Integration</Menu.Item>,
          render: () => (
            <Tab.Pane key={2} style={{ border: "none" }}>
              <NewCenterFormIntegrationTab
                center={center}
                integrationKey={integrationKey}
                errors={errors}
                integrationKeyOptions={integrationKeyOptions}
                handleIkInput={handleIkInput}
                handleFocus={handleFocus}
                enableEditDetails={enableEditDetails}
                handleAddIntegrationKey={handleAddIntegrationKey}
                handleDeleteIntegrationKey={handleDeleteIntegrationKey}
                integrationKeyErrorMessage={integrationKeyErrorMessage}
              />
            </Tab.Pane>
          )
        }
      ]}
      onTabChange={(_, tab) => {
        if (tab.activeIndex === 1) {
          handleUpdateDetailsTabView(NewCenterModal.DETAILS_TAB_VIEWS.MAIN);
        }
      }}
    />
  </Form>
);

export default NewCenterForm;
