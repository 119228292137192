import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as centerActions from "../../actions/centerActions";
import NewCenterFormDetailsTabMainView from "./NewCenterFormDetailsTabMainView";
import NewCenterFormDetailsTabAddressHistoryView from "./NewCenterFormDetailsTabAddressHistoryView";
import NewCenterFormDetailsTabEntityHistoryView from "./NewCenterFormDetailsTabEntityHistoryView";
import NewCenterModal from "./NewCenterModal";

export class NewCenterFormDetailsTab extends Component {
  componentDidUpdate(prevProps) {
    if (
      this.props.detailsTabView === NewCenterModal.DETAILS_TAB_VIEWS.MAIN &&
      prevProps.detailsTabView !== NewCenterModal.DETAILS_TAB_VIEWS.MAIN
    ) {
      this.props.actions.setForceUpdateCenterDetails(true);
    }
  }

  render() {
    const { detailsTabView: view, detailsTabEntityType: entityType } = this.props;
    return (
      <React.Fragment>
        {view === NewCenterModal.DETAILS_TAB_VIEWS.MAIN && <NewCenterFormDetailsTabMainView {...this.props} />}
        {view === NewCenterModal.DETAILS_TAB_VIEWS.ADDRESS_HISTORY && (
          <NewCenterFormDetailsTabAddressHistoryView {...this.props} />
        )}
        {view === NewCenterModal.DETAILS_TAB_VIEWS.ENTITY_HISTORY && (
          <NewCenterFormDetailsTabEntityHistoryView {...this.props} entityType={entityType} />
        )}
      </React.Fragment>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...centerActions }, dispatch)
  };
}

export default connect(
  null,
  mapDispatchToProps
)(NewCenterFormDetailsTab);
