import { isEmpty } from "lodash";

export function constructParams(filter) {
  return filter
    .map((item) => ([undefined, null, ""].includes(item.parameter) ? `` : `${item.variable}: ${item.type}`))
    .join(` `);
}

export function constructWhere(conditions) {
  return conditions
    .map((condition) => {
      if (Array.isArray(condition.op)) {
        return condition.op.every((op) => isEmpty(op.parameter))
          ? ``
          : `${condition.field}: { ${condition.op
              .map((op) => ([undefined, null, ""].includes(op.parameter) ? `` : `${op.op}: ${op.variable}`))
              .join(` `)} }`;
      } else {
        return [undefined, null, ""].includes(condition.parameter) ||
          (Array.isArray(condition.parameter) && condition.parameter.length === 0)
          ? ``
          : `${condition.field}: { ${condition.op}: ${condition.variable} }`;
      }
    })
    .join(` `);
}
