import React, { Component } from "react";
import { Form, Button } from "semantic-ui-react";
import { DateInput } from "semantic-ui-calendar-react";
import { dateFormat } from "../../../../constants/miscellaneous";
import { dateRegEx } from "../../../../constants/validation";
import { requiredFieldsFilled, updateErrorsWithStartEndDates, hasProperty } from "../../../../helpers";

const inputValidation = {
  required: ["insuranceProviderKey", "priorAuthorizationNumber", "numberOfAuthorizations"],
  startDate: dateRegEx,
  endDate: dateRegEx
};

const auths = [
  { text: "1", value: "1" },
  { text: "2", value: "2" },
  { text: "3", value: "3" },
  { text: "4", value: "4" },
  { text: "5", value: "5" },
  { text: "6", value: "6" },
  { text: "8", value: "8" },
  { text: "9", value: "9" },
  { text: "10", value: "10" },
  { text: "11", value: "11" },
  { text: "12", value: "12" },
  { text: "13", value: "13" },
  { text: "14", value: "14" },
  { text: "15", value: "15" },
  { text: "16", value: "16" },
  { text: "17", value: "17" },
  { text: "18", value: "18" },
  { text: "19", value: "19" },
  { text: "20", value: "20" },
  { text: "21", value: "21" },
  { text: "22", value: "22" },
  { text: "23", value: "23" },
  { text: "24", value: "24" },
  { text: "25", value: "25" },
  { text: "26", value: "26" },
  { text: "27", value: "27" },
  { text: "28", value: "28" },
  { text: "29", value: "29" },
  { text: "30", value: "30" }
];

const clearState = {
  insuranceProviderKey: "",
  startDate: "",
  endDate: "",
  priorAuthorizationNumber: "",
  numberOfAuthorizations: ""
};

export class UDSPriorAuthForm extends Component {
  state = {
    udsPriorAuth: {
      insuranceProviderKey: "",
      startDate: "",
      endDate: "",
      priorAuthorizationNumber: "",
      numberOfAuthorizations: ""
    },
    errors: {}
  };

  handleSave = () => {
    this.props.handleUdsSave(this.state.udsPriorAuth);
    this.setState({ udsPriorAuth: clearState });
  };

  handleFocus = e => {
    e.target.setAttribute("autocomplete", "nope");
  };

  handleInput = (e, data) => {
    const { name: field, value, required } = data;
    const { startDate, endDate } = this.state.udsPriorAuth;
    const errors = updateErrorsWithStartEndDates(
      field,
      value,
      required,
      this.state.errors,
      inputValidation,
      startDate,
      endDate
    );
    const udsPriorAuth = Object.assign({}, this.state.udsPriorAuth, {
      [field]: value
    });
    this.setState({ udsPriorAuth, errors });
  };

  handleMaskedInput = e => {
    const {
      type,
      target: { name: field, required, value }
    } = e;
    const { startDate, endDate } = this.state.udsPriorAuth;
    let errors = Object.assign({}, this.state.errors);
    const udsPriorAuth = Object.assign({}, this.state.udsPriorAuth, {
      [field]: value
    });

    if (type !== "focus" && type !== "blur") {
      errors = updateErrorsWithStartEndDates(
        field,
        value,
        required,
        this.state.errors,
        inputValidation,
        startDate,
        endDate
      );
    }

    this.setState({ udsPriorAuth, errors });
  };

  render() {
    const { udsPriorAuth, errors } = this.state;
    const { insuranceProviders, enableAddAuth } = this.props;
    const requiredFieldsCheck = requiredFieldsFilled(inputValidation.required, udsPriorAuth);
    const disableSave = requiredFieldsCheck || Object.keys(errors).length !== 0;
    return (
      <Form>
        <Form.Group>
          <Form.Field width={8}>
            <Form.Dropdown
              required
              fluid
              selection
              search
              label="Insurance"
              name="insuranceProviderKey"
              id="input-insuranceProviderKey"
              placeholder="Select..."
              onChange={this.handleInput}
              options={insuranceProviders}
              value={udsPriorAuth.insuranceProviderKey}
              error={hasProperty(errors, "insuranceProviderKey")}
              onFocus={this.handleFocus}
              disabled={!enableAddAuth}
            />
          </Form.Field>
          <Form.Field width={4} className={hasProperty(errors, "startDate") ? "error field" : "field"}>
            <label htmlFor="input-startDate">Start Date</label>
            <DateInput
              id="input-startDate"
              name="startDate"
              placeholder="Start Date"
              value={udsPriorAuth.startDate}
              dateFormat={dateFormat}
              iconPosition="right"
              onChange={this.handleInput}
              disabled={!enableAddAuth}
              hideMobileKeyboard
              closable
            />
          </Form.Field>
          <Form.Field width={4} className={hasProperty(errors, "endDate") ? "error field" : "field"}>
            <label>End Date</label>
            <DateInput
              name="endDate"
              placeholder="End Date"
              value={udsPriorAuth.endDate}
              dateFormat={dateFormat}
              iconPosition="right"
              onChange={this.handleInput}
              disabled={!enableAddAuth}
              hideMobileKeyboard
              closable
            />
          </Form.Field>
        </Form.Group>
        <Form.Group>
          <Form.Field width={6}>
            <Form.Input
              required
              label="PA #"
              name="priorAuthorizationNumber"
              value={udsPriorAuth.priorAuthorizationNumber}
              onChange={this.handleInput}
              id="input-priorAuthorizationNumber"
              error={hasProperty(errors, "priorAuthorizationNumber")}
              disabled={!enableAddAuth}
            />
          </Form.Field>
          <Form.Field width={4}>
            <Form.Dropdown
              required
              fluid
              selection
              search
              label="Number of Auths"
              name="numberOfAuthorizations"
              onChange={this.handleInput}
              id="input-numberOfAuthorizations"
              placeholder="Select..."
              options={auths}
              value={udsPriorAuth.numberOfAuthorizations}
              onFocus={this.handleFocus}
              disabled={!enableAddAuth}
            />
          </Form.Field>
          <Form.Field width={6}>
            {enableAddAuth && (
              <Button color="blue" disabled={disableSave} onClick={this.handleSave} className="prior-auth-button">
                Add New PA
              </Button>
            )}
          </Form.Field>
        </Form.Group>
      </Form>
    );
  }
}

export default UDSPriorAuthForm;
