import React, { Component } from "react";
import shortid from "shortid";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { isEmpty } from "lodash";
import * as patientHistoryActions from "../../../../actions/patientHistoryActions";
import * as modalActions from "../../../../actions/modalActions";
import * as lookupActions from "../../../../actions/lookupActions";
import { roleGroups } from "../../../../constants/securityRoles";
import { checkRoles } from "../../../../helpers";
import InterventionHistoryTableRow from "./InterventionHistoryTableRow";
import "./InterventionHistoryTable.css";

export class InterventionHistoryTable extends Component {
  componentDidMount() {
    this.props.actions.loadInterventionConditions();
  }

  handleEditInterventionHistory = (e, data) => {
    const { interventionHistory } = this.props;
    switch (data.icon) {
      case "delete":
        this.props.actions.deleteInterventionHistory(this.props.patientId, interventionHistory[data.index].itemId);
        break;
      case "plus":
      case "edit":
        this.props.actions.showModal({
          type: "ADD_INTERVENTION_HISTORY",
          props: {
            open: true,
            interventionHistory: interventionHistory[data.index],
            isEdit: true
          }
        });
        break;
      default:
        break;
    }
  };
  render() {
    const { interventionHistory, conditions, authRoles } = this.props;
    const showEditHistory = checkRoles(roleGroups.excludeHelpDesk, authRoles);
    const showDeleteHistory = checkRoles(roleGroups.providers, authRoles);
    return (
      <div className="intervention-history-table">
        <table>
          <thead>
            <tr>
              <th>Condition</th>
              <th width="15%">Start</th>
              <th width="15%">End</th>
              <th width="15%">Is Complete</th>
              <th width="5%">Note</th>
              <th width="5%" />
              <th width="5%" />
              <th width="5%" />
            </tr>
          </thead>
          <tbody>
            {interventionHistory && interventionHistory.length
              ? interventionHistory.map((historyItem, i) => (
                  <InterventionHistoryTableRow
                    key={shortid.generate()}
                    index={i}
                    interventionHistory={{
                      ...historyItem,
                      condition: !isEmpty(conditions)
                        ? conditions.find((c) => c.code === historyItem.condition).text
                        : ``
                    }}
                    handleEditInterventionHistory={this.handleEditInterventionHistory}
                    showEditHistory={showEditHistory}
                    showDeleteHistory={showDeleteHistory}
                  />
                ))
              : null}
          </tbody>
        </table>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    patientId: state.patient.currentPatient.patientId,
    interventionHistory: state.patientHistory.interventionHistory,
    conditions: state.lookups.interventionConditions,
    authRoles: state.auth.user.profile.roles
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...patientHistoryActions, ...lookupActions, ...modalActions }, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(InterventionHistoryTable);
