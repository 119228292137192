import React, { Component } from "react";
import { Switch, Route, Prompt } from "react-router-dom";
import isEmpty from "lodash/isEmpty";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { checkRoles } from "../../helpers";
import { roleGroups } from "../../constants/securityRoles";
import * as contextActions from "../../actions/contextActions";
import * as patientActions from "../../actions/patientActions";
import * as visitActions from "../../actions/visitActions";
import * as lookupActions from "../../actions/lookupActions";
import * as demographicActions from "../../actions/demographicActions";
import * as templateActions from "../../actions/templateActions";
import * as treatmentPlanActions from "../../actions/treatmentPlanActions";
import ViewPatientDemographics from "./demographics/ViewPatientDemographics";
import ManagePatientMenu from "./ManagePatientMenu";
import PatientHistoryPage from "./history/PatientHistoryPage";
import PrescriptionsPage from "./prescriptions/PrescriptionsPage";
import VisitsPage from "./visits/VisitsPage";
import LabsPage from "./labs/LabsPage";
import HistoryPhysicalPage from "./hp/HistoryPhysicalPage";
import DischargePage from "./discharge/DischargePage";
import SurveysDashboard from "./surveys/SurveysDashboard";
import TreatmentPlan from "./treatmentPlan/TreatmentPlan";
import PatientTasks from "./tasks";
import ScansPage from "./scans/ScansPage";
import emrComponent from "../common/emrComponent";
import PatientVisitContextedRouteComponent from "./PatientVisitContextedRouteComponent";

export class ManagePatientSubLayout extends Component {
  static getDerivedStateFromProps(nextProps, prevState) {
    const {
      match: {
        params: { patient: patientId }
      }
    } = nextProps;
    if (patientId !== prevState.patientId) {
      return { patientId };
    }
    return null;
  }

  state = {
    patientId: ""
  };

  componentDidMount() {
    const { patientId } = this.state;
    this.props.actions.loadVisitTypes();
    this.props.actions.loadVisitStatuses();
    this.props.actions.loadPrescriberSlots();
    this.props.actions.loadPatientCustomFields();
    if (patientId) {
      this.props.actions.setContext("patient", { patientId });
      this.props.actions.loadPatientVisits(patientId);
      this.props.actions.loadPatientSummary(patientId).then(() => this.props.actions.loadPatientDemo(patientId));
      this.props.actions.getPatientNoteSlashTaskCount(patientId);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { patientId } = this.state;
    const {
      contextVisitId,
      activeVisits,
      actions: { loadPatientActiveVisits }
    } = this.props;

    if (snapshot !== patientId) {
      this.props.actions.clearPatient();
      this.props.actions.clearPatientRelatedVisitsData();
      this.props.actions.clearHiddenHpSurveys();
      this.props.actions.clearTreatmentPlan();
      this.props.actions.setContext("patient", { patientId });
      this.props.actions.loadPatientVisits(patientId);
      this.props.actions.loadPatientSummary(patientId).then(() => this.props.actions.loadPatientDemo(patientId));
      this.props.actions.getPatientNoteSlashTaskCount(patientId);
    }

    // pushing history here drive everything crazy .. removing it for now .. the only effect of it is
    // when you load patient default view .. context visit is set .. but url is not set
    // if (patientId && contextVisitId && contextVisitId !== prevProps.contextVisitId) {
    //   this.historyPush(pathname, contextVisitId);
    // }

    if (patientId && !contextVisitId && activeVisits.length > 0) {
      if (snapshot === patientId)
        this.props.actions.loadPatientVisits(patientId).then(() => {
          loadPatientActiveVisits(patientId, true, true);
        });
      else loadPatientActiveVisits(patientId, true, true);
    }
  }

  componentWillUnmount() {
    this.props.actions.clearPatient();
    this.props.actions.clearPatientRelatedVisitsData();
    this.props.actions.clearHiddenHpSurveys();
    this.props.actions.clearTreatmentPlan();
    this.props.actions.setContext("patient", { patientId: null });
  }

  // eslint-disable-next-line class-methods-use-this
  getSnapshotBeforeUpdate(prevProps, prevState) {
    return prevState.patientId;
  }

  vidRegex = /\/vid\/(\{){0,1}[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}(\}){0,1}/;

  historyPush = (route, visitId) => {
    // const visitId = _visitId === "all" ? false : _visitId;
    const { vidRegex } = this;
    const {
      history,
      location: { pathname }
    } = this.props;
    let updatedPathname = ``;
    if (vidRegex.test(route)) {
      if (visitId) updatedPathname = route.replace(vidRegex, `/vid/${visitId}`);
      else updatedPathname = route.replace(vidRegex, ``);
    } else if (!visitId) return;
    else updatedPathname = `${route}/vid/${visitId}`;

    if (updatedPathname !== pathname) history.push(updatedPathname);
  };

  render() {
    const {
      patient,
      match,
      demographics,
      hiddenHpSurveys,
      hiddenTreatmentPlanSurveys,
      location: { pathname }
    } = this.props;

    if (isEmpty(patient) || isEmpty(demographics)) {
      return null;
    }

    const visitContexted = this.vidRegex.test(pathname); // route has /vid/{guid} part in it
    const promptOnLeaving = !isEmpty(hiddenHpSurveys) || !isEmpty(hiddenTreatmentPlanSurveys);

    return (
      <React.Fragment>
        {promptOnLeaving && (
          <Prompt
            when={promptOnLeaving}
            message={(location) =>
              location.pathname.includes(patient.patientId)
                ? true
                : `You have unsaved data\nLeaving patient page will discard any unsaved data\nAre you sure you want to leave patient?`
            }
          />
        )}
        <ManagePatientMenu visitContexted={visitContexted} historyPush={this.historyPush} />
        <Switch>
          <Route
            exact
            path={`${match.url}/visits`}
            render={() => (
              <PatientVisitContextedRouteComponent>
                <VisitsPage key={patient.patientId} />
              </PatientVisitContextedRouteComponent>
            )}
          />
          <Route
            exact
            path={`${match.url}/visits/vid/:visitId`}
            render={() => (
              <PatientVisitContextedRouteComponent>
                <VisitsPage key={patient.patientId} />
              </PatientVisitContextedRouteComponent>
            )}
          />

          <Route
            path={`${match.url}/demographics`}
            exact
            render={() => (
              <PatientVisitContextedRouteComponent>
                <ViewPatientDemographics key={patient.patientId} />
              </PatientVisitContextedRouteComponent>
            )}
          />
          <Route
            path={`${match.url}/demographics/vid/:visitId`}
            exact
            render={() => (
              <PatientVisitContextedRouteComponent>
                <ViewPatientDemographics key={patient.patientId} />
              </PatientVisitContextedRouteComponent>
            )}
          />

          <Route
            path={`${match.url}/history`}
            exact
            render={() => (
              <PatientVisitContextedRouteComponent>
                <PatientHistoryPage key={patient.patientId} />
              </PatientVisitContextedRouteComponent>
            )}
          />
          <Route
            path={`${match.url}/history/vid/:visitId`}
            exact
            render={() => (
              <PatientVisitContextedRouteComponent>
                <PatientHistoryPage key={patient.patientId} />
              </PatientVisitContextedRouteComponent>
            )}
          />

          <Route
            path={`${match.url}/prescriptions`}
            exact
            render={() => (
              <PatientVisitContextedRouteComponent>
                <PrescriptionsPage key={patient.patientId} currentPatient={patient} demographics={demographics} />
              </PatientVisitContextedRouteComponent>
            )}
          />
          <Route
            path={`${match.url}/prescriptions/vid/:visitId`}
            exact
            render={() => (
              <PatientVisitContextedRouteComponent>
                <PrescriptionsPage key={patient.patientId} currentPatient={patient} demographics={demographics} />
              </PatientVisitContextedRouteComponent>
            )}
          />

          <Route
            path={`${match.url}/scans`}
            exact
            render={() => (
              <PatientVisitContextedRouteComponent>
                <ScansPage key={patient.patientId} currentPatient={patient} />
              </PatientVisitContextedRouteComponent>
            )}
          />
          <Route
            path={`${match.url}/scans/vid/:visitId`}
            exact
            render={() => (
              <PatientVisitContextedRouteComponent>
                <ScansPage key={patient.patientId} currentPatient={patient} />
              </PatientVisitContextedRouteComponent>
            )}
          />

          <Route
            path={`${match.url}/labs`}
            exact
            render={() => (
              <PatientVisitContextedRouteComponent>
                <LabsPage key={patient.patientId} />
              </PatientVisitContextedRouteComponent>
            )}
          />
          <Route
            path={`${match.url}/labs/vid/:visitId`}
            exact
            render={() => (
              <PatientVisitContextedRouteComponent>
                <LabsPage key={patient.patientId} />
              </PatientVisitContextedRouteComponent>
            )}
          />

          <Route
            exact
            path={`${match.url}/hp`}
            render={() => (
              <PatientVisitContextedRouteComponent>
                <HistoryPhysicalPage key={patient.patientId} />
              </PatientVisitContextedRouteComponent>
            )}
          />
          <Route
            exact
            path={`${match.url}/hp/vid/:visitId`}
            render={() => (
              <PatientVisitContextedRouteComponent>
                <HistoryPhysicalPage key={patient.patientId} />
              </PatientVisitContextedRouteComponent>
            )}
          />

          <Route
            exact
            path={`${match.url}/discharge`}
            render={() => (
              <PatientVisitContextedRouteComponent>
                <DischargePage key={patient.patientId} />
              </PatientVisitContextedRouteComponent>
            )}
          />

          <Route
            path={`${match.url}/counseling`}
            exact
            render={() => (
              <PatientVisitContextedRouteComponent>
                <SurveysDashboard tab="counseling" />
              </PatientVisitContextedRouteComponent>
            )}
          />
          <Route
            path={`${match.url}/counseling/vid/:visitId`}
            exact
            render={() => (
              <PatientVisitContextedRouteComponent>
                <SurveysDashboard tab="counseling" />
              </PatientVisitContextedRouteComponent>
            )}
          />
          <Route
            path={`${match.url}/treatment-plan`}
            exact
            render={() => (
              <PatientVisitContextedRouteComponent>
                <TreatmentPlan />
              </PatientVisitContextedRouteComponent>
            )}
          />
          <Route
            path={`${match.url}/treatment-plan/vid/:visitId`}
            exact
            render={() => (
              <PatientVisitContextedRouteComponent>
                <TreatmentPlan />
              </PatientVisitContextedRouteComponent>
            )}
          />
          <Route
            path={`${match.url}/tasks`}
            exact
            render={() => (
              <PatientVisitContextedRouteComponent>
                <PatientTasks patient={patient} />
              </PatientVisitContextedRouteComponent>
            )}
          />
          <Route
            path={`${match.url}/tasks/vid/:visitId`}
            render={() => (
              <PatientVisitContextedRouteComponent>
                <PatientTasks patient={patient} />
              </PatientVisitContextedRouteComponent>
            )}
          />
        </Switch>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const contextVisit = state.visits.contextVisit;
  const patient = state.patient.currentPatient;
  const status = state.demographics.status;

  const latestVisit = state.visits.latestVisit;
  const surveysVisit = contextVisit || latestVisit;

  // const contextVisitType = contextVisit ? contextVisit.visitType : null;
  const surveysVisitType = surveysVisit ? surveysVisit.visitType : null;

  const visitTypes = state.lookups.visitTypes;
  const authRoles = state.auth.user.profile.roles;

  if (surveysVisitType && visitTypes.length > 0 && !visitTypes.find((vt) => vt.value === surveysVisitType)) {
    throw new Error(`Unknown visit type [${surveysVisitType}]`);
  }

  const isSurveyVisitMedical =
    surveysVisitType && visitTypes.length > 0 && visitTypes.find((vt) => vt.value === surveysVisitType).medical;

  const canShowCounselingTabs = checkRoles(roleGroups.counselingView, authRoles);
  const showCounselingTab = patient.patientId && canShowCounselingTabs && surveysVisit && !isSurveyVisitMedical;

  const showDischargeMenuItem = status === "Discharged" && !contextVisit; // TODO this will change in #33849

  const canShowSurveyTabs = checkRoles(roleGroups.templatesView, authRoles);
  const showHpMenuItem =
    patient.patientId &&
    canShowSurveyTabs &&
    surveysVisit &&
    isSurveyVisitMedical &&
    !showCounselingTab &&
    !showDischargeMenuItem;
  const hiddenHpSurveys = state.templates.hiddenSurveys;
  const hiddenTreatmentPlanSurveys = state.treatmentPlan.hiddenSurveys;

  return {
    patient: state.patient.currentPatient,
    activeVisits: state.visits.activeVisits,
    contextVisitId: contextVisit ? contextVisit.visitId : null,
    demographics: state.demographics,
    showHpMenuItem,
    showCounselingTab,
    showDischargeMenuItem,
    hiddenHpSurveys,
    hiddenTreatmentPlanSurveys
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...contextActions,
        ...patientActions,
        ...visitActions,
        ...lookupActions,
        ...demographicActions,
        ...templateActions,
        ...treatmentPlanActions
      },
      dispatch
    )
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(emrComponent(ManagePatientSubLayout));
