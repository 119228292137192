import React, { Component } from "react";
import { Button, Grid, Table, Segment, Icon } from "semantic-ui-react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import shortid from "shortid";
import { isEmpty, isEqual } from "lodash";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import * as modalActions from "../../../actions/modalActions";
import * as userActions from "../../../actions/userActions";
import * as prescriptionActions from "../../../actions/prescriptionActions";
import * as insuranceActions from "../../../actions/insuranceActions";
import ManagePrescriptions from "./ManagePrescriptions";
import { selectedPrescription as getSelectedPrescription } from "../../../reducers/rootReducer";
import { roleGroups } from "../../../constants/securityRoles";
import { checkRoles, subscribe, unsubscribe } from "../../../helpers";
import { fetchDrugAllergies } from "../../../api/screening";
import prescriptionStatus from "../../../constants/prescriptionStatus";
import "./PrescriptionsPage.css";

export class PrescriptionsPage extends Component {
  state = {
    searchValue: "",
    prescriptions: {
      pharmacies: [],
      selectedPharmacy: {},
      currentPrescriptions: []
    },
    activeRxTab: "Current Rx",
    orderedDrugFavorites: this.props.drugFavorites
  };

  componentDidMount() {
    const {
      patientId,
      currentUserId,
      actions: { loadPatientDefaultPharmacy, loadBenefitInformation, loadDrugFavorites }
    } = this.props;
    subscribe(`/PrescriptionError/${this.props.currentUserId}`);
    if (patientId) {
      subscribe(`/PrescriptionStatusChanged/${this.props.patientId}`);
      loadPatientDefaultPharmacy(patientId);
      loadBenefitInformation(patientId);
      if (!isEmpty(currentUserId)) loadDrugFavorites();
    }
  }

  componentDidUpdate(prevProps) {
    const {
      patientId,
      currentUserId,
      currentDrug,
      actions: { loadDrugFormulary, loadDrugFavorites }
    } = this.props;

    // check to see if a new prescription was added
    // if so, set the activeIndex to the new prescription
    if (prevProps.currentDrug.drugKey !== currentDrug.drugKey && currentDrug.drugKey) {
      loadDrugFormulary(currentDrug.prescriptionId, patientId, currentDrug.drugKey);
    }

    if (!isEqual(this.props.drugFavorites, prevProps.drugFavorites)) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ orderedDrugFavorites: this.props.drugFavorites });
    }
    if (currentUserId !== prevProps.currentUserId && !isEmpty(currentUserId)) loadDrugFavorites();
  }

  componentWillUnmount() {
    unsubscribe(`/PrescriptionError/${this.props.currentUserId}`);
    if (this.props.patientId) unsubscribe(`/PrescriptionStatusChanged/${this.props.patientId}`);
  }

  onFavoriteDragEnd = (result) => {
    const orderedDrugFavorites = [...this.state.orderedDrugFavorites];
    const { source, destination } = result;
    if (!destination) return;
    if (destination.droppableId === source.droppableId && destination.index === source.index) return;
    const item = { ...orderedDrugFavorites[source.index] };
    orderedDrugFavorites.splice(source.index, 1);
    orderedDrugFavorites.splice(destination.index, 0, item);
    this.setState({ orderedDrugFavorites });
  };

  handlePatientPharmacyClick = () => {
    const {
      actions: { showModal },
      centerId,
      patientId
    } = this.props;
    return showModal({
      type: "PATIENT_PHARMACY_MODAL",
      props: {
        open: true,
        centerId,
        patientId
      }
    });
  };

  handleSearchInput = (e, data) => {
    this.setState({ searchValue: data.value });
  };

  handleAddAlternativeDrug = (_, index) => {
    const {
      patientId,
      currentDrugFormulary = [],
      actions: { createPrescription, setSelectedPrescription }
    } = this.props;
    const selectedMedication = currentDrugFormulary[index];

    const tempObjectToSend = {
      ...selectedMedication,
      isSelected: true
    };
    setSelectedPrescription(null, null);
    createPrescription(patientId, tempObjectToSend);
  };

  handleRxTabClick = (activeTab) => {
    if (this.state.activeRxTab !== activeTab) {
      this.setState({ activeRxTab: activeTab });
      this.props.actions.setSelectedPrescription(null, null);
    }
  };

  handleAllergiesModalOpen = (selectedMedication, screeningResults) => {
    const {
      actions: { showModal }
    } = this.props;
    const { searchedMedication } = this.state;

    showModal({
      type: "PATIENT_ALLERGY_MATCH",
      props: {
        open: true,
        searchedMedication,
        selectedMedication,
        screeningResults
      }
    });
  };

  hadnleFavoriteDrugClick = (drugFavorite) => {
    const {
      authRoles,
      patientId,
      currentPrescriptions,
      recurringPrescriptions,
      selectedPrescription,
      actions: { createPrescription, createRecurringPrescription }
    } = this.props;

    const canEditPrescriptions = checkRoles(roleGroups.editPrescriptions, authRoles);
    const canEditSelectedPrescription =
      canEditPrescriptions &&
      selectedPrescription &&
      selectedPrescription.prescriptionStatus !== prescriptionStatus.signed;

    // if no selected prescription .. check if current or cecurring already has it
    let prescription = currentPrescriptions.find((i) => i.drugKey === drugFavorite.drugKey);
    let _activeRxTab = null;
    if (prescription) {
      _activeRxTab = "Current Rx";
    } else {
      prescription = recurringPrescriptions.find((i) => i.drugKey === drugFavorite.drugKey);
      if (prescription) {
        _activeRxTab = "Recurring Rx";
      }
    }
    if (prescription) {
      this.setState({ activeRxTab: _activeRxTab });
      this.props.actions.setRxItemForm(prescription);
      this.props.actions.setSelectedPrescription(
        prescription.prescriptionId,
        _activeRxTab === "Current Rx" ? "currentRx" : "recurringRx"
      );
      return;
    }

    // edit selected prescription
    if (selectedPrescription && canEditSelectedPrescription) {
      this.props.actions.applyFavoriteToSelectedPrescription(drugFavorite);
      return;
    }

    // otherwise create it
    const { activeRxTab } = this.state;
    fetchDrugAllergies(patientId, drugFavorite.drugKey).then((result) => {
      if (result.length) {
        this.handleAllergiesModalOpen(drugFavorite, result);
      } else if (activeRxTab === "Current Rx") {
        createPrescription(patientId, drugFavorite).then(({ prescriptions }) => {
          this.setState({ activeRxTab });
          this.props.actions.setRxItemForm(prescriptions[0], true);
          this.props.actions.setSelectedPrescription(
            prescriptions[0].prescriptionId,
            activeRxTab === "Current Rx" ? "currentRx" : "recurringRx"
          );
        });
      } else if (activeRxTab === "Recurring Rx") {
        createRecurringPrescription(patientId, drugFavorite).then(({ prescriptionId, prescription: _prescription }) => {
          this.setState({ activeRxTab });
          this.props.actions.setRxItemForm({ ..._prescription, prescriptionId, quantity: 0 }, true);
          this.props.actions.setSelectedPrescription(
            prescriptionId,
            activeRxTab === "Current Rx" ? "currentRx" : "recurringRx"
          );
        });
      }
    });
  };

  handleDeleteFavoriteDrug = (favoriteDrugId) => {
    this.props.actions.showModal({
      type: "CONFIRMATION",
      props: {
        open: true,
        icon: "exclamation-triangle",
        iconColor: "warning",
        title: `Are you sure?`,
        description: `Are you sure?`,
        buttonMessage: `Delete`,
        size: "mini",
        onConfirm: () => {
          this.props.actions.deleteDrugFavorites(favoriteDrugId);
        }
      }
    });
  };

  render() {
    const {
      prescriptions: { selectedPharmacy },
      benefitsInformation,
      currentDrugFormulary,
      currentDrug,
      authRoles
    } = this.props;
    const { orderedDrugFavorites, activeRxTab } = this.state;
    const canEditPrescriptions = checkRoles(roleGroups.editPrescriptions, authRoles);
    const alternatives = (currentDrugFormulary || []).map((drug, index) => (
      <Table.Row key={drug.drugKey}>
        <Table.Cell collapsing>
          <Button icon="plus" onClick={(e) => this.handleAddAlternativeDrug(e, index)} />
        </Table.Cell>
        <Table.Cell>{drug.drugDescription}</Table.Cell>
      </Table.Row>
    ));
    return (
      <div className="content-wrapper prescription-content">
        <Grid doubling stackable>
          <Grid.Row>
            <Grid.Column width={8} />
            <Grid.Column width={2}>
              <h3>Pharmacy</h3>
            </Grid.Column>
            <Grid.Column width={4}>
              <Button
                className="transparent-button-icon pharmacy-edit"
                onClick={this.handlePatientPharmacyClick}
                icon="edit"
                size="big"
              />
              {Object.keys(selectedPharmacy).length ? (
                <div className="pharmacy-information">
                  <h4>{selectedPharmacy.pharmacyName}</h4>
                  <div>
                    {selectedPharmacy.address1} {selectedPharmacy.address2}
                    <br />
                    {selectedPharmacy.city}, {selectedPharmacy.state} {selectedPharmacy.zip}
                  </div>
                  <div>{selectedPharmacy.phone}</div>
                </div>
              ) : (
                <div className="pharmacy-information">No pharmacy selected</div>
              )}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column mobile={16} tablet={16} computer={12}>
              <ManagePrescriptions
                activeRxTab={activeRxTab}
                handleRxTabClick={this.handleRxTabClick}
                showEditCurrent={canEditPrescriptions}
              />
            </Grid.Column>
            <Grid.Column mobile={16} tablet={16} computer={4}>
              <Segment className="right-rail-container">
                <h2>Favorites</h2>
                <DragDropContext onDragEnd={this.onFavoriteDragEnd}>
                  <table className="dad-table no-padding">
                    <Droppable droppableId="favorites-droppable-id">
                      {(provided, snapshot) => (
                        <tbody
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          className={snapshot.isDraggingOver ? `dragging-over` : ``}
                        >
                          {orderedDrugFavorites.length > 0 ? (
                            orderedDrugFavorites.map((drugFavorite, index) => (
                              <Draggable
                                key={drugFavorite.favoriteDrugId}
                                draggableId={drugFavorite.favoriteDrugId}
                                index={index}
                              >
                                {(_provided, _snapshot) => (
                                  <tr
                                    key={drugFavorite.favoriteDrugId}
                                    className={`clickable ${_snapshot.isDragging ? `dragging` : ``}`}
                                    {..._provided.draggableProps}
                                    {..._provided.dragHandleProps}
                                    ref={_provided.innerRef}
                                  >
                                    <td
                                      style={{ padding: ".5em 0 .5em .5em", width: "90%" }}
                                      // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
                                      role="button"
                                      tabIndex={0}
                                      onClick={() => {
                                        this.hadnleFavoriteDrugClick(drugFavorite);
                                      }}
                                    >
                                      <a className="black-link">{`${drugFavorite.drugName} ${
                                        drugFavorite.nickname ? `(${drugFavorite.nickname})` : ``
                                      } ${drugFavorite.form} ${drugFavorite.dosage} ${drugFavorite.frequency} ${
                                        drugFavorite.strength
                                      }`}</a>
                                    </td>
                                    <td
                                      style={{
                                        padding: ".5em .5em .5em 0",
                                        width: "10%",
                                        textAlign: "right"
                                      }}
                                    >
                                      <Icon
                                        title="Delete"
                                        name="delete"
                                        color="red"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          this.handleDeleteFavoriteDrug(drugFavorite.favoriteDrugId);
                                        }}
                                        style={{ margin: "2px", fontSize: "1.1em" }}
                                      />
                                    </td>
                                  </tr>
                                )}
                              </Draggable>
                            ))
                          ) : (
                            <tr>
                              <td>No available favorites</td>
                            </tr>
                          )}
                          {provided.placeholder}
                        </tbody>
                      )}
                    </Droppable>
                  </table>
                </DragDropContext>
              </Segment>

              <Segment className="right-rail-container">
                <h2>Alternatives</h2>
                <Table basic="very" unstackable>
                  <Table.Body>
                    {alternatives.length > 0 ? (
                      alternatives
                    ) : (
                      <Table.Row>
                        <Table.Cell>No available alternatives</Table.Cell>
                      </Table.Row>
                    )}
                  </Table.Body>
                  <Table.Footer />
                </Table>
              </Segment>
              <Segment className="right-rail-container">
                <h2>Drug Information</h2>
                <div>
                  <label>Drug:</label>
                  <h3>{currentDrug.drugName || "N/A"}</h3>
                  <Table basic="very" unstackable>
                    <Table.Body>
                      <Table.Row>
                        <Table.Cell>
                          <label>Policy:</label>
                        </Table.Cell>
                        <Table.Cell>{((benefitsInformation || [])[0] || {}).policy || "N/A"}</Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell>
                          <label>Formulary Status:</label>
                        </Table.Cell>
                        <Table.Cell>{((currentDrugFormulary || [])[0] || {}).formularyStatus || "N/A"}</Table.Cell>
                      </Table.Row>
                    </Table.Body>
                    <Table.Footer />
                  </Table>
                </div>
              </Segment>
              <Segment className="right-rail-container">
                <h2>Benefit Information</h2>
                {benefitsInformation.map((bi) => (
                  <div key={shortid.generate()}>
                    <label>Plan Name:</label>
                    <h3>{bi.planName || "N/A"}</h3>
                    <Table basic="very" unstackable>
                      <Table.Body>
                        <Table.Row>
                          <Table.Cell>
                            <label>Policy:</label>
                          </Table.Cell>
                          <Table.Cell>{bi.policy || "N/A"}</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                          <Table.Cell>
                            <label>Pharmacy:</label>
                          </Table.Cell>
                          <Table.Cell>{bi.pharmacy || "N/A"}</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                          <Table.Cell>
                            <label>Mail Order:</label>
                          </Table.Cell>
                          <Table.Cell>{bi.mailOrder || "N/A"}</Table.Cell>
                        </Table.Row>
                      </Table.Body>
                      <Table.Footer />
                    </Table>
                  </div>
                ))}
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const benefitsInformation = state.insurance.benefitsInformation;
  const currentDrug = state.prescriptions.currentPrescriptions.find((p) => p.isSelected && p.drugKey) || {};

  return {
    currentUserId: state.userPreference.currentUserId,
    patientId: state.patient.currentPatient.patientId,
    prescriptions: state.prescriptions,
    benefitsInformation,
    currentDrugFormulary: currentDrug.drugKey ? state.prescriptions.currentDrugFormulary : [],
    currentDrug,
    authRoles: state.auth.user.profile.roles,
    centerId: state.demographics.homeCenterId,
    drugFavorites: state.user.drugFavorites,
    currentPrescriptions: state.prescriptions.currentPrescriptions,
    recurringPrescriptions: state.prescriptions.recurringPrescriptions,
    selectedPrescription: getSelectedPrescription(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      { ...modalActions, ...userActions, ...prescriptionActions, ...insuranceActions },
      dispatch
    )
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PrescriptionsPage);
