import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function visitsReducer(state = initialState.streaming, action) {
  switch (action.type) {
    case types.SET_STREAM_ID:
      return { ...state, streamId: action.streamId };
    case types.ADD_ITEM_TO_SUBSCRIPTION_LIST:
      return { ...state, subscriptionList: [...state.subscriptionList, action.item] };
    case types.REMOVE_ITEM_FROM_SUBSCRIPTION_LIST: {
      const updatedSubscriptionList = [...state.subscriptionList];
      const index = updatedSubscriptionList.findIndex(i => i === action.item);
      if (index > -1) updatedSubscriptionList.splice(index, 1);
      return { ...state, subscriptionList: updatedSubscriptionList };
    }
    default:
      return state;
  }
}
