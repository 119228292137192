import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Icon, Button } from "../../../../ui";
import * as modalActions from "../../../../actions/modalActions";
import { checkRoles } from "../../../../helpers";
import { roleGroups } from "../../../../constants/securityRoles";

export class AddPCP extends Component {
  state = {};

  handleModalOpen = () => {
    if (this.props.handleAdd) this.props.handleAdd();
    else
      this.props.actions.showModal({
        type: "DOCTOR_SEARCH",
        props: {
          open: true
        }
      });
  };

  render() {
    const { authRoles } = this.props;
    const showAddPcp = checkRoles(roleGroups.excludeHelpDesk, authRoles);
    return (
      <div className="add-PCP">
        <p>There is currently no Primary Care Physician on file.</p>
        {showAddPcp && (
          <Button id="btn-add-PCP" variant="outline-primary" size="lg" onClick={this.handleModalOpen}>
            <Icon name={"plus-lg"} />
            Add PCP
          </Button>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    patientId: state.patient.currentPatient.patientId,
    authRoles: state.auth.user.profile.roles
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...modalActions }, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddPCP);
