import React, { Component } from "react";
import { Button, Form } from "semantic-ui-react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import shortid from "shortid";
import { isEmpty } from "lodash";
import * as miscActions from "../../actions/miscActions";
import { saveAttestation } from "../../actions/prescriptionActions";

const getAttestation = props => {
  const {
    currentPrescriptionToSign: { attestionCustomText, attestationPredefined },
    currentPrescriber: { value: prescriberId }
  } = props;
  return {
    loadingReport: false,
    reportLoaded: false,
    customText: attestionCustomText || "",
    predefined: attestationPredefined,
    prescriberId
  };
};

export class AttestationForm extends Component {
  state = getAttestation(this.props);

  componentDidUpdate(prevProps) {
    const {
      narxScores: { reportUrl }
    } = this.props;
    if (this.state.loadingReport && reportUrl !== prevProps.narxScores.reportUrl) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ loadingReport: false, reportLoaded: true });
      window.open(reportUrl, "_blank");
    }
  }

  componentWillUnmount() {
    this.props.actions.clearNarxScores();
  }

  handleSaveAttestation = () => {
    const {
      actions: { saveAttestation: saveAttest },
      currentPrescriptionToSign: { prescriptionId },
      patientId
    } = this.props;
    const payload = this.state;

    saveAttest(patientId, prescriptionId, payload);
  };

  handleFieldChange = (_, data) => {
    const { name: field, value, type, checked } = data;

    if (type === "checkbox") {
      return this.setState({ [field]: checked });
    } else if (type === "radio") {
      return this.setState({ [field]: value, customText: value });
    }
    return this.setState({ [field]: value });
  };

  handleAttestationClick = () => {
    if (this.state.reportLoaded) return;
    this.setState({ loadingReport: true });
    this.props.actions.queryNarxScores(this.props.patientId);
  };

  render() {
    const {
      prescriptionToSign: { patientFirstName, patientLastName },
      currentPrescriptionToSign: { attestationSaved },
      attestationOptions,
      processing,
      visitProvider: { fullname }
    } = this.props;
    const { customText, predefined } = this.state;

    const mappedAttestationOptions = attestationOptions.map(attestationOption => {
      const value = attestationOption.value
        .replace("--{PATIENT_NAME}--", `${patientFirstName} ${patientLastName}`)
        .replace("--{PROVIDER_NAME}--", fullname);
      const text = attestationOption.value
        .replace("--{PATIENT_NAME}--", `${patientFirstName} ${patientLastName}`)
        .replace("--{PROVIDER_NAME}--", fullname);

      return { text, value };
    });

    return (
      <Form className="attestation-form" onSubmit={this.handleSaveAttestation}>
        <Form.TextArea
          label="Custom Text:"
          name="customText"
          value={customText}
          onChange={this.handleFieldChange}
          rows={6}
          width={10}
          maxLength={500}
        />
        <Form.Group grouped>
          {mappedAttestationOptions.map(option => (
            <Form.Radio
              label={option.text}
              value={option.value}
              checked={predefined === option.value}
              key={shortid.generate()}
              onChange={this.handleFieldChange}
              onClick={this.handleAttestationClick}
              name="predefined"
            />
          ))}
        </Form.Group>
        <Button
          content="Save attestation"
          disabled={processing || (isEmpty(customText) && isEmpty(predefined))}
          color={attestationSaved ? "green" : "blue"}
          loading={processing}
          type="submit"
        />
      </Form>
    );
  }
}

function mapStateToProps(state) {
  return {
    narxScores: state.misc.narxScores
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...miscActions,
        saveAttestation
      },
      dispatch
    )
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AttestationForm);
