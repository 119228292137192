import React, { Component } from "react";
import { Button, Header, Modal, Grid } from "semantic-ui-react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";

import * as modalActions from "../../../actions/modalActions";
import { generatePrescriptionDocuments } from "../../../actions/prescriptionActions";
import { getDocument } from "../../../actions/documentActions";
import { loadLookup } from "../../../actions/lookupActions";

import "../../reusable/Modal.css";

export class PrintPrescriptionModal extends Component {
  handleClose = () => {
    const {
      actions: { hideModal }
    } = this.props;

    hideModal();
  };

  handlePrint = () => {
    const {
      actions: { generatePrescriptionDocuments: createPrescriptionDocuments, getDocument: fetchDocument },
      patientId,
      prescriptionIds,
      prescriberId
    } = this.props;

    const query = `prescriptionIds=${prescriptionIds.join("&prescriptionIds=")}`;

    createPrescriptionDocuments(patientId, query, { prescriberId })
      .then(({ prescriptionsToPrint = [] } = {}) => {
        const documentsToGet = prescriptionsToPrint.map(rx =>
          fetchDocument(rx.DocumentId, "prescriptionToPrint", rx.DocumentContainer)
        );

        return prescriptionsToPrint.length ? Promise.all(documentsToGet) : Promise.reject();
      })
      .then(responses => {
        responses.forEach(response => {
          const blob = new Blob([response.document], {
            type: "application/pdf"
          });
          const fileURL = URL.createObjectURL(blob);
          const w = window.open(fileURL);
          w.print();
        });
      })
      .then(() => this.handleClose())
      .catch(() => this.handleClose());
  };

  render() {
    const { open, processing } = this.props;

    return (
      <Modal open={open} onClose={this.handleClose} closeIcon size="small">
        <Modal.Content>
          <Grid relaxed padded stackable>
            <Grid.Row>
              <Grid.Column width={16}>
                <Header size="medium">Are you sure you want to print this prescription?</Header>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={16}>
                <Header size="small">This will move the selected prescription(s) to prescriptions sent.</Header>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Modal.Content>
        <Modal.Actions>
          <Button color="blue" onClick={this.handleClose} content="Don't Print" loading={processing} />
          <Button color="red" content="Print" loading={processing} onClick={this.handlePrint} />
        </Modal.Actions>
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  rxAuthenticationTypes: state.lookups.rxAuthenticationTypes,
  processing: state.ajaxCallsInProgress > 0,
  currentRx: state.prescriptions.currentPrescriptions
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      ...modalActions,
      getDocument,
      loadLookup,
      generatePrescriptionDocuments
    },
    dispatch
  )
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(PrintPrescriptionModal)
);
