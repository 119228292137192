import React, { Component } from "react";
import { Button, Header, Modal, Form } from "semantic-ui-react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as modalActions from "../../actions/modalActions";
import * as labActions from "../../actions/labActions";

import "../reusable/Modal.css";

export class CancelSubmittedLabModal extends Component {
  state = {
    reason: "",
    valid: false
  };

  handleClose = (back = true) => {
    const {
      orderId,
      labProvider,
      testType,
      submissionId,
      labSource,
      labCode,
      actions: { hideModal, setTestCancelButtonPressed },
      multiple
    } = this.props;
    setTestCancelButtonPressed(orderId, labProvider, testType, submissionId, labSource, labCode, false);
    hideModal(multiple, !back);
  };

  handleSave = () => {
    const { patientId, testType, orderId, labCode } = this.props;
    const promiseArray = [
      new Promise(resolve => {
        this.props.actions.cancelSubmittedLab(patientId, testType, orderId, labCode, this.state.reason);
        resolve();
      })
    ];
    Promise.all(promiseArray).then(() => this.props.actions.hideModal(false, true));
  };

  handleReasonOnChange = (e, data) => {
    this.setState({ reason: data.value, valid: data.value.trim() !== "" });
  };

  render() {
    const { open, processing, labName } = this.props;

    return (
      <Modal open={open} onClose={this.handleClose} closeIcon size="small" className="secondary-dem-modal">
        <Header content={`Cancel submitted test - ${labName}`} />
        <Modal.Content>
          <Form>
            <Form.Group widths="equal">
              <Form.Field>
                <Form.TextArea
                  name="cancelationReason"
                  label="Reason"
                  placeholder="Please type the reason"
                  value={this.state.reason}
                  maxLength={140}
                  onChange={this.handleReasonOnChange}
                  id="input-cancelation-reason"
                  onFocus={this.handleFocus}
                />
              </Form.Field>
            </Form.Group>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <div style={{ textAlign: "left" }}>
            <strong
              style={{ fontSize: "medium", color: "#db2828" }}
            >{`Are you sure you want to cancel this order? If so, you must also submit a voided label form to the Main Holyoke Lab.`}</strong>
          </div>
          <Button onClick={this.handleClose} id="btn-cancel">
            No, do not cancel
          </Button>
          <Button
            color="blue"
            onClick={this.handleSave}
            id="btn-save"
            disabled={!this.state.valid}
            loading={processing}
          >
            Yes, cancel
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    processing: state.ajaxCallsInProgress > 0
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...modalActions,
        ...labActions
      },
      dispatch
    )
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CancelSubmittedLabModal);
