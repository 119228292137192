import moment from "moment";
import "react-toastify/dist/ReactToastify.css";
import * as types from "./actionTypes";
import { beginAjaxCall, ajaxCallDone, ajaxCallError } from "./ajaxStatusActions";
import { processApiResponse, processApiError } from "../helpers";
import { adalApiFetch } from "../adalConfig";
import { API_URL } from "../constants/environment";

export function submitAthenaClaimSuccess(athenaVisitClaimItems) {
  return {
    type: types.SUBMIT_ATHENA_CLAIM_SUCCESS,
    athenaVisitClaimItems
  };
}

function loadReportsSuccess(reports) {
  return {
    type: types.LOAD_REPORTS_SUCCESS,
    reports
  };
}

function loadTokensSuccess(token) {
  return {
    type: types.LOAD_REPORTTOKENS_SUCCESS,
    token
  };
}

function loadReportRolesSuccess(reportGroups) {
  return {
    type: types.LOAD_REPORTROLES_SUCCESS,
    reportGroups
  };
}

export function loadReports() {
  return dispatch => {
    dispatch(beginAjaxCall("loadReports"));
    return adalApiFetch(`${API_URL}/Reports/List`)
      .then(reports => dispatch(loadReportsSuccess(reports)))
      .catch(error => {
        processApiError(error, dispatch);
      })
      .finally(() => {
        dispatch(ajaxCallDone());
      });
  };
}

export function loadTokens(id) {
  return dispatch => {
    dispatch(beginAjaxCall("loadTokens"));
    return adalApiFetch(`${API_URL}/Reports/Token?reportId=${id}`)
      .then(reportTokens => dispatch(loadTokensSuccess(reportTokens)))
      .catch(error => {
        processApiError(error, dispatch);
      })
      .finally(() => {
        dispatch(ajaxCallDone());
      });
  };
}

export function loadReportRoles() {
  return dispatch => {
    dispatch(beginAjaxCall("loadReportRoles"));
    return adalApiFetch(`${API_URL}/Reports/Roles`)
      .then(reportGroups => dispatch(loadReportRolesSuccess(reportGroups)))
      .catch(error => {
        processApiError(error, dispatch);
      })
      .finally(() => {
        dispatch(ajaxCallDone());
      });
  };
}

export function updateReportsFromServices() {
  return dispatch => {
    dispatch(beginAjaxCall("updateReportsFromServices"));
    return adalApiFetch(`${API_URL}/Reports/UpdateReports`, {
      method: "POST"
    })
      .then(response => processApiResponse(response.orchestrationId))
      .catch(error => {
        processApiError(error, dispatch).then(dispatch(ajaxCallError(error)));
      })
      .finally(() => {
        dispatch(ajaxCallDone());
      });
  };
}

export function updateReportRoles(reportId, roles) {
  return dispatch => {
    dispatch(beginAjaxCall("updateReportRoles"));
    return adalApiFetch(`${API_URL}/Reports/${reportId}/UpdateRoles`, {
      method: "PUT",
      body: JSON.stringify({ roles })
    })
      .then(response => processApiResponse(response.orchestrationId))
      .then(() => dispatch(loadReportRoles()))
      .catch(error => {
        processApiError(error, dispatch);
      })
      .finally(() => {
        dispatch(ajaxCallDone());
      });
  };
}

function setClaimReconciliations(data, all) {
  return {
    type: all ? types.SET_ALL_CLAIM_RECONCILIATIONS : types.SET_CLAIM_RECONCILIATIONS,
    data
  };
}

export function loadClaimReconciliations(
  pageSize = 100,
  pageNumber = 1,
  filter = {},
  sortColumn = "",
  sortDirection = "",
  all = false
) {
  return dispatch => {
    dispatch(beginAjaxCall("loadClaimReconciliations"));
    const params = {
      pageSize,
      pageNumber,
      sortBy: sortColumn || "",
      sortDirection: sortDirection === "ascending" ? "asc" : sortDirection === "descending" ? "desc" : "",
      visitFrom: filter.visitTimeFrom
        ? moment(filter.visitTimeFrom)
            .utc()
            .toISOString()
        : "",
      visitTo: filter.visitTimeTo
        ? moment(filter.visitTimeTo)
            .utc()
            .toISOString()
        : "",
      centerIds: filter.centerIds.join(","),
      providerIds: filter.providerIds.join(","),
      payorIds: filter.payorIds.join(","),
      procedureCode: filter.procedureCode,
      hasAthenaClaim: filter.hasAthenaClaim
    };
    const urlParams = Object.keys(params)
      .map(k => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
      .join("&");
    return adalApiFetch(`${API_URL}/Reports/ClaimReconciliation?${urlParams}`)
      .then(data => {
        if (data.message && data.message === "Validation Failed") {
          /* response status 422 */
          processApiError(data.errors[0].message).then(dispatch(ajaxCallError(data.errors[0].message)));
          return;
        }
        dispatch(setClaimReconciliations(data, all));
      })
      .catch(error => processApiError(error, dispatch))
      .finally(() => dispatch(ajaxCallDone()));
  };
}

function setReviewOfPatients(data, all) {
  return {
    type: all ? types.SET_ALL_REVIEW_OF_PATIENTS : types.SET_REVIEW_OF_PATIENTS,
    data
  };
}

export function loadReviewOfPatients(
  pageSize = 100,
  pageNumber = 1,
  filter = {},
  sortColumn = "",
  sortDirection = "",
  all = false
) {
  return dispatch => {
    dispatch(beginAjaxCall("loadReviewOfPatients"));
    const params = {
      pageSize,
      pageNumber,
      sortBy: sortColumn || "",
      sortDirection: sortDirection === "ascending" ? "asc" : sortDirection === "descending" ? "desc" : "",
      visitFrom: filter.visitTimeFrom || "",
      visitTo: filter.visitTimeTo || "",
      centerIds: filter.centerIds.join(","),
      providerIds: filter.providerIds.join(","),
      visitTypes: filter.visitTypes.join(","),
      patientLevels: filter.patientLevels.join(",")
    };
    const urlParams = Object.keys(params)
      .map(k => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
      .join("&");
    return adalApiFetch(`${API_URL}/Reports/ReviewOfPatients?${urlParams}`)
      .then(data => {
        if (data.message && data.message === "Validation Failed") {
          /* response status 422 */
          processApiError(data.errors[0].message).then(dispatch(ajaxCallError(data.errors[0].message)));
          return;
        }
        dispatch(setReviewOfPatients(data, all));
      })
      .catch(error => processApiError(error, dispatch))
      .finally(() => dispatch(ajaxCallDone()));
  };
}

function setReportData(response, all) {
  return {
    type: all ? types.SET_REPORT_ALL_DATA : types.SET_REPORT_DATA,
    response
  };
}

export function loadReportData(
  endPoint,
  method,
  pagination,
  pageSize = 100,
  pageNumber = 1,
  filter = {},
  sortColumn = "",
  sortDirection = "",
  all = false
) {
  return dispatch => {
    dispatch(beginAjaxCall(`load${endPoint}ReportData`));
    const params = {
      pageSize,
      pageNumber,
      sortBy: sortColumn || "",
      sortDirection: sortDirection === "ascending" ? "asc" : sortDirection === "descending" ? "desc" : ""
    };
    if (pagination === false) {
      delete params.pageSize;
      delete params.pageNumber;
    }
    if (filter) {
      Object.keys(filter).forEach(key => {
        params[key] = method === "GET" && Array.isArray(filter[key]) ? filter[key].join(",") : filter[key];
      });
    }
    const urlParams =
      method === "GET"
        ? Object.keys(params)
            .map(k => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
            .join("&")
        : ``;
    return adalApiFetch(`${API_URL}/Reports/${endPoint}?${urlParams}`, {
      method,
      body: method === "GET" ? undefined : JSON.stringify(params)
    })
      .then(response => {
        if (response.message && response.message === "Validation Failed") {
          /* response status 422 */
          processApiError(response.errors[0].message).then(dispatch(ajaxCallError(response.errors[0].message)));
          return;
        }
        dispatch(setReportData(response, all));
      })
      .catch(error => processApiError(error, dispatch))
      .finally(() => dispatch(ajaxCallDone()));
  };
}
