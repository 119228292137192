import * as types from "./actionTypes";

export function setStreamId(streamId) {
  return {
    type: types.SET_STREAM_ID,
    streamId
  };
}

export function addItemToSubscriptionList(item) {
  return {
    type: types.ADD_ITEM_TO_SUBSCRIPTION_LIST,
    item
  };
}

export function removeItemFromSubscriptionList(item) {
  return {
    type: types.REMOVE_ITEM_FROM_SUBSCRIPTION_LIST,
    item
  };
}
