import React from "react";
import { Tab as BsTab } from "react-bootstrap";

const Tab = React.forwardRef((props, ref) => <BsTab {...props} ref={ref} />);

Tab.Container = BsTab.Container;
Tab.Content = BsTab.Content;
Tab.Pane = BsTab.Pane;

export default Tab;
