import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import ChartsToSignTable from "./ChartsToSignTable";
import * as providerActions from "../../actions/providerActions";
import * as chartsActions from "../../actions/chartsActions";

export class ChartsPage extends Component {
  state = {};

  componentDidMount() {
    this.props.actions.loadProviders().then(() => {
      this.props.actions.loadChartsToSign();
    });
  }

  render() {
    const { provider, charts, visitTypes } = this.props;
    return (
      <React.Fragment>
        <div className="header-wrapper">
          <h2>Charts To Sign</h2>
        </div>

        <div className="content-wrapper">
          {visitTypes && <ChartsToSignTable loggedInProvider={provider} charts={charts} />}
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const provider = state.providers.allProviders.length ? state.providers.allProviders[0].userId : "";
  return {
    provider,
    charts: state.charts.chartsToSign,
    visitTypes: state.lookups.visitTypes
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...providerActions,
        ...chartsActions
      },
      dispatch
    )
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ChartsPage);
