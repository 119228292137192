import { createAction } from "@reduxjs/toolkit";
import * as types from "./actionTypes";
import { adalApiFetch } from "../adalConfig";
import { API_URL } from "../constants/environment";
import { pollApiEvents, processApiError, processApiResponse, processThenThrowApiError } from "../helpers";
import { beginAjaxCall, ajaxCallDone } from "./ajaxStatusActions";
import { getPatientNoteSlashTaskCount } from "./patientActions";

// export function fetchNoteSlashTasksDone(payload) {
//   return { type: types.LOAD_NOTE_SLASH_TASKS_DONE, payload };
// }

const fetchNoteSlashTasksDone = createAction(types.LOAD_NOTE_SLASH_TASKS_DONE);

export function setForceReload(value) {
  return { type: types.SET_NOTE_SLASH_TASKS_FORCE_RELOAD, value };
}

export function fetchNoteSlashTaskDetailsDone(entityId, entity) {
  return {
    type: types.FETCH_NOTE_SLASH_TASK_DETAILS_DONE,
    entityId,
    entity
  };
}

export function fetchNoteSlashTasks(filter) {
  return (dispatch) => {
    dispatch(beginAjaxCall("fetchNoteSlashTasks"));
    return adalApiFetch(`${API_URL}/Tasks`, {
      method: "POST",
      body: JSON.stringify(filter)
    })
      .then((response) => dispatch(fetchNoteSlashTasksDone(response)))
      .catch((error) => processApiError(error, dispatch))
      .finally(() => dispatch(ajaxCallDone()));
  };
}

export function fetchNoteSlashTaskDetails(entityId) {
  return (dispatch) => {
    dispatch(beginAjaxCall("fetchNoteSlashTaskDetails"));
    return adalApiFetch(`${API_URL}/Task/${entityId}`)
      .then((entity) => dispatch(fetchNoteSlashTaskDetailsDone(entityId, entity)))
      .catch((error) => processApiError(error, dispatch))
      .finally(() => dispatch(ajaxCallDone()));
  };
}

export function fetchNoteSlashTaskCommentsAndCC(entityId) {
  return (dispatch) => {
    dispatch(beginAjaxCall("fetchNoteSlashTaskCommentsAndCC"));
    return adalApiFetch(`${API_URL}/Task/${entityId}/CommentsAndCC`)
      .catch((error) => processApiError(error, dispatch))
      .finally(() => dispatch(ajaxCallDone()));
  };
}

export function saveNoteSlashTask(patientId, entity, entityId) {
  return (dispatch) => {
    dispatch(beginAjaxCall("saveNoteSlashTask"));
    return adalApiFetch(`${API_URL}/Patient/${patientId}/Task${entityId ? `/${entityId}` : ``}`, {
      method: entityId ? "PUT" : "POST",
      body: JSON.stringify(entity)
    })
      .then((response) => processApiResponse(response.orchestrationId))
      .then((orchestrationId) => pollApiEvents(orchestrationId, entityId ? "ITaskUpdatedEvent" : "ITaskCreatedEvent"))
      .then(() => dispatch(setForceReload(true)))
      .then(() => {
        dispatch(getPatientNoteSlashTaskCount(patientId));
      })
      .catch((error) => processThenThrowApiError(error, dispatch))
      .finally(() => dispatch(ajaxCallDone()));
  };
}

export function deleteNoteSlashTask(patientId, taskId) {
  return (dispatch) => {
    dispatch(beginAjaxCall("saveNoteSlashTask"));
    return adalApiFetch(`${API_URL}/Patient/${patientId}/Task/${taskId}`, {
      method: "DELETE"
    })
      .then((response) => processApiResponse(response.orchestrationId))
      .then((orchestrationId) => pollApiEvents(orchestrationId, "ITaskDeletedEvent"))
      .catch((error) => processApiError(error, dispatch))
      .then(() => {
        dispatch(getPatientNoteSlashTaskCount(patientId));
      })
      .finally(() => dispatch(ajaxCallDone()));
  };
}

export function restoreNoteSlashTask(patientId, taskId) {
  return (dispatch) => {
    dispatch(beginAjaxCall("restoreNoteSlashTask"));
    return adalApiFetch(`${API_URL}/Patient/${patientId}/Task/${taskId}/Restore`, {
      method: "POST"
    })
      .then((response) => processApiResponse(response.orchestrationId))
      .then((orchestrationId) => pollApiEvents(orchestrationId, "ITaskRestoredEvent"))
      .catch((error) => processApiError(error, dispatch))
      .then(() => {
        dispatch(getPatientNoteSlashTaskCount(patientId));
      })
      .finally(() => dispatch(ajaxCallDone()));
  };
}

export function addNoteSlashTaskComment(patientId, taskId, comment) {
  return (dispatch) => {
    dispatch(beginAjaxCall("addNoteSlashTaskComment"));
    return adalApiFetch(`${API_URL}/Patient/${patientId}/Task/${taskId}/Comment`, {
      method: "POST",
      body: JSON.stringify({ note: comment })
    })
      .then((response) => processApiResponse(response.orchestrationId))
      .then((orchestrationId) => pollApiEvents(orchestrationId, "ITaskCommentAddedEvent"))
      .catch((error) => processApiError(error, dispatch))
      .finally(() => dispatch(ajaxCallDone()));
  };
}

export function completeNoteSlashTask(patientId, taskId) {
  return (dispatch) => {
    dispatch(beginAjaxCall("completeNoteSlashTask"));
    return adalApiFetch(`${API_URL}/Patient/${patientId}/Task/${taskId}/Complete`, {
      method: "POST"
    })
      .then((response) => processApiResponse(response.orchestrationId))
      .then((orchestrationId) => pollApiEvents(orchestrationId, "ITaskCompletedEvent"))
      .then(() => {
        dispatch(getPatientNoteSlashTaskCount(patientId));
      })
      .catch((error) => processApiError(error, dispatch))
      .finally(() => dispatch(ajaxCallDone()));
  };
}

export function openNoteSlashTask(patientId, taskId) {
  return (dispatch) => {
    dispatch(beginAjaxCall("openNoteSlashTask"));
    return adalApiFetch(`${API_URL}/Patient/${patientId}/Task/${taskId}/Open`, {
      method: "POST"
    })
      .then((response) => processApiResponse(response.orchestrationId))
      .then((orchestrationId) => pollApiEvents(orchestrationId, "ITaskOpenedEvent"))
      .catch((error) => processApiError(error, dispatch))
      .then(() => {
        dispatch(getPatientNoteSlashTaskCount(patientId));
      })
      .finally(() => dispatch(ajaxCallDone()));
  };
}
