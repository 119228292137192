import React from "react";
import { Menu } from "semantic-ui-react";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { isEqual } from "lodash";
import * as badgeActions from "../../actions/badgeActions";

import "./ConnectedBadge.css";

export class ConnectedBadgeWS extends React.Component {
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.dependsOn !== prevState.dependsOn) {
      return { dependsOn: nextProps.dependsOn };
    }
    return { dependsOn: prevState.dependsOn };
  }

  state = {
    dependsOn: null
  };

  componentDidMount() {
    const { actions, loadAction } = this.props;
    if (loadAction) {
      actions[loadAction]();
    }
  }

  componentDidUpdate(prevProps) {
    const { actions, loadAction } = this.props;
    const { dependsOn } = this.state;

    if (!isEqual(dependsOn, prevProps.dependsOn)) {
      actions[loadAction](prevProps.dependsOn);
    }
  }

  render() {
    const { name, navigation, isMobile, badge, isUrgent, handleSelect } = this.props;
    return (
      <Menu.Item
        as={NavLink}
        to={`/${navigation}`}
        id={`sidebar-${navigation}`}
        data-name={navigation}
        onClick={handleSelect}
        activeStyle={{ backgroundColor: "#82bf51" }}
      >
        {this.props.children}
        {isMobile && <span>{name}</span>}
        {badge && badge !== 0 ? <span className={`connected-badge ${isUrgent ? "urgent" : ""}`}>{badge}</span> : null}
      </Menu.Item>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    badge: ownProps.subscribeTo ? state.badges[ownProps.subscribeTo] : null,
    isUrgent: ownProps.isUrgent ? state.badges[ownProps.isUrgent] : null
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...badgeActions }, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ConnectedBadgeWS);
