import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { Container, Row, Col, Button, Icon } from "../../../../ui";
import PatientPsychiatrist from "../../demographics/accordion/PatientPsychiatrist";
import PatientOutsideCounselor from "../../demographics/accordion/PatientOutsideCounselor";
import PatientOBProvider from "../../demographics/accordion/PatientOBProvider";
import PatientOtherProviders from "../../demographics/accordion/PatientOtherProviders";
import OutsideProviderHistoryView from "../accordion/OutsideProviderHistoryView";

export class OutsideProviders extends Component {
  state = {
    view: "FORMS"
  };

  componentDidMount() {}

  componentDidUpdate() {}

  render() {
    const {
      demographics: { outsideCounselor, obProvider },
      handleContinue
    } = this.props;

    const { view } = this.state;

    return (
      <Container className="p-0">
        {view === "FORMS" && (
          <React.Fragment>
            <Row>
              <Col>
                <label className="h5">Psychiatrist</label>
                <PatientPsychiatrist />
              </Col>
              <Col>
                <label className="h5">Outside Counselor</label>
                <PatientOutsideCounselor
                  outsideCounselor={outsideCounselor}
                  handleOutsideCounselorHistory={() => {
                    this.setState({ view: "OUTSIDE_COUNSELOR_HISTORY" });
                  }}
                />
              </Col>
            </Row>
            <Row className="pb-2">
              <Col>
                <label className="h5">OB Provider</label>
                <PatientOBProvider
                  obProvider={obProvider}
                  handleOBProviderHistory={() => {
                    this.setState({ view: "OB_PROVIDER_HISTORY" });
                  }}
                />
              </Col>

              <Col>
                <label className="h5">Other Provider</label>
                <PatientOtherProviders />
              </Col>
            </Row>
            <Row className="mt-2 p-2" style={{ borderTop: "1px solid rgba(34,36,38,.15)" }}>
              <Col className="text-end">
                <Button variant="primary" onClick={handleContinue} style={{ minWidth: "100px" }}>
                  {`Continue`}
                  <Icon name="chevron-right" />
                </Button>
              </Col>
            </Row>
          </React.Fragment>
        )}
        {view !== "FORMS" && (
          <Row>
            <Col className="p-3">
              <Button
                variant="danger"
                onClick={() => {
                  this.setState({ view: "FORMS" });
                }}
              >
                Back
              </Button>
            </Col>
          </Row>
        )}
        {view === "PSYCHIATRIST_HISTORY" && (
          <Row>
            <OutsideProviderHistoryView
              header="Psychiatrist History"
              stateItem="psychiatristHistory"
              columns={[
                { name: "psychiatrisId", visible: false },
                { name: "psychiatrist", header: "Psychiatrist" },
                { name: "dateAssigned", header: "Date Added" },
                { name: "dateRemoved", header: "Date Removed" }
              ]}
              keyColumn="psychiatrisId"
            />
          </Row>
        )}
        {view === "OUTSIDE_COUNSELOR_HISTORY" && (
          <Row>
            <OutsideProviderHistoryView
              header="Outside Counselor History"
              stateItem="outsideCounselorHistory"
              columns={[
                { name: "outsideCounselorId", visible: false },
                { name: "outsideCounselor", header: "Outside Counselor" },
                { name: "dateAssigned", header: "Date Added" },
                { name: "dateRemoved", header: "Date Removed" }
              ]}
              keyColumn="outsideCounselorId"
            />
          </Row>
        )}
        {view === "OB_PROVIDER_HISTORY" && (
          <Row>
            <OutsideProviderHistoryView
              header="OB Provider History"
              stateItem="obProviderHistory"
              columns={[
                { name: "obProviderId", visible: false },
                { name: "obProvider", header: "OB Provider" },
                { name: "dateAssigned", header: "Date Added" },
                { name: "dateRemoved", header: "Date Removed" }
              ]}
              keyColumn="obProviderId"
            />
          </Row>
        )}
        {view === "OTHER_PROVIDER_HISTORY" && (
          <Row>
            <OutsideProviderHistoryView
              header="Other Provider History"
              stateItem="otherProviderHistory"
              columns={[
                { name: "otherProviderId", visible: false },
                { name: "otherProvider", header: "Other Provider" },
                { name: "dateAssigned", header: "Date Added" },
                { name: "dateRemoved", header: "Date Removed" }
              ]}
              keyColumn="otherProviderId"
            />
          </Row>
        )}
      </Container>
    );
  }
}

function mapStateToProps(state) {
  return {
    patientId: state.demographics.patientId,
    authRoles: state.auth.user.profile.roles,
    demographics: state.demographics,
    processing: state.ajaxCallsInProgress > 0
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({}, dispatch)
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OutsideProviders));
