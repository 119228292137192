export const NEW_PATIENT = "NEW_PATIENT";
export const CORE_DEMOGRAPHICS = "CORE_DEMOGRAPHICS";
export const NEW_VISIT = "NEW_VISIT";
export const NEW_CENTER = "NEW_CENTER";
export const SEARCH_CONDITION = "SEARCH_CONDITION";
export const SEARCH_MEDICATION = "SEARCH_MEDICATION";
export const ADD_FAMILY_HISTORY = "ADD_FAMILY_HISTORY";
export const ADD_MEDICATION = "ADD_MEDICATION";
export const SEARCH_ALLERGY = "SEARCH_ALLERGY";
export const ADD_ALLERGY = "ADD_ALLERGY";
export const ADD_MEDICAL_HISTORY = "ADD_MEDICAL_HISTORY";
export const ADD_INTERVENTION_HISTORY = "ADD_INTERVENTION_HISTORY";
export const EDIT_EMAIL_ADDRESS = "EDIT_EMAIL_ADDRESS";
export const EDIT_PHONE_NUMBER = "EDIT_PHONE_NUMBER";
export const EDIT_ASSOCIATED_CONTACT = "EDIT_ASSOCIATED_CONTACT";
export const PATIENT_PHOTO = "PATIENT_PHOTO";
export const EDIT_ADDRESS_MODAL = "EDIT_ADDRESS_MODAL";
export const PATIENT_PHARMACY_MODAL = "PATIENT_PHARMACY_MODAL";
export const PROVIDER_INTEGRATION = "PROVIDER_INTEGRATION";
export const EDIT_MAIN_CENTER = "EDIT_MAIN_CENTER";
export const EDIT_MAIN_PRESCRIBER = "EDIT_MAIN_PRESCRIBER";
export const SEARCH_MED_PHARM = "SEARCH_MED_PHARM";
export const PATIENT_ALLERGY_MATCH = "PATIENT_ALLERGY_MATCH";
export const NOTE_SLASH_TASK_MODAL = "NOTE_SLASH_TASK_MODAL";
export const PATIENT_INTERACTION_MATCH = "PATIENT_INTERACTION_MATCH";
export const PATIENT_INSURANCE = "PATIENT_INSURANCE";
export const PATIENT_INSURANCE_ADDRESS = "PATIENT_INSURANCE_ADDRESS";
export const INSURANCE_HISTORY_MODAL = "INSURANCE_HISTORY_MODAL";
export const PRIOR_AUTHS_MODAL = "PRIOR_AUTHS_MODAL";
export const CONFIRMATION = "CONFIRMATION";
export const MESSAGE = "MESSAGE";
export const SURVEY_MODAL = "SURVEY_MODAL";
export const ADD_REFERRALS_MODAL = "ADD_REFERRALS_MODAL";
export const DOCTOR_SEARCH = "DOCTOR_SEARCH";
export const NEW_GROUP = "NEW_GROUP";
export const PRIMARY_LABEL_DATE_MODAL = "PRIMARY_LABEL_DATE_MODAL";
export const LAB_ORDER_MODAL = "LAB_ORDER_MODAL";
export const ADD_DOCUMENT_MODAL = "ADD_DOCUMENT_MODAL";
export const MAIN_PRESCRIBER_HISTORY = "MAIN_PRESCRIBER_HISTORY";
export const SEND_PRESCRIPTION_MODAL = "SEND_PRESCRIPTION_MODAL";
export const VISIT_CENTER_CONFLICT = "VISIT_CENTER_CONFLICT";
export const CANCEL_LAB_ORDER = "CANCEL_LAB_ORDER";
export const CANCEL_SUBMITTED_LAB_MODAL = "CANCEL_SUBMITTED_LAB_MODAL";
export const RAPID_RESULTS_ENTRY_MODAL = "RAPID_RESULTS_ENTRY_MODAL";
export const PRINT_PRESCRIPTION_MODAL = "PRINT_PRESCRIPTION_MODAL";
export const HISTORY_ADDRESS_MODAL = "HISTORY_ADDRESS_MODAL";
export const NOTE_SLASH_TASK_DELETE_REASON_MODAL = "NOTE_SLASH_TASK_DELETE_REASON_MODAL";
export const DOCUMENT_DELETE_REASON_MODAL = "DOCUMENT_DELETE_REASON_MODAL";
export const ERROR_DETAILS = "ERROR_DETAILS";
export const CLAIM_ERROR_DETAILS = "CLAIM_ERROR_DETAILS";
export const CONFIRM_REVERSE_DISCHARGE = "CONFIRM_REVERSE_DISCHARGE";
export const CHANGE_VISIT_TYPE_MODAL = "CHANGE_VISIT_TYPE_MODAL";
export const TELEMED_DATA_MODAL = "TELEMED_DATA_MODAL";
export const CANCEL_VISIT_MODAL = "CANCEL_VISIT_MODAL";
export const EXPORT_CLIENT_EVENTS_MODAL = "EXPORT_CLIENT_EVENTS_MODAL";
export const AOE = "AOE";
export const INTAKE_MODAL = "INTAKE_MODAL";
export const OTHER_MODAL = "OTHER_MODAL";
export const OUTSIDE_PROVIDER_HISTORY_MODAL = "OUTSIDE_PROVIDER_HISTORY_MODAL";
export const RENEW_LICENSE_MODAL = "RENEW_LICENSE_MODAL";
export const EDIT_PATIENT_LEVELS_MODAL = "EDIT_PATIENT_LEVELS_MODAL";
