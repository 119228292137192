import map from "lodash/map";
import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function centersReducer(state = initialState.centers, action) {
  switch (action.type) {
    case types.LOAD_CENTERS_SUCCESS:
      return Object.assign({}, state, {
        items: action.centers
      });
    case types.CREATE_CENTER_SUCCESS: {
      const center = Object.assign({}, action.center, {
        centerId: action.centerId
      });
      return Object.assign({}, state, {
        items: [center, ...state.items]
      });
    }
    case types.LOAD_CENTER_DETAILS_SUCCESS:
      return Object.assign({}, state, {
        centerDetails: action.details
      });
    case types.UPDATE_CENTER_DETAILS_SUCCESS: {
      const updatedCenters = map(state.items, item => {
        if (item.centerId === action.centerId) {
          const newItem = Object.assign({}, item, action.center);
          return newItem;
        }
        return item;
      });
      return Object.assign({}, state, { items: updatedCenters });
    }
    case types.UPDATE_CENTER_SORT:
      return Object.assign({}, state, {
        items: action.sortedCenters
      });
    case types.CLEAR_CENTER_ADDRESS_HISTORY: {
      return { ...state, centerAddressHistory: [] };
    }
    case types.FETCH_CENTER_ADDRESS_HISTORY_DONE: {
      return { ...state, centerAddressHistory: action.centerAddressHistory };
    }
    case types.CLEAR_LOCATION_AND_DIRECTOR_HISTORY: {
      return { ...state, locationAndDirectorHistory: [] };
    }
    case types.FETCH_LOCATION_AND_DIRECTOR_HISTORY_DONE: {
      return { ...state, locationAndDirectorHistory: action.locationAndDirectorHistory };
    }
    case types.SET_FORCE_UPDATE_CENTER_DETAILS:
      return { ...state, forceUpdateCenterDetails: action.value };
    default:
      return state;
  }
}
