import initialState from "./initialState";

import * as types from "../actions/actionTypes";

export default function modalReducer(state = initialState.modal, action) {
  switch (action.type) {
    case types.SHOW_MODAL: {
      const {
        modal: { type, props }
      } = action;
      return {
        ...state,
        type,
        props
      };
    }
    case types.HIDE_MODAL:
      return { type: null, props: null };

    default:
      return state;
  }
}
