import React, { Component } from "react";
import ReactDOMServer from "react-dom/server";
import { withRouter } from "react-router-dom";
import { Grid, Menu, Button, Icon } from "semantic-ui-react";
import moment from "moment";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import emrComponent from "../../common/emrComponent";
import * as modalActions from "../../../actions/modalActions";
import * as visitActions from "../../../actions/visitActions";
import * as chartsActions from "../../../actions/chartsActions";
import * as providerActions from "../../../actions/providerActions";
import * as badgeActions from "../../../actions/badgeActions";
import * as lookupActions from "../../../actions/lookupActions";
import * as patientHistoryActions from "../../../actions/patientHistoryActions";
import * as userPreferenceActions from "../../../actions/userPreferenceActions";
import * as labActions from "../../../actions/labActions";
import { loadPrescriptionsSentByVisit } from "../../../actions/prescriptionActions";
import {
  extendedApiSlice,
  reviewedLabResultsVisitsSelectors,
  reviewedLabResultsHistorySelectors
} from "../labs/labsSlice";
import { datetimeFormatRemoveZeroInHour } from "../../../constants/miscellaneous";
import { roleGroups } from "../../../constants/securityRoles";
import { checkRoles, isEmpty, getLookupText } from "../../../helpers";
import VisitSummary from "./VisitSummary";
// import VisitSummaryPrint from "./VisitSummaryPrint";
import "./VisitsPage.css";
import "./VisitSummaryView.css";
import VisitSummaryPrintLoading from "./VisitSummaryPrintLoading";
import { PatientLevel } from "../../../ui";

export class VisitSummaryView extends Component {
  state = {
    patientHistory: {
      medicalHistory: [],
      interventionHistory: [],
      medicationHistory: [],
      allergyHistory: [],
      familyHistory: []
    },
    isloadDataToPrint: false
  };

  unsubscribe = null;

  componentDidMount() {
    this._isMounted = true;
    const {
      visitId,
      patientId,
      actions: { loadRelationships, loadReactions, loadCurrentUserId, loadInterventionConditions }
    } = this.props;

    this.loadDataVisitSummary(visitId);
    loadRelationships();
    loadCurrentUserId();
    loadReactions();
    loadInterventionConditions();
    const { unsubscribe } = this.props.actions.getReviewedLabResultsHistory({ patientId, visitId });
    this.unsubscribe = unsubscribe;
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextState.isloadDataToPrint) return false;
    return true;
  }
  componentDidUpdate(prevProps) {
    const { visitId, patientId } = this.props;
    if (visitId && visitId !== prevProps.visitId) {
      Promise.all([
        this.props.actions.clearSignedChartDetails(),
        this.props.actions.clearCosignedChartDetails(),
        // this.props.actions.clearCounselingVisitDetail(),
        this.props.actions.clearVisitLocations("SUMMARY")
      ]).then(() => {
        this.loadDataVisitSummary(visitId);
        if (typeof this.unsubscribe === "function") this.unsubscribe();
        const { unsubscribe } = this.props.actions.getReviewedLabResultsHistory({ patientId, visitId });
        this.unsubscribe = unsubscribe;
      });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.props.actions.clearSignedChartDetails();
    this.props.actions.clearCosignedChartDetails();
    // this.props.actions.clearCounselingVisitDetail();
    this.props.clearListVisitSummaryToPrint();
    if (typeof this.unsubscribe === "function") this.unsubscribe();
  }

  _isMounted = false;

  loadDataVisitSummary = (visitId) => {
    const {
      actions: {
        loadVisitSummary,
        loadVisitAmendment,
        loadSignedChartDetails,
        loadCoSignedChartDetails,
        getVisitLocations,
        loadPrescriptionsSentByVisit: fetchPrescriptionsSentByVisit,
        loadVisitSignPermissionDetails,
        loadLabOrdersAndResultsPerVisit,
        loadSignHistory
      }
    } = this.props;

    return Promise.all([
      loadVisitSummary(visitId),
      loadVisitAmendment(visitId),
      loadSignedChartDetails(visitId),
      loadCoSignedChartDetails(visitId),
      getVisitLocations(visitId, "SUMMARY"),
      fetchPrescriptionsSentByVisit(visitId),
      loadVisitSignPermissionDetails(visitId),
      this.loadPatientHistory(visitId),
      loadLabOrdersAndResultsPerVisit(visitId),
      loadSignHistory(visitId)
    ]);
  };

  loadPatientHistory = (visitIdSelected) => {
    const { patientId } = this.props;

    const visitId = this.props.visit ? this.props.visit.visitId : null;
    const visitStatus = this.props.visit ? this.props.visit.visitStatus : null;

    if (visitIdSelected === visitId && visitStatus && visitStatus.visitStatus !== "visitcomplete") {
      return this.props.actions.loadPatientHist(patientId).then(() => {
        const { currentPatientHistory } = this.props;
        const patientHistory = {
          allergyHistory: currentPatientHistory.allergyHistory,
          medicalHistory: currentPatientHistory.medicalHistory,
          interventionHistory: currentPatientHistory.interventionHistory,
          medicationHistory: currentPatientHistory.medicationHistory,
          familyHistory: currentPatientHistory.familyHistory
        };
        if (this._isMounted) this.setState({ patientHistory });
        return Promise.resolve({ patientHistory });
      });
    }
    return this.props.actions.loadVisitPatientHist(patientId, visitIdSelected).then(() => {
      const patientHistory = {
        allergyHistory: this.props.allergyHistory,
        medicalHistory: this.props.medicalHistory,
        interventionHistory: this.props.interventionHistory,
        medicationHistory: this.props.medicationHistory,
        familyHistory: this.props.familyHistory
      };
      if (this._isMounted) this.setState({ patientHistory });
      return Promise.resolve({ patientHistory });
    });
  };

  handleCoSign = (visitId, patientId, providerId, stateCode) => () => {
    this.showConfirmCosign(visitId, patientId, providerId, stateCode);
  };

  showConfirmCosign(visitId, patientId, providerId, stateCode) {
    this.props.actions.showModal({
      type: "CONFIRMATION",
      props: {
        open: true,
        icon: "exclamation-triangle",
        iconColor: "warning",
        title: "Confirm Cosign",
        buttonColor: "red",
        description: "Are you sure you want to cosign this visit?",
        onConfirm: () => {
          // Call API Co sign
          this.props.actions.coSignVisit(patientId, visitId, providerId, stateCode).then(() => {
            this.props.actions.loadCoSignedChartDetails(visitId);
          });
        }
      }
    });
  }

  handleSign = (patientId, signInfo) => () => {
    this.showConfirmSign(patientId, signInfo);
  };

  showConfirmSign(patientId, signInfo) {
    this.props.actions.showModal({
      type: "CONFIRMATION",
      props: {
        open: true,
        icon: "exclamation-triangle",
        iconColor: "warning",
        title: "Confirm Sign",
        buttonColor: "red",
        description: "Are you sure you want to sign this visit?",
        onConfirm: () => {
          // Call API Co sign
          this.props.actions.signChart(patientId, signInfo).then(() => {
            this.props.actions.loadSignedChartDetails(signInfo.visitId);
            // this.props.actions.loadVisitsGroupBadge();
            this.props.actions.loadSignHistory(signInfo.visitId);
          });
        }
      }
    });
  }

  isShowSelected = (visitId) => {
    const { listVisitSummaryToPrint } = this.props;
    const visit = this.props.visitId;

    if (listVisitSummaryToPrint && listVisitSummaryToPrint.length > 1) {
      return !!listVisitSummaryToPrint.includes(visitId);
    }
    return visitId === visit;
  };

  handlePrint = () => {
    const printWindow = window.open();
    const loadingBody = ReactDOMServer.renderToString(<VisitSummaryPrintLoading />);
    const head = document.head.innerHTML;
    printWindow.document.write(`${head}${loadingBody}`);
    printWindow.addEventListener("load", () => {
      printWindow.print();
    });
    const bodyPrint = this.printButtonRef.current.innerHTML;
    printWindow.document.body.getElementsByClassName("zontainer")[0].remove();
    printWindow.document.write(bodyPrint);
    printWindow.document.close();
  };

  /* handlerPrintSummary = () => {
    const {
      visitId,
      authRoles,
      patientId,
      listVisitSummaryToPrint,
      reactionTypes,
      relationships,
      visitType,
      visitTypes,
      centerOptions
    } = this.props;

    const printWindow = window.open();
    const loadingBody = ReactDOMServer.renderToString(<VisitSummaryPrintLoading />);
    const head = document.head.innerHTML;

    printWindow.document.write(`${head}${loadingBody}`);

    printWindow.addEventListener("load", () => {
      printWindow.print();
    });

    this.setState({ isloadDataToPrint: true });
    this.loadDataToPrint(listVisitSummaryToPrint).then((resp) => {
      // Reload back visit
      Promise.all([this.loadDataVisitSummary(visitId), this.props.actions.getVisitLocations(visitId, "SUMMARY")]).then(
        (results) => {
          this.setState({ isloadDataToPrint: false });
          const bodyPrint = ReactDOMServer.renderToString(
            <VisitSummaryPrint
              dataVisitSummary={resp.map((i) => ({ ...i, visitType }))} // This needs a fix .. visit type should not be fixed .. should be relative to each visit
              authRoles={authRoles}
              patientId={patientId}
              reactionTypes={reactionTypes}
              relationships={relationships}
              visitTypes={visitTypes}
              visitSummaryLocations={results[1].visitLocations}
              centerOptions={centerOptions}
            />
          );
          printWindow.document.body.getElementsByClassName("zontainer")[0].remove();
          printWindow.document.write(bodyPrint);
          printWindow.document.close();
        }
      );
    });
  }; */

  loadDataToPrint = (listVisitsId) => {
    const listVisitToLoad = [];
    listVisitsId.forEach((visitId) => {
      listVisitToLoad.push(
        this.loadDataVisitSummary(visitId).then(
          (resp) =>
            new Promise((resolve) => {
              const dataConvert = Object.assign({}, ...resp);
              delete dataConvert.type;
              resolve({ visitId, data: dataConvert });
            })
        )
      );
    });

    return Promise.all(listVisitToLoad);
  };

  handleBack = () => {
    const {
      patientId,
      actions: { clearContextVisit }
    } = this.props;
    clearContextVisit(patientId);
  };
  printButtonRef = null;
  render() {
    this.printButtonRef = React.createRef();
    const {
      patientVisits = [],
      visitSummary,
      updateVisitSummaryMenu,
      visitId,
      visitType,
      visitTypes,
      handleAddAmendment,
      handleInput,
      visitAmendment,
      authRoles,
      isLoading,
      amendmentContent,
      visitSignedChart,
      cosignDetail,
      prescriptionsSent,
      patientId,
      listVisitSummaryToPrint,
      reactionTypes,
      relationships,
      currentUserId,
      visitSignPermissionDetail,
      backToTableView,
      labOrdersAndResultsPerVisit,
      signHistory,
      visitStatuses,
      visitSummaryLocations,
      centerOptions,
      interventionConditions,
      reviewedLabResultsVisits,
      reviewedLabResultsHistory
    } = this.props;
    if (!visitId || isEmpty(patientVisits)) return null;
    const { patientHistory } = this.state;
    const visit = patientVisits.find((i) => i.visitId === visitId);
    const visitStatus = visit ? visit.visitStatus : null;
    const visitPrescribingProvider = visit ? visit.prescribingProvider : null;
    const isSigned = !visitSignedChart.loading && Object.keys(visitSignedChart).length > 0;
    const isCoSigned = !cosignDetail.loading && Object.keys(cosignDetail).length > 0;
    const showCoSign =
      Object.keys(cosignDetail).length === 0 &&
      checkRoles(roleGroups.supProvidersLdac1, authRoles) &&
      visitStatus &&
      visitStatus.visitStatus &&
      visitStatus.visitStatus === "visitcomplete" &&
      currentUserId === visitSignPermissionDetail.superProviderId;
    const showSign =
      Object.keys(visitSignedChart).length === 0 &&
      checkRoles(roleGroups.chartsToSign, authRoles) &&
      visitStatus &&
      visitStatus.visitStatus &&
      visitStatus.visitStatus === "visitcomplete" &&
      visitPrescribingProvider &&
      currentUserId === visitPrescribingProvider.providerId;
    const visitTypeObject = visitTypes.find((v) => v.value === visitType);
    return (
      <div className="visit-summary-content">
        <Grid doubling columns={2} className="summary-container-grid">
          <Grid.Row>
            <Grid.Column width={3}>
              <ul className="visit-summary-menu">
                <li>
                  Visit History{" "}
                  <Button className="transparent-button" id="btn-back" size="small" onClick={backToTableView}>
                    <Icon name="angle left" />
                    Back
                  </Button>
                </li>

                {patientVisits.map((_visit) => (
                  <li
                    key={_visit.visitId + _visit.visitDateTime}
                    className={this.isShowSelected(_visit.visitId) ? "selected" : ""}
                  >
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a
                      role="button"
                      tabIndex={0}
                      onClick={(e) => {
                        updateVisitSummaryMenu(_visit.visitId, e);
                      }}
                      onKeyDown={() => {}}
                    >
                      <div className="visit-item-container">
                        <p>{moment(_visit.visitDateTime).format(datetimeFormatRemoveZeroInHour)}</p>
                        <p>
                          {getLookupText(_visit.visitType, visitTypes)}{" "}
                          {_visit.visitStatus && _visit.visitStatus.visitStatus === "visitcanceled" && (
                            <span className="spn-visit-cancelled">
                              {getLookupText(_visit.visitStatus.visitStatus, visitStatuses)}
                            </span>
                          )}
                          {_visit.selectedTemplateName && (
                            <span>
                              <br />
                              {`(${_visit.selectedTemplateName})`}
                            </span>
                          )}
                        </p>
                        <p className="provider-name">{`${_visit.prescribingProvider.firstName} ${_visit.prescribingProvider.lastName}`}</p>
                      </div>
                      <PatientLevel
                        level={_visit.level ? _visit.level.levelName : "orange"}
                        className="level-icon"
                        size="xs"
                      />
                      {_visit.bhLevel && (
                        <PatientLevel
                          level={_visit.bhLevel.levelName}
                          className="level-icon"
                          size="xs"
                          bh
                          style={{ top: "2.5rem" }}
                        />
                      )}
                    </a>
                  </li>
                ))}
              </ul>
            </Grid.Column>
            <Grid.Column width={13}>
              <Menu>
                <Menu.Item name="header">&nbsp;</Menu.Item>
              </Menu>
              <div ref={this.printButtonRef}>
                {Object.keys(visitSummary).length !== 0 && (
                  <VisitSummary
                    visitSummary={visitSummary}
                    visitAmendment={visitAmendment}
                    handleAddAmendment={handleAddAmendment}
                    handleInput={handleInput}
                    authRoles={authRoles}
                    isLoading={isLoading}
                    amendmentContent={amendmentContent}
                    isSigned={isSigned}
                    isCoSigned={isCoSigned}
                    signDetail={visitSignedChart}
                    cosignDetail={cosignDetail}
                    prescriptionsSent={prescriptionsSent}
                    handleCoSign={this.handleCoSign}
                    visitId={visitId}
                    visitTypeObject={visitTypeObject}
                    patientId={patientId}
                    showCoSign={showCoSign}
                    handlerPrintSummary={this.handlePrint}
                    listVisitSelected={listVisitSummaryToPrint}
                    patientHistory={patientHistory}
                    reactionTypes={reactionTypes}
                    relationships={relationships}
                    showSign={showSign}
                    handleSign={this.handleSign}
                    currentUserId={currentUserId}
                    labOrdersAndResultsPerVisit={labOrdersAndResultsPerVisit}
                    signHistory={signHistory}
                    visitSummaryLocations={visitSummaryLocations}
                    centerOptions={centerOptions}
                    interventionConditions={interventionConditions}
                    reviewedLabResultsVisits={reviewedLabResultsVisits}
                    reviewedLabResultsHistory={reviewedLabResultsHistory}
                  />
                )}
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const patientId = state.patient.currentPatient.patientId;
  const { visitId } = ownProps;
  const patientVisits = state.visits.patientVisits.visits || {};
  const currentPatientHistory = {
    medicalHistory: state.patientHistory.medicalHistory || [],
    interventionHistory: state.patientHistory.interventionHistory || [],
    allergyHistory: state.patientHistory.allergyHistory || [],
    medicationHistory: state.patientHistory.medicationHistory || [],
    familyHistory: state.patientHistory.familyHistory || []
  };

  return {
    patientVisits,
    visitSummary: state.visits.visitSummary,
    visitAmendment: state.visits.visitAmendment,
    visitSignedChart: state.charts.latestVisitSignedChart,
    cosignDetail: state.charts.cosignDetail,
    providers: state.providers.allProviders,
    prescriptionsSent: state.prescriptions.prescriptionsSentByVisit,
    patientId,
    medicalHistory: state.visits.medicalHistory || [],
    interventionHistory: state.visits.interventionHistory || [],
    allergyHistory: state.visits.allergyHistory || [],
    medicationHistory: state.visits.medicationHistory || [],
    familyHistory: state.visits.familyHistory || [],
    reactionTypes: state.lookups.reactionTypes,
    relationships: state.lookups.relationships,
    currentPatientHistory,
    currentUserId: state.userPreference.currentUserId,
    visitStatuses: state.lookups.visitStatuses,
    visitSignPermissionDetail: state.visits.visitSignPermissionDetail || {},
    labOrdersAndResultsPerVisit: state.labs.labOrdersAndResultsPerVisit || {},
    signHistory: state.visits.visitSummary.signHistory || [],
    visitTypes: state.lookups.visitTypes,
    interventionConditions: state.lookups.interventionConditions,
    visitSummaryLocations: state.visits.visitSummaryLocations,
    centerOptions: state.lookups.centers.map((i) => ({ text: i.text, value: i.value })),
    reviewedLabResultsVisits: reviewedLabResultsVisitsSelectors(state, { patientId }).selectAll() || [],
    reviewedLabResultsHistory: reviewedLabResultsHistorySelectors(state, { patientId, visitId }).selectAll() || []
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    actions: {
      ...bindActionCreators(
        {
          ...modalActions,
          ...visitActions,
          ...chartsActions,
          ...providerActions,
          ...badgeActions,
          ...patientHistoryActions,
          ...lookupActions,
          ...userPreferenceActions,
          ...labActions,
          loadPrescriptionsSentByVisit
        },
        dispatch
      ),
      getReviewedLabResultsHistory: ({ patientId }) =>
        dispatch(
          extendedApiSlice.endpoints.getReviewedLabResultsHistory.initiate({ patientId, visitId: ownProps.visitId })
        )
    }
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(emrComponent(VisitSummaryView)));
