import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function patientReducer(state = initialState.patient, action) {
  switch (action.type) {
    case types.RETRIEVE_PATIENT_ID_SUCCESS:
      return Object.assign({}, state, { patientId: action.response });
    case types.CREATE_PATIENT_SUCCESS: {
      const newPatient = action.patient;
      newPatient.patientId = action.response.PatientId;
      return Object.assign({}, state, {
        newPatient
      });
    }
    case types.LOAD_PATIENT_SUMMARY_SUCCESS:
      return Object.assign({}, state, {
        currentPatient: action.patientSummary
      });
    case types.LOAD_PATIENT_EXTERNAL_REFS_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        patientExternalRefs: action.patientExternalRefs
      });
    case types.LOAD_ADVANCE_DIRECTIVES_DONE:
      return { ...state, advanceDirectives: action.advanceDirectives };
    case types.UPDATE_CURRENT_PATIENT:
      return Object.assign({}, state, {
        currentPatient: {
          ...state.currentPatient,
          ...action.patient
        }
      });
    case types.UPDATE_CURRENT_PATIENT_ADDRESS:
      return Object.assign({}, state, {
        currentPatient: {
          ...state.currentPatient,
          address: action.address
        }
      });
    case types.UPDATE_CURRENT_PATIENT_INSURANCE:
      return Object.assign({}, state, {
        currentPatient: {
          ...state.currentPatient,
          primaryInsurance: action.insurance
        }
      });

    case types.CREATE_PHOTO_SUCCESS:
      return Object.assign({}, state, {
        currentPatient: {
          ...state.currentPatient,
          image64: action.image.image64
        }
      });
    case types.SET_PATIENT_LEVEL:
      if (action.bh) {
        return {
          ...state,
          currentPatient: {
            ...state.currentPatient,
            bhLevel: { ...state.currentPatient.bhLevel, levelName: action.level }
          }
        };
      } else {
        return {
          ...state,
          currentPatient: {
            ...state.currentPatient,
            level: { ...state.currentPatient.level, levelName: action.level }
          }
        };
      }
    case types.UPDATE_PATIENT_LEVEL:
      return Object.assign({}, state, {
        currentPatient: {
          ...state.currentPatient,
          level: { levelName: action.level }
        }
      });
    case types.UPDATE_CURRENT_PATIENT_TREATMENT_PLAN_STATUS:
      return {
        ...state,
        currentPatient: {
          ...state.currentPatient,
          treatmentPlanStatus: action.treatmentPlanStatus
        }
      };
    case types.GET_PATIENT_NOTE_SLASH_TASK_COUNT_DONE:
      return { ...state, numberOfOpenNoteSlashTasks: action.response };
    case types.CLEAR_PATIENT:
      return {
        currentPatient: {},
        newPatient: {},
        advanceDirectives: null,
        patientExternalRefs: [],
        numberOfOpenNoteSlashTasks: { openTasksCount: 0, urgentTasksCount: 0 },
        customFields: {}
      };
    case types.FETCH_CUSTOM_FIELDS_DONE: {
      const fields = action.data.reduce((acc, _curr) => {
        const curr = { ..._curr };
        curr.id = curr.id || curr.Id;
        curr.customFieldId = curr.customFieldId || curr.CustomFieldId;
        curr.customFieldAnswer = curr.customFieldAnswer || curr.CustomFieldValue || "";
        delete curr.Id;
        delete curr.CustomFieldId;
        delete curr.CustomFieldValue;
        acc[curr.id] = curr;
        return acc;
      }, {});
      return { ...state, customFields: { ...state.customFields, ...fields } };
    }
    default:
      return state;
  }
}
