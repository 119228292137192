import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";
import { connect } from "react-redux";
import IdleTimer from "react-idle-timer";
import BlankLayout from "./BlankLayout";
import DefaultLayout from "./DefaultLayout";
import { logout } from "../adalConfig";
import webSocket from "../ws/index";
import "./App.css";

class App extends Component {
  constructor(props) {
    super(props);
    this.idleTimer = null;
  }

  state = {
    sessionTimeout: -1
  };

  componentDidMount() {
    localStorage.setItem("ARES_LAST_ACTIVE_TIME", +new Date());
    this.updateWindowWidth();
    window.addEventListener("resize", this.updateWindowWidth);
    window.addEventListener("beforeunload", this.handleUnload);
    webSocket.connectSocket();
  }

  onAction = () => {
    this.setState({ sessionTimeout: -1 });
    localStorage.setItem("ARES_LAST_ACTIVE_TIME", this.idleTimer.getLastActiveTime());
  };

  onIdle = () => {
    if ((+new Date() - +localStorage.getItem("ARES_LAST_ACTIVE_TIME")) / (1000 * 60) >= this.props.sessionTimeout) {
      logout();
    } else {
      this.setState(
        {
          sessionTimeout:
            1000 * 60 * this.props.sessionTimeout - (+new Date() - +localStorage.getItem("ARES_LAST_ACTIVE_TIME"))
        },
        () => {
          this.idleTimer.reset();
        }
      );
    }
  };

  handleUnload = () => {
    webSocket.closeSocket();
    return "cleared";
  };

  updateWindowWidth = () => {
    if (window.innerWidth < 767) {
      this.setState({ isMobile: true });
    } else {
      this.setState({ isMobile: false });
    }
  };

  render() {
    const { sessionTimeout } = this.props;
    const { sessionTimeout: stateSessionTimeOut } = this.state;
    return (
      <React.Fragment>
        <IdleTimer
          ref={(ref) => {
            this.idleTimer = ref;
          }}
          element={document}
          // onActive={this.onActive}
          onAction={this.onAction}
          onIdle={this.onIdle}
          debounce={250}
          timeout={stateSessionTimeOut > -1 ? stateSessionTimeOut : 1000 * 60 * sessionTimeout}
        />
        <Switch>
          <Route path={`/b`} component={BlankLayout} />
          <Route path={`/s`} render={(props) => <DefaultLayout {...props} showSearchBar={false} />} />
          <Route component={DefaultLayout} />
        </Switch>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    sessionTimeout: state.userPreference.SessionTimeout ? state.userPreference.SessionTimeout : 15
  };
}

export default connect(mapStateToProps, null)(App);
