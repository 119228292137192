import { isEmpty } from "lodash";
import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Accordion, Table } from "semantic-ui-react";
import * as activityLogActions from "../../actions/activityLogActions";
import { dateWithTimeFormat } from "../../constants/miscellaneous";

export class ActivityLog extends Component {
  state = {
    log: [],
    activeAccordionIndex: ""
  };

  componentDidMount() {
    this.props.actions.fetchActivityLog(this.props.entityId).then(log => {
      this.setState({ log });
    });
  }

  handleAccordionClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeAccordionIndex } = this.state;
    const newIndex = activeAccordionIndex === index ? "" : index;
    this.setState({ activeAccordionIndex: newIndex });
  };

  render() {
    const { log, activeAccordionIndex } = this.state;

    return log.length === 0 ? (
      <div>No history found for this entity</div>
    ) : (
      <Table selectable sortable style={{ wordBreak: "break-all" }} compact className="compact-table no-padding">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={2}>Entity Type</Table.HeaderCell>
            <Table.HeaderCell width={2}>Changed At</Table.HeaderCell>
            <Table.HeaderCell width={2}>Changed By</Table.HeaderCell>
            <Table.HeaderCell width={2}>Action</Table.HeaderCell>
            <Table.HeaderCell width={8}>Changes</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {log.map(item =>
            isEmpty(JSON.parse(item.valuesChanged)) ? null : (
              <Table.Row key={item.platformId} style={{ borderBottom: "1px solid rgba(34,36,38,.15)" }}>
                <Table.Cell width={2}>{item.entityType}</Table.Cell>
                <Table.Cell width={2}>{moment(item.createdDate).format(dateWithTimeFormat)}</Table.Cell>
                <Table.Cell width={2}>{`${item.changedByFirstName} ${item.changedByLastName}`}</Table.Cell>
                <Table.Cell width={2}>{item.actionDescription}</Table.Cell>
                <Table.Cell width={8}>
                  <Accordion styled style={{ width: "100%" }}>
                    <Accordion.Title
                      active={activeAccordionIndex === item.platformId}
                      index={item.platformId}
                      onClick={this.handleAccordionClick}
                    >
                      {activeAccordionIndex === item.platformId ? `Collapse changes` : `Expand changes`}
                    </Accordion.Title>
                    <Accordion.Content active={activeAccordionIndex === item.platformId}>
                      <Table>
                        <Table.Header>
                          <Table.Row>
                            <Table.HeaderCell width={4}>Property</Table.HeaderCell>
                            <Table.HeaderCell width={6}>Old</Table.HeaderCell>
                            <Table.HeaderCell width={6}>New</Table.HeaderCell>
                          </Table.Row>
                        </Table.Header>
                        <Table.Body>
                          {JSON.parse(item.valuesChanged).map(property => (
                            <Table.Row key={property.PropertyName}>
                              <Table.Cell width={4}>{property.PropertyName}</Table.Cell>
                              <Table.Cell width={6}>
                                {property.ValueBefore ? property.ValueBefore.toString() : ``}
                              </Table.Cell>
                              <Table.Cell width={6}>
                                {property.ValueAfter ? property.ValueAfter.toString() : ``}
                              </Table.Cell>
                            </Table.Row>
                          ))}
                        </Table.Body>
                      </Table>
                    </Accordion.Content>
                  </Accordion>
                </Table.Cell>
              </Table.Row>
            )
          )}
        </Table.Body>
      </Table>
    );
  }
}

function mapStateToProps(state) {
  return {
    defaultAssigneeCenterId: state.demographics.homeCenterId,
    taskRoles: state.lookups.taskRoles
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...activityLogActions }, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ActivityLog);
