import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as patientActions from "../../../actions/patientActions";
import { Button, Form, Modal, Row, Col } from "../../../ui";
import { hideModal } from "../../../helpers";

export class EditPatientLevelsModal extends Component {
  handleLevelUpdate = (e) => {
    this.updatePatientLevel(e.target.value);
  };

  handleBhLevelUpdate = (e) => {
    this.updatePatientLevel(e.target.value, true);
  };

  updatePatientLevel = (value, bh = false) => {
    const { patientId, contextVisitId } = this.props;
    return this.props.actions.setPatientLevel(patientId, contextVisitId, value, bh);
  };

  handleClose = () => {
    hideModal();
  };

  handleFocus = (e) => {
    e.target.setAttribute("autocomplete", "nope");
  };

  render() {
    const { level, bhLevel, levelOptions, bhLevelOptions } = this.props;
    return (
      <Modal size="md" backdrop="static" keyboard={false} onHide={this.handleClose} show>
        <Modal.Header closeButton>
          <Modal.Title>{`Patient Level`}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="">
          <Form>
            <Row>
              <Form.Group as={Col}>
                <Form.Label>Level</Form.Label>
                <Form.Select
                  label="Level"
                  name="level"
                  placeholder="Select..."
                  options={levelOptions}
                  value={level.levelName}
                  onChange={this.handleLevelUpdate}
                />
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label>BH Level</Form.Label>
                <Form.Select
                  label="Level"
                  name="level"
                  placeholder="Select..."
                  options={bhLevelOptions}
                  value={bhLevel.levelName}
                  onChange={this.handleBhLevelUpdate}
                />
              </Form.Group>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={this.handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    patientId: state.patient.currentPatient.patientId,
    contextVisitId: state.visits.contextVisit?.visitId || null,
    level: state.patient.currentPatient.level,
    bhLevel: state.patient.currentPatient.bhLevel,
    levelOptions: state.lookups.levels
      .filter((l) => l.isMedicalLevel)
      .map((level) => ({
        value: level.text,
        text: level.text
      })),
    bhLevelOptions: state.lookups.levels
      .filter((l) => l.isBHLevel)
      .map((level) => ({
        value: level.text,
        text: level.text
      }))
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...patientActions
      },
      dispatch
    )
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EditPatientLevelsModal);
