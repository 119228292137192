import React, { Component } from "react";
import shortid from "shortid";
import { Dropdown, Button, Input } from "semantic-ui-react";

class ElucidationsEditor extends Component {
  state = {
    key: "",
    caption: ""
  };

  onChange = (e, data) => {
    this.setState({ [data.name]: data.value });
  };

  add = () => {
    const { question, update } = this.props;
    const { key, caption } = this.state;
    const elucidations = [...question.elucidations];
    elucidations.push({ key: key === "EMPTY_KEY" ? "" : key, caption });
    update("elucidations", elucidations);
  };

  remove = key => {
    const { question, update } = this.props;
    const elucidations = [...question.elucidations];
    const index = elucidations.findIndex(e => e.key === key);
    elucidations.splice(index, 1);
    update("elucidations", elucidations);
  };

  render() {
    const { question } = this.props;
    const { options } = question;
    let elucidations = question.elucidations || [];
    elucidations = elucidations.map(e => {
      if (e.key === "") return { ...e, key: "EMPTY_KEY" };
      return { ...e };
    });
    let keyOptions = options ? options.map(o => ({ text: o, value: o })) : [];
    keyOptions.unshift({ text: "Empty Key", value: "EMPTY_KEY" });
    keyOptions = keyOptions.filter(o => elucidations.findIndex(e => e.key === o.value) < 0);
    return (
      <div className="ui grid">
        <div className="row">
          <div className="sixteen wide column">
            <ul>
              {elucidations.map(e => (
                <React.Fragment key={shortid.generate()}>
                  <li key={shortid.generate()}>
                    <span style={{ display: "inline-block", width: "90%" }}>
                      {e.key === "EMPTY_KEY" ? "[Empty Key]" : e.key}
                    </span>
                    <i
                      className="minus link icon"
                      style={{ display: "inline-block", width: "10%", margin: "0", color: "red" }}
                      role="button"
                      tabIndex={0}
                      onClick={() => this.remove(e.key)}
                    />
                  </li>
                  <li key={shortid.generate()} style={{ borderBottom: "1px solid rgba(34,36,38,.15)" }}>
                    <span style={{ display: "inline-block", width: "70%" }}>{e.caption}</span>
                  </li>
                </React.Fragment>
              ))}
            </ul>
          </div>
          <div className="sixteen wide column">
            <div className="ui grid">
              <div className="sixteen wide column " style={{ padding: "10px 30px" }}>
                <label>Key</label>
                <Dropdown
                  name="key"
                  options={keyOptions}
                  fluid
                  selection
                  value={this.state.key}
                  onChange={this.onChange}
                />
                <label>Caption</label>
                <Input fluid name="caption" type="text" value={this.state.caption} onChange={this.onChange} />
                <div className="sixteen wide column">
                  <Button type="button" color="blue" size="mini" floated="right" onClick={this.add}>
                    Add
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ElucidationsEditor;
