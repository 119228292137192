import React, { Component } from "react";
import { Grid } from "semantic-ui-react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { checkRoles } from "../../../helpers";
import { roleGroups } from "../../../constants/securityRoles";
import * as modalActions from "../../../actions/modalActions";
import * as patientHistoryActions from "../../../actions/patientHistoryActions";
import ManageMedications from "./medications/ManageMedications";
import ManageAllergies from "./allergies/ManageAllergies";
import ManageFamilyHistory from "./family/ManageFamilyHistory";
import ManageMedicalHistory from "./medicalHistory/ManageMedicalHistory";
import ManageInterventionHistory from "./interventions/ManageInterventionHistory";
import LabHistory from "./labHistory/LabHistory";

import "./PatientHistoryPage.css";

export class PatientHistoryPage extends Component {
  state = {};

  componentDidMount() {
    const { patientId } = this.props;
    if (patientId) {
      this.props.actions.loadPatientHist(patientId);
    }
  }

  componentWillUnmount() {
    this.props.actions.clearPatientHistory(this.props.patientId);
  }

  render() {
    const { medicationHistory, allergyHistory, familyHistory, authRoles } = this.props;
    const showLabHistory = checkRoles(roleGroups.labHistory, authRoles);
    return (
      <div className="content-wrapper patient-history-content">
        <Grid>
          {showLabHistory && (
            <Grid.Row>
              <Grid.Column mobile={16} tablet={16} computer={16}>
                <LabHistory />
              </Grid.Column>
            </Grid.Row>
          )}
          <Grid.Row>
            <Grid.Column computer={8} tablet={16} mobile={16}>
              <div style={{ marginBottom: "1em" }}>
                <ManageMedications medicationHistory={medicationHistory} />
              </div>
              <div style={{ marginBottom: "1em" }}>
                <ManageAllergies allergyHistory={allergyHistory} />
              </div>
              <div style={{ marginBottom: "1em" }}>
                <ManageFamilyHistory familyHistory={familyHistory} />
              </div>
            </Grid.Column>
            <Grid.Column computer={8} tablet={16} mobile={16}>
              <div style={{ marginBottom: "1em" }}>
                <ManageMedicalHistory />
              </div>
              <div style={{ marginBottom: "1em" }}>
                <ManageInterventionHistory />
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    authRoles: state.auth.user.profile.roles,
    patientId: state.patient.currentPatient.patientId,
    medicationHistory: state.patientHistory.medicationHistory || [],
    allergyHistory: state.patientHistory.allergyHistory || [],
    familyHistory: state.patientHistory.familyHistory || []
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...modalActions, ...patientHistoryActions }, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PatientHistoryPage);
