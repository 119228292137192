import React, { Component } from "react";
import { Button, Table } from "semantic-ui-react";
import moment from "moment";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as modalActions from "../../../../actions/modalActions";
import * as lookupActions from "../../../../actions/lookupActions";
import { getLookupText } from "../../../../helpers";

export class PatientInsurance extends Component {
  componentDidMount() {
    this.props.actions.loadInsuranceProviders();
    this.props.actions.loadInsuranceRelationships();
  }

  handleInsuranceModalOpen = () =>
    this.props.actions.showModal({
      type: "PATIENT_INSURANCE",
      props: {
        open: true,
        isNew: false,
        insuranceType: this.props.insuranceType
      }
    });

  handleAddressModalOpen = () =>
    this.props.actions.showModal({
      type: "PATIENT_INSURANCE_ADDRESS",
      props: {
        open: true,
        isNew: false,
        insuranceType: this.props.insuranceType
      }
    });

  handleHistoryModalOpen = () =>
    this.props.actions.showModal({
      type: "INSURANCE_HISTORY_MODAL",
      props: {
        open: true,
        insuranceType: this.props.insuranceType
      }
    });

  handleAuthsModalOpen = () =>
    this.props.actions.showModal({
      type: "PRIOR_AUTHS_MODAL",
      props: {
        open: true
      }
    });

  handleReferralsOpen = () =>
    this.props.actions.showModal({
      type: "ADD_REFERRALS_MODAL",
      props: {
        open: true
      }
    });

  render() {
    const { /* insuranceType, */ insurance, insuranceRelationships, insuranceProviders /* , authRoles */ } = this.props;
    const showEditInsurance = false; // checkRoles(roleGroups.excludeHelpDesk, authRoles);
    return (
      <div className="insurance-content">
        <div>
          <h4 id="lbl-insurer">
            {getLookupText(insurance.insurer, insuranceProviders)}
            {insurance.relationship !== "SEL"
              ? ` (${insurance.insuredFirstName} ${insurance.insuredLastName} - ${getLookupText(
                  insurance.relationship,
                  insuranceRelationships
                )})`
              : null}
          </h4>
          {showEditInsurance && (
            <Button
              id="btn-edit-primary"
              className="btn-edit transparent-button-icon"
              onClick={this.handleInsuranceModalOpen}
              icon="edit"
              size="small"
            />
          )}
          <Table>
            <Table.Body>
              <Table.Row>
                <Table.Cell>Policy #:</Table.Cell>
                <Table.Cell id="lbl-policyNumber">{insurance.policyNumber}</Table.Cell>
                <Table.Cell>Group #:</Table.Cell>
                <Table.Cell id="lbl-policyGroup">{insurance.policyGroup || "None"}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Start Date:</Table.Cell>
                <Table.Cell id="lbl-startDate">
                  {insurance.startDate ? moment(insurance.startDate, "M/D/YYYY").format("MM/DD/YYYY") : "No start date"}
                </Table.Cell>
                <Table.Cell>End Date:</Table.Cell>
                <Table.Cell id="lbl-endDate">
                  {insurance.endDate ? moment(insurance.endDate, "M/D/YYYY").format("MM/DD/YYYY") : "No end date"}
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
          {/* we are commenting this code as we may allow this functionality later insuranceType === "primaryInsurance" ? (
            <div>
              <Button
                id="btn-prior-auths"
                className="btn-prior-auths transparent-button"
                onClick={this.handleAuthsModalOpen}
              >
                Prior Auths
              </Button>
              <Button
                id="btn-add-referrals"
                className="btn-referrals transparent-button"
                onClick={this.handleReferralsOpen}
              >
                Referrals
              </Button>
            </div>
          ) : (
            ""
          ) */}
        </div>
        <div>
          {/* we are commenting this code as we may allow this functionality later showEditInsurance && (
            <Button
              id="btn-edit-address"
              className="btn-edit transparent-button-icon"
              icon="edit"
              size="small"
              onClick={this.handleAddressModalOpen}
            />
          ) */}
          <p id="lbl-address1">{insurance.address1}</p>
          <p id="lbl-address2">{insurance.address2}</p>
          <p id="lbl-city-state">{`${insurance.city}, ${insurance.state}`}</p>
          <p id="lbl-zip">{insurance.zip}</p>
          <Button
            id="btn-insurance-history"
            className="btn-history transparent-button-icon"
            onClick={this.handleHistoryModalOpen}
            icon="history"
            size="small"
          />
        </div>
      </div>
    );
  }
}

function formatInsuranceProvForDropdown(insuranceProviders) {
  return insuranceProviders.map((insuranceProvider) => ({
    value: insuranceProvider.value.toString(),
    text: insuranceProvider.text
  }));
}

function mapStateToProps(state, ownProps) {
  return {
    insurance: state.insurance[ownProps.insuranceType],
    insuranceRelationships: state.lookups.insuranceRelationships,
    insuranceProviders: formatInsuranceProvForDropdown(state.lookups.insuranceProviders),
    authRoles: state.auth.user.profile.roles
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...modalActions, ...lookupActions }, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PatientInsurance);
