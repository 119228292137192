import React, { Component } from "react";
import { Accordion, Header } from "semantic-ui-react";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as modalActions from "../../../actions/modalActions";
import * as lookupActions from "../../../actions/lookupActions";
import * as userActions from "../../../actions/userActions";
import * as prescriptionActions from "../../../actions/prescriptionActions";
import * as providerActions from "../../../actions/providerActions";
import { selectedPrescription as getSelectedPrescription } from "../../../reducers/rootReducer";
import { roleNames, roleGroups } from "../../../constants/securityRoles";
import { pendingPrescriptionsUrlQuery } from "../../../helpers";

import RxItem from "./RxItem";

export class RxList extends Component {
  componentDidMount() {
    const {
      patientId,
      actions: { loadLookup }
    } = this.props;
    if (patientId) {
      this.handleLoadPrescriptions(patientId);
    }

    loadLookup("NumberPerDoseOptions");
    loadLookup("RefillOptions");
    loadLookup("FrequencyOptions");
  }

  handleLoadPrescriptions = patientId => {
    const roles = roleGroups.providers;
    this.props.actions.loadMainPrescribers(roles);
    this.props.actions.loadPrescriptions(patientId, pendingPrescriptionsUrlQuery);
    this.props.actions.loadRecurringPrescriptions(patientId);
  };

  handleCancel = () => {};

  handleUpdate = (prescription, prevDrugKey) => {
    const {
      actions: { updatePrescription },
      patientId
    } = this.props;

    updatePrescription(
      patientId,
      {
        ...prescription
      },
      prevDrugKey
    ).then(this.handleCancel);
  };

  handleRecurringUpdate = prescription => {
    const { patientId } = this.props;
    this.props.actions.updateRecurringPrescription(patientId, prescription).then(this.handleCancel);
  };

  handleDelete = prescriptionId => {
    const {
      patientId,
      selectedPrescription,
      actions: { setRxItemForm, setSelectedPrescription }
    } = this.props;

    if (this.props.rxType === "recurringRx") {
      this.props.actions.deleteRecurringPrescription(prescriptionId, patientId).then(this.handleCancel);
    } else {
      const isPatientContext = true;
      this.props.actions.deletePrescription(prescriptionId, patientId, isPatientContext).then(this.handleCancel);
      if (selectedPrescription && selectedPrescription.prescriptionId === prescriptionId) {
        setRxItemForm({}, true);
        setSelectedPrescription(null, null);
      }
    }
  };

  render() {
    const {
      currentPrescriptions,
      recurringPrescriptions,
      processing,
      mainPrescribers,
      numberPerDoseOptions,
      refillOptions,
      frequencyOptions,
      rxType,
      disabledRxButtons,
      isReceptionist,
      handlePrintPrescriptions,
      prescriptionsToPrint,
      mainPrescriberId,
      authRoles,
      loggedInUsername
    } = this.props;
    return (
      <Accordion className={rxType === "currentRx" ? "current-rx-list" : "recurring-rx-list"}>
        {rxType === "currentRx"
          ? (currentPrescriptions || []).length
            ? currentPrescriptions.map((prescription, i) => (
                <RxItem
                  prescription={prescription}
                  handleDelete={this.handleDelete}
                  handleUpdate={this.handleUpdate}
                  handleRecurringUpdate={this.handleRecurringUpdate}
                  key={prescription.prescriptionId || i}
                  mainPrescribers={mainPrescribers}
                  numberPerDoseOptions={numberPerDoseOptions}
                  refillOptions={refillOptions}
                  frequencyOptions={frequencyOptions}
                  rxType={rxType}
                  isReceptionist={isReceptionist}
                  handlePrintPrescriptions={handlePrintPrescriptions}
                  prescriptionToPrintId={prescriptionsToPrint.find(rxId => rxId === prescription.prescriptionId)}
                  mainPrescriberId={mainPrescriberId}
                  authRoles={authRoles}
                  loggedInUsername={loggedInUsername}
                />
              ))
            : !processing && (
                <Header.Subheader id="current-rx-no-prescrip" className="current-rx-no-prescrip">
                  There are currently no prescriptions listed
                </Header.Subheader>
              )
          : (recurringPrescriptions || []).length
          ? recurringPrescriptions.map((prescription, i) => (
              <RxItem
                prescription={prescription}
                handleDelete={this.handleDelete}
                handleUpdate={this.handleUpdate}
                handleRecurringUpdate={this.handleRecurringUpdate}
                key={prescription.prescriptionId || i}
                mainPrescribers={mainPrescribers}
                numberPerDoseOptions={numberPerDoseOptions}
                refillOptions={refillOptions}
                frequencyOptions={frequencyOptions}
                rxType={rxType}
                disabledRxButtons={disabledRxButtons}
                isReceptionist={isReceptionist}
                authRoles={authRoles}
                loggedInUsername={loggedInUsername}
              />
            ))
          : !processing && (
              <Header.Subheader id="recurring-rx-no-prescrip" className="recurring-rx-no-prescrip">
                There are currently no prescriptions listed
              </Header.Subheader>
            )}
      </Accordion>
    );
  }
}

function providersFormattedDropdown(mainPrescribers = []) {
  return mainPrescribers.map(prescriber => {
    const availablePrescriptionSlots = prescriber.availablePrescriptionSlots || 0;
    return {
      value: prescriber.userId,
      text: `${prescriber.firstName} ${prescriber.lastName} (${availablePrescriptionSlots})`,
      firstname: prescriber.firstName,
      lastname: prescriber.lastName,
      availableprescriptionslots: availablePrescriptionSlots,
      username: prescriber.userName
    };
  });
}

function mapStateToProps(state) {
  const currentPrescriptions = state.prescriptions.currentPrescriptions;
  const recurringPrescriptions = state.prescriptions.recurringPrescriptions;

  const disabledRxButtons = currentPrescriptions
    .filter(currentPrescription => currentPrescription.recurringPrescriptionKey)
    .map(currentPrescription => currentPrescription.recurringPrescriptionKey);

  const {
    auth: {
      user: {
        profile: { roles },
        userName
      }
    }
  } = state;

  return {
    currentPrescriptions,
    recurringPrescriptions,
    selectedPrescription: getSelectedPrescription(state),
    disabledRxButtons,
    mainPrescribers: providersFormattedDropdown(state.user.mainPrescribers),
    processing: state.ajaxCallsInProgress > 0,
    numberPerDoseOptions: state.lookups.numberPerDoseOptions,
    refillOptions: state.lookups.refillOptions,
    frequencyOptions: state.lookups.frequencyOptions,
    authRoles: roles,
    loggedInUsername: userName,
    isReceptionist:
      roles.indexOf(roleNames.receptionist) !== -1 &&
      roles.indexOf(roleNames.supProvider) === -1 &&
      roles.indexOf(roleNames.provider) === -1
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...modalActions,
        ...prescriptionActions,
        ...lookupActions,
        ...userActions,
        ...providerActions
      },
      dispatch
    )
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RxList);
