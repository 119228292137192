import React, { Component } from "react";
import { Breadcrumb, Grid, Header, Icon } from "semantic-ui-react";
import shortid from "shortid";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Switch, Route, Link } from "react-router-dom";
import ReportDashboardRow from "./ReportDashboardRow";
import * as biReportActions from "../../actions/reportActions";
import ClaimReconciliationsReport from "./ClaimReconciliationsReport";
import LabOrderStatusReport from "./LabOrderStatusReport";
import PatientsPendingDischargeReport from "./PatientsPendingDischargeReport";
import AppointmentReminderConfirmationReport from "./AppointmentReminderConfirmationReport";
import ManageClaimErrorsPage from "../error/ManageClaimErrorsPage";
import ReviewOfPatients from "./ReviewOfPatients";
import PrescriptionStatusReport from "./PrescriptionStatusReport";
import ExostarAuditLog from "./ExostarAuditLog";
import "./ReportDashboard.css";
import "./ReportPage.css";

class ReportDashboard extends Component {
  componentDidMount() {
    document.body.style.backgroundColor = "#fff";
    this.props.actions.loadReports();
  }

  componentWillUnmount() {
    document.body.style.backgroundColor = "#f3f3f3";
  }

  render() {
    const { match, reportGroups } = this.props;
    return (
      <div className="dashboard-page">
        <React.Fragment>
          <div className="report-container">
            <Breadcrumb>
              <Breadcrumb.Section active={false}>
                <Link to={"/"}>
                  <Icon name="home" />
                </Link>
              </Breadcrumb.Section>
              <Breadcrumb.Divider />
              <Breadcrumb.Section active={false}>
                <Link to={"/report"}>{`Reports`}</Link>
              </Breadcrumb.Section>
            </Breadcrumb>
            <Switch>
              <Route
                path={match.path}
                render={() => (
                  <React.Fragment>
                    <div className="header-wrapper">
                      <h2>Report landing page</h2>
                    </div>
                    <div className="content-wrapper">
                      {(reportGroups || []).length ? (
                        reportGroups.map(({ groupName, reportList }) => (
                          <React.Fragment key={`rand-${shortid.generate()}`}>
                            <Header as="h5" className="report-header" dividing>
                              {groupName}
                            </Header>
                            <Grid stackable padded="vertically" columns={2}>
                              {(reportList || []).length
                                ? reportList.map((report) => <ReportDashboardRow key={report.reportId} {...report} />)
                                : null}
                            </Grid>
                          </React.Fragment>
                        ))
                      ) : (
                        <h2>There are not any reports in system!</h2>
                      )}
                    </div>
                  </React.Fragment>
                )}
                exact
              />

              <Route
                path={`${match.path}/athena-claim-error`}
                render={() => <ManageClaimErrorsPage athenaServiceErrorsOnly />}
              />
              <Route
                path={`${match.path}/center-claim-error`}
                render={() => <ManageClaimErrorsPage athenaServiceErrorsOnly={false} />}
              />
              <Route path={`${match.path}/lab-order-status`} component={LabOrderStatusReport} />
              <Route
                path={`${match.path}/patients-pending-discharge-report`}
                component={PatientsPendingDischargeReport}
              />
              <Route
                path={`${match.path}/appointment-reminder-confirmation-report`}
                component={AppointmentReminderConfirmationReport}
              />
              <Route path={`${match.path}/claim-reconciliations-report`} component={ClaimReconciliationsReport} />
              <Route path={`${match.path}/review-of-patients`} component={ReviewOfPatients} />
              <Route path={`${match.path}/prescription-status`} component={PrescriptionStatusReport} />
              <Route path={`${match.path}/exostar-audit-log`} component={ExostarAuditLog} />
            </Switch>
          </div>
        </React.Fragment>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    authRoles: state.auth.user.profile.roles,
    reportGroups: state.reports.reportGroups
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...biReportActions }, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportDashboard);
