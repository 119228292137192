import orderBy from "lodash/orderBy";

import initialState from "./initialState";
import * as types from "../actions/actionTypes";

export default function modalReducer(state = initialState.user, action) {
  switch (action.type) {
    case types.LOAD_MAIN_PRESCRIBERS_SUCCESS:
      return Object.assign({}, state, {
        mainPrescribers: action.mainPrescribers.users
      });
    case types.LOAD_SUP_PROVIDERS_SUCCESS:
      return Object.assign({}, state, {
        supervisingProviders: action.supervisingProviders.users
      });
    case types.LOAD_ALL_USERS_SUCCESS:
      return Object.assign({}, state, {
        list: action.list
      });
    case types.LOAD_USERS_BY_FILTER_SUCCESS:
      return Object.assign({}, state, {
        list: state.list.filter(u => u.isActive === action.isActive)
      });
    case types.TOGGLE_USER_STATUS_SUCCESS:
      return {
        ...state,
        list: state.list.map(user => (user.userId === action.userId ? { ...user, isActive: action.isActive } : user))
      };
    case types.SORT_USER_BY_COLUMN: {
      const userList = [...state.list];
      return {
        ...state,
        list:
          action.sortColumn !== action.columnToSort
            ? orderBy(userList, [user => (user[action.columnToSort] || "").toLowerCase()])
            : userList.reverse()
      };
    }
    case types.REQUEST_EPCS_ADMIN_ACCESS_SUCCESS:
      return {
        ...state,
        list: state.list.map(user =>
          user.userId === action.userId ? { ...user, hasEcpsAdminAccess: action.hasEcpsAdminAccess } : user
        )
      };
    case types.LOAD_DRUG_FAVORITES_DONE:
      return { ...state, drugFavorites: action.drugFavorites.map(i => ({ ...i, dosage: Number(i.dosage) })) };
    default:
      return state;
  }
}
