import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function claimErrorsReducer(state = initialState.claimErrors, action) {
  switch (action.type) {
    case types.SET_CLAIM_ERRORS_SUCCESS:
      return { ...state, claims: action.data };
    case types.SET_ALL_CLAIM_ERRORS_SUCCESS:
      return { ...state, allClaims: action.data };
    case types.SET_SELECTED_CLAIM_ERROR_DETAILS_SUCCESS:
      return { ...state, selectedClaimDetails: action.data };
    default:
      return state;
  }
}
