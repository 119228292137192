import React, { Component } from "react";
import { connect } from "react-redux";
import { showModal } from "../../../helpers";
import { PatientLevel } from "../../../ui";
import { EDIT_PATIENT_LEVELS_MODAL } from "../../../helpers/modals";
import styles from "./Toolbar.module.css";

export class Toolbar extends Component {
  state = {
    isMobile: false,
    isOpen: false
  };

  handleLevelClick = () => {
    showModal(EDIT_PATIENT_LEVELS_MODAL, {});
  };

  render() {
    const { placement = "right", autohide } = this.props;
    const addLevelItem = true;
    return (
      <div className={`${styles.toolbar} ${styles[placement]} ${autohide ? styles.autohide : ""}`}>
        {addLevelItem && (
          <div
            className={`btn btn-outline-success ${styles["toolbar-item"]}`}
            role="button"
            tabIndex={0}
            onClick={this.handleLevelClick}
          >
            <PatientLevel level="_" size="sm" style={{ position: "absolute", backgroundColor: "black" }} />
            <PatientLevel level="_" size="sm" bh style={{ position: "absolute", borderBottomColor: "white" }} />
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    toolbarItems: state.userPreference.toolbarItems
  };
}

export default connect(mapStateToProps, null)(Toolbar);
