import React from "react";
import { Header, Modal } from "semantic-ui-react";
import { hideModal } from "../../../../helpers";
import DoctorSearchForm from "./DoctorSearchForm";

export const DoctorSearchModal = ({ open }) => (
  <Modal
    className="add-pcp-container"
    open={open}
    onClose={() => {
      hideModal();
    }}
    closeIcon
    closeOnDimmerClick={false}
    size="small"
  >
    <Header content="Primary Care Provider - Doctor Lookup" />
    <Modal.Content>
      <DoctorSearchForm
        handleCancel={() => {
          hideModal();
        }}
        afterSave={() => {
          hideModal();
        }}
        hideCancelButton
      />
    </Modal.Content>
  </Modal>
);

export default DoctorSearchModal;
