/* eslint-disable no-alert */
import React, { Component } from "react";
import shortid from "shortid";

class OptionsEditor extends Component {
  state = {
    option: ""
  };

  change = e => {
    this.setState({ option: e.target.value });
  };

  add = () => {
    const { options, update } = this.props;
    const { option } = this.state;
    if (options.includes(option)) alert("Option is already added");
    else update("options", [...options, option]);
  };

  remove = o => {
    const { options, update } = this.props;
    const index = options.findIndex(i => i === o);
    const updatedOptions = [...options];
    updatedOptions.splice(index, 1);
    update("options", updatedOptions);
  };

  render() {
    const { options } = this.props;
    const { option } = this.state;
    return (
      <div className="ui grid">
        <div className="row">
          <div className="sixteen wide column">
            <ul>
              {options.map(o => (
                <li key={shortid.generate()}>
                  <span style={{ display: "inline-block", width: "90%" }}>{o}</span>
                  <i
                    className="minus link icon"
                    style={{ display: "inline-block", width: "10%", margin: "0", color: "red" }}
                    role="button"
                    tabIndex={0}
                    onClick={() => this.remove(o)}
                  />
                </li>
              ))}
            </ul>
          </div>
          <div className="sixteen wide column ">
            <div className="ui icon input" style={{ display: "block" }}>
              <input type="text" className="form-control" placeholder="option" value={option} onChange={this.change} />
              <i className="circular plus link icon" role="button" tabIndex={0} onClick={this.add} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default OptionsEditor;
