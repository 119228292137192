import React, { Component } from "react";
import { Button, Header, Modal } from "semantic-ui-react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as modalActions from "../../../actions/modalActions";
import * as labActions from "../../../actions/labActions";

export class AoeModal extends Component {
  state = {};

  componentDidMount() {}

  handleClose = () => {
    const {
      patientId,
      orderId,
      actions: { showModal }
    } = this.props;
    showModal({
      type: "CANCEL_LAB_ORDER",
      props: {
        open: true,
        multiple: true,
        aoe: true,
        patientId,
        orderId,
        labSource: null,
        labCode: null,
        patientName: ``,
        isConfirmatory: false,
        isCancelOrder: true
      }
    });
  };

  handleContinue = () => {
    const {
      patientId,
      orderId,
      actions: { submitAOE, hideModal }
    } = this.props;
    submitAOE(patientId, orderId).then(() => hideModal(true, true));
  };

  render() {
    const { open, url, processing } = this.props;
    return (
      <Modal
        className="lab-order-modal"
        open={open}
        closeOnDimmerClick={false}
        onClose={this.handleClose}
        closeIcon
        size="small"
      >
        <Header content="Ask On Entry" />
        <Modal.Content scrolling>
          <iframe id="ifrm" src={url} title="Ask On Entry" width="100%" height="290" />
          <hr />
          <p>
            <strong>{`Please make sure you press "Submit" button and check for message "AOE values saved"`}</strong>
          </p>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.handleClose} id="btn-cancel" disabled={processing}>
            Cancel
          </Button>

          <Button
            onClick={this.handleContinue}
            color="blue"
            id="btn-sendToLab"
            disabled={processing}
            loading={processing}
          >
            Continue
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const fields = state.labs.labsRapidTestReference
    ? state.labs.labsRapidTestReference.filter((f) => f.labCode === ownProps.labCode)
    : [];
  return {
    fields,
    results: state.labs.labsRapidTestResults,
    processing: state.ajaxCallsInProgress > 0
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...modalActions, ...labActions }, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AoeModal);
