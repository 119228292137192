import React, { Component } from "react";
import { Button, Header, Modal, Table } from "semantic-ui-react";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as modalActions from "../../../../actions/modalActions";
import * as patientHistoryActions from "../../../../actions/patientHistoryActions";

import HistoricalAddressRow from "./HistoricalAddressRow";

import "./HistoricalAddressModal.css";

export class HistoricalAddressModal extends Component {
  componentDidMount() {
    const { patientId } = this.props;
    if (patientId) this.props.actions.loadHistoricalAddress(patientId);
  }

  handleClose = () => {
    this.props.actions.hideModal();
  };

  render() {
    const { open, historicalAddresses } = this.props;

    return (
      <Modal className="insurance-history-modal" open={open} onClose={this.handleClose} closeIcon>
        <Header content="Address History" />
        <Modal.Content className="tableFixHead">
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell className="address-action">Action</Table.HeaderCell>
                <Table.HeaderCell className="address-action">Action date</Table.HeaderCell>
                <Table.HeaderCell className="address-action">Billing/Staying</Table.HeaderCell>
                <Table.HeaderCell className="address-action">Address</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body className="historical-address-row">
              {historicalAddresses.map(addressHistory => (
                <HistoricalAddressRow key={addressHistory.patientAddressId} {...addressHistory} />
              ))}
            </Table.Body>
          </Table>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.handleClose} id="btn-cancel">
            Close
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    patientId: state.patient.currentPatient.patientId,
    historicalAddresses: state.patientHistory.historicalAddresses
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...modalActions, ...patientHistoryActions }, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HistoricalAddressModal);
