import React, { Component } from "react";
import { Button, Header, Modal, Form, Image, Popup } from "semantic-ui-react";
import MaskedInput from "react-text-mask";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as modalActions from "../../../../actions/modalActions";
import * as demographicActions from "../../../../actions/demographicActions";
import * as lookupActions from "../../../../actions/lookupActions";

// eslint-disable-next-line camelcase
import messages_ok from "../../../../assets/patients/current/messages_ok.svg";
// eslint-disable-next-line camelcase
import no_messages from "../../../../assets/patients/current/no_messages.svg";

import "../../../reusable/Modal.css";
import "./EditAssociatedContactModal.css";
import { phoneMask } from "../../../../constants/validation";
import { formatInputSpace } from "../../../../helpers/index";

const getContact = props =>
  props.currentContact || {
    firstName: "",
    lastName: "",
    associatedContactType: "EmergencyContact",
    relationship: "",
    phoneNumber: "",
    extension: "",
    consentGivenForMessages: false,
    isPrimary: false
  };

export class EditAssociatedContactModal extends Component {
  state = {
    contact: getContact(this.props),
    errors: {},
    confirmationOpen: false,
    relationships: [],
    disableSave: false
  };

  componentDidMount() {
    this.props.actions.loadRelationships().then(() => this.setState({ relationships: this.props.relationships }));
  }

  handleAddition = (e, { value }) => {
    this.setState({
      relationships: [{ text: value, value }, ...this.state.relationships]
    });
  };

  handleClose = () => {
    const { patientId } = this.props;
    this.props.actions.loadPatientDemo(patientId);
    this.props.actions.hideModal();
  };

  handleConfirmationOpen = () => {
    const { confirmationOpen } = this.state;
    this.setState({ confirmationOpen: !confirmationOpen });
  };

  handleDelete = () => {
    const { patientId } = this.props;
    this.props.actions.deleteContact(patientId, this.state.contact).then(this.handleClose);
  };

  handleInput = (e, data) => {
    const { name: field, value } = data;
    const newContact = Object.assign({}, this.state.contact, { [field]: value });
    this.setState({
      contact: newContact,
      disableSave: false
    });
  };

  handleMaskedInput = e => {
    const { name: field, value } = e.target;
    const newContact = Object.assign({}, this.state.contact, { [field]: value });
    this.setState({
      contact: newContact
    });
  };

  handleCheckbox = (e, data) => {
    const { name: field, checked } = data;
    const newContact = Object.assign({}, this.state.contact, { [field]: checked });
    this.setState({ contact: newContact });
  };

  handleSave = () => {
    const { patientId } = this.props;
    const contact = { ...this.state.contact, phoneNumber: this.state.contact.phoneNumber.replace(/\D/g, "") };
    const contactFormated = formatInputSpace(contact);
    this.setState({ disableSave: true });

    if (contact.isPrimary) {
      this.props.actions.deletePrimaryFlag(contact);
    }

    if (contact.personId) {
      this.props.actions.updateContact(patientId, contactFormated).then(this.handleClose);
    } else {
      this.props.actions.createContact(patientId, contactFormated).then(this.handleClose);
    }
  };

  render() {
    const { open, isEdit, processing } = this.props;
    const {
      contact: {
        associatedContactType,
        firstName,
        lastName,
        relationship,
        phoneNumber,
        extension,
        consentGivenForMessages,
        isPrimary
      },
      confirmationOpen,
      relationships,
      disableSave
    } = this.state;

    // eslint-disable-next-line camelcase
    const messageIconColor = consentGivenForMessages ? messages_ok : no_messages;
    return (
      <Modal className="edit-associatedcontact-modal" open={open} onClose={this.handleClose} closeIcon size="small">
        <Header content="Manage Associated Contact" />
        <Modal.Content>
          <Form>
            <Form.Group>
              <Form.Field width={6}>
                <Form.Dropdown
                  fluid
                  selection
                  search
                  label="Relationship"
                  placeholder="Select..."
                  name="relationship"
                  options={relationships}
                  onChange={this.handleInput}
                  value={relationship}
                  onAddItem={this.handleAddition}
                  id="input-relationship"
                />
              </Form.Field>
              <Form.Field width={5}>
                <Form.Input
                  label="First Name"
                  name="firstName"
                  maxLength={50}
                  defaultValue={firstName}
                  onChange={this.handleInput}
                  id="input-firstName"
                />
              </Form.Field>
              <Form.Field width={5}>
                <Form.Input
                  label="Last Name"
                  name="lastName"
                  maxLength={150}
                  defaultValue={lastName}
                  onChange={this.handleInput}
                  id="input-lastName"
                />
              </Form.Field>
            </Form.Group>
            <Form.Group>
              <Form.Field width={8}>
                <label>Phone Number</label>
                <MaskedInput
                  name="phoneNumber"
                  mask={phoneMask}
                  guide={false}
                  value={phoneNumber}
                  onChange={this.handleMaskedInput}
                  id="input-phoneNumber"
                />
              </Form.Field>
              <Form.Field width={4}>
                <Form.Input
                  label="Extension"
                  name="extension"
                  maxLength={10}
                  value={extension}
                  onChange={this.handleInput}
                  id="input-extension"
                />
              </Form.Field>
              <Form.Field width={4}>
                <label>Contact Type</label>
                <Form.Radio
                  label="Emergency"
                  name="associatedContactType"
                  value="EmergencyContact"
                  disabled={isEdit}
                  checked={associatedContactType === "EmergencyContact"}
                  id="input-EmergencyContact"
                  onChange={this.handleInput}
                />
                <Form.Radio
                  label="Other"
                  name="associatedContactType"
                  value="Other"
                  disabled={isEdit}
                  checked={associatedContactType === "Other"}
                  id="input-Other"
                  onChange={this.handleInput}
                />
              </Form.Field>
            </Form.Group>
            <Form.Group>
              {/* <Form.Field width={2} /> */}
              <Form.Field width={5}>
                <Form.Checkbox
                  className="isPreferredCheckbox"
                  label="Primary Contact"
                  name="isPrimary"
                  checked={isPrimary}
                  id="input-isPrimary"
                  onChange={this.handleCheckbox}
                />
              </Form.Field>
              <Form.Field width={7}>
                <Form.Checkbox
                  className="sendMessagesCheckbox"
                  label="Ok to Leave Messages"
                  name="consentGivenForMessages"
                  checked={consentGivenForMessages}
                  id="input-consentGivenForMessages"
                  onChange={this.handleCheckbox}
                />
                <div>
                  <Image src={messageIconColor} size="mini" inline />
                </div>
              </Form.Field>
            </Form.Group>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          {isEdit ? (
            <Popup
              trigger={
                <Button color="red" floated="left" id="btn-delete">
                  Delete
                </Button>
              }
              content={
                <React.Fragment>
                  <Button color="grey" content="Cancel" onClick={this.handleConfirmationOpen} id="btn-confirmCancel" />
                  <Button color="red" content="Delete Contact" onClick={this.handleDelete} id="btn-confirmDelete" />
                </React.Fragment>
              }
              on="click"
              position="top left"
              wide
              open={confirmationOpen}
              onOpen={this.handleConfirmationOpen}
            />
          ) : null}
          <Button onClick={this.handleClose} id="btn-cancel">
            Cancel
          </Button>
          <Button
            onClick={this.handleSave}
            loading={processing}
            color="blue"
            disabled={processing || disableSave}
            id="btn-save"
          >
            Save
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    patientId: state.patient.currentPatient.patientId,
    relationships: state.lookups.relationships,
    processing: state.ajaxCallsInProgress > 0
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...modalActions, ...lookupActions, ...demographicActions }, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditAssociatedContactModal);
