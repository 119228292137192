import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import { isEmpty, set } from "lodash";
import { Dropdown, Form, Grid, Icon } from "semantic-ui-react";
import { DateInput } from "semantic-ui-calendar-react";
import Report from "./Report";
import * as modalActions from "../../actions/modalActions";
import * as lookupActions from "../../actions/lookupActions";
import * as providerActions from "../../actions/providerActions";
import * as searchActions from "../../actions/searchActions";
import { dateFormat, dateWithTimeFormat, isoDateFormat } from "../../constants/miscellaneous";

export class PatientsPendingDischargeReport extends React.Component {
  state = {
    fetchCounter: 0,
    filter: {
      visitFrom: moment()
        .subtract(90, "days")
        .format(isoDateFormat),
      visitTo: moment().format(isoDateFormat),

      centerIds: []
    }
  };

  handleInput = (e, data) => {
    const { name: field } = data;
    let { value } = data;
    const newState = { ...this.state };
    if (field === "filter.visitFrom" || field === "filter.visitTo") {
      value = isEmpty(value) ? "" : moment(value, dateFormat).format(isoDateFormat);
    }
    set(newState, field, value);
    this.setState(newState);
  };

  handlePatientSelected = (patientSearch, patient) => {
    this.setState({ filter: { ...this.state.filter, patientIds: patient.patientId } });
    patientSearch.resetComponent();
  };

  refetch = () => {
    this.setState({ fetchCounter: this.state.fetchCounter + 1 });
  };

  renderFilterForm = () => {
    const { centerOptions } = this.props;
    const { filter } = this.state;
    return (
      <Grid.Column width={16}>
        <Form>
          <Grid>
            <Grid.Row style={{ paddingBottom: "0" }}>
              <Grid.Column width={1} />
              <Grid.Column width={3}>
                Patient Main Center
                <br />
                <Form.Group widths="equal" style={{ margin: "0 0 1em" }}>
                  <Dropdown
                    name="filter.centerIds"
                    // label="Clinic:"
                    // placeholder="Select a Center..."
                    options={centerOptions}
                    onChange={this.handleInput}
                    value={filter.centerIds}
                    // style={{ display: "inline-block" }}
                    className="mini"
                    fluid
                    clearable
                    multiple
                    selection
                    search
                  />
                </Form.Group>
              </Grid.Column>
              <Grid.Column width={2}>
                Visit date from
                <br />
                <Form.Group widths="equal">
                  <DateInput
                    size="mini"
                    name="filter.visitFrom"
                    placeholder="From"
                    value={isEmpty(filter.visitFrom) ? "" : moment(filter.visitFrom).format(dateFormat)}
                    dateFormat={dateFormat}
                    iconPosition="right"
                    onChange={this.handleInput}
                    hideMobileKeyboard
                    clearable
                    closable
                  />
                </Form.Group>
              </Grid.Column>
              <Grid.Column width={2}>
                Visit date to
                <br />
                <Form.Group widths="equal">
                  <DateInput
                    size="mini"
                    name="filter.visitTo"
                    placeholder="To"
                    value={isEmpty(filter.visitTo) ? "" : moment(filter.visitTo).format(dateFormat)}
                    dateFormat={dateFormat}
                    iconPosition="right"
                    onChange={this.handleInput}
                    hideMobileKeyboard
                    clearable
                    closable
                  />
                </Form.Group>
              </Grid.Column>
              <Grid.Column width={2}>
                {` `}
                <br />
                <Form.Button size="mini" color="blue" onClick={this.refetch}>
                  Filter
                  <Icon name="search" style={{ marginLeft: "5px" }} />
                </Form.Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
      </Grid.Column>
    );
  };

  render() {
    return (
      <Report
        endPoint="PatientsPendingDischarge"
        fetchCounter={this.state.fetchCounter}
        header="Patients Pending Discharge"
        rowKeyField="id"
        filterObject={this.state.filter}
        beforeFetch={filterObject =>
          Object.keys(filterObject).reduce((acc, key) => {
            if (!isEmpty(filterObject[key])) {
              acc[key] = filterObject[key];
            }
            return acc;
          }, {})
        }
        renderFilterForm={this.renderFilterForm}
        fields={[
          { name: "patientId", visible: false },
          {
            name: "medicalRecordNumber",
            label: "MRN",

            render: row => (
              <Link
                to={`/current-patients/${row.patientId}/demographics`}
                style={{ textDecoration: "none" }}
                key={row.patientId}
              >
                {row.medicalRecordNumber}
              </Link>
            )
          },
          { name: "firstName", label: "First Name" },
          { name: "lastName", label: "Last Name" },
          { name: "centerName", label: "Patient Main Center" },
          {
            name: "visitTime",
            label: "Date of Last Medical Visit",
            width: 2,
            render: row => moment(row.visitTime).format(dateWithTimeFormat)
          }
        ]}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    centerOptions: state.lookups.centers.map(i => ({ text: i.text, value: i.value })),
    providerOptions: state.providers.allProviders.map(i => ({ text: `${i.firstName} ${i.lastName}`, value: i.userId })),
    labCodeOptions: state.lookups.labsUnique.map(i => ({ text: i.labName, value: i.labCode })),
    sampleTypeOptions: state.lookups.labSpecimenTypes.map(i => ({ text: i, value: i })),
    testTypeOptions: state.lookups.labTestTypes.map(i => ({ text: i, value: i })),
    labProviderOptions: state.lookups.labProviders.map(i => ({ text: i, value: i })),
    orderStatusOptions: state.lookups.labOrderStatuses.map(i => ({ text: i, value: i })),
    statusOptions: state.lookups.labTestStatuses.map(i => ({ text: i, value: i }))
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...modalActions, ...lookupActions, ...providerActions, ...searchActions }, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PatientsPendingDischargeReport);
