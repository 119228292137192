import isEmpty from "lodash/isEmpty";

import { API_URL } from "../constants/environment";
import { adalApiFetch } from "../adalConfig";

export const fetchProvider = providerId => adalApiFetch(`${API_URL}/Users/Providers/${providerId}/ProviderDetails`);

export const fetchProviderEpcsSetup = (providerId, centerId = null, hasPayload = false) => {
  const queryParams = [];

  if (!isEmpty(centerId)) {
    queryParams.push(`centerId=${centerId}`);
  }

  if (hasPayload) {
    queryParams.push(`hasPayload=${hasPayload}`);
  }

  const query = queryParams.length > 0 ? `?${queryParams.join("&")}` : "";

  return adalApiFetch(`${API_URL}/Users/Providers/${providerId}/EpcsSetup${query}`);
};

export default {
  fetchProvider,
  fetchProviderEpcsSetup
};
