import React from "react";
import { Table } from "semantic-ui-react";
import DocumentsRow from "./DocumentsRow";

const DocumentsTable = props => {
  const {
    sortColumn,
    sortDirection,
    availableDocuments,
    documentTags,
    handleEdit,
    handleDowload,
    canDelete,
    handleSort,
    handleDelete
  } = props;
  return (
    <Table selectable sortable className="documents-table">
      <Table.Header className="tableHeadRow">
        <Table.Row>
          <Table.HeaderCell
            sorted={sortColumn === "DateCreated" ? sortDirection : null}
            onClick={handleSort("DateCreated")}
            style={{ minWidth: "110px" }}
          >
            Scan Date
          </Table.HeaderCell>
          <Table.HeaderCell width={3}>Tags</Table.HeaderCell>
          <Table.HeaderCell
            width={3}
            sorted={sortColumn === "DisplayName" ? sortDirection : null}
            onClick={handleSort("DisplayName")}
          >
            Document Name
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={sortColumn === "ExpirationDate" ? sortDirection : null}
            onClick={handleSort("ExpirationDate")}
            style={{ minWidth: "110px" }}
          >
            Expiration
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={sortColumn === "LastUpdateDate" ? sortDirection : null}
            onClick={handleSort("LastUpdateDate")}
            style={{ minWidth: "110px" }}
          >
            Last Updated
          </Table.HeaderCell>
          <Table.HeaderCell width={4}>Description</Table.HeaderCell>
          <Table.HeaderCell width={4}>Visit</Table.HeaderCell>
          <Table.HeaderCell style={{ minWidth: "95px" }} />
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {availableDocuments.length
          ? availableDocuments.map(document => (
              <DocumentsRow
                document={document}
                key={document.documentId}
                documentTags={documentTags}
                handleEdit={handleEdit}
                handleDowload={handleDowload}
                canDelete={canDelete}
                handleDelete={handleDelete}
              />
            ))
          : null}
      </Table.Body>
    </Table>
  );
};

export default DocumentsTable;
