import React from "react";
import { Button } from "semantic-ui-react";

const MedicalHistorySliderRow = ({
  handleQuickSearch,
  alertText,
  icd10Code
}) => (
    <div>
      <Button
        icon="plus"
        size="small"
        className="transparent-button-icon"
        onClick={() => handleQuickSearch({ icd10Code, condition: alertText })}
      />
      <span>{alertText}</span>
    </div>
  );

export default MedicalHistorySliderRow;
