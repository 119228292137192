import React, { Component } from "react";
import ReactDOM from "react-dom";
import { Grid, Menu, Label, Dropdown } from "semantic-ui-react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import shortid from "shortid";
import { isEmpty } from "lodash";
import * as providerActions from "../../actions/providerActions";
import "./ChartsToCoSignPage.css";
import * as chartsActions from "../../actions/chartsActions";
import ChartsToCoSignTable from "./ChartsToCoSignTable";

class ChartsToCoSignPage extends Component {
  // Init local state
  state = {
    data: [],
    sortColumn: "remaining",
    sortDirection: "descending",
    selectedProvider: {},
    selectedMonth: ``,
    contextRef: null,
    reloadData: false
  };

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate() {
    if (this.state.reloadData) {
      this.setState({ reloadData: false });
      this.loadData(true);
    }
  }

  // getNumberRequire = (data) => {
  //   if (data.percentageOfRequiredCosignCharts) {
  //     return Math.ceil(data.percentageOfRequiredCosignCharts * data.totalCoSignChart);
  //   }
  //   return data.numberOfRequiredCosignCharts || 0;
  // };

  prepareData = (data) =>
    data
      .map((item) => ({
        ...item,
        stateText:
          data.filter((i) => i.providerId === item.providerId).length === 1
            ? `, ${item.stateCode}`
            : ` (${item.stateCode})`
      }))
      .sort((a, b) => {
        switch (this.state.sortColumn) {
          case "supervising":
            if (this.state.sortDirection === "ascending")
              return a.providerLastName > b.providerLastName ? 1 : a.providerLastName < b.providerLastName ? -1 : 0;
            return a.providerLastName < b.providerLastName ? 1 : a.providerLastName > b.providerLastName ? -1 : 0;
          case "remaining":
            if (this.state.sortDirection === "ascending")
              return (a.selectedReport ? a.selectedReport.monthRemainingCosigns : 0) >
                (b.selectedReport ? b.selectedReport.monthRemainingCosigns : 0)
                ? 1
                : (a.selectedReport ? a.selectedReport.monthRemainingCosigns : 0) <
                  (b.selectedReport ? b.selectedReport.monthRemainingCosigns : 0)
                ? -1
                : 0;
            return (a.selectedReport ? a.selectedReport.monthRemainingCosigns : 0) <
              (b.selectedReport ? b.selectedReport.monthRemainingCosigns : 0)
              ? 1
              : (a.selectedReport ? a.selectedReport.monthRemainingCosigns : 0) >
                (b.selectedReport ? b.selectedReport.monthRemainingCosigns : 0)
              ? -1
              : 0;
          default:
            return 0;
        }
      });

  loadData = (reload = false) => {
    const date = new Date();
    const selectedMonth = reload ? this.state.selectedMonth : `${date.getFullYear()}_${1 + date.getMonth()}`;
    this.props.actions.loadProvidersBySupervisorId().then((resp) => {
      const _data = resp.providers.map((provider) => {
        const monthlyReports = provider.monthlyReports.map((line) => ({
          ...line,
          text: `${line.month}/${line.year}`,
          value: `${line.year}_${line.month}`
        }));
        return {
          ...provider,
          monthlyReports,
          selectedReport: monthlyReports.find((line) => line.value === selectedMonth) || {
            year: date.getFullYear(),
            month: 1 + date.getMonth(),
            text: `${1 + date.getMonth()}/${date.getFullYear()}`,
            value: selectedMonth,
            monthRemainingCosigns: 0
          }
        };
      });
      this.setState(
        {
          data: this.prepareData(_data)
        },
        () => {
          const { data, selectedProvider } = this.state;
          this.setState({
            selectedProvider: isEmpty(selectedProvider)
              ? data.length > 0
                ? { ...data[0] }
                : {}
              : {
                  ...data.find(
                    (i) => i.providerId === selectedProvider.providerId && i.stateCode === selectedProvider.stateCode
                  )
                },
            selectedMonth
          });
        }
      );
    });
  };

  handleSort = (clickedColumn) => () => {
    const { sortColumn, sortDirection } = this.state;
    this.setState(
      {
        sortColumn: clickedColumn,
        sortDirection: sortDirection === "ascending" && sortColumn === clickedColumn ? "descending" : "ascending"
      },
      () => {
        this.setState({
          data: this.prepareData(this.state.data)
        });
      }
    );
  };

  handleItemClick = (e, { providerid, statecode }) => {
    const { data } = this.state;
    // eslint-disable-next-line react/no-find-dom-node
    ReactDOM.findDOMNode(this).getElementsByClassName("scrolling")[0].scrollTop = 0;
    this.setState({
      selectedProvider: data.find((i) => i.providerId === providerid && i.stateCode === statecode)
    });
  };

  handleChangeSelectedMonth = (e, data) => {
    if (isEmpty(this.state.selectedProvider)) return;
    const year = +data.value.split("_")[0];
    const month = +data.value.split("_")[1];
    const _data = this.state.data.map((item) => ({
      ...item,
      selectedReport: item.monthlyReports.find((line) => line.value === data.value) || {
        year,
        month,
        text: `${month}/${year}`,
        value: data.value,
        monthRemainingCosigns: 0
      }
    }));

    this.setState({
      data: this.prepareData(_data),
      selectedProvider: {
        ...this.state.selectedProvider,
        selectedReport: this.state.selectedProvider.monthlyReports.find((line) => line.value === data.value) || {
          year,
          month,
          text: `${month}/${year}`,
          value: data.value,
          monthRemainingCosigns: 0
        }
      },
      selectedMonth: data.value
    });
  };

  render() {
    const { sortColumn, sortDirection, data, selectedProvider, selectedMonth } = this.state;
    const { isMobile, visitTypes } = this.props;

    let numberCosigned = 0;
    let monthActualRequiredCosigns = 0;
    if (data.length > 0) {
      numberCosigned = selectedProvider?.selectedReport?.monthCoSignedCharts || 0;
      monthActualRequiredCosigns = selectedProvider?.selectedReport?.monthActualRequiredCosigns || 0;
    }

    const monthsOptions = [];
    const date = new Date();
    date.setDate(1);
    monthsOptions.push({
      text: `${1 + date.getMonth()}/${date.getFullYear()}`,
      value: `${date.getFullYear()}_${1 + date.getMonth()}`
    });
    for (let i = 0; i < 5; i++) {
      date.setMonth(date.getMonth() - 1);
      const year = date.getFullYear();
      const month = 1 + date.getMonth();
      monthsOptions.push({ text: `${month}/${year}`, value: `${year}_${month}` });
    }

    return (
      <div>
        <div className="header-wrapper">
          <h2>Charts To Co-Sign</h2>
        </div>
        <div>
          <div style={{ display: "inline-block", width: "30%", direction: "rtl" }}>
            <Dropdown
              name="roles"
              options={monthsOptions}
              value={selectedMonth}
              onChange={this.handleChangeSelectedMonth}
              style={{ width: "100px", marginRight: "1rem" }}
              className="mini"
              search
              selection
            />
          </div>
          <div className="scrolling" style={{ display: "inline-block", width: "70%" }}>
            <div className="lb-cosign">
              You have Co-Signed<b>{` ${numberCosigned} `}</b> out of <b>{monthActualRequiredCosigns}</b> visit(s) this
              month
            </div>
          </div>
        </div>
        <div className="scrolling-content-wrapper">
          <div style={{ width: "30%", direction: "rtl" }}>
            <div className="ui sortable table" style={{ direction: "ltr" }}>
              <Menu fluid vertical tabular={!isMobile}>
                <Menu.Item className="top sticky">
                  <Grid columns="equal">
                    <Grid.Row className="g-row-head">
                      <Grid.Column
                        width={8}
                        className={`cell clickable sorted ${sortColumn === `supervising` ? sortDirection : null}`}
                        onClick={this.handleSort("supervising")}
                      >
                        <b>Provider you are supervising</b>
                      </Grid.Column>
                      <Grid.Column
                        width={8}
                        className={`cell clickable sorted  ${sortColumn === `remaining` ? sortDirection : null}`}
                        onClick={this.handleSort("remaining")}
                      >
                        Remaining to Co-Sign
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Menu.Item>
                {data.map((item, idx) => (
                  <Menu.Item
                    providerid={item.providerId}
                    statecode={item.stateCode}
                    active={
                      selectedProvider.providerId === item.providerId && selectedProvider.stateCode === item.stateCode
                    }
                    onClick={this.handleItemClick}
                    idx={idx}
                    key={`rand-${shortid.generate()}`}
                  >
                    <Grid columns="equal">
                      <Grid.Row>
                        <Grid.Column
                          width={8}
                          style={{ paddingTop: ".6rem" }}
                        >{`${item.providerName}${item.stateText}`}</Grid.Column>
                        <Grid.Column width={8}>
                          <Label circular className="lb-remain">
                            {item.selectedReport ? item.selectedReport.monthRemainingCosigns : 0}
                          </Label>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Menu.Item>
                ))}
              </Menu>
            </div>
          </div>
          <div className="scrolling" style={{ width: "70%" }}>
            {visitTypes && (
              <ChartsToCoSignTable
                selectedProvider={selectedProvider}
                afterCoSign={() => {
                  this.setState({ reloadData: true });
                }}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const provider = state.providers.allProviders.length ? state.providers.allProviders[0].userId : "";
  return {
    provider,
    charts: state.charts.chartsToSign,
    visitTypes: state.lookups.visitTypes

    // listVisitCoSign: state.charts.listVisitCoSign
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...providerActions,
        ...chartsActions
      },
      dispatch
    )
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ChartsToCoSignPage);
