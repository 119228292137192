import React from "react";
import { Header, Input, Form, Icon, Grid, Table, Pagination } from "semantic-ui-react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import set from "lodash/set";
import * as modalActions from "../../actions/modalActions";
import * as adminActions from "../../actions/adminActions";
import { dateWithFullTimeFormat, monthsOptions, daysOptions, getYearOptions } from "../../constants/miscellaneous";

const yearsOptions = getYearOptions();
export class ManageShadowQueuePage extends React.Component {
  static m = moment();
  state = {
    filter: {
      from: {
        day: ManageShadowQueuePage.m.date(),
        month: ManageShadowQueuePage.m.month(),
        year: ManageShadowQueuePage.m.year()
      },
      to: {
        day: ManageShadowQueuePage.m.date(),
        month: ManageShadowQueuePage.m.month(),
        year: ManageShadowQueuePage.m.year()
      },
      itemId: "",
      serviceName: "",
      sourceName: "",
      topicName: "",
      itemType: ""
    },
    activePage: 1,
    pageSize: 10
  };

  constructor(props) {
    super(props);
    this.props.actions.pushItemToBreadcrumb({ key: "bc-shadow-queue", to: "/shadow-queue", text: "Shadow Queue" });
  }

  componentDidMount() {
    this.loadShadowQueue();
    this.interval = setInterval(() => {
      this.loadShadowQueue();
    }, 5000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  handlePaginationChange = (e, { activePage }) => {
    if (activePage >= 1) {
      this.setState({ activePage }, () => this.loadShadowQueue());
    }
  };

  applyFilter = () => this.loadShadowQueue(true);

  loadShadowQueue = (resetPagination = false) => {
    const callback = () => {
      const { pageSize, activePage, filter } = this.state;
      this.props.actions.loadShadowQueue(pageSize, activePage, filter);
    };
    if (resetPagination) {
      this.setState({ activePage: 1, pageSize: 10 }, callback);
    } else {
      callback();
    }
  };

  handleFilterInput = (e, data) => {
    const { name: field, value } = data;
    const newState = { ...this.state };
    set(newState, field, value);
    this.setState(newState);
  };

  handleFocus = e => {
    e.target.setAttribute("autocomplete", "nope");
  };

  render() {
    const { filter, pageSize, activePage } = this.state;
    const { items, totalCount } = this.props.shadowQueue;
    return (
      <React.Fragment>
        <Header as="h2">Shadow Queue</Header>
        <div className="content-wrapper">
          <Grid>
            <Grid.Row>
              <Grid.Column width={11}>
                <Form>
                  <Grid>
                    <Grid.Row>
                      <Grid.Column width={6}>
                        From
                        <br />
                        <Form.Group widths="equal">
                          <Form.Dropdown
                            fluid
                            selection
                            options={monthsOptions}
                            name="filter.from.month"
                            value={filter.from.month}
                            onChange={this.handleFilterInput}
                            onFocus={this.handleFocus}
                          />
                          <Form.Dropdown
                            fluid
                            selection
                            options={daysOptions}
                            name="filter.from.day"
                            value={filter.from.day}
                            onChange={this.handleFilterInput}
                            onFocus={this.handleFocus}
                          />
                          <Form.Dropdown
                            fluid
                            selection
                            options={yearsOptions}
                            name="filter.from.year"
                            value={filter.from.year}
                            onChange={this.handleFilterInput}
                            onFocus={this.handleFocus}
                          />
                        </Form.Group>
                      </Grid.Column>
                      <Grid.Column width={6}>
                        To
                        <br />
                        <Form.Group widths="equal">
                          <Form.Dropdown
                            fluid
                            selection
                            options={monthsOptions}
                            name="filter.to.month"
                            value={filter.to.month}
                            onChange={this.handleFilterInput}
                            onFocus={this.handleFocus}
                          />
                          <Form.Dropdown
                            fluid
                            selection
                            options={daysOptions}
                            name="filter.to.day"
                            value={filter.to.day}
                            onChange={this.handleFilterInput}
                            onFocus={this.handleFocus}
                          />
                          <Form.Dropdown
                            fluid
                            selection
                            options={yearsOptions}
                            name="filter.to.year"
                            value={filter.to.year}
                            onChange={this.handleFilterInput}
                            onFocus={this.handleFocus}
                          />
                        </Form.Group>
                      </Grid.Column>
                      <Grid.Column width={4}>
                        {` `}
                        <br />
                        <Form.Button color="blue" onClick={this.applyFilter}>
                          <span className="ui padded basic segment">Update</span>
                          <Icon name="search" />
                        </Form.Button>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Form>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <div>
                  <React.Fragment>
                    <Table selectable sortable style={{ wordBreak: "break-all" }}>
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell width={1}>ID</Table.HeaderCell>
                          <Table.HeaderCell width={2}>ServiceName</Table.HeaderCell>
                          <Table.HeaderCell width={2}>SourceName</Table.HeaderCell>
                          <Table.HeaderCell width={2}>Topic</Table.HeaderCell>
                          <Table.HeaderCell width={2}>ItemType</Table.HeaderCell>
                          <Table.HeaderCell width={4}>ItemContent</Table.HeaderCell>
                          <Table.HeaderCell width={3}>CreationDate</Table.HeaderCell>
                        </Table.Row>
                        <Table.Row>
                          <Table.HeaderCell width={1}>
                            <Input
                              name="filter.itemId"
                              value={filter.itemId}
                              onChange={this.handleFilterInput}
                              onBlur={this.applyFilter}
                            />
                          </Table.HeaderCell>
                          <Table.HeaderCell width={2}>
                            <Input
                              name="filter.serviceName"
                              value={filter.serviceName}
                              onChange={this.handleFilterInput}
                              onBlur={this.applyFilter}
                            />
                          </Table.HeaderCell>
                          <Table.HeaderCell width={2}>
                            <Input
                              name="filter.sourceName"
                              value={filter.sourceName}
                              onChange={this.handleFilterInput}
                              onBlur={this.applyFilter}
                            />
                          </Table.HeaderCell>
                          <Table.HeaderCell width={2}>
                            <Input
                              name="filter.topicName"
                              value={filter.topicName}
                              onChange={this.handleFilterInput}
                              onBlur={this.applyFilter}
                            />
                          </Table.HeaderCell>
                          <Table.HeaderCell width={2}>
                            <Input
                              name="filter.itemType"
                              value={filter.itemType}
                              onChange={this.handleFilterInput}
                              onBlur={this.applyFilter}
                            />
                          </Table.HeaderCell>
                          <Table.HeaderCell width={4} />
                          <Table.HeaderCell width={3} />
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        {totalCount <= 0 && (
                          <Table.Row>
                            <Table.Cell colSpan="7" className="ui center aligned segment">
                              No items to display
                            </Table.Cell>
                          </Table.Row>
                        )}
                        {totalCount > 0 &&
                          items.map(({ id, serviceName, sourceName, topic, itemType, itemContent, creationDate }) => (
                            <Table.Row key={id}>
                              <Table.Cell width={1}>{id}</Table.Cell>
                              <Table.Cell width={2}>{serviceName}</Table.Cell>
                              <Table.Cell width={2}>{sourceName}</Table.Cell>
                              <Table.Cell width={2}>{topic}</Table.Cell>
                              <Table.Cell width={2}>{itemType}</Table.Cell>
                              <Table.Cell width={4}>{itemContent}</Table.Cell>
                              <Table.Cell width={3}>{moment(creationDate).format(dateWithFullTimeFormat)}</Table.Cell>
                            </Table.Row>
                          ))}
                      </Table.Body>
                      <Table.Footer />
                    </Table>

                    <div className="ui center aligned basic segment">
                      <Pagination
                        boundaryRange={2}
                        // defaultActivePage={1}
                        activePage={activePage}
                        ellipsisItem={`...`}
                        size="mini"
                        firstItem={null}
                        lastItem={null}
                        siblingRange={1}
                        totalPages={Math.ceil(totalCount / pageSize)}
                        onPageChange={this.handlePaginationChange}
                      />
                    </div>
                  </React.Fragment>
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    shadowQueue: state.admin.shadowQueue
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...modalActions, ...adminActions }, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManageShadowQueuePage);
