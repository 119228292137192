import * as types from "./actionTypes";
import { beginAjaxCall, ajaxCallDone } from "./ajaxStatusActions";
import { processApiResponse, pushOrchestraItem, processThenThrowApiError } from "../helpers";
import { adalApiFetch } from "../adalConfig";
import { API_URL } from "../constants/environment";

export function clearNarxScores() {
  return {
    type: types.CLEAR_NARX_SCORES
  };
}

export function setNarxScores(narxScores) {
  return {
    type: types.SET_NARX_SCORES,
    context: "patient",
    patientId: narxScores.patientId,
    narxScores
  };
}

export function queryNarxScores(patientId) {
  return (dispatch) => {
    dispatch(beginAjaxCall("queryNarxScores"));
    return adalApiFetch(`${API_URL}/Patients/${patientId}/NarxScores`, { method: "POST" })
      .then((response) => processApiResponse(response.orchestrationId))
      .then((orchestrationId) => {
        pushOrchestraItem(
          orchestrationId,
          +new Date(),
          (payload) => {
            window.store.dispatch(setNarxScores({ error: true, errorMessage: payload.errorUserMessage }));
          },
          { patientId }
        );
      })
      .catch((error) => processThenThrowApiError(error, dispatch))
      .finally(() => dispatch(ajaxCallDone()));
  };
}
