import React, { Component } from "react";
import { Modal, Button, TextArea, Grid, Icon, Message } from "semantic-ui-react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import * as modalActions from "../../actions/modalActions";
import * as claimErrorsActions from "../../actions/claimErrorsActions";
import { dateWithFullTimeFormat } from "../../constants/miscellaneous";
import "./ClaimErrorDetailsModal.css";

export class ClaimErrorDetailsModal extends Component {
  state = {
    editMode: this.props.editMode || false,
    updatedSourceMessageContent: "",
    confirmDismiss: false,
    confirmRequeue: false
  };

  componentDidMount() {
    const {
      errorId,
      actions: { loadSelectedErrorDetails }
    } = this.props;
    loadSelectedErrorDetails(errorId).then(() => {
      const {
        error: { sourceMessageContent }
      } = this.props;
      if (this.state.editMode) {
        this.setState({
          updatedSourceMessageContent: sourceMessageContent
        });
      }
    });
  }

  componentWillUnmount() {
    this.props.actions.clearClaimError();
  }

  handleCancel = () => this.props.actions.hideModal();

  handleEdit = () => {
    const { editMode } = this.state;
    const {
      error: { sourceMessageContent }
    } = this.props;
    this.setState({
      editMode: !editMode,
      updatedSourceMessageContent: !editMode ? sourceMessageContent : ""
    });
  };

  handleupdateSourceMessageContent = (e, data) => {
    this.setState({ updatedSourceMessageContent: data.value });
  };

  handleDismiss = () => {
    const { confirmDismiss } = this.state;
    if (confirmDismiss) {
      const {
        errorId,
        athenaServiceErrorsOnly,
        actions: { loadClaimErrors, dismissClaimError },
        adminPageState: { pageSize, pageNumber, filters, orderBySourceHandler }
      } = this.props;
      dismissClaimError(errorId).then(() => {
        loadClaimErrors(pageSize, pageNumber, athenaServiceErrorsOnly, filters, orderBySourceHandler);
        this.handleCancel();
      });
    } else {
      this.setState({ confirmDismiss: !confirmDismiss });
    }
  };

  handleDismissMouseOut = () => {
    this.setState({ confirmDismiss: false });
  };

  handleRequeue = () => {
    const { updatedSourceMessageContent, confirmRequeue } = this.state;
    if (confirmRequeue) {
      const {
        errorId,
        athenaServiceErrorsOnly,
        actions: { requeueClaimError, loadClaimErrors },
        adminPageState: { pageSize, pageNumber, filters, orderBySourceHandler }
      } = this.props;
      requeueClaimError(errorId, updatedSourceMessageContent).then(() => {
        loadClaimErrors(pageSize, pageNumber, athenaServiceErrorsOnly, filters, orderBySourceHandler, true);
        this.handleCancel();
      });
    } else {
      this.setState({ confirmRequeue: !confirmRequeue });
    }
  };

  handleRequeueMouseOut = () => {
    this.setState({ confirmRequeue: false });
  };

  render() {
    const { editMode, updatedSourceMessageContent, confirmDismiss, confirmRequeue } = this.state;
    const {
      open,
      processing,
      error: {
        firstName,
        lastName,
        mrn,
        visitStatus,
        visitType,
        proceduresAndDiagnoses,
        patientLevel,
        centerName,
        providerName,
        id,
        sourceHandler,
        errorType,
        errorSource,
        errorMessage,
        errorUserMessage,
        appointmentExternalId,
        visitDate,
        signedOnDate,
        labName,
        stackTrace,
        sourceMessageType,
        sourceMessageContent,
        orchestrationId,
        identityContext,
        platformEventId,
        timeStamp,
        serviceName,
        labOrderId,
        username
      }
    } = this.props;

    return (
      <Modal open={open} size="large" className="error-details-modal" closeIcon onClose={this.handleCancel}>
        <Modal.Header>{`Claim Error Details`}</Modal.Header>
        <Modal.Content className="error-details-modal-content">
          <Grid>
            <Grid.Row>
              <Grid.Column width={4}>
                <strong>Patient Name</strong>
                <span>
                  {firstName} {lastName}
                </span>
              </Grid.Column>

              <Grid.Column width={4}>
                <strong>MRN</strong>
                <span>{mrn}</span>
              </Grid.Column>

              <Grid.Column width={4}>
                <strong>Visit Status</strong>
                <span>{visitStatus}</span>
              </Grid.Column>

              <Grid.Column width={4}>
                <strong>Visit Type</strong>
                <span>{visitType}</span>
              </Grid.Column>

              <Grid.Column width={4}>
                <strong>Provider Name</strong>
                <span>{providerName}</span>
              </Grid.Column>

              <Grid.Column width={4}>
                <strong>Center Name</strong>
                <span>{centerName}</span>
              </Grid.Column>

              <Grid.Column width={4}>
                <strong>Patient Level</strong>
                <span>{patientLevel}</span>
              </Grid.Column>

              <Grid.Column width={4}>
                <strong>Procedures And Diagnoses</strong>
                <span>{proceduresAndDiagnoses}</span>
              </Grid.Column>

              <Grid.Column width={4}>
                <strong>Appointment External Id</strong>
                <span>{appointmentExternalId}</span>
              </Grid.Column>

              <Grid.Column width={4}>
                <strong>signedOnDate</strong>
                <span>{signedOnDate}</span>
              </Grid.Column>

              <Grid.Column width={4}>
                <strong>LabName</strong>
                <span>{labName}</span>
              </Grid.Column>
              <Grid.Column width={4}>
                <strong>Lab Order Id</strong>
                <span>{labOrderId}</span>
              </Grid.Column>

              <Grid.Column width={4}>
                <strong>VisitDate</strong>
                <span>{moment(visitDate).format(dateWithFullTimeFormat)}</span>
              </Grid.Column>

              <Grid.Column width={4}>
                <strong>ErrorId</strong>
                <span>{id}</span>
              </Grid.Column>

              <Grid.Column width={4}>
                <strong>UserName</strong>
                <span>{username}</span>
              </Grid.Column>

              <Grid.Column width={4}>
                <strong>ServiceName</strong>
                <span>{serviceName}</span>
              </Grid.Column>

              <Grid.Column width={4}>
                <strong>SourceHandler</strong>
                <span>{sourceHandler}</span>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column width={4}>
                <strong>ErrorType</strong>
                <span>{errorType}</span>
              </Grid.Column>

              <Grid.Column width={4}>
                <strong>ErrorSource</strong>
                <span>{errorSource}</span>
              </Grid.Column>
              <Grid.Column width={8}>
                <strong>OrchestrationId</strong>
                <span>{orchestrationId}</span>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column width={16}>
                <strong>ErrorMessage</strong>
                <span>{errorMessage}</span>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column width={16}>
                <strong>ErrorUserMessage</strong>
                <span>{errorUserMessage}</span>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column width={16}>
                <strong>StackTrace</strong>
                <span>{stackTrace}</span>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column width={16}>
                <strong>SourceMessageType</strong>
                <span>{sourceMessageType}</span>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={16}>
                <div style={{ minHeight: "30px" }}>
                  <strong style={{ display: "inline" }}>SourceMessageContent</strong>
                  {
                    <React.Fragment>
                      <Icon name="edit" color="blue" onClick={this.handleEdit} style={{ margin: "2px 10px" }} />
                      {editMode && (
                        <Message warning style={{ display: "inline", padding: "5px 10px" }}>
                          <Icon name="warning" />
                          You will need to get approval before saving edits
                        </Message>
                      )}
                    </React.Fragment>
                  }
                </div>
                <span>
                  {editMode && (
                    <TextArea
                      name="sourceMessageContent"
                      style={{ width: "100%" }}
                      rows={8}
                      value={updatedSourceMessageContent}
                      onChange={this.handleupdateSourceMessageContent}
                    />
                  )}
                  {!editMode && sourceMessageContent}
                </span>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column width={5}>
                <strong>IdentityContext</strong>
                <span>{identityContext}</span>
              </Grid.Column>
              <Grid.Column width={5}>
                <strong>PlatformEventId</strong>
                <span>{platformEventId}</span>
              </Grid.Column>
              <Grid.Column width={6}>
                <strong>TimeStamp</strong>
                <span>{timeStamp}</span>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Modal.Content>
        <Modal.Actions>
          <Button
            onClick={this.handleCancel}
            content="Close"
            id="btn-close"
            loading={processing}
            disabled={processing}
          />
          <Button
            color={confirmDismiss ? `red` : `blue`}
            onClick={this.handleDismiss}
            content={`${confirmDismiss ? `Confirm ` : ``}Dismiss`}
            onMouseLeave={this.handleDismissMouseOut}
            icon="close"
            loading={processing}
            disabled={processing}
          />
          {
            <React.Fragment>
              <Button
                color={confirmRequeue ? `red` : `blue`}
                onClick={this.handleRequeue}
                content={`${confirmRequeue ? `Confirm` : ``}Re-queue`}
                onMouseLeave={this.handleRequeueMouseOut}
                icon="undo"
                loading={processing}
                disabled={processing}
              />
            </React.Fragment>
          }
        </Modal.Actions>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    error: state.claimErrors.selectedClaimDetails,
    processing: state.ajaxCallsInProgress > 0
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...modalActions,
        ...claimErrorsActions
      },
      dispatch
    )
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ClaimErrorDetailsModal);
