import React, { Component } from "react";
import { Button, Header, Modal, Menu, Segment, Checkbox } from "semantic-ui-react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import groupBy from "lodash/groupBy";
import moment from "moment";
import * as modalActions from "../../../../actions/modalActions";
import * as lookupActions from "../../../../actions/lookupActions";
import * as insuranceActions from "../../../../actions/insuranceActions";
import { getLookupText, checkRoles } from "../../../../helpers";
import { roleGroups } from "../../../../constants/securityRoles";
import UDSPriorAuthForm from "./UDSPriorAuthForm";
import MedicationPriorAuthForm from "./MedicationPriorAuthForm";
import PriorAuthsTable from "./PriorAuthsTable";
import "./PriorAuths.css";

export class PriorAuthsModal extends Component {
  state = {
    activeTab: "UDS Prior Auths",
    statusVisible: "Active"
  };

  componentDidMount() {
    const { patientId } = this.props;
    if (patientId) {
      this.props.actions.loadInsuranceProviders();
      this.props.actions.loadPcpOptions();
    }
  }

  handleTabClick = (e, { name }) => this.setState({ activeTab: name });

  handleUdsSave = uds => {
    const { patientId } = this.props;
    this.props.actions.createUDSPriorAuth(patientId, uds);
  };

  handleMedicationSave = medication => {
    const { patientId } = this.props;
    this.props.actions.createMedicationPriorAuth(patientId, medication);
  };

  handleClose = () => this.props.actions.hideModal();

  handleToggle = (e, data) => {
    const { checked } = data;
    if (checked) {
      this.setState({ statusVisible: "Inactive" });
    } else {
      this.setState({ statusVisible: "Active" });
    }
  };

  renderActiveTab = activeTab => {
    const { insuranceProviders, pcpOptions, authRoles } = this.props;
    const enableAddAuth = checkRoles(roleGroups.excludeHelpDesk, authRoles);
    if (activeTab === "UDS Prior Auths") {
      return (
        <UDSPriorAuthForm
          insuranceProviders={insuranceProviders}
          handleUdsSave={this.handleUdsSave}
          enableAddAuth={enableAddAuth}
        />
      );
    }
    return (
      <MedicationPriorAuthForm
        insuranceProviders={insuranceProviders}
        pcpOptions={pcpOptions}
        handleMedicationSave={this.handleMedicationSave}
        enableAddAuth={enableAddAuth}
      />
    );
  };

  render() {
    const { open, separatedUds, separatedMedications, insuranceProviders } = this.props;
    const { activeTab, statusVisible } = this.state;

    return (
      <Modal className="prior-auths-modal" open={open} onClose={this.handleClose} closeIcon size="large">
        <Header content="Prior Auths" />
        <Modal.Content>
          <Menu attached="top" tabular>
            <Menu.Item
              name="UDS Prior Auths"
              active={activeTab === "UDS Prior Auths"}
              onClick={this.handleTabClick}
              id="btn-udsPriorAuths"
            />
            <Menu.Item
              name="Medication Prior Auths"
              active={activeTab === "Medication Prior Auths"}
              onClick={this.handleTabClick}
              id="btn-medicationPriorAuths"
            />
          </Menu>
          <Segment attached="bottom">{this.renderActiveTab(activeTab)}</Segment>
          <div className="view-auths-container">
            <Checkbox toggle label="View Inactive" onClick={this.handleToggle} />
            <PriorAuthsTable
              statusVisible={statusVisible}
              tableType="UDS"
              insuranceInformation={statusVisible === "Active" ? separatedUds.active : separatedUds.inactive}
              getLookupText={getLookupText}
              insuranceProviders={insuranceProviders}
            />
            <PriorAuthsTable
              statusVisible={statusVisible}
              tableType="Medication"
              insuranceInformation={
                statusVisible === "Active" ? separatedMedications.active : separatedMedications.inactive
              }
              getLookupText={getLookupText}
              insuranceProviders={insuranceProviders}
            />
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.handleClose} id="btn-cancel">
            Cancel
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  const udsPriorAuthorizations = state.insurance.udsPriorAuthorizations.map(priorAuth => {
    if (
      priorAuth.numberOfAuthorizations > 0 &&
      (moment().toDate() < moment(priorAuth.endDate).toDate() || !priorAuth.endDate)
    ) {
      // eslint-disable-next-line no-param-reassign
      priorAuth.status = "active";
    } else {
      // eslint-disable-next-line no-param-reassign
      priorAuth.status = "inactive";
    }
    return priorAuth;
  });

  const separatedUds = groupBy(udsPriorAuthorizations, priorAuth => priorAuth.status);

  const medicationPriorAuthorizations = state.insurance.medicationPriorAuthorizations.map(priorAuth => {
    if (moment().toDate() < moment(priorAuth.endDate).toDate()) {
      // eslint-disable-next-line no-param-reassign
      priorAuth.status = "active";
    } else {
      // eslint-disable-next-line no-param-reassign
      priorAuth.status = "inactive";
    }
    return priorAuth;
  });

  const separatedMedications = groupBy(medicationPriorAuthorizations, priorAuth => priorAuth.status);

  return {
    patientId: state.patient.currentPatient.patientId,
    separatedUds,
    separatedMedications,
    insuranceProviders: state.lookups.insuranceProviders || [],
    pcpOptions: state.lookups.pcpOptions || [],
    authRoles: state.auth.user.profile.roles
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...modalActions, ...lookupActions, ...insuranceActions }, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PriorAuthsModal);
