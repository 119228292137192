import * as types from "./actionTypes";
import { adalApiFetch } from "../adalConfig";
import { API_URL } from "../constants/environment";
import { beginAjaxCall, ajaxCallDone, ajaxCallError } from "./ajaxStatusActions";
import { processApiError } from "../helpers";

export function setShadowQueue(shadowQueue) {
  return {
    type: types.SET_SHADOW_QUEUE,
    shadowQueue
  };
}

export function setProxyRequests(proxyRequests) {
  return {
    type: types.SET_PROXY_REQUESTS,
    proxyRequests
  };
}

export function pushItemToBreadcrumb(item) {
  return {
    type: types.PUSH_ITEM_TO_BREADCRUMB,
    item
  };
}

export function loadShadowQueue(pageSize = 10, pageNumber = 1, filter = {}) {
  return dispatch => {
    dispatch(beginAjaxCall("loadShadowQueue"));
    const params = {
      pageSize,
      pageNumber,
      itemId: filter.itemId,
      serviceName: filter.serviceName,
      sourceName: filter.sourceName,
      topicName: filter.topicName,
      itemType: filter.itemType,
      fromDay: filter.from.day,
      fromMonth: filter.from.month + 1,
      fromYear: filter.from.year,
      toDay: filter.to.day,
      toMonth: filter.to.month + 1,
      toYear: filter.to.year
    };
    const urlParams = Object.keys(params)
      .map(k => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
      .join("&");
    return adalApiFetch(`${API_URL}/Admin/ShadowQueue?${urlParams}`)
      .then(data => {
        if (data.message && data.message === "Validation Failed") {
          /* response status 422 */
          processApiError(data.errors[0].message).then(dispatch(ajaxCallError(data.errors[0].message)));
          return;
        }
        dispatch(setShadowQueue(data));
      })

      .catch(error => {
        processApiError(error, dispatch);
      })
      .finally(() => {
        dispatch(ajaxCallDone());
      });
  };
}

export function loadProxyRequests(pageSize = 10, pageNumber = 1, filter = {}) {
  return dispatch => {
    dispatch(beginAjaxCall("loadProxyRequests"));
    const params = {
      pageSize,
      pageNumber,
      itemId: filter.itemId,
      sourceName: filter.sourceName,
      processingPhase: filter.processingPhase,
      requestId: filter.requestId,
      requestMethod: filter.requestMethod,
      requestPath: filter.requestPath,
      retryNumber: filter.retryNumber,
      responseCode: filter.responseCode,
      fromDay: filter.from.day,
      fromMonth: filter.from.month + 1,
      fromYear: filter.from.year,
      toDay: filter.to.day,
      toMonth: filter.to.month + 1,
      toYear: filter.to.year
    };
    const urlParams = Object.keys(params)
      .map(k => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
      .join("&");
    return adalApiFetch(`${API_URL}/Admin/ProxyRequests?${urlParams}`)
      .then(data => {
        if (data.message && data.message === "Validation Failed") {
          /* response status 422 */
          processApiError(data.errors[0].message).then(dispatch(ajaxCallError(data.errors[0].message)));
          return;
        }
        dispatch(setProxyRequests(data));
      })

      .catch(error => {
        processApiError(error, dispatch);
      })
      .finally(() => {
        dispatch(ajaxCallDone());
      });
  };
}
