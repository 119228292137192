import React, { Component } from "react";
import { Button, Header, Modal, Form, Popup } from "semantic-ui-react";
import { ToggleButton, ToggleButtonGroup } from "react-bootstrap";
import MaskedInput from "react-text-mask";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Icon } from "../../../../ui";
import * as modalActions from "../../../../actions/modalActions";
import * as demographicActions from "../../../../actions/demographicActions";
import referenceValues from "../../../../api/mockReferenceValues";
import { phoneMask } from "../../../../constants/validation";
import "./EditPhoneNumberModal.css";
import "../../../reusable/Modal.css";

const getPhone = (props) =>
  props.currentPhone || {
    type: "",
    phoneNumber: "",
    phoneNumberId: "",
    extension: "",
    consentToCall: false, // call
    consentToLeaveMessages: false, // voice message
    textAppointmentReminder: false, // consent to text message
    isPreferred: false
  };

export class EditPhoneNumberModal extends Component {
  state = {
    phone: getPhone(this.props),
    errors: {},
    confirmationOpen: false
  };

  handleMaskedInput = (e) => {
    const { name: field, value } = e.target;
    const phone = Object.assign({}, this.state.phone, { [field]: value });
    this.setState({
      phone
    });
  };

  handleInput = (e, data) => {
    const { name: field, value } = data;
    const phone = Object.assign({}, this.state.phone, { [field]: value });
    this.setState({
      phone
    });
  };

  handleConsentToCall = (e) => {
    const phone = { ...this.state.phone, consentToCall: e === "null" ? null : e };
    this.setState({ phone });
  };

  handleConsentToLeaveMessages = (e) => {
    const phone = { ...this.state.phone, consentToLeaveMessages: e === "null" ? null : e };
    this.setState({ phone });
  };

  handleTextAppointmentReminder = (e) => {
    const phone = { ...this.state.phone, textAppointmentReminder: e === "null" ? null : e };
    this.setState({ phone });
  };

  handleSave = () => {
    const { patientId } = this.props;
    const patientPhone = { ...this.state.phone, phoneNumber: this.state.phone.phoneNumber.replace(/\D/g, "") };
    if (patientPhone.phoneNumberId) {
      this.props.actions.updatePhone(patientId, patientPhone).then(this.handleClose);
    } else {
      this.props.actions.createPhone(patientId, patientPhone).then(this.handleClose);
    }
  };

  handleDelete = () => {
    const { patientId } = this.props;
    const patientPhone = Object.assign({}, this.state.phone);
    this.props.actions.deletePhone(patientId, patientPhone).then(this.handleClose);
  };

  handleCancel = () => {};

  handleClose = () => this.props.actions.hideModal();

  handleConfirmationOpen = () => {
    const { confirmationOpen } = this.state;
    this.setState({ confirmationOpen: !confirmationOpen });
  };

  handleCheckbox = (e, data) => {
    const { name: field, checked } = data;
    const phone = Object.assign({}, this.state.phone, { [field]: checked });
    this.setState({ phone });
  };

  render() {
    const { open, isEdit, processing } = this.props;
    const {
      phone: {
        type,
        phoneNumber,
        extension,
        consentToCall,
        consentToLeaveMessages,
        textAppointmentReminder,
        isPreferred
      },
      confirmationOpen
    } = this.state;
    const _consentToCall =
      consentToCall === null
        ? null
        : typeof consentToCall === "string"
        ? consentToCall.trim().toLowerCase() === "true"
        : consentToCall;
    const _consentToLeaveMessages =
      consentToLeaveMessages === null
        ? null
        : typeof consentToLeaveMessages === "string"
        ? consentToLeaveMessages.trim().toLowerCase() === "true"
        : consentToLeaveMessages;
    const _textAppointmentReminder =
      textAppointmentReminder === null
        ? null
        : typeof textAppointmentReminder === "string"
        ? textAppointmentReminder.trim().toLowerCase() === "true"
        : textAppointmentReminder;
    return (
      <Modal className="edit-phonenumber-modal" open={open} onClose={this.handleClose} closeIcon size="small">
        <Header content="Manage Phone Number" />
        <Modal.Content>
          <Form>
            <Form.Field>
              <Form.Checkbox
                className="isPreferredCheckbox"
                label="Primary Phone"
                name="isPreferred"
                checked={isPreferred}
                id="input-isPreferred"
                onChange={this.handleCheckbox}
              />
            </Form.Field>
            <Form.Group widths="equal">
              <Form.Field>
                <Form.Dropdown
                  fluid
                  selection
                  label="Phone Type"
                  name="type"
                  placeholder="Select..."
                  options={referenceValues.phonetypes}
                  onChange={this.handleInput}
                  value={type}
                  id="input-type"
                />
              </Form.Field>
            </Form.Group>
            <Form.Group>
              <Form.Field width={10}>
                <label htmlFor="input-phoneNumber">Phone Number</label>
                <MaskedInput
                  name="phoneNumber"
                  mask={phoneMask}
                  guide={false}
                  value={phoneNumber}
                  onChange={this.handleMaskedInput}
                  id="input-phoneNumber"
                />
              </Form.Field>
              {/* {type === "Work" || type === "Other" ? ( */}
              <Form.Field width={6}>
                <Form.Input
                  label="Extension"
                  name="extension"
                  value={extension}
                  onChange={this.handleInput}
                  id="input-extension"
                  disabled={type !== "Work" && type !== "Other"}
                />
              </Form.Field>
              {/* ) : null} */}
            </Form.Group>
            <Form.Group widths="equal" className="no-margin">
              <Form.Field className="no-padding">
                <Icon
                  name="telephone-fill"
                  className={`me-2 h5 text-${
                    _consentToCall === null ? `black-50` : _consentToCall ? `success` : `danger`
                  }`}
                />
                <ToggleButtonGroup
                  type="radio"
                  name="consentToCall"
                  value={_consentToCall === null ? "null" : _consentToCall === true ? "True" : "False"}
                  onChange={this.handleConsentToCall}
                >
                  <ToggleButton id="tbg-btn-1" variant="outline-secondary" value={"null"}>
                    {`No answer`}
                  </ToggleButton>
                  <ToggleButton id="tbg-btn-2" variant="outline-success" value={"True"} style={{ width: "180px" }}>
                    {`Ok to call`}
                  </ToggleButton>
                  <ToggleButton id="tbg-btn-3" variant="outline-danger" value={"False"} style={{ width: "180px" }}>
                    {`Don't call`}
                  </ToggleButton>
                </ToggleButtonGroup>
              </Form.Field>
            </Form.Group>
            <Form.Group widths="equal" className="no-margin">
              <Form.Field className="mt-2 no-padding">
                <Icon
                  name="voicemail"
                  className={`me-2 h5 text-${
                    _consentToLeaveMessages === null ? `black-50` : _consentToLeaveMessages ? `success` : `danger`
                  }`}
                />
                <ToggleButtonGroup
                  type="radio"
                  name="consentToLeaveMessages"
                  value={
                    _consentToLeaveMessages === null ? "null" : _consentToLeaveMessages === true ? "True" : "False"
                  }
                  onChange={this.handleConsentToLeaveMessages}
                >
                  <ToggleButton id="tbg-btn-11" variant="outline-secondary" value={"null"}>
                    {`No answer`}
                  </ToggleButton>
                  <ToggleButton id="tbg-btn-12" variant="outline-success" value={"True"} style={{ width: "180px" }}>
                    {`Ok to leave messages`}
                  </ToggleButton>
                  <ToggleButton id="tbg-btn-13" variant="outline-danger" value={"False"} style={{ width: "180px" }}>
                    {`Don't leave messages`}
                  </ToggleButton>
                </ToggleButtonGroup>
              </Form.Field>
            </Form.Group>
            {(type === "Cell" || type === "SMS") && (
              <Form.Group widths="equal" className="no-margin">
                <Form.Field className="mt-2 no-padding">
                  <Icon
                    name="chat-left-text-fill"
                    className={`me-2 h5 text-${
                      _textAppointmentReminder === null ? `black-50` : _textAppointmentReminder ? `success` : `danger`
                    }`}
                  />
                  <ToggleButtonGroup
                    type="radio"
                    name="textAppointmentReminder"
                    value={
                      _textAppointmentReminder === null ? "null" : _textAppointmentReminder === true ? "True" : "False"
                    }
                    onChange={this.handleTextAppointmentReminder}
                  >
                    <ToggleButton id="tbg-btn-21" variant="outline-secondary" value={"null"} disabled>
                      {`No answer`}
                    </ToggleButton>
                    <ToggleButton id="tbg-btn-22" variant="outline-success" value={"True"} style={{ width: "180px" }}>
                      {`Ok to SMS`}
                    </ToggleButton>
                    <ToggleButton id="tbg-btn-23" variant="outline-danger" value={"False"} style={{ width: "180px" }}>
                      {`Don't SMS`}
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Form.Field>
              </Form.Group>
            )}
          </Form>
        </Modal.Content>
        <Modal.Actions>
          {isEdit ? (
            <Popup
              trigger={
                <Button color="red" floated="left" id="btn-delete">
                  Delete
                </Button>
              }
              content={
                <React.Fragment>
                  <Button color="grey" content="Cancel" onClick={this.handleConfirmationOpen} id="btn-confirmCancel" />
                  <Button
                    color="red"
                    content="Delete Phone Number"
                    onClick={this.handleDelete}
                    id="btn-confirmDelete"
                  />
                </React.Fragment>
              }
              on="click"
              position="top left"
              wide
              open={confirmationOpen}
              onOpen={this.handleConfirmationOpen}
            />
          ) : null}
          <Button onClick={this.handleClose} id="btn-cancel">
            Cancel
          </Button>
          <Button onClick={this.handleSave} loading={processing} color="blue" id="btn-save">
            Save
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    patientId: state.patient.currentPatient.patientId,
    processing: state.ajaxCallsInProgress > 0
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...modalActions, ...demographicActions }, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EditPhoneNumberModal);
