import React from "react";
import { Table, Icon, Button } from "semantic-ui-react";
import moment from "moment";
import map from "lodash/map";
import shortid from "shortid";

import { isoDateFormat, dateFormat, timeFormat, longDateFormat } from "../../../../constants/miscellaneous";

const MedicalHistoryTableRow = ({
  medicalHistory: { condition, startDate, endDate, isResolved, notes },
  handleEditMedicalHistory,
  index,
  showEditHistory,
  showDeleteHistory
}) => (
  <React.Fragment>
    <tr>
      <td data-label="Condition" className="medical-row-item-condition">
        <strong>{condition}</strong>
      </td>
      <td data-label="Start">
        <strong>
          {startDate &&
            moment(
              moment
                .utc(startDate)
                .startOf("day")
                .format(isoDateFormat)
            ).format(dateFormat)}
        </strong>
      </td>
      <td data-label="End">
        <strong>
          {endDate &&
            moment(
              moment
                .utc(endDate)
                .startOf("day")
                .format(isoDateFormat)
            ).format(dateFormat)}
        </strong>
      </td>
      <td data-label="Resolved">
        {isResolved ? <strong className="is-resolved">Yes</strong> : <strong className="not-resolved">No</strong>}
      </td>
      <td data-label="Note">
        {showEditHistory ? (
          <Button icon="plus" index={index} className="transparent-button-icon" onClick={handleEditMedicalHistory} />
        ) : (
          <span>&nbsp;</span>
        )}
      </td>
      <td data-label="&nbsp;">
        {notes && notes.length > 0 ? (
          <Icon name="sticky note outline" />
        ) : (
          <Icon className="opacity" name="sticky note outline" />
        )}
      </td>
      <td data-label="&nbsp;">
        {showEditHistory ? (
          <Button icon="edit" index={index} className="transparent-button-icon" onClick={handleEditMedicalHistory} />
        ) : (
          <span>&nbsp;</span>
        )}
      </td>
      <td data-label="&nbsp;">
        {showDeleteHistory ? (
          <Button
            icon="delete"
            index={index}
            className="transparent-button-icon delete"
            onClick={handleEditMedicalHistory}
          />
        ) : (
          <span>&nbsp;</span>
        )}
      </td>
    </tr>
    {notes
      ? map(notes, (note, i) => (
          <React.Fragment key={note.noteId || shortid.generate()}>
            <Table.Row className="tr-tritangle">
              {i === 0 && (
                <React.Fragment>
                  <Table.Cell className="first-tritangle" colSpan={5} />
                  <Table.Cell className="first-tritangle triangle-row-item">
                    <div className="note-background-first" />
                  </Table.Cell>
                  <Table.Cell className="first-tritangle" colSpan={2} />
                </React.Fragment>
              )}
            </Table.Row>
            <Table.Row className="note-background" key={i}>
              {note.noteAddedOn && (
                <Table.Cell colSpan="5" className="general-info1">
                  <strong>{moment(note.noteAddedOn).format(longDateFormat)} </strong>
                  <span>{moment(note.noteAddedOn).format(timeFormat)}</span>
                </Table.Cell>
              )}
              <Table.Cell colSpan="4" className="general-info2">
                {note.creatorFullName}
              </Table.Cell>
            </Table.Row>
            <Table.Row className="note-background">
              <Table.Cell colSpan="9">
                <p>{note.text}</p>
              </Table.Cell>
            </Table.Row>
          </React.Fragment>
        ))
      : null}
  </React.Fragment>
);

export default MedicalHistoryTableRow;
