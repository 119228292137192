import React, { useRef, useEffect } from "react";
import { login } from "../actions/userActions";

export default function Login() {
  const mounted = useRef();
  useEffect(() => {
    if (!mounted.current) {
      login().then(response => {
        if (response && response.UserId);
        window.location = "/";
      });
    }
  });

  return (
    <div className="zontainer">
      <span className="zhild ui zimmer">Logging in . .</span>
    </div>
  );
}
