import React from "react";
import { Message } from "semantic-ui-react";

const ModalLoadingMessage = ({ style, ...rest }) => (
  <Message info {...rest} style={{ ...style, margin: "15px", padding: "15x" }}>
    {`... Loading`}
  </Message>
);

export default ModalLoadingMessage;
