import React from "react";

import { Form, Icon, Radio, Grid } from "semantic-ui-react";
import MaskedInput from "react-text-mask";
import { zipMask } from "../../../../constants/validation";

import "./EditAddressForm.css";

const EditAddressForm = ({
  address,
  address: { addressType, isBilling, isResidence },
  states,
  handleInput,
  handleMaskedInput,
  handleCheckbox,
  handleFocus,
  errors,
  handleRadioButton
}) => {
  let homelessUnknown = (
    <React.Fragment>
      <Grid>
        <Grid.Column width="1" className="radio-button">
          <Form.Field>
            <Radio value="Homeless" checked={addressType === "Homeless"} disabled />
          </Form.Field>
        </Grid.Column>
        <Grid.Column width="15" className="form-label">
          Homeless
        </Grid.Column>
      </Grid>
      <Grid>
        <Grid.Column width="1" className="radio-button">
          <Form.Field>
            <Radio value="Unknown" checked={addressType === "Unknown"} disabled />
          </Form.Field>
        </Grid.Column>
        <Grid.Column width="15" className="form-label">
          Unknown
        </Grid.Column>
      </Grid>
    </React.Fragment>
  );
  if (isResidence) {
    homelessUnknown = (
      <React.Fragment>
        <Grid>
          <Grid.Column width="1" className="radio-button">
            <Form.Field>
              <Radio value="Homeless" checked={addressType === "Homeless"} onChange={handleRadioButton} />
            </Form.Field>
          </Grid.Column>
          <Grid.Column width="15" className="form-label">
            Homeless
          </Grid.Column>
        </Grid>
        <Grid>
          <Grid.Column width="1" className="radio-button">
            <Form.Field>
              <Radio value="Unknown" checked={addressType === "Unknown"} onChange={handleRadioButton} />
            </Form.Field>
          </Grid.Column>
          <Grid.Column width="15" className="form-label">
            Unknown
          </Grid.Column>
        </Grid>
      </React.Fragment>
    );
  }

  if (isBilling) {
    homelessUnknown = (
      <React.Fragment>
        <Grid>
          <Grid.Column width="1" className="radio-button">
            <Form.Field>
              <Radio value="Homeless" checked={addressType === "Homeless"} disabled />
            </Form.Field>
          </Grid.Column>
          <Grid.Column width="15" className="form-label">
            Homeless
          </Grid.Column>
        </Grid>
        <Grid>
          <Grid.Column width="1" className="radio-button">
            <Form.Field>
              <Radio value="Unknown" checked={addressType === "Unknown"} disabled />
            </Form.Field>
          </Grid.Column>
          <Grid.Column width="15" className="form-label">
            Unknown
          </Grid.Column>
        </Grid>
      </React.Fragment>
    );
  }
  return (
    <Form>
      <Form.Group widths="3" className="address-icon-row">
        <Icon name="credit card" size="large" color="grey" />
        <Form.Field>
          <Form.Checkbox
            label="Billing Address"
            name="isBilling"
            checked={address.isBilling}
            onChange={handleCheckbox}
            id="input-isBilling"
          />
        </Form.Field>
        <Icon name="bed" size="large" color="grey" />
        <Form.Field>
          <Form.Checkbox
            label="Currently Staying"
            name="isResidence"
            checked={address.isResidence}
            onChange={handleCheckbox}
            id="input-isResidence"
          />
        </Form.Field>
      </Form.Group>

      {address.addressType !== "Home" ? (
        <Grid>
          <Grid.Column width="1" className="radio-button">
            <Form.Field>
              <Radio value="Home" checked={addressType === "Home"} onChange={handleRadioButton} />
            </Form.Field>
          </Grid.Column>
          <Grid.Column width="15" className="form-input">
            <Form.Group grouped>
              <Form.Field>
                <Form.Input
                  fluid
                  required
                  label="Street Address"
                  name="address1"
                  maxLength={255}
                  value={address.address1}
                  onChange={handleInput}
                  id="input-address1"
                  disabled
                />
              </Form.Field>
              <Form.Field>
                <Form.Input
                  fluid
                  label="Street Address 2"
                  name="address2"
                  placeholder="Optional"
                  maxLength={255}
                  value={address.address2}
                  onChange={handleInput}
                  id="input-address2"
                  disabled
                />
              </Form.Field>
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Field>
                <Form.Input
                  fluid
                  required
                  label="City"
                  name="city"
                  maxLength={50}
                  value={address.city}
                  onChange={handleInput}
                  id="input-city"
                  disabled
                />
              </Form.Field>
              <Form.Field>
                <Form.Dropdown
                  fluid
                  label="State"
                  required
                  search
                  selection
                  name="state"
                  placeholder="Select..."
                  value={address.state}
                  options={states}
                  onChange={handleInput}
                  id="input-state"
                  onFocus={handleFocus}
                  disabled
                />
              </Form.Field>
              <Form.Field
                className={
                  Object.prototype.hasOwnProperty.call(errors, "zip")
                    ? "error required field disabled"
                    : "required field disabled"
                }
              >
                <label htmlFor="input-zip">Zip</label>
                <MaskedInput
                  className="disabled"
                  name="zip"
                  required
                  mask={zipMask}
                  guide={false}
                  value={address.zip}
                  onChange={handleMaskedInput}
                  id="input-zip"
                  disabled
                />
              </Form.Field>
            </Form.Group>
          </Grid.Column>
        </Grid>
      ) : (
        <Grid>
          <Grid.Column width="1" className="radio-button">
            <Form.Field>
              <Radio value="Home" checked={addressType === "Home"} onChange={handleRadioButton} />
            </Form.Field>
          </Grid.Column>
          <Grid.Column width="15" className="form-input">
            <Form.Group grouped>
              <Form.Field>
                <Form.Input
                  fluid
                  required
                  label="Street Address"
                  name="address1"
                  maxLength={255}
                  value={address.address1}
                  onChange={handleInput}
                  id="input-address1"
                />
              </Form.Field>
              <Form.Field>
                <Form.Input
                  fluid
                  label="Street Address 2"
                  name="address2"
                  placeholder="Optional"
                  maxLength={255}
                  value={address.address2 || ""}
                  onChange={handleInput}
                  id="input-address2"
                />
              </Form.Field>
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Field>
                <Form.Input
                  fluid
                  required
                  label="City"
                  name="city"
                  maxLength={50}
                  value={address.city}
                  onChange={handleInput}
                  id="input-city"
                />
              </Form.Field>
              <Form.Field>
                <Form.Dropdown
                  fluid
                  label="State"
                  required
                  search
                  selection
                  name="state"
                  placeholder="Select..."
                  value={address.state}
                  options={states}
                  onChange={handleInput}
                  id="input-state"
                  onFocus={handleFocus}
                />
              </Form.Field>
              <Form.Field
                className={
                  Object.prototype.hasOwnProperty.call(errors, "zip") ? "error required field" : "required field"
                }
              >
                <label htmlFor="input-zip">Zip</label>
                <MaskedInput
                  name="zip"
                  required
                  mask={zipMask}
                  guide={false}
                  value={address.zip}
                  onChange={handleMaskedInput}
                  id="input-zip"
                />
              </Form.Field>
            </Form.Group>
          </Grid.Column>
        </Grid>
      )}
      {homelessUnknown}
    </Form>
  );
};

export default EditAddressForm;
