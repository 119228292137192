import React from "react";
import { Button, Input } from "semantic-ui-react";
import shortid from "shortid";
import FamilyHistorySliderRow from "./FamilyHistorySliderRow";

import "../SliderPanel.css";

const FamilyHistorySlider = ({
  conditions = [],
  sliderVisible,
  sliderClose,
  handleSearchEnter,
  handleSearchInput,
  handleSearchClick,
  handleQuickSearch
}) => (
  <div className={`slider-panel ${sliderVisible ? "visible" : ""}`}>
    <Button icon="arrow right" onClick={sliderClose} className="slider-close transparent-button-icon" />
    <div className="search-container">
      <Input
        placeholder="Conditions Search"
        onKeyPress={handleSearchEnter}
        onChange={handleSearchInput}
        icon={{
          name: "search",
          link: true,
          onClick: handleSearchClick
        }}
        id="input-search-family-history"
      />
    </div>
    <div className="search-results-container">
      <h3>common</h3>
      <div>
        {conditions.map(historyMedicalItem => (
          <FamilyHistorySliderRow
            key={shortid.generate()}
            handleQuickSearch={handleQuickSearch}
            {...historyMedicalItem}
          />
        ))}
      </div>
    </div>
  </div>
);

export default FamilyHistorySlider;
