import React, { Component } from "react";
import { Grid, Segment, Image, Icon } from "semantic-ui-react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import moment from "moment";
import * as modalActions from "../../../actions/modalActions";
import * as visitActions from "../../../actions/visitActions";
import * as chartsActions from "../../../actions/chartsActions";
import { extendedApiSlice } from "../labs/labsSlice";
import cal from "../../../assets/patients/cal.svg";
// import steth from "../../../assets/patients/Stethoscope_font_awesome.svg";
import Timeline from "./Timeline";
import VisitSummaryView from "./VisitSummaryView";
import Timer from "../../reusable/Timer";
import { dateWithTimeFormat, dateWithFullTimeFormat } from "../../../constants/miscellaneous";
import "./VisitsPage.css";
import emrComponent from "../../common/emrComponent";

export class VisitsPage extends Component {
  state = {
    visitsToLoad: 10,
    totalVisits: 0,
    view: "TIMELINE",
    summaryVisitId: null,
    amendmentContent: "",
    isLoading: false,
    listVisitSelected: []
  };

  unsubscribe = null;

  componentDidMount() {
    this._isMounted = true;
    const {
      patientId,
      actions: { updateListVisitSummaryToPrint }
    } = this.props;
    if (patientId) {
      this.loadAllVisits().then(() => {
        const { contextVisitId } = this.props;
        if (contextVisitId) {
          this.addListVisitSummaryToPrint(contextVisitId);
          updateListVisitSummaryToPrint([contextVisitId]);
        }
        this.setState({ view: contextVisitId ? "VISIT_SUMMARY" : "TIMELINE", summaryVisitId: contextVisitId });
      });
      const { unsubscribe } = this.props.actions.getReviewedLabResultsVisits({ patientId });
      this.unsubscribe = unsubscribe;
    }
  }

  componentDidUpdate(prevProps) {
    const {
      patientId,
      actions: { updateListVisitSummaryToPrint }
    } = this.props;
    if (patientId && (patientId !== prevProps.patientId || this.props.contextVisitId !== prevProps.contextVisitId)) {
      this.loadAllVisits().then(() => {
        const { contextVisitId } = this.props;
        if (contextVisitId) {
          this.addListVisitSummaryToPrint(contextVisitId);
          updateListVisitSummaryToPrint([contextVisitId]);
        }
        if (this._isMounted)
          this.setState({ view: contextVisitId ? "VISIT_SUMMARY" : "TIMELINE", summaryVisitId: contextVisitId });
      });
    } else if (this.props.contextVisitId && this.props.contextVisitId !== prevProps.contextVisitId) {
      const { contextVisitId } = this.props;
      this.addListVisitSummaryToPrint(contextVisitId);
      updateListVisitSummaryToPrint([contextVisitId]);

      if (this._isMounted) {
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({ view: contextVisitId ? "VISIT_SUMMARY" : "TIMELINE", summaryVisitId: contextVisitId });
      }
    }
    if (patientId && patientId !== prevProps.patientId) {
      if (typeof this.unsubscribe === "function") this.unsubscribe();
      const { unsubscribe } = this.props.actions.getReviewedLabResultsVisits({ patientId });
      this.unsubscribe = unsubscribe;
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    if (typeof this.unsubscribe === "function") this.unsubscribe();
  }

  _isMounted = false;

  loadAllVisits = () => {
    const { visitsToLoad } = this.state;
    const { patientId } = this.props;
    return this.props.actions.loadPatientVisits(patientId, false, visitsToLoad).then((results) => {
      if (this._isMounted && results && !results.patientVisits.loading)
        this.setState({
          visitsToLoad: results.patientVisits.totalNumberOfVisits,
          totalVisits: results.patientVisits.totalNumberOfVisits
        });
    });
  };

  backToTableView = () => {
    this.setState({ view: "TIMELINE", summaryVisitId: null });
  };

  toggleTableView = (visitId) => {
    this.setState(
      {
        view: this.state.view === "TIMELINE" ? "VISIT_SUMMARY" : "TIMELINE",
        summaryVisitId: this.state.view === "TIMELINE" ? visitId : null
      },
      () => {
        if (this.state.view === "VISIT_SUMMARY") this.goToVisit(visitId);
      }
    );
  };

  goToVisit = (visitId) => {
    this.addListVisitSummaryToPrint(visitId);
    this.setState({ view: "VISIT_SUMMARY", summaryVisitId: visitId });
  };

  updateVisitSummaryMenu = (visitId, e) => {
    if (e.shiftKey) {
      this.addListVisitSummaryToPrint(visitId);
    } else {
      this.clearListVisitSummaryToPrint().then(() => this.goToVisit(visitId));
    }
  };

  clearListVisitSummaryToPrint = () => Promise.resolve(this.props.actions.updateListVisitSummaryToPrint([]));

  addListVisitSummaryToPrint = (visitId) => {
    const { listVisitSummaryToPrint } = this.props;
    if (!listVisitSummaryToPrint.includes(visitId) && typeof visitId === "string") {
      const listUpdate = Object.assign([], listVisitSummaryToPrint);
      listUpdate.push(visitId);
      this.props.actions.updateListVisitSummaryToPrint(listUpdate);
    }
  };

  /**
   * Add amendment
   * amendment, visitId
   *  */
  handleAddAmendment = (e) => {
    e.preventDefault();
    const { amendmentContent, summaryVisitId } = this.state;
    const { patientId } = this.props;
    // check null or empty, when button is loading then return.
    if (amendmentContent.trim().length === 0 || this.state.isLoading) return;
    const body = {
      patientId,
      visitId: summaryVisitId,
      content: amendmentContent
    };
    // start loading
    this.setState({ isLoading: true });
    this.props.actions
      .createVisitAmendment(body)
      .then(() => {
        this.props.actions.loadVisitAmendment(summaryVisitId);
        // end loading and set value is empty
        this.setState({ isLoading: false, amendmentContent: "" });
      })
      .catch(() => {
        // end loading and set value is empty
        this.setState({ isLoading: false, amendmentContent: "" });
      });
  };

  clearAmendmentContentInput = () => {
    this.setState({ amendmentContent: "" });
  };

  handleInput = (e, data) => {
    const { value } = data;
    this.setState({ amendmentContent: value });
  };

  render() {
    const {
      patientVisits: { totalNumberOfEpsiodesOfCare, totalNumberOfVisits, numberOfDaysSinceLastVisit, visits = [] },
      latestVisit,
      visitAmendment,
      authRoles,
      listVisitSummaryToPrint
    } = this.props;
    const { view, summaryVisitId, isLoading, amendmentContent } = this.state;
    const lastVisitDate = visits.length ? visits[0].visitDateTime : moment();
    const currVisitStatusDate = latestVisit && latestVisit.visitStatus ? latestVisit.visitStatus.updatedOn : null;
    const summaryVisit = visits.find((v) => v.visitId === summaryVisitId);
    const summaryVisitType = summaryVisit ? summaryVisit.visitType : null;
    return (
      <div className="content-wrapper visit-content">
        <Grid doubling columns={5}>
          <Grid.Row>
            <Grid.Column>
              <Segment className="centeredSegment">
                <div className="sameLine">
                  <Image src={cal} size="mini" inline />
                </div>
                <div className="sameLine">
                  {visits.length ? (
                    <span>
                      <h2>{numberOfDaysSinceLastVisit} days</h2> Since Last Visit{" "}
                    </span>
                  ) : (
                    <span>
                      <h2>0</h2>Prior Visits
                    </span>
                  )}
                </div>
              </Segment>
            </Grid.Column>
            <Grid.Column>
              <Segment className="centeredSegment">
                <div className="sameLine">{/* <Image src={steth} size="mini" inline /> */}</div>
                <div className="sameLine">
                  <span>
                    <h2>{totalNumberOfEpsiodesOfCare || 0}</h2>{" "}
                    {totalNumberOfEpsiodesOfCare === 1 ? "Episode of Care" : "Episodes of Care"}
                  </span>
                </div>
              </Segment>
            </Grid.Column>
            <Grid.Column>
              <Segment className="centeredSegment">
                <div className="sameLine">
                  <span>
                    <h2>{totalNumberOfVisits || 0}</h2> {totalNumberOfVisits === 1 ? "Total Visit" : "Total Visits"}
                  </span>
                </div>
              </Segment>
            </Grid.Column>
            {latestVisit &&
              latestVisit.visitStatus &&
              !["visitcomplete", "visitcanceled"].includes(latestVisit.visitStatus.visitStatus) && (
                <Grid.Column>
                  <Segment className="centeredSegment">
                    <h2>
                      <Timer startTime={moment(lastVisitDate).format(dateWithTimeFormat)} />
                    </h2>{" "}
                    Current status:{" "}
                    <Timer className="inline" startTime={moment(currVisitStatusDate).format(dateWithTimeFormat)} />
                  </Segment>
                </Grid.Column>
              )}
            {latestVisit && !latestVisit.visitStatus && (
              <Grid.Column>
                <Segment className="centeredSegment">
                  <h2>
                    <Timer className="inline" startTime={moment.utc().local().format(dateWithFullTimeFormat)} />
                  </h2>{" "}
                  Current status:{" "}
                  <Timer className="inline" startTime={moment.utc().local().format(dateWithFullTimeFormat)} />
                </Segment>
              </Grid.Column>
            )}
            <Grid.Column>
              {visits && visits.length > 0 && (
                <Segment className="centeredSegment">
                  <a
                    role="button"
                    tabIndex={0}
                    onClick={() => this.toggleTableView(visits[0].visitId)}
                    onKeyDown={() => {}}
                  >
                    {view === "VISIT_SUMMARY" ? (
                      <div>
                        <h3 className="latest-summary">
                          <Icon name="file alternate outline" />
                          Latest Summary
                        </h3>
                      </div>
                    ) : (
                      <h3 className="latest-summary">
                        <Icon name="table" />
                        View Visits Table
                      </h3>
                    )}
                  </a>
                </Segment>
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>
        {view === "TIMELINE" ? (
          <Timeline goToVisit={this.goToVisit} />
        ) : (
          <VisitSummaryView
            visitId={summaryVisitId}
            visitType={summaryVisitType}
            updateVisitSummaryMenu={this.updateVisitSummaryMenu}
            handleAddAmendment={this.handleAddAmendment}
            handleInput={this.handleInput}
            visitAmendment={visitAmendment}
            authRoles={authRoles}
            isLoading={isLoading}
            amendmentContent={amendmentContent}
            latestVisit={latestVisit}
            listVisitSummaryToPrint={listVisitSummaryToPrint}
            backToTableView={this.backToTableView}
            clearListVisitSummaryToPrint={this.clearListVisitSummaryToPrint}
          />
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const patientVisits = state.visits.patientVisits || {};
  const contextVisit = state.visits.contextVisit;
  const latestVisit = state.visits.latestVisit;
  const visitAmendment = state.visits.visitAmendment;
  const authRoles = state.auth.user.profile.roles;
  const listVisitSummaryToPrint = state.visits.listVisitSummaryToPrint;

  return {
    patientId: state.patient.currentPatient.patientId,
    patientVisits,
    contextVisitId: contextVisit ? contextVisit.visitId : null,
    latestVisit,
    visitAmendment,
    authRoles,
    listVisitSummaryToPrint
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      ...bindActionCreators({ ...modalActions, ...visitActions, ...chartsActions }, dispatch),
      getReviewedLabResultsVisits: ({ patientId }) =>
        dispatch(extendedApiSlice.endpoints.getReviewedLabResultsVisits.initiate({ patientId }))
    }
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(emrComponent(VisitsPage)));
