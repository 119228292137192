import React from "react";
import { Badge as BsBadge } from "react-bootstrap";

const Badge = React.forwardRef(({ children, ...rest }, ref) => (
  <BsBadge {...rest} ref={ref}>
    {children}
  </BsBadge>
));

export default Badge;
