import React, { Component } from "react";
import moment from "moment";

class Timer extends Component {
  state = {
    timer: null
  };

  componentDidMount() {
    this.interval = setInterval(this.calculateTimeFromNow, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  calculateTimeFromNow = () => {
    const now = moment();
    const then = moment(this.props.startTime, "MM/DD/YYYY hh:mm a");
    const milliseconds = moment(now).diff(then);
    if (!milliseconds) return;
    const dur = moment.duration(milliseconds);
    const hours = Math.floor(dur.asHours());
    let minutes = Math.floor(dur.asMinutes());
    if (hours) {
      const roundedMinutes = hours * 60;
      minutes -= roundedMinutes;
    }
    this.setState({
      timer: `${hours}h ${minutes}m`
    });
  };

  render() {
    const { timer } = this.state;
    return <div className={this.props.className}>{timer}</div>;
  }
}

export default Timer;
