import React, { Component } from "react";
import { Button, Label, Modal, Table, Icon, List } from "semantic-ui-react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import shortid from "shortid";

import * as modalActions from "../../../actions/modalActions";
import * as historyActions from "../../../actions/patientHistoryActions";
import * as prescriptionActions from "../../../actions/prescriptionActions";

import "../../reusable/Modal.css";
import "../history/PatientHistorySearchModal.css";
import "./ScreeningMatchModal.css";
import "./AllergyMatchModal.css";

const closeAllModals = true;

export class AllergyMatchModal extends Component {
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.searchValue && !prevState.searchMedication) {
      return {
        searchMedication: nextProps.searchValue,
        searchedMedication: nextProps.searchValue
      };
    }
    return null;
  }

  state = {
    searchValue: ""
  };

  componentDidMount() {
    const {
      patientId,
      actions: { loadAllergyHist }
    } = this.props;

    if (patientId) {
      loadAllergyHist(patientId);
    }
  }

  handleClose = (_, __, closeAll = false) => {
    const {
      multiple,
      searchedMedication,
      actions: { hideModal }
    } = this.props;
    hideModal(multiple, closeAll, { searchValue: searchedMedication });
  };

  handleMedSearchModalOpen = () => {
    this.handleClose();
  };

  handleBackToSearchModal = () => {
    this.handleMedSearchModalOpen();
  };

  handleContinue = () => {
    const {
      patientId,
      selectedMedication,
      actions: { createPrescription }
    } = this.props;
    const { allergicReactions, contraindications, ...rest } = selectedMedication;

    createPrescription(patientId, rest).then(() => this.handleClose(null, null, closeAllModals));
  };

  render() {
    const { open, selectedMedication, isShowOnly, screeningResults, processing } = this.props;

    return (
      <Modal open={open} onClose={this.handleClose} closeIcon size="small">
        <Modal.Header>
          <Label className="allergy-match-alert-header" size="massive" circular>
            <Icon name="dont" color="red" />
            Allergy Alert for {selectedMedication.drugName}
          </Label>
        </Modal.Header>
        <Modal.Content>
          <Table columns={2} className="screening-match-alert-Table" fixed stackable>
            <Table.Body>
              {screeningResults.map((allergyMatch) => (
                <React.Fragment key={shortid.generate()}>
                  <Table.Row>
                    <Table.Cell className="allergy-match-alert-table-cell" textAlign="right" width={3}>
                      Allergy:
                    </Table.Cell>
                    <Table.Cell width={13}>{allergyMatch.allergySource}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell verticalAlign="top" className="allergy-match-alert-table-cell" textAlign="right">
                      Reaction:
                    </Table.Cell>
                    <Table.Cell verticalAlign="top" textAlign="left">
                      {allergyMatch.reactionType}
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell verticalAlign="top" className="allergy-match-alert-table-cell" textAlign="right">
                      Notes:
                    </Table.Cell>
                    <Table.Cell verticalAlign="middle" textAlign="left">
                      {allergyMatch.notes.length ? (
                        <List>
                          {allergyMatch.notes.map((note) => (
                            <List.Item key={shortid.generate()}>{note}</List.Item>
                          ))}
                        </List>
                      ) : (
                        "N/A"
                      )}
                    </Table.Cell>
                  </Table.Row>
                </React.Fragment>
              ))}
              {!isShowOnly && (
                <Table.Row textAlign="center">
                  <Table.Cell textAlign="center" colSpan={2} width={16}>
                    <Button loading={processing} onClick={this.handleBackToSearchModal} content="Back To Search" />
                  </Table.Cell>
                </Table.Row>
              )}
            </Table.Body>
          </Table>
        </Modal.Content>
        <Modal.Actions>
          {isShowOnly && (
            <Button onClick={() => this.handleClose(null, null, closeAllModals)} loading={processing}>
              Cancel
            </Button>
          )}
          <Button
            onClick={isShowOnly ? this.handleClose : this.handleContinue}
            loading={processing}
            content="Continue anyway"
            primary
          />
        </Modal.Actions>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  patientId: state.patient.currentPatient.patientId,
  prescribingProvider: state.demographics.prescribingProvider || {},
  patientAllergies: state.patientHistory.allergyHistory || [],
  processing: state.ajaxCallsInProgress > 0
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      ...modalActions,
      ...historyActions,
      ...prescriptionActions
    },
    dispatch
  )
});

export default connect(mapStateToProps, mapDispatchToProps)(AllergyMatchModal);
