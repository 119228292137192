import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "@reduxjs/toolkit";
import { Menu } from "semantic-ui-react";
import { NavLink } from "react-router-dom";
import * as badgeActions from "../../actions/badgeActions";
import { roleGroups } from "../../constants/securityRoles";
import { checkRoles, unsubscribe } from "../../helpers";
import ConnectedBadge from "../reusable/ConnectedBadge";
import ConnectedBadgeWS from "../reusable/ConnectedBadgeWS";
import Notifications from "../notifications";
import "./SideNav.css";

import {
  VisitingPatients,
  MyPatients,
  GroupPatients,
  Reports,
  Admin,
  Tasks,
  RequestedLabs,
  Charts,
  QueuedPrescriptions,
  CoSign
} from "../../assets/common/SideNavImages";

export class SideNav extends React.Component {
  componentDidMount() {
    this.props.actions.loadMyPatientStatusBadge();
    this.props.actions.loadVisitingStatusBadge();
  }

  componentDidUpdate(prevProps) {
    const { isMyPatientStatusWatchLoaded, isVisitStatusWatchLoaded } = this.props;
    if (isMyPatientStatusWatchLoaded && !prevProps.isMyPatientStatusWatchLoaded) {
      this.props.actions.loadMyPatientStatusBadge();
    }
    if (isVisitStatusWatchLoaded && !prevProps.isVisitStatusWatchLoaded) {
      this.props.actions.loadVisitingStatusBadge();
    }
  }

  componentWillUnmount() {
    if (this.props.centerId) unsubscribe(`/RequestedLabs/center/${this.props.centerId}`);
    if (this.props.centerId) unsubscribe(`/Tasks/Open/Count?centerId=${this.props.centerId}`);
  }

  handleNavClasses = (isMobile, isOpen) => {
    if (!isMobile) return "side-nav";
    if (isMobile && isOpen) return "side-nav mobile-side-nav open";
    return "side-nav mobile-side-nav closed";
  };

  // eslint-disable-next-line no-restricted-syntax
  render() {
    const { isMobile, isOpen, handleNavToggle, authRoles, isProvider, userPreference, visiting, myPatients } =
      this.props;
    const showVisitsLink = checkRoles(roleGroups.excludeHelpDesk, authRoles);
    const showChartsLink = checkRoles(roleGroups.chartsToSign, authRoles);
    const showCoSignLink = checkRoles(roleGroups.supProvidersLdac1, authRoles);
    const showAdminLink = checkRoles(roleGroups.viewAdminPage, authRoles);
    const viewRequestedLabs = checkRoles(roleGroups.viewRequestedLabs, authRoles);
    const showMyPatientLink = checkRoles(roleGroups.myPatientView, authRoles);
    const { centerContext } = this.props.userPreference;

    let version = null;
    let build = null;
    const versionAndBuildDiv = document.getElementById("div-version-and-build");
    if (versionAndBuildDiv) {
      version = versionAndBuildDiv.hasAttribute("version") ? versionAndBuildDiv.getAttribute("version") : null;
      build = versionAndBuildDiv.hasAttribute("build") ? versionAndBuildDiv.getAttribute("build") : null;
    }

    return (
      <div
        role="button"
        tabIndex={0}
        className={this.handleNavClasses(isMobile, isOpen)}
        onClick={isMobile ? handleNavToggle : undefined}
        onKeyDown={() => {}}
      >
        <Notifications isMobile={isMobile} loadAction="loadNotificationsBadge" name="Notifications" />
        {showMyPatientLink && centerContext !== "" && (
          <Menu.Item
            as={NavLink}
            to="/my-patients"
            id="sidebar-my-patients"
            data-name="my-patients-patients"
            onClick={this.handleSelect}
            activeStyle={{ backgroundColor: "#82bf51" }}
          >
            <MyPatients />
            {isMobile && <span>My Patients</span>}
            {myPatients && myPatients !== 0 ? (
              <span className={`connected-badge ${myPatients > 9 ? "urgent" : ""}`}>{myPatients}</span>
            ) : null}
          </Menu.Item>
        )}
        {showVisitsLink && centerContext !== "" && (
          <Menu.Item
            as={NavLink}
            to="/visiting-patients"
            id="sidebar-visiting-patients"
            data-name="visiting-patients"
            onClick={this.handleSelect}
            activeStyle={{ backgroundColor: "#82bf51" }}
          >
            <VisitingPatients />
            {isMobile && <span>Visiting Patients</span>}
            {visiting && visiting !== 0 ? (
              <span className={`connected-badge ${visiting > 9 ? "urgent" : ""}`}>{visiting}</span>
            ) : null}
          </Menu.Item>
        )}
        <Menu.Item
          as={NavLink}
          to="/group-patients"
          id="sidebar-group-patients"
          data-name="group-patients"
          onClick={this.handleSelect}
          activeStyle={{ backgroundColor: "#82bf51" }}
        >
          <GroupPatients />
          {isMobile && <span>Visiting Groups</span>}
        </Menu.Item>
        {/* <ConnectedBadge
          name="Group Patients"
          navigation="group-patients"
          isMobile={isMobile}
          handleSelect={this.handleSelect}
          loadAction="loadVisitsGroupBadge"
          subscribeTo="visitsGroup"
          dependsOn={latestVisit}
        >
          <GroupPatients />
        </ConnectedBadge> */}
        {centerContext !== "" && (
          <ConnectedBadgeWS
            name="Tasks"
            navigation="tasks"
            isMobile={isMobile}
            isUrgent="isTaskToSignUrgent"
            handleSelect={this.handleSelect}
            loadAction="loadTasksBadge"
            subscribeTo={"tasks"}
            dependsOn={centerContext}
          >
            <Tasks />
          </ConnectedBadgeWS>
        )}
        {viewRequestedLabs && (
          <ConnectedBadgeWS
            navigation="requested-labs"
            isMobile={isMobile}
            loadAction="loadRequestedLabsBadge"
            subscribeTo="requestedLabs"
            dependsOn={userPreference.centerContext}
            handleSelect={this.handleSelect}
          >
            <RequestedLabs />
            {isMobile && <span>Lab Submission Queue</span>}
          </ConnectedBadgeWS>
        )}
        {isProvider && (
          <ConnectedBadge
            navigation="prescriptions-to-sign"
            isMobile={isMobile}
            loadAction="loadPrescriptionsToSignBadge"
            subscribeTo="prescriptionsToSign"
            handleSelect={this.handleSelect}
          >
            <QueuedPrescriptions />
            {isMobile && <span>Prescriptions</span>}
          </ConnectedBadge>
        )}
        {showChartsLink && (
          <ConnectedBadgeWS
            navigation="charts"
            isMobile={isMobile}
            loadAction="loadChartBadge"
            subscribeTo="chartsToSign"
            isUrgent="isChartsToSignUrgent"
            handleSelect={this.handleSelect}
          >
            <Charts />
            {isMobile && <span>Charts</span>}
          </ConnectedBadgeWS>
        )}
        {showCoSignLink && (
          <Menu.Item
            as={NavLink}
            to="/charts-cosign"
            id="sidebar-charts-cosign"
            data-name="charts-cosign"
            onClick={this.handleSelect}
            activeStyle={{ backgroundColor: "#82bf51" }}
          >
            <CoSign />
            {isMobile && <span>Co-Sign</span>}
          </Menu.Item>
        )}
        <Menu.Item
          as={NavLink}
          to="/report"
          id="sidebar-report"
          data-name="report"
          onClick={this.handleSelect}
          activeStyle={{ backgroundColor: "#82bf51" }}
        >
          <Reports />
          {isMobile && <span>Reports</span>}
        </Menu.Item>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        {showAdminLink && (
          <Menu.Item
            href=""
            as={NavLink}
            to="/admin"
            id="sidebar-admin"
            data-name="admin"
            onClick={this.handleSelect}
            activeStyle={{ backgroundColor: "#82bf51" }}
          >
            <Admin />
            {isMobile && <span>Admin</span>}
          </Menu.Item>
        )}
        <div className="version-container">
          v {version}
          <br />b {build}
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    userPreference: state.userPreference,
    centerId: state.userPreference.centerContext,
    visiting: state.badges.visiting,
    myPatients: state.badges.myPatients,
    isMyPatientStatusWatchLoaded: state.visits.isMyPatientStatusWatchLoaded,
    isVisitStatusWatchLoaded: state.visits.isVisitStatusWatchLoaded
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...badgeActions
      },
      dispatch
    )
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SideNav);
