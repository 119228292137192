import React from "react";
import { Modal as BsModal } from "react-bootstrap";

const Modal = (props) => <BsModal {...props} />;

Modal.Header = BsModal.Header;
Modal.Title = BsModal.Title;
Modal.Body = BsModal.Body;
Modal.Footer = BsModal.Footer;
Modal.TRANSITION_DURATION = BsModal.TRANSITION_DURATION;
Modal.BACKDROP_TRANSITION_DURATION = BsModal.BACKDROP_TRANSITION_DURATION;

export default Modal;
