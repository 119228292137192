import React from "react";
import { Dropdown as BsDropdown } from "react-bootstrap";

const Dropdown = React.forwardRef(({ children, ...rest }, ref) => (
  <BsDropdown {...rest} ref={ref}>
    {children}
  </BsDropdown>
));

Dropdown.Toggle = BsDropdown.Toggle;
Dropdown.Menu = BsDropdown.Menu;
Dropdown.Item = BsDropdown.Item;
Dropdown.ItemText = BsDropdown.ItemText;
Dropdown.Divider = BsDropdown.Divider;
Dropdown.Header = BsDropdown.Header;

export default Dropdown;
