import React from "react";
import { Header, Input, Form, Icon, Grid, Table, Pagination } from "semantic-ui-react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import set from "lodash/set";
import * as modalActions from "../../actions/modalActions";
import * as adminActions from "../../actions/adminActions";
import { dateWithFullTimeFormat, monthsOptions, daysOptions, getYearOptions } from "../../constants/miscellaneous";

const yearsOptions = getYearOptions();
export class ManageProxyRequestsPage extends React.Component {
  static m = moment();
  state = {
    filter: {
      from: {
        day: ManageProxyRequestsPage.m.date(),
        month: ManageProxyRequestsPage.m.month(),
        year: ManageProxyRequestsPage.m.year()
      },
      to: {
        day: ManageProxyRequestsPage.m.date(),
        month: ManageProxyRequestsPage.m.month(),
        year: ManageProxyRequestsPage.m.year()
      },
      itemId: "",
      sourceName: "",
      processingPhase: "",
      requestId: "",
      requestMethod: "",
      requestPath: "",
      retryNumber: "",
      responseCode: ""
    },
    activePage: 1,
    pageSize: 10
  };

  constructor(props) {
    super(props);
    this.props.actions.pushItemToBreadcrumb({
      key: "bc-proxy-requests",
      to: "/proxy-requests",
      text: "Proxy Requests"
    });
  }

  componentDidMount() {
    this.loadProxyRequests();
    this.interval = setInterval(() => {
      this.loadProxyRequests();
    }, 5000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  handlePaginationChange = (e, { activePage }) => {
    if (activePage >= 1) {
      this.setState({ activePage }, () => this.loadProxyRequests());
    }
  };

  applyFilter = () => this.loadProxyRequests(true);

  loadProxyRequests = (resetPagination = false) => {
    const callback = () => {
      const { pageSize, activePage, filter } = this.state;
      this.props.actions.loadProxyRequests(pageSize, activePage, filter);
    };
    if (resetPagination) {
      this.setState({ activePage: 1, pageSize: 10 }, callback);
    } else {
      callback();
    }
  };

  handleFilterInput = (e, data) => {
    const { name: field, value } = data;
    const newState = { ...this.state };
    set(newState, field, value);
    this.setState(newState);
  };

  handleFocus = e => {
    e.target.setAttribute("autocomplete", "nope");
  };

  render() {
    const { filter, pageSize, activePage } = this.state;
    const { requests, totalCount } = this.props.proxyRequests;
    return (
      <React.Fragment>
        <Header as="h2">Proxy Requests</Header>
        <div className="content-wrapper">
          <Grid>
            <Grid.Row>
              <Grid.Column width={11}>
                <Form>
                  <Grid>
                    <Grid.Row>
                      <Grid.Column width={6}>
                        From
                        <br />
                        <Form.Group widths="equal">
                          <Form.Dropdown
                            fluid
                            selection
                            options={monthsOptions}
                            name="filter.from.month"
                            value={filter.from.month}
                            onChange={this.handleFilterInput}
                            onFocus={this.handleFocus}
                          />
                          <Form.Dropdown
                            fluid
                            selection
                            options={daysOptions}
                            name="filter.from.day"
                            value={filter.from.day}
                            onChange={this.handleFilterInput}
                            onFocus={this.handleFocus}
                          />
                          <Form.Dropdown
                            fluid
                            selection
                            options={yearsOptions}
                            name="filter.from.year"
                            value={filter.from.year}
                            onChange={this.handleFilterInput}
                            onFocus={this.handleFocus}
                          />
                        </Form.Group>
                      </Grid.Column>
                      <Grid.Column width={6}>
                        To
                        <br />
                        <Form.Group widths="equal">
                          <Form.Dropdown
                            fluid
                            selection
                            options={monthsOptions}
                            name="filter.to.month"
                            value={filter.to.month}
                            onChange={this.handleFilterInput}
                            onFocus={this.handleFocus}
                          />
                          <Form.Dropdown
                            fluid
                            selection
                            options={daysOptions}
                            name="filter.to.day"
                            value={filter.to.day}
                            onChange={this.handleFilterInput}
                            onFocus={this.handleFocus}
                          />
                          <Form.Dropdown
                            fluid
                            selection
                            options={yearsOptions}
                            name="filter.to.year"
                            value={filter.to.year}
                            onChange={this.handleFilterInput}
                            onFocus={this.handleFocus}
                          />
                        </Form.Group>
                      </Grid.Column>
                      <Grid.Column>
                        {` `}
                        <br />
                        <Form.Button color="blue" onClick={this.applyFilter}>
                          <span className="ui padded basic segment">Update</span>
                          <Icon name="search" />
                        </Form.Button>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Form>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <div style={{ overflowX: "scroll" }}>
                  <React.Fragment>
                    <Table selectable sortable style={{ wordBreak: "break-all" }}>
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell>ID</Table.HeaderCell>
                          <Table.HeaderCell>SourceName</Table.HeaderCell>
                          <Table.HeaderCell>ProcessingPhase</Table.HeaderCell>
                          <Table.HeaderCell>RequestId</Table.HeaderCell>
                          <Table.HeaderCell>RequestMethod</Table.HeaderCell>
                          <Table.HeaderCell>RequestPath</Table.HeaderCell>
                          <Table.HeaderCell>RequestQueryString</Table.HeaderCell>
                          <Table.HeaderCell>RequestHeaders</Table.HeaderCell>
                          <Table.HeaderCell>RequestContent</Table.HeaderCell>
                          <Table.HeaderCell>RequestTime</Table.HeaderCell>
                          <Table.HeaderCell>RetryNumber</Table.HeaderCell>
                          <Table.HeaderCell>ResponseCode</Table.HeaderCell>
                          <Table.HeaderCell>ResponseContent</Table.HeaderCell>
                        </Table.Row>
                        <Table.Row>
                          <Table.HeaderCell>
                            <Input
                              name="filter.itemId"
                              value={filter.itemId}
                              onChange={this.handleFilterInput}
                              onBlur={this.applyFilter}
                            />
                          </Table.HeaderCell>
                          <Table.HeaderCell>
                            <Input
                              name="filter.sourceName"
                              value={filter.sourceName}
                              onChange={this.handleFilterInput}
                              onBlur={this.applyFilter}
                            />
                          </Table.HeaderCell>
                          <Table.HeaderCell>
                            <Input
                              name="filter.processingPhase"
                              value={filter.processingPhase}
                              onChange={this.handleFilterInput}
                              onBlur={this.applyFilter}
                            />
                          </Table.HeaderCell>
                          <Table.HeaderCell>
                            <Input
                              name="filter.requestId"
                              value={filter.requestId}
                              onChange={this.handleFilterInput}
                              onBlur={this.applyFilter}
                            />
                          </Table.HeaderCell>
                          <Table.HeaderCell>
                            <Input
                              name="filter.requestMethod"
                              value={filter.requestMethod}
                              onChange={this.handleFilterInput}
                              onBlur={this.applyFilter}
                            />
                          </Table.HeaderCell>
                          <Table.HeaderCell>
                            <Input
                              name="filter.requestPath"
                              value={filter.requestPath}
                              onChange={this.handleFilterInput}
                              onBlur={this.applyFilter}
                            />
                          </Table.HeaderCell>
                          <Table.HeaderCell />
                          <Table.HeaderCell />
                          <Table.HeaderCell />
                          <Table.HeaderCell />
                          <Table.HeaderCell>
                            <Input
                              name="filter.retryNumber"
                              value={filter.retryNumber}
                              onChange={this.handleFilterInput}
                              onBlur={this.applyFilter}
                            />
                          </Table.HeaderCell>
                          <Table.HeaderCell>
                            <Input
                              name="filter.responseCode"
                              value={filter.responseCode}
                              onChange={this.handleFilterInput}
                              onBlur={this.applyFilter}
                            />
                          </Table.HeaderCell>
                          <Table.HeaderCell />
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        {totalCount <= 0 && (
                          <Table.Row>
                            <Table.Cell colSpan="8" className="ui center aligned segment">
                              No items to display
                            </Table.Cell>
                          </Table.Row>
                        )}
                        {totalCount > 0 &&
                          requests.map(
                            ({
                              id,
                              sourceName,
                              processingPhase,
                              requestId,
                              requestMethod,
                              requestPath,
                              requestQueryString,
                              requestHeaders,
                              requestContent,
                              requestTime,
                              retryNumber,
                              responseCode,
                              responseContent
                            }) => (
                              <Table.Row key={id}>
                                <Table.Cell>{id}</Table.Cell>
                                <Table.Cell>{sourceName}</Table.Cell>
                                <Table.Cell>{processingPhase}</Table.Cell>
                                <Table.Cell>{requestId}</Table.Cell>
                                <Table.Cell>{requestMethod}</Table.Cell>
                                <Table.Cell>{requestPath}</Table.Cell>
                                <Table.Cell>{requestQueryString}</Table.Cell>
                                <Table.Cell>{requestHeaders}</Table.Cell>
                                <Table.Cell>{requestContent}</Table.Cell>
                                <Table.Cell>{moment(requestTime).format(dateWithFullTimeFormat)}</Table.Cell>
                                <Table.Cell>{retryNumber}</Table.Cell>
                                <Table.Cell>{responseCode}</Table.Cell>
                                <Table.Cell>{responseContent}</Table.Cell>
                              </Table.Row>
                            )
                          )}
                      </Table.Body>
                      <Table.Footer />
                    </Table>

                    <div className="ui center aligned basic segment">
                      <Pagination
                        boundaryRange={2}
                        // defaultActivePage={1}
                        activePage={activePage}
                        ellipsisItem={`...`}
                        size="mini"
                        firstItem={null}
                        lastItem={null}
                        siblingRange={1}
                        totalPages={Math.ceil(totalCount / pageSize)}
                        onPageChange={this.handlePaginationChange}
                      />
                    </div>
                  </React.Fragment>
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    proxyRequests: state.admin.proxyRequests
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...modalActions, ...adminActions }, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManageProxyRequestsPage);
