import React from "react";
import { connect } from "react-redux";
import { Segment, Header } from "semantic-ui-react";
import SurveyBuilder from "./SurveyBuilder";
import { roleGroups } from "../../../constants/securityRoles";
import { checkRoles } from "../../../helpers";

export class ManageSurveysPage extends React.Component {
  componentDidMount() {}

  render() {
    const { authRoles } = this.props;
    const isSysAdmin = checkRoles(roleGroups.systemAdmin, authRoles);
    return !isSysAdmin ? (
      <Segment placeholder>
        <Header icon color="red">
          You are not authorised to open this page!
        </Header>
      </Segment>
    ) : (
      <React.Fragment>
        <SurveyBuilder />
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    authRoles: state.auth.user.profile.roles
  };
}

export default connect(
  mapStateToProps,
  null
)(ManageSurveysPage);
