import { isEmpty } from "lodash";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Container, Row, Col, Button, Icon } from "../../../../ui";
import DoctorSearchForm from "../accordion/DoctorSearchForm";
import AddPCP from "./AddPCP";
import PCPDetails from "./PCPDetails";

const PCP = ({ primaryCarePhysician, hideContinue, handleContinue }) => {
  const [mode, setMode] = useState(isEmpty(primaryCarePhysician) ? "add" : "details");
  useEffect(() => {
    setMode(isEmpty(primaryCarePhysician) ? "add" : "details");
  }, [primaryCarePhysician]);
  return (
    <Container className="add-pcp-container p-0">
      <Row>
        <Col>
          {mode === "form" && (
            <DoctorSearchForm
              handleCancel={() => {
                setMode(isEmpty(primaryCarePhysician) ? "add" : "details");
              }}
              afterSave={() => {
                setMode(isEmpty(primaryCarePhysician) ? "add" : "details");
              }}
            />
          )}
          {mode === "details" && (
            <PCPDetails
              handleEdit={() => {
                setMode("form");
              }}
            />
          )}
          {mode === "add" && (
            <AddPCP
              handleAdd={() => {
                setMode("form");
              }}
            />
          )}
        </Col>
      </Row>
      {!hideContinue && (
        <Row className="mt-2 p-2" style={{ borderTop: "1px solid rgba(34,36,38,.15)" }}>
          <Col className="text-end">
            <Button variant="primary" onClick={handleContinue} style={{ minWidth: "100px" }}>
              {`Continue`}
              <Icon name="chevron-right" />
            </Button>
          </Col>
        </Row>
      )}
    </Container>
  );
};

function mapStateToProps(state) {
  return {
    primaryCarePhysician: state.demographics.primaryCarePhysician
  };
}

export default connect(mapStateToProps)(PCP);
