import React, { Component } from "react";
import { NavLink, withRouter } from "react-router-dom";
import isEmpty from "lodash/isEmpty";
import { Menu, Icon, Popup, Label } from "semantic-ui-react";
import { connect } from "react-redux";
import { roleGroups } from "../../constants/securityRoles";
import { checkRoles } from "../../helpers";
import "./ManagePatientPage.css";
import PatientSummary from "./PatientSummary";
import emrComponent from "../common/emrComponent";
import { TEMPLATE_ID_MA_TEMPLAE_ID } from "../../constants/miscellaneous";

export class ManagePatientMenu extends Component {
  state = {
    isActive: false,
    activeTab: ""
  };

  /** this code is unstable and causes lot of issues
  componentDidUpdate(prevProps) {
    const {
      showHpMenuItem,
      showCounselingTab,
      showDischargeMenuItem,
      history,
      wasDischarged,
      location: { pathname }
    } = this.props;
    if (
      wasDischarged !== undefined &&
      (showHpMenuItem !== prevProps.showHpMenuItem ||
        showCounselingTab !== prevProps.showCounselingTab ||
        showDischargeMenuItem !== prevProps.showDischargeMenuItem)
    ) {
      let updatedPathname = pathname;
      const hpRegex = /\/hp/;
      const counselingRegex = /\/counseling/;
      const dischargeRegex = /\/discharge/;
      const surveysTab = showHpMenuItem
        ? "/hp"
        : showCounselingTab
        ? "/counseling"
        : showDischargeMenuItem
        ? "/discharge"
        : null;
      if (surveysTab) {
        if (hpRegex.test(updatedPathname) && !showHpMenuItem) {
          updatedPathname = updatedPathname.replace(hpRegex, surveysTab);
        }
        if (counselingRegex.test(updatedPathname) && !showCounselingTab) {
          updatedPathname = updatedPathname.replace(counselingRegex, surveysTab);
        }
        if (dischargeRegex.test(updatedPathname) && !showDischargeMenuItem) {
          updatedPathname = updatedPathname.replace(dischargeRegex, surveysTab);
        }
      }
      if (updatedPathname !== pathname) history.push(updatedPathname);
    }
  }
  */

  iconClick = false;

  handleTabClick = (e, { name }) => {
    const { isActive } = this.state;
    if (isActive) {
      this.setState({ isActive: false });
    }

    if (name === "visits" && isActive && this.iconClick) {
      // name
      this.iconClick = false;
      e.preventDefault();
    }

    this.setState({ activeTab: name });
    this.iconClick = false;
  };

  handleIconClick = () => {
    const { isActive } = this.state;
    this.setState({ isActive: !isActive });

    this.iconClick = true;
  };

  render() {
    const {
      patient,
      match,
      contextVisitId,
      demographics,
      showHpMenuItem,
      showCounselingTab,
      showDischargeMenuItem,
      visitTypes,
      visitContexted,
      historyPush,
      disableSurveyTabs,
      hiddenHpSurveys,
      hiddenTreatmentPlanSurveys,
      numberOfOpenNoteSlashTasks
    } = this.props;

    if (isEmpty(patient) || isEmpty(demographics) || isEmpty(visitTypes)) {
      return null;
    }

    const { isActive } = this.state;

    let hiddenMaSurvey = false;
    let hiddenHpSurvey = false;
    for (const key in hiddenHpSurveys) {
      if (Object.prototype.hasOwnProperty.call(hiddenHpSurveys, key)) {
        if (hiddenHpSurveys[key].templateId === TEMPLATE_ID_MA_TEMPLAE_ID) hiddenMaSurvey = true;
        else hiddenHpSurvey = true;
      }
    }
    const surveysTabColor = !isEmpty(hiddenHpSurveys) && hiddenHpSurvey ? "yellow" : null;
    const labsTabColor = !isEmpty(hiddenHpSurveys) && hiddenMaSurvey ? "yellow" : null;

    let treatmentPlanTabColor = null;
    if (patient.treatmentPlanStatus === "Valid") treatmentPlanTabColor = "green";
    if (["Due", "Overdue"].includes(patient.treatmentPlanStatus)) treatmentPlanTabColor = "red";
    if (!isEmpty(hiddenTreatmentPlanSurveys)) treatmentPlanTabColor = "yellow";

    const tasksTabColor =
      numberOfOpenNoteSlashTasks.urgentTasksCount > 0
        ? "red"
        : numberOfOpenNoteSlashTasks.openTasksCount > 0
        ? "orange"
        : null;

    return (
      <div>
        <div>
          <div className="sticky-content">
            <div className="barH_">
              <PatientSummary key={patient.patientId} visitContexted={visitContexted} historyPush={historyPush} />
            </div>
            <Menu pointing secondary className={isActive ? "open" : ""}>
              <Menu.Item
                id="patient-tab-visits"
                name="visits"
                as={NavLink}
                to={`${match.url}/visits${contextVisitId ? `/vid/${contextVisitId}` : ``}`}
                onClick={this.handleTabClick}
              >
                Schedule <Icon name="bars" onClick={this.handleIconClick} />
              </Menu.Item>
              <Menu.Item
                id="patient-tab-tasks"
                name="Tasks"
                as={NavLink}
                to={`${match.url}/tasks${contextVisitId ? `/vid/${contextVisitId}` : ``}`}
                onClick={this.handleTabClick}
                color={tasksTabColor}
              >
                Tasks <Icon name="bars" onClick={this.handleIconClick} />
                {numberOfOpenNoteSlashTasks.openTasksCount > 0 && (
                  <Label circular color={tasksTabColor}>
                    {numberOfOpenNoteSlashTasks.openTasksCount}
                  </Label>
                )}
              </Menu.Item>
              <Menu.Item
                id="patient-tab-demographics"
                name="demographics"
                as={NavLink}
                to={`${match.url}/demographics${contextVisitId ? `/vid/${contextVisitId}` : ``}`}
                onClick={this.handleTabClick}
              >
                Demographics <Icon name="bars" onClick={this.handleIconClick} />
              </Menu.Item>
              <Menu.Item
                id="patient-tab-history"
                name="history"
                as={NavLink}
                to={`${match.url}/history${contextVisitId ? `/vid/${contextVisitId}` : ``}`}
                onClick={this.handleTabClick}
              >
                History <Icon name="bars" onClick={this.handleIconClick} />
              </Menu.Item>
              <Menu.Item
                id="patient-tab-prescriptions"
                name="prescriptions"
                as={NavLink}
                to={`${match.url}/prescriptions${contextVisitId ? `/vid/${contextVisitId}` : ``}`}
                onClick={this.handleTabClick}
              >
                Prescriptions <Icon name="bars" onClick={this.handleIconClick} />
              </Menu.Item>
              <Menu.Item
                id="patient-tab-scans"
                name="Scans"
                as={NavLink}
                to={`${match.url}/scans${contextVisitId ? `/vid/${contextVisitId}` : ``}`}
                onClick={this.handleTabClick}
              >
                Scans <Icon name="bars" onClick={this.handleIconClick} />
              </Menu.Item>
              <Menu.Item
                id="patient-tab-labs"
                name="labs"
                as={NavLink}
                to={`${match.url}/labs${contextVisitId ? `/vid/${contextVisitId}` : ``}`}
                onClick={this.handleTabClick}
                color={labsTabColor}
              >
                Labs <Icon name="bars" onClick={this.handleIconClick} />
              </Menu.Item>
              {showHpMenuItem && (
                <Menu.Item
                  id="patient-tab-hp"
                  name="hp"
                  as={disableSurveyTabs ? "span" : NavLink}
                  content={
                    disableSurveyTabs ? (
                      <Popup
                        trigger={
                          <span size="small" className="transparent-button">
                            H&amp;P <Icon name="bars" onClick={this.handleIconClick} />
                          </span>
                        }
                        content="You are not set as the rendering provider for this visit. Please ask your office staff to update the appointment in Athena with the appropriate rendering provider."
                      />
                    ) : (
                      <React.Fragment>
                        H&amp;P <Icon name="bars" onClick={this.handleIconClick} />
                      </React.Fragment>
                    )
                  }
                  to={`${match.url}/hp${contextVisitId ? `/vid/${contextVisitId}` : ``}`}
                  onClick={this.handleTabClick}
                  color={surveysTabColor}
                  disabled={disableSurveyTabs}
                />
              )}
              {showDischargeMenuItem && (
                <Menu.Item
                  id="patient-tab-discharge"
                  name="discharge"
                  as={NavLink}
                  to={`${match.url}/discharge`}
                  onClick={this.handleTabClick}
                  color={surveysTabColor}
                >
                  Discharge <Icon name="bars" onClick={this.handleIconClick} />
                </Menu.Item>
              )}
              {showCounselingTab && (
                <Menu.Item
                  id="patient-tab-counseling"
                  name="counseling"
                  as={disableSurveyTabs ? "span" : NavLink}
                  to={`${match.url}/counseling${contextVisitId ? `/vid/${contextVisitId}` : ``}`}
                  content={
                    disableSurveyTabs ? (
                      <Popup
                        trigger={
                          <span size="small" className="transparent-button">
                            Counseling <Icon name="bars" onClick={this.handleIconClick} />
                          </span>
                        }
                        content="You are not set as the rendering provider for this visit. Please ask your office staff to update the appointment in Athena with the appropriate rendering provider."
                      />
                    ) : (
                      <React.Fragment>
                        Counseling <Icon name="bars" onClick={this.handleIconClick} />
                      </React.Fragment>
                    )
                  }
                  onClick={this.handleTabClick}
                  color={surveysTabColor}
                  disabled={disableSurveyTabs}
                />
              )}
              <Menu.Item
                id="patient-tab-treatment-plan"
                name="Treatment Plan"
                as={NavLink}
                to={`${match.url}/treatment-plan${contextVisitId ? `/vid/${contextVisitId}` : ``}`}
                onClick={this.handleTabClick}
                color={treatmentPlanTabColor}
              >
                Treatment Plan <Icon name="bars" onClick={this.handleIconClick} />
              </Menu.Item>
            </Menu>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const patient = state.patient.currentPatient;
  const numberOfOpenNoteSlashTasks = state.patient.numberOfOpenNoteSlashTasks;
  const status = state.demographics.status;

  const contextVisit = state.visits.contextVisit;
  const latestVisit = state.visits.latestVisit;
  const surveysVisit = contextVisit || latestVisit;

  const authRoles = state.auth.user.profile.roles;
  const isSysAdmin = checkRoles(roleGroups.systemAdmin, authRoles);

  const disableSurveyTabs =
    !isSysAdmin &&
    (!surveysVisit || surveysVisit.prescribingProvider.providerId !== state.userPreference.currentUserId);

  const surveysVisitType = surveysVisit ? surveysVisit.visitType : null;

  const visitTypes = state.lookups.visitTypes;

  const isSurveyVisitMedical =
    surveysVisitType && visitTypes.length > 0 && visitTypes.find((vt) => vt.value === surveysVisitType).medical;

  const canShowCounselingTabs = checkRoles(roleGroups.counselingView, authRoles);
  const showCounselingTab = patient.patientId && canShowCounselingTabs && surveysVisit && !isSurveyVisitMedical;

  const showDischargeMenuItem = status === "Discharged" && !contextVisit; // TODO this will change in #33849
  if (surveysVisitType && visitTypes.length > 0 && !visitTypes.find((vt) => vt.value === surveysVisitType)) {
    throw new Error(`Unknown visit type [${surveysVisitType}]`);
  }

  const canShowSurveyTabs = checkRoles(roleGroups.templatesView, authRoles);
  const showHpMenuItem =
    patient.patientId &&
    canShowSurveyTabs &&
    surveysVisit &&
    isSurveyVisitMedical &&
    !showCounselingTab &&
    !showDischargeMenuItem;

  return {
    patient,
    patientId: patient ? patient.patientId : null,
    processing: state.ajaxCallsInProgress > 0,
    contextVisit,
    contextVisitId: contextVisit ? contextVisit.visitId : null,
    contextVisitType: contextVisit ? contextVisit.visitType : null,
    surveysVisit,
    visitTypes,
    demographics: state.demographics,
    disableSurveyTabs,
    showHpMenuItem,
    showCounselingTab,
    showDischargeMenuItem,
    hiddenHpSurveys: state.templates.hiddenSurveys,
    hiddenTreatmentPlanSurveys: state.treatmentPlan.hiddenSurveys,
    numberOfOpenNoteSlashTasks
  };
}

export default withRouter(connect(mapStateToProps, null)(emrComponent(ManagePatientMenu)));
