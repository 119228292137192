const referenceValues = {
  phonetypes: [
    { text: "Home", value: "Home" },
    { text: "Cell", value: "Cell" },
    { text: "SMS", value: "SMS" },
    { text: "Work", value: "Work" },
    { text: "Other", value: "Other" }
  ],

  genders: [{ text: "Male", value: "Male" }, { text: "Female", value: "Female" }],

  pharmacyTypes: [
    { text: "All", value: "All" },
    { text: "Mail-order", value: "MailOrder" },
    { text: "Retail", value: "Retail" }
  ],

  proximities: [
    { text: "None", value: "None" },
    { text: "Clinic", value: "Clinic" },
    { text: "Patient", value: "Patient" },
    { text: "Zip code", value: "Zipcode" }
  ],

  distances: [
    { text: "No Distance", value: 0 },
    { text: "5 miles", value: 5 },
    { text: "10 miles", value: 10 },
    { text: "15 miles", value: 15 },
    { text: "20 miles", value: 20 },
    { text: "30 miles", value: 30 },
    { text: "40 miles", value: 40 },
    { text: "50 miles", value: 50 },
    { text: "Any distance", value: -1 }
  ]
};

export default referenceValues;
