import React, { Component } from "react";
import { Segment, Grid } from "semantic-ui-react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import shortid from "shortid";
import emrComponent from "../../common/emrComponent";
import * as labActions from "../../../actions/labActions";
import * as modalActions from "../../../actions/modalActions";
import * as documentActions from "../../../actions/documentActions";
import GroupedPendingOrders from "./GroupedPendingOrders";
import { mediumDateFormat } from "../../../constants/miscellaneous";

export class PendingOrdersTile extends Component {
  static groupOrderSetsByProvider(pendingOrder, provider) {
    if (!pendingOrder || !pendingOrder.labProviderGroup) return [];
    return pendingOrder.labProviderGroup.find((group) => group.labProvider === provider);
  }

  componentDidMount() {
    const { patientId } = this.props;
    if (patientId) this.props.actions.loadPendingOrders(patientId);
  }

  componentDidUpdate(prevProps) {
    const { patientId } = this.props;
    if (prevProps.patientId !== patientId) this.props.actions.loadPendingOrders(patientId);
  }

  handleEditConfirmatory = (orderId, labCode) => {
    const {
      patientId,
      labsVisitId,
      actions: { loadAlreadyAddedConfirmatoryTests, loadAvailableLabs, setSelectedLabOrder, showModal }
    } = this.props;

    loadAlreadyAddedConfirmatoryTests(patientId, orderId)
      .then((response) =>
        setSelectedLabOrder(patientId, {
          orderId,
          tests: [{ labCode, selectedChildren: response.confirmatoryTests }]
        })
      )
      .then(() =>
        showModal({
          type: "CONFIRMATORY_TEST_CONDITIONS_MODAL",
          props: {
            open: true,
            patientId,
            orderId,
            visitId: labsVisitId,
            editMode: true,
            loadAvailableLabs
          }
        })
      );
  };

  handlePrintLabel = (documentKey, container) => {
    this.props.actions.getDocument(documentKey, "orderLabel", container).then((response) => {
      const blob = new Blob([response.document], {
        type: "application/pdf"
      });
      const fileURL = URL.createObjectURL(blob);
      const w = window.open(fileURL);
      w.print();
    });
  };

  handleResultEntryClick = (orderId, labCode) =>
    this.props.actions.showModal({
      type: "RAPID_RESULTS_ENTRY_MODAL",
      props: {
        open: true,
        patientId: this.props.patientId,
        visitId: this.props.labsVisitId,
        orderId,
        labCode
      }
    });

  handleCancelSubmittedClick = (orderId, labProvider, testType, submissionId, labSource, labCode, labName) => {
    const {
      patientId,
      actions: { setTestCancelButtonPressed }
    } = this.props;
    setTestCancelButtonPressed(orderId, labProvider, testType, submissionId, labSource, labCode, true);
    this.props.actions.showModal({
      type: "CANCEL_SUBMITTED_LAB_MODAL",
      props: {
        open: true,
        patientId,
        orderId,
        labProvider,
        testType,
        submissionId,
        labSource,
        labCode,
        labName
      }
    });
  };

  renderPendingOrder = (pendingOrder, canAddLabOrders, canCreateStandingLabOrders, canCancelSubmittedLabOrders) => {
    const [cleanslateRapid, labDaq, quest] = [
      PendingOrdersTile.groupOrderSetsByProvider(pendingOrder, "Clean Slate Rapid"),
      PendingOrdersTile.groupOrderSetsByProvider(pendingOrder, "Clean Slate LabDaq"),
      PendingOrdersTile.groupOrderSetsByProvider(pendingOrder, "Quest")
    ];
    const isAllPSCHold = pendingOrder.labProviderGroup.every((providerGroup) =>
      providerGroup.testTypeGroup.every((testTypeGroup) =>
        testTypeGroup.submissions.every((submission) =>
          submission.labSourceGroup.every((labSourceGroup) => labSourceGroup.labs.every((lab) => lab.isPSCHold))
        )
      )
    );
    return (
      <React.Fragment key={`${pendingOrder.pendingOrderCreatedDate}-rand-${shortid.generate()}`}>
        <Grid style={{ margin: "0" }}>
          {
            <Grid.Row style={{ padding: "5px 0" }}>
              <Grid.Column>
                <em>
                  {`${pendingOrder.providerFirstName} ${pendingOrder.providerLastName} - ${moment(
                    pendingOrder.pendingOrderCreatedDate
                  ).format(mediumDateFormat)}`}
                  {isAllPSCHold ? ` (PSC hold)` : ``}
                </em>
              </Grid.Column>
            </Grid.Row>
          }
          {cleanslateRapid && (
            <React.Fragment>
              <Grid.Row>
                <Grid.Column width={16}>
                  <h3>Clean Slate Rapid</h3>
                </Grid.Column>
              </Grid.Row>
              <GroupedPendingOrders
                orderId={pendingOrder.orderId}
                isSubmitted={pendingOrder.isSubmitted}
                grouping={cleanslateRapid}
                handlePrintLabel={this.handlePrintLabel}
                handleResultEntryClick={this.handleResultEntryClick}
                isAllPSCHold={isAllPSCHold}
                canAddLabOrders={canAddLabOrders}
                canCreateStandingLabOrders={canCreateStandingLabOrders}
                canCancelSubmittedLabOrders={canCancelSubmittedLabOrders}
                handleEditConfirmatory={this.handleEditConfirmatory}
                handleCancelSubmittedClick={this.handleCancelSubmittedClick}
              />
            </React.Fragment>
          )}
          {labDaq && (
            <React.Fragment>
              <Grid.Row>
                <Grid.Column width={16}>
                  <h3>Clean Slate LabDaq</h3>
                </Grid.Column>
              </Grid.Row>
              <GroupedPendingOrders
                orderId={pendingOrder.orderId}
                isSubmitted={pendingOrder.isSubmitted}
                grouping={labDaq}
                handlePrintLabel={this.handlePrintLabel}
                isAllPSCHold={isAllPSCHold}
                canAddLabOrders={canAddLabOrders}
                canCreateStandingLabOrders={canCreateStandingLabOrders}
                canCancelSubmittedLabOrders={canCancelSubmittedLabOrders}
                handleEditConfirmatory={this.handleEditConfirmatory}
                handleCancelSubmittedClick={this.handleCancelSubmittedClick}
              />
            </React.Fragment>
          )}
          {quest && (
            <React.Fragment>
              <Grid.Row>
                <Grid.Column width={16}>
                  <h3>Quest</h3>
                </Grid.Column>
              </Grid.Row>
              <GroupedPendingOrders
                orderId={pendingOrder.orderId}
                isSubmitted={pendingOrder.isSubmitted}
                grouping={quest}
                handlePrintLabel={this.handlePrintLabel}
                isAllPSCHold={isAllPSCHold}
                canAddLabOrders={canAddLabOrders}
                canCreateStandingLabOrders={canCreateStandingLabOrders}
                canCancelSubmittedLabOrders={canCancelSubmittedLabOrders}
                handleEditConfirmatory={this.handleEditConfirmatory}
                handleCancelSubmittedClick={this.handleCancelSubmittedClick}
              />
            </React.Fragment>
          )}
        </Grid>
      </React.Fragment>
    );
  };
  render() {
    const { pendingOrders, canAddLabOrders, canCreateStandingLabOrders, canCancelSubmittedLabOrders } = this.props;
    return pendingOrders.length ? (
      <Segment id="div-pending-order" className="right-rail-container no-margin">
        <h2>Pending Orders</h2>
        {pendingOrders.map((pendingOrder) =>
          this.renderPendingOrder(
            pendingOrder,
            canAddLabOrders,
            canCreateStandingLabOrders,
            canCancelSubmittedLabOrders
          )
        )}
      </Segment>
    ) : null;
  }
}

function mapStateToProps(state) {
  const labsVisit = state.visits.contextVisit;
  return {
    patientId: state.patient.currentPatient.patientId,
    labsVisitId: labsVisit ? labsVisit.visitId : null,
    pendingOrders: state.labs.pendingOrders,
    centerId: state.demographics.homeCenterId,
    confirmatoryTests: state.labs.confirmatoryTests
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...modalActions, ...labActions, ...documentActions }, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(emrComponent(PendingOrdersTile));
