import React, { Component } from "react";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as patientHistoryActions from "../../../../actions/patientHistoryActions";
import * as modalActions from "../../../../actions/modalActions";
import * as lookupActions from "../../../../actions/lookupActions";

import { roleGroups } from "../../../../constants/securityRoles";
import { getLookupText, checkRoles } from "../../../../helpers";

import FamilyHistoryRow from "./FamilyHistoryRow";
import "./FamilyHistoryTable.css";

export class FamilyHistoryTable extends Component {
  componentDidMount() {
    this.props.actions.loadRelationships();
  }

  handleEditFamilyHistory = (e, data) => {
    const { familyHistory } = this.props;
    switch (data.icon) {
      case "delete": {
        this.props.actions.deleteFamilyHist(this.props.patientId, familyHistory[data.index].itemId);
        break;
      }
      case "edit":
      case "plus": {
        const currentFamilyHistory = familyHistory[data.index];
        currentFamilyHistory.relation = currentFamilyHistory.relation;
        this.props.actions.showModal({
          type: "ADD_FAMILY_HISTORY",
          props: {
            open: true,
            familyHistory: currentFamilyHistory,
            condition: familyHistory[data.index].condition,
            isEdit: true
          }
        });
        break;
      }
      default:
        break;
    }
  };

  render() {
    const { familyHistory, relationships, authRoles } = this.props;
    const showEditHistory = checkRoles(roleGroups.excludeHelpDesk, authRoles);
    const showDeleteHistory = checkRoles(roleGroups.providers, authRoles);
    return (
      <div className="familyHistoryTable">
        <table>
          <thead>
            <tr>
              <th>Condition</th>
              <th width="5%" />
              <th width="40%">Relation</th>
              <th width="5%">Note</th>
              <th width="5%" />
              <th width="5%" />
              <th width="5%" />
            </tr>
          </thead>
          <tbody>
            {familyHistory.length
              ? familyHistory.map((history, i) => (
                  <FamilyHistoryRow
                    condition={history.condition}
                    relation={getLookupText(history.relation, relationships)}
                    notes={history.notes}
                    key={history.itemId}
                    index={i}
                    itemID={history.itemId}
                    familyHistoryNotes={history.notes}
                    handleEditFamilyHistory={this.handleEditFamilyHistory}
                    showEditHistory={showEditHistory}
                    showDeleteHistory={showDeleteHistory}
                  />
                ))
              : null}
          </tbody>
        </table>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    patientId: state.patient.currentPatient.patientId,
    relationships: state.lookups.relationships,
    authRoles: state.auth.user.profile.roles
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...patientHistoryActions, ...modalActions, ...lookupActions }, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FamilyHistoryTable);
