import * as types from "./actionTypes";

function openSliderPanelSuccess() {
  return {
    type: types.OPEN_ERROR_LOG_SLIDER
  };
}

function closeSliderPanelSuccess() {
  return {
    type: types.CLOSE_ERROR_LOG_SLIDER
  };
}

function pushUiErrorLogToSliderSuccess(error) {
  return {
    type: types.PUSH_ERROR_LOG_TO_SLIDER,
    error
  };
}

function dismissItemErrorLogSuccess(error) {
  return {
    type: types.DISMISS_ITEM_ERROR_LOG_TO_SLIDER,
    error
  };
}

function dismissAllErrorLogSuccess() {
  return {
    type: types.DISMISS_ALL_ERROR_LOG_TO_SLIDER
  };
}

export function openSliderPanel() {
  return dispatch => {
    dispatch(openSliderPanelSuccess());
  };
}

export function closeSliderPanel() {
  return dispatch => {
    dispatch(closeSliderPanelSuccess());
  };
}

export function pushUiErrorLogToSlider(item) {
  return dispatch => {
    dispatch(pushUiErrorLogToSliderSuccess(item));
  };
}

export function dismissItemErrorLog(item) {
  return dispatch => {
    dispatch(dismissItemErrorLogSuccess(item));
  };
}

export function dismissAllErrorLog() {
  return dispatch => {
    dispatch(dismissAllErrorLogSuccess());
  };
}
