import React, { Component } from "react";
import { Modal, Button, TextArea } from "semantic-ui-react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as modalActions from "../../actions/modalActions";
import * as userActions from "../../actions/userActions";
import * as lookupActions from "../../actions/lookupActions";
import * as noteSlashTasksActions from "../../actions/noteSlashTasksActions";
import { exportTextFile } from "../../helpers";

export class ExportClientEventsModal extends Component {
  constructor() {
    super();
    this.textArea = React.createRef();
  }

  state = {
    text: ""
  };

  componentDidMount() {
    /** pretty json */
    // const text = JSON.stringify(JSON.parse(localStorage.getItem("ARES_CLIENT_EVENT_LOG")), null, 2);
    let text = `Timestamp,Tab Id, Event\n`;
    const list = JSON.parse(localStorage.getItem("ARES_CLIENT_EVENT_LOG"));
    list.forEach((item) => {
      text += `${item.ts},${item.tabId},${item.event}\n`;
    });
    this.setState({ text });
  }

  handleCancel = () => {
    this.props.actions.hideModal();
  };

  handleCopy = () => {
    this.textArea.current.ref.current.select();
    this.textArea.current.ref.current.focus();
    navigator.clipboard.writeText(this.state.text);
  };

  handleExport = () => {
    exportTextFile("log.csv", this.state.text);
  };

  render() {
    const { text } = this.state;
    const { open } = this.props;

    return (
      <Modal
        open={open}
        size="large"
        className={`export-client-events-modal`}
        closeIcon
        closeOnDimmerClick={false}
        onClose={this.handleCancel}
      >
        <Modal.Header className="actions-column" style={{ paddingRight: "4rem" }}>
          Event Log
        </Modal.Header>
        <Modal.Content>
          <TextArea ref={this.textArea} rows={20} style={{ width: "100%" }} value={text} />
        </Modal.Content>
        <Modal.Actions className="actions-column">
          <Button icon="copy" onClick={this.handleCopy} content={`Copy to clipboard`} floated="left" />
          <Button color="blue" icon="save" onClick={this.handleExport} content={`Export`} />
        </Modal.Actions>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    patientId: state.patient.currentPatient.patientId,
    saving: state.ajaxCallsInProgress > 0,
    centerOptions: state.lookups.centers.filter((c) => c.active).map((i) => ({ text: i.text, value: i.value })),
    taskTemplates: state.lookups.taskTemplates.sort((a, b) =>
      a.subject > b.subject ? 1 : a.subject < b.subject ? -1 : 0
    ),
    noteTemplates: state.lookups.noteTemplates.sort((a, b) =>
      a.subject > b.subject ? 1 : a.subject < b.subject ? -1 : 0
    ),
    currentUserId: state.userPreference.currentUserId,
    currentUserRoles: state.auth.user.profile.roles,
    defaultAssigneeCenterId: state.demographics.homeCenterId,
    taskRoles: state.lookups.taskRoles
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...modalActions,
        ...lookupActions,
        ...userActions,
        ...noteSlashTasksActions
      },
      dispatch
    )
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ExportClientEventsModal);
