import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as modalActions from "../../../actions/modalActions";
import SurveyHeaderEditor from "./SurveyHeaderEditor";
import AddSurveySection from "./AddSurveySection";
import AddSurveyQuestion from "./AddSurveyQuestion";

class SurveyBuilder extends Component {
  state = {
    mode: null,
    autosave: true,
    saving: false,
    prefix: sessionStorage.getItem("prefix") ? sessionStorage.getItem("prefix") : "***",
    survey: sessionStorage.getItem("survey") /* localStorage is behaving crazy on chrome !!! */
      ? JSON.parse(sessionStorage.getItem("survey"))
      : {
          id: "00000000-0000-0000-0000-000000000000",
          name: "",
          isCumulative: true,
          requireReview: false,
          isReadOnly: false,
          sections: [],
          title: "",
          version: "1.0.0",
          questions: []
        },
    json: ""
  };

  componentDidMount() {
    this.interval = setInterval(() => {
      if (this.state.autosave) {
        this.setState({ saving: true }, () =>
          /* fake delay to allow saving message to be shown */
          setTimeout(() => {
            this.setState({ saving: false });
          }, 1000)
        );
        sessionStorage.setItem("prefix", this.state.prefix);
        sessionStorage.setItem("survey", JSON.stringify(this.state.survey));
      }
    }, 10000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  /* json */
  onJsonChange = (e) => {
    this.setState({ survey: JSON.parse(e.target.value) });
  };

  /* modes */
  setEditHeaderMode = () => {
    this.setState({ mode: "update-header" });
  };

  setAddSectionMode = () => {
    this.setState({ mode: "add-section" });
  };

  setAddQuestionMode = () => {
    this.setState({ mode: "add-question" });
  };

  fixQuestionKeys = () => {
    const updatedSurvey = { ...this.state.survey };
    const updatedQuestionKeys = {};

    updatedSurvey.questions = updatedSurvey.questions.map((q, i) => {
      const key = `${this.state.prefix}-100-Q${i + 1}`;
      if (q.key === key && q.sortOrder === i + 1) return { ...q };
      if (q.key !== key) updatedQuestionKeys[q.key] = key;
      return {
        ...q,
        key: `${this.state.prefix}-100-Q${i + 1}`,
        sortOrder: i + 1
      };
    });

    updatedSurvey.questions = updatedSurvey.questions.map((q) => {
      if (!q.triggers) return { ...q };
      return {
        ...q,
        triggers: q.triggers.map((t) => {
          if (!Object.keys(updatedQuestionKeys).includes(t.questionKey)) return { ...t };
          return { ...t, questionKey: updatedQuestionKeys[t.questionKey] };
        })
      };
    });

    this.setState({ survey: updatedSurvey });
  };

  fixQuestionOrder = () => {
    const updatedSurvey = { ...this.state.survey };

    updatedSurvey.questions = updatedSurvey.questions.map((q, i) => {
      if (q.sortOrder === i + 1) return { ...q };
      return {
        ...q,
        sortOrder: i + 1
      };
    });

    this.setState({ survey: updatedSurvey });
  };

  /* prefix */
  handlePrefixChnage = (value) => {
    this.setState({ prefix: value });
  };

  /* header */
  handleHeaderKeyChnage = (key, value) => {
    const survey = Object.assign({}, this.state.survey);
    survey[key] = value;
    this.setState({ survey });
  };

  /* sections */
  addSection = ({ index, sectionKey, sectionHeader }) => {
    const sections = [...this.state.survey.sections];
    sections.splice(index, 0, { key: sectionKey, header: sectionHeader });
    this.setState({ survey: { ...this.state.survey, sections } });
  };

  /* questions */
  addQuestion = ({ index, question }) => {
    const questions = [...this.state.survey.questions];
    questions.splice(index, 0, question);
    this.setState({ survey: { ...this.state.survey, questions } });
  };

  handlePreview = () => {
    const { survey } = this.state;
    this.props.actions.showModal({
      type: "SURVEY_MODAL",
      props: {
        open: true,
        asModal: true,
        for: "templates",
        survey: {
          ...survey,
          questions: survey.questions.map((q) => ({
            ...q,
            options: q.options ? q.options : [],
            triggers: q.triggers ? q.triggers : [],
            elucidations: q.elucidations ? q.elucidations : [],
            previousAnswers: [],
            previousAnswersVisitId: null,
            staticValues: [],
            defaultAnswers: []
          }))
        }
      }
    });
  };

  render() {
    return (
      <div className="" style={{ height: "75vh" }}>
        <div className="ui grid" style={{ height: "6vh" }}>
          <div className="sixteen wide column" style={{ height: "100%", padding: "30px" }}>
            <button className="mini ui brown button" onClick={this.setEditHeaderMode} style={{ marginRight: "10px" }}>
              Header
            </button>
            <button className="mini ui grey button" onClick={this.setAddSectionMode} style={{ marginRight: "10px" }}>
              + Section
            </button>
            <button className="mini ui blue button" onClick={this.setAddQuestionMode} style={{ marginRight: "10px" }}>
              + Question
            </button>
            <div
              style={{
                display: this.state.saving ? "inline-block" : "none",
                minWidth: "200px",
                textAlign: "center"
              }}
            >
              Saving...
            </div>
            <button className="mini ui green button" onClick={this.handlePreview} style={{ float: "right" }}>
              Preview
            </button>
            <button className="mini ui orange button" onClick={this.fixQuestionKeys} style={{ float: "right" }}>
              Fix question keys
            </button>
            <button className="mini ui blue button" onClick={this.fixQuestionOrder} style={{ float: "right" }}>
              Fix questions order
            </button>
          </div>
        </div>
        <div className="ui grid" style={{ height: "71vh" }}>
          <div className="eight wide column" style={{ height: "100%" }}>
            {this.state.mode === "update-header" && (
              <SurveyHeaderEditor
                prefix={this.state.prefix}
                survey={this.state.survey}
                handleHeaderKeyChnage={this.handleHeaderKeyChnage}
                handlePrefixChnage={this.handlePrefixChnage}
              />
            )}
            {this.state.mode === "add-section" && (
              <AddSurveySection survey={this.state.survey} onAddSection={this.addSection} />
            )}
            {this.state.mode === "add-question" && (
              <AddSurveyQuestion
                prefix={this.state.prefix}
                questions={this.state.survey.questions}
                sections={this.state.survey.sections}
                onAddQuestion={this.addQuestion}
              />
            )}
          </div>
          <div className="eight wide column" style={{ height: "100%" }}>
            <textarea
              style={{ width: "100%", height: "100%" }}
              value={JSON.stringify(this.state.survey, null, 2)}
              onChange={this.onJsonChange}
            />
          </div>
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...modalActions
      },
      dispatch
    )
  };
}

export default connect(null, mapDispatchToProps)(SurveyBuilder);
