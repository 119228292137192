import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { isEqual, set } from "lodash";
import { Container, Row, Col, Button } from "../../../../ui";
import * as lookupActions from "../../../../actions/lookupActions";
import * as patientActions from "../../../../actions/patientActions";
import * as demographicActions from "../../../../actions/demographicActions";
import AdditionalInformationForm from "./AdditionalInformationForm";
import {
  CUSTOM_FIELD_ID_ABILITY_READ_WRITE_PREFERRED_LANGUAGE,
  CUSTOM_FIELD_ID_NEED_ASSISTED_TECHNOLOGY
} from "../../../../constants/miscellaneous";

export class AdditionalInformation extends Component {
  state = {
    model: {
      abilityReadWritePreferredLanguage: "",
      needAssistedTechnology: ""
    },
    errors: {}
  };

  componentDidMount() {
    const { patientId, abilityReadWritePreferredLanguageField, needAssistedTechnologyField } = this.props;
    if (!patientId || !abilityReadWritePreferredLanguageField || !needAssistedTechnologyField) return;
    this.fetchInfo();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.patientId &&
      this.props.abilityReadWritePreferredLanguageField &&
      this.props.needAssistedTechnologyField &&
      (this.props.patientId !== prevProps.patientId ||
        !isEqual(this.props.abilityReadWritePreferredLanguageField, prevProps.abilityReadWritePreferredLanguageField) ||
        !isEqual(this.props.needAssistedTechnologyField, prevProps.needAssistedTechnologyField))
    ) {
      this.fetchInfo();
    }
  }

  fetchInfo = () => {
    const { patientId, abilityReadWritePreferredLanguageField, needAssistedTechnologyField } = this.props;
    this.props.actions
      .fetchCustomFields(patientId, [
        abilityReadWritePreferredLanguageField.customeFieldAthenaId,
        needAssistedTechnologyField.customeFieldAthenaId
      ])
      .then(() => {
        this.setState({
          model: {
            abilityReadWritePreferredLanguage: this.props.customFields[abilityReadWritePreferredLanguageField.id]
              ? this.props.customFields[abilityReadWritePreferredLanguageField.id].customFieldAnswer
              : "",
            needAssistedTechnology: this.props.customFields[needAssistedTechnologyField.id]
              ? this.props.customFields[needAssistedTechnologyField.id].customFieldAnswer
              : ""
          }
        });
      });
  };

  handleInput = (e, data) => {
    const { name, value } = data;
    const updatedState = { ...this.state };
    set(updatedState, name, value);
    this.setState(updatedState);
  };

  handleFocus = (e) => {
    e.target.setAttribute("autocomplete", "nope");
  };

  handleSave = () => {
    const { patientId, abilityReadWritePreferredLanguageField, needAssistedTechnologyField } = this.props;
    const {
      model: { abilityReadWritePreferredLanguage, needAssistedTechnology }
    } = this.state;
    this.props.actions
      .saveCustomFields(patientId, [
        {
          customFieldId: abilityReadWritePreferredLanguageField.customeFieldAthenaId,
          customFieldValue: abilityReadWritePreferredLanguage
        },
        {
          customFieldId: needAssistedTechnologyField.customeFieldAthenaId,
          customFieldValue: needAssistedTechnology
        }
      ])
      .then(() => {
        if (this.props.afterSave) this.props.afterSave();
      });
  };

  render() {
    const {
      processing,
      hideCancelButton,
      saveButtonIcon,
      abilityReadWritePreferredLanguageOptions,
      needAssistedTechnologyOptions
    } = this.props;

    const saveButtonText = this.props.saveButtonText || "Save";

    const { model, errors } = this.state;
    // const formInvalid = requiredFieldsFilled(inputValidation.required, demographics);
    // const disableSave = formInvalid || Object.keys(errors).length !== 0;
    const disableEdit = false; // !checkRoles(roleGroups.systemAdmin, authRoles);
    const disableSave = false;

    return (
      <React.Fragment>
        <Container className="p-0">
          <Row>
            <Col>
              <AdditionalInformationForm
                model={model}
                errors={errors}
                disableEdit={disableEdit}
                handleInput={this.handleInput}
                handleFocus={this.handleFocus}
                abilityReadWritePreferredLanguageOptions={abilityReadWritePreferredLanguageOptions}
                needAssistedTechnologyOptions={needAssistedTechnologyOptions}
              />
            </Col>
          </Row>
          <Row className="p-2" style={{ borderTop: "1px solid rgba(34,36,38,.15)" }}>
            <Col>
              {!hideCancelButton && (
                <Button variant="danger" onClick={this.props.handleCancel} className="float-start">
                  Cancel
                </Button>
              )}
              <Button
                variant="primary"
                onClick={this.handleSave}
                disabled={processing || disableSave}
                loading={processing}
                className="float-end"
                style={{ minWidth: "100px" }}
              >
                {saveButtonText}
                {saveButtonIcon && <i className={saveButtonIcon} />}
              </Button>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const abilityReadWritePreferredLanguageField = state.lookups.patientCustomFields.find(
    (cf) => cf.id === CUSTOM_FIELD_ID_ABILITY_READ_WRITE_PREFERRED_LANGUAGE
  );
  const abilityReadWritePreferredLanguageOptions = (
    abilityReadWritePreferredLanguageField || {
      customFieldAnswers: []
    }
  ).customFieldAnswers.map((cfa) => ({ text: cfa.aresAnswer, value: cfa.aresAnswer }));

  const needAssistedTechnologyField = state.lookups.patientCustomFields.find(
    (cf) => cf.id === CUSTOM_FIELD_ID_NEED_ASSISTED_TECHNOLOGY
  );

  const needAssistedTechnologyOptions = (
    needAssistedTechnologyField || {
      customFieldAnswers: []
    }
  ).customFieldAnswers.map((cfa) => ({ text: cfa.aresAnswer, value: cfa.aresAnswer }));
  return {
    patientId: state.demographics?.patientId,
    customFields: state.patient.customFields,
    processing: state.ajaxCallsInProgress > 0,
    // authRoles: state.auth.user.profile.roles,
    abilityReadWritePreferredLanguageField,
    abilityReadWritePreferredLanguageOptions,
    needAssistedTechnologyField,
    needAssistedTechnologyOptions
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...demographicActions,
        ...patientActions,
        ...lookupActions
      },
      dispatch
    )
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AdditionalInformation));
