import React, { Children } from "react";
import { Tab, Row, Col, Nav, Badge } from "react-bootstrap";
import Icon from "./Icon";

const StepperItem = (props) => <React.Fragment>{props?.children}</React.Fragment>;

const StepperHeader = (props) => <React.Fragment>{props?.children}</React.Fragment>;

const StepperBody = (props) => <React.Fragment>{props?.children}</React.Fragment>;

const Stepper = ({ children, activeKey, leftColumnWidth = 3, rightColumnWidth = 9, defaultActiveKey, ...props }) => (
  <Tab.Container activeKey={activeKey} defaultActiveKey={defaultActiveKey}>
    <Row {...props}>
      <Col sm={leftColumnWidth} className="half-v-padding" style={{ borderRight: "1px solid rgba(34,36,38,.15)" }}>
        <Nav variant="pills" className="flex-column">
          {Children.map(children, (child, index) => {
            const eventKey = `${+index + 1}`;
            if (child.type !== (<StepperItem />).type) {
              throw new Error("Only StepperItem is allowed as a Stepper child");
            }
            // const header = Children.toArray(child.props.children)[0];
            const header = child.props
              ? Children.toArray(child.props.children).filter((_child) => _child.type === (<StepperHeader />).type)[0]
              : null;
            if (!header) {
              throw new Error("Every StepperItem must contain both StepperHeader and StepperBody children");
            }
            return (
              <Nav.Item key={eventKey}>
                <Nav.Link variant="light" eventKey={`${eventKey}`} onClick={(e) => header.props.onClick(e, eventKey)}>
                  <Badge pill variant="primary">
                    {`${eventKey}`}
                  </Badge>
                  {header ? header.props.children : null}
                  {activeKey === `${eventKey}` && <Icon name="chevron-right" className="float-end" />}
                </Nav.Link>
              </Nav.Item>
            );
          })}
        </Nav>
      </Col>
      <Col sm={rightColumnWidth}>
        <Tab.Content style={{ paddingRight: ".5rem" }}>
          {Children.map(children, (child, index) => {
            const eventKey = `${+index + 1}`;
            const body = child.props
              ? Children.toArray(child.props.children).filter((_child) => _child.type === (<StepperBody />).type)[0]
              : null;
            if (!body) {
              throw new Error("Every StepperItem must contain both StepperHeader and StepperBody children");
            }
            return (
              <Tab.Pane key={eventKey} eventKey={`${eventKey}`} className={body.props.className || ""}>
                {body ? body.props.children : null}
              </Tab.Pane>
            );
          })}
        </Tab.Content>
      </Col>
    </Row>
  </Tab.Container>
);

Stepper.Item = StepperItem;
Stepper.Header = StepperHeader;
Stepper.Body = StepperBody;

export default Stepper;
