import React, { Component } from "react";
import { Modal, Button, Form } from "semantic-ui-react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import isEmpty from "lodash/isEmpty";
import { DateTimeInput } from "semantic-ui-calendar-react";
import * as modalActions from "../../../actions/modalActions";
import * as labActions from "../../../actions/labActions";
import { dateWithTimeFormat, isoFormat } from "../../../constants/miscellaneous";
import { b64toBlob } from "../../../helpers";

export class PrimaryLabelDateModal extends Component {
  state = {
    date: "",
    loading: false
  };

  componentDidMount() {
    this.setState({ date: moment().format(dateWithTimeFormat) });
  }

  handlePrint = () => {
    const {
      patientId,
      actions: { printPrimaryLabel }
    } = this.props;

    this.setState({ loading: true });
    printPrimaryLabel(patientId, moment(this.state.date, dateWithTimeFormat).format(isoFormat)).then(labelPdf => {
      const utf8 = unescape(encodeURIComponent(labelPdf));
      const bytes = [];
      for (let i = 0; i < utf8.length; i++) {
        bytes.push(utf8.charCodeAt(i));
      }
      const blob = b64toBlob(labelPdf, "application/pdf");
      const fileURL = URL.createObjectURL(blob);
      const w = window.open(fileURL);
      w.print();
      this.handleCancel();
    });
  };

  handleCancel = () => {
    this.props.actions.hideModal();
  };

  handleOnChange = (e, data) => {
    const { name, value } = data;
    this.setState({ [name]: value });
  };

  render() {
    const { date, loading } = this.state;
    const canSave = !isEmpty(date);

    return (
      <Modal open={this.props.open} size={"mini"} closeIcon closeOnDimmerClick={false} onClose={this.handleCancel}>
        <Modal.Header>{`Primary Label`}</Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Group widths="equal">
              <Form.Field className={canSave ? "required field" : "error required field"}>
                <label htmlFor="input-date" className="mock-semantic-required">
                  Label Date
                </label>
                <DateTimeInput
                  id="input-date"
                  name="date"
                  placeholder="Label Date"
                  value={date}
                  dateTimeFormat={dateWithTimeFormat}
                  timeFormat={"ampm"}
                  iconPosition="right"
                  onChange={this.handleOnChange}
                  hideMobileKeyboard
                  closable
                />
              </Form.Field>
            </Form.Group>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.handleCancel} content="Cancel" disabled={loading} />
          <Button
            loading={loading}
            icon="print"
            color="blue"
            onClick={this.handlePrint}
            content="Print"
            disabled={loading || !canSave}
          />
        </Modal.Actions>
      </Modal>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...modalActions, ...labActions }, dispatch)
  };
}

export default connect(
  null,
  mapDispatchToProps
)(PrimaryLabelDateModal);
