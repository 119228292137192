import { cloneDeep, isEmpty, set } from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { DateInput } from "semantic-ui-calendar-react";
import moment from "moment";
import { Button, Form, Grid, Icon, Segment } from "semantic-ui-react";
import * as centerActions from "../../actions/centerActions";
import { dateFormat, dateWithTimeFormat, emptyGuid, isoDateFormat, isoFormat } from "../../constants/miscellaneous";
import NewCenterModal from "./NewCenterModal";

export class NewCenterFormDetailsTabEntityHistoryView extends Component {
  state = {
    entity: {
      centerId: this.props.center.centerId,
      entityId: emptyGuid,
      entityType: NewCenterModal.DETAILS_TAB_ENTITIES[this.props.entityType].entityType,
      entityValue: "",
      startDate: "",
      endDate: ""
    },
    history: [],
    newEntityClicked: false
  };

  componentDidMount() {
    this._isMounted = true;
    this.props.actions.fetchLocationAndDirectorHistory(this.props.center.centerId).then(() => {
      this.setState({
        history: this.props.locationAndDirectorHistory
          .filter(i => i.entityType === NewCenterModal.DETAILS_TAB_ENTITIES[this.props.entityType].entityType)
          .map(i => ({ ...i, edit: false }))
      });
    });
  }

  componentDidUpdate() {}

  componentWillUnmount() {
    this._isMounted = false;
    this.props.actions.clearLocationAndDirectorHistory();
  }

  _isMounted = false;

  handleFocus = e => {
    e.target.setAttribute("autocomplete", "nope");
  };

  handleInput = (e, data) => {
    const updatedState = cloneDeep(this.state);
    set(updatedState, data.name, data.value);
    if (data.name === "entity.startDate") {
      updatedState.entity.startDate = isEmpty(data.value) ? `` : moment(data.value, dateFormat).format(isoDateFormat);
    }
    if (data.name === "entity.endDate") {
      updatedState.entity.endDate = isEmpty(data.value) ? `` : moment(data.value, dateFormat).format(isoDateFormat);
    }
    if (this._isMounted) this.setState(updatedState);
  };

  handleMaskedInput = e => {
    const {
      target: { name: field, value }
    } = e;

    const state = { ...this.state };
    set(state, field, value);
    this.setState(state);
  };

  handleNewEntity = () => {
    const entity = {
      centerId: this.props.center.centerId,
      entityId: emptyGuid,
      entityType: NewCenterModal.DETAILS_TAB_ENTITIES[this.props.entityType].entityType,
      entityValue: "",
      startDate: "",
      endDate: "",
      edit: true
    };
    this.setState({
      entity,
      history: [
        { ...entity },
        ...this.state.history
          .filter(i => i.entityType === NewCenterModal.DETAILS_TAB_ENTITIES[this.props.entityType].entityType)
          .map(i => ({ ...i, edit: false }))
      ],
      newEntityClicked: true
    });
  };

  handleEditEntity = entityId => {
    this.setState({
      entity: { ...this.state.history.find(i => i.entityId === entityId) },
      history: this.state.history.map(i => (i.entityId === entityId ? { ...i, edit: true } : { ...i, edit: false }))
    });
  };

  handleCancelEditEntity = () => {
    const history = this.state.history.map(i => ({ ...i, edit: false }));
    let newEntityClicked = this.state.newEntityClicked;
    if (history[0].entityId === emptyGuid) {
      history.shift();
      newEntityClicked = false;
    }
    this.setState({ history, newEntityClicked });
  };

  handleSaveEntity = () => {
    this.props.actions
      .saveLocationAndDirector({
        ...this.state.entity,
        entityId: this.state.entity.entityId === emptyGuid ? null : this.state.entity.entityId,
        startDate: isEmpty(this.state.entity.startDate)
          ? ``
          : `${moment(this.state.entity.startDate)
              .startOf("day")
              .format(isoFormat)}`,
        endDate: isEmpty(this.state.entity.endDate)
          ? ``
          : `${moment(this.state.entity.endDate)
              .endOf("day")
              .format(isoFormat)}`
      })
      .then(() => {
        this.setState({
          newEntityClicked: false,
          history: this.props.locationAndDirectorHistory
            .filter(i => i.entityType === NewCenterModal.DETAILS_TAB_ENTITIES[this.props.entityType].entityType)
            .map(i => ({ ...i, edit: false }))
        });
      });
  };

  renderEntityForm = () => {
    const { entityType, processing: loading } = this.props;
    const { entity } = this.state;
    const disableSaveButton =
      isEmpty(entity.startDate) ||
      (NewCenterModal.DETAILS_TAB_ENTITIES[this.props.entityType].acceptsValue && isEmpty(entity.entityValue));
    return (
      <React.Fragment>
        <Form.Group widths="equal">
          <Form.Field required>
            <label style={{ display: "inline-block", width: "40%" }}>Effective from</label>
            <div style={{ display: "inline-block", width: "60%" }}>
              <DateInput
                name="entity.startDate"
                onChange={this.handleInput}
                value={isEmpty(entity.startDate) ? "" : moment(entity.startDate).format(dateFormat)}
                placeholder="Select Date"
                dateFormat={dateFormat}
                iconPosition="right"
                hideMobileKeyboard
                closable
              />
            </div>
          </Form.Field>
          <Form.Field>
            <label style={{ display: "inline-block", width: "40%" }}>To</label>
            <div style={{ display: "inline-block", width: "60%" }}>
              <DateInput
                name="entity.endDate"
                onChange={this.handleInput}
                value={isEmpty(entity.endDate) ? "" : moment(entity.endDate).format(dateFormat)}
                placeholder="Select Date"
                dateFormat={dateFormat}
                iconPosition="right"
                hideMobileKeyboard
                closable
              />
            </div>
          </Form.Field>
        </Form.Group>
        {NewCenterModal.DETAILS_TAB_ENTITIES[this.props.entityType].acceptsValue && (
          <Form.Group widths="equal">
            <Form.Field>
              <label style={{ display: "inline-block", width: "40%" }}>
                {NewCenterModal.DETAILS_TAB_ENTITIES[this.props.entityType].title}
              </label>
              <div style={{ display: "inline-block", width: "60%" }}>
                <Form.Input name="entity.entityValue" value={entity.entityValue} onChange={this.handleInput} required />
              </div>
            </Form.Field>
            <Form.Field />
          </Form.Group>
        )}
        <div style={{ textAlign: "right" }}>
          <Button size="mini" color="red" content="Cancel" onClick={this.handleCancelEditEntity} disabled={loading} />
          <Button
            size="mini"
            color="green"
            content={`Save ${NewCenterModal.DETAILS_TAB_ENTITIES[entityType].title}`}
            onClick={this.handleSaveEntity}
            disabled={loading || disableSaveButton}
          />
        </div>
      </React.Fragment>
    );
  };

  render() {
    const { entityType } = this.props;
    const { history, newEntityClicked } = this.state;
    return (
      <React.Fragment>
        <h3>{`${NewCenterModal.DETAILS_TAB_ENTITIES[entityType].title} History`}</h3>
        {!newEntityClicked && (
          <Segment vertical>
            <Button
              size="mini"
              color="blue"
              content={`New ${NewCenterModal.DETAILS_TAB_ENTITIES[entityType].title}`}
              onClick={this.handleNewEntity}
              disabled={history.filter(i => isEmpty(i.endDate)).length > 0}
            />
          </Segment>
        )}
        {history.map(item => (
          <Segment key={item.entityId} vertical>
            {item.edit ? (
              this.renderEntityForm(item)
            ) : (
              <Grid>
                <Grid.Row>
                  <Grid.Column width={15}>
                    <p style={{ marginBottom: ".5em" }}>
                      <b>Effective from: </b>
                      {isEmpty(item.startDate) ? (
                        <i>not set</i>
                      ) : (
                        <i>{moment(item.startDate).format(dateWithTimeFormat)}</i>
                      )}
                      <b> to: </b>
                      {isEmpty(item.endDate) ? (
                        <i>still active</i>
                      ) : (
                        <i>{moment(item.endDate).format(dateWithTimeFormat)}</i>
                      )}
                    </p>
                    {!isEmpty(item.entityValue) && (
                      <p style={{ marginBottom: ".5em" }}>
                        <strong>{NewCenterModal.DETAILS_TAB_ENTITIES[this.props.entityType].title}: </strong>
                        {item.entityValue}
                      </p>
                    )}
                  </Grid.Column>
                  <Grid.Column width={1} textAlign="right">
                    <Icon
                      title="Edit"
                      name="edit"
                      color="blue"
                      className="clickable"
                      onClick={() => {
                        this.handleEditEntity(item.entityId);
                      }}
                      style={{ margin: "2px" }}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            )}
          </Segment>
        ))}
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    locationAndDirectorHistory: state.centers.locationAndDirectorHistory,
    states: state.lookups.states,
    processing: state.ajaxCallsInProgress > 0
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...centerActions }, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewCenterFormDetailsTabEntityHistoryView);
