import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Grid, Table, Accordion, Popup, Icon, Segment, Button } from "semantic-ui-react";
import moment from "moment";
import shortid from "shortid";
import emrComponent from "../../common/emrComponent";
import { dateFormat } from "../../../constants/miscellaneous";
import * as modalActions from "../../../actions/modalActions";
import * as labActions from "../../../actions/labActions";
import * as documentActions from "../../../actions/documentActions";
import "./LabResults.css";
import { isEmpty } from "../../../helpers";

export class LabResults extends React.Component {
  state = { activeIndex: -1, printing: false };
  componentDidMount() {
    const {
      labCode,
      patientId,
      orderId,
      actions: { loadLabResultsHeaders }
    } = this.props;
    loadLabResultsHeaders(patientId, orderId, labCode).then(() => {
      if (orderId && labCode) this.setState({ activeIndex: 0 });
    });
    this.interval = setInterval(() => loadLabResultsHeaders(patientId, orderId, labCode), 60000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  openDocument = (documentId, container) => {
    this.props.actions.getDocument(documentId, "labResult", container).then((response) => {
      const blob = new Blob([response.document], {
        type: "application/pdf"
      });
      const fileURL = URL.createObjectURL(blob);
      window.open(fileURL);
    });
  };

  handleEdit = (e, orderId, submissionNumber, labCode) => {
    e.stopPropagation();
    const {
      patientId,
      actions: { loadLabResults, showModal }
    } = this.props;
    loadLabResults(patientId, orderId, labCode).then(() => {
      showModal({
        type: "RAPID_RESULTS_ENTRY_MODAL",
        props: {
          open: true,
          patientId: this.props.patientId,
          visitId: this.props.labsVisitId,
          orderId,
          submissionNumber,
          labCode,
          edit: true
        }
      });
    });
  };

  handlePrint = (e, orderId, submissionNumber, labCode) => {
    const {
      patientId,
      actions: { getDocument, printLabResults }
    } = this.props;
    this.setState({ printing: true });
    printLabResults(patientId, orderId, submissionNumber, labCode).then((response) => {
      getDocument(response.DocumentName, "labResult", response.ContainerName).then((_response) => {
        this.setState({ printing: false });
        const blob = new Blob([_response.document], {
          type: "application/pdf"
        });
        const fileURL = URL.createObjectURL(blob);
        window.open(fileURL);
      });
    });
  };

  handleOrderConfirmatory = (e, orderId) => {
    e.stopPropagation();
    const {
      patientId,
      actions: { orderConfirmatory }
    } = this.props;
    orderConfirmatory(patientId, orderId);
  };

  handleClick = (titleProps, orderId, labCode) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const {
      patientId,
      actions: { loadLabResults }
    } = this.props;
    const newIndex = activeIndex === index ? -1 : index;

    if (newIndex > -1) {
      loadLabResults(patientId, orderId, labCode).then(() => this.setState({ activeIndex: newIndex }));
    } else {
      this.setState({ activeIndex: newIndex });
    }
  };

  render() {
    const { results, processing, backHandler } = this.props;
    const { activeIndex, printing } = this.state;
    return (
      <Grid className="no-margin">
        {backHandler && (
          <Grid.Row>
            <Grid.Column width={1}>
              <Button onClick={backHandler}>Back</Button>
            </Grid.Column>
          </Grid.Row>
        )}
        <Grid.Row style={{ padding: "0" }}>
          <Grid.Column width={16} className="no-padding">
            <Table id="lab-results-table" className="lab-results-table" compact style={{ tableLayout: "fixed" }}>
              <Table.Header>
                <Table.Row id="lab-results-header-row">
                  <Table.HeaderCell width={2}>Lab Submission #</Table.HeaderCell>
                  <Table.HeaderCell width={2}>Test</Table.HeaderCell>
                  <Table.HeaderCell width={2}>Lab</Table.HeaderCell>
                  <Table.HeaderCell width={1}>Test Type</Table.HeaderCell>
                  <Table.HeaderCell width={2}>Sample Date</Table.HeaderCell>
                  <Table.HeaderCell width={1}>Status</Table.HeaderCell>
                  <Table.HeaderCell width={2}>Result Date</Table.HeaderCell>
                  <Table.HeaderCell width={1}>Result</Table.HeaderCell>
                  <Table.HeaderCell width={2}>Reference</Table.HeaderCell>
                  <Table.HeaderCell width={1} />
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {results.length < 1 ? (
                  <Table.Row id="lab-results-no-result-row">
                    <Table.Cell id="lab-results-no-result-cell" colSpan={10} className="empty-result">
                      There are currently no results to display
                    </Table.Cell>
                  </Table.Row>
                ) : (
                  results.map(
                    (
                      {
                        recordKey,
                        orderId,
                        labCode,
                        isResultNormal,
                        isAddOn,
                        submissionNumber,
                        sampleDate,
                        labName,
                        status,
                        resultDate,
                        result,
                        reference,
                        canEditResult,
                        canPrintResult,
                        canOrderConfirmatory,
                        resultDocumentKey,
                        container,
                        labProvider,
                        testType,
                        orderLevelComment,
                        labResults,
                        submissionComment
                      },
                      index
                    ) => (
                      <Table.Row key={recordKey} id={`lab-results-result-table-${recordKey}`}>
                        <Table.Cell colSpan={10} className="no-padding">
                          <Accordion>
                            <Accordion.Title
                              active={activeIndex === index}
                              index={index}
                              onClick={(e, titleProps) => this.handleClick(titleProps, orderId, labCode)}
                            >
                              <Table className="ui very basic table" style={{ tableLayout: "fixed" }}>
                                <Table.Header className="lab-header-table">
                                  <Table.Row
                                    id={`lab-results-result-table-${recordKey}-header-row`}
                                    key={recordKey}
                                    negative={!isResultNormal}
                                  >
                                    <Table.Cell width={2}>
                                      <Icon name="dropdown" />
                                      {submissionNumber}&nbsp;
                                      {isAddOn && (
                                        <Popup trigger={<Icon name="phone" />} content="Ordered via phone call" />
                                      )}
                                      {!isEmpty(orderLevelComment) && (
                                        <Popup
                                          trigger={
                                            <span>
                                              <Icon name="comment" color="blue" />
                                            </span>
                                          }
                                          content={
                                            <span>
                                              Result Comments:&nbsp;
                                              {JSON.parse(orderLevelComment).map((i) => (
                                                <React.Fragment key={`rand-${shortid.generate()}`}>
                                                  {i === null ? "" : i.replace(/ /g, "\u00a0")}
                                                  <br />
                                                </React.Fragment>
                                              ))}
                                            </span>
                                          }
                                        />
                                      )}
                                      {!isEmpty(submissionComment) && (
                                        <Popup
                                          trigger={<Icon name="comment" color="yellow" />}
                                          content={
                                            <span>
                                              Note sent with order:&nbsp;
                                              {submissionComment.split(/(?:\r\n|\r|\n)/g).map((i) => (
                                                <React.Fragment key={`rand-${shortid.generate()}`}>
                                                  {i === null ? "" : i.replace(/ /g, "\u00a0")}
                                                  <br />
                                                </React.Fragment>
                                              ))}
                                            </span>
                                          }
                                        />
                                      )}
                                    </Table.Cell>
                                    <Table.Cell width={2}>
                                      <strong>{labName}</strong>
                                    </Table.Cell>
                                    <Table.Cell width={2}>{labProvider}</Table.Cell>
                                    <Table.Cell width={1}>{testType}</Table.Cell>
                                    <Table.Cell width={2}>{moment(sampleDate).format(dateFormat)}</Table.Cell>
                                    <Table.Cell width={1}>{status}</Table.Cell>
                                    <Table.Cell width={2}>{moment(resultDate).format(dateFormat)}</Table.Cell>
                                    <Table.Cell width={1}>{result}</Table.Cell>
                                    <Table.Cell width={2}>{reference}</Table.Cell>
                                    <Table.Cell width={1} className="text-center">
                                      {canEditResult && (
                                        <Button
                                          size="small"
                                          icon="edit"
                                          className="transparent-button no-padding"
                                          onClick={(e) => this.handleEdit(e, orderId, submissionNumber, labCode)}
                                        />
                                      )}
                                      {canPrintResult && (
                                        <Button
                                          size="small"
                                          className="transparent-button no-padding"
                                          onClick={(e) => this.handlePrint(e, orderId, submissionNumber, labCode)}
                                          loading={printing}
                                          disabled={printing}
                                        >
                                          PDF <Icon name="file pdf outline" style={{ fontSize: "inherit" }} />
                                        </Button>
                                      )}
                                      {false && canOrderConfirmatory && (
                                        <Button
                                          size="small"
                                          className="transparent-button no-padding"
                                          onClick={(e) => this.handleOrderConfirmatory(e, orderId)}
                                          loading={processing}
                                          disabled={processing}
                                        >
                                          <Icon name="plus" />
                                          Conf. Test
                                        </Button>
                                      )}
                                      <br />
                                      {resultDocumentKey && (
                                        <a
                                          role="button"
                                          tabIndex={0}
                                          onClick={() => this.openDocument(resultDocumentKey, container)}
                                          className="lab-order-links"
                                        >
                                          PDF&nbsp;
                                          <Icon name="file pdf outline" color="blue" style={{ display: "inline" }} />
                                        </a>
                                      )}
                                    </Table.Cell>
                                  </Table.Row>
                                </Table.Header>
                              </Table>
                            </Accordion.Title>
                            <Accordion.Content active={activeIndex === index}>
                              <Table className="ui very basic table" style={{ tableLayout: "fixed" }}>
                                <Table.Body>
                                  {labResults
                                    ? labResults.map((parameter) => (
                                        <React.Fragment key={parameter.labName}>
                                          <Table.Row
                                            id={`lab-results-result-table-${recordKey}-result-row-${parameter.labName}-1`}
                                            key={`${parameter.labName}-1`}
                                            negative={!parameter.isResultNormal}
                                          >
                                            <Table.Cell width={2} />
                                            <Table.Cell width={2} />
                                            <Table.Cell
                                              width={2}
                                              id={`lab-results-result-table-${recordKey}-result-col-${parameter.labName}-1-lab-name`}
                                            >
                                              {parameter.labName}
                                            </Table.Cell>
                                            <Table.Cell width={1} />
                                            <Table.Cell width={2} />
                                            <Table.Cell
                                              width={1}
                                              id={`lab-results-result-table-${recordKey}-result-col-${parameter.labName}-1-status`}
                                            >
                                              {parameter.status}
                                            </Table.Cell>
                                            <Table.Cell width={2} />
                                            <Table.Cell
                                              width={1}
                                              id={`lab-results-result-table-${recordKey}-result-col-${parameter.labName}-1-result`}
                                            >
                                              {parameter.result}
                                            </Table.Cell>
                                            <Table.Cell
                                              id={`lab-results-result-table-${recordKey}-result-col-${parameter.labName}-1-reference`}
                                              width={2}
                                            >
                                              {parameter.reference}
                                            </Table.Cell>
                                            <Table.Cell width={1} />
                                          </Table.Row>
                                          {parameter.noteFromLab ? (
                                            <Table.Row
                                              id={`lab-results-result-table-${recordKey}-result-row-${parameter.labName}-2`}
                                              key={`${parameter.labName}-2`}
                                              negative={!parameter.isResultNormal}
                                            >
                                              <Table.Cell
                                                id={`lab-results-result-table-${recordKey}-result-col-${parameter.labName}-2-note`}
                                                key={`${parameter.labName}-1`}
                                                colSpan={10}
                                                className="no-padding"
                                              >
                                                <Grid style={{ padding: "15px" }}>
                                                  <Grid.Column width={8} />
                                                  <Grid.Column width={8}>
                                                    {parameter.noteFromLab && (
                                                      <React.Fragment>
                                                        Note from Lab:
                                                        <Segment className="lab-note">
                                                          {JSON.parse(parameter.noteFromLab).map((i) => (
                                                            <React.Fragment key={`rand-${shortid.generate()}`}>
                                                              {i === null ? "" : i.replace(/ /g, "\u00a0")}
                                                              <br />
                                                            </React.Fragment>
                                                          ))}
                                                        </Segment>
                                                      </React.Fragment>
                                                    )}
                                                  </Grid.Column>
                                                </Grid>
                                              </Table.Cell>
                                            </Table.Row>
                                          ) : null}
                                        </React.Fragment>
                                      ))
                                    : null}
                                </Table.Body>
                              </Table>
                            </Accordion.Content>
                          </Accordion>
                        </Table.Cell>
                      </Table.Row>
                    )
                  )
                )}
              </Table.Body>
              <Table.Footer />
            </Table>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  return {
    results: state.labs.results.sort((a, b) =>
      a.resultDate < b.resultDate ? 1 : b.resultDate < a.resultDate ? -1 : 0
    ),
    processing: state.ajaxCallsInProgress > 0
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...modalActions, ...labActions, ...documentActions }, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(emrComponent(LabResults));
