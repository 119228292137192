import * as types from "./actionTypes";

export function storeUser(user) {
  return {
    type: types.STORE_USER,
    user
  };
}

export default { storeUser };
