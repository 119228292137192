import React from "react";
import { Modal } from "../../../../ui";
import { hideModal } from "../../../../helpers";
import CoreDemographics from "./CoreDemographics";

export const CoreDemographicsModal = (props) => (
  <Modal
    size="xl"
    backdrop="static"
    keyboard={false}
    onHide={() => {
      hideModal();
    }}
    show={props.open}
  >
    <Modal.Header closeButton>
      <Modal.Title>Manage Patient Demographics</Modal.Title>
    </Modal.Header>
    <Modal.Body className="no-padding">
      <CoreDemographics
        afterSave={() => {
          hideModal();
        }}
        handleCancel={() => {
          hideModal();
        }}
      />
    </Modal.Body>
  </Modal>
);

export default CoreDemographicsModal;
