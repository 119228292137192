export const zip = "^\\d{5}";
export const zipMask = [/\d/, /\d/, /\d/, /\d/, /\d/];
export const zip4 = "^\\d{4}";
export const zip4Mask = [/\d/, /\d/, /\d/, /\d/];
export const dateRegEx = "^((0?[1-9]|1[012])[- /.](0?[1-9]|[12][0-9]|3[01])[- /.](1|2)[0-9]{3})*$";
export const timeRegEx = "^(0?[1-9]|1[012])(:[0-5]*\\d) [APap][mM]$";
export const date =
  // eslint-disable-next-line max-len
  "^(((0?[1-9]|1[012])/(0?[1-9]|1\\d|2[0-8])|(0?[13456789]|1[012])/(29|30)|(0?[13578]|1[02])/31)/(19|[2-9]\\d)\\d{2}|0?2/29/((19|[2-9]\\d)(0[48]|[2468][048]|[13579][26])|(([2468][048]|[3579][26])00)))$";
export const requiredDate = "^(0[1-9]|1[012])(0[1-9]|1\\d|2[0-9]|3[01])(1[9]\\d{2}|2\\d{3})";
export const dateMask = [/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/];
export const phone = "((\\(\\d{3}\\) ?)|(\\d{3}-))?\\d{3}-\\d{4}";
export const phoneMask = ["(", /[1-9]/, /\d/, /\d/, ")", " ", /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/];
export const taxId = "^\\d{3}-\\d{2}-\\d{4}$";
export const taxIdMask = [/\d/, /\d/, /\d/, "-", /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/];
export const npi = "^\\d{10}";
export const npiMask = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];

export const referralNumber = "^\\d";
export const referralNumberMask = [
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/
];
export const inputMaxLength = "100";
export const ssn = "^\\d{3}-\\d{2}-\\d{4}$";
export const ssnMask = [/\d|#/, /\d|#/, /\d|#/, "-", /\d|#/, /\d|#/, "-", /\d/, /\d/, /\d/, /\d/];
export const largeResultMessage =
  "only the top 50 results are displayed, narrow your search to display additional results";
export const time = "((1[0-2]|0?[1-9]):([0-5][0-9]) ([AaPp][Mm]))";
export const timeMask = [/\d/, /\d/, ":", /\d/, /\d/, " ", /[AaPp]/, /[Mm]/];
export const ageMask = [/\d/, /\d/, /\d/];
