import React from "react";
import "./LoggingIn.css";

export default function LoggingIn() {
  return (
    <div className="zontainer">
      <span className="zhild ui zimmer">Logging in . .</span>
    </div>
  );
}
